import Immutable from "immutable";

import { getDefaultThemeColorsList, getWhiteThemeColorsList } from "js/common/colors-list";

const targetInheritsValueColour = (trendConfigs, chartTheme, chartHasCustomColors) => {
    let colorByKpiId = Immutable.Map();
    trendConfigs.forEach((trendConfig, index) => {
        const whatToPlot = trendConfig.get("whatToPlot");
        if (whatToPlot === "value") {
            const seriesColour = chartHasCustomColors ? trendConfig.get("seriesColour") : getDefaultColour(index, trendConfig.get("dataGroupIndex"), chartTheme);
            colorByKpiId = colorByKpiId.set(trendConfig.get("kpiId"), seriesColour);
        }
        return trendConfig.get("kpiId");
    });

    return trendConfigs.map((trendConfig, index) => {
        const seriesColour = chartHasCustomColors ? trendConfig.get("seriesColour") : getDefaultColour(index, trendConfig.get("dataGroupIndex"), chartTheme);
        const shouldInheritColour = trendConfig.get("whatToPlot").indexOf("target") > -1 && !seriesColour;
        return trendConfig.set("seriesColour", shouldInheritColour ? colorByKpiId.get(trendConfig.get("kpiId")) : seriesColour);
    });
};

const getDefaultColour = (trendIndex, dataGroupIndex = 0, chartTheme = "DEFAULT") => {
    const coloursList = chartTheme === "DEFAULT"
        ? getDefaultThemeColorsList(dataGroupIndex)
        : getWhiteThemeColorsList(dataGroupIndex);
    return coloursList[trendIndex % coloursList.length];
};

export {
    targetInheritsValueColour,
    getDefaultColour
};
