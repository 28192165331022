/** @jsxImportSource @emotion/react */

import React from "react";

import * as DashboardUtils from "js/dashboards/utils";

import TextField from "js/common/views/inputs/text-field";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {css} from "@emotion/react";
import * as Users from "js/common/users";
import {getUserOrCurrent} from "js/common/users";
import Tooltip from "js/common/views/tooltips";
import FilterTooltip from "js/oneview/header/tooltip";
import Immutable from "immutable";
import {PreviewTooltipContent} from "../../common/views/filter-drawer/filter-button";
import {getGroupOrCurrent} from "../../common/groups";
import {capitaliseWords} from "../../common/utils/strings";
import {Button, Menu, MenuItem} from "@mui/material";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";

export const BasicComponentTitleBar = React.memo(({
  config,
  componentId,
  titleIsEditable,
  title,
  onTitleChange,
  onEditClick,
  onRemoveClick,
  onDownloadClickRef,
  canDownload,
  isClickThrough,
  canFullScreen,
  isFullScreen,
  onFullScreenClick,
  onDuplicateClick,
  isEditing,
  inheritKeys,
  variant
}) => {
  const {isFullEditor} = DashboardUtils.useEditorFlags();
  const {theme} = React.useContext(CustomThemeContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const renderTitle = (isEditing && titleIsEditable) || title;
  return <div
      style={{
        backgroundColor: renderTitle ? theme.palette.background.card : "transparent",
        display: "flex",
        width: renderTitle ? `calc(100% - ${isFullScreen ? "55px" : "14px"})` : "auto",
        height: 27,
        position: "absolute",
        top: isFullScreen ? 18 : 2,
        right: isFullScreen ? 25 : 2,
        left: isFullScreen ? 25 : 5,
        borderTopRightRadius: 5,
        marginRight: 10,
        borderTopLeftRadius: 5,
        paddingLeft: 6,
        zIndex: 2
      }}>

    {isEditing && <>
      <ComponentDragHandle variant={variant} isEditing={isEditing} />
    </>}
    {(isEditing && titleIsEditable)
        ? <TextField
            placeholder="Optional Title"
            data-test-id="title-input"
            value={title}
            onChange={e => onTitleChange(componentId, e.target.value)}
            labelStyle={{fontSize: 12}}
            style={{width: "calc(100% - 10px)", fontSize: 12, fontWeight: 500}} />
        :
        title ? <Tooltip
                wrap={true}
                width={200}
                text={title}
                styleType={theme.themeId === "light" ? "dark" : "light"}
                customStyle={{width: `calc(100% - ${inheritKeys ? 35 : 20}px)`}}
                position="bottom">
                <span
                    data-test-id="grid-title"
                    style={{
                      width: "calc(100% - 10px)",
                      fontWeight: 500,
                      paddingTop: 7,
                      fontSize: 12,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      display: "inline-block"
                    }}>{title}</span>
            </Tooltip>
            :
            <span
                data-test-id="component-title"
                style={{
                  width: "calc(100% - 10px)", fontWeight: 500, paddingTop: 7, fontSize: 12, textOverflow: "ellipsis",
                  whiteSpace: "nowrap", overflow: "hidden", display: "inline-block"
                }}></span>

    }

    <div
        style={{
          width: "fit-content",
          display: "flex",
          borderRadius: 8,
          position: "relative",
          left: variant === "transparent" ? -14 : 0,
          top: variant === "transparent" ? -10 : 0,
          marginTop: variant === "transparent" ? 0 : 5,
          marginRight: 0,
          marginLeft: "auto"
        }}>

      {inheritKeys && <ComponentInheritsButton
          isFullScreen={isFullScreen}
          inheritKeys={inheritKeys}
          config={config}
          isEditing={isEditing} />}


      {(isEditing || (canFullScreen && !isClickThrough) || (canDownload && !isClickThrough)) && <>
        <Button
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            style={{
              background: open ? variant === "transparent"
                  ? theme.palette.background.card
                  : theme.palette.background.paper : "transparent",
              padding: 0,
              height: 20,
              lineHeight: "20px",
              minWidth: 20,
              flexShrink: "none",
              boxShadow: "none",
              borderRadius: 5,
              marginRight: -2
            }}
            data-test-id={`${componentId}-menu`}
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}>
          <i className="bhi-kebab-menu" style={{fontSize: 14}} />
        </Button>
        <Menu
            anchorEl={anchorEl}
            open={open}
            transitionDuration={0}
            onClose={handleClose}
            MenuListProps={{
              style: {background: theme.palette.background.card}
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
        >
          {(isFullEditor && isEditing) &&
              <MenuItem
                  onClick={() => {
                    onEditClick({componentId});
                    handleClose();
                  }}>
                <ComponentEditButton componentId={componentId} isEditing={isEditing} />
              </MenuItem>
          }
          {(isFullEditor && isEditing) && <MenuItem
              onClick={() => {
                onRemoveClick(componentId);
                handleClose();
              }}>
            <ComponentDeleteButton componentId={componentId} isEditing={isEditing} />
          </MenuItem>
          }
          {canFullScreen && !isClickThrough &&
              <MenuItem
                  onClick={() => {
                    onFullScreenClick(componentId, !isFullScreen);
                    handleClose();
                  }}>
                <FullScreenButton componentId={componentId} isFullScreen={isFullScreen} />
              </MenuItem>
          }
          {(isFullEditor && isEditing) && <MenuItem
              onClick={() => {
                onDuplicateClick(componentId);
                handleClose();
              }}>
            <DuplicateButton />
          </MenuItem>
          }
          {canDownload && !isClickThrough &&
              <MenuItem
                  onClick={() => Users.currentHasPermission("EXPORT_FILE") ? onDownloadClickRef.current().enabled
                      && onDownloadClickRef.current().downloadFn() : null}>
                <DownloadButton downloadFn={onDownloadClickRef.current} />
              </MenuItem>
          }
        </Menu>
      </>}
    </div>
  </div>;
});

const menuItemStyle = {
  display: "flex",
  fontSize: 12,
  alignItems: "center"
};

const IconStyle = (hoverColor, suppressMargin = false) => css`
    cursor: pointer;
    font-size: 15px;
    margin-right: ${suppressMargin ? 0 : 10}px;

    &:hover {
        color: ${hoverColor} !important;
        opacity: 1;
    }
;
`;

const ComponentEditButton = React.memo(({componentId}) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <div style={menuItemStyle}><i
      css={IconStyle(theme.palette.primary.main)}
      data-test-id={`${componentId}-edit-button`}
      className="bhi-edit"
  />
    Edit
  </div>;
});

const ComponentDragHandle = React.memo(({variant}) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <i
      css={IconStyle(theme.palette.primary.main)}
      style={{marginLeft: -4, marginRight: 5, marginTop: variant === "transparent" ? -8 : 3}}
      className="cube19-drag-handle bhi-move"
      data-test-id="drag-handle" />;
});

const ComponentDeleteButton = React.memo(({componentId}) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <div style={menuItemStyle}><i
      css={IconStyle(theme.palette.error.main)}
      data-test-id={`${componentId}-delete-button`}
      className="bhi-delete"
  />
    Delete
  </div>;
});

const FullScreenButton = React.memo(({isFullScreen, componentId}) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <div style={menuItemStyle}><i
      css={IconStyle(theme.palette.primary.main)}
      data-test-id={`${componentId}-fullscreen-button`}
      className={`${isFullScreen ? "fas fa-compress" : "bhi-card-expand"} TESTCAFE-component-fullscreen-btn`}
  />
    {isFullScreen ? "Collapse" : "Expand"}
  </div>;
});

const DuplicateButton = React.memo(() => {
  const {theme} = React.useContext(CustomThemeContext);
  return <div style={menuItemStyle}><i
      css={IconStyle(theme.palette.primary.main)}
      className="bhi-duplicate"
  />
    Duplicate
  </div>;
});

export const DownloadButton = React.memo(({downloadFn}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const {enabled, tooltip} = downloadFn();

  return <div
      style={{
        ...menuItemStyle,
        opacity: enabled ? 1 : 0.4,
        cursor: enabled ? "pointer" : "not-allowed"
      }} title={tooltip}>
    <i
        css={IconStyle(theme.palette.text.main)}
        className={"bhi-download"}
        style={{cursor: enabled ? "pointer" : "not-allowed"}} />
    Download
  </div>;
});

const ComponentInheritsButton = React.memo(({isFullScreen, inheritKeys, config, isEditing}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const [filterNames, setFilterNames] = React.useState(Immutable.Map());
  if (DashboardUtils.defaultInheritKeys.count() !== inheritKeys.count()) {
    const customKeys = DashboardUtils.defaultInheritKeys.toJS().filter(x => !inheritKeys.toJS().includes(x));

    const excludedTags = config.getIn(["tagFilter", "excludedTagIds"], Immutable.List());
    const matchAllTags = config.getIn(["tagFilter", "matchAllTagIds"], Immutable.List());
    const matchAnyTags = config.getIn(["tagFilter", "matchAnyTagIds"], Immutable.List());
    const filteredClients = config.getIn(["clientFilter", "clientIds"], Immutable.List());
    const filteredClientSets = config.getIn(["clientFilter", "clientSetIds"], Immutable.List());
    const timeframe = TimeframeRepo.parse(config.get("timeframe").toJS());

    const filterTooltipContent = <div>
      <div>
        <div style={{marginBottom: 10}}>Filters applied</div>
        {customKeys.includes("timeframe") && <div style={{marginBottom: 5}}>
          <div>
            <i
                style={{fontSize: 14, marginRight: 5, position: "relative", bottom: 1}}
                className="bhi-calendar" />
            <b>Date Range</b>
          </div>
          {timeframe.get("start").format("YYYY-MM-DD") + " to " + timeframe.get("end").format("YYYY-MM-DD")}
        </div>}
        {customKeys.includes("qualifier") && <div>
          <div>
            <i
                style={{fontSize: 14, marginRight: 5, position: "relative", bottom: 1}}
                className="bhi-users" />
            <b>{capitaliseWords(config.getIn(["qualifier", "type"]))}</b>
          </div>
          {config.getIn(["qualifier", "type"]) === "GROUP" ? getGroupOrCurrent(config.getIn([
                "qualifier",
                "id"])).attributes.name
              : getUserOrCurrent(config.getIn(["qualifier", "id"])).attributes.fullName}
        </div>}
      </div>
      <PreviewTooltipContent
          hasShowAll={true}
          invertTagColours
          filterNames={filterNames}
          matchAllTagIds={matchAllTags}
          matchAnyTagIds={matchAnyTags}
          excludedTagIds={excludedTags}
          clientIds={filteredClients}
          maxWidth={230}
          clientSetIds={filteredClientSets} />
    </div>;

    const fullScreenOffset = isFullScreen ? 30 : 0;
    const fullScreenArrowOffset = isFullScreen ? 30 : 0;
    return (
        <>
          <FilterTooltip
              tooltipOffset={`-${50 + fullScreenOffset}px`}
              arrowOffset={`${theme.themeId === "dark" ? 192 : 173 + fullScreenArrowOffset}px`}
              typeToIdToNamedItem={filterNames}
              setNames={setFilterNames}
              content={filterTooltipContent}
              selectedClientIds={filteredClients}
              maxWidth={230}
              selectedClientSetIds={filteredClientSets}>
            <i
                css={IconStyle(theme.palette.text.main, true)}
                style={{
                  color: theme.palette.text.lighter,
                  top: -3,
                  textAlign: "center",
                  display: "inline-block",
                  height: 20,
                  width: 20,
                  lineHeight: "20px",
                  padding: 0,
                  fontSize: 12,
                  borderRadius: 5,
                  position: "relative",
                  background: theme.palette.background.paper
                }}
                className="bhi-filter" />
          </FilterTooltip>
        </>
    );
  } else {
    return null;
  }
});

