/** @jsxImportSource @emotion/react */

import React from "react";
import * as Colors from "js/common/cube19-colors";
import {css} from "@emotion/react";
import Immutable from "immutable";
import * as Styles from "js/squids/styles/styles";
import squidHappy from "img/squids/squid-happy.svg";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Numbers from "js/common/utils/numbers";
import {indexBy} from "js/common/utils/collections";
import * as GraphUtils from "js/squids/graph-utils";
import ErrorIcon from "js/squids/squid-display/error-icon";
import dagre from "dagre";
import ReactFlow, {isNode, ReactFlowProvider} from "react-flow-renderer";
import * as Formulas from "js/common/formulas/formulas";
import useKpiLoader from "js/squids/use-kpi-loader";
import {CentralNode, DefaultNode, InputNode, OutputNode} from "js/squids/squid-display/custom-nodes";
import CustomEdge from "js/squids/squid-display/custom-edge";
import LoadingSpinner from "js/common/views/loading-spinner";
import * as SavedConfigs from "js/common/saved-configs";
import * as Popups from "js/common/popups";
import * as Auditor from "js/common/auditer";
import * as Strings from "js/common/utils/strings";
import Controls from "js/squids/controls";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import EditNodeDrawer from "js/squids/squid-display/edit-mode/node-editor-drawer";
import EditEdgeDrawer from "js/squids/squid-display/edit-mode/edge-editor-drawer";
import SettingsDrawer from "js/squids/squid-display/edit-mode/settings-drawer";
import NodeIdDialog from "js/squids/squid-display/node-id-dialog";
import {findDuplicates} from "js/squids/app";
import useMountEffect from "js/common/utils/use-mount-effect";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {ViewControls} from "js/common/react-flow-controls";

const descriptionStyle = css`
  font-size: 12px;
  color: ${Colors.blueBorder};
  margin-top: 10px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Step = React.memo(({
  index,
  setActiveStepIndex,
  step,
  isActive,
  kpiIdToValue,
  style
}) => {

  const activeStepColor = style ? style.get("activeStepColor") : Colors.darkestGrey;
  const accentColor = style ? style.get("accentColor") : Colors.java;
  const inactiveAccentColor = style ? style.get("inactiveAccentColor") : Colors.stepBg;
  const activeStepNumberColor = style ? style.get("activeStepNumberColor") : Colors.offWhite;
  const inactiveStepNumberColor = style ? style.get("inactiveStepNumberColor") : Colors.blueBorder;
  const activeStepTextColor = style ? style.get("activeStepTextColor") : Colors.offWhite;
  const inactiveStepTextColor = style ? style.get("inactiveStepTextColor") : Colors.lightText;
  const iconLabelColor = style ? style.get("iconLabelColor") : Colors.blueBorder;
  return <div
      onClick={() => setActiveStepIndex(index)}
      css={css`
        padding: 15px 20px 15px 20px;
        position: relative;
        cursor: pointer;
        background-color: ${isActive && activeStepColor};

        :hover {
          background-color: ${inactiveAccentColor};
        }
      `}>
    <span
        style={{
          backgroundColor: isActive ? accentColor : inactiveAccentColor,
          borderRadius: "50%",
          width: 20,
          height: 20,
          display: "flex",
          fontSize: 12,
          alignItems: "center",
          justifyContent: "center",
          color: isActive ? activeStepNumberColor : inactiveStepNumberColor,
          position: "absolute",
          zIndex: 99,
          left: -10
        }}>
      {index + 1}
    </span>
    <span
        style={{
          color: isActive ? activeStepTextColor : inactiveStepTextColor,
          fontSize: 15
        }}>
      {step.get("evaluatedLabel")}
    </span>
    {(kpiIdToValue && isActive) &&
        <div style={{display: "flex", flexWrap: "wrap"}}>
          {step.get("icons", new Immutable.List()).map((icon, index) => {
                return <div
                    key={index}
                    style={{display: "flex", flexBasis: "50%", padding: "25px 10px 0 10px", maxWidth: "50%"}}>
                  <i
                      style={{
                        fontSize: 30,
                        color: icon.get("color"),
                        marginRight: 10
                      }} className={`fa fa-${icon.get("icon")}`} />
                  <div>
            <span style={{fontSize: 17, marginBottom: 3, color: activeStepTextColor}}>
              {icon.get("evaluatedValue")}
            </span>
                    <br />
                    <span style={{fontSize: 13, color: iconLabelColor, width: "90%"}}>{icon.get("label")}</span>
                  </div>
                </div>;
              }
          )}
          <p style={{fontSize: 13, color: activeStepTextColor, marginBottom: 0, marginTop: 15}}>
            {step.get("evaluatedDescription")}
          </p>
        </div>}
  </div>;
});

const Steps = React.memo(({
  title,
  theme,
  evaluatedDescription,
  steps,
  activeStepIndex,
  setActiveStepIndex,
  kpiIdToValue
}) => {

  const stepStyle = Styles.getStepStyle(theme);
  const dividerColor = stepStyle ? stepStyle.get("dividerColor") : Colors.tableBorder;
  const {palette} = React.useContext(CustomThemeContext).theme;

  return <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "95%",
        width: 350,
        margin: "0 20px 60px 20px",
        borderRadius: "5px",
        backgroundColor: palette.background.paper
      }}>
    <div style={{borderBottom: `1px solid ${dividerColor}`}}>
      <div style={{borderBottom: `1px solid ${dividerColor}`, padding: "20px", display: "flex"}}>
        <div style={{marginRight: 10}}>
          <h2
              style={{
                fontSize: 17,
                color: palette.textColor,
                wordBreak: "break-word"
              }}>{title}</h2>
          <p css={descriptionStyle}>
            {evaluatedDescription}
          </p>
        </div>
        <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minWidth: 75
            }}>
          <img src={squidHappy} alt="happy-squid" width="75px" />
        </div>
      </div>
      {steps && steps.map((step, i) => {
        const isActive = i === activeStepIndex;
        return <Step
            key={i}
            step={step}
            style={stepStyle}
            index={i}
            setActiveStepIndex={setActiveStepIndex}
            isActive={isActive}
            kpiIdToValue={kpiIdToValue} />;
      })}
    </div>
    {steps && steps.size > 0 &&
        <div style={{display: "flex", marginLeft: 15, marginBottom: 20}}>
          <div style={{marginRight: 10}}>
            <TextButton
                label="Previous Step"
                disabled={activeStepIndex === 0 || activeStepIndex === null}
                style={{
                  backgroundColor: Colors.java,
                  textTransform: "none",
                  fontSize: "0.75rem",
                  paddingRight: 20,
                  paddingLeft: 20,
                  height: 40,
                  lineHeight: 1.1
                }}
                onClick={() => setActiveStepIndex(Numbers.clamp(activeStepIndex - 1, 0, steps.count() - 1))} />
          </div>
          <TextButton
              label="Next Step"
              disabled={activeStepIndex === steps.size - 1}
              style={{backgroundColor: Colors.java, textTransform: "none", height: 40, lineHeight: 1.1}}
              onClick={() => activeStepIndex !== null
                  ?
                  setActiveStepIndex(Numbers.clamp(activeStepIndex + 1, 0, steps.count() - 1))
                  : setActiveStepIndex(0)} />
          <TextButton
              label="View Full Squid"
              disabled={activeStepIndex === null}
              style={{
                backgroundColor: Colors.accentPurple,
                textTransform: "none",
                marginLeft: 15,
                height: 40,
                lineHeight: 1.1,
                width: 100,
                marginRight: 15,
                paddingRight: 10,
                paddingLeft: 10
              }}
              onClick={() => setActiveStepIndex(null)} />
        </div>}
  </div>;
});

let uniqueFlowId = 0;

export const toFlowDirection = dir => dir.toLowerCase() === "vertical" ? "TB" : "LR";

export const addRenderOrder = (nodeId, nodeIsAboveSiblingMidPoint, edges) => {
  const roundingFn = nodeIsAboveSiblingMidPoint ? Math.floor : Math.ceil;
  const mid = roundingFn((edges.size - 1) / 2);
  const firstHalf = edges.slice(0, mid);
  const secondHalf = edges.slice(mid, edges.size);
  const sortedEdges = firstHalf.concat(secondHalf.reverse()).map((edge, index) => {
    const renderOrder = nodeId + Strings.leftPad(index.toString(), 5, "0");
    return edge.set("cube19RenderOrder", renderOrder);
  });

  const edgeIdToRenderOrder = indexBy(e => nodeId + "->" + e.get("nodeId"), sortedEdges)
      .map(edge => edge.get("cube19RenderOrder"));

  return edges.map(edge => {
    const edgeId = nodeId + "->" + edge.get("nodeId");
    const renderOrder = edgeIdToRenderOrder.get(edgeId);
    return edge.set("cube19RenderOrder", renderOrder);
  });
};

const getFlowElements = (evaluatedPage, activeStepIndex, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, theme, editNodeId, mode, onNodeClick, handleAddNodeClick, editEdge, errors) => {
  if (!kpiIdToValue) {
    return null;
  }

  const defaultDisplay = evaluatedPage.get("defaultDisplay");
  const stepDisplay = activeStepIndex !== "null" ?
      evaluatedPage.getIn(["steps", activeStepIndex, "display"], Immutable.Map()) :
      defaultDisplay.get("nodes");
  const nodeDisplays = stepDisplay.get("nodes", new Immutable.List());
  const nodeIdToNodeDisplayForStep = indexBy(nodeDisplay => nodeDisplay.get("id"), nodeDisplays);

  const nodes = idToNode.valueSeq();

  const idToChildIds = GraphUtils.getIdToChildIds(idToNode);
  const idToParentIds = GraphUtils.getIdToParentIds(nodes);
  const hiddenNodeIds = GraphUtils.getNodeIdsToHide(nodes, idToChildIds, idToParentIds, nodeIdToNodeDisplayForStep);

  const position = {x: 0, y: 1};

  uniqueFlowId++;
  const els = nodes
      .toArray()
      .flatMap(n => {
        const nodeId = n.get("id");
        if (hiddenNodeIds.includes(nodeId)) {
          return [];
        }

        const nodeErrors = errors
            .filter(e => e.getIn(["location", "nodeId"]) === nodeId && e.get("category") === "node");
        const isNodeErroring = nodeErrors.size > 0;

        const nodeDisplayForStep = nodeIdToNodeDisplayForStep.get(nodeId, new Immutable.Map());
        const styleIdForNode = nodeDisplayForStep.get(
            "style",
            n.get("style", defaultDisplay.get("nodeStyle", "default")));
        let styleForNode = nodeId === editNodeId ? Styles.getNodeStyle(theme, "edit", isNodeErroring) :
            Styles.getNodeStyle(theme, styleIdForNode, isNodeErroring);
        const styleForNodeValue = Styles.getNodeValueStyle(theme).toJS();
        const styleForEdge = Styles.getEdgeStyle(theme);

        const valueLength = n.get("evaluatedValue") && n.get("evaluatedValue").length;
        const labelLength = n.get("evaluatedLabel") && n.get("evaluatedLabel").join("").length;
        const extraValueHeight = (valueLength > 27 ? ((valueLength / 27)) * 20 : 0);
        const extraLabelHeight = (labelLength > 44 ? ((labelLength / 44)) * 16 : 0);

        styleForNode = styleForNode
            .set("width", styleForNode.get("width") + 30)
            .set("height", styleForNode.get("height") + extraLabelHeight + extraValueHeight);

        const addChild = () => handleAddNodeClick(nodeId, "child");
        const addParent = () => handleAddNodeClick(nodeId, "parent");
        const editNode = () => onNodeClick(nodeId);

        const flowNode = {
          id: nodeId + "-" + uniqueFlowId,
          cube19RenderOrder: nodeId,
          type: GraphUtils.getNodeType(n.get("id"), idToChildIds, idToParentIds, hiddenNodeIds),
          data: {
            label: isNodeErroring ? <>
              <div style={{display: "inline-block", margin: "10px auto"}}>
                <div style={{display: "inline-block", height: "25px", lineHeight: "25px"}}>{nodeErrors.first()
                    .get("type")}</div>
                <ErrorIcon
                    style={{
                      display: "inline-block",
                      marginLeft: 10,
                      padding: 3,
                      borderRadius: "50%",
                      backgroundColor: "rgba(225, 69, 59, 0.2)",
                      fontSize: 18,
                      height: 25,
                      width: 25
                    }} />
              </div>
            </> : <div style={{display: "flex", flexDirection: "column", width: "100%", margin: "10px 0"}}>
              <div
                  style={{
                    ...styleForNodeValue,
                    fontSize: "1.2rem",
                    justifyContent: "center",
                    alignSelf: "center",
                    display: "flex",
                    padding: "0 0.5em"
                  }}>
                {n.get("evaluatedValue")}
              </div>
              {(n.get("evaluatedLabel") !== undefined && n.get("evaluatedLabel").count() > 0) &&
                  <div style={{margin: "10px 0 0 0"}}>
                    {n.get("evaluatedLabel")}
                  </div>
              }
            </div>,
            nodeId,
            mode,
            editNode,
            addChild,
            addParent,
            theme
          },
          style: styleForNode.toJS(),
          position
        };

        //   TODO handle the non simple cases
        //      iterate through nodes in a way that allows mutation of multiple nodes at once
        //        separate out render order calculation from conversion to flow elements
        //        for each loop that edits the idToNode map
        //      for each node
        //        case 1 - 1 parent to many children
        //        case 2 - many parents to one child
        //        case 3 - anything else, give up? (perhaps there is some clever trick or maths thing here?)

        const parentIds = idToParentIds.get(nodeId, Immutable.Set());
        let nodeIsAboveSiblingMidPoint;
        if (parentIds.size === 1) {
          const parentId = parentIds.first();
          const parentNode = idToNode.get(parentId);
          const childIdsOfParent = parentNode.get("edgesOut", Immutable.List()).map(e => e.get("nodeId"));
          const nodeIndexInSiblings = childIdsOfParent.indexOf(nodeId);
          const siblingCount = childIdsOfParent.size;
          nodeIsAboveSiblingMidPoint = nodeIndexInSiblings < (siblingCount / 2);
        } else {
          // TODO this should be decided based on how top or bottom heavy the tree is
          nodeIsAboveSiblingMidPoint = false;
        }

        const flowEdges = addRenderOrder(nodeId, nodeIsAboveSiblingMidPoint, n.get("edgesOut", Immutable.List()))
            .toArray()
            .map((edge, index) => {
              const parentId = nodeId;
              const childId = edge.get("nodeId");
              const edgeId = parentId + "->" + childId;
              let animated = false;

              const edgeErrors = errors.filter(e =>
                  e.getIn(["location", "nodeId"]) === nodeId
                  && e.get("category") === "edge"
                  && e.getIn(["location", "param", 1]) === index
              );
              const isEdgeErroring = edgeErrors.size > 0;

              const styleForLabel = Styles.getLabelStyle(theme, isEdgeErroring);

              let style = styleForEdge.get("style").toJS();
              if (editEdge.get("id") && editEdge.get("id").substring(0, editEdge.get("id").lastIndexOf("-")) ===
                  edgeId) {
                style = {...style, stroke: Colors.c19Yellow};
                animated = true;
              }

              return {
                id: edgeId + "-" + uniqueFlowId,
                cube19RenderOrder: edge.get("cube19RenderOrder"),
                animated,
                style,
                type: styleForEdge.get("type"),
                ...styleForLabel.toJS(),
                label: {
                  text: isEdgeErroring ? edgeErrors.first().get("type") : edge.get("evaluatedLabel"),
                  isErroring: isEdgeErroring
                },
                source: parentId + "-" + uniqueFlowId,
                target: childId + "-" + uniqueFlowId
              };
            });

        return [flowNode, ...flowEdges];
      });

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({
    rankdir: toFlowDirection(stepDisplay.get(
        "graphDirection",
        defaultDisplay.get("graphDirection", "horizontal")))
  });

  els.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {width: el.style.width, height: el.style.height});
    } else {
      // ok, unfortunately we cant get the EXACT width as the rect isn't rendered at the time of doing this calc
      // we do however know the text it whats to put in
      // so if there is a label we take the length * 5px (the average char pixel width at this size) and add the known
      // padding px this gives us a pretty reliable width for the edge
      let eleWidth = 90;
      if (el && el.label && el.label.isErroring === false) {
        if (el.label.text && el.label.text.join("").length > 0) {
          eleWidth = el.label.text.join("").length * 5 + 30;
        }
      }
      dagreGraph.setEdge(el.source, el.target, {width: mode === "EDIT" ? eleWidth + 50 : eleWidth});
    }
  });

  dagre.layout(dagreGraph);
  const positionedEls = els.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = "left";
      el.sourcePosition = "right";

      // unfortunately we need this little hack to pass a slightly different position
      // in order to notify react flow about the change
      el.position = {
        x: nodeWithPosition.x + Math.random() / 1001,
        y: nodeWithPosition.y
      };
    }
    return el;
  });

  return positionedEls.sort((a, b) => a.cube19RenderOrder.localeCompare(b.cube19RenderOrder));
};

const PageDisplay = React.memo(({
  pageWrapper,
  selectedPageId,
  idToUndoStack,
  setIdToUndoStack,
  masterKpiTypeToKpiId,
  controlConfig,
  setControlConfig,
  setSelectedPageId,
  isDrawerOpen,
  setIsDrawerOpen,
  deleteSquid,
  handleEditPageWrapper,
  hasSquidEditorPermission,
  mode,
  setMode,
  squidNames
}) => {

  const title = pageWrapper.get("name");
  const page = pageWrapper.get("json");

  const {kpiIds: requiredKpiIds} = React.useMemo(
      () => Formulas.getRequiredKpisForConfig(page, masterKpiTypeToKpiId),
      [page, masterKpiTypeToKpiId]);

  const {kpiIdToValue, loadingKpiData} = useKpiLoader(requiredKpiIds, controlConfig);

  const idToFormula = React.useMemo(() => Formulas.getIdToFormulaForConfig(page), [page]);
  const idToNode = React.useMemo(() => Formulas.getIdToNodeForConfig(page), [page]);

  const {page: evaluatedPage, errors: evalErrors} = React.useMemo(() => {

    if ((kpiIdToValue.size > 0 || requiredKpiIds.size === 0) && !loadingKpiData) {
      let {result: evaluatedDescription, errors: descriptionErrors} = Formulas.evaluateFormulasInTemplateString(
          null,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          page.get("description"));

      let errors = descriptionErrors.map(error => {
        if (error.get("category") === "internal-error") {
          return error
              .set("category", "description")
              .delete("location");
        } else {
          return error;
        }
      });

      let updatedPage = page
          .set("evaluatedDescription", evaluatedDescription)
          .update("nodes", nodes => nodes.map(node => {
            const {result: nodeValue, errors: nodeValueErrors} = Formulas.evaluateFormulaStrAndFormat(
                Formulas.createNodeContext(node),
                masterKpiTypeToKpiId,
                idToNode,
                kpiIdToValue,
                idToFormula,
                node.get("value"));
            const locationedValueErrors = nodeValueErrors.map(error => {
              if (error.get("category") === "internal-error") {
                return error
                    .set("category", "node")
                    .set("location", Immutable.fromJS({nodeId: node.get("id"), param: "value"}));
              } else {
                return error;
              }
            });
            const {result: labelValue, errors: nodeLabelErrors} = Formulas.evaluateFormulasInTemplateString(
                Formulas.createNodeContext(node),
                masterKpiTypeToKpiId,
                idToNode,
                kpiIdToValue,
                idToFormula,
                node.get("label"));
            const locationedLabelErrors = nodeLabelErrors.map(error => {
              if (error.get("category") === "internal-error") {
                return error
                    .set("category", "node")
                    .set("location", Immutable.fromJS({nodeId: node.get("id"), param: "label"}));
              } else {
                return error;
              }
            });
            errors = errors
                .concat(locationedLabelErrors)
                .concat(locationedValueErrors);
            return node
                .set("evaluatedValue", nodeValue)
                .set("evaluatedLabel", labelValue)
                .update("edgesOut", Immutable.Map(), edges => edges.map((edge, index) => {
                  let {result, errors: edgeErrors} = Formulas.evaluateFormulasInTemplateString(
                      Formulas.createEdgeContext(node.get("id"), edge.get("nodeId")),
                      masterKpiTypeToKpiId,
                      idToNode,
                      kpiIdToValue,
                      idToFormula,
                      edge.get("label"));
                  /* if (edge.get("label") && (edge.get("label").includes("component-on-hover") ||
                      edge.get("label").includes("formula-on-hover"))) {
                    edgeErrors = edgeErrors.add(Immutable.fromJS({
                      category: "edge",
                      type: "Invalid label",
                      message: "Edge labels cannot contain on hover",
                      location: {
                        nodeId: node.get("id"),
                        param: ["edgesOut", index, "label"]
                      }
                    }));
                  } */
                  const locationedEdgeErrors = edgeErrors.map(error => {
                    if (error.get("category") === "internal-error") {
                      return error
                          .set("category", "edge")
                          .set("location", Immutable.fromJS({
                            nodeId: node.get("id"),
                            param: ["edgesOut", index, "label"]
                          }));
                    } else {
                      return error;
                    }
                  });
                  errors = errors.concat(locationedEdgeErrors);
                  return edge.set("evaluatedLabel", result);
                }));
          }))
          .update("steps", Immutable.Map(), steps => steps.map((step, index) => {
            const {result: label, errors: stepLabelErrors} = Formulas.evaluateFormulasInTemplateString(
                null,
                masterKpiTypeToKpiId,
                idToNode,
                kpiIdToValue,
                idToFormula,
                step.get("label"));
            const locationedStepLabelErrors = stepLabelErrors.map(error => {
              if (error.get("category") === "internal-error") {
                return error
                    .set("category", "step")
                    .set("location", Immutable.fromJS({stepIndex: index, param: "label"}));
              } else {
                return error;
              }
            });
            const {result: description, errors: stepDescriptionErrors} = Formulas.evaluateFormulasInTemplateString(
                null,
                masterKpiTypeToKpiId,
                idToNode,
                kpiIdToValue,
                idToFormula,
                step.get("description"));
            const locationedStepDescriptionErrors = stepDescriptionErrors.map(error => {
              if (error.get("category") === "internal-error") {
                return error
                    .set("category", "step")
                    .set("location", Immutable.fromJS({stepIndex: index, param: "description"}));
              } else {
                return error;
              }
            });
            errors = errors.concat(locationedStepLabelErrors).concat(locationedStepDescriptionErrors);
            return step
                .set("evaluatedLabel", label)
                .set("evaluatedDescription", description)
                .update("icons", Immutable.Map(), icons => icons.map((icon, iconIndex) => {
                  const {result: value, errors: valueErrors} = Formulas.evaluateFormulasInTemplateString(
                      null,
                      masterKpiTypeToKpiId,
                      idToNode,
                      kpiIdToValue,
                      idToFormula,
                      icon.get("value"));
                  const locationedValueErrors = valueErrors.map(error => {
                    if (error.get("category") === "internal-error") {
                      return error
                          .set("category", "step")
                          .set("location", Immutable.fromJS({stepIndex: index, param: ["icons", iconIndex, "value"]}));
                    } else {
                      return error;
                    }
                  });
                  errors = errors.concat(locationedValueErrors);
                  return icon.set("evaluatedValue", value);
                }));
          }));
      return {page: updatedPage, errors: errors};
    } else {
      return {page: page, errors: Immutable.Set()};
    }
  }, [page, idToFormula, idToNode, kpiIdToValue, masterKpiTypeToKpiId, loadingKpiData, requiredKpiIds]);

  const duplicateErrors = React.useMemo(() => {
    if (page) {
      const duplicateIds = findDuplicates(page.get("formulas", Immutable.List())
          .map(f => f.get("id")));
      if (duplicateIds.count() > 0) {
        return duplicateIds.map(id => Immutable.fromJS({
          category: "custom-formula",
          type: "duplicate-id",
          location: {formulaId: id},
          message: `Formula ID "${id}" must be unique`
        }));
      } else {
        return Immutable.Set();
      }
    }
  }, [page]);

  const errors = evalErrors.concat(duplicateErrors);

  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const [undoStack, setUndoStack] = React.useState(idToUndoStack.get(selectedPageId, Immutable.Stack()));
  const [redoStack, setRedoStack] = React.useState(Immutable.Stack());
  const [lastUndoMillis, setLastUndoMillis] = React.useState(null);

  useMountEffect(() => {
    const storedUndoStack = idToUndoStack.get(selectedPageId, Immutable.Stack());
    setUndoStack(storedUndoStack);
  });

  const [isEditNodeOpen, setIsEditNodeOpen] = React.useState(false);
  const [editNodeIndex, setEditNodeIndex] = React.useState(null);
  const [isEditEdgeOpen, setIsEditEdgeOpen] = React.useState(false);
  const [editEdge, setEditEdge] = React.useState(Immutable.Map());
  const [openSteps, setOpenSteps] = React.useState(Immutable.Set());

  const editNodeId = page.getIn(["nodes", editNodeIndex, "id"]);

  const {theme: globalTheme} = React.useContext(CustomThemeContext);
  const currentTheme = globalTheme.themeId;

  const theme = page.getIn(["defaultDisplay", "theme"], currentTheme === "light" ? "light" : "default");

  const pushUndo = React.useCallback((configBeforeEdit, configAfterEdit) => {
    const newUndoDelayMillis = 500;
    const timeSinceLastUndoMillis = Date.now() - lastUndoMillis;

    const pushNewUndo = (timeSinceLastUndoMillis > newUndoDelayMillis) || undoStack.isEmpty();

    let newUndoStack;
    if (pushNewUndo && !Immutable.is(configBeforeEdit, configAfterEdit)) {
      newUndoStack = undoStack.push(configBeforeEdit);
    } else {
      newUndoStack = undoStack;
    }

    if (pushNewUndo || !redoStack.isEmpty() ||
        (timeSinceLastUndoMillis > (newUndoDelayMillis / 3))) {

      setRedoStack(Immutable.Stack());
      setUndoStack(newUndoStack);
      setIdToUndoStack(idToUndoStack.set(selectedPageId, newUndoStack));
      setLastUndoMillis(Date.now());
    }
  }, [lastUndoMillis, redoStack, undoStack, idToUndoStack, setIdToUndoStack, selectedPageId]);

  const handleEditPage = React.useCallback((newPage) => {
    const newPageWrapper = pageWrapper.set("json", newPage);
    pushUndo(pageWrapper, newPageWrapper);
    handleEditPageWrapper(newPageWrapper);
  }, [pageWrapper, handleEditPageWrapper, pushUndo]);

  const handleEditTitle = React.useCallback((newTitle) => {
    const newPageWrapper = pageWrapper.set("name", newTitle);
    pushUndo(pageWrapper, newPageWrapper);
    handleEditPageWrapper(newPageWrapper);
  }, [pageWrapper, handleEditPageWrapper, pushUndo]);

  const [isNodeIdDialogOpen, setIsNodeIdDialogOpen] = React.useState(false);
  const [newNodeDetails, setNewNodeDetails] = React.useState(Immutable.Map());

  const handleAddNodeClick = React.useCallback((nodeId, newNodeType) => {
    setIsNodeIdDialogOpen(true);
    setNewNodeDetails(Immutable.Map({nodeId: nodeId, newNodeType: newNodeType}));
  }, []);

  const onConfirmNodeId = React.useCallback(() => {
    const nodeId = newNodeDetails.get("nodeId");
    const newNodeType = newNodeDetails.get("newNodeType");
    const newNodeId = newNodeDetails.get("newNodeId").replaceAll(" ", "-");

    if (newNodeType) {
      const existingNodeIndex = page.get("nodes")
          .findIndex(node => node.get("id") === nodeId);

      if (newNodeType === "child") {
        handleEditPage(
            page
                .update("nodes", nodes => nodes.push(Immutable.Map({id: newNodeId})))
                .updateIn(["nodes", existingNodeIndex, "edgesOut"], edges => {
                  if (edges === undefined) {
                    return Immutable.fromJS([{nodeId: newNodeId}]);
                  } else {
                    return edges.push(Immutable.Map({nodeId: newNodeId}));
                  }
                }));
      } else if (newNodeType === "parent") {
        handleEditPage(
            page
                .update("nodes", nodes => nodes.push(Immutable.fromJS({
                  id: newNodeId,
                  edgesOut: [{nodeId: nodeId}]
                }))));
      }

      setEditNodeIndex(page.get("nodes").size);
    } else {
      const updatedPage = page.setIn(["nodes", editNodeIndex, "id"], newNodeId);
      handleEditPage(updatedPage);
    }
    setIsNodeIdDialogOpen(false);
    setIsEditNodeOpen(true);
  }, [page, handleEditPage, newNodeDetails, editNodeIndex]);

  const onNodeClick = React.useCallback((nodeId) => {
    setIsEditNodeOpen(true);
    const index = page.get("nodes").findIndex(node => node.get("id") === nodeId);
    setEditNodeIndex(index);
  }, [page]);

  const idToEvaluatedNode = React.useMemo(() => Formulas.getIdToNodeForConfig(evaluatedPage), [evaluatedPage]);

  const nodeAndEdgeErrors = errors.filter(e => e.get("category") === "node" || e.get("category") === "edge");

  const flowElements = React.useMemo(
      () => getFlowElements(
          evaluatedPage,
          activeStepIndex,
          masterKpiTypeToKpiId,
          idToEvaluatedNode,
          kpiIdToValue,
          idToFormula,
          theme,
          editNodeId,
          mode,
          onNodeClick,
          handleAddNodeClick,
          editEdge,
          nodeAndEdgeErrors),
      [
        evaluatedPage,
        activeStepIndex,
        masterKpiTypeToKpiId,
        idToEvaluatedNode,
        kpiIdToValue,
        idToFormula,
        theme,
        editNodeId,
        mode,
        onNodeClick,
        handleAddNodeClick,
        editEdge,
        nodeAndEdgeErrors]);

  const nodeTypes = {
    centralNode: CentralNode,
    input: InputNode,
    output: OutputNode,
    default: DefaultNode
  };

  const edgeTypes = {
    custom: CustomEdge
  };

  const onElementClick = (event, element) => {
    if (!element.data) {
      const edge = Immutable.fromJS(element)
          .update("source", s => s.substring(0, s.lastIndexOf("-")))
          .update("target", t => t.substring(0, t.lastIndexOf("-")));

      setEditEdge(edge);
      setIsEditEdgeOpen(true);
    }
  };

  const handlePanelCLick = () => {
    // NOTE only way at the moment to close dropdowns when panel is clicked
    const toggleButtonEl = document.querySelectorAll(".toggleButtonEl");
    toggleButtonEl.forEach(el => el.classList.contains("isOpen") && el.click());
  };

  let treeEl;
  if (loadingKpiData) {
    treeEl = <>
      <LoadingSpinner />
    </>;
  } else if (flowElements) {
    // TODO if loading kpi data then grey out with overlay of progress
    treeEl = <ReactFlow
        elements={flowElements}
        onElementClick={mode === "EDIT" ? onElementClick : null}
        onPaneClick={handlePanelCLick}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineComponent={<div />}
        zoomOnScroll={false}
        panOnScroll={true}
        elementsSelectable={mode === "EDIT"}
        nodesConnectable={false}
        nodesDraggable={false}
        onlyRenderVisibleElements={true}
        paneMoveable={true} />;
  } else {
    treeEl = "unexpected state, not loading but also no data";
  }

  const steps = evaluatedPage.get("steps");

  const saveSquid = () => {
    SavedConfigs
        .update(Immutable.fromJS(pageWrapper))
        .then(newReport => {
          Popups.success(`'<strong>${title}</strong>' saved`);
          Auditor.audit("squid:saved", {id: newReport.get("id"), name: newReport.get("name")});
        }, err => {
          const errorMsg = "Unable to save squid";
          const responseJSON = err.responseJSON;
          if (responseJSON && responseJSON.errors) {
            Popups.alert(responseJSON.errors, {title: errorMsg});
          } else {
            Popups.error(errorMsg);
          }
        });

    setUndoStack(Immutable.Stack());
    setIdToUndoStack(idToUndoStack.delete(selectedPageId));
    setRedoStack(Immutable.Stack());
    setLastUndoMillis(null);
  };

  const tabStyle = {
    backgroundColor: "transparent",
    marginLeft: "10px",
    textTransform: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    minWidth: "auto",
    minHeight: "10px",
    marginTop: "15px"
  };

  const undo = React.useCallback(() => {
    if (!undoStack.isEmpty()) {
      const previousPageWrapper = undoStack.peek();

      const newUndoStack = undoStack.pop();
      const newRedoStack = redoStack.push(pageWrapper);

      handleEditPageWrapper(previousPageWrapper);
      setUndoStack(newUndoStack);
      if (newUndoStack.size === 0) {
        setIdToUndoStack(idToUndoStack => idToUndoStack.delete(selectedPageId));
      } else {
        setIdToUndoStack(idToUndoStack => idToUndoStack.set(selectedPageId, newUndoStack));
      }
      setRedoStack(newRedoStack);
    }
  }, [redoStack, undoStack, pageWrapper, handleEditPageWrapper, selectedPageId, setIdToUndoStack]);

  const redo = React.useCallback(() => {
    if (!redoStack.isEmpty()) {
      const nextPage = redoStack.peek();

      const newRedoStack = redoStack.pop();
      const newUndoStack = undoStack.push(pageWrapper);

      handleEditPageWrapper(nextPage);
      setUndoStack(newUndoStack);
      setRedoStack(newRedoStack);
    }
  }, [redoStack, undoStack, pageWrapper, handleEditPageWrapper]);

  const keyHandler = React.useCallback(event => {
    if ((event.code === "KeyZ" && event.shiftKey && (event.ctrlKey || event.metaKey)) ||
        (event.code === "KeyY" && event.ctrlKey)) {
      redo();
      event.preventDefault();
      return false;
    } else if (event.code === "KeyZ" && (event.ctrlKey || event.metaKey)) {
      undo();
      event.preventDefault();
      return false;
    }
  }, [undo, redo]);

  React.useEffect(() => {
    window.addEventListener("keydown", keyHandler, true);
    return () => window.removeEventListener("keydown", keyHandler, true);
  }, [keyHandler]);

  const pageStyle = Styles.getPageStyle(theme);

  const handleEditNodeId = () => {
    setNewNodeDetails(Immutable.Map({newNodeId: editNodeId}));
    setIsNodeIdDialogOpen(true);
  };

  const inputDelayMillis = 500;

  return <div style={{display: "flex", flex: "auto", backgroundColor: pageStyle.get("background"), height: "inherit"}}>
    <div style={{display: "flex", flexDirection: "column", width: "100%", position: "relative", padding: 10}}>
      <div style={{margin: "10px 20px"}}>
        <Controls
            config={controlConfig}
            onChange={setControlConfig}
            setSelectedPageId={setSelectedPageId}
            withReturnToSquidsIndex={true}
            saveSquid={saveSquid}
            deleteSquid={deleteSquid}
            undo={undo}
            redo={redo}
            redoStack={redoStack}
            undoStack={undoStack}
            hasSquidEditorPermission={hasSquidEditorPermission}
            mode={mode}
            setMode={setMode}
            setIsDrawerOpen={setIsDrawerOpen}
            errors={errors} />
      </div>
      <div style={{display: "flex", flexDirection: "row", height: "100%"}}>
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
          <div style={{marginLeft: "20px", marginTop: "10px", display: "flex"}}>
            <h2 style={{color: pageStyle.get("titleColor"), wordBreak: "break-word"}}><b>{title}</b></h2>
          </div>
          <ReactFlowProvider>
            <div style={{flex: "auto", paddingLeft: "20px"}}>
              {treeEl}
            </div>
            <ViewControls showFullScreen={false} />
          </ReactFlowProvider>
        </div>
        <ClickAwayListener onClickAway={() => mode === "EDIT" && setActiveStepIndex(null)}>
          <div>
            <Steps
                steps={steps}
                theme={theme}
                title={title}
                evaluatedDescription={evaluatedPage.get("evaluatedDescription")}
                activeStepIndex={activeStepIndex}
                setActiveStepIndex={setActiveStepIndex}
                kpiIdToValue={kpiIdToValue} />
          </div>
        </ClickAwayListener>
      </div>
    </div>
    <EditNodeDrawer
        isEditNodeOpen={isEditNodeOpen}
        editNodeIndex={editNodeIndex}
        setEditNodeIndex={setEditNodeIndex}
        setIsEditNodeOpen={setIsEditNodeOpen}
        idToNode={idToNode}
        handleEditPage={handleEditPage}
        handleEditNodeId={handleEditNodeId}
        page={page}
        errors={nodeAndEdgeErrors}
        inputDelayMillis={inputDelayMillis}
        tabStyle={tabStyle}
        theme={theme} />
    <EditEdgeDrawer
        isEditEdgeOpen={isEditEdgeOpen}
        editEdge={editEdge}
        setEditEdge={setEditEdge}
        setIsEditEdgeOpen={setIsEditEdgeOpen}
        page={page}
        errors={errors.filter(e => e.get("category") === "edge")}
        handleEditPage={handleEditPage}
        inputDelayMillis={inputDelayMillis}
        tabStyle={tabStyle} />
    <SettingsDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        setEditNodeIndex={setEditNodeIndex}
        setIsEditNodeOpen={setIsEditNodeOpen}
        openSteps={openSteps}
        setOpenSteps={setOpenSteps}
        tabStyle={tabStyle}
        handleEditPage={handleEditPage}
        handleEditTitle={handleEditTitle}
        page={page}
        evaluatedPage={evaluatedPage}
        title={title}
        squidNames={squidNames}
        masterKpiTypeToKpiId={masterKpiTypeToKpiId}
        kpiIdToValue={kpiIdToValue}
        idToFormula={idToFormula}
        idToNode={idToNode}
        inputDelayMillis={inputDelayMillis}
        errors={errors} />
    <NodeIdDialog
        isOpen={isNodeIdDialogOpen}
        nodeIds={page.get("nodes").map(node => node.get("id"))}
        editNodeId={editNodeId}
        newNodeDetails={newNodeDetails}
        setNewNodeDetails={setNewNodeDetails}
        onRequestClose={() => setIsNodeIdDialogOpen(false)}
        onButtonClick={onConfirmNodeId} />
  </div>;
});

export default PageDisplay;
