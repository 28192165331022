/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import LabelledSelect from "js/common/views/inputs/labelled-select";
import * as Colors from "js/common/cube19-colors";
import {css} from "@emotion/react";

const FormulaEditor = React.memo(({
  formula,
  index,
  handleEditPage,
  page,
  errors,
  inputDelayMillis
}) => {
  const hasDuplicateIds = () => errors.some(e => e.get("type") === "duplicate-id");
  const hasFormulaStringErrors = errors.some(e => e.get("type") !== "duplicate-id" && e.get("type") !== "invalid-format-type");
  const hasFormatTypeErrors = errors.some(e => e.get("type") === "invalid-format-type");

  const getEditorHeading = () => (
      !!formula.get("formatAs") ? `Editing: ${formula.get("id")}` :
          "New Formula"
  );
  return <tr css={editorRowStyles}>
    <td colSpan={4} style={{padding: "0 30px 30px 30px"}}>
      <h4 style={{fontSize: 20, marginBottom: "2rem"}}>{getEditorHeading()}</h4>
      <div>
        <div style={{flex: 1}}>
          <DelayedTextField
              label="Formula String"
              theme="cube2021"
              delayInMillis={inputDelayMillis}
              value={formula.get("str")}
              style={{width: "100%"}}
              error={hasFormulaStringErrors}
              helperText={hasFormulaStringErrors &&
              errors.filter(e => e.get("type") !== "duplicate-id" && e.get("type") !== "invalid-format-type").first().get("message")}
              InputProps={{disableUnderline: true}}
              onChange={changes => handleEditPage(page.setIn(["formulas", index, "str"], changes))} />
        </div>
      </div>
      <div>
        <div style={{width: "35%", marginRight: "2rem"}}>
          <LabelledSelect
              label="Formula Type"
              theme="cube2021"
              isMulti={false}
              error={hasFormatTypeErrors}
              helperText={hasFormatTypeErrors && errors.filter(e => e.get("type") === "invalid-format-type").first().get("message")}
              isClearable={false}
              isSearchable={false}
              placeholder="Select"
              selectedValue={formula.get("formatAs")}
              options={selectOptions}
              onChange={changes => {handleEditPage(page.setIn(["formulas", index, "formatAs"], changes));}} />
        </div>
        <div style={{width: "40%"}}>
          <DelayedTextField
              label="Formula ID"
              theme="cube2021"
              value={formula.get("id")}
              style={{width: "100%"}}
              InputProps={{disableUnderline: true}}
              delayInMillis={inputDelayMillis}
              error={hasDuplicateIds()}
              helperText={hasDuplicateIds() && "Cannot have a duplicate id"}
              onChange={changes => handleEditPage(page.setIn(["formulas", index, "id"], changes.replaceAll(" ", "-")))} />
        </div>
      </div>
    </td>
  </tr>;
});

const selectOptions = Immutable.fromJS([
  {label: "Number", value: "NUMBER"},
  {label: "Currency", value: "CURRENCY"},
  {label: "Percentage", value: "PERCENT"}
]);

const editorRowStyles = css`
  border-bottom: 1px solid ${Colors.tableBorder};
  background-color: ${Colors.attributeNotSetColor};

  > td {
    > div {
      display: flex;
      margin-bottom: 1.5rem;
    }
  }

  h5 {
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 1rem;
  }

  .MuiFormControl-root {
    margin-bottom: 0;
    width: 100%;
  }

  .MuiSelect-select:focus {
    background: none;
  }
`;

export default FormulaEditor;
