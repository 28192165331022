import * as Immutable from "immutable";

import * as ajax from "js/common/ajax";

const path = window.path;
const baseUrl = "pages";

const getAll = () =>
    ajax.get({url: baseUrl})
        .then(x => Immutable.fromJS(x));

const get = pageId =>
    ajax.get({url: path(baseUrl, pageId)})
        .then(x => Immutable.fromJS(x));

const getPageForUser = userId =>
    ajax.get({url: path("users", userId, "page")})
        .then(page => Immutable.fromJS(page));

const getPageForGroup = groupId =>
    ajax.get({url: path("group", groupId, "page")})
        .then(page => Immutable.fromJS(page));

const create = page =>
    ajax.post({
      url: baseUrl,
      json: page.toJS()
    }).then(x => Immutable.fromJS(x));

const update = page =>
    ajax.put({
      url: path(baseUrl, page.get("id")),
      json: page.toJS()
    }).then(x => Immutable.fromJS(x));

const del = pageId => ajax.del({url: path(baseUrl, pageId)});

export {
  getAll,
  get,
  getPageForUser,
  getPageForGroup,
  create,
  update,
  del
};