import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";
import GetContainerDimensions from "react-dimensions";

import UncaughtErrorMsg from "js/common/views/uncaught-error-msg";

import BaseCube from "js/cubetv/cubes/models/base-cube";

import getAutoplayVideoUrl from "js/common/utils/autoplay-youtube-video-url";

import Cube19 from "js/cube19.app";

const EmbeddedVideoModel = BaseCube.extend({

});

Cube19.module("Models.Cubes", function(Cubes, App, Backbone, Marionette, $, _) {

    Cubes.EmbeddedVideo = EmbeddedVideoModel;

});

export default pure((props) => (
                <ErrorBoundaryPage {...props} />
));

const ErrorBoundaryPage = createReactClass({

    mixins: [ PureRenderMixin ],

    getInitialState() {
        return {
            uncaughtError: false
        };
    },

    componentDidCatch() {
        this.setState({
            uncaughtError: true
        });
    },

    render() {
        if (this.state.uncaughtError) {
            return <UncaughtErrorMsg />;
        } else {
            return <div style={{ height: "100vh" }}><Slide {...this.props} /></div>;
        }
    }

});

const Slide = GetContainerDimensions()(pure(({
    videoUrl,
    muteVideo,
    showSubtitles,
    isFullHeight,
    containerWidth,
    containerHeight
}) => (
    <div>
        <iframe
            title={"Gamification embedded video"}
            type="text/html"
            width={containerWidth}
            height={isFullHeight ? containerHeight : containerHeight - 60}
            src={getAutoplayVideoUrl(videoUrl, muteVideo, showSubtitles)}
            frameBorder="0"
            allow="autoplay; encrypted-media"
            allowFullScreen />
    </div>
)));
