import React from "react";
import * as Colors from "js/common/cube19-colors";

const errorIconStyles = {
  display: "inline-block",
  marginLeft: "4px",
  borderRadius: "50%",
  backgroundColor: "rgba(225, 69, 59, 0.2)",
  color: Colors.redIcon,
  width: "1rem",
  height: "1rem",
  fontSize: "10px",
  lineHeight: "1rem",
  textAlign: "center"
};

const ErrorIcon = ({style}) => <span style={{...errorIconStyles, ...style}}><i className={`fa fa-exclamation`} /></span>;

export default ErrorIcon;