
export default (isCompactView) => {
    if (isCompactView) {
        return {
            target: "small-3 columns",
            percentOfTarget: "small-3 columns",
            diffFromExpected: "small-3 columns",
            total: "small-3 columns"
        };
    } else {
        return {
            groupOrUser: "small-3 columns",
            target: "small-2 columns",
            percentOfTarget: "small-2 columns",
            diffFromExpected: "small-3 columns",
            total: "small-2 columns"
        };
    }
};
