/** @jsxImportSource @emotion/react */

import moment from "moment";

import pure from "js/common/views/pure";
import ArrowIcon from "js/oneview/targets/arrow-icon";
import NotesSummary from "js/oneview/targets/notes-summary";
import {parseDate} from "js/common/utils/time";
import * as Formatter from "js/common/utils/formatter";

const tdStyle = {
  display: "flex",
  flexFlow: "row nowrap",
  flexGrow: 1,
  flexBasis: 0,
  paddingRight: "0.5em",
  wordBreak: "break-word",
  overflow: "hidden",
  textOverflow: "ellipsis",
  minWidth: 0,
  whiteSpace: "nowrap"
};

const SimpleTargetView = pure(({
  target,
  theme,
  isSelected,
  isTargetInTimeframe,
  highlightCurrentTarget,
  onClick
}) => {
  const notes = target.get("noteAssignmentDtos");
  const startDate = formatDate(target.get("targetStart"));
  const endDate = isFarFutureDate(target.get("targetEnd")) ? null : formatDate(target.get("targetEnd"));
  const hasEndDate = !!endDate;
  const arrowIconSpacing = {paddingLeft: "1rem", paddingRight: "1rem"};
  const isPriority = target.get("priority");
  const priorityIconStyle = {color: isPriority ? theme.palette.primary.main : theme.palette.textColor, flexGrow: 0.2, paddingLeft: "1rem"};
  const isNotesVisible = target.get("notesVisible");

  return (
      <div
          css={{display: "flex", flexFlow: "row nowrap", alignItems: "center", paddingRight: 20}}
          className={`table-${theme.themeId} target-row ${isSelected ? "is-selected" : ""} ${highlightCurrentTarget && isTargetInTimeframe ?
              "is-in-timeframe" : ""}`}
          onClick={() => onClick(target.get("id"))}>
        <i css={{...tdStyle, ...priorityIconStyle}} className={`fa fa-${isPriority ? "star" : "star-o"}`} />
        <div css={{...tdStyle}}>
          {`${Formatter.format(target.get("value"), {decimalPlaces: 2})} ${labelByTargetPeriod[target.get("period")]}`}
        </div>
        <div css={{...tdStyle}}>
          {hasEndDate
              ? <span>{startDate}<ArrowIcon style={arrowIconSpacing} />{endDate}</span>
              : <span>{startDate} onwards</span>}
        </div>
        {isNotesVisible &&
        <div css={{...tdStyle, paddingRight: "1rem"}}>
          <NotesSummary theme={theme} notes={notes} />
        </div>}
      </div>);
});

const getFarFutureDate = () => parseDate("2099-01-01");
const isFarFutureDate = dateStr => parseDate(dateStr).isSameOrAfter(getFarFutureDate(), "day");

const formatDate = (date, format = "YYYY-MM-DD", displayFormat = "L") => {
  return moment(date, format).format(displayFormat);
};

const labelByTargetPeriod = {
  WEEKLY: "per week",
  MONTHLY: "per month"
};

export default SimpleTargetView;