import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";

import PureRenderMixin from "react-addons-pure-render-mixin";
import onClickOutside from "react-onclickoutside";

import FilterableGroupUserTree from "js/common/views/inputs/group-and-user-picker/filterable-group-user-tree";
import Layout from "js/common/views/foundation-column-layout";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const GroupAndUserPicker = onClickOutside(createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    onGroupClick: ReactPropTypes.func.isRequired,
    onUserClick: ReactPropTypes.func.isRequired,
    containerWidth: ReactPropTypes.number.isRequired
  },

  getInitialState() {
    return {
      isOpen: false
    };
  },

  render() {
    const {isOpen} = this.state;
    const {containerWidth, theme} = this.props;
    const {palette} = theme;
    const currentTheme = theme.themeId;

    const toggleIconStyle = {color: palette.text.main, paddingRight: "8px"};
    const dropdownIconStyle = {color: palette.text.main, fontSize: "0.75rem"};
    const dropdownIconClass = `bhi-sort-${isOpen ? "asc" : "desc"}`;

    const rowStyle = {
      position: "absolute",
      width: containerWidth < 1180 ? "90%" : "100%",
      marginTop: 5
    };

    const buttonStyle = {
      cursor: "pointer",
      padding: "5px 10px",
      marginRight: "10px",
      backgroundColor: palette.background.card,
      border: `2px solid ${currentTheme === "light" ? "#ddd" : palette.background.card}`,
      borderRadius: "4px",
      position: "relative",
      bottom: -2,
      transition: "background 0.3s ease",
      ":hover": {
        backgroundColor: palette.background.paper
      }
    };

    const containerStyle = {
      position: "absolute",
      zIndex: 9999,
      width: "100%",
      maxHeight: "500px",
      overflow: "auto",
      backgroundColor: palette.background.card,
      boxShadow: "rgba(0, 0, 0, 0.5) 0 10px 20px"
    };
    return (
        <span style={{height: "31px", display: "inline"}}>
                <span
                    title="Search for a User or Group"
                    style={buttonStyle}
                    onClick={this.handleToggleClick}>
                    <i className="bhi-users" style={toggleIconStyle} />
                    <i className={dropdownIconClass} style={dropdownIconStyle} />
                </span>
                <Layout allSmall={12} allMedium={6} allLarge={5} rowStyle={rowStyle}>
                    <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
                        {isOpen &&
                        <FilterableGroupUserTree
                            theme={theme}
                            onGroupClick={this.handleGroupClick}
                            onUserClick={this.handleUserClick}
                            customContainerStyle={containerStyle} />}
                    </VelocityTransitionGroup>
                </Layout>
            </span>);
  },

  handleToggleClick() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  },

  handleGroupClick(groupId) {
    this.closeGroupAndUserPicker();
    this.props.onGroupClick(groupId);
  },

  handleUserClick(userId) {
    this.closeGroupAndUserPicker();
    this.props.onUserClick(userId);
  },

  handleClickOutside() {
    this.closeGroupAndUserPicker();
  },

  closeGroupAndUserPicker() {
    this.setState({
      isOpen: false
    });
  }

}));

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <GroupAndUserPicker theme={theme} {...props} />;
};

export default Wrapper;
