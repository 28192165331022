import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import { Popover } from '@mui/material';
import Checkbox from "js/common/views/inputs/checkbox";

export default createReactClass({
    mixins: [PureRenderMixin],

    getInitialState() {
        return {
            anchorEl: null,
            open: false
        };
    },

    render() {
        const {columns, visibleColumns, onChange} = this.props;
        const {anchorEl, open} = this.state;
        return (
            <div>
                <i onClick={e => {
                    e.preventDefault();
                    this.setState({
                        anchorEl: e.currentTarget,
                        open: true
                    });
                }}
                title="Add/Remove Columns"
                style={{color: "orange"}}
                className="fa fa-wrench" />
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                    transformOrigin={{horizontal: 'left', vertical: 'top'}}
                    onClose={() => this.setState({ open: false })}>
                    <div style={{maxHeight: 400, overflowY: "auto", width: 200, border: "1px solid #555", padding: "0.5rem"}}>
                        {columns.map(c => <Checkbox
                            key={c.get("id")}
                            label={c.get("label")}
                            style={{height: 30, width:200}}
                            checked={visibleColumns.includes(c.get("id"))}
                            onCheck={() => {
                                const newVisibleColumns = visibleColumns.includes(c.get("id"))
                                    ? visibleColumns.remove(c.get("id"))
                                    : visibleColumns.add(c.get("id"));
                                onChange(newVisibleColumns);
                            }}
                            disabled={c.get("required")} />
                        )}
                    </div>
                </Popover>
            </div>
        );
    }

});