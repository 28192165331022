import React from "react";
import {Card, IconButton, InputLabel} from "@mui/material";
import lightTheme from "img/theme/light-illustration.svg";
import darkTheme from "img/theme/dark-illustration.svg";
import * as Auditor from "js/common/auditer";
import { CustomThemeContext } from 'js/common/themes/CustomThemeProvider';

const paperStyle = {
  backgroundColor: "transparent",
  margin: "10px 0 10px -10px",
  boxShadow: "none",
  padding: "0",
  width: "100%",
  maxWidth: 450
};

const themes = [
  {name: "Light", id: "light", image: lightTheme},
  {name: "Dark", id: "dark", image: darkTheme},
];

const DisplaySettings = React.memo(() => {

  const {theme, setTheme} = React.useContext(CustomThemeContext);
  const [selectedTheme, setSelectedTheme] = React.useState(theme.themeId);

  const handleChangeTheme = (newTheme) => {
    setTheme(newTheme);
    setSelectedTheme(newTheme);
    Auditor.audit("theme-change", {oldTheme: theme.themeId, newTheme: newTheme})
  };

  return (
    <div>
      <div>
        <InputLabel>Theme: ({theme.themeId})</InputLabel>
        <Card style={{...paperStyle, height: 140, display: "flex"}}>
          {themes.map(option => {
            const isSelected = option.id === selectedTheme;
            const selectedStyle = isSelected && {border: `3px solid ${theme.palette.primary.main}`};
            return (
              <div style={{display: "flex", flexDirection: "column", margin: "15px 0 10px 10px"}}>
                <IconButton
                  style={{...selectedStyle, borderRadius: 10, marginRight: 10, padding: 0}}
                  onClick={() => handleChangeTheme(option.id)}
                  size="large">
                  <img src={option.image} alt={option.name} width="120px" />
                </IconButton>
                <div style={{textAlign: "center"}}>{option.name}</div>
              </div>
            );
          })}
        </Card>
      </div>
    </div>
  );
});

export default DisplaySettings;
