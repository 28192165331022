import React from "react";
import Immutable from "immutable";
import createReactClass from "create-react-class";
import ReactHighcharts from "react-highcharts";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import { formatBigNumber } from "js/common/utils/numbers";
import * as Formatter from "js/common/utils/formatter";
import { getColorForPercentage } from "js/oneview/kpi-values-display";
import getKpiDataSafely from "js/onpoint/get-kpi-data-safely";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

export default React.memo(({
    trendConfig,
    trendData,
    kpiSummariesConfig,
    kpiSummariesDataByIndex,
    containerHeight
}) => {
    const { theme } = React.useContext(CustomThemeContext);

    const containerPadding = {
        paddingRight: "1.5vw",
        paddingLeft: "1.5vw"
    };
    const containerStyle = {
        height: containerHeight,
        ...containerPadding
    };
    const { kpis } = kpiSummariesConfig;
    const trendChartContainerStyle = {
        height: kpis.length > 0 ? (containerHeight > 768 ? "77%" : "80%") : "100%"
    };
    const hasYLabel = !!trendConfig.yLabel || trendConfig.yLabel.length > 0;
    const hasTrendData = trendData
        .get("trends")
        .every(trend => trend.data.length > 0);
    let paddingLeft = "2.75vw";
    if (hasYLabel && hasTrendData) {
        paddingLeft = "4.75vw";
    } else if (!hasYLabel && !hasTrendData) {
        paddingLeft = "1vw";
    }
    return (
        <div style={containerStyle}>
            <div style={trendChartContainerStyle}>
                <TrendChart config={trendConfig} data={trendData} theme={theme} />
            </div>
            <div style={{height: "20%", width: "100%", paddingLeft}}>
                <KpiSummaries configs={kpis} dataByIndex={kpiSummariesDataByIndex} />
            </div>
        </div>
    );
});

const TrendChart = GetContainerDimensions()(createReactClass({

    mixins: [PureRenderMixin],

    render() {
        const trendsData = this.props.data.get("trends");
        if (!trendsData || !trendsData.length) {
            return;
        }

        const { config, data, containerWidth, containerHeight, theme } = this.props;
        const axisLabelStyle = {
            fontFamily: theme.typography.fontFamily,
            fontSize: `${isLargeScreen(containerWidth) ? (containerWidth * 1.25/100) : (containerWidth * 1.5/100)}px`,
            fontWeight: "bold",
            color: "#EEEEEE",
            textShadow: "0px 2px 2px #000"
        };
        const yAxisValuePrefix = data.get("yAxisValuePrefix");
        const height = isLargeScreen(containerWidth) ? containerHeight - (containerHeight * 0.03) : containerHeight;
        const chartConfig = {
            chart: {
                type: "areaspline",
                animation: false,
                backgroundColor: null,
                plotBackgroundColor: "rgba(37, 42, 64, 0.56)",
                plotBorderWidth: 0,
                spacingTop: 0,
                spacingLeft: 0,
                spacingRight: 0,
                spacingBottom: isLargeScreen(containerWidth) ? 5 : 15,
                height
            },
            title: {
                text: ""
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            legend: {
                align: "right",
                verticalAlign: "top",
                x: isLargeScreen(containerWidth) ? -100 : 0,
                y: 10,
                itemStyle: {
                    fontSize: "1.25rem",
                    fontFamily: theme.typography.fontFamily,
                    color: "#E0E0E3",
                    cursor: "default",
                    marginTop: -10
                },
                itemHoverStyle: {
                    color: "#E0E0E3",
                }
            },
            tooltip: {
                enabled: false
            },
            xAxis: {
                allowDecimals: false,
                tickLength: 0,
                gridLineWidth: 1,
                gridLineDashStyle: "Solid",
                gridLineColor: "#444",
                align: "left",
                units: "day",
                labels: {
                    style: axisLabelStyle,
                    y: 25,
                    x: 5,
                    formatter: function() {
                        return this.value;
                    }
                },
                title: {
                    text: config.xLabel || "Days From Start",
                    margin: trendsData.every(t => t.data.length > 0) ? null : 20,
                    style: axisLabelStyle
                }
            },
            yAxis: {
                allowDecimals: false,
                gridLineWidth: 1,
                gridLineDashStyle: "Solid",
                gridLineColor: "#444",
                tickLength: 0,
                tickInterval: Math.round(data.get("maxValue") / 4),
                endOnTick: true,
                max: data.get("maxValue"),
                labels: {
                    style: {
                        ...axisLabelStyle,
                        textOverflow: "none"
                    },
                    align: "right",
                    x: -5,
                    formatter: function() {
                        return yAxisValuePrefix + formatBigNumber(this.value);
                    }
                },
                title: {
                    text: config.yLabel || "",
                    style: {
                        ...axisLabelStyle,
                        paddingTop: "5px"
                    }
                }
            },
            plotOptions: {
                series: {
                    pointStart: 0,
                    lineWidth: 2,
                    marker: {
                        enabled: false
                    },
                    enableMouseTracking: false,
                    animation: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                    events: {
                        legendItemClick: function() {
                            return false;
                        }
                    }
                },
                allowPointSelect: false
            },
            noData: {
                position: {
                    verticalAlign: "top",
                    y: 150
                },
                style: axisLabelStyle
            },
            series: trendsData
        };
        return (
            <div style={{borderTop: "2px solid #f9ec33", height: "100%"}}>
                <ReactHighcharts config={chartConfig} />
            </div>
        );
    }

}));

const KpiSummaries = GetContainerDimensions()(React.memo(({ configs, dataByIndex, containerWidth }) => {
    const kpisCount = configs.length;
    const { theme } = React.useContext(CustomThemeContext);
    
    const containerStyle = {
        display: "flex",
        justifyContent: kpisCount < 3 ? "space-around" : "space-between"
    };
    const panelStyle = {
        minWidth: kpisCount === 1 || kpisCount === 2 ? "42%" : `${100 / kpisCount}%`,
        margin: kpisCount === 1 ? "0 auto" : 0
    };
    const kpiSummaryContainerStyle = {
        position: "relative",
        fontFamily: theme.typography.fontFamilyBold,
        textShadow: "2px 2px 8px #000",
        padding: "1.5vmin",
        background: "inherit",
        border: "2px solid #B9B9B9",
        backgroundColor: "rgba(37, 42, 64, 0.56)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    };
    return (
        <div style={containerStyle}>
            {configs.map((config, index) => {
                const kpiSummary = dataByIndex.get(index);
                const isFirstItem = index === 0;
                const isLastItem = index === kpisCount - 1;
                const kpiDisplay = getKpiDisplay(kpiSummary, config.expectedData);
                const kpiTargetPercentageMarker = {
                    width: 0,
                    height: 0,
                    position: "absolute",
                    top: 0,
                    right: 0,
                    borderTop: `4.5vh solid ${kpiDisplay.get("displayColor")}`,
                    borderLeft: "4.5vh solid transparent"
                };
                const kpiSummaryTitleStyle = {
                    paddingBottom: isLargeScreen ? 5 : 0,
                    fontSize: isLargeScreen ? "1.45vw" : "2.5vw",
                    lineHeight: isLargeScreen ? 1 : 1.15,
                    color: "#fff",
                    textTransform: "uppercase",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap"
                };
                const kpiSummaryValueStyle = {
                    fontFamily: theme.typography.fontFamilyBold,
                    overflow: "hidden",
                    fontSize: "3.8vw",
                    lineHeight: 1,
                    textAlign: "center",
                    color: "#fff"
                };
                return (
                    <div
                        key={index}
                        style={{
                            ...panelStyle,
                            paddingLeft: isFirstItem ? 0 : "0.625vw",
                            paddingRight: isLastItem ? 0 : "0.625vw"
                        }}>
                        <div style={kpiSummaryContainerStyle}>
                            <div style={kpiTargetPercentageMarker} />
                            <div style={kpiSummaryTitleStyle}>
                                {kpiSummary.get("title")}
                            </div>
                            <div style={kpiSummaryValueStyle}>
                                {kpiDisplay.get("formattedValue")}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}));

const isLargeScreen = width => width > 1024;

const getKpiDisplay = (kpiSummary, expectedData) => {
    const kpiData = kpiSummary.get("kpiData");
    if (kpiData.get("type") === "error") {
        return Immutable.fromJS({
            formattedValue: kpiData.get("value"),
            displayColor: null
        });
    } else {
        const kpiValueData = kpiData.get(expectedData);
        const kpi = kpiRepo.get(kpiSummary.get("kpiId"));
        const valueFormat = kpi.get("type").valueFormat;
        // TODO PRECISION why do we want zero decimals here?
        const formattedValue = Formatter.format(kpiValueData.toJS(), {valueFormat, maxDisplayLength: 8, decimalPlaces: 0});

        const isTargetData = expectedData === "target" || expectedData === "expectedValue";
        const totalVsExpectedPercent = getPercentageOfExpectedTargetDone(kpiData);
        const kpiHasTarget = totalVsExpectedPercent !== null;
        const valueColor = !isTargetData && kpiHasTarget ? getDisplayColor(totalVsExpectedPercent) : null;
        return Immutable.fromJS({
            formattedValue,
            displayColor: valueColor
        });
    }
};
const getDisplayColor = percentValue => {
    const color = getColorForPercentage(percentValue);
    return color === "green" ? "#17ff00" : color;
};

const getPercentageOfExpectedTargetDone = kpiData => {
    const target = getKpiDataSafely(kpiData, "target");
    const targetValue = target.get("value");
    const kpiHasTarget = targetValue > 0;
    if (kpiHasTarget) {
        const total = getKpiDataSafely(kpiData, "total");
        const totalValue = total.get("value");
        const expected = getKpiDataSafely(kpiData, "expectedValue");
        const expectedValue = expected.get("value");
        const hasExceededExpected = totalValue > expectedValue;
        const percentage = expectedValue === 0 ? 0 : totalValue / expectedValue;
        return hasExceededExpected ? 1 : Math.floor(percentage * 100) / 100;
    } else {
        return null;
    }
};
