import React from "react";
import Immutable from "immutable";
import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import InteractiveTable from "js/common/views/tables/interactive-table";
import Layout from "js/common/views/foundation-column-layout";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import Err from "js/common/views/error";
import * as Ajax from "js/common/ajax";
import * as popups from "js/common/popups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as auditor from "js/common/auditer";

const IpAccessAdmin = (props) => {
  const [ipAddresses, setIpAddresses] = React.useState(Immutable.Set());
  const [addressInputValue, setAddressInputValue] = React.useState("");
  const [errorText, setErrorText] = React.useState("");
  const [showErrorMessage, setShowErrorMessage] = React.useState(false);
  const {theme} = props;

  React.useEffect(() => {
    Ajax.get({
      url: "/ip-addresses"
    })
        .then((res) => {
          setIpAddresses(Immutable.Set(res));
        })
        .catch(error => {
          setShowErrorMessage(true);
          setErrorText("There was an issue loading IP addresses please refresh");
        });
    auditor.audit("ip-access-admin:loaded");
  }, []);



  const processIpAddresses = (ipAddresses, successMsg, clearInput) => {
    Ajax.post({
      url: "/ip-addresses",
      json: ipAddresses
    })
        .then(res => {
          setIpAddresses(Immutable.Set(res));
          clearInput && setAddressInputValue("");
          popups.success(successMsg);
        })
        .catch(error => {
          setErrorText(error.responseJSON.message);
          setShowErrorMessage(true);
        });
  };

  const ipAddressToRow = (ip) => {
    return [
      <div key="ip-address" style={{textAlign: "left", color: theme.palette.textColor}}>
        {ip}
      </div>,
      <div key="remove-ip-address">
        <IconButton
            icon="trash"
            type="dark"
            hoverType="alert"
            style={{color: "#717171"}}
            onClick={() => deleteIp(ip)} />
      </div>
    ];
  };

  const deleteIp = ip => {
    processIpAddresses(ipAddresses.delete(ip), "IP address removed", false);
  };

  const addIp = ip => {
    if (ipAddresses.has(ip)) {
      setErrorText("The IP address already exists.");
      setShowErrorMessage(true);
    } else {
      processIpAddresses(ipAddresses.add(ip), "IP address added!", true);
    }
  };

  return (
      <div>
        <div className="row collapse">
          <AdminHeader>
            <Icon icon="location" />
            IP Address Entry
          </AdminHeader>
          <Layout allSmall={12} allMedium={5} mediumCentered={true}>
            <Layout collapseRow={true} allSmall={6}>
              <SimpleTextInput
                  placeholder="Enter IP Address"
                  value={addressInputValue}
                  onChange={input => {
                    setAddressInputValue(input);
                    setShowErrorMessage(false);
                  }} />
              <TextButton icon="plus" label="Add IP" type="primary" onClick={() => addIp(addressInputValue)} />
            </Layout>
            {showErrorMessage &&
                <Layout collapseRow={true} allSmall={12}>
                  <Err style={{marginBottom: 0, textAlign: "left"}} text={errorText} />
                </Layout>
            }
          </Layout>
          <Layout allSmall={12} allMedium={5} mediumCentered={true}>
            <div style={{marginTop: "1rem"}}>
              <InteractiveTable
                  columns={["IP Address", {label: "Delete", textAlign: "center"}]}
                  rows={ipAddresses.map(ipAddressToRow, theme)} />
            </div>
          </Layout>
        </div>
      </div>
  );
};

IpAccessAdmin.displayName = "IpAccessAdmin";

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <IpAccessAdmin theme={theme} {...props} />;
};