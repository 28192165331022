import * as Colors from "js/common/cube19-colors";

export const futuristicSquid = {
  id: "futuristic",
  page: {
    background: Colors.grey,
    titleColor: Colors.white
  },
  edge: {
    style: {stroke: Colors.futuristicPink, strokeWidth: 3, boxShadow: `10px 10px ${Colors.futuristicPink}`},
    type: "custom"
  },
  label: {
    labelStyle: {
      color: Colors.java,
      textShadow: `0 0 10px ${Colors.turquoiseLight}`,
      fontFamily: "Courier New",
      fontWeight: "bold"
    },
    labelBgStyle: {
      background: Colors.darkestGrey
    },
    labelBgPadding: [20, 6]
  },
  nodeValueStyle: {
    color: Colors.java,
    textShadow: `0 0 10px ${Colors.turquoiseLight}`,
    fontFamily: "Courier New",
    fontWeight: "bold"
  },
  nodeHandleStyle: {
    background: Colors.futuristicPink,
    width: 12,
    height: 12,
    border: "4px solid black",
    boxShadow: `1px 1px 3px ${Colors.futuristicPink}`
  },
  node: {
    highlight: {
      id: "highlight",
      border: "2px solid #FFAB2E",
      backgroundColor: Colors.darkestGrey,
      color: Colors.white,
      fontFamily: "Courier New",
      fontSize: "0.7rem"
    },
    dark: {
      id: "dark",
      border: "2px solid transparent",
      opacity: 0.5,
      backgroundColor: Colors.darkestGrey,
      color: Colors.white,
      fontFamily: "Courier New",
      fontSize: "0.7rem"
    },
    edit: {
      border: "2px solid #FFAB2E",
      backgroundColor: Colors.darkestGrey,
      color: Colors.white,
      fontFamily: "Courier New",
      fontSize: "0.7rem"
    },
    default: {
      id: "default",
      backgroundColor: Colors.darkestGrey,
      color: Colors.white,
      fontFamily: "Courier New",
      fontSize: "0.7rem"
    }
  }
};