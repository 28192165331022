import Immutable from "immutable";
import {visitNode, cleanup} from "js/common/formulas/preventing-loops";
import {
  getKpiIdsForTemplateString,
  evaluateFormulaStr,
  getKpiIdsForFormulaId,
  evaluateFormulaId,
  getLabelForFormulaId,
  evaluateFormulasInTemplateString,
  addErrorLocation
} from "js/common/formulas/formulas";

export default Immutable.fromJS([
  {
    id: "node-value",
    getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, cache, args) => {
      const nodeId = args.first();
      const node = idToNode.get(nodeId);
      try {
        const isLooping = visitNode(nodeId);
        if (isLooping) {
          return {kpiIds: Immutable.Set(), errors: Immutable.Set()};
        }
        return getKpiIdsForTemplateString(
            context,
            masterKpiTypeToKpiId,
            idToNode,
            idToFormula,
            cache,
            node.get("value"));
      } finally {
        cleanup();
      }
    },
    getLabel: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs) => {
      const nodeId = callArgs.first();
      const node = idToNode.get(nodeId);
      const {result, errors} = evaluateFormulasInTemplateString(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          node.get("label"));
      return {result: result, errors: errors};
    },
    fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, args) => {
      const nodeId = args.first();
      const node = idToNode.get(nodeId);
      if (!node) {
        throw new Error(`cannot find node "${nodeId}"`);
      }
      try {
        const isLooping = visitNode(nodeId);
        if (isLooping) {
          const errors = Immutable.Set([
            Immutable.fromJS({
              category: "node",
              type: "infinite-loop",
              formulaId: nodeId,
              location: {nodeId: nodeId, param: "value"},
              message: "Node cannot reference itself"
            })]);
          return {
            result: Immutable.Map({value: 0, formatAs: "NUMBER"}),
            errors: addErrorLocation(errors, "node", {nodeId: nodeId, param: "value"})
          };
        }
        const {result, errors} = evaluateFormulaStr(
            context,
            masterKpiTypeToKpiId,
            idToNode,
            kpiIdToValue,
            idToFormula,
            node.get("value"));
        return {result, errors: addErrorLocation(errors, "node", {nodeId: nodeId, param: "value"})};
      } finally {
        cleanup();
      }

    }
  }, {
    id: "current-node-value",
    getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, cache, _) => {
      return getKpiIdsForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          idToFormula,
          cache,
          "node-value",
          Immutable.Set.of(context.get("nodeId")));
    },
    getLabel: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs) => {
      const nodeId = context.get("nodeId");
      const node = idToNode.get(nodeId);
      const {result, errors} = evaluateFormulasInTemplateString(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          node.get("label"));
      const formattedResult = result.toJS().join();
      return {result: formattedResult, errors: errors};
    },
    fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, _) => {
      return evaluateFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          "node-value",
          Immutable.Set.of(context.get("nodeId")));
    }
  }, {
    id: "percent-between-nodes",
    getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, cache, args) => {
      const {kpiIds: forParent, errors: parentErrors} = getKpiIdsForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          idToFormula,
          cache,
          "node-value",
          Immutable.List.of(args.get(0)));
      const {kpiIds: forChild, errors: childErrors} = getKpiIdsForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          idToFormula,
          cache,
          "node-value",
          Immutable.List.of(args.get(1)));
      return {kpiIds: forChild.union(forParent), errors: parentErrors.concat(childErrors)};
    },
    getLabel: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, args) => {
      const {result: parentLabel, errors: parentErrors} = getLabelForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          "node-value",
          Immutable.List.of(args.get(0)));
      const {result: childLabel, errors: childErrors} = getLabelForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          "node-value",
          Immutable.List.of(args.get(1)));

      if(typeof parentLabel === "string" && typeof childLabel === "string") {
        return {result: "(" + childLabel + " % of " + parentLabel + ")", errors: parentErrors.concat(childErrors)};
      } else {
        const result = Immutable.List(["("]).concat(childLabel).push(" % of ").concat(parentLabel).push(")");
        return{result, errors: parentErrors.concat(childErrors)}
      }
      },
    fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, args) => {
      const parentId = args.get(0);
      const childId = args.get(1);

      const {result: parentResult, errors: parentErrors} = evaluateFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          "node-value",
          Immutable.List.of(parentId));
      const {result: childResult, errors: childErrors} = evaluateFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          "node-value",
          Immutable.List.of(childId));

      if (childResult.get("currency") !== parentResult.get("currency")) {
        throw new Error("mismatched currencies (" + childResult.get("currency") + " vs " +
            parentResult.get("currency") +
            ") in nodes '" +
            childId + "' and '" + parentId + "'");
      }

      const errors = parentErrors.concat(childErrors);

      if (parentResult.get("value") === 0) {
        return {result: Immutable.Map({formatAs: "PERCENT", value: 0}), errors: errors};
      } else {
        return {
          result: Immutable.Map({
            formatAs: "PERCENT",
            value: childResult.get("value") / parentResult.get("value")
          }), errors: errors
        };
      }
    }
  }, {
    id: "current-node-percent-from-other",
    getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, cache, args) => {
      return getKpiIdsForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          idToFormula,
          cache,
          "percent-between-nodes",
          Immutable.List.of(context.get("nodeId"), args.get(0)));
    },
    getLabel: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs) => {
      return getLabelForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          "percent-between-nodes",
          Immutable.List.of(context.get("nodeId"), callArgs.get(0)));
    },
    fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, args) => {
      return evaluateFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          "percent-between-nodes",
          Immutable.List.of(context.get("nodeId"), args.get(0)));
    }
  }, {
    id: "current-node-percent-to-other",
    getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, cache, args) => {
      return getKpiIdsForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          idToFormula,
          cache,
          "percent-between-nodes",
          Immutable.List.of(args.get(0), context.get("nodeId")));
    },
    getLabel: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs) => {
      return getLabelForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          "percent-between-nodes",
          Immutable.List.of(context.get("nodeId"), callArgs.get(0)));
    },
    fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, args) => {
      return evaluateFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          "percent-between-nodes",
          Immutable.List.of(args.get(0), context.get("nodeId")));
    }
  }]);
