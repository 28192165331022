import React, {useState} from "react";
import Immutable from "immutable";

import {betterMemo} from "js/common/utils/more-memo";
import {useQueryParamChange, useFieldChange, getQueryParam, getQueryType, templateToLabel} from "js/admin/kpis/edit-kpis/utils";

import JsonField from "js/common/views/inputs/formatted-json-field";
import LabelledSelect from "js/common/views/inputs/labelled-select";
import Error from "js/common/views/error";
import {SimpleTest} from "js/admin/kpis/edit-kpis/tabs/test";
import {TextButton} from "js/common/views/inputs/buttons";
import {ParentWarning} from "js/admin/kpis/edit-kpis/warnings";
import ConfigOverview from "js/admin/kpis/edit-kpis/tabs/config-overview";
import currentClient from "js/common/repo/backbone/current-client";

export const TemplatePicker = betterMemo(
    {displayName: "TemplatePicker"},
    ({idToTemplate, templateId, ...props}) => {
      const options = idToTemplate
          .toList()
          .sortBy(t => t.get("name"))
          .map(t => new Immutable.Map({
              label: templateToLabel(t),
              value: t.get("id")
            }));
      return <LabelledSelect
          isMulti={false}
          isClearable={false}
          className="TESTCAFE-metric-template-picker"
          label="Metric Template"
          {...props}
          selectedValue={templateId}
          options={options} />;
    });

export const KpiPicker = betterMemo(
    {displayName: "KpiPicker"},
    ({kpis, kpiId, label, labelStyle, ...props}) => {
      const options = kpis
          .filter(k => !k.get("deleted") || k.get("id") === kpiId)
          .sortBy(k => k.get("name"))
          .map(k => new Immutable.Map({label: k.get("name"), value: k.get("id")}));
      return <LabelledSelect
          isMulti={false}
          clearable={true}
          label={label ? label : "Inherit from Metric"}
          labelStyle={labelStyle}
          {...props}
          selectedValue={kpiId}
          options={options} />;
    });

export const MasterMetricPicker = betterMemo(
    {displayName: "MasterMetricPicker"},
    ({masterMetrics, masterMetricType, ...props}) => {
      const options = masterMetrics
          .sortBy(m => m.get("name"))
          .map(m => new Immutable.Map({label: m.get("name"), value: m.get("type")}));
      return <LabelledSelect
          isMulti={false}
          clearable={true}
          label="Inherit from Master Metric"
          {...props}
          selectedValue={masterMetricType}
          options={options} />;
    });

export const CombineTypePicker = betterMemo(
    {displayName: "CombineTypePicker"},
    ({combineType, ...props}) => {
      const options = Immutable.fromJS([
        {label: "Overwrite", value: "OVERWRITE"},
        {label: "Merge with 'AND'", value: "MERGE_WITH_AND"},
        {label: "Merge with 'OR'", value: "MERGE_WITH_OR"},
        {label: "Complex Merge", value: "COMPLEX_MERGE"}]);
      return <LabelledSelect
          isMulti={false}
          clearable={true}
          label="Inheritance Type"
          {...props}
          selectedValue={combineType}
          options={options} />;
    });

export const GenericEntityPicker = betterMemo(
    {displayName: "GenericEntityPicker"},
    ({genericEntityType, typeToGroupingEntity, ...props}) => {
      const options = typeToGroupingEntity
          .valueSeq()
          .filter(e => e.get("entity").match(/^ENTITY\d+$/))
          .map(e => new Immutable.Map({label: e.get("name"), value: e.get("entity")}));
      return <LabelledSelect
          isMulti={false}
          isClearable={false}
          label="Generic Entity"
          {...props}
          selectedValue={genericEntityType}
          options={options} />;
    });

const InheritedBoolean = betterMemo(
    {displayName: "InheritedBoolean"},
    ({inheritedValue, selectedValue, onChange, ...props}) => {
      const inheritedValueStr = inheritedValue ? "Yes" : "No";
      const options = Immutable.fromJS([
        {label: "Yes", value: "TRUE"},
        {label: "No", value: "FALSE"},
        {label: "Inherit '" + inheritedValueStr + "' from Template", value: "INHERIT"}]);
      let pickerValue;
      if (selectedValue === false) {
        pickerValue = "FALSE";
      } else if (selectedValue) {
        pickerValue = "TRUE";
      } else {
        pickerValue = "INHERIT";
      }
      const handleChange = newPickerValue => {
        let newSelectedValue;
        switch (newPickerValue) {
          case "TRUE":
            newSelectedValue = true;
            break;
          case "FALSE":
            newSelectedValue = false;
            break;
          case "INHERIT":
            newSelectedValue = null;
            break;
          default:
            console.error("Unexpected picker value in InheritedBoolean");
            break;
        }
        onChange(newSelectedValue);
      };
      return <LabelledSelect
          isMulti={false}
          isClearable={false}
          isSearchable={false}
          {...props}
          onChange={handleChange}
          selectedValue={pickerValue}
          options={options} />;
    });

const managedKeyPaths = Immutable.fromJS([
  ["combineWithKpiId"],
  ["combineWithMasterMetricType"],
  ["combineType"],
  ["combineOptions"],
  ["templateId"],
  ["overrideTimeframe"],
  ["overrideTrendable"],
  ["overrideFilterableByClient"],
  ["overrideParams"],
  ["queryParams", "entity"]]).toSet();

const combineOptions = ["combineWithKpiId", "combineWithMasterMetricType", "combineType", "combineOptions"];

// TODO PRECISION these should be loaded from the server (which has the source values for type defaults)
const kpiTypeToDefaultDecimalPlaces = Immutable.Map({
  "NUMBER": 2,
  "CURRENCY": 0,
  "PERCENT": 2
});

const EditConfig = betterMemo(
    {displayName: "GeneralMetricConfigEditor"},
    ({
      kpi,
      combinedKpi,
      parentCombinedKpi,
      combineError,
      submitError,
      typeToGroupingEntity,
      idToTemplate,
      onChange,
      kpiIsParent,
      kpiIsChild,
      testResult,
      onTest,
      rootChangedWarning,
      isCube19User,
      kpisAvailableToCombineWith,
      masterMetricsAvailableToCombineWith,
      kpiIdToMasterKpis,
      requiresExplanationUpdate,
      getExplanationWarning
    }) => {
      const [showConfig, setShowConfig] = useState(false);

      const template = idToTemplate.get(kpi.get("templateId")) || new Immutable.Map();
      const queryType = getQueryType(kpi, idToTemplate);
      const parentQueryType = parentCombinedKpi ? getQueryType(parentCombinedKpi, idToTemplate) : null;

      const valueFormat = template.get("valueFormat");
      const clientDecimalPlaces = currentClient.getDecimalPlaces(valueFormat);
      const defaultDecimalPlaces = kpiTypeToDefaultDecimalPlaces.get(valueFormat, "N/A");
      const defaultDecimalPlaceMessage = clientDecimalPlaces ? "Default (" + clientDecimalPlaces + " from client settings)" : "Default (" + defaultDecimalPlaces + " from type default)";

      const handleParamsChange = useFieldChange(kpi, onChange, "overrideParams");
      const handleTimeframeChange = useFieldChange(kpi, onChange, "overrideTimeframe");
      const handleDecimalPlacesChange = useFieldChange(kpi, onChange, "decimalPlaces");
      const handleTrendableChange = useFieldChange(kpi, onChange, "overrideTrendable");
      const handleClientFilterableChange = useFieldChange(kpi, onChange, "overrideFilterableByClient");
      const handleCombineTypeChange = useFieldChange(kpi, onChange, "combineType");
      const handleCombineOptionsChange = useFieldChange(kpi, onChange, "combineOptions");
      const handleEntityChange = useQueryParamChange(kpi, onChange, "entity");

      const decimalPlaceOptions = () =>{
        let options = [];
        options.push({value: null, label: defaultDecimalPlaceMessage});
        for (let i = 0; i <= 10; i++){
          options.push({value: i, label: i})
        }

        return Immutable.fromJS(options);
      };

      const clearCombineOptions = React.useCallback(() => onChange(kpi.deleteAll(combineOptions)), [kpi, onChange]);
      const handleTemplateChange = React.useCallback(value => {
            if (kpi.get("templateId") !== value) {
              onChange(kpi
                  .set("templateId", value)
                  .deleteAll(combineOptions));
            }
          },
          [kpi, onChange]);
      const handleCombineKpiChange = React.useCallback(
          value => {
            if (kpi.get("combineWithKpiId") === value) {
              return;
            }

            if (value === null) {
              clearCombineOptions();
            } else {
              onChange(kpi
                  .set("combineWithKpiId", value)
                  .update("combineType", type => type ?? "MERGE_WITH_AND"));
            }
          },
          [kpi, onChange, clearCombineOptions]);
      const handleCombineMasterMetricChange = React.useCallback(
          value => {
            if (kpi.get("combineWithMasterMetricType") === value) {
              return;
            }

            if (value === null) {
              clearCombineOptions();
            } else {
              onChange(kpi
                  .set("combineWithMasterMetricType", value)
                  .update("combineType", type => type ?? "MERGE_WITH_AND"));
            }
          },
          [kpi, onChange, clearCombineOptions]);

      const isMasterKpi = kpiIdToMasterKpis.has(kpi.get("id"));
      const masterKpis = isMasterKpi ? kpiIdToMasterKpis.get(kpi.get("id")) : null;
      const kpisWithMatchingFormatToCombineWith = kpisAvailableToCombineWith.filter(k => k.get("id") === kpi.get("combineWithKpiId") || !!k.get("config") === !!kpi.get("config"));

      if (!queryType) {
        return <div style={{padding: "1rem"}}>
          <div style={{display: "flex", marginBottom: "1rem"}}>
            <KpiPicker
                containerStyle={{flex: 1, marginRight: "1rem"}}
                kpis={kpisWithMatchingFormatToCombineWith}
                kpiId={kpi.get("combineWithKpiId")}
                isDisabled={kpi.get("combineWithMasterMetricType")}
                onChange={handleCombineKpiChange} />
            <MasterMetricPicker
                containerStyle={{flex: 1, marginRight: "1rem"}}
                masterMetrics={masterMetricsAvailableToCombineWith}
                masterMetricType={kpi.get("combineWithMasterMetricType")}
                isDisabled={kpi.get("combineWithKpiId")}
                onChange={handleCombineMasterMetricChange} />
            <CombineTypePicker
                containerStyle={{flex: 1}}
                combineType={kpi.get("combineType")}
                hasError={(kpi.get("combineWithKpiId") || kpi.get("combineWithMasterMetricType")) &&
                !kpi.get("combineType")}
                onChange={handleCombineTypeChange} />
          </div>
          {(kpi.get("combineWithKpiId") || kpi.get("combineWithMasterMetricType")) &&
          <div style={{display: "flex", marginBottom: "1rem"}}>
            <JsonField
                label="Combine Options (JSON)"
                value={kpi.get("combineOptions")}
                emptyValue={null}
                onChange={handleCombineOptionsChange}
                style={{flex: 1}} />
          </div>}

          <div style={{display: "flex", marginBottom: "1rem"}}>
            <TemplatePicker
                containerStyle={{flex: 1}}
                idToTemplate={idToTemplate}
                templateId={kpi.get("templateId")}
                onChange={handleTemplateChange} />
          </div>
        </div>;
      } else {
        return (
            <div style={{padding: "1rem"}}>
              {kpiIsParent && <ParentWarning />}
              {requiresExplanationUpdate && getExplanationWarning()}
              {rootChangedWarning && <Error text={rootChangedWarning} type="warn" style={{marginTop: 0}} />}
              {submitError && <Error text={submitError} type="error" style={{marginTop: 0}} />}
              {combineError && <Error text={combineError} type="error" style={{marginTop: 0}} />}
              {(queryType === "GENERIC_ENTITY"
                  && parentQueryType !== "GENERIC_ENTITY"
                  && !kpi.get("readOnlyRootGroupingEntity")) &&
              <Error text="You must pick a generic entity" type="warn" style={{marginTop: 0}} />}
              {(queryType === "FORWARD_REPORT" && !combinedKpi.get("readOnlyRootGroupingEntity")) &&
              <Error text="You must set a return Metric in the forward report config"
                     type="warn"
                     style={{marginTop: 0}} />}
              {isCube19User && !isNaN(kpi.get("id")) &&
              <div style={{display: "flex", marginBottom: "1rem"}}>
                <div style={{float: "left", width: "50%"}}>{"ID: " + kpi.get("id")}</div>
                {isMasterKpi &&
                <div style={{float: "left", width: "50%"}}>
                  <div>{"Master Metric Types: " + masterKpis.map(mk => mk.get("type")).join(", ")}</div>
                </div>
                }
              </div>
              }

              <div style={{display: "flex", marginBottom: "1rem"}}>
                <KpiPicker
                    containerStyle={{flex: 1, marginRight: "1rem"}}
                    kpis={kpisWithMatchingFormatToCombineWith}
                    kpiId={kpi.get("combineWithKpiId")}
                    isDisabled={kpi.get("combineWithMasterMetricType")}
                    onChange={handleCombineKpiChange} />
                <MasterMetricPicker
                    containerStyle={{flex: 1, marginRight: "1rem"}}
                    masterMetrics={masterMetricsAvailableToCombineWith}
                    masterMetricType={kpi.get("combineWithMasterMetricType")}
                    isDisabled={kpi.get("combineWithKpiId")}
                    onChange={handleCombineMasterMetricChange} />
                <CombineTypePicker
                    containerStyle={{flex: 1}}
                    combineType={kpi.get("combineType")}
                    hasError={(kpi.get("combineWithKpiId") || kpi.get("combineWithMasterMetricType")) &&
                    !kpi.get("combineType")}
                    onChange={handleCombineTypeChange} />
              </div>
              {kpi.get("combineType") === "COMPLEX_MERGE" &&
              <div style={{display: "flex", marginBottom: "1rem"}}>
                <JsonField
                    label="Combine Options (JSON)"
                    value={kpi.get("combineOptions")}
                    emptyValue={null}
                    onChange={handleCombineOptionsChange}
                    style={{flex: 1}} />
              </div>}

              <div style={{display: "flex", marginBottom: "1rem"}}>
                <TemplatePicker
                    containerStyle={{flex: 1}}
                    idToTemplate={idToTemplate}
                    templateId={kpi.get("templateId")}
                    onChange={handleTemplateChange} />
                {(queryType === "GENERIC_ENTITY" && parentQueryType !== "GENERIC_ENTITY") && <GenericEntityPicker
                    containerStyle={{flex: 1, marginLeft: "1rem"}}
                    typeToGroupingEntity={typeToGroupingEntity}
                    genericEntityType={getQueryParam(kpi, "entity")}
                    onChange={handleEntityChange} />}
                <div style={{marginLeft: 15, flex: 1}}>
                  <LabelledSelect
                      isMulti={false}
                      clearable={false}
                      label="Decimal Places"
                      placeholder={defaultDecimalPlaceMessage}
                      onChange={handleDecimalPlacesChange}
                      selectedValue={kpi.get("decimalPlaces")}
                      options={decimalPlaceOptions()}/>
                </div>
              </div>

              <div style={{color: "black", display: "flex", marginBottom: "1rem"}}>
                <JsonField
                    label="Override Timeframe (JSON)"
                    value={kpi.get("overrideTimeframe")}
                    emptyValue={new Immutable.Map({type: "NONE"})}
                    onChange={handleTimeframeChange}
                    style={{color: "black", flex: 1, marginRight: "1rem"}} />
                <InheritedBoolean
                    label="Trendable?"
                    containerStyle={{flex: 1, marginRight: "1rem"}}
                    inheritedValue={template.get("trendable")}
                    selectedValue={kpi.get("overrideTrendable")}
                    onChange={handleTrendableChange} />
                <InheritedBoolean
                    label="Filterable by Client?"
                    containerStyle={{flex: 1, marginRight: "1rem"}}
                    inheritedValue={template.get("filterableByClient")}
                    selectedValue={kpi.get("overrideFilterableByClient")}
                    onChange={handleClientFilterableChange} />
              </div>

              <div style={{display: "flex", marginBottom: "1rem"}}>
                <JsonField
                    label="Override Params (JSON)"
                    value={kpi.get("overrideParams") || Immutable.Map()}
                    onChange={handleParamsChange}
                    fullWidth={false}
                    style={{flex: 1, marginRight: "1rem"}} />
                <JsonField
                    label="Original Params (JSON)"
                    value={template.get("params") || Immutable.Map()}
                    disabled={true}
                    fullWidth={false}
                    style={{flex: 1}} />
              </div>

              <div style={{display: "flex", marginBottom: "1rem"}}>
                <SimpleTest
                    style={{marginRight: "1rem"}}
                    testResult={testResult}
                    onTest={onTest} />
                {kpiIsChild && <TextButton
                    label={`${showConfig ? "Hide" : "Show"} Inherited Config`}
                    onClick={() => setShowConfig(prev => !prev)} />}
              </div>

              {kpiIsChild && showConfig &&
              <div style={{display: "flex", marginBottom: "1rem"}}>
                <ConfigOverview
                    columnNameToMap={Immutable.Map({"Value": combinedKpi})}
                    keyPaths={managedKeyPaths}
                    idToTemplate={idToTemplate} />
              </div>}
            </div>);
      }
    });

export default EditConfig;