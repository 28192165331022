import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import SectionHeader from "js/admin/cubetv/section-header";
import EditKpiSummaries from "js/admin/cubetv/trend/edit-kpi-summaries";
import EditTrend from "js/admin/cubetv/trend/edit-trend";

const pathToTrendConfig = ["layout", "left", 0];
const pathToKpiSummaryConfigs = ["layout", "right", 0];

export default createReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        timeframes: ImmutablePropTypes.list.isRequired,
        slide: ReactPropTypes.object.isRequired,
        onChange: ReactPropTypes.func.isRequired
    },

    render() {
        const { slide, timeframes } = this.props;
        const singleDayTimeframes = Immutable
            .fromJS([ "today", "yesterday" ])
            .toSet();
        const trendableTimeframes = timeframes.filter(t => !singleDayTimeframes.includes(t.get("id")));
        return (
            <div style={{ marginTop: "1rem" }}>
                <SectionHeader text="Trend chart - Displays a Metric as time series trends" />
                <EditTrend
                    timeframes={trendableTimeframes}
                    timingInMillis={slide.get("timing")}
                    onTimingChange={this.handleTimingChange}
                    trend={slide.getIn(pathToTrendConfig)}
                    onChange={this.handleTrendConfigChange} />
                <SectionHeader text="Metric Summary - Displays up to 4 Metric summaries below the Trend Chart" />
                <EditKpiSummaries
                    timeframes={trendableTimeframes}
                    kpiSummaries={slide.getIn(pathToKpiSummaryConfigs)}
                    onChange={this.handleKpiSummaryConfigsChange} />
            </div>
        );
    },

    handleTimingChange(timingInMillis) {
        this.props.onChange(this.props.slide.set("timing", timingInMillis));
    },

    handleTrendConfigChange(trend) {
        this.props.onChange(this.props.slide.setIn(pathToTrendConfig, trend));
    },

    handleKpiSummaryConfigsChange(kpiSummaries) {
        this.props.onChange(this.props.slide.setIn(pathToKpiSummaryConfigs, kpiSummaries));
    }

});

