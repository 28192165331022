import store from "store";

import * as ajax from "js/common/ajax";

function checkForValidSession() {
  return ajax.post({
    autoLogout: false,
    url: "auth/has_session"
  });
}

function checkForSSORequired(corpId) {
  return ajax.post({
    autoLogout: false,
    url: "auth/sso/required",
    data: {corpId}
  });
}

function login(username, password) {
  username = username.trim();
  return ajax
      .post({
        url: "auth/login/" + username,
        data: {password},
        retryOnRateLimit: false,
        autoLogout: false
      })
      .then(response => {
        store.remove("ipRestricted");
        return true;
      }, xhr => {
        const response = xhr.responseJSON;
        if (xhr.status === 429) {
          return Promise.reject({
            isError: true,
            type: "RATE_LIMIT",
            message: "Too many failed login attempts, please wait a minute before trying again."
          });
        } else {
          return Promise.reject(response);
        }
      });
}

function changePassword(username, oldPassword, newPassword) {
  return ajax
      .post({
        url: "auth/change-password",
        data: {
          username,
          oldPassword,
          newPassword
        },
        retryOnRateLimit: false,
        autoLogout: false
      })
      .then(
          () => login(username, newPassword),
          xhr => Promise.reject(xhr.responseJSON));
}

function requestPasswordReset(username) {
  return ajax.post({
    url: "auth/request_password_reset2",
    data: {
      username
    }
  })
      .then(xhr => {
        if (xhr.errorName) {
          return Promise.reject({
            message: xhr.longMessage
          });
        }

        return true;
      });
}

function logout() {
  store.set("ignoreLoginUrl", true);
  logoutWithoutRefresh().then(() => {
    // check if can fetch cube
    ajax.isCubeAvailable().then(
        () => {
          window.location.reload(true);
        },
        () => {});
  });
}

function logoutWithoutRefresh() {
  store.remove("ipRestricted");
  return ajax.post({
    url: "auth/logout"
  });
}

function sendAuthCode(code) {
  return ajax.get({
    url: "auth/sso/redirect?code=" + code,
    autoLogout: false
  });
}

export {
  checkForValidSession,
  checkForSSORequired,
  login,
  changePassword,
  requestPasswordReset,
  logout,
  logoutWithoutRefresh,
  sendAuthCode
};
