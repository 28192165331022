import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import Tooltip from "js/common/views/tooltips";
import * as Users from "js/common/users";

import {TextButton as Button, IconButton} from "js/common/views/inputs/buttons";
import {IconButton as UIIconButton} from "@mui/material";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const SavedReportsMenu = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    savedReportsByType: ImmutablePropTypes.map.isRequired,
    onSavedReportClick: ReactPropTypes.func.isRequired,
    onGenerateAsCsvClick: ReactPropTypes.func,
    savedReportIdBeingGeneratedAsCsv: ReactPropTypes.number,
    onRefreshReportsClick: ReactPropTypes.func,
    onShareReportClick: ReactPropTypes.func,
    onRemoveSavedReportClick: ReactPropTypes.func,
    notifierPopupPosition: ReactPropTypes.string,
    containerWidth: ReactPropTypes.number
  },

  render() {
    const {theme} = this.props;
    return (
        <div>
          <div style={headerContainerStyle(theme)}>
            <h1 style={headerStyle(theme)}>
              Saved Slice & Dice Reports
            </h1>
            <div style={{display: "flex", paddingRight: "10px"}}>
              <Tooltip text="Refresh" position="left">
                <UIIconButton
                    style={{...iconButtonStyle, marginRight: 8}}
                    onClick={() => this.props.onRefreshReportsClick()}>
                  <i className="bhi-refresh" style={{color: theme.palette.text.secondary}}></i>
                </UIIconButton>
              </Tooltip>
            </div>
          </div>
          <h2 style={subheaderStyle(theme)}>
            My reports
          </h2>
          {this.renderMenuItems("ownedReports", theme)}
          <h2 style={subheaderStyle(theme)}>
            Shared with me
          </h2>
          {this.renderMenuItems("sharedUserReports", theme)}
          <h2 style={subheaderStyle(theme)}>
            Shared with my group
          </h2>
          {this.renderMenuItems("sharedGroupReports", theme)}
        </div>
    );
  },

  renderMenuItems(reportType, theme) {

    const reportConfigs = this.props.savedReportsByType
        .get(reportType)
        .sortBy(config => config.get("reportName"));
    if (reportConfigs.isEmpty()) {
      return <div style={emptyMenuStyle(theme)}>No reports to view</div>;
    }

    const {
      onSavedReportClick,
      onShareReportClick,
      onGenerateAsCsvClick,
      savedReportIdBeingGeneratedAsCsv,
      onRemoveSavedReportClick
    } = this.props;
    const userPermissions = Users.getCurrentUser().get("permissions");
    const deletableReportTypes = ["ownedReports", "sharedUserReports"];
    const isReportDeleteable = deletableReportTypes.includes(reportType);
    const allowedActionsCount = getCountOfAllowedActions(userPermissions, isReportDeleteable);
    const actionButtonsWidth = (iconButtonWidth * allowedActionsCount) + 16;
    const isGeneratingCsv = !!savedReportIdBeingGeneratedAsCsv;
    const reportNameLimit = 60;
    const maxNameWidth = 480 - actionButtonsWidth;

    return reportConfigs
        .map(config => {
          const isLongName = config.get("reportName").length >= reportNameLimit;
          const reportName = isLongName ? config.get("reportName").substring(0, reportNameLimit) + "..." : config.get(
              "reportName");
          return <div key={config.get("id")} style={menuItemStyle(theme)}>
            <div style={{display: "flex", flexGrow: 1, width: 400}}>
              <Tooltip
                  wrap={true}
                  text={config.get("reportName")}
                  disable={!isLongName}
                  maxWidth={300}
                  position={reportType === "ownedReports" ? "bottom" : "top"}>
                <Button
                    label={reportName}
                    fullWidth
                    labelStyle={{
                      textTransform: "none",
                      padding: "0 8px 0 8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: maxNameWidth
                    }}
                    style={{
                      background: theme.palette.background.card,
                      height: "auto",
                      lineHeight: 1,
                      padding: "11px 0 11px 10px",
                      fontSize: "12px",
                      justifyContent: "flex-start",
                      textAlign: "left"
                    }}
                    onClick={() => onSavedReportClick(config, reportType)} />
              </Tooltip>
            </div>
            <div
                style={{
                  display: "inline-block",
                  flexShrink: 0,
                  width: allowedActionsCount > 0 ? actionButtonsWidth : 0
                }}>
              <div style={{float: "right"}}>
                {Users.currentHasPermission("EXPORT_DIRECT_TO_CSV") &&
                    <div style={{display: "inline-block"}}>
                      <Tooltip text="Generate as CSV" position="top">
                        <IconButton
                            icon={
                              isGeneratingCsv && config.get("id") === savedReportIdBeingGeneratedAsCsv ?
                                  "spinner fa-pulse fa-1x" : "file"}
                            iconStyle={{fontSize: 16}}
                            type="bright"
                            style={iconButtonStyle}
                            disabled={isGeneratingCsv}
                            data-tip
                            data-for="generate-as-csv"
                            onClick={() => onGenerateAsCsvClick(config)}
                            size="large" />
                      </Tooltip>
                    </div>}
                {Users.currentHasPermission("SHARE_SND_REPORT") &&
                    <div style={{display: "inline-block"}}>
                      <Tooltip text="Share" position="top">
                        <IconButton
                            iconType={theme.themeId === "light" ? "bhi" : "fa"}
                            icon={theme.themeId === "light" ? "share" : "share-square-o"}
                            iconStyle={{fontSize: 19}}
                            type="bright"
                            backgroundColor="transparent"
                            style={iconButtonStyle}
                            disabled={isGeneratingCsv}
                            onClick={() => onShareReportClick(config, reportType)}
                            data-tip
                            data-for="share-report"
                            size="large" />
                      </Tooltip>
                    </div>}
                {isReportDeleteable &&
                    <div style={{display: "inline-block", marginRight: 5}}>
                      <Tooltip text="Remove" position="top">
                        <IconButton
                            iconType="bhi"
                            icon="times"
                            iconStyle={{color: "#B71C1C", fontSize: 18}}
                            style={iconButtonStyle}
                            disabled={isGeneratingCsv}
                            onClick={() => onRemoveSavedReportClick(config.set("reportType", reportType))}
                            data-tip
                            data-for="remove-report"
                            size="large" />
                      </Tooltip>
                    </div>}
              </div>
            </div>
          </div>;
        });
  }

});

const headerContainerStyle = theme => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.border.main}`
});

const headerStyle = theme => ({
  fontSize: theme.themeId === "light" ? "20px" : "11px",
  fontWeight: theme.themeId === "light" ? "600" : "400",
  padding: "10px 15px",
  color: theme.palette.text.primary,
  lineHeight: 1
});

const subheaderStyle = theme => ({
  ...headerStyle(theme),
  fontSize: "13px",
  color: theme.palette.primary.main,
  textTransform: "uppercase"
});

const iconButtonWidth = 33;
const iconButtonStyle = {
  padding: 0,
  width: iconButtonWidth,
  height: 24,
  backgroundColor: "transparent"
};

const menuItemStyle = theme => ({
  backgroundColor: theme.palette.background.card,
  borderTop: `1px solid ${theme.themeId === "light" ? "#ccc" : "rgba(0,0,0,0.2)"}`,
  borderBottom: `1px solid ${theme.themeId === "light" ? "#fff" : theme.palette.border.light}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "10px"
});

const emptyMenuStyle = theme => ({
  ...menuItemStyle(theme),
  paddingTop: 10,
  paddingBottom: 10,
  lineHeight: 1.5,
  fontSize: "14px",
  textAlign: "center",
  color: "#bbb"
});

const getCountOfAllowedActions = (userPermissions, isReportDeleteable) => {
  const sndPermissions = new Set(["SHARE_SND_REPORT", "EXPORT_DIRECT_TO_CSV"]);
  const userSnDPermissions = userPermissions.filter(permission => sndPermissions.has(permission.get("name")));
  const userSnDPermissionsCount = userSnDPermissions.length;
  return isReportDeleteable ? userSnDPermissionsCount + 1 : userSnDPermissionsCount;
};


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <SavedReportsMenu theme={theme} {...props} />;
};

SavedReportsMenu.displayName = "SavedReportsMenu";
export default Wrapper;
