import eventBus from "js/cube19.event-bus";
import * as ajax from "js/common/ajax";
import * as auditor from "js/common/auditer";
import * as Immutable from "immutable";

const PubNub = window.PubNub;
const _ = window._;

let pubnub;
let config;

async function load() {
    config = await ajax.get({
        url: "pubnub/config"
    });
    pubnub = new PubNub({
        subscribeKey: config.subscribeKey,
        authKey: config.authKey,
        ssl: true
    });
    eventBus.trigger("data-loaded", "pubnub-config");

    pubnub.addListener(statusListener);
}

function unload() {
    pubnub.removeListener(statusListener);
    pubnub.stop();
}

function getAuthKey() {
    return config.authKey;
}

function connect(channelType, callback) {
    requestReadAccess(channelType).then(channelName => {
        subscribeToChannel(channelName, callback);
    });
}

function disconnect(channelType, callback) {
    requestReadAccess(channelType).then(channelName => {
        unsubscribeFromChannel(channelName, callback);
    });
}

async function requestReadAccess(channelType) {
    const response = await ajax.get({
        url: "pubnub/auth/" + channelType,
        data: {
            authKey: config.authKey
        }
    });
    await delay(2500);
    return response.channel;
}

function delay(timeInMillis) {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, timeInMillis);
    });
}

/*
 * Prevent lots of requests for authorisation
 * debounce() limits requests to once every 3 seconds
 * after() stops individual temporary errors causing a request
 */
const audited = channelType => {
    requestReadAccess(channelType)
        .then(channelName => {
            audit("pubnub:reauth-after-error", channelName);
        });
};
const debounced = _.debounce(audited, 3000, true);
const safeRequestReadAccess = _.after(10, debounced);
const statusListener = {
    status: statusEvent => {
        if (statusEvent.category === "PNConnectedCategory") {
            auditor.audit("pubnub:subscribed", {
                channelNames: statusEvent.affectedChannels,
                authKey: config.authKey
            });
            statusEvent.affectedChannels.forEach(name => {
                const nameSplit = name.split(":");
                const channelType = nameSplit[nameSplit.length - 1];
                scheduleAuthKeyRenewal(channelType, name);
            });
        } else if (statusEvent.error && statusEvent.errorData.payload) { // Error status handling
            auditor.audit("pubnub:subscribe-error", {
                statusEventCategory: statusEvent.category,
                channelNames: statusEvent.errorData.payload.channels,
                authKey: config.authKey
            });
            statusEvent.errorData.payload.channels.forEach(name => {
                const nameSplit = name.split(":");
                const channelType = nameSplit[nameSplit.length - 1];
                safeRequestReadAccess(channelType);
            });
        }
    }
};

let channelToOriginalCallback = Immutable.Map();

function subscribeToChannel(channelName, callback) {
    if (callback) {
        channelToOriginalCallback = channelToOriginalCallback.set(channelName, callback);
        pubnub.addListener({
            message: body => {
                if (body.channel === channelName) {
                    callback(body);
                }
            }
        });
    }

    pubnub.subscribe({channels: [channelName]});
}

function unsubscribeFromChannel(channelName) {
    const callback = channelToOriginalCallback.get(channelName);
    if (callback) {
        pubnub.removeListener({
            message: callback
        });
    }

    audit("pubnub:unsubscribed", channelName);
    pubnub.unsubscribe({channels: [channelName]});
}

function scheduleAuthKeyRenewal(channelType) {
    const every12Hours = 12 * 3600 * 1000;
    setInterval(() => {
        requestReadAccess(channelType)
            .then(channelName => {
                audit("pubnub:reauth-on-schedule", channelName);
            });
    }, every12Hours);
}

function audit(category, channelName) {
    auditor.audit(category, {
        channelName: channelName,
        authKey: config.authKey
    });
}

let commandToListeners = Immutable.Map();
let listenerId = 0;
const getListenerId = () => {
    listenerId = listenerId + 1;
    return listenerId;
};

function onCommand(message) {
    message = JSON.parse(message.message);

    commandToListeners.get(message.command, Immutable.List())
        .forEach(object => object.get("listener")(message));
}

function registerCommandListener(command, listener) {
    const id = getListenerId();
    commandToListeners = commandToListeners.update(command, (list = Immutable.List()) =>
        list.push(Immutable.Map({id, listener})));
    return id;
}

function deregisterCommandListener(listenerId) {
    commandToListeners.forEach((objects, command) => {
        const index = objects.findIndex(object => object.get("id") === listenerId);
        if (index !== -1) {
            commandToListeners = commandToListeners.update(command, list => list.delete(index));
            return false;
        }
    });
}

export {
    load,
    unload,
    getAuthKey,
    connect,
    disconnect,
    onCommand,
    registerCommandListener,
    deregisterCommandListener
};
