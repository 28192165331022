import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const emptyMap = {};

const ErrorBox = React.memo(({
    text = "",
    type = "error",
    style = emptyMap,
    theme,
    children
}) => {
    const typeToStyle = {
        error: errorStyle(theme),
        warn: warningStyle(theme)
    };
    return (
        <span className="error" style={{ ...baseStyle(theme), ...typeToStyle[type], ...style }}>
            <i className="fa fa-exclamation-triangle" style={{ display: "table-cell", paddingRight: 8 }} />
            <span style={{ display: "table-cell" }}>{text || children}</span>
        </span>
    );
});

const baseStyle = (theme) => ({
    fontSize: "0.75rem",
    fontWeight: "bold",
    letterSpacing: theme.themeId === "light" ? 0 : "2px",
    lineHeight: 1.25,
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    padding: "5px 10px"
});

const errorStyle = (theme) => ({
    backgroundColor: theme.themeId === "light" ? theme.palette.error.main : "#750000",
    border: theme.themeId === "light" ? "none" : "1px solid #ff8d00",
    borderRadius: theme.themeId === "light" ? 4 : 0
});

const warningStyle = (theme) => ({
    backgroundColor: theme.themeId === "light" ? theme.palette.warning.main : "#5f4c03",
    border: theme.themeId === "light" ? "none" : "1px solid #ff8d00",
    borderRadius: theme.themeId === "light" ? 4 : 0
});


export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <ErrorBox theme={theme} {...props} />;
};
