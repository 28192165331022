import React from "react";
import Immutable from "immutable";
import ReactTooltip from "react-tooltip";

import * as DashboardUtils from "js/dashboards/utils";
import {fullReloadForTrendChart} from "js/charting/trend/data-loader";
import ConfigurableTrendChart from "js/charting/trend/configurable-trend-chart";
import * as Rata from "js/common/utils/remote-data";
import * as RatioRepo from "js/common/repo/ratio-repo";
import DataConfigToolbar from "js/dashboards/data-config-toolbar";

import {between} from "js/common/utils/numbers";
import moment from "moment";
import {getThemeColorsList} from "js/common/colors-list";
import DataAggregationPicker from "js/charting/trend/data-aggregation-picker";
import {getDataAggregations} from "js/charting/trend/data-aggregator";
import {RadioGroup as RadioButtonGroup} from "@mui/material";
import RadioButton from "js/common/views/inputs/radio-button";
import RatioForm from "js/dashboards/components/trends/ratio-form";
import MetricForm from "js/dashboards/components/trends/metric-form";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Trend = React.memo(({
  componentId,
  dimensions,
  data,
  config
}) => {
  const jsConfig = React.useMemo(() => config.toJS(), [config]);
  const erroringTrends = Rata.getValue(data).trends.filter(trend => trend.error);
  const {theme} = React.useContext(CustomThemeContext);
  const errorTooltipId = `tooltip-error-${componentId}`;
  const trendType = config.get("trendValueType") || "metric";
  return (
      <>
        {erroringTrends.length > 0 && <div style={{
          background: theme.palette.error.background,
          color: theme.palette.error.main,
          marginLeft: 10,
          display: "flex",
          zIndex: 999,
          alignItems: "center",
          borderRadius: 3,
          padding: 5,
          width: "calc(100% - 20px)"
        }}>
          <i className="bhi-caution" />
          <ul style={{margin: "0 0 0 5px", padding: 0}}>
            <li style={{fontSize: 13, listStyle: "none"}}>
              <span data-for={errorTooltipId} data-tip="" style={{display: "inline", whiteSpace: "nowrap", borderBottom: "1px dashed red"}}>{erroringTrends.length} {trendType}{erroringTrends.length > 1 ? "s" : ""}</span>
              <span style={{display: "inline"}}>&nbsp;cannot be displayed on the trend chart. Please select different {trendType}s.</span>
            </li>
            <ReactTooltip
                textColor={theme.palette.text.inverted}
                backgroundColor={theme.palette.background.inverted}
                place="bottom"
                effect="solid"
                id={errorTooltipId}>
              <ul style={{margin: 0, padding: 0}}>
                {erroringTrends.map(trend => <li>{trend.error.message}</li>)}
              </ul>
            </ReactTooltip>
          </ul>
        </div>}
        <ConfigurableTrendChart
            config={jsConfig}
            trendType="ADVANCED"
            data={Rata.getValue(data)}
            rangeEnabled={false}
            zoomEnabled={false}
            yAxisEnabled={false}
            exportEnabled={false}
            height={dimensions.height - (erroringTrends.length > 0 ? 30 : 0)}
            width={dimensions.width} />
      </>
  );
});

const TrendEditor = React.memo(({
  component,
  onComponentChange
}) => {
  const dataConfig = component.get("dataConfig");
  const [type, setType] = React.useState(dataConfig.get("trendValueType") || "ratio");
  const onDataConfigChange = React.useCallback(dataConfig => {
    onComponentChange(component.set("dataConfig", dataConfig));
  }, [onComponentChange, component]);


  const aggregation = dataConfig.get("dataAggregation") || getInitialDataAggregation(dataConfig.get("timeframe"));

  return <div style={{overflowY: "auto", height: "calc(100% - 130px)", padding: "0.5rem"}}>
    <DataConfigToolbar config={dataConfig} onConfigChange={onDataConfigChange} />
    <h3 style={{fontSize: "1rem", margin: "1.5rem 0 1rem 0", textTransform: "capitalize"}}>What would you like to trend?</h3>
    <RadioButtonGroup
        name="selectionType"
        value={type}
        onChange={e => {
          setType(e.target.value);
          onDataConfigChange(dataConfig.set("trendValueType", e.target.value));
        }}>
      <div style={{display: "flex"}}>
        <RadioButton
            value="metric"
            label="Metrics" />
        <RadioButton
            value="ratio"
            label="Ratios" />
      </div>
    </RadioButtonGroup>
    <h3 style={{fontSize: "1rem", margin: "1.5rem 0 1rem 0", textTransform: "capitalize"}}>{type} Options</h3>
    <div style={{marginBottom: "1rem"}}>
      <DataAggregationPicker
          dataAggregations={getDataAggregations(aggregation)}
          value={aggregation}
          onChange={dataAggregation => onDataConfigChange(dataConfig.set("dataAggregation", dataAggregation))}
          style={{width: 235}} />
    </div>
    {type === "ratio" ? <RatioForm dataConfig={dataConfig} onDataConfigChange={onDataConfigChange} /> : <MetricForm
        dataConfig={dataConfig}
        onDataConfigChange={onDataConfigChange} />}
  </div>;
});

const getInitialDataAggregation = (timeframe) => {
  const start = moment(timeframe.start);
  const end = moment(timeframe.end);
  const noOfDays = end.diff(start, "days") + 1;
  let dataAggregation;

  if (between(noOfDays, 1, 31)) {
    dataAggregation = "DAILY";
  }
  if (between(noOfDays, 31, 180)) {
    dataAggregation = "WEEKLY";
  }
  if (between(noOfDays, 180, 365 * 2)) {
    dataAggregation = "MONTHLY";
  }
  if (between(noOfDays, 365 * 2, 365 * 5)) {
    dataAggregation = "QUARTERLY";
  }
  if (noOfDays > 365 * 5) {
    dataAggregation = "YEARLY";
  }

  return dataAggregation;
};

const getDefaultRatiosDataGroup = config => {
  const dataGroupIndex = 0;
  const howToPlot = config.displayDataCumulatively ? "cumulative" : "non-cumulative";

  const getDefaultColour = (trendIndex, dataGroupIndex = 0) => {
    const coloursList = getThemeColorsList(dataGroupIndex);
    return coloursList[trendIndex % coloursList.length];
  };

  const metricTrendConfigs = config.kpiIds.map((kpiId, index) => {
    return {
      cid: Math.random(),
      type: "KPI",
      kpiId: kpiId,
      whatToPlot: config.averageByUser ? "average-by-user" : "value",
      seriesColour: getDefaultColour(index, dataGroupIndex),
      lineStyle: "Solid",
      dataPointMarker: null,
      howToPlot,
      yAxis: 0
    };
  });

  const ratioTrendConfigs = config.ratiosList.map((r, index) => {
    const ratio = RatioRepo.get(r.id).toJS();
    return {
      cid: Math.random(),
      type: "RATIO",
      ratio: ratio,
      whatToPlot: config.averageByUser ? "average-by-user" : "value",
      seriesColour: getDefaultColour(index, dataGroupIndex),
      lineStyle: "Solid",
      dataPointMarker: null,
      howToPlot,
      yAxis: 0
    };
  });

  return [
    {
      cid: Math.random(),
      name: "",
      timeframe: config.timeframe,
      trendConfigs: config.trendValueType === "ratio" ? ratioTrendConfigs : metricTrendConfigs,
      qualifierType: config.qualifier.type,
      qualifierId: config.qualifier.id,
      clientIds: config.clientFilter.allClientIds,
      matchAnyTagIds: config.tagFilter.matchAnyTagIds,
      matchAllTagIds: config.tagFilter.matchAllTagIds,
      excludedTagIds: config.tagFilter.excludedTagIds,
      dateDisplay: "ABSOLUTE"
    }];
};

export default Immutable.fromJS({
  type: "Trend",
  label: "Trend",
  getDefaultData: () => ({isLoading: false, trends: []}),
  getReactComponent: () => Trend,
  getEditorReactComponent: () => TrendEditor,
  getTitle: component => component.get("title"),
  canFullScreen: true,
  variant: "scrollable",
  layout: {
    min: {width: 14, height: 8},
    max: {width: 48, height: 24},
    init: {width: 24, height: 12}
  },
  applyParentConfig: (parentConfig, dataConfig) => DashboardUtils.applyInheritanceForKeys(parentConfig, dataConfig),
  load: component => {
    const config = component.get("dataConfig").toJS();
    const chartConfig = {
      ...config,
      trendType: "ADVANCED",
      dataAggregation: config.dataAggregation || getInitialDataAggregation(config.timeframe),
      dataGroups: getDefaultRatiosDataGroup(config)
    };
    const {promiseForFinalData} = fullReloadForTrendChart(chartConfig, false, true);
    return promiseForFinalData;
  }
});
