// We need to make some event listeners non-passive. Amongst over things this massively improves scroll performance..
// to do so have to check that browsers support EventListenerOptions in the first place:
// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support

let passiveSupported = false;

try {
  const options = {
    get passive() {
      passiveSupported = true;
      return false;
    }
  };
  window.addEventListener("test", null, options);
  window.removeEventListener("test", null, options);
} catch(err) {
  console.log("passive events not supported in the browser", err.message);
  passiveSupported = false;
}

function makePassiveEventOption(passive) {
  return passiveSupported ? { passive } : passive;
}

export default makePassiveEventOption;