import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";
import onClickOutside from "react-onclickoutside";
import classes from "classnames";
import Tooltip from "js/common/views/tooltips";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import Layout from "js/common/views/foundation-column-layout";

import * as Styles from "js/common/views/styles/react-dropdown-toggle.styles";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const DropdownToggle = onClickOutside(createReactClass({
  displayName: "DropdownToggle",

  mixins: [PureRenderMixin],

  propTypes: {
    children: ReactPropTypes.node.isRequired,
    primaryText: ReactPropTypes.string.isRequired,
    secondaryText: ReactPropTypes.string,
    isDisabled: ReactPropTypes.bool,
    isOpen: ReactPropTypes.bool,
    onToggleButtonClick: ReactPropTypes.func,
    onRequestClose: ReactPropTypes.func,
    tooltipDisabled: ReactPropTypes.bool,
    disableEllipsis: ReactPropTypes.bool,
    tooltipText: ReactPropTypes.string
  },

  render() {
    const {
      primaryText,
      secondaryText,
      isDisabled,
      children,
      isOpen,
      theme,
      tooltipDisabled,
      disableEllipsis = false,
      tooltipText
    } = this.props;
    return (
        <div style={{cursor: isDisabled ? "not-allowed" : "pointer"}}>
          <ToggleButton
              theme={theme}
              primaryText={primaryText}
              secondaryText={secondaryText}
              icon={isOpen ? "sort-asc" : "sort-desc"}
              isDisabled={isDisabled}
              disableEllipsis={disableEllipsis}
              tooltipText={tooltipText}
              tooltipDisabled={tooltipDisabled}
              onClick={this.props.onToggleButtonClick} />
          <Layout collapseRow={true} allSmall={12}>
            <VelocityTransitionGroup
                component="div"
                enter="slideDown"
                leave="slideUp"
                style={isOpen ? {...dropdownStyle(theme), ...expandedDropdownStyle} : dropdownStyle(theme)}>
              {isOpen && children}
            </VelocityTransitionGroup>
          </Layout>
        </div>
    );
  },

  handleClickOutside() {
    this.props.isOpen && this.props.onRequestClose();
  }

}));

const ToggleButton = pure(({
  primaryText = "",
  secondaryText = "",
  icon,
  isOpen,
  isDisabled,
  onClick,
  theme,
  tooltipDisabled,
  disableEllipsis,
  tooltipText
}) => {
  const borderStyle = {border: `2px solid ${theme.palette.background.paper}`};
  const overflowStyle = disableEllipsis ? standardOverflowStyle : ellipsisOverflowStyle;
  const textForTooltip = tooltipText ? tooltipText : `${primaryText} ${secondaryText}`;
  return <div
      onClick={isDisabled ? () => {} : onClick}
      style={isDisabled
          ? disabledContainerStyle(theme)
          : {...borderStyle, borderRadius: 4, background: theme.palette.background.card, cursor: "pointer"}}
      className={`toggleButtonEl${isOpen ? " isOpen" : ""}`}>
    <Tooltip styleType={theme.themeId} text={textForTooltip} position="top" disable={tooltipDisabled}>
      <Layout
          collapseRow={true}
          small={[11, 1]}
          rowStyle={Styles.toggleStyle(theme)}>

        <div style={{...overflowStyle, color: theme.palette.text.main}}>
          {primaryText}
          {secondaryText && <span style={secondaryTextStyle}>{secondaryText}</span>}
        </div>
        <i className={classes("right", `${icon ? `bhi-${icon}` : ""}`)} style={{lineHeight: 1.5, fontSize: 15}} />
      </Layout>
    </Tooltip>
  </div>;
}, "ToggleButton");

const disabledContainerStyle = theme => ({
  opacity: 0.6,
  pointerEvents: "none",
  cursor: "not-allowed",
  borderRadius: "4px",
  background: theme.themeId === "light" ? "#eeeeee" : "#272731",
  border: `2px solid ${theme.themeId === "light" ? "#eeeeee" : "#272731"}`
});

const standardOverflowStyle = {
  overflowX: "hidden",
  whiteSpace: "nowrap"
};

const ellipsisOverflowStyle = {
  overflowX: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
};

const secondaryTextStyle = {
  paddingLeft: 10,
  paddingRight: 10
};

const dropdownStyle = (theme) => ({
  position: "absolute",
  zIndex: 99999,
  width: "100%",
  background: theme.palette.background.card,
  fontSize: "12px"
});
const expandedDropdownStyle = {
  boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)"
};


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <DropdownToggle theme={theme} {...props} />;
};

export default Wrapper;
