import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import ReactHighstock from "react-highcharts/ReactHighstock";
import moment from "moment";

import * as numbers from "js/common/utils/numbers";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import getHighchartsConfig from "js/common/highcharts-config";
import { setC19Theme } from "js/common/setup-highcharts-theme";
import { getAdvancedTrendSeriesName } from "js/charting/trend/display-name-util";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const _ = window._;

const TrendChart = createReactClass({

    propTypes: {
        trends: ReactPropTypes.array,
        config: ReactPropTypes.object.isRequired,
        startDate: ReactPropTypes.object.isRequired,
        endDate: ReactPropTypes.object.isRequired,
        theme: ReactPropTypes.object
    },

    shouldComponentUpdate(nextProps) {
        return this.props.trends !== nextProps.trends;
    },

    render() {
        const { trends, startDate, endDate, config, theme } = this.props;
        const { dataAggregation, trendType } = config;
        const xAxisSettings = {
            minRange: 0.1,
            type: "datetime",
            ordinal: false,
            dateTimeLabelFormats: {
                millisecond: "%H:%M:%S.%L",
                second: "%H:%M:%S",
                minute: "%H:%M",
                hour: "%H:%M",
                day: "%e %b \'%y",
                week: "%e %b \'%y",
                month: "%b \'%y",
                year: "%Y"
            }
        };
        // TODO JC - what on earth is happening with these names? trends, trendData, trendWrapper, trendConfig?
        const rangeSelectorButtons = getRangeSelectorButtons(startDate, endDate);
        const yAxisSettings = trendType === "ADVANCED" ? getAdvancedChartYAxes(trends) : Y_AXIS_DEFAULTS;
        const seriesData = trends.map((trendWrapper, index) => toHighChartSeries(
            index,
            trendWrapper,
            trendType,
            dataAggregation,
            yAxisSettings));

        setC19Theme(theme);

        const highChartsConfig = getHighchartsConfig(TREND_DEFAULTS(theme), xAxisSettings, {
            chart: {
                spacingRight: 40
            },
            rangeSelector: {
                selected: rangeSelectorButtons.length - 1,
                inputEnabled: false,
                buttons: rangeSelectorButtons
            },
            navigator: {
                enabled: xAxisSettings
            },
            xAxis: xAxisSettings,
            yAxis: yAxisSettings,
            series: seriesData
        });

        return <ReactHighstock config={highChartsConfig} isPureConfig={true} />;
    }

});

const getYAxisLabel = (yAxisIndex = 0, yAxisSettings) => {
    const yAxis = yAxisSettings[yAxisIndex];
    return yAxis.title ? ` (${yAxis.title.text})` : "";
};

const toHighChartSeries = (trendIndex, trendWrapper, trendType, dataAggregation, yAxisSettings) => {
    let seriesName;
    let color;
    let dashStyle;
    let yAxis;
    let headerFormat;

    switch (trendType) {
    case "ADVANCED":
        const trendConfig = trendWrapper.trendConfig;
        const { howToPlot, whatToPlot, lineStyle, yAxis } = trendConfig;
        dashStyle = lineStyle;
        color = trendConfig.seriesColour;
        const yAxisLabel = getYAxisLabel(yAxis, yAxisSettings);
        const rootName = trendWrapper.rootTrendName;
        seriesName = `${getAdvancedTrendSeriesName(rootName, whatToPlot, howToPlot)} ${yAxisLabel}`;
        break;
    case "STANDARD":
        seriesName = kpiRepo.get(trendWrapper.kpiId).get("name");
        break;
    default:
        throw new Error("Unsupported Trend Type: " + trendType);
    }

    return {
        type: "spline",
        data: mapTrendDataToAbsoluteDate(trendWrapper.trend),
        yAxis,
        name: seriesName,
        color,
        dashStyle,
        dataGrouping: {
            approximation: "sum"
        },
        tooltip: {
            followPointer: true,
            headerFormat,
            xDateFormat: xDateFormats[dataAggregation],
            pointFormatter() {
                const point = this;
                return getTooltipTemplate(point.color, point.series.name, point.y);
            }
        }
    };
};

const mapTrendDataToAbsoluteDate = trendData => trendData.map(item => [toUTC(item.date), item.value]);
const toUTC = date => Date.UTC(date.year(), date.month(), date.date());

const getTooltipTemplate = (color, name, value) => {
    return "<span style=\"color:" + color + "\">\u25CF</span> " + name + ": <b>" + numbers.roundTo(value,2) + "</b><br/>";
};

const getRangeSelectorButtons = (start, end) => {
    const daysBetweenStartAndEndDate = end.diff(start, "days") + 1;
    const rangeSelectors = [];

    if (daysBetweenStartAndEndDate > 1) {
        rangeSelectors.push({
            type: "day",
            count: 1,
            text: "1day"
        });
    }
    if (daysBetweenStartAndEndDate > 8) {
        rangeSelectors.push({
            type: "day",
            count: 7,
            text: "7day"
        });
    }
    if (daysBetweenStartAndEndDate > 32) {
        rangeSelectors.push({
            type: "day",
            count: 32,
            text: "30day"
        });
    }
    if (daysBetweenStartAndEndDate > 91) {
        rangeSelectors.push({
            type: "day",
            count: 90,
            text: "90day"
        });
    }

    const TODAY = moment();
    const DAYS_IN_LAST_6_MONTHS = TODAY.diff(moment().subtract(7, "months"), "days");
    const DAYS_IN_LAST_12_MONTHS = TODAY.diff(moment().subtract(13, "months"), "days");
    if (daysBetweenStartAndEndDate > DAYS_IN_LAST_6_MONTHS) {
        const Months6 = 6;
        const Months6Text = "6mth";
        rangeSelectors.push({
            type: "month",
            count: Months6,
            text: Months6Text
        });
    }
    if (daysBetweenStartAndEndDate > DAYS_IN_LAST_12_MONTHS) {
        const Months12 = 12;
        const Months12Text = "12mth";
        rangeSelectors.push({
            type: "month",
            count: Months12,
            text: Months12Text
        });
    }

    rangeSelectors.push({
        type: "all",
        text: "All"
    });

    return rangeSelectors;
};

const TREND_DEFAULTS = theme => ({
    chart: {
        height: 550,
        pinchType: null // temp workaround for tooltips after zooming: https://github.com/highcharts/highcharts/issues/3905
    },
    legend: {
        enabled: true,
        align: "center",
        borderColor: "transparent",
        borderWidth: 0
    },
    exporting: {
        buttons: {
            contextButton: {
                menuItems: [{
                    text: "Download Trend Chart (.jpeg)",
                    onclick: function () {
                        this.exportChart({
                            type: "image/jpeg"
                        });
                    }
                },
                {
                    text: "Download Trend Chart (.pdf)",
                    onclick: function () {
                        this.exportChart({
                            type: "application/pdf"
                        });
                    }
                },
                {
                    text: "Download Trend Chart (vector)",
                    onclick: function () {
                        this.exportChart({
                            type: "image/svg+xml"
                        });
                    }
                }]
            }
        },
        chartOptions: {
            chart: {
                backgroundColor: theme.palette.background.card,
                events: {
                    load: function() {
                        this.legend.itemStyle = {
                            fontSize: ".6em",
                            paddingBottom: "15px"
                        };
                        this.series.forEach(s => {
                            return s.update({
                                dataLabels: {
                                    enabled: false,
                                    formatter: function() {
                                        if (this.y > 0) {
                                            return this.y
                                        }
                                    }
                                }
                            });
                        });
                    }
                }
            },
            rangeSelector: {
                enabled: false
            },
            navigator: {
                enabled: false
            },
            scrollbar: {
                liveRedraw: true,
                enabled: true
            },
        },
        scale: 2,
        fallbackToExportServer: false
    }
});

const getAdvancedChartYAxes = trends => {
    const yAxesInTrendConfig = trends.map(t => t.trendConfig.yAxis || 0);
    const uniqueYAxesSet = new Set(yAxesInTrendConfig);

    const yAxisTitleDefaults = {
        style: { fontSize: "12px", fontWeight: "bold" },
        rotation: 0
    };
    const axesByIndex = {
        0: {
            ...Y_AXIS_DEFAULTS,
            order: 0,
            opposite: false,
            title: {
                ...yAxisTitleDefaults,
                text: "L"
            }
        },
        1: {
            ...Y_AXIS_DEFAULTS,
            order: 1,
            opposite: true,
            title: {
                ...yAxisTitleDefaults,
                text: "R1"
            }
        },
        2: {
            ...Y_AXIS_DEFAULTS,
            order: 2,
            opposite: true,
            title: {
                ...yAxisTitleDefaults,
                text: "R2"
            }
        }
    };

    const LEFT_YAXIS_INDEX = 0;
    const leftYAxis = Object.assign({}, axesByIndex[LEFT_YAXIS_INDEX]);
    const RIGHT1_YAXIS_INDEX = 1;
    const right1YAxis = Object.assign({}, axesByIndex[RIGHT1_YAXIS_INDEX]);
    const RIGHT2_YAXIS_INDEX = 2;
    const maxYAxisIndex = Math.max(...[...uniqueYAxesSet]);
    let minRequiredYAxesForHighCharts = [];
    uniqueYAxesSet.forEach(yAxisIndex => {
        minRequiredYAxesForHighCharts.push(axesByIndex[yAxisIndex]);
    });
    if (maxYAxisIndex === RIGHT1_YAXIS_INDEX && minRequiredYAxesForHighCharts.length === 1) {
        delete leftYAxis.title;
        minRequiredYAxesForHighCharts.push(leftYAxis);
    } else if (maxYAxisIndex === RIGHT2_YAXIS_INDEX) {
        if (!uniqueYAxesSet.has(LEFT_YAXIS_INDEX)) {
            delete leftYAxis.title;
            minRequiredYAxesForHighCharts.push(leftYAxis);
        }
        if (!uniqueYAxesSet.has(RIGHT1_YAXIS_INDEX)) {
            delete right1YAxis.title;
            minRequiredYAxesForHighCharts.push(right1YAxis);
        }
    }
    return _(minRequiredYAxesForHighCharts).sortBy("order");
};

const Y_AXIS_DEFAULTS = {
    gridLineDashStyle: "Dot"
};

const xDateFormats = {
    "WEEKLY"                : "Week from %A, %e %b %Y",
    "QUARTERLY"             : "Quarter from %b %Y",
    "FINANCIAL_QUARTERLY"   : "Financial Quarter from %b %Y",
    "FINANCIAL_YEARLY"      : "Financial Year from %b %Y"
};



const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <TrendChart theme={theme} {...props} />;
};
  
export default Wrapper;
