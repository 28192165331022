import currentClient from "js/common/repo/backbone/current-client";
import Checkbox from "js/common/views/inputs/checkbox";
import React from "react";
import * as clientRepo from "js/common/repo/backbone/current-client";
import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";
import * as auditer from "js/common/auditer";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

export const ExplanationSettings = () => {

  const [areExplanationsEnabled, setAreExplanationsEnabled] = React.useState(currentClient.areExplanationsEnabled());
  const {theme} = React.useContext(CustomThemeContext);

  const handleUpdate = e => {
    currentClient.save({explanationsEnabled: e.target.checked}, {wait: true});
    clientRepo.load();
    setAreExplanationsEnabled(e.target.checked);
    const state = e.target.checked ? "enabled" : "disabled";
    auditer.audit("client:explanations-" + state);
  };

  return <div>
    <AdminHeader>Publish metric explanations</AdminHeader>
    <Hint style={{margin: "1rem"}}>
      <Icon icon="info" style={{color: theme.palette.hints.text}} />
      Metric explanations provide details of what a Metric does and are visible in click-throughs and Metric Admin.  Default explanations are provided for Master Metrics.  Explanations can be reviewed, edited and added in Metric Admin.
    </Hint>
    <div style={{display: "inline-block", width: 276, margin: "0.25rem 1rem"}}>
      <Checkbox
          label="Enable Metric Explanations"
          checked={areExplanationsEnabled}
          onCheck={handleUpdate} />
    </div>
  </div>;
};