import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import moment from "moment";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";
import {parseDateTime} from "js/common/utils/time";
import * as Users from "js/common/users";

const NotesSummary = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    notes: ImmutablePropTypes.list.isRequired,
    viewType: ReactPropTypes.string,
    theme: ReactPropTypes.object
  },

  getDefaultProps() {
    return {
      viewType: "FULL"
    };
  },

  render() {
    const SummaryView = summaryViewByType[this.props.viewType];
    return <SummaryView notes={this.props.notes} theme={this.props.theme}/>;
  }

});

const CompactSummaryView = pure(({notes, theme}) => (
    <div style={{color: theme.palette.text.secondary}}>
      {(notes.isEmpty() && Users.canAccessApp(Users.getCurrentUser(), "TARGET_ADMIN")) ?
          <AddNoteIcon /> : <i className="bhi-note" style={{fontSize: "1rem", paddingLeft: "0.5rem"}} />}
    </div>
));

const FullSummaryView = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    notes: ImmutablePropTypes.list.isRequired,
    theme: ReactPropTypes.object
  },

  render() {
    const theme = this.props.theme;
    return <div style={{
      display: "flex",
      height: "100%",
      flexDirection: "row",
      alignItems: "center",
      color: theme.palette.text.primary
    }}>{this.props.notes.isEmpty() ? this.renderEmptyStateView(this.props.theme) : this.renderNotes()}</div>;
  },

  renderEmptyStateView(theme) {
    const loggedInUser = Users.getCurrentUser();
    return (
        <div style={{fontSize: "0.9rem", color: theme.palette.text.disabled, paddingTop: 5}}>
          {Users.canAccessApp(loggedInUser, "TARGET_ADMIN") ?
              <div><AddNoteIcon style={{paddingRight: 5}} />Add a note</div> :
              <div>No notes to view</div>}
        </div>);
  },

  renderNotes() {
    const sortedNotes = this.props.notes.sortBy(note => parseDateTime(note.get("createdDate")));
    const iconStyle = {paddingLeft: "0.5rem", paddingRight: 5};
    if (sortedNotes.count() > 3) {
      const earliestNote = sortedNotes.first().get("note");
      const mostRecentNote = sortedNotes.last().get("note");
      return (
          <ul style={{marginBottom: 0}}>
            <li style={noteStyle}>
              <i className="bhi-note" style={iconStyle} />
              {limitNoteLength(localiseDates(earliestNote))}
            </li>
            <li style={noteStyle}>
              <i className="bhi-notes" style={iconStyle} />
              {`${sortedNotes.count() - 2} more`}
            </li>
            <li style={noteStyle}>
              <i className="bhi-note" style={iconStyle} />
              {limitNoteLength(localiseDates(mostRecentNote))}
            </li>
          </ul>);
    } else {
      return <ul style={{marginBottom: 0}}>{sortedNotes.map((noteWrapper, index) => (
          <li key={index} style={noteStyle}>
            <i className="bhi-note" style={iconStyle} />
            {limitNoteLength(localiseDates(noteWrapper.get("note")))}
          </li>
      ))}</ul>;
    }
  }
});

const limitNoteLength = noteStr => noteStr.length < 45 ? noteStr : noteStr.substring(0, 45) + "...";

const localiseDates = noteStr => noteStr.replace(/DATE\([\d-]+\)/g, val => moment(val, "YYYY-MM-DD").format("L"));

const AddNoteIcon = pure(({style = {}}) => (
    <span style={style}>
        <i className="bhi-add-note" />
    </span>
));

const summaryViewByType = {
  COMPACT: CompactSummaryView,
  FULL: FullSummaryView
};

const noteStyle = {
  fontSize: "0.9rem",
  overflow: "hidden",
  textOverflow: "ellipsis",
  lineHeight: 1.3
};

export default NotesSummary;
