import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Immutable from "immutable";

const DashboardFallback = React.memo(({
  variant,
}) => {
  const {theme} = React.useContext(CustomThemeContext);

  const panelStyle = {
    background: theme.palette.background.card,
    borderRadius: 3,
    padding: 14,
    margin: "10px 20px",
    display: "flex",
    position: "relative",
    overflow: "hidden",
    width: 560,
    minHeight: 200,
  }

  const iconStyle = {
    position: "absolute",
    right: 10,
    bottom: -130,
    fontSize: 270,
    opacity: 0.1
  }

  const variantToContentMap = Immutable.fromJS({
    "create": {
      icon: "bhi-dashboard-o",
      title: "Set Up Your New Dashboard",
      description: "Click the Edit toggle to begin adding panels to your new Dashboard. Use the Save or Save As buttons to keep any changes."
    },
    "share": {
      icon: "bhi-dashboard-o",
      title: "No Shared Dashboards Yet",
      description: "You don’t have any dashboards shared with you yet. Once dashboards are shared, you will be able to access them via the “Open” button above."}
  });

  const content = variantToContentMap.get(variant);

  return (
      <div style={panelStyle} data-test-id={`fallback-${variant}`}>
        <div style={{width: "56%"}}>
          <h2 style={{fontSize: 20, fontWeight: 700, marginBottom: 10}}>{content.get("title")}</h2>
          <p style={{fontSize: 13, paddingRight: 10}}>{content.get("description")}</p>
        </div>
        <i className={content.get("icon")} style={iconStyle} />
      </div>
  )
});

export default DashboardFallback;