import React from "react";
import Immutable from "immutable";
import { InputLabel } from "@mui/material";
import { Alert } from '@mui/material';
import InheritedLocalePicker from "js/common/views/inputs/inherited-locale-picker";
import {getCurrentUser, updateSettings} from "js/common/users";
import * as Locales from "js/common/locales";

const DateTimeSettings = React.memo(() => {

  const user = getCurrentUser();
  const [idToLocale, setIdToLocale] = React.useState(null);
  const [updatingUser, setUpdatingUser] = React.useState(false);

  React.useEffect(() => {
    Locales
        .loadAll()
        .then(locales => setIdToLocale(locales.groupBy(l => l.get("id")).map(ls => ls.first())));
  }, []);

  return <div>
    <Alert severity="info" style={{marginBottom: "1rem", maxWidth: 450}}>You may have to refresh your web browser to see your changes take effect.</Alert>
    <div style={{margin: "10px 0px"}}>
      <InputLabel>Date Format:</InputLabel>
    </div>
    <div style={{marginLeft: -20}}>
      {idToLocale && <InheritedLocalePicker
          idToLocale={idToLocale}
          columnStyle={{whiteSpace: "nowrap"}}
          user={user}
          onChange={({localeId}) => {
            setUpdatingUser(true);
            updateSettings(Immutable.fromJS({localeId}))
                .then(() => {
                  user.set("localeId", localeId);
                  setUpdatingUser(true);
                })
                .finally(() => {
                  setUpdatingUser(false);
                });
          }} /> }
    </div>
  </div>
});

export default DateTimeSettings;
