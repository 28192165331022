import React from "react";
import pure from "js/common/views/pure";
import { TextField } from '@mui/material';

const _ = window._;

export default pure(({
    range: [lower, upper] = [],
    onChange,
    onBlur,
    ...props
}) => (
    <TextField variant="standard"
        type="number"
        min={lower}
        max={upper}
        onBlur={e => {
            let num = parseInt(e.target.value, 10);
            if (_.isNaN(num)) {
                num = 0;
            }
            if (lower != null && lower != undefined) {
                num = Math.max(num, lower);
            }
            if (upper != null && lower != undefined) {
                num = Math.min(num, upper);
            }

            if (onBlur) {
                onBlur(num, e);
            } else {
                onChange(num, e);
            }
        }}
        onChange={e => onChange(parseInt(e.target.value, 10), e)}
        {...props} />));
