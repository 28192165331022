/** @jsxImportSource @emotion/react */
import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import Tooltip from "react-tooltip";
import {css} from "@emotion/react";

import * as Users from "js/common/users";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

const DISABLED_TEXT_COLOR = "#9e9e9e";

const UserItem = createReactClass({

  propTypes: {
    user: ReactPropTypes.object.isRequired,
    onUserClick: ReactPropTypes.func.isRequired,
    isSelected: ReactPropTypes.bool,
    isDisabled: ReactPropTypes.bool,
    identifyObservers: ReactPropTypes.bool,
    multiSelect: ReactPropTypes.bool,
    qualifierType: ReactPropTypes.string
  },

  render() {
    const {user, isSelected, isDisabled, identifyObservers, theme, multiSelect, qualifierType} = this.props;
    const BASE_TEXT_COLOR = theme.palette.text.main;
    const color = user.isFilterMatch ? theme.palette.primary.main :
        (isDisabled || isSelected ? DISABLED_TEXT_COLOR : BASE_TEXT_COLOR);
    const hoverColor = isDisabled || isSelected ? DISABLED_TEXT_COLOR : theme.palette.primary.main;
    const labelCss = css`
      color: ${color};

      &:hover {
        color: ${hoverColor} !important
      }`;
    const listItemStyle = {
      listStyleType: "none",
      cursor: isDisabled || isSelected ? "default" : "pointer",
      paddingTop: "0.25rem"
    };
    const isObserver = identifyObservers && user.state === "INVISIBLE";

    if (multiSelect && (qualifierType && qualifierType === "USER")) {
      return (
          <li style={listItemStyle} onClick={!multiSelect ? this.handleClick : null}>
            <FormControlLabel
                control={<Checkbox
                    size="small"
                    style={{padding: 0, fontSize: 10}}
                    value={user.id}
                    defaultChecked={isSelected}
                    onClick={this.handleClick} />}
                label={<span style={{fontSize: 13, marginLeft: 5}}>{user.fullName}</span>} />
          </li>
      );
    } else {
      return (
          <li style={listItemStyle} onClick={this.handleClick}>
            <i
                className={`fa fa-user${isObserver ? "-secret" : ""}`}
                style={{color: "#9E9E9E"}}
                data-tip
                data-for={"user-" + user.id} />
            <span
                data-test-id={"users-and-groups-list-item"}
                style={labelStyle}
                css={labelCss}>{user.fullName}
          </span>
            {identifyObservers &&
                <Tooltip id={"user-" + user.id} type="light" place="top">
                  {isObserver ? "Observer" : "Active User"}
                </Tooltip>}
            {isSelected && <i className="bhi-check" />}
          </li>
      );
    }
  },

  handleClick() {
    const {user, onUserClick, isDisabled} = this.props;
    if (!isDisabled) {
      onUserClick(user.id);
    }
  }

});

const GroupItem = createReactClass({

  propTypes: {
    group: ReactPropTypes.object.isRequired,
    isExpanded: ReactPropTypes.bool.isRequired,
    isExpandable: ReactPropTypes.bool.isRequired,
    groupsClickable: ReactPropTypes.bool.isRequired,
    onGroupClick: ReactPropTypes.func.isRequired,
    onToggle: ReactPropTypes.func.isRequired,
    isSelected: ReactPropTypes.bool,
    isDisabled: ReactPropTypes.bool,
    readOnly: ReactPropTypes.bool
  },

  render() {
    const {
      isExpanded,
      onToggle,
      group,
      isExpandable,
      isDisabled,
      isSelected,
      groupsClickable,
      theme,
      multiSelect,
      qualifierType,
      readOnly
    } = this.props;

    const BASE_TEXT_COLOR = theme.palette.text.main;
    const iconClass = `fa fa-${isExpanded ? "minus" : "plus"}-circle`;

    const labelCss = groupsClickable ? css`
              cursor: ${(isDisabled || isSelected || readOnly) ? "default" : "pointer"};
              color: ${group.isFilterMatch ?
                      theme.palette.primary.main :
                      (isDisabled || readOnly || (isSelected && !multiSelect) ? DISABLED_TEXT_COLOR : BASE_TEXT_COLOR)};

              &:hover {
                color: ${isDisabled || isSelected || readOnly ? DISABLED_TEXT_COLOR : theme.palette.primary.main}
              }`
        : css`
              cursor: ${"text"};
              color: ${group.isFilterMatch ?
                      theme.palette.primary.main :
                      (isDisabled || (isSelected && !multiSelect) ? DISABLED_TEXT_COLOR : BASE_TEXT_COLOR)};`;
    const toggleIconCss = css`
      cursor: pointer;

      &:hover {
        color: ${theme.palette.primary.main}
      }
    `;

    if (multiSelect && (qualifierType && qualifierType === "GROUP")) {
      return (
          <li onClick={!multiSelect ? this.handleClick : null}>
            <FormControlLabel
                style={{fontSize: 10, padding: 0, margin: "0 0 0.2rem 0"}}
                control={<Checkbox
                    size="small"
                    value={group.id}
                    style={{padding: 0, fontSize: 10}}
                    defaultChecked={isSelected}
                    onClick={this.handleClick} />}
                label={<span style={{fontSize: 13, marginLeft: 5}}>{group.name}</span>} />
          </li>
      );
    } else {
      return (
          <div style={{paddingTop: "0.25rem", overflow: "hidden", textOverflow: "ellipsis"}}>
            {isExpandable && <i key="icon" css={toggleIconCss} className={iconClass} onClick={onToggle} />}
            <span
                data-test-id={"users-and-groups-list-item"}
                key={group.id}
                style={labelStyle}
                css={labelCss}
                onClick={groupsClickable ? this.handleClick : null}>{group.name}</span>
            {isSelected && !multiSelect && <i className="bhi-check" />}
          </div>
      );
    }
  },

  handleClick() {
    const {group, onGroupClick, isDisabled} = this.props;
    if (!isDisabled) {
      onGroupClick(group.id);
    }
  }

});

const HierarchyTree = createReactClass({

  propTypes: {
    groupNode: ReactPropTypes.object.isRequired,
    selectedGroupIds: ImmutablePropTypes.set.isRequired,
    selectedUserIds: ImmutablePropTypes.set.isRequired,
    excludedGroupIds: ImmutablePropTypes.set.isRequired,
    onGroupClick: ReactPropTypes.func.isRequired,
    onUserClick: ReactPropTypes.func.isRequired,
    expandToLevel: ReactPropTypes.number,
    pathToSelectedGroup: ImmutablePropTypes.list,
    excludeUsers: ReactPropTypes.bool,
    showDeletedGroups: ReactPropTypes.bool,
    identifyObservers: ReactPropTypes.bool,
    includeCube19Users: ReactPropTypes.bool,
    includeInactiveUsers: ReactPropTypes.bool,
    excludeNewAndInactive: ReactPropTypes.bool,
    disableChildrenOfSelectedGroups: ReactPropTypes.bool,
    groupsClickable: ReactPropTypes.bool,
    isDisabled: ReactPropTypes.bool,
    multiSelect: ReactPropTypes.bool,
    readOnly: ReactPropTypes.bool
  },

  getDefaultProps() {
    return {
      onGroupClick: () => {},
      onUserClick: () => {},
      expandToLevel: 0,
      excludeUsers: false,
      includeCube19Users: true,
      includeInactiveUsers: true,
      excludeNewAndInactive: false,
      disableChildrenOfSelectedGroups: false,
      isDisabled: false,
      groupsClickable: true,
      multiSelect: false,
      readOnly: false
    };
  },

  getInitialState() {
    const isExpanded = this.props.groupNode.isExpanded || this.props.expandToLevel > 0;
    return {
      isExpanded,
      hasBeenExpandedAtLeastOnce: isExpanded
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.props.expandToLevel > 0 || this.setExpanded(nextProps.groupNode.isExpanded);
  },

  render() {
    const {isExpanded, hasBeenExpandedAtLeastOnce} = this.state;
    const {
      groupNode,
      onGroupClick,
      excludeUsers,
      showDeletedGroups,
      pathToSelectedGroup,
      selectedGroupIds,
      excludedGroupIds,
      includeCube19Users,
      includeInactiveUsers,
      excludeNewAndInactive,
      isDisabled,
      groupsClickable,
      theme,
      multiSelect,
      qualifierType,
      readOnly
    } = this.props;
    const children = groupNode.values || [];
    const childGroups = children.filter(child => {
      const isSelected = selectedGroupIds.includes(child.id)
          || pathToSelectedGroup && pathToSelectedGroup.first() === child.id;
      return child.qualifierType === "GROUP"
          && child.visible
          && (showDeletedGroups || isSelected || !child.deleted)
          && !excludedGroupIds.has(child.id);
    });
    const childUsers = excludeUsers ? [] : children.filter(child =>
        child.qualifierType === "USER"
        && child.visible
        && (includeCube19Users || !child.cube19User)
        && (includeInactiveUsers || child.hasLogin)
        && (!excludeNewAndInactive || (child.state !== "NEW" && !(child.state === "INVISIBLE" && !child.hasLogin)))
    );
    const hasChildren = childGroups.length > 0 || childUsers.length > 0;
    const userCanOnlySeeThemselves = Users.getCurrentUser().get("dataVisibility") === "SELF";
    const displayStyle = {display: isExpanded ? "block" : "none", marginLeft: userCanOnlySeeThemselves ? 0 : "1.25em"};

    if (excludedGroupIds.has(groupNode.id)) {
      console.warn("Root Group is excluded in picker");
      return <div />;
    }

    return (
        <li style={listStyle}>
          {!userCanOnlySeeThemselves &&
              <GroupItem
                  theme={theme}
                  group={groupNode}
                  onToggle={this.onToggle}
                  onGroupClick={onGroupClick}
                  isExpandable={hasChildren}
                  isExpanded={multiSelect && qualifierType === "GROUP" ? true : isExpanded}
                  isSelected={selectedGroupIds.includes(groupNode.id)}
                  isDisabled={isDisabled}
                  groupsClickable={groupsClickable}
                  multiSelect={multiSelect}
                  qualifierType={qualifierType}
                  readOnly={readOnly}
              />}
          {hasBeenExpandedAtLeastOnce &&
              <ul style={displayStyle}>{childGroups.map(this.groupToElement)}</ul>}
          {hasBeenExpandedAtLeastOnce && <ul style={displayStyle}>{childUsers.map(this.userToElement)}</ul>}
        </li>
    );
  },

  groupToElement(childNode) {
    const {
      groupNode,
      selectedGroupIds,
      selectedUserIds,
      excludedGroupIds,
      pathToSelectedGroup,
      excludeUsers,
      showDeletedGroups,
      onGroupClick,
      onUserClick,
      identifyObservers,
      includeCube19Users,
      includeInactiveUsers,
      excludeNewAndInactive,
      disableChildrenOfSelectedGroups,
      groupsClickable,
      isDisabled,
      theme,
      multiSelect,
      qualifierType,
      readOnly
    } = this.props;

    let expandToLevel = this.props.expandToLevel;
    if (pathToSelectedGroup && pathToSelectedGroup.first() === childNode.id) {
      expandToLevel = expandToLevel + 1;
    }

    const disabled = disableChildrenOfSelectedGroups && (isDisabled || selectedGroupIds.includes(groupNode.id));

    return (
        <HierarchyTree
            theme={theme}
            key={childNode.id}
            pathToSelectedGroup={pathToSelectedGroup.delete(0) || Immutable.List()}
            excludeUsers={excludeUsers}
            showDeletedGroups={showDeletedGroups}
            groupNode={childNode}
            isDisabled={disabled || readOnly}
            selectedGroupIds={selectedGroupIds}
            selectedUserIds={selectedUserIds}
            excludedGroupIds={excludedGroupIds}
            onGroupClick={onGroupClick}
            onUserClick={onUserClick}
            expandToLevel={expandToLevel - 1}
            identifyObservers={identifyObservers}
            includeCube19Users={includeCube19Users}
            includeInactiveUsers={includeInactiveUsers}
            excludeNewAndInactive={excludeNewAndInactive}
            disableChildrenOfSelectedGroups={disableChildrenOfSelectedGroups}
            groupsClickable={groupsClickable}
            multiSelect={multiSelect}
            qualifierType={qualifierType}
            readOnly={readOnly}
        />
    );
  },

  userToElement(user) {
    const {
      groupNode,
      onUserClick,
      selectedGroupIds,
      selectedUserIds,
      identifyObservers,
      disableChildrenOfSelectedGroups,
      isDisabled,
      theme,
      multiSelect,
      qualifierType,
      readOnly
    } = this.props;
    const disabled = disableChildrenOfSelectedGroups && (isDisabled || selectedGroupIds.includes(groupNode.id));

    return (
        <UserItem
            theme={theme}
            key={user.id}
            user={user}
            onUserClick={onUserClick}
            isSelected={selectedUserIds.includes(user.id)}
            isDisabled={disabled || readOnly}
            identifyObservers={identifyObservers}
            multiSelect={multiSelect}
            qualifierType={qualifierType}
        />);
  },

  onToggle(e) {
    e.stopPropagation();
    this.setExpanded(!this.state.isExpanded);
    this.props.groupNode.isExpanded = !this.state.isExpanded;
  },

  setExpanded(isExpanded) {
    this.setState({
      isExpanded,
      hasBeenExpandedAtLeastOnce: this.state.hasBeenExpandedAtLeastOnce || isExpanded
    });
  }

});

export default HierarchyTree;


const listStyle = {
  listStyleType: "none",
  fontSize: "0.875rem"
};

const labelStyle = {
  paddingLeft: 10,
  paddingRight: 10
};
