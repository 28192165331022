import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";

import { Layout } from "js/common/views/foundation-column-layout";
import AdminHeader from "js/admin/common/admin-header";
import LoadingSpinner from "js/common/views/loading-spinner";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import JobErrorsTable from "js/admin/job-errors-table";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";

import getPriorityFlagLabel from "js/admin/targets/priority-flag-labels";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";


import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as Branding from "js/common/branding-constants"

const TargetCalculationJobs = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const { isLoading, selectedJobId, theme } = this.props;
        return (
            <div>
                <AdminHeader>
                    Target Recalculation Jobs
                </AdminHeader>
                <Layout allSmall={12} collapseRow={false}>
                    <Hint style={{margin: "1rem"}}>
                        <Icon icon="info" style={{color: theme.palette.hints.text}} />
                        Click on a row in the table below to see further details about that file upload job, e.g. a detailed error report (if any errors are found).
                    </Hint>
                    {isLoading ? <LoadingSpinner /> : this.renderTable()}
                    {selectedJobId && this.renderJobDetails()}
                </Layout>
            </div>
        );
    },

    renderTable() {
        const columns = [
            "ID",
            "Started by",
            "Started on",
            "Ended on",
            "Status",
            "Metric(s) Affected",
            "Target Start Date",
            "Display Target as a Priority",
            "% of Group Target Calculated",
            "Starting from Group",
            "Include Inactive Users"
        ];
        const rows = this.props.jobs
            .map(jobToRow)
            .toJS();
        return (
            <SimpleDataTable
                columns={columns}
                rows={rows}
                onCellClick={this.handleJobRowClick}
                initialSortDirection="DESC" />
        );
    },

    handleJobRowClick(cellValue, row) {
        const jobId = row[0].originalValue;
        this.props.onJobSelect(jobId);
    },

    renderJobDetails() {
        const { jobs, selectedJobId } = this.props;
        const index = jobs.findIndex(j => j.get("id") === selectedJobId);
        const job = jobs.get(index);
        const spacing = {
            marginTop: "1rem",
            marginBottom: "1rem"
        };
        if (job.has("errors") && !job.get("errors").isEmpty()) {
            return (
                <Layout allSmall={12} rowStyle={spacing}>
                    <p>
                        <i className="fa fa-exclamation-triangle" style={{ color: "#f00", paddingRight: 6 }} />
                        The following errors were found in the file uploaded for job ID {selectedJobId}
                    </p>
                    <JobErrorsTable errors={job.get("errors")} />
                </Layout>
            );
        } else {
            const labelsByStatus = {
                PENDING: `is queued for processing`,
                PROCESSING: `is currently being processed`,
                FAILED: `was not processed. ${Branding.submitTicketString}`,
                COMPLETED: `was processed successfully`
            };
            const params = Immutable.fromJS(job.get("payload"));
            const kpiIds = getKpiIds(params);
            const kpis = kpiIds.map(kpiId => kpiRepo.get(kpiId).get("name"));
            return (
                <Layout allSmall={12} rowStyle={spacing}>
                    <p>{`Job ID ${selectedJobId} ${labelsByStatus[job.get("status")]} ${kpis.isEmpty() ? "for all Metrics" : ""}`}</p>
                    {!kpis.isEmpty() &&
                        <p>
                            The following {kpis.count()} Metrics were affected:
                            <br />
                            {kpis.join(", ")}
                        </p>}
                </Layout>
            );
        }
    }

});

const jobToRow = job => {
    const startedBy = Users.getUser(job.get("userId")).get("fullName");
    const startedOn = timestampToDateTime(job.get("startTimestamp"));
    const lastStatusChange = timestampToDateTime(job.get("lastStatusChangeTimestamp"));
    const jobStatus = job.get("status");
    const jobDoneStatuses = new Set(["COMPLETED", "FAILED"]);
    const endedOn = jobDoneStatuses.has(jobStatus) ? lastStatusChange : "";
    const status = `Moved to ${jobStatus} at ${lastStatusChange}`;

    const params = Immutable.fromJS(job.get("payload"));
    const groupTargetPercent = params.get("loadingFactor") * 100;
    const startingGroup = Groups.getGroup(params.get("startingGroupId"));
    const kpiIds = getKpiIds(params);
    const kpis = kpiIds
        .map(kpiId => {
            const kpi = kpiRepo.get(kpiId);
            return kpi ? kpi.get("name") : "";
        })
        .filter(kpiName => kpiName.length > 0);

    return [
        job.get("id"),
        startedBy,
        startedOn,
        endedOn,
        status,
        getAffectedKpisLabel(kpis),
        params.get("targetStart"),
        getPriorityFlagLabel(params.get("priorityKpiFlag")),
        groupTargetPercent,
        startingGroup ? startingGroup.get("name") : <span><i className="fa fa-exclamation-triangle" /> Unknown Group</span>,
        !params.get("excludeInvisibleUsers") ? "Yes" :"No"
    ];
};

const getKpiIds = jobParams => jobParams.get("kpiIds") || Immutable.List();

const getAffectedKpisLabel = kpis => {
    if (kpis.isEmpty()) {
        return "All Metrics";
    } else if (kpis.count() === 1) {
        return kpis.first();
    } else {
        return `${kpis.count()} Metrics (click for details)`;
    }
};

const timestampToDateTime = (timestamp, pattern = "YYYY-MM-DD HH:mm:ss") => {
    const dateTime = moment(timestamp).local();
    return dateTime.format(pattern);
};

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <TargetCalculationJobs theme={theme} {...props} />;
};