import React from "react";
import createReactClass from "create-react-class";
import currentClient from "js/common/repo/backbone/current-client";

import Tabs from "js/common/views/tabs";
import EditKpisApp from "js/admin/kpis/edit-kpis/edit-kpis-app";
import OrderMetrics from "js/admin/kpis/order-kpis/order-kpis-app";
import KeyKpis from "js/admin/kpis/key-kpis/app";
import * as Users from "js/common/users";

import {ExplanationOverview} from "js/admin/kpis/explanation-overview";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";


const indexByTabName = {
  KPIS: 0,
  ORDER_KPIS: 1,
  KEY_KPIS: 2
};

const MetricsApp = createReactClass({

  getDefaultProps() {
    return {
      initialTab: "KPIS"
    };
  },

  getInitialState() {
    return {
      showTabIndex: indexByTabName[this.props.initialTab]
    };
  },

  render() {
    return (
        <Tabs
            selectedIndex={this.state.showTabIndex}
            onChangeTab={this.handleChangeTab}
            tabs={this.getTabs()}
            saveTabStateOnChange={false}
            contentStyle={{
              border: "none !important",
              borderTop: `1px solid ${this.props.theme.palette.action.selected}`
            }}
            containerStyle={{margin: 3}} />
    );
  },

  getTabs() {
    let tabs = [];

    const metricsTab = {
      title: <TabTitle text="Edit Metrics" />,
      content: <EditKpisApp />
    };

    const orderMetricsTab = {
      title: <TabTitle text="Metric Ordering" />,
      content: <OrderMetrics />
    };

    const keyKpisTab = {
      title: <TabTitle text="Master Metrics" />,
      content: <KeyKpis />
    };

    const explanationsTab = {
      title: <TabTitle text="Metric Explanations" />,
      content: <ExplanationOverview />
    };

    const currentUser = Users.getCurrentUser();
    if (currentClient.canAccessApp("kpi_admin") && Users.canAccessApp(currentUser, "kpi_admin")) {
      tabs.push(metricsTab);
      tabs.push(orderMetricsTab);
      tabs.push(keyKpisTab);
      tabs.push(explanationsTab);
    }

    return tabs;
  },

  handleChangeTab(index) {
    this.setState({showTabIndex: index});
  }

});

const TabTitle = ({icon, text}) => <span><i className={`fa fa-${icon}`} /> {text}</span>;

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <MetricsApp theme={theme} {...props} />;
};
