import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';

import Dropzone from "js/common/views/inputs/dropzone";
import Err from "js/common/views/error";

import { TextButton } from "js/common/views/inputs/buttons";
import { formatStorageSize } from "js/common/utils/numbers";

const maxFileSizeInBytes = 10e6;
const maxFileSizeStr = formatStorageSize(maxFileSizeInBytes, 0);

const IMAGE_SCALE = {
    maxHeight: "100px"
};

export default createReactClass({

    propTypes: {
        user: ReactPropTypes.object.isRequired,
        onUploadClick: ReactPropTypes.func.isRequired
    },

    getInitialState() {
        return {
            file: null,
            isDropzoneVisible: false
        };
    },

    componentWillUnmount() {
        const {file} = this.state;
        if (file) {
            URL.revokeObjectURL(file.preview);
        }
    },

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState(this.getInitialState());
    },

    render() {
        const { isDropzoneVisible } = this.state;
        const label = isDropzoneVisible ? "Hide upload" : "Upload Photo";
        return (
            <div>
                <TextButton label={label} onClick={() => this.setState({ isDropzoneVisible: !isDropzoneVisible })}/>
                {isDropzoneVisible && this.renderDropZone()}
            </div>);
    },

    renderDropZone() {
        const { user, onUploadClick } = this.props;
        const { file } = this.state;
        const hasFile = !!file;
        let content;
        let buttons;
        if (hasFile) {
            const fileName = file.name;
            const fileSize = file.size;
            if (!isValidImageFile(fileName)) {
                const message = `You can only upload .png or .jpg files. '${fileName}' is not a supported image file format.`;
                content = <Err text={message} />;
            } else if (!isValidFileSize(fileSize)) {
                content = <Err text={`You can only upload files with a maximum size of ${maxFileSizeStr}. '${fileName}' is ${formatStorageSize(fileSize)}.`} />;
            } else {
                content = <div>
                    <img src={file.preview} style={IMAGE_SCALE}/>
                    <p>{file.name}</p>
                </div>;
                buttons = <div style={{ margin: "0 auto" }}>
                    <TextButton
                        icon="upload" label={`Upload`}
                        style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
                        onClick={() => {
                            onUploadClick(user, this.state.file);
                            this.setState({ file: null });
                        }} />
                    <TextButton
                        icon="trash" label={`Remove`}
                        style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
                        onClick={this.removeFile} />
                </div>;
            }
        } else {
            content = <p>
                Drop your new profile picture here or click to choose an image file.
                <br />
                {`It should be a .png or .jpg file of up to ${maxFileSizeStr}.`}
            </p>;
        }

        return (
            <div>
                <Dropzone
                    onDrop={acceptedFiles => this.onFileDrop(acceptedFiles[0])}
                    multiple={false}
                    className="react-dropzone"
                    activeClassName="react-dropzone-active"
                    style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    {content}
                </Dropzone>
                {buttons}
            </div>);
    },

    onFileDrop(file) {
        const preview = URL.createObjectURL(file);
        this.setState({file: Object.assign(file, {preview})})
    },

    removeFile() {
        URL.revokeObjectURL(this.state.file.preview);
        this.setState({file: null})
    },
});

const isValidImageFile = fileName => {
    const regex = /\.(jpe?g|png)$/i;
    return regex.test(fileName.toLowerCase());
};

const isValidFileSize = fileSizeInBytes => fileSizeInBytes <= maxFileSizeInBytes;
