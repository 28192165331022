import moment from "moment";
import Immutable from "immutable";

import ConfigurableLeaderboard from "js/charting/leaderboard/configurable-leaderboard";
import { fullReloadForLeaderboard, configChangeReloadForLeaderboard } from "js/charting/leaderboard/data-loader";

import ConfigurableTrendChart from "js/charting/trend/configurable-trend-chart";
import { fullReloadForTrendChart } from "js/charting/trend/data-loader";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Users from "js/common/users";

export const getDefaultPage = () => Immutable.fromJS({
    name: "New Page",
    charts: [ getExampleTrendChartConfig() ]
});

export const getExampleTrendChartConfig = () => ({
    ...getDefaultConfigForTrendChart("Trend Chart 1"),
    isExampleChart: true
});
export const getExampleTrendData = () => {
    const startDate = moment("2016-05-01", "YYYY-MM-DD");
    const values = [0, 15, 35, 25, 45, 50, 65];
    const trend = values.map((v, i) => ({
        dataAggregation: "DAILY",
        date: startDate.clone().add(i, "day"),
        offsetFromStart: 1,
        value: values[i]
    }));
    return [{
        kpiId: getKpis().first().get("id"),
        trend
    }];
};

const getKpis = () => Immutable.fromJS(kpiRepo.getAll().toJSON()).filter(kpi => kpi.get("visible"));

export const getDefaultConfigForChartType = (type, name, widthScale) => {
    const chartType = chartProtocolByType[type];
    return chartType.getDefaultConfig(name, widthScale);
};

const getCommonDefaultConfig = (type, widthScale = 1) => ({
    cid: Math.random(),
    type,
    widthScale
});
export const getDefaultConfigForTrendChart = (chartName, widthScale) => ({
    ...getCommonDefaultConfig("TREND", widthScale),
    name: chartName,
    timeframe: timeframeRepo.getDefaultForClient().getRawJson(),
    kpiIds: [],
    clientIds: [],
    matchAnyTagIds: [],
    matchAllTagIds: [],
    excludedTagIds: [],
    displayDataCumulatively: false,
    qualifierType: "GROUP",
    qualifierId: getCurrentUserGroupId(),
    trendType: "STANDARD",      // "ADVANCED" || "STANDARD"
    dataGroups: [],
    chartTheme: "DEFAULT",      // "DEFAULT" || "WHITE"
    excludeWeekends: false,
    averageByUser: false,
    dateDisplay: "ABSOLUTE",    // "ABSOLUTE" || "RELATIVE"
    dataAggregation: "DAILY",   // "DAILY" || "WEEKLY" || "MONTHLY" || "QUARTERLY" || "YEARLY" || FINANCIAL_QUARTERLY || FINANCIAL_YEARLY
    showDataPoints: false
});
const getDefaultConfigForLeaderboard = () => ({
    ...getCommonDefaultConfig("LEADERBOARD"),
    groupId: getCurrentUserGroupId(),
    timeframe: timeframeRepo.getDefaultForClient().getRawJson()
});

const getCurrentUserGroupId = () => Users.getCurrentUser().get("groupId");

export const getViewClassForChartType = type => chartProtocolByType[type].viewClass;
export const fullReloadForChart = config => chartProtocolByType[config.type].fullReload(config);

const chartProtocolByType = {
    "TREND": {
        getDefaultConfig: getDefaultConfigForTrendChart,
        viewClass: ConfigurableTrendChart,
        fullReload: fullReloadForTrendChart
    },
    "LEADERBOARD": {
        getDefaultConfig: getDefaultConfigForLeaderboard,
        viewClass: ConfigurableLeaderboard,
        fullReload: fullReloadForLeaderboard,
        configChangeReload: configChangeReloadForLeaderboard
    }
};
