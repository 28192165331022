/** @jsxImportSource @emotion/react */
import React, {useRef, useState} from "react";

import {
  Box,
  Button,
  Card,
  Grid,
  ListItemText,
  Menu,
  MenuItem
} from "@mui/material";
import Tooltip from "js/common/views/tooltips";
import ArrowDropDownIcon from "@mui/icons-material/MoreVert";
import * as Styles from "js/common/views/index-view/index-card.styles";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const IndexCard = (props) => {
  const {data, onClick, mode, actions, ...other} = props;
  const [openOption, setOpenOption] = useState(false);
  const optionsRef = useRef(null);
  const { theme } = React.useContext(CustomThemeContext)

  const handleOptionOpen = () => {
    setOpenOption(true);
  };

  const handleOptionClose = () => {
    setOpenOption(false);
  };

  const handleClick = () => {
    onClick && onClick(data.get("id"));
  };

  const renderMenu = () => (
      <>
        <Menu
            anchorEl={optionsRef.current}
            elevation={1}
            onClose={handleOptionClose}
            open={openOption}
            getContentAnchorEl={null}
            anchorOrigin={{vertical: "bottom", horizontal: "right"}}
            transformOrigin={{vertical: "top", horizontal: "right"}}
        >
          {data.get("actions")
              .map((option) => (
                  <MenuItem key={option.get("label")} onClick={() => option.get("action")(data.get("id"))}>
                    <ListItemText primary={option.get("label")} />
                  </MenuItem>
              ))}
        </Menu>
        <Button style={{ color: theme.themeId === "light" ? theme.palette.text.main : "yellow" }}
                ref={optionsRef}
                onClick={handleOptionOpen}
                variant="text"
                css={Styles.cardMenuStyle(props)}>
          <ArrowDropDownIcon fontSize="small" />
        </Button>
      </>
  );

  const renderName = (name) => {
    if(name.length > 30) {
      const processedName = name.substring(0, 30) + "..."
      return <Box>
        <Tooltip
            text={name}
            wrap={true}
            customStyle={{width: "100%"}}
            position={mode === "grid" ? "top" : "right"}
            maxWidth={mode === "grid" ? 200 : 600}
      >
          <h2 css={Styles.cardTitleStyle(props, theme)}>{processedName}</h2>
        </Tooltip>
      </Box>
    }
    else {
      return <Box><h2 css={Styles.cardTitleStyle(props, theme)}>{name}</h2></Box>
    }
  };

  const renderDescription = (description) => {
    let descString;
    if (typeof description === 'string' || description instanceof String){
      descString = description;
    } else {
      descString = description.join(" ");
    }
    if(descString.length > 150){
      return <Box>
        <Tooltip
            text={descString}
            wrap={true}
            customStyle={{width: "100%"}}
            position={"top"}
            maxWidth={mode === "grid" ? 300 : 600}
        >
          <p css={Styles.cardDescriptionStyle(props, theme)}>{descString.substring(0, 150).trim() + "..."}</p>
        </Tooltip>
      </Box>
    } else {
      return <Box><p css={Styles.cardDescriptionStyle(props, theme)}>{descString}</p></Box>
    }
  };

  return (
    <Grid
        {...other}
        css={Styles.cardContainerStyle(props, theme)}
        item
        key={data.get("id")}
        md={mode === "grid" ? 4 : 12}
        sm={mode === "grid" ? 6 : 12}
        xs={12}>
      <Card css={Styles.cardStyle(props, theme)}>
        {data.get("actions") && renderMenu()}
        {data.get("hasUnsavedChanges") && <div onClick={handleClick} css={Styles.unsavedChangesStyle(props)}><i className="fa fa-warning" style={{marginBottom: 5}}/>Unsaved Changes</div>}
        <Box sx={Styles.cardInnerStyle(props, theme)} onClick={handleClick}>
          {data.get("image") && <div css={Styles.cardImageStyle(props)}><img src={data.get("image")} /></div>}
          {data.get("name") && renderName(data.get("name"))}
          {data.get("description") && renderDescription(data.get("description"))}
        </Box>
      </Card>
    </Grid>
  );
};

export default IndexCard;
