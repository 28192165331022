const getAutoplayVideoUrl = (videoUrl, muteVideo, showSubtitles, displayTimeInSecs) => {
    const videoInfo = parseYoutubeVideoUrl(videoUrl);
    const videoId = videoInfo.id;
    const startAtInSecs = videoInfo.startSeconds;
    const startAt = startAtInSecs ? startAtInSecs : 0;
    const defaultParams = `rel=0&autoplay=1&modestbranding=1&controls=0&showinfo=0&iv_load_policy=3`;
    const url = `https://www.youtube.com/embed/${videoId}?${defaultParams}&cc_load_policy=${showSubtitles ? 1 : 0}&mute=${muteVideo ? 1 : 0}&start=${startAt}`;
    if (displayTimeInSecs) {
        const endAt = startAt ? (startAt + displayTimeInSecs) : displayTimeInSecs;
        return `${url}&end=${endAt}`;
    } else {
        return url;
    }
};

const parseYoutubeVideoUrl = url => {
    const videoId = /^https?\:\/\/(www\.)?youtu\.be/.test(url) ?
        url.replace(/^https?\:\/\/(www\.)?youtu\.be\/([\w-]{11}).*/,"$2") : url.replace(/.*\?v\=([\w-]{11}).*/,"$1");
    const videoStartTime = /[^a-z]t\=/.test(url) ? url.replace(/^.+t\=([\dhms]+).*$/,'$1') : 0;
    const videoStartSeconds = videoStartTime ? timeToSec(videoStartTime) : 0;

    return {
        id: videoId,
        startString: videoStartTime,
        startSeconds: videoStartSeconds
    };
};

const timeToSec = str => {
    let sec = 0;
    if (/h/.test(str)) {
        sec += parseInt(str.match(/(\d+)h/,'$1')[0],10) * 60 * 60;
    }
    if (/m/.test(str)) {
        sec += parseInt(str.match(/(\d+)m/,'$1')[0],10) * 60;
    }
    if (/s/.test(str)) {
        sec += parseInt(str.match(/(\d+)s/,'$1')[0],10);
    }
    if (!/h/.test(str) && !/m/.test(str) && !/s/.test(str) && !isNaN(str)) {
        sec = parseInt(str, 10);
    }
    return sec;
};

export default getAutoplayVideoUrl;

export {
    getAutoplayVideoUrl,
    parseYoutubeVideoUrl
};
