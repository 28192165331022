import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";

import AdminHeader from "js/admin/common/admin-header";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import Layout from "js/common/views/foundation-column-layout";
import LoadingSpinner from "js/common/views/loading-spinner";

import NewUsersTable from "js/admin/users/new-users-table";
import NewUsersGuide from "js/admin/users/new-users-guide";

import ActivatedUsersTable from "js/admin/users/activated-users-table";
import EditUserPanel from "js/admin/users/edit-user-panel";
import DeactivateUserDialog from "js/admin/users/deactivate-user-dialog";
import SetPasswordDialog from "js/admin/users/set-password-dialog";

import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";
import * as popups from "js/common/popups";
import * as auth from "js/common/auth";
import * as Pages from "js/common/pages";
import * as Locales from "js/common/locales";
import eventBus from "js/cube19.event-bus";
import {apiUrl} from "js/app-configuration";
import {indexBy} from "js/common/utils/collections";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const path = window.path;

const UsersApp = createReactClass({

  getInitialState() {
    return {
      userById: Immutable.Map(),
      idToLocale: Immutable.Map(),
      permissions: null,
      appAssignments: null,
      userIdsToLogInAs: null,
      oneViewPages: null,
      selectedUserId: null,
      isLoadingUser: false,
      isLoading: false,
      isUpdating: false,
      showDeactivateDialog: false,
      showSetPasswordDialog: false
    };
  },

  componentDidMount() {
    this.setState({isLoading: true});
    Promise
        .all([
          Users.load(),
          Pages.getAll(),
          Locales.loadAll(),
          Users.fetchFallbackUsers()
        ])
        .then(([users, pages, locales, fallbackUsers]) => {
          const idToFallbackUser = indexBy(user => user.get("id"), fallbackUsers);
          const idToUser = users.groupBy(u => u.get("id")).map(us => us.first());
          const idToLocale = locales.groupBy(l => l.get("id")).map(ls => ls.first());
          this.setState({
            userById: idToUser,
            idToNameOnlyUser: idToFallbackUser,
            idToLocale,
            oneViewPages: pages,
            isLoading: false
          });
        });
  },

  componentDidUpdate(_prevProps, prevState) {
    const {userById, selectedUserId, userIdsToLogInAs, appAssignments, permissions} = this.state;
    const currentUserId = Users.getCurrentUser().get("id");
    const sourceUserId = Users.getSourceUser().get("id");
    const currentUserFromState = userById.get(currentUserId);
    const sourceUserFromState = userById.get(sourceUserId);

    const selectedUserIdChanged = prevState.selectedUserId !== selectedUserId;
    const currentUserWasUpdated = prevState.userById.get(currentUserId, currentUserFromState) !== currentUserFromState;
    const sourceUserWasUpdated = prevState.userById.get(sourceUserId, sourceUserFromState) !== sourceUserFromState;
    const userIdsToLogInAsWereUpdated = prevState.userIdsToLogInAs !== userIdsToLogInAs;
    const sourceUserTargetIdsWereUpdated = !selectedUserIdChanged && userIdsToLogInAsWereUpdated;
    const appAssignmentsWereUpdated = prevState.appAssignments !== appAssignments;
    const currentUserAppAssignmentsWereUpdated = !selectedUserIdChanged && appAssignmentsWereUpdated;
    const permissionsWereUpdated = prevState.permissions !== permissions;
    const currentUserPermissionsWereUpdated = !selectedUserIdChanged && permissionsWereUpdated;

    const triggerCurrentUserUpdate = currentUserWasUpdated
        || currentUserAppAssignmentsWereUpdated
        || currentUserPermissionsWereUpdated;
    const triggerSourceUserUpdate = sourceUserWasUpdated || sourceUserTargetIdsWereUpdated;
    const currentUserIsSourceUser = currentUserId === sourceUserId;

    if (triggerCurrentUserUpdate || (currentUserIsSourceUser && triggerSourceUserUpdate)) {
      eventBus.trigger("current-user:updated");
    }

    if (triggerSourceUserUpdate || (currentUserIsSourceUser && triggerCurrentUserUpdate)) {
      eventBus.trigger("source-user:updated");
    }
  },

  render() {
    const {isLoading, selectedUserId} = this.state;
    const {theme} = this.props;
    return (
        <div>
          <AdminHeader>
            Activate new users
          </AdminHeader>
          <Layout small={[8, 4]} rowStyle={{marginTop: "1rem", marginBottom: "1rem"}}>
            {isLoading
                ? <LoadingSpinner iconSize={3} />
                : <NewUsersTable users={this.getNewUsers()} onActivate={this.handleActivateUser} />}
            <NewUsersGuide />
          </Layout>
          <AdminHeader>
            Manage activated users
          </AdminHeader>
          <Hint style={{margin: "0 1em"}}>
            <Icon icon="info" style={{color: theme.palette.hints.text}} />
            Changes on this page will auto-save
          </Hint>
          <Layout allSmall={6} columnStyle={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
            {isLoading ?
                <LoadingSpinner iconSize={3} /> :
                <ActivatedUsersTable users={this.getActivatedUsers()} onUserClick={this.handleUserClick} />}
            {!selectedUserId &&
                <span>
                            <i className="fa fa-question-circle" style={{display: "table-cell"}} />
                            <span style={{display: "table-cell", paddingLeft: 8}}>
                                Click on a User in the table to see and edit their details
                            </span>
                        </span>}
            {selectedUserId && this.renderEditUserPanel()}
          </Layout>
          <DeactivateUserDialog
              isOpen={this.state.showDeactivateDialog}
              onDeactivateClick={this.handleDeactivateUser}
              onCancelClick={() => this.setState({showDeactivateDialog: false})} />
          <SetPasswordDialog
              isOpen={this.state.showSetPasswordDialog}
              onUpdateClick={this.handleSetUserPassword}
              onCancelClick={() => this.setState({showSetPasswordDialog: false})} />
        </div>);
  },

  renderEditUserPanel() {
    if (this.state.isLoadingUser) {
      return (
          <span style={{color: "#999"}}>
                    <span style={{paddingRight: 8}}>Loading User</span>
                    <i className="fa fa-spinner fa-pulse fa-1x" />
                </span>
      );
    } else {
      const {
        selectedUserId,
        userById,
        idToNameOnlyUser,
        permissions,
        userIdsToLogInAs,
        appAssignments,
        oneViewPages,
        idToLocale,
        isUpdating
      } = this.state;
      return (
          <EditUserPanel
              key={selectedUserId}
              user={userById.get(selectedUserId)}
              userById={userById}
              idToNameOnlyUser={idToNameOnlyUser}
              permissions={permissions}
              userIdsToLogInAs={userIdsToLogInAs}
              appAssignments={appAssignments}
              oneViewPages={oneViewPages}
              idToLocale={idToLocale}
              onChange={this.handleUserChange}
              onPermissionChange={this.handlePermissionChange}
              onAppPermissionChange={this.handleAppAssignmentChange}
              onUsersToLoginAsChange={this.handleUsersToLogInAsChange}
              onUploadClick={this.handleUploadClick}
              onResetUserPassword={this.handleResetUserPassword}
              onSetUserPassword={this.showSetPasswordDialog}
              onDeactivateClick={this.showDeactivateDialog}
              isLoading={isUpdating} />
      );
    }
  },

  showSetPasswordDialog() {
    this.setState({showSetPasswordDialog: true});
  },

  showDeactivateDialog() {
    this.setState({showDeactivateDialog: true});
  },

  getUser(userId) {
    return this.state.userById.get(userId);
  },

  getNewUsers() {
    return this.state.userById
        .valueSeq()
        .filter(Users.isNew);
  },

  getActivatedUsers() {
    if (Users.isCube19User(Users.getCurrentUser())) {
      return this.state.userById
          .valueSeq()
          .filter(Users.isActive);
    } else {
      return this.state.userById
          .valueSeq()
          .filter(user => Users.isActive(user) && !Users.isCube19User(user));
    }
  },

  handleUserClick(selectedUserId) {
    this.setState({
      selectedUserId,
      isLoadingUser: true,
      error: null
    });

    Promise
        .all([
          loadAppsForUser(selectedUserId),
          loadPermissionsForUser(selectedUserId),
          loadUsersToLoginAs(selectedUserId)
        ])
        .then(([appAssignments, permissions, userIdsToLogInAs]) => {

          this.setState({
            permissions,
            appAssignments,
            userIdsToLogInAs,
            isLoadingUser: false
          });
        });
  },

  handleUserChange(changes, localChangeOnly) {
    const immutableChanges = Immutable.fromJS(changes);

    const {selectedUserId, userById} = this.state;
    const user = this.getUser(selectedUserId);
    const mergedUser = user.merge(immutableChanges);
    const changedAttribute = immutableChanges.keySeq().get(0);
    const allUserNames = userById.map(user => user.get("username")).toList();

    if (localChangeOnly) {
      this.setState({userById: userById.set(selectedUserId, mergedUser)});
    } else {
      this.setState({isUpdating: true});
      const updatePromise = changedAttribute === "email" ? Users.updateEmail(mergedUser) : Users.update(mergedUser);
      updatePromise
          .then(updatedUser => {
            const originalValue = user.get(changedAttribute);
            const newValue = updatedUser.get(changedAttribute);
            if (originalValue !== newValue) {
              if (selectedUserId === Users.getCurrentUser().get("id")) {
                if (changedAttribute === "dataVisibility") {
                  eventBus.trigger("logged-in-user:data-visibility-changed");
                }
              }
            }
            this.setState({
              isUpdating: false,
              userById: userById.set(selectedUserId, updatedUser)
            });
          }, e => {
            if (changedAttribute === "username" && allUserNames.contains(immutableChanges.get("username"))) {
              const userWithSharedName = userById.find(user => user.get("username")
                  === immutableChanges.get("username"));
              popups.error("Username could not be changed as '"
                  + immutableChanges.get("username")
                  + "' is already in use by "
                  + userWithSharedName.get("fullName")
                  + ".");
            } else if (e.responseJSON && e.responseJSON.type) {
              popups.error(e.responseJSON.message + ". Your changes have not been saved.");
            } else {
              popups.contactSupport();
            }
            this.setState({isUpdating: false});
          });
    }
  },

  handlePermissionChange(newPermissions) {
    this.setState({isUpdating: true});

    updatePermissionsForUser(this.state.selectedUserId, newPermissions).then(serverUpdatedPermissions => {
      this.setState({
        isUpdating: false,
        permissions: serverUpdatedPermissions
      });
    }, () => {
      popups.contactSupport();
      this.setState({isUpdating: false});
    });
  },

  handleAppAssignmentChange(newAppAssignments) {
    this.setState({isUpdating: true});

    updateAppsForUser(this.state.selectedUserId, newAppAssignments).then(serverUpdatedAppAssignments => {
      this.setState({
        isUpdating: false,
        appAssignments: serverUpdatedAppAssignments
      });
    }, () => {
      popups.contactSupport();
      this.setState({isUpdating: false});
    });
  },

  handleUsersToLogInAsChange(newUserIds) {
    this.setState({isUpdating: true});

    updateUsersToLoginAs(this.state.selectedUserId, newUserIds).then(serverUpdatedUserIdsToLogInAs => {
      this.setState({
        isUpdating: false,
        userIdsToLogInAs: serverUpdatedUserIdsToLogInAs
      });

    }, () => {
      popups.contactSupport();
      this.setState({isUpdating: false});
    });
  },

  handleActivateUser(user, state) {
    Users.setupAs(user.get("id"), state).then(updatedUser => {
      const stateLabel = state === "OBSERVER" ? state : `${state} USER`;
      const message = "New User <strong>" + updatedUser.get("fullName") + "</strong> activated as " + stateLabel;
      popups.success(message);
      this.setState({
        userById: this.state.userById.set(updatedUser.get("id"), updatedUser)
      });
    }, () => {
      popups.contactSupport();
    });

  },

  handleUploadClick(user, file) {
    this.setState({isUpdating: true});
    const req = window.superagent
        .post(apiUrl + "/ApplicationLayer/users/" + user.get("id") + "/upload-profile-photo")
        .withCredentials();
    req.attach("file", file);
    req.end((error, response) => {
      if (error) {
        popups.contactSupport();
        this.setState({isUpdating: false});
      } else {
        popups.success("Your file has been uploaded");
        this.handleUserChange({photoURL: response.body.photoURL});
      }
    });
  },

  handleResetUserPassword(user) {
    if (!user.get("hasLogin")) {
      popups.alert(user.get("username") +
          " has \"Can Login\" selected as 'no', <br/> switch it to 'yes' before requesting a password reset.", {
        title: "Update User Settings"
      });
      return;
    }

    auth
        .requestPasswordReset(user.get("username"))
        .then(() => {
          popups.success("An email has been sent to " + user.get("email"), "bottom-left");
        }, e => {
          if (e?.responseJSON?.type === "SSO_LOGIN_REQUIRED") {
            popups.error(e.responseJSON.message);
          } else {
            popups.contactSupport();
          }
        });
  },

  handleSetUserPassword(options) {
    this.setState({
      isUpdating: true,
      showSetPasswordDialog: false
    });
    setUserPassword(this.state.selectedUserId, options)
        .then(() => {
          popups.success("Password updated", "bottom-left");
        }, (e) => {
          if (e.responseJSON && e.responseJSON.type === "PERMISSION_DENIED") {
            popups.error(e.responseJSON.message + ". Password not set.");
          } else if (e?.responseJSON?.type === "SSO_LOGIN_REQUIRED") {
            popups.error(e.responseJSON.message);
          } else {
            popups.contactSupport();
          }
        })
        .finally(() => {
          this.setState({
            isUpdating: false
          });
        });
  },

  handleDeactivateUser(options) {
    this.setState({
      isUpdating: true,
      showDeactivateDialog: false
    });

    const {selectedUserId, userById} = this.state;
    const user = this.getUser(selectedUserId);
    deactivateUser(user, options)
        .then(result => {
          popups.success(`User ${user.get("fullName")} deactivated`, "bottom-left");
          this.setState({
            isUpdating: false,
            userById: userById.set(selectedUserId, Immutable.fromJS(result)),
            selectedUserId: null
          });
        }, () => {
          popups.contactSupport();
          this.setState({
            isUpdating: false
          });
        });
  }

});

const setUserPassword = (userId, options) => ajax.put({
  url: path("users", userId, "set-password"),
  data: options
});

const deactivateUser = (user, options) => ajax.put({
  url: path("users", user.get("id"), "deactivate"),
  json: {
    ...options,
    leavingDate: options.leavingDate.format("YYYY-MM-DD")
  }
});

export const loadPermissionsForUser = userId => ajax
    .get({url: path("users", userId, "permissions")})
    .then(response => Immutable.fromJS(response).toSet());

const updatePermissionsForUser = (userId, permissions) => ajax
    .put({
      url: path("users", userId, "permissions"),
      json: permissions.toJS()
    })
    .then(response => Immutable.fromJS(response).toSet());

export const loadAppsForUser = userId => ajax
    .get({url: path("users", userId, "apps")})
    .then(response => Immutable.fromJS(response).toSet());

const updateAppsForUser = (userId, apps) => ajax
    .put({
      url: path("users", userId, "apps"),
      json: apps.toJS()
    })
    .then(response => Immutable.fromJS(response).toSet());

const loadUsersToLoginAs = userId => ajax
    .get({url: path("users", userId, "can-login-as-users")})
    .then(response => Immutable.fromJS(response).toSet());

const updateUsersToLoginAs = (userId, userIdsToLogInAs) => {
  return ajax
      .put({
        url: path("users", userId, "can-login-as-users"),
        json: userIdsToLogInAs.toJS()
      })
      .then(response => Immutable.fromJS(response).toSet());
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <UsersApp theme={theme} {...props} />;
};
