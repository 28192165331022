import React from "react";
import pure from "js/common/views/pure";

export default pure(({
    onClick,
    style = {}
}) => (
    <i className="fa fa-times" title="Remove" style={{ ...removeBtnStyle, ...style }} onClick={onClick} />
));

const removeBtnStyle = {
    cursor: "pointer",
    fontSize: "1rem",
    color: "#d85050",
    textAlign: "center",
    backgroundColor: "transparent",
    transition: "all 0.3s ease",

    ":hover": {
        color: "#f00"
    }
};
