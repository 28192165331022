import React from "react";

import { reactComponentToMarionetteComponent } from "js/common/views/react-to-marionette";
import TrendSlide from "js/cubetv/cubes/trend-slide";

import Cube19 from "js/cube19.app";

const JST = window.JST;

Cube19.module("Views.CubeTv.Layouts", function(Layouts, App, Backbone, Marionette, $, _) {
    
    Layouts.LeftBigRight4 = Marionette.LayoutView.extend({

        template: JST["cubetv/layouts/fullscreen"],
        className: "fullscreen",

        regions: {
            slideRegion: ".slide-region"
        },

        initialize() {
            this.listenTo(this.model.trendModel, "change", this.update);
        },

        onShow() {
            this.hasBeenShown = true;
            this.renderTrendSlide();
        },

        update() {
            if (!this.hasBeenShown) {
                return;
            }

            this.renderTrendSlide();
        },

        renderTrendSlide() {
            const trendSlideProps = this.model.trendModel.toJSON();
            const trendSlide = reactComponentToMarionetteComponent(
                <TrendSlide { ...trendSlideProps } />
            );
            this.slideRegion.show(trendSlide);
        }

    });

});
