/** @jsxImportSource @emotion/react */

import React from "react";
import Tooltip from "react-tooltip";
import {jsx, css} from "@emotion/react";

import * as Colors from "js/common/cube19-colors";
import pure from "js/common/views/pure";
import UserAvatar from "js/common/views/user-avatar";

export default pure(({user, showUserPics, showUserNames, handleUserClick}) => {
  const customLabels = <ul className={labelListCss}>
    {user.get("pageId") !== null && <li css={labelListItemCss}>
      <span css={pageIconCss}><i className="fa fa-columns" /></span>
      Custom OneView page set
    </li>}

    {user.get("dealMusicId") !== null && <li css={labelListItemCss}>
      <span css={musicIconCss}><i className="fa fa-music" /></span>
      Custom deal music set
    </li>}

    {user.get("localeId") !== null && <li css={labelListItemCss}>
      <span css={localeIconCss}><i className="fa fa-globe" /></span>
      Custom locale set
    </li>}
  </ul>;

  const hasAttributeSet = user.get("pageId") !== null
      || user.get("dealMusicId") !== null
      || user.get("localeId") !== null;

  const userId = user.get("id");
  const fullName = user.get("fullName");

  return (
      <li key={userId}
          className={"TESTCAFE-user"}
          css={userListItemCss}
          data-tip={""}
          data-for={`users-info-${userId}`}>
        <a onClick={() => handleUserClick(userId, user.get("groupId"))}>
          {showUserPics && <UserAvatar
              user={user}
              css={hasAttributeSet ? userWithAttributesPicCss : userNoAttributesPicCss} />}
          {showUserNames &&
          <p css={userNameCss}>{fullName}</p>}
        </a>
        <Tooltip
            key={`${userId}-tooltip`}
            id={`users-info-${userId}`}
            place="top"
            type="dark"
            effect="solid">
          <div css={tooltipContentCss}>
            {fullName}
            {customLabels}
          </div>
        </Tooltip>
      </li>
  );
}, "GroupNodeUserDisc");

const labelListCss = css({padding: 0, margin: 0});
const labelListItemCss = css({whiteSpace: "nowrap"});

const labelIconStyle = {
  display: "inline-block",
  width: 18,
  height: 18,
  fontSize: 10,
  borderRadius: 2,
  lineHeight: "18px",
  textAlign: "center",
  marginRight: "0.5rem"
};
const pageIconCss = css({...labelIconStyle, background: Colors.pageColor});
const musicIconCss = css({...labelIconStyle, background: Colors.musicColor});
const localeIconCss = css({...labelIconStyle, background: Colors.localeColor});

const tooltipContentCss = css({textAlign: "center", whiteSpace: "nowrap"});

const userListItemCss = css({display: "flex"});

const userNameCss = css({fontSize: "12px"});

const userPicStyle = {
  borderWidth: 2
};
const userWithAttributesPicCss = css({...userPicStyle, borderColor: Colors.c19Yellow});
const userNoAttributesPicCss = css({...userPicStyle, borderColor: Colors.grey});
