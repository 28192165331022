/** @jsxImportSource @emotion/react */

import React from "react";
import {css, jsx} from "@emotion/react";
import {Row, Column} from "js/common/views/foundation-column-layout";
import {betterMemo} from "js/common/utils/more-memo";
import {IconButton} from "js/common/views/inputs/buttons";
import EditColumnsSubGroup from "js/admin/kpis/edit-kpis/tabs/edit-columns-sub-group";


const EditColumnsGroup = betterMemo({displayName: "EditColumnsGroup"}, ({
                                                                          theme,
                                                                          label,
                                                                          group,
                                                                          handleVisibleClick,
                                                                          searching,
                                                                          handleToggleCollapse,
                                                                          collapsedSectionIds,
                                                                        }) => {
  const toggleCollapse = (id) => {
    handleToggleCollapse && handleToggleCollapse(id)
  }

  const parentColumns = group.filter(c => c.get("readableJoinPath").count() === 1).filter(c => !c.get("visible"))
  const firstGroup = group.filter(g => g.get("readableJoinPath").count() === 1).first();
  const groupId = firstGroup ? firstGroup.get("joinPathStr") : label;

  // Render
  return (
      <div>
        {parentColumns.count() > 0 && <div style={{...columnGroupStyle(theme), position: 'relative', padding: parentColumns.count() > 0 ? "5px 5px" : "0"}} key={`column-group-${label}`}>
         <div onClick={() => toggleCollapse(groupId)} style={{cursor: 'pointer', display: parentColumns.count() > 0 ? "block" : "none"}}>
          {!searching && <i className={`fa fa-${!collapsedSectionIds.contains(groupId) ? 'minus' : 'plus'}`}
             style={{fontSize: "1rem", position: 'absolute', top: 14, right: 20}}/>}
          <h3 css={groupTitle(theme)}>{label}</h3>
        </div>
        <div style={{display: `${!collapsedSectionIds.contains(groupId) || searching ? 'block' : 'none'}`}}>
          {parentColumns
              .sortBy(c => c.get('label'))
              .map(column => {
                return (
                    <Row css={rowStyle(theme)} key={column.get("entityColumnId") + column.get("joinPathStr")}>
                      <Column small={12} style={columnStyle}>
                        {column.get("label")}
                        <div className="right">
                          <IconButton
                              icon="eye"
                              type="bright"
                              title="Make visible"
                              onClick={() => handleVisibleClick(column)}/>
                        </div>
                      </Column>
                    </Row>)
              })
          }
        </div>
      </div>
      }
      <EditColumnsSubGroup
          theme={theme}
          key={`group-${group}`}
          group={group}
          currentDepth={1}
          searching={searching}
          handleVisibleClick={handleVisibleClick}
          handleToggleCollapse={handleToggleCollapse}
          collapsedSectionIds={collapsedSectionIds}
      />
      </div>
  )
});

const groupTitle = theme => ({
  margin: "10px 10px 5px 10px",
  fontFamily: theme.typography.fontFamilyBold,
  fontSize: "14px !important",
  color: theme.palette.text.main,
  textTransform: "capitalize"
});

const rowStyle = (theme) => css`
  border-bottom: 1px solid ${theme.themeId === "light" ? theme.palette.border.light : "#393838"};
  font-size: 13px;

  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    border-bottom: none;
  }
`

const columnGroupStyle = theme =>({
  background: theme.themeId === "light" ? theme.palette.background.paper : "#1c1c22",
  borderRadius: 3,
  marginBottom: 10,
});

const columnStyle = {
  paddingLeft: "0.9375em",
  paddingRight: "0.9375em"
};

export default EditColumnsGroup;
