import * as numbers from "js/common/utils/numbers";

const getColorForPercentage = (percentage, theme) => {
    let color = "";
    if (percentage < 0.5) {
        color = theme ? theme.palette.error.main : "red";
    } else if (percentage >= 0.5 && percentage < 1.0) {
        color = theme ? theme.palette.warning.main : "yellow";
    } else if (percentage >= 1.0) {
        color = theme ? theme.palette.success.main : "green";
    }
    return color;
};

const getPercentageOfTargetComplete = (totalValue, targetValue) => {
    const roundedTarget = numbers.roundTo(targetValue, 3);
    const percent = roundedTarget === 0 ? 0 : totalValue / roundedTarget;
    return Math.floor(percent * 100) / 100;
};

const getPercentageOfTargetCompleteString = (totalValue, targetValue) => {
    return numbers.toPercentageStr(getPercentageOfTargetComplete(totalValue, targetValue));
};

export {
    getPercentageOfTargetComplete,
    getPercentageOfTargetCompleteString,
    getColorForPercentage
};
