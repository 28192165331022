import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";

import {Layout, Row, Column} from "js/common/views/foundation-column-layout";
import LoadingSpinner from "js/common/views/loading-spinner";
import ErrorMsg from "js/common/views/error";
import GroupUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import SelectedUserOrGroup from "js/admin/timezones/selected-user-or-group";
import TimezoneHistoryTable from "js/admin/timezones/history-table";
import InheritedTimezonesTable from "js/admin/timezones/inherited-timezones-table";
import HistoryCalculationJobsTable from "js/admin/timezones/history-calculation-jobs-table";
import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";

import Checkbox from "js/common/views/inputs/checkbox";
import * as auditor from "js/common/auditer";

const path = window.path;

const LOAD_DATA_ERROR = "Unable to load timezone history";

export default createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      showInvisibleUsers: false,
      assignedTo: null,
      assignedToId: null,
      history: Immutable.List(),
      isLoading: false,
      errors: Immutable.List()
    };
  },

  render() {
    const {isLoading, errors, assignedTo, assignedToId, showInvisibleUsers} = this.state;
    const isUserOrGroupSelected = !!assignedToId;
    const rowStyle = {marginTop: "1rem", marginBottom: "1rem"};
    return (
        <div>
          <AdminHeader>
            <Icon icon="map" />
            Set a User or Group's Timezone Location
          </AdminHeader>
          <Row style={rowStyle}>
            <Column small={12} medium={5}>
              <GroupUserPicker
                  label="Select a User or Group"
                  showInvisibleUsers={showInvisibleUsers}
                  onGroupClick={this.onGroupSelect}
                  onUserClick={this.onUserSelect} />
            </Column>
            <Column small={12} medium={3} end={true}>
              <Checkbox
                  label="Show Invisible Users"
                  style={{marginTop: -4}}
                  checked={showInvisibleUsers}
                  onCheck={(e, isChecked) => this.onShowInvisibleUsersChange(isChecked)} />
            </Column>
          </Row>
          {isUserOrGroupSelected &&
              <SelectedUserOrGroup
                  selected={assignedTo}
                  selectedId={assignedToId}
                  onGroupSelect={this.onGroupSelect} />}
          {!errors.isEmpty() && this.renderErrors()}
          {isUserOrGroupSelected &&
              (isLoading ? this.renderLoadingSpinner() : this.renderTimezoneHistory())}
          {isUserOrGroupSelected &&
              <InheritedTimezonesTable assignedTo={assignedTo} assignedToId={assignedToId} />}
          <HistoryCalculationJobsTable />
        </div>
    );
  },

  componentDidMount() {
    auditor.audit("timezone-admin:loaded");
  },

  renderErrors() {
    return (
        <Layout allSmall={12}>
          {this.state.errors.map(error => <ErrorMsg text={error} />)}
        </Layout>);
  },

  renderLoadingSpinner() {
    return (
        <div style={{margin: "3rem"}}>
          <LoadingSpinner iconSize={3} />
        </div>);
  },

  renderTimezoneHistory() {
    return (
        <TimezoneHistoryTable
            assignedTo={this.state.assignedTo}
            assignedToId={this.state.assignedToId}
            initialHistory={this.state.history}
            onChange={this.onHistoryChange} />);
  },

  onShowInvisibleUsersChange(isChecked) {
    this.setState({
      showInvisibleUsers: isChecked
    }, () => {
      const {assignedTo, assignedToId, history, errors} = this.state;
      const isUser = assignedTo === "USER";
      const isInvisibleUser = isUser && Users.getUser(assignedToId).get("state") === "INVISIBLE";
      if (!isChecked && isInvisibleUser) {
        this.setState({
          assignedTo: null,
          assignedToId: null,
          history: history.clear(),
          errors: errors.clear()
        });
      }
    });
  },

  onGroupSelect(groupId) {
    this.setState({
      assignedTo: "GROUP",
      assignedToId: groupId,
      errors: this.state.errors.clear(),
      isLoading: true
    }, () => {
      loadTimezoneHistoryForGroup(groupId)
          .then(history => {
            this.setState({
              history,
              isLoading: false
            });
          })
          .catch(err => {
            this.setState({
              errors: this.state.errors.push(LOAD_DATA_ERROR),
              isLoading: false
            });
          });
    });
  },

  onUserSelect(userId) {
    this.setState({
      assignedTo: "USER",
      assignedToId: userId,
      errors: this.state.errors.clear(),
      isLoading: true
    }, () => {
      loadTimezoneHistoryForUser(userId)
          .then(history => {
            this.setState({
              history,
              isLoading: false
            });
          })
          .catch(err => {
            this.setState({
              errors: this.state.errors.push(LOAD_DATA_ERROR),
              isLoading: false
            });
          });
    });
  },

  onHistoryChange(newhistory) {
    this.setState({
      errors: this.state.errors.clear(),
      history: newhistory
    });
  }

});

const loadTimezoneHistoryForGroup = groupId => ajax
    .get({url: path("groups", groupId, "locations")})
    .then(response => Immutable.fromJS(response).map(parseDatesInTimezoneAssignment));

const loadTimezoneHistoryForUser = userId => ajax
    .get({url: path("users", userId, "locations")})
    .then(response => Immutable.fromJS(response).map(parseDatesInTimezoneAssignment));

const parseDatesInTimezoneAssignment = timezoneAssignment => timezoneAssignment
    .set("startDate", parseDate(timezoneAssignment.get("startDate")))
    .set("endDate", parseDate(timezoneAssignment.get("endDate")));

const parseDate = (dateStr, pattern = "YYYY-MM-DD HH:mm:ss") => moment(dateStr, pattern);
