import Immutable from "immutable";

const isKpiNameDupe = (name, nameToCount) => nameToCount.get(name.trim()) > 1;

const getNameForValidation = kpi => (kpi.get("name") || "").toLowerCase().trim();

const validateKpi = (kpi, nameToCount) => {
  const name = getNameForValidation(kpi);
  const isBlank = !name;
  const isDupe = isKpiNameDupe(name, nameToCount);
  const templateNotSet = !kpi.get("templateId");
  return Immutable.Map({
    isNameBlank: isBlank,
    // NOTE we don't want to show a dupe message if a bunch of kpis have blank names
    isNameDupe: !isBlank && isDupe,
    templateNotSet
  });
};

const isValidKpi = (kpi, nameToCount) => {
  const name = getNameForValidation(kpi);
  const isBlank = !name;
  const isDupe = isKpiNameDupe(name, nameToCount);
  const hasTemplate = !!kpi.get("templateId");
  return !isBlank && !isDupe && hasTemplate;
};

const getIssuesWithWrappedKpi = (wk, nameToCount) => {
  const validationErrors = validateKpi(wk.get("kpi"), nameToCount);

  let issues = Immutable.List();
  if (validationErrors.get("isNameBlank")) {
    issues = issues.push("Blank name");
  }
  if (validationErrors.get("isNameDupe")) {
    issues = issues.push("Duplicate name");
  }
  if (validationErrors.get("templateNotSet")) {
    issues = issues.push("Template not set");
  }
  if (hasTestError(wk.get("testResult"))) {
    issues = issues.push("Tests");
  }
  if (wk.get("combineError")) {
    issues = issues.push("Inheritance failure")
  }
  if (wk.get("submitError")) {
    issues = issues.push("Submission failure")
  }
  return issues;
};

const hasTestError = testResult => testResult && (
    testResult.hasIn(["user", "response", "summary", "error"]) ||
    testResult.hasIn(["user", "response", "report", "error"]) ||
    testResult.hasIn(["user", "response", "trend", "error"]) ||
    testResult.hasIn(["user", "response", "leaderboard", "error"]) ||
    testResult.hasIn(["group", "response", "summary", "error"]) ||
    testResult.hasIn(["group", "response", "report", "error"]) ||
    testResult.hasIn(["group", "response", "trend", "error"]) ||
    testResult.hasIn(["group", "response", "leaderboard", "error"]) ||
    testResult.hasIn(["group", "response", "concurrency", "error"]));

export {
  getIssuesWithWrappedKpi,
  getNameForValidation,
  isValidKpi,
  hasTestError,
  isKpiNameDupe,
  validateKpi
};

