/** @jsxImportSource @emotion/react */
import React from "react";
import { jsx } from '@emotion/react';
import pure from "js/common/views/pure";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const InlineRadioButtons = pure(({ options = [], selected, onChange, isDisabled, style = {}, theme}) => (
    <div style={{ ...style, opacity: isDisabled ? 0.6 : 1 }}>
        {options.map((opt, i) => {
            const value = opt.value || opt;
            const label = opt.label || opt;
            const isSelected = value === selected;
            const isFirst = i === 0;
            const isLast = i === options.length - 1;
            let buttonStyle = getButtonStyle(isDisabled, theme);
            if (isFirst) {
                buttonStyle = {
                    ...buttonStyle,
                    borderLeft: `1px solid ${theme.themeId === "light" ? "#cdcdcd" : "#fff"}`,
                    borderTopLeftRadius: borderRadius,
                    borderBottomLeftRadius: borderRadius,
                    borderRight: `1px solid ${theme.themeId === "light" ? "#cdcdcd" : "#fff"}`
                };
            } else if (isLast) {
                buttonStyle = {
                    ...buttonStyle,
                    borderRight: `1px solid ${theme.themeId === "light" ? "#cdcdcd" : "#fff"}`,
                    borderTopRightRadius: borderRadius,
                    borderBottomRightRadius: borderRadius
                };
            }
            return (
                <div
                    key={`btn-${i}`}
                    value={value}
                    onClick={isDisabled ? () => {} : () => onChange(value)}
                    css={isSelected ? { ...buttonStyle, ...selectedButtonStyle(theme) } : buttonStyle} >
                    {label}
                </div>
            );
        })}
    </div>
));

const getButtonStyle = (isDisabled, theme) => ({
    ...buttonStyle(theme),
    opacity: 0.6,
    pointerEvents: isDisabled ? "none" : "all"
});

const borderRadius = "3px";

const buttonStyle = theme => ({
    display: "inline-block",
    verticalAlignt: "middle",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "0.85rem",
    textTransform: "uppercase",
    borderTop: `1px solid ${theme.themeId === "light" ? "#cdcdcd" : "#fff"}`,
    borderBottom: `1px solid ${theme.themeId === "light" ? "#cdcdcd" : "#fff"}`,
    borderRight: `1px solid ${theme.themeId === "light" ? "#cdcdcd" : "#fff"}`,
    opacity: 0.7,
    padding: "8px 5px 10px 5px",
    minWidth: 48,
    height: 36,
    transition: "all 0.3s ease",

    "&:hover": {
        background: theme.themeId === "light" ? "#3f75bd" : "#bbb126",
        color: theme.palette.text.inverted,
        opacity: 1
    }
});

const selectedButtonStyle = theme =>({
    background: theme.palette.primary.main,
    color: theme.palette.text.inverted,
    opacity: 1
});

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <InlineRadioButtons theme={theme} {...props} />;
};