import React from "react";
import Immutable from "immutable";

import Layout, {Column, Row} from "js/common/views/foundation-column-layout";
import pure from "js/common/views/pure";
import {getColorForPercentage} from "js/oneview/kpi-values-display";
import Info from "js/common/views/info-text-box";
import * as Users from "js/common/users";
import * as Numbers from "js/common/utils/numbers";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

export const columnHeadersByDataValue = Immutable.fromJS({
  rank: "Rank",
  userId: "Name",
  totalValueDisplay: "Value",
  targetValueDisplay: "Target",
  percentOfTarget: "% of Target"
});

const selectedColStyle = theme => ({
  color: theme.palette.primary.main,
  ":hover": {
    color: theme.palette.action.selected
  }
});

const unselectedColStyle = theme => ({
  color: theme.palette.textColor,
  ":hover": {
    color: theme.palette.action.selected
  }
});

const SortIcon = pure(({isDescending}) => {
  const iconStyle = {
    verticalAlign: isDescending ? "top" : "bottom",
    lineHeight: 0.5,
    paddingLeft: 5
  };
  return <i style={iconStyle} className={isDescending ? `fa fa-sort-desc` : `fa fa-sort-asc`} />;
});

const LeaderboardHeader = ({
  theme,
  onSortChange,
  sortField,
  sortDescending
}) => {
  const percentOfTargetColStyle = {textAlign: "center", padding: 0};
  return (
      <Layout
          small={[1, 5, 2, 2, 2]}
          rowStyle={{color: theme.palette.text.main, fontSize: "0.9rem"}}
          columnStyle={{padding: 10}}>
        <div style={{textAlign: "center"}}>
          {columnHeadersByDataValue.get("rank")}
        </div>
        <div style={{textAlign: "left"}}>
          {columnHeadersByDataValue.get("userId")}
        </div>
        <div key="value"
             style={sortField === "VALUE" ?
                 {textAlign: "right", ...selectedColStyle(theme)} :
                 {textAlign: "right", ...unselectedColStyle(theme)}
             }
             onClick={() => onSortChange("VALUE")}>
          {columnHeadersByDataValue.get("totalValueDisplay")}
          {sortField === "VALUE" && <SortIcon isDescending={sortDescending} />}
        </div>
        <div style={{textAlign: "right", paddingRight: 3}}>
          {columnHeadersByDataValue.get("targetValueDisplay")}
        </div>
        <div key="percent"
             style={sortField === "PERCENTAGE" ?
                 {...percentOfTargetColStyle, ...selectedColStyle(theme)} :
                 {...percentOfTargetColStyle, ...unselectedColStyle(theme)}}
             onClick={() => onSortChange("PERCENTAGE")}>
          {columnHeadersByDataValue.get("percentOfTarget")}
          {sortField === "PERCENTAGE" && <SortIcon isDescending={sortDescending} />}
        </div>
      </Layout>
  );
};

const UserLink = pure(({theme, label, onClick}) => <a style={linkStyle(theme)} onClick={onClick}>{label}</a>);

const LeaderboardRow = ({
  theme,
  data,
  onUserClick,
  isEvenRow,
  isSelected
}) => {
  const baseRowStyle = isEvenRow ? evenRowStyle(theme) : rowStyle(theme);
  const userId = data.get("userId");
  const percentOfTarget = data.get("percentOfTarget");
  const hasTarget = !!data.get("targetValueDisplay");
  const percentOfTargetColor = percentOfTarget ? getColorForPercentage(percentOfTarget, theme) : "";
  
  return (
      <Layout
          small={[1, 5, 2, 2, 2]}
          rowStyle={isSelected ? selectedRowStyle(theme) : baseRowStyle}
          columnStyle={{padding: 2}}>
        <div id={isSelected ? "selectedUser" : ""} style={{textAlign: "center"}}>
          {data.get("rank")}
        </div>
        <div style={{textAlign: "left"}}>
          <UserLink theme={theme} label={Users.getUser(userId).get("fullName")} onClick={() => onUserClick(userId)} />
        </div>
        <div style={{textAlign: "right"}}>{data.get("totalValueDisplay")}</div>
        <div style={{textAlign: "right", color: hasTarget ? theme.palette.textColor.secondary : theme.palette.textColor.main}}>
          {hasTarget ? data.get("targetValueDisplay") : "--"}
        </div>
        <div style={{textAlign: "center", color: hasTarget ? percentOfTargetColor : theme.palette.textColor.main}}>
          {hasTarget ? Numbers.toPercentageStr(percentOfTarget) : "--"}
        </div>
      </Layout>
  );
};

const Leaderboards = ({
  leaderboard,
  groupWarning,
  onSortChange,
  sortField,
  sortDescending,
  containerHeight,
  containerWidth,
  userId,
  onUserClick
}) => {
  const { theme } = React.useContext(CustomThemeContext);
  const screenBreakpoint = 800;
  const isSmallScreen = containerWidth < screenBreakpoint;
  const isLandscapeMode = containerWidth > containerHeight;
  const maxDataRowsContainerHeight = containerHeight - (isSmallScreen ? 215 : 105) - (!!groupWarning ? 70 : 0);
  const borderColor= theme.themeId === "light" ? "#eef4fc" : theme.palette.background.paper

  return <Row
      className="leaderboard-container"
      style={{maxWidth: "100%"}}>
    {!!groupWarning &&
    <Column small={12}>
      <Info text={groupWarning} style={{marginBottom: "0.5rem", backgroundColor: theme.palette.background.card}} />
    </Column>}

    <Column
        className="leaderboard-header"
        small={12}
        style={{backgroundColor: theme.palette.background.card, borderBottom: `2px solid ${borderColor}`, marginTop: "0.5rem"}}>
      <LeaderboardHeader
          theme={theme}
          onSortChange={onSortChange}
          sortField={sortField}
          sortDescending={sortDescending} />
    </Column>

    {!leaderboard.isEmpty() ?
        <Column 
            small={12}
            style={{
              paddingLeft: 0,
              paddingRight: 0,
              maxHeight: (isSmallScreen && isLandscapeMode) ? maxDataRowsContainerHeight + 50 :
                  maxDataRowsContainerHeight,
              overflow: "auto"
            }}>
          <div id="leaderboard-rows">
            {leaderboard.map((rowData, index) => (
                <LeaderboardRow
                    theme={theme}
                    key={index}
                    data={rowData}
                    onUserClick={onUserClick}
                    isEvenRow={index % 2 === 0}
                    isSelected={userId === rowData.get("userId")} />
            ))}
          </div>
        </Column> :
        <Column small={12} style={noUsersMsgStyle(theme)}>
          <div>There are no Users to show</div>
        </Column>}
  </Row>;
}

const linkStyle = theme => ({
  color: theme.palette.text.primary,

  ":hover": {
    color: theme.palette.action.hover
  }
});

const rowStyle = theme => ({
  width: "100%",
  marginLeft: 0,
  marginRight: 0,
  backgroundColor: theme.palette.background.card
});

const evenRowStyle = theme => ({
  ...rowStyle(theme),
  backgroundColor: theme.themeId === "light" ? "#eef4fc" : theme.palette.background.paper
});

const selectedRowStyle = theme => ({
  ...rowStyle(theme),
  borderRadius: "3px",
  backgroundColor: theme.palette.background.paper
});

const noUsersMsgStyle = theme => ({
  backgroundColor: theme.palette.background.card,
  padding: "10px 20px",
  borderWidth: "0.1px",
  borderStyle: "solid",
  borderColor: theme.palette.background.card,
  textAlign: "center"
});


Leaderboards.displayName = "Leaderboards";
export default Leaderboards;