/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";

import Drawer from "js/common/views/drawer";
import * as Styles from "js/common/views/filter-drawer/filters-drawer.styles";
import {TextButton} from "js/common/views/inputs/buttons";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const EditComponentDrawer = React.memo(({
  open,
  onRequestClose,
  componentInfo,
  dashboard,
  onDashboardChange,
  componentTypeToTemplate
}) => {
  const {theme} = React.useContext(CustomThemeContext);

  const [canApply, setCanApply] = React.useState(true);

  const existingComponentId = componentInfo?.componentId;
  const newComponent = componentInfo?.newComponent;
  const isExistingComponent = !!existingComponentId;
  const componentId = existingComponentId || newComponent?.get("id");

  const component = React.useMemo(
      () => {
        if (isExistingComponent) {
          return dashboard
              .getIn(["json", "components"], Immutable.List())
              .find(c => c.get("id") === componentId) || Immutable.Map();
        } else {
          return newComponent || Immutable.Map();
        }
      },
      [isExistingComponent, componentId, newComponent, dashboard]);

  const [localComponent, setLocalComponent] = React.useState(component);
  // TODO JC - is componentId needed as a dep here?
  React.useEffect(() => setLocalComponent(component), [componentId, component]);

  // TODO support being able to clear out all data
  //    this allows the compoonent type to change
  //    this will be very helpful for switching between small/big data grids without losing the pivot setup
  const applyChanges = React.useCallback(() => {
    onDashboardChange(dashboard.updateIn(
        ["json", "components"],
        Immutable.List(),
        components => {
          if (isExistingComponent) {
            return components.map(c => {
              if (c.get("id") === componentId) {
                return localComponent;
              } else {
                return c;
              }
            });
          } else {
            return components.push(localComponent);
          }
        }), {reloadComponentId: componentId});
    onRequestClose();
  }, [
    componentId,
    isExistingComponent,
    dashboard,
    onDashboardChange,
    localComponent,
    onRequestClose
  ]);

  const cancelChanges = React.useCallback(() => {
    setLocalComponent(component);
    onRequestClose();
  }, [onRequestClose, component]);

  const handleComponentChange = React.useCallback((component, {canApply} = {canApply: true}) => {
    setCanApply(canApply);
    setLocalComponent(component);
  }, []);

  const template = componentTypeToTemplate.get(component.get("type"));
  const getterForEditor = template && template.get("getEditorReactComponent");
  const EditorReactComponent = getterForEditor && getterForEditor();

  return <Drawer
      dataProductId={open ? "edit-panel-drawer-open" : "edit-panel-drawer-closed"}
      width="95%"
      onRequestClose={onRequestClose}
      open={open}
      openRight={true}
      title={template && template.get("getTitle")(component)}>
    {EditorReactComponent && <EditorReactComponent
        component={localComponent}
        onComponentChange={handleComponentChange} />}
    <div css={Styles.drawerControlsStyle(theme, "95%")}>
      <TextButton
          type="primary"
          label="Apply"
          disabled={!canApply}
          onClick={applyChanges}
          css={Styles.applyButtonStyle} />
      <TextButton
          label="Cancel"
          onClick={cancelChanges}
          css={Styles.resetButtonStyle} />
    </div>
  </Drawer>;
});

export default EditComponentDrawer;
