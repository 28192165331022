import Immutable from "immutable";

export default (kpiData, key) => {
    if (!kpiData || !kpiData.has(key)) {
        return Immutable.fromJS({ value: 0 });
    }

    const obj = kpiData.get(key);
    if (!obj.get("value")) {
        return obj.set("value", 0);
    } else {
        return obj;
    }
};
