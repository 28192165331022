import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";

import pure from "js/common/views/pure";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Select from "js/common/views/inputs/immutable-react-select";
import AdminHeader from "js/admin/common/admin-header";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import NumberField from "js/common/views/inputs/number-field";
import {Layout} from "js/common/views/foundation-column-layout";
import * as ajax from "js/common/ajax";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as auditor from "js/common/auditer";

import {TextField} from "@mui/material";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const dateToUseOptions = Immutable.fromJS([
  {
    label: "Created Date",
    value: "CREATED"
  }, {
    label: "Event Date",
    value: "EVENT"
  }]);

const typeOptions = Immutable.fromJS([
  {
    label: "In Progress",
    value: "LIVE"
  }, {
    label: "Placement",
    value: "PLACED"
  }, {
    label: "Rejection / Dropout / Cancellation",
    value: "DEAD"
  }]);

const path = window.path;

const JobsPipelineApp = createReactClass({
  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      loadingPipelines: false,
      idToPipeline: Immutable.Map(),
      idToStage: Immutable.Map(),

      savingSelectedPipeline: false,

      originalIdToPipeline: Immutable.Map(),
      originalIdToStage: Immutable.Map(),

      loadingEmploymentTypes: false,
      employmentTypes: Immutable.List(),

      selectedPipelineId: null
    };
  },

  componentDidMount() {
    Promise.all([
      this.loadAndSetPipelines(),
      this.loadAndSetEmploymentTypes()
    ]).then((values) => {
      auditor.audit("jobs-pipeline-admin:loaded");
    });
  },

  loadAndSetPipelines() {
    this.setState({loadingPipelines: true});
    return loadPipelines()
        .then(pipelines => {
          const idToPipeline = indexBy(p => p.get("id"), pipelines);
          this.setState({idToPipeline});
          return loadStages();
        })
        .then(stages => {
          const idToStage = indexBy(s => s.get("id"), stages);
          this.setState({idToStage, loadingPipelines: false});
          setTimeout(this.checkpointDataAsOriginal);
        });
  },

  loadAndSetEmploymentTypes() {
    this.setState({loadingEmploymentTypes: true});
    return loadEmploymentTypes()
        .then(employmentTypes => this.setState({employmentTypes, loadingEmploymentTypes: false}));
  },

  render() {
    if (this.state.selectedPipelineId) {
      return this.renderEditPipelinePage();
    } else {
      return this.renderAllPipelinesPage();
    }
  },

  renderEditPipelinePage() {
    const pipeline = this.state.idToPipeline.get(this.state.selectedPipelineId);
    if (this.state.savingSelectedPipeline) {
      return "Saving " + pipeline.get("name");
    }

    const stagesForPipeline = this.state.idToStage
        .valueSeq()
        .filter(s => s.get("pipelineId") === pipeline.get("id"))
        .toList();

    return <EditPipelinePage
        theme={this.props.theme}
        pipeline={pipeline}
        stages={stagesForPipeline}
        employmentTypes={this.state.employmentTypes}
        onChangePipeline={p => this.setPipeline(p)}
        onChangeStages={stages => this.setStagesForPipeline(pipeline.get("id"), stages)}
        onRequestSave={this.handleSaveChangesToSelectedPipeline}
        onRequestBack={() => {
          this.setState({selectedPipelineId: null});
          this.restoreOriginalData();
        }} />;
  },

  renderAllPipelinesPage() {
    if (this.state.loadingPipelines) {
      return "Loading...";
    } else {
      return <PipelineListPage
          theme={this.props.theme}
          onSelectPipeline={selectedPipelineId => this.setState({selectedPipelineId})}
          onChangePipelines={pipelines => this.setState({idToPipeline: indexBy(p => p.get("id"), pipelines)})}
          onRequestSave={this.handleSavePipelines}
          onRequestDelete={this.handleDeletePipeline}
          onRequestCreate={this.handleCreatePipeline}
          onRequestClone={this.handleClonePipeline}
          pipelines={this.state.idToPipeline.valueSeq()}
          stages={this.state.idToStage.valueSeq()} />;
    }
  },

  handleClonePipeline(pipelineId) {
    this.setState({loadingPipelines: true});

    const pipelineToClone = this.state.idToPipeline.get(pipelineId);
    const stagesToClone = this.state.idToStage.valueSeq().filter(s => s.get("pipelineId") === pipelineId);
    createPipeline(pipelineToClone
        .set("id", getTempId())
        .set("name", "Clone of " + pipelineToClone.get("name"))
        .set("ordering", this.state.idToPipeline.count() + 1))
        .then(clonedPipeline => {
          const stagesWithCorrectIds = stagesToClone.map(s => s
              .set("id", getTempId())
              .set("pipelineId", clonedPipeline.get("id")));
          this.setPipeline(clonedPipeline);
          return updateStagesForPipeline(clonedPipeline.get("id"), stagesWithCorrectIds);
        })
        .then(clonedStages => {
          const idToStage = clonedStages.reduce(
              (idToStage, clonedStage) => idToStage.set(clonedStage.get("id"), clonedStage),
              this.state.idToStage);
          this.setState({idToStage, loadingPipelines: false});
          setTimeout(this.checkpointDataAsOriginal);
        });
  },

  handleDeletePipeline(pipelineId) {
    deletePipeline(pipelineId).then(() => {
      this.setState({idToPipeline: this.state.idToPipeline.delete(pipelineId)});
      setTimeout(this.checkpointDataAsOriginal);
    });
  },

  handleCreatePipeline(pipeline) {
    createPipeline(pipeline).then(pipeline => {
      this.setPipeline(pipeline);
      setTimeout(this.checkpointDataAsOriginal);
    });
  },

  handleSaveChangesToSelectedPipeline() {
    const pipeline = this.state.idToPipeline.get(this.state.selectedPipelineId);
    const stages = this.state.idToStage
        .valueSeq()
        .filter(s => s.get("pipelineId") === pipeline.get("id"));
    this.setState({savingSelectedPipeline: true});
    Promise
        .all([
          updatePipeline(pipeline),
          updateStagesForPipeline(pipeline.get("id"), stages)
        ])
        .then(([updatedPipeline, updatedStages]) => {
          this.setStagesForPipeline(updatedPipeline.get("id"), updatedStages);
          this.setPipeline(updatedPipeline);
          this.setState({savingSelectedPipeline: false});
          setTimeout(this.checkpointDataAsOriginal);
        });
  },

  handleSavePipelines() {
    this.setState({loadingPipelines: true});
    Promise
        .all(this.state.idToPipeline.valueSeq().map(updatePipeline))
        .then(pipelines => {
          pipelines = Immutable.List(pipelines);
          this.setState({
            loadingPipelines: false,
            idToPipeline: indexBy(p => p.get("id"), pipelines)
          });
        });
  },

  setPipeline(pipeline) {
    this.setState({
      idToPipeline: this.state.idToPipeline.set(pipeline.get("id"), pipeline)
    });
  },

  setStagesForPipeline(pipelineId, stages) {
    const stagesForOtherPipelines = this.state.idToStage.filter(s => s.get("pipelineId") !== pipelineId);
    const idToStage = stages.reduce(
        (idToStage, stage) => idToStage.set(stage.get("id"), stage),
        stagesForOtherPipelines);
    this.setState({idToStage});
  },

  restoreOriginalData() {
    this.setState({
      idToStage: this.state.originalIdToStage,
      idToPipeline: this.state.originalIdToPipeline
    });
  },

  checkpointDataAsOriginal() {
    this.setState({
      originalIdToStage: this.state.idToStage,
      originalIdToPipeline: this.state.idToPipeline
    });
  }

});

const PipelineListPage = pure(({
  theme,
  pipelines,
  stages,
  onSelectPipeline,
  onChangePipelines,
  onRequestSave,
  onRequestDelete,
  onRequestCreate,
  onRequestClone
}) => {
  return (
      <div>
        <AdminHeader>
          Create and Edit Pipelines
        </AdminHeader>
        <div style={{padding: "1rem"}}>
          <TextButton
              label="Create Pipeline"
              style={{marginRight: "1rem"}}
              onClick={() => onRequestCreate(defaultPipeline(pipelines.count()))} />
          <TextButton label="Save New Pipeline Order" onClick={onRequestSave} />
        </div>
        <div style={{padding: "1rem"}}>
          {pipelines
              .sortBy(p => p.get("ordering"))
              .map(pipeline => {
                const stagesForPipeline = stages.filter(s => s.get("pipelineId") === pipeline.get("id"));
                return (
                    <div
                        key={pipeline.get("id")}
                        onClick={() => onSelectPipeline(pipeline.get("id"))}
                        style={{
                          marginBottom: "1rem",
                          padding: "0.5rem",
                          display: "flex",
                          backgroundColor: theme.themeId === "light"
                              ? theme.palette.background.paper
                              : theme.palette.background.card
                        }}>
                        <div style={{display: "flex", flexGrow: 1}}>
                        {getPipelineTitle(pipeline, stagesForPipeline, theme)}
                      </div>
                      <div style={{display: "flex", float: "right"}}>
                        <IconButton
                            customStyle={{fontSize: "1.4rem"}}
                            icon="copy"
                            title={"Clone " + pipeline.get("name")}
                            onClick={e => {
                              e.stopPropagation();
                              onRequestClone(pipeline.get("id"));
                            }}
                            size="large" />
                        <IconButton
                            customStyle={{fontSize: "1.4rem"}}
                            icon="arrow-up"
                            title={"Move " + pipeline.get("name") + " up"}
                            onClick={e => {
                              e.stopPropagation();
                              onChangePipelines(reorderList("ordering", pipeline.get("id"), pipelines, true));
                            }}
                            size="large" />
                        <IconButton
                            customStyle={{fontSize: "1.4rem"}}
                            icon="arrow-down"
                            title={"Move " + pipeline.get("name") + " down"}
                            onClick={e => {
                              e.stopPropagation();
                              onChangePipelines(reorderList("ordering", pipeline.get("id"), pipelines, false));
                            }}
                            size="large" />
                        <IconButton
                            customStyle={{fontSize: "1.4rem"}}
                            icon="times"
                            type="alert"
                            title={"Delete " + pipeline.get("name")}
                            onClick={e => {
                              e.stopPropagation();
                              onRequestDelete(pipeline.get("id"));
                            }}
                            size="large" />
                      </div>
                    </div>
                );
              })
          }
        </div>
      </div>
  );
}, "PipelineListPage");

const EditPipelinePage = ({
  pipeline,
  stages,
  employmentTypes,
  onChangePipeline,
  onChangeStages,
  onRequestBack,
  onRequestSave
}) => {
  const employmentTypesForPipeline = getApplyToMatches(pipeline, "employmentType")
      .map(e => e.trim().toLowerCase())
      .toSet();
  const idToStage = indexBy(s => s.get("id"), stages);
  return (
      <div>
        <AdminHeader>
          Edit {pipeline.get("name")}
        </AdminHeader>
        <div style={{padding: "1rem"}}>
          <TextButton
              label="Back To Pipelines"
              onClick={onRequestBack} />
          <TextButton
              label="Save Changes"
              style={{marginLeft: "1rem"}}
              onClick={onRequestSave} />
          <TextButton
              label="Add New Stage"
              style={{marginLeft: "1rem"}}
              onClick={() => onChangeStages(stages.push(defaultStage(pipeline.get("id"), stages.count())))} />
          <br />
          <br />
          <TextField
              variant="standard"
              label="Pipeline Name"
              value={pipeline.get("name")}
              onChange={e => onChangePipeline(pipeline.set("name", e.target.value.substring(0, 64)))} />
          <br />
          <br />
          <div>
            <Select
                options={employmentTypes
                    .map(e => Immutable.Map({label: e, value: e.trim().toLowerCase()}))
                    .groupBy(opt => opt.get("value"))
                    .map(opts => opts.first())
                    .valueSeq()}
                placeholder="Choose Employment Types..."
                selectedValues={employmentTypesForPipeline}
                onChange={employmentTypes => onChangePipeline(updateApplyTos(
                    pipeline,
                    "employmentType",
                    employmentTypes))}
                isMulti={true}
                isClearable={true}
                isSearchable={true} />
          </div>
          <br />
          <h4>Stages</h4>
          <div>
            {stages
                .sortBy(s => s.get("ordering"))
                .map(stage => {
                  return (
                      <div
                          key={stage.get("id")}
                          style={{
                            position: "relative",
                            marginBottom: "1rem",
                            padding: "0.5rem",
                            border: "solid 3px #555"
                          }}>
                        <Layout small={[4, 3]} floatLastColumnLeft={true}>
                          <TextField
                              variant="standard"
                              style={{display: "inline-block", marginBottom: "0.5rem"}}
                              label="Stage Name"
                              fullWidth={true}
                              value={stage.get("name")}
                              onChange={e => {
                                onChangeStages(idToStage
                                    .update(stage.get("id"), s => s.set("name", e.target.value.substring(0, 64)))
                                    .valueSeq());
                              }} />
                          <NumberField
                              style={{display: "inline-block", marginBottom: "0.5rem"}}
                              range={[0, 100]}
                              fullWidth={true}
                              label="% Chance of Fill"
                              value={Math.floor(stage.get("weighting") * 100)}
                              onChange={value => {
                                onChangeStages(idToStage
                                    .update(stage.get("id"), s => s.set("weighting", value / 100))
                                    .valueSeq());
                              }} />
                        </Layout>
                        <Layout small={[3, 3, 6]} floatLastColumnLeft={true}>
                          <Select
                              options={typeOptions}
                              placeholder=""
                              selectedValue={stage.get("type")}
                              onChange={type => {
                                onChangeStages(idToStage
                                    .update(stage.get("id"), s => s.set("type", type))
                                    .valueSeq());
                              }}
                              isMulti={false}
                              isClearable={false}
                              isSearchable={false} />
                          <Select
                              options={dateToUseOptions}
                              placeholder=""
                              selectedValue={stage.get("dateToUse")}
                              onChange={dateToUse => {
                                onChangeStages(idToStage
                                    .update(stage.get("id"), s => s.set("dateToUse", dateToUse))
                                    .valueSeq());
                              }}
                              isMulti={false}
                              isClearable={false}
                              isSearchable={false} />
                          <Select
                              options={getKpis(stage.get("kpiIds"))}
                              keys={["id", "name"]}
                              placeholder="Choose Metrics For Stage..."
                              selectedValues={stage.get("kpiIds")}
                              onChange={kpiIds => {
                                onChangeStages(idToStage
                                    .update(stage.get("id"), s => s.set("kpiIds", kpiIds))
                                    .valueSeq());
                              }}
                              isMulti={true}
                              isClearable={true}
                              isSearchable={true} />
                        </Layout>
                        <div style={{position: "absolute", top: 0, right: 0}}>
                          <IconButton
                              customStyle={{fontSize: "1.4rem"}}
                              icon="arrow-up"
                              title={"Move " + stage.get("name") + " up"}
                              onClick={e => {
                                e.stopPropagation();
                                onChangeStages(reorderList("ordering", stage.get("id"), stages, true));
                              }}
                              size="large" />
                          <IconButton
                              customStyle={{fontSize: "1.4rem"}}
                              icon="arrow-down"
                              title={"Move " + stage.get("name") + " down"}
                              onClick={e => {
                                e.stopPropagation();
                                onChangeStages(reorderList("ordering", stage.get("id"), stages, false));
                              }}
                              size="large" />
                          <IconButton
                              customStyle={{fontSize: "1.4rem"}}
                              icon="times"
                              type="alert"
                              title={"Delete " + stage.get("name")}
                              onClick={e => {
                                e.stopPropagation();
                                onChangeStages(idToStage.delete(stage.get("id")).valueSeq());
                              }}
                              size="large" />
                        </div>
                      </div>
                  );
                })
            }
          </div>
        </div>
      </div>
  );
};

const updateApplyTos = (pipeline, field, matches) => {
  const index = pipeline.get("applyTos").findIndex(at => at.get("field") === field);
  return pipeline.setIn(["applyTos", index], Immutable.Map({field, matches}));
};

const reorderList = (orderKey, itemIdToMove, items, shouldMoveUp) => {
  const change = shouldMoveUp ? -3 : 3;
  return items
      .sortBy(p => p.get(orderKey))
      .map((p, i) => {
        if (p.get("id") === itemIdToMove) {
          return p.set(orderKey, (i * 2) + change);
        } else {
          return p.set(orderKey, i * 2);
        }
      })
      .sortBy(p => p.get(orderKey))
      .map((p, i) => p.set(orderKey, i));
};

const defaultPipeline = count => Immutable.fromJS({
  id: getTempId(),
  ordering: (count + 1),
  name: "New Pipeline " + (count + 1),
  defaultWeighting: 0,
  applyTos: [],
  visibleColumns: ["CLIENT", "JOB_TITLE", "OWNER", "JOB_VALUE", "JOB_ADDED_DATE", "LATEST_ACTIVITY_DATE"]
});

const defaultStage = (pipelineId, count) => Immutable.fromJS({
  id: getTempId(),
  name: "New Stage " + (count + 1),
  ordering: (count + 1),
  kpiIds: [],
  dateToUse: "CREATED",
  type: "LIVE",
  weighting: 0.5,
  gracePeriodInDays: 0,
  pipelineId: pipelineId
});

const getApplyToMatches = (pipeline, field) => {
  const applyTo = pipeline.get("applyTos").find(at => at.get("field") === field);
  if (applyTo) {
    return applyTo.get("matches");
  } else {
    return Immutable.List();
  }
};

let tempId = 0;
const getTempId = () => {
  tempId = tempId - 1;
  return tempId;
};

const getPipelineTitle = (pipeline, stages, theme) => {
  const employmentTypes = getApplyToMatches(pipeline, "employmentType");
  const priorities = getApplyToMatches(pipeline, "priority");
  const stageNames = stages.sortBy(s => s.get("ordering")).map(s => s.get("name"));
  return (
      <span>
            <h3 style={{color: theme.palette.primary.main, textTransform: "uppercase"}}>{pipeline.get("name")}</h3>
            <span>For employment types: {employmentTypes.isEmpty() ? "[all]" : employmentTypes.sort().join(", ")}</span>
            <br />
            <span>For priorities: {priorities.isEmpty() ? "[all]" : priorities.sort().join(", ")}</span>
            <br />
            <span>Stages: {stageNames.join(", ")}</span>
        </span>
  );
};

const indexBy = (f, xs) => xs.groupBy(f).map(xs => xs.first());

const validQueries = Immutable.Set.of(
    "CV_SENT",
    "TOTAL_CV_SENT",
    "INTERVIEW",
    "OFFER",
    "PLACEMENT",
    "PLACEMENTS_IGNORING_SPLITS",
    "SALES",
    "CALL",
    "MEETING"
);

const getKpis = selectedKpis => Immutable
    .fromJS(kpiRepo.getAll().toJSON())
    .filter(k => (!k.get("deleted") && k.get("visible") && validQueries.contains(k.get("type").get("query")))
        || selectedKpis.contains(k.get("id")));

const loadEmploymentTypes = () => ajax
    .get({url: "job-pipeline/employment-types"})
    .then(es => Immutable.Set(es).filter(e => !!e));

const createPipeline = pipeline => ajax
    .post({
      url: "job-pipeline/pipelines",
      json: pipeline.toJS()
    })
    .then(pipeline => Immutable.fromJS(pipeline));

const updatePipeline = pipeline => ajax
    .put({
      url: path("job-pipeline/pipelines", pipeline.get("id")),
      json: pipeline.toJS()
    })
    .then(pipeline => Immutable.fromJS(pipeline));

const deletePipeline = pipelineId => ajax
    .del({url: path("job-pipeline/pipelines", pipelineId)});

const updateStagesForPipeline = (pipelineId, stages) => ajax
    .put({
      url: path("job-pipeline/pipelines", pipelineId, "stages"),
      json: stages
    })
    .then(stages => Immutable.fromJS(stages));

const loadPipelines = () => ajax
    .get({url: "job-pipeline/pipelines"})
    .then(pipelines => Immutable.fromJS(pipelines));

const loadStages = () => ajax
    .get({url: "job-pipeline/pipelines/stages"})
    .then(x => Immutable.fromJS(x));

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <JobsPipelineApp theme={theme} {...props} />;
};
