import * as Immutable from "immutable";
import * as ajax from "js/common/ajax";

const loadAll = () =>
    ajax
      .get({url: "locales"})
      .then(x => Immutable.fromJS(x));

export {
  loadAll
};
