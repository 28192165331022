import React from "react";
import pure from "js/common/views/pure";

export default pure(({ theme, label, onClick, customStyle = {} }) => (
    <a style={{ ...style(theme), ...customStyle }} onClick={onClick}>{label}</a>
));


const style = theme => ({
    fontSize: "0.9rem",
    textTransform: "uppercase",
    color: theme.palette.primary.main,

    ":hover": {
        color: theme.palette.action.hover,
        textDecoration: "underline"
    }
});
