import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import pure from "js/common/views/pure";
import PureRenderMixin from "react-addons-pure-render-mixin";

import SelectField from "@mui/material/Select";
import SlideTimeframePicker from "js/admin/cubetv/slide-timeframe-picker";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import Layout from "js/common/views/foundation-column-layout";
import LabelledComponent from "js/common/views/labelled-component";
import ImmutableTagFilter from "js/common/views/inputs/tag-picker/immutable-tag-filter";
import GroupPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import { TextButton, IconButton } from "js/common/views/inputs/buttons";
import ConfigContainer from "js/admin/cubetv/config-container";
import { getDefaultKpiSummary } from "js/admin/cubetv/trend/default-trend-slide";

import currentClient from "js/common/repo/backbone/current-client";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Groups from "js/common/groups";


import { TextField, MenuItem, FormControl, InputLabel } from '@mui/material';

export default createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes:{
        timeframes: ImmutablePropTypes.list.isRequired,
        kpiSummaries: ImmutablePropTypes.map.isRequired,
        onChange: ReactPropTypes.func.isRequired
    },

    render() {
        const { kpiSummaries, timeframes } = this.props;
        const kpis = kpiSummaries.get("kpis");
        return (
            <div>
                <Layout allSmall={12}>
                    {kpis.map((kpi, index) =>
                        <EditKpiSummary
                            key={`kpi-${index}`}
                            index={index}
                            timeframes={timeframes}
                            kpiSummary={kpi}
                            isDeletable={kpis.count() > 1}
                            onDelete={this.handleDelete}
                            onChange={this.handleChange} />)}
                </Layout>
                <Layout allSmall={12} smallCentered={true} columnStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <TextButton
                        icon="plus"
                        label="Add Another Metric (max 4)"
                        disabled={kpis.count() === 4}
                        onClick={this.handleAdd} />
                </Layout>
            </div>
        );
    },

    handleChange(index, kpi) {
        this.props.onChange(this.props.kpiSummaries.setIn([ "kpis", index ], kpi));
    },

    handleAdd() {
        this.props.onChange(this.props.kpiSummaries.update("kpis", kpis => kpis.push(getDefaultKpiSummary())));
    },

    handleDelete(index) {
        this.props.onChange(this.props.kpiSummaries.deleteIn([ "kpis", index ]));
    }

});

const ordinal = [ "1st", "2nd", "3rd", "4th" ];

const EditKpiSummary = pure(({
    index,
    kpiSummary,
    onChange,
    timeframes,
    isDeletable,
    onDelete
}) => {
    const currentTimeframeId = kpiSummary.get("timeframeId").toLowerCase();
    const visibleTimeframes = timeframes
        .filter(t => (t.get("visible") && !t.get("isDeleted")) || t.get("id") === currentTimeframeId);
    const label = (
        <div>
            {ordinal[index]} Metric
            {isDeletable &&
                <div style={{ float: "right", marginTop: -3 }}>
                    <IconButton
                        icon="times"
                        type="bright"
                        size="small"
                        hoverType="alert"
                        onClick={() => onDelete(index)} />
                </div>}
        </div>
    );
    return (
        <ConfigContainer key={index} label={label} style={{ marginTop: "1rem", paddingBottom: "1rem" }}>
            <Layout allSmall={12}>
                <TextField variant="standard"
                    style={{marginTop: "0.5rem"}}
                    label="Title"
                    value={kpiSummary.get("title")}
                    onChange={e => onChange(index, kpiSummary.set("title", e.target.value.substring(0, 60)))} />
            </Layout>
            <Layout allSmall={12} allMedium={6}>
                <LabelledKpiPicker
                    label="Metric to Display"
                    placeholder="Select a Metric"
                    multiSelect={false}
                    clearable={false}
                    kpis={getKpis()}
                    selectedKpiId={kpiSummary.get("kpiId")}
                    onChange={kpiId => {
                        let newKpiSummary = kpiSummary.set("kpiId", kpiId);
                        if (kpiSummary.get("title") === getDefaultTitle(kpiSummary)) {
                            newKpiSummary = newKpiSummary.set("title", getDefaultTitle(newKpiSummary));
                        }
                        onChange(index, newKpiSummary);
                    }} />
                    <div style={{paddingTop: "1.2rem"}}>
                      <FormControl style={{width: 150}}>
                        <InputLabel>What to Display</InputLabel>
                        <SelectField variant="standard"
                            value={kpiSummary.get("expectedData")}
                            onChange={e => {
                              let newKpiSummary = kpiSummary.set("expectedData", e.target.value);
                              if (kpiSummary.get("title") === getDefaultTitle(kpiSummary)) {
                                newKpiSummary = newKpiSummary.set("title", getDefaultTitle(newKpiSummary));
                              }
                              onChange(index, newKpiSummary);
                            }}>
                          <MenuItem value="total">Value</MenuItem>
                          <MenuItem value="target">Target</MenuItem>
                          <MenuItem value="expectedValue">Expected Target</MenuItem>
                        </SelectField>
                      </FormControl>
                    </div>

            </Layout>
            {currentClient.canAccessApp("TAGS_ADMIN") &&
                <Layout allSmall={12} allMedium={4} columnStyle={{ marginTop: "1rem" }}>
                    <ImmutableTagFilter
                        label="Match Any Tags"
                        onChange={tagIds => onChange(index, kpiSummary.set("matchAnyTagIds", tagIds))}
                        multiple={true}
                        tagIds={kpiSummary.get("matchAnyTagIds")} />
                    <ImmutableTagFilter
                        label="Match All Tags"
                        onChange={tagIds => onChange(index, kpiSummary.set("matchAllTagIds", tagIds))}
                        multiple={true}
                        tagIds={kpiSummary.get("matchAllTagIds")} />
                    <ImmutableTagFilter
                        label="Exclude Tags"
                        onChange={tagIds => onChange(index, kpiSummary.set("excludedTagIds", tagIds))}
                        multiple={true}
                        tagIds={kpiSummary.get("excludedTagIds")} />
                </Layout>}
            <Layout allSmall={12} allMedium={6} columnClass="margin-top">
                <LabelledTimeframePicker
                    selectedTimeframeId={currentTimeframeId}
                    timeframes={visibleTimeframes}
                    onChange={timeframeId => onChange(index, kpiSummary.set("timeframeId", timeframeId))} />
                <LabelledGroupPicker
                    hierarchy={Groups.getHierarchyWithUsers()}
                    excludeUsers={true}
                    qualifierType="GROUP"
                    qualifierId={kpiSummary.get("groupId")}
                    onGroupClick={groupId => onChange(index, kpiSummary.set("groupId", groupId))} />
            </Layout>
        </ConfigContainer>);
});

const getDefaultTitle = kpiSummary => {
    const expectedData = kpiSummary.get("expectedData");
    const kpi = kpiRepo.get(kpiSummary.get("kpiId"));
    const kpiName = kpi.get("name");
    switch (expectedData) {
        case "total":
            return kpiName;
        case "target":
            return `${kpiName} Target`;
        case "expectedValue":
            return `${kpiName} Expected Target`;
        default:
            throw new Error("Unsupported expectedData type:",expectedData);
    }
};

const LabelledKpiPicker = pure(({ ...props }) => (
    <LabelledComponent label="Metric" style={{ marginTop:"15px" }}>
        <KpiPicker {...props} />
    </LabelledComponent>));

const LabelledGroupPicker = pure(({ ...props }) => (
    <LabelledComponent label="Group" icon="group">
        <GroupPicker {...props} />
    </LabelledComponent>));

const LabelledTimeframePicker = pure(({ ...props }) => (
    <LabelledComponent label="Timeframe" icon="calendar-o">
        <SlideTimeframePicker {...props} />
    </LabelledComponent>));

const getKpis = () => Immutable
    .fromJS(kpiRepo.getAll().toJSON())
    .filter(kpi => kpi.get("visible"));
