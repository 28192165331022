
const parseQueryString = rawQueryString => {
	const parts = rawQueryString
		.replace("?", "")
		.split("&")
		.map(partStr => partStr.split("="));

	const partsMap = {};
	parts.forEach(partArr => {
		const key = decode(partArr[0]);
		const value = decode(partArr[1]);
		partsMap[key] = value;
	});
	return partsMap;
};

const decode = window.decodeURIComponent;

const currentQueryString = parseQueryString(window.location.search);

const addParam = (key, value) => {
	if (window.history.replaceState) {
		let searchParams = new URLSearchParams(window.location.search);
		searchParams.set(key, value);
		let newUrl = window.location.protocol
				+ "//"
				+ window.location.host
				+ window.location.pathname
				+ "?"
				+ searchParams.toString();
		window.history.replaceState({path: newUrl}, "", newUrl);
	}
};

const deleteParam = (paramKey) => {
	if (window.history.replaceState) {
		const url = window.location.href;
		let r = new URL(url);
		r.searchParams.delete(paramKey);
		const newUrl = r.href;
		window.history.replaceState({path: newUrl}, "", newUrl);
	}
};

const replaceParams = (queryString) => {
	if (window.history.replaceState) {
		const url = window.location.origin + queryString;
		let r = new URL(url);
		const newUrl = r.href;
		window.history.replaceState({path: newUrl}, "", newUrl);
	}
}

export {
	parseQueryString,
	currentQueryString,
	addParam,
	deleteParam,
	replaceParams
};
