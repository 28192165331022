import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import PureRenderMixin from "react-addons-pure-render-mixin";
import { RadioGroup as RadioButtonGroup} from "@mui/material";

import { TextButton } from "js/common/views/inputs/buttons";
import Dialog from "js/onpoint/dialog";
import RadioButton from "js/common/views/inputs/radio-button";
import * as Groups from "js/common/groups";

export default createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        groupId: ReactPropTypes.number.isRequired,
        onConfirm: ReactPropTypes.func.isRequired,
        onCancel:  ReactPropTypes.func.isRequired
    },

    getInitialState() {
        return {
            selectionType: "GROUP"
        };
    },

    render() {
        const { selectionType } = this.state;
        const { groupId, onConfirm, onCancel } = this.props;
        const buttonStyle = { margin: "0.5rem" };

        return (
            <Dialog headerLabel={Groups.getGroupBreadcrumbsStr(groupId, ">")}>
                <p style={{ marginTop: "1rem" }}>
                    Please confirm the option to be added
                </p>
                <RadioButtonGroup
                    name="selectionType"
                    value={selectionType}
                    onChange={e => this.setState({ selectionType: e.target.value })}>
                   <RadioButton
                        value="GROUP"
                        label={"The group"}/>
                    <RadioButton
                        value="USERS_IN_GROUP"
                        label={`All active Users in ${Groups.getGroup(groupId).get("name")}`} />
                    <RadioButton
                        value="BOTH"
                        label="The Group and all sub-Groups and active Users in it"/>
                    <RadioButton
                        value="BOTH_WITH_DELETED_GROUPS"
                        label="The Group and all sub-Groups, deleted sub-Groups and active Users in it" />
                </RadioButtonGroup>

                <div style={{ float: "right", marginTop: "0.5rem" }}>
                    <TextButton label="Cancel" onClick={onCancel} style={buttonStyle} />
                    <TextButton label="Add" onClick={() => onConfirm(selectionType)} style={buttonStyle} />
                </div>
            </Dialog>
        );
    }

});
