import React from "react";
import pure from "js/common/views/pure";
import {TextButton} from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import GroupAndUserList from "js/common/views/group-and-user-list";

export default pure(({
  page,
  onRequestClose,
  onDelete
}) => {
  const usedByRootGroup = page.get("usages")
      .filter(usage => usage.get("qualifier") === "GROUP")
      .map(usage => usage.get("id"))
      .includes(Groups.getRootGroup().get("id"));

  if (!usedByRootGroup) {
    const groups = page.get("usages")
        .filter(usage => usage.get("qualifier") === "GROUP")
        .map(usage => Groups.getGroup(usage.get("id")))
        .sortBy(group => group.get("name"));
    const users = page.get("usages")
        .filter(usage => usage.get("qualifier") === "USER")
        .map(usage => Users.getUser(usage.get("id")))
        .sortBy(user => user.get("fullName"));
    const actions = [
        <TextButton label="Cancel" type="default" icon="history" key="cancel-btn" onClick={onRequestClose} style={{marginRight:8}}/>,
        <TextButton label="Delete" type="primary" icon="trash" key="delete-btn" onClick={onDelete} style={{marginRight:8}} />
    ];
    return (
        <Dialog
            bodyStyle={{overflow: "auto"}}
            actionsContainerStyle={{paddingRight: "2rem"}}
            title={`Delete ${page.get("name")}?`}
            actions={actions}
            autoDetectWindowHeight={true}
            open={true}
            enableScroll={true}>
          <div>
            <div>
              <p>Deleting this page will unassign it from any User or Groups that it is assigned to.
                These are listed below.</p>
              <p>Users or Groups underneath the assigned Groups may be inheriting this page.</p>
            </div>
            <GroupAndUserList
                groups={groups}
                users={users} />
          </div>
        </Dialog>
    );
  } else {
    const actions = [<TextButton label="Back" onClick={onRequestClose} style={{marginRight:8}} />];
    return (
        <Dialog
            bodyStyle={{overflow: "visible"}}
            actionsContainerStyle={{paddingRight: "2rem"}}
            title="Unable to delete page"
            titleStyle={{
              fontSize: "1rem",
              fontWeight: 300,
              textTransform: "uppercase"
            }}
            actions={actions}
            autoDetectWindowHeight={true}
            open={true}>
          <div>
            <p>{`${page.get("name")} is in use by the root Group`}</p>
          </div>
        </Dialog>
    );
  }
});
