/** @jsxImportSource @emotion/react */
import React from "react";
import {betterMemo} from "js/common/utils/more-memo";
import * as Immutable from "immutable";
import {capitaliseWords} from "js/common/utils/strings";
import * as ratioRepo from "js/common/repo/ratio-repo";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import Select from "js/common/views/inputs/immutable-react-select";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const CustomRatioMaker = betterMemo({displayName: "CustomRatioMaker"}, ({kpis, onConfigChange, config}) => {
  const ratioDisplayTypes = React.useMemo(() => ratioRepo.getDisplayTypes(), []);
  const { theme } = React.useContext(CustomThemeContext);
  const labelStyle = {color: theme.palette.text.main};
  return (
      <div style={{display: "flex", columnGap: "1.5rem"}}>
        <div style={{width: 200}}>
          <label style={labelStyle}>First Metric</label>
          <KpiPicker
              placeholder="Set First Metric"
              kpis={kpis.filter(kpi => kpi.get("visible") || kpi.get("id") === config.get("firstKpiId"))}
              selectedKpiId={config.get("firstKpiId")}
              onChange={kpiId => onConfigChange(config.set("firstKpiId", kpiId))}
              clearable={false}
              multiSelect={false} />
        </div>
        <div style={{width: 200}}>
          <label style={labelStyle}>Second Metric</label>
          <KpiPicker
              placeholder="Set Second Metric"
              kpis={kpis.filter(kpi => kpi.get("visible") || kpi.get("id") === config.get("secondKpiId"))}
              selectedKpiId={config.get("secondKpiId")}
              onChange={kpiId => onConfigChange(config.set("secondKpiId", kpiId))}
              clearable={false}
              multiSelect={false} />
        </div>
        <div style={{width: 200}}>
          <label style={labelStyle}>
            <span style={{paddingRight: "0.5rem"}}>Display Format</span>
          </label>
          <Select
              placeholder="Select Display"
              isMulti={false}
              isClearable={false}
              selectedValue={config.get("displayType")}
              options={ratioDisplayTypes.map(type => (Immutable.Map({label: capitaliseWords(type), value: type})))}
              onChange={displayType => onConfigChange(config.set("displayType", displayType))} />
        </div>
      </div>
  );
});

export default CustomRatioMaker;
