import React from "react";
import ReactDOM from "react-dom";
import CustomThemeProvider from 'js/common/themes/CustomThemeProvider';
import LatestVersionDialog from "js/common/views/latest-version-dialog"
import { StyledEngineProvider } from '@mui/material/styles';

const Marionette = window.Marionette;
const _ = window._;


function reactClassToMarionetteClass(ReactClass) {
    return Marionette.LayoutView.extend({
        template: _.template(""),

        initialize({ props = {}, children = []}) {
            this.props = props;
            this.children = children;
        },

        onShow() {
            this.reactRef = ReactDOM.render(<ReactClass {...this.props}>{this.children}</ReactClass>, this.$el[0]);
        },

        onDestroy() {
            ReactDOM.unmountComponentAtNode(this.$el[0]);
        }

    });
}

function reactClassToMarionetteComponent(ReactClass, { props = {}, children = [] } = {}) {
    const ViewClass = Marionette.LayoutView.extend({
        template: _.template(""),

        onShow() {
            this.reactRef = ReactDOM.render(<ReactClass {...props}>{children}</ReactClass>, this.$el[0]);
        },

        onDestroy() {
            ReactDOM.unmountComponentAtNode(this.$el[0]);
        }

    });
    return new ViewClass();
}

function reactComponentToMarionetteComponent(reactComponent) {
  const ViewClass = Marionette.LayoutView.extend({
    template: _.template(""),

    onShow() {
      this.reactRef = ReactDOM.render(
          <CustomThemeProvider>
            {reactComponent}
          </CustomThemeProvider>,
          this.$el[0],
      )
    },

        onDestroy() {
            ReactDOM.unmountComponentAtNode(this.$el[0]);
        }

    });
    return new ViewClass();
}

function reactAppToMarionetteComponent(reactComponent) {
    const ViewClass = Marionette.LayoutView.extend({
        template: _.template(""),


        onShow() {
            this.reactRef = ReactDOM.render(
                <CustomThemeProvider>
                    {reactComponent}
                    <LatestVersionDialog />
                </CustomThemeProvider>, this.$el[0]);
        },

        onDestroy() {
            ReactDOM.unmountComponentAtNode(this.$el[0]);
        }

    });
    return new ViewClass();
}

export {
    reactClassToMarionetteClass,
    reactClassToMarionetteComponent,
    reactComponentToMarionetteComponent,
    reactAppToMarionetteComponent
};
