import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import GroupAndUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import GroupBreadcrumbs from "js/admin/group-breadcrumbs";
import Breadcrumbs from "js/admin/simple-breadcrumbs";
import LoadingSpinner from "js/common/views/loading-spinner";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import TagPicker from "js/common/views/inputs/tag-picker/react-tag-filter";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import { TextButton, IconButton } from "js/common/views/inputs/buttons";

import * as tagRepo from "js/common/repo/backbone/tag-repo";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const mysqlDateFormat = "YYYY-MM-DD";

const TagsHistoryWrapper = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            hierarchy,
            onGroupSelect,
            onUserSelect,
            currentQualifierType,
            currentQualifierId,
            isLoadingTagsHistory,
            isLoadingInheritedTags,
        } = this.props;
        const isGroupOrUserSelected = currentQualifierType && currentQualifierId;
        const centeredContainerStyle = {
            maxWidth: 1024,
            margin: "1rem auto"
        };
        return (
            <div>
                <AdminHeader>
                    <Icon icon="category-tags" />
                    Assign Tags to a User or Group
                </AdminHeader>
                <div style={{ margin: "1rem", maxWidth: 350 }}>
                    <GroupAndUserPicker
                        isDisabled={isLoadingTagsHistory || isLoadingInheritedTags}
                        hierarchy={hierarchy}
                        qualifierType={currentQualifierType}
                        qualifierId={currentQualifierId}
                        onGroupClick={onGroupSelect}
                        onUserClick={onUserSelect} />
                </div>
                {isGroupOrUserSelected && this.renderCurrentUserOrGroupBreadcrumbs()}
                <div style={centeredContainerStyle}>
                    {isGroupOrUserSelected && this.renderTagsHistoryRows()}
                </div>
                <AdminHeader>
                    <Icon icon="category-tags" />
                    Inherited Tags
                </AdminHeader>
                <div style={centeredContainerStyle}>
                    {isGroupOrUserSelected && this.renderInheritedTags()}
                </div>
            </div>
        );
    },

    renderCurrentUserOrGroupBreadcrumbs() {
        const { currentQualifierType, currentQualifierId, onGroupSelect, theme } = this.props;
        const isUserTagsHistory = currentQualifierType === "USER";
        const groupId = isUserTagsHistory ? Users.getUser(currentQualifierId).get("groupId") : currentQualifierId;
        return (
            <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                <div style={{ fontSize: "0.875rem" }}>
                    <i className={`fa fa-${isUserTagsHistory ? "user" : "group"}`} style={{ color: theme.palette.primary.main }} />
                    <span style={{ paddingLeft: 8, paddingRight: 8 }}>
                        Tags History for:
                    </span>
                    {isUserTagsHistory && this.renderUserFullName(theme)}
                </div>
                <GroupBreadcrumbs
                    theme={theme}
                    groupId={groupId}
                    onGroupCrumbClick={onGroupSelect}
                    isLastCrumbClickable={isUserTagsHistory} />
            </div>
        );
    },

    renderUserFullName(theme) {
        const user = Users.getUser(this.props.currentQualifierId);
        return (
            <span style={{ fontStyle: "italic", fontWeight: "bold", color: theme.palette.primary.main }}>
                {user.get("fullName")}
            </span>
        );
    },

    renderTagsHistoryRows() {
        const {
            currentQualifierType,
            currentQualifierId,
            isLoadingTagsHistory,
            tagsHistory,
            hasUnsavedChanges,
            onChange,
            onCancelChangesRequest,
            onSaveChangesRequest,
            isSavingChanges,
            error,
            success,
            onSuccessMsgTimeout,
            theme
        } = this.props;
        if (isLoadingTagsHistory) {
            return <LoadingSpinner label="Loading Tags History" />;
        } else {
            const user = Users.getCurrentUser();
            const userCanOnlySeeSelf = user.get("dataVisibility") === "SELF";
            const isCurrentUser = currentQualifierType === "USER" && currentQualifierId === user.get("id");
            const isActionsDisabled = userCanOnlySeeSelf && !isCurrentUser;
            return (
                <TagsHistoryInner
                    theme={theme}
                    tagsHistory={tagsHistory}
                    isActionsDisabled={isActionsDisabled}
                    onAddTagHistoryRequest={this.handleAddTagHistoryRequest}
                    onChange={onChange}
                    hasUnsavedChanges={hasUnsavedChanges}
                    onCancelChangesRequest={onCancelChangesRequest}
                    onSaveChangesRequest={onSaveChangesRequest}
                    isSavingChanges={isSavingChanges}
                    error={error}
                    success={success}
                    onSuccessMsgTimeout={onSuccessMsgTimeout} />
            );
        }
    },

    renderInheritedTags() {
        const { isLoadingInheritedTags, inheritedTags, onGroupSelect } = this.props;
        if (isLoadingInheritedTags) {
            return <LoadingSpinner label="Loading Inherited Tags" />;
        } else {
            return <InheritedTags inheritedTags={inheritedTags} onGroupSelect={onGroupSelect} />;
        }
    },

    handleAddTagHistoryRequest() {
        const { currentQualifierType, currentQualifierId, tagsHistory, onChange } = this.props;
        const newTagHistory = getDefaultTagHistory(currentQualifierType, currentQualifierId);
        onChange(tagsHistory.push(newTagHistory));
    }

});

const TagsHistoryInner = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            tagsHistory,
            hasUnsavedChanges,
            isActionsDisabled,
            onAddTagHistoryRequest,
            onCancelChangesRequest,
            onSaveChangesRequest,
            isSavingChanges,
            error,
            success,
            onSuccessMsgTimeout,
            theme
        } = this.props;
        const buttonSpacing = { margin: "0.5rem" };
        return (
            <div>
                <div style={{ textAlign: "right" }}>
                    <TextButton
                        type="primary"
                        icon="plus"
                        label="Add Tag"
                        disabled={isActionsDisabled || isSavingChanges}
                        onClick={onAddTagHistoryRequest} />
                </div>
                <div style={tagsListContainerStyle}>
                    {this.renderHeaderRow()}
                    {tagsHistory.isEmpty() ? <NoDataMsgRow /> : this.renderTagsHistoryRows()}
                </div>
                <div>
                    {error && this.renderErrorMsg()}
                    {success && <SuccessMsg text={success} onMessageTimeout={onSuccessMsgTimeout} />}
                </div>
                <div style={{ margin: "1rem", textAlign: "center" }}>
                    <TextButton
                        icon="history"
                        label="Cancel"
                        onClick={onCancelChangesRequest}
                        disabled={isActionsDisabled || !hasUnsavedChanges || isSavingChanges}
                        style={buttonSpacing} />
                    <TextButton
                        icon="floppy-o"
                        label="Save"
                        disabled={isActionsDisabled || !hasUnsavedChanges || !!error || isSavingChanges}
                        onClick={() => onSaveChangesRequest(tagsHistory)}
                        style={buttonSpacing} />
                </div>
            </div>
        );
    },

    renderErrorMsg() {
        const { error } = this.props;
        if (Immutable.List.isList(error)) {
            return <div>{error.map((msg, i) => <ErrorMsg key={i} text={msg} />)}</div>;
        } else {
            return <ErrorMsg text={error} />;
        }
    },

    renderHeaderRow() {
        const { theme } = this.props;
        return (
            <div className="row" style={headerRowStyle(theme)}>
                <div className="small-5 columns">
                    Tag
                </div>
                <div className="small-3 columns" style={{ textAlign: "center" }}>
                    Start date
                </div>
                <div className="small-3 columns" style={{ textAlign: "center" }}>
                    End date
                </div>
                <div className="small-1 columns" style={{ textAlign: "center" }}>
                    Action
                </div>
            </div>
        );
    },

    renderTagsHistoryRows() {
        const { tagsHistory, isSavingChanges, theme } = this.props;
        console.log("THEME", theme);
        const datePickerContainerStyle = {
            width: "100%",
            margin: "0 auto"
        };
        return (
            <div>
                {tagsHistory
                    .filter(th => !th.get("deleted"))
                    .map((tagHistory, index) => {
                        const tagHistoryId = tagHistory.get("id") || tagHistory.get("cid");
                        const tagId = tagHistory.get("tagId");
                        const startDate = tagHistory.get("startDate");
                        const endDate = tagHistory.get("endDate");
                        const tagHistoryRowStyle = {
                            ...getRowStyle(index, theme),
                            height: 44
                        };
                        return (
                            <div key={`th-row-${tagHistoryId}`} className="row" style={tagHistoryRowStyle}>
                                <div className="small-5 columns">
                                    <TagPicker
                                        multiple={false}
                                        clearable={false}
                                        disabled={isSavingChanges}
                                        tagId={tagId}
                                        onChange={tagId => {
                                            this.handleTagHistoryChange(tagHistory.set("tagId", tagId));
                                        }} />
                                </div>
                                <div className="small-3 columns" style={{ textAlign: "center" }}>
                                    <div style={datePickerContainerStyle}>
                                        <DatePicker
                                            value={startDate}
                                            onDateChange={startDate => {
                                                this.handleTagHistoryChange(tagHistory.set("startDate", startDate));
                                            }}
                                            isDisabled={isSavingChanges}
                                            hideError={true} />
                                    </div>
                                </div>
                                <div className="small-3 columns" style={{ textAlign: "center" }}>
                                    <div style={datePickerContainerStyle}>
                                        <DatePicker
                                            value={isFarFutureDate(endDate) ? null : endDate}
                                            onDateChange={(endDate, endDateStr) => {
                                                const isEmptyDateValue = !endDate.isValid() && endDateStr === "";
                                                const date = isEmptyDateValue ? getFarFutureDate() : endDate;
                                                this.handleTagHistoryChange(tagHistory.set("endDate", date));
                                            }}
                                            isDisabled={isSavingChanges}
                                            hideError={true} />
                                    </div>
                                </div>
                                <div className="small-1 columns" style={{ textAlign: "center" }}>
                                    <IconButton
                                        type="alert"
                                        hoverType="alert"
                                        icon="times"
                                        label="Delete"
                                        container="column"
                                        onClick={() => this.handleDeleteTagHistoryClick(tagHistory)}
                                        disabled={isSavingChanges}
                                        size="large" />
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    },

    handleDeleteTagHistoryClick(tagHistoryToDelete) {
        const { tagsHistory, onChange } = this.props;
        const isNewTagAssignment = tagHistoryToDelete.has("cid");
        let updatedTagsHistory;
        if (isNewTagAssignment) {
            const index = tagsHistory.findIndex(th => th.get("cid") === tagHistoryToDelete.get("cid"));
            updatedTagsHistory = tagsHistory.delete(index);
        } else {
            const index = tagsHistory.findIndex(th => th.get("id") === tagHistoryToDelete.get("id"));
            updatedTagsHistory = tagsHistory.set(index, tagHistoryToDelete.set("deleted", true));
        }
        onChange(updatedTagsHistory);
    },

    handleTagHistoryChange(tagHistory) {
        const { tagsHistory, onChange } = this.props;
        const isNewTagAssignment = tagHistory.has("cid");
        let index;
        if (isNewTagAssignment) {
            index = tagsHistory.findIndex(th => th.get("cid") === tagHistory.get("cid"));
        } else {
            index = tagsHistory.findIndex(th => th.get("id") === tagHistory.get("id"));
        }
        onChange(tagsHistory.set(index, tagHistory.set("isChanged", true)));
    }

});


const TagsHistory = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <TagsHistoryInner cubeTheme={theme} {...props} />;
};


const InheritedTagsInner = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const { inheritedTags, onGroupSelect, theme } = this.props;
        return (
            <div style={tagsListContainerStyle}>
                <div className="row" style={headerRowStyle(theme)}>
                    <div className="small-3 columns">
                        Tag
                    </div>
                    <div className="small-5 columns">
                        Inherited from
                    </div>
                    <div className="small-2 columns" style={{ textAlign: "center" }}>
                        Start date
                    </div>
                    <div className="small-2 columns" style={{ textAlign: "center" }}>
                        End date
                    </div>
                </div>
                <div>
                    {inheritedTags.isEmpty() && <NoDataMsgRow />}
                    {inheritedTags
                        .sortBy(inheritedTag => {
                            const tag = tagRepo.get(inheritedTag.get("tagId"));
                            const category = tagRepo.getCategory(tag.get("categoryId"));
                            const tagDisplayLabel = `${category.get("name")}: ${tag.get("name")}`;
                            return tagDisplayLabel;
                        })
                        .map((tagRow, index) => {
                            const tag = tagRepo.get(tagRow.get("tagId"));
                            const category = tagRepo.getCategory(tag.get("categoryId"));
                            const endDate = tagRow.get("endDate");
                            const inheritedTagRowStyle = {
                                ...getRowStyle(index, theme),
                                minHeight: 34
                            };
                            return (
                                <div key={`it-row--${tagRow.get("id")}`} className="row" style={inheritedTagRowStyle}>
                                    <div className="small-3 columns">
                                        <span className="inherited-tag">
                                            <span className="category">{`${category.get("name")}: `}</span>
                                            {tag.get("name")}
                                        </span>
                                    </div>
                                    <div className="small-5 columns" style={{ paddingLeft: 0, paddingRight: 0 }}>
                                        <LinkToInheritedFromGroup
                                            theme={theme}
                                            groupId={tagRow.get("groupId")}
                                            onClick={() => onGroupSelect(tagRow.get("groupId"))} />
                                    </div>
                                    <div className="small-2 columns" style={{ textAlign: "center" }}>
                                        {formatDate(tagRow.get("startDate"))}
                                    </div>
                                    <div className="small-2 columns" style={{ textAlign: "center" }}>
                                        {isFarFutureDate(endDate) ? "" : formatDate(endDate)}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }

});

const InheritedTags = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <InheritedTagsInner theme={theme} {...props} />;
};

const NoDataMsgRow = React.memo(() => {
    const { theme } = React.useContext(CustomThemeContext);

    return (
    <div className="row">
        <div className="small-12 column" style={getRowStyle(1, theme)}>
            <div className="small-12 medium-6 column" style={{ margin: "0 auto", textAlign: "center" }}>
                <i className="fa fa-warning" style={{ paddingRight: 8 }} />
                There is no Tag history to display
            </div>
        </div>
    </div>
)});

const LinkToInheritedFromGroup = pure(({ theme, groupId, onClick }) => {
    const group = Groups.getGroup(groupId);
    const groupBreadcrumbs = Immutable.fromJS(group.get("breadcrumbs"));
    const containerStyle = {
        cursor: "pointer",
        color: theme.palette.text.main,
        backgroundColor: "transparent",
        paddingLeft: "0.9375em",
        paddingRight: "0.9375em",

        ":hover": {
            backgroundColor: "#000"
        }
    };
    return (
        <div onClick={onClick} style={containerStyle}>
            <Breadcrumbs trail={groupBreadcrumbs} dividerIcon="chevron-circle-right" dividerColor={theme.palette.primary.main} />
        </div>
    );
});

const tagsListContainerStyle = {
    width: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
    border: "none",
    fontSize: "0.875rem",
    lineHeight: 2
};

const headerRowStyle = (theme) => ({
    fontSize: "0.75rem",
    border: "none !important",
});

const rowStyle = {
    display: "flex",
    alignItems: "center",
};

const getRowStyle = (index, theme) => {
    const isEvenNumRow = index % 2 === 0;
    const stripeColor = theme.themeId === "light" ? "#eef4fc" : "rgba(0,0,0,0.2)"
    return {
        ...rowStyle,
        backgroundColor: isEvenNumRow ? "transparent" : stripeColor
    };
};

const getDefaultTagHistory = (qualifierType, qualifierId) => {
    const unixEpochDate = moment("1970-01-01", mysqlDateFormat);
    const tagHistory = Immutable.fromJS({
        cid: Math.random(),
        tagId: null,
        startDate: unixEpochDate,
        endDate: getFarFutureDate(),
        deleted: false
    });
    const qualifierIdKey = qualifierType === "GROUP" ? "groupId" : "userId";
    return tagHistory.set(qualifierIdKey, qualifierId);
};


const formatDate = (date, pattern = "L") => date.format(pattern);

const getFarFutureDate = () => moment("2099-01-01", mysqlDateFormat);
const isFarFutureDate = date => {
    const farFutureDate = getFarFutureDate();
    return date.isSame(farFutureDate, "day");
};

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <TagsHistoryWrapper theme={theme} {...props} />;
};
