import React from "react";
import createReactClass from "create-react-class";
import async from "async";
import moment from "moment";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";
import pure from "js/common/views/pure";

import LoadingScreen from "js/common/views/loading/loading-screen";
import UncaughtErrorMsg from "js/common/views/uncaught-error-msg";
import * as currencyRepo from "js/common/repo/backbone/currency-repo";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as kpiCalculator from "js/common/kpi-calculator";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import currentClient from "js/common/repo/backbone/current-client";
import BaseCube from "js/cubetv/cubes/models/base-cube";
import CurrentTrendSlide from "js/cubetv/cubes/current-trend-slide-layout";
import NewTrendSlide from "js/cubetv/cubes/new-trend-slide-layout";
import * as Groups from "js/common/groups";
import * as auditor from "js/common/auditer";
import Cube19 from "js/cube19.app";
import {colorToHex} from "js/common/utils/fn";

const _ = window._;

const TrendModel = BaseCube.extend({

  loadData() {
    this.set({
      id: Math.random(),
      isTrendDataLoaded: false,
      trendData: Immutable.fromJS({
        yAxisValuePrefix: "",
        maxValue: 0,
        trends: []
      }),
      isKpiSummariesDataLoaded: false,
      kpiSummariesDataByIndex: Immutable.Map(),
      trendFatalError: false
    });
    this.loadKpiTrendsData();
  },

  loadKpiTrendsData() {
    this.kpiTrends = [];
    this.targetTrends = [];

    const trendConfig = this.get("trendConfig");
    const trendKpis = trendConfig.kpis;
    const trendColours = [
      "#0fd800",
      "#add8e6",
      "#e34d70",
      "#ca7df9"
    ];
    const targetColours = [
      "#ffff00",
      "#ff8c00",
      "#0ff1ce",
      "#eaeaea"
    ];
    const kpiTrends = trendKpis.filter(tk => kpiRepo.getByIdOrTemplateName(tk.kpiId || tk.templateName));
    if (kpiTrends.length === 0) {
      Cube19.execute("cubetv:show-next");
    } else {
      const kpiTrendConfigs = kpiTrends
          .map((trendKpi, index) => {
            const kpi = kpiRepo.getByIdOrTemplateName(trendKpi.kpiId || trendKpi.templateName);
            const trendConfig = Immutable
                .fromJS(trendKpi)
                .set("kpiId", kpi.get("id"))
                .delete("templateName")
                .toJS();
            const reverseIndex = trendKpis.length - index - 1;
            return {
              ...trendConfig,
              order: trendKpi.order || index,
              color: trendKpi.color || trendColours[reverseIndex],
              targetColor: trendKpi.targetColor || targetColours[reverseIndex]
            };
          });
      async.each(kpiTrendConfigs, _.bind(this.loadKpiTrend, this));
    }
  },

  loadKpiTrend(kpiConfig, callback) {
    const {kpiId, timeframeId, matchAnyTagIds, matchAllTagIds, excludedTagIds} = kpiConfig;
    const kpi = kpiRepo.get(kpiId);
    const timeframe = timeframeRepo.get(timeframeId);
    let groupId = kpiConfig.groupId;
    if (groupId && !Groups.getGroup(groupId)) {
      auditor.audit("deleted-group:trend-slide");
    }
    if (!groupId) {
      groupId = Groups.getRootGroup().get("id");
    }
    const requestOptions = {
      groupId,
      timeframe,
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds
    };
    return kpiCalculator
        .targetedTrend(kpiId, requestOptions)
        .then(response => {
          const trendValueList = Immutable.fromJS(response.trendValueList);
          if (kpi.get("type").valueFormat === "CURRENCY" && !trendValueList.isEmpty()) {
            const currencyCode = trendValueList.last().getIn(["value", "currency"]);
            const trendData = this.get("trendData");
            this.set("trendData", trendData.set("yAxisValuePrefix", currencyRepo.getSymbol(currencyCode)));
          }

          const startDate = timeframe && timeframe.get("start");
          const endDate = timeframe && timeframe.get("end");
          const lastValueDate = trendValueList.last() ? trendValueList.last().get("date") : endDate;
          const today = moment();
          const graphEndDate = endDate.isBefore(today) || today.isBefore(lastValueDate)
              ? endDate
              : today;
          const numberOfDays = Math.abs(startDate.diff(graphEndDate, "days")) + 1;
          let kpiTrendData = this.padValuesUpto(trendValueList, numberOfDays);
          const kpiQueryType = kpi.get("type").query;
          if (kpiQueryType === "CONTRACTOR_IN_PERIOD") {
            const maxValue = this.getMaxForSlidingWindow(kpiTrendData);
            const trendData = this.get("trendData");
            this.set("trendData", trendData.set("maxValue", maxValue));
          } else {
            kpiTrendData = this.accumulateValues(kpiTrendData);
          }
          kpiTrendData = flattenValues(kpiTrendData);

          if (kpiConfig.showTarget) {
            const targets = Immutable.fromJS(response.trendTargetValueList);
            this.getTargetTrend(kpiConfig, targets);
          }

          this.addTrend({
            name: kpiConfig.key,
            data: kpiTrendData.toJS(),
            color: colorToHex(kpiConfig.color),
            fillOpacity: 0.5,
            order: kpiConfig.order
          });
          callback();
        }, () => {
          Cube19.execute("cubetv:show-next");
        });
  },

  padValuesUpto(values, numberOfDays) {
    if (values.isEmpty()) {
      return values;
    } else {
      let indexedByDaysFromStart = Immutable.Map();
      values.forEach(value => {
        const daysFromStart = value.get("daysFromStart");
        indexedByDaysFromStart = indexedByDaysFromStart.set(daysFromStart, value);
      });
      for (let i = 0; i <= numberOfDays; i++) {
        if (!indexedByDaysFromStart.get(i)) {
          const defaultTrendValue = Immutable.fromJS({
            daysFromStart: i,
            value: {
              value: 0
            }
          });
          indexedByDaysFromStart = indexedByDaysFromStart.set(i, defaultTrendValue);
        }
      }
      return indexedByDaysFromStart
          .toList()
          .sortBy(data => data.get("daysFromStart"));
    }
  },

  getMaxForSlidingWindow(values) {
    const maxValueData = values.maxBy(valueData => valueData.get("value").get("value"));
    return maxValueData.get("value").get("value");
  },

  accumulateValues(values) {
    let total = 0;
    const accumulatedValues = values.map(valueData => {
      const valueDataMap = valueData.get("value");
      total += valueDataMap.get("value");
      return valueData.set("value", valueDataMap.set("value", total));
    });
    const trendData = this.get("trendData");
    const maxValue = Math.max(trendData.get("maxValue"), total);
    this.set("trendData", trendData.set("maxValue", maxValue));
    return accumulatedValues;
  },

  addTrend(trend) {
    if (trend.isTarget) {
      this.targetTrends.push(trend);
    } else {
      this.kpiTrends.push(trend);
    }
    const trendConfig = this.get("trendConfig");
    const trendKpis = trendConfig.kpis;
    const kpiTrendsCount = trendKpis.reduce((counter, kpi) => counter + (kpi.showTarget ? 2 : 1), 0);
    if (this.targetTrends.length + this.kpiTrends.length === kpiTrendsCount) {
      this.kpiTrends = this.kpiTrends.sort((a, b) => a.order - b.order);
      const allTrends = this.kpiTrends.concat(this.targetTrends);
      const trendData = this.get("trendData");
      this.set({
        trendData: trendData.set("trends", allTrends),
        trends: allTrends,
        isTrendDataLoaded: true
      });
      this.trigger("change");
      this.loadKpiSummariesData();
    }
  },

  getTargetTrend(kpiConfig, targetValues) {
    const {targetKey, targetColor} = kpiConfig;
    const targetTrendValues = flattenValues(this.accumulateValues(targetValues));
    const startingValues = Immutable.fromJS([0]);
    const targetTrendData = startingValues.concat(targetTrendValues);
    this.addTrend({
      name: targetKey || "Target",
      data: targetTrendData.toJS(),
      color: colorToHex(targetColor),
      type: "line",
      zIndex: 100,
      isTarget: true
    });
  },

  loadKpiSummariesData() {
    const kpiSummariesConfig = this.get("kpiSummariesConfig");
    const kpiSummaries = kpiSummariesConfig.kpis;

    const kpiPromises = kpiSummaries.map(kpiSummary => {
      let groupId = kpiSummary.groupId;
      if (groupId && !Groups.getGroup(groupId)) {
        auditor.audit("deleted-group:trend-slide");
      }
      if (!groupId || groupId === "root") {
        const rootGroup = Groups.getRootGroup();
        groupId = rootGroup.get("id");
      }
      const kpi = kpiRepo.getByIdOrTemplateName(kpiSummary.kpiId || kpiSummary.templateName);
      const kpiId = kpi.get("id");
      const timeframeId = kpiSummary.timeframeId;
      const requestOptions = {
        timeframe: timeframeRepo.get(timeframeId),
        groupId,
        matchAnyTagIds: kpiSummary.matchAnyTagIds,
        matchAllTagIds: kpiSummary.matchAllTagIds,
        excludedTagIds: kpiSummary.excludedTagIds
      };
      const defaultKpiData = Immutable.fromJS({
        cid: kpiSummary.cid,    // NOTE: not all Trend slide configs have a cid for the Mini Cubes
        kpiId,
        timeframeId,
        title: kpiSummary.title
      });
      const kpiDataValueError = Immutable.fromJS({
        type: "error",
        value: "N/A"
      });
      return kpiCalculator
          .summary(kpiId, requestOptions)
          .then(
              response => defaultKpiData.set("kpiData", Immutable.fromJS(response)),
              () => defaultKpiData.set("kpiData", kpiDataValueError));
    });
    Promise
        .all(kpiPromises)
        .then(results => {
          const kpiSummariesData = Immutable.fromJS(results);
          let kpiSummariesDataByIndex = Immutable.Map();
          kpiSummariesData.forEach((kpiSummaryData, index) => {
            kpiSummariesDataByIndex = kpiSummariesDataByIndex.set(index, kpiSummaryData);
          });
          this.set({
            kpiSummariesDataByIndex,
            isKpiSummariesDataLoaded: true
          });
          this.trigger("change");
        });
  }

});

Cube19.module("Models.Cubes", function(Cubes, App, Backbone, Marionette, $, _) {

  Cubes.Trend = TrendModel;

});

export default pure((props) => (
        <ErrorBoundaryPage {...props} />
));

const ErrorBoundaryPage = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      uncaughtError: false
    };
  },

  componentDidCatch() {
    this.setState({
      uncaughtError: true
    });
  },

  render() {
    if (this.state.uncaughtError) {
      return <UncaughtErrorMsg />;
    } else {
      return (
          <div style={{height: "calc(100vh - 130px)", paddingTop: 10}}>
            <Slide {...this.props} />
          </div>
      );
    }
  }

});

const Slide = GetContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const client = currentClient;
    const TrendSlide = client.hasPermission("HAS_NEW_LOOK_CUBETV") ? NewTrendSlide : CurrentTrendSlide;
    return (
        <div style={{height: "100%", width: "100%"}}>
          {(this.isAllDataLoaded() && !this.props.trendFatalError) ?
              <TrendSlide
                  trendConfig={this.props.trendConfig}
                  trendData={this.props.trendData}
                  kpiSummariesConfig={this.props.kpiSummariesConfig}
                  kpiSummariesDataByIndex={this.props.kpiSummariesDataByIndex}
                  containerHeight={this.props.containerHeight} />
              : <LoadingScreen />}
        </div>
    );
  },

  isAllDataLoaded() {
    return this.props.isTrendDataLoaded && this.props.isKpiSummariesDataLoaded;
  }

}));

const flattenValues = values => values.map(valueData => valueData.get("value").get("value"));
