import React from "react";
import Immutable from "immutable";

import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import {indexBy} from "js/common/utils/collections";

import Chip from "@mui/material/Chip";
import KpiPicker from "js/common/views/kpis/custom-kpi-picker";
import DataConfigToolbar from "js/dashboards/data-config-toolbar";
import RatioPicker from "js/common/views/inputs/ratio-picker";
import * as RatioRepo from "js/common/repo/ratio-repo";
import Select from "js/common/views/inputs/immutable-react-select";

const PerformanceSnapshotEditor = React.memo(({component, onComponentChange}) => {
  const config = component.get("dataConfig");

  const onConfigChange = React.useCallback(newDataConfigOrFn => {
    if (typeof (newDataConfigOrFn) === "function") {
      onComponentChange(component => component.update("dataConfig", newDataConfigOrFn));
    } else {
      onComponentChange(component => component.set("dataConfig", newDataConfigOrFn));
    }
  }, [onComponentChange]);

  const allVisibleKpis = React.useMemo(
      () => Immutable.fromJS(KpiRepo.getAll().toJSON()).filter(k => k.get("visible")),
      []);
  const idToKpi = React.useMemo(() => indexBy(k => k.get("id"), allVisibleKpis), [allVisibleKpis]);

  const selectedKpiIds = config.get("kpiIds", Immutable.Set()).toSet();
  const selectableKpis = React.useMemo(
      () => allVisibleKpis.filter(k => !selectedKpiIds.has(k.get("id"))),
      [allVisibleKpis, selectedKpiIds]);

  const getRatios = () => RatioRepo.getAll();
  const ratiosList = config.get("ratiosList");

  const onRemoveKpi = React.useCallback(kpi => {
    onConfigChange(config => config.update("kpiIds", kpiIds => kpiIds.toSet().remove(kpi.get("id"))));
  }, [onConfigChange]);

  const onRemoveRatio = React.useCallback(ratio => {
    onConfigChange(config => config.update(
        "ratiosList",
        ratios => ratios.filter(r => r.get("id") !== ratio.get("id"))));
  }, [onConfigChange]);

  const sortOptions = [
    {label: "Value (default)", value: "value"},
    {label: "Target", value: "target"},
    {label: "% of Target achieved", value: "achieved"}
  ];


  return <div style={{padding: "0.5rem"}}>
    <DataConfigToolbar config={config} onConfigChange={onConfigChange} />

    <div
        style={{
          display: "flex",
          columnGap: "0.3rem",
          rowGap: "0.3rem",
          flexWrap: "wrap",
          padding: "0.3rem",
          marginTop: 20
        }}>
      <p style={{fontSize: 12}}>Selected metrics:</p>
      {selectedKpiIds
          .map(kpiId => idToKpi.get(kpiId))
          .sortBy(kpi => kpi.get("name"))
          .map(kpi => <Chip
              key={kpi.get("id")}
              label={kpi.get("name")}
              onDelete={() => onRemoveKpi(kpi)} />)}
    </div>
    <div style={{display: "flex", columnGap: "0.3rem", rowGap: "0.3rem", flexWrap: "wrap", padding: "0.3rem"}}>
      <p style={{fontSize: 12}}>Selected ratios:</p>
      {ratiosList.map(ratio => <Chip
          key={ratio.get("id")}
          label={ratio.get("name")}
          onDelete={() => onRemoveRatio(ratio)} />)}
    </div>

    <div style={{display: "flex", marginBottom: "0.3rem"}}>
      <div style={{width: "50%", marginRight: 20}}>
        <KpiPicker
            kpis={selectableKpis}
            onKpiSelect={kpiId => onConfigChange(config.update("kpiIds", kpiIds => kpiIds.toSet().add(kpiId)))}
            label="Add Metrics"
            closeOnSelect={false} />
      </div>
      <div style={{width: 220}}>
        <label style={{fontSize: 11, marginTop: -16, display: "block"}}>Sort Metrics By:</label>
        <Select
            placeholder="Sort By..."
            isMulti={false}
            isClearable={false}
            selectedValue={config.get("metricSortType")}
            options={Immutable.fromJS(sortOptions)}
            onChange={type => onConfigChange(config.set("metricSortType", type))} />
      </div>
    </div>

    <div style={{width: "50%", marginBottom: "0.3rem"}}>
      <RatioPicker
          label="Add Ratios"
          data-test-id="ratio-picker"
          ratios={getRatios()
              .deleteAll(ratiosList.map(r => r.get("id")))
              .toList()
              .filter(r => !r.get("isDeleted"))}
          onRatioSelect={(r) => {
            const updatedRatiosList = ratiosList.push(r);
            console.log("updated ratios list", updatedRatiosList);
            onConfigChange(config.set("ratiosList", updatedRatiosList));
          }}
          closeOnSelect={false} />
    </div>
  </div>;
});

export default PerformanceSnapshotEditor;
