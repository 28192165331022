import React from "react";
import Immutable from "immutable";
import squidImage from "img/squids/squid-happy.svg";
import IndexCard from "js/common/views/index-view/index-card";
import * as Users from "js/common/users";

const SquidCard = (props) => {
  const {data, onClick, duplicateSquid, deleteSquid, ...other} = props;

  const hasEditPermission = Users.currentHasPermission("SQUID_EDITOR");
  const descriptionErrors = data.getIn(["json", "descriptionErrors"], Immutable.Set());

  // Convert squid data into a generic
  // data structure that index-card is expecting
  const squidCardData = Immutable.fromJS({
    id: data.get("id"),
    name: data.get("name"),
    image: squidImage,
    description: !descriptionErrors.isEmpty() ? "Error rendering description" :
        data.getIn(["json", "description"]),
    actions: hasEditPermission ? [{label: "Duplicate Squid", action: duplicateSquid}, {label: "Delete Squid", action: deleteSquid}] : null,
    hasUnsavedChanges: data.get("hasUnsavedChanges")
  });

  return <IndexCard className="TESTCAFE-squid-card" data={squidCardData} {...other}
                    onClick={onClick} />
};

export default SquidCard;
