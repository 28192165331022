import React from "react";
import pure from "js/common/views/pure";

export default pure(({
    iconSize = 2,
    label = "",
    color = "#969696"
}) => (
    <div className="row">
        <div
            className="small-12 medium-6 small-centered column text-center"
            style={{ marginBottom: "0.5rem", textTransform: "uppercase", color }}>
            {label}
        </div>
        <div className="small-12 medium-6 small-centered column text-center">
            <i className={`fa fa-spinner fa-pulse fa-${iconSize}x`} style={{ color }} />
        </div>
    </div>
), "LoadingSpinner");
