import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";

import InteractiveTable from "js/common/views/tables/interactive-table";

import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";
import PlaceholderTextField from "js/common/views/inputs/placeholder-text-field";
import { TextButton } from "js/common/views/inputs/buttons";
import * as popups from "js/common/popups";
import * as rssFeedRepo from "js/common/repo/rss-feed-repo";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const titleErrorTypes = ["EMPTY_TITLE"];
const urlErrorTypes = ["NON_HTTP_URL", "AUTHENTICATED_URL", "INVALID_URL"];

const EditRSSFeed = createReactClass({

    mixins: [ PureRenderMixin ],

    getInitialState() {
        return {
            rssFeeds: Immutable.List()
        };
    },

    componentDidMount() {
        const rssFeeds = rssFeedRepo.getAll();
        this.setState({ rssFeeds });
    },

    render() {
        const {theme} = this.props;
        return (
            <div style={{ padding: "1rem" }}>
                <Hint style={{marginTop: "1rem"}}>
                    <Icon icon="info" style={{color: theme.palette.hints.text}} />
                    <span>RSS feeds added here will be available as options for setting up a channel's News Ticker.</span>
                </Hint>
                <div style={{ marginTop:"1rem", marginBottom:"1rem", textAlign:"center" }}>
                    <TextButton icon="plus" label="Add RSS Feed" onClick={this.addNewRssFeed} />
                </div>
                <RssFeedsTable
                    rssFeeds={this.state.rssFeeds}
                    onChange={this.updateRssFeed}
                    onDelete={this.deleteRssFeed} />
            </div>);
    },

    addNewRssFeed() {
        const rssFeeds = this.state.rssFeeds;
        if (!rssFeeds.find(feed => feed.get("id") === "new")) {
            const newFeed = Immutable.fromJS({
                id: "new",
                title: "New RSS Feed",
                url: ""
            });
            this.setState({
                rssFeeds: rssFeeds.push(newFeed)
            });
        }
    },

    deleteRssFeed(rssFeed) {
        if (rssFeed.get("id") === "new") {
            const rssFeeds = this.state.rssFeeds;
            this.setState({
                rssFeeds: rssFeeds.pop()
            });
        } else {
            rssFeedRepo
                .remove(rssFeed.get("id"))
                .then(updatedRssFeeds => {
                    this.setState({
                        rssFeeds: updatedRssFeeds
                    });
                }, () => {
                    popups.contactSupport();
                });
        }
    },

    updateRssFeed(rssFeed) {
        const update = rssFeed.get("id") === "new" ? rssFeedRepo.create : rssFeedRepo.update;
        update(rssFeed)
            .then(updatedRssFeeds => {
                this.setState({
                    rssFeeds: updatedRssFeeds
                });
            }, e => {
                let titleErrorMessage, urlErrorMessage;
                if (e.responseJSON && e.responseJSON.type) {
                    if (titleErrorTypes.includes(e.responseJSON.type)) {
                        titleErrorMessage = e.responseJSON.message;
                    } else if (urlErrorTypes.includes(e.responseJSON.type)) {
                        urlErrorMessage = e.responseJSON.message;
                    } else {
                        popups.contactSupport();
                    }
                } else {
                    popups.contactSupport();
                }
                const rssFeeds = this.state.rssFeeds;
                const index = rssFeeds.findIndex(feed => feed.get("id") === rssFeed.get("id"));
                const newFeed = rssFeed
                    .set("titleErrorMessage", titleErrorMessage)
                    .set("urlErrorMessage", urlErrorMessage);
                this.setState({
                    rssFeeds: rssFeeds.set(index, newFeed)
                })
            });
    }

});

const RssFeedsTable = createReactClass({

    displayName: "RssFeedsTable",

    mixins: [ PureRenderMixin ],

    propTypes: {
        rssFeeds: ReactPropTypes.object.isRequired,
        onChange: ReactPropTypes.func.isRequired,
        onDelete: ReactPropTypes.func.isRequired,
        theme: ReactPropTypes.object
    },

    render() {
        return (
            <InteractiveTable
                columns={["RSS Feed","URL","",{label:"Actions", textAlign: "center"},""]}
                rows={this.props.rssFeeds.map(this.renderRssFeedRow).toArray()} />
        );
    },

    renderRssFeedRow(rssFeed) {
        const isDisabled = rssFeed.get("isCube19Default");
        const textFieldInputStyle = isDisabled ? {color:"#cacaca", cursor:"not-allowed", marginBottom:0} : {marginBottom:0};
        const buttonStyle = {
            // marginTop : "-5px", // to compensate for the 8px padding that fixed-data-table sets on cells
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
            height: 25,
            lineHeight: 1
        };
        return ([
            <div key="name-input" style={{width: 400}}>
                <PlaceholderTextField
                    hintText="RSS Feed Name"
                    errorText={rssFeed.get("titleErrorMessage") ? rssFeed.get("titleErrorMessage") : ""}
                    disabled={isDisabled}
                    inputStyle={textFieldInputStyle}
                    maxLength={128}
                    value={rssFeed.get("title")}
                    onChange={title => this.props.onChange(rssFeed.set("title", title))} />
            </div>,
            <div key="url-input" style={{width: 400}}>
                <PlaceholderTextField
                    hintText="RSS Feed URL"
                    errorText={rssFeed.get("urlErrorMessage") ? rssFeed.get("urlErrorMessage") : ""}
                    disabled={isDisabled}
                    inputStyle={{...textFieldInputStyle, width: '400px !important'}}
                    maxLength={512}
                    fullWidth={true}
                    value={rssFeed.get("url")}
                    onChange={url => this.props.onChange(rssFeed.set("url", url))} />
            </div>,
            <div style={{width: "auto"}}/>,
            <div key="remove-btn" style={{minWidth: 100}}>
                {!rssFeed.get("isCube19Default") &&
                    <TextButton
                        icon="trash-o"
                        label="Delete"
                        type="alertInner"
                        onClick={() => this.props.onDelete(rssFeed)}
                        style={{ ...buttonStyle}} />
                }
            </div>,
            <div style={{width: "auto"}} />
        ]);
    }

});

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <EditRSSFeed theme={theme} {...props} />;
};