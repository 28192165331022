import React from "react";

const NovoLoading = React.memo(() =>
    <div className="novo-loading">
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </div>);

export default NovoLoading;
