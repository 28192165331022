import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";

import pure from "js/common/views/pure";
import { TextButton } from "js/common/views/inputs/buttons";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import Layout from "js/common/views/foundation-column-layout";
import Overlay from "js/common/views/overlay";
import LoadingSpinner from "js/common/views/loading-spinner";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import * as Users from "js/common/users";
import * as Branding from "js/common/branding-constants"
import currentClient from "js/common/repo/backbone/current-client";

const buttonStyle = {
    fontSize : "14px",
    // marginTop : "-5px", // to compensate for the 8px padding that fixed-data-table sets on cells
    marginTop : "0.5px",
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginRight: "1rem",
    marginLeft: "1rem",
    height: 25,
    lineHeight: 1
};

const InactiveUserPage = createReactClass({

    displayName: "InactiveUserPage",

    getInitialState() {
        return {
            userById: Immutable.Map(),
            loading: false,
            updating: false
        };
    },

    componentDidMount() {
        this.setState({loading: true});
        Users.load().then(users => {
            const userById = users.groupBy(u => u.get("id")).map(us => us.first());
            this.setState({userById, loading: false});
        });
    },

    render() {
        const inactiveUsers = this.state.userById
            .valueSeq()
            .filter(Users.isInactive);
        const { theme } = this.props;
        const boxStyle = theme =>({
            border: theme.themeId === "light" ? `1px solid ${theme.palette.background.paper}` : "none",
            backgroundColor: theme.themeId === "dark" ? "#272731" : "#eeeeee"
        });
        const columns = currentClient.isBullhornCrm() ? [
            {
                label: "ID",
                width: 50
            },
            "Name",
            {
                label: "Reactivate as",
                sortMapper: () => {},
                width: 300
            }
        ] : [
            "Name",
            {
                label: "Reactivate as",
                sortMapper: () => {},
                width: 300
            }
        ];
        return (
            <div style={{position: "relative"}}>
                <Layout small={[8,4]} rowStyle={{ marginTop: "1rem" }}>
                    {this.state.loading ? <LoadingSpinner iconSize={3} /> : <SimpleDataTable
                        maxTableHeight={700}
                        columns={columns}
                        rows={inactiveUsers.map(this.userToRow).toArray()} />}
                    <Guide theme={theme} style={boxStyle(theme)}/>
                </Layout>
                {this.state.updating && <Overlay />}
            </div>);
    },

    userToRow(user) {
        const name = user.get("fullName");
        const limitedName = name.length < 35 ? name : name.substring(0, 34) + "...";
        let row = [
            limitedName,
            <div>
                <TextButton
                    icon="user"
                    label="Full user"
                    labelStyle={{fontSize: "13px"}}
                    onClick={() => this.handleFullUserClick(user.get("id"))}
                    style={buttonStyle} />
                <TextButton
                    icon="user-secret"
                    label="Observer"
                    labelStyle={{fontSize: "13px"}}
                    onClick={() => this.handleObserverClick(user.get("id"))}
                    style={buttonStyle} />
            </div>
        ];
        if (currentClient.isBullhornCrm()) {
            row.unshift(Users.isCrmUser(user)? user.get("originalCrmUserId") : "");
        }
        return row;
    },

    handleFullUserClick(userId) {
        this.setState({updating: true});
        Users.setupAs(userId, "FULL")
            .then(user => {
                this.setUserInState(user);
                this.setState({updating: false});
            });
    },

    handleObserverClick(userId) {
        this.setState({updating: true});
        Users.setupAs(userId, "OBSERVER")
            .then(user => {
                this.setUserInState(user);
                this.setState({updating: false});
            });
    },

    setUserInState(user) {
        const userById = this.state.userById.set(user.get("id"), user);
        this.setState({userById});
    }

});

const Guide = pure(({ theme, style }) => (
    <dl className="text-box-wrapper" style={{ ...style, padding: "1rem" }}>
        <dt style={labelStyle(theme)}>
            <i className='fa fa-moon-o' style={iconStyle(theme)} /> Inactive users
        </dt>
        <dd>
        These Users are not visible and cannot login to {Branding.brandingName}.
        You can reactivate them here, after which they can be managed the same as your other Users.
        </dd>
    </dl>));

const iconStyle = theme => ({
    color: theme.palette.textColor,
    paddingRight: 5
});

const labelStyle = theme => ({
    fontWeight: "normal",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    marginBottom: 0
});



const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <InactiveUserPage theme={theme} {...props} />;
};

export default Wrapper;