import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";
import UncaughtErrorMsg from "js/common/views/uncaught-error-msg";

import BaseCube from "js/cubetv/cubes/models/base-cube";
import Cube19 from "js/cube19.app";
import * as NewsTicker from "js/cubetv/cubes/news-ticker";
import * as Colors from "js/common/cube19-colors"
import pure from "js/common/views/pure";

const IframeModel = BaseCube.extend({});

Cube19.module("Models.Cubes", function(Cubes, App, Backbone, Marionette, $, _) {
  Cubes.Iframe = IframeModel;
});

export default pure((props) => (
          <ErrorBoundaryPage {...props} />
));

const ErrorBoundaryPage = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      uncaughtError: false
    };
  },

  componentDidCatch() {
    this.setState({
      uncaughtError: true
    });
  },

  render() {
    if (this.state.uncaughtError) {
      return <UncaughtErrorMsg />;
    } else {
      return <div style={{height: "100vh"}}><Slide {...this.props} /></div>;
    }
  }

});

const Slide = GetContainerDimensions()(pure(({
  url,
  hasTitle,
  pageWidth,
  pageHeight,
  isCustomSizeEnabled,
  isFullHeight,
  containerHeight
}) => (
    <div style={{
      display: "flex",
      height: isFullHeight ? containerHeight : containerHeight - NewsTicker.height,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    }}>
      {hasTitle && <div style={hrStyle} />}
      <iframe
          title={"Gamification embedded web page"}
          width={isCustomSizeEnabled ? pageWidth : "100%"}
          height={isCustomSizeEnabled ? pageHeight : "100%"}
          src={url}
          style={{borderWidth: 0, marginTop: "auto", marginBottom: "auto"}}
          sandbox={"allow-forms allow-scripts allow-same-origin"}
          allow={"autoplay"} />
    </div>
)));

const hrStyle = {
  height: "3px",
  width: "calc(100% - 2.5rem)",
  backgroundColor: Colors.c19Yellow,
  marginBottom: 10
};
