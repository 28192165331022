import React from "react";
import {betterMemo} from "js/common/utils/more-memo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as auditer from "js/common/auditer";
import * as Branding from "js/common/branding-constants"

export default betterMemo(
    {displayName: "ReleaseWindows"},
    () =>  {
        auditer.audit("release_window:load");
        const { theme } = React.useContext(CustomThemeContext);
        return <div style={{ padding: "1rem" }}>
            <div>
                <section style={sectionStyle}>
                    <h1 style={h1Style(theme)}>
                        Release Windows
                    </h1>
                    <p>
                        The current regularly scheduled maintenance windows are:
                        <ul style={listStyle}>
                            <li style={{ display: "flex", marginBottom: "1rem" }}>
                                <i className="fa fa-circle" style={listItemCircleStyle(theme)} />
                                <div>
                                    <span style={listItemStyle}>Every Tuesday 07:00 - 08:00 London, UK time.</span>
                                    <span style={{fontSize: "0.8rem"}}>This is used infrequently for smaller updates and upgrades where downtime can't be avoided.</span>
                                </div>
                            </li>
                            <li style={{ display: "flex" }}>
                                <i className="fa fa-circle" style={listItemCircleStyle(theme)} />
                                <div>
                                    <span style={listItemStyle}>Every Saturday 00:01 - 23:59 London, UK time.</span>
                                    <span style={{fontSize: "0.8rem"}}>This is used infrequently for larger updates and upgrades where downtime can't be avoided.</span>
                                </div>
                            </li>
                        </ul>
                        These times are subject to alteration at {Branding.brandingName}' sole discretion from time to time.
                    </p>
                </section>
            </div>
        </div>
    });

const sectionStyle = {
    marginTop: "1rem",
    marginBottom: "2rem"
};

const h1Style = theme =>({
    fontSize: "1.875rem",
    color: theme.palette.primary.main,
    marginTop: 0,
    marginBottom: "1.75rem"
});

const listStyle = {
    margin: "1rem",
    listStyle: "none",
    listStylePosition: "inside",
    marginBottom: "2.25rem"
};

const listItemStyle = {
    display: "block",
    fontSize: "0.85rem",
    fontWeight: "bold"
};

const listItemCircleStyle = theme =>({
    color: theme.palette.primary.main,
    paddingRight: 10,
    fontSize: "0.85rem",
    verticalAlign: "top",
    lineHeight: "30px",
});
