export const getUniqueName = (originalName, listOfNames, maxNameLength = 1024) => {

    const isNameUnique = originalName => listOfNames.every(n => n !== originalName);

    let index = 0;
    let newName;
    do {
        if (index === 0) {
            const tempName = "Copy of " + originalName;
            newName = tempName.substring(0, maxNameLength)
        } else {
            const suffix = " (" + index + ")";
            const tempName = "Copy of " + originalName;
            newName = tempName.substring(0, maxNameLength - suffix.length) + suffix;
        }
        index++;
    } while (!isNameUnique(newName));

    return newName;
};
