/** @jsxImportSource @emotion/react */

import React from "react";
import {css, jsx} from "@emotion/react";
import Immutable from "immutable";
import ReactFlow, {isNode, ReactFlowProvider, useZoomPanHelper} from "react-flow-renderer";
import * as GraphUtils from "js/squids/graph-utils";
import dagre from "dagre";
import {addRenderOrder, toFlowDirection} from "js/squids/squid-display/squid-display";
import CustomEdge from "js/squids/squid-display/custom-edge";
import {FormControlLabel, Switch} from "@mui/material";
import Checkbox from "js/common/views/inputs/checkbox";
import * as Colors from "js/common/cube19-colors";
import Fullscreen from "react-full-screen";
import {ViewControls} from "js/common/react-flow-controls";
import {capitaliseWords} from "js/common/utils/strings";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const DependencyGraph = ({
  currentKpiId,
  idToNode,
  kpiIdToContributingKpis,
  kpiIdToDependentKpis,
  onDependencyClick,
  theme
}) => {

  const [showDirectOnly, setShowDirectOnly] = React.useState(true);
  const [highlightedRelationship, setHighlightedRelationship] = React.useState(null);
  const [selectedTypes, setSelectedTypes] = React.useState(Immutable.Set([
    "COMBINATION",
    "SIMPLE_SUM",
    "FORWARD_REPORT"]));
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  const edgeTypes = {
    custom: CustomEdge
  };

  const contributingNodeIds = getDirectNodeIds(currentKpiId, kpiIdToContributingKpis);
  const dependentNodeIds = getDirectNodeIds(currentKpiId, kpiIdToDependentKpis);

  const hiddenNodeIds = idToNode
      .filter((node, id) => {
        const dependencyTypes = node.get("dependencyTypeToParents", Immutable.Map()).keySeq();
        const isIndirect = showDirectOnly && !contributingNodeIds.includes(id) && !dependentNodeIds.includes(id);
        const hasHiddenDependencyTypes = !dependencyTypes.isEmpty()
            && !dependencyTypes.some(type => selectedTypes.includes(type));
        const isCurrentNode = currentKpiId === id;
        return isIndirect
            || (hasHiddenDependencyTypes && !isCurrentNode);
      })
      .keySeq()
      .toSet();

  const flowElements = React.useMemo(
      () => getFlowElements(
          theme,
          idToNode,
          hiddenNodeIds,
          showDirectOnly,
          currentKpiId,
          contributingNodeIds,
          dependentNodeIds,
          highlightedRelationship,
          setHighlightedRelationship),
      [
        idToNode,
        hiddenNodeIds,
        showDirectOnly,
        currentKpiId,
        contributingNodeIds,
        dependentNodeIds,
        highlightedRelationship,
        setHighlightedRelationship]);

  return flowElements && <Fullscreen
      enabled={isFullScreen}
      onChange={isFullScreen => setIsFullScreen(isFullScreen)}>
    <div style={{width: "100%", backgroundColor: theme.palette.background.card}}>
      <div style={{height: isFullScreen ? "100vh" : 500}}>
        <ReactFlowProvider>
          <Filters
              theme={theme}
              isFullScreen={isFullScreen}
              selectedTypes={selectedTypes}
              setSelectedTypes={setSelectedTypes}
              showDirectOnly={showDirectOnly}
              setShowDirectOnly={setShowDirectOnly} />
          <Graph
              flowElements={flowElements}
              onDependencyClick={onDependencyClick}
              edgeTypes={edgeTypes}
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
          />
        </ReactFlowProvider>
      </div>
    </div>
  </Fullscreen>;
};

const Graph = ({flowElements, onDependencyClick, edgeTypes, isFullScreen, setIsFullScreen}) => {

  const {fitView} = useZoomPanHelper();

  const onElementClick = (event, element) => {
    if (element.data.kpiId) {
      const timeOutDuration = isFullScreen ? 1000 : 0;
      isFullScreen && setIsFullScreen(false);
      setTimeout(() => {
        fitView();
        onDependencyClick(element.data.kpiId, "RELATIONSHIPS");
      }, timeOutDuration);
    }
  };

  return <ReactFlow
      elements={flowElements}
      onElementClick={onElementClick}
      edgeTypes={edgeTypes}
      connectionLineComponent={<div />}
      zoomOnScroll={false}
      panOnScroll={true}
      nodesConnectable={false}
      defaultPosition={[300, 20]}
      nodesDraggable={false}
      defaultZoom={0.75}
      onlyRenderVisibleElements={true}
      selectionKeyCode={null}
      paneMoveable={true}>
    <ViewControls
        isFullScreen={isFullScreen}
        setIsFullScreen={setIsFullScreen}
    />
  </ReactFlow>;
};

let uniqueFlowId = Math.floor(Math.random() * 1000);

const getFlowElements = (theme, idToNode, hiddenNodeIds, showDirectOnly, currentNodeId, contributingNodeIds, dependentNodeIds, highlightedRelationship, setHighlightedRelationship) => {

  const nodes = idToNode.valueSeq();
  const idToParentIds = GraphUtils.getIdToParentIds(nodes);
  const position = {x: 0, y: 1};

  uniqueFlowId++;
  const els = nodes
      .toArray()
      .flatMap(n => {
        const nodeId = n.get("id");

        if (hiddenNodeIds.includes(nodeId)) {
          return [];
        }

        const nodeType = getNodeType(nodeId, currentNodeId, contributingNodeIds, dependentNodeIds);
        let nodeStyle = nodeTypeToNodeStyle.get(nodeType);

        if (highlightedRelationship) {
          const isNodeHighlighted = (n.getIn(
                  ["edgesOut", highlightedRelationship.get("childId"), "dependencyTypes"],
                  Immutable.List())
                  .includes(highlightedRelationship.get("dependencyType"))
              || highlightedRelationship.get("childId") === nodeId);

          if (isNodeHighlighted) {
            nodeStyle.opacity = "100%";
          } else {
            nodeStyle.opacity = "50%";
          }
        } else {
          nodeStyle.opacity = nodeType === "INDIRECT" ? "50%" : "100%";
        }

        const flowNode = {
          id: nodeId + "-" + uniqueFlowId,
          cube19RenderOrder: nodeId.toString(),
          data: {
            kpiId: nodeId,
            label: <div style={{display: "flex", flexDirection: "column", width: "100%", height: "100%"}}>
              <div
                  style={{
                    fontSize: "1.4rem",
                    fontFamily: theme.typography.fontFamilyBold,
                    alignSelf: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%"
                  }}>
                {isNaN(nodeId) ? "<NEW>" : nodeId}
                <div
                    style={{
                      color: "#000",
                      display: "flex",
                      justifyContent: "space-around",
                      margin: "5px 40px 0px"
                    }}>
                  {n.get("dependencyTypeToParents", Immutable.Map())
                      .sortBy((values, key) => key)
                      .reverse()
                      .map((parents, type) => {

                        const areAllParentsHidden = parents.every(parent => hiddenNodeIds.includes(parent));
                        const opacity = areAllParentsHidden ? 0.4 : 0.85;

                        return <div style={{display: "flex", alignContent: "center"}}>
                          <i
                              key={type}
                              onMouseOver={e => {
                                if (!highlightedRelationship) {
                                  setHighlightedRelationship(Immutable.Map({
                                    childId: nodeId,
                                    dependencyType: type
                                  }));
                                }
                                e.stopPropagation();
                              }}
                              onMouseOut={e => {
                                setHighlightedRelationship(null);
                                e.stopPropagation();
                              }}
                              style={{marginLeft: 5, opacity, color: "#000"}}
                              className={dependencyTypeToDetails.getIn([type, "icon"])} />
                          {type === "COMBINATION" && <b
                              style={{
                                fontSize: "1rem",
                                paddingLeft: 3,
                                paddingBottom: 2,
                                width: 20,
                                opacity
                              }}>
                            {combineTypeToLabel.get(n.get("combineType"))}
                          </b>}
                        </div>;
                      }).toList()
                  }
                </div>
              </div>
              <div
                  style={{
                    fontSize: n.get("name").length > 22 ? theme.themeId === "light" ? "0.96rem" : "1rem" : "1.3rem",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center"
                  }}>
                <div style={{alignSelf: "center"}}>{n.get("name")}</div>
              </div>
            </div>
          },
          style: {
            width: 200,
            height: (n.get("dependencyTypeToParents") && n.get("name").length > 10) || n.get("name").length > 45 ? 180 : 140,
            borderRadius: 15,
            boxShadow: "5px 5px 10px black", ...nodeStyle
          },
          position
        };

        const parentIds = idToParentIds.get(nodeId, Immutable.Set());
        let nodeIsAboveSiblingMidPoint;
        if (parentIds.size === 1) {
          const parentId = parentIds.first();
          const parentNode = idToNode.get(parentId);
          const childIdsOfParent = parentNode.get("edgesOut", Immutable.Map()).map(e => e.get("nodeId")).toList();
          const nodeIndexInSiblings = childIdsOfParent.indexOf(nodeId);
          const siblingCount = childIdsOfParent.size;
          nodeIsAboveSiblingMidPoint = nodeIndexInSiblings < (siblingCount / 2);
        } else {
          nodeIsAboveSiblingMidPoint = false;
        }

        const flowEdges = addRenderOrder(
            nodeId,
            nodeIsAboveSiblingMidPoint,
            n.get("edgesOut", Immutable.List()).toList())
            .filter(edge => !hiddenNodeIds.includes(edge.get("nodeId")))
            .toArray()
            .map(edge => {
              const parentId = nodeId;
              const childId = edge.get("nodeId");
              const edgeId = parentId + "->" + childId;
              let animated = false;

              const edgeType = getEdgeType(currentNodeId, childId, parentId, contributingNodeIds, dependentNodeIds);
              let edgeStyle;

              if (highlightedRelationship) {
                if (childId === highlightedRelationship.get("childId") && edge.get("dependencyTypes")
                    .includes(highlightedRelationship.get("dependencyType"))) {
                  edgeStyle = {stroke: theme.palette.textColor, strokeWidth: 2};
                } else {
                  edgeStyle = {opacity: "50%"};
                }
              } else {
                edgeStyle = nodeTypeToEdgeStyle.get(edgeType);
              }

              return {
                id: edgeId + "-" + uniqueFlowId,
                cube19RenderOrder: edge.get("cube19RenderOrder"),
                animated,
                type: "straight",
                style: edgeStyle,
                source: parentId + "-" + uniqueFlowId,
                target: childId + "-" + uniqueFlowId
              };
            });

        return [flowNode, ...flowEdges];
      });

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({rankdir: toFlowDirection("vertical")});

  els.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {width: el.style.width, height: el.style.height});
    } else {
      let eleWidth = 90;
      dagreGraph.setEdge(el.source, el.target, {width: eleWidth});
    }
  });

  dagre.layout(dagreGraph);
  const positionedEls = els.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = "top";
      el.sourcePosition = "bottom";

      el.position = {
        x: nodeWithPosition.x + Math.random() / 1001,
        y: nodeWithPosition.y
      };
    }
    return el;
  });

  return positionedEls.sort((a, b) => a.cube19RenderOrder.localeCompare(b.cube19RenderOrder));
};

const Filters = ({theme, selectedTypes, setSelectedTypes, showDirectOnly, setShowDirectOnly, isFullScreen}) => {

  const switchStyle = css`
    [class*="MuiSwitch-track"] {
      background-color: ${showDirectOnly ? theme.palette.primary.main : Colors.lightestGrey} !important;
      opacity: 1 !important;
    }

    [class*="MuiSwitch-thumb"] {
      background-color: white;
    }`;

  return <div
      style={{
        width: "100%",
        position: "absolute",
        display: "flex",
        justifyContent: "space-between"
      }}>
    <div style={{backgroundColor: theme.themeId === "light" ? theme.palette.background.paper : Colors.greyLight, padding: "10px 0px 10px 10px", margin: 7, zIndex: 99}}>
      <b>Filter Options:</b>
      <div style={{display: "flex", flexDirection: "column", marginLeft: 15, marginTop: 15, marginBottom: 10}}>
        {dependencyTypeToDetails.map((details, type) => <Checkbox
            key={type}
            id={type}
            iconStyle={{padding: 2}}
            checked={selectedTypes.includes(type)}
            onCheck={(e, isChecked) => setSelectedTypes(isChecked
                ? selectedTypes.add(type)
                : selectedTypes.delete(type))}
            label={<div>{details.get("label")}<i style={{marginLeft: 5}} className={details.get("icon")} />
            </div>} />).toList()}
      </div>
      <FormControlLabel
          style={{width: 200, marginRight: 0}}
          control={<Switch
              checked={showDirectOnly}
              css={switchStyle}
              onChange={e => setShowDirectOnly(e.target.checked)} />}
          label="Show only direct dependencies" />
    </div>
    <div
        style={{
          backgroundColor: theme.themeId === "light" ? theme.palette.background.paper : Colors.greyLight,
          marginRight: isFullScreen ? 7 : 20,
          marginTop: 7,
          padding: 10,
          height: 230,
          zIndex: 99
        }}>
      <b>Key:</b>
      <div style={{display: "flex", fontSize: "0.9rem", marginTop: 10}}>
        <div
            style={{
              ...keyCubeStyle,
              border: `2px solid ${Colors.c19Green}`
            }} />
        Current Metric
      </div>
      <div style={{display: "flex", fontSize: "0.9rem", marginTop: 5}}>
        <div
            style={{
              ...keyCubeStyle,
              border: `2px solid ${Colors.redIcon}`
            }} />
        Dependent Metric
      </div>
      <div style={{paddingTop: 15, fontSize: "0.95rem"}}>
        Inheritance Types
        <div style={{fontSize: "0.8rem", padding: "5px 5px 0"}}>
          {combineTypeToLabel.map((symbol, type) => <div key={type}>
            <b>{symbol}</b>{" - " + capitaliseWords(type.replaceAll("_", " "))}
          </div>).toList()}
        </div>
      </div>
    </div>
  </div>;
};

const dependencyTypeToDetails = Immutable.fromJS({
  "FORWARD_REPORT": {
    icon: "fa fa-arrow-right",
    label: "Forward Report"
  },
  "SIMPLE_SUM": {
    icon: "fa fa-plus",
    label: "Simple Sum"
  },
  "COMBINATION": {
    icon: "far fa-clone",
    label: "Inheriting"
  }
});

const combineTypeToLabel = Immutable.fromJS({
  "MERGE_WITH_AND": "AND",
  "MERGE_WITH_OR": "OR",
  "COMPLEX_MERGE": "CPLX",
  "OVERWRITE": "OW"
});

const getDirectNodeIds = (startingNodeId, kpiIdToNextGeneration) => {
  let currentNodeId = startingNodeId;
  let paths = Immutable.Set();
  let directNodeIds = Immutable.Set();

  while (currentNodeId) {
    directNodeIds = directNodeIds.add(currentNodeId);
    const nextGenNodes = kpiIdToNextGeneration.get(currentNodeId);
    if (nextGenNodes) {
      if (nextGenNodes.size > 1) {
        paths = paths.union(nextGenNodes.rest());
      }
      currentNodeId = nextGenNodes.first().get("id");
    } else {
      if (!paths.isEmpty()) {
        currentNodeId = paths.first().get("id");
        paths = paths.rest();
      } else {
        currentNodeId = null;
      }
    }
  }
  return directNodeIds;
};

const getNodeType = (nodeId, currentNodeId, contributingNodeIds, dependentNodeIds) => {
  if (nodeId === currentNodeId) {
    return "CURRENT";
  } else if (contributingNodeIds.includes(nodeId)) {
    return "CONTRIBUTOR";
  } else if (dependentNodeIds.includes(nodeId)) {
    return "DEPENDENT";
  } else {
    return "INDIRECT";
  }
};

const getEdgeType = (currentNodeId, childNodeId, parentNodeId, contributingNodeIds, dependentNodeIds) => {
  if (contributingNodeIds.includes(parentNodeId) && (contributingNodeIds.includes(childNodeId)
      || childNodeId
      === currentNodeId)) {
    return "CONTRIBUTOR";
  } else if (dependentNodeIds.includes(childNodeId) && (dependentNodeIds.includes(parentNodeId)
      || parentNodeId
      === currentNodeId)) {
    return "DEPENDENT";
  } else {
    return "INDIRECT";
  }
};

const nodeTypeToNodeStyle = Immutable.Map({
  CURRENT: {border: `4px solid ${Colors.c19Green}`},
  CONTRIBUTOR: {},
  DEPENDENT: {border: `4px solid ${Colors.redIcon}`},
  INDIRECT: {opacity: "50%"}
});

const nodeTypeToEdgeStyle = Immutable.Map({
  CURRENT: {strokeWidth: 1.5, stroke: Colors.white},
  CONTRIBUTOR: {strokeWidth: 1.5},
  DEPENDENT: {stroke: Colors.redIcon, strokeWidth: 1.5},
  INDIRECT: {opacity: "50%", strokeDasharray: 4}
});

const keyCubeStyle = {
  height: 18,
  width: 18,
  background: "white",
  borderRadius: 5,
  marginRight: 5
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <DependencyGraph theme={theme} {...props} />;
};
