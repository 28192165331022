import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";

export default createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        text: ReactPropTypes.oneOfType([
            ReactPropTypes.string,
            ReactPropTypes.element
        ]).isRequired,
        icon: ReactPropTypes.string,
        style: ReactPropTypes.object,
        textStyle: ReactPropTypes.object,
        iconStyle: ReactPropTypes.object
    },

    getDefaultProps() {
        return {
            icon: "info-circle"
        };
    },

    render() {
        const { text, icon, style, iconStyle, textStyle } = this.props;
        return (
            <div className="text-box-wrapper" style={{ ...wrapperStyle, ...style }}>
                <i className={`fa fa-${icon}`} style={{ ...baseIconStyle, ...iconStyle }} />
                <span style={{ display: "table-cell", ...textStyle }}>{text || this.props.children}</span>
            </div>
        );
    }

});

const wrapperStyle = {
    fontSize: "0.8rem",
    lineHeight: 1.6,
    display: "inline-block",
    width: "100%",
    borderRadius: 5
};

const baseIconStyle = {
    display: "table-cell",
    paddingLeft: 5,
    paddingRight: 8
};
