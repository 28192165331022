import {css} from "@emotion/react";
import * as Colors from "js/common/cube19-colors";

export const introContainer = (theme) => css`
  display: flex;
  background: ${theme.themeId === "light" ? theme.palette.background.card : "transparent"};
  flex-direction: row;
  max-width: ${theme.themeId === "light" ? "100%" : "90em"};;
  width: ${theme.themeId === "light" ? "100%" : "98%"};
  padding: ${theme.themeId === "light" ? "1em calc(1% + 0.9375em)" : "0 0.9375em"};
  align-items: center;
  justify-content: space-between;
  align-self: center;
  margin: ${theme.themeId === "light" ? "0" : "1.6em 0"};
`;

export const imageContainer = css`
  display: flex;
  margin-right: 2em;
  img {
    width: 94px;
    margin-top: -4px;
  }
`;

export const infoContainer = (theme) => css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  margin-right: 5em;
  h2 {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: ${theme.themeId === "light" ? "22px" : "32px"};
  }
  p {
    color: ${theme.palette.text.main};
    margin-bottom: 0;
    max-width: 70%;
    font-size: 15px;
  }
`;
