/** @jsxImportSource @emotion/react */
import React from "react";
import Immutable from "immutable";
import FormulaList from "js/squids/squid-display/edit-mode/formula-tab/formula-list";
import {TextButton} from "js/common/views/inputs/buttons";
import {css} from "@emotion/react";
import * as Colors from "js/common/cube19-colors";


const FormulaeTab = React.memo(({
      handleEditPage,
      page,
      setActiveTab,
      setIsEditNodeOpen,
      setEditNodeIndex,
      openSteps,
      setOpenSteps,
      formulaErrors,
      inputDelayMillis
    }) => {
      const [inProgressFormulaIndexes, setInProgressFormulaIndexes] = React.useState(Immutable.Set());

      const formulas = page.get("formulas", Immutable.List());

      const createNewFormula = () => {
        handleEditPage(page.set("formulas",
            formulas
                .push(
                    Immutable.fromJS({
                      id: "new-formula",
                      str: "",
                      formatAs: "NUMBER"
                    }))));
        setInProgressFormulaIndexes(inProgressFormulaIndexes.add(formulas.size));
      };

      const deleteStep = index => {
        setInProgressFormulaIndexes(inProgressFormulaIndexes.filter(formula => formula !== index));
        handleEditPage(page.deleteIn(["formulas", index]));
      };

      const toggleFormula = (index) => {
        if (inProgressFormulaIndexes.includes(index)) {
          setInProgressFormulaIndexes(inProgressFormulaIndexes.filter(i => i !== index));
        } else {
          setInProgressFormulaIndexes(inProgressFormulaIndexes.add(index));
        }
      };

      return (
          <div css={formulaeTabStyles}>
            <TextButton
                label="New Formula"
                onClick={createNewFormula}
                style={newFormulaButtonStyles} />
            <FormulaList
                toggleFormula={toggleFormula}
                deleteStep={deleteStep}
                inProgressFormulaIndexes={inProgressFormulaIndexes}
                setInProgressFormulaIndexes={setInProgressFormulaIndexes}
                openSteps={openSteps}
                setOpenSteps={setOpenSteps}
                handleEditPage={handleEditPage}
                page={page}
                setActiveTab={setActiveTab}
                setIsEditNodeOpen={setIsEditNodeOpen}
                setEditNodeIndex={setEditNodeIndex}
                formulaErrors={formulaErrors}
                inputDelayMillis={inputDelayMillis} />
          </div>
      );
    }
);

const formulaeTabStyles = css`
  button {
    text-transform: none;
  }

  .MuiInputBase-input {
    color: #8DABC4;
  }
`;

const newFormulaButtonStyles = {
  display: "block",
  margin: "20px 20px 20px auto",
  color: "white",
  backgroundColor: Colors.java
};


export default FormulaeTab;