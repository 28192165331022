import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import PropTypes from "prop-types";
import createReactClass from "create-react-class";

import SectionHeader from "js/admin/cubetv/section-header";
import NumberField from "js/common/views/inputs/number-field";
import Layout from "js/common/views/foundation-column-layout";

import { TextField } from '@mui/material';
import Checkbox from "js/common/views/inputs/checkbox";

export default createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    slide: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
  },

  getInitialState() {
    return {
      isValidUrl: true
    };
  },

  render() {
    const {slide} = this.props;
    return (
        <div>
          <SectionHeader text="Display a web page" />
          <EditIframeSlide
              displayTimeInMillis={slide.get("timing")}
              onDisplayTimeChange={this.handleDisplayTimeChange}
              url={slide.getIn(pathToUrl)}
              onUrlChange={this.handleUrlChange}
              title={slide.getIn(pathToTitle)}
              onTitleChange={this.handleTitleChange}
              isValidUrl={this.state.isValidUrl}
              isCustomSizeEnabled={slide.getIn(pathToCustomSizeBool)}
              onIsCustomSizeEnabledChange={this.handleIsCustomSizeEnabledChange}
              width={slide.getIn(pathToWidth)}
              onWidthChange={this.handleWidthChange}
              height={slide.getIn(pathToHeight)}
              onHeightChange={this.handleHeightChange} />
        </div>
    );
  },

  handleDisplayTimeChange(newDisplayTime) {
    this.props.onChange(this.props.slide.set("timing", newDisplayTime));
  },

  handleUrlChange(newUrl) {
    this.props.onChange(this.props.slide.setIn(pathToUrl, newUrl));
    this.setState({
      isValidUrl: isValidUrl(newUrl)
    });
  },

  handleTitleChange(newTitle) {
    let updatedSlide = this.props.slide.setIn(pathToTitle, newTitle);
    updatedSlide = updatedSlide.setIn(pathToHasTitle, newTitle !== "");
    this.props.onChange(updatedSlide);
  },

  handleIsCustomSizeEnabledChange(isCustomSizeEnabled) {
    this.props.onChange(this.props.slide.setIn(pathToCustomSizeBool, isCustomSizeEnabled));
  },

  handleWidthChange(newWidth) {
    this.props.onChange(this.props.slide.setIn(pathToWidth, newWidth));
  },

  handleHeightChange(newHeight) {
    this.props.onChange(this.props.slide.setIn(pathToHeight, newHeight));
  }

});

const EditIframeSlide = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    displayTimeInMillis: PropTypes.number.isRequired,
    onDisplayTimeChange: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    onUrlChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    onTitleChange: PropTypes.func.isRequired,
    isValidUrl: PropTypes.bool.isRequired,
    isCustomSizeEnabled: PropTypes.bool.isRequired,
    onIsCustomSizeEnabledChange: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    onWidthChange: PropTypes.func.isRequired,
    height: PropTypes.number.isRequired,
    onHeightChange: PropTypes.func.isRequired
  },

  render() {
    const invalidUrlMsg = "Please enter a valid URL that begins with 'https://'. For example: https://www.example.com";
    return (
        <div style={{padding: "0.5rem"}}>
          <Layout allSmall={12} medium={[12, 12, 12, 12]} large={[3, 3, 5, 12]} floatLastColumnLeft={true}>
            <NumberField style={{marginTop: 15}}
                range={[1, 10800]}
                label="Seconds to Display Slide"
                value={this.props.displayTimeInMillis / 1000}
                onChange={displayTimeInSecs => this.props.onDisplayTimeChange(displayTimeInSecs * 1000)} />
            <TextField variant="standard" style={{marginTop: 15}} InputLabelProps={{style: {color: "#757575"}}}
                fullWidth={true}
                label="Title"
                value={this.props.title}
                onChange={e => this.props.onTitleChange(e.target.value)} />
            <TextField variant="standard" style={{marginTop: 15}} InputLabelProps={{style: {color: "#757575"}}}
                fullWidth={true}
                label="Web page URL"
                value={this.props.url}
                error={!this.props.isValidUrl}
                helperText={this.props.isValidUrl ? "" : invalidUrlMsg}
                onChange={e => this.props.onUrlChange(e.target.value)} />
            <div>
              <Checkbox
                  label="Custom Size"
                  style={{marginTop: "1.3rem", marginRight: "1.5rem"}}
                  checked={this.props.isCustomSizeEnabled}
                  onCheck={(e, isCustomSizeEnabled) => this.props.onIsCustomSizeEnabledChange(isCustomSizeEnabled)} />
              <span style={{visibility: this.props.isCustomSizeEnabled? "visible" : "hidden"}}>
                <NumberField
                    style={{display: "inline-block", marginTop: "1rem", marginRight: "1rem"}}
                    range={[1, 10000]}
                    label="Page width in pixels"
                    value={this.props.width}
                    onChange={width => this.props.onWidthChange(width)} />
                <NumberField
                    style={{display: "inline-block", marginTop: "1rem"}}
                    range={[1, 10000]}
                    label="Page height in pixels"
                    value={this.props.height}
                    onChange={height => this.props.onHeightChange(height)} />
              </span>
            </div>
          </Layout>
        </div>
    );
  }

});

const pathToConfig = ["layout", "fullscreen", 0];
const pathToTitle = ["pageTitle", "title"];
const pathToUrl = [...pathToConfig, "url"];
const pathToHasTitle = [...pathToConfig, "hasTitle"];
const pathToCustomSizeBool = [...pathToConfig, "isCustomSizeEnabled"];
const pathToWidth = [...pathToConfig, "pageWidth"];
const pathToHeight = [...pathToConfig, "pageHeight"];

const isValidUrl = string => {
  try {
    const url = new URL(string);
    return url.protocol === "https:";
  } catch (_) {
    return false;
  }
};
