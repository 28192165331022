import * as Colors from "js/common/cube19-colors";

export const defaultSquid = {
  id: "default",
  page: {
    background: Colors.grey,
    titleColor: Colors.white
  },
  edge: {
    style: {stroke: Colors.java},
    type: "custom"
  },
  label: {
    labelStyle: {
      color: Colors.java
    },
    labelBgStyle: {
      border: `1px solid ${Colors.java}`,
      background: Colors.labelBg
    },
    labelBgPadding: [10, 6],
    labelBgBorderRadius: 13
  },
  node: {
    highlight: {
      id: "highlight",
      border: "2px solid #FFAB2E"
    },
    dark: {
      id: "dark",
      border: "2px solid transparent",
      opacity: 0.5
    },
    edit: {
      border: "2px solid #FFAB2E"
    },
    default: {
      border: "2px solid transparent",
      id: "default"
    }
  }
};