/** @jsxImportSource @emotion/react */
import React from "react";
import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import {TextButton} from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";


const NodeIdDialog = React.memo(({
      isOpen,
      newNodeDetails,
      setNewNodeDetails,
      onRequestClose,
      onButtonClick,
      editNodeId,
      nodeIds
    }
) => {

  const newNodeId = newNodeDetails.get("newNodeId");
  const isIdDuplicate = nodeIds.contains(newNodeId) && newNodeId !== editNodeId;

  const dialogContent = <div>
    <h4 style={{marginBottom: 10}}>Give your node a unique ID</h4>
    <div style={{lineHeight: 1.5}}>Nodes require a unique identifier. This identifier an be used as a reference within
      all squid formulae. You can edit this ID anytime. Node IDs must not include spaces.
    </div>
    <DelayedTextField
        delayInMillis={100}
        label="Node ID"
        theme="cube2021"
        autoFocus={true}
        error={isIdDuplicate}
        helperText={isIdDuplicate && "This node ID is already in use"}
        value={newNodeDetails.get("newNodeId") || ""}
        onChange={value => setNewNodeDetails(newNodeDetails.set("newNodeId", value.replaceAll(" ", "-")))}
        style={{width: "100%", textAlign: "left"}}
        InputProps={{disableUnderline: true}} />
  </div>;

  const dialogActions = <TextButton
      key="btn"
      style={{textTransform: "none", backgroundColor: "#3EC3D4"}}
      type="success"
      disabled={isIdDuplicate}
      label="Save & Continue"
      onClick={onButtonClick} />;

  return <Dialog
      open={isOpen}
      theme="cube2021"
      iconStyle="info"
      onBackdropClick={onRequestClose}
      children={dialogContent}
      actions={dialogActions} />;
});

export default NodeIdDialog;
