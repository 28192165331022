import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";

import TrendConfig from "js/oneview/trends/trend-config";
import LoadingSpinner from "js/oneview/loading-spinner";
import TrendChart from "js/oneview/trends/trend-chart";
import * as charting from "js/charting/charting-defaults";
import * as Users from "js/common/users";
import {fullReloadForTrendChart} from "js/charting/trend/data-loader";
import {getDefaultColour} from "js/charting/trend/target-trend-color-util";
import {between} from "js/common/utils/numbers";
import * as kpiRepo from "../../common/repo/backbone/kpi-repo";

export default createReactClass({

  propTypes: {
    ratio: ReactPropTypes.object.isRequired,
    timeframe: ReactPropTypes.object.isRequired,
    qualifierType: ReactPropTypes.string.isRequired,
    qualifierId: ReactPropTypes.number.isRequired
  },

  getInitialState() {
    const {
      timeframe,
      qualifierType,
      qualifierId,
      ratio,
      clientIds,
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds
    } = this.props;
    const defaultChart = charting.getDefaultConfigForChartType("TREND", "Trend Chart 1");
    const kpiIds = [ratio.firstKpiId, ratio.secondKpiId];
    const allKpisAggregable = kpiIds.every(kpiId => kpiRepo.get(kpiId).get("trendDataAggregable"));

    return {
      isLoading: true,
      trends: [],
      config: {
        ...defaultChart,
        ratio,
        trendType: "ADVANCED",
        timeframe: timeframe.getRawJson(),
        qualifierType: qualifierType,
        qualifierId: qualifierId,
        dataAggregation: allKpisAggregable ? this.getInitialDataAggregation() : "DAILY",
        clientIds: clientIds,
        matchAnyTagIds: matchAnyTagIds,
        matchAllTagIds: matchAllTagIds,
        excludedTagIds: excludedTagIds
      }
    };
  },

  componentDidMount() {
    this.loadAdvancedChartFromConfig(this.state.config);
  },

  render() {
    const {isLoading} = this.state;
    return (
        <div>
          {this.renderChartConfigOptions()}
          {!isLoading ? this.renderChart() : <LoadingSpinner customIconStyle={{padding: "50px"}} />}
        </div>);
  },

  loadAdvancedChartFromConfig(config) {
    const chartConfig = {
      ...config,
      dataGroups: getDefaultRatiosDataGroup(config)
    };
    const {promiseForFinalData} = fullReloadForTrendChart(chartConfig);
    promiseForFinalData.then(data => {
      this.setState({
        isLoading: false,
        config: chartConfig,
        trends: [data.trends[0]]
      });
    });
  },

  renderChart() {
    return (<TrendChart
        trends={this.state.trends}
        config={this.state.config}
        startDate={this.props.timeframe.get("start")}
        endDate={this.props.timeframe.get("end")} />);
  },

  renderChartConfigOptions() {
    const {config} = this.state;
    const {kpiId, timeframe, qualifierType, qualifierId} = this.props;
    const isUserOneView = qualifierType === "USER";
    const groupId = isUserOneView ? Users.getUser(qualifierId).get("groupId") : qualifierId;
    const currentOneViewConfig = {
      kpiId,
      timeframe,
      groupId,
      qualifierType,
      qualifierId
    };
    return (
        <TrendConfig
            config={config}
            currentOneViewConfig={currentOneViewConfig}
            onConfigChange={this.handleConfigChange} />
    );
  },

  handleConfigChange(changedConfig) {
    const oldConfig = this.state.config;
    const newConfig = {
      ...oldConfig,
      ...changedConfig
    };
    this.setState({
      isLoading: true,
      config: newConfig
    });
    this.loadAdvancedChartFromConfig(newConfig);
  },

  getInitialDataAggregation() {
    const start = this.props.timeframe.get("start");
    const end = this.props.timeframe.get("end");
    const noOfDays = end.diff(start, "days") + 1;
    let dataAggregation;

    if (between(noOfDays, 1, 31)) {
      dataAggregation = "DAILY";
    }
    if (between(noOfDays, 31, 180)) {
      dataAggregation = "WEEKLY";
    }
    if (between(noOfDays, 180, 365 * 2)) {
      dataAggregation = "MONTHLY";
    }
    if (between(noOfDays, 365 * 2, 365 * 5)) {
      dataAggregation = "QUARTERLY";
    }
    if (noOfDays > 365 * 5) {
      dataAggregation = "YEARLY";
    }

    return dataAggregation;
  }
});

const getDefaultRatiosDataGroup = config => {
  const index = 0;
  const dataGroupIndex = 0;
  const chartTheme = "DEFAULT";
  const seriesColour = getDefaultColour(index, dataGroupIndex, chartTheme);
  const howToPlot = config.displayDataCumulatively ? "cumulative" : "non-cumulative";
  return [
    {
      cid: Math.random(),
      name: "Data Group 1",
      timeframe: config.timeframe,
      trendConfigs: [
        {
          cid: Math.random(),
          type: "RATIO",
          ratio: config.ratio,
          whatToPlot: config.averageByUser ? "average-by-user" : "value",
          seriesColour,
          lineStyle: "Solid",
          dataPointMarker: null,
          howToPlot,
          yAxis: 0
        }],
      qualifierType: config.qualifierType,
      qualifierId: config.qualifierId,
      matchAnyTagIds: config.matchAnyTagIds,
      matchAllTagIds: config.matchAllTagIds,
      excludedTagIds: config.excludedTagIds,
      clientIds: config.clientIds,
      dateDisplay: "ABSOLUTE"
    }];
};
