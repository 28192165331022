import Immutable from "immutable";
import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Sortable from "react-sortable-mixin";
import ErrorMsg from "js/common/views/error";
import { IconButton } from "js/common/views/inputs/buttons";
import { TextButton } from "js/common/views/inputs/buttons";
import { isMobile, isDesktop } from "js/common/ua-parser";
import { getDataAggregations } from "js/charting/trend/data-aggregator";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import InfoTextBox from "js/common/views/info-text-box";
import Tooltip from "js/common/views/tooltips";
import DataAggregationPicker from "js/charting/trend/data-aggregation-picker";

import Dialog from "js/common/views/dialog";
import Checkbox from "js/common/views/inputs/checkbox";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const ChartContainer = createReactClass({

    mixins: [ isDesktop() && Sortable.ItemMixin ],
    displayName: "ChartingContainer",

    propTypes: {
        config: ReactPropTypes.object.isRequired,
        onConfigChange: ReactPropTypes.func.isRequired,
        data: ReactPropTypes.object.isRequired,
        onRemoveChartClick: ReactPropTypes.func.isRequired,
        onEditChartConfigClick: ReactPropTypes.func.isRequired,
        onCloneChartClick: ReactPropTypes.func.isRequired,
        containerWidth: ReactPropTypes.number.isRequired,
        error: ReactPropTypes.string
    },

    shouldComponentUpdate(nextProps) {
        return nextProps.config !== this.props.config
            || nextProps.data !== this.props.data;
    },

    render() {
        const {
            children,
            config,
            onRemoveChartClick,
            onEditChartConfigClick,
            onCloneChartClick,
            onConfigChange,
            error,
            containerWidth,
            theme
        } = this.props;
        const isFullWidth = config.widthScale === 1;
        const columnStyle = {
            paddingTop: "0.5rem",
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            paddingBottom: "0.5rem"
        };
        const containerStyle = {
            border: `1px solid ${theme.palette.background.card}`,
            borderRadius: "5px",
            background: theme.palette.background.card,
            paddingTop: "1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem"
        };

        return (
            <div className={`kpi-trend-chart small-12 columns medium-${isFullWidth ? 12 : 6}`} style={columnStyle}>
                <div style={containerStyle}>
                    <Header
                        theme={theme}
                        config={config}
                        error={error}
                        onConfigChange={onConfigChange}
                        onRemoveChartClick={onRemoveChartClick}
                        onEditChartConfigClick={onEditChartConfigClick}
                        onCloneChartClick={onCloneChartClick}
                        containerWidth={containerWidth} />
                    {children}
                </div>
            </div>);
    }

});

const Header = createReactClass({

    propTypes: {
        config: ReactPropTypes.object.isRequired,
        onConfigChange: ReactPropTypes.func.isRequired,
        onRemoveChartClick: ReactPropTypes.func.isRequired,
        onEditChartConfigClick: ReactPropTypes.func.isRequired,
        containerWidth: ReactPropTypes.number.isRequired
    },

    render() {
        const {
            config,
            onEditChartConfigClick,
            onRemoveChartClick,
            onCloneChartClick,
            containerWidth,
            error,
            theme
        } = this.props;
        const isFullWidth = config.widthScale === 1;
        const { trendType, kpiIds, dataGroups } = config;
        const isStandardChart = trendType === "STANDARD";
        const isAdvancedChart = trendType === "ADVANCED";
        const allKpiIds = isStandardChart && kpiIds || isAdvancedChart && dataGroups.flatMap(dg =>
            dg.trendConfigs.flatMap(tc => {
                if (tc.type === "KPI") {
                    return [tc.kpiId];
                } else {
                    const ratio = tc.ratio;
                    return [ratio.firstKpiId, ratio.secondKpiId]
                }
            }));
        const isDataAggregatorVisible = allKpiIds.length > 0;
        const allKpisAggregable = allKpiIds.every(kpiId => kpiRepo.get(kpiId).get("trendDataAggregable"));
        const isWeeklyDataAggregationWarningVisible = isDataAggregatorVisible && config.dataAggregation === "WEEKLY";
        return (
            <div>
                <div className="row collapse" style={{ padding:"0.5rem 0", maxWidth:"100%" }}>
                    <div className={`columns small-12 ${containerWidth <= 1024 && !isFullWidth ? "" : "medium-5"}`}
                        style={{color: theme.palette.primary.main, fontSize: "0.875rem", paddingBottom: "0.5rem"}}>
                        { config.name }
                    </div>
                    <div
                        className={`columns small-12 ${containerWidth <= 1024 && !isFullWidth ? "" : "medium-7 text-right"}`}
                        style={{display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "10px"}}>
                        { isDesktop() && this.renderDragHandle() }
                        { !config.isExampleChart &&
                            <IconButton
                                type="bright"
                                icon="clone"
                                label="Clone Trend Chart"
                                container="column"
                                style={{width: "auto", height: "auto", paddingTop: 0, paddingBottom: 0, color: theme.palette.text.primary}}
                                onClick={() => onCloneChartClick(config) }
                                size="large" />
                        }
                        <IconButton
                            type="bright"
                            icon="cogs"
                            label="Edit Trend Chart"
                            style={{width: "auto", height: "auto", paddingTop: 0, paddingBottom: 0, color: theme.palette.text.primary}}
                            container="column"
                            onClick={() => onEditChartConfigClick(config) }
                            size="large" />
                        {!isMobile() && this.getResizeButton()}
                        <DeleteChartDialogButton theme={theme} onDeleteChart={onRemoveChartClick} />
                    </div>
                </div>
                {isDataAggregatorVisible &&
                    <div style={{display: "inline-flex"}}>
                        {allKpisAggregable &&
                            <DataAggregationPicker
                                dataAggregations={getDataAggregations(config.dataAggregation)}
                                value={config.dataAggregation}
                                onChange={dataAggregation => this.onConfigChange({ dataAggregation })}
                                style={{width: 235}} />
                        }
                        {!allKpisAggregable &&
                            <div>
                                <Tooltip
                                    text="Data aggregation changes not available for one or more of the Metrics in this chart"
                                    position="right"
                                    styleType="light">
                                     <InfoTextBox
                                        text="Data aggregation changes not available"
                                        style={{paddingTop: 6, paddingBottom: 6, paddingLeft: 3, backgroundColor: theme.palette.background.card}} />
                                </Tooltip>
                            </div>}
                        {isWeeklyDataAggregationWarningVisible &&
                            <div>
                                <InfoTextBox
                                    text="Weekly trend aggregations may start/end with a partial week's data"
                                    style={{paddingTop: 6, paddingBottom: 6, paddingLeft: 3, backgroundColor: theme.palette.background.card}} />
                            </div>}
                    </div>}
                {!config.isExampleChart &&
                    <Checkbox
                        label="Show Data Points"
                        checked={config.showDataPoints}
                        onCheck={(e, isChecked) => this.onConfigChange({ showDataPoints: isChecked })}
                        iconStyle={{marginRight: 5}}
                        style={{
                            display: "inline-block",
                            width: 200,
                            verticalAlign: "top",
                            marginTop: -4,
                            marginLeft: isFullWidth ? "1rem" : 0
                        }} />}
                {error && <ErrorMsg text={error} />}
            </div>
        );
    },

    onConfigChange( changedConfig ) {
        const { onConfigChange, config } = this.props;
        const chartId = config.cid;
        let newConfig = { ...config, ...changedConfig };
        onConfigChange( chartId, Immutable.fromJS(newConfig) );
    },

    renderDragHandle() {
        const { theme } = this.props;
        const dragHandleStyle = {
            position: "relative",
            textAlign: "center",
            zIndex: 1,
            cursor:"move",
            clear:"both"
        };

        return (
            <div className="drag-handle" style={{...dragHandleStyle }}>
                <IconButton
                    type="bright"
                    icon="arrows"
                    label="Re-Order"
                    isDragHandle
                    container="column"
                    disableClick={true}
                    style={{textAlign: "center", paddingTop: 0, paddingBottom: 0, color: theme.palette.text.primary}}
                    size="large" />
            </div>
        );
    },

    getResizeButton() {
        const { theme } = this.props;
        const isFullWidth = this.props.config.widthScale === 1;
        return (
            <IconButton
                type="bright"
                icon={isFullWidth ? "compress" : "expand"}
                label={isFullWidth ? "Half Width" : "Full Width"}
                container="column"
                style={{width: "auto", height: "auto", verticalAlign: "top", paddingTop: 0, paddingBottom: 0, color: theme.palette.text.primary}}
                onClick={this.handleResizeClick}
                size="large" />
        );
    },

    handleResizeClick() {
        const { onConfigChange, config } = this.props;
        const chartId = config.cid;
        const isFullWidth = config.widthScale === 1;
        let newConfig = { ...config };
        newConfig.widthScale = isFullWidth ? 0.5 : 1;
        onConfigChange(chartId, newConfig);
    }

});

const DeleteChartDialogButton = createReactClass({

    propTypes: {
        onDeleteChart: ReactPropTypes.func.isRequired
    },

    getInitialState() {
        return { open: false };
    },

    handleOpen() {
        this.setState({ open: true });
    },

    handleDelete() {
        this.props.onDeleteChart();
        this.setState({open: false});
    },

    handleCancel() {
        this.setState({open: false});
    },

    render() {
        const { theme } = this.props;
        return (
            <div style={{display:"inline-block", textAlign: "center", verticalAlign: "top", marginLeft: "10px"}}>
                <IconButton
                    icon="times"
                    style={{padding: 0, color: theme.palette.text.primary}}
                    container="column"
                    label="Delete"
                    type="bright"
                    hoverType="alert"
                    onClick={this.handleOpen}
                    size="large" />
                <Dialog
                    title="Delete Chart?"
                    autoDetectWindowHeight={true}
                    modal={false}
                    open={this.state.open}
                    onRequestClose={this.handleClose}
                    actions={[
                        <TextButton
                            type="default"
                            key="cancel-btn"
                            label="Cancel"
                            icon="history"
                            onClick={this.handleCancel}/>,
                        <TextButton
                            type="primary"
                            key="delete-btn"
                            label="Delete"
                            icon="trash"
                            onClick={this.handleDelete} />
                    ]}>
                    <p>Are you sure you want to delete this chart?</p>
                </Dialog>
            </div>
        );
    }
});


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <ChartContainer theme={theme} {...props} />;
  };

  ChartContainer.displayName = "ChartContainer";
  export default Wrapper;
