
const capitaliseWords = (str) => {
    if (!str) {
        return str;
    }
    return str.replace(/\w\S*/g, txt => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
};

const startsWith = (pattern, str) => str.indexOf(pattern) === 0;
const endsWith = (pattern, str) => str.indexOf(pattern) === str.length - pattern.length;

const toCamel = str => str.toLowerCase().replace(/(_[a-z])/g, c => c.toUpperCase().replace('_',''));
const toUnderscored = str => str.replace(/([A-Z])/g, c => "_" + c.toLowerCase());

const leftPad = (str, len, ch = " ")  => {
    str = String(str);
    var i = -1;
    len = len - str.length;
    while (++i < len) {
        str = ch + str;
    }
    return str;
};

const similarityScore = (string, word, fuzziness) => {
  if (string === word) {
    return 1;
  }
  if (word === "") {
    return 0;
  }

  let runningScore = 0,
      charScore,
      finalScore,
      lString = string.toLowerCase(),
      strLength = string.length,
      lWord = word.toLowerCase(),
      wordLength = word.length,
      idxOf,
      startAt = 0,
      fuzzies = 1,
      fuzzyFactor,
      i;

  // Cache fuzzyFactor for speed increase
  if (fuzziness) { fuzzyFactor = 1 - fuzziness; }

  // Walk through word and add up scores.
  // Code duplication occurs to prevent checking fuzziness inside for loop
  if (fuzziness) {
    for (i = 0; i < wordLength; i+=1) {

      // Find next first case-insensitive match of a character.
      idxOf = lString.indexOf(lWord[i], startAt);

      if (idxOf === -1) {
        fuzzies += fuzzyFactor;
      } else {
        if (startAt === idxOf) {
          // Consecutive letter & start-of-string Bonus
          charScore = 0.7;
        } else {
          charScore = 0.1;

          // Acronym Bonus
          // Weighing Logic: Typing the first character of an acronym is as if you
          // preceded it with two perfect character matches.
          if (string[idxOf - 1] === ' ') { charScore += 0.8; }
        }

        // Same case bonus.
        if (string[idxOf] === word[i]) { charScore += 0.1; }

        // Update scores and startAt position for next round of indexOf
        runningScore += charScore;
        startAt = idxOf + 1;
      }
    }
  } else {
    for (i = 0; i < wordLength; i+=1) {
      idxOf = lString.indexOf(lWord[i], startAt);
      if (-1 === idxOf) { return 0; }

      if (startAt === idxOf) {
        charScore = 0.7;
      } else {
        charScore = 0.1;
        if (string[idxOf - 1] === ' ') { charScore += 0.8; }
      }
      if (string[idxOf] === word[i]) { charScore += 0.1; }
      runningScore += charScore;
      startAt = idxOf + 1;
    }
  }

  // Reduce penalty for longer strings.
  finalScore = 0.5 * (runningScore / strLength    + runningScore / wordLength) / fuzzies;

  if ((lWord[0] === lString[0]) && (finalScore < 0.85)) {
    finalScore += 0.15;
  }

  return finalScore;
};

const isMonetaryValue = str => {
    const regex = /^[A-Z\$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u20A0-\u20BD\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]{1,3}\s?\d+/;
    if (str.indexOf("-") === 0) {
        return !!str.substr(1).match(regex);
    } else {
        return !!str.match(regex);
    }
};

const toCommaSeparatedWithAnd = list => {
  let str = list.join(", ");

  const positionForAnd = str.lastIndexOf(",");

  if (positionForAnd > 0) {
    return str.substring(0, positionForAnd) + " &" + str.substring(positionForAnd + 1);
  } else {
    return str;
  }
};

export {
    capitaliseWords,
    startsWith,
    endsWith,
    toCamel,
    toUnderscored,
    leftPad,
    isMonetaryValue,
    similarityScore,
    toCommaSeparatedWithAnd
};
