import React from "react";

import pure from "js/common/views/pure";

const c = cs => cs.filter(c => !!c).join(" ");

/**
 * @deprecated use responsive flex, grid etc. CSS instead.
 */
const Row = pure(
    ({
      children,
      onClick,
      style = {},
      className = "",
      collapse = false
    }) => {
      const collapseClass = collapse ? "collapse" : "";
      const classes = [
        "row",
        className,
        collapseClass
      ];
      return (
          <div className={c(classes)} style={style} onClick={onClick}>
            {children}
          </div>);
    },
    "Row");

/**
 * @deprecated use responsive flex, grid etc. CSS instead.
 */
const Column = pure(
    ({
      children,
      small,
      smallCentered = false,
      medium,
      mediumCentered = false,
      large,
      largeCentered = false,
      style = {},
      className = "",
      end = false,
      onClick
    }) => {
      if (!small) {
        throw new Error("Foundation is mobile first, put some small sizes in here or else!");
      }

      const smallClass = small ? "small-" + small : "";
      const smallCenteredClass = smallCentered ? "small-centered" : "";

      const mediumClass = medium ? "medium-" + medium : "";
      const mediumCenteredClass = mediumCentered ? "medium-centered" : "";

      const largeClass = large ? "large-" + large : "";
      const largeCenteredClass = largeCentered ? "large-centered" : "";

      const textCenterClass = (largeCentered || mediumCentered || smallCentered) && "text-center";

      const classes = [
        smallClass,
        smallCenteredClass,
        mediumClass,
        mediumCenteredClass,
        largeClass,
        largeCenteredClass,
        "column",
        className,
        textCenterClass,
        (end && "end")
      ];
      return (
          <div className={c(classes)} style={style} onClick={onClick}>
            {children}
          </div>);
    },
    "Column");

/**
 * @deprecated use responsive flex, grid etc. CSS instead.
 */
const Layout = pure(
    ({
      children,
      allSmall,
      small = [],
      smallCentered = false,
      allMedium,
      medium = [],
      mediumCentered = false,
      allLarge,
      large = [],
      largeCentered = false,
      columnStyle = {},
      columnClass = "",
      onRowClick,
      collapseRow = false,
      rowStyle = {},
      rowClass = "",
      floatLastColumnLeft = false
    }) => {
      const cs = React.Children.toArray(children);
      return (
          <Row collapse={collapseRow} style={rowStyle} className={rowClass} onClick={onRowClick}>
            {cs.filter(c => c != null).map((child, index) => {
              const isLastColumn = index === (children.length - 1);
              const end = floatLastColumnLeft && isLastColumn;
              return (
                  <Column
                      key={index}
                      small={allSmall || small[index]}
                      smallCentered={smallCentered}
                      medium={allMedium || medium[index]}
                      mediumCentered={mediumCentered}
                      large={allLarge || large[index]}
                      largeCentered={largeCentered}
                      className={columnClass}
                      style={columnStyle}
                      end={end}>
                    {child}
                  </Column>
              );
            })}
          </Row>);
    },
    "Layout");

export {
  Layout,
  Row,
  Column
};

export default Layout;
