import moment from "moment";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as kpiCalculator from "js/common/kpi-calculator";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as percentTargetParser from "js/charting/trend/percent-target-parser";
import * as dataAggregator from "js/charting/trend/data-aggregator";
import {getKpiTrendName} from "js/charting/trend/display-name-util";

import currentClient from "js/common/repo/backbone/current-client";
import {getQualifierId} from "js/charting/trend/advanced-charting/data-group";

const _ = window._;

export const fullReloadForTrendChart = (config, catchErrors, includeErroringTrends) => {
  const isAdvancedTrendChart = config.trendType === "ADVANCED";
  return isAdvancedTrendChart ? fullReloadForAdvancedTrendChart(config, catchErrors, includeErroringTrends) : fullReloadForStandardTrendChart(config);
};

const fullReloadForStandardTrendChart = config => {
  const timeframe = timeframeRepo.parse(config.timeframe);
  const actualQualifierId = getQualifierId(config.qualifierType, config.qualifierId);

  const trendOptions = {
    timeframe,
    qualifierType: config.qualifierType,
    qualifierId: actualQualifierId,
    matchAnyTagIds: config.matchAnyTagIds,
    matchAllTagIds: config.matchAllTagIds,
    excludedTagIds: config.excludedTagIds,
    clientIds: config.clientIds
  };

  const howToPlot = config.displayDataCumulatively ? "cumulative" : "non-cumulative";
  const promiseForFinalData = Promise.all(config.kpiIds.map(kpiId => {
    const loadKpiTrend = config.averageByUser ? loadAverageTrendForKpi : loadTrendForKpi;
    const {excludeWeekends, dataAggregation} = config;
    const kpi = kpiRepo.get(kpiId);
    if (!kpi) {
      let missingMetricError = new Error("Metric not found");
      missingMetricError.type = "METRIC_NOT_FOUND";
      return Promise.reject(missingMetricError);
    }
    const {uiParams} = kpi.get("type");
    const trendAggregation = uiParams ? uiParams.trendAggregation : "SUM";
    return loadKpiTrend(kpiId, trendOptions).then(response => ({
      kpiId,
      trend: processKpiTrendData(
          response,
          timeframe,
          howToPlot,
          excludeWeekends,
          dataAggregation,
          trendAggregation
      )
    }));
  }));

  return {
    initialData: toData(true),
    promiseForFinalData: promiseForFinalData.then(trends => toData(false, trends))
  };
};

const flatMap = (arr, fn) => Array.prototype.concat.apply([], arr.map(fn));

const fullReloadForAdvancedTrendChart = (config, catchErrors, includeErroringTrends) => {
  const promises = flatMap(config.dataGroups, dataGroup => {
    const {
      qualifierType,
      qualifierId,
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds,
      clientIds
    } = dataGroup;

    const actualQualifierId = getQualifierId(qualifierType, qualifierId);

    const trendOptions = {
      timeframe: timeframeRepo.parse(dataGroup.timeframe),
      qualifierType,
      qualifierId: actualQualifierId,
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds,
      clientIds
    };

    return dataGroup.trendConfigs.map(trendConfig => {

      const dataGroupName = dataGroup.name;
      const {howToPlot, whatToPlot, type, kpiId, ratio} = trendConfig;
      const {excludeWeekends, dataAggregation} = config;
      const isRatioTrend = type === "RATIO";
      const name = !isRatioTrend ? getKpiTrendName(kpiId, whatToPlot, qualifierType, actualQualifierId) : ratio.name;
      const DEFAULT_TREND_OBJ = {
        trendConfig,
        rootTrendName: `${dataGroupName} - ${name}`
      };
      const DUMMY_TRENDS_ARR = [{
        date: moment(),
        dataAggregation: "DAILY",
        isPartialPeriod: false,
        offsetFromStart: 1,
        value: 0,
      }]

      if (isRatioTrend) {
        if(includeErroringTrends) {
          return getRatioTrendData(ratio, trendOptions, whatToPlot, howToPlot, excludeWeekends, dataAggregation)
              .then(data => ({
                ...DEFAULT_TREND_OBJ,
                trend: data
              }))
              .catch(err => {
                return ({
                  ...DEFAULT_TREND_OBJ,
                  trend: DUMMY_TRENDS_ARR,
                  error: err.responseJSON,
                })});
        }
        if(!catchErrors) {
          return getRatioTrendData(ratio, trendOptions, whatToPlot, howToPlot, excludeWeekends, dataAggregation)
              .then(data => ({
                ...DEFAULT_TREND_OBJ,
                trend: data
              }))
        } else {
          return getRatioTrendData(ratio, trendOptions, whatToPlot, howToPlot, excludeWeekends, dataAggregation)
              .then(data => ({
                ...DEFAULT_TREND_OBJ,
                trend: data
              })).catch(err => console.log(err));
        }
      }

      if(!includeErroringTrends) {
        return getKpiTrendData(kpiId, trendOptions, whatToPlot, howToPlot, excludeWeekends, dataAggregation)
            .then(data => {
              return ({
                ...DEFAULT_TREND_OBJ,
                trend: data
              })
            })
      } else {
        return getKpiTrendData(kpiId, trendOptions, whatToPlot, howToPlot, excludeWeekends, dataAggregation)
            .then(data => {
              return ({
                ...DEFAULT_TREND_OBJ,
                trend: data
              })
            })
            .catch(err => {
              return ({
                ...DEFAULT_TREND_OBJ,
                trend: DUMMY_TRENDS_ARR,
                error: err.responseJSON,
              })});
      }
    });
  });

  return {
    initialData: toData(true),
    promiseForFinalData: Promise.all(promises).then(trends => toData(false, trends))
  };
};

const getKpiTrendData = (kpiId, trendOptions, whatToPlot, howToPlot, excludeWeekends, dataAggregation) => {
  const kpi = kpiRepo.get(kpiId);
  const {uiParams} = kpi.get("type");
  const trendAggregation = uiParams ? uiParams.trendAggregation : "SUM";
  let loadTrendData;
  switch (whatToPlot) {
    case "value":
      loadTrendData = loadTrendForKpi;
      break;
    case "target":
      loadTrendData = loadTrendTargetsForKpi;
      break;
    case "percentage-target":
      loadTrendData = loadTargettedTrendsForKpi;
      break;
    case "average-by-user":
      loadTrendData = loadAverageTrendForKpi;
      break;
    default:
      throw new Error("Unsupported whatToPlot value: ", whatToPlot);
  }

  const processData = whatToPlot === "percentage-target" ? processTargettedKpiTrendData : processKpiTrendData;
  return loadTrendData(kpiId, trendOptions)
      .then(data => processData(
          data,
          trendOptions.timeframe,
          howToPlot,
          excludeWeekends,
          dataAggregation,
          trendAggregation));
};

const getRatioTrendData = (ratio, trendOptions, whatToPlot, howToPlot, excludeWeekends, dataAggregation) => {
  const ratioKpiIds = [ratio.firstKpiId, ratio.secondKpiId];
  let loadTrendData;
  switch (whatToPlot) {
    case "value":
      loadTrendData = loadTrendForRatio;
      break;
    case "average-by-user":
      loadTrendData = loadAverageTrendForRatio;
      break;
    default:
      throw new Error("Unsupported whatToPlot value: ", whatToPlot);
  }
  return loadTrendData(ratioKpiIds, trendOptions, howToPlot, excludeWeekends, dataAggregation);
};

const toData = (isLoading, trends = []) => ({isLoading, trends});

const loadTrendForKpi = (kpiId, options) => kpiCalculator
    .trend(kpiId, options)
    .then(parseTrendResponse);

const loadTrendForRatio = (
    kpiIds,
    options,
    howToPlotTrend,
    excludeWeekends,
    dataAggregation
) => Promise
    .all(kpiIds.map(kpiId => ({
      kpiId: kpiId,
      trend: loadTrendForKpi(kpiId, options)
    })))
    .then(([firstRatioKpi, secondRatioKpi]) => getRatioTrend(
        [firstRatioKpi, secondRatioKpi],
        options.timeframe,
        howToPlotTrend,
        excludeWeekends,
        dataAggregation
    ));

const loadAverageTrendForKpi = (kpiId, options) => kpiCalculator
    .averageTrend(kpiId, options)
    .then(parseTrendResponse);

const loadAverageTrendForRatio = (
    kpiIds,
    options,
    howToPlotTrend,
    excludeWeekends,
    dataAggregation
) => Promise
    .all(kpiIds.map(kpiId => ({
      kpiId: kpiId,
      trend: loadAverageTrendForKpi(kpiId, options)
    })))
    .then(([firstRatioKpi, secondRatioKpi]) => {
      return getRatioTrend(
          [firstRatioKpi, secondRatioKpi],
          options.timeframe,
          howToPlotTrend,
          excludeWeekends,
          dataAggregation
      );
    });

const getRatioTrend = (ratioKpis, timeframe, howToPlotTrend, excludeWeekends, dataAggregation) => {
  return ratioKpis[0].trend.then(firstKpiTrend => {
    return ratioKpis[1].trend.then(secondKpiTrend => {
      const firstKpi = kpiRepo.get(ratioKpis[0].kpiId);
      const firstKpiUiParams = firstKpi.get("type").uiParams;
      const firstKpiAggregationType = firstKpiUiParams ? firstKpiUiParams.trendAggregation : "SUM";
      const firstKpiTrendData = processRatioKpiTrendData(
          firstKpiTrend,
          timeframe,
          excludeWeekends,
          dataAggregation,
          firstKpiAggregationType
      );
      const secondKpi = kpiRepo.get(ratioKpis[1].kpiId);
      const secondKpiUiParams = secondKpi.get("type").uiParams;
      const secondKpiAggregationType = secondKpiUiParams ? secondKpiUiParams.trendAggregation : "SUM";
      const secondKpiTrendData = processRatioKpiTrendData(
          secondKpiTrend,
          timeframe,
          excludeWeekends,
          dataAggregation,
          secondKpiAggregationType
      );
      let cumulativeTotal = 0;
      return firstKpiTrendData
          .map((data, i) => {
            const firstKpiValue = data.value;
            const secondKpiValue = secondKpiTrendData[i] ? secondKpiTrendData[i].value : 0;
            const ratioValue = firstKpiValue === 0 || secondKpiValue === 0 ? 0 : firstKpiValue / secondKpiValue;
            let value = ratioValue;
            if (howToPlotTrend === "cumulative") {
              cumulativeTotal += value;
              value = cumulativeTotal;
            }
            return {
              ...data,
              value
            };
          })
          .filter(item => (excludeWeekends && dataAggregation === "DAILY") ?
              !(isWeekend(item.date) && item.value === 0) : true);
    });
  });
};
const processRatioKpiTrendData = (data, timeframe, excludeWeekends, dataAggregation, dataAggregationType = "SUM") => {
  const startDate = timeframe.get("start");
  const endDate = timeframe.get("end");
  const aggregateTrendData = dataAggregations[dataAggregation];
  const financialYearStartMonth = currentClient.get("financialYearStartMonth");
  return aggregateTrendData(data, startDate, endDate, dataAggregationType, financialYearStartMonth)
      .map(item => {
        const offsetFromStart = item.daysFromStart
            || item.weeksFromStart
            || item.monthsFromStart
            || item.quartersFromStart
            || item.yearsFromStart;
        return getTrendChartObject(
            item.date,
            offsetFromStart,
            dataAggregation,
            item.value.value,
            item.isPartialWeekStart);
      });
};

export const loadTrendTargetsForKpi = (kpiId, options) =>
    kpiCalculator.trendTargets(kpiId, options).then(parseTrendResponse);

const parseTrendResponse = response => {
  const parsedTrendData = response.map(convertItemDateToMoment);
  return sortDataByDaysFromStart(parsedTrendData);
};

const loadTargettedTrendsForKpi = (kpiId, options) =>
    kpiCalculator.targetedTrend(kpiId, options).then(response => parseTargettedTrendResponse(response));
const parseTargettedTrendResponse = response => {
  const parsedTrendData = parseTrendResponse(response.trendValueList);
  const parsedTargetTrendData = parseTrendResponse(response.trendTargetValueList);
  return {
    valueTrendData: sortDataByDaysFromStart(parsedTrendData),
    targetTrendData: sortDataByDaysFromStart(parsedTargetTrendData)
  };
};

const convertItemDateToMoment = item => ({
  ...item,
  date: moment(item.date, "YYYY-MM-DD")
});

const sortDataByDaysFromStart = data => _(data).sortBy("daysFromStart");

export const processKpiTrendData = (
    data,
    timeframe,
    howToPlot,
    excludeWeekends,
    dataAggregation,
    dataAggregationType = "SUM"
) => {
  const startDate = timeframe.get("start");
  const endDate = timeframe.get("end");
  const aggregateTrendData = dataAggregations[dataAggregation];
  let cumulativeTotal = 0;
  const client = currentClient;
  return aggregateTrendData(data, startDate, endDate, dataAggregationType, client.get("financialYearStartMonth"))
      .map(item => {
        let value = item.value.value;
        if (howToPlot === "cumulative") {
          cumulativeTotal += value;
          value = cumulativeTotal;
        }
        const offsetFromStart = item.daysFromStart
            || item.weeksFromStart
            || item.monthsFromStart
            || item.quartersFromStart
            || item.yearsFromStart;
        return getTrendChartObject(item.date, offsetFromStart, dataAggregation, value, item.isPartialWeekStart);
      })
      .filter(item => (excludeWeekends && dataAggregation === "DAILY") ? !(isWeekend(item.date) && item.value === 0) :
          true);
};

const processTargettedKpiTrendData = (
    data,
    timeframe,
    howToPlot,
    excludeWeekends,
    dataAggregation,
    dataAggregationType = "SUM"
) => {
  const startDate = timeframe.get("start");
  const endDate = timeframe.get("end");
  const aggregateTrendData = dataAggregations[dataAggregation];
  const financialYearStartMonth = currentClient.get("financialYearStartMonth");
  const valueTrend = aggregateTrendData(
      data.valueTrendData,
      startDate,
      endDate,
      dataAggregationType,
      financialYearStartMonth
  );
  const targetTrend = aggregateTrendData(
      data.targetTrendData,
      startDate,
      endDate,
      dataAggregationType,
      financialYearStartMonth
  );
  const parse = percentTargetParsers[howToPlot];
  const percentTargetTrend = parse(valueTrend, targetTrend);
  return percentTargetTrend.map(obj => {
    const offsetFromStart = obj.daysFromStart
        || obj.weeksFromStart
        || obj.monthsFromStart
        || obj.quartersFromStart
        || obj.yearsFromStart;
    return getTrendChartObject(obj.date, offsetFromStart, dataAggregation, obj.value.value, obj.isPartialWeekStart);
  });
};

const dataAggregations = {
  DAILY: dataAggregator.aggregateByDay,
  WEEKLY: dataAggregator.aggregateByWeek,
  MONTHLY: dataAggregator.aggregateByMonth,
  QUARTERLY: dataAggregator.aggregateByQuarter,
  FINANCIAL_QUARTERLY: dataAggregator.aggregateByFinancialQuarter,
  YEARLY: dataAggregator.aggregateByYear,
  FINANCIAL_YEARLY: dataAggregator.aggregateByFinancialYear
};

const percentTargetParsers = {
  DAILY_TARGET: percentTargetParser.toNonCumulative,
  TOTAL_TARGET: percentTargetParser.toCumulative,
  EXPECTED_TARGET: percentTargetParser.toAggregatedCumulative
};

const isWeekend = date => date.day() === 0 || date.day() === 6;

const getTrendChartObject = (date, offsetFromStart, dataAggregation, value, isPartialPeriod = false) => ({
  date,
  offsetFromStart,
  dataAggregation,
  value,
  isPartialPeriod
});
