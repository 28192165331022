// TODO: Importing PostRobot runs a bunch of initialisation code. Only import if app is in an iframe.
import PostRobot from "post-robot";
import currentClient from 'js/common/repo/backbone/current-client';
import * as Auditer from "js/common/auditer";

export const open = (entity, id) => {
  // TODO identify if app bridge is available
const runningInIframe = inIframe();
  if (runningInIframe) {
    PostRobot.sendToParent("open", {
      "type": "record",
      "entityType": entity,
      "entityId": id
    });
  } else {
    const host = currentClient.get("bullhornStaffingHost") || "www.bullhornstaffing.com";
    window.open(`https://${host}/BullhornStaffing/OpenWindow.cfm?Entity=${entity}&id=${id}`, "_blank");
  }
  Auditer.audit("deep-link:opened", {targetEntityName: entity, crmId: id, openedVia: runningInIframe ? "AppBridge" : "OpenWindow"});
};


function inIframe () {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const appBridgeOnlyEntities = [
  "BillableCharge",
  "InvoiceStatement"
];

export const getIsDeepLinkable = deepLinkingData => {
  const isBullhornCrm = currentClient.isBullhornCrm();
  const runningInIframe = inIframe();
  return deepLinkingData
      && deepLinkingData.crmEntityName
      && deepLinkingData.crmId
      && isBullhornCrm
      && (!appBridgeOnlyEntities.includes(deepLinkingData.crmEntityName) || runningInIframe);
}
