import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import Select from "js/common/views/inputs/immutable-react-select";
import getDefaultRssFeeds from "js/admin/cubetv/rss-feeds/default-rss-feeds";
import * as ajax from "js/common/ajax";

export default createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        selectedIds: ImmutablePropTypes.list.isRequired,
        onChange: ReactPropTypes.func.isRequired
    },

    getInitialState() {
        return {
            rssFeeds: Immutable.List()
        };
    },

    componentDidMount() {
        loadRssFeeds().then(customRssFeeds => {
            const defaultRssFeeds = getDefaultRssFeeds();
            const rssFeeds = defaultRssFeeds.concat(customRssFeeds);
            this.setState({ rssFeeds });
        });
    },

    render() {
        const { rssFeeds } = this.state;
        const { selectedIds, onChange } = this.props;
        const rssFeedsById = getRssFeedsById(rssFeeds);
        const options = getRssFeedOptions(rssFeeds);
        return (
            <Select
                placeholder="Choose RSS feeds to display on this channel"
                options={getRssFeedOptions(rssFeeds)}
                isMulti={true}
                selectedValues={selectedIds.filter(id => rssFeedsById.has(id))}
                onChange={onChange} />);
    }

});

const getRssFeedOptions = rssFeeds => rssFeeds.map(f => (Immutable.Map({
    label: f.get("title"),
    value: f.get("id")
})));

const getRssFeedsById = rssFeeds => {
    let rssFeedsById = Immutable.Map();
    rssFeeds.forEach(feed => {
        rssFeedsById = rssFeedsById.set(feed.get("id"), feed);
    });
    return rssFeedsById;
};

const loadRssFeeds = () => ajax.get({ url: "rss-feeds" }).then(response => Immutable.fromJS(response));
