import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import moment from "moment";
import PureRenderMixin from "react-addons-pure-render-mixin";

import { getUniqueName } from "js/common/utils/unique-naming";
import { TextButton } from "js/common/views/inputs/buttons";
import { Layout, Column } from "js/common/views/foundation-column-layout";
import Tabs from "js/common/views/tabs";
import DataGroup from "js/charting/trend/advanced-charting/data-group";
import DataGroupNameInput from "js/charting/trend/advanced-charting/data-group-name-input";
import { getDefaultColour } from "js/charting/trend/target-trend-color-util";

import PlotChartButton from "js/charting/trend/plot-chart-button";
import NoKpisSelectedWarning from "js/charting/trend/no-kpis-selected-warning";
import ChartNameInput from "js/charting/trend/chart-name-input";
import Tooltip from "js/common/views/tooltips";
import RadioButton from "js/common/views/inputs/radio-button";

import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as auditor from "js/common/auditer";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Users from "js/common/users";

import { RadioGroup as RadioButtonGroup } from '@mui/material';
import Checkbox from "js/common/views/inputs/checkbox";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const AdvancedConfigContainer = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        initialConfig: ImmutablePropTypes.map.isRequired,
        onPlotChartClick: ReactPropTypes.func.isRequired,
        onStandardChartClick: ReactPropTypes.func.isRequired,
        isSmallScreen: ReactPropTypes.bool.isRequired,
        disableControls: ReactPropTypes.bool
    },

    getInitialState() {
        return {
            config: this.props.initialConfig,
            isNoKpisSelectedWarningOpen: false,
            showTabIndex: 0
        };
    },

    render() {
        const { config, isNoKpisSelectedWarningOpen } = this.state;
        const dataGroups = config.get("dataGroups");
        const everyDataGroupHasKpis = dataGroups.reduce((total, dg) => total + dg.get("trendConfigs").count(), 0) > 0;
        const onClickHandler = everyDataGroupHasKpis ? this.handlePlotChartClick : this.openNoKpisSelectedWarning;
        return (
            <div>
                <ChartNameInput name={ config.get("name") } onChange={ name => this.onConfigChange("name", name) } />
                <div style={{ width:"100%", paddingTop:"1em" }} className="clearfix">
                    <Column small={12} medium={6}>
                        <Checkbox
                            label="Exclude Weekends"
                            checked={ config.get("excludeWeekends") }
                            onCheck={ (e, excludeWeekends) => this.onConfigChange("excludeWeekends", excludeWeekends) }
                            style={{width: 177, height: 25}} />
                        { this.renderDateDisplayRadioGroup() }
                    </Column>
                    <Column small={12} medium={6} className="text-right">
                        <TextButton
                            type="dark"
                            label="Standard Trend Chart"
                            style={{ marginRight: "1em" }}
                            onClick={() => this.props.onStandardChartClick(config)} />
                        <PlotChartButton isDisabled={!everyDataGroupHasKpis} onClick={onClickHandler} />
                    </Column>
                </div>

                <Layout allSmall={12} rowStyle={{ maxWidth: "100%", ...spacer }}>
                    {this.renderDataGroupTabs()}
                </Layout>

                {isNoKpisSelectedWarningOpen &&
                    <NoKpisSelectedWarning onRequestClose={this.closeNoKpisSelectedWarning} />}
            </div>);
    },

    renderDataGroupTabs() {
        const { disableControls, isSmallScreen, theme } = this.props;
        const { config, showTabIndex } = this.state;
        const dataGroups = config.get("dataGroups");
        const tabs = dataGroups.map(dataGroup => {
            const dataGroupId = dataGroup.get("cid");
            const tooltipId = `clone-data-group-${dataGroupId}`;
            return ({
                title:
                    <div style={{ display: "table-row" }}>
                        <div style={{ display: "table-cell" }}>
                            <DataGroupNameInput
                                name={ dataGroup.get("name") }
                                onChange={name => this.handleDataGroupChange(dataGroup.set("name", name))} />
                        </div>
                        <div style={{ display: "table-cell" }}>
                            <div style={{ display: "inline-block" }}>
                                { this.renderDeleteOrResetButton(dataGroupId) }
                            </div>
                            <div style={{ display: "inline-block" }}>
                                <Tooltip
                                    key={tooltipId}
                                    text="Clone this data group"
                                    styleType="light"
                                    position="right">
                                    <i className="fa fa-clone"
                                        style={iconButtonStyle(theme)}
                                        onClick={evt => this.onCloneDataGroupClick(evt, dataGroup)} />
                                </Tooltip>
                            </div>
                        </div>
                    </div>,
                content:
                    <DataGroup
                        key={dataGroup.get("cid")}
                        chartTheme={ config.get("chartTheme") }
                        isDisabled={disableControls}
                        isSmallScreen={isSmallScreen}
                        dataGroup={dataGroup}
                        onDataGroupChange={this.handleDataGroupChange} />
            });
        });
        return (
            <Tabs
                tabs={tabs.toArray()}
                onChangeTab={index => this.setState({ showTabIndex: index })}
                selectedIndex={showTabIndex}
                allowAddTab={true}
                addTabLabel="Add a Data Group"
                onAddTabClick={this.addDataGroup}
                saveTabStateOnChange={false}
            />);
    },

    renderDeleteOrResetButton(dataGroupId) {
        const dataGroups = this.state.config.get("dataGroups");
        const { theme } = this.props;
        const hasOnlyOneDataGroup = dataGroups.count() === 1;
        const buttonKey = hasOnlyOneDataGroup ? "reset" : "delete";
        const tooltipId = `${buttonKey}-data-group-${dataGroupId}`;
        const tooltipLabel = hasOnlyOneDataGroup ? "Discard all changes" : "Delete this data group";
        const onClick = hasOnlyOneDataGroup ? this.onResetDataGroupClick : this.onDeleteDataGroupClick;

        return (
            <Tooltip key={tooltipId} text={tooltipLabel} styleType="light" position="right">
                <span>
                    <i key={buttonKey}
                        className={`fa fa-${hasOnlyOneDataGroup ? "undo" : "times-circle"}`}
                        style={iconButtonStyle(theme)}
                        onClick={evt => onClick(evt, dataGroupId)} />
                </span>
            </Tooltip>);
    },

    renderDateDisplayRadioGroup() {
        return (
            <RadioButtonGroup
                name="display-target-as-priority"
                onChange={e => this.onConfigChange("dateDisplay", e.target.value)}
                value={this.state.config.get("dateDisplay") || "ABSOLUTE"}>
                <RadioButton
                    value="ABSOLUTE"
                    label={`Normal Dates (e.g. ${moment().format("ll").replace(",", "")})`}
                    style={{marginRight: 10, width: 440, height: 25, marginTop: 7, marginBottom: 2}} />
                <RadioButton
                    value="RELATIVE"
                    label="Period on Period (i.e. number of days from start date)"
                    style={{marginRight: 10, width: 440, height: 25, marginTop: 2, marginBottom: 2}} />
            </RadioButtonGroup>);
    },

    onConfigChange(key, newValue) {
        this.setState({
            config: this.state.config.set(key, newValue)
        });
    },

    handlePlotChartClick() {
        const config = this.state.config;
        auditor.audit("charting:plot-advanced", config.toJS());
        this.props.onPlotChartClick(config);
    },

    openNoKpisSelectedWarning() {
        this.setState({ isNoKpisSelectedWarningOpen: true });
    },

    closeNoKpisSelectedWarning() {
        this.setState({ isNoKpisSelectedWarningOpen: false });
    },

    handleDataGroupChange(newDataGroup) {
        let { config } = this.state;
        const dataGroups = config.get("dataGroups");
        const index = dataGroups.findIndex(dg => dg.get("cid") === newDataGroup.get("cid"));
        const kpiIds = newDataGroup.get("trendConfigs").flatMap(tc => {
            if (tc.get("type") === "KPI") {
                return Immutable.List([tc.get("kpiId")]);
            } else {
                const ratio = tc.get("ratio");
                return Immutable.List([ratio.get("firstKpiId"), ratio.get("secondKpiId")]);
            }
        });
        const allKpisAggregable = kpiIds.every(kpiId => kpiRepo.get(kpiId).get("trendDataAggregable"));
        if (!allKpisAggregable) {
            config = config.set("dataAggregation", "DAILY");
        }
        this.setState({
            config: config.setIn([ "dataGroups", index ], newDataGroup)
        });
    },

    onCloneDataGroupClick(evt, dataGroup) {
        evt.stopPropagation();

        const { config } = this.state;
        const dataGroups = config.get("dataGroups");
        const existingDataGroupNames = dataGroups.map(dg => dg.get("name"));
        const originalDataGroupName = dataGroup.get("name");
        const clonedDataGroupName = getUniqueName(originalDataGroupName, existingDataGroupNames);
        const newDataGroupIndex = dataGroups.count();

        const clonedTrendConfigs = dataGroup.get("trendConfigs")
            .map(tc => tc.set("cid", Math.random()))
            .map((trendConfig, i) => trendConfig.set("seriesColour", getDefaultColour(i, newDataGroupIndex)));

        const dataGroupClone = dataGroup
            .set("cid", Math.random())
            .set("name", clonedDataGroupName)
            .set("dataGroupIndex", newDataGroupIndex)
            .set("trendConfigs", clonedTrendConfigs);

        this.setState({
            config: config.set("dataGroups", dataGroups.push(dataGroupClone))
        });
    },

    onDeleteDataGroupClick(evt, dataGroupId) {
        evt.stopPropagation();

        const { config } = this.state;
        const dataGroups = config.get("dataGroups");
        const index = dataGroups.findIndex(dg => dg.get("cid") === dataGroupId);
        const newConfig = config.set("dataGroups", dataGroups.delete(index));
        this.setState({
            config: newConfig,
            showTabIndex: index > 0 ? index - 1 : 0
        });
    },

    onResetDataGroupClick(evt, dataGroupId) {
        evt.stopPropagation();

        const initialStateDataGroups = this.getInitialState().config.get("dataGroups");
        const index = initialStateDataGroups.findIndex(dg => dg.get("cid") === dataGroupId);
        this.setState({
            config: this.state.config.setIn([ "dataGroups", index ], initialStateDataGroups.get(index))
        });
    },

    addDataGroup() {
        const { config } = this.state;
        const dataGroups = config.get("dataGroups");
        const newDataGroupIndex = dataGroups.count();
        const newDataGroup = createBlankDataGroup(this.getNewDefaultDataGroupName(), newDataGroupIndex);
        this.setState({
            config: config.set("dataGroups", dataGroups.push(newDataGroup))
        });
    },

    getNewDefaultDataGroupName() {
        const dataGroupNames = this.state.config.get("dataGroups").map(dg => dg.get("name"));
        const numbersInUse = dataGroupNames.map(name => {
            return name.match(/Data Group /) ? parseInt(name.match(/\d+/), 10) : 0;
        });
        const nextNumber = numbersInUse.sort().last() + 1;
        return `Data Group ${nextNumber}`;
    }

});

const spacer = {
    marginTop: "0.5em",
    marginBottom: "0.5em"
};

const iconButtonStyle = theme => ({
    cursor: "pointer",
    color: theme.palette.text.secondary,
    padding: "0 10px",
    height: "34px",
    lineHeight: "34px",
    verticalAlign: "bottom",
    ":hover": {
        color: theme.palette.text.primary
    }
});

const createBlankDataGroup = (dataGroupName, dataGroupIndex) => Immutable.fromJS({
    cid: Math.random(),
    dataGroupIndex,
    name: dataGroupName,
    timeframe: timeframeRepo.get("LAST_30_DAYS").getRawJson(),
    trendConfigs: [],
    qualifierType: "GROUP",
    qualifierId: Users.getCurrentUser().get("groupId"),
    matchAnyTagIds: [],
    matchAllTagIds: [],
    excludedTagIds: [],
    clientIds: [],
    dateDisplay: "ABSOLUTE"
});


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <AdvancedConfigContainer theme={theme} {...props} />;
};

export default Wrapper;
