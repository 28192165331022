/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import onClickOutside from "react-onclickoutside";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/pro-regular-svg-icons";
import {Chip} from "@mui/material";

import InfiniteList from "js/common/views/infinite-loading/infinite-list";
import * as Styles from "js/common/views/infinite-loading/infinite-list-select.styles";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const InfiniteListSelect = createReactClass({
  propTypes: {
    searchUrl: ReactPropTypes.string,
    searchParams: ReactPropTypes.object,
    searchResultToItem: ReactPropTypes.func,
    placeholder: ReactPropTypes.node,
    itemHeight: ReactPropTypes.number,
    itemIcon: ReactPropTypes.object,
    startItems: ImmutablePropTypes.list,
    startItemStyle: ReactPropTypes.object,
    startItemIcon: ReactPropTypes.object,
    selectedItems: ImmutablePropTypes.set,
    menuFooterCreatorFn: ReactPropTypes.oneOfType([ReactPropTypes.func, ReactPropTypes.node]),
    onChange: ReactPropTypes.func.isRequired,
    onRequestClose: ReactPropTypes.func,
    disabled: ReactPropTypes.bool,
    keys: ReactPropTypes.array,
    onInputChange: ReactPropTypes.func,
    closeOnSelect: ReactPropTypes.bool,
    style: ReactPropTypes.object,
    innerStyle: ReactPropTypes.object,
    maxHeight: ReactPropTypes.number
  },

  getInitialState() {
    return {
      searchInput: "",
      isOpen: false
    };
  },

  getDefaultProps() {
    return {
      selectedItems: Immutable.Set(),
      keys: ["id", "name", "name"],
      disabled: false,
      itemHeight: 32,
      onInputChange: () => {},
      onRequestClose: () => {},
      menuFooterCreatorFn: () => null,
      closeOnSelect: false,
      maxHeight: 200
    };
  },

  render() {
    const {theme} = this.context;
    const {placeholder, selectedItems, disabled, style, innerStyle} = this.props;
    const {isOpen, searchInput} = this.state;
    const bgColor = this.props.bgColor ? this.props.bgColor : "card";
    let placeholderToShow;
    if (searchInput === "") {
      if (this.props.showSelectedInDropdown) {
        placeholderToShow = placeholder;
      } else {
        if (selectedItems.isEmpty()) {
          placeholderToShow = placeholder;
        } else {
          placeholderToShow = "";
        }
      }
    } else {
      placeholderToShow = "";
    }
    // TODO: Replace onClick with onFocus to fix list not expanding with keyboard focusing
    return (
        <div style={{...{position: "relative"}, ...style}}>
          <div
              css={Styles.selectWrapperStyles(theme, bgColor, disabled)}
              style={innerStyle}
              onClick={isOpen ? () => {} : this.openMenu}>
            <div style={{flex: 1}}>
              {!this.props.showSelectedInDropdown && selectedItems.map(item => this.renderSelectedItem(item, theme.palette))}
              <input
                  ref={ref => this.input = ref}
                  type="text"
                  value={searchInput}
                  css={Styles.selectInputStyle(theme, searchInput, isOpen)}
                  onChange={this.handleInputChange}
                  data-test-id = "infinite-list-search"
                  placeholder={"Type here to search"}
              />
              {!isOpen && placeholderToShow}
            </div>
            <div css={Styles.iconZoneStyle}>
              {!this.props.showSelectedInDropdown && selectedItems.count() !== 0 &&
                  <span onClick={this.handleRemoveAllItems}>
                &times;
              </span>}
              <FontAwesomeIcon
                  icon={isOpen ? faChevronUp : faChevronDown}
                  onClick={isOpen ? this.closeMenu : this.openMenu} />
            </div>
          </div>
          <VelocityTransitionGroup
              component="div"
              enter="slideDown"
              css={isOpen
                  ? Styles.dropdownOpenStyle(theme, this.props.showSelectedInDropdown)
                  : Styles.dropdownClosedStyle(theme)}>
            {isOpen && this.renderMenu()}
          </VelocityTransitionGroup>
        </div>
    );
  },

  renderSelectedItem(item, palette) {
    const [valueKey, labelKey] = this.props.keys;
    const label = item.get(labelKey);
    const value = item.get(valueKey);const handleItemRemove = () => this.handleItemRemove(item);
    const tagItemCss = Styles.tagItemCss(palette);

    return (
        <Chip
            onDelete={handleItemRemove}
            label={label}
            className="TESTCAFE-client-select-value"
            data-tip
            data-for={value}
            key={value}
            css={tagItemCss}
        />
    );
  },

  renderMenu() {
    const {theme} = this.context;
    const {
      searchUrl,
      searchParams,
      searchResultToItem,
      selectedItems,
      itemHeight,
      itemIcon,
      startItems,
      startItemStyle,
      startItemIcon,
      menuFooterCreatorFn,
      keys: [valueKey, labelKey, searchKey],
      closeOnSelect,
      maxHeight
    } = this.props;
    const MenuFooter = menuFooterCreatorFn;
    const searchInput = this.state.searchInput;
    // Todo: Filter selected items
    const filteredStartItems = startItems &&
        startItems
            .filter(item => item.get(searchKey).toLowerCase().includes(searchInput))
    ;
    return (
        <div style={{marginTop: "0.5rem"}} onClick={this.focusInput}>
          {this.props.showSelectedInDropdown && <>
            <div css={Styles.dropdownHeadingCss(theme)}>Selected <span
                style={{
                  float: "right",
                  color: theme.palette.primary.main,
                  cursor: "pointer"
                }} onClick={this.handleRemoveAllItems}>Remove all</span>
            </div>
            {this.props.showSelectedInDropdown &&
                selectedItems.map(item => this.renderSelectedItem(item, theme.palette))}
            <div css={Styles.dropdownHeadingCss(theme)}>Available</div>
          </>}
          <InfiniteList
              searchPrefix={searchInput}
              searchUrl={searchUrl}
              searchParams={searchParams}
              searchResultToItem={searchResultToItem}
              itemIcon={itemIcon}
              itemHeight={itemHeight}
              startItems={filteredStartItems}
              startItemStyle={startItemStyle}
              startItemIcon={startItemIcon}
              selectedItemIds={selectedItems.map(item => item.get(valueKey))}
              onItemClick={this.handleItemClick}
              keys={this.props.keys}
              maxHeight={maxHeight} />
          <MenuFooter closeMenu={this.closeMenu} />
        </div>
    );
  },

  handleClickOutside() {
    this.closeMenu();
  },

  focusInput() {
    this.input.focus();
  },

  openMenu() {
    if (!this.props.disabled) {
      this.props.onOpenStateChange && this.props.onOpenStateChange(true);
      this.setState({isOpen: true});
      this.focusInput();
    }
  },

  closeMenu() {
    if(this.state.isOpen) {
      this.props.onOpenStateChange && this.props.onOpenStateChange(false);
      this.setState(this.getInitialState());
      this.props.onRequestClose();
    }
  },

  handleInputChange(e) {
    const input = e.target.value;
    this.setState({searchInput: input.toLowerCase()});
    this.props.onInputChange(input);
  },

  handleItemRemove(item) {
    const {
      selectedItems,
      onChange
    } = this.props;
    onChange(selectedItems.delete(item));
  },

  handleRemoveAllItems() {
    this.props.onChange(Immutable.Set());
  },

  handleItemClick(item) {
    const {
      selectedItems,
      onChange,
      keys: [valueKey, labelKey],
      closeOnSelect
    } = this.props;

    const newItem = item
        .delete("id")
        .delete("name")
        .update(valueKey, () => item.get("id"))
        .update(labelKey, () => item.get("name"));

    const newItems = selectedItems.add(newItem);
    onChange(newItems);

    if (closeOnSelect) {
      this.closeMenu();
    }
  }
});
InfiniteListSelect.contextType = CustomThemeContext;

export default onClickOutside(InfiniteListSelect);
