import React from "react";
import Immutable from "immutable";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

import currentClient from "js/common/repo/backbone/current-client";
import {betterMemo} from "js/common/utils/more-memo";
import {indexBy} from "js/common/utils/collections";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";

import {getDefaultTestConfig, useCalculatedImmutableState} from "js/admin/kpis/order-kpis/utils";
import {getNameForValidation, hasTestError, isValidKpi} from "js/admin/kpis/edit-kpis/validation";
import * as Popups from "js/common/popups";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Kpis from "js/common/kpis";
import * as Users from "js/common/users";
import * as Ajax from "js/common/ajax";

import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import LoadingSpinner from "js/common/views/loading-spinner";
import OrderKpiDraggable from "js/admin/kpis/order-kpis/order-kpi-draggable";
import {TextButton} from "js/common/views/inputs/buttons";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const setOrderOnWrappedKpis = (idToWrappedKpis, orderedKpiIds) => {
  return idToWrappedKpis.withMutations(idToWrappedKpis => {
    orderedKpiIds.forEach((kpiId, index) => {
      const order = idToWrappedKpis.getIn([kpiId, "kpi", "order"]);
      const changed = order !== index;
      idToWrappedKpis
          .setIn([kpiId, "kpi", "order"], index)
          .setIn([kpiId, "positionIndex"], index)
          .updateIn([kpiId, "changed"], oldValue => oldValue || changed);
    });
  });
};

const Rows = betterMemo({debug: false, displayName: "Rows"}, (
    {
      isCube19User,
      idToWrappedKpi,
      orderedKpiIds,
      onOrderChange,
      filterText,
      showOnlyFailingKpis,
      showOnlyChangedKpis
    }) => {

  const {theme} = React.useContext(CustomThemeContext);

  const handleDragEnd = React.useCallback(
      result => {
        if (result.destination) {
          const startIndex = result.source.index;
          const newIndex = result.destination.index;
          const changed = orderedKpiIds.get(startIndex);
          const reorderedKpiIds = orderedKpiIds.delete(startIndex).insert(newIndex, changed);

          onOrderChange(reorderedKpiIds);
        }
      }, [orderedKpiIds, onOrderChange]);

  const headingLabelStyle = {flex: "1.9 1 0%", fontSize: "11px", paddingLeft: 0, color: "#999"};

  const filteredKpiIds = React.useMemo(
      () => {
        const filterWords = Immutable.List(filterText.toLowerCase().split(" "));
        return orderedKpiIds.filter(kpiId => {
          const wrappedKpi = idToWrappedKpi.get(kpiId);

          let matchesFilter;
          if (filterText) {
            const id = isCube19User ? " " + wrappedKpi.getIn(["kpi", "id"]).toString() : "";
            const matchTerms = (wrappedKpi.getIn(["kpi", "id"]).toString() + " " + wrappedKpi.getIn(["kpi", "name"]) +
                " " +
                wrappedKpi.getIn(["kpi", "trueName"]) + id).toLowerCase();
            matchesFilter = filterWords.every(word => matchTerms.indexOf(word) !== -1);
          } else {
            matchesFilter = true;
          }

          const matchesFailing = showOnlyFailingKpis ? !!hasTestError(wrappedKpi.get("testResult")) : true;
          const matchesChanged = showOnlyChangedKpis
              ? (!!wrappedKpi.get("changed") || !!wrappedKpi.get("columnsChanged"))
              : true;

          return matchesFilter && matchesFailing && matchesChanged;
        });
      },
      [showOnlyFailingKpis, showOnlyChangedKpis, filterText, orderedKpiIds, idToWrappedKpi, isCube19User]);

  return (
      <div data-test-id={"order-metrics-kpi-list"}>
        <div style={{display: "flex", borderBottom: `2px solid ${theme.palette.primary.main}`, paddingBottom: 7}}>
          <span style={{...headingLabelStyle, marginLeft: 6, flex: "0.3 1 1.8%", flexShrink: 0}} />
          <span style={{...headingLabelStyle, marginLeft: 6}}>Display Name</span>
          <span style={{flex: "1 1 0%"}} />
          <span style={{flex: "0.25 1 0%"}} />
          <span style={{flex: "0.25 1 0%"}} />
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="metric-list" type="ROW" direction="vertical">
            {(droppableProvided, droppableSnapshot) => (
                <div
                    ref={droppableProvided.innerRef}
                    style={{
                      backgroundColor: droppableSnapshot.isDraggingOver ? (theme.themeId === "light"
                          ? "#d3d3d3"
                          : "#2c3e50") : "transparent",
                      position: "relative"
                    }}
                    {...droppableProvided.droppableProps}>
                  {filteredKpiIds
                      .filter(kpiId => idToWrappedKpi.has(kpiId))
                      .map(kpiId => {
                        const wrappedKpi = idToWrappedKpi.get(kpiId);
                        const kpi = wrappedKpi.get("kpi");
                        return <OrderKpiDraggable
                            theme={theme}
                            key={kpi.get("id")}
                            hasChanged={wrappedKpi.get("changed") || wrappedKpi.get("columnsChanged")}
                            kpi={kpi}
                            index={wrappedKpi.get("positionIndex")}
                        />;
                      })}
                  {droppableProvided.placeholder}
                </div>)}
          </Droppable>
        </DragDropContext>
      </div>);
});

const disableDragX = {dragX: false};

const infoText = `Drag and drop Metrics into the order you want them to appear in "Priority Metrics"
                        and "Slice & Dice". It will not affect the order they are displayed within sections.`;

const sortWrappedKpis = idToWrappedKpi => {
  const wrappedKpis = idToWrappedKpi.toList();
  const visible = wrappedKpis
      .filter(wk => wk.getIn(["kpi", "visible"]))
      .sortBy(wk => wk.getIn(["kpi", "order"]))
      .map(wk => wk.getIn(["kpi", "id"]));
  const hidden = wrappedKpis
      .filter(wk => !wk.getIn(["kpi", "visible"]))
      .sortBy(wk => wk.getIn(["kpi", "name"]))
      .map(wk => wk.getIn(["kpi", "id"]));
  return visible.concat(hidden);
};

const OrderKpisApp = betterMemo({displayName: "MetricAdminPage"}, () => {
  const storeKeyRoot = React.useMemo(() => {
    const currentClientId = currentClient.get("id");
    const currentUserId = Users.getCurrentUser().get("id");
    return "cube19.admin.metrics." + currentClientId + "." + currentUserId;
  }, []);

  const [filterText, setFilterText] = React.useState("");

  const [idToWrappedKpi, setIdToWrappedKpi] = React.useState(Immutable.Map());
  const [idToTemplate, setIdToTemplates] = React.useState(Immutable.Map());
  const [idToEntityColumn, setIdToEntityColumn] = React.useState(Immutable.Map());
  const [loading, setLoading] = React.useState(true);

  const unmounted = React.useRef(false);
  const {theme} = React.useContext(CustomThemeContext);
  React.useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const [isEditor, isCube19User, isSwitchingUser] = React.useMemo(() => {
    const currentUser = Users.getCurrentUser();
    return [
      Users.currentHasPermission("METRIC_SUBMISSION"),
      Users.isCube19User(currentUser),
      !!currentUser.get("adminConsoleUsername")];
  }, []);

  const orderedKpiIds = useCalculatedImmutableState(() => {
    return sortWrappedKpis(idToWrappedKpi);
  }, [idToWrappedKpi]);

  const loadAndSetData = React.useCallback(() => {
    setLoading(true);
    return Promise
        .all([
          Kpis.loadEditableKpis(),
          Kpis.loadTemplates(),
          (isEditor && isCube19User) ? loadEntities() : Promise.resolve(Immutable.List()),
          loadEntityColumns(),
          Kpis.loadActionTypes()])
        .then(([kpis, templates, entities, entityColumns, actionTypes]) => {

          const defaultTestConfig = getDefaultTestConfig();
          const wrappedKpis = kpis
              .filter(kpi => !kpi.get("deleted"))
              .map(kpi => Immutable.Map({
            kpi,
            testConfig: defaultTestConfig,
            combinedKpi: kpi.get("readOnlyCombined") ?? kpi
          }));
          let idToWrappedKpi = indexBy(wk => wk.getIn(["kpi", "id"]), wrappedKpis);
          const sortedKpiIds = sortWrappedKpis(idToWrappedKpi);
          idToWrappedKpi = idToWrappedKpi.withMutations(idToWrappedKpi => {
            sortedKpiIds.forEach((id, index) => {
              idToWrappedKpi.setIn([id, "positionIndex"], index);
            });
          });
          setIdToWrappedKpi(idToWrappedKpi);
          setIdToTemplates(indexBy(t => t.get("id"), templates));
          setIdToEntityColumn(indexBy(e => e.get("id"), entityColumns));
          setLoading(false);
        });
  }, [isCube19User, isEditor]);

  React.useEffect(() => {
    loadAndSetData();
  }, [loadAndSetData]);


  const hasChanges = React.useMemo(
      () => !idToWrappedKpi
          .toList()
          .filter(wk => wk.get("changed") || wk.get("columnsChanged"))
          .isEmpty(),
      [idToWrappedKpi]);

  const nameToCount = React.useMemo(
      () => idToWrappedKpi
          .toList()
          .groupBy(wk => getNameForValidation(wk.get("kpi")))
          .map(wks => wks.map(wk => wk.getIn(["kpi", "id"])).count()),
      [idToWrappedKpi]);

  const hasValidationErrors = React.useMemo(
      () => {
        const invalidKpis = idToWrappedKpi
            .toList()
            .filter(wk => !isValidKpi(wk.get("kpi"), nameToCount));
        return invalidKpis.count() > 0;
      },
      [nameToCount, idToWrappedKpi]);


  const handleSaveClick = React.useCallback(() => {
    let testPromise = Promise.resolve(Immutable.List());
    testPromise
        .then(testResults => {
          const processedTestResults = testResults.map(tr => tr.get(0)
              .setIn(["group", "response", "concurrency"], tr.get(1)));
          const kpiIdToTestTimings = indexBy(tr => tr.get("kpiId"), processedTestResults)
              .map(tr => tr
                  .delete("kpiId")
                  .mapKeys(userOrGroup => userOrGroup.toUpperCase())
                  .filter(userOrGroup => userOrGroup)
                  .map(userOrGroup => userOrGroup
                      .get("response")
                      .filter(test => test)
                      .mapKeys(test => test.toUpperCase())
                      .map(test => Immutable.Map({
                        timing: test.get("time"),
                        valueCount: test.getIn(["body", "values"]) ? test.getIn(["body", "values"]).size : null
                      }))));

          const failures = processedTestResults.filter(hasTestError);
          if (failures.isEmpty()) {
            const reason = (isSwitchingUser || isCube19User) ?
                prompt("Please provide a reason for changing Metrics.  Leave blank to use login reason.") : "";
            if (reason === null) {
              return Promise.reject(new Error("Save cancelled"));
            }
            const wrappedKpisToUpsert = idToWrappedKpi.toList().filter(wk => wk.get("changed") ||
                wk.get("columnsChanged") ||
                wk.get("requiresTest"));
            const formattedWrappedKpisToUpsert = wrappedKpisToUpsert
                .map(wk => {
                  const testTimings = kpiIdToTestTimings.get(wk.getIn(["kpi", "id"]));
                  const kpi = wk.get("isUnsaved") ? wk.get("kpi").delete("id") : wk.get("kpi");
                  return Immutable.fromJS({config: kpi, columns: wk.get("columns"), testTimings});
                });
            return Promise.all([wrappedKpisToUpsert, Kpis.submitKpis(formattedWrappedKpisToUpsert, reason)]);
          } else {
            setIdToWrappedKpi(idToWrappedKpi => mergeIdToWrappedKpiWithResults(idToWrappedKpi, processedTestResults));
            return Promise.reject(new Error("No changes saved, test failures must be fixed first"));
          }
        })
        .then(([changedWrappedKpis, response]) => {
          const kpisUpdated = response.get("kpisUpdated");
          const kpisAndErrors = response.get("kpisAndErrors");
          const hasError = !!(kpisAndErrors.find(kpiAndError => kpiAndError.get("error")));
          if (hasError) {
            const wrappedKpisWithErrors = changedWrappedKpis
                .zipWith(
                    (wrappedKpi, kpiAndError) => {
                      const error = kpiAndError.get("error");
                      if (error) {
                        return wrappedKpi.set("submitError", error);
                      } else {
                        return wrappedKpi;
                      }
                    },
                    kpisAndErrors)
                .filter(wrappedKpi => wrappedKpi.get("submitError"));
            setIdToWrappedKpi(idToWrappedKpi.merge(indexBy(wk => wk.getIn(["kpi", "id"]), wrappedKpisWithErrors)));
            return Promise.reject(new Error("Unable to save, see errors"));
          } else {
            const savedWrappedKpis = changedWrappedKpis
                .zipWith(
                    (wrappedKpi, kpiAndError) => {
                      const kpi = kpiAndError.get("config");
                      return wrappedKpi
                          .set("kpi", kpi)
                          .set("combinedKpi", kpi.get("readOnlyCombined") ?? kpi)
                          .delete("isUnsaved")
                          .delete("changed")
                          .delete("testResult")
                          .delete("rootChanged")
                          .delete("requiresTest")
                          .delete("sendForTests")
                          .delete("columns")
                          .delete("columnsChanged")
                          .delete("requiresExplanationUpdate");
                    },
                    kpisAndErrors);
            const idToSavedWrappedKpis = indexBy(wk => wk.getIn(["kpi", "id"]), savedWrappedKpis);
            const newIdToWrappedKpi = idToWrappedKpi
                .filter(wrappedKpi => !wrappedKpi.get("isUnsaved"))
                .map(wrappedKpi => wrappedKpi
                    .delete("testResult")
                    .delete("requiresTest"))
                .merge(idToSavedWrappedKpis);

            setIdToWrappedKpi(newIdToWrappedKpi);

            if (kpisUpdated) {
              KpiRepo.reload();
              Popups.success("Changes have been saved");
            } else {
              Popups.success("Changes have been submitted pending approval");
            }
            return Promise.resolve();
          }
        });
  }, [idToWrappedKpi, isCube19User, isSwitchingUser]);


  const handleResetClick = React.useCallback(() => {
    loadAndSetData();
  }, [loadAndSetData]);


  const handleOrderChange = React.useCallback(orderedKpiIds => {
    setIdToWrappedKpi(setOrderOnWrappedKpis(idToWrappedKpi, orderedKpiIds));
  }, [idToWrappedKpi]);


  return (
      <div>
        <Hint style={{margin: "1rem"}}>
          <Icon icon="info" style={{color: theme.palette.hints.text}} />
          {infoText}
        </Hint>
        <div style={{display: "flex", marginBottom: "1.5rem", padding: "0 1rem"}}>
          <div style={{display: "flex", justifyContent: "center"}}>
            <div className="TESTCAFE-search-metrics" data-test-id={`search-order-metrics`}>
              <DelayedTextField
                  variant="standard"
                  style={{width: 300, marginRight: "0.5rem"}}
                  label="Search Metrics..."
                  value={filterText}
                  onChange={setFilterText} />
            </div>
          </div>
          <div
              style={{
                display: "flex",
                flexGrow: 1,
                marginLeft: "2rem",
                justifyContent: "flex-end",
                alignItems: "center"
              }}>
            <TextButton
                icon="history"
                label="Cancel"
                onClick={handleResetClick}
                disabled={!hasChanges}
                style={{marginLeft: "0.5rem", marginRight: "0.5rem"}} />
            <TextButton
                type={(!hasChanges || hasValidationErrors) ? "default" : "primary"}
                icon="floppy-o"
                label="Save"
                onClick={handleSaveClick}
                disabled={!hasChanges || hasValidationErrors}
                style={{marginLeft: "0.5rem", marginRight: "0.5rem"}} />
          </div>
        </div>

        {loading
            ? <LoadingSpinner />
            : <Rows
                nameToCount={nameToCount}
                idToWrappedKpi={idToWrappedKpi}
                orderedKpiIds={orderedKpiIds}
                onOrderChange={handleOrderChange}
                idToEntityColumn={idToEntityColumn}
                filterText={filterText}
                settings={disableDragX}
            />}
      </div>);
});

const mergeIdToWrappedKpiWithResults = (idToWrappedKpi, testResults) => {
  const kpiIdToTestResult = indexBy(testResult => testResult.get("kpiId"), testResults);
  return idToWrappedKpi.mergeWith(
      (wrappedKpi, testResult) => wrappedKpi.set("testResult", testResult),
      kpiIdToTestResult);
};

const loadEntities = () => Ajax
    .get({url: "data-explorer/entities"})
    .then(res => Immutable.fromJS(res));

const loadEntityColumns = () => Ajax
    .get({url: "entity-columns"})
    .then(res => Immutable.fromJS(res));

export default OrderKpisApp;