import React from "react";
import createReactClass from "create-react-class";
import Tooltip from "react-tooltip";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";
import { RadioGroup as RadioButtonGroup } from '@mui/material';

import NumberField from "js/common/views/inputs/number-field";
import { TextButton } from "js/common/views/inputs/buttons";
import { Row, Column } from "js/common/views/foundation-column-layout";
import AdminHeader from "js/admin/common/admin-header";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import GroupPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import RadioButton from "js/common/views/inputs/radio-button";
import getPriorityFlagLabel from "js/admin/targets/priority-flag-labels";
import * as Groups from "js/common/groups";
import Checkbox from "js/common/views/inputs/checkbox";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const labelHeadingStyle = {
    display: "block",
    fontSize: "0.875rem"
};

const RecalculateGroupTarget = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            kpis,
            config,
            onCancelConfigChangesRequest,
            onCalculateGroupTargetsRequest,
            isActionsDisabled,
            error,
            success,
            onSuccessMessageTimeout,
            theme
        } = this.props;
        const rowSpacing = {
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem"
        };
        const buttonSpacing = {
            marginLeft: "0.5rem",
            marginRight: "0.5rem"
        };

        const bgTooltip = theme.themeId === "light" ? "dark": "light";

        return (
            <div>
                <AdminHeader>
                    Calculate Group Targets
                </AdminHeader>
                <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
                    <Row style={{...rowSpacing, paddingBottom: "1rem"}}>
                        <Column small={12} medium={6} large={5}>
                            <label style={labelHeadingStyle}>
                                Only Calculate for the Following Metrics
                            </label>
                            <KpiPicker
                                placeholder="All Metrics"
                                multiSelect={true}
                                clearable={true}
                                kpis={kpis}
                                selectedKpiIds={config.get("kpiIds").toList()}
                                onChange={this.handleKpiPickerChange} />
                        </Column>
                    </Row>
                    <Row style={rowSpacing}>
                        <Column small={12} medium={3}>
                            <div>
                                <label style={labelHeadingStyle}>
                                    Target Start Date
                                </label>
                                <DatePicker
                                    value={config.get("groupTargetStartDate")}
                                    onDateChange={this.handleGroupDateChange} />
                            </div>
                        </Column>
                        <Column small={12} medium={5}>
                            <PriorityFlagRadioButtonGroup
                                value={config.get("priorityFlag")}
                                onChange={this.handlePriorityFlagChange} />
                        </Column>
                        <Column small={12} medium={4}>
                            <GroupTargetPercentagePicker
                                bgTooltip={bgTooltip}
                                value={config.get("groupPercentage")}
                                onChange={this.handleGroupPercentageChange} />
                        </Column>
                    </Row>
                    <Row style={rowSpacing}>
                        <Column small={12} medium={6} large={5}>
                            <label style={labelHeadingStyle}>
                                Calculate from This Group Down
                            </label>
                            <GroupPicker
                                hierarchy={Groups.getHierarchyWithUsers()}
                                excludeUsers={true}
                                qualifierId={config.get("groupId")}
                                onGroupClick={this.handleGroupPickerClick} />
                        </Column>
                        <Column small={12} medium={6} large={4} end={true}>
                            <div style={{ marginTop: "1.35rem" }}>
                                <Checkbox
                                    label="Include Inactive Users"
                                    checked={config.get("includeInvisibleUsers")}
                                    onCheck={(e, value) => this.handleInactiveUserChange(value)}
                                    style={{ display:"inline-block", maxWidth:250, marginTop: -4 }}/>
                                <i className="fa fa-info-circle"
                                    data-tip data-for="incl-inactive-users-info"
                                    style={{ lineHeight: 1.5, verticalAlign: "top", marginTop: -1 }} />
                            </div>
                            <Tooltip id="incl-inactive-users-info" place="top" type={bgTooltip} effect="solid">
                                Normally targets for inactive Users are not included in the target calculations.<br />
                                Select this option if you want them to be included, but be aware that <br/>
                                unless you updated the target end date when the User was made inactive, <br/>
                                their target will continue to count towards the totals of the Group they were in.
                            </Tooltip>
                        </Column>
                    </Row>
                    <Row style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                        <Column small={6} mediumCentered={true}>
                            <TextButton
                                label="Cancel"
                                icon="history"
                                style={buttonSpacing}
                                disabled={isActionsDisabled}
                                onClick={onCancelConfigChangesRequest} />
                            <TextButton
                                label="Calculate Targets"
                                type="primary"
                                onClick={onCalculateGroupTargetsRequest}
                                style={buttonSpacing}
                                disabled={isActionsDisabled || !!error} />
                        </Column>
                        <Column small={12}>
                            {error && <ErrorMsg text={error} />}
                            {success && <SuccessMsg text={success} onMessageTimeout={onSuccessMessageTimeout} />}
                        </Column>
                    </Row>
                </div>
            </div>
        );
    },

    handlePriorityFlagChange(priorityFlag) {
        const { config, onConfigChange } = this.props;
        onConfigChange(config.set("priorityFlag", priorityFlag));
    },

    handleGroupPercentageChange(groupPercentage) {
        const { config, onConfigChange } = this.props;
        onConfigChange(config.set("groupPercentage", groupPercentage));
    },

    handleGroupDateChange(groupTargetStartDate) {
        const { config, onConfigChange } = this.props;
        onConfigChange(config.set("groupTargetStartDate", groupTargetStartDate));
    },

    handleInactiveUserChange(includeInvisibleUsers) {
        const { config, onConfigChange } = this.props;
        onConfigChange(config.set("includeInvisibleUsers", includeInvisibleUsers));
    },

    handleGroupPickerClick(groupId) {
        const { config, onConfigChange } = this.props;
        onConfigChange(config.set("groupId", groupId));
    },

    handleKpiPickerChange(kpiIds) {
        const { config, onConfigChange } = this.props;
        onConfigChange(config.set("kpiIds", kpiIds));
    }

});

const PriorityFlagRadioButtonGroup = pure(({ onChange, value }) => {
    const priorityFlags = ["ALL", "ANY", "NONE"];
    return (
        <div>
            <label style={labelHeadingStyle}>
                Display Target as a Priority
            </label>
            <RadioButtonGroup
                name="display-target-as-priority"
                onChange={(e, selected) => onChange(selected)}
                value={value}>
                {priorityFlags.map(priorityFlag => (
                    <RadioButton
                        key={priorityFlag}
                        value={priorityFlag}
                        label={getPriorityFlagLabel(priorityFlag)}
                         />))}
            </RadioButtonGroup>
        </div>
    );
});

const GroupTargetPercentagePicker = pure(({ bgTooltip, value, onChange }) => (
    <div>
        <label style={{ display:"inline-block", fontSize:"0.875rem" }}>
            Calculate % of Group Target
        </label>
        <i className="fa fa-info-circle"
            data-tip data-for="group-target-percentage-info"
            style={{
                display: "inline-block",
                verticalAlign: "top",
                marginLeft: "1rem",
                marginRight: 20
            }} />
        <Tooltip id="group-target-percentage-info" place="top" type={bgTooltip} effect="solid">
            If required, you can apply a loading factor to either increase or decrease the calculated targets.<br/>
            By default this value is set to 100% which means the calculation will be the sum of User targets set.
            If you entered a value less than 100% then the Group target will decrease proportionally.
            If you entered a value larger than 100% the Group target value will be increased.
        </Tooltip>
        <NumberField
            fullWidth={false}
            style={{ width: 210, marginBottom: 8 }}
            range={[ 1, 1000 ]}
            value={value}
            onChange={onChange}
            label="Enter a value between 1-1000%" />
    </div>
));

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <RecalculateGroupTarget theme={theme} {...props} />;
};