import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";

import * as ajax from "js/common/ajax";
import * as rssFeedRepo from "js/common/repo/rss-feed-repo";
import * as auditor from "js/common/auditer";

import currentClient from "js/common/repo/backbone/current-client";
import * as Colors from "js/common/cube19-colors";
import {rssToApiUrl} from "js/app-configuration";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const height = 60;

const magicScrollNumber = 4.2;

const NewsTicker = createReactClass({

  getInitialState() {
    return {
      items: Immutable.List()
    };
  },

  componentDidMount() {
    const {feedIds} = this.props;
    const urls = feedIds.map(id => rssFeedRepo.get(id).get("url"));
    loadRssFeeds(urls).then(items => {
      this.setState({items});
    });
  },

  render() {
    const newsItems = this.state.items
        .filter(item => item.indexOf("VIDEO") === -1 && item.indexOf("AUDIO") === -1);
    const client = currentClient;
    if (client.hasPermission("HAS_NEW_LOOK_CUBETV")) {
      return this.renderNewNewsTicker(newsItems, this.props.theme);
    } else {
      return this.renderCurrentNewsTicker(newsItems, this.props.theme);
    }
  },

  renderCurrentNewsTicker(newsItems, theme) {
    const newsStr = newsItems.join("  |  ");
    const animationDuration = newsStr.length / magicScrollNumber;
    const newsItemStyle = {
      color: "#eee",
      fontFamily: theme.typography.fontFamilyBold,
      fontSize: 30,
      fontWeight: 700,
      display: "inline-block",
      animation: "cubetv-news-ticker " + animationDuration + "s linear infinite"
    };
    return (
        <div style={{...newsTickerContainerStyle, backgroundColor: "#900000"}}>
          <div style={newsTickerStyle(theme)}>
            {!this.state.items.isEmpty() &&
            <span style={newsItemStyle}>
                            &nbsp;&nbsp;{newsStr}{"  |"}
                        </span>
            }
            {!this.state.items.isEmpty() &&
            <span style={newsItemStyle}>
                            &nbsp;&nbsp;{newsStr}{"  |"}
                        </span>
            }
          </div>
          <div style={{
            ...newsTickerHeaderStyle(theme),
            padding: "0px 20px",
            color: Colors.c19Yellow,
            backgroundColor: "#10005c"
          }}>
            news
          </div>
        </div>
    );
  },

  renderNewNewsTicker(newsItems, theme) {
    const ua = auditor.getUserAgent();
    const browser = ua.getBrowser();
    const isIeOrEdge = browser.name === "IE" || browser.name === "Edge";
    const newsStr = newsItems.join("\u00A0\u00A0\u25CF\u00A0\u00A0");
    const animationDuration = newsStr.length / magicScrollNumber;
    const newsItemStyle = {
      color: "#000",
      fontWeight: "700",
      fontSize: "1.875rem",
      fontFamily: theme.typography.fontFamilyBold,
      display: "inline-block",
      animation: "cubetv-news-ticker " + animationDuration + "s linear infinite"
    };
    return (
        <div style={{...newsTickerContainerStyle, backgroundColor: Colors.c19Yellow}}>
          <div
              style={newsTickerStyle(theme)}>
            {!this.state.items.isEmpty() &&
            <span style={newsItemStyle}>
                            &nbsp;&nbsp;{newsStr}{"\u00A0\u00A0\u25CF"}
                        </span>
            }
            {!this.state.items.isEmpty() &&
            <span style={newsItemStyle}>
                            &nbsp;&nbsp;{newsStr}{"\u00A0\u00A0\u25CF"}
                        </span>
            }
          </div>
          <div style={{
            ...newsTickerHeaderStyle(theme),
            color: Colors.white,
            backgroundColor: "#000",
            paddingLeft: 20,
            paddingRight: isIeOrEdge ? 20 : 60,
            clipPath: isIeOrEdge ? "none" : "polygon(0 0, 70% 0, 100% 100%, 0% 100%)"
          }}>
            news
          </div>
        </div>
    );
  }

});

const newsTickerContainerStyle = {
  position: "fixed",
  bottom: 0
};

const newsTickerStyle = theme => ({
  borderTop: "2px solid #eee",
  whiteSpace: "nowrap",
  overflow: "hidden",
  padding: 6
});

const newsTickerHeaderStyle = theme => ({
  position: "absolute",
  height,
  fontWeight: "700",
  fontSize: "2.5rem",
  fontFamily: theme.typography.fontFamily,
  "bottom": 0,
  "left": 0,
  zIndex: 10
});

const loadRssFeeds = urls => {
  const promises = urls.map(url => {
    const count = 25
    const requestUrl = `${rssToApiUrl}/feed?url=${url}&count=${count}`;
    return ajax
        .get({
          relativeToAppLayer: false,
          url: requestUrl
        })
        .catch(() => {
          console.debug("unable to load rss feed from " + url);
          return [];
        });
  });

  return Promise
      .all(promises)
      .then(feeds => shuffle(Immutable.fromJS(feeds)).flatMap(items => items));
};

const shuffle = list => list.withMutations(mutableList => {
  let currentItem = mutableList.size;
  let tmp = null;
  let swappedItem = null;
  while (currentItem) {
    // Pick a remaining element…
    swappedItem = Math.floor(Math.random() * currentItem--);
    // Swap with current element
    tmp = mutableList.get(currentItem);
    mutableList.set(currentItem, mutableList.get(swappedItem));
    mutableList.set(swappedItem, tmp);
  }
});

export {
  height
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <NewsTicker theme={theme} {...props} />;
};
