import React from "react";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import {TextButton} from "js/common/views/inputs/buttons";
import * as Colors from "js/common/cube19-colors";
import {getUniqueName} from "js/common/utils/unique-naming";
import Dialog from "js/common/views/dialog";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import { TextField } from '@mui/material';

const dialogTitleStyle = {
  color: Colors.c19Yellow,
  fontSize: "1rem"
};

const dialogBodyStyle = theme => ({
  color: theme.palette.text.main
});

const dialogButtonStyle = {
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
  marginBottom: "1rem"
};

const SaveAsDialog = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    const {initialName, unavailableNames, generateUniqueName} = this.props;
    let name;
    if (generateUniqueName) {
      if (unavailableNames.contains(initialName)) {
        name = getUniqueName(initialName, unavailableNames);
      }
    } else {
      name = initialName || "";
    }
    return {
      name,
      errorText: ""
    };
  },

  componentDidMount() {
    const {savingTypeName} = this.props;
    const capitalisedName = savingTypeName.charAt(0).toUpperCase() + savingTypeName.substr(1);
    this.nameRequiredError = `${capitalisedName} name cannot be blank`;
    this.nameUnavailableError = `${capitalisedName} name already in use`;
  },

  render() {
    const {savingTypeName, theme} = this.props;
    const {name, errorText} = this.state;
    return (
        <Dialog
            title={`Save ${savingTypeName} as...`}
            actions={[
              <TextButton
                  testId="save-as-dialog-cancel-button"
                  key="cancel"
                  label="Cancel"
                  style={dialogButtonStyle}
                  onClick={this.props.onCancelRequest} />,
              <TextButton
                  testId="save-as-dialog-save-button"
                  key="save"
                  type="primary"
                  label="Save"
                  style={dialogButtonStyle}
                  disabled={!!errorText}
                  onClick={this.handleSaveRequest} />
            ]}
            autoDetectWindowHeight={true}
            titleStyle={dialogTitleStyle}
            bodyStyle={dialogBodyStyle(theme)}
            open={true}>
          <TextField
              variant="standard"
              inputStyle={{boxShadow: "none"}}
              inputProps={{
                "data-test-id": "save-as-textfield"
              }}
              fullWidth={true}
              label={`Give this ${savingTypeName} a name`}
              error={!!errorText}
              helperText={errorText}
              value={name}
              onChange={e => this.handleNameChange(e.target.value.substring(0, 255))}
              onKeyDown={this.handleOnKeyDown} />
        </Dialog>
    );
  },

  handleNameChange(newReportName) {
    let errorText = "";
    if (newReportName.length === 0) {
      errorText = this.nameRequiredError;
    } else if (this.props.unavailableNames.includes(newReportName)) {
      errorText = this.nameUnavailableError;
    }
    this.setState({
      name: newReportName,
      errorText
    });
  },

  handleOnKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSaveRequest();
    }
  },

  handleSaveRequest() {
    const {name} = this.state;
    if (name.length === 0) {
      this.setState({
        errorText: this.nameRequiredError
      });
    } else if (this.props.unavailableNames.includes(name)) {
      this.setState({
        errorText: this.nameUnavailableError
      });
    } else {
      this.props.onSaveAsRequest(name);
    }
  }

});


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <SaveAsDialog theme={theme} {...props} />;
};

export default Wrapper;
