
import getKpiDataSafely from "js/onpoint/get-kpi-data-safely";
import * as numbers from "js/common/utils/numbers";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";

const sortByGroupName = (data, sortOrder) => {
    const sortedData = data.sortBy(row => Groups.getGroupOrCurrent(row.get("groupId")).get("name").toLowerCase());
    return sortOrder === "DESC" ? sortedData.reverse() : sortedData;
};

const sortByGroupBreadcrumbs = (data, sortOrder) => {
    const sortedData = data.sortBy(row => getGroupBreadcrumbsStr(row.get("groupId")).toLowerCase());
    return sortOrder === "DESC" ? sortedData.reverse() : sortedData;
};

const getGroupBreadcrumbsStr = groupId => {
    if(groupId === 0){
        groupId = Users.getCurrentUser().get("groupId")
    }
    const group = Groups.getGroupOrCurrent(groupId);
    const isRootGroup = groupId === Groups.getRootGroup().get("id");
    if (isRootGroup) {
        return group.get("name");
    } else {
        const breadcrumbs = group.get("breadcrumbs");
        return breadcrumbs.join(" > ");
    }
};

const sortByUserFullName = (data, sortOrder) => {
    const sortedData = data.sortBy(row => Users.getUserOrCurrent(row.get("userId")).get("fullName").toLowerCase());
    return sortOrder === "DESC" ? sortedData.reverse() : sortedData;
};

const sortByUserBreadcrumbs = (data, sortOrder) => {
    const sortedData = data.sortBy(row => {
        const user = Users.getUserOrCurrent(row.get("userId"));
        const isUserInRootGroup = user.get("groupId") === Groups.getRootGroup().get("id");
        const group = Groups.getGroupOrCurrent(user.get("groupId"));
        let fullBreadcrumb = `${group ? getGroupBreadcrumbsStr(group.get("id")) : ""} *> ${user.get("fullName")}`;
        if (isUserInRootGroup) {
            fullBreadcrumb = `* > ${fullBreadcrumb}`;
        }
        return fullBreadcrumb.toLowerCase();
    });
    return sortOrder === "DESC" ? sortedData.reverse() : sortedData;
};

const sortByKpiTotalValue = (data, sortByKpiId, sortOrder) => {
    const sortedData = data.sortBy(row => {
        const kpiData = row.getIn(["dataByKpiId", sortByKpiId]);
        const total = getKpiDataSafely(kpiData, "total");
        return total.get("value");
    });
    return sortOrder === "DESC" ? sortedData.reverse() : sortedData;
};

const sortByKpiTargetValue = (data, sortByKpiId, sortOrder) => {
    const sortedTargetlessData = data
        .filter(row => {
            const kpiData = row.getIn(["dataByKpiId", sortByKpiId]);
            const target = getKpiDataSafely(kpiData, "target");
            return target.get("value") === 0;
        })
        .sortBy(row => {
            const kpiData = row.getIn(["dataByKpiId", sortByKpiId]);
            const total = getKpiDataSafely(kpiData, "total");
            return total.get("value");
        });
    const sortedTargetsData = data
        .filter(row => {
            const kpiData = row.getIn(["dataByKpiId", sortByKpiId]);
            const target = getKpiDataSafely(kpiData, "target");
            return target.get("value") > 0;
        })
        .sort((rowA, rowB) => {
            const kpiDataA = rowA.getIn(["dataByKpiId", sortByKpiId]);
            const targetA = getKpiDataSafely(kpiDataA, "target");

            const kpiDataB = rowB.getIn(["dataByKpiId", sortByKpiId]);
            const targetB = getKpiDataSafely(kpiDataB, "target");

            if (targetA.get("value") < targetB.get("value")) { return -1; }
            if (targetA.get("value") > targetB.get("value")) { return 1; }
            else { // equal case
                const totalA = getKpiDataSafely(kpiDataA, "total");
                const totalB = getKpiDataSafely(kpiDataB, "total");
                return totalA.get("value") - totalB.get("value");
            }
        });
    const sortedData = sortOrder === "DESC" ? sortedTargetsData.reverse() : sortedTargetsData;
    return sortedData.concat(sortOrder === "DESC" ? sortedTargetlessData.reverse() : sortedTargetlessData);
};

const sortByKpiTargetPercentage = (data, sortByKpiId, sortOrder) => {
    const sortedTargetlessData = data
        .filter(row => {
            const kpiData = row.getIn(["dataByKpiId", sortByKpiId]);
            const target = getKpiDataSafely(kpiData, "target");
            return target.get("value") === 0;
        })
        .sortBy(row => {
            const kpiData = row.getIn(["dataByKpiId", sortByKpiId]);
            const total = getKpiDataSafely(kpiData, "total");
            return total.get("value");
        });
    const sortedTargetsData = data
        .filter(row => {
            const kpiData = row.getIn(["dataByKpiId", sortByKpiId]);
            const target = getKpiDataSafely(kpiData, "target");
            return target.get("value") > 0;
        })
        .sort((rowA, rowB) => {
            const kpiDataA = rowA.getIn(["dataByKpiId", sortByKpiId]);
            const targetPercentA = calculateTargetPercentage(kpiDataA);

            const kpiDataB = rowB.getIn(["dataByKpiId", sortByKpiId]);
            const targetPercentB = calculateTargetPercentage(kpiDataB);

            if (targetPercentA < targetPercentB) { return -1; }
            if (targetPercentA > targetPercentB) { return 1; }
            if (targetPercentA === targetPercentB) {
                const totalA = getKpiDataSafely(kpiDataA, "total");
                const totalB = getKpiDataSafely(kpiDataB, "total");
                return totalA.get("value") - totalB.get("value");
            }
        });
    const sortedData = sortOrder === "DESC" ? sortedTargetsData.reverse() : sortedTargetsData;
    return sortedData.concat(sortOrder === "DESC" ? sortedTargetlessData.reverse() : sortedTargetlessData);
};

const calculateTargetPercentage = kpiData => {
    const target = getKpiDataSafely(kpiData, "target");
    if (target.get("value") === 0) {
        return 0;
    } else {
        const total = getKpiDataSafely(kpiData, "total");
        return (total.get("value") / target.get("value")) * 100;
    }
};

const sortByRatioValue = (data, sortByRatio, sortOrder) => {
    const ratioDisplayType = sortByRatio.get("displayType");
    let sortedData;
    if (ratioDisplayType === "ABSOLUTE" || ratioDisplayType === "NORMALISED" || ratioDisplayType === "FRACTION") {
        sortedData = data.sort((rowA, rowB) => {
            const firstKpiDataA = rowA.getIn(["dataByKpiId", sortByRatio.get("firstKpiId")]);
            const firstKpiTotalA = getKpiDataSafely(firstKpiDataA, "total");
            const secondKpiDataA = rowA.getIn(["dataByKpiId", sortByRatio.get("secondKpiId")]);
            const secondKpiTotalA = getKpiDataSafely(secondKpiDataA, "total");
            const ratioValueA = getRatioSortValue(firstKpiTotalA.get("value"), secondKpiTotalA.get("value"), ratioDisplayType);

            const firstKpiDataB = rowB.getIn(["dataByKpiId", sortByRatio.get("firstKpiId")]);
            const firstKpiTotalB = getKpiDataSafely(firstKpiDataB, "total");
            const secondKpiDataB = rowB.getIn(["dataByKpiId", sortByRatio.get("secondKpiId")]);
            const secondKpiTotalB = getKpiDataSafely(secondKpiDataB, "total");
            const ratioValueB = getRatioSortValue(firstKpiTotalB.get("value"), secondKpiTotalB.get("value"), ratioDisplayType);

            if (ratioValueA.numerator < ratioValueB.numerator) { return -1; }
            if (ratioValueA.numerator > ratioValueB.numerator) { return 1; }
            if (ratioValueA.numerator === ratioValueB.numerator) {
                return ratioValueA.denominator - ratioValueB.denominator;
            }
        });
    } else {
        sortedData = data.sortBy(row => {
            const firstKpiData = row.getIn(["dataByKpiId", sortByRatio.get("firstKpiId")]);
            const firstKpiTotal = getKpiDataSafely(firstKpiData, "total");

            const secondKpiData = row.getIn(["dataByKpiId", sortByRatio.get("secondKpiId")]);
            const secondKpiTotal = getKpiDataSafely(secondKpiData, "total");

            const ratioSortValue = getRatioSortValue(firstKpiTotal.get("value"), secondKpiTotal.get("value"), ratioDisplayType);
            return ratioSortValue;
        });
    }
    return sortOrder === "DESC" ? sortedData.reverse() : sortedData;
};
const getRatioSortValue = (firstKpiTotalValue, secondKpiTotalValue, displayType) => {
    switch (displayType) {
        case "ABSOLUTE":
            return {
                numerator: firstKpiTotalValue,
                denominator: secondKpiTotalValue
            };
        case "NORMALISED":
            if (firstKpiTotalValue === 0 || secondKpiTotalValue === 0) {
                return {
                    numerator: firstKpiTotalValue,
                    denominator: secondKpiTotalValue
                };
            } else {
                return numbers.normaliseFraction(firstKpiTotalValue, secondKpiTotalValue);
            }
        case "FRACTION":
            if (firstKpiTotalValue === 0 || secondKpiTotalValue === 0) {
                return {
                    numerator: firstKpiTotalValue,
                    denominator: secondKpiTotalValue
                };
            } else {
                return numbers.simplifyFraction(firstKpiTotalValue, secondKpiTotalValue);
            }
        default:
            if (firstKpiTotalValue === 0 || secondKpiTotalValue === 0) {
                return 0;
            } else {
                return secondKpiTotalValue / firstKpiTotalValue;
            }
    }
};

export {
    sortByGroupName,
    sortByUserFullName,
    sortByGroupBreadcrumbs,
    sortByUserBreadcrumbs,
    sortByKpiTotalValue,
    sortByKpiTargetValue,
    sortByKpiTargetPercentage,
    sortByRatioValue
};
