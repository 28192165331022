import React from "react";
import {betterMemo} from "js/common/utils/more-memo";
import DependencyGraph from "js/admin/kpis/edit-kpis/tabs/relationships/dependency-graph";
import CycleGraph from "js/admin/kpis/edit-kpis/tabs/relationships/cycle-graph";
import ErrorMsg from "js/common/views/error";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import {getIdToNode} from "js/admin/kpis/edit-kpis/utils";

const MetricRelationships = betterMemo(
    "MetricRelationships",
    ({kpiIdToDependentKpis, kpiIdToContributingKpis, kpi, onDependencyClick, cyclingKpis, rootNodes}) => {

      const { theme } = React.useContext(CustomThemeContext);

      const idToNode = !cyclingKpis && getIdToNode(
          kpi.get("id"),
          rootNodes,
          kpiIdToDependentKpis);

      return <div style={{width: "100%"}}>
        {cyclingKpis ? <>
          <ErrorMsg style={{margin: "0 5px"}} text="Unable to load graph as config contains a cycle." />
          <CycleGraph
              currentKpiId={kpi.get("id")}
              onClick={onDependencyClick}
              cyclingKpis={cyclingKpis}
          />
        </> : <>
          <Hint style={{margin: "1rem"}}>
            <Icon icon="info" style={{color: theme.palette.hints.text}} />
            <span>Click on a metric in the tree to jump to that metric in the editor.  You can hover over a dependency icon on a metric to highlight the relevant connections.</span>
          </Hint>
          <DependencyGraph
              currentKpiId={kpi.get("id")}
              idToNode={idToNode}
              kpiIdToContributingKpis={kpiIdToContributingKpis}
              kpiIdToDependentKpis={kpiIdToDependentKpis}
              onDependencyClick={onDependencyClick}
          />
        </>}
      </div>;
    });

export default MetricRelationships;
