import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import moment from "moment";
import promiseLimit from "promise-limit";
import PureRenderMixin from "react-addons-pure-render-mixin";

import {TextButton} from "js/common/views/inputs/buttons";

import Tooltip from "js/common/views/tooltips";
import ThinSectionHeader from "js/common/views/thin-section-header";
import MobilesNotSupportedMsg from "js/onpoint/mobiles-not-supported";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import KpiPicker from "js/common/views/kpis/custom-kpi-picker";
import RatioPicker from "js/common/views/inputs/ratio-picker";
import UserAndGroupPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import UsersAndGroupsColumn from "js/onpoint/users-and-groups-column";
import DataColumns from "js/onpoint/data-columns";
import GroupSelectionTypeDialog from "js/onpoint/group-selection-type-dialog";
import SaveReportAsDialog from "js/common/views/save-as-dialog";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import useDimensions from "js/common/utils/use-dimensions";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as kpiCalculator from "js/common/kpi-calculator";
import * as ratioRepo from "js/common/repo/ratio-repo";
import * as popups from "js/common/popups";
import * as numbers from "js/common/utils/numbers";
import * as dataSorting from "js/onpoint/data-sorting";
import * as auditor from "js/common/auditer";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import {isMobile} from "js/common/ua-parser";
import {formatRowDataForCsv} from "js/onpoint/csv-formatter";
import saveAsCsv from "js/common/save-as-csv";
import Checkbox from "js/common/views/inputs/checkbox";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants";

const $ = window.$;

const delayInMillis = 600;
const concurrency = 5;
let limit = promiseLimit(concurrency);

let currentLoadId = 0;
let loadIdToCancelled = Immutable.Map();

const configContainerStyle = {
  display: "inline-block",
  paddingTop: "0.5rem",
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
  paddingBottom: "0.5rem"
};

const buttonStyle = {
  minWidth: 85,
  marginLeft: "0.3rem",
  marginRight: "0.3rem",
  paddingLeft: "1rem",
  paddingRight: "1rem"
};

const ConfigurableReport = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    let kpisByKpiId = Immutable.Map();
    getKpis().forEach(kpi => {
      kpisByKpiId = kpisByKpiId.set(kpi.get("id"), kpi);
    });

    const {config} = this.props;
    const sortBy = config.get("sortBy");
    const sortOrder = sortBy.get("columnType") === "GROUPS_AND_USERS" ? sortBy.get("sortOrder") : null;

    let groupsKpiData = config
        .get("groupIds")
        .map(groupId => Immutable.fromJS({
          groupId,
          dataByKpiId: Immutable.Map()
        }));
    groupsKpiData = config.get("showBreadcrumbs") ?
        dataSorting.sortByGroupBreadcrumbs(groupsKpiData, sortOrder) :
        dataSorting.sortByGroupName(groupsKpiData, sortOrder);

    let usersKpiData = config
        .get("userIds")
        .map(userId => Immutable.fromJS({
          userId,
          dataByKpiId: Immutable.Map()
        }));
    usersKpiData = config.get("showBreadcrumbs") ?
        dataSorting.sortByUserBreadcrumbs(usersKpiData, sortOrder) :
        dataSorting.sortByUserFullName(usersKpiData, sortOrder);

    return {
      timeframes: timeframeRepo.getAll(),
      hierarchy: getHierarchy(),
      kpisByKpiId,
      ratiosByRatioId: getRatios(),
      groupsKpiData,
      promisesWithGroupIdsKpiIds: Immutable.List(),
      usersKpiData,
      promisesWithUserIdsKpiIds: Immutable.List(),
      selectedGroupId: null,
      isLoadingUsersInGroup: false,
      isSaveReportAsDialogOpen: false,
      reportAreaHeight: 0,
      scrollTop: null,
      scrollLeft: null
    };
  },

  componentDidMount() {
    if (!this.state.groupsKpiData.isEmpty() || !this.state.usersKpiData.isEmpty()) {
      this.loadData();
    }
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.renderId !== this.props.renderId) {
      const newConfig = nextProps.config;
      const sortBy = newConfig.get("sortBy");
      const sortOrder = sortBy.get("columnType") === "GROUPS_AND_USERS" ? sortBy.get("sortOrder") : null;

      let groupsKpiData = newConfig
          .get("groupIds")
          .map(groupId => Immutable.fromJS({
            groupId,
            dataByKpiId: Immutable.Map()
          }));
      groupsKpiData = newConfig.get("showBreadcrumbs") ?
          dataSorting.sortByGroupBreadcrumbs(groupsKpiData, sortOrder) :
          dataSorting.sortByGroupName(groupsKpiData, sortOrder);

      let usersKpiData = newConfig
          .get("userIds")
          .map(userId => Immutable.fromJS({
            userId,
            dataByKpiId: Immutable.Map()
          }));
      usersKpiData = newConfig.get("showBreadcrumbs") ?
          dataSorting.sortByUserBreadcrumbs(usersKpiData, sortOrder) :
          dataSorting.sortByUserFullName(usersKpiData, sortOrder);

      this.setState({
        groupsKpiData,
        usersKpiData
      });
    }
  },

  render() {
    return (
        <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
          {isMobile() ? <MobilesNotSupportedMsg /> : this.renderPage()}
        </div>
    );
  },

  renderPage() {
    const cellHeight = 50;
    const gridBorderStyle = "1px solid #555";
    const {
      selectedGroupId,
      isLoadingUsersInGroup,
      groupsKpiData,
      usersKpiData,
      isSaveReportAsDialogOpen
    } = this.state;
    const {config, isConfigChanged, onDataCellClick, containerWidth, savedReports, theme} = this.props;
    const isGroupSelectionTypeDialogOpen = !!selectedGroupId && selectedGroupId !== 0;

    const groupsDataToLoad = groupsKpiData.reduce((total, row) => total + row.get("dataByKpiId").count(), 0);
    const groupsDataStillLoading = groupsKpiData.reduce((total, row) => {
      const dataStillLoading = row.get("dataByKpiId").filter(data => !!data && data.get("isLoading"));
      return total + dataStillLoading.count();
    }, 0);
    const groupsDataLoaded = groupsDataToLoad - groupsDataStillLoading;

    const usersDataToLoad = usersKpiData.reduce((total, row) => total + row.get("dataByKpiId").count(), 0);
    const usersDataStillLoading = usersKpiData.reduce((total, row) => {
      const dataStillLoading = row.get("dataByKpiId").filter(data => !!data && data.get("isLoading"));
      return total + dataStillLoading.count();
    }, 0);
    const usersDataLoaded = usersDataToLoad - usersDataStillLoading;
    const totalLoaded = groupsDataLoaded + usersDataLoaded;
    const totalToLoad = groupsDataToLoad + usersDataToLoad;
    const percentLoaded = totalLoaded / totalToLoad;
    const isAllDataLoaded = totalLoaded === totalToLoad;
    const sortedGroupsKpiData = isAllDataLoaded ? this.sortGroupsKpiData() : groupsKpiData;
    const sortedUsersKpiData = isAllDataLoaded ? this.sortUsersKpiData() : usersKpiData;

    const sortedCurrentGroupsKpiData = sortedGroupsKpiData.map(groupToKpi =>
        groupToKpi.get("groupId") === 0
            ? groupToKpi.set("groupId", Users.getCurrentUser().get("groupId"))
            : groupToKpi);
    const sortedCurrentUsersKpiData = sortedUsersKpiData.map(userToKpi =>
        userToKpi.get("userId") === 0
            ? userToKpi.set("userId", Users.getCurrentUser().id)
            : userToKpi);

    const reportName = config.get("name");
    const isSavedReportChanged = isConfigChanged && config.get("id");
    const headerLabel = isSavedReportChanged ? <span>{reportName} <em>(modified)</em></span> : reportName;

    const noGroupsOrUsersSelected = groupsKpiData.isEmpty() && usersKpiData.isEmpty();
    const usersAndGroupsColumnWidth = noGroupsOrUsersSelected ? 0 : this.getGroupAndUserColumnWidth();
    const containerMarginWidth = 15;
    const dataColumnsContainerWidth = containerWidth - (usersAndGroupsColumnWidth + containerMarginWidth);

    let headingAdjustment = 220;
    if (containerWidth < 1375) {
      headingAdjustment = headingAdjustment + 60;
    } else if (containerWidth < 500) {
      headingAdjustment = headingAdjustment + 200;
    }

    const reportAreaHeight = this.props.height - headingAdjustment;

    return (
        <>
          <div ref={this.props.dimensionRef}>
            <ThinSectionHeader id="onpoint-header" label={headerLabel} style={{borderTop: 0}} theme={theme} />
            {this.renderReportConfigSettings(percentLoaded)}
            <div style={{display: "inline-flex", height: "100%", width: "100%"}}>
              {(!groupsKpiData.isEmpty() || !usersKpiData.isEmpty()) &&
                  <UsersAndGroupsColumn
                      groupIds={sortedGroupsKpiData.map(row => row.get("groupId"))}
                      userIds={sortedUsersKpiData.map(row => row.get("userId"))}
                      showGroupBreadcrumbs={config.get("showBreadcrumbs")}
                      isLoadingUsersInGroup={isLoadingUsersInGroup}
                      sortBy={config.get("sortBy")}
                      onSortingChange={this.handleSortingChange}
                      onRemoveUserRequest={this.handleRemoveUserRequest}
                      onRemoveGroupRequest={this.handleRemoveGroupRequest}
                      columnWidth={usersAndGroupsColumnWidth}
                      columnHeight={reportAreaHeight}
                      scrollTop={this.state.scrollTop}
                      onScroll={this.handleDataColumnsScroll}
                      cellHeight={cellHeight}
                      borderStyle={gridBorderStyle} />}
              <DataColumns
                  viewType={config.get("viewType")}
                  columnConfigs={config.get("columnConfigs")}
                  sortBy={config.get("sortBy")}
                  onSortingChange={this.handleSortingChange}
                  onRatioColumnNameChange={this.handleRatioColumnNameChange}
                  onRatioDisplayChange={this.handleRatioDisplayChange}
                  groupsKpiData={sortedCurrentGroupsKpiData}
                  usersKpiData={sortedCurrentUsersKpiData}
                  onRemoveColumnRequest={this.handleRemoveColumnRequest}
                  onCellClick={(columnType, cell) => onDataCellClick(columnType, cell)}
                  onColumnsReorder={this.handleColumnsReorder}
                  scrollTop={this.state.scrollTop}
                  onScroll={this.handleDataColumnsScroll}
                  scrollLeft={this.state.scrollLeft}
                  cellHeight={cellHeight}
                  borderStyle={gridBorderStyle}
                  width={dataColumnsContainerWidth}
                  height={reportAreaHeight} />
            </div>
          </div>
          {isGroupSelectionTypeDialogOpen &&
              <GroupSelectionTypeDialog
                  groupId={selectedGroupId}
                  onConfirm={this.handleGroupSelectionTypeConfirm}
                  onCancel={this.closeGroupSelectionTypeDialog} />}
          {isSaveReportAsDialogOpen &&
              <SaveReportAsDialog
                  savingTypeName={"report"}
                  initialName={config.get("name")}
                  onSaveAsRequest={this.handleSaveReportAsRequest}
                  onCancelRequest={this.closeSaveReportAsDialog}
                  unavailableNames={savedReports.get("ownedConfigs").map(config => config.get("name"))} />}
        </>);
  },

  renderReportConfigSettings(percentLoaded) {
    const {config, onConfigChange, containerWidth, theme} = this.props;
    const {
      timeframes,
      hierarchy,
      kpisByKpiId,
      ratiosByRatioId,
      groupsKpiData,
      usersKpiData
    } = this.state;

    const selectedKpiIds = config
        .get("columnConfigs")
        .filter(col => col.get("type") === "KPI")
        .map(col => col.getIn(["kpi", "id"]));
    const selectableKpis = kpisByKpiId
        .deleteAll(selectedKpiIds)
        .valueSeq()
        .sortBy(kpi => kpi.get("name").toLowerCase());

    const selectedRatioIds = config
        .get("columnConfigs")
        .filter(col => col.get("type") === "RATIO")
        .map(col => col.getIn(["ratio", "id"]));
    const selectableRatios = ratiosByRatioId
        .deleteAll(selectedRatioIds)
        .valueSeq()
        .filter(r => !r.get("isDeleted"))
        .sortBy(ratio => ratio.get("name").toLowerCase());

    const timeframe = config.get("timeframe");
    const visibleTimeframes = timeframes
        .filter(t => (t.get("visible") && !t.get("isDeleted")) || t.get("id") === timeframe.get("type"));

    const maxWidth = 410;
    let columnWidth;
    if (containerWidth > 768 && containerWidth < 1024) {
      columnWidth = 335;
    } else if (containerWidth > 1024) {
      const width = containerWidth / 3;
      columnWidth = width > maxWidth ? maxWidth : width;
    } else {
      columnWidth = 250;
    }

    return (
        <div
            id="onpoint-config-area"
            style={{
              width: "100%",
              backgroundColor: theme.themeId === "light" ? theme.palette.background.card : ""
            }}>
          <div>
            <div style={{...configContainerStyle, width: columnWidth}}>
              <KpiPicker
                  label="Add Metric"
                  kpis={selectableKpis}
                  onKpiSelect={this.handleKpiSelect}
                  closeOnSelect={false} />
            </div>
            <div style={{...configContainerStyle, width: columnWidth}}>
              <UserAndGroupPicker
                  showLoggedInUserOptions={Users.currentHasPermission("SHARE_ONPOINT_REPORT")}
                  label="Add User or Group"
                  hierarchy={hierarchy.toJS()}
                  selectedUserIds={config.get("userIds").toSet()}
                  onGroupClick={this.handleGroupSelect}
                  onUserClick={this.handleUserSelect}
                  closeOnSelect={false} />
            </div>
            <div style={{...configContainerStyle, width: columnWidth}}>
              <TimeframePicker
                  timeframes={visibleTimeframes}
                  timeframe={getTimeframeModel(timeframe)}
                  onChange={timeframe => {
                    this.cancelPendingPromises();
                    this.setState({
                      usersKpiData: usersKpiData.map(usr => usr.set("dataByKpiId", Immutable.Map())),
                      groupsKpiData: groupsKpiData.map(grp => grp.set("dataByKpiId", Immutable.Map()))
                    });
                    onConfigChange(config.set("timeframe", Immutable.fromJS(timeframe.getRawJson())));
                  }} />
            </div>
          </div>

          <div>
            <div style={{...configContainerStyle, width: columnWidth}}>
              <RatioPicker
                  label="Add Ratio"
                  ratios={selectableRatios}
                  kpis={getKpis()}
                  onRatioSelect={this.handleRatioSelect}
                  closeOnSelect={false} />
            </div>
            <div
                style={{
                  ...configContainerStyle,
                  verticalAlign: "top",
                  paddingLeft: "0.85rem",
                  paddingTop: "0.85rem",
                  marginTop: "-9px"
                }}>
              <Checkbox
                  label="Display Groups and Users' Hierarchy"
                  checked={config.get("showBreadcrumbs")}
                  onCheck={(e, isChecked) => this.handleShowBreadcrumbsToggle(isChecked)}
                  style={{display: "inline-block"}}
                  labelStyle={{left: "-4px", position: "relative", top: "2px"}}
                  iconStyle={{marginRight: 0}} />
              <Checkbox
                  label="Detailed View"
                  checked={config.get("viewType") === "DETAILED"}
                  onCheck={(e, isChecked) => this.handleViewTypeChange(isChecked)}
                  style={{display: "inline-block"}}
                  labelStyle={{left: "-4px", position: "relative", top: "2px"}}
                  iconStyle={{marginRight: 0}} />
            </div>
            {this.renderReportActionButtons(percentLoaded)}
          </div>
        </div>
    );
  },

  renderReportActionButtons(percentLoaded) {
    const {config, isSavingReport, containerWidth} = this.props;
    const {isLoadingUsersInGroup, groupsKpiData, usersKpiData} = this.state;

    const noGroupsOrUsersSelected = groupsKpiData.isEmpty() && usersKpiData.isEmpty();
    const configDoesNotHaveRequiredOptions = config.get("columnConfigs").isEmpty() || noGroupsOrUsersSelected;

    const isLoadingGroupData = groupsKpiData
        .map(row => row.get("dataByKpiId"))
        .some(dataByKpiId => dataByKpiId.some(kpiData => !!kpiData && kpiData.get("isLoading")));
    const isLoadingUserData = usersKpiData
        .map(row => row.get("dataByKpiId"))
        .some(dataByKpiId => dataByKpiId.some(kpiData => !!kpiData && kpiData.get("isLoading")));
    const isLoadingData = isLoadingGroupData || isLoadingUserData;

    const isAllGroupDataLoaded = config.get("groupIds").isEmpty() ||
        groupsKpiData.every(row => !row.isEmpty() && !row.get("dataByKpiId").isEmpty());
    const isAllUserDataLoaded = config.get("userIds").isEmpty() ||
        usersKpiData.every(row => !row.isEmpty() && !row.get("dataByKpiId").isEmpty());
    const isAllDataLoaded = isAllGroupDataLoaded && isAllUserDataLoaded;

    const isButtonsDisabled = configDoesNotHaveRequiredOptions || isLoadingUsersInGroup || isLoadingData;
    const isLargeScreen = containerWidth >= 1280;
    const buttonsContainerStyle = {
      ...configContainerStyle,
      width: isLargeScreen ? "auto" : "100%",
      paddingLeft: 0,
      paddingRight: 0,
      marginLeft: "0.25rem",
      marginRight: "0.25rem",
      verticalAlign: "top"
    };

    return (
        <div style={buttonsContainerStyle}>
          <TextButton type="secondary" label="Clear" onClick={this.handleClearReportClick} style={buttonStyle} />
          {this.renderDownloadButton(isButtonsDisabled || isSavingReport || !isAllDataLoaded)}
          <TextButton
              label="Save"
              onClick={this.handleSaveReportClick}
              disabled={isButtonsDisabled || isSavingReport}
              style={buttonStyle} />
          <TextButton
              label="Save As"
              onClick={this.openSaveReportAsDialog}
              disabled={isButtonsDisabled || isSavingReport}
              style={buttonStyle} />
          <TextButton
              type="primary"
              label={isLoadingData ? <LoadingProgress percentLoaded={percentLoaded} /> : "Load Data"}
              onClick={() => {
                auditor.audit("onpoint:run-custom-report", {});
                this.loadData();
              }}
              disabled={isButtonsDisabled}
              style={{...buttonStyle, width: isLoadingData ? 150 : "auto"}} />
        </div>
    );
  },

  renderDownloadButton(isDisabled) {
    const buttonLabel = "Download";
    const tooltipLabel = "Ask one of your admin Users for the 'Export To File' permission if you need to download this information";
    if (Users.currentHasPermission("EXPORT_FILE")) {
      return (
          <TextButton
              label={buttonLabel}
              onClick={this.handleDownloadClick}
              disabled={isDisabled}
              style={buttonStyle} />
      );
    } else {
      return (
          <div style={{display: "inline-block"}}>
            <Tooltip text={tooltipLabel} position="top">
              <TextButton
                  label={buttonLabel}
                  onClick={() => popups.alert(tooltipLabel, {title: "Permission required to download"})}
                  disabled={isDisabled}
                  style={{...buttonStyle, opacity: 0.5}} />
            </Tooltip>
          </div>
      );
    }
  },

  handleDataColumnsScroll(target) {
    const scrollTop = target.scrollTop;
    const scrollLeft = target.scrollLeft;
    this.setState({
      scrollTop,
      scrollLeft
    });
  },

  getGroupAndUserColumnWidth() {
    const {groupsKpiData, usersKpiData} = this.state;
    const {theme} = this.props;
    const minWidth = 250;
    const maxWidth = 350;
    const pixelsPerBreadcrumbsChar = 7;
    const pixelsPerNameChar = theme.themeId === "light" ? 11 : 10;
    if (groupsKpiData.isEmpty() && usersKpiData.isEmpty()) {
      return minWidth;
    } else if (this.props.config.get("showBreadcrumbs")) {
      const groupBreadcrumbs = groupsKpiData
          .map(row => {
            const group = Groups.getGroupOrCurrent(row.get("groupId"));
            return group ? group.get("breadcrumbs").join(" > ") : "";
          })
          .toSet();
      const userGroupBreadcrumbs = usersKpiData
          .map(row => {
            const user = Users.getUserOrCurrent(row.get("userId"));
            const group = Groups.getGroupOrCurrent(user.get("groupId"));
            return group ? group.get("breadcrumbs").join(" > ") : "";
          })
          .toSet();
      const allBreadcrumbs = groupBreadcrumbs.union(userGroupBreadcrumbs);
      const longestBreadcrumbsStr = allBreadcrumbs.maxBy(bc => bc.length);
      const longestBreadcrumbsStrWidth = longestBreadcrumbsStr.length * pixelsPerBreadcrumbsChar;

      const groupNames = groupsKpiData
          .map(row => Groups.getGroupOrCurrent(row.get("groupId")).get("name"))
          .toSet();
      const userFullNames = usersKpiData
          .map(row => Users.getUserOrCurrent(row.get("userId")).get("fullName"))
          .toSet();
      const allNames = groupNames.union(userFullNames);
      const longestNameStr = allNames.maxBy(bc => bc.length);
      const longestNameStrWidth = longestNameStr.length * pixelsPerNameChar;
      const longestStrWidth = longestNameStrWidth > longestBreadcrumbsStrWidth ? longestNameStrWidth :
          longestBreadcrumbsStrWidth;
      if (longestStrWidth < minWidth) {
        return minWidth;
      } else if (longestStrWidth > maxWidth) {
        return maxWidth;
      } else {
        return longestStrWidth;
      }
    } else {
      const groupNames = groupsKpiData
          .map(row => Groups.getGroupOrCurrent(row.get("groupId")).get("name"))
          .toSet();
      const userFullNames = usersKpiData
          .map(row => Users.getUserOrCurrent(row.get("userId")).get("fullName"))
          .toSet();
      const allNames = groupNames.union(userFullNames);
      const longestNameStr = allNames.maxBy(bc => bc.length);
      const width = longestNameStr.length * pixelsPerNameChar;
      if (width < minWidth) {
        return minWidth;
      } else if (width > maxWidth) {
        return maxWidth;
      } else {
        return width;
      }
    }
  },

  openSaveReportAsDialog() {
    this.setState({
      isSaveReportAsDialogOpen: true
    });
  },

  closeSaveReportAsDialog() {
    this.setState({
      isSaveReportAsDialogOpen: false
    });
  },

  handleSaveReportClick() {
    const {config, onUpdateReportRequest} = this.props;
    if (config.get("id")) {
      onUpdateReportRequest(config);
    } else {
      this.openSaveReportAsDialog();
    }
  },

  handleSaveReportAsRequest(reportName) {
    this.closeSaveReportAsDialog();
    this.props.onSaveReportRequest(reportName);
  },

  handleDownloadClick() {
    const {groupsKpiData, usersKpiData} = this.state;
    const {config} = this.props;
    const columns = config.get("columnConfigs");
    let headers = Immutable.fromJS(["Groups and Users"]);
    columns.forEach(col => {
      const columnName = col.get("name");
      if (col.get("type") === "KPI") {
        headers = headers
            .push(`${columnName} - total`)
            .push(`${columnName} - target`)
            .push(`${columnName} - % target`)
            .push(`${columnName} - % target expected`);
      } else if (col.get("type") === "RATIO") {
        headers = headers.push(columnName);
      }
    });

    let dataToExport = Immutable.List();
    const showBreadcrumbs = config.get("showBreadcrumbs");
    groupsKpiData.forEach(row => {
      const groupId = row.get("groupId");
      const groupName = Groups.getGroupOrCurrent(groupId).get("name");
      const rowName = showBreadcrumbs ? `${getGroupBreadcrumbsLabel(groupId)}` : groupName;
      const rowData = formatRowDataForCsv(row, columns);
      dataToExport = dataToExport.push(rowData.unshift(rowName));
    });
    usersKpiData.forEach(row => {
      const user = Users.getUserOrCurrent(row.get("userId"));
      const userFullName = user.get("fullName");
      const rowName = showBreadcrumbs ? `${getGroupBreadcrumbsLabel(user.get("groupId"))} > ${userFullName}` :
          userFullName;
      const rowData = formatRowDataForCsv(row, columns);
      dataToExport = dataToExport.push(rowData.unshift(rowName));
    });
    const reportName = config.get("name") === "New OnPoint Report"
        ? `${Branding.brandingName} OnPoint Report`
        : config.get("name");
    const datetime = moment().format("YYYY-MM-DD HH-mm-ss");
    saveAsCsv([headers.toJS()].concat(dataToExport.toJS()), `${reportName} exported on ${datetime}.csv`);
  },

  handleViewTypeChange(isChecked) {
    const {config, onConfigChange} = this.props;
    const newViewType = isChecked ? "DETAILED" : "COMPACT";
    onConfigChange(config.set("viewType", newViewType));
  },

  handleShowBreadcrumbsToggle(showBreadcrumbs) {
    const {groupsKpiData, usersKpiData} = this.state;
    const {config, onConfigChange} = this.props;
    const sortBy = config.get("sortBy");
    if (sortBy.get("columnType") === "GROUPS_AND_USERS") {
      const sortOrder = sortBy.get("sortOrder");
      const {
        sortByGroupBreadcrumbs,
        sortByGroupName,
        sortByUserBreadcrumbs,
        sortByUserFullName
      } = dataSorting;
      const newGroupsKpiData = showBreadcrumbs ?
          sortByGroupBreadcrumbs(groupsKpiData, sortOrder) :
          sortByGroupName(groupsKpiData, sortOrder);
      const newUsersKpiData = showBreadcrumbs ?
          sortByUserBreadcrumbs(usersKpiData, sortOrder) :
          sortByUserFullName(usersKpiData, sortOrder);
      this.setState({
        groupsKpiData: newGroupsKpiData,
        usersKpiData: newUsersKpiData
      });
    }
    onConfigChange(config.set("showBreadcrumbs", showBreadcrumbs));
  },

  handleKpiSelect(kpiId) {
    const {kpisByKpiId} = this.state;
    const {config, onConfigChange} = this.props;
    const kpi = kpisByKpiId.get(kpiId);
    const newColumnConfig = Immutable.fromJS({
      name: kpi.get("name"),
      type: "KPI",
      kpi,
      order: kpi.get("order")
    });
    const newColumnConfigs = config
        .get("columnConfigs")
        .push(newColumnConfig)
        .sortBy(col => col.get("order"));
    onConfigChange(config.set("columnConfigs", newColumnConfigs));
  },

  handleRatioSelect(ratio) {
    const {config, onConfigChange} = this.props;
    const newColumnConfig = Immutable.fromJS({
      name: ratio.get("name"),
      type: "RATIO",
      ratio,
      order: 10000 + ratio.get("order")
    });
    const newColumnConfigs = config
        .get("columnConfigs")
        .push(newColumnConfig)
        .sortBy(col => col.get("order"));
    onConfigChange(config.set("columnConfigs", newColumnConfigs));
  },

  handleRatioDisplayChange(newRatioDisplayConfig) {
    const {config, onConfigChange} = this.props;
    const columnConfigs = config.get("columnConfigs");
    const ratioId = newRatioDisplayConfig.get("id");
    const index = columnConfigs
        .findIndex(col => col.get("type") === "RATIO" && col.getIn(["ratio", "id"]) === ratioId);
    const columnConfig = columnConfigs.get(index);
    const newColumnConfig = columnConfig.set("ratio", newRatioDisplayConfig);
    onConfigChange(config.set("columnConfigs", columnConfigs.set(index, newColumnConfig)));
  },

  handleRatioColumnNameChange(ratioId, columnName) {
    const {config, onConfigChange} = this.props;
    const columnConfigs = config.get("columnConfigs");
    const index = columnConfigs
        .findIndex(col => col.get("type") === "RATIO" && col.getIn(["ratio", "id"]) === ratioId);
    const columnConfig = columnConfigs.get(index);
    const newColumnConfig = columnConfig.set("name", columnName);
    onConfigChange(config.set("columnConfigs", columnConfigs.set(index, newColumnConfig)));
  },

  handleRemoveColumnRequest(colType, id) {
    const removeColumnByType = {
      KPI: this.removeKpiColumn,
      RATIO: this.removeRatioColumn
    };
    const removeColumn = removeColumnByType[colType];
    removeColumn(id);
  },

  removeKpiColumn(kpiId) {
    const {config, onConfigChange} = this.props;
    const columnConfigs = config.get("columnConfigs");
    const index = columnConfigs
        .findIndex(col => col.get("type") === "KPI" && col.getIn(["kpi", "id"]) === kpiId);
    onConfigChange(config.set("columnConfigs", columnConfigs.delete(index)));
  },

  removeRatioColumn(ratioId) {
    const {config, onConfigChange} = this.props;
    const columnConfigs = config.get("columnConfigs");
    const index = columnConfigs
        .findIndex(col => col.get("type") === "RATIO" && col.getIn(["ratio", "id"]) === ratioId);
    onConfigChange(config.set("columnConfigs", config.get("columnConfigs").delete(index)));
  },

  handleColumnsReorder(reorderedColumnConfigs) {
    const {config, onConfigChange} = this.props;
    onConfigChange(config.set("columnConfigs", reorderedColumnConfigs));
  },

  handleSortingChange(columnType, sortOrder, id, sortField) {
    const {config, onConfigChange} = this.props;
    let newSortConfig = Immutable.Map();
    if (columnType === "GROUPS_AND_USERS") {
      newSortConfig = Immutable.fromJS({
        columnType,
        id: null,
        sortField: null,
        sortOrder
      });
    } else if (sortOrder) {
      newSortConfig = Immutable.fromJS({
        columnType,
        id,
        sortField,
        sortOrder
      });
    } else {
      newSortConfig = Immutable.fromJS({
        columnType: "GROUPS_AND_USERS",
        id: null,
        sortField: "GROUPS_AND_USERS",
        sortOrder: "ASC"
      });
    }
    onConfigChange(config.set("sortBy", newSortConfig));
  },

  handleUserSelect(userId) {
    const {config, onConfigChange} = this.props;
    const userIds = config.get("userIds");
    if (!userIds.includes(userId)) {
      const newUserRow = Immutable.fromJS({
        userId,
        dataByKpiId: Immutable.Map()
      });
      let newUsersKpiData = this.state.usersKpiData.push(newUserRow);
      const sortBy = config.get("sortBy");
      const sortOrder = sortBy.get("columnType") === "GROUPS_AND_USERS" ? sortBy.get("sortOrder") : null;
      newUsersKpiData = config.get("showBreadcrumbs") ?
          dataSorting.sortByUserBreadcrumbs(newUsersKpiData, sortOrder) :
          dataSorting.sortByUserFullName(newUsersKpiData, sortOrder);
      this.setState({
        usersKpiData: newUsersKpiData
      });
      onConfigChange(config.set("userIds", newUsersKpiData.map(row => row.get("userId"))));
    }
  },

  handleGroupSelect(groupId) {
    this.setState({selectedGroupId: groupId}, () => {
      if (groupId === 0) {
        this.addSelectedGroup();
      }
    });
  },

  closeGroupSelectionTypeDialog() {
    this.setState({selectedGroupId: null});
  },

  handleGroupSelectionTypeConfirm(selectionType) {
    this.closeGroupSelectionTypeDialog();

    switch (selectionType) {
      case "USERS_IN_GROUP":
        this.addAllUsersInSelectedGroup();
        break;
      case "GROUP":
        this.addSelectedGroup();
        break;
      case "BOTH":
        this.addSelectedGroupAndAllSubGroupsAndUsers();
        break;
      case "BOTH_WITH_DELETED_GROUPS":
        this.addSelectedGroupAndAllSubGroupsAndUsers(true);
        break;
      default:
        throw new Error("Unsupported selectionType:", selectionType);
    }
  },

  addSelectedGroup() {
    const {selectedGroupId, groupsKpiData} = this.state;
    const {config, onConfigChange} = this.props;
    const currentGroupIds = config.get("groupIds").toSet();
    const groupIds = Immutable
        .List()
        .push(selectedGroupId)
        .toSet();
    const newGroupIds = groupIds.subtract(currentGroupIds).toList();
    let newGroupsKpiData = groupsKpiData;
    newGroupIds.forEach(groupId => {
      newGroupsKpiData = newGroupsKpiData.push(getInitialGroupRow(groupId));
    });
    const sortBy = config.get("sortBy");
    const sortOrder = sortBy.get("columnType") === "GROUPS_AND_USERS" ? sortBy.get("sortOrder") : null;
    const sortedGroupsKpiData = config.get("showBreadcrumbs") ?
        dataSorting.sortByGroupBreadcrumbs(newGroupsKpiData, sortOrder) :
        dataSorting.sortByGroupName(newGroupsKpiData, sortOrder);
    this.setState({
      groupsKpiData: sortedGroupsKpiData
    });
    onConfigChange(config.set("groupIds", sortedGroupsKpiData.map(row => row.get("groupId"))));
  },

  addAllUsersInSelectedGroup() {
    this.setState({isLoadingUsersInGroup: true});

    const {selectedGroupId, usersKpiData} = this.state;
    const {config, onConfigChange} = this.props;
    const selectedUserIds = config.get("userIds");
    getActiveUserIdsInGroup(selectedGroupId)
        .then(userIdsInGroup => {
          let newUsersKpiData = usersKpiData;
          const newUserIds = userIdsInGroup
              .toSet()
              .subtract(selectedUserIds.toSet())
              .toList();
          newUserIds.forEach(userId => {
            newUsersKpiData = newUsersKpiData
                .push(Immutable.fromJS({
                  userId,
                  dataByKpiId: Immutable.Map()
                }));
          });
          const sortBy = config.get("sortBy");
          const sortOrder = sortBy.get("columnType") === "GROUPS_AND_USERS" ? sortBy.get("sortOrder") : null;
          const sortedUsersKpiData = config.get("showBreadcrumbs") ?
              dataSorting.sortByUserBreadcrumbs(newUsersKpiData, sortOrder) :
              dataSorting.sortByUserFullName(newUsersKpiData, sortOrder);
          this.setState({
            isLoadingUsersInGroup: false,
            config: config.set("userIds", sortedUsersKpiData.map(row => row.get("userId"))),
            usersKpiData: sortedUsersKpiData
          });
          onConfigChange(config.set("userIds", sortedUsersKpiData.map(row => row.get("userId"))));
        });
  },

  addSelectedGroupAndAllSubGroupsAndUsers(includeDeletedGroups) {
    this.setState({isLoadingUsersInGroup: true});

    const {selectedGroupId, usersKpiData, groupsKpiData} = this.state;
    const {config, onConfigChange} = this.props;
    getActiveUserIdsInGroup(selectedGroupId)
        .then(userIdsInGroup => {
          const newUserIds = userIdsInGroup
              .toSet()
              .subtract(config.get("userIds").toSet())
              .toList();
          let newUsersKpiData = usersKpiData;
          newUserIds.forEach(userId => {
            newUsersKpiData = newUsersKpiData
                .push(Immutable.fromJS({
                  userId,
                  dataByKpiId: Immutable.Map()
                }));
          });
          const sortBy = config.get("sortBy");
          const sortOrder = sortBy.get("columnType") === "GROUPS_AND_USERS" ? sortBy.get("sortOrder") : null;
          const showBreadcrumbs = config.get("showBreadcrumbs");
          const sortedUsersKpiData = showBreadcrumbs ?
              dataSorting.sortByUserBreadcrumbs(newUsersKpiData, sortOrder) :
              dataSorting.sortByUserFullName(newUsersKpiData, sortOrder);
          let newConfig = config.set("userIds", sortedUsersKpiData.map(row => row.get("userId")));
          const currentGroupIds = config.get("groupIds").toSet();
          const groupIds = getSubGroupIds(selectedGroupId, includeDeletedGroups)
              .push(selectedGroupId)
              .toSet();
          const newGroupIds = groupIds.subtract(currentGroupIds).toList();
          let newGroupsKpiData = groupsKpiData;
          newGroupIds.forEach(groupId => {
            newGroupsKpiData = newGroupsKpiData.push(getInitialGroupRow(groupId));
          });
          let sortedGroupsKpiData = showBreadcrumbs ?
              dataSorting.sortByGroupBreadcrumbs(newGroupsKpiData, sortOrder) :
              dataSorting.sortByGroupName(newGroupsKpiData, sortOrder);
          newConfig = newConfig.set("groupIds", sortedGroupsKpiData.map(row => row.get("groupId")));
          this.setState({
            isLoadingUsersInGroup: false,
            groupsKpiData: sortedGroupsKpiData,
            usersKpiData: sortedUsersKpiData
          });
          onConfigChange(newConfig);
        });
  },

  handleRemoveUserRequest(userId) {
    const {usersKpiData} = this.state;
    const {config, onConfigChange} = this.props;
    const userIds = config.get("userIds");
    this.setState({
      usersKpiData: usersKpiData.delete(usersKpiData.findIndex(row => row.get("userId") === userId))
    });
    onConfigChange(config.set("userIds", userIds.delete(userIds.findIndex(id => id === userId))));
  },

  handleRemoveGroupRequest(groupId) {
    const {groupsKpiData} = this.state;
    const {config, onConfigChange} = this.props;
    const groupIds = config.get("groupIds");
    this.setState({
      groupsKpiData: groupsKpiData.delete(groupsKpiData.findIndex(row => row.get("groupId") === groupId))
    });
    onConfigChange(config.set("groupIds", groupIds.delete(groupIds.findIndex(id => id === groupId))));
  },

  loadData() {
    const {groupsKpiData, usersKpiData} = this.state;
    const {config} = this.props;

    auditLoadData(config);

    currentLoadId++;
    loadIdToCancelled = loadIdToCancelled.set(currentLoadId, false);

    let kpiIdsToLoad = Immutable.List();
    config
        .get("columnConfigs")
        .forEach(col => {
          if (col.get("type") === "KPI") {
            kpiIdsToLoad = kpiIdsToLoad.push(col.getIn(["kpi", "id"]));
          } else if (col.get("type") === "RATIO") {
            const firstRatioKpiId = col.getIn(["ratio", "firstKpiId"]);
            const secondRatioKpiId = col.getIn(["ratio", "secondKpiId"]);
            if (!kpiIdsToLoad.includes(firstRatioKpiId)) {
              kpiIdsToLoad = kpiIdsToLoad.push(firstRatioKpiId);
            }
            if (!kpiIdsToLoad.includes(secondRatioKpiId)) {
              kpiIdsToLoad = kpiIdsToLoad.push(secondRatioKpiId);
            }
          }
        });

    let allGroupIdsKpiIdsToLoad = Immutable.List();
    const newGroupsKpiData = groupsKpiData.map(groupRow => {
      const groupId = groupRow.get("groupId");
      let dataByKpiId = Immutable.Map();
      kpiIdsToLoad
          .forEach(kpiId => {
            const cellCoords = Immutable.Map({groupId, kpiId});
            allGroupIdsKpiIdsToLoad = allGroupIdsKpiIdsToLoad.push(cellCoords);

            dataByKpiId = dataByKpiId.set(kpiId, Immutable.Map({isLoading: true}));
          });
      return groupRow.set("dataByKpiId", dataByKpiId);
    });
    let allUserIdsKpiIdsToLoad = Immutable.List();
    const newUsersKpiData = usersKpiData.map(userRow => {
      const userId = userRow.get("userId");
      let dataByKpiId = Immutable.Map();
      kpiIdsToLoad
          .forEach(kpiId => {
            const cellCoords = Immutable.Map({userId, kpiId});
            allUserIdsKpiIdsToLoad = allUserIdsKpiIdsToLoad.push(cellCoords);

            dataByKpiId = dataByKpiId.set(kpiId, Immutable.Map({isLoading: true}));
          });
      return userRow.set("dataByKpiId", dataByKpiId);
    });

    this.setState({
      groupsKpiData: newGroupsKpiData,
      usersKpiData: newUsersKpiData
    });
    const promisesWithGroupIdsKpiIds = loadKpiDataForGroups(
        currentLoadId,
        allGroupIdsKpiIdsToLoad,
        config.get("timeframe"));
    this.updateDataAsEachGroupKpiLoads(promisesWithGroupIdsKpiIds);

    const promisesWithUserIdsKpiIds = loadKpiDataForUsers(
        currentLoadId,
        allUserIdsKpiIdsToLoad,
        config.get("timeframe"));
    this.updateDataAsEachUserKpiLoads(promisesWithUserIdsKpiIds);
  },

  updateDataAsEachGroupKpiLoads(promisesWithGroupIdsKpiIds) {
    promisesWithGroupIdsKpiIds.forEach(({promise, kpiId, groupId}) => {
      promise
          .then(response => {
            const data = Immutable.fromJS({
              isLoading: false,
              ...response
            });
            const index = this.state.groupsKpiData.findIndex(row => row.get("groupId") === groupId);
            const groupKpiData = this.state.groupsKpiData.get(index);
            const updatedGroupKpiData = groupKpiData.setIn(["dataByKpiId", kpiId], data);
            this.setState({
              groupsKpiData: this.state.groupsKpiData.set(index, updatedGroupKpiData)
            });
          }, reason => {
            const index = this.state.groupsKpiData.findIndex(row => row.get("groupId") === groupId);
            const groupKpiData = this.state.groupsKpiData.get(index);
            if (reason.isCanceled) {
              this.setState({
                groupsKpiData: this.state.groupsKpiData.set(index, groupKpiData.setIn(["dataByKpiId", kpiId], null))
              });
              return;
            }
            const error = Immutable.fromJS({
              isLoading: false,
              error: true
            });
            this.setState({
              groupsKpiData: this.state.groupsKpiData.set(index, groupKpiData.setIn(["dataByKpiId", kpiId], error))
            });
          });
    });
  },

  updateDataAsEachUserKpiLoads(promisesWithUserIdsKpiIds) {
    promisesWithUserIdsKpiIds.forEach(({promise, kpiId, userId}) => {
      promise
          .then(response => {
            const data = Immutable.fromJS({
              isLoading: false,
              ...response
            });
            const index = this.state.usersKpiData.findIndex(row => row.get("userId") === userId);
            const userKpiData = this.state.usersKpiData.get(index);
            const updatedUserKpiData = userKpiData.setIn(["dataByKpiId", kpiId], data);
            this.setState({
              usersKpiData: this.state.usersKpiData.set(index, updatedUserKpiData)
            });
          }, reason => {
            const index = this.state.usersKpiData.findIndex(row => row.get("userId") === userId);
            const userKpiData = this.state.usersKpiData.get(index);
            if (reason.isCanceled) {
              this.setState({
                usersKpiData: this.state.usersKpiData.set(index, userKpiData.setIn(["dataByKpiId", kpiId], null))
              });
              return;
            }
            const error = Immutable.fromJS({
              isLoading: false,
              error: true
            });
            this.setState({
              usersKpiData: this.state.usersKpiData.set(index, userKpiData.setIn(["dataByKpiId", kpiId], error))
            });
          });
    });
  },

  sortGroupsKpiData() {
    const {config} = this.props;
    const {groupsKpiData} = this.state;
    if (groupsKpiData.isEmpty()) {
      return groupsKpiData;
    } else {
      const sortBy = config.get("sortBy");
      const sortOrder = sortBy.get("sortOrder");
      if (sortBy.get("columnType") === "GROUPS_AND_USERS") {
        return config.get("showBreadcrumbs") ?
            dataSorting.sortByGroupBreadcrumbs(groupsKpiData, sortOrder) :
            dataSorting.sortByGroupName(groupsKpiData, sortOrder);
      } else if (sortBy.get("columnType") === "RATIO") {
        const ratioColumnConfig = config
            .get("columnConfigs")
            .find(col => col.get("type") === "RATIO" && col.getIn(["ratio", "id"]) === sortBy.get("id"));
        const ratio = ratioColumnConfig.get("ratio");
        return dataSorting.sortByRatioValue(groupsKpiData, ratio, sortOrder);
      } else {
        return sortKpiData(groupsKpiData, sortBy);
      }
    }
  },

  sortUsersKpiData() {
    const {config} = this.props;
    const {usersKpiData} = this.state;
    if (usersKpiData.isEmpty()) {
      return usersKpiData;
    } else {
      const sortBy = config.get("sortBy");
      const sortOrder = sortBy.get("sortOrder");
      if (sortBy.get("columnType") === "GROUPS_AND_USERS") {
        return config.get("showBreadcrumbs") ?
            dataSorting.sortByUserBreadcrumbs(usersKpiData, sortOrder) :
            dataSorting.sortByUserFullName(usersKpiData, sortOrder);
      } else if (sortBy.get("columnType") === "RATIO") {
        const ratioColumnConfig = config
            .get("columnConfigs")
            .find(col => col.get("type") === "RATIO" && col.getIn(["ratio", "id"]) === sortBy.get("id"));
        const ratio = ratioColumnConfig.get("ratio");
        return dataSorting.sortByRatioValue(usersKpiData, ratio, sortOrder);
      } else {
        return sortKpiData(usersKpiData, sortBy);
      }
    }
  },

  handleClearReportClick() {
    this.cancelPendingPromises();
    this.props.onClearReportRequest();
  },

  cancelPendingPromises() {
    loadIdToCancelled = loadIdToCancelled.set(currentLoadId, true);
  },

  componentWillUnmount() {
    this.cancelPendingPromises();
  }

});

const auditLoadData = (config) => {
  const auditKpis = config.get("columnConfigs")
      .filter(columnConfig => columnConfig.get("type") === "KPI")
      .slice(0, 10)
      .map(columnConfig => {
        return {id: columnConfig.get("kpi").get("id"), name: columnConfig.get("kpi").get("name")};
      });
  const auditRatios = config.get("columnConfigs")
      .filter(columnConfig => columnConfig.get("type") === "RATIO")
      .slice(0, 10)
      .map(columnConfig => {
        return {id: columnConfig.get("ratio").get("id"), name: columnConfig.get("ratio").get("name")};
      });
  const auditUsers = config.get("userIds")
      .slice(0, 10)
      .map(userId => {
        return {userId, fullName: Users.getUserOrCurrent(userId).get("fullName")};
      });
  const auditGroups = config.get("groupIds")
      .slice(0, 10)
      .map(groupId => {
        return {groupId, groupName: Groups.getGroupOrCurrent(groupId).get("name")};
      });
  const notAuditedKpis = config.get("columnConfigs").filter(columnConfig => columnConfig.get("type") === "KPI")
      .slice(10);
  const notAuditedRatios = config.get("columnConfigs").filter(columnConfig => columnConfig.get("type") === "RATIO")
      .slice(10);
  const notAuditedUsers = config.get("userIds").slice(10);
  const notAuditedGroups = config.get("groupIds").slice(10);
  const notAuditedCounts = {
    kpis: notAuditedKpis.count(),
    ratios: notAuditedRatios.count(),
    users: notAuditedUsers.count(),
    groups: notAuditedGroups.count()
  };
  auditor.audit("onpoint:load-data", {
    kpis: auditKpis,
    ratios: auditRatios,
    users: auditUsers,
    groups: auditGroups,
    notAuditedCounts,
    timeframe: config.get("timeframe")
  });
};

const LoadingProgress = ({percentLoaded}) => (
    <div>
      <span>{`${numbers.toPercentageStr(percentLoaded)} Loaded `}</span>
      <i className="fa fa-circle-o-notch fa-spin fa-fw" />
    </div>
);

const sortKpiData = (data, sortBy) => {
  if (!sortBy || sortBy.get("columnType") !== "KPI") {
    return data;
  }

  const sortByKpiId = sortBy.get("id");
  const sortField = sortBy.get("sortField");
  const sortOrder = sortBy.get("sortOrder");
  if (sortField === "TARGET_PERCENT") {
    return dataSorting.sortByKpiTargetPercentage(data, sortByKpiId, sortOrder);
  }
  if (sortField === "TARGET") {
    return dataSorting.sortByKpiTargetValue(data, sortByKpiId, sortOrder);
  }
  if (sortField === "TOTAL") {
    return dataSorting.sortByKpiTotalValue(data, sortByKpiId, sortOrder);
  }
};

const getKpis = () => Immutable
    .fromJS(kpiRepo.getAll().toJSON())
    .filter(kpi => kpi.get("visible"));

const getRatios = () => ratioRepo.getAll();

const getHierarchy = () => Immutable.fromJS(Groups.getHierarchyWithUsers());

const getActiveUserIdsInGroup = groupId => Groups.loadUsersInGroup(groupId)
    .then(users => users
        .filter(user => user.get("state") === "VISIBLE")
        .sortBy(user => user.get("fullName").toLowerCase())
        .map(user => user.get("id")));

const getSubGroupIds = (groupId, includeDeletedGroups) => Immutable
    .fromJS(Groups.getChildGroups(groupId))
    .filter(group => includeDeletedGroups || !group.get("deleted"))
    .map(grp => grp.get("id"));
const getInitialGroupRow = groupId => Immutable.fromJS({
  groupId,
  dataByKpiId: Immutable.Map()
});

const loadKpiDataForGroups = (loadId, allGroupIdsKpiIdsToLoad, timeframe) => allGroupIdsKpiIdsToLoad
    .map(groupIdKpiIdMap => {
      const kpiId = groupIdKpiIdMap.get("kpiId");
      const groupId = groupIdKpiIdMap.get("groupId");
      const groupIdOrCurrent = groupIdKpiIdMap.get("groupId") === 0 ? Users.getCurrentUser().get("groupId") :
          groupIdKpiIdMap.get("groupId");
      const kpiOptions = {
        timeframe: getTimeframeModel(timeframe),
        groupId: groupIdOrCurrent
      };
      return {
        kpiId,
        groupId,
        promise: limit(() => {
          if (loadIdToCancelled.get(loadId)) {
            return Promise.reject({isCanceled: true});
          } else {
            return delay(
                kpiCalculator.summary(kpiId, kpiOptions),
                delayInMillis
            );
          }
        })
      };
    });
const loadKpiDataForUsers = (loadId, allUserIdsKpiIdsToLoad, timeframe) => allUserIdsKpiIdsToLoad
    .map(userIdKpiIdMap => {
      const kpiId = userIdKpiIdMap.get("kpiId");
      const userId = userIdKpiIdMap.get("userId");
      const user = Users.getUserOrCurrent(userId);
      const kpiOptions = {
        timeframe: getTimeframeModel(timeframe),
        userId: user.get("id"),
        groupId: user.get("groupId")
      };
      return {
        kpiId,
        userId,
        promise: limit(() => {
          if (loadIdToCancelled.get(loadId)) {
            return Promise.reject({isCanceled: true});
          } else {
            return delay(
                kpiCalculator.summary(kpiId, kpiOptions),
                delayInMillis
            );
          }
        })
      };
    });
const getTimeframeModel = timeframe => timeframeRepo.parse(timeframe.toJS());
const delay = (pData, delayInMillis) => new Promise((resolve, reject) => {
  setTimeout(() => resolve(pData), delayInMillis);
});

const getGroupBreadcrumbsLabel = groupId => {
  const group = Groups.getGroupOrCurrent(groupId);
  if (group) {
    const isRootGroup = !group.get("parentId");
    if (isRootGroup) {
      return group.get("name");
    } else {
      const breadcrumbs = Immutable.fromJS(group.get("breadcrumbs"));
      return breadcrumbs.shift().join(" > ");
    }
  } else {
    return "[orphan user]";
  }
};

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  const [ref, {width, height}] = useDimensions();
  return <ConfigurableReport
      dimensionRef={ref}
      containerWidth={width}
      containerHeight={height}
      theme={theme} {...props} />;
};

export default Wrapper;
