import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import CreateTarget from "js/admin/targets/create-target";
import CurrentTargets from "js/admin/targets/current-targets";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import {TextButton} from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";
import RadioButton from "js/common/views/inputs/radio-button";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as ajax from "js/common/ajax";

import currentClient from "js/common/repo/backbone/current-client";

import {RadioGroup as RadioButtonGroup} from "@mui/material";
import Checkbox from "js/common/views/inputs/checkbox";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants";

const path = window.path;

const mySqlDateFormat = "YYYY-MM-DD";

const contactSupport = `An unexpected error has occurred. ${Branding.submitTicketString}`;

const OldTargetApp = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      kpis: getTargetableKpis(),
      groups: Immutable.fromJS([Groups.getHierarchy()]),
      users: Immutable.List(),
      isLoadingUsers: false,

      newTarget: Immutable.fromJS({
        kpiId: null,
        qualifierType: "USER",
        qualifierIds: Immutable.Set(),
        period: currentClient.hasPermission("USES_445_CALENDAR") ? "WEEKLY" : "MONTHLY",
        targetStart: null,
        priority: false,
        value: "",
        valueType: "numerical"
      }),
      isCreatingNewTarget: false,
      newTargetErrorsByProp: Immutable.Map(),
      createNewTargetSuccess: null,

      currentTargetsKpiId: null,
      isLoadingCurrentTargets: false,
      currentTargetsById: null,
      currentlyEditingTarget: null,
      isCurrentlyEditingTargetChanged: false,
      targetIdToDelete: null,
      currentTargetsError: null,
      currentTargetsSuccess: null
    };
  },

  componentDidMount() {
    this.setState({
      isLoadingUsers: true
    });
    Users.load().then(users => {
      const activeUsers = users.filter(Users.isActive);
      const currentUser = Users.getCurrentUser();
      if (currentUser.get("dataVisibility") === "SELF") {
        this.setState({
          users: activeUsers.filter(u => u.get("id") === currentUser.get("id")),
          isLoadingUsers: false
        });
      } else {
        this.setState({
          users: activeUsers.filter(u => !u.get("cube19User")),
          isLoadingUsers: false
        });
      }
    });
  },


  render() {
    const {
      kpis,
      groups,
      users,
      isLoadingUsers,
      newTarget,
      isCreatingNewTarget,
      newTargetErrorsByProp,
      createNewTargetSuccess,
      currentTargetsKpiId,
      isLoadingCurrentTargets,
      currentTargetsById,
      currentlyEditingTarget,
      isCurrentlyEditingTargetChanged,
      targetIdToDelete,
      currentTargetsError,
      currentTargetsSuccess
    } = this.state;

    const {theme} = this.props;
    return (
        <div>
          <CreateTarget
              kpis={kpis}
              groups={groups}
              users={users}
              isLoadingUsers={isLoadingUsers}
              target={newTarget}
              isInitialState={Immutable.is(newTarget, this.getInitialState().newTarget)}
              onKpiIdChange={this.handleNewTargetKpiIdChange}
              onPriorityChange={this.handleNewTargetPriorityChange}
              onPeriodChange={this.handleNewTargetPeriodChange}
              onStartDateChange={this.handleNewTargetStartDateChange}
              onValueChange={this.handleNewTargetValueChange}
              onCurrencyChange={this.handleNewTargetCurrencyChange}
              onQualifierTypeChange={this.handleNewTargetQualifierTypeChange}
              onSelectUsersToggleValueChange={this.handleNewTargetSelectUsersToggleValueChange}
              onQualifierIdsChange={this.handleNewTargetQualifierIdsChange}
              onCancelChangesRequest={this.handleCancelNewTargetChangesRequest}
              onCreateTargetRequest={this.handleCreateNewTargetRequest}
              isCreatingNewTarget={isCreatingNewTarget}
              errorsByProp={newTargetErrorsByProp}
              success={createNewTargetSuccess}
              hasRequiredFields={newTarget.get("kpiId") && !newTarget.get("qualifierIds").isEmpty() && newTarget.has(
                  "targetStart")}
              onSuccessMessageTimeout={() => this.setState({createNewTargetSuccess: null})} />
          <CurrentTargets
              kpis={kpis}
              onKpiSelect={this.handleCurrentTargetsKpiSelect}
              kpiId={currentTargetsKpiId}
              isLoading={isLoadingCurrentTargets && !currentlyEditingTarget && !targetIdToDelete}
              currentTargetsById={currentTargetsById}
              onEditTargetRequest={this.handleEditTargetRequest}
              onRemoveTargetRequest={this.handleRemoveTargetRequest} />
          {currentlyEditingTarget &&
              <EditCurrentTargetDialog
                  theme={theme}
                  target={currentlyEditingTarget}
                  onChange={this.handleCurrentTargetChange}
                  onCloseRequest={this.closeEditCurrentTargetDialog}
                  onSaveTargetChangesRequest={this.saveCurrentTargetChanges}
                  hasChanges={isCurrentlyEditingTargetChanged}
                  isSavingChanges={isLoadingCurrentTargets}
                  error={currentTargetsError}
                  success={currentTargetsSuccess}
                  onSuccessMessageTimeout={this.closeEditCurrentTargetDialog} />}
          {targetIdToDelete &&
              <ConfirmRemoveCurrentTargetDialog
                  theme={theme}
                  target={currentTargetsById.get(targetIdToDelete)}
                  onCloseRequest={this.closeConfirmRemoveCurrentTargetDialog}
                  onConfirmRequest={this.removeTarget}
                  isRemovingTarget={isLoadingCurrentTargets}
                  error={currentTargetsError}
                  success={currentTargetsSuccess}
                  onSuccessMessageTimeout={this.closeConfirmRemoveCurrentTargetDialog} />}
        </div>
    );
  },

  handleCurrentTargetsKpiSelect(kpiId) {
    if (kpiId) {
      this.setState({
        currentTargetsKpiId: kpiId,
        isLoadingCurrentTargets: true
      });
      loadCurrentTargetsForKpi(kpiId)
          .then(currentTargetsById => {
            this.setState({
              currentTargetsById,
              isLoadingCurrentTargets: false
            });
          });
    } else {
      this.setState({
        currentTargetsKpiId: null
      });
    }
  },

  handleEditTargetRequest(target) {
    this.setState({
      currentlyEditingTarget: target
    });
  },

  closeEditCurrentTargetDialog() {
    this.setState({
      currentlyEditingTarget: null,
      isCurrentlyEditingTargetChanged: false,
      currentTargetsError: null,
      currentTargetsSuccess: null
    });
  },

  handleRemoveTargetRequest(targetId) {
    this.setState({
      targetIdToDelete: targetId
    });
  },

  closeConfirmRemoveCurrentTargetDialog() {
    this.setState({
      targetIdToDelete: null,
      currentTargetsError: null,
      currentTargetsSuccess: null
    });
  },

  handleNewTargetKpiIdChange(kpiId) {
    const {newTarget} = this.state;
    let updatedNewTarget = newTarget.set("kpiId", kpiId);
    const kpi = kpiRepo.get(kpiId);
    const isFinancialKpi = kpi.get("type").valueFormat === "CURRENCY";
    if (isFinancialKpi) {
      updatedNewTarget = updatedNewTarget.set("valueType", "monetary");
      if (!newTarget.has("currency")) {
        const rootGroup = Groups.getRootGroup();
        updatedNewTarget = updatedNewTarget.set("currency", rootGroup.get("currencyCode"));
      }
    } else {
      updatedNewTarget = updatedNewTarget
          .set("valueType", "numerical")
          .delete("currency");
    }
    this.setState({
      newTarget: updatedNewTarget,
      newTargetErrorsByProp: this.state.newTargetErrorsByProp.delete("create-target-failed").delete("kpiId")
    });
  },

  handleNewTargetPriorityChange(isPriority) {
    this.setState({
      newTarget: this.state.newTarget.set("priority", isPriority),
      newTargetErrorsByProp: this.state.newTargetErrorsByProp.delete("create-target-failed")
    });
  },

  handleNewTargetPeriodChange(selectedPeriod) {
    this.setState({
      newTarget: this.state.newTarget.set("period", selectedPeriod),
      newTargetErrorsByProp: this.state.newTargetErrorsByProp.delete("create-target-failed")
    });
  },

  handleNewTargetStartDateChange(startDate) {
    const {newTarget, newTargetErrorsByProp} = this.state;
    const updatedNewTarget = newTarget.set("targetStart", startDate);
    const updatedNewTargetErrorsByProp = newTargetErrorsByProp.delete("create-target-failed");
    if (!startDate || !startDate.isValid()) {
      const errorMsg = `Target start date must be in the format ${moment.localeData().longDateFormat("L")}`;

      this.setState({
        newTarget: updatedNewTarget,
        newTargetErrorsByProp: updatedNewTargetErrorsByProp.set("targetStart", errorMsg)
      });
    } else {
      this.setState({
        newTarget: updatedNewTarget,
        newTargetErrorsByProp: updatedNewTargetErrorsByProp.delete("targetStart")
      });
    }
  },

  handleNewTargetValueChange(value) {
    const {newTarget, newTargetErrorsByProp} = this.state;
    const updatedNewTargetErrorsByProp = newTargetErrorsByProp.delete("create-target-failed");
    const updatedNewTarget = newTarget.set("value", value);
    const targetFieldErrMsg = this.isTargetValueValid(value);
    if (targetFieldErrMsg) {
      this.setState({
        newTarget: updatedNewTarget,
        newTargetErrorsByProp: updatedNewTargetErrorsByProp.set("value", targetFieldErrMsg)
      });
    } else {
      this.setState({
        newTarget: updatedNewTarget,
        newTargetErrorsByProp: updatedNewTargetErrorsByProp.delete("value")
      });
    }
  },

  handleNewTargetCurrencyChange(currency) {
    const {newTarget, newTargetErrorsByProp} = this.state;
    this.setState({
      newTarget: newTarget.set("currency", currency),
      newTargetErrorsByProp: newTargetErrorsByProp.delete("create-target-failed")
    });
  },

  handleNewTargetQualifierTypeChange(qualifierType) {
    const {newTarget, newTargetErrorsByProp} = this.state;
    const updatedNewTargetErrorsByProp = newTargetErrorsByProp.delete("create-target-failed");
    const qualifierIds = newTarget.get("qualifierIds");
    const updatedNewTarget = newTarget
        .set("qualifierType", qualifierType)
        .set("qualifierIds", qualifierIds.clear())
        .set("selectUsersToggleValue", null);
    this.setState({
      newTarget: updatedNewTarget,
      newTargetErrorsByProp: updatedNewTargetErrorsByProp.delete("qualifierIds")
    });
  },

  handleNewTargetQualifierIdsChange(qualifierIds) {
    const {newTarget, newTargetErrorsByProp} = this.state;
    const updatedNewTargetErrorsByProp = newTargetErrorsByProp.delete("create-target-failed");
    const updatedNewTarget = newTarget.set("qualifierIds", qualifierIds);
    if (qualifierIds.isEmpty()) {
      const errorMsg = `Please select at least 1 ${newTarget.get("qualifierType").toLowerCase()}`;
      this.setState({
        newTarget: updatedNewTarget,
        newTargetErrorsByProp: updatedNewTargetErrorsByProp.set("qualifierIds", errorMsg)
      });
    } else {
      this.setState({
        newTarget: updatedNewTarget,
        newTargetErrorsByProp: updatedNewTargetErrorsByProp.delete("qualifierIds")
      });
    }
  },

  handleNewTargetSelectUsersToggleValueChange(selectedValue) {
    const {users, newTarget, newTargetErrorsByProp} = this.state;
    const updatedNewTarget = newTarget.set("selectUsersToggleValue", selectedValue);
    if (selectedValue === "ALL") {
      this.setState({
        newTarget: updatedNewTarget.set("qualifierIds", users.map(u => u.get("id")).toSet()),
        newTargetErrorsByProp: newTargetErrorsByProp.delete("create-target-failed")
      });
    } else if (selectedValue === "NONE") {
      const qualifierIds = newTarget.get("qualifierIds");
      this.setState({
        newTarget: updatedNewTarget.set("qualifierIds", qualifierIds.clear()),
        newTargetErrorsByProp: newTargetErrorsByProp.delete("create-target-failed")
      });
    }
  },

  handleCancelNewTargetChangesRequest() {
    this.setState({
      newTarget: this.getInitialState().newTarget,
      newTargetErrorsByProp: this.state.newTargetErrorsByProp.clear()
    });
  },

  handleCreateNewTargetRequest() {
    const errorsFound = validateNewTarget(this.state.newTarget);
    this.setState({
      newTargetErrorsByProp: errorsFound
    });
    if (errorsFound.isEmpty()) {
      this.createNewTarget();
    }
  },

  createNewTarget() {
    const {newTarget, newTargetErrorsByProp, currentTargetsKpiId} = this.state;
    this.setState({
      isCreatingNewTarget: true
    });
    ajax.post({
      url: "kpi/target?source=Target Admin",
      json: getNewTargetJson(newTarget)
    }).then(() => {
      const kpi = kpiRepo.get(newTarget.get("kpiId"));
      const successMsg = `New ${kpi.get("name")} target added`;
      const initialNewTarget = this.getInitialState().newTarget;
      if (currentTargetsKpiId === newTarget.get("kpiId")) {
        loadCurrentTargetsForKpi(currentTargetsKpiId)
            .then(currentTargetsById => {
              this.setState({
                newTargetErrorsByProp: Immutable.Map(),
                isCreatingNewTarget: false,
                newTarget: initialNewTarget,
                createNewTargetSuccess: successMsg,
                currentTargetsById
              });
            });
      } else {
        this.setState({
          isCreatingNewTarget: false,
          newTarget: initialNewTarget,
          createNewTargetSuccess: successMsg
        });
      }
    }, error => {
      if (error.status === 400 && error.responseJSON && error.responseJSON.type === "INVALID_TARGET") {
        const message = error.responseJSON.message;
        this.setState({
          isCreatingNewTarget: false,
          newTargetErrorsByProp: newTargetErrorsByProp.set("create-target-failed", message)
        });
      } else {
        this.setState({
          isCreatingNewTarget: false,
          newTargetErrorsByProp: newTargetErrorsByProp.set("create-target-failed", contactSupport)
        });
      }
    });
  },

  handleCurrentTargetChange(updatedCurrentTarget) {
    const hasCurrentTargetChanged = !Immutable.is(updatedCurrentTarget, this.state.currentlyEditingTarget);
    const targetStartDate = moment(updatedCurrentTarget.get("targetStart"), mySqlDateFormat);
    const targetEndDate = moment(updatedCurrentTarget.get("targetEnd"), mySqlDateFormat);
    const targetValue = updatedCurrentTarget.get("value").get("value");
    const targetFieldErrMsg = this.isTargetValueValid(targetValue);
    if (!targetEndDate || !targetEndDate.isValid()) {
      this.setState({
        currentTargetsError: `Please enter a valid target end date (format: ${moment.localeData().longDateFormat("L")})`
      });
    } else if (targetEndDate.isBefore(targetStartDate)) {
      this.setState({
        currentTargetsError: "Target end date cannot be before the start date"
      });
    } else if (targetFieldErrMsg) {
      this.setState({
        currentTargetsError: targetFieldErrMsg
      });
    } else {
      this.setState({
        currentTargetsError: null
      });
    }
    this.setState({
      currentlyEditingTarget: updatedCurrentTarget,
      isCurrentlyEditingTargetChanged: hasCurrentTargetChanged
    });
  },

  isTargetValueValid(value) {
    const minValue = 0;
    const maxValue = 1000000000000000;
    return value.length < 1 || isNaN(value) || value < minValue || value > maxValue
        ?`Please enter a numerical value between ${minValue} and ${maxValue}`
        : null;
  },

  saveCurrentTargetChanges() {
    this.setState({
      isLoadingCurrentTargets: true
    });
    const {currentTargetsById, currentlyEditingTarget, currentTargetsKpiId} = this.state;
    const newTargetValueMap = currentlyEditingTarget.get("value");
    const json = currentlyEditingTarget
        .set("value", newTargetValueMap.set("value", newTargetValueMap.get("value") + ""))
        .toJS();
    const targetId = currentlyEditingTarget.get("id");
    ajax.put({
      url: path(`kpi/${currentTargetsKpiId}/targets`, targetId) + "?source=Target Admin",
      json
    }).then(() => {
      this.setState({
        isLoadingCurrentTargets: false,
        isCurrentlyEditingTargetChanged: false,
        currentTargetsById: currentTargetsById.set(targetId, currentlyEditingTarget),
        currentTargetsSuccess: "Target updated"
      });
    }, error => {
      if (error.status === 400 && error.responseJSON && error.responseJSON.type === "INVALID_TARGET") {
        const message = error.responseJSON.message;
        this.setState({
          isLoadingCurrentTargets: false,
          currentTargetsError: message
        });
      } else {
        this.setState({
          isLoadingCurrentTargets: false,
          currentTargetsError: contactSupport
        });
      }
    });
  },

  removeTarget() {
    const {currentTargetsById, currentTargetsKpiId, targetIdToDelete} = this.state;
    const url = path(`kpi/${currentTargetsKpiId}/targets`, targetIdToDelete) + "?source=Target Admin";
    this.setState({
      isLoadingCurrentTargets: true
    });
    ajax.del({url})
        .then(() => {
          const target = currentTargetsById.get(targetIdToDelete);

          const deletedTarget = target.set("deleted", true);
          this.setState({
            isLoadingCurrentTargets: false,
            currentTargetsById: currentTargetsById.set(targetIdToDelete, deletedTarget),
            currentTargetsSuccess: "Target removed"
          });
        }, () => {
          this.setState({
            isLoadingCurrentTargets: false,
            currentTargetsError: contactSupport
          });
        });
  }

});

const validateNewTarget = newTarget => {
  const targetValue = newTarget.get("value");
  const targetStartDate = newTarget.get("targetStart");
  const minValue = 0;
  const maxValue = 1000000000000000;
  const errorMsgByProp = Immutable.fromJS({
    kpiId: "Please select a Metric",
    targetStart: `Target start date must be in the format ${moment.localeData().longDateFormat("L")}`,
    value: `Please enter a numerical value between ${minValue} and ${maxValue}`,
    qualifierIds: `Please select at least 1 ${newTarget.get("qualifierType").toLowerCase()}`
  });
  let errorsFound = Immutable.Map();
  if (!newTarget.get("kpiId")) {
    errorsFound = errorsFound.set("kpiId", errorMsgByProp.get("kpiId"));
  } else {
    errorsFound = errorsFound.delete("kpiId");
  }
  if (!targetStartDate || !targetStartDate.isValid()) {
    errorsFound = errorsFound.set("targetStart", errorMsgByProp.get("targetStart"));
  } else {
    errorsFound = errorsFound.delete("targetStart");
  }
  if (targetValue.length < 1 || isNaN(targetValue) || targetValue < minValue || targetValue > maxValue) {
    errorsFound = errorsFound.set("value", errorMsgByProp.get("value"));
  } else {
    errorsFound = errorsFound.delete("value");
  }
  if (newTarget.get("qualifierIds").isEmpty()) {
    errorsFound = errorsFound.set("qualifierIds", errorMsgByProp.get("qualifierIds"));
  } else {
    errorsFound = errorsFound.delete("qualifierIds");
  }
  return errorsFound;
};

const getNewTargetJson = newTarget => {
  const targetStartDate = formatDate(newTarget.get("targetStart"), mySqlDateFormat);
  const targetValueType = newTarget.get("valueType");
  const targetValueObj = {
    type: newTarget.get("valueType"),
    value: newTarget.get("value")
  };
  if (targetValueType === "monetary") {
    targetValueObj.currency = newTarget.get("currency");
  }
  return newTarget
      .set("targetStart", targetStartDate)
      .set("targetQualifierIds", newTarget.get("qualifierIds"))
      .delete("qualifierIds")
      .delete("selectUsersToggleValue")
      .set("value", targetValueObj)
      .delete("valueType")
      .delete("currency")
      .toJS();
};

const EditCurrentTargetDialog = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {
      target,
      onSaveTargetChangesRequest,
      onCloseRequest,
      hasChanges,
      isSavingChanges,
      error,
      success,
      onSuccessMessageTimeout,
      theme
    } = this.props;
    const actions = [
      <TextButton
          key="cancel-edit"
          label={success ? "Close" : "Cancel"}
          icon={success ? "" : "history"}
          onClick={onCloseRequest}
          disabled={isSavingChanges}
          style={buttonSpacing} />,
      <TextButton
          key="confirm-edit"
          type="primary"
          label={isSavingChanges ? "Saving..." : "Save"}
          icon="floppy-o"
          onClick={onSaveTargetChangesRequest}
          disabled={!hasChanges || isSavingChanges || !!error || !!success}
          style={buttonSpacing} />
    ];
    const kpi = kpiRepo.get(target.get("kpiId"));
    const getNameFnByQualifierType = {
      GROUP: getGroupName,
      USER: getUserName
    };
    const getName = getNameFnByQualifierType[target.get("qualifierType")];
    const groupOrUserName = getName(target.get("qualifierId"));
    const targetStart = target.get("targetStart");
    const targetStartDate = moment(targetStart, mySqlDateFormat);
    const targetEnd = target.get("targetEnd");
    const targetEndDate = moment(targetEnd, mySqlDateFormat);
    const targetValueLabelByPeriod = {
      WEEKLY: "per week",
      MONTHLY: "per month"
    };
    const targetValue = target.get("value").get("value");
    const spacing = {
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem"
    };
    const labelStyle = {
      cursor: "default",
      display: "inline-block",
      width: 200
    };
    const radioButtonStyle = {
      width: 100
    };
    const restrictedKeys = new Set(['e', '-', '+']);
    return (
        <Dialog
            paperStyle={{
              maxWidth: 500,
              backgroundColor: theme.themeId === "light"
                  ? theme.palette.background.card
                  : theme.palette.background.paper
            }}
            title={`Edit ${kpi.get("name")} Target for ${groupOrUserName}`}
            actions={actions}
            actionsContainerStyle={dialogButtonContainerStyle}
            titleStyle={dialogHeaderStyle(theme)}
            bodyStyle={{...dialogBodyStyle(theme), overflowY: "visible"}}
            autoDetectWindowHeight={true}
            onRequestClose={onCloseRequest}
            open={true}>
          <div>
            <div style={spacing}>
              <label style={labelStyle}>
                Priority Metric
              </label>
              <Checkbox
                  label=""
                  labelPosition="left"
                  checked={target.get("priority")}
                  onCheck={(e, value) => this.handlePriorityMetricStatusChange(value)}
                  style={{maxWidth: 205}} />
            </div>
            <div style={spacing}>
              <label style={labelStyle}>
                Starts On
              </label>
              <div style={{display: "inline-block", fontSize: "0.9rem"}}>
                {formatDate(targetStartDate)}
              </div>
            </div>
            <div style={spacing} data-test-id="date-picker">
              <label style={labelStyle}>
                Ends On
              </label>
              <div style={{display: "inline-block"}}>
                <DatePicker
                    value={isFarFutureDate(targetEndDate) ? null : targetEndDate}
                    onDateChange={this.handleEndDateChange}
                    hideError={true} />
              </div>
            </div>
            <div style={spacing}>
              <label style={labelStyle}>
                Target Period
              </label>
              <RadioButtonGroup
                  name="target-period-type"
                  value={target.get("period")}
                  onChange={e => this.handleTargetPeriodChange(e.target.value)}
                  style={{display: "inline-block"}}>
                <RadioButton value="WEEKLY" label="Weekly" style={radioButtonStyle} />
                <RadioButton value="MONTHLY" label="Monthly" style={radioButtonStyle} />
              </RadioButtonGroup>
            </div>
            <div style={spacing}>
              <label style={labelStyle}>
                Target Value
              </label>
              <div style={{display: "inline-flex"}}>
                <SimpleTextInput
                    type="number"
                    placeholder="Enter target value"
                    value={targetValue}
                    onChange={this.handleTargetValueChange}
                    onKeyDown={e => {
                      if(e.key && restrictedKeys.has(e.key.toLowerCase()))
                        e.preventDefault();
                    }}
                    allowPaste={false}
                    customStyle={targetValue ? inputStyle : inputErrorStyle} />
                <span style={{paddingLeft: "0.5rem", paddingRight: "0.5rem", margin: "auto"}}>
                                {targetValueLabelByPeriod[target.get("period")]}
                            </span>
              </div>
            </div>
            {error && <ErrorMsg text={error} />}
            {success && <SuccessMsg text={success} onMessageTimeout={onSuccessMessageTimeout} />}
          </div>
        </Dialog>
    );
  },

  handlePriorityMetricStatusChange(isPriority) {
    const {target, onChange} = this.props;
    onChange(target.set("priority", isPriority));
  },

  handleEndDateChange(endDate, endDateStr) {
    const {target, onChange} = this.props;
    const targetEndDate = !endDate.isValid() && endDateStr === "" ? getFarFutureDate() : endDate;
    onChange(target.set("targetEnd", formatDate(targetEndDate, mySqlDateFormat)));
  },

  handleTargetValueChange(value) {
    const {target, onChange} = this.props;
    const targetValue = target.get("value");
    onChange(target.set("value", targetValue.set("value", value)));
  },

  handleTargetPeriodChange(newPeriod) {
    const {target, onChange} = this.props;
    onChange(target.set("period", newPeriod));
  }

});

const ConfirmRemoveCurrentTargetDialog = pure(({
  target,
  onCloseRequest,
  onConfirmRequest,
  isRemovingTarget,
  error,
  success,
  onSuccessMessageTimeout,
  theme
}) => {
  const actions = [
    <TextButton
        key="cancel-grp-targets-recalc"
        label={success ? "Close" : "Cancel"}
        style={buttonSpacing}
        disabled={isRemovingTarget}
        onClick={onCloseRequest} />,
    <TextButton
        key="confirm-grp-targets-recalc"
        type="primary"
        label={isRemovingTarget ? "Removing..." : "Yes"}
        style={buttonSpacing}
        disabled={isRemovingTarget || !!error || !!success}
        onClick={onConfirmRequest} />
  ];
  const getNameFnByQualifierType = {
    GROUP: getGroupName,
    USER: getUserName
  };
  const getName = getNameFnByQualifierType[target.get("qualifierType")];
  const groupOrUserName = getName(target.get("qualifierId"));
  const periodType = target.get("period").toLowerCase();
  const kpi = kpiRepo.get(target.get("kpiId"));
  const startDate = formatDate(moment(target.get("targetStart"), mySqlDateFormat));
  const endDate = formatDate(moment(target.get("targetEnd"), mySqlDateFormat));
  return (
      <Dialog
          paperStyle={{
            backgroundColor: theme.themeId === "light"
                ? theme.palette.background.card
                : theme.palette.background.paper
          }}
          title="Remove Target"
          actions={actions}
          actionsContainerStyle={dialogButtonContainerStyle}
          titleStyle={dialogHeaderStyle(theme)}
          bodyStyle={dialogBodyStyle(theme)}
          autoDetectWindowHeight={true}
          onRequestClose={onCloseRequest}
          open={true}>
        <p>
          Are you sure you want to remove the {periodType} {kpi.get("name")} target set
          for <strong>{groupOrUserName}</strong> from <strong>{startDate} to {endDate}</strong>?
        </p>
        {error && <ErrorMsg text={error} />}
        {success && <SuccessMsg text={success} onMessageTimeout={onSuccessMessageTimeout} />}
      </Dialog>
  );
});


const dialogHeaderStyle = theme => ({
  color: theme.palette.primary.main,
  fontSize: "1rem"
});

const dialogBodyStyle = theme => ({
  overflow: "visible",
  color: theme.palette.textColor
});

const dialogButtonContainerStyle = {
  paddingLeft: "2rem",
  paddingRight: "2rem",
  paddingBottom: "2rem"
};

const buttonSpacing = {
  marginLeft: "0.5rem",
  marginRight: "0.5rem"
};

const inputStyle = {
  display: "inline-block",
  width: 120
};

const inputErrorStyle = {
  ...inputStyle,
  border: "1px solid #f00",
  backgroundColor: "#502f2f"
};

const getGroupName = groupId => {
  const group = Groups.getGroup(groupId);
  return group ? group.get("name") : "N/A";
};

const getUserName = userId => Users.getUser(userId).get("fullName");

const getFarFutureDate = () => moment("2099-01-01", mySqlDateFormat);
const isFarFutureDate = date => date.isSame(getFarFutureDate(), "day");

const formatDate = (momentDate, pattern = "L") => momentDate.format(pattern);

const getTargetableKpis = () => {
  const allKpis = Immutable.fromJS(kpiRepo.getAll().toJSON());
  return allKpis.filter(kpi => kpi.get("visible") && kpi.getIn(["type", "targetType"]) !== "NONE");
};

const loadCurrentTargetsForKpi = kpiId => ajax
    .get({url: `kpi/${kpiId}/targets`})
    .then(results => {
      const targets = Immutable.fromJS(results);
      let targetsById = Immutable.Map();
      targets.forEach(target => {
        targetsById = targetsById.set(target.get("id"), target);
      });
      return targetsById;
    });

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <OldTargetApp theme={theme} {...props} />;
};