import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import SectionHeader from "js/admin/cubetv/section-header";
import EditFreeText from "js/admin/cubetv/free-text/edit-free-text";
import EditBackgroundImage from "js/admin/cubetv/free-text/edit-background-image";

const pathToConfig = ["layout", "fullscreen", 0];
const pathToContent = [...pathToConfig, "content"];
const pathToBackgroundUrl = [...pathToConfig, "backgroundUrl"];
const pathToBackgroundColor = [...pathToConfig, "backgroundColor"];
const pathToBackgroundSize = [...pathToConfig, "backgroundSize"];

export default createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes:{
        slide: ReactPropTypes.object.isRequired,
        onChange: ReactPropTypes.func.isRequired,
        channels: ImmutablePropTypes.list.isRequired,
        hasUnsavedChanges: ReactPropTypes.bool.isRequired
    },

    render() {
        const { slide } = this.props;
        return (
            <div>
                <SectionHeader text="Display a formatted text message" />
                <EditFreeText
                    timingInMillis={slide.get("timing")}
                    onTimingChange={this.handleTimingChange}
                    content={slide.getIn(pathToContent)}
                    onContentChange={this.handleContentChange}
                    backgroundColor={slide.getIn(pathToBackgroundColor)}
                    onBackgroundColorChange={this.handleBackgroundColorChange}
                    backgroundSize={slide.getIn(pathToBackgroundSize) || "contain"}
                    onBackgroundSizeChange={this.handleBackgroundSizeChange}
                    backgroundUrl={slide.getIn(pathToBackgroundUrl)} />
                <SectionHeader text="Optionally display a custom background image" />
                <EditBackgroundImage
                    url={slide.getIn(pathToBackgroundUrl)}
                    onChange={this.handleImageChange}
                    channels={this.props.channels}
                    hasUnsavedChanges={this.props.hasUnsavedChanges} />
            </div>);
    },

    handleTimingChange(timingInMillis) {
        const { slide, onChange } = this.props;
        onChange(slide.set("timing", timingInMillis));
    },

    handleContentChange(text) {
        const { slide, onChange } = this.props;
        onChange(slide.setIn(pathToContent, text));
    },

    handleBackgroundColorChange(color) {
        const { slide, onChange } = this.props;
        onChange(slide.setIn(pathToBackgroundColor, color || "#000"));
    },

    handleBackgroundSizeChange(backgroundSize) {
        const { slide, onChange } = this.props;
        onChange(slide.setIn(pathToBackgroundSize, backgroundSize));
    },

    handleImageChange(url) {
        const { slide, onChange } = this.props;
        const updatedSlide = slide.updateIn(pathToBackgroundUrl, currentBackgroundUrl => {
            const isCurrentBackground = currentBackgroundUrl === url;
            return isCurrentBackground ? null : url;
        });
        onChange(updatedSlide);
    }

});
