import React from "react";
import createReactClass from "create-react-class";
import ImmutablePropTypes from "react-immutable-proptypes";
import ReactPropTypes from "prop-types";

import SimpleDataTable from "js/common/views/tables/simple-data-table";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Users from "js/common/users";
import currentClient from "js/common/repo/backbone/current-client";

export default createReactClass({

  displayName: "NewUsersTable",

  propTypes: {
    users: ImmutablePropTypes.seq.isRequired,
    onActivate: ReactPropTypes.func.isRequired
  },

  render() {
    const columns = currentClient.isBullhornCrm() ?
        [
          {
            label: "ID",
            width: 50
          },
          {
            label: "Name",
            width: 260
          },
          {
            label: "Activate as",
            sortMapper: () => {},
            width: 370
          }
        ] : [
          {
            label: "Name",
            width: 260
          },
          {
            label: "Activate as",
            sortMapper: () => {},
            width: 420
          }
        ];
    const rows = this.props.users.map(this.userToRow).toArray();
    return <SimpleDataTable columns={columns} rows={rows} />;
  },

  userToRow(user) {
    const {onActivate} = this.props;
    const row = [
      user.get("fullName"),
      <div key="actions">
        <TextButton
            icon="user"
            label="Full User"
            onClick={() => onActivate(user, "FULL")}
            style={buttonStyle} />
        <TextButton
            icon="user-secret"
            label="Observer"
            onClick={() => onActivate(user, "OBSERVER")}
            style={buttonStyle} />
        <TextButton
            icon="moon-o"
            label="Inactive"
            onClick={() => onActivate(user, "INACTIVE")}
            style={buttonStyle} />
      </div>
    ];
    if (currentClient.isBullhornCrm()) {
      row.unshift(Users.isCrmUser(user) ? user.get("originalCrmUserId") : "");
    }
    return row;
  }

});

const buttonStyle = {
  fontSize: "13px",
  // marginTop : "-5px", // to compensate for the 8px padding that fixed-data-table sets on cells
  marginTop: "0.5px",
  marginBottom: "5px",
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: "0.8rem",
  paddingLeft: "0.8rem",
  marginRight: "0.5rem",
  marginLeft: "0.5rem",
  height: 25,
  lineHeight: 1
};
