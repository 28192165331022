/** @jsxImportSource @emotion/react */
import React from "react";
import {css} from "@emotion/react";
import Immutable from "immutable";
import {Dialog, DialogActions, DialogContent} from "@mui/material";

import * as Colors from "js/common/cube19-colors";
import {IconButton} from "js/common/views/inputs/buttons";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {betterMemo} from "js/common/utils/more-memo";

const DialogCube2021 = betterMemo(
    {displayName: "DialogCube2021"},
    ({
      isOpen,
      onRequestClose,
      content,
      actions,
      title,
      hideBackdrop,
      disableEnforceFocus,
      transitionDuration,
      iconStyle,
      type,
      childrenStyle,
      closeButtonStyle,
      titleStyle = {}
    }) => {

      const {theme} = React.useContext(CustomThemeContext);
      const dialogStyle = (type) => css`
        overflow-y: visible;
        overscroll-behavior-y: contain;
        pointer-events: ${type === "toast" ? "none" : "auto"};
        z-index: 999999;

        div {
          overflow-y: visible;

          &[class^="MuiDialog-paper"],
          &[class|='MuiDialog-paper'] {
            border-radius: 10px;
            overflow-y: visible;
            background-color: ${theme.palette.background.card};
            padding: 5px 20px 20px;
          }
        }

        [class*='MuiDialog-scrollPaper'] {
          align-items: ${type === "toast" ? "flex-start" : "center"};
          justify-content: ${type === "toast" ? "flex-end" : "center"};
          height: ${type === "toast" ? "auto" : "100%"};
        }

        [class*='MuiDialog-paper'] {
          border-radius: 15px;
          pointer-events: auto;
          border: 1px solid rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 25px rgba(0, 0, 0, 0.45);
          overflow-y: visible;
          background-color: ${theme.palette.background.card};
          padding: ${type === "toast" ? "20px" : "20px 20px"};

          h4 {
            font-size: ${type === "toast" ? "15px" : "18px"};
            text-align: ${type === "toast" ? "left" : "center"};
            display: ${type === "toast" ? "inline-block" : "block"};
          }

          p {
            font-size: ${type === "toast" ? "13px" : "15px"};
            text-align: ${type === "toast" ? "left" : "center"};
          }
        }

        [class*='MuiDialogContent-root'] {
          overflow-y: visible;
          padding: 0 !important;
        }
      `;

      const closeButtonDefaultStyle = theme => ({
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.main,
        borderRadius: 60,
        padding: "8px 10px",
        fontSize: 12
      });
      const iStyle = type === "toast" ? toastIconStyle : fullDialogIconStyle;

      return <Dialog
          css={dialogStyle(type)}
          hideBackdrop={hideBackdrop}
          transitionDuration={transitionDuration || 200}
          disableEnforceFocus={disableEnforceFocus}
          open={isOpen}>
        <DialogContent>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <div style={{position: "absolute", right: -15, top: -5, background: "#26c1c9", borderRadius: 30}}>
              {onRequestClose && <IconButton
                  icon="times"
                  onClick={onRequestClose}
                  style={closeButtonStyle || closeButtonDefaultStyle} />}
            </div>
            <div
                style={{
                  maxHeight: "calc(100vh - 208px)",
                  overflowY: "auto",
                  textAlign: type === "toast" ? "left" : "center", ...childrenStyle
                }}>
              {iconStyle && <i
                  className={iconStyleOptions.get(iconStyle).get("icon")}
                  style={{...iStyle, backgroundColor: iconStyleOptions.get(iconStyle).get("color")}} />}
              <h4 style={{...titleStyle, marginBottom: 10}}>{title}</h4>
              {content}
            </div>
            {actions && <DialogActions style={{justifyContent: "center", marginTop: 20}}>
              {actions}
            </DialogActions>}
          </div>
        </DialogContent>
      </Dialog>;
    });


const fullDialogIconStyle = {
  color: "#fff",
  padding: "10px 15px",
  borderRadius: 10,
  marginRight: 0,
  position: "absolute",
  top: -20,
  left: "calc(50% - 20px)",
  display: "block"
};

const toastIconStyle = {
  ...fullDialogIconStyle,
  padding: "5px 10px",
  borderRadius: 5,
  marginRight: 10,
  position: "relative",
  top: 0,
  left: 0,
  display: "inline-block"
};

const iconStyleOptions = Immutable.fromJS({
  info: {color: Colors.accentPurple, icon: "fas fa-info"},
  warning: {color: Colors.redLight, icon: "fas fa-exclamation"}
});

export default DialogCube2021;
