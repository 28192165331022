import moment from "moment";

const today = () => moment();
const parseDate = dateStr => moment(dateStr, "YYYY-MM-DD");
const formatDate = date => date.format("YYYY-MM-DD");
const formatDateForDisplay = date => date.format("ll").replace(",", "");

const diffInWholeDays = date => {
    return today().startOf("day").diff(date.clone().startOf("day"), "days");
};

const getTimeAgo = date => {
    const days = diffInWholeDays(date);
    if (days === 0) {
        return "Today";
    } else if (days > 0) {
        return days + " " + dayWord(days) + " ago";
    } else if (days < 0) {
        return Math.abs(days) + " " + dayWord(days) + " after today";
    }
};

const dayWord = days => Math.abs(days) !== 1 ? "days" : "day";

export {
	today,
	parseDate,
	formatDate,
	formatDateForDisplay,
	getTimeAgo,
	dayWord
};
