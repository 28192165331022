import React from "react";
import Immutable from "immutable";

import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {TextButton} from "js/common/views/inputs/buttons";
import {betterMemo} from "js/common/utils/more-memo";
import UserAvatar from "js/common/views/user-avatar";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import * as Ajax from "js/common/ajax";
import * as Popups from "js/common/popups";


const SwitchingUserPicker = betterMemo({displayName: "SwitchingUserPicker"}, ({
  users = getUsersForSwitching(),
  sourceUserId = Users.getSourceUser().get("id"),
  selectedUsers = Immutable.Set(),
  setSelectedUsers,
  multi,
  getUserIcon = user => <UserAvatar user={user} style={avatarStyle} />,
  showUserIcon = true,
  onUserClick = handleUserClick,
  listStyle
}) => {

  const {theme: {themeId, palette}} = React.useContext(CustomThemeContext);
  const [searchTextInput, setSearchTextInput] = React.useState("");

  const filteredUsers = searchTextInput
      ? users.filter(u => {
        const allDetails = u.get("fullName") + u.get("email") + u.get("originalCrmUserId");
        return allDetails.toUpperCase().indexOf(searchTextInput.toUpperCase()) !== -1;
      })
      : users;

  const currentUserId = Users.getCurrentUser().get("id");

  return <div style={{...containerStyle(palette, themeId)}}>
    <div
        style={{
          ...spacer,
          paddingTop: "0.5rem",
          paddingLeft: "1rem",
          display: "flex",
          justifyContent: "space-between"
        }}>
      <DelayedTextField
          variant="standard"
          label={<span style={{fontSize: 13}}><i className="bhi-search" />&nbsp;Search for Users</span>}
          style={{width: "70%", marginBottom: 10}}
          value={searchTextInput}
          onChange={setSearchTextInput} />
      {multi && <>
        <TextButton
            type="default"
            label="Remove All"
            disabled={selectedUsers.isEmpty()}
            style={{
              margin: "10px 0 10px 15px",
              textTransform: "none",
              backgroundColor: palette.background.paper,
              width: 110,
              padding: "0 1rem",
              fontSize: "0.8rem"
            }}
            onClick={() => setSelectedUsers(Immutable.Set())} />
      </>}
    </div>
    <hr style={{opacity: "60%"}} />
    <ul style={{...spacer, ...listStyle, marginBottom: 0}}>
      {filteredUsers
          .map(user => {
            const isCurrentUser = user.get("id") === currentUserId;
            return <div
                    onClick={() => !isCurrentUser && onUserClick(user.get("id"))}
                    style={{display: "flex", margin: "10px 15px", alignItems: "center", ...!isCurrentUser && {cursor: "pointer"}}}
                    key={user.get("id")}>
                  <div style={{width: 20}}>
                    {user.get("isSelected") && <i className="fa fa-check" style={{marginRight: 10}} />}
                  </div>
                  <SwitchingUserItem
                      user={user}
                      icon={showUserIcon && getUserIcon(user)}
                      isSourceUser={sourceUserId === user.get("id")}
                  />
                </div>
              }
          )}
    </ul>
  </div>;
});

const SwitchingUserItem = React.memo(({user, icon, disableEmail, isSourceUser}) => {
  const hasEmail = user.get("email") && user.get("email") !== "[hidden]";
  const emailLabel = hasEmail ? " (" + user.get("email") + ")" : "";

  return <div style={{display: "flex", maxWidth: "90%"}}>
    {icon}
    <div style={{display: "flex", flexDirection: "column", fontSize: "0.9rem"}}>
      <div>
        {user.get("fullName")}
        {!disableEmail && <span style={{opacity: "60%"}}>{emailLabel}</span>}
        {isSourceUser && <i className="fa fa-home" style={{marginTop: 5, marginLeft: 10}} />}
      </div>
      {Users.isCrmUser(user) && <div style={{overflow: "hidden", opacity: "60%", fontSize: "0.7rem"}}>
        {user.get("originalCrmUserId")}</div>}
      <div style={{overflow: "hidden"}}>{user.get("groupBreadcrumbsLabel")}</div>
    </div>
  </div>;
});

const spacer = {
  paddingRight: "1rem"
};

const containerStyle = (palette, themeId) => ({
  position: "relative",
  backgroundColor: palette.background.card,
  borderBottom: themeId === "dark" ? `2px solid ${palette.primary.main}` : "none",
  borderRadius: 5
});

const getGroupBreadcrumbsLabel = groupId => {
  const group = Groups.getGroup(groupId);
  if (!group) {
    return "";
  } else {
    const breadcrumbs = Immutable.fromJS(group.get("breadcrumbs"));
    return (
        <span style={{display: "table-cell", fontSize: "0.7rem", opacity: "60%", marginTop: 5}}>
            {breadcrumbs.map((crumb, index) => {
              const isLastItem = index === breadcrumbs.size - 1;
              if (isLastItem) {
                return <span key={crumb}>{crumb}</span>;
              } else {
                return (
                    <span key={crumb}>
                            {crumb}
                      <i className="fa fa-chevron-right" style={{paddingLeft: 8, paddingRight: 8}} />
                    </span>);
              }
            })}
        </span>);
  }
};

const getUsersForSwitching = () => {
  const sourceUser = Users.getSourceUser();
  const targetUserIds = Immutable.List(Users.getSourceUser().get("targetUserIds"));
  const currentUserId = Users.getCurrentUser().get("id");
  return targetUserIds
      .map(Users.getUser)
      .filter(user => user.get("id") !== currentUserId)
      .sortBy(u => u.get("fullName"))
      .unshift(sourceUser)
      .map(setGroupBreadcrumbsLabel);
};

const setGroupBreadcrumbsLabel = user => user.set(
    "groupBreadcrumbsLabel",
    getGroupBreadcrumbsLabel(user.get("groupId")));

const handleUserClick = id => {
  Ajax.post({
    url: "auth/login-as/" + id
  })
      .then(_ => Ajax.isCubeAvailable().then(
          () => {
            window.location.reload(true);
          },
          () => {}))
      .catch(error => {
        Popups.error(error.responseJSON.message);
      });
};

const avatarStyle = {
  height: 50,
  width: 50,
  maxWidth: "none",
  borderRadius: 10,
  marginRight: 7,
  objectFit: "cover"
};

export {SwitchingUserItem, getGroupBreadcrumbsLabel, handleUserClick, getUsersForSwitching, setGroupBreadcrumbsLabel};
export default SwitchingUserPicker;