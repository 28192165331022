import React from "react";
import ReactPropTypes from 'prop-types';
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import Layout from "js/common/views/foundation-column-layout";
import GroupBreadcrumbs from "js/admin/group-breadcrumbs";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const SelectedUserOrGroup = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        selected: ReactPropTypes.string.isRequired,
        selectedId: ReactPropTypes.number.isRequired,
        onGroupSelect: ReactPropTypes.func.isRequired,
        theme: ReactPropTypes.object
    },

    render() {
        const { selected, selectedId, theme } = this.props;
        const isRootGroupSelected = selected === "GROUP" && isRootGroup(selectedId);
        return (
            <Layout allSmall={12}>
                <div style={{ fontSize: "0.875rem" }}>
                    <i className={`fa fa-${selected.toLowerCase()}`} style={iconStyle(theme)} />
                    {`Timezone Locations History for ${selected.toLowerCase()}:`}
                    {selected === "USER" &&
                        <strong style={labelStyle(theme)}>
                            {getLabelForSelectedUser(selectedId, theme)}
                        </strong>}
                </div>
                {!isRootGroupSelected && this.renderGroupBreadcrumbs()}
            </Layout>);
    },

    renderGroupBreadcrumbs() {
        const { selected, selectedId, onGroupSelect, theme } = this.props;
        const groupId = selected === "USER" ? Users.getUser(selectedId).get("groupId") : selectedId;
        return (
            <GroupBreadcrumbs
                theme={theme}
                groupId={groupId}
                onGroupCrumbClick={onGroupSelect}
                isLastCrumbClickable={selected === "USER"} />
        );
    }

});

const iconStyle = theme =>({
    color: theme.palette.primary.main,
    paddingRight: 8
});

const labelStyle = theme =>({
    paddingLeft: 8,
    color: theme.palette.primary.main,
    fontStyle: "italic",
    letterSpacing: "1px"
});

const isRootGroup = groupId => {
    const rootGroup = Groups.getRootGroup();
    return groupId === rootGroup.get("id");
};

const getLabelForSelectedUser = (userId, theme) => {
    return <strong style={labelStyle(theme)}>{Users.getUser(userId).get("fullName")}</strong>;
};


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <SelectedUserOrGroup theme={theme} {...props} />;
};
  
export default Wrapper;