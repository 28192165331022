import Immutable from "immutable";

const getDefaultThemeColorsList = (dataGroupIndex = 0) => {
  const colorGroups = defaultBackgroundColorGroups;
  const noOfColorListsInGroup = Object.keys(colorGroups).length;
  return colorGroups[dataGroupIndex % noOfColorListsInGroup];
};

const getWhiteThemeColorsList = (dataGroupIndex = 0) => {
  const colorGroups = whiteBackgroundColorGroups;
  const noOfColorListsInGroup = Object.keys(colorGroups).length;
  return colorGroups[dataGroupIndex % noOfColorListsInGroup];
};

const getThemeColorsList = (dataGroupIndex = 0) => {
  const colorGroups = singleGroupBackgroundColorGroups;
  const noOfColorListsInGroup = Object.keys(colorGroups).length;
  return colorGroups[dataGroupIndex % noOfColorListsInGroup];
};

const defaultBackgroundColorGroups = {
  0: [
    "#29ff00",
    "#ff00a6",
    "#ff5d00",
    "#0cf",
    "#f9b9ff",
    "#f1ff00",
    "#f00",
    "#0053ff",
    "#ba00ff",
    "#81ffe6"
  ],
  1: [
    "#2b908f",
    "#90ee7e",
    "#f45b5b",
    "#7798BF",
    "#aaeeee",
    "#ff0066",
    "#eeaaee",
    "#55BF3B",
    "#DF5353",
    "#8085e9",
    "#ac3884"
  ],
  2: [
    "#82ffe6",
    "#e192ff",
    "#81aaff",
    "#ff8989",
    "#f8ff7c",
    "#c3c3c3",
    "#aaeeff",
    "#fcaa7c",
    "#ff71cd",
    "#9aff87"
  ],
  3: [
    "#b5c000",
    "#ad0e0e",
    "#466ec1",
    "#a737d0",
    "#00ab89",
    "#a84d18",
    "#ac3884",
    "#27a50f",
    "#6ba4b2",
    "#919191"
  ]
};

const whiteBackgroundColorGroups = {
  0: [
    "#7cb5ec",
    "#f7a35c",
    "#2b908f",
    "#7798BF",
    "#aaeeee",
    "#ff0066",
    "#eeaaee",
    "#55BF3B",
    "#DF5353",
    "#27a50f",
    "#81ffe6"
  ],
  1: [
    "#14a900",
    "#e900cc",
    "#ff6600",
    "#00aeff",
    "#f9b9ff",
    "#d7e300",
    "#ff0000",
    "#0053ff",
    "#ba00ff",
    "#00ffcc"
  ],
  2: [
    "#80dfcc",
    "#e192ff",
    "#81aaff",
    "#ff8989",
    "#cdd18a",
    "#c3c3c3",
    "#a6d9e6",
    "#fcaa7c",
    "#ff71cd",
    "#9aff87"
  ],
  3: [
    "#b5c000",
    "#ad0e0e",
    "#466ec1",
    "#a737d0",
    "#00ab89",
    "#a84d18",
    "#ac3884",
    "#27a50f",
    "#6ba4b2",
    "#919191"
  ]
};

const singleGroupBackgroundColorGroups = {
  0: [
    "#E66F96",
    "#827FE3",
    "#80dfcc",
    "#FCB3A3",
    "#175A7B",
    "#657AD2",
    "#F4928E",
    "#386BA9",
    "#F07F8D",
    "#00484C",
  ],
};

const safeColors = Immutable.OrderedSet.of(
    '#DA4453',
    '#EB6845',
    '#F6B042',
    '#8CC152',
    '#37BC9B',
    '#3BAFDA',
    '#4A89DC',
    '#D770AD',
    '#967ADC',
    '#9678B6'
);

export {
  getThemeColorsList,
  getDefaultThemeColorsList,
  getWhiteThemeColorsList,
  safeColors
};
