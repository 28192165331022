import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import * as Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";
import getCube19Theme from "js/common/themes/dark-theme";
import Dropzone from "js/common/views/inputs/dropzone";

import {Layout} from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import ErrorMsg from "js/common/views/error";

import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import AudioPlayer from "js/admin/simple-audio-player";
import Overlay from "js/common/views/overlay";
import {endsWith} from "js/common/utils/strings";
import * as auditor from "js/common/auditer";
import * as popups from "js/common/popups";
import currentClient from "js/common/repo/backbone/current-client";
import {formatStorageSize} from "js/common/utils/numbers";
import {TextField} from "@mui/material";
import {apiUrl, assetsUrl} from "js/app-configuration";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Checkbox from "js/common/views/inputs/checkbox";

const MAX_FILENAME_CHAR_LENGTH = 255;
const AUDIO_FILE_LIMIT = 25;
const maxFileSizeInBytes = 100e6;
const maxFileSizeStr = formatStorageSize(maxFileSizeInBytes, 0);

const DealMusicUploader = createReactClass({

  mixins: [PureRenderMixin],

  childContextTypes: {
    muiTheme: ReactPropTypes.object
  },

  getChildContext() {
    return {
      muiTheme: getCube19Theme()
    };
  },

  propTypes: {
    onDealMusicUploaded: ReactPropTypes.func.isRequired,
    theme: ReactPropTypes.object
  },

  getInitialState() {
    return {
      file: null,
      description: "",
      isUploading: false,
      acceptedUploadConditions: false,
      error: null
    };
  },

  componentWillUnmount() {
    const {file} = this.state;
    if (file) {
      URL.revokeObjectURL(file.preview);
    }
  },

  render() {
    const {file, description, isUploading, error, acceptedUploadConditions} = this.state;
    const {theme} = this.props;
    const isInvalidFile = !!file && !!error;

    return (
        <div style={{position: "relative", marginTop: "0.5rem"}}>
          {isUploading && <Overlay />}
          <Layout allSmall={12}>
            <Dropzone
                multiple={true}
                onDrop={this.handleDrop}
                className="react-dropzone"
                activeClassName="react-dropzone-active">
              {file ? <FilePreview file={file} /> : <HelpText theme={theme} />}
            </Dropzone>
            {isInvalidFile && <ErrorMsg text={error} />}
            <div>
              <TextField variant="standard"
                         fullWidth={true}
                         label="File Description"
                         disabled={!file || isInvalidFile}
                         value={description}
                         onChange={e => this.setState({
                           description: e.target.value.substring(0, MAX_FILENAME_CHAR_LENGTH)
                         })} />
            </div>
            <Hint style={{backgroundColor: theme.palette.background.warning, borderColor: theme.palette.border.warning, marginTop: "1rem"}}>
              <Icon icon="caution" style={{color: theme.palette.hints.text}} />
              <span>While the Bullhorn Analytics service permits users to upload music files to be used as "New Deal Music", nothing herein constitutes an authorization by Bullhorn regarding your use of any specific music files. Your uploading and/or use of any specific music file(s) for any purpose is prohibited unless you have obtained the appropriate licenses.</span>
            </Hint>
            <Checkbox
                label="By checking this box and uploading one or more music files to the New Deal Music Uploader you are representing that you have the necessary license(s) to use the specific music file(s) uploaded."
                checked={acceptedUploadConditions}
                labelStyle={{whiteSpace: "wrap !important", fontSize: "12px !important"}}
                style={{ height: 30, margin: "1rem 0" }}
                onCheck={(e, acceptedUploadConditions) => this.setState({ acceptedUploadConditions })} />
            <div style={{marginTop: "1rem", marginBottom: "0.5rem"}}>
              <TextButton
                  tooltipContent={!acceptedUploadConditions ? "You must confirm that you have the necessary rights to the music file above." : undefined}
                  style={{marginRight: "0.5rem"}}
                  icon="upload"
                  label="Upload"
                  disabled={!file || isInvalidFile || !acceptedUploadConditions}
                  onClick={this.handleUploadClick} />
              <TextButton
                  style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}
                  icon="trash"
                  label="Remove"
                  disabled={!file}
                  onClick={this.removeFile} />
            </div>
          </Layout>
        </div>
    );
  },

  handleDrop(acceptedFiles) {
    const file = acceptedFiles[0];  // File object returned by Dropzone component is read-only
    const preview = URL.createObjectURL(file);
    const fileName = file.name;
    const BASE_ERROR_MSG = `You can only upload MP3 (.mp3) files of up to ${AUDIO_FILE_LIMIT} seconds in length and ${maxFileSizeStr} in size.`;
    this.setState({
      file: Object.assign(file, {preview}),
      description: fileName,
      error: null
    });

    if (!isValidFileExtension(fileName)) {
      this.setState({
        error: `${BASE_ERROR_MSG} '${fileName}' is not a supported file format.`
      });
      return;
    }

    if (!isValidSize(file.size)) {
      this.setState({
        error: `You can only upload files with a maximum size of ${maxFileSizeStr}. '${file.name}' is ${formatStorageSize(file.size)}.`
      });
      return;
    }

    const audio = new Audio(preview);
    if (!currentClient.hasPermission("LONG_DEAL_MUSIC")) {
      audio.addEventListener("loadedmetadata", () => {
        if (audio.duration >= AUDIO_FILE_LIMIT + 1) {
          this.setState({
            error: `${BASE_ERROR_MSG} '${fileName}' is more than ${AUDIO_FILE_LIMIT} seconds long.`
          });
        }
      });
    }
  },

  handleUploadClick() {
    this.setState({
      isUploading: true
    });

    const {file, description} = this.state;
    window.superagent
        .post(apiUrl + "/ApplicationLayer/dealmusic/upload")
        .withCredentials()
        .attach("file", file)
        .field("description", description.substring(0, MAX_FILENAME_CHAR_LENGTH))
        .end((error, response) => {
          if (error) {
            const errorJSON = JSON.parse(response.xhr.responseText);
            const errorText = errorJSON.message;
            this.setState({
              isUploading: false,
              error: `Unable to add new deal music. ${errorText ?
                  errorText.charAt(0).toUpperCase() + errorText.slice(1) : ""}`
            });
          } else {
            popups.success("New Deal Music added");
            const result = response.body;
            const {dealMusicId, description} = result;
            auditor.audit("deal_music_added", {
              dealMusicId,
              description
            });
            const newDealMusic = Immutable.fromJS(result);
            this.props.onDealMusicUploaded(newDealMusic);
            this.setState(this.getInitialState());
          }
        });
  },

  removeFile() {
    URL.revokeObjectURL(this.state.file.preview);
    this.setState(this.getInitialState());
  }

});

const HelpText = pure(({theme}) => (
    <span style={{fontSize: 12, lineHeight: 1.2}}>Drop a new MP3 file here<br/>or click to choose a file to upload.<br/><br/>Deal music must be an MP3 (.mp3) file of up to {AUDIO_FILE_LIMIT} seconds in length.<br/>
      Click <a href="https://kb.bullhorn.com/analytics/Content/Analytics/Topics/uploadDealMusic.htm" target="_blank"
               style={{textDecoration: "underline", color: theme.palette.primary.main}}>here</a> to see how.</span>
));

const FilePreview = pure(({file}) => (
    <div>
      {isValidFileExtension(file.name) && <AudioPlayer source={file.preview} />}
      <p><i className="fa fa-file-audio-o" /> {file.name}</p>
    </div>
));

const isValidFileExtension = fileName => endsWith(".mp3", fileName.toLowerCase());
const isValidSize = fileSizeInBytes => fileSizeInBytes <= maxFileSizeInBytes;

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <DealMusicUploader theme={theme} {...props} />;
};