/** @jsxImportSource @emotion/react */

import React from "react";
import {jsx} from "@emotion/react";

import {betterMemo} from "js/common/utils/more-memo";
import * as Colors from "js/common/cube19-colors";
import Select from "js/common/views/inputs/immutable-react-select";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";




const cube2021LabelStyle = palette => ({
  padding: "5px 0",
  margin: "5px 0 0 0",
  fontSize: 14,
  color: palette.text.main,
  display: "block",
  transform: "none",
  position: "relative",
  top: 0,
  left: 0
});

const Error = (message) => {
  return <span
      style={{
        background: Colors.redLight,
        color: `${Colors.white} !important`,
        position: "absolute",
        overflow: "hidden",
        whiteSpace: "nowrap",
        top: 20,
        fontSize: "0.7rem",
        right: 5,
        padding: "2px 8px",
        borderRadius: 4,
        zIndex: 99999
      }}>{message}
    <i
        className={`fa fa-times`}
        style={{
          width: "15px",
          height: "15px",
          marginLeft: "0.5rem",
          borderRadius: "50%",
          textAlign: "center",
          lineHeight: "15px",
          backgroundColor: "rgba(255,255,255,0.2)",
          color: Colors.white
        }} />
  </span>;
};

export default betterMemo(
    {displayName: "LabelledSelect"},
    ({label, title, containerStyle, labelStyle, helperText, ...props}) => {
      const is2021 = props.theme === "cube2021";
      const { palette } = React.useContext(CustomThemeContext).theme;

      const defaultLabelStyle = {
        display: "inline-block",
        marginBottom: "0.2rem",
        fontSize: "0.75rem",
        color: props.hasError ? Colors.errorColor : palette.text.main
      };

      return (
          <div title={label || title} style={{...containerStyle, position: "relative"}}>
            {label && <span
                style={
                  is2021 ?
                      {...cube2021LabelStyle(palette), ...labelStyle} :
                      {...defaultLabelStyle, ...labelStyle}
                }>{label}</span>}
            {is2021 && props.error && helperText && Error(helperText)}
            <Select {...props} />
          </div>);
    });
