import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";
import ReactQuill from "react-quill";

import Layout from "js/common/views/foundation-column-layout";
import NumberField from "js/common/views/inputs/number-field";
import ColorPicker from "js/common/views/inputs/color-picker";
import { TextButton } from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";
import RadioButton from "js/common/views/inputs/radio-button";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import cubeTvLogo from "img/cubetv/cubetv-logo.svg";
import backgroundSizeDifferenceImage from "img/cubetv/background-size-difference.png"

import { RadioGroup as RadioButtonGroup } from '@mui/material';

const leftAngleBracket = new RegExp("&lt;", "g");
const rightAngleBracket = new RegExp("&gt;", "g");
const unescapeStr = str => str
    .replace(leftAngleBracket, "<")
    .replace(rightAngleBracket, ">");

const EditFreeText = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        content: ReactPropTypes.string.isRequired,
        onContentChange: ReactPropTypes.func.isRequired,
        backgroundColor: ReactPropTypes.string.isRequired,
        onBackgroundColorChange: ReactPropTypes.func.isRequired,
        backgroundSize: ReactPropTypes.string.isRequired,
        onBackgroundSizeChange: ReactPropTypes.func.isRequired,
        backgroundUrl: ReactPropTypes.string,
        timingInMillis: ReactPropTypes.number.isRequired,
        onTimingChange: ReactPropTypes.func.isRequired
    },

    getInitialState() {
        return {
            showBackgroundSizeExamples: false
        };
    },

    render() {
        const {
            timingInMillis,
            onTimingChange,
            backgroundColor,
            onBackgroundColorChange,
            backgroundSize,
            onBackgroundSizeChange,
            theme
        } = this.props;
        const imgStyle = {
            display: "block",
            height: 350,
            width: "auto"
        };
        const labelStyle = {
            fontSize: "11px",
            fontFamily: theme.typography.fontFamily,
            fontWeight: "500",
            color: theme.themeId === "light" ? "rgba(0, 0, 0, 0.6)" : "rgba(255, 255, 255, 0.7)"
        };
        return (
            <div className="free-text-slide-editor" style={{marginTop: "0.5rem"}}>
                <Layout allSmall={12} medium={[4,2,5]} floatLastColumnLeft={true}>
                    <NumberField
                        range={[1, 300]}
                        label="Seconds to Display Slide"
                        value={timingInMillis / 1000}
                        onChange={timingInSeconds => onTimingChange(timingInSeconds * 1000)} />
                    <div>
                        <div style={{ ...labelStyle, marginBottom: "0.5rem" }}>
                            Background Color:
                        </div>
                        <ColorPicker initialColor={backgroundColor} onChange={onBackgroundColorChange} />
                    </div>
                    <div>
                        <div style={{ display: "table", marginBottom: "0.5rem" }}>
                            <div style={{ ...labelStyle, display: "table-cell", verticalAlign: "middle" }}>
                                Background Image Sizing Options:
                            </div>
                            <HelpIconButton
                                title="Show examples"
                                onClick={() => this.setState({ showBackgroundSizeExamples: true })} />
                        </div>
                        <RadioButtonGroup
                            name="background-size"
                            onChange={e => onBackgroundSizeChange(e.target.value)}
                            value={backgroundSize}>
                            <RadioButton
                                style={{height: 25}}
                                value="contain"
                                label="Show whole image (recommended)"  />
                            <RadioButton
                                style={{height: 25}}
                                value="cover"
                                label="Fit screen" />
                        </RadioButtonGroup>
                    </div>
                </Layout>
                <Layout allSmall={12} rowStyle={{ marginTop: "1rem" }}>
                    {this.renderTextEditor()}
                </Layout>
                <Layout allSmall={12} rowStyle={{ marginTop: "1rem" }}>
                    <TextButton label="Preview Slide" onClick={this.showSlidePreview} />
                </Layout>
                <Dialog
                    title="Difference Between The Background Size Options"
                    titleStyle={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                    actionsContainerStyle={{ paddingBottom: "1rem", paddingLeft: "2rem", paddingRight: "2rem" }}
                    actions={[
                        <TextButton
                            key="close"
                            label="Got it!"
                            onClick={() => this.setState({ showBackgroundSizeExamples: false })} />
                    ]}
                    contentStyle={{ width: 1050, maxWidth: "90%" }}
                    bodyStyle={{ color: theme.palette.textColor }}
                    autoDetectWindowHeight={true}
                    open={this.state.showBackgroundSizeExamples}
                    maxWidth="md"
                    onBackdropClick={() => this.setState({ showBackgroundSizeExamples: false })}>
                    <img src={backgroundSizeDifferenceImage} style={imgStyle} />
                    <strong style={{ fontSize: "0.875rem", textTransform: "uppercase" }}>
                        Note:
                    </strong>
                    <p style={{ fontSize: "0.875rem" }}>
                        If you have RSS Feeds enabled for your Gamification channel(s), the bottom of the slide area will be covered by an RSS Feed News Ticker of 60px height
                    </p>
                </Dialog>
            </div>
        );
    },

    renderTextEditor() {
        const { backgroundUrl, backgroundColor, backgroundSize, content, onContentChange } = this.props;

        const backgroundStyle = {
            backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : "",
            backgroundSize,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundColor
        };

        /*
         * JC scrolling styles must be kept separate from background styles
         * react quill breaks if anything other than a content change causes a re-render e.g. a changing "styles" prop
         */

        const modules = {
            toolbar: [
                [{ "font": [ false, "serif", "monospace" ] }],
                [{ "size": [ "small", false, "large", "huge" ] }],
                [{ "align": [ false, "center", "right", "justify" ] }],
                ["bold", "italic","strike", "underline" ],
                [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }, { "background": [] }],
                [{ "list": "ordered" }, { "list": "bullet" }]
            ]
        };
        return (
            <div style={backgroundStyle}>
                <ReactQuill theme="snow" value={unescapeStr(content)} modules={modules} onChange={onContentChange} />
            </div>
        );
    },

    showSlidePreview() {
        const { backgroundColor, backgroundUrl, backgroundSize, content } = this.props;
        const win = window.open();
        const containerStyle = `
            padding: 0;
            color: #fff;
            font-weight: normal;
            font-style: normal;
            background-color: ${backgroundColor};
            background-image: ${backgroundUrl ? `url(${backgroundUrl})` : "none"};
            background-size: ${backgroundSize ? backgroundSize : "contain"};
            background-repeat: no-repeat;
            background-position: center center;
            line-height: 1.65;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 0;
            overflow: hidden;`;
        const rssFeedAreaStyle = `
            border-top: 2px solid #eeeeee;
            background-color: #900100;
            position: fixed;
            z-index: 99999;
            bottom: 0;
            width: 100%;
            color: #fff;
            font-weight: bold;
            line-height: 50px;
            opacity: 0.6;
            padding-left: 1rem;`;
        win.document.write(
            `<html>
                <head>
                    <meta charset="utf-8">
                    <link rel="icon" href="/favicon.ico" sizes="32x32">
                    <link rel="icon" href="/favicon.ico" sizes="16x16">
                    <title>Gamification Custom Text Slide Preview</title>
                    <!-- LOCAL ENV -->
                    <link rel="stylesheet" type="text/css" href="src/css/app.css" />
                    <!-- PROD ENV -->
                    <link rel="stylesheet" type="text/css" href="app.min.css" />
                </head>
                <body class="cubetv">
                    <div id="content-region"><div class="cubetv-app"><div class="header-region">
                        <img class="cubetv-logo right" src="${cubeTvLogo}" alt="Gamification" width="105" height="40">
                        <div class="menu-region clearfix"></div>
                    </div>
                    <div class="center-region">
                        <div class="cubetv-page">
                            <div id="cubetv-page-header"></div>
                                <div id="cubetv-page-content">
                                    <div class="cubetv">
                                        <div id="slide-region" class="slide-region">
                                            <div>
                                                <div class="ql-editor" style="${containerStyle}">
                                                    ${unescapeStr(content)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="${rssFeedAreaStyle}">
                        News Ticker Area (height: 60px)
                    </div>
                </body>
            </html>`
        );
    }

});

const HelpIconButton = pure(({
    onClick
}) => (
    <i onClick={e => {
        e.preventDefault();
        onClick(e.currentTarget);
    }}
    title="Show examples"
    style={{
        cursor: "pointer",
        display: "table-cell",
        paddingLeft: 5,
        color: "#757575",
        ":hover": {
            color: "#fff"
        }
    }}
    className="fa fa-question-circle" />
));


export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <EditFreeText theme={theme} {...props} />;
};