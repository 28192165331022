import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import store from "store";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Tooltip from "react-tooltip";
import {Popover, TextField} from "@mui/material";

import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";
import Select from "js/common/views/inputs/immutable-react-select";
import InlineRadioButtons from "js/common/views/inputs/inline-radio-buttons";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import UncaughtErrorMsg from "js/common/views/uncaught-error-msg";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import Dialog from "js/common/views/dialog";
import LoadingSpinner from "js/common/views/loading-spinner";
import UnsavedChangesDialog from "js/common/views/unsaved-changes-dialog";
import Checkbox from "js/common/views/inputs/checkbox";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import currentClient from "js/common/repo/backbone/current-client";
import pure from "js/common/views/pure";
import * as Ajax from "js/common/ajax";
import * as Auditor from "js/common/auditer";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Users from "js/common/users";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants";
import * as auditor from "js/common/auditer";

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ErrorBoundaryPage theme={theme} {...props} />;
};

const ErrorBoundaryPage = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      uncaughtError: false
    };
  },

  componentDidCatch() {
    this.setState({
      uncaughtError: true
    });
  },

  render() {
    const {theme} = this.props;
    if (this.state.uncaughtError) {
      return <UncaughtErrorMsg />;
    } else {
      return <Page theme={theme} />;
    }
  }
});

const cube19DefaultLabel = "Default";
const errorsNeedFixingMsg = "There are errors in your timeframe changes. Please fix the highlighted errors before saving your changes.";
const unexpectedErrorMsg = `An unexpected error has occurred. ${Branding.submitTicketString}`;
const unsavedChangesMessage = "You have unsaved changes. Don't worry, we'll hold on to them until you come back.";

const maxTimeframeDays = 430;
const minOffset = -maxTimeframeDays;
const maxOffset = maxTimeframeDays;

const shortNameMaxLength = 16;
const displayNameMaxLength = 32;

const Page = GetContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      initialTimeframes: Immutable.List(),
      timeframes: Immutable.List(),
      idToEditState: Immutable.Map(),
      timeframeChangeById: Immutable.Map(),
      hasUnsavedChanges: false,
      hasStoredChanges: hasStoredChanges(),
      isShortNameInfoPopoverOpen: false,
      isConfirmRemoveTimeframesDialogOpen: false,
      isLoading: true,
      isUpdating: false,
      idToErrorsByAttribute: Immutable.Map(),
      updateError: null,
      updateSuccess: null
    };
  },

  componentDidMount() {
    this.loadTimeframes()
        .then(() => auditor.audit("timeframes-admin:loaded"));

    window.onbeforeunload = () => {
      const {hasUnsavedChanges, timeframes, idToEditState, timeframeChangeById} = this.state;
      if (hasUnsavedChanges) {
        saveChangesToLocalStorage(timeframes, idToEditState, timeframeChangeById);
        return unsavedChangesMessage;
      }
    };
  },

  componentWillUnmount() {
    window.onbeforeunload = null;
    const {hasUnsavedChanges, timeframes, idToEditState, timeframeChangeById} = this.state;
    if (hasUnsavedChanges) {
      alert(unsavedChangesMessage);
      saveChangesToLocalStorage(timeframes, idToEditState, timeframeChangeById);
    }
  },

  loadTimeframes() {
    this.setState({
      isLoading: true
    });
    return getTimeframes()
        .then(timeframes => {
          const sortedTimeframes = sortTimeframes(timeframes).filter(t => !t.get("isDeleted"));
          this.setState({
            isLoading: false,
            idToEditState: this.state.idToEditState.clear(),
            initialTimeframes: sortedTimeframes,
            timeframes: sortedTimeframes
          });
        }, () => {
          this.setState({
            isLoading: false,
            updateError: unexpectedErrorMsg
          });
        });
  },

  render() {
    const {theme} = this.props;
    return (
        <div>
          <AdminHeader>
            Manage timeframe options
          </AdminHeader>
          <Hint>
            <Icon icon="info" style={{color: theme.palette.hints.text}} />
            To see the saved timeframe changes here take effect in other areas of the app a browser refresh is required
          </Hint>
          {this.state.isLoading ? this.renderLoadingSpinner() : this.renderTimeframesList()}
          <Popover
              open={this.state.isShortNameInfoPopoverOpen}
              anchorEl={this.state.popoverAnchorEl}
              anchorOrigin={{horizontal: "left", vertical: "bottom"}}
              transformOrigin={{horizontal: "left", vertical: "top"}}
              onClose={this.closeShortNameInfoPopover}>
            <p style={{maxWidth: 200, fontSize: "0.875rem", marginBottom: 0, padding: "0.5rem"}}>
              <strong style={{color: theme.palette.primary.main}}>Short Name</strong> is used in
              the <strong style={{color: theme.palette.primary.main}}>Gamification
              Trend Slide</strong> for the Trend Chart's trend label, if provided
            </p>
          </Popover>
          {this.state.isConfirmRemoveTimeframesDialogOpen &&
              <ConfirmRemoveTimeframesDialog
                  theme={theme}
                  timeframesToRemove={this.state.timeframes.filter(tf => tf.get("isDeleted"))}
                  onCloseRequest={this.handleCancelRemoveTimeframesRequest}
                  onConfirmRequest={this.handleConfirmRemoveTimeframesRequest} />}
          <UnsavedChangesDialog
              onRetrieveClick={this.handleGetStoredChangesRequest}
              onDiscardClick={this.discardStoredChanges}
              open={this.state.hasStoredChanges} />
        </div>
    );
  },

  handleGetStoredChangesRequest() {
    const storedChanges = getStoredChanges();
    const storedTimeframeChanges = storedChanges.timeframes;
    let newTimeframes = this.state.timeframes
        .map(timeframe => {
          const index = storedTimeframeChanges.findIndex(tf => tf.get("id") === timeframe.get("id"));
          const isStoredTimeframeChange = index < 0;
          if (isStoredTimeframeChange) {
            return timeframe;
          } else {
            return storedTimeframeChanges.get(index);
          }
        });
    storedTimeframeChanges.forEach(timeframe => {
      if (timeframe.get("cid")) {
        newTimeframes = newTimeframes.push(timeframe);
      }
    });
    this.setState({
      timeframes: sortTimeframes(newTimeframes),
      idToEditState: storedChanges.idToEditState,
      timeframeChangeById: storedChanges.timeframeChangeById,
      hasUnsavedChanges: true
    });
    this.discardStoredChanges();
  },

  discardStoredChanges() {
    clearStoredChanges();
    this.setState({
      hasStoredChanges: false
    });
  },

  renderLoadingSpinner() {
    return (
        <div style={{marginTop: "2rem"}}>
          <LoadingSpinner label="Loading Timeframes" />
        </div>
    );
  },

  renderTimeframesList() {
    const buttonStyle = {
      marginLeft: "0.5rem",
      marginRight: "0.5rem"
    };
    const statusMsgAreaStyle = {
      minHeight: 27,
      marginTop: "0.5rem",
      marginRight: "0.5rem",
      marginLeft: "0.5rem",
      marginBottom: "1rem"
    };
    const {theme} = this.props;
    const isCancelBtnDisabled = this.state.isUpdating ||
        (this.state.idToEditState.isEmpty() && !this.state.hasUnsavedChanges);
    const hasErrors = !this.state.idToErrorsByAttribute.isEmpty() || !!this.state.updateError;
    const isSaveBtnDisabled = !this.state.hasUnsavedChanges || this.state.isUpdating || hasErrors;
    const totalColumnsWidth = getTotalColumnsWidth();
    const maxWidth = this.props.containerWidth < totalColumnsWidth ? this.props.containerWidth : totalColumnsWidth;
    const headerRowStyle = theme => ({
      color: theme.palette.textColor,
      fontSize: "0.7rem",
      fontWeight: "bold",
      display: "flex",
      alignItems: "flex-end",
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      paddingLeft: 5,
      paddingRight: 5,
      paddingBottom: 7,
      width: totalColumnsWidth
    });
    const headerColumnStyle = {
      ...columnStyle,
      lineHeight: 1.25
    };
    return (
        <div style={{maxWidth: maxWidth + 5, margin: "1rem auto"}}>
          <div style={{textAlign: "center"}}>
            <TextButton
                icon="history"
                label="Cancel"
                onClick={this.handleCancelChangesRequest}
                disabled={isCancelBtnDisabled}
                style={buttonStyle} />
            <TextButton
                icon="floppy-o"
                label="Save"
                onClick={this.handleSaveChangesRequest}
                disabled={isSaveBtnDisabled}
                style={buttonStyle} />
            <TextButton
                icon="plus"
                label="New Timeframe"
                onClick={this.handleAddNewTimeframeRequest}
                disabled={this.state.isUpdating || hasErrors}
                style={buttonStyle} />
          </div>
          <div style={statusMsgAreaStyle}>
            {this.state.hasUnsavedChanges &&
                <ErrorMsg
                    type="warn"
                    text="Changes made to timeframes will affect Saved Reports" />}
            {this.state.updateError && <ErrorMsg text={this.state.updateError} />}
            {this.state.updateSuccess &&
                <SuccessMsg
                    text={this.state.updateSuccess}
                    onMessageTimeout={() => this.setState({updateSuccess: null})} />}
          </div>
          <div>
            <div style={headerRowStyle(theme)}>
              <div style={{...headerColumnStyle, minWidth: getColWidth("order")}}>
                &nbsp;
              </div>
              <div style={{...headerColumnStyle, minWidth: getColWidth("name")}}>
                Display Name
              </div>
              <div style={{...headerColumnStyle, minWidth: getColWidth("trueName")}}>
                Original Name
              </div>
              <div style={{...headerColumnStyle, minWidth: getColWidth("shortName")}}>
                Short Name for<br />Gamification<br />
                <em style={{paddingLeft: 5, paddingRight: 5}}>(optional)</em>
                <MoreInfoBtn onClick={this.openShortNameInfoPopover} />
              </div>
              <div style={{...headerColumnStyle, minWidth: getColWidth("timeframeType")}}>
                Timeframe Type
              </div>
              <div style={{...headerColumnStyle, minWidth: getColWidth("dateRange")}}>
                Date Range
              </div>
              <div style={{...headerColumnStyle, minWidth: getColWidth("visible")}}>
                Visible
              </div>
              <div style={{...headerColumnStyle, minWidth: getColWidth("actions")}}>
                &nbsp;
              </div>
            </div>
            <DragDropContext onDragEnd={this.handleDragEnd}>
              <TimeframeList
                  theme={theme}
                  timeframes={this.state.timeframes.filter(tf => !tf.get("isDeleted"))}
                  idToEditState={this.state.idToEditState}
                  idToErrorsByAttribute={this.state.idToErrorsByAttribute}
                  isDisabled={this.state.isUpdating}
                  onTimeframeChange={this.handleTimeframeChange}
                  onEditTimeframeRequest={this.handleEditTimeframeRequest}
                  onCopyTimeframeRequest={this.handleCopyTimeframeRequest}
                  onRemoveTimeframeRequest={this.handleRemoveTimeframeRequest} />
            </DragDropContext>
          </div>
        </div>
    );
  },

  openShortNameInfoPopover(evt) {
    this.setState({
      popoverAnchorEl: evt.currentTarget,
      isShortNameInfoPopoverOpen: true
    });
  },

  closeShortNameInfoPopover() {
    this.setState({
      popoverAnchorEl: null,
      isShortNameInfoPopoverOpen: false
    });
  },

  handleDragEnd(result) {
    if (!result.destination) {  // dropped outside the list area, do nothing
      return;
    } else {
      const startIndex = result.source.index;
      const destinationIndex = result.destination.index;
      const visibleTimeframes = this.state.timeframes.filter(t => t.get("visible") && !t.get("isDeleted"));
      const lastVisibleTimeframeIndex = visibleTimeframes.count() - 1;
      const endIndex = destinationIndex > lastVisibleTimeframeIndex ? lastVisibleTimeframeIndex : destinationIndex;
      const movedTimeframe = visibleTimeframes
          .get(startIndex)
          .set("order", endIndex);
      const hiddenTimeframes = this.state.timeframes
          .filter(t => !t.get("visible") || t.get("isDeleted"))
          .sortBy(t => t.get("name").toLowerCase());
      const reorderedTimeframes = visibleTimeframes
          .delete(startIndex)
          .insert(endIndex, movedTimeframe)
          .map((timeframe, index) => {
            const isChanged = timeframe.get("isChanged");
            const isTimeframeChanged = isChanged ? isChanged : index >= Math.min(startIndex, endIndex);
            const updatedTimeframe = timeframe
                .set("isChanged", isTimeframeChanged)
                .set("order", index);
            return updatedTimeframe;
          })
          .concat(hiddenTimeframes);
      let newTimeframeChangeById = this.state.timeframeChangeById;
      if (movedTimeframe.get("id")) {
        newTimeframeChangeById = newTimeframeChangeById.set(movedTimeframe.get("id"), movedTimeframe);
      }
      this.setState({
        timeframes: reorderedTimeframes,
        timeframeChangeById: newTimeframeChangeById,
        hasUnsavedChanges: true
      });
    }
  },

  handleAddNewTimeframeRequest() {
    const newTimeframe = getDefaultCustomTimeframe();
    const newTimeframes = this.state.timeframes
        .unshift(newTimeframe)
        .map((timeframe, index) => timeframe
            .set("isChanged", true)
            .set("order", index));
    this.setState({
      timeframes: newTimeframes,
      hasUnsavedChanges: true,
      idToEditState: this.state.idToEditState.set(newTimeframe.get("cid"), true)
    });
  },

  handleCopyTimeframeRequest(timeframeId) {
    const index = this.getTimeframeIndex(timeframeId);
    const timeframe = this.state.timeframes.get(index);
    const timeframeCopyIndex = index + 1;
    let timeframeCopy = timeframe
        .set("cid", `new-${Math.random()}`)
        .set("name", `Copy of ${timeframe.get("name")}`)
        .set("shortName", "")
        .set("order", timeframeCopyIndex)
        .delete("id");
    const timeframeDescription = timeframe.get("timeframeDescription");
    if (timeframeDescription.get("timeframeType") === "FIXED") {
      const newTimeframeDescription = timeframeDescription
          .setIn(["start", "date"], timeframeDescription.getIn(["end", "date"]));
      timeframeCopy = timeframeCopy
          .set("start", timeframe.get("end"))
          .set("timeframeDescription", newTimeframeDescription);
    }
    const newTimeframes = this.state.timeframes
        .insert(timeframeCopyIndex, timeframeCopy)
        .map((timeframe, index) => timeframe
            .set("isChanged", index !== timeframe.get("order"))
            .set("order", index));
    this.setState({
      timeframes: newTimeframes,
      hasUnsavedChanges: true,
      idToEditState: this.state.idToEditState.set(timeframeCopy.get("cid"), true)
    });
  },

  handleRemoveTimeframeRequest(timeframeId) {
    this.setState({
      idToErrorsByAttribute: this.state.idToErrorsByAttribute.delete(timeframeId)
    });
    const index = this.getTimeframeIndex(timeframeId);
    const timeframe = this.state.timeframes.get(index);
    const timeframeRequired = `You must have at least 1 timeframe set up and visible in ${Branding.brandingName}`;
    if (timeframe.get("cid")) {
      const newTimeframes = this.state.timeframes.delete(index);
      this.setState({
        timeframes: newTimeframes,
        hasUnsavedChanges: newTimeframes.some(tf => tf.get("isChanged")),
        updateError: newTimeframes.isEmpty() ? timeframeRequired : null
      });
    } else {
      const timeframeToRemove = timeframe
          .set("isDeleted", true)
          .set("isChanged", true);
      const newTimeframes = this.state.timeframes.set(index, timeframeToRemove);
      this.setState({
        timeframes: newTimeframes,
        hasUnsavedChanges: true,
        updateError: newTimeframes.every(tf => tf.get("isDeleted")) ? timeframeRequired : null
      });
    }
  },

  handleEditTimeframeRequest(timeframeId) {
    this.setState({
      idToEditState: this.state.idToEditState.set(timeframeId, true)
    });
  },

  handleTimeframeChange(timeframe) {
    const {timeframes, idToErrorsByAttribute, timeframeChangeById} = this.state;
    const timeframeId = timeframe.get("id") || timeframe.get("cid");
    const errorsFound = this.validateTimeframe(timeframe);
    const newIdToErrorsByAttribute = errorsFound.isEmpty() ?
        idToErrorsByAttribute.delete(timeframeId) : idToErrorsByAttribute.set(timeframeId, errorsFound);
    const newTimeframes = timeframes.set(this.getTimeframeIndex(timeframeId), timeframe.set("isChanged", true));
    const isAllTimeframesHidden = newTimeframes.every(tf => !tf.get("visible"));
    let updateError = null;
    if (isAllTimeframesHidden) {
      updateError = `You must have at least 1 timeframe visible in ${Branding.brandingName}`;
    } else if (!newIdToErrorsByAttribute.isEmpty()) {
      updateError = errorsNeedFixingMsg;
    }
    let newTimeframeChangeById = timeframeChangeById;
    if (timeframe.get("id")) {
      newTimeframeChangeById = newTimeframeChangeById.set(timeframeId, timeframe);
    }
    this.setState({
      timeframes: sortTimeframes(newTimeframes),
      timeframeChangeById: newTimeframeChangeById,
      hasUnsavedChanges: true,
      idToErrorsByAttribute: newIdToErrorsByAttribute,
      updateError,
      updateSuccess: null
    });
  },

  handleCancelChangesRequest() {
    this.setState({
      timeframeChangeById: this.state.timeframeChangeById.clear(),
      hasUnsavedChanges: false,
      idToEditState: this.state.idToEditState.clear(),
      idToErrorsByAttribute: this.state.idToErrorsByAttribute.clear(),
      updateError: null
    });
    this.loadTimeframes();
  },

  handleSaveChangesRequest() {
    const idToErrorsByAttribute = this.validateAllTimeframes();
    if (!idToErrorsByAttribute.isEmpty()) {
      this.setState({
        idToErrorsByAttribute,
        updateError: errorsNeedFixingMsg
      });
    } else {
      const duplicateNamesError = this.checkForDuplicateTimeframeNames();
      if (duplicateNamesError) {
        this.setState({
          updateError: duplicateNamesError
        });
      } else {
        const timeframesToRemove = this.state.timeframes.filter(tf => tf.get("isDeleted"));
        if (timeframesToRemove.isEmpty()) {
          this.saveChanges();
        } else {
          this.openConfirmRemoveTimeframesDialog();
        }
      }
    }
  },

  checkForDuplicateTimeframeNames() {
    const duplicateNames = this.state.timeframes
        .filter(timeframe => timeframe.get("name").length > 0)
        .countBy(timeframe => timeframe.get("name").toLowerCase())
        .filter(nameCount => nameCount > 1)
        .keySeq();
    const duplicateNamesStr = this.state.timeframes
        .filter(timeframe => duplicateNames.toJS().includes(timeframe.get("name").toLowerCase()))
        .map(timeframe => timeframe.get("name"))
        .toSet()
        .join(", ");

    const duplicateShortNames = this.state.timeframes
        .filter(timeframe => timeframe.get("shortName").length > 0)
        .countBy(timeframe => timeframe.get("shortName").toLowerCase())
        .filter(nameCount => nameCount > 1)
        .keySeq();
    const duplicateShortNamesStr = this.state.timeframes
        .filter(timeframe => duplicateShortNames.toJS().includes(timeframe.get("shortName").toLowerCase()))
        .map(timeframe => timeframe.get("shortName"))
        .toSet()
        .join(", ");

    if (!duplicateNames.isEmpty() && !duplicateShortNames.isEmpty()) {
      return (
          <p style={{fontSize: "inherit", fontWeight: "inherit", lineHeight: "inherit", marginBottom: "inherit"}}>
            {`Timeframe names and short names must be unique.`}
            <br />
            {`Please change one of the following duplicated names: ${duplicateNamesStr}`}
            <br />
            {`and one of the following duplicated short names: ${duplicateShortNamesStr}`}
          </p>
      );
    } else if (!duplicateNames.isEmpty()) {
      return `Timeframe names must be unique. Please change one of the following duplicated names: ${duplicateNamesStr}`;
    } else if (!duplicateShortNames.isEmpty()) {
      return `Timeframe short names must be unique. Please change one of the following duplicated short names: ${duplicateShortNamesStr}`;
    }
  },

  validateTimeframe(timeframe) {
    let errorByAttribute = Immutable.Map();
    const name = timeframe.get("name");
    if (!name || name.length === 0) {
      errorByAttribute = errorByAttribute.set("name", "Timeframe name is required");
    }
    const timeframeDescription = timeframe.get("timeframeDescription");
    if (!timeframe.get("isCube19Default") && timeframeDescription.get("timeframeType") === "FIXED") {
      const dateRangeError = validateDateRange(
          timeframeDescription.getIn(["start", "date"]),
          timeframeDescription.getIn(["end", "date"])
      );
      if (dateRangeError) {
        errorByAttribute = errorByAttribute.set("dateRange", dateRangeError);
      }
    }
    return errorByAttribute;
  },

  validateAllTimeframes() {
    let idToErrorsByAttribute = Immutable.Map();
    this.state.timeframes.forEach(timeframe => {
      const errorsFound = this.validateTimeframe(timeframe);
      const id = timeframe.get("id") || timeframe.get("cid");
      idToErrorsByAttribute = errorsFound.isEmpty() ?
          idToErrorsByAttribute.delete(id) : idToErrorsByAttribute.set(id, errorsFound);
    });
    return idToErrorsByAttribute;
  },

  openConfirmRemoveTimeframesDialog() {
    this.setState({
      isConfirmRemoveTimeframesDialogOpen: true
    });
  },

  handleCancelRemoveTimeframesRequest() {
    this.closeConfirmRemoveTimeframesDialog();
    const timeframes = this.state.timeframes
        .map(timeframe => timeframe.get("isDeleted") ? timeframe.set("isDeleted", false) : timeframe);
    this.setState({
      timeframes: sortTimeframes(timeframes)
    });
  },

  handleConfirmRemoveTimeframesRequest() {
    this.closeConfirmRemoveTimeframesDialog();
    this.saveChanges();
  },

  closeConfirmRemoveTimeframesDialog() {
    this.setState({
      isConfirmRemoveTimeframesDialogOpen: false
    });
  },

  saveChanges() {
    const timeframesToSave = this.state.timeframes
        .filter(tf => tf.get("id") || (tf.get("cid") && !tf.get("isDeleted")))
        .filter(tf => tf.get("cid") || tf.get("isChanged"));
    let timeframeChangeById = this.state.timeframeChangeById;
    timeframesToSave.forEach(timeframe => {
      if (this.state.timeframeChangeById.has(timeframe.get("id"))) {
        timeframeChangeById = timeframeChangeById.set(timeframe.get("id"), timeframe);
      }
    });
    this.setState({
      isUpdating: true,
      idToErrorsByAttribute: this.state.idToErrorsByAttribute.clear(),
      updateError: null
    });
    let newTimeframes = Immutable.Set();
    let deletedTimeframes = Immutable.Set();
    const timeframePromises = timeframesToSave
        .map(timeframe => {
          if (timeframe.get("id") && timeframe.get("isDeleted")) {
            deletedTimeframes = deletedTimeframes.add(timeframe);
            return removeTimeframe(timeframe.get("id"));
          } else if (timeframe.get("cid")) {
            newTimeframes = newTimeframes.add(timeframe.delete("cid"));
            return createTimeframe(timeframe);
          } else {
            return updateTimeframe(timeframe);
          }
        });
    Promise
        .all(timeframePromises)
        .then(() => {
          const timeframeChangesToAudit = newTimeframes
              .union(deletedTimeframes)
              .union(this.state.timeframeChangeById.toSet())
              .toList()
              .map(timeframe => parseDatesForJson(timeframe.delete("isChanged")));
          const timeframeIds = timeframeChangesToAudit
              .map(t => t.get("id"))
              .toSet();
          const originalTimeframes = this.state.initialTimeframes
              .filter(t => timeframeIds.includes(t.get("id")))
              .map(parseDatesForJson);
          if (!timeframeChangesToAudit.isEmpty()) {
            Auditor.audit("timeframes:changed", {
              originalTimeframes: originalTimeframes.toJS(),
              changedTimeframes: timeframeChangesToAudit.toJS()
            });
          }
          this.setState({
            timeframeChangeById: this.state.timeframeChangeById.clear(),
            hasUnsavedChanges: false,
            isUpdating: false,
            updateSuccess: "Timeframe changes saved."
          }, () => this.loadTimeframes());
        }, error => {
          const errorJson = error.responseJSON;
          const errorMsg = errorJson.type === "TIMEFRAME_TOO_LONG" ? errorJson.message : unexpectedErrorMsg;
          this.setState({
            isUpdating: false,
            updateError: `Unable to save all timeframe changes. ${errorMsg}`
          });
        });
  },

  getTimeframeIndex(timeframeId) {
    return this.state.timeframes.findIndex(tf => tf.get("id") === timeframeId || tf.get("cid") === timeframeId);
  }

}));

const TimeframeList = createReactClass({

  displayName: "TimeframeList",

  mixins: [PureRenderMixin],

  render() {
    const {
      timeframes,
      idToEditState,
      idToErrorsByAttribute,
      isDisabled,
      onTimeframeChange,
      onEditTimeframeRequest,
      onCopyTimeframeRequest,
      onRemoveTimeframeRequest,
      theme
    } = this.props;
    const clientDefaultTimeframe = TimeframeRepo.getDefaultForClient();
    return (
        <Droppable droppableId="timeframe-list" type="ROW" direction="vertical">
          {(droppableProvided, droppableSnapshot) => (
              <div
                  ref={droppableProvided.innerRef}
                  style={{
                    width: getTotalColumnsWidth(),
                    backgroundColor: droppableSnapshot.isDraggingOver ? (theme.themeId === "light"
                        ? theme.palette.background.card
                        : "#2c3e50") : "transparent",
                    position: "relative"
                  }}
                  {...droppableProvided.droppableProps}>
                {timeframes.map((timeframe, index) => {
                  const timeframeId = timeframe.get("id") || timeframe.get("cid");
                  const isClientDefaultTimeframe = timeframeId === clientDefaultTimeframe.get("id");
                  const isEditable = idToEditState.get(timeframeId);
                  const isOddNumberRow = index % 2 === 0;
                  return (
                      <Draggable
                          key={timeframeId}
                          draggableId={timeframeId}
                          index={index}
                          type="ROW"
                          isDragDisabled={!timeframe.get("visible") || isDisabled}>
                        {(draggableProvided) => (
                            <div>
                              <div
                                  className="table-dark"
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}>
                                {isEditable ?
                                    <EditableTimeframe
                                        theme={theme}
                                        config={timeframe}
                                        onChange={onTimeframeChange}
                                        onCopyClick={() => onCopyTimeframeRequest(timeframeId)}
                                        onRemoveClick={() => onRemoveTimeframeRequest(timeframeId)}
                                        idToErrorsByAttribute={idToErrorsByAttribute}
                                        isDisabled={isDisabled}
                                        isClientDefaultTimeframe={isClientDefaultTimeframe}
                                        isOddNumberRow={isOddNumberRow}
                                        dragHandleProps={draggableProvided.dragHandleProps} /> :
                                    <SimpleTimeframe
                                        theme={theme}
                                        config={timeframe}
                                        onChange={onTimeframeChange}
                                        isDisabled={isDisabled}
                                        isClientDefaultTimeframe={isClientDefaultTimeframe}
                                        onCopyClick={() => onCopyTimeframeRequest(timeframeId)}
                                        onEditClick={() => onEditTimeframeRequest(timeframeId)}
                                        isOddNumberRow={isOddNumberRow}
                                        dragHandleProps={draggableProvided.dragHandleProps} />}
                              </div>
                              {draggableProvided.placeholder}
                            </div>
                        )}
                      </Draggable>
                  );
                })}
                {droppableProvided.placeholder}
              </div>
          )}
        </Droppable>
    );
  }

});

const SimpleTimeframe = pure(({
  config,
  onChange,
  isDisabled,
  isClientDefaultTimeframe,
  onCopyClick,
  onEditClick,
  isOddNumberRow,
  dragHandleProps,
  theme
}) => {
  const timeframeDescription = config.get("timeframeDescription");
  const dragHandleStyle = {
    cursor: "move",
    display: config.get("visible") ? "inline-block" : "none"
  };
  const defaultTagStyle = theme => ({
    textTransform: "uppercase",
    fontSize: "0.725rem",
    color: theme.palette.text.inverted,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 3,
    padding: "3px 5px",
    marginLeft: 8
  });
  const id = config.get("id") || config.get("cid");
  const name = config.get("name");
  const shortName = config.get("shortName");
  const isCube19Default = config.get("isCube19Default");
  return (
      <div className={`striped-rows--${isOddNumberRow ? "odd" : "even"}`} style={rowStyle}>
        <div style={{textAlign: "center", minWidth: getColWidth("order")}}>
          <i className="fa fa-arrows" style={dragHandleStyle} {...dragHandleProps} />
        </div>
        <div
            data-tip data-for={`name-${id}`}
            style={{
              ...columnStyle,
              ...overflowStyle,
              fontFamily: theme.typography.fontFamilyBold,
              display: "flex",
              alignItems: "center",
              minWidth: getColWidth("name")
            }}>
          <span>{name}</span>
          {name.length > displayNameMaxLength &&
              <Tooltip id={`name-${id}`} place="top" type="light" effect="solid">
                {name}
              </Tooltip>}
          {isClientDefaultTimeframe && <span style={defaultTagStyle(theme)}>Default</span>}
        </div>
        <div
            style={{
              ...columnStyle,
              ...overflowStyle,
              minWidth: getColWidth("trueName")
            }}>
          <TrueNameLabel
              trueName={isCube19Default ? config.get("trueName") : null}
              customStyle={{
                paddingLeft: !isCube19Default ? 10 : 0,
                paddingRight: !isCube19Default ? 10 : 0
              }} />
        </div>
        <div
            data-tip data-for={`short-name-${id}`}
            style={{
              ...columnStyle,
              ...overflowStyle,
              minWidth: getColWidth("shortName")
            }}>
          {`${shortName}`}
          {shortName.length > shortNameMaxLength &&
              <Tooltip id={`short-name-${id}`} place="top" type="light" effect="solid">
                {name}
              </Tooltip>}
        </div>
        <div style={{...columnStyle, minWidth: getColWidth("timeframeType")}}>
          {isCube19Default ?
              cube19DefaultLabel : getTimeframeTypeDisplayLabel(timeframeDescription.get("timeframeType"))}
        </div>
        <div style={{...columnStyle, minWidth: getColWidth("dateRange")}}>
          <FixedDateRangeLabel theme={theme} startDate={config.get("start")} endDate={config.get("end")} />
          {!isCube19Default && timeframeDescription.get("timeframeType").includes("DYNAMIC") &&
              <DynamicDateRangeInfo
                  timeframeType={timeframeDescription.get("timeframeType")}
                  start={timeframeDescription.get("start")}
                  end={timeframeDescription.get("end")} />}
        </div>
        <div style={{...columnStyle, minWidth: getColWidth("visible")}}>
          <Checkbox
              label=""
              style={{height: 30}}
              checked={config.get("visible")}
              onCheck={(e, isChecked) => onChange(config.set("visible", isChecked))}
              disabled={isDisabled || isClientDefaultTimeframe}
              iconStyle={{marginRight: 3, marginLeft: 3}} />
        </div>
        <div
            style={{
              ...columnStyle,
              display: "flex",
              justifyContent: "space-evenly",
              minWidth: getColWidth("actions")
            }}>
          {!isCube19Default ?
              <IconButton
                  container="column"
                  label="Copy"
                  icon="clone"
                  size="small"
                  type="bright"
                  onClick={onCopyClick} /> :
              <span style={{width: 35.56}} />}
          <span style={{width: 57.34, textAlign: "center"}}>
                    <IconButton
                        container="column"
                        label="Edit"
                        icon="edit"
                        type="bright"
                        size="small"
                        onClick={onEditClick} />
                </span>
        </div>
      </div>
  );
}, "SimpleTimeframe");

const EditableTimeframe = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {
      config,
      isDisabled,
      isClientDefaultTimeframe,
      onChange,
      onCopyClick,
      onRemoveClick,
      idToErrorsByAttribute,
      isOddNumberRow,
      dragHandleProps,
      theme
    } = this.props;

    const id = config.get("id") || config.get("cid");
    const isCube19Default = config.get("isCube19Default");
    const timeframeDescription = config.get("timeframeDescription");
    const timeframeType = config.getIn(["timeframeDescription", "timeframeType"]);
    const pathToStart = ["timeframeDescription", "start"];
    const pathToEnd = ["timeframeDescription", "end"];
    const start = isCube19Default ? config.get("start") : timeframeDescription.get("start");
    const end = isCube19Default ? config.get("end") : timeframeDescription.get("end");
    const colWidthPadding = 25;
    const materialUiInputHeight = 48;
    const errorsByAttribute = idToErrorsByAttribute.get(id) || Immutable.Map();
    const dragHandleStyle = {
      cursor: "pointer",
      display: config.get("visible") ? "inline-block" : "none",
      height: materialUiInputHeight,
      lineHeight: `${materialUiInputHeight}px`
    };
    const defaultTagStyle = theme => ({
      display: "block",
      fontWeight: 600,
      fontSize: "0.725rem",
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      textStyle: "italic"
    });
    return (
        <div className={`striped-rows--${isOddNumberRow ? "odd" : "even"}`} style={getEditableRowStyle()}>
          <div style={{textAlign: "center", minWidth: getColWidth("order")}}>
            <i className="fa fa-arrows" style={dragHandleStyle} {...dragHandleProps} />
          </div>
          <div style={{...columnStyle, minWidth: getColWidth("name")}}>
            {isClientDefaultTimeframe && <span style={defaultTagStyle(theme)}>Default</span>}
            <TextField
                variant="standard"
                placeholder="Timeframe name"
                error={!!errorsByAttribute.get("name")}
                helperText={errorsByAttribute.get("name") || ""}
                value={config.get("name")}
                onChange={e => onChange(config.set("name", e.target.value.substring(0, displayNameMaxLength)))}
                onBlur={e => {
                  const name = e.target.value;
                  onChange(config.set("name", name.substring(0, displayNameMaxLength).trim()));
                }}
                disabled={isDisabled}
                style={{marginTop: "0.5rem", width: getColWidth("name") - colWidthPadding}} />
          </div>
          <div style={{...columnStyle, minWidth: getColWidth("trueName")}}>
            <TrueNameLabel
                trueName={isCube19Default ? config.get("trueName") : null}
                customStyle={{
                  lineHeight: `${materialUiInputHeight}px`,
                  paddingLeft: !isCube19Default ? 10 : 0,
                  paddingRight: !isCube19Default ? 10 : 0
                }} />
          </div>
          <div style={{...columnStyle, minWidth: getColWidth("shortName")}}>
            <TextField
                variant="standard"
                placeholder="Short name"
                error={!!errorsByAttribute.get("shortName")}
                helperText={errorsByAttribute.get("shortName") || ""}
                value={config.get("shortName")}
                onChange={e => onChange(config.set("shortName", e.target.value.substring(0, shortNameMaxLength)))}
                onBlur={e => {
                  const shortName = e.target.value;
                  onChange(config.set("shortName", shortName.substring(0, shortNameMaxLength).trim()));
                }}
                disabled={isDisabled}
                style={{marginTop: "0.5rem", width: getColWidth("shortName") - colWidthPadding}} />
          </div>
          <div
              style={{
                ...columnStyle,
                paddingTop: 4,
                minWidth: getColWidth("timeframeType"),
                alignSelf: isCube19Default ? "center" : null
              }}>
            {isCube19Default ?
                cube19DefaultLabel :
                <InlineRadioButtons
                    options={["FIXED", "DYNAMIC"]}
                    selected={timeframeType.includes("DYNAMIC") ? "DYNAMIC" : "FIXED"}
                    onChange={timeframeType => {
                      const typeDisplayLabel = timeframeType === "DYNAMIC" ? "DYNAMIC_SOLO" : "FIXED";
                      this.handleTimeframeTypeChange(typeDisplayLabel);
                    }}
                    isDisabled={isDisabled} />}
          </div>
          <div
              style={{
                ...columnStyle,
                paddingTop: timeframeType === "FIXED" ? 4 : 0,
                minWidth: getColWidth("dateRange"),
                alignSelf: isCube19Default ? "center" : null
              }}>
            {isCube19Default && <FixedDateRangeLabel theme={theme} startDate={start} endDate={end} />}
            {(!isCube19Default && timeframeType === "FIXED") &&
                <FixedDateRangePicker
                    startDate={config.getIn([...pathToStart, "date"])}
                    onStartDateChange={startDate => onChange(config.setIn([...pathToStart, "date"], startDate))}
                    endDate={config.getIn([...pathToEnd, "date"])}
                    onEndDateChange={endDate => onChange(config.setIn([...pathToEnd, "date"], endDate))}
                    isDisabled={isDisabled} />}
            {(!isCube19Default && timeframeType.includes("DYNAMIC")) &&
                <DynamicDateRangePicker
                    theme={theme}
                    timeframeType={timeframeType}
                    onTimeframeTypeChange={this.handleTimeframeTypeChange}
                    start={config.getIn(pathToStart)}
                    onStartChange={start => onChange(config.setIn(pathToStart, start))}
                    end={config.getIn(pathToEnd)}
                    onEndChange={end => onChange(config.setIn(pathToEnd, end))}
                    isDisabled={isDisabled} />}
            {errorsByAttribute.get("dateRange") && <ErrorMsg text={errorsByAttribute.get("dateRange")} />}
          </div>
          <div style={{...columnStyle, paddingTop: 11, minWidth: getColWidth("visible")}}>
            <Checkbox
                label=""
                checked={config.get("visible")}
                onCheck={(e, isChecked) => onChange(config.set("visible", isChecked))}
                disabled={isDisabled || isClientDefaultTimeframe}
                iconStyle={{marginRight: 3, marginLeft: 3}} />
          </div>
          {!isCube19Default &&
              <div
                  style={{
                    ...columnStyle,
                    paddingTop: 9,
                    display: "flex",
                    justifyContent: "space-evenly",
                    minWidth: getColWidth("actions")
                  }}>
                <IconButton
                    label="Copy"
                    icon="clone"
                    type="bright"
                    onClick={onCopyClick}
                    container="column"
                    size="large" />
                <IconButton
                    label="Remove"
                    icon="times"
                    type="alert"
                    onClick={onRemoveClick}
                    container="column"
                    size="large" />
              </div>}
        </div>
    );
  },

  handleTimeframeTypeChange(timeframeType) {
    const timeframeTypeByDefaultTimeframeDescription = {
      FIXED: getDefaultFixedTimeframeDescription,
      DYNAMIC_SOLO: getDefaultDynamicSoloTimeframeDescription,
      DYNAMIC_DUO: getDefaultDynamicDuoTimeframeDescription
    };
    const getTimeframeDescription = timeframeTypeByDefaultTimeframeDescription[timeframeType];
    const newTimeframeDescription = getTimeframeDescription(this.props.config.get("timeframeDescription"));
    this.props.onChange(this.props.config.set("timeframeDescription", newTimeframeDescription));
  }

});

const FixedDateRangePicker = pure(({
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  isDisabled
}) => {
  const hideDatePickerError = true;
  const datePickerContainerStyle = {
    display: "inline-block",
    width: 135
  };
  return (
      <div>
        <div style={datePickerContainerStyle}>
          <DatePicker
              value={startDate}
              onDateChange={onStartDateChange}
              isDisabled={isDisabled}
              hideError={hideDatePickerError} />
        </div>
        <i className="fa fa-arrow-right" style={{fontSize: "0.875rem", paddingLeft: 5, paddingRight: 5}} />
        <div style={datePickerContainerStyle}>
          <DatePicker
              minDate={startDate}
              value={endDate}
              onDateChange={onEndDateChange}
              isDisabled={isDisabled}
              hideError={hideDatePickerError} />
        </div>
      </div>
  );
});

const DynamicDateRangePicker = pure(({
  timeframeType,
  onTimeframeTypeChange,
  start,
  onStartChange,
  end,
  onEndChange,
  isDisabled,
  theme
}) => {
  const numberFieldWidth = 58;
  const pointInTimePickerWidth = 128;
  const timeframePickerWidth = 175;
  const textDivStyle = {
    paddingTop: "0.5rem",
    marginRight: "0.5rem",
    display: "inline-block"
  };
  return (
      <div style={{fontSize: "0.9rem"}}>
        <div>
          {timeframeType === "DYNAMIC_DUO" &&
              <div style={{...textDivStyle, fontWeight: "bold"}}>
                Starts
              </div>}
          <TextField
              variant="standard"
              id="start-offset"
              style={{display: "inline-block", width: numberFieldWidth, marginRight: "0.5rem", marginBottom: "0.5rem"}}
              type="number"
              inputProps={{min: 0}}
              value={start.get("offset") ? Math.abs(start.get("offset")) : start.get("offset")}
              onChange={(evt, value) => {
                const newOffsetValue = value ? getNewOffsetValue(value, start.get("pointInTime")) : value;
                onStartChange(start.set("offset", newOffsetValue));
              }}
              onBlur={evt => {
                const value = evt.target.value;
                const offsetValue = value ? getNewOffsetValue(value, start.get("pointInTime")) : value;
                onStartChange(start.set("offset", validateOffsetValue(offsetValue)));
              }}
              disabled={isDisabled} />
          <div style={textDivStyle}>
            day(s)
          </div>
          <div>
            <div style={{display: "inline-block", verticalAlign: "middle", width: pointInTimePickerWidth}}>
              <PointInTimePicker
                  pointInTime={start.get("pointInTime")}
                  onChange={pointInTime => {
                    const newOffsetValue = getNewOffsetValue(start.get("offset"), pointInTime);
                    const newDynamicStart = start
                        .set("offset", validateOffsetValue(newOffsetValue))
                        .set("pointInTime", pointInTime);
                    onStartChange(newDynamicStart);
                  }}
                  isDisabled={isDisabled || start.get("offset") === 0} />
            </div>
            <div style={{display: "inline-block", verticalAlign: "middle", width: timeframePickerWidth}}>
              <Select
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  disabled={isDisabled}
                  placeholder="Select start timeframe"
                  selectedValue={start.get("date")}
                  options={getDynamicDateRangeTimeframeOptions()}
                  onChange={value => onStartChange(start.set("date", value))} />
            </div>
          </div>
        </div>
        {timeframeType === "DYNAMIC_DUO" &&
            <div style={{borderTop: "1px solid #888", marginTop: "1rem", paddingTop: "0.5rem"}}>
              <div style={{...textDivStyle, fontWeight: "bold"}}>
                Ends
              </div>
              <TextField
                  variant="standard"
                  id="end-offset"
                  type="number"
                  inputProps={{min: 0}}
                  style={{
                    display: "inline-block",
                    width: numberFieldWidth,
                    marginRight: "0.5rem",
                    marginBottom: "0.5rem"
                  }}
                  value={end.get("offset") ? Math.abs(end.get("offset")) : end.get("offset")}
                  onChange={(evt, value) => {
                    const newOffsetValue = value ? getNewOffsetValue(value, end.get("pointInTime")) : value;
                    onEndChange(end.set("offset", newOffsetValue));
                  }}
                  onBlur={evt => {
                    const value = evt.target.value;
                    const offsetValue = value ? getNewOffsetValue(value, end.get("pointInTime")) : value;
                    onEndChange(end.set("offset", validateOffsetValue(offsetValue)));
                  }}
                  disabled={isDisabled} />
              <div style={textDivStyle}>
                day(s)
              </div>
              <div>
                <div style={{display: "inline-block", verticalAlign: "middle", width: pointInTimePickerWidth}}>
                  <PointInTimePicker
                      pointInTime={end.get("pointInTime")}
                      onChange={pointInTime => {
                        const newOffset = getNewOffsetValue(end.get("offset"), pointInTime);
                        const newDynamicEnd = end
                            .set("offset", validateOffsetValue(newOffset))
                            .set("pointInTime", pointInTime);
                        onEndChange(newDynamicEnd);
                      }}
                      isDisabled={isDisabled || end.get("offset") === 0} />
                </div>
                <div style={{display: "inline-block", verticalAlign: "middle", width: timeframePickerWidth}}>
                  <Select
                      isMulti={false}
                      isClearable={false}
                      isSearchable={false}
                      disabled={isDisabled}
                      placeholder="Select end timeframe"
                      selectedValue={end.get("date")}
                      options={getDynamicDateRangeTimeframeOptions()}
                      onChange={value => onEndChange(end.set("date", value))} />
                </div>
              </div>
            </div>
        }
        <div style={columnStyle}>
          <DynamicTimeframeTypeToggle theme={theme} timeframeType={timeframeType} onClick={onTimeframeTypeChange} />
        </div>
      </div>
  );
});

const DynamicTimeframeTypeToggle = pure(({theme, timeframeType, onClick}) => {
  const style = theme => ({
    display: "inline-block",
    padding: "0.5rem 0",
    cursor: "pointer",
    color: theme.palette.textColor,
    ":hover": {
      color: "#999"
    }
  });
  const isDynamicSoloTimeframeType = timeframeType === "DYNAMIC_SOLO";
  return (
      <div style={style(theme)} onClick={() => onClick(isDynamicSoloTimeframeType ? "DYNAMIC_DUO" : "DYNAMIC_SOLO")}>
        <i className={`fa fa-${isDynamicSoloTimeframeType ? "plus-circle" : "minus-circle"}`} />
        <span style={{fontWeight: "bold", paddingLeft: 8, paddingRight: 8}}>
                {isDynamicSoloTimeframeType ? "Set a dynamic end range" : "Remove dynamic end range"}
            </span>
      </div>
  );
});

const PointInTimePicker = pure(({pointInTime, onChange, isDisabled}) => (
    <InlineRadioButtons
        options={["BEFORE", "AFTER"]}
        selected={pointInTime}
        onChange={onChange}
        isDisabled={isDisabled} />
));

const MoreInfoBtn = pure(({onClick}) => {
  const style = {
    cursor: "pointer",
    paddingLeft: 3,
    paddingRight: 3,
    color: "inherit",
    ":hover": {
      color: "#fff"
    }
  };
  return <i className="fa fa-info-circle" style={style} onClick={onClick} />;
});

const TrueNameLabel = pure(({trueName, customStyle = {}}) => {
  const tooltipId = `true-name-${Math.random()}`;
  return (
      <span
          data-tip data-for={tooltipId}
          style={{
            color: "#999",
            ...overflowStyle,
            ...customStyle
          }}>
            {trueName ? trueName : "--"}
        {(trueName && trueName.length > shortNameMaxLength) &&
            <Tooltip id={tooltipId} place="top" type="light" effect="solid">
              {trueName}
            </Tooltip>}
        </span>
  );
});

const FixedDateRangeLabel = pure(({theme, startDate, endDate}) => (
    <div style={{display: "flex", alignItems: "center", fontFamily: theme.typography.fontFamilyBold}}>
      <span>{getDisplayDate(startDate)}</span>
      <i className="fa fa-arrow-right" style={{fontSize: "0.875rem", paddingLeft: 10, paddingRight: 10}} />
      <span>{getDisplayDate(endDate)}</span>
    </div>
));

const DynamicDateRangeInfo = pure(({timeframeType, start, end}) => {
  const startOffset = start.get("offset");
  const dynamicDateStartLabel = dynamicDateToLabel[start.get("date")];
  const textStyle = {
    color: "#999",
    fontSize: "0.85rem",
    fontStyle: "italic"
  };
  if (timeframeType === "DYNAMIC_SOLO") {
    const containerStyle = {
      display: "flex",
      alignItems: "center",
      borderTop: "1px solid #888",
      marginTop: "0.25rem",
      paddingTop: "0.5rem",
      ...textStyle
    };
    return (
        <div style={containerStyle}>
          <div>{`${dynamicDateStartLabel} ${startOffset > 0 ? "+" : ""}${startOffset} day(s)`}</div>
        </div>
    );
  } else if (timeframeType === "DYNAMIC_DUO") {
    const daysFromStart = Math.abs(startOffset);
    const dynamicDateStartLabel = dynamicDateToLabel[start.get("date")];
    const startFromLabel = `Starts ${daysFromStart} day(s) ${startOffset > 0 ? "after" :
        "before"} ${dynamicDateStartLabel}`;
    const endOffset = end.get("offset");
    const daysFromEnd = Math.abs(endOffset);
    const endAtLabel = `Ends ${daysFromEnd} day(s) ${endOffset > 0 ? "after" : "before"} ${dynamicDateToLabel[end.get(
        "date")]}`;
    const listStyle = {
      listStylePosition: "inside",
      marginBottom: 0,
      marginTop: "0.25rem",
      paddingTop: "0.25rem",
      borderTop: "1px solid #888",
      ...textStyle
    };
    return (
        <ul style={listStyle}>
          <li><span style={{marginLeft: -10}}>{startFromLabel}</span></li>
          <li><span style={{marginLeft: -10}}>{endAtLabel}</span></li>
        </ul>
    );
  }
});

const ConfirmRemoveTimeframesDialog = pure(({
  theme,
  timeframesToRemove,
  onCloseRequest,
  onConfirmRequest
}) => (
    <Dialog
        title="Remove Timeframes"
        actions={[
          <TextButton
              key="close-btn"
              type="dark"
              label="Cancel"
              style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}
              onClick={onCloseRequest} />,
          <TextButton
              key="confirm-btn"
              type="primary"
              label="Yes"
              style={{marginLeft: "0.5rem", marginRight: "0.5rem"}}
              onClick={onConfirmRequest} />
        ]}
        actionsContainerStyle={{paddingLeft: "2rem", paddingRight: "2rem", paddingBottom: "2rem"}}
        titleStyle={{color: theme.palette.primary.main, fontSize: "1rem"}}
        bodyStyle={{color: theme.palette.textColor}}
        autoDetectWindowHeight={true}
        onRequestClose={onCloseRequest}
        open={true}>
      <p>Are you sure you want to remove the following timeframe(s)?</p>
      <ul style={{listStylePosition: "inside"}}>
        {timeframesToRemove.map(timeframe => <li key={timeframe.get("id")}>{timeframe.get("name")}</li>)}
      </ul>
    </Dialog>
));

const overflowStyle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
};

const columnStyle = {
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem"
};

const colNameToWidth = {
  order: 30,
  name: 250,
  trueName: 140,
  shortName: 140,
  timeframeType: 150,
  dateRange: 320,
  visible: 50,
  actions: 125
};

const getColWidth = colName => colNameToWidth[colName];

const getTotalColumnsWidth = () => Object
    .keys(colNameToWidth)
    .reduce((total, col) => total + colNameToWidth[col], 0);

const rowStyle = {
  display: "flex",
  alignItems: "center",
  padding: 5,
  width: getTotalColumnsWidth(),
  borderBottom: "1px solid #111"
};

const getEditableRowStyle = () => ({
  ...rowStyle,
  alignItems: "flex-start"
});

const getDisplayDate = date => date.format(displayDatePattern);

const toMysqlDate = date => date.format(mysqlDatePattern);

const toMomentDate = mysqlDateStr => moment(mysqlDateStr, mysqlDatePattern);

const validateDateRange = (startDate, endDate) => {
  if (!startDate.isValid() || !endDate.isValid()) {
    return `Invalid date. Date must be in the format ${displayDatePattern}.`;
  } else if (endDate.isBefore(startDate)) {
    return "Start Date cannot be after End Date.";
  } else if (endDate.diff(startDate, "days") > maxTimeframeDays) {
    return `Timeframe duration cannot be more than ${maxTimeframeDays} days.`;
  }
};

const getNewOffsetValue = (offset, pointInTime) => {
  if (pointInTime === "BEFORE") {
    return offset > 0 ? -offset : offset;
  } else {
    return Math.abs(offset);
  }
};

const validateOffsetValue = offset => {
  if (offset.length === 0) {
    return 0;
  } else if (offset >= minOffset && offset <= maxOffset) {
    return parseInt(offset, 10);
  } else if (offset < minOffset) {
    return minOffset;
  } else if (offset > maxOffset) {
    return maxOffset;
  } else {
    return 0;
  }
};

const getTimeframeTypeDisplayLabel = timeframeType => timeframeType === "FIXED" ? "Fixed" : "Dynamic";

const getDefaultCustomTimeframe = () => Immutable
    .fromJS({
      cid: `new-${Math.random()}`,
      isCube19Default: false,
      name: "",
      shortName: "",
      trueName: "",
      timeframeDescription: getDefaultFixedTimeframeDescription(),
      visible: true,
      isDeleted: false,
      order: 0
    });

const getDefaultFixedTimeframeDescription = () => Immutable
    .fromJS({
      timeframeType: "FIXED",
      start: {
        date: moment(),
        offset: null
      },
      end: {
        date: moment(),
        offset: null
      }
    });

const getDefaultDynamicSoloTimeframeDescription = timeframeDescription => {
  const defaultTimeframeDescription = Immutable
      .fromJS({
        timeframeType: "DYNAMIC_SOLO",
        start: {
          date: getDefaultDynamicTimeframeStart(),
          offset: 1,
          pointInTime: "AFTER"
        },
        end: null
      });
  const currentTimeframeType = timeframeDescription.get("timeframeType");
  if (currentTimeframeType === "DYNAMIC_DUO") {
    return defaultTimeframeDescription.set("start", timeframeDescription.get("start"));
  } else {
    return defaultTimeframeDescription;
  }
};

const getDefaultDynamicDuoTimeframeDescription = timeframeDescription => {
  const defaultTimeframeDescription = Immutable
      .fromJS({
        timeframeType: "DYNAMIC_DUO",
        start: {
          date: getDefaultDynamicTimeframeStart(),
          offset: 1,
          pointInTime: "AFTER"
        },
        end: {
          date: getDefaultDynamicTimeframeStart(),
          offset: 1,
          pointInTime: "AFTER"
        }
      });
  const currentTimeframeType = timeframeDescription.get("timeframeType");
  if (currentTimeframeType === "DYNAMIC_SOLO") {
    return defaultTimeframeDescription.set("start", timeframeDescription.get("start"));
  } else {
    return defaultTimeframeDescription;
  }
};

const getDefaultDynamicTimeframeStart = () => {
  if (currentClient.hasPermission("USES_445_CALENDAR")) {
    return "WEEK_START";
  } else {
    return "MONTH_START";
  }
};

const dynamicDateToLabel = {
  TODAY: "Today",
  WEEK_START: "Start of This Week",
  WEEK_END: "End of This Week",
  MONTH_START: "Start of This Month",
  MONTH_END: "End of This Month",
  QUARTER_START: "Start of This Quarter",
  QUARTER_END: "End of This Quarter",
  YEAR_START: "Start of This Year",
  YEAR_END: "End of This Year",
  NEXT_MONTH_START: "Start of Next Month",
  NEXT_MONTH_END: "End of Next Month",
  NEXT_QUARTER_START: "Start of Next Quarter",
  NEXT_QUARTER_END: "End of Next Quarter",
  NEXT_YEAR_START: "Start of Next Year",
  NEXT_YEAR_END: "End of Next Year"
};

const getDynamicDateRangeTimeframeOptions = () => {
  const allDynamicDateRangeOptions = Immutable
      .fromJS([
        "TODAY",
        "WEEK_START",
        "WEEK_END",
        "MONTH_START",
        "MONTH_END",
        "QUARTER_START",
        "QUARTER_END",
        "YEAR_START",
        "YEAR_END",
        "NEXT_MONTH_START",
        "NEXT_MONTH_END",
        "NEXT_QUARTER_START",
        "NEXT_QUARTER_END",
        "NEXT_YEAR_START",
        "NEXT_YEAR_END"
      ]);
  const typesAvailableToAll = Immutable
      .fromJS(["TODAY", "WEEK_START", "WEEK_END"])
      .toSet();
  const finalDateRangeOptions = currentClient.hasPermission("USES_445_CALENDAR") ?
      allDynamicDateRangeOptions.filter(drt => typesAvailableToAll.contains(drt)) : allDynamicDateRangeOptions;
  return finalDateRangeOptions
      .map(date => Immutable.fromJS({
        label: dynamicDateToLabel[date],
        value: date
      }));
};

const mysqlDatePattern = "YYYY-MM-DD";
const displayDatePattern = "L";

const getTimeframes = () => Ajax
    .get({
      url: "timeframe",
      data: {
        today: toMysqlDate(moment())
      }
    })
    .then(result => Immutable
        .fromJS(result)
        .filter(t => !t.get("isDeleted"))
        .map(parseTimeframe));

const parseTimeframe = timeframe => {
  const timeframeDescription = !timeframe.get("isCube19Default") ?
      parseTimeframeDescription(timeframe.get("timeframeDescription")) : timeframe.get("timeframeDescription");
  return timeframe
      .set("id", timeframe.get("id") ? timeframe.get("id").toLowerCase() : timeframe.get("cid"))
      .set("start", toMomentDate(timeframe.get("start")))
      .set("end", toMomentDate(timeframe.get("end")))
      .set("timeframeDescription", timeframeDescription);
};

const parseTimeframeDescription = timeframeDescription => {
  const timeframeType = timeframeDescription.get("timeframeType");
  if (timeframeType === "FIXED") {
    const pathToStartDate = ["start", "date"];
    const pathToEndDate = ["end", "date"];
    const startDate = timeframeDescription.getIn(pathToStartDate);
    const endDate = timeframeDescription.getIn(pathToEndDate);
    return timeframeDescription
        .setIn(pathToStartDate, toMomentDate(startDate))
        .setIn(pathToEndDate, toMomentDate(endDate));
  } else if (timeframeType === "DYNAMIC_DUO") {
    const startPointInTime = getPointInTimeValue(timeframeDescription.getIn(["start", "offset"]));
    const endPointInTime = getPointInTimeValue(timeframeDescription.getIn(["end", "offset"]));
    return timeframeDescription
        .setIn(["start", "pointInTime"], startPointInTime)
        .setIn(["end", "pointInTime"], endPointInTime);
  } else if (timeframeType === "DYNAMIC_SOLO") {
    const pointInTime = getPointInTimeValue(timeframeDescription.getIn(["start", "offset"]));
    return timeframeDescription.setIn(["start", "pointInTime"], pointInTime);
  } else {
    return timeframeDescription;
  }
};

const getTimeframeDescriptionJson = timeframeDescription => {
  const timeframeType = timeframeDescription.get("timeframeType");
  if (timeframeType === "FIXED") {
    const pathToStartDate = ["start", "date"];
    const pathToEndDate = ["end", "date"];
    const startDate = timeframeDescription.getIn(pathToStartDate);
    const endDate = timeframeDescription.getIn(pathToEndDate);
    return timeframeDescription
        .setIn(pathToStartDate, toMysqlDate(startDate))
        .setIn(pathToEndDate, toMysqlDate(endDate));
  } else if (timeframeType === "DYNAMIC_DUO") {
    const start = timeframeDescription.get("start");
    const end = timeframeDescription.get("end");
    return timeframeDescription
        .set("start", start.delete("pointInTime"))
        .set("end", end.delete("pointInTime"));
  } else if (timeframeType === "DYNAMIC_SOLO") {
    return timeframeDescription.set("start", timeframeDescription.get("start").delete("pointInTime"));
  } else {
    return timeframeDescription;
  }
};

const getPointInTimeValue = offset => offset < 0 ? "BEFORE" : "AFTER";

const sortTimeframes = timeframes => {
  const visibleTimeframes = timeframes
      .filter(t => t.get("visible"))
      .sortBy(t => t.get("order"));
  const hiddenTimeframes = timeframes
      .filter(t => !t.get("visible"))
      .sortBy(t => t.get("name").toLowerCase());
  return visibleTimeframes.concat(hiddenTimeframes);
};

const createTimeframe = timeframe => Ajax
    .post({
      url: "timeframe",
      json: timeframe
          .set("timeframeDescription", getTimeframeDescriptionJson(timeframe.get("timeframeDescription")))
          .delete("start")
          .delete("end")
          .delete("isChanged")
          .delete("cid")
          .toJS()
    });

const updateTimeframe = timeframe => {
  const url = `timeframe/${timeframe.get("id")}`;
  if (timeframe.get("isCube19Default")) {
    return Ajax.put({
      url,
      json: timeframe
          .delete("start")
          .delete("end")
          .delete("isChanged")
          .toJS()
    });
  } else {
    const timeframeDescription = getTimeframeDescriptionJson(timeframe.get("timeframeDescription"));
    return Ajax.put({
      url,
      json: timeframe
          .set("timeframeDescription", timeframeDescription)
          .delete("start")
          .delete("end")
          .delete("isChanged")
          .toJS()
    });
  }
};

const removeTimeframe = id => Ajax.del({url: `timeframe/${id}`});

const hasStoredChanges = () => {
  const currentUser = Users.getCurrentUser();
  return !!store.get("timeframesAdmin.hasUnsavedChanges") && store.get("timeframesAdmin.userId") ===
      currentUser.get("id");
};

const getStoredChanges = () => {
  const userId = store.get("timeframesAdmin.userId");
  const changedTimeframes = Immutable.fromJS(store.get("timeframesAdmin.timeframes")).map(parseTimeframe);
  const idToEditState = Immutable.fromJS(store.get("timeframesAdmin.idToEditState"));
  const timeframeChangeById = Immutable.fromJS(store.get("timeframesAdmin.timeframeChangeById"));
  let parsedTimeframeChangeById = Immutable.Map();
  timeframeChangeById.forEach(timeframe => {
    const timeframeId = timeframe.get("id") || timeframe.get("cid");
    parsedTimeframeChangeById = parsedTimeframeChangeById.set(timeframeId, parseTimeframe(timeframe));
  });
  return {
    userId,
    timeframes: changedTimeframes,
    idToEditState,
    timeframeChangeById: parsedTimeframeChangeById
  };
};

const saveChangesToLocalStorage = (timeframes, idToEditState, timeframeChangeById) => {
  const currentUser = Users.getCurrentUser();
  store.set("timeframesAdmin.hasUnsavedChanges", true);
  store.set("timeframesAdmin.userId", currentUser.get("id"));
  store.set("timeframesAdmin.idToEditState", idToEditState.toJS());
  const changedTimeframesJson = timeframes
      .filter(timeframe => timeframe.get("cid") || timeframe.get("isChanged") || timeframe.get("isDeleted"))
      .map(parseDatesForJson)
      .toJS();
  store.set("timeframesAdmin.timeframes", changedTimeframesJson);
  let parsedTimeframeChangeById = Immutable.Map();
  timeframeChangeById.forEach(timeframe => {
    const timeframeId = timeframe.get("id") || timeframe.get("cid");
    parsedTimeframeChangeById = parsedTimeframeChangeById.set(timeframeId, parseDatesForJson(timeframe));
  });
  store.set("timeframesAdmin.timeframeChangeById", parsedTimeframeChangeById.toJS());
};

const parseDatesForJson = timeframe => {
  const startDate = timeframe.get("start") ? toMysqlDate(timeframe.get("start")) : null;
  const endDate = timeframe.get("end") ? toMysqlDate(timeframe.get("end")) : null;
  let parsedTimeframe = Immutable.Map();
  if (timeframe.get("isCube19Default")) {
    parsedTimeframe = timeframe
        .set("start", startDate)
        .set("end", endDate);
  } else {
    parsedTimeframe = timeframe
        .set("timeframeDescription", getTimeframeDescriptionJson(timeframe.get("timeframeDescription")))
        .set("start", startDate)
        .set("end", endDate);
  }
  return parsedTimeframe;
};

const clearStoredChanges = () => {
  store.remove("timeframesAdmin.hasUnsavedChanges");
  store.remove("timeframesAdmin.userId");
  store.remove("timeframesAdmin.timeframes");
  store.remove("timeframesAdmin.idToEditState");
  store.remove("timeframesAdmin.timeframeChangeById");
};
