import moment from "moment";

export const getChannelRuntime = channel => {
    const slides = channel.get("json").get("pages");
    return slides
        .map(getSlideRuntime)
        .reduce((total, val) => total + val, 0);
};

export const getSlideRuntime = slide => {
    const layoutId = slide.getIn(["layout", "id"]);
    switch (layoutId) {
        case "LeftBigRightSmall":
            const leaderboard = slide.getIn(["layout", "left", 0]);
            return getOverallLeaderBoardTime(leaderboard);
        case "LeftBigRight4":
        case "Fullscreen":
        case "EmbeddedVideo":
        case "Iframe":
            return slide.get("timing"); // FIXME: after React-ified Leaderboard work is done
        default:
            throw new Error("unknown channel slide type", layoutId);
    }
};

export const formatMillis = millis => {
    const duration = moment.duration(millis);
    const parts = [];
    const hours = duration.hours();
    if (hours > 0) {
        parts.push(hours + "h");
    }
    const minutes = duration.minutes();
    if (minutes > 0) {
        parts.push(minutes + "m");
    }
    const seconds = duration.seconds();
    if (seconds > 0) {
        parts.push(seconds + "s");
    }
    return parts.join(" ");
};

const getOverallLeaderBoardTime = leaderboard => {
    // TODO get a more accurate number for leaderboard time, based on active group membership capped by totalUsers
    const totalUsers = leaderboard.get("totalUsers") || 20;
    const numberOfPages = totalUsers / 10;
    const timePerPage = leaderboard.get("paginationTime") || 20000;
    const timeToHighlight = getLeaderboardCycleTime(leaderboard);
    const firstPageTime = Math.max(timePerPage, timeToHighlight);
    return firstPageTime + ((numberOfPages-1) * timePerPage);
};

const getLeaderboardCycleTime = leaderboard => {
    if (leaderboard.get("cycle")) {
        const highlightCount = leaderboard.get("limit");
        const timePerHighlight = leaderboard.get("cycleTime");
        return highlightCount * timePerHighlight;
    } else {
        return 0;
    }
};
