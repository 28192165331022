import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import NumberField from "js/common/views/inputs/number-field";
import Layout from "js/common/views/foundation-column-layout";
import EditKpiTrends from "js/admin/cubetv/trend/edit-kpi-trends";
import { TextField } from '@mui/material';


export default createReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        timeframes: ImmutablePropTypes.list.isRequired,
        trend: ReactPropTypes.object.isRequired,
        onChange: ReactPropTypes.func.isRequired,
        timingInMillis: ReactPropTypes.number.isRequired,
        onTimingChange: ReactPropTypes.func.isRequired
    },

    render() {
        const { timeframes, trend, onChange, timingInMillis, onTimingChange } = this.props;
        return (
            <div style={{ marginTop: "1rem" }}>
                <Layout allSmall={12} allMedium={4}>
                    <NumberField
                        range={[10, 120]}
                        style={{width: 250, marginTop: 15}}
                        label="Seconds to Display Slide"
                        value={timingInMillis / 1000}
                        onChange={timingInSeconds => onTimingChange(timingInSeconds * 1000)} />
                    <TextField variant="standard"
                        label="X-axis Label"
                        style={{width: 250, marginTop: 15}}
                        value={trend.get("xLabel")}
                        onChange={e => onChange(trend.set("xLabel", e.target.value.substring(0, 20)))} />
                    <TextField variant="standard"
                        label="Y-axis Label"
                        style={{width: 250, marginTop: 15}}
                        value={trend.get("yLabel")}
                        onChange={e => onChange(trend.set("yLabel", e.target.value.substring(0, 20)))} />
                </Layout>
                <EditKpiTrends
                    kpiTrends={trend.get("kpis")}
                    onChange={kpiTrends => onChange(trend.set("kpis", kpiTrends))}
                    timeframes={timeframes} />
            </div>
        );
    }

});
