import React from "react";
import createReactClass from "create-react-class";

import Tabs from "js/common/views/tabs";
import AssignTags from "js/admin/tags/react-assign-tags";
import CreateTags from "js/admin/tags/create-tags";
import BulkUpdate from "js/admin/tags/react-bulk-update-page";

import * as auditor from "js/common/auditer";

const indexByTabName = {
  TAGS_MGMT: 0,
  ASSIGN_TAGS: 1,
  BULK_UPDATE: 2
};

export default createReactClass({

  getDefaultProps() {
    return {
      initialTab: "TAGS_MGMT"
    };
  },

  getInitialState() {
    return {
      showTabIndex: indexByTabName[this.props.initialTab]
    };
  },

  render() {
    const tabs = [
      {
        title: "Create Tags",
        content: <CreateTags />
      }, {
        title: "Assign Tags",
        content: <AssignTags />
      }, {
        title: "Bulk Update",
        content: <BulkUpdate />
      }];
    return (
        <Tabs
            selectedIndex={this.state.showTabIndex}
            onChangeTab={this.handleChangeTab}
            tabs={tabs}
            containerStyle={{margin: 3}} />
    );
  },

  handleChangeTab(index) {
    this.setState({showTabIndex: index});
  },

  componentDidMount() {
    auditor.audit("tags-admin:loaded");
  }
});
