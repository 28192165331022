import React from "react";
import moment from "moment";
import Immutable from "immutable";
import createReactClass from "create-react-class";
import Select from "js/common/views/inputs/immutable-react-select";
import Layout from "js/common/views/foundation-column-layout";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import InheritedFromGroupInfo from "js/admin/inherited-from-group-info";
import * as Groups from "js/common/groups";
import Checkbox from 'js/common/views/inputs/checkbox';

const InheritedLocalePicker = createReactClass({

  render() {
    const {
      idToLocale,
      user,
      group,
      onChange,
      columnStyle,
      theme
    } = this.props;

    if ((user && group) || !(user || group)) {
      throw new Error("must specify exactly one of User or Group for inherited-locale-picker");
    }

    let localeId;
    let isInherited;
    let isRootGroup;
    let unifiedGroup;
    if (user) {
      isInherited = !user.get("localeId");
      unifiedGroup = Groups.getGroup(user.get("groupId"));
      localeId = isInherited ? unifiedGroup.get("localeId") : user.get("localeId");
      isRootGroup = false;
    } else {
      isInherited = group.get("inheritedLocaleGroupId") !== group.get("id");
      unifiedGroup = group;
      localeId = group.get("localeId");
      isRootGroup = !group.get("parentId");
    }

    const locale = idToLocale.get(localeId);
    const options = idToLocale.valueSeq().sortBy(l => l.get("name")).map(localeToMenuItem).toSet();

    return (
        <div>
          <Layout allSmall={12} allMedium={[6]} columnStyle={columnStyle} floatLastColumnLeft={true}>
            {isInherited ?
                <span style={textStyle}>{labelForLocale(locale)}</span> :
                <Select
                    placeholder="Select a locale"
                    isClearable={false}
                    isMulti={false}
                    isSearchable={false}
                    selectedValue={locale.get("id")}
                    options={options}
                    onChange={(value) => onChange({localeId: value})} />
                  }
            <Checkbox
                label="Inherit from Parent Group"
                disabled={isRootGroup}
                style={{marginTop: -4}}
                checked={isInherited}
                onCheck={(e, isChecked) => this.onChangeInherited(isChecked, localeId)} />
          </Layout>

          {isInherited &&
          <Layout allSmall={12} columnStyle={columnStyle}>
            <InheritedFromGroupInfo originalGroupId={unifiedGroup.get("inheritedLocaleGroupId")} theme={theme}/>
          </Layout>}
        </div>);
  },

  onChangeInherited(isChecked, currentLocaleId) {
    if (isChecked) {
      this.props.onChange({localeId: null});
    } else {
      this.props.onChange({localeId: currentLocaleId});
    }
  }

});

const localeToMenuItem = locale => (
    Immutable.Map({
      label: labelForLocale(locale),
      value: locale.get("id")
    }));

const labelForLocale = locale => `${locale.get("name")} (${moment().locale(locale.get("code")).localeData().longDateFormat("L")})`;

const textStyle = {
  fontSize: "0.9rem",
  height: 34,
  lineHeight: "34px"
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <InheritedLocalePicker theme={theme} {...props} />;
};