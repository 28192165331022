import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import Immutable from "immutable";

import * as Users from "js/common/users";
import * as ajax from "js/common/ajax";

import AdminHeader from "js/admin/common/admin-header";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import { Layout } from "js/common/views/foundation-column-layout";
import LoadingSpinner from "js/common/views/loading-spinner";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import JobErrorsTable from "js/admin/job-errors-table";
import * as Branding from "js/common/branding-constants";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const _ = window._;

const UploadBulkFilesTable = createReactClass({

    getInitialState() {
        return {
            isLoading: false,
            asyncJobs: [],
            selectedJobId: null
        };
    },

    componentDidMount() {
        this.setState({ isLoading: true });
        this.reloadJobs();
        this.intervalId = setInterval(() => this.reloadJobs(), 30*1000);
    },

    componentWillUnmount() {
        clearInterval(this.intervalId);
    },

    render() {
        const selectedJob = _(this.state.asyncJobs).find(j => j.id === this.state.selectedJobId);
        return (
            <section className="page-section">
                <AdminHeader>
                    <Icon icon="users" />
                    Status of uploaded files
                </AdminHeader>
                <Layout allSmall={12} collapseRow={false} rowStyle={{ marginTop: "1rem" }}>
                    {this.state.isLoading ? <LoadingSpinner /> : this.renderTable()}
                    {selectedJob && getMessageForJob(Immutable.fromJS(selectedJob))}
                </Layout>
            </section>);
    },

    renderTable() {
        const columns = [ "ID", "File Name", "Uploaded By", "Uploaded On", "Status" ];
        return(
            <div>
                <Hint>
                    <Icon icon="info" style={{color: this.props.theme.palette.hints.text}} />
                    Click on a row in the table below to see further details about that file upload job, e.g. a detailed error report (if any errors are found).
                </Hint>
                <SimpleDataTable
                    onCellClick={this.handleCellClick}
                    columns={columns}
                    rows={this.state.asyncJobs.map(asyncJobToRow)}
                    initialSortDirection="DESC" />
            </div>
        );
    },

    reloadJobs() {
        loadAsyncJobs().then(asyncJobs => this.setState({ asyncJobs, isLoading: false }));
    },

    handleCellClick(cellValue, row) {
        const asyncJobId = row[0].originalValue;
        const asyncJob = _(this.state.asyncJobs).find(j => {
            return j.id === asyncJobId;
        });
        this.setState({ selectedJobId: asyncJob.id });
    }

});

const loadAsyncJobs = () => ajax.get({ url: "group-history/jobs" })
    .then(asyncJobs => asyncJobs.map(parsePayload));

const parsePayload = asyncJob => {
    return {
        ...asyncJob,
        payload: JSON.parse(asyncJob.payload)
    };
};

const asyncJobToRow = asyncJob => {
    let statusStr = "Moved to ";
    statusStr += asyncJob.status;
    statusStr += " at ";
    statusStr += formatDateTime(moment(asyncJob.lastStatusChangeTimestamp).local());

    const uploadedBy = Users.getUser(asyncJob.userId).get("fullName");

    return [
        asyncJob.id,
        asyncJob.payload.originalFileName,
        uploadedBy,
        formatDateTime(moment(asyncJob.startTimestamp).local()),
        statusStr
    ];
};

const getMessageForJob = asyncJob => {
    const jobId = asyncJob.get("id");
    const errors = asyncJob.get("errors");
    if (!errors.isEmpty()) {
        return (
            <div>
                <p>
                    <i className="fa fa-exclamation-triangle" style={{ color: "#f00", paddingRight: 6 }} />
                    The following errors were found in the file uploaded for job ID {asyncJob.get("id")}
                </p>
                <JobErrorsTable id="groups-bulk-update-errors" errors={errors} />
            </div>
        );
    }

    switch (asyncJob.get("status")) {
    case "COMPLETED":
        return `The file uploaded for job ID ${jobId} was processed successfully`;
    case "PENDING":
        return `The file uploaded for job ID ${jobId} is queued for processing`;
    case "FAILED":
        return `The file uploaded for job ID ${jobId} was not processed. ${Branding.submitTicketString}`;
    case "PROCESSING":
        return `The file uploaded for job ID ${jobId} is currently being processed`;
    default:
        return `Something unexpected happened with job ID ${jobId}. ${Branding.submitTicketString}`;
    }
};

const formatDateTime = (date, pattern = "YYYY-MM-DD HH:mm:ss") => date.format(pattern);

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <UploadBulkFilesTable theme={theme} {...props} />;
};
