import React from "react";
import {capitaliseWords} from "js/common/utils/strings";
import * as Colors from "js/common/cube19-colors";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const CycleGraph = ({currentKpiId, cyclingKpis, onClick}) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <div>
    <div style={{display: "flex", justifyContent: "right"}}>
      <div
          style={{
            backgroundColor: theme.themeId === "light" ? theme.palette.background.paper : Colors.greyLight,
            marginRight: 20,
            padding: 10,
            margin: "10px 10px 0",
            width: "15%",
            fontSize: "0.85rem"
          }}>
        <b>Key:</b>
        <div style={{display: "flex", marginTop: 10}}>
          <div
              style={{
                height: 15,
                width: 15,
                background: "white",
                borderRadius: 5,
                marginRight: 5,
                border: `2px solid ${Colors.c19Green}`
              }} />
          Current Metric
        </div>
      </div>
    </div>
    <div style={{display: "flex", justifyContent: "center"}}>
      <div style={{display: "flex", marginBottom: 20, overflow: "auto"}}>
        {cyclingKpis.flatMap((kpi, index) => {
          const isCurrent = currentKpiId === kpi.get("id");
          const nodeStyle = {
            border: isCurrent && `4px solid ${Colors.c19Green}`,
            cursor: !isCurrent && "pointer",
            backgroundColor: Colors.white,
            color: Colors.darkestGrey,
            textAlign: "center",
            padding: 10,
            margin: 20,
            minWidth: 150,
            borderRadius: 5,
            boxShadow: `2px 2px 5px ${Colors.darkestGrey}`
          };
          const node = <div
              onClick={() => !isCurrent && onClick(kpi.get("id"), "RELATIONSHIPS")}
              style={nodeStyle}>
            <div style={{marginBottom: 10}}>
              <b>{isNaN(kpi.get("id")) ? "<NEW>" : kpi.get("id")}</b>
            </div>
            <div>{kpi.get("name")}</div>
          </div>;
          const relationship = <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: 10
              }}>
            {cyclingKpis.size - 1 === index ? "CYCLE" : <div style={{alignSelf: "center"}}>
              {capitaliseWords(kpi.get("dependencyType", "").replace("_", " "))}
            </div>}
            <i style={{alignSelf: "center"}} className="fa fa-arrow-right" />
          </div>;
          return [node, relationship];
        })}
      </div>
    </div>
  </div>;
};

export default CycleGraph;