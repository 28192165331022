import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import moment from "moment";
import PureRenderMixin from "react-addons-pure-render-mixin";

import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";
import {Layout} from "js/common/views/foundation-column-layout";
import LoadingSpinner from "js/common/views/loading-spinner";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import JobErrorsTable from "js/admin/job-errors-table";
import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants";

const UploadedFilesTable = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      isLoading: false,
      asyncJobs: Immutable.List(),
      selectedJobId: null
    };
  },

  componentDidMount() {
    this.setState({isLoading: true});
    this.reloadFileProcessingJobs();
    this.intervalId = setInterval(() => this.reloadFileProcessingJobs(), 30 * 1000);
  },

  componentWillUnmount() {
    clearInterval(this.intervalId);
  },

  render() {
    const {theme} = this.props;
    const {asyncJobs, selectedJobId, isLoading} = this.state;
    const selectedJob = asyncJobs.find(j => j.get("id") === selectedJobId);
    return (
        <div style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
          <AdminHeader>
            Status of uploaded files
          </AdminHeader>
          <Layout allSmall={12} columnStyle={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
            <Hint>
              <Icon icon="info" style={{color: this.props.theme.palette.hints.text}} />
              Click on a row in the table below to see further details about that file upload job, e.g. a detailed error report (if any errors are found).
            </Hint>
            {isLoading ? <LoadingSpinner /> : this.renderTable()}
            {selectedJob && getMessageForJob(selectedJob, theme)}
          </Layout>
        </div>
    );
  },

  renderTable() {
    const columns = [{
      label: "ID",
      width: 60
    },
      "File Name",
      "Uploaded By",
      "Uploaded On",
      "Status",
      "Replacement Start",
      "Replacement End",
      "Replacement Types"
    ];
    return (
        <SimpleDataTable
            id={`${this.props.actualsCategory.toLowerCase()}-actuals-uploads-table`}
            onCellClick={this.onCellClick}
            columns={columns}
            rows={this.state.asyncJobs.map(asyncJobToRow).toJS()}
            initialSortDirection="DESC" />
    );
  },

  reloadFileProcessingJobs() {
    loadFileProcessingJobs(this.props.actualsCategory.toLowerCase())
        .then(asyncJobs => this.setState({asyncJobs, isLoading: false}));
  },

  onCellClick(cellValue, row) {
    const jobId = row[0].originalValue;
    const job = this.state.asyncJobs.find(j => j.get("id") === jobId);
    this.setState({selectedJobId: job.get("id")});
  }
});

const loadFileProcessingJobs = category => ajax
    .get({url: `actuals/${category.toLowerCase()}/recent-uploads`})
    .then(res => Immutable.fromJS(res));

const dateTimeDisplayFormat = `YYYY-MM-DD HH:mm:ss`;
const asyncJobToRow = job => {
  const uploadedBy = Users.getUser(job.get("userId")).get("fullName");
  const uploadedAt = convertToDateTimeString(job.get("uploadedTimestamp"));
  const lastStatusChange = convertToDateTimeString(job.get("lastStatusChangeTimestamp"));
  const statusStr = `Moved to ${job.get("status")} at ${lastStatusChange}`;
  const replacementStart = job.get("replacementStartDate")
      ? moment(job.get("replacementStartDate"))
          .format("ll")
          .replace(",", "")
      : "";
  const replacementEnd = job.get("replacementEndDate")
      ? moment(job.get("replacementEndDate"))
          .format("ll")
          .replace(",", "")
      : "";
  const replacementTypes = job.get("replacementTypes") ? JSON.parse(job.get("replacementTypes")) : [];

  return [
    job.get("id"),
    job.get("fileName"),
    uploadedBy,
    uploadedAt,
    statusStr,
    replacementStart,
    replacementEnd,
    replacementTypes.join(", ")
  ];
};
const convertToDateTimeString = timestamp => {
  if (!timestamp) {
    return "";
  } else {
    const localDateTime = moment(timestamp).local();
    return formatDateTime(localDateTime, dateTimeDisplayFormat);
  }
};
const formatDateTime = (date, pattern) => date.format(pattern);

const getMessageForJob = (job, theme) => {
  const jobId = job.get("id");
  const fileName = job.get("fileName");
  const errors = job.get("errors");
  if (!errors.isEmpty()) {
    return (
        <div>
          <p>
            <i className="fa fa-exclamation-triangle" style={{color: "#f12f2f", paddingRight: 6}} />
            The following errors were found in <em>{fileName} (Job ID: {jobId})</em>
          </p>
          <JobErrorsTable id="actuals-job-errors" errors={errors} />
        </div>
    );
  }

  switch (job.get("status")) {
    case "COMPLETED":
      return `Job ${jobId} - ${fileName} was processed successfully`;
    case "PENDING":
      return `Job ${jobId} - ${fileName} is queued for processing`;
    case "FAILED":
      return `Job ${jobId} - ${fileName} was not processed. ${Branding.submitTicketString}`;
    case "PROCESSING":
      return `Job ${jobId} - ${fileName} is currently being processed`;
    default:
      return `Something unexpected happened with job ID ${jobId}. ${Branding.submitTicketString}`;
  }
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <UploadedFilesTable theme={theme} {...props} />;
};