import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import createReactClass from "create-react-class";
import store from "store";
import moment from "moment";
import Immutable from "immutable";
import GetContainerDimensions from "react-dimensions";
import Cube19 from "js/cube19.app";
import CubeTvApp from "js/cubetv/app";

import MenuBar from "js/common/views/menu-bar";
import LoadingSpinner from "js/common/views/loading-spinner";
import Tooltip from "js/common/views/tooltips";
import pure from "js/common/views/pure";
import * as Ajax from "js/common/ajax";
import { TextField, Card } from '@mui/material';
import {Layout} from "js/common/views/foundation-column-layout";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const ChannelSelection = createReactClass({

  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      autoPlaySingleChannel: true,
      stopKioskMode: false
    };
  },

  getInitialState() {
    return {
      channels: Immutable.List(),
      isLoading: false,
      searchFilter: ""
    };
  },

  componentDidMount() {
    const $ = window.$;
    const theme = localStorage.getItem('appTheme');
    $("body")
        .removeClass()
        .addClass(`theme-${theme}`);

    if (this.props.stopKioskMode) {
      store.remove("kioskMode.carouselId");
    }

    this.setState({
      isLoading: true
    });
    loadCurrentUsersChannels().then(channels => {
      const sortedChannels = channels.sortBy(channel => channel.get("name").toLowerCase());
      this.setState({
        isLoading: false,
        channels: sortedChannels
      }, () => {
        if (channels.count() === 1 && this.props.autoPlaySingleChannel) {
          const channel = channels.first();
          showChannel(channel.get("id"));
        } else {
          const id = store.get("kioskMode.carouselId");
          const channel = this.state.channels.find(c => c.get("id") === id);
          const channelExists = !!channel;
          if (channelExists) {
            showChannel(channel.get("id"));
          } else {
            store.remove("kioskMode.carouselId");
          }
        }
      });
    });
  },

  render() {
    const {theme} = this.props;
    return (
          <div>
            <MenuBar appView="channel-selection" />
            <div style={theme.themeId === "light" ? {
              background: theme.palette.background.card,
              boxShadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px",
              marginBottom: 30,
              padding: "10px 20px 30px 10px"} : {}}>
              <Layout
                allSmall={6}
                medium={theme.themeId === "light" ? [6, 6] : 12}
                smallCentered={!theme.themeId === "light"}
                rowStyle={{marginTop: "0.5rem", maxWidth: "100%", width: "100%"}}>
                {theme.themeId === "light" && <h2 style={{fontSize: 22, display: "inline-block", fontWeight: 500}}>Gamification Channels</h2>}
              </Layout>
            </div>
            {theme.themeId === "dark" && <div style={{marginTop: "2rem", marginLeft: "auto", marginRight: "auto", marginBottom: "2rem"}}>
              <h2 style={{textAlign: "center", marginBottom: "1rem", color: "rgb(234, 64, 64)"}}>
                Gamification Channels
              </h2>
            </div>}
            {this.renderChannelList()}
          </div>
    );
  },

  renderChannelList() {
    const {channels, isLoading, searchFilter} = this.state;

    if (isLoading) {
      return <LoadingSpinner label="Loading Gamification Channels" />;
    } else if ((channels.count() === 1 && !this.props.autoPlaySingleChannel) || channels.count() >= 0) {
      const searchString = searchFilter.toLowerCase();
      const filteredChannels = channels.filter(channel => {
        const nameString = channel.get("name").toLowerCase();
        const descriptionString = channel.get("description").toLowerCase();
        return nameString.includes(searchString) || descriptionString.includes(searchString);
      });
      return [
        <div style={{maxWidth: "90em", marginTop: "50px"}} className="channel-selector">
          {channels.count() > 5 &&
          <TextField variant="standard"
              label="Search for Channel"
              onChange={e => this.setState({searchFilter: e.target.value})}
              value={searchFilter}
              style={{marginLeft: "1rem", marginRight: "1rem"}} />}
        </div>,
        <div style={{maxWidth: "90em", margin: "10px auto"}}>
          <ChannelGrid channels={filteredChannels} onChannelSelect={showChannel} />
        </div>];
    }
  }

});

const ChannelGrid = GetContainerDimensions()(pure(({
                                                     channels,
                                                     onChannelSelect,
                                                     containerWidth,
    theme
                                                   }) => {
  let columns = 3;
  if (containerWidth <= 1024) {
    columns = 2;
  }
  if (containerWidth <= 768) {
    columns = 1;
  }
  const columnWidthByTotalColumns = {
    3: "33%",
    2: "49%",
    1: "99%"
  };
  return (
      <div style={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, ${columnWidthByTotalColumns[columns]})`,
        justifyContent: "space-between",
        margin: "1rem",
        rowGap: "10px"
      }}>
        {channels.isEmpty() &&
        <div style={{padding: "0.5rem 1rem", margin: "0.5rem 0"}}>No channels to display</div>}
        {channels.map(channel =>
            <ChannelInfo
                theme={theme}
                key={channel.get("id")}
                channel={channel}
                onClick={onChannelSelect}
                columns={columns} />)}
      </div>
  );
}));

const ChannelInfo = React.memo(({channel, onClick}) => {

  const { theme } = React.useContext(CustomThemeContext);
  const { palette } = theme;

  const curveContainerStyle = {
    position: "relative",
    borderRadius: "5px",
    padding: "1em",
    cursor: "pointer",
    height: "100%",
    minHeight: "100px",
    transition: "all 0.5s ease",
    background: palette.background.card,
    boxShadow: "0 -1px 3px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)",
    ":hover": {
      backgroundColor: "rgba(0,0,0,0.8)"
    }
  };

  const createdByLabelStyle = {
    color: theme.themeId === "light" ? palette.text.main : "rgb(150, 150, 150)",
    fontWeight: theme.themeId === "light" ? "bold" : "regular",
    fontSize: "0.7rem",
    fontStyle: "italic",
    marginBottom: "0.25rem"
  };
  return (
      <Tooltip text={"Channel Id: " + channel.get("id")} position="top" showOnHover={true} styleType="dark">
        <div style={curveContainerStyle} onClick={() => onClick(channel.get("id"))}>
          <h4 style={{textTransform: "none", color: palette.background.main}}>
            <i className="fa fa-television" style={{color: palette.background.main, paddingRight: 10}} />
            {channel.get("name")}
          </h4>
          {channel.get("description") &&
          <p style={{fontSize: "0.9rem", marginBottom: "0.25rem", wordBreak: "break-all"}}>
            {channel.get("description")}
          </p>}
          <p style={createdByLabelStyle}>
            {`Last modified: ${formatDate(channel.get("lastModified"))}`}
          </p>
        </div>
      </Tooltip>
  );
});

const formatDate = dateTimeStr => moment(dateTimeStr, "YYYY-MM-DD HH:mm:ss").format("lll");

const showChannel = channelId => {
  store.set("kioskMode.carouselId", channelId);
  const cubeTvApp = new CubeTvApp({channelId});
  // NOTE - because there is no react wrapper here <LatestUIVersionDialog /> does not show which is actually the desired
  // functionality we want, but when this is rewritten in react this will need to be looked at.
  Cube19.contentRegion.show(cubeTvApp);
};

const loadCurrentUsersChannels = () => Ajax
    .get({url: "carousels/current-user"})
    .then(response => Immutable.fromJS(response));

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ChannelSelection theme={theme} {...props} />;
};
