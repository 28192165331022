import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import MenuBar from "js/common/views/menu-bar";
import ConfigurableReport from "js/onpoint/configurable-report";
import OneViewRatioDetails from "js/oneview/ratio-details/dialog";
import OneViewKpiClickThrough from "js/oneview/kpi-details/dialog";
import OneViewApp from "js/oneview/main";
import Drawer from "js/common/views/drawer";
import SavedConfigsMenu from "js/common/views/sharing/saved-configs-menu";
import ConfirmRemoveReportDialog from "js/common/views/sharing/confirm-remove-dialog";
import ErrorBoundary from "js/common/views/error-boundary";
import Cube19 from "js/cube19.app";
import {reactComponentToMarionetteComponent} from "js/common/views/react-to-marionette";
import pure from "js/common/views/pure";
import currentClient from "js/common/repo/backbone/current-client";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Auditor from "js/common/auditer";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as SavedConfigs from "js/common/saved-configs";
import * as Popups from "js/common/popups";
import Dialog from "js/common/views/tabs-dialog";
import ShareReport from "js/common/views/sharing/share-config";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {css, Global} from "@emotion/react";

const $ = window.$;

const ErrorBoundaryPage = pure(props => (
    <ErrorBoundary>
      <Page {...props} />
    </ErrorBoundary>
));

const Page = GetContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      savedReports: Immutable.Map(),
      renderId: Math.random(),
      config: getDefaultReportConfig(),
      oneViewClickThroughOptions: null,
      ratioDetailsOptions: null,
      isSavedReportsMenuOpen: false,
      savedReportToRemove: null,
      isSavingReport: false,
      isLoadingReports: true,
      ownershipTypeToSavedReports: Immutable.Map(),
      savedReportToShare: null,
      loadingSavedReportSharing: null,
      shareSavedReportError: null,
      shareSavedReportSuccess: null,
      savedReportSharingSettings: null,
      editableSavedReportSharingSettings: null
    };
  },

  componentDidMount() {
    const theme = localStorage.getItem("appTheme");
    $("body")
        .removeClass("main-app")
        .addClass("onpoint-app")
        .addClass(`theme-${theme}`);
    Auditor.audit("onpoint:loaded");
    loadSavedReports()
        .then((savedReports) => {
          this.setState({
            savedReports,
            isLoadingReports: false
          });
        }, () => {
          this.setState({
            isLoadingReports: false
          });
        });

  },

  render() {
    const {
      savedReports,
      renderId,
      config,
      isConfigChanged,
      oneViewClickThroughOptions,
      ratioDetailsOptions,
      isSavedReportsMenuOpen,
      savedReportToRemove,
      savedReportToShare,
      loadingSavedReportSharing,
      isSavingReport,
      isLoadingReports,
      shareSavedReportError,
      shareSavedReportSuccess,
      editableSavedReportSharingSettings,
      savedReportSharingSettings
    } = this.state;
    const isOneViewKpiClickThroughOpen = !!oneViewClickThroughOptions;
    const isRatioDetailsOpen = !!ratioDetailsOptions;
    const isConfirmRemoveReportDialogOpen = !!savedReportToRemove;
    return (
        <>
          <Global
              styles={css`
                body, #content-region {
                  height: 100%;
                }

                #content-region > div {
                  height: 100%; // leave enough space for saved reports button
                  display: flex;
                  flex-direction: column;
                }
              `} />
          <div
              style={{
                width: "100%",
                height: window.innerHeight
              }}>
            <MenuBar appView="onpoint" onClick={this.handleMenuBarClick} />
            <Drawer
                open={isSavedReportsMenuOpen && !savedReportToShare}
                width={500}
                onRequestOpen={this.openSavedReportsMenu}
                onRequestClose={this.closeSavedReportsMenu}>
              <SavedConfigsMenu
                  headerLabel="Saved Reports"
                  ownershipTypeToSavedConfigs={savedReports}
                  ownershipTypeToCustomDisplayName={Immutable.fromJS({ownedConfigs: "My reports"})}
                  onRefreshConfigsRequest={this.refreshSavedReports}
                  onAddConfigRequest={null}
                  isLoading={isLoadingReports}
                  onSavedConfigSelect={this.handleSavedReportClick}
                  isSavedConfigSharingEnabled={Users.currentHasPermission("SHARE_ONPOINT_REPORT")}
                  onShareSavedConfigRequest={this.handleShareSavedReportClick}
                  onRemoveSavedConfigRequest={this.handleRemoveSavedReportClick}
                  mainContent={null} />
            </Drawer>
            {savedReportToShare &&
                <Dialog
                    label={
                      <span
                          style={{
                            color: this.props.theme.themeId === "light"
                                ? this.props.theme.palette.text.main
                                : this.props.theme.palette.primary.main,
                            textTransform: "uppercase"
                          }}>{`Share ${savedReportToShare.get("name")}`}</span>
                    }
                    onRequestClose={this.handleCloseShareDialogClick}
                    content={
                      <ShareReport
                          config={savedReportToShare}
                          isLoading={loadingSavedReportSharing}
                          reportSharingSettings={editableSavedReportSharingSettings}
                          onReportSharingSettingsChange={this.handleSavedReportSharingChange}
                          hasReportSharingSettingsChanged={
                            !Immutable.is(savedReportSharingSettings, editableSavedReportSharingSettings)
                          }
                          onCancelChangesRequest={this.handleCancelSavedReportSharingClick}
                          onShareReportRequest={this.handleSaveSavedReportSharingClick}
                          shareReportError={shareSavedReportError}
                          shareReportSuccess={shareSavedReportSuccess}
                          onSuccessTimeout={this.handleCloseShareDialogClick} />
                    }
                    flex={1}
                    contentOverflowAutoScroll={false} />}
            <div
                style={{
                  width: "100%",
                  height: window.innerHeight - 65
                }}>
              <ConfigurableReport
                  height={window.innerHeight - 65}
                  key={config.get("id") || config.get("cid")}
                  renderId={renderId}
                  config={config}
                  savedReports={savedReports}
                  isConfigChanged={isConfigChanged}
                  onConfigChange={this.handleConfigChange}
                  onClearReportRequest={this.handleClearReportRequest}
                  onSaveReportRequest={this.createSavedReport}
                  onUpdateReportRequest={this.updateSavedReport}
                  onGroupClick={this.handleGroupCellClick}
                  onUserClick={this.handleUserCellClick}
                  onDataCellClick={this.handleDataCellClick}
                  isSavingReport={isSavingReport} />
            </div>
            {isOneViewKpiClickThroughOpen &&
                <OneViewKpiClickThrough
                    {...oneViewClickThroughOptions}
                    onRequestClose={this.closeOneViewKpiClickThrough}
                    onGroupClick={this.handleOneViewKpiClickThroughGroupClick}
                    onUserClick={this.handleOneViewKpiClickThroughUserClick} />}
            {isRatioDetailsOpen &&
                <OneViewRatioDetails
                    {...ratioDetailsOptions}
                    onRequestClose={this.closeRatioDetails}
                    onKpiClick={this.handleRatioKpiClick} />}
            {isConfirmRemoveReportDialogOpen &&
                <ConfirmRemoveReportDialog
                    reportName={this.state.savedReportToRemove.id.get("name")}
                    onRemoveRequest={this.removeSavedReport}
                    onCancelRequest={this.closeConfirmRemoveReportDialog} />}
          </div>
        </>
    );
  },

  refreshSavedReports() {

    this.setState({
      loadingSavedReports: true
    });
    loadSavedReports()
        .then(savedReports => {
              this.setState({
                savedReports,
                loadingSavedReports: false
              });
            }
            , () => {
              this.setState({
                loadingSavedReports: false
              });
            }
        );
  },

  handleShareSavedReportClick(savedReport) {
    this.setState({
      savedReportToShare: savedReport,
      loadingSavedReportSharing: true
    });
    SavedConfigs
        .getShareList(savedReport.get("id"))
        .then(savedReportSharingSettings => {
          this.setState({
            loadingSavedReportSharing: false,
            savedReportSharingSettings,
            editableSavedReportSharingSettings: savedReportSharingSettings
          });
        }, () => {
          this.setState({
            loadingSavedReportSharing: false,
            shareSavedReportError: "Unable to load onpoint sharing settings"
          });
        });
  }
  ,

  handleCloseShareDialogClick() {
    const initialState = this.getInitialState();
    this.setState({
      savedReportToShare: initialState.savedReportToShare,
      savedReportSharingSettings: initialState.savedReportSharingSettings,
      editableSavedReportSharingSettings: initialState.editableSavedReportSharingSettings,
      shareSavedReportError: initialState.shareSavedReportError,
      shareSavedReportSuccess: initialState.shareSavedReportSuccess
    });
  }
  ,

  handleSavedReportSharingChange(newSavedReportSharingSettings) {
    this.setState({
      editableSavedReportSharingSettings: newSavedReportSharingSettings
    });
  }
  ,

  handleCancelSavedReportSharingClick() {
    this.setState((state) => {
      return ({editableSavedReportSharingSettings: state.savedReportSharingSettings});
    });
  },

  handleSaveSavedReportSharingClick() {
    const {savedReportToShare, editableSavedReportSharingSettings} = this.state;
    this.setState({
      loadingSavedReportSharing: true
    });
    SavedConfigs
        .updateShareList(savedReportToShare.get("id"), editableSavedReportSharingSettings)
        .then(() => {
          this.setState({
            loadingSavedReportSharing: false,
            shareSavedReportSuccess: "Your changes to the saved report sharing settings have been saved"
          });
        }, reason => {
          let errorMsg = "Unable to save changes to the saved report sharing settings";
          const responseJSON = reason.responseJSON;
          if (responseJSON && responseJSON.errors) {
            errorMsg = responseJSON.errors;
          }
          this.setState({
            loadingSavedReportSharing: false,
            shareSavedReportError: errorMsg
          });
        });
  },

  getSavedReport(savedReport) {
    const savedReports = this.state.savedReports.get(savedReport.type);
    return savedReports.get(savedReports.findIndex(sr => sr.get("id") === savedReport.id));
  }
  ,

  handleMenuBarClick(e) {
    if (e === "saved-onpoint-reports") {
      this.openSavedReportsMenu();
    } else {
      throw new Error("Unsupported menu bar click event:", e);
    }
  }
  ,

  openSavedReportsMenu() {
    this.setState({
      isSavedReportsMenuOpen: true
    });
  }
  ,

  closeSavedReportsMenu() {
    this.setState({
      isSavedReportsMenuOpen: false
    });
  }
  ,

  handleSavedReportClick(savedReport, ownershipType) {
    this.closeSavedReportsMenu();
    const timeframeStr = savedReport.getIn(["json", "timeframe"]);
    const timeframeJson = JSON.parse(timeframeStr);
    const groupIds = savedReport.getIn(["json", "groupIds"]);
    const config = savedReport
        .get("json")
        .set("id", savedReport.get("id"))
        .set("timeframe", Immutable.fromJS(timeframeJson))
        .set("groupIds", groupIds.toList());

    if (Users.getCurrentUser().get("dataVisibility") !== "ALL") {
      const visibleUsers = Immutable.fromJS(Users.getUsers()).map(user => user.get("id"));
      const visibleGroups = Immutable.fromJS(Groups.getGroups()).map(group => group.get("id"));
      const canSeeAllUsers = config.get("userIds").isSubset(visibleUsers);
      const canSeeAllGroups = config.get("groupIds").isSubset(visibleGroups);

      if (!canSeeAllUsers || !canSeeAllGroups) {
        Popups.error("The report contains a user/group that you don't have permission to view");
        return;
      }
    }

    if (ownershipType === "ownedConfigs") {
      Auditor.audit("onpoint:run-saved-report", {
        reportId: savedReport.get("id"),
        reportName: savedReport.get("name")
      });
    } else {
      Auditor.audit("onpoint:run-shared-report", {
        reportId: savedReport.get("id"),
        reportName: savedReport.get("name")
      });
    }


    this.setState({
      config,
      isConfigChanged: false,
      renderId: Math.random()
    });
  }
  ,

  handleConfigChange(newConfig) {
    this.setState({
      config: newConfig,
      isConfigChanged: true
    });
  }
  ,

  createSavedReport(reportName) {
    const {config, savedReports} = this.state;
    const configToSave = config
        .set("name", reportName)
        .set("timeframe", JSON.stringify(config.get("timeframe")))
        .delete("cid");
    SavedConfigs
        .create(Immutable.fromJS({configType: "ONPOINT", name: reportName, json: configToSave}))
        .then(newReport => {
          const ownedConfigs = savedReports.get("ownedConfigs");
          Popups.success(`'<strong>${reportName}</strong>' saved`);
          Auditor.audit("onpoint:save-as", {reportName});
          this.setState({
            isSavingReport: false,
            config,
            savedReports: savedReports.set("ownedConfigs", ownedConfigs.push(newReport))
          });
        }, err => {
          const errorMsg = "Unable to save report";
          const responseJSON = err.responseJSON;
          if (responseJSON && responseJSON.errors) {
            Popups.alert(responseJSON.errors, {title: errorMsg});
          } else {
            Popups.error(errorMsg);
          }
        });
  }
  ,

  updateSavedReport() {
    const {config, savedReports} = this.state;
    this.setState({
      isSavingReport: true
    });
    const id = config.get("id");
    const name = config.get("name");
    const configToSave = config.set("timeframe", JSON.stringify(config.get("timeframe")));
    SavedConfigs
        .update(Immutable.fromJS({configType: "ONPOINT", id, name, json: configToSave}))
        .then(updatedReport => {
          const ownedConfigs = savedReports.get("ownedConfigs");
          const index = ownedConfigs.findIndex(ownedConfig => ownedConfig.get("id") === id);
          Popups.success(`'<strong>${name}</strong>' updated`);
          Auditor.audit("onpoint:save", {id, name});
          this.setState({
            isSavingReport: false,
            isConfigChanged: false,
            config,
            savedReports: savedReports.set("ownedConfigs", ownedConfigs.set(index, updatedReport))
          });
        }, err => {
          const errorMsg = "Unable to update report";
          const responseJSON = err.responseJSON;
          if (responseJSON && responseJSON.errors) {
            Popups.alert(responseJSON.errors, {title: errorMsg});
          } else {
            Popups.error(errorMsg);
            this.setState({
              isSavingReport: false
            });
          }
        });
  }
  ,

  handleRemoveSavedReportClick(id, type) {
    this.setState({
      savedReportToRemove: {id, type}
    });
  }
  ,

  closeConfirmRemoveReportDialog() {
    this.setState({
      savedReportToRemove: null
    });
  }
  ,

  removeSavedReport() {
    const {savedReportToRemove, savedReports} = this.state;
    const {id, type} = savedReportToRemove;

    let removePromise;
    if (type === "ownedConfigs") {
      removePromise = SavedConfigs.remove(id.get("id"));
    } else if (type === "sharedUserConfigs") {
      removePromise = SavedConfigs.unshareFromCurrentUser(id.get("id"));
    }

    removePromise.then(() => {
      Popups.success("Removed successfully");
      Auditor.audit("onpoint:delete-report", {name: id.get("name")});

      const index = savedReports.get(type).indexOf(savedReportToRemove);

      this.setState((state) => {
        return {
          savedReports: state.savedReports.update(type, list => list.delete(index)),
          savedReportToRemove: null
        };
      });

    }, reason => {
      let errorMsg = `Unable to remove ${id.get("name")}`;
      const responseJSON = reason.responseJSON;
      if (responseJSON && responseJSON.message) {
        errorMsg = responseJSON.message;
      }
      Popups.error(errorMsg);
      this.setState({
        savedReportToRemove: null
      });
    });
  },

  handleClearReportRequest() {
    this.setState({
      config: getDefaultReportConfig(),
      isConfigChanged: false,
      renderId: Math.random()
    });
  }
  ,

  handleDataCellClick(type, cell) {
    const clickHandlerByType = {
      KPI: this.handleKpiCellClick,
      RATIO: this.handleRatioCellClick
    };
    const handleCellClick = clickHandlerByType[type];
    handleCellClick(cell);
  }
  ,

  handleKpiCellClick(cell) {
    const timeframe = this.state.config.get("timeframe");
    const kpiId = cell.get("kpiId");
    const userId = cell.get("userId");
    const groupId = cell.get("groupId");

    Auditor.audit("kpi_ct:open", {
      type: "onpoint-kpi",
      timeframe: timeframe.toJS(),
      kpiId,
      userId,
      groupId
    });

    const oneViewClickThroughOptions = {
      timeframe: getTimeframeModel(timeframe),
      kpiId,
      userId,
      groupId
    };
    this.setState({oneViewClickThroughOptions});
  }
  ,

  closeOneViewKpiClickThrough() {
    this.setState({oneViewClickThroughOptions: null});
  }
  ,

  handleOneViewKpiClickThroughUserClick(userId) {
    const oneViewConfig = Immutable.Map({
      ...this.state.oneViewClickThroughOptions,
      groupId: Users.getUser(userId).get("groupId"),
      userId
    });
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(
        <OneViewApp initialConfig={oneViewConfig} />
    ));
  }
  ,

  handleOneViewKpiClickThroughGroupClick(groupId) {
    const oneViewConfig = Immutable
        .Map({
          ...this.state.oneViewClickThroughOptions,
          groupId
        })
        .delete("userId");
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(
        <OneViewApp initialConfig={oneViewConfig} />
    ));
  }
  ,

  handleRatioCellClick(cell) {
    const timeframe = this.state.config.get("timeframe");
    const userId = cell.get("userId");
    const ratioDetailsOptions = {
      initialTab: "DETAILS",
      ratio: cell.get("ratio"),
      ratioData: cell.get("data"),
      userId,
      groupId: userId ? Users.getUser(userId).get("groupId") : cell.get("groupId"),
      timeframe: getTimeframeModel(timeframe)
    };
    this.setState({ratioDetailsOptions});
  }
  ,

  handleRatioKpiClick(kpiId, groupId, userId) {
    this.closeRatioDetails();

    const timeframe = this.state.config.get("timeframe");

    Auditor.audit("kpi_ct:open", {
      type: "onpoint-ratio",
      timeframe: timeframe.toJS(),
      kpiId,
      userId,
      groupId
    });

    const oneViewClickThroughOptions = {
      timeframe: getTimeframeModel(timeframe),
      kpiId,
      userId,
      groupId
    };
    this.setState({oneViewClickThroughOptions});
  }
  ,

  closeRatioDetails() {
    this.setState({ratioDetailsOptions: null});
  }
  ,

  handleUserCellClick(userId, timeframe) {
    const groupId = Users.getUser(userId).get("groupId");
    const oneViewConfig = Immutable.Map({
      timeframe: getTimeframeModel(timeframe),
      userId,
      groupId
    });
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(
        <OneViewApp initialConfig={oneViewConfig} />
    ));
  }
  ,

  handleGroupCellClick(groupId, timeframe) {
    const oneViewConfig = Immutable.Map({
      timeframe: getTimeframeModel(timeframe),
      groupId
    });
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(
        <OneViewApp initialConfig={oneViewConfig} />
    ));
  }

}));

const getTimeframeModel = timeframe => timeframeRepo.parse(timeframe.toJS());

const getDefaultReportConfig = () => {
      const clientDefaultTimeframe = timeframeRepo.get(currentClient.get("timeframe"));
      return Immutable.Map({
        cid: Math.random(),
        name: "New OnPoint Report",
        viewType: "COMPACT",         // < COMPACT, DETAILED >
        timeframe: Immutable.fromJS(clientDefaultTimeframe.getRawJson()),
        userIds: Immutable.List(),
        groupIds: Immutable.List(),
        showBreadcrumbs: false,
        showTotals: false,
        columnConfigs: Immutable.List(),
        /*
            columnConfig: {
                type: < KPI, RATIO >,
                name: < kpi / ratio name >,
                kpi: < null, kpi >,
                ratio: < null, ratio >
            }
        */
        sortBy: Immutable.fromJS({
          columnType: "GROUPS_AND_USERS",
          sortOrder: "ASC"
        })
        /*
        sortBy: {
            columnType: < GROUPS_AND_USERS, KPI, RATIO >,
            id: < kpiId, ratioId >,
            sortField: < null, RATIO, TOTAL, TARGET, TARGET_PERCENT >
            sortOrder: < null, ASC, DESC >
        }
        */
      });
    }
;

const loadSavedReports = () =>
    SavedConfigs
        .getAll("ONPOINT")
        .then(savedReports => {
              const ownedIds = savedReports.get("ownedConfigs").map(config => config.get("id")).toSet();
              return savedReports
                  .update("ownedConfigs", configs => configs.map(toSavedReport))
                  .update("sharedUserConfigs", configs => configs
                      .filter(config => !ownedIds.has(config.get("id")))
                      .map(toSavedReport))
                  .update("sharedGroupConfigs", configs => configs
                      .filter(config => !ownedIds.has(config.get("id")))
                      .map(toSavedReport)
                  );
            }
        );

const toSavedReport = config => config
    .updateIn(["json", "groupIds"], groupIds => groupIds.toSet());

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ErrorBoundaryPage theme={theme} {...props} />;
}
;
