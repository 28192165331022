import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {betterMemo} from "js/common/utils/more-memo";

/**
 * Common Header component which uses flex box to align anything you pass into it
 * Assumes you want a bottom margin, can be overridden by passing a style prop
 */

const containerStyle = (theme, style) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.background.paper}`,
  background: theme.themeId === "light" ? theme.palette.background.paper : theme.palette.background.card,
  padding: 10,
  fontWeight: "bold",
  fontSize: "0.8rem",
  display: "flex",
  alignItems: "center",
  gap: "0 0.8em",
  marginBottom: "1.5em",
  ...style
});

const AdminHeader = betterMemo(
    {displayName: "AdminHeader"},
    ({children, style}) => {
      const {theme} = React.useContext(CustomThemeContext);
      return (
          <div style={containerStyle(theme, style)}>
            {children}
          </div>);
    });

export default AdminHeader;
