import Immutable from "immutable";

let visitedStack = Immutable.Stack();
let visitedSet = Immutable.Set();

const visitNode = nodeId => {
  const label = "node-" + nodeId;
  const isLooping = visitedSet.includes(label);
  visitedStack = visitedStack.push(label);
  visitedSet = visitedSet.add(label);
  return isLooping;
};

const visitFormula = formulaId => {
  const label = "formula-" + formulaId;
  const isLooping = visitedSet.includes(label);
  visitedStack = visitedStack.push(label);
  visitedSet = visitedSet.add(label);
  return isLooping;
};

const cleanup = () => {
  visitedStack = visitedStack.pop();
  visitedSet = visitedStack.toSet();
};

export {
  visitNode,
  visitFormula,
  cleanup
};