import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {betterMemo} from "js/common/utils/more-memo";

/**
 * Common component for rendering a Novo icon at different sizes
 * Defaults to "md"
 */

// Possible todo: add different colour variants, at the moment just takes style override
//  https://bullhorn.github.io/novo-elements/docs/#/design/iconography

const iconStyle = (theme, size, style) => {
  const sizeMap = {
    "xs": 10,
    "sm": 13,
    "md": 15,
    "lg": 18,
    "xl": 22
  };

  return {
    fontSize: `${sizeMap[size]}px`,
    color: theme.palette.text.main,
    ...style
  };
};

const Icon = betterMemo(
    {displayName: "Icon"},
    ({icon, size = "md", style}) => {
      const {theme} = React.useContext(CustomThemeContext);
      return <i style={iconStyle(theme, size, style)} className={`bhi-${icon}`} />;
    });

export default Icon;
