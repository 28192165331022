import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import UserAvatar from "js/common/views/user-avatar";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as auditer from "js/common/auditer";
import * as Users from "js/common/users";
import * as Formatter from "js/common/utils/formatter";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";


const NewTopPerformerPanel = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {config, topPerformer, data} = this.props;
    const title = config.get("title");
    const user = Users.getUser(topPerformer.get("userId"));
    const userFullName = user.get("fullName");
    const hasKpis = !config.get("kpis").isEmpty();
    const ua = auditer.getUserAgent();
    const browser = ua.getBrowser();
    const isIeOrEdge = browser.name === "IE" || browser.name === "Edge";
    const headerStyle = {
      ...textStyle,
      color: "#000",
      textShadow: "none",
      fontSize: "1.5vw",
      textTransform: "uppercase",
      maxWidth: "calc(100% - 11vh)",
      paddingLeft: "1vh",
      paddingRight: "1vh",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    };
    const rankStyle = {
      ...textStyle,
      fontSize: "1.75vw",
      textAlign: "center",
      color: "#fff",
      textShadow: "none",
      width: isIeOrEdge ? "8vh" : "11vh",
      height: "6vh",
      lineHeight: "6vh",
      clipPath: isIeOrEdge ? "none" : "polygon(0% -1%, 100% -1%, 100% 101%, 40% 101%)",
      background: "#000",
      paddingLeft: isIeOrEdge ? "2vh" : "5vh",
      paddingRight: "2vh"
    };
    const userFullNameStyle = {
      fontFamily: this.props.theme.typography.fontFamilyBold,
      fontSize: "1.35vw",
      color: "#f9ec33",
      lineHeight: 1.25,
      padding: "0.5vh 1.5vh",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    };
    const userPhotoContainerStyle = {
      height: config.get("kpis").count() > 1 ? "80%" : "30vh",
      width: "auto",
      marginTop: hasKpis ? 0 : "2vh",
      display: "flex",
      justifyContent: "space-around",
      flexShrink: 0,
      overflow: "hidden"
    };
    const imgStyle = {
      maxHeight: "18vh",
      height: "100%",
      width: "auto",
      objectFit: "contain",
      filter: "drop-shadow(0 0 24px rgba(30,30,25,0.67))"
    };
    return (
        <div style={{height: "100%"}}>
          <div
              style={{
                backgroundColor: title ? "#f9ec33" : "transparent",
                height: "6vh",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
            <span style={headerStyle}>{title}</span>
            <span style={rankStyle}>
                        {topPerformer.get("rank")}
                    </span>
          </div>
          <div style={{...panelStyle(this.props.theme), height: "calc(100% - 7.5vh)"}}>
            <div style={{height: hasKpis ? "33%" : "100%"}}>
              <div style={userFullNameStyle}>
                {userFullName}
              </div>
              <div style={userPhotoContainerStyle}>
                <UserAvatar user={user} style={imgStyle} />
              </div>
            </div>
            {hasKpis && data && this.renderKpisDataSummary()}
          </div>
        </div>
    );
  },

  renderKpisDataSummary() {
    const maxKpiValueCharLength = 10;
    const maxKpiNameCharLength = 40;
    return this.props.data
        .sortBy(data => data.get("order"))
        .map((kpiData, index) => {
          const kpiId = kpiData.get("kpiId");
          const headerLabel = kpiData.get("displayName");

          const kpi = kpiRepo.get(kpiId);
          const kpiValueFormat = kpi.get("type").valueFormat;
          const kpiTotal = kpiData.get("total");
          const kpiValueLabel = kpiTotal.get("type") === "error" ?
              kpiTotal.get("value") :
              Formatter.format(kpiTotal.toJS(), {valueFormat: kpiValueFormat, maxDisplayLength: maxKpiValueCharLength});

          let kpiNameLabelFontSize = "1.35vw";
          let kpiValueLabelFontSize = "3.8vw";
          if (headerLabel.length < maxKpiNameCharLength && this.props.data.count() < 3) {
            kpiNameLabelFontSize = "1.75vw";
            kpiValueLabelFontSize = "5vw";
          } else if (headerLabel.length < maxKpiNameCharLength && this.props.data.count() > 2) {
            kpiNameLabelFontSize = "1.5vw";
          }
          const kpiNameDisplayStyle = {
            fontFamily: this.props.theme.typography.fontFamilyBold,
            lineHeight: 1,
            fontSize: kpiNameLabelFontSize,
            width: "100%",
            color: "#fff"
          };
          const kpiValueLabelStyle = {
            fontFamily: this.props.theme.typography.fontFamilyBold,
            color: "#f9ec33",
            fontSize: kpiValueLabelFontSize,
            lineHeight: "1.1"
          };
          const summaryContainerStyle = {
            borderTop: "3px solid #587BBE",
            paddingTop: this.props.data.count() === 1 ? 20 : 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: this.props.data.count() === 1 ? "flex-start" : "center",
            alignItems: "center"
          };
          return (
              <div
                  key={`${kpiId}-${index}`}
                  style={{
                    ...summaryContainerStyle,
                    height: `${Math.floor(67 / this.props.config.get("kpis").count())}%`
                  }}>
                <div style={kpiNameDisplayStyle}>
                  {kpiData.get("displayName")}
                </div>
                <div style={kpiValueLabelStyle}>{kpiValueLabel}</div>
              </div>
          );
        });
  }

});

const textStyle = theme => ({
  fontFamily: theme.typography.fontFamilyBold,
  fontSize: "2vw",
  color: "#fff",
  textShadow: "2px 2px 8px #000"
});

const panelStyle = theme => ({
  overflow: "hidden",
  border: "3px solid #B9B9B9",
  backgroundColor: "rgba(44, 53, 90, 0.56)",
  marginTop: "1vh",
  textAlign: "center",
  ...textStyle(theme)
});

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <NewTopPerformerPanel theme={theme} {...props} />;
};

export default Wrapper;
