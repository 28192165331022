import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ReactSelect from "js/common/views/inputs/immutable-react-select";
import Immutable from "immutable";

import * as tagRepo from "js/common/repo/backbone/tag-repo";
import {createFilter} from "react-select";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const TagFilter = createReactClass({

  propTypes: {
    onChange: ReactPropTypes.func.isRequired,
    label: ReactPropTypes.oneOf(["Match Any Tags", "Match All Tags", "Exclude Tags"]),
    labelStyle: ReactPropTypes.object,
    tagId: ReactPropTypes.number,
    tagIds: ReactPropTypes.array,
    multiple: ReactPropTypes.bool,
    clearable: ReactPropTypes.bool,
    disabled: ReactPropTypes.bool,
    showIcon: ReactPropTypes.bool
  },

  getDefaultProps() {
    return {
      multiple: true,
      clearable: true,
      showIcon: true
    };
  },

  render() {
    const {label, multiple, disabled, clearable, tagId, tagIds, theme, containerStyle} = this.props;
    if ((multiple && tagId) || (!multiple && tagIds)) {
      throw new Error("do not mix up multi/single picker props (multi, tagId, tagIds)");
    }

    return (
        <div className={`table-${theme.themeId}`} style={containerStyle}>
          {label && this.renderLabel()}
          <ReactSelect
              isMulti={multiple}
              isClearable={clearable}
              isDisabled={disabled}
              placeholder={multiple ? "Select Tags" : "Select Tag"}
              selectedValue={tagId}
              selectedValues={multiple && Immutable.List(tagIds)}
              options={getTagOptions()}
              filterOption={createFilter(filterConfig)}
              getOptionLabel={getOptionLabel}
              onChange={this.props.onChange} />
        </div>);
  },

  renderLabel() {
    const {label, labelStyle, showIcon} = this.props;
    return <label style={labelStyle}>{showIcon &&
    <i className="bhi-category-tags" style={{paddingRight: 8}} />}{label}</label>;
  }

});

const filterConfig = {
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: "any",
  trim: true,
  stringify: option => `${option.data.categoryName} ${option.data.tagName}`
};

const getTagOptions = () => Immutable.fromJS(tagRepo.getAll()
    .map(tag => {
      const category = tagRepo.getCategory(tag.get("categoryId"));
      const categoryName = category.get("name");
      const tagName = tag.get("name");
      return {
        categoryName,
        tagName,
        value: tag.id
      };
    }));

const getOptionLabel = option => <span className="multi-tags">
  <strong style={{color: "#888"}}>{option.categoryName}:</strong> {option.tagName}
</span>;


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <TagFilter theme={theme} {...props} />;
};

export default Wrapper;
