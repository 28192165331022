import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";

import ReportingApp from "js/reporting/app";
import Tooltip from "js/common/views/tooltips";
import ErrorMsg from "js/common/views/error";
import ReportingPivotTable from "js/reporting/reporting-pivot-table";
import C19Dialog from "js/common/views/tabs-dialog";
import Cube19 from "js/cube19.app";
import {TextButton} from "js/common/views/inputs/buttons";
import {prepareDataForClickThrough} from "js/common/pivot-utils";
import {reactComponentToMarionetteComponent} from "js/common/views/react-to-marionette";
import * as Users from "js/common/users";
import * as popups from "js/common/popups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as auditer from "js/common/auditer";
import DataDetailsTable from "js/oneview/kpi-details/data-details-table";


const SliceAndDice = createReactClass({
  displayName: "SliceAndDiceCT",

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      pivotDataCellDetails: null
    };
  },

  render() {
    const {pivotDataCellDetails} = this.state;
    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
          <div
              style={{
                width: "100%",
                display: "flex",
                paddingLeft: "1rem",
                paddingRight: "1rem",
                paddingTop: "0.35rem",
                paddingBottom: "0.9rem"
              }}>
            {this.renderDownloadButton()}
            {Users.canAccessApp(Users.getCurrentUser(), "REPORTING") &&
                <div style={{justifyContent: "flex-end", display: "flex", flexGrow: 1}}>
                  <TextButton
                      type="secondary"
                      icon="external-link"
                      label="Edit in Slice & Dice App"
                      onClick={this.gotoSndApp} />
                </div>}
            {this.props.pivotError && <ErrorMsg text={this.props.pivotError} />}
          </div>
          {this.props.originalData.rowLimitReached && <ErrorMsg
              type="warn"
              text={"A limit of 500k rows are shown below. If you need to see data that is not loaded, please adjust your filters."} />}

          <div className={`table-${this.context.theme.themeId}`}>
            <ReportingPivotTable
                config={this.props.pivotConfig}
                derivedPivotTableAttributes={this.props.derivedPivotTableAttributes}
                data={this.props.pivotData}
                onCellClick={this.handlePivotTableCellClick}
                onPivotTableRefresh={this.props.onPivotTableRefresh} />
          </div>
          {pivotDataCellDetails &&
              <C19Dialog
                  onRequestClose={() => this.setState({pivotDataCellDetails: null})}
                  label={pivotDataCellDetails.get("title")}
                  content={
                    <DataDetailsTable
                        height={600}
                        filenameForDownload={"Data"}
                        onDownloadClick={() => auditer.audit("kpi_ct:download_table", this.getAuditOptions())}
                        columns={pivotDataCellDetails.get("columns").toJS()}
                        rows={pivotDataCellDetails.get("rows").toJS()}
                        rowLimitReached={this.props.originalData.rowLimitReached} />
                  } />}
        </div>
    );
  },

  gotoSndApp() {
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(
        <ReportingApp
            initialTimeframe={this.props.timeframe.getRawJson()}
            initialUserId={this.props.userId}
            initialGroupId={this.props.groupId}
            initialKpiIds={Immutable.List.of(this.props.kpiId)}
            initialMatchAnyTagIds={this.props.matchAnyTagIds}
            initialMatchAllTagIds={this.props.matchAllTagIds}
            initialExcludedTagIds={this.props.excludedTagIds}
            initialPivotConfig={this.props.pivotConfig.remove("kpiId")}
            initialPivotData={this.props.pivotData}
            initialOriginalData={this.props.originalData}
            initialDerivedPivotTableAttributes={this.props.derivedPivotTableAttributes} />
    ));
  },

  renderDownloadButton() {
    const buttonLabel = "Download";
    if (Users.currentHasPermission("EXPORT_FILE")) {
      return (
          <a href="#" className="save-to-file">
            <TextButton type="secondary" label={buttonLabel} onClick={this.props.onDownloadSliceAndDiceRequest} />
          </a>
      );
    } else {
      const tooltipLabel = "Ask an admin user for the 'Export To File' permission to download this data";
      return (
          <Tooltip text={tooltipLabel} position="right">
            <TextButton
                type="default"
                disabled={true}
                label={buttonLabel}
                onClick={() => popups.alert(tooltipLabel, {title: "Permission required to download"})}
                style={{opacity: 0.5}} />
          </Tooltip>
      );
    }
  },

  handlePivotTableCellClick(dataFilter) {
    const pivotDataCellDetails = prepareDataForClickThrough(
        this.props.originalData,
        dataFilter,
        this.props.derivedPivotTableAttributes);
    if (!pivotDataCellDetails) {
      popups.error("There is no data behind this cell");
    } else {
      this.setState({pivotDataCellDetails});
    }
  }

});

SliceAndDice.contextType = CustomThemeContext;

export default SliceAndDice;
