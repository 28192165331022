import Immutable from "immutable";

const hasNoNeighbours = (nodeId, idToNeighbourIds, hiddenNodeIds) => idToNeighbourIds
    .get(nodeId, Immutable.List())
    .filter(nodeId => !hiddenNodeIds.includes(nodeId))
    .isEmpty();

const getNodeType = (nodeId, idToChildIds, idToParentIds, hiddenNodeIds) => {
  if (hasNoNeighbours(nodeId, idToChildIds, hiddenNodeIds) && hasNoNeighbours(nodeId, idToParentIds, hiddenNodeIds)) {
    return "default";
  }else if (hasNoNeighbours(nodeId, idToChildIds, hiddenNodeIds)) {
    return "output";
  } else if (hasNoNeighbours(nodeId, idToParentIds, hiddenNodeIds)) {
    return "input";
  } else {
    return "centralNode";
  }
};

const getIdToChildIds = idToNode => idToNode
    .toMap()
    .map(node => node
        .get("edgesOut", Immutable.List())
        .map(e => e.get("nodeId"))
        .toSet());

const getIdToParentIds = nodes => nodes.reduce(
    (idToParentIds, node) => {
      const childIds = node.get("edgesOut", Immutable.List()).map(e => e.get("nodeId"));
      if (childIds.isEmpty()) {
        return idToParentIds;
      } else {
        const nodeId = node.get("id");
        return childIds.reduce(
            (idToParentIds, childId) => idToParentIds.update(childId, Immutable.Set(), parentIds => parentIds.add(nodeId)),
            idToParentIds);
      }
    },
    Immutable.Map());

const findAllIds = (id, idToNextIds) => {
  if (idToNextIds.has(id)) {
    const nextIds = idToNextIds.get(id);
    return nextIds.union(nextIds.flatMap(nextId => findAllIds(nextId, idToNextIds)).toSet());
  } else {
    return Immutable.Set();
  }
};

const getNodeIdsToHide = (nodes, idToChildIds, idToParentIds, nodeIdToNodeDisplayForStep) => {
  return nodes
      .flatMap(node => {
        const nodeId = node.get("id");
        const visibility = nodeIdToNodeDisplayForStep.getIn([nodeId, "visibility"]);
        if (visibility) {
          switch (visibility) {
            case "hide-children":
              return findAllIds(nodeId, idToChildIds);
            case "hide-parents":
              return findAllIds(nodeId, idToParentIds);
            case "hide-children-and-self":
              return findAllIds(nodeId, idToChildIds).add(nodeId);
            case "hide-parents-and-self":
              return findAllIds(nodeId, idToParentIds).add(nodeId);
            default:
              throw new Error("unsupported node visibility of " + visibility);
          }
        } else {
          return Immutable.Set();
        }
      })
      .toSet();
};

export {
  getNodeIdsToHide,
  getNodeType,
  getIdToChildIds,
  getIdToParentIds
};
