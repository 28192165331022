import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import EditLeaderboard from "js/admin/cubetv/leaderboard/edit-leaderboard";
import EditTopPerformers from "js/admin/cubetv/leaderboard/edit-top-performers";
import EditCompanyOverview from "js/admin/cubetv/leaderboard/edit-company-overview";
import SectionHeader from "js/admin/cubetv/section-header";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const pathToLeaderboardConfig = ["layout", "left", 0];
const pathToTopPerformersConfig = ["layout", "right", 0];
const pathToCompanyOverviewConfig = ["layout", "right", 1];

const EditLeaderboardSlide = createReactClass({

    mixins: [PureRenderMixin],

    propTypes:{
        timeframes: ImmutablePropTypes.list.isRequired,
        slide: ReactPropTypes.object.isRequired,
        onChange: ReactPropTypes.func.isRequired,
        theme: ReactPropTypes.object
    },

    render() {
        const { slide, timeframes, theme } = this.props;
        return (
            <div style={{marginBottom: "1rem"}}>
                <SectionHeader text="Leaderboard - Displays 1 ranked Metric and 1 optional Metric for Users within a Group" />
                <EditLeaderboard
                    timeframes={timeframes}
                    leaderboard={slide.getIn(pathToLeaderboardConfig)}
                    onChange={this.handleLeaderboardChange} />
                <SectionHeader text="Top Performers - Displays the User's profile picture and up to 3 Metrics" />
                <EditTopPerformers
                    timeframes={timeframes}
                    topPerformers={slide.getIn(pathToTopPerformersConfig)}
                    onChange={this.handleTopPerformersChange} />
                <SectionHeader text="Group Overview - Displays up to 2 Metrics for a Group after the Top Performer highlights" />
                <EditCompanyOverview
                    timeframes={timeframes}
                    companyOverview={slide.getIn(pathToCompanyOverviewConfig)}
                    onChange={this.handleCompanyOverviewChange} />
            </div>);
    },

    handleLeaderboardChange(leaderboard) {
        this.props.onChange(this.props.slide.setIn(pathToLeaderboardConfig, leaderboard));
    },

    handleTopPerformersChange(topPerformers) {
        this.props.onChange(this.props.slide.setIn(pathToTopPerformersConfig, topPerformers));
    },

    handleCompanyOverviewChange(companyOverview) {
        this.props.onChange(this.props.slide.setIn(pathToCompanyOverviewConfig, companyOverview));
    }

});

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <EditLeaderboardSlide theme={theme} {...props} />;
};