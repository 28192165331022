import moment from "moment";

const MYSQL_DATE_PATTERN = "YYYY-MM-DD";
const MYSQL_DATETIME_PATTERN = "YYYY-MM-DD HH:mm:ss";

const formatDateTime = (momentObj, pattern = MYSQL_DATETIME_PATTERN) => momentObj.format(pattern);
const formatDate = (momentObj, pattern = MYSQL_DATE_PATTERN) => momentObj.format(pattern);

const parseDateTime = (dateStr, format = MYSQL_DATETIME_PATTERN) => moment(dateStr, format);
const parseDate = (dateStr, format = MYSQL_DATE_PATTERN) => moment(dateStr, format);

export {
  formatDateTime,
  formatDate,
  parseDateTime,
  parseDate
};