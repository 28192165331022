import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const Dialog = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        headerLabel: ReactPropTypes.string.isRequired
    },

    componentDidMount() {
        preventScrolling();
    },

    componentWillUnmount() {
        allowScrolling();
    },

    render() {
        const { headerLabel, children, theme } = this.props;

        return (
            <div className="dialog-overlay ignore-react-onclickoutside">
                <div className="dialog-container" style={dialogStyle(theme)}>
                    <h1 style={headerStyle(theme)}>
                        {headerLabel}
                    </h1>
                    <div style={{ margin: "0.5rem 0" }}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }

});

const dialogStyle = theme => ({
    display: "inline-block",
    verticalAlign: "middle",
    overflow: "auto",
    padding: "20px 20px",
    borderRadius: 8,
    backgroundColor: theme.palette.background.card,
    textAlign: "left",
    WebkitOverflowScrolling: "touch",
    minWidth: 410,
    width: "auto",
    minHeight: 200,
    height: "auto"
});

const headerStyle  = theme => ({
    fontSize: "1rem",
    fontWeight: "normal",
    textTransform: "uppercase",
    color: theme.palette.primary.main
});

const preventScrolling = () => document.body.style.overflow = "hidden";
const allowScrolling = () => document.body.style.overflow = "auto";


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <Dialog theme={theme} {...props} />;
};

export default Wrapper;