import Immutable from "immutable";

import Cube19 from "js/cube19.app";
import * as ajax from "js/common/ajax";
import eventBus from "js/cube19.event-bus";

import {indexBy} from "js/common/utils/collections";
import {fetchFallbackUsers} from "js/common/users";

const path = window.path;
const FilteredCollection = window.FilteredCollection;

Cube19.module("Users", function(Users, App, Backbone, Marionette, $, _) {

  const TagAssignment = Backbone.Model.extend({});

  const UserTagHistory = Backbone.Collection.extend({
    model: TagAssignment,

    initialize(models, options) {
      this.userId = options.userId;
    },

    url() {
      return path(App.urlRoot, "users", this.userId, "tags");
    }

  });

  const GroupAssignment = Backbone.Model.extend({});

  const UserGroupHistory = Backbone.Collection.extend({
    model: GroupAssignment,

    initialize(models, options) {
      this.userId = options.userId;
    },

    url() {
      return path(App.urlRoot, "users", this.userId, "history");
    }

  });

  const UserPermission = Backbone.Model.extend({});

  const UserPermissions = Backbone.Collection.extend({
    model: UserPermission,

    initialize(models, options) {
      this.userId = options.userId;
    },

    url() {
      return path(App.urlRoot, "users", this.userId, "permissions");
    }

  });

  const AppAssignments = Backbone.Collection.extend({
    model: UserPermission,

    initialize(models, options) {
      this.userId = options.userId;
    },

    url() {
      return path(App.urlRoot, "users", this.userId, "apps");
    }

  });

  const User = Backbone.Model.extend({

    parse(response) {
      response.permissions = new UserPermissions(response.permissions, {userId: response.id});
      response.appAssignments = new AppAssignments(response.appAssignments, {userId: response.id});
      return response;
    },

    toJSON(options) {
      return _.omit(this.attributes, [
        "tags",
        "history",
        "permissions",
        "appAssignments"
      ]);
    },

    initialize() {
      this.set("history", new UserGroupHistory([], {userId: this.id}));
      this.set("tags", new UserTagHistory([], {userId: this.id}));
    }

  });

  Users.UserCollection = Backbone.Collection.extend({
    url: "users",
    model: User,

    comparator(user) {
      return user?.get("fullName")?.toLowerCase();
    }

  });

  Users.Controller = Marionette.Controller.extend({

    initialize(options) {
      App.reqres.setHandler("users", this.getUsers, this);
      App.reqres.setHandler("users:active", this.getActiveUsers, this);
      App.reqres.setHandler("users:load", this.load, this);

      App.reqres.setHandler("user", this.getUser, this);
      App.reqres.setHandler("user:current", this.getCurrentUser, this);
      App.reqres.setHandler("user:source", this.getSourceUser, this);

      App.reqres.setHandler("users:hierarchy-changed", this.getUpdatedHeirarchy, this);

      App.reqres.setHandler("user:previous", this.getPreviousUser, this);
      App.reqres.setHandler("user:next", this.getNextUser, this);
      App.reqres.setHandler("user:load-current", this.loadCurrentUser, this);
      App.reqres.setHandler("user:load-source", this.loadSourceUser, this);

      App.vent.on("auth:logged-in", this.load, this);
      eventBus.on("logged-in-user:changed", this.loadCurrentUser, this);
      eventBus.on("current-user:updated", this.loadCurrentUser, this);
      eventBus.on("source-user:updated", this.loadSourceUser, this);

      this.users = new Users.UserCollection();
      this.idToNameOnlyUser = new Immutable.Map();
    },

    load() {
      const fetchUsers = this.users
          .fetch()
          .catch(xhr => {
            if (xhr.responseJSON?.type === "USER_CANNOT_BE_ORPHAN") {
              // ignore error, leave collection blank
            } else {
              throw xhr;
            }
          });

      Promise.all([fetchUsers, this.loadFallbackUsers()])
          .then(([_users, _fallbackUsers]) => {
            this.activeUsers = new FilteredCollection(this.users);
            this.activeUsers.filterBy({state: "VISIBLE"});
            App.vent.trigger("data-loaded", "users");
          });
    },

    loadCurrentUser() {
      return ajax
          .get({
            url: "users/current",
            fatalError: true
          })
          .then(response => {
            this.currentUserId = response.id;
            this.loggedInUser = new User(response, {parse: true});
            return Immutable.fromJS(response);
          });
    },

    loadSourceUser() {
      return ajax
          .get({
            url: "users/source",
            fatalError: true
          })
          .then(response => {
            this.sourceUser = new User(response, {parse: true});
            return Immutable.fromJS(response);
          });
    },

    loadFallbackUsers() {
      return fetchFallbackUsers()
          .then(fallbackUsers => {
            this.idToNameOnlyUser = indexBy(u => u.get("id"), fallbackUsers);
            return this.idToNameOnlyUser;
          });
    },

    getUser(id) {
      return this.users.get(id) ?? this.idToNameOnlyUser.get(id);
    },

    getPreviousUser(user) {
      const index = this.activeUsers.indexOf(user);
      let previousIndex = index - 1;
      if (previousIndex < 0) {
        previousIndex = this.activeUsers.length - 1;
      }
      const previousUser = this.activeUsers.at(previousIndex);
      return previousUser;
    },

    getNextUser(user) {
      const index = this.activeUsers.indexOf(user);
      let nextIndex = index + 1;
      if (nextIndex >= this.activeUsers.length) {
        nextIndex = 0;
      }
      const nextUser = this.activeUsers.at(nextIndex);
      return nextUser;
    },

    getUpdatedHeirarchy() {
      this.users.fetch();
      this.activeUsers = new FilteredCollection(this.users);
      this.activeUsers.filterBy({state: "VISIBLE"});
    },

    getActiveUsers() {
      return this.activeUsers;
    },

    getUsers() {
      return this.users;
    },

    getCurrentUser() {
      return this.loggedInUser;
    },

    getSourceUser() {
      return this.sourceUser;
    },

  });

});
