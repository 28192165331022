import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import Immutable from "immutable";

import * as Users from "js/common/users";
import * as ajax from "js/common/ajax";

import Icon from "js/admin/common/icon";
import { Layout } from "js/common/views/foundation-column-layout";
import LoadingSpinner from "js/common/views/loading-spinner";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const _ = window._;

const UploadBulkFilesTable = createReactClass({

  getInitialState() {
    return {
      isLoading: false,
      asyncJobs: [],
      selectedJobId: null,
    };
  },

  componentDidMount() {
    this.setState({ isLoading: true, selectedJobId: this.props.selectedJobId || null });
    this.loadJobs(this.props.url);
  },

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedJobId !== this.props.selectedJobId) {
      this.setState({ isLoading: true, selectedJobId: this.props.selectedJobId || null });
      this.loadJobs(this.props.url);
    }
  },

  render() {
    return (
          <Layout allSmall={12} collapseRow={false} rowStyle={{ marginTop: "1rem" }}>
            {this.state.isLoading ? <LoadingSpinner /> : this.state.asyncJobs.length > 0 ? this.renderTable() : this.renderFallback()}
          </Layout>
    );
  },

  renderFallback() {
    return(
        <div style={{display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center"}}>
          <span style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            width: 60,
            height: 60,
            margin: "0.5rem 0",
            textAlign: "center",
            background: "rgba(0,0,0,0.1)"
          }}>
            <Icon icon="no-data" size="xl" />
          </span>
          <p style={{opacity: 0.7}}>You have not uploaded any files yet</p>
        </div>
    )
  },

  renderTable() {
    const selectedJob = _(this.state.asyncJobs).find(j => j.id === this.state.selectedJobId);
    const columns = this.props.type === "status-of-updates"
        ? ["ID", "Started By", "Started On", "Status"]
        : ["ID", "File Name", "Uploaded By", "Uploaded On", "Status"];

    return(
        <div>
          {selectedJob && getMessageForJob(Immutable.fromJS(selectedJob), this.props.theme)}
          <SimpleDataTable
              tableType="UPLOAD_SUMMARY"
              onCellClick={this.handleCellClick}
              uploadType={this.props.type}u
              columns={columns}
              searchable
              rows={this.state.asyncJobs.map(j => asyncJobToRow(j, this.props.type))}
              initialSortDirection="DESC" />
        </div>
    );
  },

  loadJobs(url) {
    loadAsyncJobs(url).then(asyncJobs => this.setState({ asyncJobs, isLoading: false }));
  },

  handleCellClick(cellValue, row) {
    const asyncJobId = row[0].originalValue;
    const asyncJob = _(this.state.asyncJobs).find(j => {
      return j.id === asyncJobId;
    });
    this.setState({ selectedJobId: asyncJob.id });
  }

});

const loadAsyncJobs = (url) => ajax.get({ url })
    .then(asyncJobs => asyncJobs.map(parsePayload));

const parsePayload = asyncJob => {
  return {
    ...asyncJob,
    payload: JSON.parse(asyncJob.payload)
  };
};

const asyncJobToRow = (asyncJob, type) => {
  const uploadedBy = Users.getUser(asyncJob.userId).get("fullName");
  if(type === "status-of-updates") {
    return [
      asyncJob.id,
      uploadedBy,
      formatDateTime(moment(asyncJob.startTimestamp).local()),
      asyncJob.status
    ];
  } else {
    return [
      asyncJob.id,
      asyncJob.payload.originalFileName,
      uploadedBy,
      formatDateTime(moment(asyncJob.startTimestamp).local()),
      asyncJob.status
    ];
  }
};

const getMessageForJob = (asyncJob, theme) => {
  const errors = asyncJob.get("errors");

  if (!errors.isEmpty() || asyncJob.status === "FAILED") {
    return (
        <div style={{
          borderRadius: "5px",
          background: theme.palette.error.background,
          border: `1px solid ${theme.palette.error.main}`,
          marginBottom: -45,
          padding: "10px 10px 8px 10px",
          position: "relative"}}>
          <p style={{fontSize: 13, color: theme.palette.error.main, margin: 0, display: "flex", alignItems: "center"}}>
            <i className="fa fa-exclamation-triangle" style={{ color: theme.palette.error.main, paddingRight: 10, marginTop: -4 }} />
            {!errors.isEmpty() ?
                <span>
                  <strong>Job ID {asyncJob.get("id")} could not be processed</strong><br/>{errors.first().get("message")}
                </span>
                :
                <span>
                  <strong>Job ID {asyncJob.get("id")} update failed</strong><br/>Please download your file to view what went wrong and re-upload once the errors have been fixed.
                </span>
            }
          </p>
        </div>
    );
  }
};

const formatDateTime = (date, pattern = "YYYY-MM-DD HH:mm:ss") => date.format(pattern);

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <UploadBulkFilesTable theme={theme} {...props} />;
};
