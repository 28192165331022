import {css} from "@emotion/react";

export const tagFilterStyles = theme => (
    css`
      padding: 0 1rem 1.75rem 1rem;
      margin-bottom: 80px;

      .Select-control {
        background-color: rgba(0, 0, 0, 0.25);
        margin-bottom: 1.25rem;
        transition: background 0.3s ease;

        &:hover {
          background: ${theme.palette.background.dark};
        }
      }

      .Select-value {
        background-color: ${theme.palette.background.card};
      }
    `);

export const filterHeadingStyle = theme => css`
  display: inline-block;
  margin: 20px 0px 5px 10px;
  font-family: ${theme.typography.fontFamilyBold};
  font-size: 15px;
  color: ${theme.palette.main}; 
  text-transform: none;
`;

export const drawerControlsStyle = (theme, width = "598px") => (
    css`
      display: flex;
      flex-direction: ${theme.themeId === "light" ? "row-reverse" : "row"};
      padding: 1.25rem;
      background: ${theme.palette.background.card};
      position: fixed;
      bottom: 0;
      width: ${width};
      @media (max-width: 720px) {
        padding-left: 0;
        padding-right: 0;
        flex-direction: row;
      }
    `);

export const filterSectionStyles = css`
  padding: 1.5rem 1rem 1.75rem 1rem;
`;

export const applyButtonStyle = css`
  margin: 0 10px;
  height: 42px;
  font-size: 15px;
`;

export const resetButtonStyle = css`
  background: #eee;
  margin: 0px 10px;
  height: 42px;
  font-size: 15px;
`;

export const labelStyle = bgColor => ({
  display: "inline-block",
  marginBottom: 10,
  marginTop: 15,
  padding: "3px 6px",
  borderRadius: "5px",
  textAlign: "center",
  fontSize: "10px",
  lineHeight: "14px",
  color: "rgba(0, 0, 0, 0.36)",
  backgroundColor: bgColor
});
