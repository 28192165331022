import UAParser from "ua-parser-js";
import moment from "moment";
import store from "store";

import * as Ajax from "js/common/ajax";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";

const _ = window._;
const MYSQL_DATETIME_PATTERN = "YYYY-MM-DD HH:mm:ss";
const BUFFER_TIME_IN_SECONDS = 10;
setInterval(flushBuffers, BUFFER_TIME_IN_SECONDS * 1000);

let uniqueBuffer = [];
let squashedBuffer = {};
let timedAudits = {};

let ua = new UAParser();

function getUserAgent() {
  return ua;
}

function audit(category, payload = {}, options = {}) {
  payload = processPayload(payload, options);
  addToSquashedBuffer(category, payload);
}

function processPayload(payload = {}, options = {}) {
  payload.auditTimestamp = moment.utc().format(MYSQL_DATETIME_PATTERN);
  payload.timestamp = moment.utc().valueOf();
  payload.cube19DeviceId = store.get("deviceId");

  if (payload.userId) {
    payload.user = Users.getUser(payload.userId).get("fullName");
  }
  if (payload.groupId) {
    payload.group = Groups.getGroup(payload.groupId).get("name");
  }
  if (payload.kpiId) {
    const kpi = KpiRepo.get(payload.kpiId);
    payload.kpi = kpi.get("name");
    payload.kpiQueryType = kpi.get("type").query;
  }

  payload.isCube19Admin = Users.getCurrentUser().get("cube19User");

  if (options.userAgent) {
    payload = addUserAgentAndThemeData(payload);
  }
  if (options.usePayloadFrom) {
    let previousAudit = timedAudits[options.usePayloadFrom];
    if (previousAudit) {
      payload = _.extend({}, previousAudit.payload, payload);
    }
  }

  return payload;
}

function addUserAgentAndThemeData(payload = {}) {
  let browser = ua.getBrowser();
  payload.browserName = browser.name;
  payload.browserVersion = browser.version;

  let device = ua.getDevice();
  payload.deviceModel = device.model;
  payload.deviceVendor = device.vendor;
  payload.deviceType = device.type;

  let os = ua.getOS();
  payload.osName = os.name;
  payload.osVersion = os.version;

  payload.theme = localStorage.getItem('appTheme');
  return payload;
}

function addToSquashedBuffer(category, payload = {}) {
  let currentCount;
  if (squashedBuffer[category]) {
    currentCount = squashedBuffer[category].count + 1;
  } else {
    currentCount = 1;
  }

  payload.count = currentCount;
  squashedBuffer[category] = payload;
}

function uniqueAudit(category, payload = {}, options = {}) {
  payload = processPayload(payload, options);
  uniqueBuffer.push({
    category: category,
    payload: payload
  });
}

function storeForCategory(category, payload = {}, options = {}) {
  payload = processPayload(payload, options);

  timedAudits[category] = {
    payload: payload,
    startTimestamp: moment.utc()
  };
}

function auditWithStored(category, payload = {}, options = {}) {
  const timedAudit = timedAudits[category];
  if (!timedAudit) {
    console.log("cannot used stored audit for '" + category + "', no storage was made");
    return;
  }

  payload = {...timedAudit.payload, ...payload};

  uniqueBuffer.push({
    category: category,
    payload: payload
  });
}

function flushBuffers() {
  let auditDtos = _.map(squashedBuffer, (payload, category) => {
    return {
      category: category,
      payload: payload
    };
  });
  squashedBuffer = {};

  auditDtos = auditDtos.concat(uniqueBuffer);
  uniqueBuffer = [];

  if (auditDtos.length > 0) {
    sendToServer(auditDtos);
    sendToGainsight(auditDtos);
  }
}

function sendToServer(auditDtos) {
  Ajax
      .post({
        url: "audit",
        json: auditDtos
      })
      .then(response => {
        auditDtos.forEach(auditDto => {
          console.debug("audit", auditDto.category, auditDto.payload);
        });
      });
}

function sendToGainsight(auditDtos) {
  const aptrinsic = window.aptrinsic;
  if(aptrinsic) {
    auditDtos.forEach(auditDto => {
      aptrinsic('track', auditDto.category, auditDto.payload);
    });
  }
}

export {
  getUserAgent,
  audit,
  uniqueAudit,
  storeForCategory,
  auditWithStored
};
