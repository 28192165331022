import React, { useState } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import getTheme from 'js/common/themes/base'
import {CssBaseline} from "@mui/material";
import currentClient from "js/common/repo/backbone/current-client";

// eslint-disable-next-line no-unused-vars
export const CustomThemeContext = React.createContext(
    {
      setTheme: null,
      theme: getTheme("light")
    },
);

const CustomThemeProvider = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children } = props;
  const $ = window.$;
  const clientTheme = currentClient && currentClient.getTheme();

  // State to hold the selected theme name
  const currentTheme = localStorage.getItem('appTheme') || clientTheme || "light";

  if(currentTheme === "dark") {
    $("body").addClass("theme-dark") && $("body").removeClass("theme-light");
  }

  const [themeName, _setThemeName] = useState(currentTheme);

  let theme = getTheme(themeName);

  // Wrap _setThemeName to store new theme names in localStorage
  const setThemeName = (name) => {
    localStorage.setItem('appTheme', name);
    _setThemeName(name);
    theme = getTheme(name);

    if(name === "light") {
      $("body").addClass("theme-light") && $("body").removeClass("theme-dark");
    }

    if(name === "dark") {
      $("body").addClass("theme-dark") && $("body").removeClass("theme-light");
    }

    $("body").css({
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.main,
      fontFamily: theme.typography.fontFamily,
    });
  };

  const contextValue = {
    setTheme: setThemeName,
    theme: theme
  };

  return (
    <CustomThemeContext.Provider value={contextValue}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </CustomThemeContext.Provider>
  );
};

export default CustomThemeProvider
