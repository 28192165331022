import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import Dialog from "js/common/views/dialog";
import { Button as FlatButton } from '@mui/material';
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const NonClientFilterableKpisInfoDialog = createReactClass({

    propTypes: {
        onClose: ReactPropTypes.func.isRequired,
        isOpen: ReactPropTypes.bool.isRequired,
        nonClientFilterableKpis: ReactPropTypes.array.isRequired,
        theme: ReactPropTypes.object
    },

    render() {
        const { theme } = this.props;
        return (
            <Dialog
                title="Non Client filterable Metrics selected"
                actions={[
                    <FlatButton key="dismiss-btn" primary={true} onClick={this.props.onClose} data-test-id="dismiss-button">Got it!</FlatButton>
                ]}
                autoDetectWindowHeight={true}
                titleStyle={{ color: theme.palette.warning.main, fontSize:"1rem" }}
                bodyStyle={{ color: theme.palette.textColor }}
                open={this.props.isOpen}>
                The Client filter has been disabled as the following selected Metrics are not filterable by Client:
                <ul style={list}>
                    {this.renderNonClientFilterableKpisList()}
                </ul>
            </Dialog>);
    },

    renderNonClientFilterableKpisList() {
        return this.props.nonClientFilterableKpis.map(kpi => {
            return (
                <li key={kpi.id || kpi.cid} style={listItem}>
                    {kpi.get("name")}
                </li>);
        });
    }

});

const bulletShape = "url('data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 12 12\"><circle fill=\"#f9ec33\" cx=\"6\" cy=\"4\" r=\"4\"/></svg>')";
const list = {
    listStylePosition: "inside",
    listStyleImage: bulletShape,
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20
};
const listItem = {
    lineHeight: 1,
    padding: "10px 0"
};


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <NonClientFilterableKpisInfoDialog theme={theme} {...props} />;
};
  
export default Wrapper;