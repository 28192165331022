/** @jsxImportSource @emotion/react */

import {jsx} from "@emotion/react";
import React from "react";
import Immutable from "immutable";
import TextField from '@mui/material/TextField';
import {TextButton} from "js/common/views/inputs/buttons";
import {
  CombineTypePicker,
  GenericEntityPicker,
  KpiPicker,
  MasterMetricPicker,
  TemplatePicker
} from "js/admin/kpis/edit-kpis/tabs/edit-config";
import * as Colors from "js/common/cube19-colors";
import Info from "js/common/views/info-text-box";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Dialog from "js/common/views/dialog";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";

const AddKpi = ({
  isOpen,
  handleClose,
  idToTemplate,
  typeToGroupingEntity,
  handleAddKpi,
  handleCloneKpi,
  handleAddCombinedKpi,
  kpiIdToCloneOrCombine,
  idToWrappedKpi,
  kpiIdToMasterKpis,
  masterKpiTypeToKpiId,
  theme
}) => {

  const kpis = idToWrappedKpi.valueSeq().map(kpi => kpi.get("kpi")).toList();
  const names = idToWrappedKpi.map(kpi => kpi.getIn(["kpi", "name"])).valueSeq().toList();

  const checkIfDuplicate = (name) => names.includes(name);

  const ActionButtons = ({ readyToSubmit, onClick, parentKpiId }) => {
    return <div style={{marginTop: 10}}>
      <TextButton
          label={"Cancel"}
          style={{marginTop: 10, marginRight: 10}}
          onClick={handleClose} />
      <a href={`#${parentKpiId || ""}`}>
        <TextButton
            label={"Create Metric"}
            type="default"
            disabled={!readyToSubmit}
            style={{marginTop: 10, marginLeft: 10}}
            onClick={onClick} />
      </a>
    </div>
  };

  const BlankKpiForm = () => {
    const [newKpi, setNewKpi] = React.useState(Immutable.Map({name: ""}));
    const isGenericEntityRequired = idToTemplate.get(newKpi.get("templateId"), Immutable.Map()).get("queryType") === "GENERIC_ENTITY";

    const isNameDuplicate = checkIfDuplicate(newKpi.get("name"));

    const isFormComplete = newKpi.get("name") &&
        !isNameDuplicate &&
        newKpi.get("templateId") &&
        (!isGenericEntityRequired || newKpi.get("genericEntity"));

    return <div>
      <Hint>
        <Icon icon="info" style={{color: theme.palette.hints.text}} />
        Create a new metric from scratch without using any pre-existing config.
      </Hint>
      <NameInput
          theme={theme}
          name={newKpi.get("name")}
          error={isNameDuplicate}
          onChange={value => setNewKpi(newKpi.set("name", value))} />
      <TemplatePicker
          templateId={newKpi.get("templateId", null)}
          containerStyle={{textAlign: "left"}}
          onChange={(id) => {setNewKpi(newKpi.set("templateId",id))}}
          idToTemplate={idToTemplate}
      />
      {isGenericEntityRequired && <GenericEntityPicker
          containerStyle={{flex: 1, marginLeft: "1rem", textAlign: "left"}}
          typeToGroupingEntity={typeToGroupingEntity}
          genericEntityType={newKpi.get("genericEntity", null)}
          onChange={(option) => {
            setNewKpi(newKpi.set("genericEntity",option))
          }} />}
      <ActionButtons readyToSubmit={isFormComplete} onClick={() => handleAddKpi(newKpi)}/>
    </div>
  };

  const CombineKpiForm = () => {

    const [newKpi, setNewKpi] = React.useState(Immutable.Map({combineWithKpiId: kpiIdToCloneOrCombine, name: ""}));

    const savedKpis = idToWrappedKpi
        .filter(wrappedKpi => !wrappedKpi.get("isUnsaved"))
        .valueSeq()
        .map(kpi => kpi.get("kpi"))
        .toList();

    const combineOptions = ["combineWithKpiId", "combineWithMasterMetricType", "combineType", "combineOptions"];
    const clearCombineOptions = () => setNewKpi(newKpi.deleteAll(combineOptions));
    const masterMetricsAvailableToCombineWith = kpis
        .flatMap(kpi => kpiIdToMasterKpis.get(kpi.get("id"), Immutable.List()));

    const handleCombineMasterMetricChange = value => {
      if (value === null) {
        clearCombineOptions();
      } else {
        setNewKpi(newKpi
            .set("combineWithMasterMetricType", value)
            .delete("combineWithKpiId"));
      }
    };

    const handleCombineMetricChange = value => {
      if (value === null) {
        clearCombineOptions();
      } else {
        setNewKpi(newKpi
            .set("combineWithKpiId", value)
            .delete("combineWithMasterMetricType")
        );
      }
    };

    const isNameDuplicate = checkIfDuplicate(newKpi.get("name"));

    const isFormComplete = newKpi.get("name") &&
        !isNameDuplicate &&
        (newKpi.get("combineWithKpiId") || newKpi.get("combineWithMasterMetricType"));

    return <div>
      <Hint>
        <Icon icon="info" style={{color: theme.palette.hints.text}} />
        Create a new metric based on an existing metric.  Any future changes to the parent will be reflected in your new metric.
      </Hint>
      <NameInput
          theme={theme}
          name={newKpi.get("name")}
          error={isNameDuplicate}
          onChange={value => setNewKpi(newKpi.set("name", value))}/>
      <div style={{display: "flex", paddingBottom: 15}}>
        <KpiPicker
            containerStyle={{flex: 1, textAlign: "left"}}
            kpis={savedKpis}
            kpiId={newKpi.get("combineWithKpiId")}
            isDisabled={newKpi.get("combineWithMasterMetricType")}
            onChange={value => handleCombineMetricChange(value)} />
        <div style={{alignSelf: "center", margin: "0px 10px", paddingTop: 20}}>or</div>
        <MasterMetricPicker
            containerStyle={{flex: 1, textAlign: "left"}}
            masterMetrics={masterMetricsAvailableToCombineWith}
            masterMetricType={newKpi.get("combineWithMasterMetricType")}
            isDisabled={newKpi.get("combineWithKpiId")}
            onChange={value => handleCombineMasterMetricChange(value)} />
      </div>
      <CombineTypePicker
          containerStyle={{flex: 1, textAlign: "left"}}
          combineType={newKpi.get("combineType") || "MERGE_WITH_AND"}
          onChange={value => setNewKpi(newKpi.set("combineType", value))} />
      <ActionButtons
          readyToSubmit={isFormComplete}
          parentKpiId={(newKpi.get("combineWithKpiId") || masterKpiTypeToKpiId.get(newKpi.get("combineWithMasterMetricType")))}
          onClick={() => handleAddCombinedKpi(newKpi)}/>
    </div>
  };

  const CloneKpiForm = () => {
    const [newKpi, setNewKpi] = React.useState(Immutable.Map({parentId: kpiIdToCloneOrCombine, name: ""}));
    const isNameDuplicate = checkIfDuplicate(newKpi.get("name"));
    const isFormComplete = newKpi.get("name") && newKpi.get("parentId") && !isNameDuplicate;
    
    return <div>
      <Hint>
        <Icon icon="info" style={{color: theme.palette.hints.text}} />
        Create a new metric by duplicating the config of an existing metric. Future changes to the parent will NOT be reflected in your new metric.
      </Hint>
      <NameInput
          theme={theme}
          name={newKpi.get("name", null)}
          error={isNameDuplicate}
          onChange={value => setNewKpi(newKpi.set("name", value))}/>
        <KpiPicker
            containerStyle={{flex: 1, textAlign: "left"}}
            kpis={kpis}
            label="Metric To Duplicate"
            kpiId={newKpi.get("parentId")}
            onChange={value => setNewKpi(newKpi.set("parentId", value))} />
      <ActionButtons
          readyToSubmit={isFormComplete}
          parentKpiId={newKpi.get("parentId")}
          onClick={() => handleCloneKpi(newKpi.get("parentId"), newKpi.get("name"))}/>
    </div>
  };

  const isKpiToCloneUnsaved = idToWrappedKpi.getIn([kpiIdToCloneOrCombine, "isUnsaved"]);
  const options = Immutable.List(kpiIdToCloneOrCombine ? ["Inherit", "Duplicate"] : ["Blank Metric", "Inherit", "Duplicate"]);

  const optionToForm = Immutable.Map({
    "Blank Metric": <BlankKpiForm />,
    "Inherit": <CombineKpiForm />,
    "Duplicate": <CloneKpiForm />
  });
  const [selectedOption, setSelectedOption] = React.useState(null);

  React.useEffect(() => {
    setSelectedOption(null)
  }, [isOpen])

  const handleOptionClick = (name) => {
    setSelectedOption(name);
  };

  return <div style={{overflowY: "visible"}}>
    <div style={{display: "flex", justifyContent: "center", marginBottom: 10}}>
      <Tabs
          value={selectedOption || false}
          onChange={(event, value) => handleOptionClick(value)}
          TabIndicatorProps={{
            style: {
              display: "none"
            }
          }}>
        {options
            .map(option => {
                  const isTabDisabled = option === "Inherit" && isKpiToCloneUnsaved;
                  const isOptionSelected = option === selectedOption;
                  const optionStyle = {
                    margin: "0 5px",
                    minWidth: "160px",
                    borderRadius: 3,
                    textTransform: "none",
                    fontWeight: "bold",
                    color: isOptionSelected ? theme.palette.text.inverted : theme.palette.text.primary,
                    opacity: isTabDisabled ? 0.5 : 1,
                    backgroundColor: isOptionSelected ? theme.palette.primary.main : "rgba(0,0,0,0.2)"
                  };
                  return <Tab
                      style={optionStyle}
                      label={option}
                      disabled={isTabDisabled}
                      value={option}
                      key={option} />;
                }
            )}
      </Tabs>
    </div>
    {selectedOption && <div style={{margin: 10}}>
      <div style={{margin: 20}}>
        {optionToForm.get(selectedOption)}
      </div>
    </div>}</div>;
};

const NameInput = ({name, onChange, error, theme}) => {
  return <div>
    <div
        style={{
          fontSize: "0.75rem",
          marginTop: 15,
          marginBottom: 3,
          textAlign: "left"
        }}>
      Metric Name
    </div>
    <TextField
        hiddenLabel
        error={error}
        helperText={error && "Name already in use"}
        variant="standard"
        style={{marginBottom: 15, backgroundColor: theme.themeId === "light" ? "transparent" : "#272731", width: "100%", borderBottom: "none", padding: 3}}
        onChange={e => onChange(e.target.value)}
        value={name}
        inputProps={{"data-test-id": "kpi-name-input"}} />
  </div>;
};

const AddKpiDialog = ({
    idToWrappedKpi,
    kpiIdToCloneOrCombine,
    isOpen,
    idToTemplate,
    handleClose,
    handleAddCombinedKpi,
    handleAddKpi,
    handleCloneKpi,
    kpiIdToMasterKpis,
    typeToGroupingEntity,
    masterKpiTypeToKpiId,
    theme
}) => {

  const isKpiToCloneUnsaved = idToWrappedKpi.getIn([kpiIdToCloneOrCombine, "isUnsaved"]);

  const dialogTitle = kpiIdToCloneOrCombine ? <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
        Inherit or Duplicate Metric
        <div style={{fontSize: "0.8rem"}}>{idToWrappedKpi.getIn([kpiIdToCloneOrCombine, "kpi", "name"])}</div>
        {isKpiToCloneUnsaved && <Info
            text="Unsaved metrics cannot be inherited"
            style={{
              fontSize: "0.8rem",
              color: Colors.redLight,
              paddingBottom: 0,
              display: "flex",
              justifyContent: "center"
            }} />}
      </div>
      : <span style={{fontFamily: "Oxygen-light"}}>Add a New Metric</span>;

  const closeButtonStyle = {
    backgroundColor: Colors.greyLight,
    color: "white",
    borderRadius: 60,
    padding: "8px 10px",
    fontSize: 12
  };

  return <Dialog
      transitionDuration={{exit: 0, enter: 200}}
      open={isOpen}
      enableScroll={false}
      type="light"
      closeButtonStyle={closeButtonStyle}
      onBackdropClick={handleClose}
      title={dialogTitle}
      theme="cube2021"
      titleStyle={{fontSize: "1.2rem"}}>
    <AddKpi
        idToTemplate={idToTemplate}
        handleAddKpi={handleAddKpi}
        handleAddCombinedKpi={handleAddCombinedKpi}
        handleCloneKpi={handleCloneKpi}
        kpiIdToMasterKpis={kpiIdToMasterKpis}
        typeToGroupingEntity={typeToGroupingEntity}
        idToWrappedKpi={idToWrappedKpi}
        isOpen={isOpen}
        kpiIdToCloneOrCombine={kpiIdToCloneOrCombine}
        handleClose={handleClose}
        masterKpiTypeToKpiId={masterKpiTypeToKpiId}
        theme={theme}
    />
  </Dialog>
}

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <AddKpiDialog theme={theme} {...props} />;
};