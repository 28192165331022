import React from "react";
import DOMPurify from "dompurify";

import BaseCube from "js/cubetv/cubes/models/base-cube";
import Cube19 from "js/cube19.app";
import pure from "js/common/views/pure";

const FreeTextModel = BaseCube.extend({});

Cube19.module("Models.Cubes", function(Cubes, App, Backbone, Marionette, $, _) {
  Cubes.FreeText = FreeTextModel;
});

export default pure(({
  content,
  backgroundUrl,
  backgroundColor,
  backgroundSize = "contain",
  isFullHeight
}) => {
  const style = {
    backgroundColor,
    backgroundImage: backgroundUrl ? `url(${backgroundUrl})` : "",
    backgroundSize: backgroundSize,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    lineHeight: "1.65",
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    overflow: "hidden",
    padding: 0,     // remove padding from Quill library's CSS
    height: isFullHeight ? "100vh" : "calc(100% - 60px)" // 60px = RSS Feed area height
  };

  const clean = DOMPurify.sanitize(content);

  return <div className="ql-editor" style={style} dangerouslySetInnerHTML={{__html: clean}} />;
});
