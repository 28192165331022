/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import {css} from "@emotion/react";
import GroupNodeUserDisc from "js/admin/groups/group-inheritance/group-node/group-node-user-disc";
import pure from "js/common/views/pure";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const usersContainer = css`margin: 10px 0;`;

const userStyles = css`
      margin: 0;
      padding: 0;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      li {
        list-style: none;
        text-align: left;
        font-size: 12px;
        font-weight: normal;
        text-transform: capitalize;
        padding: 4px 20px 4px 0;
        display: flex;
        flex-shrink: no-shrink;
        justify-content: center;
        
        img {
          border-radius: 50%;
          height: 32px;
          width: 32px;
        }
        p {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 90px;
          margin-bottom: 0;
          position: relative;
          top: 6px;
          left: 6px;
        }
      }
    `;

const titleStyle = theme => css`
  font-size: 0.8rem !important;
  margin: 1.2rem 0 1rem 0;
  text-transform: uppercase;
  color: ${theme.palette.primary.main};
`;

export default pure(({group, users, handleUserClick, theme}) => {
  const sortedUsers = users.sort((a, b) => a.get("fullName").localeCompare(b.get("fullName")));

  const boolToUsers = sortedUsers.groupBy(u =>
      u.get("pageId") !== null ||
      u.get("dealMusicId") !== null ||
      u.get("localeId") !== null);

  const attributeUsers = boolToUsers.get(true, Immutable.List());
  const inheritedUsers = boolToUsers.get(false, Immutable.List());

  const allUsersLabel = attributeUsers.isEmpty() ? "All users" : "All other users";
  return (
      <React.Fragment>
        <h2>{group.get("name")}</h2>
        {!attributeUsers.isEmpty() && (
            <div css={usersContainer}>
              <h3 css={titleStyle(theme)}>Users with custom attributes set:</h3>
              <ul css={userStyles}>
                {attributeUsers.map(user =>
                    <GroupNodeUserDisc
                        key={user.get("id")}
                        user={user}
                        showUserPics
                        showUserNames
                        handleUserClick={handleUserClick} />)}
              </ul>
            </div>
        )}
        {!inheritedUsers.isEmpty() && (
            <div css={usersContainer}>
              <h3 css={titleStyle(theme)}>{allUsersLabel}:</h3>
              <ul css={userStyles}>
                {inheritedUsers.map(user =>
                    <GroupNodeUserDisc
                        key={user.get("id")}
                        user={user}
                        showUserPics
                        showUserNames
                        handleUserClick={handleUserClick} />)}
              </ul>
            </div>
        )}
      </React.Fragment>
  );
}, "GroupUsersDialog");
