import React from "react";

import SimpleDataTable from "js/common/views/tables/simple-data-table";
import * as Users from "js/common/users";
import currentClient from "js/common/repo/backbone/current-client";
import createReactClass from "create-react-class";
import ImmutablePropTypes from "react-immutable-proptypes";
import ReactPropTypes from "prop-types";

export default createReactClass({
  displayName: "ActivatedUsersTable",
  propTypes: {
    users: ImmutablePropTypes.seq.isRequired,
    onUserClick: ReactPropTypes.func.isRequired
  },
  render() {
    const columns = ["Name", "State"];
    if (currentClient.isBullhornCrm()) {
      columns.unshift("ID");
    }
    return <SimpleDataTable
        maxTableHeight={700}
        columns={columns}
        rows={this.props.users.map(this.userToRow).toArray()}
        initialSortBy={columns.indexOf("Name")}
        onCellClick={(cell, row) => this.props.onUserClick(row.id)} />;
  },

  userToRow(user) {
    const row = [
      user.get("fullName"),
      Users.getType(user)
    ];
    if (currentClient.isBullhornCrm()) {
      row.unshift(Users.isCrmUser(user) ? user.get("originalCrmUserId") : "");
    }
    row.id = user.get("id");
    return row;
  }
});
