import React from "react";
import moment from "moment";
import store from "store";
import Immutable from "immutable";

import Dialog from "js/common/views/dialog";
import buildInfo from "js/build-info";
import * as Ajax from "js/common/ajax";
import * as Branding from "js/common/branding-constants";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const millisFor24Hours = 86400000;
const millisFor2Weeks = 1209600000;

const LatestVersionDialog = React.memo(({refreshCounter, loadWithoutCheckingVersion, priorityOverride}) => {
  const [uiVersion, setUiVersion] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(true);
  const [moreInfoIsOpen, setMoreInfoIsOpen] = React.useState(false);
  const {theme} = React.useContext(CustomThemeContext);

  React.useEffect(() => {
    const currentTimeMillis = Date.now();
    const existingLocalStorageUiVersionUpdate = Immutable.fromJS(store.get("uiVersionUpdate"));

    if (refreshCounter !== 0) {
      const openDialog = versionInfo => {
        setUiVersion(versionInfo);
        setIsOpen(true);
      };
      const checkLocalStorageAndUpdateAccordingly = (latestUiVersion, existingLocalStorageUiVersionUpdate) => {
        if (existingLocalStorageUiVersionUpdate) {
          const uiVersionIsLater = latestUiVersion.get("id") > existingLocalStorageUiVersionUpdate.get("id");
          const uiVersionIsDifferentPriority = latestUiVersion.get("priority") !==
              existingLocalStorageUiVersionUpdate.get("priority");
          if (currentTimeMillis > existingLocalStorageUiVersionUpdate.get("snoozeUntil")) {
            openDialog(latestUiVersion);
          } else if ((uiVersionIsLater && uiVersionIsDifferentPriority)) {
            if (latestUiVersion.get("priority") === "IMPORTANT") {
              openDialog(latestUiVersion);
            }
          }
        } else {
          openDialog(latestUiVersion);
        }
      };

      if (loadWithoutCheckingVersion) {
        const overrideUiVersionInfo = priorityOverride
            ? currentUiVersionInfo.set("priority", priorityOverride)
            : currentUiVersionInfo;
        openDialog(overrideUiVersionInfo);
      } else {
        loadLatestUiVersion().then(latestUiVersion => {
          const priority = latestUiVersion.get("priority");
          const mainAppEl = document.getElementsByClassName("main-app")[0];
          if (latestUiVersion.get("id") > currentUiVersionInfo.get("id")) {
            if (priority === "CRITICAL") {
              openDialog(latestUiVersion);
            } else if (priority === "BACKGROUND") {
              /*
               * Note: if more release types come into place the below would need to be looked into. i.e
               * If we have a background release, refresh (2 week background snooze begins), then an important
               * release, refresh (1 day important snooze begins), then another background release. Even if this is
               * all to happen within 2 weeks this new bg release would trigger a popup if it were not for the line
               * of code below, instead of waiting for 2 weeks from the first background release to show.
               */
              if (moment.utc(currentUiVersionInfo.get("timestamp")).valueOf() < (currentTimeMillis - millisFor2Weeks)) {
                checkLocalStorageAndUpdateAccordingly(latestUiVersion, existingLocalStorageUiVersionUpdate);
                if (mainAppEl) {
                  mainAppEl.style.overflow = "auto";
                }
              }
            } else {
              checkLocalStorageAndUpdateAccordingly(latestUiVersion, existingLocalStorageUiVersionUpdate);
              if (mainAppEl) {
                mainAppEl.style.overflow = "auto";
              }
            }
          }
        });
      }
    }
  }, [loadWithoutCheckingVersion, refreshCounter, priorityOverride]);

  const priority = priorityOverride ? priorityOverride : (uiVersion && uiVersion.get("priority"));
  const caretClass = moreInfoIsOpen ? "fa fa-caret-up" : "fa fa-caret-down";
  return uiVersion &&
      <Dialog
          paperStyle={{overflow: "hidden", paddingBottom: "1rem", textAlign: "left"}}
          fullTheme={theme}
          type={priority !== "CRITICAL" ? "toast" : "normal"}
          hideBackdrop={priority !== "CRITICAL"}
          iconStyle={priority !== "CRITICAL" ? "info" : "warning"}
          title={`A new version of ${Branding.brandingName} is available!`}
          onBackdropClick={priority !== "CRITICAL" ? () => snoozeDialog(setIsOpen, uiVersion) : null}
          disableEnforceFocus={priority !== "CRITICAL" ? true : null}
          theme="cube2021"
          enableScroll={true}
          open={isOpen}>
        <p style={{marginBottom: 0}}>Please refresh your browser to get the latest updates.<br />
          If you still see this after you refresh, please follow <span
              style={{cursor: "pointer"}}
              onClick={() => setMoreInfoIsOpen(!moreInfoIsOpen)}>
          <u>these instructions</u> <i className={caretClass} /></span></p>
        {moreInfoIsOpen &&
            <div style={{marginTop: "1rem", textAlign: "left"}}>
              <h5 style={{marginBottom: "1rem"}}>First try a hard refresh:</h5>
              <ul style={{listStyleType: "none", marginBottom: "1rem"}}>
                <li>Windows: <b style={{color: theme.palette.primary.main}}>CTRL + F5</b></li>
                <li>Mac: <b style={{color: theme.palette.primary.main}}>CMD + SHIFT + R</b></li>
              </ul>
              <p>If that does not work then please clear your browser's cache. If you wish to clear the cache only on
                the {Branding.brandingName} site then follow the instructions below:</p>
              <h5 style={{marginBottom: "1rem"}}>On Chrome:</h5>
              <ol style={{paddingLeft: "revert"}}>
                <li><b><i className="fas fa-ellipsis-v" /></b> > <b>Settings</b> > <b>Privacy and security</b> >
                  <b>Cookies and other site data</b> > <b>See all cookies and site data</b>
                </li>
                <li>Type "{Branding.brandingName}" into the search filter at the top right</li>
                <li>Click <b>Remove All Shown</b></li>
                <li>Refresh your {Branding.brandingName} tab</li>
              </ol>
              <h5 style={{marginBottom: "1rem"}}>On Edge:</h5>
              <ol style={{marginBottom: "1rem", paddingLeft: "revert"}}>
                <li><b><i className="fas fa-ellipsis-h" /></b> > <b>Settings</b> > <b>Cookies and
                  site permissions</b> > <b>Manage and delete cookies and site data > See all cookies and site data</b>
                </li>
                <li>Type "{Branding.brandingName}" into the search filter at the top right</li>
                <li>Click <b>Remove all shown</b></li>
                <li>Refresh your {Branding.brandingName} tab</li>
              </ol>
            </div>}
      </Dialog>;
});

const snoozeDialog = (setIsOpen, uiVersion) => {
  const priority = uiVersion.get("priority");
  const version = uiVersion.get("id");
  const currentTimeMillis = Date.now();
  const existingLocalStorageUiVersionUpdate = Immutable.fromJS(store.get("uiVersionUpdate"));
  const newLocalStorageUiVersionUpdate = Immutable.fromJS({
    id: version,
    priority: priority,
    snoozeUntil: null,
    lastAutoRefreshTriedAt: null
  });

  switch (priority) {
    case "IMPORTANT":
      existingLocalStorageUiVersionUpdate ?
          storeUiVersionUpdate(existingLocalStorageUiVersionUpdate.set("id", version)
              .set("snoozeUntil", currentTimeMillis + millisFor24Hours).set("priority", priority)) :
          storeUiVersionUpdate(newLocalStorageUiVersionUpdate.set("snoozeUntil", currentTimeMillis +
              millisFor24Hours));
      break;
    case "BACKGROUND":
      existingLocalStorageUiVersionUpdate ?
          storeUiVersionUpdate(existingLocalStorageUiVersionUpdate.set("id", version)
              .set("snoozeUntil", currentTimeMillis + millisFor2Weeks).set("priority", priority)) :
          storeUiVersionUpdate(newLocalStorageUiVersionUpdate.set("snoozeUntil", currentTimeMillis +
              millisFor2Weeks));
      break;
    default:
      break;
  }
  setIsOpen(false);
};

const storeUiVersionUpdate = localStorageUiVersionUpdate => {
  store.set("uiVersionUpdate", localStorageUiVersionUpdate.toJS());
};

const currentUiVersionInfo = Immutable.Map({
  "branch": buildInfo.buildBranch,
  "id": buildInfo.buildId,
  "timestamp": buildInfo.buildTimestamp,
  "priority": null
});

const loadLatestUiVersion = () => {
  const queryParams = `?id=${currentUiVersionInfo.get("id")}&timestamp=${currentUiVersionInfo.get("timestamp")}`;
  return Ajax.get({url: `ui-versions/latest${queryParams}`, autoLogout: false})
      .then(response => Immutable.fromJS(response))
      .catch(error => {
        console.error("Failed to fetch latest UI Version info:", error);
        return currentUiVersionInfo;
      });
};

export {
  currentUiVersionInfo,
  loadLatestUiVersion
};
export default LatestVersionDialog;
