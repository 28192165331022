import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import {TextButton} from "js/common/views/inputs/buttons";

import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import {TextField} from "@mui/material";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import SharedWithItem from "js/common/views/sharing/shared-with-item";
import Immutable from "immutable";

const SharedWithList = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    configType: ReactPropTypes.string,
    sharedWith: ImmutablePropTypes.map.isRequired,
    onGroupPermissionChange: ReactPropTypes.func.isRequired,
    onUserPermissionChange: ReactPropTypes.func.isRequired,
    onUserUnshare: ReactPropTypes.func.isRequired,
    onGroupUnshare: ReactPropTypes.func.isRequired,
    onUnshareWithAllClick: ReactPropTypes.func.isRequired
  },

  getInitialState() {
    const {sharedWith} = this.props;
    const shareData = this.ensureDataStructure(sharedWith);
    return {
      groups: getAllSelectedGroups(shareData),
      users: getAllSelectedUsers(shareData),
      showOwner: true
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextSharedWith = nextProps.sharedWith;
    if (nextSharedWith !== this.props.sharedWith) {
      const shareData = this.ensureDataStructure(nextSharedWith);
      this.setState({
        groups: getAllSelectedGroups(shareData),
        users: getAllSelectedUsers(shareData)
      });
    }
  },

  // Needed for S&D share panel now that we have a new structure
  ensureDataStructure(data) {
    if (data.get("users") && data.get("groups")) {
      return data;
    } else {
      return Immutable.fromJS({
        users: data.get("userIds").map(u => (Immutable.Map({id: u}))),
        groups: data.get("groupIds").map(g => (Immutable.Map({id: g})))
      });
    }
  },

  render() {
    const {groups, users} = this.state;
    const {
      sharedWith,
      onGroupPermissionChange,
      onUserPermissionChange,
      onGroupUnshare,
      onUserUnshare,
      onUnshareWithAllClick,
      theme,
      owner,
      editEnabled,
      readOnly
    } = this.props;
    const shareData = this.ensureDataStructure(sharedWith);
    const isNotSharedWithAnyone = shareData.get("groups").isEmpty() && shareData.get("users").isEmpty();
    const searchLabel =
        <span style={{fontSize: 12}}><i className="bhi-search" />&nbsp;Search for Users and Groups</span>;
    const spacing = {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    };

    return (
        <div>
          {isNotSharedWithAnyone &&
              <div style={{color: "#bbb", ...spacing}}>
                This report is not shared with any Group/User
              </div>}
          {!isNotSharedWithAnyone && !readOnly &&
              <TextButton
                  testId="unshare-with-all"
                  suffixIcon="times"
                  iconType="bhi"
                  label="Unshare with all"
                  onClick={onUnshareWithAllClick}
                  backgroundColor="transparent"
                  extraStyle={{paddingRight: 5}}
                  labelStyle={{fontSize: 10}}
                  suffixIconStyle={{fontSize: 10}}
                  style={{
                    color: theme.palette.primary.main,
                    background: "transparent !important",
                    position: "absolute",
                    top: "0.5rem",
                    right: 0
                  }} />}
          {!isNotSharedWithAnyone &&
              <div>
                {/* this is a stopgap to fix a known issue with React and/or Material-UI for IE11
                        https://github.com/facebook/react/issues/6822 */}
                <style type="text/css" dangerouslySetInnerHTML={{__html: "::-ms-clear {display: none;}"}} />
                <TextField
                    variant="standard"
                    className={"TESTCAFE-user-group-search"}
                    InputLabelProps={{style: {fontSize: "0.93rem"}}}
                    label={searchLabel}
                    style={{width: "90%", marginBottom: 10, zIndex: 99999}}
                    inputStyle={{boxShadow: "none"}}
                    onChange={this.filterList} />
              </div>}
          {!isNotSharedWithAnyone && (groups.isEmpty() && users.isEmpty()) &&
              <div style={{fontStyle: "italic", ...spacing}}>
                No search result matches found
              </div>}
          <SharedWithItem
              readOnly={readOnly}
              owner={this.state.showOwner && owner}
              groups={groups}
              users={users}
              onGroupPermissionChange={onGroupPermissionChange}
              onUserPermissionChange={onUserPermissionChange}
              onGroupUnshare={onGroupUnshare}
              onUserUnshare={onUserUnshare}
              editEnabled={editEnabled} />
        </div>
    );
  },

  filterList(e) {
    const searchText = e.target.value;
    const {sharedWith} = this.props;
    const fullGroupsList = getAllSelectedGroups(sharedWith);
    const fullUsersList = getAllSelectedUsers(sharedWith);
    if (searchText.length > 0) {
      const filteredGroupsList = fullGroupsList.filter(group => {
        const fullGroup = Groups.getGroup(group.get("id"));
        const breadcrumbsString = fullGroup.get("breadcrumbs")?.join(" ");
        return breadcrumbsString.toLowerCase().search(searchText.toLowerCase()) !== -1;
      });
      const filteredUsersList = fullUsersList.filter(user => {
        return user.get("fullName").toLowerCase().search(searchText.toLowerCase()) !== -1;
      });
      this.setState({
        groups: filteredGroupsList,
        users: filteredUsersList,
        showOwner: false
      });
    } else {
      this.setState({
        groups: fullGroupsList,
        users: fullUsersList,
        showOwner: true
      });
    }
  }

});

const getAllSelectedGroups = sharedWith => sharedWith
    .get("groups")
    .map((permission, id) => {
      const group = Groups.getGroup(id);
      return Immutable.Map({
        id: group.get("id"),
        name: group.get("name"),
        permission: permission
      });
    })
    .filter(group => !group.get("isFallback"))
    .sortBy(group => group.get("name"));

const getAllSelectedUsers = sharedWith => sharedWith
    .get("users")
    .map((permission, id) => {
      const user = Users.getUser(id);
      return Immutable.Map({
        id: user.get("id"),
        fullName: user.get("fullName"),
        permission: permission
      });
    })
    .filter(user => !user.get("isFallback"))
    .sortBy(user => user.get("fullName"));

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <SharedWithList theme={theme} {...props} />;
};
