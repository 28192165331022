/** @jsxImportSource @emotion/react */

import React from "react";
import ReactDiffViewer, {DiffMethod} from "react-diff-viewer";
import {List, Map, Set, fromJS, isImmutable} from "immutable";
import {parseKpi} from "js/common/kpis";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {TextButton} from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog"
import TwoOptionToggle from "js/common/views/inputs/two-option-toggle";
import * as Users from "js/common/users";
import TextField from "@mui/material/TextField";
import {capitaliseWords} from "js/common/utils/strings";
import * as Colors from "js/common/cube19-colors";
import Error from "js/common/views/error";

const KpiChangeSubmission = ({submission, idToTemplate, idToWrappedKpi, onReject, onRun, onLoadIntoEditor}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const [collapsedKpis, setCollapsedKpis] = React.useState(Set());
  const [showOnlyChanges, setShowOnlyChanges] = React.useState(true);
  const [isRejecting, setRejecting] = React.useState(false);
  const [rejectionReason, setRejectionReason] = React.useState("");
  const payload = fromJS(JSON.parse(submission.get("payload")));
  let kpiChanges = payload.get("kpis").map(kpiChange => kpiChange
      .updateIn(["newKpi", "config"], parseKpi)
      .update("currentKpi", kpi => kpi.get("config") === null ? null :kpi.update("config", parseKpi)));

  const newKpis = kpiChanges.map(kpiChange => kpiChange.get("newKpi"));
  const newIdToWrappedKpi = idToWrappedKpi.withMutations(idToWrappedKpi => {
    newKpis
        .filter(newKpi => !!newKpi.getIn(["config", "id"]))
        .forEach(newKpi => idToWrappedKpi.setIn([newKpi.getIn(["config", "id"]), "kpi"], newKpi.get("config")));
  });
  kpiChanges = kpiChanges.map(kpiChange => {
    const currentKpi = kpiChange.get("currentKpi");
    const newKpi = kpiChange.get("newKpi");
    const oldText = currentKpi ? kpiToText(currentKpi, idToTemplate, newIdToWrappedKpi) : "";
    const newText = kpiToText(newKpi, idToTemplate, newIdToWrappedKpi);
    return kpiChange.set("oldText", oldText).set("newText", newText);
  })

  const toggleKpi = (label) => {
    if (collapsedKpis.has(label)) {
      setCollapsedKpis(collapsedKpis.delete(label));
    } else {
      setCollapsedKpis(collapsedKpis.add(label));
    }
  }

  const rejectionComment = submission.get("rejectionComment");
  const output = submission.get("output");

  const currentUser = Users.getCurrentUser();
  const status = submission.get("status");
  const canReject = status === "SUBMITTED" && (currentUser.get("adminConsoleUsername") === submission.get("submittedBy") ||
      Users.currentHasPermission(submission.get("approvalPermission")));
  const canRun = status === "SUBMITTED" && currentUser.get("adminConsoleUsername") !== submission.get("submittedBy") &&
      Users.currentHasPermission(submission.get("approvalPermission"));
  const handleLoadIntoEditor = () => {
    const kpisToLoad = kpiChanges
        .filter(kpiChange => kpiChange.get("oldText") !== kpiChange.get("newText"))
        .map(kpiChange => kpiChange.get("newKpi"));
    onLoadIntoEditor(idToWrappedKpi, kpisToLoad);
  }
  return <div style={{padding:"1rem", color: theme.palette.text.primary}}>
    { (rejectionComment || output) && <div style={{paddingBottom: "1rem" ,overflow: "auto"}}>
      {rejectionComment && <pre>Rejection reason: {rejectionComment}</pre>}
      {output && <pre>Output: {JSON.parse(output)[0]}</pre>}
    </div>}
    {isRejecting && <Dialog
        title={"Rejection Reason"}
        open={true}
        theme={"cube2021"}
        onBackdropClick={() => setRejecting(false)}
        actions={[
          <TextButton label={"Cancel"} onClick={() => setRejecting(false)}/>,
            <TextButton
                label={"Reject"}
                disabled={!rejectionReason}
                onClick={() => {
                  setRejecting(false);
                  onReject(submission.get("id"), rejectionReason);
                }}/>]}>
      <TextField
          hiddenLabel
          variant={"standard"}
          style={{marginBottom: 15, backgroundColor: theme.palette.background.card, width: "100%", borderBottom: "none", padding: 3}}
          onChange={e => setRejectionReason(e.target.value.substring(0, 255))}
          value={rejectionReason}
      />
    </Dialog>}
    <TwoOptionToggle
        leftLabel={"Show changes and test failures"}
        rightLabel={"Show all metrics in submission"}
        isLeft={showOnlyChanges}
        onColor={theme.palette.primary.main}
        offColor={theme.themeId === "light" ? theme.palette.primary.main : theme.palette.text.main}
        onChange={() => setShowOnlyChanges(!showOnlyChanges)}
    />
    {kpiChanges.map(kpiChange => {
      const newKpi = kpiChange.get("newKpi");
      const oldText = kpiChange.get("oldText");
      const newText = kpiChange.get("newText");
      const hasChanges = oldText !== newText
      const permission = kpiChange.get("permission");
      if (showOnlyChanges && !hasChanges && (!permission || permission === "METRIC_APPROVAL")) {
        return null;
      }

      const config = newKpi.get("config")
      const id = config.get("id");
      const label = (id ?? "<NEW>")  + " - " + config.get("name");
      const isOpen = !collapsedKpis.has(label);
      const testTimings = kpiChange.get("testTimings");
      const thresholds = kpiChange.get("thresholds");
      const failureReasons = kpiChange.get( "reasons");
      return <div style={kpiDiffStyle(theme)}>
        <span style={{cursor: "pointer"}} onClick={() => toggleKpi(label)}>
          <i className={isOpen ? "fa fa-angle-up": "fa fa-angle-down"} />  {label}
        </span>
        {!!failureReasons && failureReasons.size > 0 && <Error
            type={permission === "STANDARD_PERFORMANCE_APPROVAL" ? "warn" : "error"}
            text={<div>
              <div style={{display: "flex"}}>
                <span>{failureReasons.map(reason => <>{reason}<br /></>)}</span>
              </div>
            </div>} />}
      {isOpen && <div style={{overflow: "auto"}}>
        <div style={{fontSize: "0.9rem", display: "flex", flexDirection: "column"}}>
          {showOnlyChanges && !hasChanges && <div>This metric depends on a changed metric</div>}
          {!!testTimings && <div style={{margin: "10px 0", padding: 10, backgroundColor: theme.palette.background.paper}}>
            <div>
              Performance Test Timings
            </div>
            <div style={{display: "flex", padding: "10px 0"}}>
              {testTimings.map((testToTiming, userOrGroup) => <div>
                <div>{capitaliseWords(userOrGroup)}</div>
                <div style={{paddingLeft: 15}}>
                  {testToTiming.map((result, test) => {
                    const failurePermission = thresholds && getTestTimingFailurePermission(test, result.get("timing"), thresholds);
                    return <div>
                      <span style={{opacity: "50%"}}>{capitaliseWords(test) + ": "}</span>
                      <span style={{color: permissionToColor[failurePermission]}}>{result.get("timing") + " ms"}</span>
                    </div>}).toList()}
                </div>
              </div>).toList()}</div>
            <span>Rows returned: {testTimings.getIn(["GROUP", "REPORT", "valueCount"])}</span>
          </div>}
        </div>
        <ReactDiffViewer
          compareMethod={DiffMethod.WORDS}
          styles={diffViewerStyles(theme)}

          leftTitle="Before"
          oldValue={oldText}

          rightTitle="After"
          newValue={newText}

          useDarkTheme={theme.themeId !== "light"}
          splitView={true} />
      </div>}
      </div>
    })}
    <div style={{display: "flex", justifyContent: "flex-end"}}>
      {canReject && <TextButton style={textButtonStyle} type={theme.themeId === "light" ? "defaultInner" : "dark"} label={"Reject"} onClick={() => setRejecting(true)}/>}
      {canRun && <TextButton style={textButtonStyle} type={theme.themeId === "light" ? "defaultInner" : "dark"} label={"Run"}  onClick={() => onRun(submission.get("id"))}/>}
      <TextButton style={textButtonStyle} type={theme.themeId === "light" ? "defaultInner" : "dark"} label={"Load Changes Into Editor"} onClick={handleLoadIntoEditor}/>
    </div>
  </div>
}

const getTestTimingFailurePermission = (test, resultTimeMillis, testToThresholds) => {
  const thresholds = testToThresholds.get(test, List());
  const threshold = thresholds.find(threshold => threshold.get("lowerLimit") < resultTimeMillis &&
      (threshold.get("upperLimit") === null || threshold.get("upperLimit") >= resultTimeMillis));
  return threshold && threshold.get("requiredPermission");
}

const permissionToColor = {
  STANDARD_PERFORMANCE_APPROVAL: "#FFBF00",
  ENGINEERING_PERFORMANCE_APPROVAL: Colors.c19Red
}

const keysToIgnore = Set([
    "id",
    "order",
    "readOnlyCombined"
])

const kpiToText = (kpi, idToTemplate, idToWrappedKpi) => {
  const config = kpi.get("config");
  const configLines = config.entrySeq().flatMap(([key, value]) => {
    if (keysToIgnore.has(key) || value === null || (Map.isMap(value) && value.isEmpty())) {
      return List();
    }
    if (key === "templateId") {
      return List.of("templateId: " + value + " - " + idToTemplate.get(value).get("name"));
    }
    if (key.endsWith("KpiId")) {
      return List.of(key + ": " + value + " - " + idToWrappedKpi.getIn([value, "kpi", "name"]));
    }
    if (key === "queryParams") {
      return value
          .entrySeq()
          .flatMap(([key, value]) => safeKeyValueStringList(key, value))
          .map(line => withIndent(line, 2))
          .toList()
          .unshift("query params");
    }
    return safeKeyValueStringList(key,value);
  });
  const columns = kpi.get("columns") || List();
  const columnsLines = columns
      .filter(column => column.get("visible"))
      .sortBy(column => column.get("order"))
      .map(column => column.get("label"));

  let result = configLines.toList();
  if (!columnsLines.isEmpty()) {
    result = result.push("columns")
        .concat(columnsLines.map(text => withIndent(text, 2)))
  }

  return result.join("\n");
}

const withIndent = (text, indent) => " ".repeat(indent) + text;

const safeKeyValueStringList = (key, value) => {
  const stringValue = JSON.stringify(isImmutable(value) ? value.toJSON() : value, null, 2);
  const keyValueString = key + ": " + stringValue;
  return List(keyValueString.split("\n"));
}

const kpiDiffStyle = theme => ({
  background: theme.palette.background.card,
  borderRadius: 3,
  marginBottom: 10,
  padding: 10
});

const diffViewerStyles = theme => ({
  variables: {
    dark:
        {gutterBackground: theme.palette.background.card,
          gutterColor: theme.palette.text.default,
          diffViewerTitleBackground: theme.palette.background.card,
          diffViewerTitleColor: theme.palette.text.default,
          codeFoldContentColor: theme.palette.text.default},
    light:
        {gutterBackground: theme.palette.background.card,
          diffViewerTitleBackground: theme.palette.background.card}}})

const textButtonStyle = {
  flex: 1,
  marginRight: "1rem"
}

export default KpiChangeSubmission;