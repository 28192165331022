/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import ReactDOM from "react-dom";
import Immutable from "immutable";
import Tooltip from "react-tooltip";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";

import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import RemoveRowButton from "js/onpoint/remove-item-button";
import SortingPicker from "js/onpoint/sorting-picker";

import {css} from "@emotion/react";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const $ = window.$;

const labelStyle = {
  fontSize: 16,
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
};

const removeBtnStyle = {
  paddingLeft: "0.5rem",
  paddingRight: "0.25rem"
};

const iconStyle = {
  paddingLeft: "0.5rem",
  verticalAlign: "text-top"
};

const columnHeaderHeight = 80;
const scrollBarHeight = 17;

const UsersGroupsColumns = createReactClass({

  mixins: [PureRenderMixin],

  componentDidUpdate(prevProps) {
    const {scrollTop} = this.props;
    const $usersGroupsColumn = $(ReactDOM.findDOMNode(this)).find("#users-groups-column");
    if (prevProps.scrollTop !== scrollTop) {
      $usersGroupsColumn.scrollTop(scrollTop);
    }
  },

  render() {
    const {
      userIds,
      groupIds,
      showGroupBreadcrumbs,
      isLoadingUsersInGroup,
      sortBy,
      onSortingChange,
      onRemoveUserRequest,
      onRemoveGroupRequest,
      columnWidth,
      columnHeight,
      cellHeight,
      theme
    } = this.props;
    const currentUser = Users.getCurrentUser();
    const borderTopStyle = `1px solid ${theme.palette.background.paper}`;
    const altBorderTopStyle = `1px solid ${theme.palette.background.paper}`;
    const userCellsContainerStyle = {
      marginTop: groupIds.isEmpty() ? 0 : "0.5rem",
      borderTop: userIds.isEmpty() ? 0 : altBorderTopStyle
    };
    const cellStyle = {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: columnWidth,
      height: cellHeight,
      marginBottom: "5px",
      borderRadius: "5px",
      borderLeftColor: theme.palette.border.main,
      borderRightColor: theme.palette.border.main,
      borderBottomColor: theme.palette.border.main
    };
    const noUsersOrGroupsSelected = userIds.isEmpty() && groupIds.isEmpty();
    const sortOrder = sortBy.get("columnType") === "GROUPS_AND_USERS" ? sortBy.get("sortOrder") : null;
    const containerStyle = {
      display: "inline-block",
      marginLeft: "0.5rem",
      marginRight: "0.25rem",
      marginTop: "0.5rem",
      marginBottom: 0,
      height: columnHeight,
      width: noUsersOrGroupsSelected ? 0 : columnWidth
    };
    const cellsContainerHeight = columnHeight - columnHeaderHeight;
    return (
        <div
            style={containerStyle}>
          {!noUsersOrGroupsSelected &&
          <ColumnHeader sortOrder={sortOrder} theme={theme} onSortingChange={onSortingChange} width={columnWidth} />}
          <div
               onScroll={this.handleScroll}
               id="users-groups-column"
               style={{
                 display: "flex",
                 flexDirection: "column",
                 overflow: "auto",
                 maxHeight: cellsContainerHeight,
                 borderTop: noUsersOrGroupsSelected ? 0 : borderTopStyle,
                 marginBottom: scrollBarHeight
               }}
               css={userGroupColumnStyles}>
              <div style={{marginBottom: groupIds.isEmpty() ? 0 : "0.5rem"}}>
              {groupIds.map((groupId) => {
                return (
                    <GroupCell
                        key={`group-${groupId}`}
                        groupId={groupId}
                        currentUser={currentUser}
                        onRemoveGroupRequest={onRemoveGroupRequest}
                        showGroupBreadcrumbs={showGroupBreadcrumbs}
                        style={{
                          ...cellStyle,
                          backgroundColor: theme.palette.background.card,
                        }} />
                );
              })}
            </div>
            <div style={userCellsContainerStyle}>
              {userIds.map((userId) => {
                return (
                    <UserCell
                        key={`user-${userId}`}
                        userId={userId}
                        currentUser={currentUser}
                        onRemoveUserRequest={onRemoveUserRequest}
                        showGroupBreadcrumbs={showGroupBreadcrumbs}
                        style={{
                          ...cellStyle,
                          backgroundColor: theme.palette.background.card,
                        }} />
                );
              })}
            </div>
            {isLoadingUsersInGroup &&
            <div style={{height: cellHeight, ...labelStyle}}>LOADING USERS...</div>}
          </div>
        </div>
    );
  },

  handleScroll(e) {
    const target = e.target;
    this.props.onScroll(target);
  }
});

const ColumnHeader = pure(({
  sortOrder,
  onSortingChange,
  width,
  theme
}) => {
  const headerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.card,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: 7,
    minHeight: 80,
    width
  };
  const columnNameStyle = {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: theme.typography.fontFamilyBold,
    fontWeight: 600,
    fontSize: "0.9rem",
    paddingRight: 10,
    height: "16px",
    lineHeight: "16px"
  };
  const sortByOptions = [{
    value: "ASC",
    primaryText: "Ascending",
    label: <span>ASC <i className="fa fa-sort-alpha-asc" /></span>
  }, {
    value: "DESC",
    primaryText: "Descending",
    label: <span>DESC <i className="fa fa-sort-alpha-desc" /></span>
  }];
  return (
      <div style={headerStyle}>
        <div style={columnNameStyle}>Groups and Users</div>
        <div>
          <SortingPicker
              options={sortByOptions}
              value={sortOrder ? `${sortOrder}` : "NONE"}
              onChange={selection => onSortingChange("GROUPS_AND_USERS", selection)}
              width={95} />
        </div>
      </div>
  );
});

const GroupCell = pure(({
  groupId,
  currentUser,
  onRemoveGroupRequest,
  showGroupBreadcrumbs = false,
  style = {}
}) => {
  const tooltipId = `full-user-group-breadcrumbs-${groupId}`;
  const groupLabel = showGroupBreadcrumbs ? getGroupBreadcrumbsLabel(groupId, currentUser) :
      Groups.getGroupOrCurrent(groupId).get("name");
  return (
      <div style={style}>
        <RemoveRowButton onClick={() => onRemoveGroupRequest(groupId)} style={removeBtnStyle} />
        <i className="fa fa-group" style={iconStyle} />
        <span style={labelStyle} data-tip data-for={tooltipId}>
                {groupLabel}
            </span>
        <Tooltip id={tooltipId} place="top" type="light" effect="solid">
          {getGroupBreadcrumbsTooltipLabel(groupId, currentUser)}
        </Tooltip>
      </div>
  );
});

const UserCell = React.memo(({
  userId,
  currentUser,
  onRemoveUserRequest,
  showGroupBreadcrumbs = false,
  style = {}
}) => {
  const { theme } = React.useContext(CustomThemeContext);
  const user = Users.getUserOrCurrent(userId);
  const userBreadcrumbsStyle = {
    lineHeight: 1.5,
    fontSize: 14,
    color: theme.palette.primary.main,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingTop: "2px",
    paddingBottom: "3px"
  };
  const userFullName = user.get("fullName");
  const groupId = user.get("groupId");
  const tooltipId = `full-user-group-breadcrumbs-${userId}`;
  return (
      <div style={style}>
        <RemoveRowButton onClick={() => onRemoveUserRequest(userId)} style={removeBtnStyle} />
        <div style={{width: `calc(100% - 1.5rem)`}}>
          {showGroupBreadcrumbs &&
          <div style={userBreadcrumbsStyle} data-tip data-for={tooltipId}>
            <span>{getGroupBreadcrumbsLabel(groupId, currentUser)}</span>
            <Tooltip id={tooltipId} place="top" type="light" effect="solid">
              {getGroupBreadcrumbsTooltipLabel(groupId, currentUser)}
            </Tooltip>
          </div>}
          <i className="fa fa-user" style={iconStyle} />
          <span style={labelStyle} data-tip data-for={`user-label-${userId}`}>
                    {userFullName}
                </span>
          <Tooltip id={`user-label-${userId}`} place="top" type="light" effect="solid">
            {userFullName}
          </Tooltip>
        </div>
      </div>
  );
});

const getGroupBreadcrumbsLabel = (groupId, currentUser) => {
  const group = Groups.getGroupOrCurrent(groupId);
  const currentUserGroupId = currentUser.get("groupId");
  if (group) {
    const maxDisplayLength = 15;
    const maxDisplayIndex = maxDisplayLength - 1;
    const groupName = group.get("name");
    const isRootGroup = !group.get("parentId");
    if (isRootGroup) {
      return groupName;
    } else if (group.get("id") === currentUserGroupId) {
      return groupName;
    } else if (currentUser.get("dataVisibility") === "GROUP_AND_BELOW") {
      const breadcrumbs = Immutable.fromJS(group.get("breadcrumbs")).rest();
      const userChildGroups = Groups.getChildGroups(currentUserGroupId).map(g => g.get("name"));
      const filterBreadcrumbsByUserGroup = breadcrumbs.filter(b => userChildGroups.includes(b));
      const fullBreadcrumbsStr = filterBreadcrumbsByUserGroup.join(" > ");
      const shortenedBreadcrumbsStr = filterBreadcrumbsByUserGroup.butLast().join(" > ");
      if (breadcrumbs.count() === 2) {
        if (breadcrumbs.first().length > maxDisplayLength) {
          return `${breadcrumbs.first().slice(0, maxDisplayIndex)}... > ${groupName}`;
        } else {
          return fullBreadcrumbsStr;
        }
      } else if (breadcrumbs.count() > 2 && shortenedBreadcrumbsStr.length > maxDisplayLength) {
        return `${shortenedBreadcrumbsStr.slice(0, maxDisplayIndex)}... > ${groupName}`;
      } else {
        return fullBreadcrumbsStr;
      }
    } else {
      const breadcrumbs = Immutable.fromJS(group.get("breadcrumbs")).rest();
      const fullBreadcrumbsStr = breadcrumbs.join(" > ");
      const shortenedBreadcrumbsStr = breadcrumbs.butLast().join(" > ");
      if (breadcrumbs.count() === 2) {
        if (breadcrumbs.first().length > maxDisplayLength) {
          return `${breadcrumbs.first().slice(0, maxDisplayIndex)}... > ${groupName}`;
        } else {
          return fullBreadcrumbsStr;
        }
      } else if (breadcrumbs.count() > 2 && shortenedBreadcrumbsStr.length > maxDisplayLength) {
        return `${shortenedBreadcrumbsStr.slice(0, maxDisplayIndex)}... > ${groupName}`;
      } else {
        return fullBreadcrumbsStr;
      }
    }
  } else {
    return "";
  }
};

const getGroupBreadcrumbsTooltipLabel = (groupId, currentUser) => {
  const group = Groups.getGroupOrCurrent(groupId);
  const currentUserGroupId = currentUser.get("groupId");
  if (group) {
    const groupName = group.get("name");
    const isRootGroup = !group.get("parentId");
    if (isRootGroup) {
      return group.get("name");
    } else if (group.get("id") === currentUserGroupId) {
      return groupName;
    } else if (currentUser.get("dataVisibility") === "GROUP_AND_BELOW") {
      const breadcrumbs = Immutable.fromJS(group.get("breadcrumbs"));
      const userChildGroups = Groups.getChildGroups(currentUser.get("groupId")).map(g => g.get("name"));
      const filterBreadcrumbsByUserGroup = breadcrumbs.filter(b => userChildGroups.includes(b));
      const fullBreadcrumbsStr = filterBreadcrumbsByUserGroup.join(" > ");
      return fullBreadcrumbsStr;
    } else {
      const breadcrumbs = Immutable.fromJS(group.get("breadcrumbs")).rest();
      const fullBreadcrumbsStr = breadcrumbs.join(" > ");
      return fullBreadcrumbsStr;
    }
  } else {
    return "";
  }
};

const userGroupColumnStyles = css`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;

export {
  getGroupBreadcrumbsLabel,
  getGroupBreadcrumbsTooltipLabel
};



const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <UsersGroupsColumns theme={theme} {...props} />;
};

export default Wrapper;
