import Immutable from "immutable";

import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";
import eventBus from "js/cube19.event-bus";

const path = window.path;

const baseUrl = "reporting/config";

let reportConfigs = Immutable.Map();
const load = () => ajax
    .get({ url: baseUrl })
    .then(response => {
        reportConfigs = Immutable.fromJS(populateSpecialCaseData(response));
        eventBus.trigger("data-loaded", "snd-report-configs");
    }, error => {
        eventBus.trigger("error:fatal", error);
    });

/*
 * qualifierId of "0" means current user or group
 * see ticket #3245 in mingle
 */
const populateSpecialCaseData = response => {
    let parsedResponse = { ...response };
    Object
        .keys(parsedResponse)
        .forEach(key => {
            const configs = parsedResponse[key];
            configs.forEach(config => {
                if (config.qualifierId === 0) {
                    config.qualifierId = getCurrentQualifierId(config.qualifierType);
                }
            });
        });
    return parsedResponse;
};

const getCurrentQualifierId = qualifierType => {
    const currentUser = Users.getCurrentUser();
    switch (qualifierType) {
    case "GROUP":
        return currentUser.get("groupId");
    case "USER":
        return currentUser.get("id");
    }
};

const getAll = () => reportConfigs;

const getReportsByType = reportType => reportConfigs.get(reportType);

const create = reportConfig => ajax
    .post({
        url: path(baseUrl),
        json: reportConfig
    })
    .then(response => {
        let newReportConfig = Immutable.fromJS(response);
        if(newReportConfig.get("qualifierId") === 0){
            const qualifierId = getCurrentQualifierId(newReportConfig.get("qualifierType"));
            newReportConfig = newReportConfig.set("qualifierId", qualifierId);
        }
        const ownedReports = reportConfigs.get("ownedReports");
        reportConfigs = reportConfigs.set("ownedReports", ownedReports.push(newReportConfig));
        return reportConfigs;
    });

const remove = (reportId, reportType) => {
    const reportUrl = reportType === "ownedReports" ? `${baseUrl}/own` : `${baseUrl}/sharing`;
    return ajax
        .del({ url: path(reportUrl, reportId) })
        .then(() => {
            const index = reportConfigs.get(reportType).findIndex(r => r.get("id") === reportId);
            const newReports = reportConfigs.get(reportType).delete(index);
            reportConfigs = reportConfigs.set(reportType, newReports);
            return reportConfigs;
        });
};

export {
    load,
    create,
    remove,
    getAll,
    getReportsByType
};
