import currentClient from "js/common/repo/backbone/current-client";
import * as Users from "js/common/users";

const apps = {
  dashboards: "DASHBOARDS",
  gamification: "BIGSCREENS",
  jobsPipeline: "JOB_PIPELINE",
  onPoint: "ONPOINT",
  oneView: "ONEVIEW",
  sliceAndDice: "REPORTING",
  squids: "SQUIDS",
  trends: "CHARTING",
  usage: "USAGE",

  actualAdmin: "ACTUALS_ADMIN",
  admin: "ADMIN",
  companyAdmin: "CLIENT_ADMIN",
  dataExplorerAdmin: "DATA_EXPLORER_ADMIN",
  gamificationAdmin: "CAROUSEL_ADMIN",
  groupAdmin: "GROUP_ADMIN",
  ipAccessAdmin: "IP_ADMIN",
  jobsPipeLineAdmin: "JOB_PIPELINE_ADMIN",
  kpiAdmin: "KPI_ADMIN",
  oneviewPageAdmin: "SECTION_ADMIN",
  ratioAdmin: "RATIO_ADMIN",
  tagAdmin: "TAGS_ADMIN",
  targetAdmin: "TARGET_ADMIN",
  timeframeAdmin: "TIMEFRAME_ADMIN",
  timezoneAdmin: "TIMEZONE_ADMIN",
  userAdmin: "REACT_USER_ADMIN"
};

const clientPermissions = {
  canEditJobCloseDate: "CAN_EDIT_JOB_CLOSE_DATE",
  canEditJobValue: "CAN_EDIT_JOB_VALUE",
  canEditKpiConfigs: "KPI_CONFIG_TAB",
  canEditUserPhotoUrl: "EDIT_PHOTO_URL",
  canFilterByOrganisation: "ORGANISATION_FILTER",
  canFilterByTag: "TAG_FILTERS", // unused?
  canPivot: "PIVOT", // unused?
  canReceiveDealFlash: "DEAL_FLASH",
  canReceiveDealFlashForExtensions: "DEAL_FLASH_CONTRACT_EXTENSION",
  canSeeCandidateSource: "CANDIDATE_SOURCE",
  canSeeMultipleCurrencies: "MULTI_CURRENCY",
  canSetLongDealMusic: "LONG_DEAL_MUSIC",
  canUseAdvancedTrendCharting: "ADVANCED_TREND_CHARTING",
  canUseClickThrough: "CLICK_THROUGH",
  canUseGroupTargetCalculation: "GROUP_TARGET_CALCULATION",
  canUseLeaderboardClickThrough: "LEADERBOARD_CT",
  canUseOneViewFilters: "ONEVIEW_FILTERS", // unused?
  canUseQuickViewClickThrough: "QUICKVIEW_CT",
  canUseTargetClickThrough: "TARGET_CT",
  uses445Calendar: "USES_445_CALENDAR",
  usesNewCubeTvDesign: "HAS_NEW_LOOK_CUBETV" // all clients should have this, will be removed
};

const userPermissions = {
  canApproveKpis: "METRIC_APPROVAL",
  canExportToCsv: "EXPORT_DIRECT_TO_CSV",
  canExportToFile: "EXPORT_FILE",
  canFilterByTag: "TAG_FILTERS",
  canShareClientSet: "SHARE_CLIENT_SET",
  canShareOnPointReport: "SHARE_ONPOINT_REPORT",
  canShareSliceAndDiceReport: "SHARE_SND_REPORT",
  canShareTrendsPage: "SHARE_CHART_PAGE",
  canSubmitKpis: "METRIC_SUBMISSION",
  canUseAllOneViewPages: "ONEVIEW_PAGE_SUPER_USER",
  canUseClickThrough: "CLICK_THROUGH",
  canUseLeaderboardClickThrough: "LEADERBOARD_CT",
  canUseOneViewFilters: "ONEVIEW_FILTERS", // unused?
  canUseQuickViewClickThrough: "QUICKVIEW_CT",
  canUseSquidJsonEditor: "SQUID_JSON_EDITOR",
  canUseSquidEditor: "SQUID_EDITOR",
  canUserTargetClickThrough: "TARGET_CT"
};

/**
 * A user can access an app if they and the current client have that app granted to them.
 */
const userCanAccessApp = (user, app) => currentClient.canAccessApp(app) && Users.canAccessApp(user, app);
const currentUserCanAccessApp = app => userCanAccessApp(Users.getCurrentUser(), app);

export {apps, clientPermissions, userPermissions, currentUserCanAccessApp, userCanAccessApp};

