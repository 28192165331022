import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import {IconButton} from "js/common/views/inputs/buttons";
import Layout from "js/common/views/foundation-column-layout";
import { Card } from "@mui/material"
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Ratio = createReactClass({

  propTypes: {
    ratio: ImmutablePropTypes.map.isRequired,
    onMoveRatioDownClick: ReactPropTypes.func.isRequired,
    onMoveRatioUpClick: ReactPropTypes.func.isRequired,
    onDeleteRatioClick: ReactPropTypes.func.isRequired,
    theme: ReactPropTypes.object
  },

  render() {
    const {ratio, onMoveRatioDownClick, onMoveRatioUpClick, onDeleteRatioClick, theme} = this.props;
    const firstKpiName = KpiRepo.get(ratio.get("firstKpiId")).get("name");
    const secondKpiName = KpiRepo.get(ratio.get("secondKpiId")).get("name");
    return (
      <li style={ratioListItemStyle(theme)}>
        <Card>
        <Layout allSmall={12} medium={[9, 3]}>
          <Layout allSmall={12} medium={[6, 6]}>
            <span style={{marginRight: "2rem",display: "block", width: "100%", overflow: 'hidden', textWrap: "nowrap", textOverflow: "ellipsis"}}>{ratio.get("name")}</span>
            <span>{firstKpiName} : {secondKpiName}</span>
          </Layout>

          <div>
            <div className="right">
              <IconButton
                icon="arrow-down"
                title="Move Ratio Down"
                onClick={onMoveRatioDownClick}
                size="large" />
              <IconButton
                icon="arrow-up"
                title="Move Ratio Up"
                onClick={onMoveRatioUpClick}
                size="large" />
              <IconButton
                hoverType="alert"
                icon="trash"
                title="Delete Ratio"
                onClick={onDeleteRatioClick}
                size="large" />
            </div>
          </div>
        </Layout>
        </Card>
      </li>
    );
  }

});

const ratioListItemStyle = theme => ({
  listStyle: "none",
  borderRadius: 3,
  backgroundColor: theme.themeId === "light" ? theme.palette.background.paper : "#252525",
  border: "1px solid rgba(255, 255, 255, 0.21)",
  marginBottom: "1.25rem",
  padding: "0.625rem"
});

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <Ratio theme={theme} {...props} />;
};