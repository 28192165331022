/** @jsxImportSource @emotion/react */

import React from "react";
import ReactPropTypes from "prop-types";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import GetContainerDimensions from "react-dimensions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {jsx, css} from "@emotion/react";
import {faBuilding, faChevronDoubleRight, faFilter} from "@fortawesome/pro-regular-svg-icons";
import InfiniteList from "js/common/views/infinite-loading/infinite-list";

import LoadingSpinner from "js/common/views/loading-spinner";
import ErrorMsg from "js/common/views/error";
import SaveAsDialog from "js/common/views/save-as-dialog";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import pure from "js/common/views/pure";
import * as Colors from "js/common/cube19-colors";
import * as Ajax from "js/common/ajax";
import * as Popups from "js/common/popups";
import * as Users from "js/common/users";
import { TextField } from '@mui/material';
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

export const CLIENT_SET_SIZE_LIMIT = 2000;

const ClientSetEditor = createReactClass({

  propTypes: {
    ownClientSets: ImmutablePropTypes.list.isRequired,
    clientSet: ImmutablePropTypes.map.isRequired,
    onCancelClick: ReactPropTypes.func.isRequired,
    onSaveClick: ReactPropTypes.func.isRequired,
    onSaveAsClick: ReactPropTypes.func.isRequired,
    errorMessage: ReactPropTypes.string,
    onSetError: ReactPropTypes.func,
    onSetNameError: ReactPropTypes.func
  },

  getInitialState() {
    return ({
      changedClientSet: this.props.clientSet,
      clientSetToShare: null,
      shareClientSetError: null,
      shareClientSetSuccess: null,
      editableClientSetSharingSettings: null,
      loadingClientSetSharing: null,
      searchText: "",
      clients: null,
      loadingClients: true,
      loadingSelectAll: false,
      isSaveAsDialogOpen: false
    });
  },

  componentDidMount() {
    this.updateClients(this.props.clientSet);
  },

  render() {
    const {
      clientSet,
      errorMessage,
      ownClientSets,
      theme
    } = this.props;
    const {
      searchText,
      changedClientSet,
      clients,
      loadingClients,
      loadingSelectAll,
      isSaveAsDialogOpen
    } = this.state;
    const name = changedClientSet.get("name");
    const clientIds = changedClientSet.getIn(["json", "clientIds"]);

    const h3Style = {
      textTransform: theme.themeId === "light" ? "none" : "uppercase",
      fontWeight: 300,
      color: theme.themeId === "light" ? theme.palette.text.main : theme.palette.primary.main
    };

    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%", padding: "2rem"}}>
          <h2>{name}</h2>
          {errorMessage && <ErrorMsg text={errorMessage} />}

          <div style={{display: "flex", justifyContent: "space-between", marginTop: "2rem"}}>
            <div>
              <h3 style={h3Style}>Client picker</h3>
              <ClientPicker
                  theme={theme}
                  searchText={searchText}
                  clientIds={clientIds}
                  onSearchChange={searchText => this.setState({searchText: searchText.target.value})}
                  onAddClientClick={this.handleAddClientClick}
                  onSelectAllClick={this.handleSelectAllClick}
                  selectAllLoading={loadingSelectAll} />
            </div>

            <FontAwesomeIcon
                style={{marginTop: "auto", marginBottom: "auto"}}
                icon={faChevronDoubleRight}
                size={"3x"} />

            <div>
              <h3 style={h3Style}>Client set</h3>
              <ClientSet
                  theme={theme}
                  loadingClients={loadingClients}
                  clientIds={clientIds}
                  clients={clients}
                  onRemoveClientClick={this.handleRemoveClientClick}
                  onRemoveAllClick={this.handleRemoveAllClick} />
            </div>
          </div>

          <ControlButtons
              clientSet={changedClientSet}
              onCancelClick={this.handleCancelClick}
              onSaveAsClick={this.handleSaveAsClick}
              onSaveClick={this.handleSaveClick}
              hasUnsavedChanges={hasUnsavedChanges(clientSet, changedClientSet)} />

          {isSaveAsDialogOpen &&
          <SaveAsDialog
              savingTypeName={"client set"}
              initialName={name || "New Client Set"}
              onSaveAsRequest={this.handleSaveAs}
              onCancelRequest={() => this.setState({isSaveAsDialogOpen: false})}
              unavailableNames={ownClientSets.map(config => config.get("name"))} />}
        </div>
    );

  },

  updateClients(clientSet) {
    const json = clientSet.get("json");
    const clientIds = json.get("clientIds");
    this.setState({loadingClients: true},
        () => loadClients(clientIds)
            .then(clients => this.setState({
              clients,
              loadingClients: false
            })));
  },

  handleRemoveAllClick() {
    this.setState(state => {
      return {changedClientSet: state.changedClientSet.setIn(["json", "clientIds"], Immutable.Set())};
    });
  },

  handleSaveClick() {
    const onSaveClick = this.props.onSaveClick;
    onSaveClick(this.state.changedClientSet).then(clientSet => this.setState({changedClientSet: clientSet}));
  },

  handleSaveAsClick() {
    this.setState({isSaveAsDialogOpen: true});
  },

  handleSaveAs(name) {
    const onSaveClick = this.props.onSaveClick;
    onSaveClick(this.state.changedClientSet.delete("id").set("name", name))
        .then(clientSet => this.setState({changedClientSet: clientSet}));
  },

  handleNameChange(event) {
    const onSetNameError = this.props.onSetNameError;
    const newName = event.target.value;
    this.setState((state) => {
      return ({changedClientSet: state.changedClientSet.set("name", newName)});
    }, () => onSetNameError(""));
  },

  handleCancelClick() {
    this.props.onCancelClick();
    this.setState((state, props) => {
      if (state.changedClientSet.has("id")) {
        return ({changedClientSet: props.clientSet});
      }
    });
  },

  handleRemoveClientClick(clientId) {
    this.setState((state, props) => {
      const {onSetError} = props;
      const oldClientIds = state.changedClientSet.getIn(["json", "clientIds"]);
      const newClientIds = oldClientIds.filter(id => id !== clientId);
      onSetError("");
      return ({changedClientSet: state.changedClientSet.setIn(["json", "clientIds"], newClientIds)});
    });
  },

  handleAddClientClick(client) {
    this.setState(state => {
      const clients = state.clients.add(client);
      const clientIds = state.changedClientSet.getIn(["json", "clientIds"]).add(client.get("id"));
      const changedClientSet = state.changedClientSet.setIn(["json", "clientIds"], clientIds);
      return ({
        clients,
        changedClientSet
      });
    });
  },

  handleSelectAllClick() {
    this.setState({
      loadingSelectAll: true
    });
    Ajax
        .get({
          url: "entity-finder/all",
          data: {
            entity: "CLIENT",
            namePrefix: this.state.searchText
          }
        })
        .then(Immutable.fromJS)
        .then(response => response.get("items").map(entityToItem))
        .then(fetchedClients => {
          this.setState(state => {
            const fetchedClientIds = fetchedClients.map(client => client.get("id"));
            const clientIds = state.changedClientSet.getIn(["json", "clientIds"]).union(fetchedClientIds);
            const changedClientSet = state.changedClientSet.setIn(["json", "clientIds"], clientIds);
            const clients = state.clients.union(fetchedClients);
            return ({
              clients,
              changedClientSet,
              loadingSelectAll: false
            });
          });
        })
        .catch(error => {
          if (error.responseJSON && error.responseJSON.type === "TOO_MANY_RESULTS") {
            Popups.error("Too many Clients to add all. Please perform a more specific search.");
          } else {
            Popups.contactSupport();
          }
          this.setState({
            loadingSelectAll: false
          });
        });
  }

});

const ClientSetListItem = pure(({client, onRemoveClient}) => {
  return (
      <li css={css({display: "flex", flexDirection: "row", alignItems: "center"})}>
        <FontAwesomeIcon icon={faBuilding} style={{marginRight: "0.5rem"}} />
        <span css={css({
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "block",
          width: "100%"
        })}>
          {client.get("name")}
        </span>
        <IconButton
            icon="remove"
            size="small"
            onClick={() => onRemoveClient(client.get("id"))} />
      </li>
  );
});

const ClientPicker = pure(({theme, searchText, clientIds, onSearchChange, onAddClientClick, onSelectAllClick, selectAllLoading}) => {
  return (
      <div style={{...listContainerStyle(theme), display: "flex", flexDirection: "column"}}>
        <TextField variant="standard"
            fullWidth={true}
            label={<span><FontAwesomeIcon icon={faFilter} />&nbsp;Filter clients</span>}
            value={searchText}
            onChange={onSearchChange} />

        <div style={{height: "100%"}}>
          <FlexibleInfiniteList
              searchPrefix={searchText}
              onItemClick={onAddClientClick}
              selectedItemIds={clientIds} />
        </div>

        <TextButton
            label={"Select all"}
            fullWidth={true}
            type={"default"}
            onClick={onSelectAllClick}
            disabled={selectAllLoading}
            containerStyle={{marginTop: "0.5rem"}} />
      </div>
  );
});

const FlexibleInfiniteList = GetContainerDimensions()(createReactClass({

  propTypes: {
    onItemClick: ReactPropTypes.func.isRequired,
    searchText: ReactPropTypes.string,
    selectedItemIds: ImmutablePropTypes.set
  },

  render() {
    const {searchPrefix, onItemClick, selectedItemIds, containerHeight} = this.props;
    return (
        <InfiniteList
            searchPrefix={searchPrefix || ""}
            searchUrl={"entity-finder/search"}
            searchParams={{entity: "CLIENT"}}
            searchResultToItem={entityToItem}
            onItemClick={onItemClick}
            itemIcon={faBuilding}
            selectedItemIds={selectedItemIds}
            maxHeight={containerHeight} />
    );
  }
}));

const ClientSet = pure(({theme, loadingClients, clientIds, clients, onRemoveClientClick, onRemoveAllClick}) => {
  const clientCount = clientIds.count();
  let counterStyle = {marginTop: "0.5rem", textAlign: "center"};
  if (clientCount > CLIENT_SET_SIZE_LIMIT) {
    counterStyle = {...counterStyle, color: Colors.c19Red}
  }
  return (
      <div style={{...listContainerStyle(theme), overflow: "hidden"}}>
        {loadingClients ?
            <LoadingSpinner /> :
            <ul style={{listStyle: "none", overflow: "auto", marginBottom: 0, height: "100%"}}>
              {clientIds
                  .map(clientId => clients.find(client => client.get("id") === clientId))
                  .sort(clientItemSorter)
                  .map(client => {
                    return (<ClientSetListItem
                        key={client.get("id")}
                        client={client}
                        onRemoveClient={onRemoveClientClick} />);
                  })}
            </ul>}
            <div style={counterStyle}>
              <span>{`${clientCount} / ${CLIENT_SET_SIZE_LIMIT}`}</span>
            </div>
        <TextButton
            label={"Remove all"}
            fullWidth={true}
            type={"default"}
            onClick={onRemoveAllClick}
            disabled={clientIds.isEmpty()}
            containerStyle={{marginTop: "0.5rem"}} />
      </div>
  );
});

const ControlButtons = pure(({clientSet, onCancelClick, onSaveAsClick, onSaveClick, hasUnsavedChanges}) => {
  return (
      <div style={{display: "flex", alignSelf: "flex-end", marginTop: "auto", marginLeft: "auto"}}>
        <TextButton
            label="Cancel"
            style={{marginRight: "1rem"}}
            disabled={!hasUnsavedChanges}
            onClick={onCancelClick} />
        <TextButton
            label="Save"
            style={{marginRight: "1rem"}}
            disabled={
              !clientSet.has("id")
              || !hasUnsavedChanges
              || !isValid(clientSet)
              || clientSet.get("ownerId") !== Users.getCurrentUser().id
            }
            onClick={onSaveClick} />
        <TextButton
            label="Save As"
            disabled={!isValid(clientSet)}
            onClick={onSaveAsClick} />
      </div>
  );
});

const loadClients = (initialIds) => Ajax
    .post({url: "entity-finder", json: {entityType: "CLIENT", ids: initialIds.toJS()}})
    .then(x => Immutable.fromJS(x).map(entityToItem).toSet());

const isValid = (changedClientSet) => {
  const clientIds = changedClientSet.getIn(["json", "clientIds"]);
  return clientIds.size > 0;
};

const entityToItem = (entity) => Immutable.Map({
  id: entity.get("id"),
  name: entity.get("isDeleted") ?
      `${entity.get("originalCrmId")} - [deleted] ${entity.get("name")}` :
      `${entity.get("originalCrmId")} - ${entity.get("name")}`,
  entity
});

const clientItemSorter = (itemA, itemB) => {
  const clientA = itemA.get("entity");
  const clientB = itemB.get("entity");
  if (clientA.get("isDeleted") === clientB.get("isDeleted")) {
    return clientA.get("name").toLowerCase().localeCompare(clientB.get("name").toLowerCase());
  } else {
    return clientA.get("isDeleted") ? -1 : 1;
  }
};

const hasUnsavedChanges = (currentClientSet, changedClientSet) => {
  return !changedClientSet.equals(currentClientSet);
};

const listContainerStyle = theme => ({
  display: "flex",
  flexDirection: "column",
  backgroundColor: theme.palette.background.card,
  padding: "1rem",
  width: "25vw",
  height: "60vh"
});


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ClientSetEditor theme={theme} {...props} />;
};

export default Wrapper;
