import React from "react";
import Immutable from "immutable";
import pure from "js/common/views/pure";

import Breadcrumbs from "js/admin/simple-breadcrumbs";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";

export default pure(({ errors }) => (
    <div>
        <p style={{ marginBottom: "0.5rem" }}>{errors.error}</p>
        {Object
            .keys(errors)
            .filter(key => key !== "error")
            .map((key, index) => {
                const isGroup = key.toLowerCase().includes("group");
                const typeIconClass = `fa fa-${isGroup ? "group" : "user"}`;
                const ids = errors[key].split(",");
                return (
                    <div key={index} style={{ marginBottom: "0.5rem" }}>
                        <div>
                            <i className={typeIconClass} style={{ paddingRight: 8 }} />
                            {key}
                        </div>
                        <ul style={{ marginLeft: "1.25rem" }}>
                            {isGroup ?
                                ids.map(id => getGroupBreadcrumbs(id)) :
                                ids.map(id => getUserFullName(id))}
                        </ul>
                    </div>
                );
            })}
    </div>
));

const getGroupBreadcrumbs = groupId => {
    const group = Groups.getGroup(groupId);
    const breadcrumbs = group.get("breadcrumbs");
    return <li style={listItemStyle}><Breadcrumbs trail={Immutable.fromJS(breadcrumbs)} /></li>;
};

const getUserFullName = userId => <li style={listItemStyle}>{Users.getUser(parseInt(userId)).get("fullName")}</li>;

const listItemStyle = {
    paddingTop: 5,
    fontWeight: "normal"
};
