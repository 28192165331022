import React, {memo, useRef, useState, useEffect} from "react";
import Immutable from "immutable";

const CustomEdgeLabel = ({
  x,
  y,
  label,
  labelStyle = {},
  labelBgStyle = {},
  labelBgPadding = [6, 6],
  labelBgBorderRadius = 2,
  isErroring
}) => {

  const edgeRef = useRef(null);
  const [edgeTextBbox, setEdgeTextBbox] = useState({width: 0, height: 0});

  useEffect(() => {
    if (edgeRef.current) {
      const renderedLabel = Immutable.isList(label) ? label.first() : label;

      setEdgeTextBbox({
        width: renderedLabel ? Math.round(renderedLabel.length * 5) : 50,
        height: 28
      });
    }
  }, [label]);

  const locationAdjustment = isErroring ? 20 : 0;


  if (typeof label === "undefined" || !label || (!isErroring && label.join().length < 1)) {
    return <foreignObject
        width={edgeTextBbox.width * labelBgPadding[0] + locationAdjustment}
        height={edgeTextBbox.height + locationAdjustment}
        x={(x - labelBgPadding[0]) - (edgeTextBbox.width / 2) - locationAdjustment}
        y={y - (edgeTextBbox.height / 2) - locationAdjustment}>
      <div style={{
        transform: "translateZ(10px)",
        width: edgeTextBbox.width + 2 * labelBgPadding[0],
        height: edgeTextBbox.height,
        position: "relative",
        top: locationAdjustment,
        pointerEvents: "none"
      }}>
        <span ref={edgeRef} style={{...labelStyle, fontSize: "10px"}} />
      </div>
    </foreignObject>
  }

  const errorIcon = <svg width="25"
                         height="25"
                         x={edgeTextBbox.width + 2 * labelBgPadding[0] + 10}
                         y={`-${edgeTextBbox.height / 2}`}
                         style={{position: "absolute", top: -12, right: -10}}
                         viewBox="0 0 25 25"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg">
    <rect width="25" height="25" rx="12" fill="#84424D" />
    <path d="M14.6309 16.8743C14.6309 17.9943 13.7196 18.9055 12.5996 18.9055C11.4796 18.9055 10.5684 17.9943 10.5684 16.8743C10.5684 15.7542 11.4796 14.843 12.5996 14.843C13.7196 14.843 14.6309 15.7542 14.6309 16.8743ZM10.8035 6.54534L11.1488 13.4516C11.165 13.7759 11.4327 14.0305 11.7574 14.0305H13.4418C13.7665 14.0305 14.0342 13.7759 14.0504 13.4516L14.3957 6.54534C14.4131 6.19726 14.1356 5.90552 13.7871 5.90552H11.4121C11.0636 5.90552 10.7861 6.19726 10.8035 6.54534Z"
          fill="#FF5A61" />
  </svg>;

  return (
      <foreignObject
          width={edgeTextBbox.width * labelBgPadding[0] + locationAdjustment}
          height={edgeTextBbox.height + locationAdjustment}
          x={(x - labelBgPadding[0]) - (edgeTextBbox.width / 2) - locationAdjustment}
          y={y - (edgeTextBbox.height / 2) - locationAdjustment}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
      >
        <div style={{
          ...labelBgStyle,
          borderRadius: `${labelBgBorderRadius}px`,
          transform: "translateZ(10px)",
          width: edgeTextBbox.width + 2 * labelBgPadding[0],
          height: edgeTextBbox.height,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          top: locationAdjustment,
          pointerEvents: "none"
        }}>
          <span ref={edgeRef} style={{...labelStyle, fontSize: "10px"}}>{label}</span>
          {isErroring && errorIcon}
        </div>
      </foreignObject>
  );
};

export default memo(CustomEdgeLabel);
