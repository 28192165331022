import React from "react";
import pure from "js/common/views/pure";

import * as numbers from "js/common/utils/numbers";
import { getColorForPercentage } from "js/oneview/kpi-values-display";
import * as Formatter from "js/common/utils/formatter";

import getKpiDataSafely from "js/onpoint/get-kpi-data-safely";

export default pure(({
    theme,
    viewType,
    valueFormatType,
    kpiData,
    labelStyle = {}
}) => {
    const targetBarHeight = 20;
    const total = getKpiDataSafely(kpiData, "total");
    const totalValue = total.get("value");
    const target = getKpiDataSafely(kpiData, "target");
    const targetValue = numbers.roundTo(target.get("value"), 3);
    const hasTarget = targetValue > 0;
    const expected = getKpiDataSafely(kpiData, "expectedValue");
    const expectedValue = numbers.roundTo(expected.get("value"), 3);
    const hasExceededExpected = totalValue > expectedValue;
    const totalVsExpectedPercent = hasExceededExpected ? 1 : getPercentageValue(totalValue, expectedValue);
    const targetPercentageColor = getColorForPercentage(totalVsExpectedPercent);
    const percentageOfTarget = getPercentageValue(totalValue, targetValue);
    const expectedTargetLabel = getExpectedTargetLabel(total, expected);
    const svgHeight = 40;
    const svgWidth = 300;
    const fill = theme.themeId === "light" ?  "#eee" : "#a9a9a9"
    const valueDisplayStyle = {
        ...labelStyle,
        whiteSpace: "nowrap",
        height: svgHeight,
        textAlign: "right",
        fontWeight: "600"
    };
    if (viewType === "DETAILED" && hasTarget) {
        return (
            <div style={{ lineHeight: `${svgHeight}px`, height: svgHeight }}>
                <svg height={svgHeight} width={svgWidth}>
                    <TargetBar height={targetBarHeight} fill={fill}/>
                    <PercentageDone
                        theme={theme}
                        percentage={percentageOfTarget}
                        color={targetPercentageColor}
                        height={targetBarHeight} />
                    <ExpectedPoint
                        theme={theme}
                        xPos={getPercentageValue(expectedValue, targetValue)}
                        label={expectedTargetLabel}
                        color={targetPercentageColor} />
                    <TotalVsTarget total={total} target={target} theme={theme} />
                </svg>
            </div>
        );
    } else if (viewType === "COMPACT" && hasTarget) {
        const titleStr = `${expectedTargetLabel} - ${numbers.toPercentageStr(percentageOfTarget)} of target`;
        return (
            <div
                className="TESTCAFE-onpoint-value"
                style={{ ...valueDisplayStyle, color: textColorByName(theme)[targetPercentageColor] }}
                title={titleStr}>
                {Formatter.format(total.toJS(), {valueFormat: valueFormatType})} / {Formatter.format(target.toJS(), {valueFormat: valueFormatType})}
            </div>
        );
    } else {
        return (
            <div
                className="TESTCAFE-onpoint-value"
                style={valueDisplayStyle}>
                {Formatter.format(total.toJS(), {valueFormat: valueFormatType})}
            </div>
        );
    }
});

const TargetBar = pure(({ height, fill }) => <rect x="1" y="2" width="100%" height={height} fill={fill} />);

const PercentageDone = pure(({ percentage, color, height, theme }) => {
    const percentageStr = numbers.toPercentageStr(percentage);
    const width = percentage < 1 ? percentageStr : "100%";
    return (
        <g>
            <title>{percentageStr}</title>
            <rect x={1} y={2} width={width} height={height} fill={fillColorByName(theme)[color]} />
        </g>
    );
});

const ExpectedPoint = pure(({ xPos, label, color, theme }) => {
    const x = numbers.toPercentageStr(xPos);
    const textAnchor = getTextAnchorPosition(xPos);
    const fillColor = textColorByName(theme)[color];
    return (
        <g>
            <title>EXP: Min. Expected To Be Done</title>
            <text x={x} y="38" textAnchor={textAnchor} fill={fillColor} style={{ fontSize: "0.75rem" }}>
                {label}
            </text>
            <line x1={x} x2={x} y1={0} y2={25} strokeWidth={2} stroke="orange" />
        </g>
    );
});

const TotalVsTarget = pure(({ total, target, valueFormatType, theme }) => {
    const percentage = getPercentageValue(total.get("value"), numbers.roundTo(target.get("value"), 3));
    const formattedTotalValue = Formatter.format(total.toJS(), {valueFormat: valueFormatType});
    const formattedTargetValue = Formatter.format(target.toJS(), {valueFormat: valueFormatType});
    return (
        <g>
            {percentage > 0 && <title>{numbers.toPercentageStr(percentage)}</title>}
            <text x="99%" y={18} textAnchor="end" style={totalVsTargetLabelStyle(theme)}>
                {`${formattedTotalValue} / ${formattedTargetValue}`}
            </text>
        </g>
    );
});

const totalVsTargetLabelStyle = theme => ({
    fontSize: "1rem",
    fontFamily: theme.typography.fontFamilyBold,
    fill: "black"
});

const fillColorByName = theme => ({
    green: "#61c152",
    yellow: theme.themeId === "light" ? "#F6B042" : theme.palette.primary.main,
    red: "#DA4453"
});

const textColorByName = theme => ({
    green: "#61c152",
    yellow: theme.themeId === "light" ? "#F6B042" : theme.palette.primary.main,
    red: "#DA4453"
});

const getTextAnchorPosition = percentage => {
    let textAnchor = "start";
    if (percentage >= 0.35 && percentage <= 0.7) {
        textAnchor = "middle";
    }
    if (percentage > 0.7) {
        textAnchor = "end";
    }
    return textAnchor;
};

const getPercentageValue = (numerator, denominator) => {
    const percentage = denominator === 0 ? 0 : numerator / denominator;
    return Math.floor(percentage * 100) / 100;
};

const getExpectedTargetLabel = (total, expected) => {
    const decimalPlaces = 2;
    const maxValueDisplayLength = 10;
    const totalValue = total.get("value");
    const expectedValue = numbers.roundTo(expected.get("value"), 3);
    const difference = {
        value: Math.abs(totalValue - expectedValue),
        currency: total.get("currency")
    };
    const formattedDifference = Formatter.format(difference, {decimalPlaces, maxDisplayLength: maxValueDisplayLength});
    let differenceLabel = "";
    if (totalValue > expectedValue) {
        differenceLabel = `(\u25B2 ${formattedDifference} ahead)`;
    } else if (totalValue < expectedValue) {
        differenceLabel = `(\u25BC ${formattedDifference} behind)`;
    }
    return `EXP: ${Formatter.format(expected.toJS(), {decimalPlaces, maxDisplayLength: maxValueDisplayLength})} ${differenceLabel}`;
};
