const brandingName = "Bullhorn Analytics";
const emailSupport = "support-analytics@bullhorn.com";
const bullhornSupportTicketDocsUrl = "https://help.bullhorn.com/bhatsTopics/s/article/ATS-Submitting-a-Ticket-to-Bullhorn-Support";
const submitTicketString = `If you need help please submit a ticket, ${bullhornSupportTicketDocsUrl}`;


const linkStyle = {
  color: "inherit",
  textDecoration: "underline",

  ":hover": {
    textDecoration: "none"
  }
};
const submitTicketInstructions = <span>
    If you need help please <a style={linkStyle} href={bullhornSupportTicketDocsUrl}>submit a ticket</a>
</span>;



export {
  brandingName,
  emailSupport,
  bullhornSupportTicketDocsUrl,
  submitTicketString,
  submitTicketInstructions
};