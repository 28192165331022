import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import moment from "moment";
import FileSaver from "browser-filesaver";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Dimensions from "react-dimensions";

import {TextField} from "@mui/material";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import MenuBar from "js/common/views/menu-bar";
import Drawer from "js/common/views/drawer";
import Dialog from "js/common/views/dialog";
import SavedReportsMenu from "js/reporting/saved-reports-menu";
import ShareReport from "js/common/views/sharing/share-config";
import ConfigurableReport from "js/reporting/configurable-report";
import C19Dialog from "js/common/views/tabs-dialog";
import UncaughtErrorMsg from "js/common/views/uncaught-error-msg";
import {TextButton} from "js/common/views/inputs/buttons";
import {trackError} from "js/common/error-tracking";
import {isDesktop, isTablet} from "js/common/ua-parser";
import {c19Yellow} from "js/common/cube19-colors";
import pure from "js/common/views/pure";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as ReportRepo from "js/common/repo/report-repo";
import * as Auditor from "js/common/auditer";
import * as auditer from "js/common/auditer";
import * as Popups from "js/common/popups";
import * as Ajax from "js/common/ajax";
import {
  cellValueRequired,
  downloadTableAsExcelFile,
  getDerivedAttributes,
  isDataTableTypeReport,
  parseToPivotData,
  prepareDataForClickThrough
} from "js/common/pivot-utils";
import DataDetailsTable from "js/oneview/kpi-details/data-details-table";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants";

const path = window.path;
const $ = window.$;

const isDesktopDevice = isDesktop();
const isTabletDevice = isTablet();

const ErrorBoundaryPage = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      uncaughtError: false
    };
  },

  componentDidCatch(error, {componentStack}) {
    trackError("REACT_RENDER", error, {componentStack});
    this.setState({uncaughtError: true});
  },

  render() {
    if (this.state.uncaughtError) {
      return (
          <div>
            <MenuBar />
            <UncaughtErrorMsg />
          </div>
      );
    } else {
      return <Page theme={this.props.theme} {...this.props} />;
    }
  }

});

const MobilesNotSupportedMsg = () => (
    <p style={{textAlign: "center", margin: "5rem auto"}}>
      <i className="fa fa-exclamation-triangle" style={{fontSize: "1.5rem", color: "#fbbd2b"}} />
      <br />
      Slice & Dice is not supported for mobile phones
    </p>
);

const getDefaultReportConfig = (
    timeframe,
    groupId,
    userId,
    matchAnyTagIds,
    matchAllTagIds,
    excludedTagIds,
    kpiIds = Immutable.List(),
    pivotConfig = Immutable.Map()) => {
  const defaultTimeframe = timeframe || TimeframeRepo.getDefaultForClient().getRawJson();
  const defaultPivotTableConfig = {
    total: {
      column: true,
      row: true
    },
    columns: ["Kpi name"],
    rows: ["Owner"],
    renderer: "Table",
    aggregator: "Sum (Whole numbers)",
    cellValue: "Kpi value",
    ...pivotConfig.toJS()
  };
  return Immutable.fromJS({
    cid: Math.random(),
    reportName: "Slice & Dice Report",
    kpiIds: kpiIds.toJS(),
    groupId: groupId || Users.getCurrentUser().get("groupId"),
    userId,
    timeframe: timeframe || defaultTimeframe,
    matchAnyTagIds: matchAnyTagIds || [],
    matchAllTagIds: matchAllTagIds || [],
    excludedTagIds: excludedTagIds || [],
    extraConfig: {
      showUsersWithNoData: false,
      organisationIds: []
    },
    ...defaultPivotTableConfig
  });
};

const Page = Dimensions()(createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    const {
      initialTimeframe,
      initialGroupId,
      initialUserId,
      initialMatchAnyTagIds,
      initialMatchAllTagIds,
      initialExcludedTagIds,
      initialKpiIds,
      initialPivotConfig,
      initialOriginalData
    } = this.props;
    const defaultConfig = getDefaultReportConfig(
        initialTimeframe,
        initialGroupId,
        initialUserId,
        initialMatchAnyTagIds,
        initialMatchAllTagIds,
        initialExcludedTagIds,
        initialKpiIds,
        initialPivotConfig);
    return {
      renderId: Math.random(),
      kpisById: Immutable.Map(),
      timeframes: TimeframeRepo.getAll(),
      isLoadingKpis: false,
      hierarchy: Immutable.fromJS(Groups.getHierarchyWithUsers()),
      config: defaultConfig,
      isConfigChanged: false,
      originalPivotData: this.props.initialPivotData,
      pivotData: this.props.initialPivotData,
      originalData: initialOriginalData,
      derivedPivotTableAttributes: this.props.initialDerivedPivotTableAttributes,
      pivotDataCellDetails: null,
      reportHasNoData: false,
      reportHasLimitedData: false,
      rowLimitReached: initialOriginalData ? initialOriginalData.rowLimitReached : false,
      isPointInTimeKpisDataWarningDialogOpen: false,
      isKpisRequiredDialogOpen: false,
      isSelectedNonClientFilterableKpisInfoDialogOpen: false,
      isConfigHasOnlyNonClientFilterableKpisErrorDialogOpen: false,
      isLoadingReport: false,
      loadReportError: null,
      loadReportSuccess: null,
      isGeneratingCsv: false,
      generateCsvError: null,
      generateCsvSuccess: null,

      isSavingReport: false,
      saveReportError: null,
      saveReportSuccess: null,

      savedReportsByType: ReportRepo.getAll(),
      isSavedReportsMenuOpen: false,
      savedReportsError: null,
      savedReportsSuccess: null,

      savedReportToRemove: null,
      removeSavedReportError: null,
      removeSavedReportSuccess: null,

      savedReportToShare: null,
      reportSharingSettings: null,
      editableReportSharingSettings: null,
      isLoadingReportSharingSettings: false,
      shareReportError: null,
      shareReportSuccess: null
    };
  },

  componentDidMount() {
    const theme = localStorage.getItem("appTheme");
    $("body")
        .removeClass()
        .addClass(`theme-${theme}`);

    this.setState({
      isLoadingKpis: true
    });
    loadReportingKpis()
        .then(kpis => {
          let kpisById = Immutable.Map();
          kpis.forEach(kpi => {
            kpisById = kpisById.set(kpi.get("id"), kpi);
          });
          this.setState({
            kpisById,
            isLoadingKpis: false
          });
        });

    // Poll for new shared reports
    window.setInterval(() => {
      ReportRepo.load()
          .then(() => {
            this.setState({
              savedReportsByType: ReportRepo.getAll()
            });
          });
    }, 300000);

    Auditor.audit("main_snd:loaded");
  },

  render() {
    const isMobileDevice = !isDesktopDevice && !isTabletDevice;
    if (isMobileDevice) {
      return <MobilesNotSupportedMsg />;
    }

    const {
      renderId,
      kpisById,
      isLoadingKpis,
      hierarchy,
      timeframes,
      config,
      isConfigChanged,
      pivotData,
      derivedPivotTableAttributes,
      pivotDataCellDetails,
      reportHasNoData,
      reportHasLimitedData,
      rowLimitReached,
      isKpisRequiredDialogOpen,
      isSelectedNonClientFilterableKpisInfoDialogOpen,
      isConfigHasOnlyNonClientFilterableKpisErrorDialogOpen,
      isPointInTimeKpisDataWarningDialogOpen,
      isAggregatorCellValueRequiredDialogOpen,
      isLoadingReport,
      loadReportError,
      loadReportSuccess,

      isGeneratingCsv,
      savedReportIdBeingGeneratedAsCsv,
      generateCsvError,
      generateCsvSuccess,

      isSaveReportAsDialogOpen,
      newSavedReportNameTextFieldError,
      isSavingReport,
      saveReportError,
      saveReportSuccess,

      isSavedReportsMenuOpen,
      savedReportsByType,

      savedReportToRemove,
      removeSavedReportError,
      removeSavedReportSuccess,

      savedReportToShare,
      isLoadingReportSharingSettings,
      reportSharingSettings,
      editableReportSharingSettings,
      shareReportError,
      shareReportSuccess
    } = this.state;
    const selectedNonClientFilterableKpis = config
        .get("kpiIds")
        .filter(kpiId => {
          const kpi = KpiRepo.get(kpiId);
          return !kpi.get("filterableByClient");
        })
        .map(kpiId => KpiRepo.get(kpiId));

    return (
        <div style={{width: "100%", height: "100%"}} className={`table-${this.props.theme.themeId}`}>
          <MenuBar appView="reporting" onClick={this.handleMenuBarClick} />
          <div style={{width: "100%", height: "calc(100% - 50px)"}}>
            <ConfigurableReport
                key={config.get("id") || config.get("cid")}
                renderId={renderId}
                kpisById={kpisById}
                isLoadingKpis={isLoadingKpis}
                hierarchy={hierarchy}
                timeframes={timeframes}
                config={config}
                onConfigChange={this.handleConfigChange}
                isConfigChanged={isConfigChanged}
                theme={this.props.theme}
                onShowSelectedNonClientFilterableKpisRequest={() => {
                  this.setState({
                    isSelectedNonClientFilterableKpisInfoDialogOpen: true
                  });
                }}
                onPivotTableRefresh={this.handlePivotTableRefresh}
                onClearReportRequest={this.handleClearReportRequest}
                onGenerateReportRequest={this.handleGenerateReportRequest}
                isLoadingReport={isLoadingReport}
                loadReportError={loadReportError}
                loadReportSuccess={loadReportSuccess}
                isGeneratingCsv={isGeneratingCsv}
                generateCsvError={generateCsvError}
                generateCsvSuccess={generateCsvSuccess}
                data={pivotData}
                derivedPivotTableAttributes={derivedPivotTableAttributes}
                onPivotTableCellClick={this.handlePivotTableCellClick}
                reportHasNoData={reportHasNoData}
                reportHasLimitedData={reportHasLimitedData}
                rowLimitReached={rowLimitReached}
                onDownloadReportRequest={this.handleDownloadReportRequest}
                onGenerateAsCsvRequest={this.handleGenerateReportAsCsvRequest}
                onSaveReportRequest={this.handleSaveReportRequest}
                isSavingReport={isSavingReport} />
          </div>
          <Drawer
              width={500}
              open={isSavedReportsMenuOpen}
              onRequestOpen={this.openSavedReportsMenu}
              onRequestClose={this.closeSavedReportsMenu}>
            <SavedReportsMenu
                savedReportsByType={savedReportsByType}
                onSavedReportClick={this.loadSavedReport}
                onGenerateAsCsvClick={this.handleGenerateSavedReportAsCsvRequest}
                savedReportIdBeingGeneratedAsCsv={savedReportIdBeingGeneratedAsCsv}
                onRefreshReportsClick={this.handleRefreshReportsRequest}
                onShareReportClick={this.handleShareSavedReportRequest}
                onRemoveSavedReportClick={this.openConfirmRemoveSavedReportDialog} />
          </Drawer>
          {pivotDataCellDetails &&
              <C19Dialog
                  onRequestClose={() => this.setState({pivotDataCellDetails: null})}
                  label={pivotDataCellDetails.get("title")}
                  content={
                    <div>
                      <DataDetailsTable
                          isLoading={isLoadingReport}
                          height={600}
                          filenameForDownload={"Data"}
                          onDownloadClick={() => auditer.audit(
                              "kpi_ct:download_table",
                              this.getAuditOptions(config.get("reportName")))}
                          columns={pivotDataCellDetails.get("columns").toJS()}
                          rows={pivotDataCellDetails.get("rows").toJS()}
                          rowLimitReached={rowLimitReached} />
                    </div>
                  } />}
          {isKpisRequiredDialogOpen &&
              <Dialog
                  title="No Metrics Selected"
                  actions={[
                    <TextButton
                        key="ok-btn"
                        type="primary"
                        label="OK"
                        style={dialogButtonStyle}
                        onClick={() => this.setState({isKpisRequiredDialogOpen: false})} />
                  ]}
                  autoDetectWindowHeight={true}
                  bodyStyle={dialogBodyStyle}
                  open={true}>
                <p>Please select at least 1 Metric for your report</p>
              </Dialog>}
          {isSelectedNonClientFilterableKpisInfoDialogOpen &&
              <NonClientFilterableKpisInfoDialog
                  nonClientFilterableKpis={selectedNonClientFilterableKpis}
                  onCloseRequest={this.closeSelectedNonClientFilterableKpisInfoDialog} />}
          {isConfigHasOnlyNonClientFilterableKpisErrorDialogOpen &&
              <ConfigHasOnlyNonClientFilterableKpisErrorDialog
                  nonClientFilterableKpis={selectedNonClientFilterableKpis}
                  onCloseRequest={this.closeConfigHasOnlyNonClientFilterableKpisErrorDialog} />}
          {isAggregatorCellValueRequiredDialogOpen &&
              <Dialog
                  title="Aggregator Value Required"
                  actions={[
                    <TextButton
                        key="ok-btn"
                        type="primary"
                        label="OK"
                        style={dialogButtonStyle}
                        onClick={() => this.setState({isAggregatorCellValueRequiredDialogOpen: false})} />
                  ]}
                  autoDetectWindowHeight={true}
                  bodyStyle={dialogBodyStyle}
                  open={true}>
                <p>Please select an aggregator value from the dropdown below the selected aggregator</p>
              </Dialog>}
          {isPointInTimeKpisDataWarningDialogOpen &&
              <PointInTimeKpisWarning
                  pointInTimeKpiIds={getPointInTimeKpiIds(config.get("kpiIds"))}
                  onCloseRequest={() => this.setState({isPointInTimeKpisDataWarningDialogOpen: false})} />}

          {isSaveReportAsDialogOpen &&
              <SaveReportAsDialog
                  initialName={config.get("reportName")}
                  textFieldError={newSavedReportNameTextFieldError}
                  onClearSaveReportErrorRequest={() => this.setState({saveReportError: null})}
                  onSaveAsRequest={this.handleSaveReportAsRequest}
                  onCloseRequest={this.closeSaveReportAsDialog}
                  isSavingReport={isSavingReport}
                  saveError={saveReportError}
                  saveSuccess={saveReportSuccess} />}

          {!!savedReportToRemove &&
              <ConfirmRemoveSavedReportDialog
                  reportName={savedReportToRemove.get("reportName")}
                  onRemoveRequest={this.handleRemoveSavedReportRequest}
                  error={removeSavedReportError}
                  success={removeSavedReportSuccess}
                  onCloseRequest={this.closeConfirmRemoveSavedReportDialog} />}

          {!!savedReportToShare &&
              <C19Dialog
                  label={
                    <span
                        style={{
                          color: this.props.theme.themeId === "light"
                              ? this.props.theme.palette.text.main
                              : this.props.theme.palette.primary.main,
                          textTransform: "uppercase"
                        }}>
                            {`Share ${savedReportToShare.get("reportName")}`}
                        </span>
                  }
                  onRequestClose={this.closeShareReportDialog}
                  content={
                    <ShareReport
                        config={savedReportToShare}
                        isLoading={isLoadingReportSharingSettings}
                        reportSharingSettings={editableReportSharingSettings}
                        onReportSharingSettingsChange={this.handleReportSharingSettingsChange}
                        hasReportSharingSettingsChanged={reportSharingSettings !== editableReportSharingSettings}
                        onCancelChangesRequest={this.handleCancelReportSharingSettingsChangesRequest}
                        onShareReportRequest={this.handleSaveReportSharingSettingsChangesRequest}
                        shareReportError={shareReportError}
                        shareReportSuccess={shareReportSuccess}
                        onSuccessTimeout={this.closeShareReportDialog} />
                  }
                  flex={1}
                  contentOverflowAutoScroll={false} />}
        </div>
    );
  },

  getAuditOptions(reportName) {
    const currentUser = Users.getCurrentUser();
    return {
      currentUser,
      reportName
    };
  },

  handleMenuBarClick(e) {
    if (e === "saved-reports") {
      this.openSavedReportsMenu();
    } else {
      throw new Error("Unsupported menu bar click event:", e);
    }
  },

  openSavedReportsMenu() {
    this.setState({
      isSavedReportsMenuOpen: true
    });
  },

  closeSavedReportsMenu() {
    this.setState({
      isSavedReportsMenuOpen: false
    });
  },

  closeSelectedNonClientFilterableKpisInfoDialog() {
    this.setState({
      isSelectedNonClientFilterableKpisInfoDialogOpen: false
    });
  },

  closeConfigHasOnlyNonClientFilterableKpisErrorDialog() {
    this.setState({
      isConfigHasOnlyNonClientFilterableKpisErrorDialogOpen: false
    });
  },

  loadSavedReport(savedReport, reportType) {
    this.closeSavedReportsMenu();

    const config = parseSavedReportConfig(savedReport);
    this.setState({
      renderId: Math.random(),
      config,
      pivotData: null,
      reportHasNoData: false,
      reportHasLimitedData: false,
      rowLimitReached: false,
      isLoadingReport: true,
      loadReportError: null,
      loadReportSuccess: null,
      generateCsvError: null,
      generateCsvSuccess: null
    });

    loadReportData(config)
        .then(result => {
          const isOwnReport = reportType === "ownedReports";
          const auditCategory = isOwnReport ? "main_snd:run_saved_report" : "main_snd:run_shared_report";
          Auditor.audit(auditCategory, {
            reportName: config.get("reportName"),
            reportId: config.get("id")
          });
          const qualifierType = savedReport.get("qualifierType");
          const qualifierId = savedReport.get("qualifierId");
          this.setState({
            originalData: result,
            reportHasNoData: result.rows.length === 0,
            reportHasLimitedData: !currentUserCanViewDataFor(qualifierType, qualifierId),
            rowLimitReached: result.rowLimitReached
          });
          return this.parseData(result);
        })
        .then(pivotData => {
          const group = getGroup(config.get("groupId"));
          const weekStartsOn = group.get("weekStartsOn");
          this.setState({
            isLoadingReport: false,
            loadReportSuccess: "Saved Report Generated",
            pivotData,
            derivedPivotTableAttributes: getDerivedAttributes(pivotData, weekStartsOn)
          });
        })
        .catch(error => {
          const errorType = error.type;
          auditError(errorType);
          this.setState({
            isLoadingReport: false,
            loadReportError: errorType
          });
        });
  },

  handleRefreshReportsRequest() {
    ReportRepo.load()
        .then(() => {
          this.setState({
            savedReportsByType: ReportRepo.getAll()
          });
        });
  },

  handleShareSavedReportRequest(savedReport, reportType) {
    const isOwnReport = reportType === "ownedReports";
    const currentUserId = Users.getCurrentUser().get("id");
    this.setState({
      savedReportToShare: isOwnReport ? savedReport.set("ownerId", currentUserId) : savedReport,
      isLoadingReportSharingSettings: true,
      isSavedReportsMenuOpen: false
    }, () => {

      loadReportSharingSettings(savedReport.get("id"))
          .then(reportSharingSettings => {
            this.setState({
              isLoadingReportSharingSettings: false,
              reportSharingSettings,
              editableReportSharingSettings: reportSharingSettings
            });
          }, () => {
            this.setState({
              isLoadingReportSharingSettings: false,
              shareReportError: "Unable to load report sharing settings"
            });
          });
    });
  },

  closeShareReportDialog() {
    this.setState({
      savedReportToShare: null,
      reportSharingSettings: null,
      editableReportSharingSettings: null,
      shareReportError: null,
      shareReportSuccess: null
    });
  },

  handleReportSharingSettingsChange(reportSharingSettings) {
    this.setState({
      editableReportSharingSettings: reportSharingSettings
    });
  },

  handleCancelReportSharingSettingsChangesRequest() {
    this.setState({
      editableReportSharingSettings: this.state.reportSharingSettings
    });
  },

  handleSaveReportSharingSettingsChangesRequest() {
    this.setState({
      isLoadingReportSharingSettings: true
    });
    const {savedReportToShare, reportSharingSettings, editableReportSharingSettings} = this.state;
    saveReportSharingSettings(savedReportToShare.get("id"), editableReportSharingSettings)
        .then(() => {
          const payload = {
            reportId: savedReportToShare.get("id"),
            change: {
              originalSettings: reportSharingSettings.toJS(),
              newSettings: editableReportSharingSettings.toJS()
            }
          };
          Auditor.audit("main_snd:share-report", payload);
          this.setState({
            isLoadingReportSharingSettings: false,
            shareReportSuccess: "Your changes to the report sharing settings have been saved"
          });
        }, err => {
          let errorMsg = "Unable to save changes to the report sharing settings";
          const responseJSON = err.responseJSON;
          if (responseJSON && responseJSON.errors) {
            errorMsg = responseJSON.errors;
          }
          this.setState({
            isLoadingReportSharingSettings: false,
            shareReportError: errorMsg
          });
        });
  },

  openConfirmRemoveSavedReportDialog(savedReport) {
    this.setState({
      savedReportToRemove: savedReport
    });
  },

  closeConfirmRemoveSavedReportDialog() {
    this.setState({
      savedReportToRemove: null,
      removeSavedReportError: null,
      removeSavedReportSuccess: null
    });
  },

  handleRemoveSavedReportRequest() {
    const {savedReportToRemove} = this.state;
    const reportName = savedReportToRemove.get("reportName");
    ReportRepo
        .remove(savedReportToRemove.get("id"), savedReportToRemove.get("reportType"))
        .then(savedReportsByType => {
          Auditor.audit("main_snd:delete-report", {reportName});
          this.setState({
            savedReportsByType,
            removeSavedReportSuccess: "Saved report removed"
          });
        }, e => {
          const error = e.responseJSON;
          let errorMsg;
          if (error && error.type === "CANNOT_DELETE") {
            errorMsg = error.message;
          } else {
            errorMsg =
                `Unable to remove saved report. An unexpected error has occurred. ${Branding.submitTicketString}`;
          }
          this.setState({
            removeSavedReportError: errorMsg
          });
        });
  },

  handleSaveReportRequest() {
    const {config} = this.state;
    const kpiIds = config.get("kpiIds");
    const nonClientFilterableKpiIds = kpiIds.filter(kpiId => {
      const kpi = KpiRepo.get(kpiId);
      return !kpi.get("filterableByClient");
    });
    const organisationIds = config.getIn(["extraConfig", "organisationIds"]);
    if (kpiIds.isEmpty()) {
      this.setState({
        isKpisRequiredDialogOpen: true
      });
    } else if (kpiIds.count() === nonClientFilterableKpiIds.count() && !organisationIds.isEmpty()) {
      this.setState({
        isConfigHasOnlyNonClientFilterableKpisErrorDialogOpen: true
      });
    } else {
      const isMissingAggregatorCellValue = cellValueRequired(config.get("aggregator")) && !config.get("cellValue");
      if (isMissingAggregatorCellValue) {
        this.setState({
          isAggregatorCellValueRequiredDialogOpen: isMissingAggregatorCellValue
        });
      } else {
        this.openSaveReportAsDialog();
      }
    }
  },

  openSaveReportAsDialog() {
    this.setState({
      isSaveReportAsDialogOpen: true
    });
  },

  closeSaveReportAsDialog() {
    this.setState({
      isSaveReportAsDialogOpen: false,
      saveReportError: null,
      saveReportSuccess: null
    });
  },

  handleSaveRequest() {
    if (this.state.reportName.length === 0) {
      this.setState({
        textFieldError: nameRequiredError
      });
    } else {
      this.props.onSaveAsRequest(this.state.reportName);
    }
  },

  handleSaveReportAsRequest(newSavedReportName) {
    const isDuplicateUserOwnedReportName = this.state.savedReportsByType
        .get("ownedReports")
        .map(sr => sr.get("reportName"))
        .includes(newSavedReportName);
    if (isDuplicateUserOwnedReportName) {
      this.setState({
        saveReportError: "A saved report with this name already exists. Please give this report a different name."
      });
    } else {
      this.setState({
        isSavingReport: true
      });

      const {config} = this.state;
      const newConfig = config.set("reportName", newSavedReportName);
      const userId = config.get("userId");
      const groupId = config.get("groupId");
      const isUserReport = config.has("userId") && (userId !== null && userId !== undefined);
      const newSavedReportConfig = newConfig
          .set("timeframe", JSON.stringify(getTimeframeJson(config.get("timeframe"))))
          .set("qualifierType", isUserReport ? "USER" : "GROUP")
          .set("qualifierId", isUserReport ? userId : groupId)
          .delete("userId")
          .delete("groupId")
          .delete("cid")
          .delete("id")
          .delete("ownerId")
          .toJS();

      ReportRepo
          .create(newSavedReportConfig)
          .then(savedReportsByType => {
            this.setState({
              isSavingReport: false,
              config: newConfig,
              savedReportsByType: savedReportsByType,
              saveReportSuccess: `'${newSavedReportName}' saved'`
            });
          }, () => {
            this.setState({
              isSavingReport: false,
              saveReportError: "Unable to save report"
            });
          });
      Auditor.audit("main_snd:save-report", {name: newSavedReportName});
    }
  },

  handleConfigChange(newConfig) {
    const {config, derivedPivotTableAttributes, isConfigChanged} = this.state;
    const isKpiIdsChanged = !Immutable.is(newConfig.get("kpiIds"), config.get("kpiIds"));
    const isGroupIdChanged = !Immutable.is(newConfig.get("groupId"), config.get("groupId"));
    const isUserIdChanged = !Immutable.is(newConfig.get("userId"), config.get("userId"));
    const isTimeframeChanged = !Immutable.is(newConfig.get("timeframe"), config.get("timeframe"));
    const isMatchAnyTagIdsChanged = !Immutable.is(newConfig.get("matchAnyTagIds"), config.get("matchAnyTagIds"));
    const isMatchAllTagIdsChanged = !Immutable.is(newConfig.get("matchAllTagIds"), config.get("matchAllTagIds"));
    const isExcludedTagIdsChanged = !Immutable.is(newConfig.get("excludedTagIds"), config.get("excludedTagIds"));
    const pathToOrgIds = ["extraConfig", "organisationIds"];
    const isOrganisationIdsChanged = !Immutable.is(newConfig.getIn(pathToOrgIds), config.getIn(pathToOrgIds));
    const pathToShowUsersWithNoData = ["extraConfig", "showUsersWithNoData"];
    const isShowUsersWithNoDataChanged = newConfig.getIn(pathToShowUsersWithNoData) !==
        config.getIn(pathToShowUsersWithNoData);
    const isReportNameChanged = newConfig.get("reportName") !== config.get("reportName");
    const isNotPivotTableConfigChange = isKpiIdsChanged ||
        isGroupIdChanged ||
        isUserIdChanged ||
        isTimeframeChanged ||
        isMatchAnyTagIdsChanged ||
        isMatchAllTagIdsChanged ||
        isExcludedTagIdsChanged ||
        isOrganisationIdsChanged ||
        isShowUsersWithNoDataChanged ||
        isReportNameChanged;

    this.setState({
      config: newConfig,
      isConfigChanged: isConfigChanged ? isConfigChanged : isNotPivotTableConfigChange,
      derivedPivotTableAttributes: isNotPivotTableConfigChange ? null : derivedPivotTableAttributes,
      loadReportError: null,
      loadReportSuccess: null,
      generateCsvError: null,
      generateCsvSuccess: null
    });
  },

  handlePivotTableRefresh(pivotTableConfig) {
    const pivotTableAggregator = pivotTableConfig.get("aggregatorName");
    const pivotTableCellValues = pivotTableConfig.get("vals");
    const currentAggregator = this.state.config.get("aggregator");
    const isAggregatorChanged = pivotTableAggregator !== currentAggregator;
    const isMissingCellValues = cellValueRequired(pivotTableAggregator) && pivotTableCellValues.isEmpty();
    if (isAggregatorChanged && isMissingCellValues) {
      this.setState({
        isAggregatorCellValueRequiredDialogOpen: true
      });
    }

    const newConfig = this.state.config
        .set("renderer", pivotTableConfig.get("rendererName"))
        .set("aggregator", pivotTableConfig.get("aggregatorName"))
        .set("cellValue", pivotTableConfig.get("vals").first())
        .set("columns", pivotTableConfig.get("cols"))
        .set("rows", pivotTableConfig.get("rows"));
    this.setState({
      config: newConfig
    });
  },

  handleClearReportRequest() {
    Auditor.audit("main_snd:cleared");

    this.setState({
      config: getDefaultReportConfig(),
      isConfigChanged: false,
      originalData: null,
      pivotData: null,
      reportHasNoData: false,
      reportHasLimitedData: false,
      rowLimitReached: false,
      loadReportError: null,
      loadReportSuccess: null,
      generateCsvError: null,
      generateCsvSuccess: null,
      saveReportError: null,
      saveReportSuccess: null
    });
  },

  handleGenerateReportRequest() {
    const kpiIds = this.state.config.get("kpiIds");
    const nonClientFilterableKpiIds = kpiIds.filter(kpiId => {
      const kpi = KpiRepo.get(kpiId);
      return !kpi.get("filterableByClient");
    });
    const organisationIds = this.state.config.getIn(["extraConfig", "organisationIds"]);
    if (kpiIds.isEmpty()) {
      this.setState({
        isKpisRequiredDialogOpen: true
      });
    } else if (kpiIds.count() === nonClientFilterableKpiIds.count() && !organisationIds.isEmpty()) {
      this.setState({
        isConfigHasOnlyNonClientFilterableKpisErrorDialogOpen: true
      });
    } else {
      this.setState({
        reportHasNoData: false,
        reportHasLimitedData: false,
        isKpisRequiredDialogOpen: false,
        isLoadingReport: true,
        pivotData: null,
        loadReportError: null,
        loadReportSuccess: null
      });

      loadReportData(this.state.config)
          .then(result => {
            let runkpiIds = kpiIds;
            if (!organisationIds.isEmpty()) {
              runkpiIds = removeNonClientFilterableKpiIds(kpiIds);
            }
            Auditor.audit("main_snd:run_custom_report", {kpiIds: runkpiIds});

            this.setState({
              originalData: result,
              reportHasNoData: result.rows.length === 0,
              rowLimitReached: result.rowLimitReached
            });
            return this.parseData(result);
          })
          .then(pivotData => {
            const group = getGroup(this.state.config.get("groupId"));
            const weekStartsOn = group.get("weekStartsOn");
            this.setState({
              isLoadingReport: false,
              isConfigChanged: false,
              isPointInTimeKpisDataWarningDialogOpen: this.showPointInTimeKpisDataWarning(),
              pivotData,
              derivedPivotTableAttributes: getDerivedAttributes(pivotData, weekStartsOn),
              loadReportSuccess: "Report Generated"
            });
          })
          .catch(error => {
            const errorType = error.type;
            auditError(errorType);
            this.setState({
              isLoadingReport: false,
              loadReportError: errorType
            });
          });
    }
  },

  handleGenerateSavedReportAsCsvRequest(savedReport) {
    this.setState({
      savedReportIdBeingGeneratedAsCsv: savedReport.get("id"),
      generateCsvError: null,
      generateCsvSuccess: null
    });
    const config = parseSavedReportConfig(savedReport);
    generateReportAsCsv(config)
        .then(result => {
          const blob = new Blob([result], {type: "text/csv;charset=utf-8"});
          const csvFileName = getGeneratedCsvFileName();
          FileSaver.saveAs(blob, csvFileName);

          Auditor.audit("main_snd:generate_report_as_csv");

          Popups.success("Report generated as a CSV file");

          this.setState({
            savedReportIdBeingGeneratedAsCsv: null,
            isPointInTimeKpisDataWarningDialogOpen: this.showPointInTimeKpisDataWarning(),
            generateCsvSuccess: "CSV Generated"
          });
        }, error => {
          this.setState({
            savedReportIdBeingGeneratedAsCsv: null,
            generateCsvError: error.type
          });
        });
  },

  handleGenerateReportAsCsvRequest() {
    if (this.state.config.get("kpiIds").isEmpty()) {
      this.setState({
        isKpisRequiredDialogOpen: true
      });
    } else {
      this.setState({
        reportHasNoData: false,
        reportHasLimitedData: false,
        isKpisRequiredDialogOpen: false,
        isGeneratingCsv: true,
        generateCsvError: null,
        generateCsvSuccess: null
      });

      generateReportAsCsv(this.state.config)
          .then(result => {
            // TODO why don't we just let the browser download this CSV straight from the server?
            const blob = new Blob([result], {type: "text/csv;charset=utf-8"});
            const csvFileName = getGeneratedCsvFileName();
            FileSaver.saveAs(blob, csvFileName);

            Auditor.audit("main_snd:generate_report_as_csv");

            Popups.success("Report generated as a CSV file");

            this.setState({
              isGeneratingCsv: false,
              isPointInTimeKpisDataWarningDialogOpen: this.showPointInTimeKpisDataWarning(),
              generateCsvSuccess: "CSV Generated"
            });
          }, error => {
            const errorType = error.type;
            auditError(errorType);
            this.setState({
              isGeneratingCsv: false,
              generateCsvError: errorType
            });
          });
    }
  },

  showPointInTimeKpisDataWarning() {
    const pointInTimeKpis = getPointInTimeKpiIds(this.state.config.get("kpiIds"));
    const todayDate = moment();
    const timeframe = this.state.config.get("timeframe");
    const startDate = moment(timeframe.get("start"));
    const endDate = moment(timeframe.get("end"));
    const dateRangeIsToday = todayDate.isSame(startDate, "day") && todayDate.isSame(endDate, "day");
    const dateRangeIncludesToday = todayDate.isBetween(startDate, endDate, "day", "[]");
    return !dateRangeIsToday && dateRangeIncludesToday && !pointInTimeKpis.isEmpty();
  },

  parseData(data) {
    if (data.rows.length === 0) {
      const placeholderDataRow = data.columns.map(col => {
        switch (col.dataType.toUpperCase()) {
          case "CURRENCY":
            return {
              currency: "",
              type: "monetary",
              value: 0
            };
          case "INTEGER":
            return {
              type: "numerical",
              value: 0
            };
          default:
            return {
              type: "string",
              value: ""
            };
        }
      });
      const rows = [placeholderDataRow];
      return parseToPivotData(data.columns, rows);
    } else {
      let pivotData = parseToPivotData(data.columns, data.rows);
      this.setState({
        originalPivotData: [...pivotData]
      });

      const {config} = this.state;
      const showUsersWithNoData = config.getIn(["extraConfig", "showUsersWithNoData"]);
      const isGroupReport = !config.has("userId")
          || config.get("userId") === null
          || config.get("userId") === undefined;
      if (showUsersWithNoData && isGroupReport) {
        pivotData = injectRowsForUsersWithNoData(
            pivotData,
            data.columns,
            config.get("groupId"),
            config.get("kpiIds")
        );
      }
      return pivotData;
    }
  },

  handlePivotTableCellClick(dataFilter) {
    const pivotDataCellDetails = prepareDataForClickThrough(
        this.state.originalData,
        dataFilter,
        this.state.derivedPivotTableAttributes);
    if (!pivotDataCellDetails) {
      Popups.error("There is no data behind this cell");
    } else {
      this.setState({pivotDataCellDetails});
    }
  },

  handleDownloadReportRequest() {
    if (cellValueRequired(this.state.config.get("aggregator")) && !this.state.config.get("cellValue")) {
      this.setState({
        isCellValueForAggregatorRequiredDialogOpen: true
      });
    } else {
      const ua = Auditor.getUserAgent();
      const browser = ua.getBrowser();
      if (browser.name === "IE" && !navigator.msSaveOrOpenBlob) { // Saving to file is only supported on IE10+
        const ieMessage = "This functionality is not supported by your version of Internet Explorer.<br/>" +
            "We recommend Google Chrome or Microsoft Edge for this.";
        Popups.alert(ieMessage, {
          title: "Unable to Save to File"
        });
      } else if (isDataTableTypeReport(this.state.config.get("renderer"))) {
        const fileName = `${this.state.config.get("reportName")}.xls`;
        const $this = $(ReactDOM.findDOMNode(this));
        const tableHtml = $this.find("#pivot-table .pvtTable").html();
        const blob = downloadTableAsExcelFile(tableHtml);
        FileSaver.saveAs(blob, fileName);
        Auditor.audit("main_snd:download_report");
      } else {
        Popups.alert(
            "This functionality only works for data tables.<br />Please choose a <strong>Table</strong> type report (e.g. Table, Table Barchart, Heatmap).",
            {
              title: "Unable to Save to File"
            });
      }
    }
  }

}));

const dialogBodyStyle = {
  color: "#fff"
};

const dialogButtonStyle = {
  marginLeft: "0.5rem",
  marginRight: "0.5rem",
  marginBottom: "1rem"
};

const PointInTimeKpisWarning = pure(({pointInTimeKpiIds, onCloseRequest}) => {
  const pointInTimeKpiIdsCount = pointInTimeKpiIds.count();
  let title = `${pointInTimeKpiIdsCount} Point-in-time Metric Selected`;
  if (pointInTimeKpiIdsCount === 1) {
    title = `${pointInTimeKpiIdsCount} Point-in-time Metrics Selected`;
  }
  return (
      <Dialog
          title={title}
          actions={[
            <TextButton
                key="dismiss-btn"
                label="OK"
                type="primary"
                style={dialogButtonStyle}
                onClick={onCloseRequest} />
          ]}
          autoDetectWindowHeight={true}
          bodyStyle={dialogBodyStyle}
          open={true}>
        <div>
          <p>The following Metrics will only display the data for today:</p>
          <ul style={{marginLeft: "1.5rem"}}>
            {pointInTimeKpiIds.map(kpiId => {
              const kpi = KpiRepo.get(kpiId);
              return <li key={kpi.get("id")}>{kpi.get("name")}</li>;
            })}
          </ul>
        </div>
      </Dialog>
  );
});

const NonClientFilterableKpisInfoDialog = pure(({
  nonClientFilterableKpis,
  onCloseRequest
}) => (
    <Dialog
        title="Non Client filterable Metrics selected"
        actions={[
          <TextButton
              key="dismiss-btn"
              label="OK"
              type="primary"
              style={dialogButtonStyle}
              onClick={onCloseRequest} />
        ]}
        autoDetectWindowHeight={true}
        bodyStyle={dialogBodyStyle}
        open={true}>
      <p>The following selected Metrics are not filterable by Client:</p>
      <KpiList kpis={nonClientFilterableKpis} />
      <p>The above Metrics will be excluded from the generated report and all non Client filterable Metrics in the
        Metric picker have been disabled.</p>
    </Dialog>
));

const ConfigHasOnlyNonClientFilterableKpisErrorDialog = pure(({
  nonClientFilterableKpis,
  onCloseRequest
}) => (
    <Dialog
        title="Non Client filterable Metrics selected"
        actions={[
          <TextButton
              key="dismiss-btn"
              label="OK"
              type="primary"
              style={dialogButtonStyle}
              onClick={onCloseRequest} />
        ]}
        autoDetectWindowHeight={true}
        bodyStyle={dialogBodyStyle}
        open={true}>
      <p>Unable to generate report as there is an active Client filter and all the selected Metrics are not filterable
        by client:</p>
      <KpiList kpis={nonClientFilterableKpis} />
      <p>Please select some Client filterable Metrics or remove the Client filter(s).</p>
    </Dialog>
));

const KpiList = pure(({kpis}) => {
  const bulletImg = `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12"><circle fill="${c19Yellow}" cx="6" cy="4" r="4"/></svg>')`;
  const listStyle = {
    listStylePosition: "inside",
    listStyleImage: bulletImg,
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20
  };
  const listItemStyle = {
    lineHeight: 1,
    padding: "10px 0"
  };
  return (
      <ul style={listStyle}>
        {kpis.map(kpi => <li key={kpi.get("id") || kpi.get("cid")} style={listItemStyle}>{kpi.get("name")}</li>)}
      </ul>
  );
});

const ConfirmRemoveSavedReportDialog = pure(({
  reportName,
  isSharedReport,
  onRemoveRequest,
  onCloseRequest,
  isRemovingReport,
  error,
  success
}) => (
    <Dialog
        title={`Remove ${isSharedReport ? "Shared " : ""} Saved Report`}
        actions={[
          <TextButton
              key="dismiss-btn"
              icon="history"
              label={success ? "Close" : "Cancel"}
              style={dialogButtonStyle}
              disabled={isRemovingReport}
              onClick={onCloseRequest} />,
          <TextButton
              key="remove-btn"
              icon="trash"
              label="Remove"
              type="primary"
              style={dialogButtonStyle}
              disabled={!!error || isRemovingReport || !!success}
              onClick={onRemoveRequest} />
        ]}
        autoDetectWindowHeight={true}
        bodyStyle={dialogBodyStyle}
        open={true}>
      <p style={{marginBottom: "1rem"}}>Remove <strong>{reportName}</strong> permanently?</p>
      <div style={{minHeight: "1rem"}}>
        {error && <ErrorMsg text={error} />}
        {success && <SuccessMsg text={success} onMessageTimeout={onCloseRequest} />}
      </div>
    </Dialog>
));

const nameRequiredError = "Report name cannot be blank";

const SaveReportAsDialog = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      newReportName: this.props.initialName,
      textFieldError: ""
    };
  },

  render() {
    const {onCloseRequest, isSavingReport, saveError, saveSuccess} = this.props;
    const {newReportName, textFieldError} = this.state;
    const hasError = !!textFieldError || !!saveError;
    return (
        <Dialog
            title="Save current Slice & Dice report as..."
            actions={[
              <TextButton
                  key="dismiss-btn"
                  label={saveSuccess ? "Close" : "Cancel"}
                  icon="history"
                  style={dialogButtonStyle}
                  disabled={isSavingReport}
                  onClick={onCloseRequest} />,
              <TextButton
                  key="save-btn"
                  label="Save"
                  icon="floppy-o"
                  type="primary"
                  style={dialogButtonStyle}
                  disabled={hasError || isSavingReport || !!saveSuccess}
                  onClick={this.handleSaveRequest} />
            ]}
            autoDetectWindowHeight={true}
            bodyStyle={dialogBodyStyle}
            open={true}>
          {/* this is a stopgap to fix a known issue with React and/or Material-UI for IE11
                    https://github.com/facebook/react/issues/6822 */}
          <style type="text/css" dangerouslySetInnerHTML={{__html: "::-ms-clear {display: none;}"}} />
          <TextField
              variant="standard"
              inputStyle={{boxShadow: "none"}}
              fullWidth={true}
              label="Give this report a name"
              error={!!textFieldError}
              helperText={textFieldError}
              value={newReportName}
              onChange={this.handleNameChange}
              onKeyDown={this.handleOnKeyDown} />
          <div style={{height: "1rem"}}>
            {saveError && <ErrorMsg text={saveError} />}
            {saveSuccess && <SuccessMsg text={saveSuccess} onMessageTimeout={onCloseRequest} />}
          </div>
        </Dialog>);
  },

  handleNameChange(e) {
    const name = e.target.value;
    const maxNameLength = 255;
    this.setState({
      newReportName: name.substring(0, maxNameLength),
      textFieldError: name.length === 0 ? nameRequiredError : ""
    });
    this.props.onClearSaveReportErrorRequest();
  },

  handleOnKeyDown(e) {
    if (e.key === "Enter") {
      this.handleSaveRequest();
    }
  },

  handleSaveRequest() {
    if (this.state.newReportName.length === 0) {
      this.setState({
        textFieldError: nameRequiredError
      });
    } else {
      this.props.onSaveAsRequest(this.state.newReportName);
    }
  }

});

const getGeneratedCsvFileName = () => {
  const dateTimeGenerated = moment().format("YYYY-MM-DD HH-mm-ss");
  return `${Branding.brandingName} S&D generated at ${dateTimeGenerated}.csv`;
};

const loadReportSharingSettings = savedReportId => Ajax
    .get({url: path("reporting/config/sharing", savedReportId)})
    .then(result => {
      const groupIdToPermission = Immutable.Map(result.groupIds.map(groupId => [groupId, "VIEW"]));
      const userIdToPermisson = Immutable.Map(result.userIds.map(userId => [userId, "VIEW"]));

      return Immutable
          .Map()
          .set("groups", groupIdToPermission)
          .set("users", userIdToPermisson);
    });

const extractIds = (set) => {
  return set.map(item => item.get("id")).toList();
};
const saveReportSharingSettings = (savedReportId, reportSharingSettings) => {
  const groupIdsList = reportSharingSettings.get("groups").keySeq().toArray();
  const userIdsList = reportSharingSettings.get("users").keySeq().toArray();

  return Ajax.put({
    url: path("reporting/config/sharing", savedReportId),
    json: {groupIds: groupIdsList, userIds: userIdsList}
  });
};

const generateReportAsCsv = config => Ajax
    .post({
      url: "reporting/as-csv",
      json: getQueryParams(config)
    })
    .then(response => {
      return response;
    }, error => {
      throw {type: getErrorType(error)};
    });

const loadReportData = config => Ajax
    .post({
      url: "reporting",
      json: getQueryParams(config)
    })
    .then(response => ({
      columns: response.columns,
      rows: response.values,
      rowLimitReached: response.rowLimitReached
    }), error => {
      throw {type: getErrorType(error)};
    });

const getQueryParams = config => {
  const organisationIds = config.getIn(["extraConfig", "organisationIds"]);
  let kpiIds = config.get("kpiIds");
  if (!organisationIds.isEmpty()) {
    kpiIds = removeNonClientFilterableKpiIds(kpiIds);
  }

  // User/Group ID of "0" means current user or group (see Mingle ticket #3426)
  const userId = config.get("userId") === 0 ? Users.getCurrentUser().get("id") : config.get("userId");
  const groupId = config.get("groupId") === 0 ? Users.getCurrentUser().get("groupId") : config.get("groupId");

  const timeframe = config.get("timeframe");

  let queryParams = {
    params: {
      dateFromUI: moment().format("YYYY-MM-DD"),
      startDate: timeframe.get("start"),
      endDate: timeframe.get("end"),
      groupId,
      userId,
      anyOfTheseTagIds: config.get("matchAnyTagIds").toJS(),
      allOfTheseTagIds: config.get("matchAllTagIds").toJS(),
      noneOfTheseTagIds: config.get("excludedTagIds").toJS(),
      clientIds: organisationIds.toJS()
    },
    kpiIds: kpiIds.toJS()
  };

  // HACK to handle null user id param being sent in http request
  if (!queryParams.userId) {
    delete queryParams.userId;
  }

  return queryParams;
};

const removeNonClientFilterableKpiIds = kpiIds => kpiIds
    .filter(kpiId => KpiRepo.get(kpiId).get("filterableByClient"));

const getPointInTimeKpiIds = kpiIds => kpiIds
    .filter(kpiId => KpiRepo.get(kpiId).get("type").dateType === "INSTANT");

const loadReportingKpis = () => Ajax
    .get({url: path("reporting/kpis")})
    .then(results => Immutable.fromJS(results).filter(k => k.get("visible")));

const getGroup = groupId => Groups.getGroupOrCurrent(groupId);
const getActualGroupId = groupId => {
  // Group ID of "0" means current user's group (see Mingle ticket #3426)
  return groupId === 0 ? Users.getCurrentUser().get("groupId") : groupId;
};

const currentUserCanViewDataFor = (qualifierType, qualifierId) => {
  const currentUser = Users.getCurrentUser();
  switch (currentUser.get("dataVisibility")) {
    case "SELF":
      if (qualifierType === "GROUP") {
        return false;
      }
      if (currentUser.get("id") !== qualifierId) {
        return false;
      }
      break;
    case "GROUP_AND_BELOW":
      if (qualifierType === "GROUP") {
        return !!getGroup(qualifierId);
      }
      if (qualifierType === "USER") {
        return !!Users.getUser(qualifierId);
      }
      break;
  }
  return true;
};

const parseSavedReportConfig = savedReport => {
  const timeframe = parseTimeframeJsonString(savedReport.get("timeframe"));
  const qualifierType = savedReport.get("qualifierType");
  const qualifierId = savedReport.get("qualifierId");
  const isGroupReport = qualifierType === "GROUP";
  const groupId = isGroupReport ? qualifierId : Users.getUser(qualifierId).get("groupId");
  const isUserReport = qualifierType === "USER";
  const userId = isUserReport ? qualifierId : null;
  const extraConfig = savedReport.get("extraConfig");

  const parsedConfig = savedReport
      .set("timeframe", Immutable.fromJS(timeframe.getRawJson()))
      .set("groupId", groupId)
      .set("userId", userId)
      .set("extraConfig", parseExtraConfig(extraConfig))
      .delete("qualifierType")
      .delete("qualifierId");

  if (!currentUserCanViewDataFor(qualifierType, qualifierId)) {
    const currentUser = Users.getCurrentUser();
    if (isGroupReport && currentUser.get("dataVisibility") !== "SELF") {
      const groupNode = Groups.getHierarchy();
      return parsedConfig.set("groupId", groupNode.id);
    } else {
      return parsedConfig
          .set("groupId", currentUser.get("groupId"))
          .set("userId", currentUser.get("id"));
    }
  } else {
    return parsedConfig;
  }
};

const parseExtraConfig = extraConfig => {
  const defaultExtraConfig = getDefaultReportConfig().get("extraConfig");
  return defaultExtraConfig.merge(extraConfig);
};

const getTimeframeJson = timeframe => {
  if (timeframe.get("type").toLowerCase() === "custom") {
    return timeframe
        .set("endDate", timeframe.get("end"))
        .set("startDate", timeframe.get("start"))
        .set("timeframe", timeframe.get("type"))
        .delete("type")
        .delete("start")
        .delete("end")
        .toJS();
  } else {
    return timeframe
        .set("timeframe", timeframe.get("type"))
        .delete("type")
        .delete("start")
        .delete("end")
        .toJS();
  }
};

const parseTimeframeJsonString = timeframeJsonString => {
  const timeframeJson = JSON.parse(timeframeJsonString);
  const timeframeId = timeframeJson.timeframe;
  if (timeframeId === "custom") {
    const startDate = moment(timeframeJson.startDate, "YYYY-MM-DD");
    const endDate = moment(timeframeJson.endDate, "YYYY-MM-DD");
    const attributes = createCustomTimeframe(startDate, endDate);
    return TimeframeRepo.parseCustom(attributes, "DD-MM-YYYY");
  } else {
    return TimeframeRepo.get(timeframeId);
  }
};
const createCustomTimeframe = (startDate, endDate) => ({
  id: "custom",
  label: startDate.format("ll").replace(",", "") + " - " + endDate.format("ll").replace(",", ""),
  start: startDate,
  end: endDate
});

const injectRowsForUsersWithNoData = (originalPivotData, columns, groupId, kpiIds) => {
  let pivotData = Immutable.fromJS(originalPivotData);
  const kpiNames = kpiIds.map(kpiId => KpiRepo.get(kpiId).get("name"));
  const usersWithActivities = pivotData.map(d => d.get("Owner")).toSet();

  let dateColumnDefaultValues = Immutable.Map();
  columns
      .forEach(col => {
        if (col.dataType.toLowerCase() === "date") {
          const colLabel = col.label;
          dateColumnDefaultValues = dateColumnDefaultValues.set(colLabel, pivotData.first().get(colLabel));
        }
      });

  return Groups.loadActiveUsersInGroup(getActualGroupId(groupId))
      .then(users => {
        const rowType = "userWithNoData";
        const firstUser = users.first();
        const groupOfReport = getGroup(groupId);
        const usersWithNoActivities = users
            .filter(user => !usersWithActivities.includes(user.get("fullName")) && !user.get("cube19User"));
        kpiNames.forEach(kpiName => {
          if (usersWithNoActivities.isEmpty()) {
            let newRow = Immutable.fromJS({rowType});
            columns.forEach(column => {
              const colDataType = column.dataType.toLowerCase();
              const colLabel = column.label;
              switch (colDataType) {
                case "string":
                  if (colLabel === "Kpi name") {
                    newRow = newRow.set(colLabel, kpiName);
                  } else if (colLabel === "Owner") {
                    newRow = newRow.set(colLabel, firstUser.get("fullName"));
                  } else if (colLabel === "Group") {
                    newRow = newRow.set(colLabel, groupOfReport.get("name"));
                  }
                  break;
                case "date":
                  newRow = newRow.set(colLabel, dateColumnDefaultValues.get(colLabel));
                  break;
              }
            });

            pivotData = pivotData.push(newRow);
          } else {
            usersWithNoActivities.forEach(user => {
              let newRow = Immutable.fromJS({rowType});
              let group = getGroup(user.get("groupId"));

              columns.forEach(column => {
                const colDataType = column.dataType.toLowerCase();
                const colLabel = column.label;
                switch (colDataType) {
                  case "string":
                    if (colLabel === "Kpi name") {
                      newRow = newRow.set(colLabel, kpiName);
                    } else if (colLabel === "Owner") {
                      newRow = newRow.set(colLabel, user.get("fullName"));
                    } else if (colLabel === "Group") {
                      newRow = newRow.set(colLabel, group.get("name"));
                    }
                    break;
                  case "date":
                    newRow = newRow.set(colLabel, dateColumnDefaultValues.get(colLabel));
                    break;
                }
              });

              pivotData = pivotData.push(newRow);
            });
          }
        });
        return pivotData.toJS();
      });
};

const getErrorType = error => {
  const {responseJSON, status} = error;
  if (status === 504) {
    return "TIMEOUT";
  } else if (responseJSON && responseJSON.type) {
    const errorType = responseJSON.type;
    return errorType ? errorType : "UNHANDLED_ERROR";
  } else {
    return "SERVER_ERROR";
  }
};

const auditError = errorType => {
  if (errorType === "TIMEOUT" || errorType === "TOO_MUCH_DATA") {
    Auditor.audit("main_snd:timeout_error");
  } else if (errorType === "SERVER_ERROR") {
    Auditor.audit("main_snd:server_error");
  } else if (errorType === "DATA_VISIBILITY") {
    Auditor.audit("main_snd:data_visibility_error");
  } else {
    Auditor.audit("main_snd:unhandled_error");
  }
};


export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ErrorBoundaryPage theme={theme} {...props} />;
};
