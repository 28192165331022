import React from "react";
import Immutable from "immutable";

import * as DashboardUtils from "js/dashboards/utils";
import {columnSpacing, dashboardColumns} from "js/dashboards/utils";
import * as SavedConfigs from "js/common/saved-configs";
import {indexBy} from "js/common/utils/collections";
import useBooleanState from "js/common/hooks/use-boolean-state";
import * as Rata from "js/common/utils/remote-data";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Users from "js/common/users";

import uiGridTemplate from "js/dashboards/components/grids/ui-grid";
import asyncServerGridTemplate from "js/dashboards/components/grids/async-server-grid";
import metricCurveTemplate from "js/dashboards/components/metric-curve";
import ratioTemplate from "js/dashboards/components/ratios/ratio";
import ratioComparisonTemplate from "js/dashboards/components/ratio-comparison/ratio-comparison";
import textTemplate from "js/dashboards/components/text";
import dividerTemplate from "js/dashboards/components/divider";
import trendTemplate from "js/dashboards/components/trends/trend";
import performanceSnapshotTemplate from "js/dashboards/components/performance-snapshot/performance-snapshot";

import MenuBar from "js/common/views/menu-bar";
import NovoLoading from "js/dashboards/novo-loading";
import DashboardToolbar from "js/dashboards/dashboard-toolbar";
import DataConfigToolbar from "js/dashboards/data-config-toolbar";
import DashboardDisplay from "js/dashboards/dashboard-display";
import Dialog from "js/common/views/tabs-dialog";
import EasyShareDrawer from "js/common/views/sharing/easy-share-drawer";
import AddComponentDrawer from "js/dashboards/add-component-drawer";
import Overlay from "js/common/views/overlay";
import EditComponentDrawer from "js/dashboards/edit-component-drawer";

import {LicenseManager} from "ag-grid-enterprise";
import {css, Global} from "@emotion/react";

import "css/ag-grid-ba.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import {TextButton} from "js/common/views/inputs/buttons";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import useDimensions from "js/common/utils/use-dimensions";
import * as Auditor from "js/common/auditer";
import DashboardFallback from "js/dashboards/dashboard-fallback";
import * as TagRepo from "js/common/repo/backbone/tag-repo";

LicenseManager.setLicenseKey(
    "CompanyName=Bullhorn, Inc.,LicensedApplication=Bullhorn Analytics,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=6,LicensedProductionInstancesCount=1,AssetReference=AG-031711,SupportServicesEnd=17_August_2023_[v2]_MTY5MjIyNjgwMDAwMA==5ab15b0379b42173ac85c29392608202");

const ownershipTypeToCustomDisplayName = Immutable.fromJS({ownedConfigs: "My Dashboards"});

const globalCss = css`
  body, #content-region {
    height: 100%;
  }

  #content-region > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const DashboardApp = () => {
  const {
    flag: showSavedDashboards,
    setTrue: openSavedDashboardsDrawer,
    setFalse: closeSavedDashboardsDrawer
  } = useBooleanState();

  const [isSavingDashboard, setSavingDashboard] = React.useState(false);
  const [isEditing, setEditing] = React.useState(false);
  const {theme} = React.useContext(CustomThemeContext);
  const componentIdToLoadIdRef = React.useRef(Immutable.Map());

  const [ownershipTypeToDashboardsWrapper, setOwnershipTypeToDashboardsWrapper] = React.useState(
      () => Rata.wrapInitialValue(Immutable.Map()));

  const loadAndSetDashboards = React.useCallback(() => {
    setOwnershipTypeToDashboardsWrapper(wrapper => Rata.toLoading(wrapper));
    loadDashboards().then(result => {
      setOwnershipTypeToDashboardsWrapper(wrapper => Rata.toLoaded(wrapper, result));
    });
  }, []);

  // Sets and removes className for use in gainsight
  React.useEffect(() => {
    document.body.classList.add("dashboards");
    return function cleanup() {
      document.body.classList.remove("dashboards");
    };
  }, []);

  React.useEffect(() => {
    loadAndSetDashboards();
  }, [loadAndSetDashboards]);

  const defaultParentConfig = React.useMemo(() => DashboardUtils.getDefaultParentConfig(), []);

  const [currentDashboard, setCurrentDashboard] = React.useState(null);
  const [latestDashboardConfig, setLatestDashboardConfig] = React.useState(null);
  const [originalDashboard, setOriginalDashboard] = React.useState(null);
  const [dashboardHasUnsavedChanges, setDashboardHasUnsavedChanges] = React.useState(null);
  const currentDashboardConfig = currentDashboard && currentDashboard.getIn(["json", "dataConfig"], Immutable.Map());

  React.useEffect(() => {
    if (currentDashboardConfig) {
      setLatestDashboardConfig(currentDashboardConfig);
    }
  }, [currentDashboardConfig]);

  const parentConfigHasChanged = React.useMemo(() => {
    // NOTE we have an issue with set => list comparisons.
    // There's a workaround in client-filter that can be improved upon but not urgent
    if (currentDashboardConfig && latestDashboardConfig) {
      return !Immutable.is(latestDashboardConfig, currentDashboardConfig);
    } else {
      return false;
    }
  }, [latestDashboardConfig, currentDashboardConfig]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (currentDashboard && originalDashboard) {
        const layoutChanged = JSON.stringify(currentDashboard.getIn(["json", "components"]).toJS()) !== JSON.stringify(
            originalDashboard.getIn(["json", "components"]).toJS());
        const nameChanged = currentDashboard.get("name") !== originalDashboard.get("name");
        setDashboardHasUnsavedChanges(layoutChanged || nameChanged);
      } else {
        setDashboardHasUnsavedChanges(false);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [currentDashboard, originalDashboard]);

  const {queue: queueDataLoad, clear: clearDataLoad} = DashboardUtils.useDataLoadingQueue();
  React.useEffect(() => () => clearDataLoad(), [clearDataLoad]);

  const dashboardWithLatestConfig = React.useMemo(() => {
    if (latestDashboardConfig && currentDashboard) {
      return DashboardUtils.applyParentConfigToDashboard(
          currentDashboard,
          latestDashboardConfig,
          componentTypeToTemplate);
    } else {
      return currentDashboard;
    }
  }, [currentDashboard, latestDashboardConfig]);


  const loadDataForDashboard = React.useCallback(dashboard => {
    DashboardUtils.loadDataForDashboard(
        componentIdToLoadIdRef,
        dashboard,
        queueDataLoad,
        setIdToData,
        componentTypeToTemplate);

  }, [queueDataLoad]);

  const switchToDashboard = React.useCallback((dashboard, ownershipType) => {
    // NOTE must update timeframe to ensure correct start/end dates

    const parentConfigFromDatabase = dashboard
        .getIn(["json", "dataConfig"], Immutable.Map())
        .update(
            "timeframe",
            defaultParentConfig.get("timeframe"),
            timeframe => Immutable.fromJS(TimeframeRepo.parse(timeframe.toJS()).getRawJson()));
    const parentConfigForDashboard = defaultParentConfig.merge(parentConfigFromDatabase);
    dashboard = DashboardUtils
        .applyParentConfigToDashboard(dashboard, parentConfigForDashboard, componentTypeToTemplate);

    // Remove deleted tags
    const validTagIds = Immutable.fromJS(TagRepo.getAll().toJSON()).map(tag => tag.get("id")).toSet();
    const removeDeletedTagsFromTagFilter = tagFilter => {
      if (!tagFilter) {
        return tagFilter;
      }
      return tagFilter
          .update("matchAllTagIds", Immutable.List(), ids => ids.filter(id => validTagIds.contains(id)))
          .update("matchAnyTagIds", Immutable.List(), ids => ids.filter(id => validTagIds.contains(id)))
          .update("excludedTagIds", Immutable.List(), ids => ids.filter(id => validTagIds.contains(id)));
    };
    dashboard = dashboard
        .updateIn(["json", "dataConfig", "tagFilter"], removeDeletedTagsFromTagFilter)
        .updateIn(
            ["json", "components"],
            Immutable.List(),
            components => components.map(component => component.updateIn(
                ["dataConfig", "tagFilter"],
                removeDeletedTagsFromTagFilter)));

    loadDataForDashboard(dashboard);
    setCurrentDashboard(dashboard);
    setLatestDashboardConfig(dashboard.getIn(["json", "dataConfig"]));
    setOriginalDashboard(dashboard);
    setEditing(false);

    Auditor.uniqueAudit("dashboard:loaded", {
      id: dashboard.get("id"),
      name: dashboard.get("name"),
      panelCount: dashboard.getIn(["json", "components"]).count(),
      panels: dashboard.getIn(["json", "components"]).map(c => c.get("type"))
    });
    const dashboardType = dashboardHasUnsavedChanges ? "custom" : ownershipType;
    // eslint-disable-next-line default-case
    switch (dashboardType) {
      case "custom":
        Auditor.audit("dashboards:run-custom-report", {
          dashboardId: dashboard.get("id"),
          dashboardName: dashboard.get("name")
        });
        break;
      case "ownedConfigs":
        Auditor.audit("dashboards:run-saved-report", {
          dashboardId: dashboard.get("id"),
          dashboardName: dashboard.get("name")
        });
        break;
      case "sharedUserConfigs":
      case "sharedGroupConfigs":
        Auditor.audit("dashboards:run-shared-report", {
          dashboardId: dashboard.get("id"),
          dashboardName: dashboard.get("name")
        });
    }
  }, [defaultParentConfig, loadDataForDashboard, dashboardHasUnsavedChanges]);

  React.useEffect(() => {
    if (Rata.isLoaded(ownershipTypeToDashboardsWrapper) && latestDashboardConfig === null) {
      const ownershipTypeToDashboards = Rata.getValue(ownershipTypeToDashboardsWrapper);
      const dashboards = ownershipTypeToDashboards.get("ownedConfigs", Immutable.List())
          .concat(ownershipTypeToDashboards.get("sharedUserConfigs", Immutable.List()))
          .concat(ownershipTypeToDashboards.get("sharedGroupConfigs", Immutable.List()));

      const homeDashboardId = SavedConfigs.getHome("DASHBOARD");
      const idToDashboard = indexBy(x => x.get("id"), dashboards);
      const homeDashboard = idToDashboard.get(homeDashboardId);
      if (homeDashboard) {
        const ownershipType = ownershipTypeToDashboards.findKey((value) => value.includes(homeDashboard));
        switchToDashboard(homeDashboard, ownershipType);
      } else {
        // TODO if user can see no dashboards AND doesn't have full editor then maybe show a message explaining that
        if (dashboards.isEmpty()) {
          switchToDashboard(DashboardUtils.createBlankDashboard(defaultParentConfig), "custom");
        } else {
          const ownershipType = ownershipTypeToDashboards.findKey((value) => value.includes(dashboards.first()));
          switchToDashboard(dashboards.first(), ownershipType);
        }
      }
    }
  }, [switchToDashboard, latestDashboardConfig, ownershipTypeToDashboardsWrapper, defaultParentConfig]);

  const [idToData, setIdToData] = React.useState(Immutable.Map());

  const {flag: showComponentMenu, setTrue: openComponentMenu, setFalse: closeComponentMenu} = useBooleanState();

  const [componentInfoToEdit, setComponentInfoToEdit] = React.useState(null);
  const closeEditComponentDrawer = React.useCallback(() => setComponentInfoToEdit(null), []);

  const handleAddComponent = React.useCallback(newComponent => {
    closeComponentMenu();

    const template = componentTypeToTemplate.get(newComponent.get("type"));

    const components = currentDashboard.getIn(["json", "components"], Immutable.List());
    const maxId = components.map(c => c.get("id")).max() || 0;
    const templateDimensions = template.getIn(["layout", "init"]);

    const bottomOfLastComponent = components
        .map(c => c.getIn(["layout", "y"]) + c.getIn(["layout", "height"]))
        .max() || 0;

    newComponent = newComponent
        .set("id", maxId + 1)
        .set("layout", templateDimensions
            .set("x", 0)
            .set("y", Math.ceil(bottomOfLastComponent)));

    const applyParent = template.get("applyParentConfig");
    newComponent = newComponent.update("dataConfig", dataConfig => applyParent(
        currentDashboard.getIn(["json", "dataConfig"]),
        dataConfig));

    setComponentInfoToEdit({newComponent});
  }, [currentDashboard, closeComponentMenu]);

  const [renderDialogs, openDialog] = useDialogStack();
  const [ref, {width}] = useDimensions();

  const switchToBlankDashboard = React.useCallback(() => {
    switchToDashboard(DashboardUtils.createBlankDashboard(defaultParentConfig), "custom");
  }, [switchToDashboard, defaultParentConfig]);

  const reloadCurrentDashboard = React.useCallback(() => {
    const newDashboard = DashboardUtils.applyParentConfigToDashboard(
        currentDashboard,
        latestDashboardConfig,
        componentTypeToTemplate);
    setCurrentDashboard(newDashboard);

    if (dashboardHasUnsavedChanges) {
      Auditor.audit("dashboards:run-custom-report", {});
    }

    loadDataForDashboard(newDashboard);
  }, [currentDashboard, latestDashboardConfig, loadDataForDashboard]);

  const afterDashboardDeleted = React.useCallback(dashboardId => {
    const name = Rata.getValue(ownershipTypeToDashboardsWrapper)
        .get("ownedConfigs")
        .find(db => db.get("id") === dashboardId).get("name");
    Auditor.audit("dashboards:delete-report", {name});
    setCurrentDashboard(d => {
      if (d.get("id") === dashboardId) {
        return d.delete("id");
      } else {
        return d;
      }
    });
  }, [ownershipTypeToDashboardsWrapper]);

  const handleNameChange = React.useCallback((name) => {
    setCurrentDashboard(currentDashboard.set("name", name));
  }, [currentDashboard]);

  const handleDashboardSave = React.useCallback((savedDashboard) => {
    setOriginalDashboard(savedDashboard);
  }, []);

  const handleDashboardChange = React.useCallback((newDashboardOrFn, {
    reloadComponentId = null,
    reloadDashboard = false
  } = {}) => {
    setCurrentDashboard(oldDashboard => {
      let newDashboard;
      if (typeof newDashboardOrFn === "function") {
        newDashboard = newDashboardOrFn(oldDashboard);
      } else {
        newDashboard = newDashboardOrFn;
      }
      newDashboard = DashboardUtils.applyParentConfigToDashboard(
          newDashboard,
          newDashboard.getIn(["json", "dataConfig"]),
          componentTypeToTemplate);

      if (reloadDashboard) {
        loadDataForDashboard(newDashboard);
      } else if (reloadComponentId) {
        DashboardUtils.loadDataForComponent(
            reloadComponentId,
            componentIdToLoadIdRef,
            newDashboard,
            queueDataLoad,
            setIdToData,
            componentTypeToTemplate);
      }

      return newDashboard;
    });
  }, [componentIdToLoadIdRef, loadDataForDashboard, queueDataLoad]);

  const handleReloadComponent = React.useCallback(componentId => {
    return DashboardUtils.loadDataForComponent(
        componentId,
        componentIdToLoadIdRef,
        currentDashboard,
        queueDataLoad,
        setIdToData,
        componentTypeToTemplate);
  }, [currentDashboard, queueDataLoad]);

  const handleDuplicateComponent = React.useCallback(componentId => {
    const components = currentDashboard.getIn(["json", "components"], Immutable.List());
    const componentIndex = components.findIndex(c => c.get("id") === componentId);
    let component = currentDashboard.getIn(["json", "components", componentIndex]);
    const template = componentTypeToTemplate.get(component.get("type"));
    const title = template.get("getTitle")(component);

    const maxId = components.map(c => c.get("id")).max() || 0;
    const newId = maxId + 1;
    const bottomOfLastComponent = components
        .map(c => c.getIn(["layout", "y"]) + c.getIn(["layout", "height"]))
        .max() || 0;
    const newComponent = component
        .set("id", newId)
        .set("fullScreen", false)
        .update("layout", layout => layout.set("x", 0).set("y", bottomOfLastComponent))
        .update("title", () => title && title + " copy");

    setIdToData(idToData => {
      const initialData = DashboardUtils.getInitialComponentData(newComponent, componentTypeToTemplate);
      return idToData.set(DashboardUtils.getDataId(componentIdToLoadIdRef.current, newComponent), initialData);
    });
    handleDashboardChange(currentDashboard => {
      return currentDashboard
          .updateIn(
              ["json", "components"],
              Immutable.List(),
              components => components.push(newComponent))
          .updateIn(["json", "components", componentIndex], component => component.set("fullScreen", false));
    }, {reloadComponentId: newId});
  }, [currentDashboard, handleDashboardChange]);

  //fix to prevent background scroll when loading a dashboard saved with a fullscreen component
  //or switching to a non-fullscreen dashboard from a fullscreen one
  React.useEffect(() => {
    const components = currentDashboard
        ? currentDashboard.getIn(["json", "components"], Immutable.List())
        : Immutable.List();
    const hasFullSizedComponent = components.some(c => c.get("fullScreen"));
    if (hasFullSizedComponent) {
      DashboardUtils.lockPageScroll();
    } else {
      DashboardUtils.enablePageScroll();
    }
  }, [currentDashboard]);

  const hasNoSharedDashboards = React.useMemo(() => {
    const allDashboards = Rata.getValue(ownershipTypeToDashboardsWrapper);
    return allDashboards.get("sharedUserConfigs")?.isEmpty() &&
        allDashboards.get("sharedGroupConfigs")?.isEmpty();
  }, [ownershipTypeToDashboardsWrapper]);

  const hasPanels = React.useMemo(() => {
    return !dashboardWithLatestConfig?.getIn(["json", "components"]).isEmpty();
  }, [dashboardWithLatestConfig]);

  const showFullEditorFallback = React.useMemo(() => {
    return Users.currentHasPermission("DASHBOARD_FULL_EDITOR") && !hasPanels && !isEditing;
  }, [hasPanels, isEditing]);

  const showSharedFallback = React.useMemo(() => {
    return !Users.currentHasPermission("DASHBOARD_FULL_EDITOR") && !hasPanels && hasNoSharedDashboards && !isEditing;
  }, [hasPanels, isEditing, hasNoSharedDashboards]);

  return <div>
    <Global styles={globalCss} />
    <MenuBar appView="dashboards" />

    {isSavingDashboard && <Overlay content="Saving Dashboard..." />}
    <DashboardToolbar
        dashboard={dashboardWithLatestConfig}
        onDashboardChange={handleDashboardChange}
        handleDashboardSave={handleDashboardSave}

        ownershipTypeToDashboardsWrapper={ownershipTypeToDashboardsWrapper}
        setOwnershipTypeToDashboardsWrapper={setOwnershipTypeToDashboardsWrapper}

        isEditing={isEditing}
        setEditing={setEditing}
        setSavingDashboard={setSavingDashboard}
        hasUnsavedChanges={parentConfigHasChanged || dashboardHasUnsavedChanges}

        onRequestOpenComponentMenu={openComponentMenu}
        onRequestOpenSavedDashboards={openSavedDashboardsDrawer}

        handleNameChange={handleNameChange}

        onRequestReloadDashboard={reloadCurrentDashboard}
        onRequestNewDashboard={switchToBlankDashboard} />
    {currentDashboard
        ? <>
          {showFullEditorFallback && <DashboardFallback variant="create" />}
          {showSharedFallback && <DashboardFallback variant="share" />}
          {!showSharedFallback && !showFullEditorFallback &&
              <div style={{marginLeft: "1.5rem", marginRight: "1.5rem"}}>
                <DataConfigToolbar
                    topLevel
                    allowInherit={false}
                    label="Top level filters"
                    config={latestDashboardConfig}
                    onConfigChange={(config) => setLatestDashboardConfig(config)} />
              </div>
          }
          <div
              ref={ref}
              style={{
                position: "relative",
                margin: "0 auto",
                height: "100%",
                minWidth: 1280,
                maxWidth: 2200,
                overflowX: "auto"
              }}
          >
            {parentConfigHasChanged && <div
                style={{
                  background: theme.themeId === "light" ? "rgba(241, 241, 241, 0.92)" : "rgba(50, 50, 63, 0.92)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  position: "absolute",
                  paddingTop: "160px",
                  top: 10,
                  left: 0,
                  width: "100%",
                  zIndex: 1,
                  height: "100%"
                }}>
              <div style={{textAlign: "center"}}>

                <p style={{marginBottom: 0}}>You've updated your dashboard filters.</p><br />
                <div>
                  <TextButton
                      type="secondary"
                      label="Cancel"
                      onClick={() => setLatestDashboardConfig(currentDashboard.getIn(["json", "dataConfig"]))}
                      style={{
                        fontSize: "12px",
                        border: `1px solid ${theme.palette.text.main}`,
                        color: theme.palette.text.main,
                        marginRight: "1rem"
                      }} />
                  <TextButton
                      type="primary"
                      label="Load Latest Data"
                      onClick={() => reloadCurrentDashboard()}
                      style={{fontSize: "12px"}} />
                </div>
              </div>
            </div>}
            <DashboardDisplay
                componentIdToLoadId={componentIdToLoadIdRef.current}
                dashboard={currentDashboard}
                onDashboardChange={handleDashboardChange}
                onDuplicateClick={handleDuplicateComponent}
                isEditing={isEditing}
                queueDataLoad={queueDataLoad}
                clearDataLoad={clearDataLoad}
                onRequestDialog={openDialog}
                onRequestComponentReload={handleReloadComponent}
                onRequestEdit={setComponentInfoToEdit}
                idToData={idToData}
                rowHeight={(width / dashboardColumns) - columnSpacing}
                componentTypeToTemplate={componentTypeToTemplate} />
            <EditComponentDrawer
                open={!!componentInfoToEdit}
                onRequestClose={closeEditComponentDrawer}
                componentInfo={componentInfoToEdit}
                dashboard={currentDashboard}
                onDashboardChange={handleDashboardChange}
                componentTypeToTemplate={componentTypeToTemplate} />
          </div>
        </>
        : <div ref={ref}><NovoLoading /></div>}

    <EasyShareDrawer
        open={showSavedDashboards}
        onRequestClose={closeSavedDashboardsDrawer}
        headerLabel="Dashboards"
        configType="DASHBOARD"
        ownershipTypeToCustomDisplayName={ownershipTypeToCustomDisplayName}
        ownershipTypeToConfigsWrapper={ownershipTypeToDashboardsWrapper}
        setOwnershipTypeToConfigsWrapper={setOwnershipTypeToDashboardsWrapper}
        onSelect={switchToDashboard}
        onRemoved={afterDashboardDeleted}
        onRequestReload={loadAndSetDashboards}
        isEditSharingPermissionEnabled={true}
        isSharingEnabled={Users.currentHasPermission("SHARE_DASHBOARD")}
        isSharingReadOnly={!Users.currentHasPermission("DASHBOARD_FULL_EDITOR")}
    />

    <AddComponentDrawer
        open={showComponentMenu}
        onRequestClose={closeComponentMenu}
        onAddComponent={handleAddComponent} />

    {renderDialogs()}
  </div>;
};

const useDialogStack = () => {
  const [dialogStack, setDialogStack] = React.useState(Immutable.List());

  const closeDialog = React.useCallback(id => {
    setDialogStack(dialogStack => dialogStack.filter(dialog => dialog.id !== id));
  }, []);

  const openDialog = React.useCallback((content, {
    id,
    requireExistingId = false,
    title,
    width,
    minWidth,
    height,
    minHeight,
    titleStyle
  } = {}) => {
    id = id || Math.random();
    setDialogStack(dialogStack => {
      if (requireExistingId) {
        return dialogStack.map(dialog => {
          if (dialog.id === id) {
            title = title || dialog.title;
            return {id, content, title, width, minWidth, height, minHeight, titleStyle};
          } else {
            return dialog;
          }
        });
      } else {
        return dialogStack.push({id, content, title, width, minWidth, height, minHeight, titleStyle});
      }
    });
    return () => closeDialog(id);
  }, [closeDialog]);

  const renderDialogs = React.useCallback(
      () => <>
        {dialogStack
            .map(({id, title, content, width, minWidth, height, minHeight, titleStyle}) => <Dialog
                key={id}
                width={width}
                minWidth={minWidth}
                height={height}
                minHeight={minHeight}
                titleStyle={titleStyle}
                label={title}
                content={content}
                onRequestClose={() => closeDialog(id)} />)
            .toArray()}
      </>,
      [dialogStack, closeDialog]);


  return [renderDialogs, openDialog, closeDialog];
};

const componentTypeToTemplate = indexBy(
    x => x.get("type"),
    Immutable.List.of(
        trendTemplate,
        uiGridTemplate,
        asyncServerGridTemplate,
        metricCurveTemplate,
        ratioTemplate,
        ratioComparisonTemplate,
        textTemplate,
        performanceSnapshotTemplate,
        dividerTemplate));

const loadDashboards = () => SavedConfigs.getAll("DASHBOARD");

export default DashboardApp;
