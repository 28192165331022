import Immutable from "immutable";
import * as Colors from "js/common/cube19-colors";
import {squidLight} from "js/squids/styles/light";
import {futuristicSquid} from "js/squids/styles/futuristic";
import {defaultSquid} from "js/squids/styles/default";

const baseNodeStyle = Immutable.fromJS({
  width: 300,
  height: 60,
  fontSize: "0.8rem",
  borderRadius: 2,
  textAlign: "center",
  padding: 0,
  display: "flex",
  alignItems: "center",
  border: "2px solid transparent",
  color: Colors.accentBlue,
  backgroundColor: Colors.white,
  opacity: 1
});

const themes = Immutable
    .fromJS({
      default: defaultSquid,
      light: squidLight,
      futuristic: futuristicSquid
        }
    ).map(theme => {
      return theme.update("node", nodeStyles => nodeStyles.map(s => {
            if (s.get("inheritFromBase", true)) {
              return baseNodeStyle.merge(s);
            } else {
              return s;
            }
          }
      ))
    });


const getNodeStyle = (themeId, nodeStyleId, isErroring) => {
  let style = themes.has(themeId)
      ? themes.getIn([themeId, "node", nodeStyleId])
      : baseNodeStyle;
  if(isErroring){
    return style
        .set("backgroundColor", Colors.lightestRed)
        .set("color", Colors.c19Red);
  }
  else {
    return style
  }
};

const getNodeValueStyle = (themeId) => {
  return themes.has(themeId) ? themes.getIn([themeId,"nodeValueStyle"], Immutable.Map()) : Immutable.Map();
};

const getNodeHandleStyle = (themeId) => {
  return themes.has(themeId) ? themes.getIn([themeId, "nodeHandleStyle"]) : null
};

const getEdgeStyle = (themeId) => {
  return themes.has(themeId) ? themes.getIn([themeId, "edge"]) : themes.getIn(["default", "edge"]);
};

const getLabelStyle = (themeId, isErroring) => {
  let style = themes.has(themeId) ? themes.getIn([themeId, "label"]) : themes.getIn(["default", "label"]);

  if(isErroring){
    return style
        .setIn(["labelStyle", "color"], Colors.c19Red)
        .setIn(["labelBgStyle", "border"], `1px solid ${Colors.c19Red}`);
  }
  else {
    return style;
  }
};

const getPageStyle = (themeId) => {
  return themes.has(themeId) ? themes.getIn([themeId, "page"]) : themes.getIn(["default", "page"])
};

const getStepStyle = (themeId) => {
  return themes.has(themeId) ? themes.getIn([themeId, "steps"]) : null;
};

const getControlsStyle = (themeId) => {
  return themes.has(themeId) ? themes.getIn([themeId, "controls"]) : null;
};

const getIdToNodeStyle = (themeId) => themes.getIn([themeId, "node"]);

const getThemes = () => themes.keySeq().toList();

export {
  getNodeStyle,
  getNodeValueStyle,
  getNodeHandleStyle,
  getEdgeStyle,
  getLabelStyle,
  getPageStyle,
  getStepStyle,
  getControlsStyle,
  getIdToNodeStyle,
  getThemes
};
