import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";

import DropdownToggle from "js/common/views/react-dropdown-toggle";
import GroupAndUserPicker from "js/common/views/inputs/group-and-user-picker/filterable-group-user-tree";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
const _ = window._;

const containerStyle = theme => ({
  position: "absolute",
  zIndex: 9999,
  width: "100%",
  maxHeight: "400px",
  overflow: "auto",
  backgroundColor: theme.palette.background.card,
  boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)"
});

const DropdownUserGroupPicker = createReactClass({

  propTypes: {
    selectedGroupIds: ImmutablePropTypes.set,
    selectedUserIds: ImmutablePropTypes.set,
    excludedGroupIds: ImmutablePropTypes.set,
    onGroupClick: ReactPropTypes.func,
    onUserClick: ReactPropTypes.func,
    qualifierId: ReactPropTypes.number,
    qualifierType: ReactPropTypes.string,
    label: ReactPropTypes.string,
    prefix: ReactPropTypes.string,
    isDisabled: ReactPropTypes.bool,
    excludeUsers: ReactPropTypes.bool,
    showInvisibleUsers: ReactPropTypes.bool,
    showDeletedGroups: ReactPropTypes.bool,
    showLoggedInUserOptions: ReactPropTypes.bool,
    disableChildrenOfSelectedGroups: ReactPropTypes.bool,
    hierarchy: ReactPropTypes.object,
    onClose: ReactPropTypes.func,
    closeOnSelect: ReactPropTypes.bool,
    breadcrumbMode: ReactPropTypes.bool
  },

  getDefaultProps() {
    return {
      selectedUserIds: Immutable.Set(),
      selectedGroupIds: Immutable.Set(),
      onGroupClick: () => {},
      onUserClick: () => {},
      isDisabled: false,
      excludeUsers: false,
      showInvisibleUsers: false,
      showLoggedInUserOptions: false,
      closeOnSelect: true,
      breadcrumbMode: false,
      onClose: () => {}
    };
  },

  getInitialState() {
    return {
      isOpen: false
    };
  },

  render() {
    const {
      selectedGroupIds,
      selectedUserIds,
      excludedGroupIds,
      hierarchy,
      qualifierId,
      qualifierType,
      isDisabled,
      excludeUsers,
      showInvisibleUsers,
      showDeletedGroups,
      showLoggedInUserOptions,
      disableChildrenOfSelectedGroups,
      label,
      prefix,
      fullTheme,
      breadcrumbMode
    } = this.props;
    return (
        <DropdownToggle
            primaryText={getLabel(qualifierType, qualifierId, excludeUsers, label, prefix, breadcrumbMode)}
            isDisabled={isDisabled}
            disableEllipsis={false}
            tooltipText={breadcrumbMode && customBread(qualifierId, ">").fullPath}
            isOpen={this.state.isOpen}
            onToggleButtonClick={this.toggleDropdown}
            onRequestClose={this.closeDropdown}>
          <GroupAndUserPicker
              theme={fullTheme}
              selectedGroupIds={selectedGroupIds}
              selectedUserIds={selectedUserIds}
              excludedGroupIds={excludedGroupIds}
              hierarchy={hierarchy}
              excludeUsers={excludeUsers}
              showInvisibleUsers={showInvisibleUsers}
              showDeletedGroups={showDeletedGroups}
              showLoggedInUserOptions={showLoggedInUserOptions}
              disableChildrenOfSelectedGroups={disableChildrenOfSelectedGroups}
              qualifierId={qualifierId}
              qualifierType={excludeUsers ? "GROUP" : qualifierType}
              onGroupClick={this.handleGroupClick}
              onUserClick={this.handleUserClick}
              customContainerStyle={containerStyle(fullTheme)} />
        </DropdownToggle>
    );
  },

  toggleDropdown() {
    if (this.state.isOpen) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  },

  openDropdown() {
    this.setState({isOpen: true});
  },

  closeDropdown() {
    this.setState({isOpen: false});
  },

  handleGroupClick(groupId) {
    this.props.onGroupClick(groupId);
    if (this.props.closeOnSelect) {
      this.closeDropdown();
    }
  },

  handleUserClick(userId) {
    this.props.onUserClick(userId);
    if (this.props.closeOnSelect) {
      this.closeDropdown();
    }
  }

});

const getBread = (groupId) => {
  let breadcrumbs = [];
  let group = Groups.getGroup(groupId);
  if (!group) {
    breadcrumbs.push({
      id: "ERROR",
      name: "ERROR: Group Not Found"
    });
    return breadcrumbs;
  } else {
    do {
      breadcrumbs.push({
        id: group.id,
        name: group.get("name")
      });
      group = Groups.getGroup(group.get("parentId"));
    } while (group);

    const bread = breadcrumbs.reverse();
    if (bread.length > 1) {
      bread.shift();
    }
    return bread;
  }
};

const customBread = (groupId, delimiter, maxWidth = 400, charWidth = 15) => {
  const delimiterStr = delimiter || "&#9654;";
  const breadcrumbs = getBread(groupId);
  if (!breadcrumbs) {
    return "ERROR: Group Not Found";
  } else {
    let breadcrumbsStr = "";
    let fullPathStr = "";
    _.each(breadcrumbs, function(breadcrumb) {
      const firstBreadcrumb = _.first(breadcrumbs);
      if (breadcrumb.id === firstBreadcrumb.id) {
        breadcrumbsStr = breadcrumb.name;
        fullPathStr = breadcrumb.name;
      } else {
        const stringAddition = `${delimiterStr} ${breadcrumb.name}`;
        breadcrumbsStr = `${breadcrumbsStr} ${stringAddition}`;
        fullPathStr = `${fullPathStr} ${stringAddition}`;
        if (breadcrumbsStr.length > maxWidth / charWidth) {
          const split = breadcrumbsStr.split(delimiterStr);
          if (split.length > 2) {
            const firstItem = split.shift(); // remove last
            const lastItem = split.pop(); // remove first
            const middleItems = split.map(() => "...").join(delimiterStr);
            breadcrumbsStr = `${firstItem}${delimiterStr}${middleItems}${delimiterStr}${lastItem}`;
          }
          ;
        }
      }
    });

    return {
      fullPath: fullPathStr,
      breadCrumb: breadcrumbsStr
    };
  }
};

const getLabel = (qualifierType, qualifierId, excludeUsers, overrideLabel, prefix = "", breadcrumbMode) => {
  qualifierType = excludeUsers ? "GROUP" : qualifierType;

  if (qualifierId === 0) {
    switch (qualifierType) {
      case "USER":
        return prefix + "Logged in User";
      case "GROUP":
        return prefix + "Logged in User's Group";
    }
  } else if (qualifierId && qualifierType) {
    switch (qualifierType) {
      case "GROUP":
        if (breadcrumbMode) {
          return customBread(qualifierId, ">").breadCrumb;
        } else {
          return prefix + Groups.getGroupBreadcrumbsStr(qualifierId, ">");
        }
      case "USER":
        const user = Users.getUser(qualifierId);
        return prefix + user.get("fullName");
    }
  } else {
    const defaultLabel = excludeUsers ? "Choose a Group" : "Choose a User or Group";
    return prefix + (overrideLabel || defaultLabel);
  }
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <DropdownUserGroupPicker fullTheme={theme} {...props} />;
};

