import React from "react";
import ReactSelect from "js/common/views/inputs/immutable-react-select";
import Immutable from "immutable";
import pure from "js/common/views/pure";
import { capitaliseWords } from "js/common/utils/strings";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const DataAggregationPicker = pure(({
    dataAggregations = [],
    value,
    onChange,
    style = {},
    theme
}) => {
    const options = toOptions(dataAggregations)
    return <div style={style}>
        <div style={{
            display: "inline-block",
            width: theme.themeId === "light" ? 115: 105,
            height: 36,
            lineHeight: "36px",
            verticalAlign: "top",
            color: theme.palette.textColor,
            fontSize: "0.875rem"
        }}>
            Aggregate Data
        </div>
        <div style={{display: "inline-block", width: 115}} data-test-id={'aggregate-data'}>
            <ReactSelect
                placeholder="Select aggregation"
                isMulti={false}
                options={options}
                selectedValue={value}
                onChange={newOption => onChange(newOption)}
                isSearchable={false}
                isClearable={false}
                />
        </div>
    </div>
});

const toOptions = dataAggregations => Immutable.fromJS(dataAggregations.map(dataAggregation => ({
    label: getLabelForDataAggregation(dataAggregation),
    value: dataAggregation
})));

const getLabelForDataAggregation = value => {
    const dataGroupingByLabel = {
        FINANCIAL_QUARTERLY: "Quarterly (Financial)",
        FINANCIAL_YEARLY: "Yearly (Financial)"
    };
    return dataGroupingByLabel[value] || capitaliseWords(value.replace(/_/g, " "));
};


export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <DataAggregationPicker theme={theme} {...props} />;
  }