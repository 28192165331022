import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';

import { Row, Column } from "js/common/views/foundation-column-layout";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const LoadingScreen = createReactClass({

    propTypes: {
        text: ReactPropTypes.string
    },

    getDefaultProps() {
        return {
            text: "Loading Data"
        };
    },

    render() {
        return (
            <Row style={{ paddingTop: "6.25vh" }}>
                <Column small={10} smallCentered={true} medium={5}>
                    <p className="novo-loading-text" style={{ fontSize: window.innerWidth < 800 ? "1.15rem" : "1.35rem", fontWeight: "bold", letterSpacing: "2px", textTransform: "uppercase" }}>
                        {this.props.text}
                    </p>
                    {this.props.theme.themeId === "light" ?
                        <div className="novo-loading">
                            <span className="dot"/>
                            <span className="dot"/>
                            <span className="dot"/>
                            <span className="dot"/>
                            <span className="dot"/>
                        </div>
                            :
                        <div className="loading-cube" />
                    }
                </Column>
            </Row>);
    }

});

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <LoadingScreen theme={theme} {...props} />;
};
