import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

export default (fn, displayName) => createReactClass({
  mixins: [PureRenderMixin],
  displayName: displayName,

  render() {
    return fn(this.props);
  }

});
