import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";

import TrendConfig from "js/oneview/trends/trend-config";
import TrendChart from "js/oneview/trends/trend-chart";
import LoadingSpinner from "js/common/views/loading-spinner";
import * as charting from "js/charting/charting-defaults";
import {fullReloadForTrendChart} from "js/charting/trend/data-loader";
import {getDefaultColour} from "js/charting/trend/target-trend-color-util";
import {between} from "js/common/utils/numbers";
import currentClient from "js/common/repo/backbone/current-client";
import * as Users from "js/common/users";
import * as kpiRepo from "../../common/repo/backbone/kpi-repo";

export default createReactClass({
  displayName: "trendCT",
  propTypes: {
    timeframe: ReactPropTypes.object.isRequired,
    qualifierType: ReactPropTypes.string.isRequired,
    qualifierId: ReactPropTypes.number.isRequired,
    kpiId: ReactPropTypes.number.isRequired,
    clientIds: ReactPropTypes.array
  },

  getInitialState() {
    const {
      timeframe,
      clientIds,
      qualifierType,
      qualifierId,
      kpiId,
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds
    } = this.props;
    const defaultChart = charting.getDefaultConfigForChartType("TREND", "Trend Chart 1");

    const kpi = kpiRepo.get(kpiId);
    const isKpiAggregable = kpi.get("trendDataAggregable");

    return {
      isLoading: true,
      trends: [],
      config: {
        ...defaultChart,
        timeframe: timeframe.getRawJson(),
        clientIds,
        matchAnyTagIds,
        matchAllTagIds,
        excludedTagIds,
        kpiIds: [kpiId],
        qualifierType,
        qualifierId,
        dataAggregation: isKpiAggregable ? this.getInitialDataAggregation() : "DAILY"
      }
    };
  },

  componentDidMount() {
    this.loadChartFromConfig(this.state.config);
  },

  render() {
    const {isLoading} = this.state;
    return (
        <div style={{height: "100%", overflowY: "auto", overflowX: "hidden"}}>
          {this.renderChartConfigOptions()}
          {!isLoading ? this.renderChart() : this.renderLoadingSpinner()}
        </div>);
  },

  loadChartFromConfig(config) {
    const client = currentClient;
    const hasAdvancedCharting = client.hasPermission("ADVANCED_TREND_CHARTING");
    const chartConfig = {
      ...config,
      trendType: hasAdvancedCharting ? "ADVANCED" : "STANDARD"
    };
    if (hasAdvancedCharting) {
      chartConfig.dataGroups = getDefaultDataGroup(config);
    }
    const {promiseForFinalData} = fullReloadForTrendChart(chartConfig);
    promiseForFinalData.then(data => {
      let trends = [];
      const kpiTrendData = data.trends[0];
      trends.push(kpiTrendData);

      const kpiTargetTrendData = data.trends[1];
      const hasTargets = kpiTargetTrendData && kpiTargetTrendData.trend.some(d => d.value > 0);
      const hasClientFilter = config.clientIds && config.clientIds.length > 0;
      if (hasAdvancedCharting && !hasClientFilter && hasTargets) {
        trends.push(kpiTargetTrendData);
      }

      this.setState({
        isLoading: false,
        config: chartConfig,
        trends: trends
      });
    });
  },

  renderLoadingSpinner() {
    return (
        <div style={{margin: "3rem"}}>
          <LoadingSpinner label="Loading Trend Chart" iconSize={3} />
        </div>
    );
  },

  renderChart() {
    return (
        <TrendChart
            trends={this.state.trends}
            config={this.state.config}
            startDate={this.props.timeframe.get("start")}
            endDate={this.props.timeframe.get("end")} />);
  },

  renderChartConfigOptions() {
    const {config} = this.state;
    const {kpiId, timeframe, qualifierType, qualifierId} = this.props;
    const isUserOneView = qualifierType === "USER";
    const groupId = isUserOneView ? Users.getUser(qualifierId).get("groupId") : qualifierId;
    const currentOneViewConfig = {
      kpiId,
      timeframe,
      groupId,
      qualifierType,
      qualifierId
    };
    return (
        <TrendConfig
            config={config}
            currentOneViewConfig={currentOneViewConfig}
            onConfigChange={this.handleConfigChange} />
    );
  },

  handleConfigChange(changedConfig) {
    const oldConfig = this.state.config;
    const newConfig = {
      ...oldConfig,
      ...changedConfig
    };
    this.setState({
      isLoading: true,
      config: newConfig
    });
    this.loadChartFromConfig(newConfig);
  },

  getInitialDataAggregation() {
    const start = this.props.timeframe.get("start");
    const end = this.props.timeframe.get("end");
    const noOfDays = end.diff(start, "days") + 1;
    let dataAggregation;

    if (between(noOfDays, 1, 31)) {
      dataAggregation = "DAILY";
    }
    if (between(noOfDays, 31, 180)) {
      dataAggregation = "WEEKLY";
    }
    if (between(noOfDays, 180, 365 * 2)) {
      dataAggregation = "MONTHLY";
    }
    if (between(noOfDays, 365 * 2, 365 * 5)) {
      dataAggregation = "QUARTERLY";
    }
    if (noOfDays > 365 * 5) {
      dataAggregation = "YEARLY";
    }

    return dataAggregation;
  }
});

const getDefaultDataGroup = config => {
  const kpiId = config.kpiIds[0];
  const index = 0;
  const dataGroupIndex = 0;
  const chartTheme = "DEFAULT";
  const seriesColour = getDefaultColour(index, dataGroupIndex, chartTheme);
  const primaryWhatToPlot = config.averageByUser ? "average-by-user" : "value";
  const secondaryWhatToPlot = "target";
  const howToPlot = config.displayDataCumulatively ? "cumulative" : "non-cumulative";
  return [
    {
      cid: Math.random(),
      name: "Data Group 1",
      timeframe: config.timeframe,
      trendConfigs: [
        {
          cid: Math.random(),
          type: "KPI",
          kpiId,
          whatToPlot: primaryWhatToPlot,
          seriesColour,
          hasCustomColor: true,
          lineStyle: "Solid",
          dataPointMarker: null,
          howToPlot,
          yAxis: 0
        }, {
          cid: Math.random(),
          type: "KPI",
          kpiId,
          whatToPlot: secondaryWhatToPlot,
          seriesColour,
          hasCustomColor: true,
          lineStyle: "ShortDash",
          dataPointMarker: null,
          howToPlot,
          yAxis: 0
        }],
      qualifierType: config.qualifierType,
      qualifierId: config.qualifierId,
      matchAnyTagIds: config.matchAnyTagIds,
      matchAllTagIds: config.matchAllTagIds,
      excludedTagIds: config.excludedTagIds,
      clientIds: config.clientIds,
      dateDisplay: "ABSOLUTE"
    }];
};
