import moment from "moment";

import {getQuarterDto} from "js/common/utils/financial-quarter-deriver";

const dayOfWeekColumnDeriver = columnName => record => {
    const dateStr = record[columnName];
    const date = moment(dateStr, "YYYY/MM/DD");
    return date.isValid() ? date.format("dddd") : "";
};

const customDateBinDeriver = (col, formatString, startingMonthStr) => {
    const startingMonth = parseInt(moment().month(startingMonthStr).format("M"), 10);
    return record => {
        let date = moment(record[col], "YYYY/MM/DD");
        if (!date.isValid()) {
            return "";
        } else {
            const quarterDto = getQuarterDto(date, startingMonth);
            const formattedDate = formatString.replace(/%(.)/g, (m, p) => {
                switch (p) {
                case "y":
                    const january = 1;
                    const isCalendarYear = startingMonth === january;
                    if ((formatString === "%y %q" || formatString === "%y") && !isCalendarYear) {
                        return quarterDto.year + "/" + quarterDto.nextYear;
                    }
                    return date.get("year");
                case "q":
                    return `Q${quarterDto.quarter}`;
                case "m":
                    const monthNumber = date.get("month") + 1;    // months are zero indexed in moment.js
                    return ("0" + monthNumber).substr(-2, 2);
                case "w":
                    return `W${date.isoWeek()}`;
                default:
                    return `%${p}`;
                }
            });
            return formattedDate;
        }
    };
};

export {
    dayOfWeekColumnDeriver,
    customDateBinDeriver
};