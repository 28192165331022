import React from "react";
import Immutable from "immutable";

import useDimensions from "js/common/utils/use-dimensions";
import * as SavedConfigs from "js/common/saved-configs";
import * as Popups from "js/common/popups";
import * as Auditor from "js/common/auditer";
import * as Rata from "js/common/utils/remote-data";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as DashboardUtils from "js/dashboards/utils";
import TextField from "js/common/views/inputs/text-field";
import Tooltip from "js/common/views/tooltips";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {TextButton} from "js/common/views/inputs/buttons";
import {FormControlLabel, Switch} from "@mui/material";
import SaveReportAsDialog from "js/common/views/save-as-dialog";

const renderUnsavedChangedIndicator = (theme, isFullEditor) => {
  return (
      <Tooltip
          width={200}
          styleType={theme.themeId === "light" ? "dark" : "light"}
          text="You have unsaved changes."
          customStyle={{top: -20}}
          position={isFullEditor ? "bottom" : "right"}>
                    <span
                        data-test-id="unsaved-changes-indicator"
                        style={
                          {
                            height: 15,
                            width: 15,
                            borderRadius: "50%",
                            background: theme.palette.error.main,
                            position: "absolute",
                            right: 2,
                            top: -20
                          }
                        } />
      </Tooltip>
  );
};

const DashboardToolbar = React.memo(({
      dashboard,
      onDashboardChange,
      setSavingDashboard,
      hasUnsavedChanges,
      handleDashboardSave,

      isEditing,
      setEditing,

      ownershipTypeToDashboardsWrapper,
      setOwnershipTypeToDashboardsWrapper,

      onRequestOpenSavedDashboards,
      onRequestOpenComponentMenu,

      onRequestReloadDashboard,
      onRequestNewDashboard,
      handleNameChange
    }) => {
      const {isFullEditor, isBasicEditor} = DashboardUtils.useEditorFlags();

      const [showSaveAsDialog, setShowSaveAsDialog] = React.useState(false);

      const handleSaveAsRequest = React.useCallback(
          dashboardName => {
            setShowSaveAsDialog(false);
            setSavingDashboard(true);
            SavedConfigs
                .create(dashboard.set("name", dashboardName))
                .then(
                    savedDashboard => {
                      Popups.success(`'<strong>${dashboardName}</strong>' saved`);
                      Auditor.audit("dashboards:save-as", {dashboardName});
                      handleDashboardSave(savedDashboard);
                      setSavingDashboard(false);
                      onDashboardChange(savedDashboard);
                      setOwnershipTypeToDashboardsWrapper(wrapper => Rata
                          .updateValue(wrapper, x => x
                              .update("ownedConfigs", ownedConfigs => ownedConfigs.push(savedDashboard))));
                    },
                    err => {
                      const errorMsg = "Unable to save dashboard";
                      if (err.responseJSON && err.responseJSON.message) {
                        Popups.alert(err.responseJSON.message, {title: errorMsg});
                      } else {
                        Popups.error(errorMsg);
                      }
                    });
          },
          [
            dashboard,
            handleDashboardSave,
            onDashboardChange,
            setOwnershipTypeToDashboardsWrapper,
            setSavingDashboard,
            setShowSaveAsDialog]);

      const handleSaveRequest = React.useCallback(() => {
        const id = dashboard.get("id");
        const name = dashboard.get("name");
        setSavingDashboard(true);
        SavedConfigs
            .update(dashboard)
            .then(
                savedDashboard => {
                  Popups.success(`'<strong>${name}</strong>' updated`);
                  Auditor.audit("dashboards:save", {id, name});
                  handleDashboardSave(savedDashboard);
                  setSavingDashboard(false);
                  onDashboardChange(savedDashboard);
                  setOwnershipTypeToDashboardsWrapper(wrapper => {
                    const index = Rata.getValue(wrapper)
                        .get("ownedConfigs")
                        .findIndex(ownedConfig => ownedConfig.get("id") === id);
                    return Rata.updateValue(wrapper, x => x
                        .update("ownedConfigs", ownedConfigs => ownedConfigs.set(index, savedDashboard)));
                  });
                },
                err => {
                  const errorMsg = "Unable to save dashboard";
                  if (err.responseJSON && err.responseJSON.message) {
                    Popups.alert(err.responseJSON.message, {title: errorMsg});
                  } else {
                    Popups.error(errorMsg);
                  }
                });
      }, [dashboard, onDashboardChange, handleDashboardSave, setOwnershipTypeToDashboardsWrapper, setSavingDashboard]);

      const {theme} = React.useContext(CustomThemeContext);

      const [dimRef, dimensions] = useDimensions();
      const {width} = dimensions;
      const smallResolution = width <= 1024;

      const dashboardGroupPermission = React.useMemo(() => {
        const sharedDashboardGroups = Rata.getValue(ownershipTypeToDashboardsWrapper);

        if (Rata.isLoaded(ownershipTypeToDashboardsWrapper)) {
          const currentUser = Users.getCurrentUser();
          const currentUsersGroupId = currentUser.get("groupId");
          const groupHierarchy = Groups.getGroupBreadcrumbs(currentUsersGroupId).map(g => g.id).reverse();
          const sharedGroupConfigs = sharedDashboardGroups
              .get("sharedGroupConfigs")
              .find(config => config.get("id") === dashboard?.get("id"));

          if (!sharedGroupConfigs) {
            return null;
          }

          const sharedWithGroups = sharedGroupConfigs.getIn(["sharedWith", "groups"]);

          for (let groupId of groupHierarchy) {
            const group = sharedWithGroups.find(g => g.get("id") === groupId);
            if (group) {
              return group.get("permission");
            }
          }

          return null;
        }
      }, [dashboard, ownershipTypeToDashboardsWrapper]);

      const dashboardUserPermission = React.useMemo(() => {
        const sharedDashboardGroups = Rata.getValue(ownershipTypeToDashboardsWrapper);

        if (Rata.isLoaded(ownershipTypeToDashboardsWrapper)) {
          return sharedDashboardGroups
              .get("sharedUserConfigs")
              .find(config => config.get("id") === dashboard?.get("id"))
              ?.getIn(["sharedWith", "users"])
              .find(g => g.get("id") === Users.getCurrentUser().get("id"))
              ?.get("permission");
        }
      }, [dashboard, ownershipTypeToDashboardsWrapper]);

      const userHasPermission = React.useMemo(() => {
        return (dashboardUserPermission === "EDIT" ||
            (dashboardGroupPermission
                === "EDIT"
                && dashboardUserPermission
                !== "VIEW"
            )) && isFullEditor;
      }, [dashboardUserPermission, dashboardGroupPermission, isFullEditor]);

      const isDashboardOwner = dashboard?.get("ownerId") === Users.getCurrentUser().get("id");
      const isDashboardOwnerOrEditor = isDashboardOwner || userHasPermission;

      return <div
          ref={dimRef}
          style={{
            backgroundColor: theme.palette.background.card,
            boxShadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px",
            display: "flex",
            padding: "0.8rem 1.5rem",
            marginBottom: "1rem"
          }}>
        <div style={{flex: 1, display: "flex", alignItems: "center"}}>
          {isFullEditor && <TextButton
              testId="new-dashboard-button"
              style={{marginRight: "0.5rem", fontSize: "12px", paddingRight: 15, paddingLeft: 15}}
              type="inverted"
              label={`${smallResolution ? "" : "New"}`}
              iconTyp="bhi"
              icon="plus"
              onClick={onRequestNewDashboard} />}
          <div style={{position: "relative"}}>
            {!isDashboardOwnerOrEditor ?
                <Tooltip
                    width={200}
                    styleType={theme.themeId === "light" ? "dark" : "light"}
                    wrap={true}
                    pointerStyle={{top: "20%"}}
                    text={`This dashboard is owned by ${Users.getUser(dashboard?.get("ownerId"))
                        ?.get("fullName")} and can only be edited by them or users who have been granted Edit access. Use Save As instead.`}
                    position="right">
                  <TextButton
                      disabled={!isDashboardOwnerOrEditor}
                      testId="save-dashboard-button"
                      style={{marginRight: "0.5rem", fontSize: "12px", paddingRight: 15, paddingLeft: 15}}
                      type="inverted"
                      iconType="bhi"
                      icon="save"
                      label={`${smallResolution ? "" : "Save"}`}
                      onClick={() => {
                        if (dashboard.get("id")) {
                          handleSaveRequest();
                        } else {
                          setShowSaveAsDialog(true);
                        }
                      }} />
                </Tooltip> :
                <>
                  <TextButton
                      dataProductId="save-dashboard-button"
                      testId="save-dashboard-button"
                      disabled={!isDashboardOwnerOrEditor}
                      style={{marginRight: "0.5rem", fontSize: "12px", paddingRight: 15, paddingLeft: 15}}
                      type="inverted"
                      iconType="bhi"
                      icon="save"
                      label={`${smallResolution ? "" : "Save"}`}
                      onClick={() => {
                        if (dashboard.get("id")) {
                          handleSaveRequest();
                        } else {
                          setShowSaveAsDialog(true);
                        }
                      }} />
                  {hasUnsavedChanges && renderUnsavedChangedIndicator(theme, isFullEditor)}
                </>
            }
          </div>
          <div style={{position: "relative"}}>
            <TextButton
                testId="save-as-dashboard-button"
                style={{marginRight: "0.5rem", fontSize: "12px", whiteSpace: "nowrap", paddingRight: 15, paddingLeft: 15}}
                label={`${smallResolution ? "" : "Save As"}`}
                iconType="bhi"
                icon="save"
                stackedIcon="edit"
                type="inverted"
                onClick={() => setShowSaveAsDialog(true)} />
            {(hasUnsavedChanges && !isDashboardOwnerOrEditor) && renderUnsavedChangedIndicator(theme)}
          </div>
          <TextButton
              dataProductId="open-dashboard-button"
              testId="open-dashboard-button"
              style={{marginRight: "0.5rem", fontSize: "12px", paddingRight: 15, paddingLeft: 15}}
              type="inverted"
              iconType="bhi"
              icon="file"
              label={`${smallResolution ? "" : "Open"}`}
              onClick={onRequestOpenSavedDashboards} />
        </div>
        <div style={{display: "flex", alignItems: "center"}}>
          {dashboard && <h3 style={{textAlign: "center", fontSize: "1em"}}>{isEditing ? <TextField
                  value={dashboard.get("name")}
                  className="TESTCAFE-dashboard-name"
                  onChange={e => handleNameChange(e.target.value)}
                  style={{width: 300, paddingTop: 3, paddingLeft: 10}} />
              : dashboard.get("name")}</h3>}
        </div>
        <div style={{flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
          {(isFullEditor && isEditing) && <TextButton
              dataProductId="add-panel-button"
              testId="add-panel-button"
              style={{marginRight: "1.5rem", fontSize: "12px", whiteSpace: "nowrap", paddingRight: 15, paddingLeft: 15}}
              disabled={dashboard.getIn(["json", "layoutType"]) === "window-only"
                  && dashboard.getIn(["json", "components"], Immutable.List()).size >= 1}
              type="inverted"
              label={`${smallResolution ? "" : "Add Panel"}`}
              iconType="bhi"
              icon="addcard"
              onClick={onRequestOpenComponentMenu} />}
          {isBasicEditor && <FormControlLabel
              componentsProps={{typography: {fontSize: 14}}}
              label="Edit"
              style={{whiteSpace: "nowrap", marginRight: "1.5em"}}
              control={<Switch
                  data-product-id="edit-mode-switch"
                  data-test-id="toggle-edit-mode"
                  checked={isEditing}
                  onChange={e => {
                    e.target.checked
                        ? document.body.classList.add("isEditing")
                        : document.body.classList.remove("isEditing");
                    setEditing(e.target.checked);
                  }} />} />}
          <TextButton
              icon="refresh" iconType="bhi" label="" onClick={onRequestReloadDashboard}
              style={{fontSize: "12px", padding: 0, minWidth: 50}} />
        </div>

        {showSaveAsDialog && <SaveReportAsDialog
            initialName={dashboard.get("name")}
            savingTypeName="dashboard"
            generateUniqueName={false}
            onSaveAsRequest={handleSaveAsRequest}
            onCancelRequest={() => setShowSaveAsDialog(false)}
            unavailableNames={Rata
                .getValue(ownershipTypeToDashboardsWrapper)
                .get("ownedConfigs")
                .map(config => config.get("name"))} />}
      </div>;
    })
;

export default DashboardToolbar;
