import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import {TextField} from "@mui/material";

import pure from "js/common/views/pure";
import ConfigContainer from "js/admin/cubetv/leaderboard/config-container";
import Layout from "js/common/views/foundation-column-layout";
import SlideTimeframePicker from "js/admin/cubetv/slide-timeframe-picker";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import ImmutableTagFilter from "js/common/views/inputs/tag-picker/immutable-tag-filter";
import {TextButton} from "js/common/views/inputs/buttons";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import currentClient from "js/common/repo/backbone/current-client";
import getDefaultLeaderboardSlide from "js/admin/cubetv/leaderboard/default-leaderboard-slide";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import Checkbox from "js/common/views/inputs/checkbox";

const pathToTopPerformersKpis = ["layout", "right", 0, "kpis"];

const EditTopPerformers = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    timeframes: ImmutablePropTypes.list.isRequired,
    topPerformers: ReactPropTypes.object.isRequired,
    onChange: ReactPropTypes.func.isRequired,
    theme: ReactPropTypes.object
  },

  render() {
    const isEnabled = !this.props.topPerformers.get("kpis").isEmpty();
    return (
        <div>
          <Checkbox
              style={{paddingTop: "1rem", paddingLeft: "1rem", paddingRight: "1rem"}}
              label="Show Metrics for Top Performers"
              checked={isEnabled}
              onCheck={(e, isChecked) => this.handleShowTopPerformerMetricsToggle(isChecked)} />
          {isEnabled && this.renderEditArea()}
        </div>);
  },

  renderEditArea() {
    const {timeframes, topPerformers, onChange, theme} = this.props;
    const configKpis = topPerformers.get("kpis");
    return (
        <div>
          <Layout allSmall={12}>
            <TextField variant="standard" style={{marginTop: 15}}
                       label="Title"
                       onChange={e => onChange(topPerformers.set("title", e.target.value.substring(0, 50)))}
                       value={topPerformers.get("title")} />
          </Layout>
          <Layout allSmall={12} rowStyle={{marginTop: "1rem"}}>
            {topPerformers
                .get("kpis")
                .map((config, index) => (
                    <EditKpi
                        theme={theme}
                        key={index}
                        label={`Metric ${index + 1}`}
                        timeframes={timeframes}
                        kpis={getKpis()}
                        config={config}
                        onChange={config => this.handleKpiChange(index, config)}
                        onRemoveKpiRequest={() => this.handleRemoveKpiRequest(index)} />
                ))}
          </Layout>
          <Layout allSmall={12} smallCentered={true} columnStyle={{marginTop: "1rem", marginBottom: "1rem"}}>
            <TextButton
                icon="plus"
                label="Add Another Metric (max 3)"
                disabled={configKpis.count() === 3}
                onClick={this.handleAddKpiRequest} />
          </Layout>
        </div>);
  },

  handleKpiChange(index, config) {
    const {topPerformers, onChange} = this.props;
    onChange(topPerformers.setIn(["kpis", index], config));
  },

  handleAddKpiRequest() {
    const {topPerformers, onChange} = this.props;
    const defaultTopPerformerKpi = getDefaultLeaderboardSlide().getIn(pathToTopPerformersKpis).first();
    onChange(topPerformers.set("kpis", topPerformers.get("kpis").push(defaultTopPerformerKpi)));
  },

  handleRemoveKpiRequest(index) {
    const {topPerformers, onChange} = this.props;
    onChange(topPerformers.set("kpis", topPerformers.get("kpis").delete(index)));
  },

  handleShowTopPerformerMetricsToggle(isEnabled) {
    const {topPerformers, onChange} = this.props;
    if (isEnabled) {
      const defaultTopPerformerKpis = getDefaultLeaderboardSlide().getIn(pathToTopPerformersKpis);
      const enabledTopPerformers = topPerformers.set("kpis", defaultTopPerformerKpis);
      onChange(enabledTopPerformers);
    } else {
      const disabledTopPerformers = topPerformers.set("kpis", Immutable.List());
      onChange(disabledTopPerformers);
    }
  }

});

const EditKpi = pure(({
  timeframes,
  kpis,
  label,
  config,
  onChange,
  onRemoveKpiRequest,
  theme
}) => {
  const kpiId = config.get("kpiId");
  const timeframeId = config.get("timeframeId").toLowerCase();
  const defaultName = createName(kpiRepo.get(kpiId), timeframeRepo.get(timeframeId));
  const displayName = config.get("displayName") ?? defaultName;
  const visibleTimeframes = timeframes
      .filter(t => (t.get("visible") && !t.get("isDeleted")) || t.get("id") === timeframeId);
  const visibleKpis = kpis.filter(k => k.get("visible") || k.get("id") === config.get("kpiId"));
  return (
      <ConfigContainer label={label} onRemoveClick={onRemoveKpiRequest}>
        <Layout allSmall={12}>
          <TextField variant="standard"
              style={{marginTop: 15}}
              label="Display Name"
              value={displayName}
              onBlur={e => {
                if (e.target.value.substring(0, 39) === "") {
                  onChange(config.set("displayName", defaultName));
                }
              }
              }
              onChange={e => onChange(config.set("displayName", e.target.value.substring(0, 39)))} />
        </Layout>
        <Layout allSmall={6} columnStyle={{marginTop: "1rem"}}>
          <KpiPicker
              multiSelect={false}
              kpis={visibleKpis}
              selectedKpiId={kpiId}
              clearable={false}
              onChange={kpiId => {
                const newKpiId = kpiId;
                const newConfig = config
                    .update(c => updateName(c, newKpiId, c.get("timeframeId")))
                    .set("kpiId", newKpiId);
                onChange(newConfig);
              }} />
          <SlideTimeframePicker
              selectedTimeframeId={timeframeId}
              timeframes={visibleTimeframes}
              onChange={timeframeId => {
                const newConfig = config
                    .update(c => updateName(c, c.get("kpiId"), timeframeId))
                    .set("timeframeId", timeframeId);
                onChange(newConfig);
              }} />
        </Layout>
        {currentClient.canAccessApp("TAGS_ADMIN") &&
        <Layout allSmall={12} allMedium={4} columnStyle={{marginTop: "1rem"}}>
          <ImmutableTagFilter
              label="Match Any Tags"
              onChange={tagIds => onChange(config.set("matchAnyTagIds", Immutable.List(tagIds)))}
              multiple={true}
              tagIds={config.get("matchAnyTagIds")} />
          <ImmutableTagFilter
              label="Match All Tags"
              onChange={tagIds => onChange(config.set("matchAllTagIds", Immutable.List(tagIds)))}
              multiple={true}
              tagIds={config.get("matchAllTagIds")} />
          <ImmutableTagFilter
              label="Exclude Tags"
              onChange={tagIds => onChange(config.set("excludedTagIds", Immutable.List(tagIds)))}
              multiple={true}
              tagIds={config.get("excludedTagIds")} />
        </Layout>}
      </ConfigContainer>);
});

const getKpis = () => Immutable.fromJS(kpiRepo.getAll().toJSON());

const updateName = (config, newKpiId, newTimeframeId) => config
    .update("displayName", name => {
      const oldKpi = kpiRepo.get(config.get("kpiId"));
      const oldTimeframe = timeframeRepo.get(config.get("timeframeId"));
      const isDefaultName = name === createName(oldKpi, oldTimeframe);
      const newKpi = kpiRepo.get(newKpiId);
      const newTimeframe = timeframeRepo.get(newTimeframeId);
      return isDefaultName ? createName(newKpi, newTimeframe) : name;
    });
const createName = (kpi, timeframe) => `${kpi.get("name")} ${timeframe.get("shortName") || timeframe.get("name")}`;

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <EditTopPerformers theme={theme} {...props} />;
};