import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";

import pure from "js/common/views/pure";
import { TextButton } from "js/common/views/inputs/buttons";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import Layout from "js/common/views/foundation-column-layout";
import Overlay from "js/common/views/overlay";
import LoadingSpinner from "js/common/views/loading-spinner";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import * as Users from "js/common/users";
import * as Branding from "js/common/branding-constants";
import currentClient from "js/common/repo/backbone/current-client";

const OrphanUserPage = createReactClass({

    getInitialState() {
        return {
            userById: Immutable.Map(),
            loading: false,
            updating: false
        };
    },

    componentDidMount() {
        this.setState({loading: true});
        Users.load().then(users => {
            const userById = users.groupBy(u => u.get("id")).map(us => us.first());
            this.setState({userById, loading: false});
        });
    },

    render() {
        const orphanUsers = this.state.userById
            .valueSeq()
            .filter(Users.isOrphan);
        const { theme } = this.props;
        const boxStyle = theme =>({
            border: theme.themeId === "light" ? `1px solid ${theme.palette.background.paper}` : "none",
            backgroundColor: theme.themeId === "dark" ? "#272731" : "#eeeeee"
        });

        return (
            <div style={{position: "relative"}}>
                <Layout small={[7,5]} rowStyle={{ marginTop: "1rem" }}>
                    {this.state.loading ? <LoadingSpinner iconSize={3}/> : <OrphanUsersTable
                        users={orphanUsers}
                        onFixOrphanClick={this.handleFixClick}
                        onDeactivateOrphanClick={this.handleDeactivateClick} />}
                    <Guide theme={theme} style={boxStyle(theme)}/>
                </Layout>
                {this.state.updating && <Overlay />}
            </div>);
    },

    handleFixClick(userId) {
        this.setState({updating: true});
        Users.fixOrphan(userId)
            .then(user => {
                this.setUserInState(user);
                this.setState({updating: false});
            });
    },

    handleDeactivateClick(userId) {
        this.setState({updating: true});
        Users.deactivateOrphan(userId)
            .then(user => {
                this.setUserInState(user);
                this.setState({updating: false});
            });
    },

    setUserInState(user) {
        const userById = this.state.userById.set(user.get("id"), user);
        this.setState({userById});
    }

});

const buttonStyle = {
    fontSize : "10px",
    // marginTop : "-5px", // to compensate for the 8px padding that fixed-data-table sets on cells
    marginTop : "0.5px",
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: "1rem",
    paddingLeft: "1rem",
    marginRight: "1rem",
    marginLeft: "1rem",
    height: 25,
    lineHeight: 1
};

const OrphanUsersTable = createReactClass({

    displayName: "OrphanUsersTable",

    render() {
        const columns = currentClient.isBullhornCrm() ? [
            {
                label: "ID",
                width: 50
            },
            "Name",
            {
                label: "Action",
                sortMapper: () => {},
                width: 250
            }
        ] : [
            "Name",
            {
                label: "Action",
                sortMapper: () => {},
                width: 250
            }
        ];
        return (
            <SimpleDataTable
                maxTableHeight={700}
                columns={columns}
                rows={this.props.users.map(this.userToRow).toArray()} />);
    },

    userToRow(user) {
        const {onFixOrphanClick, onDeactivateOrphanClick} = this.props;
        const name = user.get("fullName");
        const limitedName = name.length < 35 ? name : name.substring(0, 34) + "...";
        let row = [
            limitedName,
            <div key="actions">
                <TextButton
                    icon="wrench"
                    label="Fix"
                    labelStyle={{fontSize: "13px"}}
                    onClick={() => onFixOrphanClick(user.get("id"))}
                    style={buttonStyle} />
                <TextButton
                    icon="moon-o"
                    label="Deactivate"
                    labelStyle={{fontSize: "13px"}}
                    onClick={() => onDeactivateOrphanClick(user.get("id"))}
                    style={buttonStyle} />
            </div>
        ];
        if (currentClient.isBullhornCrm()) {
            row.unshift(Users.isCrmUser(user)? user.get("originalCrmUserId") : "");
        }
        return row;
    }

});

const Guide = pure(({theme, style}) => (
    <dl className="text-box-wrapper" style={{ ...style, padding: "1rem" }}>
        <dd>
        Orphaned Users have been set to be visible and/or able to login to {Branding.brandingName}, but they are lacking a current Group assignment.
        Without this they will be unable to be seen within or login to {Branding.brandingName}.
        </dd>
        <dt style={labelStyle(theme)}>
            <i className='fa fa-wrench' style={iconStyle(theme)} /> Fix
        </dt>
        <dd>This will extend the User's last Group assignment, allowing them to be seen within or login to {Branding.brandingName}.</dd>

        <dt style={labelStyle(theme)}>
            <i className='fa fa-moon-o' style={iconStyle(theme)} /> Deactivate
        </dt>
        <dd>This will make the User properly invisible and unable to login.</dd>
    </dl>));

const iconStyle = theme => ({
    color: theme.palette.textColor,
    paddingRight: 5
});

const labelStyle = theme => ({
    fontWeight: "normal",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    marginBottom: 0,
    marginTop: "1.25rem"
});


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <OrphanUserPage theme={theme} {...props} />;
};

OrphanUserPage.displayName = "OrphanUserPage";
export default Wrapper;