import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

import LoadingSpinner from "js/common/views/loading-spinner";
import {Layout, Row, Column} from "js/common/views/foundation-column-layout";
import {IconButton} from "js/common/views/inputs/buttons";
import Error from "js/common/views/error";
import {MessageOnlyTab} from "js/admin/kpis/edit-kpis/utils";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const EditSimpleSumColumns = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {columnsEditable, columns} = this.props;
    if (!columnsEditable || (columns && columns.isEmpty())) {
      return <MessageOnlyTab message={"Columns on this Metric are not available for editing."} />;
    } else if (!columns) {
      return this.renderLoadingText();
    } else {
      return this.renderPage();
    }
  },

  renderLoadingText() {
    return (
        <Layout allSmall={6} smallCentered={true}>
          <LoadingSpinner />
        </Layout>);
  },

  renderPage() {
    const {columns, submitError, rootChangedWarning, theme} = this.props;
    const visibleSimpleSumColumns = groupColumns(columns.filter(c => c.get("visible")))
        .sortBy(c => c.get("columns").minBy(c => c.get("order")).get("order"));

    return (
        <div style={{padding: "1rem"}}>
          {submitError && <Error text={submitError} style={{marginTop: 0}} />}
          {rootChangedWarning && <Error text={rootChangedWarning} type={"warn"} style={{marginTop: 0}} />}
          <Row style={{lineHeight: "40px"}}>
            <Column small={12} medium={6} style={{padding: "0px 10px"}}>
                    <span style={headerStyle(theme)}>
                        <i className="fa fa-eye"></i>  Visible Columns
                    </span>
              <VisibleColumns
                  theme={theme}
                  columns={visibleSimpleSumColumns}
                  onReordered={this.handleReordered}
                  onInvisibleClick={this.handleInvisibleClick} />
            </Column>
            <Column small={12} medium={6} style={{padding: "0px 10px"}}>
                    <span style={headerStyle(theme)}>
                        <i className="fa fa-eye-slash"></i>  Hidden Columns
                    </span>
              {this.renderInvisibleColumns()}
            </Column>
          </Row>
        </div>
    );
  },

  renderInvisibleColumns() {
    const {theme} = this.props;
    return (
      <div>
        {groupColumns(this.props.columns.filter(c => !c.get("visible")))
            .sortBy(c => c.get("label"))
            .map((column, index) => {
              return (
                <Row style={rowStyle(theme)} key={column.get("label")}>
                  <Column small={12} style={columnStyle}>
                    {column.get("label")}
                    <div className="right">
                      <IconButton
                        icon="eye"
                        type="bright"
                        title="Make visible"
                        onClick={() => this.handleVisibleClick(column)}
                        size="large" />
                    </div>
                  </Column>
                </Row>
              );
            })}
      </div>
    );
  },

  handleReordered(groupedVisibleColumns) {
    const {columns, onChange} = this.props;
    const invisibleColumns = columns.filter(c => !c.get("visible"));
    const ungroupedVisibleColumns = groupedVisibleColumns
        .flatMap((c, index) => c.get("columns").map(innerColumn => innerColumn.set("order", index)));
    const updatedColumns = ungroupedVisibleColumns
        .concat(invisibleColumns);
    onChange(updatedColumns);
  },

  handleInvisibleClick(groupedColumn) {
    const {columns, onChange} = this.props;

    const updatedColumns = columns.map(c => {
      if (c.get("visible") && c.get("label") === groupedColumn.get("label")) {
        return c.set("visible", false);
      } else {
        return c;
      }
    });

    onChange(updatedColumns);
  },

  handleVisibleClick(groupedColumn) {
    const {columns, onChange} = this.props;
    const visibleColumns = columns.filter(c => c.get("visible"));
    let maxOrder = 0;
    if (!visibleColumns.isEmpty()) {
      maxOrder = visibleColumns
          .maxBy(c => c.get("order"))
          .get("order");
    }
    const order = visibleColumns.isEmpty() ? maxOrder : maxOrder + 1;

    const updatedColumns = columns.map(c => {
      if (!c.get("visible") && c.get("label") === groupedColumn.get("label")) {
        return c.set("visible", true).set("order", order);
      } else {
        return c;
      }
    });

    onChange(updatedColumns);
  }
});

const groupColumns = columns => {
  return columns
      .groupBy(c => c.get("label"))
      .map((val, key) => (Immutable.fromJS({label: key, columns: val})))
      .toList();
};

const VisibleColumns = createReactClass({

  handleDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const newIndex = result.destination.index;

    const columns = this.props.columns;
    const movedColumn = columns.get(startIndex);
    const reorderedColumns = columns
        .delete(startIndex)
        .insert(newIndex, movedColumn);
    this.props.onReordered(reorderedColumns);
  },

  render() {
    const {theme} = this.props;
    return (
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="columns-list" type="ROW" direction="vertical">
            {(droppableProvided, droppableSnapshot) => (
                <div
                    ref={droppableProvided.innerRef}
                    style={{
                      backgroundColor: droppableSnapshot.isDraggingOver ? (theme.themeId === "light" ? "#d3d3d3" : "#2c3e50") : "transparent",
                      position: "relative"
                    }}
                    {...droppableProvided.droppableProps}>

                  {this.props.columns.map((column, index) => {
                    return <Draggable
                        key={column.get("label")}
                        draggableId={column.get("label")}
                        index={index}
                        type="ROW">
                      {(draggableProvided) =>
                          //inner and outer div so we don't accidentally drag the placeholder too
                          (<div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}>
                            <VisibleColumn
                                theme={theme}
                                column={column}
                                onInvisibleClick={this.props.onInvisibleClick}
                                dragHandleProps={draggableProvided.dragHandleProps} />
                          </div>)}
                    </Draggable>;
                  })}
                  {droppableProvided.placeholder}
                </div>)}
          </Droppable>
        </DragDropContext>);
  }
});

const VisibleColumn = createReactClass({

  render() {
    const {column, onInvisibleClick, dragHandleProps, theme} = this.props;
    return (
      <Row style={rowStyle(theme)}>
        <Column small={12} style={columnStyle}>
          {column.get("label")}
          <div className="right">
            <i className="fa fa-arrows" style={{cursor: "move"}} {...dragHandleProps} />
            <IconButton
              icon="eye-slash"
              type="bright"
              title="Hide column"
              onClick={() => onInvisibleClick(column)}
              size="large" />
          </div>
        </Column>
      </Row>
    );
  }
});

const headerStyle = theme =>({
  textTransform: "uppercase",
  fontSize: ".7rem",
  color: theme.palette.primary.main,
  paddingBottom: "5px",
  marginLeft: ".5rem"
});

const rowStyle = theme =>({
  background: theme.themeId === "light" ? theme.palette.background.paper : "#1f1f1f",
  borderBottom: "1px solid #393838",
  borderTop: "1px solid #000"
});

const columnStyle = {
  paddingLeft: "0.9375em",
  paddingRight: "0.9375em"
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <EditSimpleSumColumns theme={theme} {...props} />;
};