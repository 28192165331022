import React from "react";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Drawer from "js/common/views/drawer";
import getLibraryOfComponents from "js/dashboards/components/prebuilt-component-library";

const Header = React.memo(({text}) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <div style={{
    fontWeight: "500",
    padding: "10px 15px 0 15px",
    marginTop: 10,
    lineHeight: 1,
    fontSize: "13px",
    color: theme.palette.text.main,
  }}>{text}</div>;
});

const Item = React.memo(({text, description, category, onClick = () => {}}) => {
  const {theme} = React.useContext(CustomThemeContext);

  const iconMap = {
    "Metric": "fa fa-circle-o-notch",
    "Ratio": "fa fa-percent",
    "RatioComparison": "fa fa-percent",
    "PerformanceSnapshot": "fa fa-th-large",
    "SmallData": "fa fa-th-large",
    "BigData": "fa fa-th",
    "Trend": "fa fa-line-chart",
    "Heading": "fa fa-paragraph",
    "Divider": "fa fa-grip-lines"
  };

  const backgroundMap = {
    "Metrics": theme.palette.primary.main,
    "Grids": theme.palette.warning.main,
    "Charts": theme.palette.trends.main,
    "Layout": "#44BB77"
  };

  const iconLookup = text.split(' ').slice(0,2).join('');

  return <div data-test-id="prebuilt-panel" onClick={onClick} style={{
    cursor: "pointer",
    backgroundColor: theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.paper,
    boxShadow: "0 0 3px rgba(0,0,0,0.15)",
    borderRadius: "5px",
    padding: "9px",
    minWidth: "100%",
    maxWidth: "100%",
    margin: "10px 0 0 0",
    fontSize: "13px",
    display:  "flex",
    alignItems: "center",
  }}>
    <span style={{margin: "0 10px 0 0", position: "relative", width: 36, minWidth: 36, height: 36, lineHeight: "36px", textAlign: "center"}}>
      <span style={{
        background: backgroundMap[category],
        borderRadius: "5px",
        opacity: 0.2,
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%"}} />
      <i className={iconMap[iconLookup]} style={{position: "relative", top: 2, fontSize: 18, color: backgroundMap[category]}} />
    </span>
    <div>
      {text}
      <span style={{display: "block", fontSize: 10, opacity: 0.5}}>{description}</span>
    </div>
  </div>;
});

const AddComponentDrawer = React.memo(({open, onRequestClose, onAddComponent}) => {
  const categoryToComponents = getLibraryOfComponents().groupBy(c => c.get("category"));
  return <Drawer
      dataProductId={open ? "add-panel-drawer-open" : "add-panel-drawer-closed"}
      width={400}
      onRequestClose={onRequestClose}
      open={open}
      title="Add Panel"
      showCloseButton
      openRight={true}>
    {categoryToComponents
        .entrySeq()
        .map(([category, componentsForCategory]) => {
          return <React.Fragment key={category}>
            <Header text={category} />
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between", padding: "5px 15px 0 15px"}}>
              {componentsForCategory.map(component => <Item
                  key={component.get("label")}
                  text={component.get("label")}
                  description={component.get("description")}
                  category={category}
                  onClick={() => onAddComponent(component.get("componentConfig"))} />)}
            </div>
          </React.Fragment>;
        })}
  </Drawer>;
});

export default AddComponentDrawer;
