/** @jsxImportSource @emotion/react */
import React from "react";
import * as Styles from "js/common/views/index-view/index-intro.styles";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const IndexIntro = React.memo(({image, title, description, cta}) => {
  const { theme } = React.useContext(CustomThemeContext);

  return <div css={Styles.introContainer(theme)}>
    {theme.themeId === "dark" &&
      <div css={Styles.imageContainer}>
        <img src={image} alt={title} className="TESTCAFE-index-header-image" />
      </div>
    }
    <div css={Styles.infoContainer(theme)}>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
    {cta && React.cloneElement(cta)}
  </div>;
});

export default IndexIntro;