import React from "react";
import Select from "js/common/views/inputs/immutable-react-select";
import pure from "js/common/views/pure";
import Immutable from "immutable";

export default pure(({value, onChange, isDisabled, containerStyle, style}) => (
    <div onClick={e => e.stopPropagation()} style={containerStyle}>
      <Select
          style={style}
          placeholder="Frequency"
          options={periodTypeOptions}
          selectedValue={value}
          isMulti={false}
          isClearable={false}
          isSearchable={false}
          isDisabled={isDisabled}
          onChange={newOption => onChange(newOption)} />
    </div>
));

const periodTypeOptions = Immutable.fromJS([{
  label: "per month",
  value: "MONTHLY"
}, {
  label: "per week",
  value: "WEEKLY"
}]);
