/** @jsxImportSource @emotion/react */

import React from "react";
import {jsx, css} from "@emotion/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "react-tooltip";

import { clamp, midpoint, touchPt, touchDistance } from "js/common/utils/geometry";
import makePassiveEventOption from "js/common/utils/passive-event";
import {greyDark, c19Yellow} from "./cube19-colors";

import {
  faSearchPlus,
  faSearchMinus,
  faExpandWide,
  faCompressWide,
  faCrosshairs,
} from "@fortawesome/pro-solid-svg-icons";

// The amount that a value of a dimension will change given a new scale
const coordChange = (coordinate, scaleRatio) => {
  return (scaleRatio * coordinate) - coordinate;
};

class PanZoom extends React.Component {
  static get defaultProps() {
    return {
      minScale: 0.01,
      maxScale: 3,
      translationBounds: {},
      disableZoom: false,
      disablePan: false,
      centerOffsetY: 0,
      centerOffsetX: 0,
      shouldCenterOnLoad: false,
      onZoom: undefined,
      nodeIdentifier: "",
      parentIdentifier: "",
      containerIdentifier: "",
      showControls: true,
    };
  }

  constructor(props) {
    super(props);
    const { scale, defaultScale, translation, defaultTranslation, minScale, maxScale } = props;

    let desiredScale;
    if (scale !== undefined) {
      desiredScale = scale;
    } else if (defaultScale !== undefined) {
      desiredScale = defaultScale;
    } else {
      desiredScale = 1;
    }

    this.state = {
      scale: clamp(minScale, desiredScale, maxScale),
      translation: translation || defaultTranslation || { x: 0, y: 0 },
      shouldPreventTouchEndDefault: false
    };

    this.startPointerInfo = undefined;

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);

    this.onMouseUp = this.onMouseUp.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);

    this.onWheel = this.onWheel.bind(this);
  }

  componentDidMount() {
    const passiveOption = makePassiveEventOption(false);
    this.containerNode.addEventListener('wheel', this.onWheel, passiveOption);
    this.containerNode.addEventListener('contextmenu', event => event.preventDefault());

    if(this.props.shouldCenterOnLoad) {
      setTimeout(() => {
        this.centerNode(this.props.rootId, true);
      }, 10)
    }
    /*
      Setup events for the gesture lifecycle: start, move, end touch
    */

    // start gesture
    this.containerNode.addEventListener('touchstart', this.onTouchStart, passiveOption);
    this.containerNode.addEventListener('mousedown', this.onMouseDown, passiveOption);

    // move gesture
    window.addEventListener('touchmove', this.onTouchMove, passiveOption);
    window.addEventListener('mousemove', this.onMouseMove, passiveOption);

    // end gesture
    const touchAndMouseEndOptions = { capture: true, ...passiveOption };
    window.addEventListener('touchend', this.onTouchEnd, touchAndMouseEndOptions);
    window.addEventListener('mouseup', this.onMouseUp, touchAndMouseEndOptions);

  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const scale = (newProps.scale !== undefined) ? newProps.scale : this.state.scale;
    const translation = newProps.translation || this.state.translation;

    // if parent has overridden state then abort current user interaction
    if (
        translation.x !== this.state.translation.x ||
        translation.y !== this.state.translation.y ||
        scale !== this.state.scale
    ) {
      this.setPointerState();
    }

    this.setState({
      scale: clamp(newProps.minScale, scale, newProps.maxScale),
      translation: this.clampTranslation(translation, newProps)
    });

    // if we are passed a node ID to center, call the centerNode function
    if (newProps.centerNode) {
      this.centerNode(newProps.centerNode);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.centerNode && this.props.shouldRecenter) {
        setTimeout(() => {
          this.centerNode(this.props.rootId, true);
        }, 1)
    }
  }

  componentWillUnmount() {
    this.containerNode.removeEventListener('wheel', this.onWheel);

    // Remove touch events
    this.containerNode.removeEventListener('touchstart', this.onTouchStart);
    window.removeEventListener('touchmove', this.onTouchMove);
    window.removeEventListener('touchend', this.onTouchEnd);

    // Remove mouse events
    this.containerNode.removeEventListener('contextmenu', event => event.preventDefault());
    this.containerNode.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('mouseup', this.onMouseUp);
  }

  updateParent() {
    if (!this.props.onZoom) {
      return;
    }
    const { scale } = this.state;
    this.props.onZoom(scale);
  }

  centerNode(nodeId, onlyY = false) {
    const { nodeIdentifier, parentIdentifier, isFullScreen } = this.props;
    const node = document.getElementById(`${nodeIdentifier}-${nodeId}`);
    if (!node) return null;
    const parent = document.getElementById(parentIdentifier);
    const nodeTop = node.getBoundingClientRect().top;
    const parentTop = parent.getBoundingClientRect().top;

    const nodeLeft = node.getBoundingClientRect().left;
    const parentLeft = parent.getBoundingClientRect().left;

    this.setState({translation: {
        x: onlyY ? 20 : this.state.translation.x -(nodeLeft - parentLeft) + (this.props.centerOffsetX - 150),
        y: this.state.translation.y -(nodeTop - parentTop) + (isFullScreen ? this.props.centerOffsetY - 70 : this.props.centerOffsetY +  190)
      }});
  }

  /*
    Event handlers

    All touch/mouse handlers preventDefault because we add
    both touch and mouse handlers in the same session to support a device
    with both touch screen and mouse inputs. The browser may fire both
    a touch and mouse event, so we have to ensure that only one handler is used.

    https://developer.mozilla.org/en-US/docs/Web/API/Touch_events/Supporting_both_TouchEvent_and_MouseEvent
  */

  onMouseDown(e) {
    e.preventDefault();
    this.setPointerState([e]);
  }

  onTouchStart(e) {
    e.preventDefault();
    this.setPointerState(e.touches);
  }

  onMouseUp(e) {
    e.preventDefault();
    this.setPointerState();
  }

  onTouchEnd(e) {
    this.setPointerState(e.touches);
  }

  onMouseMove(e) {
    if (!this.startPointerInfo || this.props.disablePan) {
      return;
    }
    e.preventDefault();
    this.onDrag(e);
  }

  onTouchMove(e) {
    if (!this.startPointerInfo) {
      return;
    }

    e.preventDefault();

    const { disablePan, disableZoom } = this.props;

    const isPinchAction = e.touches.length === 2 && this.startPointerInfo.pointers.length > 1;
    if (isPinchAction && !disableZoom) {
      this.scaleFromMultiTouch(e);
    } else if ((e.touches.length === 1) && this.startPointerInfo && !disablePan) {
      this.onDrag(e.touches[0]);
    }
  }

  // handles both touch and mouse drags
  onDrag(pointer) {
    const { translation, pointers } = this.startPointerInfo;
    const startPointer = pointers[0];
    const dragX = pointer.clientX - startPointer.clientX;
    const dragY = pointer.clientY - startPointer.clientY;
    const newTranslation = {
      x: translation.x + dragX,
      y: translation.y + dragY
    };

    this.setState({
      translation: this.clampTranslation(newTranslation),
      shouldPreventTouchEndDefault: true
    }, () => this.updateParent());
  }

  onWheel(e) {
    if (this.props.disableZoom) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const scaleChange = 2 ** (e.deltaY * 0.002);

    const newScale = clamp(
        this.props.minScale,
        this.state.scale + (1 - scaleChange),
        this.props.maxScale
    );

    const mousePos = this.clientPosToTranslatedPos({ x: e.clientX, y: e.clientY });

    this.scaleFromPoint(newScale, mousePos);
  }

  setPointerState(pointers) {
    if (!pointers || pointers.length === 0) {
      this.startPointerInfo = undefined;
      return;
    }

    this.startPointerInfo = {
      pointers,
      scale: this.state.scale,
      translation: this.state.translation,
    }
  }

  clampTranslation(desiredTranslation, props = this.props) {
    const { x, y } = desiredTranslation;
    let { xMax, xMin, yMax, yMin } = props.translationBounds;
    xMin = xMin !== undefined ? xMin : -Infinity;
    yMin = yMin !== undefined ? yMin : -Infinity;
    xMax = xMax !== undefined ? xMax : Infinity;
    yMax = yMax !== undefined ? yMax : Infinity;

    return {
      x: clamp(xMin, x, xMax),
      y: clamp(yMin, y, yMax)
    };
  }

  translatedOrigin(translation = this.state.translation) {
    const clientOffset = this.containerNode.getBoundingClientRect();
    return {
      x: clientOffset.left + translation.x,
      y: clientOffset.top + translation.y
    };
  }

  clientPosToTranslatedPos({ x, y }, translation = this.state.translation) {
    const origin = this.translatedOrigin(translation);
    return {
      x: x - origin.x,
      y: y - origin.y
    };
  }

  scaleFromPoint(newScale, focalPt) {
    const { translation, scale } = this.state;
    const scaleRatio = newScale / (scale !== 0 ? scale : 1);

    const focalPtDelta = {
      x: coordChange(focalPt.x, scaleRatio),
      y: coordChange(focalPt.y, scaleRatio)
    };

    const newTranslation = {
      x: translation.x - focalPtDelta.x,
      y: translation.y - focalPtDelta.y
    };

    this.setState({
      scale: newScale,
      translation: this.clampTranslation(newTranslation)
    }, () => this.updateParent());
  }

  scaleFromMultiTouch(e) {
    const startTouches = this.startPointerInfo.pointers;
    const newTouches   = e.touches;

    // calculate new scale
    const dist0       = touchDistance(startTouches[0], startTouches[1]);
    const dist1       = touchDistance(newTouches[0], newTouches[1]);
    const scaleChange = dist1 / dist0;

    const startScale  = this.startPointerInfo.scale;
    const targetScale = startScale + ((scaleChange - 1) * startScale);
    const newScale    = clamp(this.props.minScale, targetScale, this.props.maxScale);

    // calculate mid points
    const newMidPoint   = midpoint(touchPt(newTouches[0]), touchPt(newTouches[1]));
    const startMidpoint = midpoint(touchPt(startTouches[0]), touchPt(startTouches[1]))

    const dragDelta = {
      x: newMidPoint.x - startMidpoint.x,
      y: newMidPoint.y - startMidpoint.y
    };

    const scaleRatio = newScale / startScale;

    const focalPt = this.clientPosToTranslatedPos(startMidpoint, this.startPointerInfo.translation);
    const focalPtDelta = {
      x: coordChange(focalPt.x, scaleRatio),
      y: coordChange(focalPt.y, scaleRatio)
    };

    const newTranslation = {
      x: this.startPointerInfo.translation.x - focalPtDelta.x + dragDelta.x,
      y: this.startPointerInfo.translation.y - focalPtDelta.y + dragDelta.y
    };

    this.setState({
      scale: newScale,
      translation: this.clampTranslation(newTranslation)
    }, () => this.updateParent());
  }

  discreteScaleStepSize() {
    const { minScale, maxScale } = this.props;
    const delta = Math.abs(maxScale - minScale);
    return delta / 10;
  }

  changeScale(delta) {
    const targetScale = this.state.scale + delta;
    const { minScale, maxScale } = this.props;
    const scale = clamp(minScale, targetScale, maxScale);

    const rect = this.containerNode.getBoundingClientRect();
    const x = rect.left + (rect.width / 2);
    const y = rect.top + (rect.height / 2);

    const focalPoint = this.clientPosToTranslatedPos({ x, y });
    this.scaleFromPoint(scale, focalPoint);
  }

  render() {
    const { children, showControls } = this.props;
    const { scale } = this.state;
    // Defensively clamp the translation. This should not be necessary if we properly set state elsewhere.
    const translation = this.clampTranslation(this.state.translation);

    /*
      This is a little trick to allow the following ux: We want the parent of this
      component to decide if elements inside the map are clickable. Normally, you wouldn't
      want to trigger a click event when the user *drags* on an element (only if they click
      and then release w/o dragging at all). However we don't want to assume this
      behavior here, so we call `preventDefault` and then let the parent check
      `e.defaultPrevented`. That value being true means that we are signalling that
      a drag event ended, not a click.
    */
    const handleEventCapture = (e) => {
      if (this.state.shouldPreventTouchEndDefault) {
        e.preventDefault();
        this.setState({ shouldPreventTouchEndDefault: false });
      }
    };

    return (
        <div
            ref={(node) => {
              this.containerNode = node;
            }}
            style={{
              height: '100%',
              width: '100%',
              position: 'relative', // for absolutely positioned children
              touchAction: 'none',
              zIndex: 98
            }}
            onClickCapture={handleEventCapture}
            onTouchEndCapture={handleEventCapture}
        >
          {(children || undefined) && children({ translation, scale })}
          {showControls && this.renderPanZoomControls()}
        </div>
    );
  }


  renderPanZoomControls() {
    const { minScale, maxScale, handleFullScreen, isFullScreen, theme } = this.props;
    const screenIcon = isFullScreen ? faCompressWide : faExpandWide;
    const sidebarOffSet = isFullScreen ? 0 : 300;

    const ControlsContainer = css`
      position: relative;
      pointer-events: none;
      z-index: 2;
      position: fixed;
      padding: 1.2rem;
      bottom: ${isFullScreen ? "0" : "80px"};
      flex-grow: 1;
      display: flex;
      justify-content: center;
      width: calc(100% - ${sidebarOffSet}px);
    `;

    const ControlIcon = css`
      border: none;
      font-size: 1rem;
      margin: 0 0.25rem;
      padding: 0.5rem;
      border-radius: 3px;
    `;

    const ControlsPanel = css`
      background: ${theme.palette.background.card};
      border-radius: 3px;
      align-items: center;
      pointer-events: auto;
      padding: 0.5rem;
      display: flex;
      user-select: none;
      > div {
        display: inline-block;
        padding: 0 1rem;
        color: ${theme.palette.text.main};
        &:hover {
            cursor: pointer;
            color: ${theme.palette.primary.main};
        }
      }
    `;

    return (
        <div css={ControlsContainer}>
          <div css={ControlsPanel}>
            <div css={ControlIcon} id="pan-zoom-zoom-in" onClick={() => this.setState({scale: clamp(minScale, this.state.scale + 0.2, maxScale)})}>
              <FontAwesomeIcon icon={faSearchPlus} key={`icon-${faSearchPlus}`} data-tip data-for="zoomInTooltip" />
              <Tooltip id="zoomInTooltip" place="top" type="light" effect="solid">Zoom in</Tooltip>
            </div>
            <div css={ControlIcon} id="pan-zoom-zoom-out" onClick={() => this.setState({scale: clamp(minScale, this.state.scale - 0.2, maxScale)})}>
              <FontAwesomeIcon icon={faSearchMinus} key={`icon-${faSearchMinus}`} data-tip data-for="zoomOutTooltip" />
              <Tooltip id="zoomOutTooltip" place="top" type="light" effect="solid">Zoom out</Tooltip>
            </div>
            <div css={ControlIcon} id="pan-zoom-reset-zoom" onClick={() => this.setState({
              scale: 1,
            }, () => this.centerNode(this.props.rootId, true))}>
              <FontAwesomeIcon icon={faCrosshairs} key={`icon-${faCrosshairs}`} data-tip data-for="resetZoomTooltip" />
              <Tooltip id="resetZoomTooltip" place="top" type="light" effect="solid">Reset zoom</Tooltip>
            </div>
            {handleFullScreen && (
                <div css={ControlIcon} id="pan-zoom-fullscreen" onClick={() => handleFullScreen()}>
                  <FontAwesomeIcon icon={screenIcon} key={`icon-${screenIcon}`} data-tip data-for="toggleFullscreenTooltip" />
                  <Tooltip id="toggleFullscreenTooltip" place="top" type="light" effect="solid">Toggle fullscreen</Tooltip>
                </div>
            )}
          </div>
        </div>
    );
  }
}

export default PanZoom;
