import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import PagedSearchPicker from "js/common/views/inputs/paged-search-picker";
import * as ajax from "js/common/ajax";

export default createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    onChange: ReactPropTypes.func.isRequired,
    clientIds: ImmutablePropTypes.list,
    isDisabled: ReactPropTypes.bool
  },

  render() {
    // TODO: Update to new client search endpoint (entity-finder/search)
    return <PagedSearchPicker
        searchPath="recruitment-clients/search"
        entity="CLIENT"
        placeholder=" Search for Clients"
        fetchInitialItems={fetchInitialClients}
        onChange={this.props.onChange}
        selectedIds={this.props.clientIds}
        isDisabled={this.props.isDisabled}
        iconClass="fa fa-building-o"
    />;
  }

});

const fetchInitialClients = clientIds =>
    ajax.post({
      url: "entity-finder",
      json: {
        ids: clientIds.toJS(),
        entityType: "CLIENT"
      }
    });