import React from "react";
import Immutable from "immutable";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import getContainerDimensions from "react-dimensions";

import {TextButton} from "js/common/views/inputs/buttons";
import LoadingSpinner from "js/common/views/loading-spinner";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import GroupAndUserPicker from "js/common/views/inputs/group-and-user-picker/filterable-group-user-tree";
import SharedWithList from "js/common/views/sharing/shared-with-list";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";

const ShareConfig = getContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {
      containerHeight,
      reportSharingSettings,
      originalConfigShareData,
      hasReportSharingSettingsChanged,
      onCancelChangesRequest,
      onShareReportRequest,
      isLoading,
      shareReportError,
      shareReportSuccess,
      onSuccessTimeout,
      theme,
      config,
      isSharingReadOnly = false,
      isEditSharingPermissionEnabled = false,
    } = this.props;
    const sectionHeaderHeight = 26;
    const buttonAndHeaderAreaHeight = 100;
    const height = containerHeight - buttonAndHeaderAreaHeight;
    const containerStyle = {
      height: height,
      maxHeight: height,
      overflow: "auto"
    };
    const buttonStyle = {
      marginLeft: "1rem"
    };

    // NOTE: S&D does not use a Rata wrapper so we need to not explode if settings are still loading
    if (!reportSharingSettings || isLoading) {
      return null;
    }

    const currentUser = Users.getCurrentUser();
    const currentUserId = currentUser.get("id");

    const userPermission = reportSharingSettings
        .getIn(["users", currentUserId], undefined)

    const currentGroupId = currentUser.get("groupId");
    const groupHierarchy = Groups.getGroupBreadcrumbs(currentGroupId).map(g => g.id).reverse();

    const selectedUserIds = new Immutable.Set(reportSharingSettings.get("users", Immutable.Map()).keySeq());
    const selectedGroupIds = new Immutable.Set(reportSharingSettings.get("groups", Immutable.Map()).keySeq());

    let groupPermission = "VIEW";

    // Only check group hierarchy for permissions if edit is a viable option
    // and we have the original config (currently only passed in from dashboards)
    if(isEditSharingPermissionEnabled && originalConfigShareData) {
      groupPermission = groupHierarchy
          .map(groupId => originalConfigShareData.getIn(["groups", groupId]))
          .find(permission => permission !== undefined) ?? groupPermission;
    }

    const userCanEdit = userPermission === "EDIT" || (groupPermission === "EDIT" && userPermission !== "VIEW");
    const isItemOwnerOrEditor = config.get("ownerId") === currentUserId || userCanEdit;

    return (
        <div style={{height: "100%", overflow: "hidden", position: "relative"}}>
          {isLoading
              ? this.renderLoadingSpinner()
              : <div style={{display: "flex", maxWidth: "100%", paddingTop: "1rem", paddingBottom: "1rem"}}>
                <div style={{display: "flex", flexDirection: "column", width: "49.5%"}}>
                  <div style={{height: sectionHeaderHeight}}>
                    Select users and groups
                  </div>
                  <div style={containerStyle}>
                    <GroupAndUserPicker
                        shouldAutoFocus={false}
                        hideSpacer
                        testId="share-user-search"
                        selectedGroupIds={selectedGroupIds}
                        selectedUserIds={selectedUserIds}
                        onGroupClick={this.handleGroupSelect}
                        onUserClick={this.handleUserSelect}
                        showInvisibleUsers={true}
                        expandAll={true}
                        customContainerStyle={{padding: 0, borderBottom: "none !important"}}
                        identifyObservers={true}
                        includeCube19Users={false}
                        includeInactiveUsers={false}
                        disableChildrenOfSelectedGroups={false}
                        readOnly={isSharingReadOnly || !isItemOwnerOrEditor}
                    />
                  </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "49.5%", marginLeft: "1%"}}>
                  <div style={{height: sectionHeaderHeight}}>
                    Shared with
                  </div>
                  <div style={containerStyle}>
                    {reportSharingSettings.isEmpty()
                        ? <LoadingSpinner />
                        : <SharedWithList
                            owner={Users.getUser(config.get("ownerId"))}
                            readOnly={isSharingReadOnly || !isItemOwnerOrEditor}
                            configType={config.get("configType")}
                            sharedWith={reportSharingSettings}
                            onGroupPermissionChange={this.handleGroupPermissionChange}
                            onUserPermissionChange={this.handleUserPermissionChange}
                            onGroupUnshare={this.handleGroupUnshare}
                            onUserUnshare={this.handleUserUnshare}
                            onUnshareWithAllClick={this.removeAllSelectedGroupsAndUsers}
                            editEnabled={isEditSharingPermissionEnabled}
                        />}
                  </div>
                </div>
              </div>}
          <div style={{borderTop: `1px solid ${theme.palette.border.light}`, paddingTop: "0.3rem", textAlign: "right"}}>
            <TextButton
                testId="cancel-sharing-changes-button"
                label="Cancel"
                onClick={onCancelChangesRequest}
                disabled={!hasReportSharingSettingsChanged}
                style={buttonStyle} />
            <TextButton
                testId="save-sharing-changes-button"
                icon="check"
                iconType="bhi"
                type="primary"
                label={isLoading ? "Saving" : "Save"}
                onClick={onShareReportRequest}
                disabled={!hasReportSharingSettingsChanged || isLoading}
                style={buttonStyle} />
            <div style={{marginTop: "0.5rem"}}>
              {shareReportError && <ErrorMsg text={shareReportError} />}
              {shareReportSuccess &&
                  <SuccessMsg text={shareReportSuccess} onMessageTimeout={onSuccessTimeout} />}
            </div>
          </div>
        </div>
    );
  },

  renderLoadingSpinner() {
    return (
        <div style={{marginTop: "1rem"}}>
          <LoadingSpinner label="Loading Report Sharing Settings" />
        </div>
    );
  },

  handleGroupSelect(groupId) {
    const {reportSharingSettings, onReportSharingSettingsChange} = this.props;
    const groups = reportSharingSettings.get("groups");
    const defaultPermission = "VIEW";
    onReportSharingSettingsChange(
        reportSharingSettings
            .set("groups", groups.set(groupId, defaultPermission))
    );
  },

  handleUserSelect(userId) {
    const {reportSharingSettings, onReportSharingSettingsChange} = this.props;
    const users = reportSharingSettings.get("users");
    const defaultPermission = "VIEW";
    onReportSharingSettingsChange(
        reportSharingSettings
            .set("users", users.set(userId, defaultPermission))
    );
  },

  handleGroupPermissionChange(groupId, permission) {
    const {reportSharingSettings, onReportSharingSettingsChange} = this.props;
    const groups = reportSharingSettings.get("groups");
    const updatedGroups = groups.set(groupId, permission);
    onReportSharingSettingsChange(reportSharingSettings.set("groups", updatedGroups));
  },

  handleUserPermissionChange(userId, permission) {
    const {reportSharingSettings, onReportSharingSettingsChange} = this.props;
    const users = reportSharingSettings.get("users");
    const updatedUsers = users.set(userId, permission);
    onReportSharingSettingsChange(reportSharingSettings.set("users", updatedUsers));
  },

  handleUserUnshare(userId) {
    const {reportSharingSettings, onReportSharingSettingsChange} = this.props;
    const users = reportSharingSettings.get("users");
    const updatedUsers = users.delete(userId);
    onReportSharingSettingsChange(reportSharingSettings.set("users", updatedUsers));
  },

  handleGroupUnshare(groupId) {
    const {reportSharingSettings, onReportSharingSettingsChange} = this.props;
    const groups = reportSharingSettings.get("groups");
    const updatedGroups = groups.delete(groupId);
    onReportSharingSettingsChange(reportSharingSettings.set("groups", updatedGroups));
  },

  removeAllSelectedGroupsAndUsers() {
    const {reportSharingSettings, onReportSharingSettingsChange} = this.props;
    const newReportSharingSettings = reportSharingSettings
        .set("groups", reportSharingSettings.get("groups").clear())
        .set("users", reportSharingSettings.get("users").clear());
    onReportSharingSettingsChange(newReportSharingSettings);
  }

}));

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ShareConfig theme={theme} {...props} />;
};
