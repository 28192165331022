/** @jsxImportSource @emotion/react */
import React from "react";
import {betterMemo} from "js/common/utils/more-memo";
import {css} from "@emotion/react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const ChartTypeSelector = betterMemo({displayName: "ChartTypeSelector"}, ({componentId, onConfigChange, config}) => {
  const { theme } = React.useContext(CustomThemeContext);

  const switcherStyle = css`
        border-radius: 8px;
        border: 1px solid ${theme.palette.background.paper};
        box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        margin-right: 1rem;
        span {
          font-size: 16px;
          padding: 5px 10px;
          cursor: pointer;
          border-left: 1px solid ${theme.palette.background.paper};
          &:first-of-type {
            border-left: none;
          }
          &:hover {
            background: ${theme.palette.background.paper};
          }
        }
  `;

  return (
      <div css={switcherStyle}>
        <span
            data-test-id="disable-chart-button"
            onClick={() => onConfigChange(componentId, config.setIn(["chart", "position"], "NONE"))}>
          <i className="fa fa-th" style={{
            color: config.getIn(["chart", "position"]) === "NONE" || !config.getIn(["chart", "position"]) ? theme.palette.primary.main : theme.palette.text.main
          }}/>
        </span>
        <span
            data-test-id="split-chart-button"
            onClick={() => onConfigChange(componentId, config.setIn(["chart", "position"], "LEFT"))}
            style={{display: "flex", alignItems: "center"}}>
          <div className="split-icon" style={{overflow: "hidden"}}>
            <i className="bhi bhi-chart-bar" style={{
              color: config.getIn(["chart", "position"]) === "LEFT" ? theme.palette.primary.main : theme.palette.text.main,
              marginRight: -9
            }}/>
          </div>
          <div style={{
            background: config.getIn(["chart", "position"]) === "LEFT" ? theme.palette.primary.main : theme.palette.text.main,
            height: 18,
            margin: "0 2px",
            width: "1px"}} />
          <div className="split-icon" style={{overflow: "hidden"}}>
            <i className="fa fa-th" style={{
              marginLeft: -7,
              color: config.getIn(["chart", "position"]) === "LEFT" ? theme.palette.primary.main : theme.palette.text.main,
              position: "relative",
              bottom: -1}}/>
          </div>
        </span>
        <span
            data-test-id="chart-only-button"
            onClick={() => onConfigChange(componentId, config.setIn(["chart", "position"], "FULL"))}>
          <i className="bhi bhi-chart-bar" style={{
            color: config.getIn(["chart", "position"]) === "FULL" ? theme.palette.primary.main : theme.palette.text.main
          }}/>
        </span>
      </div>
  );
});

export default ChartTypeSelector;
