import * as Users from "js/common/users";
import * as numbers from "js/common/utils/numbers"

const pathToTotalValue = [ "kpi", "total", "value" ];

const sortEntries = (a, b, fn) => {
    const rankSort = fn(a,b);

    if (rankSort !== 0) {
        return rankSort;
    }

    const userA = Users.getUser(a.get("userId"));
    const userB = Users.getUser(b.get("userId"));

    return userA.get("fullName").localeCompare(userB.get("fullName"));
};

const rankByValue = (rankedKpiData) => {
    const sortFn = (a,b) => b.getIn(pathToTotalValue) - a.getIn(pathToTotalValue);
    const rankedKpiDataSortedByName = rankedKpiData.sort((a,b) => sortEntries(a, b, sortFn));

    let rank = 0;
    let skips = 0;
    let skip = false;
    let lastValue = -1;
    return rankedKpiDataSortedByName
        .map(kpiData => {
            const totalValue = kpiData.getIn(pathToTotalValue);
            if (totalValue !== lastValue) {
                rank++;
                if (skip) {
                    rank += skips;
                    skips = 0;
                }
                skip = false;
            } else {
                skips++;
                skip = true;
            }
            lastValue = totalValue;
            return kpiData.set("rank", rank);
        });
};

const rankByPercent = (rankedKpiData) => {
    const sortFn = (a,b) => calculatePercentage(b) - calculatePercentage(a);
    const rankedKpiDataSortedByName = rankedKpiData.sort((a,b) => sortEntries(a, b, sortFn));

    let rank = 0;
    let skips = 0;
    let skip = false;
    let lastValue = -1;
    const rankedKpiDataSortedByPercentage = rankedKpiDataSortedByName
        .map(kpiData => {
            const percentage = Math.floor(calculatePercentage(kpiData));
            if (percentage !== lastValue) {
                rank++;
                if (skip) {
                    rank += skips;
                    skips = 0;
                }
                skip = false;
            } else {
                skips++;
                skip = true;
            }
            lastValue = percentage;
            return kpiData.set("rank", rank);
        });
    return rankedKpiDataSortedByPercentage;
};

const calculatePercentage = kpiData => {
    const targetValue = numbers.roundTo(kpiData.getIn([ "kpi", "target", "value" ]),3);
    if (targetValue === 0) {
        return 0;
    } else {
        const totalValue = kpiData.getIn(pathToTotalValue);
        return (totalValue / targetValue) * 100;
    }
};

export {
    rankByValue,
    rankByPercent
};