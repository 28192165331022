import React from "react";

const SettingsPage = React.memo(({title, content}) => {
  return <div style={{margin: "15px 10px"}}>
    <h2 style={{fontSize: "1rem", marginBottom: 20}}><b>{title}</b></h2>
    {content}
  </div>
});

export default SettingsPage;
