import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {betterMemo} from "js/common/utils/more-memo";

/**
 * Common component for rendering a step number
 * Designed to be used in AdminHeader but can be used in any flex container
 */

const containerStyle = theme => ({
  width: "1.8em",
  height: "1.8em",
  lineHeight: "1.8em",
  border: `1px solid ${theme.palette.text.main}`,
  color: theme.palette.text.main,
  textAlign: "center",
  borderRadius: "50%"
});

const StepNumber = betterMemo(
    {displayName: "Step"},
    ({step}) => {
      const {theme} = React.useContext(CustomThemeContext);
      return (
          <div style={containerStyle(theme)}>
            {step}
          </div>);
    });

export default StepNumber;
