import React from "react";
import ReactTooltip from "react-tooltip";
import Immutable from "immutable";
import pure from "js/common/views/pure";
import {getLabelForApp} from "js/admin/users/app-assignment-labels";

import Checkbox from "js/common/views/inputs/checkbox";

export default pure(({allowedPerms, appAssignments, dataVisibility, landingPage, onChange, theme}) => {
  const singlePermission = appAssignments.filter(a => a !== "ADMIN").count() === 1;
  return (
      <div>
        <h3 style={{marginBottom: 5, color: theme.palette.primary.main, textTransform: "uppercase"}}>App Permissions</h3>
        {allowedPerms.map(app => {
          const requiresDataVisibility = appsRequiringAllDataVis.has(app) && dataVisibility !== "ALL";
          const hasPermission = appAssignments.has(app);
          const isLandingPage = app === landingPage;
          let tooltip = "";
          let disabled = false;
          if (hasPermission) {
            if (singlePermission) {
              tooltip = lastPermissionWarning;
              disabled = true;
            } else if (isLandingPage) {
              tooltip = landingPageWarning;
              disabled = true;
            }
          } else if (requiresDataVisibility) {
            tooltip = dataVisibilityWarning;
            disabled = true;
          }

          return (
              <div
                  key={app}
                  data-tip={!!tooltip}
                  data-for={app}>
                <Checkbox
                    label={getLabelForApp(app)}
                    style={{marginTop: "-12px"}}
                    checked={hasPermission}
                    onCheck={(e, isInputChecked) => {
                      onChange(isInputChecked ? appAssignments.add(app) : appAssignments.remove(app));
                    }}
                    disabled={disabled} />
                {!!tooltip && <ReactTooltip id={app}>
                  <span>{tooltip}</span>
                </ReactTooltip>}
              </div>);
        })}
      </div>);
});

const appsRequiringAllDataVis = Immutable.Set.of("BIGSCREENS", "USAGE");
const dataVisibilityWarning = "Requires 'User Sees' set to 'Everyone'";
const landingPageWarning = "Change the landing page to modify this permission";
const lastPermissionWarning = "Users must have at least one permission. To prevent activity please disable login or deactivate this user";
