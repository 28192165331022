import Error from "js/common/views/error";
import React from "react";

const ParentWarning = () => <Error
    text="Other metrics are based on this one. Changes here may have unintended side-effects on those other metrics."
    type="warn"
    style={{marginTop: 0}} />;

const ForwardReportInheritedConfig = () => <Error
    text="This metric inherits its forward report JSON config from its parent."
    type="warn"
    style={{marginTop: 0}} />;

export {
  ParentWarning,
  ForwardReportInheritedConfig
};
