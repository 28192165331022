import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";

export default createReactClass({

  propTypes: {
    tooltipHtml: ReactPropTypes.any,
    text: ReactPropTypes.string,
    position: ReactPropTypes.oneOf(["top", "bottom", "left", "right"]).isRequired,
    showOnHover: ReactPropTypes.bool,
    styleType: ReactPropTypes.oneOf(["yellow", "dark", "light"]),
    customStyle: ReactPropTypes.object,
    maxWidth: ReactPropTypes.number,
    wrap: ReactPropTypes.bool,
    disable: ReactPropTypes.bool,
    textSize: ReactPropTypes.number,
    width: ReactPropTypes.number,
    pointerStyle: ReactPropTypes.object,
  },

  getInitialState() {
    return {
      isVisible: !this.props.showOnHover
    };
  },

  getDefaultProps() {
    return {
      disable: false,
      showOnHover: true,
      styleType: "light",
      pointerStyle: {},
    };
  },

  render() {
    if (this.props.disable) {
      return (<div>{this.props.children}</div>);
    } else {
      const closeTooltipBtnStyle = {
        position: "relative",
        zIndex: 9999999,
        marginLeft: "10px",
        cursor: "pointer"
      };
      return (
          <div
              style={{position: "relative", ...this.props.customStyle}}
              onMouseOver={this.handleMouseIn}
              onMouseOut={this.handleMouseOut}>
            <div style={this.getTooltipStyle()}>
              {this.props.tooltipHtml && this.props.tooltipHtml()}
              {this.props.text}
              {!this.props.showOnHover &&
              <span onClick={this.closeTooltip} style={closeTooltipBtnStyle}>
                                <i className="fa fa-times-circle" />
                            </span>
              }
              <div style={{...this.getTooltipArrowStyle(), ...this.props.pointerStyle}} />
            </div>
            {this.props.children}
          </div>
      );
    }
  },

  getTooltipStyle() {
    const tooltipStyleByPosition = {
      top: {
        top: "0",
        left: "50%",
        transform: "translateX(-50%) translateY(-100%)",
        textAlign: "center"
      },
      bottom: {
        top: "30px",
        left: "50%",
        transform: "translateX(-50%)",
        textAlign: "center"
      },
      right: {
        left: "100%",
        marginLeft: "10px"
      },
      left: {
        transform: "translateX(-100%)",
        marginLeft: "-10px"
      }
    };
    return {
      display: this.state.isVisible ? "inline" : "none",
      maxWidth: this.props.maxWidth,
      position: "absolute",
      whiteSpace: this.props.wrap ? null : "nowrap",
      overflowWrap: "break-word",
      padding: "10px",
      borderRadius: "5px",
      fontWeight: 400,
      fontSize: this.props.textSize ? `${this.props.textSize}px` : "0.8rem",
      zIndex: 99999,
      ...this.props.customStyle,
      ...tooltipStyleByPosition[this.props.position],
      ...tooltipByStyleType[this.props.styleType],
      width: this.props.width
    };
  },

  getTooltipArrowStyle() {
    const tooltipArrowStyleByPosition = {
      top: {
        transform: "rotate(45deg) translateX(0%) translateY(50%)",
        left: "50%",
        bottom: 0
      },
      bottom: {
        transform: "rotate(45deg) translateX(-50%) translateY(0%)",
        left: "50%",
        top: 0
      },
      right: {
        transform: "rotate(45deg) translateX(-100%) translateY(-30%)",
        left: 3,
      },
      left: {
        transform: "rotate(45deg) translateX(-30%) translateY(-100%)",
        right: 3
      }
    };
    return {
      background: "#0e0e0e",
      position: "absolute",
      width: "12px",
      height: "12px",
      zIndex: -1,
      ...tooltipByStyleType[this.props.styleType],
      ...tooltipArrowStyleByPosition[this.props.position],
      boxShadow: "none !important"
    };
  },

  handleMouseIn() {
    if (this.props.disable) {
      return;
    }
    this.props.showOnHover && this.setState({isVisible: true});
  },

  handleMouseOut() {
    if (this.props.disable) {
      return;
    }
    this.props.showOnHover && this.setState({isVisible: false});
  },

  closeTooltip() {
    this.setState({isVisible: false});
  }
});

const tooltipByStyleType = {
  dark: {
    background: "#0e0e0e",
    color: "white"
  },

  yellow: {
    background: "#f9ec33",
    color: "black"
  },

  light: {
    background: "#fff",
    color: "black",
    boxShadow: "1px 1px 10px rgba(0,0,0,0.3)"
  }
};
