import React from "react";
import {Button} from "@mui/material";
import Immutable from "immutable";
import moment from "moment";
import FileSaver from "browser-filesaver";

import Err from "js/common/views/error";
import DownloadReferenceButton from "js/admin/common/download-reference-button";
import UploadedFilesTable from "js/admin/common/uploaded-files-table";
import AdminHeader from "js/admin/common/admin-header";
import Step from "js/admin/common/step-number";
import Hint from "js/admin/common/hint";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import MultipleUserGroupPicker from "js/common/views/inputs/multiple-user-group-picker/multiple-user-group-picker";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {TextButton} from "js/common/views/inputs/buttons";
import {CsvUpload} from "js/common/csv-upload";
import {betterMemo} from "js/common/utils/more-memo";
import * as Ajax from "js/common/ajax";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";

const BackupAndDownloadContent = betterMemo(
    {displayName: "BackupAndDownloadContent"},
    () => {
      const [selectedUserIds, setSelectedUserIds] = React.useState([]);
      const [selectedGroupIds, setSelectedGroupIds] = React.useState([]);
      const [selectedKpiIds, setSelectedKpiIds] = React.useState([]);
      const [selectedStartDate, setSelectedStartDate] = React.useState(moment().subtract(1, "months"));
      const [selectedEndDate, setSelectedEndDate] = React.useState(moment());
      const [dateErrorMessage, setDateErrorMessage] = React.useState("");
      const [dateValid, setDateValid] = React.useState(true);
      const [isLoading, setIsLoading] = React.useState(false);

      React.useEffect(() => {
        // Check if dates are valid
        if (selectedStartDate && selectedEndDate) {
          if (selectedStartDate.isValid() && selectedEndDate.isValid() && !selectedEndDate.isAfter(selectedStartDate)) {
            setDateErrorMessage("Start Date must be before end date.");
            setDateValid(false);
          } else {
            setDateValid(true);
          }
        }
      }, [selectedStartDate, selectedEndDate]);

      return <div style={{display: "flex", flexDirection: "column", padding: "0 1rem 1rem 1rem"}}>
        <Hint icon={"info"}>
          <span>We recommend that you use the backup all targets button to backup your current targets before you begin.</span>
        </Hint>

        <p style={{fontSize: "0.85rem"}}>Download reference files to match the corresponding ID values needed for the
          bulk update using the Download Reference Files button.</p>

        <div style={{display: "flex", gap: "0 0.8em", margin: "0 0 1rem 0"}}>
          <Button
              style={{padding: "4px 14px"}}
              variant="outlined"
              disabled={isLoading}
              onClick={() => {
                setIsLoading(true);
                handleDownloadAll(setIsLoading);
              }}>
            {isLoading ? "Downloading" : "Backup all targets"}
          </Button>
          <DownloadReferenceButton options={downloadReferenceOptions} />
        </div>

        <p style={{fontSize: "0.85rem"}}>Modify the targets file by adding, updating, or deleting targets to prepare it
          for uploading for bulk updates. New targets can be added as a new row in the file. Existing targets can be
          updated or flagged for deletion.</p>

        <p style={{fontSize: 13, marginBottom: "0.5rem", fontWeight: 600}}>Filter by</p>
        <div style={{display: "flex", gap: "0 0.8em"}}>
          <DatePicker
              style={{width: 345}}
              value={selectedStartDate}
              onDateChange={(date) => setSelectedStartDate(date)}
          />
          <DatePicker
              style={{width: 345}}
              value={selectedEndDate}
              onDateChange={(date) => setSelectedEndDate(date)}
          />
          {!dateValid && <Err text={dateErrorMessage} />}
        </div>
        <div style={{display: "flex", gap: "0 0.8em", margin: "1rem 0 0 -1px"}}>
          <div style={{width: 300}}>
            <KpiPicker
                disableNonTargetKpis={true}
                kpis={Immutable.fromJS(KpiRepo.getAll().toJSON())}
                selectedKpiIds={selectedKpiIds.size > 0 ? selectedKpiIds : null}
                onChange={(kpis) => setSelectedKpiIds(kpis)}
                multiSelect={true} />
          </div>
          <div style={{width: 300}}>
            <MultipleUserGroupPicker
                type="GROUP"
                onChange={(groupIds) => {
                  setSelectedGroupIds([]);
                  setSelectedGroupIds(groupIds);
                }}
                expandAll={true} />
          </div>
          <div style={{width: 300}}>
            <MultipleUserGroupPicker
                type="USER"
                onChange={(userIds) => {
                  setSelectedUserIds([]);
                  setSelectedUserIds(userIds);
                }}
                groupsClickable={false}
            />
          </div>
          <TextButton
              icon="download"
              label={isLoading ? "Downloading" : "Download"}
              type="primary"
              disabled={!selectedStartDate.isValid() || !selectedEndDate.isValid() || !dateValid || isLoading}
              onClick={() => {
                setIsLoading(true);
                handleFilteredDownload(
                    selectedStartDate,
                    selectedEndDate,
                    selectedKpiIds,
                    selectedGroupIds,
                    selectedUserIds,
                    setIsLoading);
              }}
          />
        </div>
        <div>
        </div>

      </div>;
    }
);

const handleDownloadAll = (setIsLoading) => Ajax
    .get({url: "/targets/bulk/backup-targets.csv", returnFileName: true})
    .then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      setIsLoading(false);
    });

const handleFilteredDownload = (startDate, endDate, kpiIds, groupIds, userIds, setIsLoading) => {

  if (!startDate && !endDate && kpiIds.isEmpty() && groupIds.length === 0 && userIds.length === 0) {
    return handleDownloadAll(setIsLoading);
  }

  return Ajax
      .post({
        url: "/targets/bulk/targets.csv",
        json: {
          startDate: startDate.isValid() ? startDate.format("YYYY-MM-DD") : undefined,
          endDate: endDate.isValid() ? endDate.format("YYYY-MM-DD") : undefined,
          kpiIds: kpiIds.size > 0 ? kpiIds.toJS() : undefined,
          groupIds: groupIds.length > 0 ? groupIds : undefined,
          userIds: userIds.length > 0 ? userIds : undefined
        },
        returnFileName: true
      })
      .then(response => {
        downloadCsv(Immutable.fromJS(response.body), response.fileName);
        setIsLoading(false);
      });
};

const downloadCurrencyCodes = (setIsLoading) => Ajax
    .get({url: "/targets/bulk/currency-codes.csv", returnFileName: true})
    .then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      setIsLoading(false);
    });

const downloadGroups = (setIsLoading) => Ajax
    .get({url: "/targets/bulk/groups.csv", returnFileName: true})
    .then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      setIsLoading(false);
    });

const downloadUsers = (setIsLoading) => Ajax
    .get({url: "/targets/bulk/users.csv", returnFileName: true})
    .then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      setIsLoading(false);
    });

const downloadMetrics = (setIsLoading) => Ajax
    .get({url: "/targets/bulk/kpis.csv", returnFileName: true})
    .then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      setIsLoading(false);
    });

const downloadReferenceOptions = Immutable.fromJS([
  {
    label: "Download Currency Codes",
    onClick: downloadCurrencyCodes
  },
  {
    label: "Download Metrics",
    onClick: downloadMetrics
  },
  {
    label: "Download Users",
    onClick: downloadUsers
  },
  {
    label: "Download Groups",
    onClick: downloadGroups
  }
]);

const downloadCsv = (csv, header) => {
  const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
  FileSaver.saveAs(blob, header);
};

const BulkUpdateTargetsTab = () => {
  const {theme} = React.useContext(CustomThemeContext);
  const [selectedJobId, setSelectedJobId] = React.useState();
  return <>
    <AdminHeader><Step step="1" />Backup & Download</AdminHeader>
    <BackupAndDownloadContent />
    <AdminHeader><Step step="2" />Upload</AdminHeader>
    <CsvUpload
        onUploadSuccess={(request) => setSelectedJobId(request.body.id)}
        theme={theme}
        requiresLocale={false}
        csvLabel="Targets"
        uploadUrl="/ApplicationLayer/targets/bulk/upload" />
    <AdminHeader><Step step="3" />Status of uploaded files</AdminHeader>
    <UploadedFilesTable type="targets" url="targets/bulk/jobs" selectedJobId={selectedJobId} />
  </>;
};

export default BulkUpdateTargetsTab;