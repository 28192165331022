import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import moment from "moment";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";

import PureRenderMixin from "react-addons-pure-render-mixin";
import Dropzone from "js/common/views/inputs/dropzone";
import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";
import { Layout, Row, Column } from "js/common/views/foundation-column-layout";
import { TextButton } from "js/common/views/inputs/buttons";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import LoadingSpinner from "js/common/views/loading-spinner";
import BulkUpdateUserGuide from "js/admin/tags/react-bulk-update-user-guide";
import TagRecalculationJobs from "js/admin/tags/react-tag-recalculation-jobs";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import JobErrorsTable from "js/admin/job-errors-table";
import SelectField from "@mui/material/Select";
import * as Ajax from "js/common/ajax";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import * as Locales from "js/common/locales";
import * as Users from "js/common/users";
import { endsWith, capitaliseWords } from "js/common/utils/strings";
import { formatStorageSize } from "js/common/utils/numbers";

import dropzoneArrow from "img/dropzone-arrow.png"
import { MenuItem } from '@mui/material';
import { apiUrl } from "js/app-configuration";
import * as Branding from "js/common/branding-constants"

const buttonSpacing = {
    marginLeft: "0.5rem",
    marginRight: "0.5rem"
};

const BulUpdatePage = createReactClass({

    mixins: [ PureRenderMixin ],

    getInitialState() {
        return {
            file: null,
            isUserGuideOpen: false,
            success: null,
            error: null,
            uncaughtError: false,
            isLoadingFileUploadJobs: false,
            fileUploadJobs: Immutable.List(),
            selectedFileUploadJobId: null,
            isLoadingTagRecalculationJobs: false,
            tagRecalculationJobs: Immutable.List(),
            selectedTagRecalculationJobId: null,
            uploadingFile: false,
            loadingLocales: false,
            idToLocale: Immutable.Map(),
            selectedLocaleId: Users.getInherited(Users.getCurrentUser(), x => x.get("localeId"))
        };
    },

    componentDidMount() {
        this.setState({
            isLoadingFileUploadJobs: true,
            isLoadingTagRecalculationJobs: true,
            loadingLocales: true
        });
        this.loadJobs();
        this.jobsLoaderIntervalId = window.setInterval(() => this.loadJobs(), 30000);

        Locales
            .loadAll()
            .then(locales => this.setState({
                loadingLocales: false,
                idToLocale: locales.groupBy(l => l.get("id")).map(ls => ls.first())
            }));
    },

    componentWillUnmount() {
        window.clearInterval(this.jobsLoaderIntervalId);
    },

    loadJobs() {
        loadFileUploadJobs()
            .then(fileUploadJobs => {
                this.setState({
                    fileUploadJobs,
                    isLoadingFileUploadJobs: false
                });
            });
        loadTagHistoryRecalculationJobs()
            .then(tagRecalculationJobs => {
                this.setState({
                    tagRecalculationJobs,
                    isLoadingTagRecalculationJobs: false
                });
            });
    },

    render() {
        if (this.state.loadingLocales || this.state.idToLocale.isEmpty()) {
            return <LoadingSpinner />;
        }

        const {
            isLoadingFileUploadJobs,
            selectedFileUploadJobId,

            isLoadingTagRecalculationJobs,
            tagRecalculationJobs,
            selectedTagRecalculationJobId,
            isUserGuideOpen,
            file,
            error,
            success
        } = this.state;

        return (
            <div>
                <div>
                    <AdminHeader>
                        <Icon icon="category-tags" />
                        Update in bulk
                    </AdminHeader>
                    {this.state.uploadingFile
                        ? <LoadingSpinner />
                        : <BulkUpdateSection
                            theme={this.props.theme}
                            file={file}
                            onFileDrop={this.handleFileDrop}
                            onRemoveFileClick={this.handleRemoveFileClick}
                            onUploadFileClick={this.handleUploadFileClick}
                            idToLocale={this.state.idToLocale}
                            selectedLocaleId={this.state.selectedLocaleId}
                            onChangeLocaleId={selectedLocaleId => this.setState({selectedLocaleId})}
                            isUserGuideOpen={isUserGuideOpen}
                            onUserGuideToggleClick={this.toggleUserGuideVisibility}
                            error={error}
                            success={success}
                            onSuccessMessageTimeout={() => this.setState({ success: null })} />}
                </div>
                <div>
                    <AdminHeader>
                        <Icon icon="category-tags" />
                        Status of uploaded files
                    </AdminHeader>
                    <Layout allSmall={12} rowStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
                        {isLoadingFileUploadJobs ? <LoadingSpinner /> : this.renderFileUploadJobsTable()}
                    </Layout>
                    {selectedFileUploadJobId && this.renderFileUploadJobDetails()}
                </div>
                <TagRecalculationJobs
                    isLoading={isLoadingTagRecalculationJobs}
                    jobs={tagRecalculationJobs}
                    selectedJobId={selectedTagRecalculationJobId}
                    onJobSelect={jobId => this.setState({ selectedTagRecalculationJobId: jobId })} />
            </div>);
    },

    toggleUserGuideVisibility() {
        this.setState({
            isUserGuideOpen: !this.state.isUserGuideOpen
        });
    },

    renderFileUploadJobsTable() {
        const columns = [ "ID", "File Name", "Uploaded By", "Uploaded On", "Status" ];
        const rows = this.state.fileUploadJobs
            .map(fileUploadJobToRow)
            .toJS();
        return(
            <div>
                <Hint style={{margin: "1rem"}}>
                    <Icon icon="info" style={{color: this.props.theme.palette.hints.text}} />
                    Click on a row in the table below to see further details about that file upload job, e.g. a detailed error report (if any errors are found).
                </Hint>
                <SimpleDataTable
                    columns={columns}
                    rows={rows}
                    onCellClick={this.handleFileUploadJobsRowClick}
                    initialSortDirection="DESC" />
            </div>
        );
    },

    handleFileUploadJobsRowClick(cellValue, row) {
        const jobId = row[0].originalValue;
        this.setState({
            selectedFileUploadJobId: jobId
        });
    },

    renderFileUploadJobDetails() {
        const { fileUploadJobs, selectedFileUploadJobId } = this.state;
        const index = fileUploadJobs.findIndex(j => j.get("id") === selectedFileUploadJobId);
        const job = fileUploadJobs.get(index);
        const jobId = job.get("id");
        if (job.has("errors") && !job.get("errors").isEmpty()) {
            return (
                <Layout allSmall={12}>
                    <p>
                        <i className="fa fa-exclamation-triangle" style={{ color: "#f00", paddingRight: 6 }} />
                        The following errors were found in the file uploaded for job ID {jobId}
                    </p>
                    <JobErrorsTable errors={job.get("errors")} />
                </Layout>
            );
        } else {
            const labelsByStatus = {
                PENDING: `is queued for processing`,
                PROCESSING: `is currently being processed`,
                FAILED: `was not processed. ${Branding.submitTicketString}`,
                COMPLETED: `was processed successfully`
            };
            return (
                <Layout allSmall={12} rowStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <p>{`The file uploaded for job ID ${jobId} ${labelsByStatus[job.get("status")]}`}</p>
                </Layout>
            );
        }
    },

    renderTagRecalculationJobsTable() {
        const columns = [ "ID", "Source", "Started By", "Started On", "Status" ];
        const rows = this.state.tagRecalculationJobs
            .map(tagRecalculationJobToRow)
            .toJS();
        return(
            <div>
                <Hint>
                    <Icon icon="info" style={{color: this.props.theme.palette.hints.text}} />
                    Click on a row in the table below to see further details about that Tag recalculation job, e.g. a detailed error report (if any errors are found).
                </Hint>
                <SimpleDataTable
                    columns={columns}
                    rows={rows}
                    onCellClick={this.handleTagRecalculationJobsRowClick}
                    initialSortDirection="DESC" />
            </div>
        );
    },

    handleTagRecalculationJobsRowClick(cellValue, row) {
        const jobId = row[0].originalValue;
        this.setState({
            selectedTagRecalculationJobId: jobId
        });
    },

    handleFileDrop(file) {
        this.setState({
            file,
            error: null,
            success: null
        }, () => this.validateFile());
    },

    validateFile() {
        const { file } = this.state;
        const fileName = file.name;
        if (fileName === "tags.csv" || fileName === "users.csv" || fileName === "groups.csv") {
            this.setState({
                error: `You can only upload the 'tags-history.csv' file, the '${fileName}' file is for reference only.`
            });
        } else if (!endsWith(".csv", fileName.toLowerCase())) {
            this.setState({
                error: `You can only upload CSV (.csv) files, '${fileName}' is not a CSV file.`
            });
        }
    },

    handleRemoveFileClick(e) {
        e.stopPropagation();
        this.setState({
            file: null,
            error: null,
            success: null
        });
    },

    handleUploadFileClick(e) {
        this.setState({uploadingFile: true});

        e.stopPropagation();
        window.superagent
            .post(apiUrl + "/ApplicationLayer/tags/upload")
            .withCredentials()
            .field("locale-code", this.state.idToLocale.get(this.state.selectedLocaleId).get("code"))
            .attach("file", this.state.file)
            .end((error, response) => {
                if (error) {
                    if (response.status === 403) {
                        this.setState({
                            uploadingFile: false,
                            error: "You cannot upload a new file whilst an old file is still being processed"
                        });
                    } else {
                        this.setState({
                            uploadingFile: false,
                            error: `An unexpected error has occurred. ${Branding.submitTicketString}`
                        });
                    }
                } else {
                    this.setState({
                        file: null,
                        uploadingFile: false,
                        success: "Your file has been uploaded"
                    });
                }
            });
    }

});

const BulkUpdateSection = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            file,
            onFileDrop,
            isUserGuideOpen,
            onUserGuideToggleClick,
            error,
            success,
            onSuccessMessageTimeout,
            theme
        } = this.props;
        const buttonStyle = {
            ...buttonSpacing,
            paddingLeft: "0.25rem",
            paddingRight: "0.25rem"
        };
        const localeCode = this.props.idToLocale.get(this.props.selectedLocaleId).get("code");
        return (
            <div style={{ paddingBottom: "1rem" }}>
                <Hint style={{margin: "0 1rem 1rem 1rem"}}>
                    <Icon icon="info" style={{color: theme.palette.hints.text}} />
                    You can use this bulk update feature to easily make multiple changes by uploading a CSV formatted spreadsheet file with all your Users' and/or Groups' Tags history changes.
                </Hint>
                <Layout allSmall={12} allLarge={10} smallCentered={true}>
                    <TextButton
                        icon="info-circle"
                        label={`${isUserGuideOpen ? "Hide" : "Show"} User Guide`}
                        onClick={onUserGuideToggleClick}
                        iconStyle={{ fontSize: "13px" }}
                        labelStyle={{ fontSize: "12px" }}
                        style={{ height: "26px", lineHeight: 1, float:"right" }} />
                </Layout>
                <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
                    {isUserGuideOpen && <BulkUpdateUserGuide />}
                </VelocityTransitionGroup>
                <Row style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Column small={12} smallCentered={true}>
                        <SelectField variant="standard"
                            value={this.props.selectedLocaleId}
                            onChange={e => this.props.onChangeLocaleId(e.target.value)}
                            style={{verticalAlign: "middle"}}>
                          {this.props.idToLocale.valueSeq().sortBy(l => l.get("name")).map(localeToMenuItem)}
                        </SelectField>
                        <TextButton
                            icon="download"
                            label="Example Tags History"
                            link={apiUrl + "/ApplicationLayer/tags/tags-history-example.csv?localeCode=" + localeCode}
                            style={buttonStyle} />
                        <TextButton
                            icon="download"
                            label="Tags History"
                            link={apiUrl + "/ApplicationLayer/tags/tags-history.csv?localeCode=" + localeCode}
                            style={buttonStyle} />
                        <TextButton
                            icon="tags"
                            label="Tags List"
                            link={apiUrl + "/ApplicationLayer/tags/tags.csv"}
                            style={buttonStyle} />
                        <TextButton
                            icon="user"
                            label="Users List"
                            link={apiUrl + "/ApplicationLayer/group-history/users.csv"}
                            style={buttonStyle} />
                        <TextButton
                            icon="group"
                            label="Groups List"
                            link={apiUrl + "/ApplicationLayer/group-history/groups.csv"}
                            style={buttonStyle} />
                    </Column>
                </Row>
                <Layout
                    allSmall={12}
                    allLarge={10}
                    smallCentered={true}
                    rowStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <Dropzone
                        onDrop={files => onFileDrop(files[0])}
                        multiple={false}
                        disablePreview={true}
                        className="react-dropzone"
                        activeClassName="react-dropzone-active"
                        style={{
                            minHeight: file ? 40 : 120,
                            borderStyle: file ? "solid" : "dashed",
                            backgroundColor: file ? "rgba(0,0,0,0.1)" : "transparent",
                            padding: 5
                        }}
                        activeStyle={{ borderStyle: "dashed" }}>
                        {file ? this.renderFileUploadPreview() : this.renderDropzoneHelpMessage()}
                    </Dropzone>
                    {error && <ErrorMsg text={error} />}
                    {success && <SuccessMsg text={success} onMessageTimeout={onSuccessMessageTimeout} />}
                </Layout>
            </div>
        );
    },

    renderDropzoneHelpMessage() {
        return (
            <div style={{ textAlign:"center",color:"#bbb",paddingTop:"1em" }}>
                <img src={dropzoneArrow} />
                <p>Drop your Tags History file here or click to choose a file.</p>
            </div>
        );
    },

    renderFileUploadPreview() {
        const { file, error, onRemoveFileClick, onUploadFileClick } = this.props;
        const fileName = file.name;
        const fileSize = file.size;
        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "45%" }}>
                    <i className="fa fa-file-excel-o" />&nbsp;{fileName}
                </div>
                <div style={{ width: "10%" }}>
                    {`${formatStorageSize(fileSize)}`}
                </div>
                <div>
                    <TextButton
                        icon="remove"
                        label="Remove"
                        onClick={onRemoveFileClick}
                        style={buttonSpacing} />
                    <TextButton
                        type="primary"
                        icon="upload"
                        label="Upload"
                        disabled={!!error}
                        onClick={onUploadFileClick}
                        style={buttonSpacing} />
                </div>
            </div>
        );
    }

});

const fileUploadJobToRow = job => {
    const uploadedFileName = job.getIn([ "payload", "originalFileName" ]);
    const uploadedBy = getUserFullName(job.get("userId"));
    const uploadedOn = timestampToDateTime(job.get("startTimestamp"));
    const lastStatusChangeOn = timestampToDateTime(job.get("lastStatusChangeTimestamp"));
    const uploadStatus = `Moved to ${job.get("status")} on ${lastStatusChangeOn}`;
    return [
        job.get("id"),
        uploadedFileName,
        uploadedBy,
        uploadedOn,
        uploadStatus
    ];
};

const tagRecalculationJobToRow = job => {
    const source = job.getIn([ "payload", "source" ]).replace(/_/g, " ");
    const startedBy = getUserFullName(job.get("userId"));
    const startedOnDateTime = timestampToDateTime(job.get("startTimestamp"));
    const lastStatusChangeOn = timestampToDateTime(job.get("lastStatusChangeTimestamp"));
    const status = `Moved to ${job.get("status")} on ${lastStatusChangeOn}`;
    return [
        job.get("id"),
        capitaliseWords(source),
        startedBy,
        startedOnDateTime,
        status
    ];
};

const timestampToDateTime = (timestamp, pattern = "YYYY-MM-DD HH:mm:ss") => {
    const dateTime = moment(timestamp).local();
    return dateTime.format(pattern);
};

const getUserFullName = userId => Users.getUser(userId).get("fullName");

const loadFileUploadJobs = () => Ajax
    .get({ url: "tags/jobs" })
    .then(jobs => Immutable.fromJS(jobs).map(parsePayload));

const loadTagHistoryRecalculationJobs = () => Ajax
    .get({ url: "async-calculation-jobs" })
    .then(jobs => Immutable.fromJS(jobs).map(parsePayload));

const parsePayload = job => {
    const payloadJson = JSON.parse(job.get("payload"));
    return job.set("payload", Immutable.fromJS(payloadJson));
};

const localeToMenuItem = locale => <MenuItem
  key={locale.get("id")}
  value={locale.get("id")}>
  {`${locale.get("name")} Dates - ${moment().locale(locale.get("code")).localeData().longDateFormat("L")}`}
</MenuItem>;

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <BulUpdatePage theme={theme} {...props} />;
};
