import React from "react";
import Immutable from "immutable";
import {setQueryParam} from "js/admin/kpis/edit-kpis/utils";
import Select from "js/common/views/inputs/immutable-react-select";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";

const OwnershipSection = React.memo(({
  theme,
  kpi,
  onKpiChange,
  typeToGroupingEntity,
  requiresExplanationUpdate,
  getExplanationWarning
}) => {
  const ownedByLinkedEntities = formatFromParamString(kpi.getIn(
      ["queryParams", "ownedByLinkedEntities"],
      Immutable.List()));
  const rootGroupingEntity = kpi.get("readOnlyRootGroupingEntity");
  let linkedEntities = typeToGroupingEntity
      .get(rootGroupingEntity)
      .get("directJoinsTo");
  if (rootGroupingEntity === "PLACEMENT_SPLIT") {
    linkedEntities = linkedEntities
        .concat(typeToGroupingEntity.getIn(["PLACEMENT", "directJoinsTo"]))
        .toSet()
        .toList();
  };
  const linkedEntityOptions = linkedEntities
      .filter(entity => typeToGroupingEntity.get(entity).get("filterableByDate"))
      .map(entity => Immutable.Map({value: entity, label: entity}))
      .push(Immutable.Map({value: "RECORD", label: "RECORD"}));

  const unusedEntityOptions = linkedEntityOptions.filter(entity => !ownedByLinkedEntities.map(entity => entity.get(
      "entity")).contains(entity.get("value")));

  const onQueryParamChange = (key, value) => onKpiChange(setQueryParam(kpi, key, value));

  const onLinkedEntityChange = (linkedEntities) => {
    onQueryParamChange("ownedByLinkedEntities", formatToParamString(linkedEntities));
  };

  const handleAddRow = () => {
    const entity = unusedEntityOptions.first().get("value");
    const updatedLinkedEntities = ownedByLinkedEntities.push(Immutable.Map({entity: entity, ownedBy: "OWNER"}));
    onLinkedEntityChange(updatedLinkedEntities);
  };

  const handleDeleteRow = (index) => {
    if (ownedByLinkedEntities.size > 1) {
      onLinkedEntityChange(ownedByLinkedEntities.delete(index));
    } else {
      onQueryParamChange("ownedByLinkedEntities", null);
    }
  };

  const handleEntityChange = (value, index) => {
    onLinkedEntityChange(ownedByLinkedEntities.setIn([index, "entity"], value));
  };

  const handleOwnedByChange = (value, index) => {
    onLinkedEntityChange(ownedByLinkedEntities.setIn([index, "ownedBy"], value));
  };

  const ownershipOptions = Immutable.fromJS([
    {value: "OWNER", label: "Record owner"},
    {value: "ASSIGNEE", label: "Assignee"},
    {value: "ANY", label: "Record owner or assignee"}
  ]);

  return <div className="TESTCAFE-ownership-section">
    {requiresExplanationUpdate && <div style={{padding: "0 10px"}}>
      {getExplanationWarning()}
    </div>}
    <div
        style={{
          backgroundColor: theme.themeId === "light" ? "transparent" : "#445",
          padding: "0.6rem",
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start"
        }}>
      Owned By Linked Entities
    </div>
    <div style={{marginBottom: 20, padding: "10px 20px"}}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{fontSize: "0.9rem", paddingBottom: 0, color: "#999"}}>Entity Path</TableCell>
            <TableCell style={{fontSize: "0.9rem", paddingBottom: 0, color: "#999"}}>Owned By</TableCell>
            <TableCell style={{width: 180}} align="center">
              <TextButton
                  icon="plus"
                  label="Add Row"
                  disabled={unusedEntityOptions.size === 0}
                  onClick={handleAddRow}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ownedByLinkedEntities.map((row, index) => {
            const availableEntities = linkedEntityOptions.filter(option => unusedEntityOptions.contains(option)
                || option.get("value")
                === row.get("entity"));
            return <TableRow key={row.get("entity")}>
              <TableCell>
                <Select
                    selectedValue={row.get("entity")}
                    onChange={value => handleEntityChange(value, index)}
                    options={availableEntities}
                    isMulti={false}
                    isClearable={false}
                />
              </TableCell>
              <TableCell>
                <Select
                    selectedValue={row.get("ownedBy")}
                    onChange={value => handleOwnedByChange(value, index)}
                    isMulti={false}
                    options={ownershipOptions}
                    isClearable={false}
                />
              </TableCell>
              <TableCell align="center">
                <IconButton
                    style={{fontSize: "1rem"}}
                    type="alert"
                    icon="trash"
                    onClick={() => handleDeleteRow(index)}
                />
              </TableCell>
            </TableRow>;
          })}
        </TableBody>
      </Table>
    </div>
  </div>;
});

const formatFromParamString = (linkedEntities) => {
  return linkedEntities.map(str => Immutable.Map({
    entity: str.substr(0, str.indexOf(":")),
    ownedBy: str.substr(str.indexOf(":") + 1, str.length)
  }));
};

const formatToParamString = (linkedEntities) => {
  return linkedEntities.map(map => map.get("entity") + ":" + map.get("ownedBy"));
};

export default OwnershipSection;