import { getWhiteThemeColorsList, getDefaultThemeColorsList } from "js/common/colors-list";
import { isMobile } from "js/common/ua-parser";
import Highcharts from "highcharts";
import ReactHighcharts from "react-highcharts/ReactHighcharts";
import ReactHighstock from "react-highcharts/ReactHighstock";

const $ = window.$;

const HCDefaults = $.extend(true, {}, Highcharts.getOptions(), {});
const setHighchartsDefaultTheme = () => {

    const options = {
        ...HCDefaults,
        ...{ colors: getWhiteThemeColorsList() }
    };

    Highcharts.setOptions(options);
    ReactHighcharts.Highcharts.setOptions(options);
    ReactHighstock.Highcharts.setOptions(options);
};

const setC19Theme = theme => {
    Highcharts.setOptions(C19_THEME(theme));
    ReactHighcharts.Highcharts.setOptions(C19_THEME(theme));
    ReactHighstock.Highcharts.setOptions(C19_THEME(theme));
};

const C19_THEME = theme => ({
    lang: {
        rangeSelectorZoom: isMobile() ? "Zoom" : "Zoom Range",
        thousandsSep: ","
    },
    colors: getDefaultThemeColorsList(),
    chart: {
        style: {
            backgroundColor: "transparent",
            fontFamily: theme.typography.fontFamily,
        },
        plotBorderColor: theme.palette.text.primary
    },
    title: {
        style: {
            color: theme.palette.text.primary,
            textTransform: "uppercase",
            fontSize: "20px"
        }
    },
    subtitle: {
        style: {
            color: theme.palette.text.primary,
            textTransform: "uppercase"
        }
    },
    xAxis: {
        gridLineColor: theme.palette.text.secondary,
        gridLineDashStyle: "Dot",
        labels: {
            style: {
                color: theme.palette.text.primary
            }
        },
        lineColor: theme.palette.text.secondary,
        minorGridLineColor: theme.palette.text.secondary,
        tickColor: theme.palette.text.secondary,
        title: {
            style: {
                color: theme.palette.text.primary
            }
        }
    },
    yAxis: {
        gridLineColor: theme.palette.text.secondary,
        gridLineDashStyle: "Dot",
        labels: {
            style: {
                color: theme.palette.text.primary
            }
        },
        lineColor: theme.palette.text.secondary,
        minorGridLineColor: theme.palette.text.secondary,
        tickColor: theme.palette.text.secondary,
        tickWidth: 1,
        title: {
            style: {
                color: theme.palette.text.primary
            }
        }
    },
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        style: {
            color: theme.palette.text.primary
        }
    },
    plotOptions: {
        series: {
            dataLabels: {
                color: theme.palette.text.primary
            },
            marker: {
                lineColor: theme.palette.background.paper
            }
        },
        boxplot: {
            fillColor: theme.palette.background.default
        },
        candlestick: {
            lineColor: theme.palette.white
        },
        errorbar: {
            color: theme.palette.white
        }
    },
    legend: {
        backgroundColor: "transparent",
        itemStyle: {
            color: theme.palette.action.active
        },
        itemHoverStyle: {
            color: theme.palette.grey.A400
        },
        itemHiddenStyle: {
            color: "#606063"
        }
    },
    credits: {
        style: {
            color: "#666"
        }
    },
    labels: {
        style: {
            color: "#707073"
        }
    },
    drilldown: {
        activeAxisLabelStyle: {
            color: theme.palette.text.secondary
        },
        activeDataLabelStyle: {
            color: theme.palette.text.secondary
        }
    },
    navigation: {
        buttonOptions: {
            symbolStroke: theme.palette.background.card,
            theme: {
                fill: theme.palette.grey.A400
            }
        }
    },
    rangeSelector: {
        buttonTheme: {
            fill: theme.palette.action.hover,
            stroke: theme.palette.action.hover,
            style: {
                color: theme.palette.text.secondary
            },
            states: {
                hover: {
                    fill: theme.palette.action.hover,
                    stroke: theme.palette.background.card,
                    style: {
                        color: theme.palette.text.paper
                    }
                },
                select: {
                    fill: theme.palette.background.default,
                    stroke: theme.palette.background.default,
                    style: {
                        color: theme.palette.textColor
                    }
                }
            }
        },
        inputBoxBorderColor: theme.palette.background.paper,
        inputStyle: {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.text.secondary
        },
        labelStyle: {
            color: theme.palette.text.secondary
        }
    },
    navigator: {
        handles: {
            backgroundColor: theme.palette.background.default,
            borderColor: theme.palette.background.light
        },
        outlineColor: theme.palette.background.light,
        maskFill: "rgba(255,255,255,0.1)",
        series: {
            color: "#7798BF",
            lineColor: "#A6C7ED"
        },
        xAxis: {
            gridLineColor: "#505053",
            gridLineDashStyle: "Dot"
        }
    },
    scrollbar: {
        barBackgroundColor: theme.palette.grey.A400,
        barBorderColor: theme.palette.grey.A400,
        buttonArrowColor: theme.palette.background.card,
        buttonBackgroundColor: theme.palette.grey.A400,
        buttonBorderColor: theme.palette.grey.A400,
        rifleColor: "#FFF",
        trackBackgroundColor: theme.themeId === "light" ? "#999" : "#404043",
        trackBorderColor: theme.themeId === "light" ? "#999" : "#404043",
        liveRedraw: true,
        enabled: true
    },
    legendBackgroundColor: theme.palette.background.default,
    background2: "#505053",
    dataLabelsColor: "#B0B0B3",
    textColor: "#C0C0C0",
    contrastTextColor: "#F0F0F3",
    maskColor: "rgba(255,255,255,0.3)"
});

export default () => {
    Highcharts.setOptions(C19_THEME);
    ReactHighcharts.Highcharts.setOptions(C19_THEME);
    ReactHighstock.Highcharts.setOptions(C19_THEME);
};

export {
    setHighchartsDefaultTheme,
    setC19Theme
};
