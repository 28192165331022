import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import getContainerDimensions from "react-dimensions";
import PureRenderMixin from "react-addons-pure-render-mixin";

import Drawer from "js/common/views/drawer";
import MenuBar from "js/common/views/menu-bar";
import ChartingPage from "js/charting/charting-page";
import SavedConfigsMenu from "js/common/views/sharing/saved-configs-menu";
import ConfirmRemoveReportDialog from "js/common/views/sharing/confirm-remove-dialog";
import UncaughtErrorMsg from "js/common/views/uncaught-error-msg";

import * as SavedConfigs from "js/common/saved-configs";
import Dialog from "js/common/views/tabs-dialog";
import SharePage from "js/common/views/sharing/share-config";
import {trackError} from "js/common/error-tracking";

import * as Immutable from "immutable";
import * as Users from "js/common/users";
import * as Auditor from "js/common/auditer";
import * as Popups from "js/common/popups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const $ = window.$;

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ErrorBoundaryPage theme={theme} {...props} />;
};

const ErrorBoundaryPage = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      uncaughtError: false
    };
  },

  componentDidCatch(error, {componentStack}) {
    trackError("REACT_RENDER", error, {componentStack});
    this.setState({uncaughtError: true});
  },

  render() {
    if (this.state.uncaughtError) {
      return (
          <div>
            <MenuBar />
            <UncaughtErrorMsg />
          </div>
      );
    } else {
      return <Page {...this.props} />;
    }
  }

});

const Page = getContainerDimensions()(createReactClass({

  propTypes: {
    initialChartConfig: ReactPropTypes.object,
    containerWidth: ReactPropTypes.number
  },


  getInitialState() {

    return {
      savedPages: Immutable.Map(),
      isLoadingPages: true,
      pageId: null,
      renderId: Math.random(),
      isSavedPagesMenuOpen: false,
      savedPageToRemove: null,
      isSavingPage: false,
      ownershipTypeToSavedPages: Immutable.Map(),
      savedPageToShare: null,
      loadingSavedPageSharing: null,
      shareSavedPageError: null,
      shareSavedPageSuccess: null,
      savedPageSharingSettings: null,
      editableSavedPageSharingSettings: null
    };
  },

  componentDidMount() {
    const theme = localStorage.getItem("appTheme");
    $("body")
        .removeClass()
        .addClass("charting")
        .addClass(`theme-${theme}`);

    Auditor.audit("charting:loaded");

    const homeChartPageId = getLoggedInUser().get("homeChartPageId");
    const hasInitialChartConfig = !!this.props.initialChartConfig;

    loadSavedPages()
        .then((savedPages) => {

          const savedPageIds = savedPages
              .toList()
              .flatMap(category => category.map(page => page.get("id")));

          const hasShareAccessForHomeChart = savedPageIds.contains(homeChartPageId);

          this.setState({
            savedPages,
            isLoadingPages: false,
            pageId: hasInitialChartConfig || !hasShareAccessForHomeChart ? null : homeChartPageId
          });
        }, (err) => {
          console.log(err);
          this.setState({
            isLoadingPages: false
          });
        });
  },

  componentWillUnmount() {
    $("body").removeClass("charting");
  },

  render() {
    const {
      savedPages,
      renderId,
      pageId,
      isLoadingPages,
      isSavedPagesMenuOpen,
      savedPageToRemove,
      savedPageToShare,
      loadingSavedPageSharing,
      editableSavedPageSharingSettings,
      savedPageSharingSettings,
      shareSavedPageError,
      shareSavedPageSuccess
    } = this.state;
    const smallScreenBreakpoint = 800;
    const isSmallScreen = this.props.containerWidth < smallScreenBreakpoint;
    const isConfirmRemovePageDialogOpen = !!savedPageToRemove;
    const theme = this.props.theme;

    return (
        <div>
          <MenuBar appView="charting" onClick={this.handleMenuBarClick} />
          {!savedPageToShare &&
              <Drawer
                  width={500}
                  open={isSavedPagesMenuOpen}
                  onRequestOpen={this.openSavedPagesMenu}
                  onRequestClose={this.closeSavedPagesMenu}>
                <SavedConfigsMenu
                    headerLabel="Saved Pages"
                    ownershipTypeToSavedConfigs={savedPages}
                    ownershipTypeToCustomDisplayName={Immutable.fromJS({ownedConfigs: "My pages"})}
                    onRefreshConfigsRequest={this.refreshSavedPages}
                    onAddConfigRequest={null}
                    isLoading={isLoadingPages}
                    onSavedConfigSelect={this.handleSavedPageClick}
                    extraButtons={Immutable.fromJS([
                      {
                        disabled: false,
                        onClick: pageId => SavedConfigs.toggleHome("CHART_PAGE", pageId),
                        faClass: "fa fa-home",
                        tooltipText: "Set as default page",
                        activeTooltipText: "Unset as default page",
                        isActive: (chartPageId) => {
                          return getLoggedInUser().get("homeChartPageId") === chartPageId;
                        }
                      }])}
                    isSavedConfigSharingEnabled={Users.currentHasPermission("SHARE_CHART_PAGE")}
                    onShareSavedConfigRequest={this.handleShareSavedPageClick}
                    onRemoveSavedConfigRequest={this.handleRemoveSavedPageClick}
                    mainContent={null} />
              </Drawer>
          }
          {savedPageToShare &&
              <Dialog
                  label={
                    <span
                        style={{
                          color: theme.themeId === "light" ? theme.palette.text.main : theme.palette.primary.main,
                          textTransform: "uppercase"
                        }}>{`Share ${savedPageToShare.get("name")}`}</span>
                  }
                  onRequestClose={this.handleCloseShareDialogClick}
                  content={
                    <SharePage
                        config={savedPageToShare}
                        isLoading={loadingSavedPageSharing}
                        reportSharingSettings={editableSavedPageSharingSettings}
                        onReportSharingSettingsChange={this.handleSavedPageSharingChange}
                        hasReportSharingSettingsChanged={
                          !Immutable.is(savedPageSharingSettings, editableSavedPageSharingSettings)
                        }
                        onCancelChangesRequest={this.handleCancelSavedPageSharingClick}
                        onShareReportRequest={this.handleSaveSavedPageSharingClick}
                        shareReportError={shareSavedPageError}
                        shareReportSuccess={shareSavedPageSuccess}
                        onSuccessTimeout={this.handleCloseShareDialogClick} />
                  }
                  flex={1}
                  contentOverflowAutoScroll={false} />}
          {!isLoadingPages &&
              <ChartingPage
                  key={pageId}
                  pageId={pageId}
                  renderId={renderId}
                  savedPages={savedPages}
                  onUpdateSavedPages={this.handleUpdateSavedPages}
                  isSmallScreen={isSmallScreen}
                  initialChartConfig={pageId ? null : this.props.initialChartConfig}
                  onSavedPagesMenuClick={this.openSavedPagesMenu}
                  containerWidth={this.props.containerWidth} />}
          {isConfirmRemovePageDialogOpen &&
              <ConfirmRemoveReportDialog
                  reportName={this.state.savedPageToRemove.id.get("name")}
                  onRemoveRequest={this.removeSavedPage}
                  onCancelRequest={this.closeConfirmRemovePageDialog} />}
        </div>);
  },

  openSavedPagesMenu() {
    this.setState({
      isSavedPagesMenuOpen: true
    });
  },

  closeSavedPagesMenu() {
    this.setState({
      isSavedPagesMenuOpen: false
    });
  },

  handleMenuBarClick(e) {
    if (e === "saved-charting-pages") {
      this.openSavedPagesMenu();
    } else {
      throw new Error("Unsupported menu bar click event:", e);
    }
  },

  handleSavedPageClick(savedPage, ownershipType) {
    if (ownershipType === "ownedConfigs") {
      Auditor.audit("charting:run-saved-page", {
        pageId: savedPage.get("id"),
        pageName: savedPage.get("name")
      });
    } else {
      Auditor.audit("charting:run-shared-page", {
        pageId: savedPage.get("id"),
        pageName: savedPage.get("name")
      });
    }


    this.setState({
      isSavedPagesMenuOpen: false,
      pageId: savedPage.get("id"),
      renderId: Math.random()
    });
  },

  handleRemoveSavedPageClick(id, type) {
    if (id.get("id") === getLoggedInUser().get("homeChartPageId")) {
      Popups.error("Cannot delete saved trend whilst set as default page");
    } else {
      this.setState({
        savedPageToRemove: {id, type}
      });
    }
  },

  closeConfirmRemovePageDialog() {
    this.setState({
      savedPageToRemove: null
    });
  },

  removeSavedPage() {
    const {savedPageToRemove, savedPages} = this.state;
    const {id: page, type} = savedPageToRemove;
    const name = page.get("name");

    let removePromise;
    if (type === "ownedConfigs") {
      Auditor.audit("charting:delete-page", {name});
      removePromise = SavedConfigs.remove(page.get("id"));
    } else if (type === "sharedUserConfigs") {
      removePromise = SavedConfigs.unshareFromCurrentUser(page.get("id"));
    }

    removePromise.then(() => {
      Popups.success("Removed successfully");

      const index = savedPages.get(type).indexOf(savedPageToRemove);
      const updatedSavedPages = savedPages
          .update(type, list => list.delete(index));


      this.setState({
        savedPages: updatedSavedPages,
        savedPageToRemove: null
      });

    }, reason => {
      let errorMsg = `Unable to remove ${page.get("name")}`;
      const responseJSON = reason.responseJSON;
      if (responseJSON && responseJSON.message) {
        errorMsg = responseJSON.message;
      }
      Popups.error(errorMsg);
      this.setState({
        savedPageToRemove: null
      });
    });
  },

  refreshSavedPages() {
    this.setState({
      loadingSavedPages: true
    });
    loadSavedPages()
        .then(savedPages => {
          this.setState({
            savedPages,
            loadingSavedPages: false
          });
        }, () => {
          this.setState({
            loadingSavedPages: false
          });
        });
  },

  handleUpdateSavedPages(updatedSavedPages) {
    this.setState({
      savedPages: updatedSavedPages
    });
  },

  handleShareSavedPageClick(savedPage) {
    this.setState({
      savedPageToShare: savedPage,
      loadingSavedPageSharing: true
    });
    SavedConfigs
        .getShareList(savedPage.get("id"))
        .then(savedPageSharingSettings => {
          this.setState({
            loadingSavedPageSharing: false,
            savedPageSharingSettings,
            editableSavedPageSharingSettings: savedPageSharingSettings
          });
        }, () => {
          this.setState({
            loadingSavedPageSharing: false,
            shareSavedPageError: "Unable to load trend sharing settings"
          });
        });
  },

  handleCloseShareDialogClick() {
    const initialState = this.getInitialState();
    this.setState({
      savedPageToShare: initialState.savedPageToShare,
      savedPageSharingSettings: initialState.savedPageSharingSettings,
      editableSavedPageSharingSettings: initialState.editableSavedPageSharingSettings,
      shareSavedPageError: initialState.shareSavedPageError,
      shareSavedPageSuccess: initialState.shareSavedPageSuccess
    });
  },

  handleSavedPageSharingChange(newSavedPageSharingSettings) {
    this.setState({
      editableSavedPageSharingSettings: newSavedPageSharingSettings
    });
  },

  handleCancelSavedPageSharingClick() {
    this.setState((state) => {
      return ({editableSavedPageSharingSettings: state.savedPageSharingSettings});
    });
  },

  handleSaveSavedPageSharingClick() {
    const {savedPageToShare, editableSavedPageSharingSettings} = this.state;
    this.setState({
      loadingSavedPageSharing: true
    });
    SavedConfigs
        .updateShareList(savedPageToShare.get("id"), editableSavedPageSharingSettings)
        .then(() => {
          this.setState({
            loadingSavedPageSharing: false,
            shareSavedPageSuccess: "Your changes to the saved page sharing settings have been saved"
          });
        }, reason => {
          let errorMsg = "Unable to save changes to the saved page sharing settings";
          const responseJSON = reason.responseJSON;
          if (responseJSON && responseJSON.errors) {
            errorMsg = responseJSON.errors;
          }
          this.setState({
            loadingSavedPageSharing: false,
            shareSavedPageError: errorMsg
          });
        });
  }

}));

const getLoggedInUser = () => Users.getCurrentUser();

const loadSavedPages = () => SavedConfigs
    .getAll("CHART_PAGE")
    .then(savedPages => {
      const ownedIds = savedPages.get("ownedConfigs").map(config => config.get("id")).toSet();
      return savedPages
          .update("sharedUserConfigs", configs => configs
              .filter(config => !ownedIds.has(config.get("id")))
          )
          .update("sharedGroupConfigs", configs => configs
              .filter(config => !ownedIds.has(config.get("id")))
          );
    });

