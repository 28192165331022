import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import store from "store";
import FileSaver from "browser-filesaver";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import TabsDialog from "js/common/views/tabs-dialog";
import Tabs from "js/common/views/tabs";
import DataDetailsTable from "js/oneview/kpi-details/data-details-table";
import ClickThroughPivot from "js/oneview/kpi-details/slice-and-dice";
import Trend from "js/oneview/kpi-details/trend";
import QuickView from "js/oneview/quick-view/main";
import Targets from "js/oneview/targets/main";
import Leaderboard from "js/oneview/leaderboards/main";
import Explanation from "js/oneview/kpi-details/explanation";
import LoadingSpinner from "js/common/views/loading-spinner";

import * as kpiCalculator from "js/common/kpi-calculator";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as ajax from "js/common/ajax";
import * as auditer from "js/common/auditer";
import * as popups from "js/common/popups";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import eventBus from "js/cube19.event-bus";
import currentClient from "js/common/repo/backbone/current-client";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants";

import {
  cellValueRequired,
  downloadTableAsExcelFile,
  getDerivedAttributes,
  isDataTableTypeReport,
  parseToPivotData
} from "js/common/pivot-utils";

const $ = window.$;

const unexpectedErrorMsg = `An unexpected error has occurred. ${Branding.submitTicketString}`;

const Dialog = createReactClass({

  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      initialTab: "DATA_DETAILS"
    };
  },

  getInitialState() {
    const indexByTabName = this.getTabIndexes();
    const tabNameByIndex = indexByTabName.mapEntries(([k, v]) => [v, k]);
    const showTabIndex = indexByTabName.get(this.props.initialTab, 0);
    return {
      indexByTabName: indexByTabName,
      tabNameByIndex: tabNameByIndex,
      showTabIndex: showTabIndex,
      tableData: {
        isLoading: true,
        error: null,
        columns: [],
        rows: []
      },
      pivotConfig: null,
      pivotData: null,
      pivotError: null,
      loadingPivot: true
    };
  },

  componentDidMount() {
    const {
      kpiId,
      userId,
      timeframe,
      clientIds,
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds,
      entityToIds
    } = this.props;
    const groupId = userId ? Users.getUser(userId).get("groupId") : this.props.groupId;
    const params = {
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds,
      clientIds,
      userId,
      groupId,
      timeframe,
      entityToIds
    };
    kpiCalculator
        .report(kpiId, params)
        .then(({columns, values, rowLimitReached}) => {
          this.setState({
            tableData: {
              isLoading: false,
              error: null,
              columns,
              rows: values,
              rowLimitReached
            }
          });
        }, reason => {
          const responseJSON = reason.responseJSON;
          const error = responseJSON && responseJSON.message ? responseJSON.message : unexpectedErrorMsg;
          this.setState({
            tableData: {
              isLoading: false,
              error,
              columns: [],
              rows: []
            }
          });
        })
        .then(() => {
          if (this.state.indexByTabName.has("SLICE_AND_DICE")) {
            loadPivotConfigForKpi(this.props.kpiId)
                .then(result => {
                  const defaultConfig = Immutable.fromJS({
                    columns: [],
                    rows: ["Owner"],
                    aggregator: "Count All",
                    renderer: "Heatmap",
                    total: {
                      row: true,
                      column: true
                    },
                    extraConfig: {
                      organisationIds: clientIds || []
                    }
                  });
                  const pivotConfig = result
                      ? Immutable.fromJS(result)
                          .setIn(["extraConfig", "organisationIds"], clientIds ? clientIds : Immutable.List())
                      : defaultConfig;
                  const {columns, rows} = this.state.tableData;
                  const pivotData = parseToPivotData(columns, rows);
                  const groupId = this.props.groupId || Users.getUser(this.props.userId).get("groupId");
                  const group = Groups.getGroup(groupId);
                  this.setState({
                    loadingPivot: false,
                    pivotConfig,
                    pivotData,
                    derivedPivotTableAttributes: getDerivedAttributes(pivotData, group.get("weekStartsOn"))
                  });
                }, () => this.setState({pivotError: unexpectedErrorMsg, loadingPivot: false}));
          }
        });
    this.changeTab(this.state.showTabIndex);
  },

  render() {
    // TODO: This could be fixed by having a seperate component to render the content that would be used here and in
    // dashboard metric curves
    if (this.props.renderContentOnly) {
      return <Tabs
          selectedIndex={this.state.showTabIndex}
          onChangeTab={this.handleChangeTab}
          tabs={this.getTabs().toArray()} />;
    } else {
      return (
          <TabsDialog
              theme={this.context.theme}
              label={
                <div style={{display: "inline-block"}}>
                <span
                    style={{
                      fontSize: this.context.theme.themeId === "light" ? "20px" : "16px",
                      textTransform: this.context.theme.themeId === "light" ? "none" : "uppercase",
                      color: this.context.theme.themeId === "light" ? this.context.theme.palette.text.main :
                          this.context.theme.palette.primary.main,
                      fontWeight: 500
                    }}>
                    {kpiRepo.get(this.props.kpiId).get("name")}
                </span>
                  <span
                      style={{
                        textTransform: this.context.theme.themeId === "light" ? "none" : "uppercase",
                        color: this.context.theme.palette.text.secondary,
                        fontWeight: 500
                      }}>
                    {` (${this.props.timeframe.get("name")})`}
                </span>
                  {this.renderUserOrGroupDetails()}
                </div>
              }
              content={
                <Tabs
                    selectedIndex={this.state.showTabIndex} onChangeTab={this.handleChangeTab}
                    tabs={this.getTabs()} />
              }
              onRequestClose={this.handleCloseRequest} />
      );
    }
  },

  renderUserOrGroupDetails() {
    const user = Users.getUser(this.props.userId);
    const isCurrentlyViewingUser = !!user;
    const groupId = isCurrentlyViewingUser ? user.get("groupId") : this.props.groupId;
    const breadcrumbs = Groups.getGroupBreadcrumbs(groupId);
    const dividerStyle = {fontSize: "0.725rem", paddingLeft: 5, paddingRight: 5};
    return (
        <span style={{color: this.context.theme.palette.textColor, paddingLeft: 5}}>
                {breadcrumbs.map((crumb, i) => {
                  const isFirstCrumb = i === 0;
                  const isLastCrumb = i === breadcrumbs.length - 1;
                  return (
                      <span key={`crumb-${i}`}>
                            {`${isFirstCrumb ? "for " : ""}${crumb.name}`}
                        {!isLastCrumb &&
                            <i className="fa fa-chevron-right" style={dividerStyle} />}
                        </span>
                  );
                })}
          {isCurrentlyViewingUser &&
              <span>
                        <i className="fa fa-chevron-right" style={dividerStyle} />
                {user.get("fullName")}
                    </span>}
            </span>);
  },

  getTabIndexes() {
    const tabIndexes = new Map();
    const client = currentClient;
    const currentUser = Users.getCurrentUser();
    if (client.hasPermission("CLICK_THROUGH")
        && Users.hasPermission(currentUser, "CLICK_THROUGH")) {
      tabIndexes.set("DATA_DETAILS", tabIndexes.size);
    }
    if (client.canAccessApp("REPORTING")
        && (!client.isUserAssignedCtTabs() || Users.canAccessApp(currentUser, "REPORTING"))) {
      tabIndexes.set("SLICE_AND_DICE", tabIndexes.size);
    }
    if (client.canAccessApp("CHARTING")
        && (!client.isUserAssignedCtTabs() || Users.canAccessApp(currentUser, "CHARTING"))) {
      tabIndexes.set("TREND", tabIndexes.size);
    }
    if (client.hasPermission("QUICKVIEW_CT")
        && !this.props.isUserOneView
        && Users.hasPermission(currentUser, "QUICKVIEW_CT")) {
      tabIndexes.set("QUICKVIEW", tabIndexes.size);
    }
    if (client.hasPermission("TARGET_CT")
        && Users.hasPermission(currentUser, "TARGET_CT")) {
      tabIndexes.set("TARGETS", tabIndexes.size);
    }
    if (client.hasPermission("LEADERBOARD_CT")
        && Users.hasPermission(currentUser, "LEADERBOARD_CT")) {
      tabIndexes.set("LEADERBOARD", tabIndexes.size);
    }
    if (client.areExplanationsEnabled() || Users.canAccessApp(currentUser, "kpi_admin")) {
      tabIndexes.set("EXPLANATION", tabIndexes.size);
    }
    if (tabIndexes.size === 0) {
      tabIndexes.set("PERMISSION_DENIED", tabIndexes.size);
    }
    return new Immutable.Map(tabIndexes);
  },

  getTabs() {
    return this.state.indexByTabName.keySeq().map(tabName => {
      switch (tabName) {
        case "DATA_DETAILS":
          return this.getDataDetailsTab();
        case "SLICE_AND_DICE":
          return this.getSliceAndDiceTab();
        case "TREND":
          return this.getTrendTab();
        case "QUICKVIEW":
          return this.getQuickViewTab();
        case "TARGETS":
          return this.getTargetsTab();
        case "LEADERBOARD":
          return this.getLeaderboardTab();
        case "EXPLANATION":
          return this.getExplanationTab();
        case "PERMISSION_DENIED":
          return this.getPermissionDeniedTab();
        default:
          return null;
      }
    });
  },

  getDataDetailsTab() {
    const {kpiId, timeframe} = this.props;
    const kpi = kpiRepo.get(kpiId);
    return {
      title: <TabTitle icon="table" text="Details" />,
      content:
          <DataDetailsTable
              isLoading={this.state.tableData.isLoading}
              error={this.state.tableData.error}
              onDownloadClick={() => auditer.audit("kpi_ct:download_table", this.getAuditOptions())}
              filenameForDownload={`${kpi.get("name")} (${timeframe.get("name")}).csv`}
              columns={this.state.tableData.columns}
              rows={this.state.tableData.rows}
              rowLimitReached={this.state.tableData.rowLimitReached} />
    };
  },

  getQuickViewTab() {
    const groupId = this.props.userId ? Users.getUser(this.props.userId).get("groupId") : this.props.groupId;
    return {
      title: <TabTitle icon="sitemap" text="Quick View" />,
      content:
          <QuickView
              kpiId={this.props.kpiId}
              timeframe={this.props.timeframe}
              clientIds={this.props.clientIds}
              matchAnyTagIds={this.props.matchAnyTagIds}
              matchAllTagIds={this.props.matchAllTagIds}
              excludedTagIds={this.props.excludedTagIds}
              groupId={groupId}
              onGroupClick={this.props.onGroupClick}
              onUserClick={this.props.onUserClick} />
    };
  },

  getTrendTab() {
    const {kpiId, groupId, userId, timeframe, clientIds} = this.props;
    const qualifierType = userId ? "USER" : "GROUP";
    const qualifierId = userId ? userId : groupId;
    const isTrendable = kpiRepo.get(kpiId).get("trendable");
    return {
      title: <TabTitle icon="line-chart" text="Trend Chart" />,
      content: isTrendable ?
          <Trend
              kpiId={kpiId}
              qualifierType={qualifierType}
              qualifierId={qualifierId}
              timeframe={timeframe}
              clientIds={clientIds}
              matchAnyTagIds={this.props.matchAnyTagIds}
              matchAllTagIds={this.props.matchAllTagIds}
              excludedTagIds={this.props.excludedTagIds} /> :
          <UnsupportedFeatureMsg label="This Metric cannot be viewed as a Trend Chart" />
    };
  },

  getTargetsTab() {
    const {kpiId, groupId, userId, timeframe, clientIds} = this.props;
    const qualifierType = userId ? "USER" : "GROUP";
    const qualifierId = userId ? userId : groupId;
    let content;
    if (clientIds && clientIds.length > 0) {
      content = <UnsupportedFeatureMsg label="Targets are not supported when a Client filter is applied" />;
    } else if (kpiRepo.get(kpiId).get("type").targetType === "NONE") {
      content = <UnsupportedFeatureMsg label="This Metric is not supported for targets" />;
    } else {
      content = <Targets
          kpiId={kpiId}
          timeframe={timeframe}
          qualifierType={qualifierType}
          qualifierId={qualifierId} />;
    }
    return {
      title: <TabTitle icon="dot-circle-o" text="Targets" />,
      content
    };
  },

  getLeaderboardTab() {
    const {
      kpiId,
      groupId,
      userId,
      timeframe,
      clientIds,
      onUserClick,
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds
    } = this.props;
    const qualifierType = userId ? "USER" : "GROUP";
    const qualifierId = userId ? userId : groupId;
    const hasPermission = Users.getCurrentUser().get("dataVisibility") !== "SELF";
    const content = hasPermission ?
        <Leaderboard
            kpiId={kpiId}
            timeframe={timeframe}
            clientIds={clientIds && clientIds.toJS()}
            matchAnyTagIds={matchAnyTagIds}
            matchAllTagIds={matchAllTagIds}
            excludedTagIds={excludedTagIds}
            qualifierType={qualifierType}
            qualifierId={qualifierId}
            onUserClick={onUserClick} /> :
        <UnsupportedFeatureMsg label="You cannot view a leaderboard as you can only see your own data" />;
    return {
      title: <TabTitle icon="trophy" text="Leaderboard" />,
      content: content
    };
  },

  getExplanationTab() {
    const content = <Explanation kpiId={this.props.kpiId} />;
    return {
      title: <TabTitle icon="question" text="Explanation" />,
      content
    };
  },

  getPermissionDeniedTab() {
    const content =
        <UnsupportedFeatureMsg label="You do not have permission to view this" />;
    return {
      title: <TabTitle icon="ban" text="Permission Denied" />,
      content: content
    };
  },

  handleCloseRequest() {
    const isCurrentlyOnTargetsTab = this.state.tabNameByIndex.get(this.state.showTabIndex) === "TARGETS";
    if (isCurrentlyOnTargetsTab && store.enabled && store.get("targetsTab.hasUnsavedChanges")) {
      popups.confirm("You have unsaved target changes. Would you like to save them?", {
        title: "Discard Changes?",
        labels: {
          cancel: "No",
          ok: "Yes"
        },
        onok: () => {
          eventBus.trigger("targets-tab:save-changes");
          this.props.onRequestClose();
          return;
        },
        oncancel: () => {
          store.remove("targetsTab.hasUnsavedChanges");
          this.props.onRequestClose();
          return;
        }
      });
    } else {
      this.props.onRequestClose();
    }
  },

  handleChangeTab(index) {
    const isCurrentlyOnTargetsTab = this.state.tabNameByIndex.get(this.state.showTabIndex) === "TARGETS";
    if (isCurrentlyOnTargetsTab && store.enabled && store.get("targetsTab.hasUnsavedChanges")) {
      popups.confirm("You have unsaved target changes. Would you like to save them?", {
        title: "Discard Changes?",
        labels: {
          cancel: "No",
          ok: "Yes"
        },
        onok: () => {
          eventBus.trigger("targets-tab:save-changes");
          this.changeTab(index);
          return;
        },
        oncancel: () => {
          store.remove("targetsTab.hasUnsavedChanges");
          this.changeTab(index);
          return;
        }
      });
    } else {
      this.changeTab(index);
    }
  },

  changeTab(index) {
    const newTab = this.state.tabNameByIndex.get(index);
    if (newTab !== "PERMISSION_DENIED") {
      const auditCategory = "kpi_ct:change_to_" + newTab.toLowerCase();
      auditer.uniqueAudit(auditCategory, this.getAuditOptions());
    }
    this.setState({showTabIndex: index});
  },

  getSliceAndDiceTab() {
    const groupId = this.props.userId ? Users.getUser(this.props.userId).get("groupId") : this.props.groupId;
    const isLoading = this.state.loadingPivot;
    return {
      title: <TabTitle icon="line-chart" text="Slice & Dice" />,
      content: isLoading ? <LoadingSpinner /> :
          <ClickThroughPivot
              kpiId={this.props.kpiId}
              timeframe={this.props.timeframe}
              groupId={groupId}
              userId={this.props.userId}
              matchAnyTagIds={this.props.matchAnyTagIds}
              matchAllTagIds={this.props.matchAllTagIds}
              excludedTagIds={this.props.excludedTagIds}
              pivotError={this.state.pivotError}
              pivotConfig={this.state.pivotConfig}
              pivotData={this.state.pivotData}
              originalData={this.state.tableData}
              derivedPivotTableAttributes={this.state.derivedPivotTableAttributes}
              onPivotTableRefresh={this.handlePivotTableRefresh}
              onDownloadSliceAndDiceRequest={this.handleDownloadSliceAndDiceRequest} />
    };
  },

  handlePivotTableRefresh(pivotTableConfig) {
    const aggregator = pivotTableConfig.get("aggregatorName");
    const cellValues = pivotTableConfig.get("vals");
    const currentAggregator = this.state.pivotConfig.get("aggregator");
    const isAggregatorChanged = aggregator !== currentAggregator;
    const isMissingCellValues = cellValueRequired(aggregator) && cellValues.isEmpty();
    if (isAggregatorChanged && isMissingCellValues) {
      popups.alert("Please select an aggregator value from the dropdown below the selected aggregator", {
        title: "Aggregator Value Required"
      });
    }
    const newPivotConfig = this.state.pivotConfig
        .set("renderer", pivotTableConfig.get("rendererName"))
        .set("aggregator", pivotTableConfig.get("aggregatorName"))
        .set("cellValue", pivotTableConfig.get("vals").first())
        .set("columns", pivotTableConfig.get("cols"))
        .set("rows", pivotTableConfig.get("rows"));
    this.setState({
      pivotConfig: newPivotConfig
    });
  },

  handleDownloadSliceAndDiceRequest() {
    const aggregator = this.state.pivotConfig.get("aggregator");
    const cellValue = this.state.pivotConfig.get("cellValue");
    const isMissingCellValues = cellValueRequired(aggregator) && !cellValue;
    if (isMissingCellValues) {
      popups.alert("Please select an aggregator value from the dropdown below the selected aggregator", {
        title: "Aggregator Value Required"
      });
    } else {
      const ua = auditer.getUserAgent();
      const browser = ua.getBrowser();
      if (browser.name === "IE" && !navigator.msSaveOrOpenBlob) { // Saving to file is only supported on IE10+
        const ieMessage = "This functionality is not supported by your version of Internet Explorer.<br/>" +
            "We recommend Google Chrome or Microsoft Edge for this.";
        popups.alert(ieMessage, {
          title: "Unable to Save to File"
        });
      } else if (isDataTableTypeReport(this.state.pivotConfig.get("renderer"))) {
        const fileName = `${kpiRepo.get(this.props.kpiId).get("name")} (${this.props.timeframe.get("name")}).xls`;
        const $this = $(ReactDOM.findDOMNode(this));
        const tableHtml = $this.find("#pivot-table .pvtTable").html();
        const blob = downloadTableAsExcelFile(tableHtml);
        FileSaver.saveAs(blob, fileName);
      } else {
        popups.alert(
            "This functionality only works for data tables.<br />Please choose a <strong>Table</strong> type report (e.g. Table, Table Barchart, Heatmap).",
            {
              title: "Unable to Save to File"
            });
      }
    }
  },

  getAuditOptions() {
    const {kpiId, userId, timeframe} = this.props;
    const groupId = userId ? Users.getUser(userId).get("groupId") : this.props.groupId;
    return {
      kpiId,
      userId,
      groupId,
      timeframe: timeframe.getRawJson()
    };
  }

});

const TabTitle = pure(({icon, text}) => <span><i className={`fa fa-${icon}`} /> {text}</span>);

const UnsupportedFeatureMsg = pure(({label}) => (
    <p style={{marginTop: "1em", textAlign: "center", color: "#969696"}}>
      <i className="fa fa-ban" />
      <br />
      {label}
    </p>
));

const loadPivotConfigForKpi = kpiId => ajax.get({url: `kpi/pivot/default-config/${kpiId}`});


Dialog.contextType = CustomThemeContext;

export default Dialog;
