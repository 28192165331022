import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import moment from "moment";

import * as Users from "js/common/users";

import SimpleDataTable from "js/common/views/tables/simple-data-table";
import LoadingSpinner from "js/common/views/loading-spinner";
import ErrorMsg from "js/common/views/error";
import * as Formatter from "js/common/utils/formatter";
import * as NovoComms from "js/common/utils/novo-comms";

const $ = window.$;

export default createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    const indexesToHide = new Set(this.props.columns
        .map((column, index) => ({column, index}))
        .filter(({column}) => column.columnFor !== "UI")
        .map(({index}) => index));
    const columns = this.props.columns.filter((col, i) => !indexesToHide.has(i)).map(parseColumn);
    const rows = this.props.rows.map(row => row.filter((cell, i) => !indexesToHide.has(i)));
    return {
      columns,
      rows
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const indexesToHide = new Set(nextProps.columns
        .map((column, index) => ({column, index}))
        .filter(({column}) => column.columnFor !== "UI")
        .map(({index}) => index));
    const columns = nextProps.columns.filter((col, i) => !indexesToHide.has(i)).map(parseColumn);
    const rows = nextProps.rows.map(row => row.filter((cell, i) => !indexesToHide.has(i)));
    this.setState({columns, rows});
  },

  render() {
    const {error, isLoading} = this.props;
    return (
        <div>
          {error && <ErrorMsg text={error} style={{marginRight: "1rem", marginLeft: "1rem"}} />}
          {(!error && isLoading) && this.renderLoadingSpinner()}
          {(!error && !isLoading) && this.renderTable()}
        </div>);
  },

  renderLoadingSpinner() {
    return (
        <div style={{margin: "3rem"}}>
          <LoadingSpinner label="Loading Details Table" iconSize={3} />
        </div>);
  },

  handleCellClick(cellValue, row, columnIndex, rowIndex, isDeepLinkable) {
    if (isDeepLinkable) {
      const originalCrmId = row[columnIndex].originalValue.deepLinkingData.crmId;
      const crmEntityName = row[columnIndex].originalValue.deepLinkingData.crmEntityName;
      NovoComms.open(crmEntityName, originalCrmId);
    }
  },

  renderTable() {
    const height = this.props.height || ($(".tabs-content-container").innerHeight() - 98); // 98px = height of tabs +
                                                                                           // download, search filter
                                                                                           // row
    return (
        <div>
          {this.props.rowLimitReached && <ErrorMsg
              type="warn"
              text={"A limit of 500k rows are shown below. If you need to see data that is not loaded, please adjust your filters."} />}
          <SimpleDataTable
              onCellClick={this.handleCellClick}
              onDownloadClick={this.props.onDownloadClick}
              downloadable={Users.currentHasPermission("EXPORT_FILE")}
              showGreyButtonIfNotDownloadable={true}
              filenameForDownload={this.props.filenameForDownload}
              maxTableHeight={height}
              columns={this.state.columns}
              rows={this.state.rows} />
        </div>
    );
  }

});

const parseColumn = column => {
  return {
    label: column.label,
    sortMapper: cell => cell.value,
    displayMapper: cell => cell.value,
    ...mappersByColumnType[column.dataType]
  };
};

const mappersByColumnType = {
  STRING: {
    commonMapper: cell => cell.value,
    displayMapper: cell => cell,
    sortMapper: null
  },
  PERCENTAGE: {
    displayMapper: cell => Formatter.format(cell, {valueFormat: "PERCENT"})
  },
  CURRENCY: {
    displayMapper: cell => Formatter.format(cell)
  },
  INTEGER: {
    displayMapper: cell => Formatter.format(cell, {valueFormat: "NUMBER"})
  },
  DATE: {
    commonMapper: cell => {
      const str = cell.value;
      if (str) {
        const year = parseInt(str.substring(0, 4));
        const month = parseInt(str.substring(5, 7)) - 1;
        const day = parseInt(str.substring(8, 10));
        return moment(new Date(year, month, day));
      } else {
        return moment.invalid();
      }
    },
    sortMapper: date => date.isValid() ? date.valueOf() : 0,
    displayMapper: date => date.isValid() ? date.format("L") : ""
  }
};
