import React from "react";
import Immutable from "immutable";
import moment from "moment";
import GetContainerDimensions from "react-dimensions";

import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import GroupAndUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import Dialog from "js/common/views/tabs-dialog";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import Leaderboard from "js/oneview/leaderboards/leaderboard-container";
import {rankByPercent, rankByValue} from "js/cubetv/cubes/leaderboard-ranker";
import * as Formatter from "js/common/utils/formatter";
import TrendChart from "js/oneview/trends/trend-chart";
import LabelledComponent from "js/common/views/labelled-component";
import LoadingSpinner from "js/common/views/loading-spinner";
import InteractiveTable from "js/common/views/tables/interactive-table";
import LabelledSelect from "js/common/views/inputs/labelled-select";
import TraceViewer from "js/common/views/trace-viewer";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import {betterMemo} from "js/common/utils/more-memo";
import {processKpiTrendData} from "js/charting/trend/data-loader";
import {getDefaultColour} from "js/charting/trend/target-trend-color-util";
import {hasTestError} from "js/admin/kpis/edit-kpis/validation";
import * as Numbers from "js/common/utils/numbers";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Popups from "js/common/popups";
import * as Charting from "js/charting/charting-defaults";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {isCube19User} from "js/common/users";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import * as Branding from "js/common/branding-constants";
import * as Users from "js/common/users";

const parseColumn = column => {
  return {
    label: column.label,
    sortMapper: cell => cell.value,
    displayMapper: cell => cell.value,
    ...mappersByColumnType[column.dataType]
  };
};

const DataDetailsContent = GetContainerDimensions()(({
  reportResponse,
  containerHeight
}) => (
    <div>
      <SimpleDataTable
          columns={reportResponse.get("columns").toJS().map(parseColumn)}
          rows={reportResponse.get("values").toJS()}
          rowLimitReached={reportResponse.get("rowLimitReached")}
          maxTableHeight={containerHeight - 43} />
    </div> // 43px = height of Download button, Search Input row above table
));

const mapToTableRow = (data, valueFormat) => {
  const rank = data.get("rank");
  const userId = data.get("userId");
  const kpi = data.get("kpi");
  const total = kpi.get("total");
  const totalValueDisplay = Formatter.format(total.toJS(), {valueFormat});
  return Immutable.fromJS({
    rank,
    userId,
    totalValueDisplay,
    targetValueDisplay: "",
    percentOfTarget: ""
  });
};

const rankLeaderboardData = (data, valueFormat, sortField, sortDescending, filterZero) => {
  const sortFn = sortField === "VALUE" ? rankByValue : rankByPercent;
  const rankedData = sortFn(data)
      .filter(row => row.getIn(["kpi", "total", "value"]) !== null)
      .filter(row => filterZero ? row.getIn(["kpi", "total", "value"]) !== 0 : true)
      .map(row => mapToTableRow(row, valueFormat));
  if (sortDescending) {
    return Immutable.fromJS(rankedData);
  }
  return Immutable.fromJS(rankedData.reverse());
};

const LeaderboardContent = ({
  leaderboardResponse,
  valueFormat,
  groupId,
  userId
}) => {
  const [filterZero, setFilterZero] = React.useState(false);
  const [sortField, setSortField] = React.useState("VALUE");
  const [sortDescending, setSortDescending] = React.useState(true);
  const handleSortChange = (field) => {
    if (field === sortField) {
      setSortDescending(!sortDescending);
    } else {
      setSortField(field);
      setSortDescending(true);
    }
  };
  const notSupported = () => Popups.warning("Not supported on test leaderboard");
  return <div style={{width: 800, margin: "0 auto"}}>
    <Leaderboard
        hideGroupPicker={true}
        leaderboard={rankLeaderboardData(leaderboardResponse, valueFormat, sortField, sortDescending, filterZero)}
        onSortChange={handleSortChange}
        sortField={sortField}
        sortDescending={sortDescending}
        groupId={groupId}
        userId={userId}
        filterZero={filterZero}
        onFilterToggle={() => setFilterZero(!filterZero)}
        onGroupChange={notSupported}
        onUserClick={() => {}}
        onDownloadClick={notSupported} />
  </div>;
};

const getInitialDataAggregation = (timeframe) => {
  const start = timeframe.get("start");
  const end = timeframe.get("end");
  const noOfDays = end.diff(start, "days") + 1;
  let dataAggregation;

  if (Numbers.between(noOfDays, 1, 31)) {
    dataAggregation = "DAILY";
  }
  if (Numbers.between(noOfDays, 31, 180)) {
    dataAggregation = "WEEKLY";
  }
  if (Numbers.between(noOfDays, 180, 365 * 2)) {
    dataAggregation = "MONTHLY";
  }
  if (Numbers.between(noOfDays, 365 * 2, 365 * 5)) {
    dataAggregation = "QUARTERLY";
  }
  if (noOfDays > 365 * 5) {
    dataAggregation = "YEARLY";
  }

  return dataAggregation;
};

const getDefaultTrendConfig = (timeframe) => {
  const seriesColour = getDefaultColour(0, 0, "DEFAULT");
  const defaultConfig = Charting.getDefaultConfigForChartType("TREND", "Trend Chart 1");
  return {
    ...defaultConfig,
    timeframe,
    dataAggregation: getInitialDataAggregation(timeframe),
    trendType: "ADVANCED",
    dataGroups: [
      {
        cid: Math.random(),
        name: "Data Group 1",
        timeframe,
        trendConfigs: [
          {
            cid: Math.random(),
            type: "KPI",
            kpiId: null,
            whatToPlot: "value",
            seriesColour,
            hasCustomColor: true,
            lineStyle: "Solid",
            dataPointMarker: null,
            howToPlot: "non-cumulative",
            yAxis: 0
          }],
        qualifierType: defaultConfig.qualifierType,
        qualifierId: defaultConfig.qualifierId,
        matchAnyTagIds: defaultConfig.matchAnyTagIds,
        matchAllTagIds: defaultConfig.matchAllTagIds,
        excludedTagIds: defaultConfig.excludedTagIds,
        clientIds: defaultConfig.clientIds,
        dateDisplay: "ABSOLUTE"
      }]
  };
};

const TrendContent = ({
  trendResponse,
  timeframe,
  trendAggregation = "SUM"
}) => {
  const timeframeModel = TimeframeRepo.parse(timeframe.toJS());
  const config = getDefaultTrendConfig(timeframeModel);

  const sortedTrendData = trendResponse
      .map(item => item.update("date", date => moment(date, "YYYY-MM-DD")))
      .sortBy(item => item.get("daysFromStart"))
      .toJS();
  const {excludeWeekends, dataAggregation} = config;
  const trends = config.dataGroups.flatMap(
      dataGroup => dataGroup.trendConfigs.map(
          trendConfig => ({
            trendConfig,
            rootTrendName: dataGroup.name,
            trend: processKpiTrendData(
                sortedTrendData,
                timeframeModel,
                trendConfig.howToPlot,
                excludeWeekends,
                dataAggregation,
                trendAggregation)
          })
      ));
  return <TrendChart
      trends={trends}
      config={config}
      startDate={timeframeModel.get("start")}
      endDate={timeframeModel.get("end")} />;
};

const displayError = (traceId) => {
  return <div style={{padding: "0.5em"}}>
  <span>
    An unexpected error has occurred. {Branding.submitTicketInstructions}.
    {traceId && <br />}
    {traceId && " You can quote traceId: " + traceId}
  </span>
  </div>;
};

const getLeaderboardResult = (result, setDialogContent, theme) => {
  const leaderboard = result.getIn(["response", "leaderboard", "body"]);
  const error = result.getIn(["response", "leaderboard", "error"]);
  const traceId = result.getIn(["response", "leaderboard", "traceId"]);
  const isTimeout = error && error.get("status") === 504;
  const onTraceClick = () => setDialogContent(
      result.get("name") + " Leaderboard Trace - ID: " + traceId,
      <TraceViewer traceId={traceId} />);
  const onResultsClick = () => setDialogContent(
      result.get("name"),
      <LeaderboardContent
          leaderboardResponse={leaderboard}
          valueFormat={result.getIn(["template", "valueFormat"])}
          groupId={result.get("groupId")}
          userId={result.get("userId")} />);
  return (
      <div>
        {leaderboard && !error
            ? <IconButton
                icon="check"
                type="success"
                hoverType="success"
                style={successResultStyle(theme)}
                label="View Results"
                container="row"
                labelStyle={{marginLeft: 4, padding: "2px 4px", fontSize: 12}}
                onClick={onResultsClick}
                size="large" />
            : <div style={errorResultStyle(theme)}>
              <i
                  className="fa fa-times"
                  style={{color: theme.palette.error.main}} /> {isTimeout ? "Timed out" : "Error"}
            </div>
        }
        {traceId && isCube19User(Users.getCurrentUser()) &&
            <IconButton
                type={error ? "alert" : "success"}
                hoverType={error ? "alert" : "success"}
                style={error ? errorResultStyle(theme) : successResultStyle(theme)}
                label={"View Trace"}
                container="row"
                labelStyle={{marginLeft: 4, padding: "2px 4px", fontSize: 12}}
                onClick={onTraceClick}
                size="large" />
        }
      </div>
  );
};

const getTrendResult = (result, setDialogContent, theme) => {
  const trend = result.getIn(["response", "trend", "body"]);
  const error = result.getIn(["response", "trend", "error"]);
  const isTimeout = error && error.get("status") === 504;
  const traceId = result.getIn(["response", "trend", "traceId"]);

  if (!trend && !error) {
    return <div style={testResultStyle}>{"N/A"}</div>;
  } else {
    const onTraceClick = () => setDialogContent(
        result.get("name") + " Trend Trace - ID: " + traceId,
        <TraceViewer traceId={traceId} />);
    const onResultsClick = () => setDialogContent(
        result.get("name"),
        <TrendContent
            trendResponse={trend}
            timeframe={result.get("timeframe")}
            trendAggregation={result.getIn(["template", "uiParams", "trendAggregation"])} />);
    return (
        <div>
          {trend && !error ? <IconButton
                  icon={"check"}
                  type={"success"}
                  hoverType="success"
                  style={successResultStyle(theme)}
                  label={"View Results"}
                  container="row"
                  labelStyle={{marginLeft: 4, padding: "2px 4px", fontSize: 12}}
                  onClick={onResultsClick}
                  size="large" />
              : <div style={errorResultStyle(theme)}><i
                  className="fa fa-times"
                  style={{color: theme.palette.error.main}} /> {isTimeout ? "Timed out" : "Error"}</div>}
          {traceId && isCube19User &&
              <IconButton
                  type={error ? "alert" : "success"}
                  hoverType={error ? "alert" : "success"}
                  style={error ? errorResultStyle(theme) : successResultStyle(theme)}
                  label={"View Trace"}
                  container="row"
                  labelStyle={{marginLeft: 4, padding: "2px 4px", fontSize: 12}}
                  onClick={onTraceClick}
                  size="large" />}
        </div>
    );
  }
};

const getDataDetailsResult = (result, setDialogContent, theme) => {
  const report = result.getIn(["response", "report", "body"]);
  const error = result.getIn(["response", "report", "error"]);
  const isTimeout = error && error.get("status") === 504;
  const traceId = result.getIn(["response", "report", "traceId"]);

  const onResultsClick = () => setDialogContent(result.get("name"), <DataDetailsContent reportResponse={report} />);
  const onTraceClick = () => setDialogContent(
      result.get("name") + " Details Trace - ID: " + traceId,
      <TraceViewer traceId={traceId} />);

  return (
      <div>
        {report && !error ? <IconButton
                icon={"check"}
                type={"success"}
                hoverType="success"
                style={successResultStyle(theme)}
                label={"View Results"}
                container="row"
                labelStyle={{marginLeft: 4, padding: "2px 4px", fontSize: 12}}
                onClick={onResultsClick}
                size="large" />
            : <div style={errorResultStyle(theme)}><i
                className="fa fa-times"
                style={{color: theme.palette.error.main}} /> {isTimeout ? "Timed out" : "Error"}</div>}
        {traceId && isCube19User &&
            <IconButton
                type={error ? "alert" : "success"}
                hoverType={error ? "alert" : "success"}
                style={error ? errorResultStyle(theme) : successResultStyle(theme)}
                label={"View Trace"}
                container="row"
                labelStyle={{marginLeft: 4, padding: "2px 4px", fontSize: 12}}
                onClick={onTraceClick}
                size="large" />}
      </div>
  );
};

const getSummaryResult = (result, setDialogContent, theme) => {
  const summary = result.getIn(["response", "summary", "body"]);
  const error = result.getIn(["response", "summary", "error"]);
  const isTimeout = error && error.get("status") === 504;
  const traceId = result.getIn(["response", "summary", "traceId"]);

  const onTraceClick = () => setDialogContent(
      result.get("name") + " Summary Trace - ID: " + traceId,
      <TraceViewer traceId={traceId} />);
  return (
      <div style={{padding: "0.2rem", display: "block"}}>
        {summary && !error ?
            <div style={successResultStyle(theme)}>
              {Formatter.format(summary, {valueFormat: result.getIn(["template", "valueFormat"])})}
            </div>
            : <div style={errorResultStyle(theme)}><i
                className="fa fa-times"
                style={{color: theme.palette.error.main}} /> {isTimeout ? "Timed out" : "Error"}</div>
        }
        {traceId && isCube19User &&
            <IconButton
                type={error ? "alert" : "success"}
                hoverType={error ? "alert" : "success"}
                style={error ? errorResultStyle(theme) : successResultStyle(theme)}
                label={"View Trace"}
                container="row"
                labelStyle={{marginLeft: 4, padding: "2px 4px", fontSize: 12}}
                onClick={onTraceClick}
                size="large" />}
      </div>
  );
};

const FullResult = betterMemo(
    {displayName: "FullResult"},
    ({testResult}) => {
      const [dialog, setDialog] = React.useState(null);
      const {theme} = React.useContext(CustomThemeContext);
      const setDialogContent = React.useCallback((title, content) => {
        setDialog(<Dialog
            label={title}
            content={content}
            onRequestClose={() => setDialog(null)} />);
      }, []);
      const groupResult = testResult.get("group");
      const userResult = testResult.get("user");
      const errorsToTraceId = Immutable.List.of(
          Immutable.Map({
            error: groupResult.getIn(["response", "summary", "error"]),
            traceId: groupResult.getIn(["response", "summary", "traceId"])
          }),
          Immutable.Map({
            error: groupResult.getIn(["response", "report", "error"]),
            traceId: groupResult.getIn(["response", "report", "traceId"])
          }),
          Immutable.Map({
            error: groupResult.getIn(["response", "trend", "error"]),
            traceId: groupResult.getIn(["response", "trend", "traceId"])
          }),
          Immutable.Map({
            error: groupResult.getIn(["response", "leaderboard", "error"]),
            traceId: groupResult.getIn(["response", "leaderboard", "traceId"])
          }),
          Immutable.Map({
            error: userResult.getIn(["response", "summary", "error"]),
            traceId: userResult.getIn(["response", "summary", "traceId"])
          }),
          Immutable.Map({
            error: userResult.getIn(["response", "report", "error"]),
            traceId: userResult.getIn(["response", "report", "traceId"])
          }),
          Immutable.Map({
            error: userResult.getIn(["response", "trend", "error"]),
            traceId: userResult.getIn(["response", "trend", "traceId"])
          }),
          Immutable.Map({
            error: userResult.getIn(["response", "leaderboard", "error"]),
            traceId: userResult.getIn(["response", "leaderboard", "traceId"])
          }),
          Immutable.Map({
            error: groupResult.hasIn(["response", "concurrency", "error"]),
            traceId: groupResult.hasIn(["response", "concurrency", "traceId"]),
          })
      )
      .filter(entry => entry.get("error") !== undefined && entry.get("error") !== false);

      const columns = [
        {label: "", foundationClasses: "medium-1"},
        {label: groupResult.get("name"), foundationClasses: "medium-3", textAlign: "center"},
        {
          label: userResult ? userResult.get("name") : "No Visible Users",
          foundationClasses: "medium-3",
          textAlign: "center"
        }
      ];
      const rows = [
        [
          "Summary",
          groupResult && getSummaryResult(groupResult, setDialogContent, theme),
          userResult && getSummaryResult(userResult, setDialogContent, theme)],
        [
          "Details",
          groupResult && getDataDetailsResult(groupResult, setDialogContent, theme),
          userResult && getDataDetailsResult(userResult, setDialogContent, theme)],
        [
          "Trend",
          groupResult && getTrendResult(groupResult, setDialogContent, theme),
          userResult && getTrendResult(userResult, setDialogContent, theme)],
        [
          "Leaderboard",
          groupResult && getLeaderboardResult(groupResult, setDialogContent, theme),
          userResult && getLeaderboardResult(userResult, setDialogContent, theme)]
      ];

      return <div>
        {dialog}
        {errorsToTraceId.count() > 0 &&
            <Hint style={{clear: "right"}}>
              <Icon icon="info" style={{color: theme.palette.hints.text}} />
              {displayError(errorsToTraceId.first().get("traceId"))}
            </Hint>
        }
        <InteractiveTable
            columns={columns}
            rows={rows} />
      </div>;
    }
);

const ConfigurableTest = betterMemo(
    {displayName: "ConfigurableTest"},
    ({
      testConfig,
      onConfigChange,
      testResult,
      template,
      onTest
    }) => {
      const userId = testConfig.get("userId");
      const groupId = testConfig.get("groupId");
      const timeframe = TimeframeRepo.parse(testConfig.get("timeframe").toJS());
      const traceLevel = testConfig.get("traceLevel");
      const {theme} = React.useContext(CustomThemeContext);
      const visibleTimeframes = TimeframeRepo
          .getAll()
          .filter(t => t.get("visible") && !t.get("isDeleted"));

      return <div
          style={!!testResult && !testResult.get("loading") ? {padding: "1rem 0.5rem"} :
              {padding: "1rem 0.5rem", marginBottom: "40px"}}>
        <div style={{display: "flex"}}>
          <LabelledComponent
              style={{flex: 1, margin: "0.5rem"}}
              labelStyle={labelStyle(theme)}
              label={"Group to Test"}>
            <GroupAndUserPicker
                qualifierId={groupId}
                qualifierType={"GROUP"}
                onGroupClick={groupId => onConfigChange(testConfig.set("groupId", groupId))}
                excludeUsers={true} />
          </LabelledComponent>
          <LabelledComponent
              style={{flex: 1, margin: "0.5rem"}}
              labelStyle={labelStyle(theme)}
              label={"User to Test"}>
            <GroupAndUserPicker
                qualifierId={userId}
                qualifierType={"USER"}
                onUserClick={userId => onConfigChange(testConfig.set("userId", userId))} />
          </LabelledComponent>
        </div>
        <div style={{display: "flex"}}>
          <LabelledComponent
              style={{flex: 1, margin: "0.5rem"}}
              labelStyle={labelStyle(theme)}
              label={"Timeframe to Test"}>
            <TimeframePicker
                isDisabled={template.get("dateType") === "INSTANT"}
                timeframes={visibleTimeframes}
                timeframe={timeframe}
                onChange={timeframe => onConfigChange(testConfig.set(
                    "timeframe",
                    Immutable.fromJS(timeframe.getRawJson())))} />
          </LabelledComponent>
          {isCube19User(Users.getCurrentUser()) &&
              <LabelledSelect
                  label="Trace Level"
                  title="Trace Level"
                  containerStyle={{textAlign: "left", flex: 1, margin: "0.5rem", marginTop: "0.25rem"}}
                  onChange={traceLevel => onConfigChange(testConfig.set("traceLevel", traceLevel))}
                  selectedValue={traceLevel}
                  isMulti={false}
                  isClearable={false}
                  options={
                    Immutable.fromJS(
                        [
                          {value: "NONE", label: "No Tracing"},
                          {value: "LIGHT", label: "Trace Queries and Timing"}
                        ]
                    )}
              >
              </LabelledSelect>}
        </div>
        <div>
          <TextButton
              type="primary"
              label="Test"
              disabled={testResult && testResult.get("loading")}
              onClick={onTest}
              style={{margin: "0.5rem 0.6rem 1rem", float: "right"}} />
        </div>
        {testResult && testResult.get("loading") && <LoadingSpinner />}
        {!!testResult && !testResult.get("loading") && <div style={{margin: "0.5rem"}}>
          <FullResult testResult={testResult} traceData={null} />
        </div>}
      </div>;
    });

const SimpleTest = betterMemo(
    {displayName: "SimpleTest"},
    ({
      testResult,
      onTest,
      style
    }) => {
      const hasError = React.useMemo(() => hasTestError(testResult), [testResult]);

      let status;
      if (!!testResult) {
        if (testResult.get("loading")) {
          status = <i className={`fa fa-spinner fa-pulse`} style={{color: "#969696", marginRight: "0.3rem"}} />;
        } else if (hasError) {
          status = <span>
            <i className={`fa fa-times`} style={{color: "red", marginRight: "0.3rem"}} />
            FAIL
          </span>;
        } else {
          status = <span>
            <i className={`fa fa-check`} style={{color: "green", marginRight: "0.3rem"}} />
            PASS
          </span>;
        }
      }
      return <div style={style}>
        <TextButton
            type="primary"
            label={!!testResult ? "RERUN" : "RUN TEST"}
            disabled={testResult && testResult.get("loading")}
            onClick={onTest}
            style={{marginRight: "0.5rem"}} />
        {status}
      </div>;
    });

const testResultStyle = {
  borderRadius: 5,
  display: "block",
  width: "fit-content",
  padding: "0.2rem 0.5rem",
  margin: "5px auto",
  fontSize: 12
};

const successResultStyle = (theme) => ({
  ...testResultStyle,
  background: theme.palette.success.background,
  color: theme.palette.success.main
});

const errorResultStyle = (theme) => ({
  ...testResultStyle,
  background: theme.palette.error.background,
  color: theme.palette.error.main
});

const labelStyle = theme => ({
  fontSize: "0.75rem",
  color: theme.themeId === "light" ? "#333333" : "rgba(255, 255, 255, 0.7)"
});

const mappersByColumnType = {
  STRING: {
    commonMapper: cell => cell.value,
    displayMapper: cell => cell,
    sortMapper: null
  },
  PERCENT: {
    displayMapper: cell => Formatter.format(cell, {valueFormat: "PERCENT"})
  },
  CURRENCY: {
    displayMapper: cell => Formatter.format(cell)
  },
  INTEGER: {
    displayMapper: cell => Formatter.format(cell, {valueFormat: "NUMBER"})
  },
  DATE: {
    commonMapper: cell => moment(cell.value, "YYYY-MM-DD"),
    sortMapper: date => date.isValid() ? date.valueOf() : 0,
    displayMapper: date => date.isValid() ? date.format("L") : ""
  }
};

export {
  ConfigurableTest,
  SimpleTest
};
