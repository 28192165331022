import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import Select from "js/common/views/inputs/immutable-react-select";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

export default createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes:{
        timeframes: ImmutablePropTypes.list.isRequired,
        selectedTimeframeId: ReactPropTypes.string.isRequired,
        onChange: ReactPropTypes.func.isRequired
    },

    render() {
        return (
            <Select
                isMulti={false}
                isClearable={false}
                isSearchable={this.props.timeframes.count() > 5}
                placeholder="Select a Timeframe"
                selectedValue={this.props.selectedTimeframeId}
                valueRenderer={valueRenderer}
                options={toOptions(this.props.timeframes)}
                optionRenderer={optionRenderer}
                onChange={this.props.onChange} />
        );
    }

});

const toOptions = timeframes => timeframes
    .map(timeframe => (Immutable.Map({
        value: timeframe.get("id"),
        label: timeframe.get("shortName") || timeframe.get("name"),
        isHidden: !timeframe.get("visible"),
        isDeleted: timeframe.get("isDeleted")
    })));

const optionRenderer = option => (
    <span key={option.value}>
        <span style={{color: "inherit"}}>{option.label}</span>
        {option.isDeleted &&  <StatusLabel status="DELETED" labelColor="#f00" />}
        {option.isHidden && <StatusLabel status="HIDDEN" labelColor="#ff8d00" />}
    </span>
);

const valueRenderer = value => (
    <span>
        <span style={{color: "inherit"}}>{value.label}</span>
        {value.isDeleted && <StatusLabel status="DELETED" labelColor="#f00" />}
        {value.isHidden && <StatusLabel status="HIDDEN" labelColor="#ff8d00" />}
    </span>
);

const StatusLabel = pure(({ status, labelColor }) => (
    <strong style={{...labelStyle, backgroundColor: labelColor}}>
        <i className="fa fa-exclamation-triangle" /> {status}
    </strong>
));

const labelStyle = {
    color: "#000",
    borderRadius: 3,
    paddingLeft: 6,
    paddingRight: 6,
    marginLeft: 8,
    marginRight: 8
};
