import React from "react";
import Immutable from "immutable";
import {betterMemo} from "js/common/utils/more-memo";

import LabelledSelect from "js/common/views/inputs/labelled-select";
import Select from "js/common/views/inputs/immutable-react-select";
import LoadingSpinner from "js/common/views/loading-spinner";

const SingleEntityPathPicker = betterMemo(
  {displayName: "SingleEntityPathPicker"},
  ({entityPath, onChange, typeToGroupingEntity, label, style}) => {
  if (typeToGroupingEntity.isEmpty()) {
    return <LoadingSpinner />;
  }

  const entityType = entityPath.last();
  const entity = typeToGroupingEntity.get(entityType);
  const potentialPaths = entity.get("directJoinsTo").map(join => entityPath.push(join));

  let options = potentialPaths
    .map(path => Immutable.Map({
      label: path.map(e => typeToGroupingEntity.get(e).get("name")).join(" > "),
      value: path.join(",")
    }))
    .push(Immutable.Map({
      label: entityPath.map(e => typeToGroupingEntity.get(e).get("name")).join(" > "),
      value: entityPath.join(",")
    }))

  if(entityPath.size > 1) {
    options = options.push(Immutable.Map({
      label: entityPath
        .take(entityPath.size-1)
        .map(e => typeToGroupingEntity.get(e).get("name")).join(" > "),
      value: entityPath
        .take(entityPath.size-1)
        .join(",")
    }));
  }

  options = options.sortBy(option => option.get("label"));

  const selectedValue = entityPath.join(",");

  return <LabelledSelect
    label={label}
    options={options}
    placeholder="Joins"
    selectedValue={selectedValue}
    onChange={pathStr => onChange(pathStr)}
    closeOnSelect={false}
    isMulti={false}
    isClearable={true}
    isSearchable={true}
    containerStyle={style}/>;

});

const MultiEntityPathPicker = betterMemo(
  {displayName: "MultiEntityPathPicker"},
  ({entityPaths, onChange, typeToGroupingEntity, label, style}) => {

  const handleEntityPathsChange = React.useCallback((entityPathStrs, pathLengthToReplace) => {
    const selectedEntityPaths = entityPathStrs.map(pathStr => Immutable.List(pathStr.split(",")));
    const oldPathsWithSameLength = entityPaths.filter(path => path.count() === pathLengthToReplace);
    const deletedPaths = oldPathsWithSameLength.subtract(selectedEntityPaths);

    const newEntityPaths = entityPaths
      .filter(path => path.count() !== pathLengthToReplace)
      .filter(path => {
        if (path.count() > pathLengthToReplace) {
          const dependsOnDeletedPath = deletedPaths.some(deletedPath => pathStartsWith(path, deletedPath));
          return !dependsOnDeletedPath;
        } else {
          return true;
        }
      })
      .union(selectedEntityPaths);

    onChange(newEntityPaths);
  }, [entityPaths, onChange]);

  const maxEntityPathLength = entityPaths.map(entityPath => {
    return entityPath.count();}).max();
  let pickers = [];
  for (let i = 0; i < maxEntityPathLength; i++) {
    const pathsUptoIndex = entityPaths
      .filter(entityPath => entityPath.count() >= (i + 1))
      .map(entityPath => entityPath.take(i + 1));

    const potentialPaths = pathsUptoIndex.flatMap(path => {
      const entityType = path.last();
      const entity = typeToGroupingEntity.get(entityType);
      return entity.get("directJoinsTo").map(e => path.push(e));
    });

    const options = potentialPaths
      .map(path =>  Immutable.Map({
        label: path.skip(1).map(e => typeToGroupingEntity.get(e).get("name")).join(" > "),
        value: path.join(",")
      }))
      .sortBy(option => option.get("label"));

    const selectedValues = entityPaths
      .filter(entityPath => entityPath.count() >= (i + 2))
      .map(entityPath => entityPath.take(i + 2))
      .map(path => path.join(","));

    pickers.push(<Select
      key={i}
      options={options}
      placeholder="Joins"
      selectedValues={selectedValues}
      onChange={pathStrs => handleEntityPathsChange(pathStrs, i + 2)}
      closeOnSelect={false}
      isMulti={true}
      isClearable={true}
      isSearchable={true} />);
  }
  if (pickers.length > 0) {
    return <div style={style}>
      <div>{label}</div>
      {pickers}</div>;
  } else {
    return null;
  }
});

const pathStartsWith = (path, prefix) => Immutable.is(path.slice(0, prefix.count()), prefix);


export {
  SingleEntityPathPicker,
  MultiEntityPathPicker
};