/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";
import Immutable from "immutable";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";
import {css} from "@emotion/react";

import KpiPicker from "js/reporting/kpi-picker";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import GroupAndUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import TagFilter from "js/common/views/inputs/tag-picker/immutable-tag-filter";
import ClientFilter from "js/common/views/inputs/organisation-picker/react-organisation-picker";
import Tooltip from "js/common/views/tooltips";
import ErrorMsg from "js/common/views/error";
import LoadingSpinner from "js/common/views/loading-spinner";
import ReportingPivotTable from "js/reporting/reporting-pivot-table";
import Checkbox from "js/common/views/inputs/checkbox";
import {greyLight} from "js/common/cube19-colors";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import {Column, Layout, Row} from "js/common/views/foundation-column-layout";
import {isDataTableTypeReport} from "js/common/pivot-utils";
import pure from "js/common/views/pure";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Auditor from "js/common/auditer";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import * as Branding from "js/common/branding-constants";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const reportNameBarHeight = 36;

const smallScreenBreakpoint = 1024;

const dataVisibilityErrorMessage = "You do not have permission to run the report over the intended User or Group. "
    + "Please select another User or Group for the report. "
    + "You can save a copy of the modified report to avoid seeing this message again.";

export default GetContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      isReportConfigEditorOpen: true
    };
  },

  render() {
    const {isLoadingReport, data, loadReportError, generateCsvError, theme} = this.props;
    const error = loadReportError || generateCsvError;
    const isInitialState = !isLoadingReport && !data && !error;
    const hasError = !isLoadingReport && !!error;
    return (
        <div style={{height: "100%"}}>
          <div
              style={theme.themeId === "light" ? {
                background: theme.palette.background.card,
                padding: "10px 0",
                marginBottom: 0,
                boxShadow: "rgb(0 0 0 / 20%) 0px 2px 1px -1px"
              } : {}}>
            <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
              {this.state.isReportConfigEditorOpen && this.renderReportingConfig()}
            </VelocityTransitionGroup>
          </div>
          {this.renderCurrentReportNameBar()}
          <div id="report-container" style={{overflow: "auto", paddingBottom: "60px"}}>
            {isInitialState && <HowToUseMessage />}
            {isLoadingReport && this.renderLoadingSpinner()}
            {(!isInitialState && !hasError) && this.renderReportingPivotTable()}
            {hasError && this.renderReportError()}
          </div>
        </div>
    );
  },

  renderLoadingSpinner() {
    return (
        <div style={{paddingTop: "3.5rem"}}>
          <LoadingSpinner label="Loading Slice & Dice" />
        </div>
    );
  },

  renderReportingConfig() {
    const {
      isLoadingKpis,
      kpisById,
      hierarchy,
      timeframes,
      config,
      onConfigChange,
      isConfigChanged,
      isLoadingReport,
      isGeneratingCsv,
      theme,
      data
    } = this.props;
    const isLoadingData = isLoadingReport || isGeneratingCsv;
    const isUserReport = config.has("userId") && !(config.get("userId") === null || config.get("userId") === undefined);
    const extraConfig = config.get("extraConfig");
    const organisationIds = extraConfig.get("organisationIds", Immutable.List());
    const timeframe = config.get("timeframe");
    const visibleTimeframes = timeframes
        .filter(t => (t.get("visible") && !t.get("isDeleted")) || t.get("id") === timeframe.get("type"));
    const rowStyle = {
      padding: "0.5rem",
      maxWidth: "100%"
    };
    return (
        <div id="report-config-editor" style={theme.themeId === "light" ? {} : {margin: "20px 0"}}>
          <Layout allSmall={12} allMedium={4} collapseRow={true} rowStyle={rowStyle} columnStyle={columnStyle}>
            <KpiPicker
                kpisById={kpisById}
                selectedKpiIds={config.get("kpiIds").toSet()}
                isLoadingKpis={isLoadingKpis}
                isDisabled={isLoadingData}
                disableNonClientFilterableKpis={!organisationIds.isEmpty()}
                onKpiSelectionChange={kpiIds => onConfigChange(config.set("kpiIds", kpiIds))} />
            <GroupAndUserPicker
                hierarchy={hierarchy.toJS()}
                showLoggedInUserOptions={Users.currentHasPermission("SHARE_SND_REPORT")}
                isDisabled={Users.getCurrentUser().get("dataVisibility") === "SELF" || isLoadingReport}
                qualifierType={isUserReport ? "USER" : "GROUP"}
                qualifierId={isUserReport ? config.get("userId") : config.get("groupId")}
                onGroupClick={this.handleGroupSelect}
                onUserClick={this.handleUserSelect} />
            <TimeframePicker
                timeframes={visibleTimeframes}
                timeframe={getTimeframeModel(timeframe)}
                isDisabled={isLoadingData}
                onChange={timeframe => {
                  onConfigChange(config.set("timeframe", Immutable.fromJS(timeframe.getRawJson())));
                }} />
          </Layout>
          <Layout allSmall={12} allMedium={4} collapseRow={true} rowStyle={rowStyle} columnStyle={columnStyle}>
            <TagFilter
                label="Match Any Tags"
                labelStyle={{fontSize: 14}}
                tagIds={config.get("matchAnyTagIds")}
                disabled={isLoadingData}
                onChange={tagIds => onConfigChange(config.set("matchAnyTagIds", tagIds))} />
            <TagFilter
                label="Match All Tags"
                labelStyle={{fontSize: 14}}
                tagIds={config.get("matchAllTagIds")}
                disabled={isLoadingData}
                onChange={tagIds => onConfigChange(config.set("matchAllTagIds", tagIds))} />
            <TagFilter
                label="Exclude Tags"
                labelStyle={{fontSize: 14}}
                tagIds={config.get("excludedTagIds")}
                disabled={isLoadingData}
                onChange={tagIds => onConfigChange(config.set("excludedTagIds", tagIds))} />
          </Layout>
          <Layout allSmall={12} allMedium={6} collapseRow={true} rowStyle={rowStyle} columnStyle={columnStyle}>
            {this.renderClientFilter()}
            {this.renderPivotTableOptions()}
          </Layout>
          {this.renderReportActionButtons()}
          {(!isLoadingReport && data && isConfigChanged) &&
              <WarningMsg
                  text="The report settings have changed. Click on 'Generate Report' to update the report data."
                  style={{margin: "0.5rem 1rem"}} />}
        </div>
    );
  },

  handleGroupSelect(groupId) {
    const newConfig = this.props.config
        .set("groupId", groupId)
        .delete("userId");
    this.props.onConfigChange(newConfig);
  },

  handleUserSelect(userId) {
    const newConfig = this.props.config
        .set("groupId", getUser(userId).get("groupId"))
        .set("userId", userId);
    this.props.onConfigChange(newConfig);
  },

  renderClientFilter() {
    const {
      config,
      isLoadingReport,
      isGeneratingCsv,
      onShowSelectedNonClientFilterableKpisRequest,
      onConfigChange,
      theme
    } = this.props;
    const pathToOrganisationIds = ["extraConfig", "organisationIds"];
    const organisationIds = config.getIn(pathToOrganisationIds);
    const isClientFilterActive = !organisationIds.isEmpty();
    const isNonClientFilterableKpisSelected = config
        .get("kpiIds")
        .some(kpiId => {
          const kpi = KpiRepo.get(kpiId);
          return !kpi.get("filterableByClient");
        });
    const isNonClientFilterableKpisDisabledWarningVisible = isNonClientFilterableKpisSelected && isClientFilterActive;
    return (
        <div className={`table-${theme.themeId}`}>
          <label style={{cursor: "default", marginBottom: "0.1875rem"}}>
            <i className="bhi-company" />
            <span style={{paddingLeft: 8, paddingRight: 8, fontSize: 14}}>
                        Clients &nbsp;
              {isNonClientFilterableKpisDisabledWarningVisible &&
                  <NonClientFilterableKpisSelectedWarning
                      theme={theme}
                      onClick={onShowSelectedNonClientFilterableKpisRequest} />}
                    </span>
          </label>
          <ClientFilter
              clientIds={config.getIn(pathToOrganisationIds)}
              onChange={organisationIds => onConfigChange(config.setIn(pathToOrganisationIds, organisationIds))}
              isDisabled={isLoadingReport || isGeneratingCsv} />
        </div>
    );
  },

  renderPivotTableOptions() {
    const isLoadingData = this.props.isLoadingReport || this.props.isGeneratingCsv;
    const showTotals = this.props.config.get("total").some(showColOrRowTotal => showColOrRowTotal);
    const chartRenderer = this.props.config.get("renderer");
    const isShowTotalsCheckboxDisabled = !isDataTableTypeReport(chartRenderer);
    const pathToShowUsersWithNoData = ["extraConfig", "showUsersWithNoData"];
    return (
        <div>
          <h5 style={{fontSize: "13px", fontWeight: "normal", marginBottom: 3}}>
            Display Options
          </h5>
          <Checkbox
              id="TESTCAFE_show_totals_checkbox"
              label="Show Totals"
              checked={showTotals}
              onCheck={(e, isChecked) => this.handleShowTotalsToggle(isChecked)}
              disabled={isShowTotalsCheckboxDisabled || isLoadingData}
              style={{display: "inline-block", width: 145, marginTop: "-9px"}}
              labelStyle={{marginLeft: "-4px", position: "relative", top: "1px"}}
              iconStyle={{marginRight: 0}} />
          <Checkbox
              id="TESTCAFE_no_data_checkbox"
              label="Show Rows For Users With No Data"
              checked={this.props.config.getIn(pathToShowUsersWithNoData)}
              onCheck={(e, isChecked) => {
                this.props.onConfigChange(this.props.config.setIn(pathToShowUsersWithNoData, isChecked));
              }}
              disabled={isLoadingData}
              style={{
                display: "inline-block",
                width: 286,
                marginTop: "-9px"
              }}
              labelStyle={{marginLeft: "-5px", position: "relative", top: "1px"}}
              iconStyle={{marginRight: 0}} />
        </div>
    );
  },

  handleShowTotalsToggle(isChecked) {
    if (isChecked) {
      Auditor.audit("main_snd:show_totals");
    } else {
      Auditor.audit("main_snd:hide_totals");
    }

    const newTotal = Immutable.fromJS({
      column: isChecked,
      row: isChecked,
      grand: isChecked
    });
    this.props.onConfigChange(this.props.config.set("total", newTotal));
  },

  renderReportActionButtons() {
    const {
      config,
      isLoadingReport,
      isGeneratingCsv,
      onGenerateReportRequest,
      onGenerateAsCsvRequest,
      onClearReportRequest,
      onSaveReportRequest,
      containerWidth
    } = this.props;
    const rowStyle = {
      maxWidth: "100%",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      paddingLeft: "0.25rem",
      paddingRight: "0.25rem"
    };
    const columnStyle = {padding: containerWidth < smallScreenBreakpoint ? "0.5rem" : "0 0.5rem"};
    return (
        <Row collapse={true} style={{...rowStyle, paddingTop: "0.2rem"}}>
          <Column
              small={12}
              medium={5}
              style={{...columnStyle, textAlign: containerWidth < smallScreenBreakpoint ? "center" : "left"}}>
            <TextButton
                iconType="bhi"
                icon="check"
                type="primary"
                label="Save Report"
                disabled={config.get("kpiIds").isEmpty() || isLoadingReport || isGeneratingCsv}
                onClick={onSaveReportRequest}
                style={buttonStyle} />
            {this.renderDownloadButton()}
          </Column>
          <Column
              small={12}
              medium={7}
              style={{...columnStyle, textAlign: containerWidth < smallScreenBreakpoint ? "center" : "right"}}>
            <TextButton
                iconType="bhi"
                icon="delete"
                label="Clear Report"
                disabled={config.get("kpiIds").isEmpty() || isLoadingReport}
                onClick={onClearReportRequest}
                style={buttonStyle} />
            <TextButton
                type="primary"
                icon={isLoadingReport ? "" : "play-circle"}
                label={isLoadingReport ?
                    <LoadingIndicatorLabel label="Generating Report" /> : "Generate Report"
                }
                disabled={isLoadingReport}
                onClick={onGenerateReportRequest}
                style={buttonStyle} />
            {Users.currentHasPermission("EXPORT_DIRECT_TO_CSV") &&
                <TextButton
                    iconType="bhi"
                    icon={isGeneratingCsv ? "" : "note"}
                    label={isGeneratingCsv ?
                        <LoadingIndicatorLabel label="Generating CSV File" /> : "Generate as CSV"
                    }
                    onClick={onGenerateAsCsvRequest}
                    disabled={isGeneratingCsv}
                    style={buttonStyle} />}
          </Column>
        </Row>
    );
  },

  renderDownloadButton() {
    const buttonLabel = "Download";
    if (Users.currentHasPermission("EXPORT_FILE")) {
      return (
          <TextButton
              iconType="bhi"
              id={"save-snd-to-file"}
              icon="save"
              label={buttonLabel}
              disabled={this.props.isLoadingReport || this.props.isGeneratingCsv || !this.props.data}
              onClick={this.props.onDownloadReportRequest}
              style={buttonStyle} />
      );
    } else {
      return (
          <div style={{display: "inline-block"}}>
            <Tooltip
                text="Ask one of your admin Users for the 'Export To File' permission if you need to download this information"
                position={this.props.containerWidth < smallScreenBreakpoint ? "top" : "right"}>
              <TextButton icon="file-o" label={buttonLabel} disabled={true} onClick={() => {}} style={buttonStyle} />
            </Tooltip>
          </div>
      );
    }
  },

  renderCurrentReportNameBar() {
    const {config, theme} = this.props;
    const {isReportConfigEditorOpen} = this.state;
    const groupId = config.get("groupId");
    const userId = config.get("userId");
    const isUserReport = config.has("userId") && (userId !== null && userId !== undefined);
    const group = getGroup(groupId);
    const groupBreadcrumbs = group ? group.get("breadcrumbs").join(" > ") : "";

    const containerStyle = {
      fontSize: "0.825rem",
      height: reportNameBarHeight,
      display: "flex",
      flexShrink: 0,
      alignItems: "center",
      justifyContent: "space-between",
      borderTop: `1px solid ${greyLight}`,
      borderBottom: `1px solid ${greyLight}`,
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem"
    };
    const breadcrumbsStyle = theme => ({
      fontWeight: "bold",
      color: theme.palette.primary.main,
      paddingLeft: "0.25rem",
      paddingRight: "0.25rem"
    });
    return (
        <div style={containerStyle}>
              <span style={{paddingLeft: "0.5rem", paddingRight: "0.5rem"}}>
                  <span>Currently viewing <strong>{config.get("reportName")}</strong> data for:</span>
                  <span style={breadcrumbsStyle(theme)}>
                        {groupBreadcrumbs}{isUserReport && ` > ${getUser(userId).get("fullName")}`}
                  </span>
              </span>

          <span style={{paddingLeft: "0.5rem", paddingRight: "0.5rem"}}>
                  <IconButton
                      icon={isReportConfigEditorOpen ? "expand" : "compress"}
                      iconStyle={{
                        width: 22,
                        height: 22
                      }}
                      style={{
                        width: 36,
                        height: 36,
                        padding: 0
                      }}
                      hoveredStyle={{background: "#555"}}
                      tooltip={isReportConfigEditorOpen ? "Enter Fullscreen mode" : "Exit Fullscreen mode"}
                      onClick={this.toggleConfigEditorVisibility}
                      tooltipPosition="bottom-left"
                      size="large" />
              </span>
        </div>
    );
  },

  toggleConfigEditorVisibility() {
    this.setState({
      isReportConfigEditorOpen: !this.state.isReportConfigEditorOpen
    }, () => {
      const isInFullscreenMode = !this.state.isReportConfigEditorOpen;
      if (isInFullscreenMode) {
        Auditor.audit("main_snd:fullscreen");
      }
    });
  },

  renderReportError() {
    const error = this.props.loadReportError || this.props.generateCsvError;
    switch (error) {
      case "TOO_MUCH_DATA":
      case "TIMEOUT":
        return <TimeoutErrorMessage />;
      case "DATA_VISIBILITY":
        return <DataVisibilityErrorMessage />;
      case "SERVER_ERROR":
        return <ServerErrorMessage />;
      case "NO_KPI_IDS":
        return <NoKpisErrorMessage />;
      default:
        return <UnexpectedErrorMessage />;
    }
  },

  renderReportingPivotTable() {
    return (
        <div style={{padding: "0.5rem"}}>
          {this.props.reportHasNoData &&
              <WarningMsg text="This report contains no data. Please choose different Metrics, Users/Groups or change the timeframe for the report." />}
          {this.props.reportHasLimitedData && <WarningMsg text={dataVisibilityErrorMessage} />}
          {this.props.rowLimitReached &&
              <WarningMsg text={"A limit of 500k rows are shown below. If you need to see data that is not loaded, please adjust your filters."} />}
          <ReportingPivotTable
              config={this.props.config}
              derivedPivotTableAttributes={this.props.derivedPivotTableAttributes}
              data={this.props.data}
              onCellClick={this.props.onPivotTableCellClick}
              onPivotTableRefresh={this.props.onPivotTableRefresh} />
        </div>
    );
  }

}));

const NonClientFilterableKpisSelectedWarning = pure(({theme, onClick}) => {
  const moreInfoStyle = css`
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    text-transform: none;
    margin: 0;
    padding: 0;
    color: #bbb;

    &:hover,
    &:focus {
      color: #959595;
      background-color: transparent;
      text-decoration: none;
    }`;
  return (
      <span style={{color: theme.palette.warning.main, paddingLeft: 5, paddingRight: 5}}>
        <i className="fa fa-exclamation-triangle" style={{paddingLeft: 5, paddingRight: 5}} />
        Non Client filterable Metric(s) selected &nbsp;
        <button css={moreInfoStyle} onClick={onClick}>(more info)</button>
    </span>
  );
});

const WarningMsg = pure(({text, style = {}}) => {
  return <ErrorMsg type="warn" text={text} style={{marginLeft: "0.5rem", marginRight: "0.5rem", ...style}} />;
});

const headerStyle = {marginBottom: "1rem"};

const errorMessageContainerStyle = {padding: "5vh 3vw"};

const TimeoutErrorMessage = pure(() => (
    <div style={errorMessageContainerStyle}>
      <h3 style={headerStyle}>
        We are sorry, but the data you have requested is too large.
      </h3>

      <p>Please try to select a smaller date range, fewer Users, or fewer Metrics to report on.</p>

      <p>If this problem persists please
        contact <a href={`mailto:${Branding.emailSupport}?subject=${Branding.brandingName} Slice & Dice Timeout Error`}>{Branding.brandingName}</a> with
        details of the search you are trying to run.<br />
        {Branding.submitTicketInstructions}
      </p>
    </div>
));

const DataVisibilityErrorMessage = pure(() => (
    <div style={errorMessageContainerStyle}>
      <h3 style={headerStyle}>
        You no longer have permission to view this Slice & Dice Report.
      </h3>

      <p>{dataVisibilityErrorMessage}</p>
    </div>
));

const ServerErrorMessage = pure(() => (
    <div style={errorMessageContainerStyle}>
      <h3 style={headerStyle}>
        We are sorry, but we were unable to load your Slice & Dice Report.
      </h3>

      <p>Please try again after a few minutes. If this problem persists please
        contact <a href={`mailto:${Branding.emailSupport}?subject=${Branding.brandingName} Slice & Dice Server Error`}>{Branding.brandingName}</a> with
        details of the search you are trying to run.<br />
        {Branding.submitTicketInstructions}
      </p>
    </div>
));

const NoKpisErrorMessage = pure(() => (
    <div style={errorMessageContainerStyle}>
      <h3 style={headerStyle}>
        This Slice & Dice report contains no valid Metrics.
      </h3>

      <p>Please select at least one Metric for your report.</p>
    </div>
));

const UnexpectedErrorMessage = pure(() => (
    <div style={errorMessageContainerStyle}>
      <h3 style={headerStyle}>
        We are sorry, but an unexpected error has occurred.
      </h3>

      <p>Please try again after a few minutes. If this problem persists please
        contact <a href={`mailto:${Branding.emailSupport}?subject=${Branding.brandingName} Slice & Dice Unexpected Error`}>{Branding.emailSupport}</a> with
        details of the search you are trying to run.<br />
        {Branding.submitTicketInstructions}
      </p>
    </div>
));

const HowToUseMessage = GetContainerDimensions()(React.memo(({containerWidth}) => {

  const {theme} = React.useContext(CustomThemeContext);

  return (
      <div style={{paddingTop: "5vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div style={{maxWidth: containerWidth < 1024 ? "100%" : 895}}>
          <h3 style={{fontSize: "1.5rem", marginBottom: "1.5rem", ...headerTextStyle(theme)}}>
            How to use Slice & Dice
          </h3>

          <p>Welcome to the {Branding.brandingName} Slice & Dice.</p>

          <p>There are 2 ways to create a Report in Slice & Dice:</p>

          <h4 style={subHeaderStyle(theme)}>
            Method 1: Saved Reports
          </h4>

          <ol style={listStyle}>
            <li>Click on <strong>Saved Reports</strong> in the Menu Bar located at the bottom left hand corner of the
              screen.
            </li>
            <li>Select the Saved Report you would like to use from the list.</li>
          </ol>

          <h4 style={subHeaderStyle(theme)}>
            Method 2: Manually Set Up A Report
          </h4>

          <ol style={listStyle}>
            <li>Using the Report Setting options available above, select the required Report Metric(s), a User or Group
              and a Timeframe.
            </li>
            <li>Click on the <strong>Generate Report</strong> button when you're done to generate the Report.</li>
          </ol>
        </div>
      </div>
  );
}));

const headerTextStyle = theme => ({
  color: theme.palette.primary.main,
  lineHeight: 1.4
});

const subHeaderStyle = theme => ({
  fontSize: "1rem",
  ...headerTextStyle(theme)
});

const listStyle = {marginLeft: "1.25rem"};

const columnStyle = {
  paddingLeft: "0.5rem",
  paddingRight: "0.5rem"
};

const buttonStyle = {
  minWidth: 85,
  marginLeft: "0.25rem",
  marginRight: "0.25rem",
  paddingLeft: "0.8rem",
  paddingRight: "0.8rem"
};

const LoadingIndicatorLabel = pure(({label}) => (
    <span style={{paddingLeft: 5, paddingRight: 7}}>
        <i className="fa fa-circle-o-notch fa-spin fa-fw" /> {label}
    </span>
));

// User/Group ID of "0" means current user or group (see Mingle ticket #3426)
const getGroup = groupId => Groups.getGroupOrCurrent(groupId);
const getUser = userId => Users.getUserOrCurrent(userId);

const getTimeframeModel = timeframe => TimeframeRepo.parse(timeframe.toJS());
