window.globalVariables = {};

const saveAsGlobalVariable = (name, object) => {
    window.globalVariables[name] = object;
};

const getGlobalVariable = (name) => {
    return window.globalVariables[name];
};

const destroyGlobalVariable = (name) => {
    delete window.globalVariables[name];
};

export {
    saveAsGlobalVariable,
    getGlobalVariable,
    destroyGlobalVariable
};
