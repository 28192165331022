/** @jsxImportSource @emotion/react */
import React from "react";
import Switch from '@mui/material/Switch';
import {css} from "@emotion/react";
import pure from "js/common/views/pure";

export default pure(({
    leftLabel = "",
    rightLabel = "",
    style = {display: "flex", alignItems: "center", justifyContent: "center"},
    isLeft = true,
    onChange = () => {},
    onColor,
    offColor,
}) => {

  const switchStyle = css`
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track{
      background-color: ${onColor};
    }

    .MuiSwitch-track {
      background-color: ${offColor};
      opacity: 0.4 !important;
    }

    .MuiSwitch-thumb {
      background-color: ${onColor};
    }

    .label {
      margin-bottom: 0 !important;
    }`;

  return (
        <div style={style}>
            <div
                style={{display: "inline-block", color: isLeft ? onColor : "grey"}}
                onClick={() => onChange(true)}>
                {leftLabel}
            </div>
            <Switch
                label=""
                data-test-id = "two-option-toggle"
                checked={!isLeft}
                onChange={() => onChange(!isLeft)}
                css={switchStyle}
            />
            <div
                style={{display: "inline-block", color: isLeft ? "grey" : onColor}}
                onClick={() => onChange(false)}>
                {rightLabel}
            </div>
        </div>
    );
}, "TwoOptionToggle");
