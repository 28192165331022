import Immutable from "immutable";
import moment from "moment";

import { getQuarterDto } from "js/common/utils/financial-quarter-deriver";
import * as numbers from "js/common/utils/numbers";
import currentClient from "js/common/repo/backbone/current-client";

const mysqlDateFormat = "YYYY-MM-DD";

const aggregateByDay = (data, startDate, endDate) => {
    const daysBetweenStartAndEndDate = endDate.diff(startDate, "days") + 1;
    let dataByDaysFromStart = new Immutable.Map();
    data.forEach(t => { dataByDaysFromStart = dataByDaysFromStart.set(t.daysFromStart, t); });

    // NOTE: Trend Data's 'daysFromStart' property does not start from zero
    return rangeAsArray(1, daysBetweenStartAndEndDate)
        .map(daysFromStart => {
            const date = startDate.clone().add(daysFromStart - 1, "days");
            return dataByDaysFromStart.get(daysFromStart) || getDefaultDataFor("daysFromStart", daysFromStart, date);
        });
};

const aggregateByWeek = (data, startDate, endDate, trendAggregation) => {
    const weeksBetweenStartAndEndDate = endDate.diff(startDate.clone().startOf("isoWeek"), "weeks") + 1;
    let dataByWeeksFromStart = new Immutable.Map();
    let weeksFromStart = 0;
    while (++weeksFromStart <= weeksBetweenStartAndEndDate) {
        const startOfWeek = getStartOfTimePeriod(startDate, weeksFromStart, "week");
        const isPartialWeekStart = startOfWeek.isBefore(startDate);
        const startDateToDisplay = isPartialWeekStart ? startDate : startOfWeek;
        const endOfWeek = startOfWeek.clone().endOf("isoWeek");
        const dataForWeek = data.filter(t => t.date.isBetween(startOfWeek, endOfWeek, null, "[]"));

        const total = dataForWeek.reduce((sum, data) => sum += data.value.value, 0);
        if (trendAggregation === "AVERAGE") {
            const averageForWeek = getDefaultDataFor("weeksFromStart", weeksFromStart, startDateToDisplay);
            const average = calculateAverage(total, dataForWeek.length);
            averageForWeek.value.value = numbers.roundTo(average, 2);
            averageForWeek.isPartialWeekStart = isPartialWeekStart;
            dataByWeeksFromStart = dataByWeeksFromStart.set(weeksFromStart, averageForWeek);
        } else {
            const totalForWeek = getDefaultDataFor("weeksFromStart", weeksFromStart, startDateToDisplay);
            totalForWeek.value.value = total;
            totalForWeek.isPartialWeekStart = isPartialWeekStart;
            dataByWeeksFromStart = dataByWeeksFromStart.set(weeksFromStart, totalForWeek);
        }
    }
    return rangeAsArray(1, weeksBetweenStartAndEndDate)
        .map(weeksFromStart => {
            const date = getStartOfTimePeriod(startDate, weeksFromStart, "week");
            return dataByWeeksFromStart.get(weeksFromStart)
                || getDefaultDataFor("weeksFromStart", weeksFromStart, date);
        });
};

const aggregateByMonth = (data, startDate, endDate, trendAggregation) => {
    const monthsBetweenStartAndEndDate = endDate.diff(startDate, "months") + 1;
    let dataByMonthsFromStart = new Immutable.Map();
    let monthsFromStart = 0;
    while (++monthsFromStart <= monthsBetweenStartAndEndDate) {
        const startOfMonth = getStartOfTimePeriod(startDate, monthsFromStart, "month");
        const dataForMonth = data.filter(t => t.date.isSame(startOfMonth, "month"));

        const total = dataForMonth.reduce((sum, data) => sum += data.value.value, 0);
        if (trendAggregation === "AVERAGE") {
            const averageForMonth = getDefaultDataFor("monthsFromStart", monthsFromStart, startOfMonth);
            const average = calculateAverage(total, dataForMonth.length);
            averageForMonth.value.value = numbers.roundTo(average, 2);
            dataByMonthsFromStart = dataByMonthsFromStart.set(monthsFromStart, averageForMonth);
        } else {
            const totalForMonth = getDefaultDataFor("monthsFromStart", monthsFromStart, startOfMonth);
            totalForMonth.value.value = total;
            dataByMonthsFromStart = dataByMonthsFromStart.set(monthsFromStart, totalForMonth);
        }
    }
    return rangeAsArray(1, monthsBetweenStartAndEndDate)
        .map(monthsFromStart => {
            const date = getStartOfTimePeriod(startDate, monthsFromStart, "month");
            return dataByMonthsFromStart.get(monthsFromStart)
                || getDefaultDataFor("monthsFromStart", monthsFromStart, date);
        });
};

const aggregateByQuarter = (data, startDate, endDate, trendAggregation) => {
    const quartersBetweenStartAndEndDate = endDate.diff(startDate, "quarters") + 1;
    let dataByQuartersFromStart = new Immutable.Map();
    let quartersFromStart = 0;
    while (++quartersFromStart <= quartersBetweenStartAndEndDate) {
        const startOfQuarter = getStartOfTimePeriod(startDate, quartersFromStart, "quarter");
        const dataForQuarter = data.filter(t => t.date.isSame(startOfQuarter, "quarter"));

        const total = dataForQuarter.reduce((sum, data) => sum += data.value.value, 0);
        if (trendAggregation === "AVERAGE") {
            const averageForQuarter = getDefaultDataFor("quartersFromStart", quartersFromStart, startOfQuarter);
            const average = calculateAverage(total, dataForQuarter.length);
            averageForQuarter.value.value = numbers.roundTo(average, 2);
            dataByQuartersFromStart = dataByQuartersFromStart.set(quartersFromStart, averageForQuarter);
        } else {
            const totalForQuarter = getDefaultDataFor("quartersFromStart", quartersFromStart, startOfQuarter);
            totalForQuarter.value.value = total;
            dataByQuartersFromStart = dataByQuartersFromStart.set(quartersFromStart, totalForQuarter);
        }
    }
    return rangeAsArray(1, quartersBetweenStartAndEndDate)
        .map(quartersFromStart => {
            const date = getStartOfTimePeriod(startDate, quartersFromStart, "quarter");
            return dataByQuartersFromStart.get(quartersFromStart)
                || getDefaultDataFor("quartersFromStart", quartersFromStart, date);
        });
};

const aggregateByYear = (data, startDate, endDate, trendAggregation) => {
    const yearsBetweenStartAndEndDate = (endDate.year() - startDate.year()) + 1;
    let dataByYearsFromStart = new Immutable.Map();
    let yearsFromStart = 0;
    while (++yearsFromStart <= yearsBetweenStartAndEndDate) {
        const startOfYear = getStartOfTimePeriod(startDate, yearsFromStart, "year");
        const dataForYear = data.filter(t => t.date.isSame(startOfYear, "year"));

        const total = dataForYear.reduce((sum, data) => sum += data.value.value, 0);
        if (trendAggregation === "AVERAGE") {
            const averageForYear = getDefaultDataFor("yearsFromStart", yearsFromStart, startOfYear);
            const average = calculateAverage(total, dataForYear.length);
            averageForYear.value.value = numbers.roundTo(average, 2);
            dataByYearsFromStart = dataByYearsFromStart.set(yearsFromStart, averageForYear);
        } else {
            const totalForYear = getDefaultDataFor("yearsFromStart", yearsFromStart, startOfYear);
            totalForYear.value.value = total;
            dataByYearsFromStart = dataByYearsFromStart.set(yearsFromStart, totalForYear);
        }
    }
    return rangeAsArray(1, yearsBetweenStartAndEndDate)
        .map(yearsFromStart => {
            const date = getStartOfTimePeriod(startDate, yearsFromStart, "year");
            return dataByYearsFromStart.get(yearsFromStart)
                || getDefaultDataFor("yearsFromStart", yearsFromStart, date);
        });
};

const aggregateByFinancialYear = (data, startDate, endDate, trendAggregation, startingMonthStr) => {
    const startingMonth = getStartingMonth(startingMonthStr);
    const startingFinancialQuarter = getQuarterDto(startDate, startingMonth);
    const endingFinancialQuarter = getQuarterDto(endDate, startingMonth);
    const yearsBetweenStartAndEndDate = (endingFinancialQuarter.year - startingFinancialQuarter.year) + 1;
    const calendarStartDate = moment(startingMonthStr, "MMMM").year(startingFinancialQuarter.year).date(1);

    let dataByYearsFromStart = new Immutable.Map();
    let yearsFromStart = 0;
    while (++yearsFromStart <= yearsBetweenStartAndEndDate) {
        const startOfYear = calendarStartDate.clone().add(yearsFromStart - 1, "years");
        const endOfYear = startOfYear.clone().add(1, "years");
        const dataForYear = data.filter(t => t.date.isBetween(startOfYear, endOfYear, null, "[]"));

        const total = dataForYear.reduce((sum, data) => sum += data.value.value, 0);
        if (trendAggregation === "AVERAGE") {
            const averageForYear = getDefaultDataFor("yearsFromStart", yearsFromStart, startOfYear);
            const average = calculateAverage(total, dataForYear.length);
            averageForYear.value.value = numbers.roundTo(average, 2);
            dataByYearsFromStart = dataByYearsFromStart.set(yearsFromStart, averageForYear);
        } else {
            const totalForYear = getDefaultDataForFinancialYear(yearsFromStart, startDate, startingMonth);
            totalForYear.value.value = total;
            dataByYearsFromStart = dataByYearsFromStart.set(yearsFromStart, totalForYear);
        }
    }

    return rangeAsArray(1, yearsBetweenStartAndEndDate)
        .map(yearsFromStart => dataByYearsFromStart.get(yearsFromStart) || getDefaultDataForFinancialYear(yearsFromStart, startDate, startingMonth));
};

const aggregateByFinancialQuarter = (data, startDate, endDate, trendAggregation, startingMonthStr) => {
    const startingMonth = getStartingMonth(startingMonthStr);
    const startingDateQuarter = getQuarterDto(startDate, startingMonth);
    const quartersBetweenStartAndEndDate = Math.ceil(endDate.diff(startDate, "months") / 3.0);

    let dataByQuartersFromStart = new Immutable.Map();
    let quartersFromStart = 0;
    while (++quartersFromStart <= quartersBetweenStartAndEndDate) {
        const startOfQuarter = moment(startingDateQuarter.start, mysqlDateFormat)
            .clone()
            .add((quartersFromStart - 1) * 3, "months");
        const endOfQuarter = startOfQuarter.clone().add(2, "months").endOf("month");
        const dataForQuarter = data.filter(t => t.date.isBetween(startOfQuarter, endOfQuarter, null, "[]"));

        let total = 0;
        dataForQuarter.forEach(d => total += d.value.value);
        if (trendAggregation === "AVERAGE") {
            const averageForQuarter = getDefaultDataForFinancialQuarter(quartersFromStart, startDate, startingMonth);
            const average = calculateAverage(total, dataForQuarter.length);
            averageForQuarter.value.value = numbers.roundTo(average, 2);
            dataByQuartersFromStart = dataByQuartersFromStart.set(quartersFromStart, averageForQuarter);
        } else {
            const totalForQuarter = getDefaultDataForFinancialQuarter(quartersFromStart, startDate, startingMonth);
            totalForQuarter.value.value = total;
            dataByQuartersFromStart = dataByQuartersFromStart.set(quartersFromStart, totalForQuarter);
        }
    }

    return rangeAsArray(1, quartersBetweenStartAndEndDate)
        .map(quartersFromStart => dataByQuartersFromStart.get(quartersFromStart) || getDefaultDataForFinancialQuarter(quartersFromStart, startDate, startingMonth));
};

const calculateAverage = (total, numberOfDays) => total / numberOfDays || 0;

const getStartingMonth = startingMonthStr => parseInt(moment().month(startingMonthStr).format("M"), 10);

const getStartOfTimePeriod = (date, offset, unit) => {
    const adjustedDate = date.clone().add(offset - 1, unit);
    if (unit === "week") {  // NOTE: weeks start on Monday (1) for us, moment's start on Sunday (0)
        return adjustedDate.clone().startOf("isoWeek");
    }
    return adjustedDate.clone().startOf(unit);
};

const getDefaultDataFor = (offsetType, offsetFromStart, date) => {
    let defaultData = getDefaultValue(date);
    defaultData[offsetType] = offsetFromStart;
    return defaultData;
};
const getDefaultDataForFinancialQuarter = (quartersFromStart, startDate, startingMonth) => {
    const startingQuarterDate = getQuarterDto(startDate, startingMonth).start;
    const financialQuarterStartDate = startingQuarterDate
        .clone()
        .add((quartersFromStart - 1) * 3, "months");
    return ({
        quartersFromStart,
        ...getDefaultValue(financialQuarterStartDate)
    });
};
const getDefaultDataForFinancialYear = (yearsFromStart, startDate, startingMonth) => {
    const startingQuarter = getQuarterDto(startDate, startingMonth);
    let financialCalendarStartDate = startingQuarter.start;
    if (startingQuarter.quarter > 1) {
        financialCalendarStartDate = financialCalendarStartDate
            .clone()
            .subtract((startingQuarter.quarter - 1) * 3, "months")
            .add((yearsFromStart - 1), "years");
    }
    return ({
        yearsFromStart,
        ...getDefaultValue(financialCalendarStartDate)
    });
};
const getDefaultValue = date => ({
    date,
    value: {
        value: 0
    }
});

const rangeAsArray = (start, end) => {
    let arr = [];
    for (let i = start; i <= end; i++) {
        arr.push(i);
    }
    return arr;
};

const getDataAggregations = currentDataAggregation => {
    const client = currentClient;
    if (client.hasPermission("USES_445_CALENDAR")) {
        const dataAggregations = [ "DAILY", "WEEKLY" ];
        if (!dataAggregations.includes(currentDataAggregation)) {
            dataAggregations.push(currentDataAggregation);
        }
        return dataAggregations;
    } else {
        const defaultPeriods = [
            "DAILY",
            "WEEKLY",
            "MONTHLY",
            "QUARTERLY",
            "FINANCIAL_QUARTERLY",
            "YEARLY",
            "FINANCIAL_YEARLY"
        ];
        const financialPeriods = new Set(["FINANCIAL_QUARTERLY", "FINANCIAL_YEARLY"]);
        return client.get("financialYearStartMonth") === "JANUARY" ?
            defaultPeriods.filter(period => !financialPeriods.has(period)) : defaultPeriods;
    }
};

export {
    aggregateByDay,
    aggregateByWeek,
    aggregateByMonth,
    aggregateByQuarter,
    aggregateByYear,
    aggregateByFinancialYear,
    aggregateByFinancialQuarter,
    getDataAggregations
};
