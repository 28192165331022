import React from "react";
import Immutable from "immutable";
import {TextField} from "@mui/material";

import {indexBy} from "js/common/utils/collections";

import {ParentWarning} from "js/admin/kpis/edit-kpis/warnings";
import {TextButton} from "js/common/views/inputs/buttons";
import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import Dialog from "js/common/views/dialog";

const uidPartSeparator = " - ";

const columnUid = c => c.get("entityColumnId") + uidPartSeparator + c.get("joinPathStr");

const columnLabelledUid = c => c.get("entityColumnId") + uidPartSeparator + c.get("joinPathStr") + uidPartSeparator + c.get("label");

const labelledUidToUid = labelledUid => {
  const [eid, joinPathStr] = labelledUid.split(uidPartSeparator);
  return eid + uidPartSeparator + joinPathStr;
}

const kpiToStr = (kpi, columns) => {
  // TODO might have to maintain id/order/visible in a multi export context
  //   user might want to make a bunch of common changes in a text editor then import all at once
  return JSON.stringify(
      {
        kpi: kpi
            .delete("id")
            .delete("order")
            .delete("visible")
            .toJS(),
        columns: columns
            .filter(c => c.get("visible"))
            .sortBy(c => c.get("order"))
            .map(columnLabelledUid)
            .toJS()
      },
      null,
      2);
};

const getKpiIdForReference = (reference, idToWrappedKpi, nameToWrappedKpi, masterKpiTypeToKpiId) => {
  if (idToWrappedKpi.has(reference)) {
    return reference;
  } else if (nameToWrappedKpi.has(reference)) {
    return nameToWrappedKpi.getIn([reference, "kpi", "id"]);
  } else if (masterKpiTypeToKpiId.has(reference)) {
    return masterKpiTypeToKpiId.get(reference);
  } else {
    return null;
  }
};

const resolveReferences = (newKpis, idToWrappedKpi, masterKpiTypeToKpiId) => {
  const nameToWrappedKpi = indexBy(k => k.get("name"), idToWrappedKpi.valueSeq());
  return newKpis.map(newKpi => {
    const columnsKpiId = getKpiIdForReference(newKpi.get("columnsKpiId"), idToWrappedKpi, nameToWrappedKpi, masterKpiTypeToKpiId);
    if (!columnsKpiId) {
      throw new Error("cannot resolve 'columnsKpiId' id/name/master-kpi-type to kpi: " + newKpi.get("columnsKpiId"));
    }
    newKpi.set("columnsKpiId", columnsKpiId);

    const id = getKpiIdForReference(newKpi.get("id"), idToWrappedKpi, nameToWrappedKpi, masterKpiTypeToKpiId);
    if (!id) {
      throw new Error("cannot resolve 'id' id/name/master-kpi-type to kpi: " + newKpi.get("id"));
    }
    newKpi.set("id", id);

    const inheritFromKpiId = getKpiIdForReference(newKpi.get("inheritFromKpiId"), idToWrappedKpi, nameToWrappedKpi, masterKpiTypeToKpiId);
    if (!inheritFromKpiId) {
      throw new Error("cannot resolve 'inheritFromKpiId' id/name/master-kpi-type to kpi: " +
          newKpi.get("inheritFromKpiId"));
    }
    newKpi.set("inheritFromKpiId", inheritFromKpiId);

    // TODO resolve in query params? kpisToSum, kpiIdsToSum, etc
    return newKpi;
  });
};

const MultiMetricImportExportDialog = React.memo(({
  open,
  onClose,
  idToWrappedKpi,
  masterKpiTypeToKpiId
}) => {
  // TODO multi export?
  //   picker for kpis, clicking adds kpi config to

  const [str, setStr] = React.useState("");

  const doImport = () => {
    try {
      const json = JSON.parse(str);

      let jsonConfigs;
      if (Array.isArray(json)) {
        jsonConfigs = json;
      } else {
        jsonConfigs = [json];
      }
      const configs = Immutable.fromJS(jsonConfigs);

      // TODO simplest possible start:
      //  import
      //    don't resolve root ids with name or master metric type etc (bit confusing), instead make it clear
      // what will happen list of kpis that will be updated and why (id or name matches) list of kpis that will
      // be created anew ?? option to create all kpis anew (ids will be dropped, name dupes will be resolved by
      // appending (N) to the end) if id set and is valid: update existing if id set and is invalid: fail import
      // if id not set: create new export export with id / order / visible (for update in place) export without
      // id / order / visible (for create new or making templates)

      // TODO
      //  user imports entirely new kpi with unique name and no id
      //  user imports kpi with existing name and wants to update it
      //  user imports kpi with existing name but didn't realise and just wants a new kpi
      //  user imports kpi with existing id and wants to update it (we don't even export with the id so how?)
      //  user imports kpi with existing id but didn't realise and just wants a new kpi
      //  pattern: wants new or wants overwrite

      // TODO latest after talks with simon
      //   output report of what will be created updated to confirm
      //   import with no id = create
      //   import with id = update
      //   import with id that doesn't exist in prod = create
      //   export converts all kpi id references into names
      //   import first updates all names from the new json, then resolves all references

      // could we use `handleKpiChange` in a loop instead of bulk updating here?
      // significantly less complex and less brittle, might be worse for perf but ehhhhh....

      const configsWithResolvedRefs = resolveReferences(configs, idToWrappedKpi, masterKpiTypeToKpiId);

      configsWithResolvedRefs.forEach(config => {
        const newKpi = config.get("kpi");

        // TODO need to factor out complex logic in app/handleKpiChange first
        //   deals with visibility ordering, column mirroring and speculative changes
        if (idToWrappedKpi.has(newKpi.get("id"))) {
          // TODO update existing
        } else {
          // TODO create new
        }
      });
      // TODO update idToWrapped kpi via some kind of callback
    } catch (e) {
      alert("invalid metric config: " + e.message);
    }
  };
  return (<Dialog
      bodyStyle={{overflow: "visible", color: "#fff"}}
      actionsContainerStyle={{paddingRight: "2rem"}}
      title="Import / Export Metrics"
      actions={[
        <TextButton
            key="import"
            label="Import"
            style={{marginLeft: "0.5rem", marginRight: "0.5rem", marginBottom: "1rem"}}
            onClick={doImport} />,
        <TextButton
            key="cancel"
            label="Cancel"
            style={{marginLeft: "0.5rem", marginRight: "0.5rem", marginBottom: "1rem"}}
            onClick={onClose} />]}
      autoDetectWindowHeight={true}
      scroll="paper"
      titleStyle={{color: "#f9ec33", fontSize: "1rem"}}
      onClose={onClose}
      open={open}>
    <p>TODO implement this</p>
    <TextField variant="standard"
        style={{marginBottom: "1rem"}}
        fullWidth={true}
        multiline={true}
        label="Metric configs"
        value={str}
        onChange={e => setStr(e.target.value)} />
  </Dialog>);
});

const SingleMetricImportExport = React.memo(({kpi, onKpiChange, kpiIsParent, columns, onColumnsChange, requiresExplanationUpdate, getExplanationWarning}) => {
  const configAsStr = React.useMemo(() => kpiToStr(kpi, columns), [kpi, columns]);
  const [str, setStr] = React.useState(configAsStr);
  const hasChanged = React.useMemo(
      () => {
        try {
          return !Immutable.is(Immutable.fromJS(JSON.parse(str)), Immutable.fromJS(JSON.parse(configAsStr)));
        } catch (e) {
          return true;
        }
      },
      [str, configAsStr]);
  return (
      <div style={{padding: "1rem"}} className="TESTCAFE-import-export-section">
        {kpiIsParent && <ParentWarning />}
        {requiresExplanationUpdate && getExplanationWarning()}
        <DelayedTextField
            style={{marginBottom: "1rem"}}
            fullWidth={true}
            multiline={true}
            label="Metric Config"
            value={str}
            onChange={setStr} />
        <TextButton
            icon="refresh"
            label=""
            style={{marginRight: "1rem"}}
            disabled={!hasChanged}
            onClick={() => {
              setStr(configAsStr);
            }} />
        <TextButton
            icon="import"
            label="Import"
            disabled={!hasChanged}
            onClick={() => {
              try {
                const json = JSON.parse(str);
                const newKpi = Immutable.fromJS(json.kpi)
                    .set("id", kpi.get("id"))
                    .set("order", kpi.get("order"))
                    .set("visible", kpi.get("visible"));
                onKpiChange(newKpi);

                const newColumnUids = Immutable
                    .fromJS(json.columns)
                    .map(labelledUidToUid);
                const newColumns = columns.map(c => {
                  const index = newColumnUids.indexOf(columnUid(c));
                  if (index !== -1) {
                    return c
                        .set("visible", true)
                        .set("order", index);
                  } else {
                    return c.set("visible", false);
                  }
                });
                onColumnsChange(newColumns);
              } catch (e) {
                alert("invalid json config: " + e.message);
              }
            }} />
      </div>
  );
});

export {
  SingleMetricImportExport,
  MultiMetricImportExportDialog
};
