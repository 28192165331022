/** @jsxImportSource @emotion/react */

import React from "react";
import {FixedSizeList} from "react-window";
import {jsx, css} from "@emotion/react";
import Select, {components} from "react-select";
import {betterMemo} from "js/common/utils/more-memo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {TextButton} from "js/common/views/inputs/buttons";
import Immutable from "immutable";
import useClickOutside from "js/common/hooks/use-click-outside";
import * as Kpis from "js/common/kpis";

const styles = (theme) => ({
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: 14,
    color: state.isDisabled ? theme.palette.text.disabled : theme.palette.text.primary,
    paddingLeft: 5
  }),
  input: (provided, state) => ({
    ...provided,
    color: theme.palette.text.main,
    fontFamily: `${theme.typography.fontFamily} !important`,
    paddingLeft: 5,
    input: {
      fontFamily: `${theme.typography.fontFamily} !important`,
      color: theme.palette.text.main,
      width: "auto !important"
    }
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? theme.palette.action.hover : theme.palette.background.card,
    border: `2px solid ${theme.palette.background.paper}`,
    boxShadow: "none",
    minHeight: 42,
    cursor: "pointer",
    fontSize: 14,
    ":hover": {
      backgroundColor: theme.palette.action.hover,
      border: `2px solid ${theme.palette.background.paper}`
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 2
  }),
  multiValue: (provided, state) => ({
    ...provided,
    ...(state.isDisabled && {opacity: 0.6}),
    fontSize: 14,
    margin: 2,
    color: `${state.isDisabled
        ? theme.palette.text.disabled
        : theme.palette.text.primary} !important`,
    backgroundColor: theme.themeId === "light" ? (state.isDisabled ? theme.palette.grey.A200 : theme.palette.grey.A100) : theme.palette.background.paper,
  }),
  multiValueLabel: (provided) => ({...provided, color: theme.palette.text.main, fontSize: 14}),
  multiValueRemove: (provided, state) => ({
    ...provided,
    ...(state.isDisabled && {display: "none"}),
    ":hover": {
      color: theme.palette.action.focus
    },
    color: theme.palette.text.disabled,
    backgroundColor: `${theme.themeId === "light" ? theme.palette.grey.A200 : theme.palette.background.paper}`,
    //borderLeft: `1px solid ${palette.border.light}`,
    padding: "0 2px"
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    marginRight: 5,
    color: state.isDisabled ? theme.palette.text.disabled : theme.palette.text.primary,
    ":hover": state.isDisabled ? theme.palette.text.disabled : theme.palette.text.primary
  }),
  indicatorSeparator: () => ({display: "none"}),
  menu: (provided) => ({
    ...provided,
    backgroundColor: `${theme.palette.background.card}`,
    zIndex: 9999,
    marginTop: 0,
    overflow: "auto"
  }),
  option: (provided, state) => ({
    ...provided,
    color: theme.palette.text.main,
    fontSize: 13,
    backgroundColor: state.isFocused
        ? theme.palette.background.paper
        : theme.palette.background.card
  })
});

const optionStyle = theme => css`
  height: 35px;
  color: ${theme.palette.text.main};
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;
  padding: 0 5px;
  background-color: ${theme.palette.background.card};

  &:hover {
    cursor: pointer !important;
    background-color: ${theme.palette.background.paper};
  }

,
`;

const labelStyle = {
  fontSize: "0.8rem",
  display: "flex",
  alignItems: "center"
};

const tooltipIconStyle = (theme) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "50%",
  height: 14,
  width: 14,
  lineHeight: "10px",
  textAlign: "center",
  marginLeft: 5
});

const errorStyle = (theme) => ({
  color: theme.palette.button.alert,
  fontSize: 10,
  position: "absolute"
});

const customOptionInputStyle = (theme) => ({
  background: "transparent",
  border: `1px solid ${theme.palette.background.paper}`,
  color: theme.palette.text.main,
  padding: "10px 12px",
  fontSize: 14,
  fontFamily: theme.typography.fontFamily,
  flexGrow: 1,
  marginRight: 10
});

const customOptionContainerStyle = (theme) => ({
  display: "flex",
  borderTop: `1px solid ${theme.palette.background.paper}`,
  padding: 10,
  alignItems: "center",
  justifyContent: "space-between"
});

const CustomMenuWithInput = React.memo(({selectProps: {onMenuInputFocus, onAddOption}, ...props}) => {
  const [value, setValue] = React.useState(undefined);
  const {theme} = React.useContext(CustomThemeContext);
  const inputRef = React.useRef();
  const itemHeight = 36;
  const childCount = props.children.length || 0;
  const containerHeight = itemHeight * childCount;
  const listRef = React.useRef(null);
  const prevChildCountRef = React.useRef(childCount);

  React.useEffect(() => {
    if (childCount > prevChildCountRef.current) {
      const scrollToBottom = () => listRef?.current.scrollToItem(childCount);
      scrollToBottom();
    }
    // Update the previous childCount
    prevChildCountRef.current = childCount;
  }, [childCount]);

  return (
      <div>
        <FixedSizeList
            ref={listRef}
            itemCount={childCount}
            height={containerHeight < 260 ? containerHeight : 260}
            itemSize={itemHeight}>
          {({index, style}) => <div style={style}>{props.children[index]}</div>}
        </FixedSizeList>
        <div style={customOptionContainerStyle(theme)}>
          <input
              ref={inputRef}
              style={customOptionInputStyle(theme)}
              placeholder="Enter a custom value"
              type="text"
              maxLength={128}
              value={value || ""}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
                e.target.focus();
              }}
              onKeyDown={event => {
                // Override default space bar functionality
                if (event.keyCode === 32) {
                  inputRef.current.value = inputRef.current.value + " ";
                  event.preventDefault();
                }
                // // Override default enter functionality
                if (event.keyCode === 13) {
                  event.preventDefault();
                  value && onAddOption(value.trim());
                }
              }}
              onFocus={() => onMenuInputFocus(true)}
          />
          <TextButton
              label="Add"
              suffixIcon="add-thin"
              suffixIconStyle={{fontSize: 12}}
              iconType="bhi"
              style={{
                boxShadow: "none !important",
                background: "transparent",
                color: theme.palette.primary.main,
                opacity: value ? 1 : 0.5,
                width: 80
              }}
              onClick={() => {
                setValue(undefined);
                value && onAddOption(value.trim());
              }} />
        </div>
      </div>
  );
});

const DropdownIndicator = ({selectProps: {onMenuInputFocus, menuIsOpen}, ...props}) => {
  return (
      <components.DropdownIndicator
          {...props}
          innerProps={{
            ...props.innerProps,
            onMouseDown: e => {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
      >
        <i
            className="bhi-sort-desc"
            onMouseDown={() => menuIsOpen ? onMenuInputFocus(false) : onMenuInputFocus(true)}
        />
      </components.DropdownIndicator>
  );
};
const Input = (props) => {
  const inputProps = {...props};
  return <components.Input {...inputProps} />;
};

const Option = ({value, isSelected, label, theme, innerProps: {onClick}}) => React.useMemo(() => {
      return (
          <div
              onClick={onClick}
              key={`option-${value.replace(/\s/g, "")}`}
              css={optionStyle(theme)}>
            <input
                key={value}
                type="checkbox"
                checked={isSelected}
                onChange={() => {}}
            />
            <label style={{cursor: "pointer", marginLeft: "5px"}}>{label}</label>
          </div>);
    }
    , [value, isSelected, label, theme, onClick]);


const renderLabel = (label, labelTooltip, theme) => {
  return <label style={labelStyle}>
    {label}
    {labelTooltip &&
        <div style={tooltipIconStyle(theme)}>
          <i className="bhi-info-o" style={{color: theme.palette.primary.main, fontSize: 8}} />
        </div>
    }
  </label>;
};

const MultiSelectWithCustomOption = betterMemo(
    {displayName: "MultiSelectWithCustomOption"},
    ({autoFocus, placeholder, label, labelTooltip, disabled, joinPath, onChange, selectedValues}) => {
      const {theme} = React.useContext(CustomThemeContext);
      const selectRef = React.useRef();
      const containerRef = React.useRef();
      const [showOnBlurError, setShowOnBlurError] = React.useState(false);
      const [dropdownOptions, setDropdownOptions] = React.useState(Immutable.OrderedSet());
      const [isFocused, setIsFocused] = React.useState(false);
      const [inputValue, setInputValue] = React.useState("");
      const [optionsLoaded, setOptionsLoaded] = React.useState(false);

      useClickOutside(containerRef, () => setIsFocused(false)); // Hook to detect clicks outside

      const ensureSelectedValuesAreAdded = React.useCallback(() => {
        const newOptions = selectedValues.map(v => Immutable.Map({label: v, value: v}));
        setDropdownOptions(existingOptions => existingOptions.union(newOptions));
      }, [selectedValues]);

      React.useEffect(() => {
        setOptionsLoaded(false);
      }, [joinPath])

      // If a column is changed we want to fetch values
      React.useEffect(() => {
        if(!optionsLoaded) {
          const tableEntity = joinPath.last().get("tableEntity");
          Kpis.loadEntityValues(tableEntity)
              .then(values => {
                const newOptions = values
                    .map(value => Immutable.Map({label: value, value: value}))
                    .sortBy(option => option.get("value"));
                setDropdownOptions(Immutable.OrderedSet(newOptions));
                setOptionsLoaded(true);
                ensureSelectedValuesAreAdded();
              });
        } else {
          ensureSelectedValuesAreAdded();
        }
      }, [ensureSelectedValuesAreAdded, joinPath, optionsLoaded]);

      const values = React.useMemo(() => {
        const selectedValuesSet = new Set(selectedValues);
        return dropdownOptions
            .filter(option => selectedValuesSet.has(option.get("value")))
            .sortBy(option => option.get("value"));
      }, [dropdownOptions, selectedValues]);
      const handleBlur = () => {
        setIsFocused(false);
        setInputValue("");
        if (!selectedValues || selectedValues.isEmpty()) {
          setShowOnBlurError(true);
        } else {
          setShowOnBlurError(false);
        }
      };

      const handleAddOption = (option) => {
        const newOption = Immutable.Map({label: option, value: option});
        if (!dropdownOptions.includes(newOption)) {
          setDropdownOptions(dropdownOptions.add(newOption));
          onChange(selectedValues.push(option));
        }
      };

      return (
          <div ref={containerRef}>
            {label && renderLabel(label, labelTooltip, theme)}
            <Select
                ref={selectRef}
                styles={styles(theme)}
                components={{
                  Input: Input,
                  MenuList: CustomMenuWithInput,
                  Option: Option,
                  DropdownIndicator: DropdownIndicator
                }}
                inputValue={inputValue}
                theme={theme}
                isMulti={true}
                value={values.toJS()}
                blurInputOnSelect={false}
                backspaceRemovesValue={false}
                hideSelectedOptions={false}
                tabSelectsValue={false}
                isClearable={true}
                isDisabled={disabled}
                placeholder={placeholder}
                options={dropdownOptions.toJS()}
                onChange={opt => {
                  setIsFocused(true);
                  onChange(Immutable.fromJS(opt.map(x => x.value)));
                }}
                onKeyDown={event => {
                  if ((event.keyCode === 32 && !event.target.value) || event.keyCode === 13) {
                    event.preventDefault();
                  }
                }}
                onInputChange={(value, action) => action.action === "input-change" && setInputValue(value)}
                onBlur={handleBlur}
                onMenuInputFocus={(value) => setIsFocused(value)}
                onAddOption={handleAddOption}
                autoFocus={autoFocus}
                {...{
                  menuIsOpen: isFocused || undefined,
                  isFocused: isFocused || undefined
                }}
            />
            {showOnBlurError && <span style={errorStyle(theme)}>Required field</span>}
          </div>
      );
    });

export default MultiSelectWithCustomOption;