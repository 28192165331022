import Immutable from "immutable";

import * as ajax from "js/common/ajax";

const getAll = () =>
    ajax.get({url: "kpi/key"})
        .then(x => Immutable.fromJS(x));


const update = (keyKpiId, selection) => ajax
    .put({
        url: window.path("kpi/key", keyKpiId),
        json: selection
    });

export {
    getAll,
    update
};