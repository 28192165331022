import eventBus from "js/cube19.event-bus";

const Backbone = window.Backbone;
const _ = window._;

export const MAX_NAME_LENGTH = 64;

const KpiModel = Backbone.Model.extend({

    getOverrideTimeframe() {
        return JSON.parse(this.get("overrideTimeframe"));
    }

});

const KpiCollection = Backbone.Collection.extend({
    url: "kpi",
    model: KpiModel,
    comparator: kpi => kpi?.get("name")?.toLowerCase()
});

const kpis = new KpiCollection();
const load = () => kpis
    .fetch()
    .then(() => {
        eventBus.trigger("data-loaded", "kpis");
    }, error => {
        eventBus.trigger("error:fatal", error);
    });

const reload = () => kpis.fetch();

const get = id => kpis.get(id);

const getAll = () => kpis;

const getTrendableKpis = () => kpis.filter(kpi => kpi.get("trendable"));

const getByQuery = query => kpis.find(kpi => kpi.get("type").query === query);

const getByIdOrTemplateName = identifier => {
    const identifierIsId = !_.isString(identifier);
    return kpis.find(kpi => {
        if (identifierIsId) {
            return kpi.id === identifier;
        } else {
            return kpi.get("type").templateName === identifier;
        }
    });
};

const getByTemplateName = templateName => kpis.find(k => k.get("type").templateName === templateName);

const getCollectionClass = () => KpiCollection;

export {
    load,
    reload,
    get,
    getAll,
    getTrendableKpis,
    getByQuery,
    getByIdOrTemplateName,
    getByTemplateName,
    getCollectionClass
};
