import eventBus from "js/cube19.event-bus";

/* eslint no-undef: */
window.Cube19 = new Marionette.Application();

Cube19.vent = eventBus;

Cube19.urlRoot = "/ApplicationLayer";

Cube19.addRegions({
    contentRegion: "#content-region",
    bullhornRegion: "#bullhorn-region",
    bullhornSidebarRegion: "#bullhorn-sidebar-region",
    modalRegion: "#modal-region",
    buildInfoRegion: '#build-info-region'
});

export default Cube19;
