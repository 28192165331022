import Immutable from "immutable";

import * as Ajax from "js/common/ajax";
import * as Users from "js/common/users";
import * as Popups from "js/common/popups";

const path = window.path;

const baseUrl = "saved-config";

const getAll = configType => Ajax
    .get({
      url: baseUrl,
      data: {configType}
    })
    .then(response => Immutable
        .fromJS(response)
        .map(configs => configs
            .map(config => config.update("json", str => Immutable.fromJS(JSON.parse(str))))));

const create = newConfig => Ajax
    .post({
      url: baseUrl,
      json: toDto(newConfig)
    })
    .then(fromDto);

const update = config => Ajax
    .put({
      url: path(baseUrl, config.get("id")),
      json: toDto(config)
    })
    .then(fromDto);

const remove = configId => Ajax.del({url: path(baseUrl, configId)});

const getShareList = configId => Ajax
    .get({url: path(baseUrl, "sharing", configId)})
    .then(shareList => {
      const groupIdToPermission = shareList.groups.reduce((acc, group) => acc.set(group.id, group.permission), Immutable.Map());
      const userIdToPermission = shareList.users.reduce((acc, user) => acc.set(user.id, user.permission), Immutable.Map());

      return Immutable
          .Map()
          .set("groups", groupIdToPermission)
          .set("users", userIdToPermission);
    });

const updateShareList = (configId, newShareList) => {
  const groups = newShareList.get("groups").entrySeq().map(([groupId, permission]) => ({
    "id": groupId,
    "permission": permission
  })).toArray();

  const users = newShareList.get("users").entrySeq().map(([userId, permission]) => ({
    "id": userId,
    "permission": permission
  })).toArray();

  return Ajax.put({
    "url": path(baseUrl, "sharing", configId),
    "json": { "groups": groups, "users": users }
  });
};

const unshareFromCurrentUser = configId => Ajax.del({url: path(baseUrl, "sharing", configId)});

const toggleHome = (configType, configId) => {
  const homeConfigId = getHome(configType);
  if (homeConfigId === configId) {
    return unsetHome(configType);
  } else {
    return setAsHome(configType, configId);
  }
};

const setAsHome = (configType, configId) => Ajax
    .post({url: path(baseUrl, configId, "set-as-home")})
    .then(
        () => {
          setHomeOnCurrentUserObject(configType, configId);
          Popups.success("Home page set");
        },
        error => {
          Popups.contactSupport();
        });

const unsetHome = configType => Ajax
    .post({url: path(baseUrl, configType, "unset-home")})
    .then(
        () => {
          setHomeOnCurrentUserObject(configType, null);
          Popups.success("Home page has been unset");
        },
        error => {
          Popups.contactSupport();
        });


const setHomeOnCurrentUserObject = (configType, configId) => {
  const currentUser = Users.getCurrentUser();
  let homeKey;
  switch (configType) {
    case "DASHBOARD":
      homeKey = "homeDashboardId";
      break;
    case "CHART_PAGE":
      homeKey = "homeChartPageId";
      break;
    default:
      homeKey = null;
  }
  if (homeKey) {
    currentUser.set(homeKey, configId);
  }
};

const getHome = configType => {
  const currentUser = Users.getCurrentUser();
  let homeKey;
  switch (configType) {
    case "DASHBOARD":
      homeKey = "homeDashboardId";
      break;
    case "CHART_PAGE":
      homeKey = "homeChartPageId";
      break;
    default:
      homeKey = null;
  }
  if (homeKey) {
    return currentUser.get(homeKey);
  }
};

const fromDto = dto => Immutable.fromJS(dto).update("json", str => Immutable.fromJS(JSON.parse(str)));

const toDto = config => {
  return config.set("json", JSON.stringify(config.get("json"))).toJS();
};

export {
  getAll,
  create,
  update,
  remove,
  getShareList,
  updateShareList,
  unshareFromCurrentUser,
  toggleHome,
  getHome
};