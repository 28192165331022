import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import Tabs from "js/common/views/tabs";
import TimeframeSettings from "js/admin/client/timeframe-settings";
import CompanyLogoUpload from "js/admin/client/company-logo-upload";
import Gdpr from "js/admin/client/gdpr";
import ReleaseWindows from "js/admin/client/release-windows";
import DataExtraction from "js/admin/client/data-extraction";
import DefaultTheme from "js/admin/client/default-theme";

import * as Users from "js/common/users";
import * as auditor from "js/common/auditer";
import {ExplanationSettings} from "js/admin/client/explanation-settings";

export default createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      activeTabIndex: 0
    };
  },

  render() {
    let tabs = [
      {
        title: "Timeframe Settings",
        content: <TimeframeSettings />
      }, {
        title: "Company Logo",
        content: <CompanyLogoUpload />
      }, {
        title: "GDPR",
        content: <Gdpr />
      }, {
        title: "Release Windows",
        content: <ReleaseWindows />
      }, {
        title: "Default Theme",
        content: <DefaultTheme />
      }, {
        title: "Metric Explanations",
        content: <ExplanationSettings />
      }];

    if (Users.isCube19User(Users.getCurrentUser())) {
      tabs.push({title: "Data Extraction", content: <DataExtraction />});
    }

    return (
        <Tabs
            selectedIndex={this.state.activeTabIndex}
            onChangeTab={tabIndex => this.setState({activeTabIndex: tabIndex})}
            tabs={tabs}
            saveTabStateOnChange={false}
            containerStyle={{margin: 3}} />
    );
  },

  componentDidMount() {
    auditor.audit("company-settings-admin:loaded");
  }
});
