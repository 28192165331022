import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import PureRenderMixin from "react-addons-pure-render-mixin";

import { Layout } from "js/common/views/foundation-column-layout";
import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import LoadingSpinner from "js/common/views/loading-spinner";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import ErrorMsg from "js/common/views/error";
import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";

export default createReactClass({

    mixins: [ PureRenderMixin ],

    getInitialState() {
        return {
            isLoading: false,
            asyncJobs: [],
            error: null
        };
    },

    componentDidMount() {
        this.setState({ isLoading: true });
        this.reloadJobs();
        this.intervalId = setInterval(() => this.reloadJobs(), 30*1000);
    },

    componentWillUnmount() {
        clearInterval(this.intervalId);
    },

    render() {
        return (
            <div>
                <AdminHeader>
                    <Icon icon="map" />
                    Status of Updates to User and/or Group Timezone History
                </AdminHeader>
                <Layout allSmall={12} rowStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    {this.state.isLoading && <LoadingSpinner />}
                    {(!this.state.isLoading && this.state.asyncJobs.length > 0) && this.renderTable()}
                    {(!this.state.isLoading && this.state.error) && <ErrorMsg text={this.state.error} />}
                </Layout>
            </div>
        );
    },

    renderTable() {
        return (
            <SimpleDataTable
                columns={[ "ID", "Started by", "Started at", "Status" ]}
                rows={this.state.asyncJobs.map(asyncJobToRow)}
                initialSortDirection="DESC" />
        );
    },

    reloadJobs() {
        loadTimezoneHistoryCalculationJobs()
            .then(asyncJobs => {
                this.setState({
                    asyncJobs,
                    isLoading: false
                });
            })
            .catch(error => {
                this.setState({
                    error: "Unabled to load data",
                    isLoading: false
                });
            });
    }

});

const loadTimezoneHistoryCalculationJobs = () => loadAsyncJobs()
    .then(asyncJobs => asyncJobs.filter(asyncJob => asyncJob.payload.source === "LOCATION_HISTORY"));

const loadAsyncJobs = () => ajax.get({ url: "async-calculation-jobs" })
    .then(asyncJobs => asyncJobs.map(parsePayload));

const parsePayload = asyncJob => ({
    ...asyncJob,
    payload: JSON.parse(asyncJob.payload)
});

const asyncJobToRow = asyncJob => {
    const movedToDateTime = formatDateTime(moment(asyncJob.lastStatusChangeTimestamp).local());
    const statusStr = `Moved to ${asyncJob.status} at ${movedToDateTime}`;

    const startedBy = Users.getUser(asyncJob.userId).get("fullName");

    return [
        asyncJob.id,
        startedBy,
        formatDateTime(moment(asyncJob.startTimestamp).local()),
        statusStr
    ];
};

const formatDateTime = (date, pattern = "YYYY-MM-DD HH:mm:ss") => date.format(pattern);
