import React from "react";
import Select from "js/common/views/inputs/immutable-react-select";
import Immutable from "immutable";

export default ({ onChange, value = "Solid", color, isDisabled = false }) => {
    const strokeColor = color || "#fff";
    return (
        <Select
            name="line-style"
            placeholder="Select style"
            selectedValue={value}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={isDisabled}
            optionRenderer={renderLineTypeAsSvg}
            valueRenderer={renderLineTypeAsSvg}
            options={Immutable.fromJS([{
                value: "Solid",
                label: "Line",
                color: strokeColor
            },{
                value: "ShortDash",
                label: "Dashes",
                color: strokeColor
            },{
                value: "ShortDot",
                label: "Dots",
                color: strokeColor
            }])}
            onChange={newOption => onChange(newOption)} />
    );
};

const renderLineTypeAsSvg = option => {
    const dashStylesByValue = {
        Solid: [],
        ShortDash: [10, 5],
        ShortDot: [2, 4]
    };
    const { value, color } = option;
    return (
        <svg
            width="100%"
            height="16px"
            viewport="0 0 120 120"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <line
                strokeDasharray={dashStylesByValue[value]}
                strokeWidth={4}
                stroke={color}
                x1="0" y1="8" x2="120" y2="8" />
        </svg>
    );
};
