/** @jsxImportSource @emotion/react */

import React from "react";
import JsonField, {validateJsonObject} from "js/common/views/inputs/formatted-json-field";
import Immutable from "immutable";
import {findDuplicates} from "js/squids/app";

const JSONTab = React.memo(({handleEditPage, page}) => {
  const removeNodeAndFormulaSpaces = (value) => {
    const sanitizedFormulas = value.get("formulas", Immutable.List()).map(f => f.update("id", id => id.replaceAll(" ", "-")));
    const sanitizedNodes = value.get("nodes", Immutable.List()).map(n => n.update("id", id => id.replaceAll(" ", "-")));
    const sanitizedValue = value.set("formulas", sanitizedFormulas).set("nodes", sanitizedNodes);

    handleEditPage(sanitizedValue);
  };

  const validateConfig = str => {

    const isInvalidJson = validateJsonObject(str);

    if(!isInvalidJson && str){
      const config = Immutable.fromJS(JSON.parse(str));
      const nodeIds = config.get("nodes").map(n => n.get("id"));
      const duplicates = findDuplicates(nodeIds);

      if(duplicates.size === 0){
        return null
      }
      else {
        return "Duplicate Node ID: " + duplicates.first();
      }
    }
    else {
      return isInvalidJson
    }
  };

  return <div style={{margin: 30}}>
    <span style={{display: "block", marginBottom: 20}}>JSON Config</span>
    <JsonField
        theme="cube2021"
        className="squid-json-field"
        validator={validateConfig}
        InputProps={{disableUnderline: true}}
        value={page}
        emptyValue={new Immutable.Map()}
        onChange={(value) => removeNodeAndFormulaSpaces(value)}
        style={{
          fullWidth: true,
          height: "calc(100vh - 210px)",
          paddingTop: "10px",
          marginTop: "-18px",
          overflowY: "auto"
        }}
     />
  </div>;
});

export default JSONTab;