import React from "react";

const ErrorTooltip = React.memo(({
  children,
  content,
  position,
  customStyle = {},
}) => {

  const [isVisible, setIsVisible] = React.useState(false);

  const handleMouseIn = () => {
    setIsVisible(true);
  };

  const handleMouseOut = () => {
    setIsVisible(false);
  };

  const tooltipStyle = {
    display: isVisible ? "block" : "none",
    transition: "opacity .25s ease-in-out",
    position: "absolute",
    whiteSpace: "nowrap",
    padding: "2rem 2rem 2rem 1.5rem",
    borderRadius: "5px",
    fontSize: "0.8rem",
    zIndex: 9999,
    background: "#272731",
    color: "white",
    border: "2px solid #49495B"
  };

  const tooltipArrowStyle = {
    position: "absolute",
    width: "12px",
    height: "12px",
    lineHeight: "1rem",
    zIndex: -1,
    color: "#272731",
    textShadow: "#49495B 0px 3px 0px"
  };

  const getTooltipStyle = position => {
    const tooltipStyleByPosition = {
      top: {
        transform: "translateX(-50%) translateY(-100%)",
        left: "50%",
        top: "-12px",
      },
      bottom: {
        transform: "translateX(-50%) translateY(100%)",
        left: "50%",
        bottom: "-12px",
      },
      right: {
        transform: "translateX(30%)"
      },
      left: {
        transform: "translateX(-100%)",
        marginLeft: -10
      }
    };
    return {...tooltipStyle, ...tooltipStyleByPosition[position]};
  };

  const getTooltipArrowStyle = position => {
    const tooltipArrowStyleByPosition = {
      top: {
        transform: "translateX(0%) translateY(100%)",
        left: "50%",
        bottom: "4px",
      },
      bottom: {
        transform: "rotate(180deg) translateX(0%) translateY(0%)",
        left: "50%",
        top: "-8px",
      },
      right: {
        transform: "rotate(90deg) translateX(6px) translateY(7px)",
        left: 0,
        bottom: "50%"
      },
      left: {
        transform: "rotate(-90deg) translateX(-6px) translateY(7px)",
        right: 0,
        bottom: "50%"
      }
    };
    return {...tooltipArrowStyle, ...tooltipArrowStyleByPosition[position]};
  }

  return (
      <div
          style={{position: "relative", display: "inline-block"}}
          onMouseOver={handleMouseIn}
          onMouseOut={handleMouseOut}>
        <div style={{...getTooltipStyle(position), ...customStyle}}>
          {content}
          <div style={getTooltipArrowStyle(position)}>▼</div>
        </div>
        {children}
      </div>
  );
});

export default ErrorTooltip;