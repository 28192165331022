import React from "react";
import Immutable from "immutable";
import pure from "js/common/views/pure";

import Select from "js/common/views/inputs/immutable-react-select";
import * as currencyRepo from "js/common/repo/backbone/currency-repo";

export default pure(({value, onChange, containerStyle, style, theme}) => {
  const options = getCurrencyOptions();
  return (
      <div style={{minWidth: 100, ...containerStyle}} onClick={e => e.stopPropagation()}>
        <Select
            style={style}
            theme={theme}
            placeholder="Currency"
            isClearable={false}
            isSearchable={true}
            isMulti={false}
            selectedValue={value}
            options={options}
            onChange={onChange} />
      </div>);
});

const getCurrencyOptions = () => Immutable.fromJS(currencyRepo.getAll().map(currency => ({
  label: currency.get("code"),
  value: currency.get("code")
})));
