/** @jsxImportSource @emotion/react */

import React from "react";
import {v4 as uuid} from "uuid";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const SimpleTextInput = React.memo(({
  type = "text",
  onChange,
  onKeyPress = () => {},
  onKeyDown = () => {},
  customStyle = {},
  allowPaste = true,
  blurErrorMessage = undefined,
  autoFocus = false,
  value,
  prefix,
  label,
  maxLength = undefined,
  ...props
}) => {
  const { theme } = React.useContext(CustomThemeContext);
  const textRef = React.useRef();
  const [showOnBlurError, setShowOnBlurError] = React.useState(false);
  const [prefixWidth, setPrefixWidth] = React.useState(0);
  const uniqueInputId = uuid();
  const prefixRef = React.useRef();


  React.useEffect(() => {
    autoFocus && textRef.current.focus();
    if (prefixRef && prefixRef.current && prefixRef.current.clientWidth) {
      setPrefixWidth(prefixRef.current.clientWidth);
    }
  }, [autoFocus]);

  const style = {
    ...textInputStyle(theme, prefix, prefixWidth),
    ...customStyle
  };

  const errorStyle = (theme) => ({
    color: theme.palette.button.alert,
    fontSize: 10,
    position: "absolute"
  })

  const handleBlur = (event) => {
    if(blurErrorMessage) {
      const blurValue = event.target.value;
      if(!blurValue || blurValue.length === 0 || blurValue === "") {
        setShowOnBlurError(true)
      }
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if(blurErrorMessage && value && value.trim().length > 0) {
      setShowOnBlurError(false)
    }
    onChange(value, event)
  }

  const emptyValue = autoFocus ? [] : value;
  return (
      <div style={{display: "block", position: "relative"}}>
        {label && <label htmlFor={uniqueInputId} style={{fontSize: "0.8rem"}}>{label}</label>}
        {prefix && <span style={prefixStyle(theme)} ref={prefixRef}>{prefix}</span>}
        <input
          id={uniqueInputId}
          ref={textRef}
          type={type}
          onChange={handleChange}
          onBlur={blurErrorMessage && handleBlur}
          onKeyDown={(evt) => type === "number" && ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
          onKeyPress={e => onKeyPress(e.key)}
          onPaste={(e) => !allowPaste && e.preventDefault()}
          css={style}
          value={value || emptyValue}
          maxLength={maxLength}
          {...props} />
        {showOnBlurError && <span style={errorStyle(theme)}>{blurErrorMessage}</span>}
      </div>);
});

const prefixStyle = (theme) => ({
  position: "absolute",
  background: theme.palette.background.paper,
  borderRadius: "2px",
  left: "6px",
  padding: "4px 6px",
  bottom: "6px",
  fontSize: 14,
  color: theme.palette.text.prefix,
  fontFamily: theme.typography.fontFamilyBold,
  fontWeight: 600,
});

const textInputStyle = (theme, prefix, prefixWidth) => ({
  color: theme.palette.textColor,
  fontSize: "0.875rem",
  fontFamily: "inherit",
  display: "block",
  boxSizing: "border-box",
  width: "100%",
  borderRadius: 3,
  backgroundColor: theme.palette.background.card,
  border: `2px solid ${theme.palette.border.main}`,
  padding: "0.5rem",
  paddingLeft: prefix ? `${prefixWidth + 10}px` : "0.5rem",
  height: 36,

  ":active": {
    backgroundColor: theme.palette.background.card,
    outline: "none"
  },

  ":disabled": {
    color: theme.palette.action.disabled
  },

  ":focus": {
    backgroundColor: theme.palette.background.card,
    border: `2px solid ${theme.palette.border.main}`,
    outline: "none"
  }
});

SimpleTextInput.displayName = "SimpleTextInput";

export default SimpleTextInput;
