import React from "react";
import Immutable from "immutable";
import {TextField, RadioGroup} from "@mui/material";

import {betterMemo} from "js/common/utils/more-memo";
import LabelledSelect from "js/common/views/inputs/labelled-select";
import AutocompleteTextArea from "js/common/views/inputs/autocomplete-textarea";
import RadioButton from "js/common/views/inputs/radio-button";
import Tooltip from "js/common/views/tooltips";
import Error from "js/common/views/error";
import {SingleEntityPathPicker, MultiEntityPathPicker} from "js/common/views/inputs/entity-path-pickers";
import {ParentWarning} from "js/admin/kpis/edit-kpis/warnings";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import {
  getQueryParam,
  setQueryParam,
  deleteQueryParam,
  getTableEntityTypeToGroupingEntityType,
  removeComponentLinksFromEntityPath,
  filterPattern,
  MessageOnlyTab
} from "js/admin/kpis/edit-kpis/utils";
import {SimpleTest} from "js/admin/kpis/edit-kpis/tabs/test";
import * as Kpis from "js/common/kpis";
import * as Strings from "js/common/utils/strings";
import {TextButton} from "js/common/views/inputs/buttons";
import ConfigOverview from "js/admin/kpis/edit-kpis/tabs/config-overview";

const modeToParams = Immutable.fromJS({
  "legacy-sql": ["overrideDateColumn"],
  "default": [],
  "filter": ["overrideDateFilter"],
  "column": ["overrideDateEntityColumnId", "overrideDatePath"]
});

const getInitialDateMode = kpi => {
  if (getQueryParam(kpi, "overrideDateFilter")) {
    return "filter";
  } else if (getQueryParam(kpi, "overrideDateEntityColumnId")) {
    return "column";
  } else if (getQueryParam(kpi, "overrideDateColumn")) {
    return "legacy-sql";
  } else {
    return "default";
  }
};

const clearParamsForMode = (mode, kpi) => {
  const paramsToDelete = modeToParams.delete(mode).valueSeq().flatMap(x => x);
  return paramsToDelete.reduce((k, param) => deleteQueryParam(k, param), kpi);
};

const managedKeyPaths = Immutable.fromJS([
  ["queryParams", "overrideDateEntityColumnId"],
  ["queryParams", "overrideDatePath"],
  ["queryParams", "overrideDateFilter"],
  ["queryParams", "overrideDateColumn"]]).toSet();

export default betterMemo(
    {displayName: "DateSettingsSection"},
    ({
      idToEntityColumn,
      idToTemplate,
      typeToGroupingEntity,
      kpi,
      combinedKpi,
      onKpiChange,
      kpiIsParent,
      kpiIsChild,
      testResult,
      onTest,
      rootChangedWarning,
      queryType,
      requiresExplanationUpdate,
      getExplanationWarning
    }) => {
      const tableEntityTypeToGroupingEntityType = React.useMemo(
          () => getTableEntityTypeToGroupingEntityType(typeToGroupingEntity),
          [typeToGroupingEntity]);

      const startingEntity = kpi.get("readOnlyRootGroupingEntity");
      const initialPath = getKpiEntityPathForDateFilter(tableEntityTypeToGroupingEntityType, kpi);

      const [dateMode, setDateMode] = React.useState(() => getInitialDateMode(kpi));
      const [showConfig, setShowConfig] = React.useState(false);

      const handleDateEntityColumnIdChange = React.useCallback(str => {
        let newKpi = clearParamsForMode("column", kpi);
        if (str) {
          const [columnIdStr, columnPath] = str.split("+");
          newKpi = setQueryParam(newKpi, "overrideDateEntityColumnId", parseInt(columnIdStr));
          newKpi = setQueryParam(newKpi, "overrideDatePath", columnPath);
        } else {
          newKpi = kpi;
        }
        onKpiChange(newKpi);
      }, [kpi, onKpiChange]);

      const handleDateFilterChange = React.useCallback(newFilter => {
        let newKpi = clearParamsForMode("filter", kpi);
        newKpi = setQueryParam(newKpi, "overrideDateFilter", newFilter);
        onKpiChange(newKpi);
      }, [kpi, onKpiChange]);

      const handleDateModeChange = React.useCallback(newDateMode => {
        let newKpi;
        if (newDateMode === "default") {
          // NOTE we clear params when changing mode to default
          // because there will be no user interaction to prompt clearing later
          newKpi = clearParamsForMode("default", kpi);
        } else {
          newKpi = kpi;
        }
        setDateMode(newDateMode);
        onKpiChange(newKpi);
      }, [kpi, onKpiChange]);

      if (queryType === "GENERIC_ENTITY" && !kpi.get("readOnlyRootGroupingEntity")) {
        return <MessageOnlyTab message={"You must pick a generic entity."} />;
      } else if (queryType === "FORWARD_REPORT" && !combinedKpi.get("readOnlyRootGroupingEntity")) {
        return <MessageOnlyTab message={"You must set a return Metric in the forward report config."} />;
      } else if (queryType === "SIMPLE_SUM") {
        return <MessageOnlyTab message={"Date settings are not supported for this query type."} />;
      } else {
        return <div style={{padding: "1rem"}}>
          {kpiIsParent && <ParentWarning />}
          {rootChangedWarning && <Error text={rootChangedWarning} type={"warn"} style={{marginTop: 0}} />}
          {requiresExplanationUpdate && getExplanationWarning()}

          <RadioGroup
              style={{display: "flex"}}
              row={true}
              name="date-mode"
              onChange={e => handleDateModeChange(e.target.value)}
              value={dateMode}>
            <RadioButton
                style={{height: 25, flex: 1}}
                value="default"
                label="Default" />
            {getQueryParam(kpi, "overrideDateColumn") && <RadioButton
                style={{height: 25, flex: 1}}
                value="legacy-sql"
                label="Legacy SQL column" />}
            <RadioButton
                style={{height: 25, flex: 1}}
                value="column"
                label="Choose a Custom Date Column" />
            <RadioButton
                style={{height: 25, flex: 1}}
                value="filter"
                label="Write SQL for a Custom Date Filter" />
          </RadioGroup>

          <div style={{marginBottom: "1rem"}}>
            {dateMode === "default" && <span>Date filtering will follow the defaults of the query type</span>}

            {dateMode === "column" &&
            <ColumnOverrideSection
                kpi={kpi}
                idToEntityColumn={idToEntityColumn}
                typeToGroupingEntity={typeToGroupingEntity}
                onDateColumnChange={handleDateEntityColumnIdChange}
                startingEntity={startingEntity}
                initialPath={initialPath} />}

            {dateMode === "legacy-sql" &&
            <TextField variant="standard"
                style={{marginTop: "0.5rem"}}
                fullWidth={true}
                label="Custom SQL expression that resolves to a date column or value"
                value={getQueryParam(kpi, "overrideDateColumn")}
                onChange={e => onKpiChange(setQueryParam(kpi, "overrideDateColumn", e.target.value))} />}

            {dateMode === "filter" &&
            <FilterOverrideSection
                tableEntityTypeToGroupingEntityType={tableEntityTypeToGroupingEntityType}
                typeToGroupingEntity={typeToGroupingEntity}
                label="Paths For Date Filter"
                dateFilter={getQueryParam(kpi, "overrideDateFilter")}
                onDateFilterChange={handleDateFilterChange}
                startingEntity={startingEntity}
                initialPaths={Immutable.Set.of(initialPath)}
                kpiIsInstant={idToTemplate.get(kpi.get("templateId")).get("dateType") === "INSTANT"} />}
          </div>

          <div style={{display: "flex", marginBottom: "1rem"}}>
            <SimpleTest
                style={{marginRight: "1rem"}}
                testResult={testResult}
                onTest={onTest} />
            {kpiIsChild &&<TextButton
                label={`${showConfig ? "Hide" : "Show"} Inherited Config`}
                onClick={() => setShowConfig(prev => !prev)} />}
          </div>

          {kpiIsChild && showConfig &&
          <div style={{display: "flex", marginBottom: "1rem"}}>
            <ConfigOverview
                columnNameToMap={Immutable.Map({"Value": combinedKpi})}
                keyPaths={managedKeyPaths} />
          </div>}

        </div>;
      }
    });

const ColumnOverrideSection = betterMemo(
    {displayName: "ColumnOverrideSection"},
    ({kpi, idToEntityColumn, typeToGroupingEntity, onDateColumnChange, startingEntity, initialPath}) => {
      const [entityPath, setEntityPath] = React.useState(initialPath);

      const handleEntityPathChange = React.useCallback((entityPathStr) => {
        if (entityPathStr === null) {
          setEntityPath(Immutable.List.of(startingEntity));
        } else {
          const selectedEntityPath = new Immutable.List(entityPathStr.split(","));
          setEntityPath(selectedEntityPath);
        }
      }, [startingEntity]);


      return <div style={{marginTop: "10px"}}>
        Select the path to the new date column, then select the column from the dropdown.
        <SingleEntityPathPicker
            entityPath={entityPath}
            onChange={handleEntityPathChange}
            typeToGroupingEntity={typeToGroupingEntity}
            label="Path to date column"
            style={{marginTop: "10px"}} />
        <DateColumnPicker
            entityPath={entityPath}
            idToEntityColumn={idToEntityColumn}
            kpi={kpi}
            onChange={onDateColumnChange}
            style={{marginTop: "10px"}} />
      </div>;
    });

const instantLookupColumns = Immutable.fromJS([{
  "displayLabel": "Today",
  "sortScore": 0,
  "value": ":eventDate",
  "autoCompleteChar": ":"
}]);

const rangeLookupColumns = Immutable.fromJS([{
  "displayLabel": "Timeframe Start Date",
  "sortScore": 0,
  "value": ":startDate",
  "autoCompleteChar": ":"
}, {
  "displayLabel": "Timeframe End Date",
  "sortScore": 0,
  "value": ":endDate",
  "autoCompleteChar": ":"
}]);

const lookupColumnsForTimeframeParams = kpiIsInstant => {
  if (kpiIsInstant) {
    return instantLookupColumns;
  } else {
    return rangeLookupColumns;
  }
};

const FilterOverrideSection = betterMemo(
    {displayName: "FilterOverrideSection"},
    ({
      tableEntityTypeToGroupingEntityType,
      typeToGroupingEntity,
      dateFilter,
      onDateFilterChange,
      startingEntity,
      initialPaths,
      kpiIsInstant
    }) => {
      const [entityPaths, setEntityPaths] = React.useState(() => {
        const initialPathsFromFilter = getInitialPathsFromDateFilter(tableEntityTypeToGroupingEntityType, dateFilter, initialPaths);
        let pathsToUse;
        if (initialPathsFromFilter.isEmpty()) {
          pathsToUse = initialPaths;
        } else {
          pathsToUse = initialPathsFromFilter;
        }
        return pathsToUse.map(path => removeComponentLinksFromEntityPath(path, typeToGroupingEntity));
      });

      const {theme} = React.useContext(CustomThemeContext);

      const [columnsForPaths, setColumnsForPaths] = React.useState(new Immutable.List());

      React.useEffect(() => {
        Kpis
            .loadEntityColumnsForEntityPaths(entityPaths.toList())
            .then(cs => {
              const lookupColumns = cs
                  .map(c => new Immutable.Map({
                    entityColumnId: c.get("entityColumnId"),
                    joinPathStr: c.get("joinPathStr"),
                    displayLabel: c.get("label"),
                    sortScore: sortScoreForColumn(c),
                    value: "[" + c.get("joinPathStr") + " " + c.get("entityColumnId") + " " + c.get("label") + "]",
                    autoCompleteChar: ":"
                  }))
                  .concat(lookupColumnsForTimeframeParams(kpiIsInstant))
                  .sortBy(c => c.get("sortScore"));
              setColumnsForPaths(lookupColumns.toList());
            });
      }, [kpiIsInstant, entityPaths]);

      const handleEntityPathsChange = React.useCallback(entityPaths => {
        if (entityPaths === null || entityPaths.isEmpty()) {
          setEntityPaths(Immutable.Set.of(Immutable.List.of(startingEntity)));
        } else {
          setEntityPaths(entityPaths);
        }
      }, [startingEntity]);

      return <div style={{marginTop: "10px"}}>
        <MultiEntityPathPicker
            entityPaths={entityPaths}
            typeToGroupingEntity={typeToGroupingEntity}
            label="Paths For Date Filter"
            onChange={handleEntityPathsChange}
            style={{marginTop: "10px"}} />
        <Tooltip
            text={"Timeframe Start and End Date will give you the start and end date chosen by the User to run the kpi with. " +
            "For kpis which always use live data, only Today is available"}
            styleType={theme.themeId}
            position="top"
            customStyle={{marginTop: "10px"}}>
          <div>
            <div>Custom Date Filter</div>
            Use ':' to bring up a list of all available columns for the selected paths above,
            and constants based on the timeframe the Metric runs over <i className="fa fa-info" />
          </div>
        </Tooltip>
        <AutocompleteTextArea
            delayInMillis={1000}
            lookupEntries={columnsForPaths}
            onChange={onDateFilterChange}
            value={dateFilter || ""}
            label="Date Filter. Type ':' to show columns you can select from" />
      </div>;
    });

const DateColumnPicker = React.memo((
    {
      entityPath,
      idToEntityColumn,
      kpi,
      onChange,
      style,
      ...props
    }) => {
  const [columnsForPath, setColumnsForPath] = React.useState(new Immutable.List());

  React.useEffect(() => {
    Kpis.loadColumnsForEntityPaths(Immutable.List.of(entityPath))
        .then(cs =>
            setColumnsForPath(cs.toList()));
  }, [entityPath]);

  const entityColumnId = kpi.getIn(["queryParams", "overrideDateEntityColumnId"], null);
  const datePath = kpi.getIn(["queryParams", "overrideDatePath"], null);
  const selectedValue = entityColumnId ? entityColumnId + "+" + datePath : null;

  const idToDateColumn = idToEntityColumn.filter(c => c.get("dataType") === "DATE");

  const dateColumnsForEntityPath = columnsForPath
      .filter(c => idToDateColumn.has(c.get("entityColumnId")))
      .map(c => new Immutable.Map({label: c.get("label"), value: c.get("entityColumnId") + "+" + c.get("joinPathStr")}))
      .toList();

  return <LabelledSelect
      isMulti={false}
      isClearable={true}
      label="Override Date Column"
      {...props}
      options={dateColumnsForEntityPath}
      selectedValue={selectedValue}
      onChange={onChange}
      containerStyle={style} />;
});

const getKpiEntityPathForDateFilter = (tableEntityTypeToGroupingEntityType, kpi) => {
  const overrideDatePath = kpi.getIn(["queryParams", "overrideDatePath"], null);
  if (overrideDatePath) {
    return new Immutable.List(
        overrideDatePath
            .split(",")
            .map(segmentStr => {
              let tableEntityType;
              if (segmentStr.indexOf("+") !== -1) {
                tableEntityType = segmentStr;
              } else {
                tableEntityType = segmentStr.split("+")[0];
              }
              return tableEntityTypeToGroupingEntityType.get(tableEntityType);
            }));
  } else {
    return Immutable.List.of(kpi.get("readOnlyRootGroupingEntity"));
  }
};

const sortScoreForColumn = c => {
  const pathStr = c.get("joinPathStr");
  const label = c.get("label");

  const isId = label.indexOf("ID") !== -1;
  const isUser = Strings.endsWith("USER", pathStr);
  const isGroup = Strings.endsWith("GROUP", pathStr);
  const isUserName = isUser && !isId;
  const isGroupName = isGroup && !isId;
  const pathLength = countInStr(pathStr, ",") + 1 + (isUserName || isGroupName ? -1 : 0);
  const largePathLength = pathLength * 50;

  const isCreatedDate = label.indexOf("Created Date") !== -1;

  if (isId && !(isUserName || isGroupName)) {
    return largePathLength + 1;
  } else if (isCreatedDate) {
    return largePathLength + 2;
  } else if (isUser) {
    return largePathLength + 3;
  } else if (isGroup) {
    return largePathLength + 4;
  } else {
    return largePathLength + 5;
  }
};

const countInStr = (str, char) => {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) === char) {
      count++;
    }
  }
  return count;
};

const getInitialPathsFromDateFilter = (tableEntityTypeToGroupingEntityType, dateFilter) => {
  let regexMatches;
  let tablePaths = new Immutable.Set();
  while ((regexMatches = filterPattern.exec(dateFilter)) !== null) {
    tablePaths = tablePaths.add(regexMatches[1]);
  }
  return tablePaths
      .map(str => str
          .split(",")
          .map(tableEntity => tableEntityTypeToGroupingEntityType.get(tableEntity)))
      .flatMap(groupEntityPath => makeSubchains(groupEntityPath))
      .map(pathStr => new Immutable.List(JSON.parse(pathStr)));
};

const makeSubchains = (path) => {
  const reversePath = path.reverse();
  let paths = new Immutable.Set();
  for (let i = path.length - 1; i >= 0; i--) {
    paths = paths.add(reversePath.slice(i).reverse());
  }
  return new Immutable.Set(paths.map(JSON.stringify));
};

