/** @jsxImportSource @emotion/react */
import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import pure from "js/common/views/pure";
import {css} from "@emotion/react";

import TreeModel from "tree-model";

import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import GroupAndUserTree from "js/common/views/inputs/group-and-user-picker/group-user-tree";
import eventBus from "js/cube19.event-bus";

import {TextField} from "@mui/material";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const $ = window.$;

const FilterableGroupUserTree = createReactClass({
  propTypes: {
    selectedGroupIds: ImmutablePropTypes.set,
    selectedUserIds: ImmutablePropTypes.set,
    excludedGroupIds: ImmutablePropTypes.set,
    onGroupClick: ReactPropTypes.func,
    onUserClick: ReactPropTypes.func,
    qualifierId: ReactPropTypes.number,
    qualifierType: ReactPropTypes.string,
    label: ReactPropTypes.string,
    excludeUsers: ReactPropTypes.bool,
    showInvisibleUsers: ReactPropTypes.bool,
    showDeletedGroups: ReactPropTypes.bool,
    showLoggedInUserOptions: ReactPropTypes.bool,
    expandAll: ReactPropTypes.bool,
    customContainerStyle: ReactPropTypes.object,
    hierarchy: ReactPropTypes.object,
    identifyObservers: ReactPropTypes.bool,
    includeCube19Users: ReactPropTypes.bool,
    includeInactiveUsers: ReactPropTypes.bool,
    excludeNewAndInactive: ReactPropTypes.bool,
    disableChildrenOfSelectedGroups: ReactPropTypes.bool,
    multiSelect: ReactPropTypes.bool,
    groupsClickable: ReactPropTypes.bool,
    hideSpacer: ReactPropTypes.bool,
    shouldAutoFocus: ReactPropTypes.bool,
    readOnly: ReactPropTypes.bool
  },

  getDefaultProps() {
    return {
      selectedGroupIds: Immutable.Set(),
      selectedUserIds: Immutable.Set(),
      excludedGroupIds: Immutable.Set(),
      onGroupClick: () => {},
      onUserClick: () => {},
      excludeUsers: false,
      showInvisibleUsers: false,
      showDeletedGroups: false,
      expandAll: false,
      showLoggedInUserOptions: false,
      multiSelect: false,
      groupsClickable: true,
      hideSpacer: false,
      shouldAutoFocus: true,
      readOnly: false
    };
  },

  getInitialState() {
    this.initialHierarchy = this.props.hierarchy || Groups.getHierarchyWithUsers(this.props.showInvisibleUsers);
    this.editableHierarchy = createTree(this.initialHierarchy);
    return {
      hierarchy: this.editableHierarchy.model
    };
  },

  componentDidMount() {
    this.timeout = this.props.shouldAutoFocus && setTimeout(() => this.searchTextInput.focus(), 250);
    eventBus.on("hierarchy:group-added", this.addChildGroup, this);
    eventBus.on("hierarchy:group-updated", this.updateGroup, this);
  },

  componentWillUnmount() {
    clearTimeout(this.timeout);
  },

  render() {
    const {
      selectedGroupIds,
      selectedUserIds,
      excludedGroupIds,
      qualifierId,
      excludeUsers,
      showDeletedGroups,
      onGroupClick,
      onUserClick,
      customContainerStyle,
      showLoggedInUserOptions,
      expandAll,
      identifyObservers,
      includeCube19Users,
      includeInactiveUsers,
      excludeNewAndInactive,
      disableChildrenOfSelectedGroups,
      theme,
      multiSelect,
      groupsClickable,
      testId,
      hideSpacer,
      readOnly
    } = this.props;
    const qualifierType = excludeUsers ? "GROUP" : this.props.qualifierType;
    let pathToSelectedGroup;
    if (qualifierId && qualifierType) {
      let groupBreadcrumbs;
      switch (qualifierType) {
        case "GROUP":
          groupBreadcrumbs = Immutable.fromJS(Groups.getGroupBreadcrumbs(qualifierId));
          break;
        case "USER":
          const user = Users.getUser(qualifierId);
          groupBreadcrumbs = Immutable.fromJS(Groups.getGroupBreadcrumbs(user.get("groupId")));
          break;
      }
      pathToSelectedGroup = groupBreadcrumbs.map(group => group.get("id"));
    }
    const searchLabel = `Search for ${excludeUsers ? "Groups" : "Users and Groups"}`;
    return (
        <div style={{...containerStyle(theme), ...customContainerStyle}}>
          {showLoggedInUserOptions &&
              <div>
                {!excludeUsers &&
                    <CustomOption theme={theme} label="Logged in User" icon="user" onClick={() => onUserClick(0)} />}
                <CustomOption
                    theme={theme}
                    label="Logged in User's Group"
                    icon="group"
                    onClick={() => onGroupClick(0)} />
              </div>}
          <div style={{...hideSpacer ? noSpacerStyle : spacerStyle}}>
            {/* this is a stopgap to fix a known issue with React and/or Material-UI for IE11
                    https://github.com/facebook/react/issues/6822 */}
            <style type="text/css" dangerouslySetInnerHTML={{__html: "::-ms-clear {display: none;}"}} />
            <TextField
                variant="standard"
                data-test-id={testId}
                label={<span style={{fontSize: 12}}><i className="bhi-search" />&nbsp;{searchLabel}</span>}
                style={{width: hideSpacer ? "90%" : "80%", marginBottom: 10, zIndex: 99999}}
                onChange={this.handleSearchTextChange}
                inputRef={ref => this.searchTextInput = ref} />
          </div>
          <ul style={hideSpacer ? noSpacerStyle : {...spacerStyle, paddingTop: 0}}>
            <GroupAndUserTree
                theme={theme}
                pathToSelectedGroup={pathToSelectedGroup ? pathToSelectedGroup.delete(0) : Immutable.List()}
                groupNode={this.state.hierarchy}
                selectedGroupIds={selectedGroupIds}
                selectedUserIds={selectedUserIds}
                excludedGroupIds={excludedGroupIds}
                onGroupClick={!readOnly ? onGroupClick : undefined}
                excludeUsers={excludeUsers}
                showDeletedGroups={showDeletedGroups}
                onUserClick={!readOnly ? onUserClick : undefined}
                expandToLevel={expandAll ? 999 : 1}
                identifyObservers={identifyObservers}
                includeCube19Users={includeCube19Users}
                includeInactiveUsers={includeInactiveUsers}
                excludeNewAndInactive={excludeNewAndInactive}
                disableChildrenOfSelectedGroups={disableChildrenOfSelectedGroups}
                multiSelect={multiSelect}
                groupsClickable={groupsClickable}
                qualifierType={qualifierType}
                readOnly={readOnly}
            />
          </ul>
        </div>);
  },

  handleSearchTextChange(event) {
    const searchText = event.target.value;
    let hierarchy;
    if (searchText.length > 0) {
      this.editableHierarchy.all(function(node) {
        node.model.visible = false;
        node.model.isExpanded = true;
        node.model.isFilterMatch = false;
        let name;
        if (node.model.fullName) {
          name = node.model.fullName;
        } else {
          name = node.model.name;
        }
        const stringIndex = name.toUpperCase().indexOf(searchText.toUpperCase());
        if (stringIndex > -1) {
          node.model.isFilterMatch = true;
          const pathsToNode = node.getPath();
          pathsToNode.forEach(node => {
            node.model.visible = true;
          });
        }
      });
      hierarchy = this.editableHierarchy.model;
    } else {
      hierarchy = this.initialHierarchy;
    }
    this.setState({hierarchy});
  },

  addChildGroup(group) {
    let childNode = new TreeModel({childrenPropertyName: "values"});
    childNode = childNode.parse({
      id: group.get("id"),
      name: group.get("name"),
      parentId: group.get("parentId"),
      qualifierType: "GROUP",
      values: [],
      visible: true
    });
    const parentNode = this.editableHierarchy.first(node => node.model.id === group.get("parentId"));
    const index = parentNode.children.length;
    parentNode.addChildAtIndex(childNode, index);
  },

  updateGroup(group) {
    let groupNode = this.editableHierarchy.first(node => node.model.id === group.get("id"));
    groupNode.model.name = group.get("name");
    groupNode.model.deleted = group.get("deleted");
  }

});

const CustomOption = pure(({
  theme,
  label,
  icon,
  onClick
}) => (
    <div css={customOptionCss(theme)} onClick={onClick} className={"TESTCAFE-user-group-custom-option"}>
      {icon && <i className={`fa fa-${icon}`} style={{width: 25, paddingRight: 8}} />}
      {label}
    </div>
));

const createTree = hierarchy => {
  hierarchy = $.extend(true, {}, hierarchy);
  const tree = new TreeModel({childrenPropertyName: "values"});
  return tree.parse(hierarchy);
};

const containerStyle = theme => ({
  position: "relative",
  maxHeight: "100%",
  borderBottom: theme.themeId === "dark" ? `2px solid ${theme.palette.primary.main}` : "none"
});

const spacerStyle = {
  paddingLeft: "1rem",
  paddingRight: "1rem",
  paddingTop: "0.5rem"
};

const noSpacerStyle = {
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0
}

const customOptionCss = theme => css`
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  color: ${theme.palette.text.main};

  &:hover {
    color: ${theme.palette.primary.main}
  }`;


export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <FilterableGroupUserTree theme={theme} {...props} />;
};
