import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import {IconButton} from "js/common/views/inputs/buttons";
import Sortable from "react-sortable-mixin";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const KpiAssignment = createReactClass({
  mixins: [Sortable.ItemMixin],

  propTypes: {
    name: ReactPropTypes.string.isRequired,
    minimisedOrder: ReactPropTypes.number,
    onDeleteClick: ReactPropTypes.func.isRequired,
    onMinimisedOrderChange: ReactPropTypes.func.isRequired,
    isSmallScreen: ReactPropTypes.bool,
    theme: ReactPropTypes.object
  },

  render() {
    const {name, minimisedOrder, onDeleteClick, onMinimisedOrderChange, isSmallScreen, theme} = this.props;
    const nameWrapperStyle = {
      maxWidth: isSmallScreen ? "140px" : "360px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      float: "left"
    };
    return (
      <li className="assigned-kpi__list-item medium-6 small-12 column">
        <div style={nameWrapperStyle}>
          <span style={{lineHeight: 2}}>{name}</span>
        </div>

        <div className="right">
          <MinimisedOrderIcon
              theme={theme}
              minimisedOrder={minimisedOrder}
              onMinimisedOrderChange={onMinimisedOrderChange} />
          <IconButton
            disableClick={true}
            icon="arrows"
            title="Drag to rearrange display order"
            size="large" />
          <IconButton
            hoverType="alert"
            icon="times"
            title="Remove Metric"
            onClick={onDeleteClick}
            size="large" />
        </div>
      </li>
    );
  }

});

const MinimisedOrderIcon = createReactClass({

  propTypes: {
    minimisedOrder: ReactPropTypes.number,
    onMinimisedOrderChange: ReactPropTypes.func,
    theme: ReactPropTypes.object
  },

  getInitialState() {
    return {
      isActive: false
    };
  },

  render() {
    return <span
        onMouseOver={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}>
            {[0, 1, 2].map(index => this.renderOption(index))}
            {this.renderDisplayOption()}
        </span>;
  },

  renderOption(index) {
    const {theme} = this.props;
    let labelStyle = baseLabelStyle;
    if (index === this.props.minimisedOrder) {
      labelStyle = {...labelStyle, color: theme.palette.primary.main};
    }
    if (!this.state.isActive) {
      labelStyle = {...labelStyle, visibility: "hidden"};
    }
    return <span
        key={index}
        style={labelStyle}
        onClick={() => this.props.onMinimisedOrderChange(index)}>
        {(index + 1).toString()}
      </span>;
  },

  renderDisplayOption() {
    const {theme} = this.props;
    let label = "4";
    let labelStyle = baseLabelStyle;
    const minimisedOrder = this.props.minimisedOrder;
    if (!this.state.isActive) {
      if (minimisedOrder !== null) {
        label = (minimisedOrder + 1).toString();
        labelStyle = {...labelStyle, color: theme.palette.primary.main};
      } else {
        labelStyle = {...labelStyle, visibility: "hidden"};
      }
    } else {
      if (minimisedOrder === 3) {
        labelStyle = {...labelStyle, color: theme.palette.primary.main};
      }
    }
    return <span
        style={labelStyle}
        onClick={() => this.props.onMinimisedOrderChange(3)}>
        {label}
      </span>;
  },

  handleMouseOver() {
    this.setState({
      isActive: true
    });
  },

  handleMouseLeave() {
    this.setState({
      isActive: false
    });
  }

});

const baseLabelStyle = {
  cursor: "pointer",
  minWidth: 10,
  lineHeight: 1,
  paddingRight: 8,
  paddingLeft: 8,
  color: "#626262"
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <KpiAssignment theme={theme} {...props} />;
};