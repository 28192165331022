import React from "react";
import createReactClass from "create-react-class";
import SelectField from "@mui/material/Select";

import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import { capitaliseWords } from "js/common/utils/strings";
import * as ratioRepo from "js/common/repo/ratio-repo";
import * as auditer from "js/common/auditer";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import { Popover, TextField, MenuItem, FormControl } from '@mui/material';

const EditRatioColumn = createReactClass({

    mixins: [ PureRenderMixin ],

    getInitialState() {
        return {
            isPopoverOpen: false,
            initialColumnName: this.props.columnName
        };
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.columnName !== this.props.columnName && nextProps.columnName.length > 0) {
            this.setState({
                initialColumnName: nextProps.columnName
            });
        }
    },

    render() {
        const { isPopoverOpen, anchorEl } = this.state;
        const { columnName, displayType, theme } = this.props;
        return (
            <div>
                <EditColumnButton theme={theme} onClick={this.openPopover} />
                <Popover
                    open={isPopoverOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{ horizontal: "right", vertical: isIpad() ? "top" : "bottom" }}
                    transformOrigin={{ horizontal: "left", vertical: isIpad() ? "bottom" : "top" }}
                    onClose={this.closePopover} >
                    <div style={{ padding: "1rem", border: "1px solid #555" }}>
                        <TextField
                            variant="standard"
                            label="Ratio column name"
                            style={{width: 250}}
                            error={columnName.length === 0}
                            helperText={columnName.length === 0 ? "Ratio column name cannot be blank" : ""}
                            value={columnName}
                            onChange={this.handleColumnNameChange} />
                        <br />
                        <span style={{
                            display: "block",
                            margin: "14px 0 0 0",
                            fontSize: "0.7rem",
                            lineHeight: "1.4375em",
                            opacity: 0.7
                        }}>Display format</span>
                        <FormControl style={{marginTop: "0", width: 250}}>
                            <SelectField
                                variant="standard"
                                value={displayType}
                                onChange={e => this.handleDisplayTypeChange(e.target.value)} >
                                {getDisplayTypeOptions()}
                            </SelectField>
                        </FormControl>
                    </div>
                </Popover>
            </div>
        );
    },

    handleColumnNameChange(e) {
        const text = e.target.value;
        this.props.onColumnNameChange(text.substring(0,50));
    },

    handleDisplayTypeChange(newDisplayType) {
        this.props.onRatioDisplayChange(newDisplayType);
        this.closePopover();
    },

    openPopover(evt) {
        evt.preventDefault();
        this.setState({
            isPopoverOpen: true,
            anchorEl: evt.currentTarget
        });
    },

    closePopover() {
        this.setState({ isPopoverOpen: false });
        const { columnName, onColumnNameChange } = this.props;
        if (columnName.length === 0) {
            onColumnNameChange(this.state.initialColumnName);
        }
    }

});

const isIpad = () => {
    const parser = auditer.getUserAgent().getResult();
    const device = parser.device;
    return device.model === "iPad";
};

const getDisplayTypeOptions = () => ratioRepo
    .getDisplayTypes()
    .map(type => <MenuItem key={type} value={type}>{capitaliseWords(type)}</MenuItem>);

const EditColumnButton = pure(({ theme, onClick }) => (
    <i className="fa fa-cogs" title="Edit Column" style={editBtnStyle(theme)} onClick={onClick} />
));
const editBtnStyle = theme => ({
    cursor: "pointer",
    fontSize: "1rem",
    textAlign: "center",
    color: theme.palette.textColor,

    ":hover": {
        color: theme.palette.action.hover
    }
});

const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <EditRatioColumn theme={theme} {...props} />;
};

export default Wrapper;