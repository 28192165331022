import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import { Layout } from "js/common/views/foundation-column-layout";
import { TextButton } from "js/common/views/inputs/buttons";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import Dialog from "js/common/views/dialog";
import TagCategoryPicker from "js/admin/tags/tag-category-picker";
import TagCategoryMgmt from "js/admin/tags/react-tag-category-mgmt";
import TagMgmt from "js/admin/tags/react-tag-mgmt";
import ErrorMsg from "js/common/views/error";
import * as tagRepo from "js/common/repo/backbone/tag-repo";
import * as ajax from "js/common/ajax";
import * as Groups from "js/common/groups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants"

const contactSupport = `An unexpected error has occurred. ${Branding.submitTicketString}`;
const categoryNameRequired = "Tag category name required";
const tagNameRequired = "Tag name required";

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <App theme={theme} {...props} />;
};

const App = createReactClass({

    mixins: [ PureRenderMixin ],

    getInitialState() {
        return {
            categories: getTagCategories(),
            tags: getTags(),

            newCategoryName: "",
            currentlyEditingCategory: null,
            isCurrentlyEditingCategoryNameChanged: false,
            categoryIdToDelete: null,
            isLoadingCategories: false,
            editCategoryError: null,
            categoryMgmtError: null,
            categoryMgmtSuccess: null,

            newTagName: "",
            newTagCategoryId: null,
            currentlyEditingTag: null,
            hasCurrentlyEditingTagChanged: false,
            tagIdToDelete: null,
            groupsAndUsersAssignedToTagIdToDelete: null,
            isLoadingGroupsAndUsersAssignedToTag: false,
            isDeleteTagDialogOpen: false,
            isLoadingTags: false,
            editTagError: null,
            tagMgmtError: null,
            tagMgmtSuccess: null
        };
    },

    render() {
        const {
            categories,
            isLoadingCategories,
            newCategoryName,
            currentlyEditingCategory,
            isCurrentlyEditingCategoryNameChanged,
            editCategoryError,
            categoryIdToDelete,
            categoryMgmtError,
            categoryMgmtSuccess,
            tags,
            isLoadingTags,
            newTagName,
            newTagCategoryId,
            currentlyEditingTag,
            hasCurrentlyEditingTagChanged,
            editTagError,
            tagIdToDelete,
            isLoadingGroupsAndUsersAssignedToTag,
            isDeleteTagDialogOpen,
            tagMgmtError,
            tagMgmtSuccess
        } = this.state;

        return (
            <div>
                <TagCategoryMgmt
                    categories={categories}
                    isLoading={isLoadingCategories}
                    isCreatingNewCategory={isLoadingCategories && !categoryIdToDelete && !currentlyEditingCategory}
                    newCategoryName={newCategoryName}
                    onNewCategoryNameChange={this.handleNewCategoryNameChange}
                    onCreateNewCategoryRequest={this.handleCreateNewCategoryRequest}
                    onEditCategoryClick={this.openEditCategoryDialog}
                    onDeleteCategoryClick={this.openDeleteCategoryDialog}
                    categoryMgmtError={categoryMgmtError}
                    categoryMgmtSuccess={categoryMgmtSuccess}
                    onCategoryMgmtSuccessMsgTimeout={this.handleCategoryMgmtSuccessMsgTimeout} />
                {currentlyEditingCategory &&
                    <EditCategoryDialog
                        categoryName={currentlyEditingCategory.get("name")}
                        onCategoryNameChange={this.handleCurrentCategoryNameChange}
                        hasChanged={isCurrentlyEditingCategoryNameChanged}
                        isLoading={isLoadingCategories}
                        error={editCategoryError}
                        onCloseRequest={this.closeEditCategoryDialog}
                        onSaveRequest={this.handleSaveCategoryChangeRequest} />}
                {categoryIdToDelete && this.renderDeleteCategoryDialog()}
                <TagMgmt
                    tags={tags}
                    categories={categories}
                    isLoading={isLoadingTags || isLoadingCategories || isLoadingGroupsAndUsersAssignedToTag}
                    isCreatingNewTag={isLoadingTags && !tagIdToDelete && !currentlyEditingTag}
                    newTagName={newTagName}
                    onNewTagNameChange={this.handleNewTagNameChange}
                    newTagCategoryId={newTagCategoryId}
                    onNewTagCategoryIdChange={this.handleNewTagCategoryIdChange}
                    onCreateNewTagRequest={this.handleCreateNewTagRequest}
                    onEditTagClick={this.openEditTagDialog}
                    onDeleteTagClick={this.handleDeleteTagClick}
                    editTagError={editTagError}
                    tagMgmtError={tagMgmtError}
                    tagMgmtSuccess={tagMgmtSuccess}
                    onTagMgmtSuccessMsgTimeout={this.handleTagMgmtSuccessMsgTimeout} />
                {currentlyEditingTag &&
                    <EditTagDialog
                        isOpen={!!currentlyEditingTag}
                        categories={categories}
                        tag={currentlyEditingTag}
                        isLoading={isLoadingTags || isLoadingCategories}
                        hasChanged={hasCurrentlyEditingTagChanged}
                        error={editTagError}
                        onChange={this.handleCurrentTagChange}
                        onCancelClick={this.closeEditTagDialog}
                        onSaveClick={this.handleSaveTagChangesRequest} />}
                {isDeleteTagDialogOpen && this.renderDeleteTagDialog()}
            </div>
        );
    },

    renderDeleteCategoryDialog() {
        const {theme} = this.props;
        const { categories, categoryIdToDelete, isLoadingCategories } = this.state;
        const index = categories.findIndex(cat => cat.get("id") === categoryIdToDelete);
        const category = categories.get(index);
        const categoryName = category.get("name");
        const categoryHasTagsAssigned = tagRepo.getTagsInCategory(categoryIdToDelete).length > 0;
        if (categoryHasTagsAssigned) {
            return (
                <CannotDeleteCategoryWarning
                    isOpen={categoryHasTagsAssigned}
                    categoryName={categoryName}
                    onCloseRequest={this.closeDeleteCategoryDialog} />
            );
        } else {
            return (
                <ConfirmDeleteCategoryDialog
                    isOpen={!!categoryIdToDelete}
                    categoryName={categoryName}
                    isLoading={isLoadingCategories}
                    onConfirmRequest={this.handleDeleteCategoryRequest}
                    onCancelRequest={this.closeDeleteCategoryDialog} />
            );
        }
    },

    renderDeleteTagDialog() {
        const {
            tags,
            tagIdToDelete,
            isLoadingTags,
            groupsAndUsersAssignedToTagIdToDelete
        } = this.state;
        const index = tags.findIndex(t => t.get("id") === tagIdToDelete);
        const tag = tags.get(index);
        return (
            <ConfirmDeleteTagDialog
                isOpen={!!tagIdToDelete}
                tagName={tag.get("name")}
                tagCategoryId={tag.get("categoryId")}
                groupsAndUsersAssignedToTag={groupsAndUsersAssignedToTagIdToDelete}
                isActionsDisabled={isLoadingTags}
                onConfirmRequest={this.handleDeleteTagRequest}
                onCancelRequest={this.closeDeleteTagDialog} />
        );
    },

    handleCurrentCategoryNameChange(categoryName) {
        const { categories, currentlyEditingCategory } = this.state;
        const index = categories.findIndex(cat => cat.get("id") === currentlyEditingCategory.get("id"));
        const initialCategory = categories.get(index);
        const isNewNameSameAsInitialName = categoryName === initialCategory.get("name");
        this.setState({
            currentlyEditingCategory: currentlyEditingCategory.set("name", categoryName),
            isCurrentlyEditingCategoryNameChanged: !isNewNameSameAsInitialName,
            editCategoryError: null
        });
        if (!categoryName) {
            this.setState({
                editCategoryError: categoryNameRequired
            });
        } else if (!isNewNameSameAsInitialName && categoryAlreadyExists(categoryName)) {
            this.setState({
                editCategoryError: `Tag category '${categoryName}' already exists`
            });
        }
    },

    handleCategoryMgmtSuccessMsgTimeout() {
        this.setState({
            categoryMgmtSuccess: null
        });
    },

    handleCategoryNameChange(newCategoryName) {
        const { currentlyEditingCategory } = this.state;
        this.setState({
            currentlyEditingCategory: currentlyEditingCategory.set("name", newCategoryName)
        });
    },

    handleNewCategoryNameChange(categoryName) {
        const maxNameLength = tagRepo.MAX_NAME_LENGTH;
        this.setState({
            newCategoryName: categoryName.substring(0, maxNameLength),
            categoryMgmtError: null
        });
    },

    handleCreateNewCategoryRequest() {
        const categoryName = this.state.newCategoryName;
        if (!categoryName) {
            this.setState({
                categoryMgmtError: categoryNameRequired
            });
        } else if (categoryAlreadyExists(categoryName)) {
            this.setState({
                categoryMgmtError: `Tag category '${categoryName}' already exists`
            });
        } else {
            this.setState({
                isLoadingCategories: true
            });
            tagRepo
                .getAllCategories()
                .create({
                    name: categoryName
                },{
                    wait: true,
                    success: () => {
                        this.setState({
                            isLoadingCategories: false,
                            categories: getTagCategories(),
                            newCategoryName: "",
                            categoryMgmtSuccess: `Tag category '${categoryName}' created`
                        });
                    },
                    error: () => {
                        this.setState({
                            isLoadingCategories: false,
                            categoryMgmtError: contactSupport
                        });
                    }
                });
        }
    },

    openEditCategoryDialog(category) {
        this.setState({
            currentlyEditingCategory: category
        });
    },

    closeEditCategoryDialog() {
        this.setState({
            currentlyEditingCategory: null,
            editCategoryError: null
        });
    },

    handleSaveCategoryChangeRequest() {
        const { currentlyEditingCategory } = this.state;
        const categoryId = currentlyEditingCategory.get("id");
        const categoryName = currentlyEditingCategory.get("name");
        const categoryModel = tagRepo.getCategory(categoryId);
        this.setState({
            isLoadingCategories: true
        });
        categoryModel.save({
            name: categoryName
        },{
            wait: true,
            success: () => {
                this.setState({
                    isLoadingCategories: false,
                    categories: getTagCategories(),
                    isCurrentlyEditingCategoryNameChanged: false,
                    currentlyEditingCategory: null
                });
            },
            error: () => {
                this.setState({
                    isLoadingCategories: false,
                    editCategoryError: contactSupport
                });
            }
        });
    },

    openDeleteCategoryDialog(id) {
        this.setState({
            categoryIdToDelete: id
        });
    },

    closeDeleteCategoryDialog() {
        this.setState({
            categoryIdToDelete: null
        });
    },

    handleDeleteCategoryRequest() {
        const { categoryIdToDelete } = this.state;
        const categoryModel = tagRepo.getCategory(categoryIdToDelete);
        const categoryName = categoryModel.get("name");
        this.setState({
            isLoadingCategories: true
        });
        categoryModel.destroy({
            wait: true,
            success: () => {
                this.setState({
                    isLoadingCategories: false,
                    categoryIdToDelete: null,
                    categories: getTagCategories(),
                    categoryMgmtSuccess: `Tag category '${categoryName}' deleted`
                });
            },
            error: () => {
                this.setState({
                    isLoadingCategories: false,
                    categoryMgmtError: contactSupport
                });
            }
        });
    },

    handleTagMgmtSuccessMsgTimeout() {
        this.setState({
            tagMgmtSuccess: null
        });
    },

    handleNewTagNameChange(newTagName) {
        const maxNameLength = tagRepo.MAX_NAME_LENGTH;
        this.setState({
            newTagName: newTagName.substring(0, maxNameLength),
            tagMgmtError: null
        });
    },

    handleNewTagCategoryIdChange(categoryId) {
        this.setState({
            newTagCategoryId: categoryId,
            tagMgmtError: null
        });
    },

    handleCreateNewTagRequest() {
        const { newTagName, newTagCategoryId } = this.state;
        if (!newTagName) {
            this.setState({
                tagMgmtError: tagNameRequired
            });
            return;
        }
        if (!newTagCategoryId) {
            this.setState({
                tagMgmtError: `Tag category required for new tag`
            });
            return;
        }
        const category = tagRepo.getCategory(newTagCategoryId);
        if (tagAlreadyExistsInCategory(newTagName, newTagCategoryId)) {
            this.setState({
                tagMgmtError: `Tag '${newTagName}' already exists in category '${category.get("name")}'`
            });
        } else {
            this.setState({
                isLoadingTags: true
            });
            tagRepo
                .getAll()
                .create({
                    name: newTagName,
                    categoryId: newTagCategoryId
                },{
                    wait: true,
                    success: () => {
                        this.setState({
                            isLoadingTags: false,
                            tags: getTags(),
                            newTagName: "",
                            newTagCategoryId: null,
                            tagMgmtSuccess: `Tag '${newTagName}' in category '${category.get("name")}' created`
                        });
                    },
                    error: () => {
                        this.setState({
                            isLoadingTags: false,
                            tagMgmtError: contactSupport
                        });
                    }
                });
        }
    },

    handleDeleteTagClick(tagId) {
        this.setState({
            tagIdToDelete: tagId,
            isLoadingGroupsAndUsersAssignedToTag: true
        });
        getGroupsAndUsersAssignedToTag(tagId)
            .then(groupsAndUsersAssignedToTag => {
                this.setState({
                    isLoadingGroupsAndUsersAssignedToTag: false,
                    groupsAndUsersAssignedToTagIdToDelete: groupsAndUsersAssignedToTag
                });
                this.openDeleteTagDialog();
            });
    },

    openDeleteTagDialog() {
        this.setState({
            isDeleteTagDialogOpen: true
        });
    },

    closeDeleteTagDialog() {
        this.setState({
            tagIdToDelete: null,
            isDeleteTagDialogOpen: false
        });
    },

    handleDeleteTagRequest() {
        const tagModel = tagRepo.get(this.state.tagIdToDelete);
        const tagName = tagModel.get("name");
        this.setState({
            isLoadingTags: true
        });
        tagModel.destroy({
            wait: true,
            success: () => {
                this.setState({
                    isLoadingTags: false,
                    tags: getTags(),
                    tagIdToDelete: null,
                    tagMgmtSuccess: `Tag '${tagName}' deleted`
                });
            },
            error: () => {
                this.setState({
                    isLoadingTags: false,
                    tagMgmtError: contactSupport
                });
            }
        });
    },

    openEditTagDialog(tag) {
        this.setState({
            currentlyEditingTag: tag
        });
    },

    closeEditTagDialog() {
        this.setState({
            currentlyEditingTag: null,
            editTagError: null
        });
    },

    handleCurrentTagChange(updatedTag) {
        const tagName = updatedTag.get("name");
        const categoryId = updatedTag.get("categoryId");
        const { tags, currentlyEditingTag } = this.state;
        const index = tags.findIndex(cat => cat.get("id") === currentlyEditingTag.get("id"));
        const initialTag = tags.get(index);
        const isSameAsInitialTagName = tagName === initialTag.get("name");
        if (!tagName) {
            this.setState({
                currentlyEditingTag: updatedTag,
                editTagError: tagNameRequired
            });
        } else if (!isSameAsInitialTagName && tagAlreadyExistsInCategory(tagName, categoryId)) {
            const category = tagRepo.getCategory(categoryId);
            this.setState({
                currentlyEditingTag: updatedTag,
                editTagError: `Tag '${tagName}' already exists in category '${category.get("name")}'`
            });
        } else {
            this.setState({
                currentlyEditingTag: updatedTag,
                hasCurrentlyEditingTagChanged: !Immutable.is(updatedTag, initialTag),
                editTagError: null
            });
        }
    },

    handleSaveTagChangesRequest() {
        const { currentlyEditingTag } = this.state;
        const tagId = currentlyEditingTag.get("id");
        const tagModel = tagRepo.get(tagId);
        this.setState({
            isLoadingTags: true
        });
        tagModel.save({
            name: currentlyEditingTag.get("name"),
            categoryId: currentlyEditingTag.get("categoryId")
        },{
            wait: true,
            success: () => {
                this.setState({
                    isLoadingTags: false,
                    tags: getTags(),
                    currentlyEditingTag: null,
                    tagMgmtSuccess: "Tag change(s) saved"
                });
            },
            error: () => {
                this.setState({
                    isLoadingTags: false,
                    editTagError: contactSupport
                });
            }
        });
    }

});

const EditCategoryDialog = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            categoryName,
            hasChanged,
            onCloseRequest,
            onSaveRequest,
            isLoading,
            error
        } = this.props;
        const actions = [
            <TextButton
                key="cancel-edit"
                label="Cancel"
                icon="history"
                onClick={onCloseRequest}
                disabled={isLoading}
                style={buttonSpacing}/>,
            <TextButton
                key="confirm-edit"
                type="primary"
                label="Save"
                icon="floppy-o"
                onClick={onSaveRequest}
                disabled={!hasChanged || isLoading || !!error}
                style={buttonSpacing}/>
        ];
        return (
            <Dialog
                title="Rename Tag Category"
                actions={actions}
                actionsContainerStyle={dialogButtonContainerStyle}
                bodyStyle={dialogBodyStyle}
                contentStyle={{ maxWidth: 500 }}
                autoDetectWindowHeight={true}
                onRequestClose={onCloseRequest}
                open={true}>
                <div>
                    <label style={labelStyle}>Category Name</label>
                    <SimpleTextInput
                        type="text"
                        placeholder="Tag category name"
                        value={categoryName}
                        onChange={this.handleCategoryNameChange}
                        customStyle={!categoryName ? inputErrorStyle : {}} />
                    {error && <ErrorMsg text={error} />}
                </div>
            </Dialog>
        );
    },

    handleCategoryNameChange(categoryName) {
        const maxNameLength = tagRepo.MAX_NAME_LENGTH;
        this.props.onCategoryNameChange(categoryName.substring(0, maxNameLength));
    }

});

const CannotDeleteCategoryWarning = pure(({
    isOpen,
    categoryName,
    onCloseRequest
}) => (
    <Dialog
        title="Category Still Has Tags"
        actions={[ <TextButton key="ok-btn" type="primary" label="OK" style={buttonSpacing} onClick={onCloseRequest} /> ]}
        actionsContainerStyle={dialogButtonContainerStyle}
        bodyStyle={dialogBodyStyle}
        autoDetectWindowHeight={true}
        onRequestClose={onCloseRequest}
        open={isOpen}>
        <p>
            {`Tag category '${categoryName}' still has Tags associated. Please delete these Tags before deleting this category.`}
        </p>
    </Dialog>
));

const ConfirmDeleteCategoryDialog = pure(({
    isOpen,
    isLoading,
    categoryName,
    onConfirmRequest,
    onCancelRequest
}) => {
    const actions = [
        <TextButton
            key="cancel-delete"
            label="Cancel"
            icon="history"
            disabled={isLoading}
            style={buttonSpacing}
            onClick={onCancelRequest} />,
        <TextButton
            key="confirm-delete"
            type="primary"
            label="Delete"
            icon="trash"
            disabled={isLoading}
            style={buttonSpacing}
            onClick={onConfirmRequest} />
    ];
    return (
        <Dialog
            title="Delete Tag Category"
            actions={actions}
            actionsContainerStyle={dialogButtonContainerStyle}
            bodyStyle={dialogBodyStyle}
            autoDetectWindowHeight={true}
            onRequestClose={onCancelRequest}
            open={isOpen}>
            <p>{`Delete Tag Category '${categoryName}' permanently?`}</p>
        </Dialog>
    );
});

const EditTagDialog = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            isOpen,
            isLoading,
            onCancelClick,
            onSaveClick,
            categories,
            tag,
            hasChanged,
            error
        } = this.props;
        const tagName = tag.get("name");
        const actions = [
            <TextButton
                key="cancel-edit"
                label="Cancel"
                icon="history"
                onClick={onCancelClick}
                disabled={isLoading}
                style={buttonSpacing}/>,
            <TextButton
                key="confirm-edit"
                type="primary"
                label="Save"
                icon="floppy-o"
                onClick={onSaveClick}
                disabled={!hasChanged || !!error || isLoading}
                style={buttonSpacing}/>
        ];
        const spacing = {
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem"
        };
        return (
            <Dialog
                title="Edit Tag"
                actions={actions}
                actionsContainerStyle={dialogButtonContainerStyle}
                bodyStyle={dialogBodyStyle}
                contentStyle={{ maxWidth: 500 }}
                autoDetectWindowHeight={true}
                onRequestClose={onCancelClick}
                open={isOpen}>
                <div>
                    <div style={spacing}>
                        <label style={labelStyle}>
                            Tag Name
                        </label>
                        <SimpleTextInput
                            type="text"
                            placeholder="Tag name"
                            value={tagName}
                            onChange={this.handleTagNameChange}
                            customStyle={!tagName ? inputErrorStyle : {}} />
                    </div>
                    <div style={spacing}>
                        <label style={labelStyle}>
                            Category
                        </label>
                        <TagCategoryPicker
                            categories={categories}
                            selectedCategoryId={tag.get("categoryId")}
                            onChange={this.handleCategoryChange} />
                    </div>
                    {error && <ErrorMsg text={error} />}
                </div>
            </Dialog>
        );
    },

    handleTagNameChange(tagName) {
        const maxNameLength = tagRepo.MAX_NAME_LENGTH;
        const { tag, onChange } = this.props;
        onChange(tag.set("name", tagName.substring(0, maxNameLength)));
    },

    handleCategoryChange(categoryId) {
        const { tag, onChange } = this.props;
        onChange(tag.set("categoryId", categoryId));
    }

});

const ConfirmDeleteTagDialogInner = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            isOpen,
            isActionsDisabled,
            onConfirmRequest,
            onCancelRequest
        } = this.props;
        const actions = [
            <TextButton
                key="cancel-delete"
                label="Cancel"
                icon="history"
                style={buttonSpacing}
                disabled={isActionsDisabled}
                onClick={onCancelRequest} />,
            <TextButton
                key="confirm-delete"
                type="primary"
                label="Delete"
                icon="trash"
                style={buttonSpacing}
                disabled={isActionsDisabled}
                onClick={onConfirmRequest} />
        ];
        return (
            <Dialog
                title="Delete Tag?"
                actions={actions}
                actionsContainerStyle={dialogButtonContainerStyle}
                bodyStyle={dialogBodyStyle}
                autoDetectWindowHeight={true}
                onRequestClose={onCancelRequest}
                open={isOpen}>
                {this.renderDeleteTagConfirmation()}
            </Dialog>
        );
    },

    renderDeleteTagConfirmation() {
        const { tagName, tagCategoryId, groupsAndUsersAssignedToTag, theme } = this.props;
        const category = tagRepo.getCategory(tagCategoryId);
        const categoryName = category.get("name");
        if (groupsAndUsersAssignedToTag && !groupsAndUsersAssignedToTag.isEmpty()) {
            return (
                <div>
                    <p>The &apos;<strong style={{ color: theme.palette.primary.main }}>{categoryName} : {tagName}</strong>&apos; Tag is currently assigned to the following Users and/or Groups:</p>
                    {this.renderGroupsAndUsersAssignedToTagLists()}
                    <p>Are you sure you want to delete this Tag?</p>
                </div>
            );
        } else {
            return <p>{`Delete Tag '${categoryName} : ${tagName}' permanently?`}</p>;
        }
    },

    renderGroupsAndUsersAssignedToTagLists() {
        const { groupsAndUsersAssignedToTag, theme } = this.props;
        const groupList = groupsAndUsersAssignedToTag.filter(obj => obj.get("qualifierType") === "GROUP");
        const userList = groupsAndUsersAssignedToTag.filter(obj => obj.get("qualifierType") === "USER");
        const listHeaderStyle = {
            color: theme.palette.primary.main,
            fontWeight: "normal",
            fontSize: "1rem",
            paddingBottom: "0.625rem"
        };
        const listStyle = { listStylePosition: "inside" };
        return (
            <div style={{ maxHeight: 400, overflowY: "auto", paddingBottom: "1rem" }}>
                <Layout allSmall={12} allMedium={6}>
                    <div>
                        <h4 style={listHeaderStyle}>
                            Users
                        </h4>
                        <ul style={listStyle}>
                            {userList.isEmpty() && <li><em>No Users Assigned</em></li>}
                            {userList.map(user => <li key={`usr-${user.get("id")}`}>{user.get("name")}</li>)}
                        </ul>
                    </div>
                    <div>
                        <h4 style={listHeaderStyle}>
                            Groups
                        </h4>
                        <ul style={listStyle}>
                            {groupList.isEmpty() && <li><em>No Groups Assigned</em></li>}
                            {groupList.map(grp => {
                                const groupId = grp.get("id");
                                return <li key={`grp-${groupId}`}>
                                    {Groups.getGroupBreadcrumbsStr(groupId, ">")}
                                </li>;
                            })}
                        </ul>
                    </div>
                </Layout>
            </div>
        );
    }

});

const dialogBodyStyle = {
    overflow: "visible",
    color: "#fff"
};

const dialogButtonContainerStyle = {
    paddingLeft: "2rem",
    paddingRight: "2rem",
    paddingBottom: "2rem"
};

const buttonSpacing = {
    marginLeft: "0.5rem",
    marginRight: "0.5rem"
};

const labelStyle = {
    cursor: "default",
    fontSize: "0.875rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem"
};

const inputErrorStyle = {
    border: "1px solid #ff4d4d",
    backgroundColor: "rgba(255, 77, 77, 0.1)"
};

const tagAlreadyExistsInCategory = (tagName, categoryId) => {
    const tagsInCategory = getTags().filter(t => t.get("categoryId") === categoryId);
    const existingTagNames = tagsInCategory.map(tag => tag.get("name"));
    return existingTagNames.includes(tagName);
};

const categoryAlreadyExists = categoryName => {
    const existingCategoryNames = getTagCategories().map(cat => cat.get("name"));
    return existingCategoryNames.includes(categoryName);
};

const getGroupsAndUsersAssignedToTag = tagId => ajax
    .get({ url: `tags/${tagId}` })
    .then(results => Immutable.fromJS(results));

const getTags = () => Immutable.fromJS(tagRepo.getAll().toJSON());

const getTagCategories = () => {
    const categories = Immutable.fromJS(tagRepo.getAllCategories().toJSON());
    return categories.sortBy(cat => cat.get("name").toLowerCase());
};

const ConfirmDeleteTagDialog = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <ConfirmDeleteTagDialogInner theme={theme} {...props} />;
};
