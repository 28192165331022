/** @jsxImportSource @emotion/react */

import React from "react";
import * as Styles from "js/squids/styles/styles";
import Immutable from "immutable";
import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import LabelledSelect from "js/common/views/inputs/labelled-select";

const SettingsTab = React.memo(({
  page,
  title,
  squidNames,
  handleEditPage,
  handleEditTitle,
  inputDelayMillis,
  errors
}) => {

  const themes = Styles.getThemes();
  const isDescriptionErroring = errors && errors.size > 0;
  const [nameBlank, setNameBlank] = React.useState(false);
  const getHelperText = () => {
    if (squidNames.includes(title)) {
      return "Please choose a unique name";
    }
    if (nameBlank) {
      return "Name cannot be blank";
    }
    return null;
  };
  const onNameChange = (value) => {
    handleEditTitle(value.substring(0, 255));
    setNameBlank(false);
  };
  return (
      <div style={{margin: 30, height: "100%"}}>
        <div style={{width: "40%"}}>
          <DelayedTextField
              label="Squid Name"
              theme="cube2021"
              delayInMillis={inputDelayMillis}
              style={{width: "100%"}}
              error={squidNames.includes(title) || nameBlank}
              helperText={getHelperText()}
              InputProps={{disableUnderline: true}}
              value={title || ""}
              inputProps={{ maxLength: 255 }}
              onChange={value => value === "" ? setNameBlank(true) : onNameChange(value)} />
        </div>
        <div>
          <DelayedTextField
              label="Squid Description"
              theme="cube2021"
              delayInMillis={inputDelayMillis}
              style={{width: "100%"}}
              multiline
              rows={4}
              error={isDescriptionErroring}
              helperText={isDescriptionErroring && errors.first().get("message")}
              InputProps={{disableUnderline: true}}
              value={page.get("description") || ""}
              onChange={value => handleEditPage(page.set("description", value))} />
        </div>
        <div style={{width: "40%"}}>
          <LabelledSelect
              theme="cube2021"
              label="Squid Theme"
              isMulti={false}
              isClearable={false}
              isSearchable={false}
              selectedValue={page.getIn(["defaultDisplay", "theme"]) || "default"}
              onChange={value => handleEditPage(page.setIn(["defaultDisplay", "theme"], value))}
              options={themes.map(theme => Immutable.Map({label: theme, value: theme}))} />
        </div>
      </div>
  );
});

export default SettingsTab;
