import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import moment from "moment";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";
import FileSaver from "browser-filesaver";

import AdminHeader from "js/admin/common/admin-header";
import {TextButton} from "js/common/views/inputs/buttons";
import Select from "js/common/views/inputs/immutable-react-select";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import currentClient from "js/common/repo/backbone/current-client";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";

import * as clientRepo from "js/common/repo/backbone/current-client";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as ajax from "js/common/ajax";
import * as auditer from "js/common/auditer";
import * as Users from "js/common/users";

import Checkbox from "js/common/views/inputs/checkbox";
import * as Branding from "js/common/branding-constants";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const unexpectedErrorMsg = `An unexpected error has occurred. ${Branding.submitTicketString}`;

const TimeFrameSettings = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    const client = currentClient;
    return {
      financialYearStartMonth: client.get("financialYearStartMonth"),
      isUpdatingFinancialYearStartMonth: false,
      updateFinancialYearStartMonthError: null,
      updateFinancialYearStartMonthSuccess: null,

      timeframes: getTimeframes(),
      defaultTimeframeId: client.get("timeframe"),
      isUpdatingDefaultTimeframeId: false,
      updateDefaultTimeframeIdError: null,
      updateDefaultTimeframeIdSuccess: null,

      isUsingCustomCalendar: client.hasPermission("USES_445_CALENDAR"),
      isActivatingCustomCalendar: false,
      activateCustomCalendarError: null,
      showMonthlyTargetsDownloadButton: false,
      isGettingMonthlyTargetsCsv: false,
      monthlyTargetsCsvError: null,
      monthlyTargetsCsvSuccess: null
    };
  },

  render() {
    const initialState = this.getInitialState();
    const isFinancialYearStartMonthChanged = initialState.financialYearStartMonth !==
        this.state.financialYearStartMonth;
    const isDefaultTimeframeIdChanged = initialState.defaultTimeframeId !== this.state.defaultTimeframeId;
    return (
        <div>
          <FinancialYearStart
              startMonth={this.state.financialYearStartMonth}
              onChange={this.handleFinancialYearStartMonthChange}
              isActionsDisabled={!isFinancialYearStartMonthChanged || this.state.isUpdating}
              isUpdating={this.state.isUpdatingFinancialYearStartMonth}
              onSaveChangeRequest={this.handleSaveFinancialYearStartMonthChangeRequest}
              onCancelChangeRequest={this.handleCancelFinancialYearStartMonthChangeRequest}
              error={this.state.updateFinancialYearStartMonthError}
              success={this.state.updateFinancialYearStartMonthSuccess}
              onSuccessMessageTimeout={() => this.setState({updateFinancialYearStartMonthSuccess: null})} />
          <DefaultTimeframe
              timeframes={this.state.timeframes}
              timeframeId={this.state.defaultTimeframeId}
              onChange={this.handleDefaultTimeframeIdChange}
              isActionsDisabled={!isDefaultTimeframeIdChanged || this.state.isUpdating}
              isUpdating={this.state.isUpdatingDefaultTimeframeId}
              onSaveChangeRequest={this.handleSaveDefaultTimeframeIdChangeRequest}
              onCancelChangeRequest={this.handleCancelDefaultTimeframeIdChangeRequest}
              error={this.state.updateDefaultTimeframeIdError}
              success={this.state.updateDefaultTimeframeIdSuccess}
              onSuccessMessageTimeout={() => this.setState({updateDefaultTimeframeIdSuccess: null})} />
          {currentUserCanAccessApp("TIMEFRAME_ADMIN") &&
          <EnableCustomCalendar
              theme={this.props.theme}
              isUsingCustomCalendar={this.state.isUsingCustomCalendar}
              onCheckboxClick={this.handleToggleCustomCalendarStatusRequest}
              onDownloadMonthlyTargetsClick={this.handleDownloadMonthlyTargetsRequest}
              isUpdating={this.state.isActivatingCustomCalendar}
              showMonthlyTargetsDownloadButton={this.state.showMonthlyTargetsDownloadButton}
              error={this.state.activateCustomCalendarError} />}
        </div>
    );
  },

  handleDownloadMonthlyTargetsRequest() {
    this.setState({
      isGettingMonthlyTargetsCsv: true
    });
    getMonthlyTargetsCsv()
        .then(result => {
          const blob = new Blob([result], {type: "text/csv;charset=utf-8"});
          const dateTimeGenerated = moment().format("YYYY-MM-DD HH-mm-ss");
          const csvFileName = `${currentClient.get("name")} Monthly Targets as of ${dateTimeGenerated}.csv`;
          FileSaver.saveAs(blob, csvFileName);

          auditer.audit("client:make_monthly_targets_csv");

          this.setState({
            isGettingMonthlyTargetsCsv: false,
            monthlyTargetsCsvSuccess: "Current Monthly Targets CSV generated"
          });
        }, () => {
          this.setState({
            isGettingMonthlyTargetsCsv: false,
            monthlyTargetsCsvError: unexpectedErrorMsg
          });
        });
  },

  handleFinancialYearStartMonthChange(newStartMonth) {
    this.setState({
      financialYearStartMonth: newStartMonth,
      updateFinancialYearStartMonthError: null,
      updateFinancialYearStartMonthSuccess: null
    });
  },

  handleSaveFinancialYearStartMonthChangeRequest() {
    this.setState({isUpdatingFinancialYearStartMonth: true});
    updateClient({financialYearStartMonth: this.state.financialYearStartMonth.toUpperCase()})
        .then(result => {
          timeframeRepo.load();
          this.setState({
            isUpdatingFinancialYearStartMonth: false,
            financialYearStartMonth: result.financialYearStartMonth,
            updateFinancialYearStartMonthSuccess: "Financial year starting month updated"
          });
        }, () => {
          this.setState({
            isUpdatingFinancialYearStartMonth: false,
            updateFinancialYearStartMonthError: "Unable to update financial year starting month"
          });
        });
  },

  handleCancelFinancialYearStartMonthChangeRequest() {
    this.setState({
      financialYearStartMonth: this.getInitialState().financialYearStartMonth
    });
  },

  handleDefaultTimeframeIdChange(newDefaultTimeframeId) {
    this.setState({
      defaultTimeframeId: newDefaultTimeframeId,
      updateDefaultTimeframeIdError: null,
      updateDefaultTimeframeIdSuccess: null
    });
  },

  handleSaveDefaultTimeframeIdChangeRequest() {
    this.setState({
      isUpdatingDefaultTimeframeId: true
    });
    updateClient({timeframe: this.state.defaultTimeframeId})
        .then(result => {
          clientRepo.load();
          this.setState({
            isUpdatingDefaultTimeframeId: false,
            defaultTimeframeId: result.timeframe,
            updateDefaultTimeframeIdSuccess: "Default timeframe updated"
          });
        }, () => {
          this.setState({
            isUpdatingDefaultTimeframeId: false,
            updateDefaultTimeframeIdError: "Unable to update default timeframe"
          });
        });
  },

  handleCancelDefaultTimeframeIdChangeRequest() {
    this.setState({
      defaultTimeframeId: this.getInitialState().defaultTimeframeId
    });
  },

  handleToggleCustomCalendarStatusRequest() {
    this.setState({
      isActivatingCustomCalendar: true
    });
    if (this.state.isUsingCustomCalendar) {
      disableCustomCalendar()
          .then(() => {
            auditer.audit("client:custom-calendar-disabled");
            clientRepo.load();
            this.setState({
              isActivatingCustomCalendar: false,
              isUsingCustomCalendar: false
            });
          }, () => {
            this.setState({
              isActivatingCustomCalendar: false,
              isUsingCustomCalendar: true,
              activateCustomCalendarError: `Could not disable custom financial years. ${unexpectedErrorMsg}`
            });
          });
    } else {
      enableCustomCalendar()
          .then(() => {
            auditer.audit("client:custom-calendar-enabled");
            clientRepo.load();
            this.setState({
              isActivatingCustomCalendar: false,
              isUsingCustomCalendar: true
            });
          }, err => {
            const error = err.responseJSON;
            if (error.type === "MONTHLY_TARGETS_EXIST") {
              this.setState({
                isActivatingCustomCalendar: false,
                isUsingCustomCalendar: false,
                activateCustomCalendarError: error.message,
                showMonthlyTargetsDownloadButton: true
              });
            } else {
              this.setState({
                isActivatingCustomCalendar: false,
                isUsingCustomCalendar: false,
                activateCustomCalendarError: `Could not enable custom financial years. ${unexpectedErrorMsg}`
              });
            }
          });
    }
  }

});

const FinancialYearStart = pure(({
  startMonth,
  onChange,
  isActionsDisabled,
  isUpdating,
  onSaveChangeRequest,
  onCancelChangeRequest,
  error,
  success,
  onSuccessMessageTimeout
}) => (
    <div>
      <AdminHeader>
        Set the month your financial year starts on
      </AdminHeader>
      <div style={{margin: "1rem 0.5rem"}}>
        <div style={{display: "inline-block", textAlign: "center", width: 320, margin: "0 0.5rem"}}>
          <Select
              isMulti={false}
              isClearable={false}
              isSearchable={true}
              placeholder="Select month"
              selectedValue={startMonth}
              options={getMonthOptions()}
              onChange={onChange} />
        </div>
        <div style={{display: "inline-block", verticalAlign: "top", margin: "0 0.5rem"}}>
          <TextButton
              icon="history"
              label="Cancel"
              onClick={onCancelChangeRequest}
              disabled={isActionsDisabled}
              style={buttonStyle} />
          <TextButton
              icon="floppy-o"
              label={isUpdating ? <LoadingLabel text="Saving" /> : "Save"}
              type="primary"
              onClick={onSaveChangeRequest}
              disabled={isActionsDisabled || !!error}
              style={buttonStyle} />
        </div>
        <div style={{display: "inline-block", verticalAlign: "bottom", height: 36, margin: "0 0.5rem"}}>
          {error && <ErrorMsg text={error} style={statusMsgStyle} />}
          {success &&
          <SuccessMsg text={success} onMessageTimeout={onSuccessMessageTimeout} style={statusMsgStyle} />}
        </div>
      </div>
    </div>
));

const DefaultTimeframe = pure(({
  timeframes,
  timeframeId,
  onChange,
  isActionsDisabled,
  isUpdating,
  onSaveChangeRequest,
  onCancelChangeRequest,
  error,
  success,
  onSuccessMessageTimeout
}) => (
    <div>
      <AdminHeader>
        Set the default timeframe displayed in OneView
      </AdminHeader>
      <div style={{margin: "1rem 0.5rem"}}>
        <div style={{display: "inline-block", textAlign: "center", width: 320, margin: "0 0.5rem"}}>
          <Select
              isMulti={false}
              isClearable={false}
              isSearchable={timeframes.count() > 5}
              placeholder="Select timeframe"
              selectedValue={timeframeId.toLowerCase()}
              options={toTimeframeOptions(timeframes)}
              onChange={onChange} />
        </div>
        <div style={{display: "inline-block", verticalAlign: "top", margin: "0 0.5rem"}}>
          <TextButton
              icon="history"
              label="Cancel"
              onClick={onCancelChangeRequest}
              disabled={isActionsDisabled}
              style={buttonStyle} />
          <TextButton
              icon="floppy-o"
              label={isUpdating ? <LoadingLabel text="Saving" /> : "Save"}
              type="primary"
              onClick={onSaveChangeRequest}
              disabled={isActionsDisabled || !!error}
              style={buttonStyle} />
        </div>
        <div style={{display: "inline-block", verticalAlign: "bottom", height: 36, margin: "0 0.5rem"}}>
          {error && <ErrorMsg text={error} style={statusMsgStyle} />}
          {success &&
          <SuccessMsg text={success} onMessageTimeout={onSuccessMessageTimeout} style={statusMsgStyle} />}
        </div>
      </div>
    </div>
));

const enableCustomCalendarLabel = "Use custom financial years";
const EnablingCustomCalendarLabel = () => (
    <span>
        <span style={{paddingRight: 8}}>{enableCustomCalendarLabel}</span>
        <i className="fa fa-spinner fa-pulse fa-1x" />
    </span>
);

const EnableCustomCalendar = pure(({
  theme,
  isUsingCustomCalendar,
  onCheckboxClick,
  onDownloadMonthlyTargetsClick,
  isUpdating,
  error,
  showMonthlyTargetsDownloadButton
}) => (
    <div>
      <AdminHeader>
        Enable custom financial year structure
      </AdminHeader>
      <div style={{paddingBottom: "0.5rem"}}>
        <Hint style={{margin: "0 1rem"}}>
          <Icon icon="info" style={{color: theme.palette.hints.text}} />
          If your financial year follows a different structure from the standard Gregorian calendar, e.g. the 4/4/5 period structure, you can define a custom financial year that meets your needs.
        </Hint>
        <div style={{display: "inline-block", width: 276, margin: "0.25rem 1rem"}}>
          <Checkbox
              label={isUpdating ? <EnablingCustomCalendarLabel /> : enableCustomCalendarLabel}
              checked={isUsingCustomCalendar}
              onCheck={onCheckboxClick}
              disabled={isUpdating || !!error} />
        </div>
        {error &&
        <div style={{display: "inline-block", verticalAlign: "top", margin: "0.25rem 1rem"}}>
          <ErrorMsg text={error} style={statusMsgStyle} />
        </div>}
        <div style={{margin: "0.25rem 1rem"}}>
          {showMonthlyTargetsDownloadButton &&
          <TextButton
              icon="download"
              label="Current list of monthly targets"
              onClick={onDownloadMonthlyTargetsClick}
              style={{paddingLeft: "0.5rem", paddingRight: "0.5rem"}} />}
        </div>
      </div>
    </div>
));

const LoadingLabel = pure(({text}) => (
    <span>
        <i className="fa fa-circle-o-notch fa-spin fa-fw" />
        <span style={{paddingLeft: 5}}>{text}</span>
    </span>
));

const buttonStyle = {
  marginLeft: "0.5rem",
  marginRight: "0.5rem"
};

const statusMsgStyle = {
  display: "inline-block",
  margin: "0"
};


const currentUserCanAccessApp = app => currentClient.canAccessApp(app)
    && Users.canAccessApp(Users.getCurrentUser(), app);

const toTimeframeOptions = timeframes => timeframes
    .map(tf => Immutable.fromJS({
      label: tf.get("name"),
      value: tf.get("id")
    }));

const getMonthOptions = () => {
  const monthIndexes = Immutable.fromJS([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  return monthIndexes
      .map(i => moment().month(i).format("MMMM"))
      .map(month => Immutable
          .fromJS({
            label: month,
            value: month.toUpperCase()
          }));
};

const getTimeframes = () => Immutable
    .fromJS(timeframeRepo.getAll().toJSON())
    .filter(t => t.get("visible"));

const updateClient = changes => currentClient.save(changes, {wait: true});

const enableCustomCalendar = () => ajax.post({url: "clients/current/enable445"});

const disableCustomCalendar = () => ajax.post({url: "clients/current/disable445"});

const getMonthlyTargetsCsv = () => ajax.get({url: "kpi/target/monthly"});

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <TimeFrameSettings theme={theme} {...props} />;
};