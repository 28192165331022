import React from "react";
import pure from "js/common/views/pure";
import { Layout } from "js/common/views/foundation-column-layout";

import InteractiveTable from "js/common/views/tables/interactive-table";
import ErrorMsg from "js/common/views/error";
import Link from "js/admin/link";
import DealMusicUploader from "js/admin/deal-music-uploader";
import AudioPlayer from "js/admin/simple-audio-player";
import EntityStillAssignedError from "js/admin/entity-still-assigned-error";
import * as popups from "js/common/popups";
import * as dealMusicRepo from "js/common/repo/deal-music-repo";
import Checkbox from 'js/common/views/inputs/checkbox';
import {betterMemo} from "js/common/utils/more-memo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

export default betterMemo({displayName: "DealMusicPicker"}, ({
    selectedDealMusicId,
    onChange,
    onCloseRequest
}) => {
    const [uploaderOpen, setUploaderOpen] = React.useState(false);
    const [dealMusicOptions, setDealMusicOptions] = React.useState(dealMusicRepo.getAll());
    const [deletingDealMusicId, setDeletingDealMusicId] = React.useState(null);
    const [error, setError] = React.useState(null);
    const { theme } = React.useContext(CustomThemeContext);

    const onUploadDealMusicToggleClick = () => setUploaderOpen(uploaderOpen => !uploaderOpen);
    const onNewDealMusicUploaded = dealMusic => {
        setDealMusicOptions(dealMusicRepo.add(dealMusic));
        onChange(dealMusic.get("dealMusicId"));
    };
    const deleteDealMusic = dealMusicId => {
        setDeletingDealMusicId(dealMusicId);

        dealMusicRepo
            .remove(dealMusicId)
            .then(dealMusicOptions => {
                setDeletingDealMusicId(null);
                setDealMusicOptions(dealMusicOptions);
            }, e => {
                const errorsJSON = e.responseJSON;
                let error;
                if (errorsJSON.errors && errorsJSON.errors.error) {
                    error = <EntityStillAssignedError errors={errorsJSON.errors} />;
                } else {
                    error = "Unable to delete deal music.";
                }
                setDeletingDealMusicId(null);
                setError(error);
            });

    }
    const onDeleteClick = dealMusicId => {
        const dealMusic = dealMusicRepo.get(dealMusicId);
        popups.confirm(`Delete ${dealMusic.get("description")} permanently?`, {
            title: "Delete Deal Music",
            labels: {
                ok: "Yes"
            },
            onok: () => deleteDealMusic(dealMusicId)
        });
    };

    const columns = [{
        label: "Deal Music",
        width: "65%"
    },{
        label: "Preview",
        textAlign: "center"
    },{
        label: "Delete",
        textAlign: "center"
    }];
    const rows = dealMusicOptions.map(dealMusic => {
        const dealMusicId = dealMusic.get("dealMusicId");
        return [
            <Checkbox
                label={dealMusic.get("description")}
                labelStyle={{whiteSpace: "normal !important"}}
                checked={dealMusicId === selectedDealMusicId}
                onCheck={(e, isChecked) => onChange(isChecked ? dealMusicId : null)} />,
            <AudioPlayer source={dealMusic.get("url")} />,
            <div style={{ textAlign: "center" }}>
                {deletingDealMusicId === dealMusicId ?
                    <i className="fa fa-spinner fa-pulse fa-1x" /> :
                    <DeleteButton onClick={() => onDeleteClick(dealMusicId)} />}
            </div>
        ];
    });

    return (
        <Layout allSmall={12} rowStyle={{ paddingBottom: "0.5rem" }}>
            <div className="right" style={{ marginBottom: "0.5rem" }}>
                <Link theme={theme} label="Close" onClick={onCloseRequest} />
            </div>
            {error && <ErrorMsg text={error} />}
            <div>
                <InteractiveTable maxHeight={"240px"} columns={columns} rows={rows.toJS()} />
                <UploaderToggleButton
                    theme={theme}
                    label={uploaderOpen ? `Hide New Deal Music Uploader` : `Add New Deal Music`}
                    onClick={onUploadDealMusicToggleClick} />
            </div>
            {uploaderOpen && <DealMusicUploader onDealMusicUploaded={onNewDealMusicUploaded} />}
        </Layout>
    );
})

const DeleteButton = pure(({ onClick }) => (
    <i className="fa fa-times" style={deleteButtonStyle} onClick={onClick} />
));

const UploaderToggleButton = pure(({ theme, label, onClick }) => (
    <div style={uploaderToggleStyle(theme)} onClick={onClick}>{label}</div>
));

const deleteButtonStyle = {
    cursor: "pointer",
    fontSize: "1.25rem",
    color: "#f00",
    opacity: 0.7,
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",

    ":hover": {
        opacity: 1
    }
};

const uploaderToggleStyle = theme => ({
    cursor: "pointer",
    paddingTop: "0.75rem",
    paddingBottom: "0.75rem",
    textAlign: "center",
    color: theme.palette.text.primary,
    fontSize: "0.9rem",
    textTransform: "uppercase",
    backgroundColor: theme.palette.background.paper,

    ":hover": {
        backgroundColor: theme.palette.background.card,
        color: theme.palette.text.secondary
    }
});
