import React from "react";
import pure from "js/common/views/pure";

export default pure(({
	children,
	label,
    icon,
	style = {},
	labelStyle = {}
}) => (
    <div style={style}>
        <label style={labelStyle}>
            {icon && <i style={{ paddingRight:5 }} className={`fa fa-${icon}`} />}{label}
        </label>
        {children}
    </div>));
