import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import GetContainerDimensions from "react-dimensions";


import * as Kpis from "js/common/kpis";
import * as popups from "js/common/popups";
import {TextButton} from "js/common/views/inputs/buttons";
import RatioList from "js/admin/oneview-pages/ratio-list";
import SectionList from "js/admin/oneview-pages/section-list";
import * as ratioRepo from "js/common/repo/ratio-repo";
import RatioPicker from "js/common/views/inputs/ratio-picker";
import * as Sections from "js/common/sections";
import * as Pages from "js/common/pages";
import UsagesDialog from "js/admin/oneview-pages/usages-dialog";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Page = GetContainerDimensions()(createReactClass({

  propTypes: {
    onPageChange: ReactPropTypes.func.isRequired,
    page: ImmutablePropTypes.map.isRequired,
    theme: ReactPropTypes.object
  },

  getInitialState() {
    return {
      sections: this.props.page.get("sections"),
      ratioAssignments: this.props.page.get("ratioAssignments"),
      expandedSections: Immutable.Set(),
      displayUsagesDialog: false,
      loadedKpis: Immutable.List(),
      canCancel: false,
      canSave: false
    };
  },

  componentDidMount() {
    Kpis.loadKpis().then(response => {
      this.setState({loadedKpis: response});
    });
  },

  render() {
    const {sections, expandedSections, ratioAssignments, displayUsagesDialog, canCancel, canSave} = this.state;
    const {theme} = this.props;
    const buttonSpacing = {margin: "0.5em"};

    const smallScreenBreakpoint = 800;
    const isSmallScreen = this.props.containerWidth < smallScreenBreakpoint;
    const pageSegmentTitleStyle = theme =>({
      color: theme.palette.primary.main,
      fontWeight: "normal",
      padding: "1rem"
    });
    const assignedRatioIds = ratioAssignments.map(ra => ra.get("ratio").get("id")).toSet();
    const availableRatios = ratioRepo.getAll().toList().filter(r => !r.get("isDeleted")).filter(ratio => !assignedRatioIds.has(ratio.get("id")));

    const page = this.props.page;
    const groupsCount = page.get("usages").filter(usage => usage.get("qualifier") === "GROUP").count();
    const usersCount = page.get("usages").filter(usage => usage.get("qualifier") === "USER").count();
    const inUse = groupsCount > 0 || usersCount > 0;

    return (
        <div className="section-mgmt">
          <div className="row main-section-controls">
            <div className="small-12 column small-centered text-center"
                 style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
              <TextButton
                  icon="history"
                  label="Cancel"
                  disabled={!canCancel}
                  onClick={this.onResetChangesClick}
                  style={buttonSpacing} />
              <TextButton
                  icon="floppy-o"
                  label="Save"
                  disabled={!canSave}
                  onClick={this.onSaveChangesClick}
                  style={buttonSpacing} />
            </div>
          </div>

          {inUse &&
          <div style={{margin: "1rem", cursor: "pointer"}} onClick={() => this.setState({displayUsagesDialog: true})}>
            <i className="fa fa-info-circle" />
            <em style={{paddingLeft: 8, paddingRight: 8}}>In use by {groupsCount} Group(s) and {usersCount} User(s)</em>

          </div>}

          {displayUsagesDialog &&
          <UsagesDialog
              theme={theme}
              page={page}
              onRequestClose={() => this.setState({displayUsagesDialog: false})} />}

          <h2 style={pageSegmentTitleStyle(theme)}>Sections</h2>
          <div className="small-12 column"
               style={{marginBottom: "0.5rem"}}>
            <TextButton
                icon="plus"
                label="Add Section"
                onClick={this.addSection}
                style={buttonSpacing} />
          </div>

          <div className="row">
            <div className="small-12 column">
              <SectionList
                  loadedKpis={this.state.loadedKpis}
                  sections={sections.filter(section => !section.get("toBeDeleted"))}
                  onDeleteSectionClick={this.deleteSection}
                  onSectionChange={this.updateSection}
                  onMoveSectionDownClick={this.moveSectionDown}
                  onMoveSectionUpClick={this.moveSectionUp}
                  onSectionExpandToggle={this.toggleExpandState}
                  isSmallScreen={isSmallScreen}
                  expandedSections={expandedSections}
              />
            </div>
          </div>

          <h2 style={pageSegmentTitleStyle(theme)}>Ratios</h2>

          <div className="row" style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
            <div className="small-12 medium-6 columns">
              <RatioPicker
                  label="Add Ratio"
                  ratios={availableRatios}
                  onRatioSelect={this.addRatio}
                  closeOnSelect={false} />
            </div>
          </div>

          <div className="row">
            <div className="small-12 column">
              <RatioList
                  ratioAssignments={ratioAssignments}
                  onDeleteRatioClick={this.deleteRatio}
                  onMoveRatioDownClick={this.moveRatioDown}
                  onMoveRatioUpClick={this.moveRatioUp} />
            </div>
          </div>

        </div>
    );
  },

  onSaveChangesClick() {
    const {sections, ratioAssignments} = this.state;
    const sectionsHaveErrors = sections.some(section => !section.get("toBeDeleted") && section.hasErrors);
    if (sectionsHaveErrors) {
      popups.error("Please fix the highlighted errors");
      return;
    }

    const sectionPromises = sections
        .map(section => {
          if (section.get("toBeDeleted")) {
            return Sections.del(section.get("id"));
          } else if (section.has("id")) {
            return Sections.update(section
                .update("assignedKpis", assignedKpis => assignedKpis.map(assignedKpi => assignedKpi.remove("cid"))));
          } else {
            return Sections.create(section.remove("cid")
                .update("assignedKpis", assignedKpis => assignedKpis.map(assignedKpi => assignedKpi.remove("cid"))));
          }
        }).toJS();
    Promise.all(sectionPromises)
        .then(() => Pages.update(this.props.page.set("ratioAssignments", ratioAssignments.map(ratioAssignment => ratioAssignment.remove("cid")))))
        .then(updatedPage => {
          this.props.onPageChange(updatedPage);
          this.setState({
            sections: updatedPage.get("sections"),
            ratioAssignments: updatedPage.get("ratioAssignments").sortBy(assignment => assignment.get("order")),
            canCancel: false,
            canSave: false
          });
          popups.changesSaved();
        });
  },

  onResetChangesClick() {
    this.setState({
      canSave: true,
      canCancel: false,
      sections: this.props.page.get("sections"),
      ratioAssignments: this.props.page.get("ratioAssignments")
    });
    popups.changesReset();
  },

  toggleExpandState(sectionId) {
    this.setState((state) => {
      const expandedSections = state.expandedSections;
      return {
        expandedSections: expandedSections.has(sectionId) ?
            expandedSections.remove(sectionId) :
            expandedSections.add(sectionId)
      };
    });
  },

  addSection() {
    this.setState((state) => {
      const sections = state.sections;
      const order = sections.count() === 0 ? 0 : sections.map(section => section.get("order")).max() + 1;
      const cid = Math.random();
      return {
        sections: sections.push(Immutable.fromJS({
          pageId: this.props.page.get("id"),
          name: "",
          visible: true,
          assignedKpis: [],
          order,
          cid
        })),
        expandedSections: state.expandedSections.add(cid),
        canCancel: true,
        canSave: true
      };
    });
  },

  deleteSection(id) {
    const sections = this.state.sections;
    const index = sections.findIndex(section => section.has("id") ? section.get("id") === id :
        section.get("cid") === id);
    if (sections.get(index).has("id")) {
      this.setState((state) => {
        return {
          sections: state.sections.update(index, section => section.set("toBeDeleted", true)),
          expandedSections: state.expandedSections.remove(state.sections.get(index).get("id")),
          canCancel: true,
          canSave: true
        };
      });
    } else {
      this.setState((state) => {
        return {
          sections: state.sections.remove(index),
          expandedSections: state.expandedSections.remove(state.sections.get(index).get("cid")),
          canCancel: true,
          canSave: true
        };
      });
    }
  },

  updateSection(id, section) {
    const sections = this.state.sections;
    const index = sections.findIndex(section => section.has("id") ? section.get("id") === id :
        section.get("cid") === id);
    this.setState({
      sections: sections.set(index, section),
      canCancel: true,
      canSave: true
    });
  },

  moveSectionUp(id) {
    const sections = this.state.sections;
    const index = sections.findIndex(section => section.has("id") ? section.get("id") === id :
        section.get("cid") === id);
    const sectionToMove = sections.get(index);
    const indexToSwapWith = sections.slice(0, index).findLastIndex(section => !section.get("toBeDeleted"));
    if (indexToSwapWith >= 0) {
      const sectionToSwapWith = sections.get(indexToSwapWith);
      this.setState({
        canCancel: true,
        canSave: true,
        sections: sections
            .set(indexToSwapWith, sectionToMove.set("order", sectionToSwapWith.get("order")))
            .set(index, sectionToSwapWith.set("order", sectionToMove.get("order")))
      });
    }
  },

  moveSectionDown(id) {
    const sections = this.state.sections;
    const index = sections.findIndex(section => section.has("id") ? section.get("id") === id :
        section.get("cid") === id);
    const sectionToMove = sections.get(index);
    const indexToSwapWith = index + 1 + sections.slice(index + 1)
        .findIndex(section => !section.get("toBeDeleted"));
    if (indexToSwapWith > index) {
      const sectionToSwapWith = sections.get(indexToSwapWith);
      this.setState({
        canCancel: true,
        canSave: true,
        sections: sections
            .set(indexToSwapWith, sectionToMove.set("order", sectionToSwapWith.get("order")))
            .set(index, sectionToSwapWith.set("order", sectionToMove.get("order")))
      });
    }
  },

  addRatio(ratio) {
    const ratioAssignments = this.state.ratioAssignments;
    const order = ratioAssignments.count() === 0 ? 0 : ratioAssignments.map(ratio => ratio.get("order")).max() + 1;
    const cid = Math.random();
    this.setState({
      canCancel: true,
      canSave: true,
      ratioAssignments: ratioAssignments.push(Immutable.fromJS({
        cid,
        ratio,
        order
      })).sortBy(assignment => assignment.get("order"))
    });
  },

  deleteRatio(id) {
    const ratios = this.state.ratioAssignments;
    const index = ratios.findIndex(assignment => {
      const ratio = assignment.get("ratio");
      return ratio.has("id") ? ratio.get("id") === id :
          ratio.get("cid") === id
    });
    this.setState({
      canCancel: true,
      canSave: true,
      ratioAssignments: this.state.ratioAssignments.remove(index).sortBy(assignment => assignment.get("order"))
    });
  },

  moveRatioUp(id) {
    const ratios = this.state.ratioAssignments;
    const index = ratios.findIndex(assignment => {
      const ratio = assignment.get("ratio");
      return ratio.has("id") ? ratio.get("id") === id :
          ratio.get("cid") === id
    });
    if (index > 0) {
      const ratioAssignments = this.state.ratioAssignments;
      const ratioToMove = ratioAssignments.get(index);
      const ratioToSwapWith = ratioAssignments.get(index - 1);
      this.setState({
        canCancel: true,
        canSave: true,
        ratioAssignments: ratioAssignments
            .set(index - 1, ratioToMove.set("order", ratioToSwapWith.get("order")))
            .set(index, ratioToSwapWith.set("order", ratioToMove.get("order")))
            .sortBy(assignment => assignment.get("order"))
      });
    }
  },

  moveRatioDown(id) {
    const ratios = this.state.ratioAssignments;
    const index = ratios.findIndex(assignment => {
      const ratio = assignment.get("ratio");
      return ratio.has("id") ? ratio.get("id") === id :
          ratio.get("cid") === id
    });
    if (index < ratios.count() - 1) {
      const ratioToMove = ratios.get(index);
      const ratioToSwapWith = ratios.get(index + 1);
      this.setState({
        canCancel: true,
        canSave: true,
        ratioAssignments: ratios
            .set(index + 1, ratioToMove.set("order", ratioToSwapWith.get("order")))
            .set(index, ratioToSwapWith.set("order", ratioToMove.get("order")))
            .sortBy(assignment => assignment.get("order"))
      });
    }
  }

}));

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <Page theme={theme} {...props} />;
};