import React from "react";
import * as Immutable from "immutable";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";

import {betterMemo} from "js/common/utils/more-memo";
import {indexBy} from "js/common/utils/collections";
import * as Sections from "js/common/sections";
import KpiPicker from "js/common/views/kpis/custom-kpi-picker";
import KpiAssignmentList from "js/admin/oneview-pages/kpi-assignment-list";
import {IconButton} from "js/common/views/inputs/buttons";
import Error from "js/common/views/error";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const sectionListItemStyle = theme =>({
  listStyle: "none",
  borderRadius: 3,
  backgroundColor: theme.themeId === "light" ? theme.palette.background.paper : "#252525",
  border: "1px solid rgba(255, 255, 255, 0.21)",
  marginBottom: "1.25rem",
  padding: "0.625rem"
});

const Section = betterMemo(
    {displayName: "Section"},
    ({
      loadedKpis,
      section,
      onMoveSectionDownClick,
      onMoveSectionUpClick,
      onChange,
      onDeleteSectionClick,
      onSectionExpandToggle,
      isSmallScreen,
      isExpanded
    }) => {

      const { theme } = React.useContext(CustomThemeContext);
      const nameIsBlank = !section.get("name");
      const nameInputError = nameIsBlank && <Error text="This section needs a name" />;

      const hasNoKpis = section.get("assignedKpis").count() === 0;
      const kpiAssignmentsError = hasNoKpis && <Error text="This section needs at least 1 Metric" />;

      const selectedKpiIds = section.get("assignedKpis").map(assignedKpi => assignedKpi.get("kpiId")).toSet();
      const selectableKpis = loadedKpis.filter(kpi => kpi.get("visible") && !selectedKpiIds.contains(kpi.get("id")));

      section.hasErrors = nameIsBlank || hasNoKpis;

      const updateSectionName = React.useCallback((name) => {
        onChange(section.set("name", name.substring(0, Sections.MAX_NAME_LENGTH)));
      }, [onChange, section]);

      const onToggleSectionVisibilityClick = React.useCallback(() => {
        onChange(section.update("visible", visible => !visible));
      }, [onChange, section]);

      const reorderKpiAssignments = React.useCallback((kpiAssignments) => {
        const newKpiAssignments = kpiAssignments.map((kpiAssignment, index) => kpiAssignment.set("order", index));
        onChange(section.set("assignedKpis", newKpiAssignments));
      }, [onChange, section]);

      const deleteKpiAssignment = React.useCallback((index) => {
        const updatedSection = section
            .update("assignedKpis", assignedKpis =>
                assignedKpis.remove(index)
            ).update("assignedKpis", assignedKpis => assignMissingMinimisedOrders(assignedKpis));
        onChange(updatedSection);
      }, [onChange, section]);

      const handleMinimisedOrderChange = React.useCallback((index, newOrder) => {
        let assignedKpis = section.get("assignedKpis");
        const oldIndexWithOrder = assignedKpis.findIndex(kpi => kpi.get("minimisedOrder") === newOrder);
        if (oldIndexWithOrder > -1) {
          assignedKpis = assignedKpis.update(oldIndexWithOrder, kpi => kpi.set("minimisedOrder", null));
        }
        assignedKpis = assignedKpis.update(index, kpi => kpi.set("minimisedOrder", newOrder));
        const updatedSection = section
            .set("assignedKpis", assignMissingMinimisedOrders(assignedKpis));
        onChange(updatedSection);
      }, [onChange, section]);

      const handleKpiSelected = React.useCallback((kpiId) => {
        const existingKpis = section.get("assignedKpis");
        const order = existingKpis.count() === 0 ? 0 : existingKpis.map(kpi => kpi.get("order")).max() + 1;
        const updatedSection = section
            .update("assignedKpis", assignedKpis => assignedKpis.push(Immutable.fromJS({
              cid: Math.random(),
              kpiId: kpiId,
              order: order,
              minimisedOrder: null
            })))
            .update("assignedKpis", assignedKpis => assignMissingMinimisedOrders(assignedKpis));
        onChange(updatedSection);
      }, [onChange, section]);

      return (
        <li style={sectionListItemStyle(theme)}>
          <div className="row">
            <div className="medium-6 columns">
              <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                <IconButton
                    size="small"
                    icon={isExpanded ? "angle-up" : "angle-down"}
                    onClick={() => onSectionExpandToggle(section.get("id") || section.get("cid"))}
                    customStyle={{display: "inline-block", fontSize: "1.5rem", marginRight: "1rem"}} />
                <SimpleTextInput
                    placeholder="Display Name"
                    value={section.get("name")}
                    onChange={updateSectionName}
                    customStyle={{marginTop: 2}} />
              </div>
              {nameInputError}
            </div>

            <div className="medium-3 columns">
              <div className="right">
                <IconButton style={{color: theme.themeId === "light" ? "#a1a1a1": "#717171"}} icon="arrow-down" onClick={onMoveSectionDownClick} size="large" />
                <IconButton style={{color: theme.themeId === "light" ? "#a1a1a1": "#717171"}} icon="arrow-up" onClick={onMoveSectionUpClick} size="large" />
                <IconButton
                  icon={section.get("visible") ? "eye" : "eye-slash"}
                  type={section.get("visible") ? "success" : "alert"}
                  style={{color: "green"}}
                  onClick={onToggleSectionVisibilityClick}
                  size="large" />
                <IconButton
                  hoverType="alert"
                  style={{color: theme.themeId === "light" ? "#a1a1a1": "#717171"}}
                  icon="trash"
                  onClick={onDeleteSectionClick}
                  size="large" />
              </div>
            </div>
          </div>

          <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
            {isExpanded &&
            <div style={{marginTop: "1rem"}}>
              <div className="row">
                <div className="small-12 medium-6 columns">
                  <KpiPicker
                      label="Add Metric(s)"
                      kpis={selectableKpis}
                      onKpiSelect={handleKpiSelected}
                      closeOnSelect={false}
                      isKpiReselectable={false} />
                </div>
              </div>
              <div className="row">
                <div className="medium-12 columns" style={{marginTop: "0.625rem"}}>
                  <KpiAssignmentList
                      kpiById={indexBy(k => k.get("id"), loadedKpis)}
                      kpiAssignments={section.get("assignedKpis")}
                      onDeleteClick={deleteKpiAssignment}
                      onMinimisedOrderChange={handleMinimisedOrderChange}
                      onResorted={reorderKpiAssignments}
                      isSmallScreen={isSmallScreen} />
                  {kpiAssignmentsError}
                </div>
              </div>
            </div>}
          </VelocityTransitionGroup>
        </li>
      );

    });

const assignMissingMinimisedOrders = assignedKpis => {
  const assignedOrders = assignedKpis.map(kpi => kpi.get("minimisedOrder"))
      .filter(order => order !== null);
  const ordersToAssign = Immutable.List([0, 1, 2, 3]).filter(order => !assignedOrders.includes(order));
  let returnKpis = assignedKpis;
  ordersToAssign.forEach(order => {
    const firstAvailableIndex = returnKpis.findIndex(kpi => kpi.get("minimisedOrder") === null);
    if (firstAvailableIndex > -1) {
      returnKpis = returnKpis.update(
          returnKpis.findIndex(kpi => kpi.get("minimisedOrder") === null),
          kpi => kpi.set("minimisedOrder", order));
    } else {
      return false;
    }

  });
  return returnKpis;
};

export default Section;