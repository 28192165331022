import React from "react";
import {getSmoothStepPath, Position, getMarkerEnd, getEdgeCenter} from "react-flow-renderer";

import CustomEdgeLabel from "js/squids/squid-display/custom-edge-label";

export default function CustomEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  label,
  labelStyle,
  labelShowBg,
  labelBgStyle,
  labelBgPadding,
  labelBgBorderRadius,
  style,
  sourcePosition = Position.Bottom,
  targetPosition = Position.Top,
  arrowHeadType,
  markerEndId,
  borderRadius = 0,
  ref
}) {

  const path = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius
  });

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const [centerX] = getEdgeCenter({sourceX, sourceY, targetX, targetY});

  React.useEffect(() => {
    // This whole function is needed to do nice edge highlighting, because SVG renders
    // the last child as the highest z-index we need to shuffle the else so that the active one is last
    // without out this we have active edge lines vanishing when they overlap with other edges
    // we cannot (atm) re-order the list of edge before they are rendered in auto mode
    // as such it will just re-render the nodes and make them jump about
    const activeEdge = document.getElementsByClassName("edge-active")[0];
    if (activeEdge) {
      activeEdge.parentNode.appendChild(activeEdge);
    }
  }, []);

  const text = label ? (
      <CustomEdgeLabel
          x={centerX}
          y={targetY}
          label={label.text}
          isErroring={label.isErroring}
          labelStyle={labelStyle}
          labelShowBg={labelShowBg}
          labelBgStyle={labelBgStyle}
          labelBgPadding={labelBgPadding}
          labelBgBorderRadius={labelBgBorderRadius}
      />
  ) : null;
  return (
      <>
        <path style={style} ref={ref} className="react-flow__edge-path" d={path} markerEnd={markerEnd} />
        {text}
      </>
  );
};