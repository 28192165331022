import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import moment from "moment";

import { TextButton } from "js/common/views/inputs/buttons";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import Layout from "js/common/views/foundation-column-layout";
import Err from "js/common/views/error";
import Dialog from "js/common/views/dialog";
import Checkbox from "js/common/views/inputs/checkbox";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const DeactivateUserDialog = createReactClass({

    propTypes: {
        onDeactivateClick: ReactPropTypes.func.isRequired,
        onCancelClick:  ReactPropTypes.func.isRequired,
        isOpen: ReactPropTypes.bool.isRequired,
        theme: ReactPropTypes.object
    },

    getInitialState() {
        return {
            leavingDate: moment(),
            shouldEndTargets: true,
            shouldEndGroups: true,
            shouldEndTags: true,
            errorText: ""
        };
    },

    render() {
        const {
          leavingDate,
          shouldEndTargets,
          shouldEndGroups,
          shouldEndTags
        } = this.state;
        const TODAY = moment();
        const isDateValid = leavingDate.isValid() && !leavingDate.isAfter(TODAY);
        const { theme } = this.props;
        const actions = [
            <TextButton
                type="default"
                key="cancel"
                label="Cancel"
                style={{marginRight:8}}
                onClick={this.handleCancelClick} />,
            <TextButton
                type="primary"
                key="deactivate"
                label="Deactivate"
                style={{marginRight:8}}
                disabled={!isDateValid}
                onClick={this.handleSaveClick} />];
        return (
            <Dialog
                paperStyle={{overflow: "visible", backgroundColor: theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.paper}}
                actionsContainerStyle={{paddingRight: "2rem"}}
                title="Deactivate User"
                actions={actions}
                autoDetectWindowHeight={true}
                titleStyle={{ color: theme.palette.primary.main, fontSize: "1rem" }}
                open={this.props.isOpen}>
                <Layout small={[6, 12, 12, 12]}>
                    <div className={`picker-${theme.themeId}`} >
                        <DatePicker
                            style={{...boxStyle}}
                            hideError={true}
                            maxDate={TODAY}
                            onDateChange={leavingDate => this.setState({ leavingDate })}
                            value={this.state.leavingDate} />
                        {!isDateValid && <Err text="User's leaving date must be today or earlier" />}
                        <br />
                    </div>
                    <Checkbox
                        label="End Targets"
                        checked={shouldEndTargets}
                        style={{ height: 30, width: 210 }}
                        onCheck={(e, shouldEndTargets) => this.setState({ shouldEndTargets })} />
                    <Checkbox
                        label="End Group Membership"
                        checked={shouldEndGroups}
                        style={{ height: 30, width: 210 }}
                        onCheck={(e, shouldEndGroups) => this.setState({ shouldEndGroups })} />
                    <Checkbox
                        label="End Tag Membership"
                        checked={shouldEndTags}
                        style={{ height: 30, width: 210 }}
                        onCheck={(e, shouldEndTags) => this.setState({ shouldEndTags })} />
                </Layout>
            </Dialog>);
    },

    handleCancelClick() {
        this.setState(this.getInitialState());
        this.props.onCancelClick();
    },

    handleSaveClick() {
        this.props.onDeactivateClick({
            leavingDate: this.state.leavingDate,
            shouldEndTargets: this.state.shouldEndTargets,
            shouldEndGroups: this.state.shouldEndGroups,
            shouldEndTags: this.state.shouldEndTags
        });
    }

});

const boxStyle = {
    border: "none",
    boxShadow: "rgb(0 0 0 / 20%) 0px -1px 3px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px"
}

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <DeactivateUserDialog theme={theme} {...props} />;
};