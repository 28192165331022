import React from "react";
import pure from "js/common/views/pure";

export default pure(({ trail, dividerIcon = "chevron-right", dividerColor = "#fff" }) => {
    const dividerStyle = {
        color: dividerColor,
        paddingLeft: 5,
        paddingRight: 5
    };
    return (
        <span>
            {trail.map((crumb, i) => {
                const isLastCrumb = i === trail.count() - 1;
                return (
                    <span key={i}>
                        {crumb}
                        {!isLastCrumb && <i className={`fa fa-${dividerIcon}`} style={dividerStyle} />}
                    </span>
                );
            })}
        </span>
    );
});
