import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const positionStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: 9999
};

const overlayStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "black",
  opacity: 0.5
};

const contentStyle = theme => ({
  position: "fixed",
  left: "45%",
  top: "40vh",
  color: theme.palette.text.primary,
  textAlign: "center",
  verticalAlign: "middle",
  horizontalAlign: "middle",
  display: "table-cell",
  zIndex: 99999
});

const Overlay = React.memo(({content}) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <div style={positionStyle}>
    <div style={overlayStyle} />
    <div style={contentStyle(theme)}>{content}</div>
  </div>
});

export default Overlay;

