import React from "react";
import pure from "js/common/views/pure";

export default pure(({
  value = "",
  onChange,
  customStyle = {},
  max = 8192
}) => {
  const charCount = value.length;
  const isNearMaxCharLimit = (max - charCount) <= 100;
  const isOverMaxCharLimit = (max - charCount) < 0;
  const charLimitWarningStyle = {
    paddingBottom: "0.5rem",
    fontSize: "0.9rem",
    color: isOverMaxCharLimit ? "#f22" : "#fff"
  };
  return (
      <div>
            <textarea
                style={{...textareaStyle, ...customStyle}}
                placeholder="Add a note..."
                value={value}
                onChange={e => onChange(e.target.value)} />
        {isNearMaxCharLimit &&
        <div className="right" style={charLimitWarningStyle}>
          {max - value.length} characters left
        </div>}
      </div>
  );
});

const textareaStyle = {
  resize: "vertical",
  minHeight: 75,
  display: "block",
  width: "100%",
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  padding: "0.5rem",
  backgroundColor: "#272731",
  border: "none",
  borderRadius: "3px",
  boxShadow: "rgb(0 0 0 / 20%) 0px -1px 3px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px",
  transition: "box-shadow 0.45s, border-color 0.45s ease-in-out",
  fontSize: "0.9rem",
  color: "#fff",

  ":hover": {
    borderColor: "#24455f"
  },

  ":focus": {
    borderColor: "#4f728e",
    boxShadow: "0 0 5px #999999",
    outline: "none"
  }
};
