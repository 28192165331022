import React from "react";

import * as DashboardUtils from "js/dashboards/utils";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const containerZIndex = 1001;
const overlayZIndex = 1002;
const contentZIndex = 1003;

const positionStyle = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%"
};

const contentContainerStyle = {
  padding: "0.5rem"
};

const centeredStyle = {
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  justifyContent: "center"
};

const Overlay = React.memo(({style = {}}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const overlayStyle = {
    ...positionStyle,
    backgroundColor: theme.palette.background.card,
    zIndex: overlayZIndex,
    ...style
  };
  return <div style={overlayStyle} />;
});

export const Message = React.memo(({
  onClick = () => {},
  containerStyle = {},
  overlayStyle = {},
  children,
  coverOtherContent = true
}) => {
  const finalContainerStyle = {
    ...positionStyle,
    zIndex: coverOtherContent ? containerZIndex : null,
    ...centeredStyle,
    ...containerStyle
  };
  return <div style={finalContainerStyle} onClick={onClick}>
    {coverOtherContent && <Overlay style={overlayStyle} />}
    <div style={{
      ...contentContainerStyle,
      zIndex: coverOtherContent ? contentZIndex : null
    }}>
      {children}
    </div>
  </div>;
});

export const Error = React.memo(({
  containerStyle = {},
  overlayStyle = {},
  onClick = () => {},
  children,
  coverOtherContent = true
}) => {
  const finalContainerStyle = {
    ...positionStyle,
    zIndex: coverOtherContent ? containerZIndex : null,
    ...centeredStyle,
    ...containerStyle
  };
  return <div style={finalContainerStyle} onClick={onClick} data-test-id="dashboard-error">
    {coverOtherContent && <Overlay style={overlayStyle} />}
    <div style={{
      ...contentContainerStyle,
      zIndex: coverOtherContent ? contentZIndex : null
    }}>
      {children}
    </div>
  </div>;
});

export const Loading = React.memo(({
  onClick = () => {},
  children,
  containerStyle = {},
  overlayStyle = {},
  coverOtherContent = true
}) => {
  const finalContainerStyle = {
    ...positionStyle,
    zIndex: coverOtherContent ? containerZIndex : null,
    ...centeredStyle,
    ...containerStyle
  };
  return <div style={finalContainerStyle} onClick={onClick}>
    {coverOtherContent && <Overlay style={overlayStyle} />}
    <div style={{
      ...contentContainerStyle,
      zIndex: coverOtherContent ? contentZIndex : null
    }}>
      {/*{children ? children : <span>Loading</span>}*/}
      {children ? children : <DashboardUtils.LoadingBalls />}
    </div>
  </div>;
});