import React from "react";

import InvalidStatePage from "js/invalid-state/invalid-state-page";
import SwitchingUserPicker from "js/common/views/switching-user/switching-user-picker";
import LoadingSpinner from "js/common/views/loading-spinner";
import Drawer from "js/common/views/drawer";
import useMountEffect from "js/common/utils/use-mount-effect";
import {TextButton} from "js/common/views/inputs/buttons";
import {showLogoutButton} from "js/login/bullhorn-sso";
import * as Auth from "js/common/auth";
import * as Users from "js/common/users";
import * as Branding from "js/common/branding-constants";

import Cube19 from "js/cube19.app";

const OrphanUserPageContent = () => {
  const [switchingUsersDialogIsOpen, setSwitchingUsersDialogIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // Ensure that users are loaded
  useMountEffect(() => {
    if (!Users.getCurrentUser() || !Users?.getUsers().length > 0) {
      setLoading(true);
      Promise
          .all([
            Cube19.request("users:load"),
            Cube19.request("user:load-current"),
            Cube19.request("user:load-source")
          ])
          .finally(() => setLoading(false));
    }
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  const hasUserSwitching = Users.getSourceUser()?.get("targetUserIds")?.length > 0;
  return (
      <>
        <p>
          Ask one of the {Branding.brandingName} admins in your company to assign you to a group.
          <br />
          They will find you in the &quot;<strong>Orphan Users</strong>&quot; tab on the User admin page.
          <br />
          {hasUserSwitching
              ? <>You can login as another user to continue using {Branding.brandingName}.</>
              : <>Until this is done you will not be able to access {Branding.brandingName}.</>}
        </p>

        {showLogoutButton() &&
            <TextButton label="Back to Login" onClick={Auth.logout} style={{marginRight: "1rem"}} />}
        {hasUserSwitching &&
            <TextButton
                label={"Change User"}
                type={"primary"}
                onClick={() => setSwitchingUsersDialogIsOpen(true)} />}

        <Drawer
            open={switchingUsersDialogIsOpen}
            onRequestClose={() => setSwitchingUsersDialogIsOpen(false)}
            showCloseButton={true}
            openRight={true}
            width={598}
            title="Choose A User To Log In As">
          <SwitchingUserPicker listStyle={{maxHeight: "75vh", overflow: "scroll"}} />
        </Drawer>
      </>
  );
};

const OrphanUserPage = () => <InvalidStatePage
    heading={"Orphan User"}
    subheading={"You are not currently assigned to a group"}
    content={<OrphanUserPageContent />} />;

export default OrphanUserPage;