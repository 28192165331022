
export const getQuarterDto = (date, startMonth) => {
    let nextYear = null;
    let adjustedDate;
    if (startMonth > 1) {
        adjustedDate = date.clone().subtract(startMonth - 1, "months");
        nextYear = adjustedDate.clone().add(1, "years");
    } else {
        adjustedDate = date;
    }

    const financialCalendarIndex = getFinancialCalendar(startMonth).indexOf(date.month());
    const monthsIntoQuarter = financialCalendarIndex % 3;
    const startOfQuarter = date.clone().startOf("month").subtract(monthsIntoQuarter, "months");

    let result = {};
    result.quarter = Math.ceil((adjustedDate.month() + 1.0) / 3.0);
    result.year = adjustedDate.year();
    result.nextYear = (nextYear) ? nextYear.year() : nextYear;
    result.start = startOfQuarter;
    result.end = startOfQuarter.clone().add(3, "months").subtract(1, "day");
    result.toString = function() {
        const str = `${result.quarter} ${result.year}`;
        return nextYear ? `${str}/${nextYear.format("YY")}` : str;
    };

    return result;
};

const getFinancialCalendar = startMonth => {
    if (startMonth < 0) {
        startMonth = 13 + startMonth;
    }
    startMonth--;
    let months = [];
    for (let i = 0; i < 12; i++) {
        months.push(startMonth);
        startMonth++;
        if (startMonth > 11) {
            startMonth = 0;
        }
    }
    return months;
};
