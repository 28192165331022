import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const CloseButton = React.memo(({ onClick, style }) => {
	const { theme } = React.useContext(CustomThemeContext);
	return (
			<i className="bhi-times" title="Close" style={{...baseStyle(theme), ...style}} onClick={onClick}/>
	)
}, );

const baseStyle = theme => ({
	cursor: "pointer",
	color: theme.palette.text.main,
	paddingTop: 2,
	paddingRight: 3,
	paddingBottom: 2,
	paddingLeft: 3,
	backgroundColor: "transparent",
	transition: "all 0.3s ease",
});

CloseButton.displayName = "CloseButton";
export default CloseButton;
