import Immutable from "immutable";
import React from "react";
import {evaluate as evaluateMathStr, typeOf} from "mathjs";

import {indexBy} from "js/common/utils/collections";
import {formatResult, applyArgsToFormula} from "js/common/formulas/utils";
import * as String from "js/common/utils/strings";

import kpiLibFormulas from "js/common/formulas/kpi-lib";
import nodeLibFormulas from "js/common/formulas/node-lib";
import edgeLibFormulas from "js/common/formulas/edge-lib";
import showWorkingLibFormulas from "js/common/formulas/show-working-lib";

import Tooltip from "react-tooltip";
import {cleanup, visitFormula} from "js/common/formulas/preventing-loops";

const idToLibFormula = indexBy(
    x => x.get("id"),
    kpiLibFormulas
        .concat(nodeLibFormulas)
        .concat(edgeLibFormulas)
        .concat(showWorkingLibFormulas));

const makeStatefulCallPattern = () => /\$\(.*?\)/g;
const statelessCallPattern = makeStatefulCallPattern();

const parseFormulaCallStr = callStr => {
  // NOTE idea for future: allow custom stuff between $ and (
  // could be used for inline formatting overrides
  // e.g. $formatAs=NUMBER(some-formula 123)
  // query string versus json data?
  //   query string easier to embed in a string
  //   json allows us to do anything
  //   how about supporting both?
  const stripped = callStr.substring(2, callStr.length - 1);
  const [callId, ...callArgs] = stripped.split(" ");
  return {callId, callArgs: new Immutable.List(callArgs)};
};

const mergeFormats = (format1, format2) => {
  if (format1 === "CURRENCY" || format2 === "CURRENCY") {
    return "CURRENCY";
  } else if (format1 === "NUMBER" || format2 === "NUMBER") {
    return "NUMBER";
  } else {
    return format1;
  }
};

const addErrorLocation = (errors, category, location) => errors.map(error => {
  if (error.get("category") === "internal-error" && error.get("type") !== "lib-formula-error") {
    return error
        .set("category", category)
        .set("location", Immutable.fromJS(location));
  } else {
    return error;
  }
});

const evaluateFormulaStr = (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, formulaStr) => {
  context = context || new Immutable.Map();
  formulaStr = formulaStr || "0";

  let overallCurrency = null;
  let overallFormatAs = null;
  let errors = Immutable.Set();
  const readyForMathsLib = formulaStr.replace(statelessCallPattern, callStr => {
    const {callId, callArgs} = parseFormulaCallStr(callStr);
    try {
      const {
        result,
        errors: formulaIdErrors
      } = evaluateFormulaId(context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs);
      if (formulaIdErrors) {
        errors = errors.union(formulaIdErrors);
      }
      const currency = result.get("currency");
      if (currency && overallCurrency && overallCurrency !== currency) {
        throw new Error("mismatched currencies (" + overallCurrency + " vs " + currency + ") in formula " + formulaStr);
      } else if (currency) {
        overallCurrency = currency;
      }

      if (result.get("formatAs") === "STRING") {
        throw new Error(
            "cannot evaluate a formula with STRING output (e.g. `show-working`), such formulas can only be used in template strings");
      }
      overallFormatAs = mergeFormats(result.get("formatAs"), overallFormatAs);
      return result.get("value");
    } catch (e) {
      errors = errors.add(Immutable.Map({
        category: "internal-error",
        type: "Invalid formula string",
        formulaId: callId,
        message: e.message
      }));
      return 0;
    }
  });

  try {
    const numberResult = evaluateMathStr(readyForMathsLib);

    if (typeOf(numberResult) === "Unit") {
      throw new Error("Invalid formula string");
    }

    if (overallCurrency) {
      return {
        result: Immutable.Map({
          formatAs: overallFormatAs || "CURRENCY",
          value: numberResult,
          currency: overallCurrency
        }), errors: errors
      };
    } else {
      return {result: Immutable.Map({formatAs: overallFormatAs || "NUMBER", value: numberResult}), errors: errors};
    }
  } catch (e) {
    errors = errors.add(Immutable.Map({
      category: "internal-error",
      type: "Invalid formula string",
      formulaId: formulaStr,
      message: "failed to evaluate to number"
    }));
    return {result: Immutable.Map({formatAs: overallFormatAs || "NUMBER", value: 0}), errors: errors};
  }
};

const evaluateFormulaStrAndFormat = (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, formulaStr) => {
  const {result, errors} = evaluateFormulaStr(
      context,
      masterKpiTypeToKpiId,
      idToNode,
      kpiIdToValue,
      idToFormula,
      formulaStr);
  const {callId} = formulaStr ? parseFormulaCallStr(formulaStr) : {callId: "unknown"};
  const {result: formattedValue, errors: formattingErrors} = formatResult(result, callId);

  return {result: formattedValue, errors: errors.union(formattingErrors)};
};

const evaluateFormulaId = (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, formulaId, args) => {
  context = context || new Immutable.Map();
  if (idToLibFormula.has(formulaId)) {
    try {
      const str = "$(" + formulaId + " " + args.join(" ") + ")";
      const isLooping = visitFormula(str);
      if (isLooping) {
        return {
          result: Immutable.Map({value: 0, formatAs: "NUMBER"}),
          errors: Immutable.fromJS([
            {category: "internal-error",
              type: "lib-formula-error",
              message: "Library formula calling itself"
            }]).toSet()
        };
      }
    const libFormula = idToLibFormula.get(formulaId);
    try {
      return libFormula.get("fn")(context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, args);
    } catch (e) {
      const message = e.message;
      console.error(e);
      return {
        result: Immutable.Map({value: 0, formatAs: "NUMBER"}),
        errors: Immutable.fromJS([
          {
            location: {formulaId},
            category: "internal-error",
            type: "lib-formula-error",
            message: message
          }]).toSet()
      };
    } } finally {
     cleanup();
  }
  } else if (idToFormula.get(formulaId)) {
    try {
      const isLooping = visitFormula(formulaId);
      if (isLooping) {
        return {
          result: Immutable.Map({value: 0, formatAs: "NUMBER"}),
          errors: Immutable.fromJS([
            {
              location: {formulaId},
              category: "custom-formula",
              type: "infinite-loop",
              message: "Formula cannot reference itself"
            }]).toSet()
        };
      }
      const formula = idToFormula.get(formulaId);

      const formulaStr = applyArgsToFormula(formula.get("str"), args);
      let {
        result,
        errors: formulaStrErrors
      } = evaluateFormulaStr(context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, formulaStr);
      if (formula.has("formatAs")) {
        result = result.set("formatAs", formula.get("formatAs"));
      }

      const locationedFormulaErrors = addErrorLocation(formulaStrErrors, "custom-formula", {formulaId});

      return {result: result, errors: locationedFormulaErrors};
    } finally {
      cleanup();
    }
  } else {
    return {
      result: Immutable.Map({value: 0, formatAs: "NUMBER"}),
      errors: Immutable
          .fromJS([
            {
              location: {formulaId: formulaId},
              category: "internal-error",
              type: "incorrect-formula-id",
              message: `invalid formula "${formulaId}"`
            }])
          .toSet()
    };
  }
};

const getKpiIdsForTemplateString = (context, masterKpiTypeToKpiId, idToNode, idToFormula, formulaIdAndArgsToCachedKpiIds, templateStr) => {
  context = context || new Immutable.Map();

  if (templateStr) {
    let errors = Immutable.Set();
    const result = Immutable.Set(templateStr.match(statelessCallPattern)).flatMap(callStr => {
      try {
        const {callId, callArgs} = parseFormulaCallStr(callStr);
        const {
          kpiIds,
          errors: kpiErrors
        } = getKpiIdsForFormulaId(
            context,
            masterKpiTypeToKpiId,
            idToNode,
            idToFormula,
            formulaIdAndArgsToCachedKpiIds,
            callId,
            callArgs);
        errors = errors.union(kpiErrors);
        return kpiIds;
      } catch (e) {
        console.error("Error finding metric IDs for '" + callStr + "'", e);
        errors = errors.add(Immutable.fromJS({
          message: "Error finding metric IDs for '" + callStr + "': " + e.message,
          type: "find-kpi-id"
        }));
        return Immutable.Set();
      }
    });
    return {kpiIds: result.toSet(), errors: errors};
  } else {
    return {kpiIds: Immutable.Set(), errors: Immutable.Set()};
  }
};

const getKpiIdsForFormulaId = (context, masterKpiTypeToKpiId, idToNode, idToFormula, formulaIdAndArgsToCachedKpiIds, formulaId, args) => {
  //args = replaceSpecialArgs(context, args);

  if (idToLibFormula.has(formulaId)) {
    return idToLibFormula
        .get(formulaId)
        .get("getKpiIds")
        (context, masterKpiTypeToKpiId, idToNode, idToFormula, formulaIdAndArgsToCachedKpiIds, args);
  } else {
    if (formulaIdAndArgsToCachedKpiIds[formulaId + "." + args]) {
      return {kpiIds: formulaIdAndArgsToCachedKpiIds[formulaId + "." + args], errors: Immutable.Set()};
    } else if (idToFormula.get(formulaId)) {
      try {
        const isLooping = visitFormula(formulaId);
        if (isLooping) {
          return {
            kpiIds: Immutable.Set(),
            errors: Immutable.Set()
          };
        }
        const formula = idToFormula.get(formulaId);
        const formulaStr = applyArgsToFormula(formula.get("str"), args);
        const kpiIds = Immutable
            .Set(formulaStr.match(statelessCallPattern))
            .map(callStr => {
              const {callId, callArgs} = parseFormulaCallStr(callStr);
              return getKpiIdsForFormulaId(
                  context,
                  masterKpiTypeToKpiId,
                  idToNode,
                  idToFormula,
                  formulaIdAndArgsToCachedKpiIds,
                  callId,
                  callArgs);
            });
        const mergedKpiIdsAndErrors = kpiIds.reduce((merged, x) => {
          return {
            kpiIds: merged.kpiIds.union(x.kpiIds),
            errors: merged.errors.union(x.errors)
          };
        });
        formulaIdAndArgsToCachedKpiIds[formulaId + "." + args] = mergedKpiIdsAndErrors.kpiIds;
        return mergedKpiIdsAndErrors;
      } finally {
        cleanup();
      }
    } else {
      throw new Error(`cannot find formula with id "${formulaId}"`);
    }
  }
};

const getIdToNodeForConfig = config => {
  return indexBy(n => n.get("id"), config.get("nodes", Immutable.List()));
};

const createNodeContext = node => Immutable.fromJS({
  nodeId: node.get("id")
});

const createEdgeContext = (parentNodeId, childNodeId) => Immutable.fromJS({
  parentNodeId,
  childNodeId
});

const getIdToFormulaForConfig = config => indexBy(f => f.get("id"), config.get("formulas", Immutable.List()));

const getRequiredKpisForConfig = (config, masterKpiTypeToKpiId) => {
  const idToFormula = getIdToFormulaForConfig(config);
  const idToNode = getIdToNodeForConfig(config);

  const formulaIdAndArgsToCachedKpiIds = {};
  let errors = Immutable.Set();
  const forSteps = config
      .get("steps", Immutable.List())
      .flatMap(step => {
        const {
          kpiIds: forLabel,
          errors: labelErrors
        } = getKpiIdsForTemplateString(
            null,
            masterKpiTypeToKpiId,
            idToNode,
            idToFormula,
            formulaIdAndArgsToCachedKpiIds,
            step.get("label"));
        const {
          kpiIds: forDescription,
          errors: descriptionErrors
        } = getKpiIdsForTemplateString(
            null,
            masterKpiTypeToKpiId,
            idToNode,
            idToFormula,
            formulaIdAndArgsToCachedKpiIds,
            step.get("description"));
        const forIcons = step
            .get("icons", new Immutable.List())
            .flatMap(icon => {
              const {
                kpiIds: forIconLabel,
                errors: iconLabelErrors
              } = getKpiIdsForTemplateString(
                  null,
                  masterKpiTypeToKpiId,
                  idToNode,
                  idToFormula,
                  formulaIdAndArgsToCachedKpiIds,
                  icon.get("label"));
              const {
                kpiIds: forIconValue,
                errors: iconValueErrors
              } = getKpiIdsForTemplateString(
                  null,
                  masterKpiTypeToKpiId,
                  idToNode,
                  idToFormula,
                  formulaIdAndArgsToCachedKpiIds,
                  icon.get("value"));
              errors = errors.union(iconLabelErrors).union(iconValueErrors);
              return forIconLabel.union(forIconValue);
            });
        errors = errors.union(labelErrors).union(descriptionErrors);
        return forLabel.union(forDescription).union(forIcons);
      })
      .toSet();
  const {
    kpiIds: forDescription,
    errors: descriptionErrors
  } = getKpiIdsForTemplateString(
      null,
      masterKpiTypeToKpiId,
      idToNode,
      idToFormula,
      formulaIdAndArgsToCachedKpiIds,
      config.get("description"));
  errors = errors.union(descriptionErrors);
  const forNodes = config
      .get("nodes", Immutable.List())
      .flatMap(node => {
        const nodeContext = createNodeContext(node);
        const {
          kpiIds: forName,
          errors: nameErrors
        } = getKpiIdsForTemplateString(
            nodeContext,
            masterKpiTypeToKpiId,
            idToNode,
            idToFormula,
            formulaIdAndArgsToCachedKpiIds,
            node.get("name"));
        const {
          kpiIds: forValue,
          errors: valueErrors
        } = getKpiIdsForTemplateString(
            nodeContext,
            masterKpiTypeToKpiId,
            idToNode,
            idToFormula,
            formulaIdAndArgsToCachedKpiIds,
            node.get("value"));
        const forEdgesOut = node
            .get("edgesOut", Immutable.List())
            .flatMap(edge => {
              const edgeContext = createEdgeContext(node.get("id"), edge.get("nodeId"));
              const {
                kpiIds: forNodeId,
                errors: nodeIdErrors
              } = getKpiIdsForTemplateString(
                  edgeContext,
                  masterKpiTypeToKpiId,
                  idToNode,
                  idToFormula,
                  formulaIdAndArgsToCachedKpiIds,
                  edge.get("edgesOut"));
              const {
                kpiIds: forLabel,
                errors: labelErrors
              } = getKpiIdsForTemplateString(
                  edgeContext,
                  masterKpiTypeToKpiId,
                  idToNode,
                  idToFormula,
                  formulaIdAndArgsToCachedKpiIds,
                  edge.get("edgesOut"));
              errors = errors.union(nodeIdErrors).union(labelErrors);
              return forNodeId.union(forLabel);
            });
        errors = errors.union(nameErrors).union(valueErrors);
        return forName.union(forValue).union(forEdgesOut);
      });
  return {kpiIds: forSteps.union(forDescription).union(forNodes), errors: errors};
};

const getLabelForFormulaId = (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs) => {
  if (idToLibFormula.has(callId)) {
    const {result: label, errors} = idToLibFormula.get(callId)
        .get("getLabel")(context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs);
    return {result: label, errors: errors};
  } else if (idToFormula.get(callId).get("label")) {
    const label = idToFormula.get(callId).get("label");
    return {result: label, errors: Immutable.Set()};
  } else {
    const formulaId = idToFormula.get(callId).get("id").replaceAll("-", " ");
    const label = String.capitaliseWords(formulaId);
    return {result: label, errors: Immutable.Set()};
  }
};

const getUsagesOfFormulasForTemplateString = (formulaIds, templateStr, location) => {
  return Immutable.List(templateStr.match(statelessCallPattern)).flatMap(callStr => { //
    const {callId, callArgs} = parseFormulaCallStr(callStr);
    if (idToLibFormula.has(callId)) {
      const getFormulaUsed = idToLibFormula
          .get(callId)
          .get("getFormulaUsed", (args) => {
            return Immutable.Map({
              formulaId: callId,
              formulaArgs: args
            });
          });
      const result = getFormulaUsed(callArgs);
      const realCallId = result.get("formulaId");
      const realCallArgs = result.get("formulaArgs");
      return Immutable.fromJS([
        {
          formulaId: realCallId,
          formulaArgs: realCallArgs,
          location: location
        }]);
    } else {
      return callArgs
          // NOTE: we are not able to retrieve formulaArgs for custom formulas that arent the first in the callStr
          .filter(callArg => formulaIds.includes(callArg))
          .map(callArg => Immutable.fromJS({
            formulaId: callArg,
            formulaArgs: [],
            location: location,
            unknownArgs: true
          })).push(
              Immutable.fromJS({
                formulaId: callId,
                formulaArgs: callArgs,
                location: location
              })
          );
    }
  });
};

const getUsagesOfAllFormulasInConfig = (config) => {
  const formulas = config.get("formulas") || Immutable.List();
  const setOfFormulaIds = formulas.map(formula => formula.get("id")).filter(f => f !== "").toSet();
  const forSteps = config
      .get("steps", Immutable.List())
      .flatMap((step, index) => {
        const stepId = step.get("id");
        const forLabel = getUsagesOfFormulasForTemplateString(setOfFormulaIds, step.get("label", ""), {
          area: "steps",
          stepId: stepId,
          path: ["label"]
        });
        const forDescription = getUsagesOfFormulasForTemplateString(setOfFormulaIds, step.get("description", ""), {
          area: "steps",
          stepId: stepId,
          path: ["description"]
        });
        const forIcons = step
            .get("icons", new Immutable.List())
            .flatMap(icon => {
              const forIconLabel = getUsagesOfFormulasForTemplateString(setOfFormulaIds, icon.get("label", ""), {
                area: "steps",
                stepId: stepId,
                path: ["icons", "label"]
              });
              const forIconValue = getUsagesOfFormulasForTemplateString(setOfFormulaIds, icon.get("value", ""), {
                area: "steps",
                stepId: stepId,
                path: ["icons", "value"]
              });
              return forIconLabel.concat(forIconValue);
            });
        return forLabel.concat(forDescription).concat(forIcons);
      });
  const forDescription = getUsagesOfFormulasForTemplateString(setOfFormulaIds, config.get("description", ""), {area: "description"});
  const forNodes = config
      .get("nodes", Immutable.List())
      .flatMap(node => {
        const forLabel = getUsagesOfFormulasForTemplateString(setOfFormulaIds, node.get("label", ""), {
          area: "nodes",
          areaId: node.get("id"),
          path: ["label"]
        });
        const forValue = getUsagesOfFormulasForTemplateString(setOfFormulaIds, node.get("value", ""), {
          area: "nodes",
          areaId: node.get("id"),
          path: ["value"]
        });
        const forEdgesOut = node
            .get("edgesOut", new Immutable.List())
            .flatMap(edge => getUsagesOfFormulasForTemplateString(setOfFormulaIds, edge.get("label", ""), {
              area: "nodes",
              areaId: node.get("id"),
              path: ["edge"]
            }));
        return forLabel.concat(forValue).concat(forEdgesOut);
      });
  const forFormulas = config
      .get("formulas", Immutable.List())
      .flatMap(formula => getUsagesOfFormulasForTemplateString(setOfFormulaIds, formula.get("str", ""), {
        area: "formulas",
        areaId: formula.get("id")
      }));
  return forSteps.concat(forDescription).concat(forNodes).concat(forFormulas).groupBy(u => u.get("formulaId"));
};

const evaluateFormulaLabelsInTemplateString = (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, templateStr) => {
  if (!templateStr) {
    return "";
  }
  context = context || new Immutable.Map();

  let matches;
  let startingPoint = 0;
  let formulaLabel = [];
  const statefulCallPattern = makeStatefulCallPattern();
  while ((matches = statefulCallPattern.exec(templateStr)) !== null) {
    const callStr = matches[0];
    const {callId, callArgs} = parseFormulaCallStr(callStr);
    const piece = templateStr.substring(startingPoint, statefulCallPattern.lastIndex - callStr.length);
    if (piece.length > 0) {
      formulaLabel.push(piece);
    }
    const {
      result: label
    } = getLabelForFormulaId(context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs);
    formulaLabel.push(label);

    startingPoint = statefulCallPattern.lastIndex;
  }
  const lastPiece = templateStr.substring(startingPoint, templateStr.length);
  formulaLabel.push(lastPiece);

  return new Immutable.List(formulaLabel);
};

const evaluateFormulasInTemplateString = (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, templateStr, type) => {
  if (!templateStr) {
    return {result: Immutable.List(), errors: Immutable.Set()};
  }
  context = context || new Immutable.Map();

  let matches;
  let startingPoint = 0;
  let pieces = Immutable.List();
  let errors = Immutable.Set();
  const statefulCallPattern = makeStatefulCallPattern();
  while ((matches = statefulCallPattern.exec(templateStr)) !== null) {
    try {
      const callStr = matches[0];
      const {callId, callArgs} = parseFormulaCallStr(callStr);
      const piece = templateStr.substring(startingPoint, statefulCallPattern.lastIndex - callStr.length);
      if (piece.length > 0) {
        pieces = pieces.push(piece);
      }
      const {
        result,
        errors: formulaIdErrors
      } = evaluateFormulaId(context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs);
      const {result: formattedResult, errors: formattingErrors} = formatResult(result, callId);

      errors = errors.union(formulaIdErrors).union(formattingErrors);

      let resultComponent;

      if (type === "hover") {
        const {
          result: label,
          errors: labelErrors
        } = getLabelForFormulaId(context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs);
        errors = errors.union(labelErrors);
        resultComponent = <>
          <i data-tip="" data-for={callStr}>
            {formattedResult}
            <Tooltip id={callStr} place="top" type="light" effect="solid">
              {label}
            </Tooltip>
          </i>
        </>;
      } else if (type === "label") {
        const {
          result: label,
          errors: labelErrors
        } = getLabelForFormulaId(context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, callId, callArgs);
        errors = errors.union(labelErrors);
        if (Immutable.isList(formattedResult) && Immutable.isList(label)) {
          resultComponent = formattedResult.push(" ").concat(label);
        } else if (Immutable.isList(formattedResult)) {
          resultComponent = formattedResult.push(" ").push(label);
        } else if (Immutable.isList(label)) {
          resultComponent = Immutable.List([formattedResult + " "]).concat(label)
        } else {
          resultComponent = formattedResult + " " + label;
        }
      } else {
        resultComponent = formattedResult;
      }
      if (Immutable.isList(resultComponent)) {
        pieces = pieces.concat(resultComponent);
      } else {
        pieces = pieces.push(resultComponent);
      }
      startingPoint = statefulCallPattern.lastIndex;
    } catch (e) {
      const error = Immutable.Map({category: "internal-error", type: "Unknown error", message: e.message});
      errors = errors.add(error);
      pieces = pieces.push("${ ERROR }");  // eslint-disable-line no-template-curly-in-string
      startingPoint = statefulCallPattern.lastIndex;
    }
  }
  const lastPiece = templateStr.substring(startingPoint, templateStr.length);
  if (lastPiece.length > 0) {
    pieces = pieces.push(lastPiece);
  }

  return {result: pieces, errors: errors};
};

export {
  getIdToFormulaForConfig,
  getIdToNodeForConfig,

  getRequiredKpisForConfig,
  getUsagesOfAllFormulasInConfig,
  getKpiIdsForTemplateString,
  getKpiIdsForFormulaId,

  evaluateFormulasInTemplateString,
  evaluateFormulaStr,
  evaluateFormulaStrAndFormat,
  evaluateFormulaLabelsInTemplateString,

  evaluateFormulaId,
  createNodeContext,
  createEdgeContext,

  getLabelForFormulaId,
  addErrorLocation
};
