import moment from "moment";

import {formatDateTime} from "js/common/utils/time";

const buildInfo = {
  buildBranch: process.env.REACT_APP_BUILD_BRANCH ?? "n/a",
  buildTimestamp: process.env.REACT_APP_BUILD_TIMESTAMP ?? formatDateTime(moment.utc()),
  buildUrl: process.env.REACT_APP_BUILD_URL,
  buildId: process.env.REACT_APP_BUILD_ID ?? 0,
};

export default buildInfo;