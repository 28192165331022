/** @jsxImportSource @emotion/react */
import React from "react";
import Immutable from "immutable";
import * as Formulas from "js/common/formulas/formulas";
import useKpiLoader from "js/squids/use-kpi-loader";
import SquidCard from "js/squids/squid-selection/squid-card";
import Controls from "js/squids/controls";
import {TextButton} from "js/common/views/inputs/buttons";
import IndexIntro from "js/common/views/index-view/index-intro";
import squidHappy from "img/squids/squid-happy.svg";
import IndexView from "js/common/views/index-view/index-view";
import Dialog from "js/common/views/dialog";
import * as Auditor from "js/common/auditer";
import { Paper } from "@mui/material";

const SquidSelection = (props) => {
  const {
    idToPageWrapper,
    idToUndoStack,
    onSelect,
    masterKpiTypeToKpiId,
    controlConfig,
    setControlConfig,
    hasSquidEditorPermission,
    squidViewStyle,
    setSquidViewStyle,
    createEmptySquid,
    duplicateSquid,
    deleteSquid
  } = props;

  const pageWrappers = React.useMemo(() => {

        return idToPageWrapper.valueSeq().sortBy(p => p.get("name")).map(pageWrapper => {
          const page = pageWrapper.get("json");
          const idToFormula = Formulas.getIdToFormulaForConfig(page);
          const idToNode = Formulas.getIdToNodeForConfig(page);
          const hasUnsavedChanges = idToUndoStack.has(pageWrapper.get("id"));
          return pageWrapper
              .set("idToFormula", idToFormula)
              .set("idToNode", idToNode)
              .set("hasUnsavedChanges", hasUnsavedChanges);
        });
      },
      [idToPageWrapper, idToUndoStack]);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [indexToDelete, setIndexToDelete] = React.useState(null);

  const {requiredKpiIds} = React.useMemo(
      () => {
        let errors = Immutable.Set();
        const requiredKpiIds = pageWrappers
            .flatMap(pageWrapper => {
              const description = pageWrapper.getIn(["json", "description"]);
              const {kpiIds, errors: descriptionErrors} = Formulas.getKpiIdsForTemplateString(
                  null,
                  masterKpiTypeToKpiId,
                  pageWrapper.get("idToNode"),
                  pageWrapper.get("idToFormula"),
                  {},
                  description);
              errors = errors.union(descriptionErrors);
              return kpiIds;
            })
            .toSet();
        return {requiredKpiIds, errors};
      },
      [pageWrappers, masterKpiTypeToKpiId]);

  const {kpiIdToValue, loadingKpiData} = useKpiLoader(requiredKpiIds, controlConfig);

  const populatedSquids = React.useMemo(() => {
    return pageWrappers.map(pageWrapper => {
      if (kpiIdToValue && !loadingKpiData) {
        const {result, errors} = Formulas.evaluateFormulasInTemplateString(
            null,
            masterKpiTypeToKpiId,
            pageWrapper.get("idToNode"),
            kpiIdToValue,
            pageWrapper.get("idToFormula"),
            pageWrapper.getIn(["json", "description"]));
        return pageWrapper.setIn(["json", "description"], result).setIn(["json", "descriptionErrors"], errors);
      } else {
        return pageWrapper.setIn(["json", "description"], "Loading...");
      }
    });
  },[kpiIdToValue, masterKpiTypeToKpiId, pageWrappers, loadingKpiData]);

  const controls = <Controls config={controlConfig} onChange={setControlConfig} mode="VIEW" />;
  const deleteDialogActions = <>
    <TextButton
        type="default"
        key="cancel-btn"
        label="Cancel"
        icon="history"
        onClick={() => setIsDeleteDialogOpen(false)} />
    <TextButton
        type="primary"
        key="delete-btn"
        label="Delete"
        icon="trash"
        onClick={() => indexToDelete && handleSquidDelete(indexToDelete)} />
  </>;
  const newButton = hasSquidEditorPermission &&
      <TextButton type="primary" label="Create new squid" onClick={createEmptySquid} style={{whiteSpace: "nowrap"}} />;

  const handleIndexDelete = (id) => {
    setIsDeleteDialogOpen(true);
    setIndexToDelete(id);
  };

  const handleSquidDelete = (squidId) => {
    deleteSquid(squidId);
    setIndexToDelete(null);
    setIsDeleteDialogOpen(false);
  };

  const sendAuditInformationOnLoad = (populatedSquids) => {
    const auditList = [];
    populatedSquids.forEach(squid => auditList.push({id: squid.get("id"), name: squid.get("name")}));
    Auditor.audit("squid-app:loaded", {squids: auditList});
  };

  populatedSquids.count() > 0 && sendAuditInformationOnLoad(populatedSquids);

  return <Paper style={{height: "100%"}}>
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <IndexIntro
          title="Squids"
          description="Using squids you can deep dive into your data and just like Paul the Octopus you can predict the future to optimize your conversions. Select a squid to get started!"
          image={squidHappy}
          cta={newButton} />

      <IndexView
          data={populatedSquids}
          controls={controls}
          card={<SquidCard />}
          onClick={onSelect}
          squidViewStyle={squidViewStyle}
          setSquidViewStyle={setSquidViewStyle}
          deleteSquid={handleIndexDelete}
          duplicateSquid={duplicateSquid} />
    </div>

    <Dialog
        title="Delete Squid?"
        open={isDeleteDialogOpen}
        onBackdropClick={() => setIsDeleteDialogOpen(false)}
        actions={deleteDialogActions}>
      <p>Are you sure you want to delete this squid?</p>
    </Dialog>
  </Paper>;
};

export default SquidSelection;
