import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

import {
    Table,
    TableContainer,
    TableCell,
    TableRow,
    TableHead,
    TableBody
} from '@mui/material';

const overflowStyle = {
    overflow: "visible",
    overflowX: "visible",
    overflowY: "visible",
    whiteSpace: "inherit",
    backgroundColor: "transparent",
    background: "transparent"
};

const headerColumnStyle = theme =>({
    color: theme.palette.text.main,
    background: theme.palette.background.card,
    fontSize: "0.8rem",
    fontWeight: "normal",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: "8px",
    paddingRight: "8px",
    height: "20px"
});

const cellStyle = theme =>({
    ...overflowStyle,
    padding: "0px 8px",
    fontSize: "0.9375rem",
    color: theme.palette.text.main,
    borderBottom: "none !important"
});

const tableRowStyle = theme =>({
    borderBottom: "2px solid #000",
    backgroundColor: theme.palette.background.card
});

const GRID_LINES_COLOR = "#000";

const InteractiveTable = createReactClass({

    displayName: "InteractiveTable",

    propTypes: {
        columns: ReactPropTypes.array.isRequired,
        rows: ReactPropTypes.array.isRequired,
        onHeaderClick: ReactPropTypes.func,
        maxHeight: ReactPropTypes.string,
        gridLayout: ReactPropTypes.bool
    },

    getDefaultProps() {
        return {
          onHeaderClick: () => {}
        }
    },

    getInitialState() {
        return {
            sortOrder: "none"
        };
    },

    render() {
        const maxHeight = this.props.maxHeight;
        if (maxHeight) {
            return (
                <TableContainer style={{maxHeight}}>
                {this.renderTable()}
            </TableContainer>)
        } else {
            return this.renderTable()
        }
    },

    renderTable() {
        const { columns, rows, maxHeight, theme } = this.props;
        return (
            <Table
                stickyHeader={!!maxHeight} >
                <TableHead>
                    <TableRow style={{ height: "30px", borderBottom: `2px solid ${theme.palette.primary.main}` }}>
                        {columns.map(this.renderHeaderColumn)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(this.renderRow)}
                </TableBody>
            </Table>);
    },

    renderRow(cells, rowIndex, rows) {
        const {theme} = this.props;
        const isEvenRow = rowIndex % 2 === 0;
        const isLastRow = rowIndex === rows.length - 1;
        const stripedRowStyle = { backgroundColor: theme.themeId === "light" ? (isEvenRow ? '#eef4fc' : '#fff') : (isEvenRow ? theme.palette.background.paper : theme.palette.background.card) };
        const borderColor = this.props.gridLayout ? GRID_LINES_COLOR : theme.palette.border.main;
        const rowLayoutStyle = { borderBottom: isLastRow ? 0 : `1px solid ${borderColor}` };
        return (
            <TableRow key={cells.id || rowIndex} style={{ ...tableRowStyle(theme), ...stripedRowStyle, ...rowLayoutStyle }}>
                {cells.map(this.renderCell)}
            </TableRow>);
    },

    renderCell(cell, cellIndex) {
        const headerColumn = this.props.columns[cellIndex];
        const {theme} = this.props;
        const width = headerColumn ? headerColumn.width : "auto";
        const textAlign = headerColumn ? headerColumn.textAlign : "left";
        let cellLayoutStyle = this.props.gridLayout ? { borderRight: "1px solid " + GRID_LINES_COLOR } : { border: 0 };
        if (cellIndex === this.props.columns.length - 1) {
            cellLayoutStyle = { borderRight:0 };
        }
        const allStyles = { ...cellStyle(theme), ...cellLayoutStyle, width };
        return (
            <TableCell
                key={cellIndex}
                align={textAlign}
                style={{...allStyles, borderBottom: "0"}}
                className={headerColumn ? headerColumn.foundationClasses : ""}>
                {cell}
            </TableCell>);
    },

    renderHeaderColumn(column, index) {
        const {theme} = this.props;
        const columnLabel = typeof column === "string" ? column : column.label;
        const columnStyles = column.styles;
        const foundationClasses = column.foundationClasses;
        const sortable = column.sortable;
        const sortOrder = column.sortOrder;
        const textAlign = column.textAlign || "left";
        const width = column ? column.width : "auto";
        const allStyles = { ...headerColumnStyle(theme), width, ...columnStyles };
        return (
            <TableCell
                key={columnLabel}
                style={allStyles}
                align={textAlign}
                className={foundationClasses}
                onClick={() => this.props.onHeaderClick(index)}>
                <span>{columnLabel}</span>
                {sortable &&
                <span className="right">
                    <i className={getSortButtonIcon(sortOrder)} />
                </span>}
            </TableCell>
        );
    }

});

const getSortButtonIcon = sortOrder => {
    const prefix = "fa fa-sort";
    switch (sortOrder) {
    case "asc":
        return prefix + "-asc";
    case "desc":
        return prefix + "-desc";
    default:
        return prefix;
    }
};


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <InteractiveTable theme={theme} {...props} />;
};

export default Wrapper;
