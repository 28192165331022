import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";

import ErrorBoundary from "js/common/views/error-boundary";

import MenuBar from "js/common/views/menu-bar";
import UserAdmin from "js/admin/users/tabbed";
import GroupAdmin from "js/admin/groups/app";
import ClientAdmin from "js/admin/client/app";
import TagsAdmin from "js/admin/tags/app";
import KpisAdmin from "js/admin/kpis/app";
import PagesAdmin from "js/admin/oneview-pages/app";
import TargetsAdmin from "js/admin/targets/app";
import CubeTvAdmin from "js/admin/cubetv/app";
import IpAccessAdmin from "js/admin/ip-access/app";
import TimezonesAdmin from "js/admin/timezones/app";
import ActualsAdmin from "js/admin/actuals/app";
import RatiosAdmin from "js/admin/ratios/app";
import JobsPipelineAdmin from "js/admin/jobs-pipeline/app";
import TimeframesAdmin from "js/admin/timeframes/app";
import DataExplorerPage from "js/admin/data-explorer/app";
import NoPermissions from "js/admin/no-permissions";
import * as keyKpis from "js/admin/kpis/key-kpis/key-kpis";
import * as auditor from "js/common/auditer";
import * as Users from "js/common/users";
import currentClient from "js/common/repo/backbone/current-client";
import LoadingSpinner from "js/common/views/loading-spinner";

import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const $ = window.$;

const adminPages = [{
  id: "REACT_USER_ADMIN",
  title: "Users",
  view: <UserAdmin />
}, {
  id: "GROUP_ADMIN",
  title: "Groups",
  view: <GroupAdmin />
}, {
  id: "TIMEZONE_ADMIN",
  title: "Timezones",
  view: <TimezonesAdmin />
}, {
  id: "KPI_ADMIN",
  title: "Metrics",
  view: <KpisAdmin />
}, {
  id: "SECTION_ADMIN",
  title: "OneView Pages",
  view: <PagesAdmin />
}, {
  id: "TARGET_ADMIN",
  title: "Targets",
  view: <TargetsAdmin />
}, {
  id: "RATIO_ADMIN",
  title: "Ratios",
  view: <RatiosAdmin />
}, {
  id: "CLIENT_ADMIN",
  title: "Company Settings",
  view: <ClientAdmin />
}, {
  id: "TIMEFRAME_ADMIN",
  title: "Timeframes",
  view: <TimeframesAdmin />
}, {
  id: "IP_ADMIN",
  title: "IP Access",
  view: <IpAccessAdmin />
}, {
  id: "ACTUALS_ADMIN",
  title: "Actuals",
  view: <ActualsAdmin />
}, {
  id: "CAROUSEL_ADMIN",
  title: "Gamification",
  view: <CubeTvAdmin />
}, {
  id: "TAGS_ADMIN",
  title: "Tags",
  view: <TagsAdmin />
}, {
  id: "JOB_PIPELINE_ADMIN",
  title: "Jobs Pipeline",
  view: <JobsPipelineAdmin />
}, {
  id: "DATA_EXPLORER_ADMIN",
  title: "Data Explorer",
  view: <DataExplorerPage />
}];

const NoAdminPermissionsPage = {
  id: "NO_ADMIN_PERMISSIONS",
  title: "No Admin Access",
  view: <NoPermissions />
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return (
      <ErrorBoundary>
        <Page theme={theme} {...props} />
      </ErrorBoundary>
  )
};

const Page = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    if (this.props.initialPage) {
      let initialPageObject;
      adminPages.forEach(page => {
        if (page.id === this.props.initialPage) {
          initialPageObject = page;
        }
        ;
      });
      return {
        currentPage: initialPageObject
      };
    }
    if (getAppsForCurrentUser().includes("KPI_ADMIN")) {
      return {
        currentPage: null
      };
    } else {
      const pages = getAdminPages();
      return {
        currentPage: pages[0]
      };
    }
  },

  componentDidMount() {
    const theme = localStorage.getItem('appTheme');
    $("body")
        .removeClass()
        .addClass("admin")
        .addClass(`theme-${theme}`);
    if (!this.props.initialPage && getAppsForCurrentUser().includes("KPI_ADMIN")) {
      keyKpis
          .getAll()
          .then(keyKpis => {
            if (keyKpis
                .filter(keyKpi => (Users.isCube19User(Users.getCurrentUser()) || keyKpi.get("isCustomerVisible"))
                    && !keyKpi.get("selectedKpiId")
                    && !keyKpi.get("noSelectionReason"))
                .count() > 0) {
              this.setState({
                currentPage: {
                  id: "KPI_ADMIN",
                  title: "Metrics",
                  view: <KpisAdmin initialTab={"KEY_KPIS"} />
                }
              });
            } else {
              this.setState({
                currentPage: getAdminPages()[0]
              });
            }
            auditor.audit("admin:loaded");
          }, () => {
            this.setState({
              currentPage: getAdminPages()[0]
            });
            auditor.audit("admin:loaded");
          });
    } else {
      auditor.audit("admin:loaded");
    }
  },

  componentWillUnmount() {
    $("body").removeClass("admin");
  },

  render() {
    const containerStyle = {
      padding: "0",
      boxSizing: "border-box",
      position: "relative"
    };
    const pageTitleStyle = (theme) => ({
      color: theme.palette.text.main,
      fontSize: "1.5rem",
      fontWeight: "bold",
      padding: "10px"
    });
    const {currentPage} = this.state;
    const pages = getAdminPages();

    const linkStyle = (isCurrentPage, theme) => ({
      cursor: "pointer",
      textTransform: "uppercase",
      fontWeight: isCurrentPage ? "bold" : "normal",
      color: isCurrentPage ? theme.palette.text.inverted : theme.themeId === "light" ? "#666" : "#bbb",
      background: isCurrentPage ? theme.palette.primary.main : "none",
      padding: "0.5rem 1rem",
      transition: "all 0.3s ease",

      ":hover": {
        background: isCurrentPage ? "#f9ec33" : "#272731",
        color: isCurrentPage ? "#000" : "#fff"
      }
    });

    return (
        <div>
          <MenuBar appView="admin" />
            <div className="row admin-main-content" style={{
              ...containerStyle,
              background: this.props.theme.palette.background.paper,
              minHeight: "100vh",
            }}>
              <nav className="small-12 medium-2 column" style={{...containerStyle, height: "100%", padding: 0}}>
                <ul style={{listStyle: "none", fontSize: "0.9rem"}}>
                  {pages.map(page =>
                      <li style={linkStyle(currentPage && page.id === currentPage.id, this.props.theme)} onClick={() => this.setState({currentPage: page})} id={page.id} key={page.id}>{page.title}</li>
                  )}
                </ul>
              </nav>
              <div
                  className="small-12 medium-10 column"
                  style={{
                    ...containerStyle,
                    height: "100%",
                    backgroundColor: this.props.theme.themeId === "light" ? this.props.theme.palette.background.card : this.props.theme.palette.background.paper,
                    borderLeft: this.props.theme.themeId === "light" ? "none" : "1px solid #555",
                    minHeight: "100vh",
                    padding: 0
                  }}>
                {currentPage ?
                    <div>
                      <h1 style={pageTitleStyle(this.props.theme)}>
                        {currentPage.title}
                      </h1>
                      <div style={{padding: 10}}>
                        {currentPage.view}
                      </div>
                    </div> :
                    <div><br /><LoadingSpinner /><br /></div>
                }
              </div>
            </div>
        </div>
    );
  }

});

const Link = (({title, isCurrentPage, onClick}) => {
  const style = {
    cursor: "pointer",
    textTransform: "uppercase",
    fontWeight: isCurrentPage ? "bold" : "normal",
    color: isCurrentPage ? "#000" : "#bbb",
    background: isCurrentPage ? "#f9ec33" : "none",
    padding: "0.5rem 1rem",
    transition: "all 0.3s ease",

    ":hover": {
      background: isCurrentPage ? "#f9ec33" : "#272731",
      color: isCurrentPage ? "#000" : "#fff"
    }
  };
  return <li style={style} onClick={onClick}>{title}</li>;
}, "Link");

const userCanAccess = app => {
  const user = Users.getCurrentUser();
  return currentClient.canAccessApp(app) || !Users.canAccessApp(user, app);
};
const getAppsForCurrentUser = () => Immutable
    .fromJS(Users.getCurrentUser().get("appAssignments").toJSON())
    .map(permission => permission.get("app"))
    .filter(app => userCanAccess(app))
    .toSet();

const getAdminPages = () => {
  const adminAppPermissionsForUser = getAppsForCurrentUser();
  return adminAppPermissionsForUser.isEmpty() ?
      [NoAdminPermissionsPage] : adminPages.filter(app => adminAppPermissionsForUser.includes(app.id));
};
