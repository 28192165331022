const indexBy = (f, xs) => xs.groupBy(f).map(xs => xs.first());

const selectKeys = (m, keySet) => m.filter((v, k) => keySet.has(k));

const indexByJs = (f, xs) => {
  const kToX = {};
  for (const x of xs) {
    kToX[f(x)] = x;
  }
  return kToX;
};

export {
  indexBy,
  indexByJs,
  selectKeys
};