import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import * as Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import sortable from "react-sortable-mixin";

import KpiAssignment from "js/admin/oneview-pages/kpi-assignment";

export default createReactClass({
  mixins: [sortable.ListMixin],

  propTypes: {
    kpiAssignments: ImmutablePropTypes.list.isRequired,
    onDeleteClick: ReactPropTypes.func.isRequired,
    onMinimisedOrderChange: ReactPropTypes.func.isRequired,
    onResorted: ReactPropTypes.func.isRequired,
    isSmallScreen: ReactPropTypes.bool
  },

  getItems() {
    return this.props.kpiAssignments.toArray();
  },

  onResorted(kpiAssignments) {
    this.props.onResorted(Immutable.List(kpiAssignments));
  },

  render() {
    return (
        <ul className="row">
          {this.renderKpiAssignmentElements()}
        </ul>);
  },

  renderKpiAssignmentElements() {
    return this.props.kpiAssignments.map((kpiAssignment, index) => {
      const kpiId = kpiAssignment.get("kpiId");
      let kpi = this.props.kpiById.get(kpiId);
      return (
          <KpiAssignment
              key={kpiAssignment.get("id") || kpiAssignment.get("cid")}
              index={index}
              {...this.movableProps}
              onDeleteClick={() => this.props.onDeleteClick(index)}
              name={kpi ? kpi.get("name") : <UnknownKpiError kpiId={kpiId} />}
              minimisedOrder={kpiAssignment.get("minimisedOrder")}
              onMinimisedOrderChange={newOrder => this.props.onMinimisedOrderChange(index, newOrder)}
              isSmallScreen={this.props.isSmallScreen} />);
    });
  }
});

const UnknownKpiError = ({kpiId}) => (
    <span style={{color: "#ff0000"}}><i className="fa fa-exclamation-triangle" /> Metric ID {kpiId} not found</span>
);