import Immutable from "immutable";

const Status = Object.freeze({
  INITIAL: "INITIAL",
  LOADING: "LOADING",
  LOADED: "LOADED",
  ERROR: "ERROR"
});

const currentMillis = () => new Date().getTime();

const calculateExpiresAtMillis = expiresMinutes => {
  if (expiresMinutes) {
    return currentMillis() + (expiresMinutes * 60 * 1000);
  } else {
    return null;
  }
};

const getOrMap = wrapper => (wrapper || Immutable.Map());

const isExpired = (wrapper) => getOrMap(wrapper).get("expiresAtMillis")
    && wrapper.get("expiresAtMillis") < currentMillis();
const isInitial = (wrapper) => getOrMap(wrapper).get("status") === Status.INITIAL;
const isLoading = (wrapper) => getOrMap(wrapper).get("status") === Status.LOADING;
const isLoaded = (wrapper) => getOrMap(wrapper).get("status") === Status.LOADED;
const hasError = (wrapper) => getOrMap(wrapper).get("status") === Status.ERROR;
const getError = (wrapper) => getOrMap(wrapper).get("error");
const getValue = (wrapper, defaultValue) => getOrMap(wrapper).get("value", defaultValue);
const getStatus = (wrapper) => getOrMap(wrapper).get("status");

const wrapInitialValue = value => {
  return Immutable.Map({
    value: value,
    status: Status.INITIAL
  });
};

const toLoading = wrapper => getOrMap(wrapper)
    .set("status", Status.LOADING)
    .delete("error");

const toLoaded = (wrapper, newValue, expiresMinutes) => getOrMap(wrapper)
    .set("status", Status.LOADED)
    .set("value", newValue)
    .delete("error")
    .set("loadedAtMillis", currentMillis())
    .set("expiresAtMillis", calculateExpiresAtMillis(expiresMinutes));

const toError = (wrapper, error) => getOrMap(wrapper)
    .set("status", Status.ERROR)
    .set("error", error);

const updateValue = (wrapper, updater) => {
  wrapper = getOrMap(wrapper);
  const current = getValue(wrapper);
  const updated = updater(current);
  return toLoaded(wrapper, updated);
};

export {
  wrapInitialValue,
  toLoading,
  toLoaded,
  toError,
  updateValue,

  isExpired,
  isInitial,
  isLoading,
  isLoaded,
  hasError,

  getError,
  getStatus,
  getValue,

  Status
};
