import store from "store";
import {v4 as uuid} from "uuid";

import InvalidStatePage from "js/invalid-state/invalid-state-page";
import ErrorPage from "js/invalid-state/error-page";
import NewUserPage from "js/invalid-state/new-user-page";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Users from "js/common/users";
import * as Auth from "js/common/auth";
import * as Branding from "js/common/branding-constants";
import {bullhornSsoAuthorizationEndpoint, bullhornSsoClientId, bullhornSsoRedirectUri} from "js/app-configuration";

const loginViaSso = () => {
  const state = {
    uuid: uuid(),
    queryString: window.location.search
  };
  store.set("ssoState", state);
  window.location.href =
      `${bullhornSsoAuthorizationEndpoint}?client_id=${bullhornSsoClientId}&response_type=code&redirect_uri=${bullhornSsoRedirectUri}&state=${window.btoa(
          JSON.stringify(state))}`;
};

const isIframed = () => {
  try {
    return window.self !== window.top;
  } catch (_) {
    return true;
  }
};

const SsoLoginFailedPage = ({error}) => {
  const errorType = error?.responseJSON?.type ?? error?.type;
  const errorMessage = error?.responseJSON?.message;
  if (errorType === "FAILED_SSO_CHECK" || errorType === "ERROR_IN_BULLHORN_AUTH") {
    return <InvalidStatePage
        heading={"Login Failed"}
        subheading={"We could not authenticate your user"}
        content={<FailedSsoCheckContent />} />;
  } else if (errorType === "USER_NOT_FOUND") {
    return <NewUserPage />;
  } else if (errorType === "FORM_LOGIN_REQUIRED") {
    return <InvalidStatePage
        heading={"Access Denied"}
        subheading={"Single sign-on is not enabled for your company"}
        content={<FormLoginRequiredContent />} />;
  } else if (errorType === "USER_MISMATCH") {
    return <InvalidStatePage
        heading={"User Mismatch"}
        subheading={"Current user does not match the expected user"}
        content={<UserMismatchContent />} />;
  } else if (errorType === "INVALID_IP") {
    return <InvalidStatePage
        heading={"Access Denied"}
        subheading={`Your current IP Address is not allowed to access ${Branding.brandingName}`}
        content={<InvalidIpAddressContent />} />;
  } else if (errorType === "EXPIRED_CLIENT") {
    return <InvalidStatePage
        heading={"Access Denied"}
        subheading={errorMessage}
        content={<BackToLoginButton />} />;
  } else {
    return <ErrorPage />;
  }
};

const FailedSsoCheckContent = () => <>
  <p>Check that you are logged into Bullhorn.</p>
  <p>{Branding.submitTicketInstructions}</p>

  <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
    {showLogoutButton() &&
        <BackToLoginButton />}
    <TextButton
        label={"Retry"}
        type={"primary"}
        onClick={loginViaSso} />
  </div>
</>;

const FormLoginRequiredContent = () => <>
  <p>Use your username and password in the login form.</p>

  <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
    <BackToLoginButton />
  </div>
</>;

const InvalidIpAddressContent = () => <>
  <p>Sign in from an allowed IP address.</p>

  <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
    {showLogoutButton() &&
        <BackToLoginButton />}
    <TextButton
        label={"Retry"}
        type={"primary"}
        onClick={loginViaSso} />
  </div>

</>;

const UserMismatchContent = () => <>
  <p>The user account has changed and has been logged out. Please click the button below to login again.</p>
  <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
    {!isIframed() && <BackToLoginButton />}
    <TextButton
        label={"Login as current ATS User"}
        type={"primary"}
        onClick={loginViaSso} />
  </div>
</>;

const BackToLoginButton = () => <TextButton
    label="Back to Login"
    onClick={Auth.logout}
    style={{marginRight: "1rem"}} />;

const showLogoutButton = () => !isIframed() || (isIframed() && Users.getCurrentUser()?.get("isSsoSession") === false);

export {loginViaSso, isIframed, showLogoutButton, SsoLoginFailedPage};