import React from "react";
import Immutable from "immutable";
import Pluralize from "pluralize";
import Error from "js/common/views/error";
import {loadPermissionsForUser} from "js/admin/users/app";
import {toCommaSeparatedWithAnd} from "js/common/utils/strings";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import {betterMemo} from "js/common/utils/more-memo";
import InfiniteListSelect from "js/common/views/infinite-loading/infinite-list-select";

const AdminSwitchingUsersPicker = betterMemo({displayName: "AdminSwitchingUserPicker"}, ({
  user, userPermissions, userById, idToNameOnlyUser, userIdsToLogInAs, onChange
}) => {
  const [selectedUserIds, setSelectedUserIds] = React.useState(userIdsToLogInAs);
  const [excessPermissionsToUserIds, setExcessPermissionsToUserIds] = React.useState(Immutable.Map());

  const missingSelectedUsers = selectedUserIds
      .subtract(userById.keySeq())
      .map(id => idToNameOnlyUser.get(id));

  const getTruncatedGroupBreadcrumbsLabel = groupId => {
    const group = Groups.getGroup(groupId);
    if (!group) {
      return "";
    } else {
      let breadcrumbs = Immutable.fromJS(group.get("breadcrumbs"));
      const shouldTruncate = breadcrumbs.size > 4;
      if (shouldTruncate) {
        breadcrumbs = breadcrumbs.take(2).concat("…").concat(breadcrumbs.takeLast(2));
      }
      return (
          <span
              style={{display: "table-cell", fontSize: "0.7rem", opacity: "60%", marginTop: 5}}>
            {breadcrumbs.map((crumb, index) => {
              const isLastItem = index === breadcrumbs.size - 1;
              if (isLastItem) {
                return <span key={crumb}>{crumb}</span>;
              } else {
                return (
                    <span key={crumb}>
                            {crumb}
                      <i className="bhi-next" style={{paddingLeft: 8, paddingRight: 8}} />
                    </span>);
              }
            })}
        </span>);
    }
  };

  const availableUsers = userById
      .delete(user.get("id"))
      .toList()
      .filterNot(Users.isOrphan)
      .concat(missingSelectedUsers)
      .map(user => user
          .set("isSelected", selectedUserIds.includes(user.get("id")))
          .set("groupBreadcrumbsLabel", getTruncatedGroupBreadcrumbsLabel(user.get("groupId"))))
      .sortBy(user => user.get("fullName"));

  const usersLabel = selectedUserIds.size < 10 ? " - " + selectedUserIds
      .map(id => userById.get(id) ? userById.get(id).get("fullName") : idToNameOnlyUser.get(id).get("fullName"))
      .sort()
      .join(", ") : "";
  const label = selectedUserIds.isEmpty() ? "No Users Selected" : `(${selectedUserIds.size}) ${Pluralize(
      "User",
      selectedUserIds.size,
      false)} Selected${usersLabel}`;

  const onRequestClose = () => {
    if (!userIdsToLogInAs.equals(selectedUserIds)) {
      onChange(selectedUserIds);
    }
  };

  React.useEffect(() => {
    getExcessPermissionsToUserIds(userIdsToLogInAs, userPermissions).then(setExcessPermissionsToUserIds);
  }, [userIdsToLogInAs, userPermissions, getExcessPermissionsToUserIds]);


  const onUserClick = items => setSelectedUserIds(items.map(user => user.get("id")).toSet());

  const hasEmail = user.get("email") && user.get("email") !== "[hidden]";
  const emailLabel = hasEmail ? " (" + user.get("email") + ")" : "";

  return <div style={{paddingLeft: 15}}>
    {!excessPermissionsToUserIds.isEmpty() && <Error
        text={getPermissionsWarning(excessPermissionsToUserIds, userById)}
        type={"warn"} />}
    <InfiniteListSelect
        selectedItems={availableUsers.filter((user) => user.get("isSelected")).map(user => {
          return new Immutable.Map({item: user.get("fullName"), id: user.get("id")});
        }).sortBy(user => user.get("name"))
            .toSet()}
        startItems={availableUsers
            .map(user => {
              return new Immutable.Map({
                item: <div style={{display: "flex", flexDirection: "column", fontSize: "0.9rem"}}>
                  <div>
                    {user.get("fullName")}
                    <span style={{opacity: "60%"}}>{emailLabel}</span>
                    {Users.getSourceUser().get("id") === user.get("id") && <i
                        className="fa fa-home"
                        style={{marginTop: 5, marginLeft: 10}} />}
                  </div>
                  {Users.isCrmUser(user) && <div style={{overflow: "hidden", opacity: "60%", fontSize: "0.7rem"}}>
                    {user.get("originalCrmUserId")}</div>}
                  <div style={{overflow: "hidden"}}>{user.get("groupBreadcrumbsLabel")}</div>
                </div>,
                searchText: [user.get("fullName"), user.get("email"), user.get("originalCrmUserId")].filter(s => s)
                    .join(" - ") + " " + Groups.getGroupBreadcrumbsStr(user.get("groupId"), " "),
                id: user.get("id")
              });
            })
            .toList()}
        onChange={onUserClick}
        onRequestClose={onRequestClose}
        showSelectedInDropdown={true}
        placeholder={label}
        keys={["id", "item", "searchText"]}
        itemHeight={68}
    />
  </div>;
});


const getUserString = (userIds, userById) => {
  const userNames = userIds.map(id => userById.getIn([id, "fullName"]));
  return toCommaSeparatedWithAnd(userNames);
};

const getExcessPermissionsToUserIds = (users, userPermissions) => {
  const orderedUserIds = users.toList();
  const promises = orderedUserIds.map(loadPermissionsForUser);

  return Promise
      .all(promises)
      .then(results => Immutable.fromJS(results)
          .flatMap((permissions, index) => permissions
              .map(permission => Immutable.Map({
                permission: permission,
                userId: orderedUserIds.get(index)
              })))
          .groupBy(map => map.get("permission"))
          .map(permissions => permissions.map(permission => permission.get("userId")))
          .filter((_users, permission) => !userPermissions.includes(permission)));
};

// TODO: This should show a user friendly string instead of the permission name
const getPermissionsWarning = (excessPermissionsToUserId, userById) => {
  return <div style={{marginTop: 5}}>
    <div>This user can log into other users with the following permissions outside their current scope:</div>
    <ul style={{padding: 0, margin: 0}}>
      {excessPermissionsToUserId.map((userIds, permission) => <li>
        {permission}
        <span style={{opacity: "60%"}}>{" (via " + getUserString(userIds, userById) + ")"}</span>
      </li>).toList()}
    </ul>
  </div>;
};

export default AdminSwitchingUsersPicker;