import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import * as Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import Sortable from "react-sortable-mixin";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";
import {Column, Layout, Row} from "js/common/views/foundation-column-layout";

import {TextButton} from "js/common/views/inputs/buttons";
import Slide from "js/admin/cubetv/slide";
import GroupUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import ConfigContainer from "js/admin/cubetv/config-container";
import {getUniqueName} from "js/common/utils/unique-naming";
import {saveChannelToLocalStorage} from "js/admin/cubetv/channels-user-config";
import {formatMillis, getChannelRuntime} from "js/admin/cubetv/channel-time-utils";
import getDefaultLeaderboardSlide from "js/admin/cubetv/leaderboard/default-leaderboard-slide";
import RssFeedPicker from "js/admin/cubetv/rss-feeds/rss-feed-picker";
import * as Groups from "js/common/groups";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

import { TextField } from '@mui/material';

const unsavedChangesMessage = "You have unsaved changes. Don't worry, we'll hold on to them until you come back.";

// NOTE - Mingle ticket #3549: (Big Screen) Carousels === (cubeTV) Channels

const Channel = createReactClass({
    mixins: [PureRenderMixin],

    propTypes: {
        hasUnsavedChanges: ReactPropTypes.bool.isRequired,
        channel: ImmutablePropTypes.map.isRequired,
        onChange: ReactPropTypes.func.isRequired,
        channels: ImmutablePropTypes.list.isRequired,
        timeframes: ImmutablePropTypes.list.isRequired,
        theme: ReactPropTypes.object
    },

    componentDidMount() {
        window.onbeforeunload = () => {
            if (this.props.hasUnsavedChanges) {
                saveChannelToLocalStorage(this.props.channel);
                return unsavedChangesMessage;
            }
        };
    },

    componentWillUnmount() {
        window.onbeforeunload = null;
        if (this.props.hasUnsavedChanges) {
            alert(unsavedChangesMessage);
            saveChannelToLocalStorage(this.props.channel);
        }
    },

    render() {
        const {channel, theme} = this.props;
        const json = channel.get("json");
        const slides = json.get("pages");
        const rssFeedIds = json.getIn(["newsfeeds", "newsfeeds"]);

        return (
            <div>
                <span style={{fontSize: 15, color: theme.palette.primary.main}}>
                    This channel has {slides.count()} slide{slides.count() === 1 ? "" :
                    "s"} and a run time of {formatMillis(getChannelRuntime(channel))}
                </span>
                <br />
                <br />
                {/* this is a stopgap to fix a known issue with React and/or Material-UI for IE11
                    https://github.com/facebook/react/issues/6822 */}
                <style type="text/css" dangerouslySetInnerHTML={{__html: "::-ms-clear {display: none;}"}} />
                <TextField variant="standard"
                    style={{width: 300, marginTop: 15, marginBottom: "0.5rem"}}
                    label="Name"
                    onChange={e => this.props.onChange(channel.set("name", e.target.value.substring(0, 45)))}
                    value={channel.get("name")} />
                <TextField variant="standard"
                    style={{width: "100%", marginTop: 15, marginBottom: "1rem"}}
                    label="Description"
                    onChange={e => this.props.onChange(channel.set("description", e.target.value.substring(0, 256)))}
                    value={channel.get("description")} />
                <ConfigContainer label="News Ticker">
                    <Layout allSmall={12} columnStyle={{marginTop: "1rem"}}>
                        <RssFeedPicker
                            selectedIds={rssFeedIds.toList()}
                            onChange={feeds => {
                                this.props.onChange(channel.setIn(["json", "newsfeeds", "newsfeeds"], feeds));
                            }} />
                    </Layout>
                </ConfigContainer>
                <br />
                <DealFlash
                    theme={theme}
                    dealflash={json.get("dealflash")}
                    onChange={df => this.props.onChange(channel.setIn(["json", "dealflash"], df))} />
                <br />
                <Slides
                    timeframes={this.props.timeframes}
                    slides={this.props.channel.getIn(["json", "pages"])}
                    onChange={slides => this.props.onChange(this.props.channel.setIn(["json", "pages"], slides))}
                    channels={this.props.channels}
                    hasUnsavedChanges={this.props.hasUnsavedChanges} />
            </div>);
    }

});

const Slides = createReactClass({
    mixins: [PureRenderMixin, Sortable.ListMixin],

    propTypes: {
        hasUnsavedChanges: ReactPropTypes.bool.isRequired,
        slides: ReactPropTypes.object.isRequired,
        onChange: ReactPropTypes.func.isRequired,
        channels: ImmutablePropTypes.list.isRequired,
        timeframes: ImmutablePropTypes.list.isRequired
    },

    getInitialState() {
        return {
            expandedSlideId: null
        };
    },

    render() {
        return (
            <ConfigContainer label="Slides">
                <div style={{padding: "1rem"}}>
                    <div>
                        {this.getItems().map((slide, index) => (
                            <Slide
                                handle=".drag-handle"
                                key={slide.get("cid")}
                                index={index}
                                {...this.movableProps}
                                slide={slide}
                                onChange={this.handleChange}
                                onDeleteClick={this.handleDelete}
                                onCloneClick={this.handleClone}
                                numberOfSlides={this.getItems().length}
                                initiallyExpanded={slide.get("cid") === this.state.expandedSlideId}
                                timeframes={this.props.timeframes}
                                channels={this.props.channels}
                                hasUnsavedChanges={this.props.hasUnsavedChanges} />))}
                    </div>
                    <Layout allSmall={12} smallCentered={true} rowStyle={{marginTop: "1rem"}}>
                        <TextButton icon="plus" label="Add Slide" onClick={this.handleAdd} />
                    </Layout>
                </div>
            </ConfigContainer>);
    },

    handleAdd() {
        const {slides, onChange} = this.props;
        const newSlide = getDefaultLeaderboardSlide();

        this.setState({
            expandedSlideId: newSlide.get("cid")
        });
        onChange(slides.push(newSlide));
    },

    handleChange(updatedSlide) {
        const {slides, onChange} = this.props;
        const index = slides.findIndex(s => s.get("cid") === updatedSlide.get("cid"));
        onChange(slides.set(index, updatedSlide));
    },

    handleDelete(slideToDelete) {
        const {slides, onChange} = this.props;
        const index = slides.findIndex(s => s.get("cid") === slideToDelete.get("cid"));
        onChange(slides.delete(index));
    },

    handleClone(slideToClone) {
        const {slides, onChange} = this.props;
        const index = slides.findIndex(s => s.get("cid") === slideToClone.get("cid"));
        const clone = createCloneOfSlide(slideToClone, slides);
        this.setState({
            expandedSlideId: clone.get("cid")
        });
        onChange(slides.insert(index + 1, clone));
    },

    getItems() {
        return this.props.slides.toArray();
    },

    onResorted(slides) {
        this.props.onChange(Immutable.List(slides));
    }

});

const createCloneOfSlide = (slide, slides) => {
    const pathToTitle = ["pageTitle", "title"];
    const initialName = slide.getIn(pathToTitle);
    const existingNames = slides.map(s => s.getIn(pathToTitle));
    const uniqueName = getUniqueName(initialName, existingNames);
    return slide
        .setIn(pathToTitle, uniqueName)
        .set("cid", Math.random())
        .set("id", "legacy-id-" + Math.random());
};

const DealFlash = pure(({
    dealflash,
    onChange,
    theme
}) => {
    const groupIds = dealflash.get("groupIds", Immutable.List());
    const breadcrumbStyle = theme =>({
        display: "inline-block",
        padding: "5px",
        marginRight: "4px",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: "3px",
        fontSize: "15px"
    });
    return (
        <ConfigContainer label="Deal Flash">
            <Row style={{margin: "0.5rem"}}>
                <Column small={12} medium={6} style={{margin: "0.5rem 0"}}>
                    <GroupUserPicker
                        label="Choose which Groups to show Deal Flash for"
                        excludeUsers={true}
                        hierarchy={Groups.getHierarchyWithUsers()}
                        onGroupClick={groupId => {
                            const newGroupIds = groupIds.contains(groupId) ? groupIds : groupIds.push(groupId);
                            onChange(dealflash.set("groupIds", newGroupIds));
                        }} />
                </Column>
                <Column small={12} style={{margin: "0.5rem 0"}}>
                    <span className="info-text">
                        <i className="fa fa-users" aria-hidden="true"></i> &nbsp;
                        {groupIds.count() > 0 ? "Only showing for these Groups and below:" : "Showing for all Groups"}
                    </span>
                </Column>
                <Column small={12} style={{margin: "0.5rem 0"}}>
                    <div>
                        {groupIds
                            .map(groupId => {
                                const breadcrumbs = Groups.getGroupBreadcrumbs(groupId).map(g => g.name).join(" ▶ ");
                                return (
                                    <div key={groupId} style={breadcrumbStyle(theme)}>
                                        {breadcrumbs}
                                        <span
                                            style={{
                                                color: "#888",
                                                cursor: "pointer",
                                                paddingLeft: "5px",
                                                fontWeight: "bold"
                                            }}
                                            onClick={() => {
                                                const index = groupIds.indexOf(groupId);
                                                const newGroupIds = groupIds.remove(index);
                                                onChange(dealflash.set("groupIds", newGroupIds));
                                            }}>
                                            {" x "}
                                        </span>
                                        <br />
                                    </div>);
                            })
                            .toArray()}
                    </div>
                </Column>
            </Row>
        </ConfigContainer>);
});

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <Channel theme={theme} {...props} />;
};