import FileSaver from "browser-filesaver";
import Immutable from "immutable";

const formulaChars = Immutable.Set.of("=", "@", "-", "+", "|", "%");

const quote = str => "\"" + str + "\"";

export default (rows, name = "Data.csv") => {
	const rowsStr = rows
            .map(row => row
                .map(cell => {
                    cell = (cell + "");
                    cell = cell.trim();
                    cell = cell.replace(/\"/g, "\"\"");
                    if (cell.length > 0 && formulaChars.includes(cell.charAt(0))) {
                        if (cell.charAt(0) === "=") {
                            return quote(cell.substring(1));
                        } else {
                            return quote("\t" + cell);
                        }
                    } else {
                        return quote(cell);
                    }
                })
                .join(","))
            .join("\r\n")

    const byteOrderMark = "\uFEFF";
    const csv = byteOrderMark + rowsStr;
    const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
    FileSaver.saveAs(blob, name);
};
