import React from "react";
import moment from "moment";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import pure from "js/common/views/pure";
import PureRenderMixin from "react-addons-pure-render-mixin";

import Dropzone from "js/common/views/inputs/dropzone";
import Tabs from "js/common/views/tabs";
import AdminHeader from "js/admin/common/admin-header";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import DateRangePicker from "js/admin/actuals/date-range-picker";
import UploadedFilesTable from "js/admin/actuals/uploaded-files-table";
import ErrorMsg from "js/common/views/error";
import {Layout} from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import Select from "js/common/views/inputs/immutable-react-select";
import LoadingSpinner from "js/common/views/loading-spinner";
import SelectField from "@mui/material/Select";
import {endsWith} from "js/common/utils/strings";

import * as Popups from "js/common/popups";
import * as Ajax from "js/common/ajax";
import * as Users from "js/common/users";
import * as Locales from "js/common/locales";
import {MenuItem} from "@mui/material";
import dropzoneArrow from "img/dropzone-arrow.png";
import {apiUrl} from "js/app-configuration";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants";
import * as auditor from "js/common/auditer";

const csvStyles = Immutable.fromJS([
  {
    id: "COMMA",
    label: "Comma ,",
    separator: ","
  }, {
    id: "DECIMAL",
    label: "Decimal .",
    separator: "."
  }, {
    id: "SEMICOLON",
    label: "Semi-colon ;",
    separator: ";"
  }]);

const idToCsvStyle = csvStyles.groupBy(x => x.get("id")).map(xs => xs.first());

const labelsByActualsCategory = {
  PLACEMENTS: "Placement Actuals",
  JOBS: "Job Actuals"
};

const App = createReactClass({

  getInitialState() {
    return {
      activeTabIndex: 0
    };
  },

  render() {
    const {theme} = this.props;
    const tabs = [
      {
        title: "Placement actuals",
        content: <Actuals category="PLACEMENTS" theme={theme} />
      }, {
        title: "Job actuals",
        content: <Actuals category="JOBS" theme={theme} />
      }];
    return <Tabs
        selectedIndex={this.state.activeTabIndex}
        onChangeTab={index => this.setState({activeTabIndex: index})}
        tabs={tabs}
        saveTabStateOnChange={false}
    />;
  }

});


export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <App theme={theme} {...props} />;
};

const Actuals = createReactClass({
  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      isUploading: false,
      isCsvFormattingOpen: false,
      dataReplaceTypes: Immutable.Set(),
      dataReplaceStartDate: null,
      dataReplaceEndDate: null,
      csvStyleId: "COMMA",
      file: null,
      types: Immutable.List(),
      loadingLocales: false,
      idToLocale: Immutable.Map(),
      selectedLocaleId: Users.getInherited(Users.getCurrentUser(), x => x.get("localeId"))
    };
  },

  componentDidMount() {
    this.setState({loadingLocales: true});

    Locales
        .loadAll()
        .then(locales => {
              this.setState({
                idToLocale: locales.groupBy(l => l.get("id")).map(ls => ls.first()),
                loadingLocales: false
              });
              auditor.audit("actuals-admin:loaded");
            }
        );
    loadActualsTypes(this.props.category.toLowerCase()).then(types => this.setState({types}));
  },

  render() {
    if (this.state.loadingLocales || this.state.idToLocale.isEmpty()) {
      return <LoadingSpinner />;
    }

    const {
      file,
      dataReplaceStartDate,
      dataReplaceEndDate,
      dataReplaceTypes,
      isCsvFormattingOpen,
      types
    } = this.state;
    const {category, theme} = this.props;
    const actualsCategoryLabel = labelsByActualsCategory[category];
    const hasFile = !!file;
    const fileName = hasFile && file.name;
    const isCsvFile = hasFile && endsWith(".csv", fileName.toLowerCase());
    const isValidDateRange = isDateRangeValid(dataReplaceStartDate, dataReplaceEndDate);
    const spacing = {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    };
    const headerStyle = theme => ({
      fontSize: "0.875rem",
      fontWeight: "normal",
      color: theme.palette.primary.main,
      marginBottom: "0.5rem"
    });
    return (
        <div>
          <AdminHeader>
            Add new {actualsCategoryLabel} data or replace current data within a time period
          </AdminHeader>

          <ExampleFileDownload
              actualsCategory={category.toLowerCase()}
              idToLocale={this.state.idToLocale}
              selectedLocaleId={this.state.selectedLocaleId}
              onChangeLocaleId={localeId => this.setState({selectedLocaleId: localeId})}
              theme={theme}
          />

          <Layout allSmall={12} allMedium={10} smallCentered={true} columnStyle={spacing}>
            <Dropzone
                onDrop={files => this.setState({file: files[0]})}
                multiple={false}
                disablePreview={false}
                className="react-dropzone"
                activeClassName="react-dropzone-active"
                style={{
                  minHeight: file ? 40 : 120,
                  borderStyle: file ? "solid" : "dashed",
                  padding: 5,
                  borderColor: theme.themeId === "light" ? "#b7b7b8" : "#fff"
                }}
                activeStyle={{borderStyle: "dashed"}}>
              {file ?
                  <FilePreview file={file} onRemoveBtnClick={this.onRemoveFileClick} /> :
                  <HelpText theme={theme} actualsCategory={category.toLowerCase()} />}
            </Dropzone>
            {(hasFile && !isCsvFile) &&
                <ErrorMsg text={`You can only upload CSV (.csv) files. '${fileName}' is not a CSV file.`} />}
          </Layout>

          <Layout allSmall={12} rowStyle={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
            <h5 style={headerStyle(theme)}>
              <i className="fa fa-cogs" style={{paddingRight: 8}} />
              Limit data to be replaced
            </h5>
            <Hint>
              <Icon icon="info" style={{color: theme.palette.hints.text}} />
              If replacing data in {Branding.brandingName}, data for all actuals types will be deleted and replaced by
              the newly uploaded data for the date range set unless specific actuals types are selected.
            </Hint>
          </Layout>
          <Layout allSmall={12} medium={[6, 5]} floatLastColumnLeft={true} columnStyle={spacing}>
            <DateRangePicker
                startDate={dataReplaceStartDate}
                endDate={dataReplaceEndDate}
                onStartDateChange={startDate => this.setState({dataReplaceStartDate: startDate})}
                onEndDateChange={endDate => this.setState({dataReplaceEndDate: endDate})} />
            <div>
              <label style={{cursor: "default"}}>Replace data for</label>
              <Select
                  isMulti={true}
                  isSearchable={true}
                  placeholder="All actuals types"
                  isDisabled={!isValidDateRange}
                  selectedValues={Immutable.List(dataReplaceTypes.toArray())}
                  options={types.map(type => (Immutable.Map({label: type, value: type})))}
                  onChange={selectedTypes => {
                    this.setState({dataReplaceTypes: selectedTypes});
                  }} />
              <Hint style={{marginTop: "0.5em"}}>
                <Icon icon="info" style={{color: theme.palette.hints.text}} />
                Set date range first before selecting actuals types to replace
              </Hint>
            </div>
          </Layout>
          {dataReplaceStartDate && this.renderDataReplacementWarning()}

          <Layout
              allSmall={12}
              collapseRow={true}
              rowStyle={{
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
                border: `2px solid ${theme.palette.borderColor}`,
                borderRadius: 4
              }}>
            <ClickableHeader
                theme={theme}
                label={"Change CSV file separator formatting (currently using '" +
                    idToCsvStyle.get(this.state.csvStyleId).get("label") + "')"}
                isActive={isCsvFormattingOpen}
                onClick={() => this.setState({isCsvFormattingOpen: !isCsvFormattingOpen})} />
            {isCsvFormattingOpen && this.renderCsvFileFormattingOptions()}
          </Layout>

          {isCsvFile && this.renderUploadFileButton()}

          <UploadedFilesTable actualsCategory={category.toLowerCase()} />
        </div>
    );
  },

  renderCsvFileFormattingOptions() {
    const csvStyle = idToCsvStyle.get(this.state.csvStyleId);
    const valueSeparator = csvStyle.get("separator");

    return (
        <div style={{padding: "1rem"}}>
          <div style={{width: "30rem"}}>
            <Select
                isMulti={false}
                isSearchable={false}
                isClearable={false}
                placeholder="Choose CSV separators"
                selectedValue={this.state.csvStyleId}
                options={csvStyles}
                keys={["id", "label"]}
                onChange={csvStyleId => this.setState({csvStyleId})} />
          </div>
          <p style={{margin: 0, padding: "0.5rem"}}>
            You usually format the
            values <strong>"hello"</strong>, <strong>"world"</strong> and <strong>"test"</strong> in a CSV like this:
            <br /><strong>hello{valueSeparator}world{valueSeparator}test</strong>
          </p>
        </div>
    );
  },

  renderUploadFileButton() {
    const {file, dataReplaceStartDate, dataReplaceEndDate, isUploading} = this.state;
    const id = `${this.props.category.toLowerCase()}-actuals-dropzone`;
    const label = `Upload ${file.name}`;
    const isValidDateRange = isDateRangeValid(dataReplaceStartDate, dataReplaceEndDate);
    const isEnabled = ((!dataReplaceStartDate && !dataReplaceEndDate) || isValidDateRange) && !isUploading;

    return (
        <Layout
            allSmall={12}
            allMedium={10}
            largeCentered={true}
            columnStyle={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
          <TextButton
              id={id}
              icon="upload"
              label={label}
              disabled={!isEnabled}
              onClick={this.onUploadFileClick}
              style={{marginBottom: 0}} />
          {isUploading && <LoadingSpinner />}
        </Layout>
    );
  },

  renderDataReplacementWarning() {
    const {theme} = this.props;
    const style = {
      margin: "0.5rem 1rem",
      padding: "1rem",
      border: `1px solid ${theme.palette.primary.main}`,
      textAlign: "left"
    };
    return (
        <Layout allSmall={12} collapseRow={true} rowStyle={style}>
          <p>Please ensure that the file you are uploading contains all the necessary records for this period. This file
            cannot contain records outside of this date range.</p>
          <TextButton label="Undo data replacement settings" onClick={this.undoDataReplaceSettings} />
        </Layout>
    );
  },

  undoDataReplaceSettings() {
    this.setState({
      dataReplaceStartDate: null,
      dataReplaceEndDate: null,
      dataReplaceTypes: this.state.dataReplaceTypes.clear()
    });
  },

  onRemoveFileClick(e) {
    e.stopPropagation();
    this.setState({file: null});
  },

  onUploadFileClick() {
    const {
      dataReplaceStartDate,
      dataReplaceEndDate,
      dataReplaceTypes
    } = this.state;
    const {theme} = this.props;
    const actualsCategoryLabel = labelsByActualsCategory[this.props.category];
    const isValidDateRange = isDateRangeValid(dataReplaceStartDate, dataReplaceEndDate);
    if (isValidDateRange) {
      const dateRange = dataReplaceStartDate.format("L") + " to " + dataReplaceEndDate.format("L");
      const actualsTypes = !dataReplaceTypes.isEmpty()
          ?
          ` for actuals types <strong style='color:${theme.palette.primary.main}'>${dataReplaceTypes.join(", ")}</strong> `
          :
          ` for <strong style='color:${theme.palette.primary.main}'>ALL</strong> actuals types `;
      const message = `<p><strong style='color:${theme.palette.primary.main}'>`
          + actualsCategoryLabel
          + "</strong> data in the system"
          +
          actualsTypes
          + "between these dates will be deleted prior to processing this file:</p>"
          +
          `<p><strong style='color:${theme.palette.primary.main}'>`
          + dateRange
          + "</strong></p>";
      Popups.confirm(message, {
        title: "Warning",
        onok: () => this.uploadFile()
      });
    } else {
      this.uploadFile();
    }
  },

  uploadFile() {
    const {
      file,
      dataReplaceStartDate,
      dataReplaceEndDate,
      dataReplaceTypes
    } = this.state;
    this.setState({isUploading: true});
    const category = this.props.category.toLowerCase();
    const hasDateRange = dataReplaceStartDate && dataReplaceEndDate;
    const mysqlDateFormat = "YYYY-MM-DD";
    const startDate = dataReplaceStartDate ? dataReplaceStartDate.format(mysqlDateFormat) : "";
    const endDate = dataReplaceEndDate ? dataReplaceEndDate.format(mysqlDateFormat) : "";
    let typesToReplace = "";
    if (hasDateRange && dataReplaceTypes.isEmpty()) {
      typesToReplace = JSON.stringify([]);
    }
    if (hasDateRange && !dataReplaceTypes.isEmpty()) {
      typesToReplace = JSON.stringify(dataReplaceTypes);
    }
    const valueSeparator = idToCsvStyle.get(this.state.csvStyleId).get("separator");
    window.superagent
        .post(`${apiUrl}/ApplicationLayer/actuals/${category}/upload`)
        .withCredentials()
        .field("cube19-replace-start", startDate)
        .field("cube19-replace-end", endDate)
        .field("cube19-replace-types", typesToReplace)
        .field("cube19-value-separator", valueSeparator)
        .field("locale-code", this.state.idToLocale.get(this.state.selectedLocaleId).get("code"))
        .attach("file", file)
        .end((error, response) => {
          if (error) {
            if (error.status === 504) {
              const timeoutWarning = "The server is taking longer than expected to process your file.<br />" +
                  "The file will continue processing for another 4 minutes.<br />" +
                  "Please check back in 5 minutes and if the file is still 'PROCESSING', please contact support.";
              Popups.alert(timeoutWarning);
            } else if (error.status === 413) {
              Popups.error("File exceeds max size of 20mb");
            } else if (response && response.body && response.body.message) {
              Popups.error(response.body.message);
            } else {
              Popups.contactSupport();
            }
          } else {
            this.setState({
              isCsvFormattingOpen: false,
              dataReplaceTypes: dataReplaceTypes.clear(),
              dataReplaceStartDate: null,
              dataReplaceEndDate: null,
              file: null
            });
            Popups.success("Your file is now queued for processing");
          }
          this.setState({isUploading: false});
        });
  }

});

const ClickableHeader = pure(({theme, label, isActive, onClick}) => {
  const headerStyle = theme => ({
    cursor: "pointer",
    fontSize: "0.875rem",
    fontWeight: "normal",
    padding: "0.5rem",
    color: theme.palette.textColor,
    backgroundColor: isActive ? theme.palette.background.card : "transparent",
    transition: "all 0.3s ease",

    ":hover": {
      backgroundColor: theme.palette.background.card
    }
  });
  return (
      <h5 id="change-csv-formatting" style={headerStyle(theme)} onClick={onClick}>
        {label}
        <i
            className={`fa fa-${isActive ? "minus" : "plus"}`}
            style={{float: "right", paddingRight: "0.5rem", lineHeight: 1.4}} />
      </h5>
  );
});

const ExampleFileDownload = pure(({actualsCategory, idToLocale, selectedLocaleId, onChangeLocaleId, theme}) => {
  const url = `${apiUrl}/ApplicationLayer/actuals/${actualsCategory}`;
  const localeCode = idToLocale.get(selectedLocaleId).get("code");
  return (
      <Layout allSmall={12}>
        <div style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
          <SelectField
              variant="standard"
              value={selectedLocaleId}
              onChange={e => onChangeLocaleId(e.target.value)}
              style={{verticalAlign: "middle"}}>
            {idToLocale.valueSeq().sortBy(l => l.get("name")).map(localeToMenuItem)}
          </SelectField>
          <DownloadButton
              id={`${actualsCategory}-template-file`}
              label="Blank template CSV file"
              link={`${url}/blank.csv?localeCode=${localeCode}`} />
          <DownloadButton
              id={`${actualsCategory}-example-file`}
              label="Example CSV file"
              link={`${url}/example.csv?localeCode=${localeCode}`} />
        </div>
        <Hint>
          <Icon icon="info" style={{color: theme.palette.hints.text}} />
          The Header Row is required to process the CSV file. Please make sure it has not been removed before uploading
          your updated file.
        </Hint>
      </Layout>
  );
});
const DownloadButton = pure(({
  id,
  label,
  link
}) => <TextButton
    id={id}
    icon="download"
    label={label}
    link={link}
    style={{marginBottom: "0.5rem", marginTop: "0.5rem", marginLeft: "1rem", marginRight: "1rem"}} />);

const HelpText = pure(({theme, actualsCategory}) => (
    <div style={{textAlign: "center", color: "#bbb", paddingTop: "1rem"}}>
      <img src={dropzoneArrow} alt={"File dropzone"} />
      <p>Drop
        your <span style={{color: theme.palette.primary.main}}>{labelsByActualsCategory[actualsCategory]}</span> data
        file here or
        click to choose a file</p>
    </div>
));

export const FilePreview = pure(({file, onRemoveBtnClick}) => (
    <div>
      <span><i className="fa fa-file-excel-o" /> {file.name}</span>
      <TextButton
          id="remove-file"
          icon="remove"
          label="Remove"
          onClick={onRemoveBtnClick}
          style={{
            display: "inline-block",
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            marginLeft: "1rem",
            marginRight: "1rem"
          }} />
    </div>
));

const localeToMenuItem = locale => <MenuItem
    key={locale.get("id")}
    value={locale.get("id")}>
  {`${locale.get("name")} Dates - ${moment().locale(locale.get("code")).localeData().longDateFormat("L")}`}
</MenuItem>;

const isDateRangeValid = (startDate, endDate) => {
  const isValidStartDate = startDate && startDate.isValid();
  const isValidEndDate = endDate && endDate.isValid();
  return (isValidStartDate && isValidEndDate) && startDate.isSameOrBefore(endDate);
};

const loadActualsTypes = category => Ajax
    .get({url: `actuals/${category}/actual-types`})
    .then(result => Immutable.fromJS(result)
        .filter(type => type.length > 0)
        .sortBy(x => x.toLowerCase()));
