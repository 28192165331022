import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

import KpiDataDisplay from "js/onpoint/kpi-data-display";

import * as Users from "js/common/users";
import * as Colors from "js/common/cube19-colors";

const scrollBarWidth = "17px";

const flexBoxWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    alignContent: "space-around"
};

const columnStyle = {
    padding: 0,
    marginTop: 0,
    marginRight: "0.25rem",
    marginBottom: 0,
    marginLeft: "0.25rem",
    ...flexBoxWrapperStyle
};

const labelStyle = {
    lineHeight: "40px"
};

const KpiColumn = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            groupsKpiData,
            usersKpiData,
            theme
        } = this.props;
        const borderTopStyle = `1px solid ${theme.palette.background.paper}`;
        const userCellsContainerStyle = {
            marginTop: groupsKpiData.isEmpty() ? 0 : "0.5rem",
            borderTop: usersKpiData.isEmpty() ? 0 : borderTopStyle
        };
        return (
            <div style={{ ...columnStyle, borderTop: borderTopStyle }}>
                <div style={{ marginBottom: groupsKpiData.isEmpty() ? 0 : "0.5rem" }}>
                    {groupsKpiData.map(this.renderGroupKpiDataCell)}
                </div>
                <div style={userCellsContainerStyle}>
                    {usersKpiData.map(this.renderUserKpiDataCell)}
                </div>
            </div>
        );
    },

    renderGroupKpiDataCell(groupKpiData) {
        const { kpi, viewType, onCellClick, cellHeight, width, borderStyle, theme } = this.props;
        const kpiId = kpi.get("id");
        const groupId = groupKpiData.get("groupId");
        const dataByKpiId = groupKpiData.get("dataByKpiId");
        const cell = Immutable.Map({ groupId, kpiId });
        return (
            <KpiDataCell
                theme={theme}
                key={`group-${groupId}-kpi-${kpiId}`}
                kpiData={dataByKpiId.get(kpiId)}
                onClick={() => onCellClick(cell)}
                viewType={viewType}
                valueFormatType={kpi.getIn([ "type", "valueFormat" ])}
                cellHeight={cellHeight}
                width={width}
                backgroundColor={theme.palette.background.card}
                borderStyle={borderStyle}
                color={theme.palette.text.primary} />
        );
    },

    renderUserKpiDataCell(userKpiData) {
        const { kpi, viewType, onCellClick, cellHeight, width, borderStyle, theme } = this.props;
        const kpiId = kpi.get("id");
        const userId = userKpiData.get("userId");
        const dataByKpiId = userKpiData.get("dataByKpiId");
        const cell = Immutable.Map({ userId, kpiId });
        return (
            <KpiDataCell
                theme={theme}
                key={`user-${userId}-kpi-${kpiId}`}
                kpiData={dataByKpiId.get(kpiId)}
                onClick={() => onCellClick(cell)}
                viewType={viewType}
                valueFormatType={kpi.getIn([ "type", "valueFormat" ])}
                cellHeight={cellHeight}
                width={width}
                backgroundColor={theme.palette.background.card}
                borderStyle={borderStyle}
                color={theme.palette.text.primary} />
        );
    }

});

const KpiDataCell = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const { kpiData, onClick, cellHeight, width, backgroundColor, color } = this.props;
        const cellStyle = {
            height: cellHeight,
            width,
            padding: `0.25rem ${scrollBarWidth}`,
            textAlign: "center",
            backgroundColor: backgroundColor,
            marginBottom: "5px",
            borderRadius: "5px",
            borderLeftColor: Colors.greyDark,
            borderRightColor: Colors.greyDark,
            borderBottomColor: Colors.greyDark
        };
        if (kpiData) {
            const isLoadingData = kpiData.get("isLoading");
            const isClickable = Users.currentHasPermission("CLICK_THROUGH") && !isLoadingData && !kpiData.has("error");
            const finalCellStyle = {
                ...cellStyle,
                color: isLoadingData ? "#999" : color,
                cursor: isClickable ? "pointer" : "default"
            };
            return (
                <div style={finalCellStyle} onClick={isClickable ? e => onClick(e) : () => {}}>
                    {this.renderKpiDataDisplay()}
                </div>
            );
        } else {
            return <div style={{ ...cellStyle, ...labelStyle, color: "#999" }}> -- </div>;
        }
    },

    renderKpiDataDisplay() {
        const { viewType, valueFormatType, kpiData, theme } = this.props;
        if (kpiData.get("isLoading")) {
            return <div style={labelStyle}>LOADING...</div>;
        } else if (kpiData.has("error")) {
            return <div style={labelStyle}>Error</div>;
        } else {
            return (
                <KpiDataDisplay
                    theme={theme}
                    viewType={viewType}
                    valueFormatType={valueFormatType}
                    kpiData={kpiData}
                    labelStyle={labelStyle} />
            );
        }
    }

});


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <KpiColumn theme={theme} {...props} />;
  };
  
  export default Wrapper;