import Immutable from "immutable";

import * as DashboardUtils from "js/dashboards/utils";
import {getDefaultConfigForTrendChart} from "js/charting/charting-defaults";

// TODO kpi ids cannot appear here
//    paybill reports are confusing to give without kpis already set
//    config stores kpi ids or master metrics
//    whats the edit workflow?
//      do we stamp master metrics in as kpi ids on component add?
//      do we maintain master metric support only for our own library components?
//      do we allow user to pick kpis and/or master metrics in component config?
//        2 pickers?
//        1 picker with two options for any kpi set as master metric?
//        1 picker and when you pick a kpi set as a master metric it uses the master metric type instead of the kpi id

const getComponents = () => Immutable.fromJS([{
  category: "Metrics",
  label: "Metric",
  description: "A metric total against any target that has been set",
  componentConfig: {
    type: "MetricCurve",
    dataConfig: {
      inheritKeys: DashboardUtils.defaultInheritKeys
    }
  }
},
  {
    category: "Metrics",
    label: "Ratio",
    description: "A comparison of two metrics",
    componentConfig: {
      type: "Ratio",
      dataConfig: {
        inheritKeys: DashboardUtils.defaultInheritKeys
      }
    }
  },
  {
    category: "Metrics",
    label: "Ratio Comparison",
    description: "Compare multiple ratios",
    componentConfig: {
      type: "RatioComparison",
      title: "New Ratio Comparison",
      fullScreen: false,
      dataConfig: {
        inheritKeys: DashboardUtils.defaultInheritKeys,
        ...getDefaultConfigForTrendChart("Trend", 1),
        trendType: "ADVANCED",
        ratiosList: [],
        maxRange: "100-percent"
      },
    }
  },
  {
    category: "Grids",
    label: "Performance Snapshot",
    description: "AKA Mini OnPoint",
    componentConfig: {
      type: "PerformanceSnapshot",
      fullScreen: false,
      dataConfig: {
        inheritKeys: DashboardUtils.defaultInheritKeys,
        ratiosList: [],
        filterModel: {},
        kpiIds: [],
        colOrder: [],
      }
    }
  },
  {
  category: "Grids",
  label: "Small Data",
  description: "A grid of fewer than 20,000 rows",
  componentConfig: {
    type: "UiGrid",
    fullScreen: false,
    dataConfig: {
      inheritKeys: DashboardUtils.defaultInheritKeys,
      chart: {
        position: "NONE",
        type: "stackedColumn",
      },
      filterModel: {},
      columnStates: [{
        colId: "Owner",
        rowGroup: true
      },{
        colId: "Kpi Name",
        pivot: true
      },{
        colId: "Kpi Value",
        hide: false,
        aggFunc: "sum"
      }],
      kpiIds: [],
      colOrder: []
    }
  }
}, {
  category: "Grids",
  label: "Big Data",
  description: "A grid of more than 20,000 rows",
  componentConfig: {
    type: "AsyncServerGrid",
    fullScreen: false,
    dataConfig: {
      inheritKeys: DashboardUtils.defaultInheritKeys,
      chart: {
        position: "NONE",
        type: "stackedColumn",
      },
      filterModel: {},
      columnStates: [{
        colId: "Owner",
        rowGroup: true
      },{
        colId: "Kpi Name",
        pivot: true
      },{
        colId: "Kpi Value",
        hide: false,
        aggFunc: "sum"
      }],
      kpiIds: [],
      colOrder: [],
    }
  },
},
{
   category: "Charts",
   label: "Trend",
   description: "View metrics or ratios over time",
   componentConfig: {
     type: "Trend",
     fullScreen: false,
     title: "New Trend Chart",
     dataConfig: {
       inheritKeys: DashboardUtils.defaultInheritKeys,
       ...getDefaultConfigForTrendChart("Trend", 1),
       trendType: "ADVANCED",
       trendValueType: "metric",
       ratiosList: [],
     },
   },
},
  {
    category: "Layout",
    label: "Heading",
    description: "Add headings to your dashboards",
    componentConfig: {
      type: "Text",
      dataConfig: {
        inheritKeys: DashboardUtils.defaultInheritKeys,
        text: "Enter some text",
        textBorder: "BOTTOM",
        textAlignment: "LEFT",
      }
    },
  },
]);

export default getComponents;