import React, {memo} from "react";
import {IconButton} from "js/common/views/inputs/buttons";
import {Handle} from "react-flow-renderer";
import * as Colors from "js/common/cube19-colors";
import {getNodeHandleStyle} from "js/squids/styles/styles";


const AddParent = ({onClick}) => {
  return <IconButton
      icon="plus"
      onClick={onClick}
      style={{
        backgroundColor: Colors.java,
        color: "white",
        borderRadius: "50%",
        width: 20,
        height: 20,
        position: "absolute",
        top: "calc(50% - 10px)",
        left: -30
      }}
      size="small" />;
};

const AddChild = ({onClick}) => {
  return <IconButton icon="plus"
                     onClick={onClick}
                     style={{
                       backgroundColor: Colors.java,
                       color: "white",
                       borderRadius: "50%",
                       width: 20,
                       height: 20,
                       position: "absolute",
                       top: "calc(50% - 10px)",
                       right: -30
                     }}
                     size="small" />;
};

const nodeStyle = (theme) => {
  const nodeStyle = theme && getNodeHandleStyle(theme);
  return nodeStyle ? nodeStyle.toJS() : {
    background: Colors.java
  }
};

const CentralNode = memo(({data}) => {
  return (
      <>
        <Handle
            type="target"
            position="left"
            style={nodeStyle(data.theme)}
        />
        <div style={{display: "flex", width: "100%", height: "100%", padding: 10}}>
          {data.mode === "EDIT" && <AddParent onClick={data.addParent} />}
          <div onClick={data.editNode} style={{display: "flex", alignItems: "center", width: "100%"}}>
            {data.label}
          </div>
          {data.mode === "EDIT" && <AddChild onClick={data.addChild} />}
        </div>
        <Handle
            type="source"
            position="right"
            style={nodeStyle(data.theme)}
        />
      </>
  );
});

const InputNode = memo(({data}) => {
  return (
      <>
        <div style={{display: "flex", width: "100%", height: "100%"}}>
          {data.mode === "EDIT" && <AddParent onClick={data.addParent} />}
          <div onClick={data.editNode} style={{display: "flex", alignItems: "center", width: "100%"}}>
            {data.label}
          </div>
          {data.mode === "EDIT" && <AddChild onClick={data.addChild} />}
        </div>
        <Handle
            type="source"
            position="right"
            style={nodeStyle(data.theme)}
        />
      </>
  );
});

const OutputNode = memo(({data}) => {
  return (
      <>
        <Handle
            type="target"
            position="left"
            style={nodeStyle(data.theme)}
        />
        <div style={{display: "flex", width: "100%", height: "100%"}}>
          {data.mode === "EDIT" && <AddParent onClick={data.addParent} />}
          <div onClick={data.editNode} style={{display: "flex", alignItems: "center", width: "100%"}}>
            {data.label}
          </div>
          {data.mode === "EDIT" && <AddChild onClick={data.addChild} />}
        </div>
      </>
  );
});

const DefaultNode = memo(({data}) => {
  return (
      <>
        <div style={{display: "flex", width: "100%", height: "100%"}}>
          {data.mode === "EDIT" && <AddParent onClick={data.addParent} />}
          <div onClick={data.editNode} style={{display: "flex", alignItems: "center", width: "100%"}}>
            {data.label}
          </div>
          {data.mode === "EDIT" && <AddChild onClick={data.addChild} />}
        </div>
      </>
  );
});

export {CentralNode, InputNode, OutputNode, DefaultNode};