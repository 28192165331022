import React from "react";

import {betterMemo} from "js/common/utils/more-memo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import companyLogoLightModeDefault from "img/logo/light-mode-vertical.svg";
import companyLogoDarkModeDefault from "img/logo/dark-mode-vertical.svg";

const CompanyLogo = betterMemo(
    {displayName: "CompanyLogo"},
    ({client, ...props}) => {
      const {theme} = React.useContext(CustomThemeContext);
      const companyLogoDefault = theme.themeId === "light" ? companyLogoLightModeDefault : companyLogoDarkModeDefault;
      return <img
          src={client.get("logoURL") || companyLogoDefault}
          onError={event => event.target.src = companyLogoDefault}
          alt={"Company logo"}
          {...props} />;
    });

export default CompanyLogo;
