import {css} from "@emotion/react";
import * as Colors from "js/common/cube19-colors";

export const viewContainer = (theme) => css`
  display: flex;
  flex-direction: column;
  color: ${theme.palette.text.main};
  border-radius: 10px;
  max-width: ${theme.themeId === "light" ? "100%" : "90em"};
  width: 100%;
  padding-left: 0.9375em;
  padding-right: 0.9375em;
`;

export const viewControls = (theme) => css`
  background: ${theme.palette.background.card};
  display: flex;
  justify-content: space-between;
  margin: ${theme.themeId === "light" ? "0 -0.9375em 30px -0.9375em" : "0 0 30px 0"};
  padding: ${theme.themeId === "light" ? "0 0.9375em 5px 0.9375em" : "0"};
  box-shadow: ${theme.themeId === "light" ? "rgb(0 0 0 / 20%) 0px 2px 1px -1px" : "none"};
`;

export const viewToggle = (theme) => css`
  border: none !important;
  background-color: ${theme.palette.background.card};
  border-radius: 5px !important;

  .MuiToggleButton-root {
    background-color: ${theme.palette.background.card};
    color: ${theme.palette.text.main};
    border: none !important;
    &:first-of-type {
      padding-left: 15px;
    }
    &:last-of-type {
      padding-right: 15px;
    }
    &.Mui-selected {
      background-color: ${theme.palette.background.card};
      color: ${theme.palette.primary.main}
    }
  }
`;
