/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import ReactTooltip from "react-tooltip";
import getContainerDimensions from "react-dimensions";
import {Card} from "@mui/material";

import Header from "js/oneview/section-header";
import LoadingSpinner from "js/oneview/loading-spinner";
import Layout from "js/common/views/foundation-column-layout";
import {clamp} from "js/common/utils/numbers";
import {css} from "@emotion/react";
import pure from "js/common/views/pure";
import * as ratioFormatter from "js/common/utils/ratio-formatter";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import {timeframeAffectsKpiData} from "js/oneview/kpi-sections/kpi-summary";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Ratios = getContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],
  displayName: "Ratios",

  propTypes: {
    ratioAssignments: ImmutablePropTypes.list.isRequired,
    onRatioClick: ReactPropTypes.func.isRequired,
    containerWidth: ReactPropTypes.number.isRequired,
    kpiDataById: ImmutablePropTypes.map,
    clientIds: ImmutablePropTypes.set
  },

  render() {
    const hasRatios = !this.props.ratioAssignments.isEmpty();
    const NO_RATIOS_MESSAGE = <p>No Ratios have been configured</p>;
    return (
        <div>
          <Layout allSmall={12}>
            <Header label="Ratios" />
          </Layout>
          <div className="row" style={{padding: "0 15px"}}>
            {hasRatios ? this.renderRatios() : NO_RATIOS_MESSAGE}
          </div>
        </div>);
  },

  renderRatios() {
    const {
      containerWidth,
      ratioAssignments,
      kpiDataById,
      clientIds,
      onRatioClick,
      currentTimeframe,
      theme
    } = this.props;
    const {palette} = theme;
    const configs = ratioAssignments
        .sortBy(assignment => assignment.get("order"))
        .map(assignment => assignment.get("ratio"));
    return (
        <ul className={`block-grid-${calculateColumnsForWidth(containerWidth)}`}
            style={{listStyleType: "none"}}>
          {configs
              .map(config => {
                const id = config.get("id");
                const ratioData = Immutable.Map()
                    .set("firstKpiData", kpiDataById.get(config.get("firstKpiId")))
                    .set("secondKpiData", kpiDataById.get(config.get("secondKpiId")));

                return (
                    <Ratio
                        key={id}
                        config={config}
                        ratioData={ratioData}
                        clientIds={clientIds}
                        currentTimeframe={currentTimeframe}
                        onClick={() => onRatioClick(config, Immutable.fromJS(ratioData.toJS()))}
                        palette={palette} />
                );
              })}
        </ul>);
  }
}));

const MIN_WIDTH = 220;
const MIN_COLUMNS = 1;
const MAX_COLUMNS = 5;
const calculateColumnsForWidth = width => {
  return clamp(
      Math.floor(width / MIN_WIDTH),
      MIN_COLUMNS,
      MAX_COLUMNS);
};

const ratioNameStyle = {
  display: "block",
  width: "100%",
  height: "4em",
  fontSize: ".9rem",
  overflow: "hidden",
  textWrap: "none",
  textOverflow: "ellipsis"
};

const ratioValueStyle = {
  fontSize: "2em",
  fontWeight: "500",
};

const Ratio = pure(
    ({
      config,
      ratioData,
      clientIds,
      currentTimeframe,
      onClick,
      palette
    }) => {
      const hasClientFilter = clientIds && clientIds.count() > 0;
      const tooltipId = `ratio-${config.get("id")}`;
      const ratioCube = css`
        cursor: pointer;
        height: 180px;
        text-align: center;
        padding: 1em;
        background-color: ${palette.background.card};
        box-shadow: 0 -1px 3px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        &:hover {
          box-shadow: 0 0 0 2px ${palette.primary.light};
        }
      `;
      if (hasClientFilter) {
        const isFirstKpiClientFilterable = kpiRepo.get(config.get("firstKpiId")).get("filterableByClient");
        const isSecondKpiClientFilterable = kpiRepo.get(config.get("secondKpiId")).get("filterableByClient");

        if (!isFirstKpiClientFilterable || !isSecondKpiClientFilterable) {
          return (
              <li>
                <ReactTooltip
                    textColor={palette.text.inverted}
                    backgroundColor={palette.background.inverted}
                    effect="solid"
                    place="top"
                    id={tooltipId}>
                  {"This Ratio contains a Metric that can't be filtered by client"}
                </ReactTooltip>
                <Card data-tip data-for={tooltipId} style={{opacity: 0.6}} css={ratioCube}>
                  <div style={ratioNameStyle}>
                    {config.get("name")}
                  </div>
                  <div style={ratioValueStyle}>{"N/A"}</div>
                </Card>
              </li>
          );
        }
      }

      const isDataLoaded = (ratioData.get("firstKpiData") && !ratioData.get("firstKpiData").get("error"))
          && (ratioData.get("secondKpiData") && !ratioData.get("secondKpiData").get("error"));

      if (isDataLoaded) {
        const firstKpiTotal = isDataLoaded && ratioData.get("firstKpiData").get("total");
        const secondKpiTotal = isDataLoaded && ratioData.get("secondKpiData").get("total");
        const ratioValueDisplay = ratioFormatter.format(config.get("displayType"), firstKpiTotal, secondKpiTotal);
        const firstKpiType = kpiRepo.get(config.get("firstKpiId")).get("type").dateType;
        const secondKpiType = kpiRepo.get(config.get("secondKpiId")).get("type").dateType;
        const firstKpiName = kpiRepo.get(config.get("firstKpiId")).get("name");
        const secondKpiName = kpiRepo.get(config.get("secondKpiId")).get("name");

        const isInstant = firstKpiType === "INSTANT" || secondKpiType === "INSTANT";
        const isDataAffectedByTimeframe = timeframeAffectsKpiData(isInstant, currentTimeframe);

        let message = "";

        if (!isDataAffectedByTimeframe) {
          if (firstKpiType === "INSTANT" && secondKpiType === "INSTANT") {
            message = firstKpiName + "' and '" + secondKpiName;
          } else if (firstKpiType === "INSTANT" && secondKpiType !== "INSTANT") {
            message = firstKpiName;
          } else if (secondKpiType === "INSTANT" && firstKpiType !== "INSTANT") {
            message = secondKpiName;
          }
        }

        const fontOpacityStyle = !isDataAffectedByTimeframe ? {opacity: 0.6} : {};
        const nameStyle = {...ratioNameStyle, ...fontOpacityStyle};
        const valueStyle = ratioValueDisplay.length > 9 ? {...ratioValueStyle, ...fontOpacityStyle, fontSize: "2rem"} :
            {...ratioValueStyle, ...fontOpacityStyle};

        return (
            <li onClick={() => onClick(config.get("id"))}>
              <Card css={ratioCube} data-tip data-for={tooltipId}>
                {!isDataAffectedByTimeframe &&
                <ReactTooltip class="cube19-dark narrow-tooltip" id={tooltipId} place="top" effect="solid">
                  {"Always current data for '" + message + "'; timeframe selection not applied."}
                </ReactTooltip>}
                <div className="TESTCAFE-ratio" style={nameStyle}>
                  {config.get("name")}
                </div>
                <div style={valueStyle}>{ratioValueDisplay}</div>
              </Card>
            </li>
        );
      } else {
        return (
            <li>
              <Card css={ratioCube}>
                <div style={ratioNameStyle}>
                  {config.get("name")}
                </div>
                <LoadingSpinner />
              </Card>
            </li>
        );
      }
    },
    "Ratio");

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <Ratios theme={theme} {...props} />;
};

export default Wrapper;
