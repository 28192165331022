import React from "react";
import Immutable from "immutable";
import {TextField} from "@mui/material";

import {getQueryParam, setQueryParam} from "js/admin/kpis/edit-kpis/utils";
import JsonField, {validateJsonArray} from "js/common/views/inputs/formatted-json-field";
import LabelledSelect from "js/common/views/inputs/labelled-select";
import Checkbox from "js/common/views/inputs/checkbox";
import ConfigOverview from "js/admin/kpis/edit-kpis/tabs/config-overview";
import {SimpleTest} from "js/admin/kpis/edit-kpis/tabs/test";
import {TextButton} from "js/common/views/inputs/buttons";
import {ParentWarning} from "js/admin/kpis/edit-kpis/warnings";

const oldSumSectionLabel = "Old Sum Sales, Actuals, Invoices";

const params = Immutable.fromJS([{
  section: "Slow Filters",
  key: "idQueryJoinPaths",
  type: "json-array",
  example: "[\"CV_SENT,DIM_JOB\", \"CV_SENT,FACT_CANDIDATE\"]",
  label: "ID Query Join Paths (JSON Array)"
}, {
  section: "Slow Filters",
  type: "multiline-text",
  key: "filter",
  label: "SQL Filter"
}, {
  section: "Slow Filters",
  type: "multiline-text",
  key: "statusFilter",
  label: "Placement Status SQL Filter"
}, {
  section: "Slow Filters",
  type: "multiline-text",
  key: "liveFilter",
  label: "Live Job SQL Filter"
}, {
  section: "Slow Filters",
  key: "dynamicFilter",
  type: "json-array",
  label: "Dynamic Filter (JSON Array, only for Leads / Opps)"
}, {
  section: "Simple Sum",
  type: "json-array",
  key: "kpisToSum",
  label: "Kpis To Sum (JSON Array of KPI IDs and/or Master KPI types)"
}, {
  section: "Sum Sales, Actuals, Invoices",
  type: "long-text",
  key: "kpiIdsToSum",
  label: "Kpi IDs To Sum"
}, {
  section: oldSumSectionLabel,
  key: "actualsKpiId",
  label: "Actuals Kpi ID"
}, {
  section: oldSumSectionLabel,
  key: "actualsKpiIds",
  label: "Actuals Kpi IDs"
}, {
  section: oldSumSectionLabel,
  key: "invoiceKpiId",
  label: "Invoice Kpi ID"
}, {
  section: oldSumSectionLabel,
  key: "invoiceKpiIds",
  label: "Invoice Kpi IDs"
}, {
  section: oldSumSectionLabel,
  key: "jobActualsKpiId",
  label: "Job Actuals Kpi ID"
}, {
  section: oldSumSectionLabel,
  key: "jobActualsKpiIds",
  label: "Job Actuals Kpi IDs"
}, {
  section: oldSumSectionLabel,
  key: "salesKpiId",
  label: "Sales Kpi ID"
}, {
  section: oldSumSectionLabel,
  key: "salesKpiIds",
  label: "Sales Kpi IDs"
}, {
  section: "Monthly / Weekly Invoice Metrics",
  key: "permSalesMasterKpiType",
  label: "Perm Sales Master KPI Type"
}, {
  section: "Monthly / Weekly Invoice Metrics",
  key: "permSalesKpiId",
  label: "Perm Sales KPI ID"
}, {
  section: "Monthly / Weekly Invoice Metrics",
  key: "invoiceMasterKpiType",
  label: "Invoice Master KPI Type"
}, {
  section: "Monthly / Weekly Invoice Metrics",
  key: "invoiceKpiId",
  label: "Invoice KPI ID"
}, {
  section: "Other",
  key: "deletedType",
  type: "enum",
  options: [{
    label: "Show only not-deleted rows",
    value: "NOT_DELETED"
  }, {
    label: "Show only deleted rows",
    value: "DELETED"
  }, {
    label: "Show all rows",
    value: "ALL"
  }],
  label: "Deleted Type"
}, {
  section: "Other",
  key: "useCountTowardsSales",
  label: "Filter by DB Field 'countTowardsSales'",
  type: "boolean"
}]);

const managedKeyPaths = params.map(p => Immutable.List.of("queryParams", p.get("key"))).toSet();

const OtherConfigEditor = React.memo(({
  theme,
  kpi,
  combinedKpi,
  onKpiChange,
  kpiIsParent,
  kpiIsChild,
  testResult,
  onTest,
  requiresExplanationUpdate,
  getExplanationWarning
}) => {
  const [showConfig, setShowConfig] = React.useState(false);
  const onQueryParamChange = (key, value) => onKpiChange(setQueryParam(kpi, key, value));
  return <div>
    <div style={{marginBottom: "1rem", padding: "0 10px"}}>
      {kpiIsParent && <ParentWarning />}
      {requiresExplanationUpdate && getExplanationWarning()}
      <div style={{display: "flex"}}>
        <SimpleTest
            style={{marginRight: "1rem"}}
            testResult={testResult}
            onTest={onTest} />
        {kpiIsChild && <TextButton
            label={`${showConfig ? "Hide" : "Show"} Inherited Config`}
            onClick={() => setShowConfig(prev => !prev)} />}
      </div>
    </div>

    {kpiIsChild && showConfig &&
    <div style={{display: "flex", marginBottom: "1rem"}}>
      <ConfigOverview
          columnNameToMap={Immutable.Map({"Value": combinedKpi})}
          keyPaths={managedKeyPaths} />
    </div>}

    {params
        .groupBy(p => p.get("section"))
        .entrySeq()
        .map(([section, paramsForSection], index) => {
          return <div key={section}>
            <div style={{
              backgroundColor: theme.themeId === "light" ? "transparent" : "#445",
              padding: "0.6rem",
              display: "flex",
              flexWrap: "wrap",
              alignItems: "flex-start"
            }}>
              {section}
            </div>
            <div style={{padding: "0.5rem 1rem 1rem 1rem"}}>
              {paramsForSection.map(param => {
                const key = param.get("key");
                const label = param.get("label");
                const value = getQueryParam(kpi, param.get("key"), "");
                const exampleStr = param.get("example") ? " e.g. " + param.get("example") : "";
                const title = key + exampleStr;
                switch (param.get("type")) {
                  case "enum":
                    return <LabelledSelect
                        key={key}
                        containerStyle={{
                          marginTop: "1rem",
                          width: "300px",
                          display: "inline-block",
                          marginRight: "1rem"
                        }}
                        isMulti={false}
                        label={label}
                        title={title}
                        options={param.get("options")}
                        selectedValue={value}
                        onChange={value => onQueryParamChange(key, value)} />;
                  case "boolean":
                    return <Checkbox
                        key={key}
                        label={label}
                        title={title}
                        checked={value === "true" || value === true || value === "1" || value === 1}
                        onCheck={(e, isChecked) => onQueryParamChange(key, isChecked ? "true" : "false")} />;
                  case "json-map":
                    return <JsonField
                        key={key}
                        style={{marginTop: "1rem"}}
                        label={label}
                        value={value}
                        title={title}
                        emptyValue=""
                        onChange={value => onQueryParamChange(key, value)} />;
                  case "json-array":
                    return <JsonField
                        key={key}
                        style={{marginTop: "1rem"}}
                        validator={validateJsonArray}
                        label={label}
                        value={value}
                        title={title}
                        emptyValue=""
                        onChange={value => onQueryParamChange(key, value)} />;
                  case "multiline-text":
                    return <TextField variant="standard"
                        key={key}
                        style={{marginTop: "1rem"}}
                        fullWidth
                        multiline
                        label={label}
                        title={title}
                        value={value}
                        onChange={e => onQueryParamChange(key, e.target.value)} />;
                  case "long-text":
                    return <TextField variant="standard"
                        key={key}
                        style={{marginTop: "1rem"}}
                        fullWidth
                        label={label}
                        title={title}
                        value={value}
                        onChange={e => onQueryParamChange(key, e.target.value)} />;
                  default:
                    return <TextField variant="standard"
                        style={{marginRight: "1rem"}}
                        key={key}
                        label={label}
                        title={title}
                        value={value}
                        onChange={e => onQueryParamChange(key, e.target.value)} />;
                }
              })}
            </div>
          </div>;
        })}
  </div>;
});

export default OtherConfigEditor;
