import React from "react";
import * as Users from "js/common/users";
import * as Locales from "js/common/locales";
import {Button, MenuItem} from "@mui/material";
import moment from "moment";
import {apiUrl} from "js/app-configuration";
import * as Branding from "js/common/branding-constants";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import SelectField from "@mui/material/Select/Select";
import LoadingSpinner from "js/common/views/loading-spinner";
import Dropzone from "js/common/views/inputs/dropzone";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import pure from "js/common/views/pure";
import {IconButton} from "js/common/views/inputs/buttons";
import Immutable from "immutable";

const maxFileMB = 20;

export const CsvUpload = ({theme, requiresLocale, uploadUrl, csvLabel, onUploadSuccess}) => {
  const [file, setFile] = React.useState(null);
  const [idToLocale, setIdToLocale] = React.useState();
  const defaultLocaleId = Users.getInherited(Users.getCurrentUser(), x => x.get("localeId"));
  const [selectedLocaleId, setSelectedLocaleId] = React.useState(defaultLocaleId);
  const [selectedStyleId, setSelectedStyleId] = React.useState("COMMA");
  const [uploadingFile, setUploadingFile] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);

  const idToCsvStyle = csvStyles.groupBy(x => x.get("id")).map(xs => xs.first());

  React.useEffect(() => {
    Locales
        .loadAll()
        .then(locales => {
          setIdToLocale(locales.groupBy(l => l.get("id")).map(ls => ls.first()));
          setSelectedLocaleId(locales.first().get("id"));
        });
  }, []);

  const onRemoveFileClick = e => {
    e.stopPropagation();
    setFile(null);
  };

  const localeToMenuItem = locale => <MenuItem
      key={locale.get("id")}
      value={locale.get("id")}>
    {`${locale.get("name")} Dates - ${moment().locale(locale.get("code")).localeData().longDateFormat("L")}`}
  </MenuItem>;

  const uploadFile = () => {
    setUploadingFile(true);
    setError(null);
    setSuccess(null);
    window.superagent
        .post(apiUrl + uploadUrl)
        .withCredentials()
        .field("locale-code", idToLocale.get(selectedLocaleId).get("code"))
        .field("cube19-value-separator", idToCsvStyle.get(selectedStyleId).get("separator"))
        .attach("file", file)
        .end((error, response) => {
          if (error) {
            if (response?.body?.type === "UPLOAD_COLLISION" && response?.body?.message) {
              setError(response.body.message);
            } else if (response?.body?.type === "PERMISSION_DENIED") {
              setError("You do not have the correct permissions to use this feature.");
            } else if (response?.status === 413) {
              setError(`You cannot upload a file over ${maxFileMB}MB`);
            } else {
              setError(`An unexpected error has occurred. ${Branding.submitTicketString}`);
            }

            setUploadingFile(false);
          } else {
            setFile(null);
            setUploadingFile(false);
            setSuccess("Your file has been uploaded");
            onUploadSuccess && onUploadSuccess(response);
          }
        });
  };

  return <div style={{padding: "0 1rem 1rem 1rem"}}>
    <b style={{fontSize: "0.9rem"}}>Upload Formatting Options</b>
    <p style={{fontSize: "0.85rem", marginBottom: "0.5rem", opacity: "60%"}}>Select options based on your CSV file
      configuration.</p>
    {requiresLocale && idToLocale &&
        <FormControl>
          <InputLabel variant="standard">Date Format</InputLabel>
          <SelectField
              variant="standard"
              defaultValue={selectedLocaleId}
              value={selectedLocaleId}
              onChange={e => setSelectedLocaleId(e.target.value)}
              style={{verticalAlign: "middle", width: "15rem"}}>
            {idToLocale.valueSeq().sortBy(l => l.get("name")).map(localeToMenuItem)}
          </SelectField>
        </FormControl>
    }
    <CsvFileFormattingOptions
        selectedStyleId={selectedStyleId}
        setSelectedStyleId={setSelectedStyleId} />
    <b style={{fontSize: "0.9rem"}}>Upload</b>
    <p style={{fontSize: "0.85rem", marginBottom: "0.5rem", opacity: "60%"}}>After you have finished making changes you
      can upload the updated CSV using the box below.</p>
    <div style={{textAlign: "left"}}>
      {uploadingFile
          ? <LoadingSpinner />
          : <Dropzone
              onDrop={files => {
                setFile(files[0]);
                setSuccess(null);
                setError(null);
              }}
              onDropRejected={(files) => {
                setError(files.map(({file, errors}) => file.name + ": " + errors.map(e => {
                  if (e.message.startsWith("File is larger")) {
                    return `File cannot be larger than ${maxFileMB}MB`;
                  }
                  return e.message;
                }).join(", "))
                    .join("\n"));
              }}
              multiple={false}
              disablePreview={false}
              className="react-dropzone"
              accept={{"text/csv": [".csv"]}}
              maxSize={20000000}
              activeClassName="react-dropzone-active"
              style={{
                minHeight: file ? 40 : 120,
                borderStyle: file ? "none" : "dashed",
                boxShadow: file && `0 0 5px ${theme.themeId === "light" ? "#b7b7b8" : "#fff"}`,
                padding: 15,
                margin: "10px 0 5px",
                borderRadius: 0,
                borderColor: theme.themeId === "light" ? "#b7b7b8" : "#fff"
              }}
              activeStyle={{borderStyle: "dashed"}}>
            {file ?
                <FilePreview file={file} onRemoveBtnClick={onRemoveFileClick} theme={theme} /> :
                <HelpText theme={theme} csvLabel={csvLabel} />}
          </Dropzone>}
      {error && <ErrorMsg text={error} />}
      {success && <SuccessMsg text={success} />}
    </div>
    <Button
        variant="contained"
        disabled={!file || uploadingFile}
        id="basic-button"
        onClick={uploadFile}
        style={{marginTop: 10}}
    >
      Upload
    </Button>
  </div>;
};

const HelpText = pure(({theme, csvLabel}) => <div
    style={{
      display: "flex",
      flexDirection: "column",
      height: 120,
      justifyContent: "space-between",
      color: "#bbb",
      padding: 10
    }}>
  <div>Drop your {csvLabel} CSV file here</div>
  <div>or click to <span style={{color: theme.palette.primary.main, cursor: "pointer"}}>choose a file</span></div>
  <div style={{fontSize: "0.85rem", marginTop: 10}}>Max file size: {maxFileMB}MB</div>
</div>);

const FilePreview = pure(({file, onRemoveBtnClick, theme}) => (
    <div style={{display: "flex", justifyContent: "space-between", fontSize: "0.9rem"}}>
      <span style={{color: theme.palette.primary.main}}>{file.name}</span>
      <span>Ready for Upload</span>
      <IconButton
          iconStyle={{
            backgroundColor: "black",
            opacity: "50%",
            borderRadius: 30,
            color: "white",
            width: 20,
            height: 20,
            padding: 0,
            fontSize: "0.5rem"
          }}
          icon="times"
          type="bright"
          hoverType="bright"
          title="Remove File"
          onClick={onRemoveBtnClick}
      />
    </div>
));

const CsvFileFormattingOptions = ({selectedStyleId, setSelectedStyleId}) => {

  return <div style={{marginTop: 10}}>
    <div style={{width: "40rem", paddingBottom: 3}}>
      <FormControl>
        <InputLabel variant="standard">CSV file separator formatting</InputLabel>
        <SelectField
            variant="standard"
            value={selectedStyleId}
            keys={["id", "label"]}
            onChange={e => setSelectedStyleId(e.target.value)}
            style={{verticalAlign: "middle", width: "15rem"}}>
          {csvStyles.map(csvStyleToMenuItem)}
        </SelectField>
      </FormControl>
    </div>
    <p style={{fontSize: "0.85rem", opacity: "80%"}}>You usually format the
      values <b>"hello"</b>, <b>"world"</b> and <b>"test"</b> in a CSV like this: <b>hello,world,test</b></p>
  </div>;
};

const csvStyleToMenuItem = csvStyle => <MenuItem
    key={csvStyle.get("id")}
    value={csvStyle.get("id")}>
  {csvStyle.get("label")}
</MenuItem>;

const csvStyles = Immutable.fromJS([
  {
    id: "COMMA",
    label: "Comma ,",
    separator: ","
  }, {
    id: "DECIMAL",
    label: "Decimal .",
    separator: "."
  }, {
    id: "SEMICOLON",
    label: "Semi-colon ;",
    separator: ";"
  }]);