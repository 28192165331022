import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import BreakdownTree from "js/oneview/quick-view/breakdown-tree";
import getColWidthClasses from "js/oneview/quick-view/column-widths";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";

const _ = window._;

const QuickView = createReactClass({
  displayName: "QuickViewCT",
  mixins: [PureRenderMixin],

  render() {
    const {
      kpiId,
      timeframe,
      clientIds,
      groupId,
      onGroupClick,
      onUserClick,
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds
    } = this.props;
    const groups = Groups.getGroups().toJSON();
    const users = Users.getActiveUsers().toJSON();
    const isSmallScreen = this.props.containerWidth < 800;
    const maxWidth = isSmallScreen ? 600 : 950;
    const theme = this.context.theme;

    const containerStyle = {
      margin: "0 auto",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      maxHeight: "100%",
      width: maxWidth
    };
    const headerRowStyle = {
      display: "flex",
      alignItems: "flex-end",
      width: "100%",
      height: 25,
      color: theme.palette.text.main,
      fontSize: "0.9rem",
      textAlign: "center",
      borderBottom: theme.themeId === "light" ? "none" : "2px solid #f9ec33",
      backgroundColor: theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.paper
    };
    const headerColumnStyle = {
      paddingBottom: "0.5rem"
      // borderBottom: "2px solid #ffeb3b"
    };
    const colWidthClassByName = getColWidthClasses(isSmallScreen);
    return (
        <div style={{overflow: "auto"}}>
          <div style={containerStyle}>
            <div style={headerRowStyle}>
              {!isSmallScreen &&
                  <div className={colWidthClassByName.groupOrUser} style={headerColumnStyle}>&nbsp;</div>}
              <div
                  className={colWidthClassByName.target}
                  style={headerColumnStyle}>
                Target
              </div>
              <div
                  className={colWidthClassByName.percentOfTarget}
                  style={headerColumnStyle}>
                % of Target
              </div>
              <div
                  className={colWidthClassByName.diffFromExpected}
                  style={headerColumnStyle}>
                Difference from Expected
              </div>
              <div
                  className={colWidthClassByName.total}
                  style={headerColumnStyle}>
                Total
              </div>
            </div>
            <div style={{maxHeight: "70vh", overflowY: "auto"}}>
              <BreakdownTree
                  groupsByParentId={_(groups).groupBy("parentId")}
                  usersByGroupId={_(users).groupBy("groupId")}
                  kpiId={kpiId}
                  timeframe={timeframe}
                  clientIds={clientIds}
                  matchAnyTagIds={matchAnyTagIds}
                  matchAllTagIds={matchAllTagIds}
                  excludedTagIds={excludedTagIds}
                  groups={[Groups.getGroup(groupId).toJSON()]}
                  onGroupClick={onGroupClick}
                  onUserClick={onUserClick}
                  expandToLevel={1}
                  width={maxWidth}
                  isSmallScreen={isSmallScreen} />
            </div>
            <p style={{fontSize: "0.75rem", color: theme.palette.text.secondary, margin: "0.5rem", padding: "0 1rem"}}>
              -- : No Target Set
            </p>
          </div>
        </div>
    );
  }

});

QuickView.contextType = CustomThemeContext;

export default GetContainerDimensions()(QuickView);