import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import MenuBar from "js/common/views/menu-bar";
import {trackError} from "js/common/error-tracking";
import * as Branding from "js/common/branding-constants"

// TODO when app state starts migrating to rematch/redux we can persist some useful subset on error
export default createReactClass({

    mixins: [PureRenderMixin],

    getInitialState() {
        return {
            uncaughtError: false
        };
    },

    componentDidCatch(error, {componentStack}) {
        trackError("REACT_RENDER", error, {componentStack});
        this.setState({uncaughtError: true});
    },

    render() {
        if (this.state.uncaughtError) {
            return (
                <div>
                    <MenuBar appView="error" />
                    <div style={{padding: "1rem"}}>
                        An unexpected error has occurred. {Branding.submitTicketInstructions}.
                    </div>
                </div>
            );
        } else {
            return this.props.children;
        }
    }

});