/** @jsxImportSource @emotion/react */
import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";

import PureRenderMixin from "react-addons-pure-render-mixin";
import onClickOutside from "react-onclickoutside";
import * as Groups from "js/common/groups";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import InfiniteListSelect from "js/common/views/infinite-loading/infinite-list-select";


const ActiveUsersList = onClickOutside(createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    currentGroup: ReactPropTypes.object.isRequired,
    onUserClick: ReactPropTypes.func.isRequired
  },

  getInitialState() {
    return {
      isOpen: false,
      activeUsersInGroup: Immutable.List(),
      searchText: "",
      dropdownOpen: false
    };
  },

  componentDidMount() {
    loadActiveUsersInGroup(this.props.currentGroup.get("id"))
        .then(activeUsersInGroup => this.setState({activeUsersInGroup}));
  },

  componentDidUpdate(prevProps) {
    if (prevProps.currentGroup !== this.props.currentGroup) {
      loadActiveUsersInGroup(this.props.currentGroup.get("id"))
          .then(activeUsersInGroup => this.setState({activeUsersInGroup}));
    }
  },

  render() {
    const {activeUsersInGroup} = this.state;

    return (
        <InfiniteListSelect
            startItems={activeUsersInGroup
                .map(user => new Immutable.Map({
                  name: user.get("fullName"),
                  id: user.get("id")
                }))}
            onChange={this.handleUserClick}
            placeholder={activeUsersInGroup.count() + " Active User" + (activeUsersInGroup.count()
            === 1 ? "" : "s")}
            closeOnSelect={true}
            innerStyle={{padding: "2px 5px", minHeight: 20}}
            style={{width: "15em"}}
            maxHeight={400}
        />);
  },

  handleUserClick(users) {
    if (users.count() === 1) {
      this.closeActiveUsersList();
      this.props.onUserClick(users.first().get("id"));
    }
  },

  handleClickOutside() {
    this.closeActiveUsersList();
  },

  closeActiveUsersList() {
    this.setState({
      isOpen: false
    });
  }

}));

const loadActiveUsersInGroup = groupId => Groups
    .loadUsersInGroup(groupId)
    .then(users => users
        .filter(user => user.get("state") === "VISIBLE")
        .sort((user, comparison) => user.get("fullName").localeCompare(comparison.get("fullName"))));


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ActiveUsersList theme={theme} {...props} />;
};

export default Wrapper;
