/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import {css} from "@emotion/react";

import * as DashboardUtils from "js/dashboards/utils";
import {dashboardColumns} from "js/dashboards/utils";
import * as Rata from "js/common/utils/remote-data";
import useErrorBoundary from "use-error-boundary";
import useDimensions from "js/common/utils/use-dimensions";
import {indexByJs} from "js/common/utils/collections";

import GridLayout, {WidthProvider} from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {BasicComponentTitleBar} from "js/dashboards/components/title-bar";
import {ComponentTile} from "js/dashboards/components/grids/utils";
import * as Overlays from "js/dashboards/components/overlays";
import {TextButton} from "js/common/views/inputs/buttons";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const SizedGridLayout = WidthProvider(GridLayout);

const dragHandles = ["se"];
const noDragHandles = [];

// NOTE: This style is required so that tooltips do no get hidden by other components.
// All alternatives surrounding Tooltip z-index have been explored, this is the nicest solution.
const componentStyle = css`
  &:hover {
    z-index: 1;
  }
;
`;

const fullScreenStyle = (theme) => {
  const yOffset = window.pageYOffset;

  return css`
    background: ${theme.palette.background.paper};
    position: fixed !important;
    top: ${yOffset > 120 ? "2px" : `calc(112px - ${yOffset}px)`};
    left: 0;
    padding-top: 10px;
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 10px;
    height: calc(100vh - ${yOffset > 120 ? "0px" : `calc(110px - ${yOffset}px)`}) !important;
    width: 100vw !important;
    transform: none !important;
    z-index: 1;
  `;
};

const DashboardDisplay = React.memo(({
  componentIdToLoadId,
  dashboard,
  onDashboardChange = () => {},
  isEditing,
  componentTypeToTemplate,
  onRequestEdit,
  onRequestComponentReload,
  onDuplicateClick,
  queueDataLoad,
  clearDataLoad,
  onRequestDialog,
  idToData,
  rowHeight
}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const components = dashboard.getIn(["json", "components"]);
  const handleComponentChange = React.useCallback((componentId, newComponentFn, reload = false) => {
    onDashboardChange(dashboard => {
      const components = dashboard.getIn(["json", "components"], Immutable.List());
      const index = components.findIndex(c => c.get("id") === componentId);
      const oldComponent = components.get(index);
      const newComponent = newComponentFn(oldComponent);
      const newComponents = components.set(index, newComponent);
      return dashboard.setIn(["json", "components"], newComponents);
    }, {reloadDashboard: reload});
  }, [onDashboardChange]);

  const handleParentConfigChange = React.useCallback((newParentConfigOrFn, reload = false) => {
    onDashboardChange(dashboard => {
      if (typeof newParentConfigOrFn === "function") {
        return dashboard.updateIn(["json", "dataConfig"], Immutable.Map(), newParentConfigOrFn);
      } else {
        return dashboard.setIn(["json", "dataConfig"], newParentConfigOrFn);
      }
    }, {reloadDashboard: reload});
  }, [onDashboardChange]);

  const layout = React.useMemo(
      () => components.map(c => getLayoutForComponent(
          c,
          componentTypeToTemplate,
          {columns: dashboardColumns, rowHeight: rowHeight})).toArray(),
      [components, componentTypeToTemplate, rowHeight]);

  const handleLayoutChange = React.useCallback(dashboardLayout => {
    const componentIdToLayout = indexByJs(componentLayout => {
      const strId = componentLayout.i;
      return parseInt(strId.replace("component-", ""));
    }, dashboardLayout);

    onDashboardChange(dashboard => dashboard
        .updateIn(
            ["json", "components"],
            Immutable.List(),
            components => components
                .map(component => {
                  const newLayoutForComponent = componentIdToLayout[component.get("id")];
                  return component.update(
                      "layout",
                      Immutable.Map(),
                      layout => layout
                          .set("x", newLayoutForComponent.x)
                          .set("y", newLayoutForComponent.y)
                          .set("width", newLayoutForComponent.w)
                          .set("height", newLayoutForComponent.h));
                })));
  }, [onDashboardChange]);

  const handleDeleteComponent = React.useCallback((componentId, onCloseDialog) => {
    onDashboardChange(dashboard => {
      const components = dashboard.getIn(["json", "components"], Immutable.List());
      const index = components.findIndex(c => c.get("id") === componentId);
      return dashboard.deleteIn(["json", "components", index]);
    });
    onCloseDialog();
  }, [onDashboardChange]);

  const handleDeleteComponentRequest = React.useCallback(componentId => {
    const onCloseDialog = onRequestDialog(
        <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "80%",
              margin: "0 10px"
            }}>
          <p style={{margin: 0}}>
            Are you sure you want to delete this panel?
          </p>
          <div style={{display: "flex", justifyContent: "flex-end"}}>
            <TextButton icon="history" label="Cancel" onClick={() => onCloseDialog()} style={{margin: "0 0.5rem"}} />
            <TextButton
                icon="trash" type="primary" label="Delete"
                testId={`${componentId}-delete-confirm`}
                onClick={() => handleDeleteComponent(componentId, onCloseDialog)} style={{margin: "0 0.5rem"}} />
          </div>
        </div>,
        {
          id: "server-grid-cell-dialog-" + Math.random(),
          title: "Delete panel?",
          width: 700,
          height: 180,
          titleStyle: {color: theme.palette.primary.main, textTransform: "uppercase", margin: 10}
        });
  }, [onRequestDialog, handleDeleteComponent, theme]);


  switch (dashboard.getIn(["json", "layoutType"])) {
    case "infinite":
      if (components.filter(c => c.get("fullScreen")).count() > 0) {
        DashboardUtils.lockPageScroll();
      } else {
        DashboardUtils.enablePageScroll();
      }
      // NOTE this wrapping div is necessary
      // otherwise the grid layout container does not apply its containerPadding to the bottom
      return <div style={{width: "100%", height: "100%"}}>
        <SizedGridLayout
            className="layout"
            cols={48}
            rowHeight={rowHeight}
            layout={layout}
            margin={[12, 12]}
            containerPadding={[25, 20]}
            onLayoutChange={handleLayoutChange}
            measureBeforeMount={true}
            resizeHandles={isEditing ? dragHandles : noDragHandles}
            draggableHandle=".cube19-drag-handle">
          {components.map(component => {
            const template = componentTypeToTemplate.get(component.get("type"));
            const data = idToData.get(DashboardUtils.getDataId(componentIdToLoadId, component));
            return (
                <div
                    key={"component-" + component.get("id")}
                    css={component.get("fullScreen") ? fullScreenStyle(theme) : componentStyle}
                    data-test-id={"component-wrapper-" + component.get("id")}>
                  <ComponentWrapper
                      key={component.get("id")}
                      component={component}
                      onComponentChange={handleComponentChange}
                      template={template}
                      data={data}
                      isEditing={isEditing}
                      componentIdToLoadId={componentIdToLoadId}
                      queueDataLoad={queueDataLoad}
                      clearDataLoad={clearDataLoad}
                      onRequestDialog={onRequestDialog}
                      onRequestDelete={handleDeleteComponentRequest}
                      onRequestEdit={onRequestEdit}
                      enableCharts={template.get("type") === "UiGrid"}
                      onRequestReload={onRequestComponentReload}
                      onDuplicateClick={onDuplicateClick}
                      dashboardType={dashboard.getIn(["json", "layoutType"])}
                      parentConfig={dashboard.getIn(["json", "dataConfig"])}
                      onParentConfigChange={handleParentConfigChange}
                      componentTypeToTemplate={componentTypeToTemplate} />
                </div>
            );
          })}
        </SizedGridLayout>
      </div>;
    case "window-only":
      const component = components.get(0);
      if (component) {
        const template = componentTypeToTemplate.get(component.get("type"));
        const dataId = DashboardUtils.getDataId(componentIdToLoadId, component);
        const data = idToData.get(dataId);
        return <div
            data-test-id={"component-wrapper-" + component.get("id")}
            style={{flex: "1", padding: "1rem", overflow: "hidden"}}>
          <ComponentWrapper
              key={component.get("id")}
              component={component}
              onComponentChange={handleComponentChange}
              template={template}
              data={data}
              isEditing={isEditing}
              componentIdToLoadId={componentIdToLoadId}
              queueDataLoad={queueDataLoad}
              clearDataLoad={clearDataLoad}
              onRequestDialog={onRequestDialog}
              enableCharts={false}
              onRequestDelete={handleDeleteComponentRequest}
              onRequestEdit={onRequestEdit}
              onRequestReload={onRequestComponentReload}
              onDuplicateClick={onDuplicateClick}
              dashboardType={dashboard.getIn(["json", "layoutType"])}
              parentConfig={dashboard.getIn(["json", "dataConfig"])}
              onParentConfigChange={handleParentConfigChange}
              componentTypeToTemplate={componentTypeToTemplate} />
        </div>;
      } else {
        return null;
      }
    default:
      return "unknown layout type: " + dashboard.getIn(["json", "layoutType"]);
  }
});

const getLayoutForComponent = (component, componentTypeToTemplate) => {
  const layout = component.get("layout", Immutable.Map());
  const template = componentTypeToTemplate.get(component.get("type"));
  const templateMin = template.getIn(["layout", "min"]);
  const templateMax = template.getIn(["layout", "max"]);

  return {
    i: "component-" + component.get("id"),
    x: layout.get("x"),
    y: layout.get("y"),
    w: layout.get("width"),
    h: layout.get("height"),
    minW: templateMin.get("width"),
    minH: templateMin.get("height"),
    maxW: templateMax.get("width"),
    maxH: templateMax.get("height")
  };
};

const ComponentWrapper = React.memo(({
  component,
  onComponentChange,
  template,
  data,
  parentConfig,
  onParentConfigChange,
  isEditing,
  componentIdToLoadId,
  clearDataLoad,
  onRequestDialog,
  queueDataLoad,
  onRequestDelete,
  onRequestEdit,
  onRequestReload,
  componentTypeToTemplate,
  dashboardType,
  onDuplicateClick,
  enableCharts
}) => {
  const {ErrorBoundary, didCatch, reset} = useErrorBoundary();
  const ReactComponent = template.get("getReactComponent")();
  const componentId = component.get("id");

  const handleReloadComponent = React.useCallback(() => {
    onRequestReload(componentId);
  }, [componentId, onRequestReload]);

  const handleDataConfigChange = React.useCallback((componentId, newDataConfig) => {
    onComponentChange(componentId, component => {
      if (typeof newDataConfig === "function") {
        return component.update("dataConfig", newDataConfig);
      } else {
        return component.set("dataConfig", newDataConfig);
      }
    });
  }, [onComponentChange]);

  const handleTitleChange = React.useCallback((componentId, newTitle) => {
    onComponentChange(componentId, component => component.set("title", newTitle));
  }, [onComponentChange]);

  const handleFullScreen = React.useCallback((componentId, fullScreen) => {
    if (fullScreen) {
      onComponentChange(componentId, component => component.set("fullScreen", true));
      DashboardUtils.lockPageScroll();
    } else {
      onComponentChange(componentId, component => component.set("fullScreen", false));
      DashboardUtils.enablePageScroll();
    }
  }, [onComponentChange]);

  const componentLoadId = componentIdToLoadId.get(componentId, 0);
  const [dimRef, dimensions] = useDimensions();
  const downloadFnRef = React.useRef();
  if (didCatch) {
    return <ComponentTile variant={template.get("variant")}>
      <BasicComponentTitleBar
          componentId={component.get("id")}
          onRemoveClick={onRequestDelete}
          onEditClick={onRequestEdit}
          canFullScreen={template.get("canFullScreen", false)}
          isFullScreen={component.get("fullScreen")}
          onFullScreenClick={handleFullScreen}
          isEditing={isEditing}
          titleIsEditable={template.get("titleIsEditable", true)}
          title={template.get("getTitle")(component)}
          variant={template.get("variant")}
          onTitleChange={handleTitleChange} />
      <Overlays.Error onClick={reset} coverOtherContent={false}>
        Error displaying data, click to try again
      </Overlays.Error>
    </ComponentTile>;
  } else {
    let content;
    if (Rata.isLoading(data)) {
      content = <Overlays.Loading coverOtherContent={false} />;
    } else if (Rata.hasError(data)) {
      const error = Rata.getError(data);
      let message;
      if (typeof error === "string") {
        message = error;
      } else if (error.responseJSON && error.responseJSON.message) {
        message = <p style={{fontSize: "0.6rem", lineHeight: "0.8rem", margin: 0}}>
          {error.responseJSON.message}
            {error.responseJSON.errorId && <>Error ID: {error.responseJSON.errorId}</>}
        </p>;
      } else {
        message = "Error loading data, click to try again";
      }
      content = <Overlays.Error onClick={handleReloadComponent} coverOtherContent={false}>
        {message}
      </Overlays.Error>;
    } else {
      content = <ReactComponent
          key={"component-" + componentId + "-" + componentLoadId}
          componentId={componentId}
          dimensions={dimensions}
          dashboardType={dashboardType}
          downloadFnRef={downloadFnRef}
          title={template.get("getTitle")(component)}
          config={component.get("dataConfig")}
          onConfigChange={handleDataConfigChange}
          data={data}
          isEditing={isEditing}
          enableCharts={enableCharts}
          component={component}
          componentLoadId={componentLoadId}
          onRequestDialog={onRequestDialog}
          onRequestReload={handleReloadComponent}
          queueDataLoad={queueDataLoad}
          clearDataLoad={clearDataLoad}
          componentTypeToTemplate={componentTypeToTemplate}
          parentConfig={parentConfig}
          onParentConfigChange={onParentConfigChange} />;
    }

    return <ErrorBoundary>
      <ComponentTile variant={template.get("variant")}>
        <BasicComponentTitleBar
            config={component.get("dataConfig")}
            inheritKeys={component.getIn(["dataConfig", "inheritKeys"])}
            componentId={componentId}
            onRemoveClick={onRequestDelete}
            onEditClick={onRequestEdit}
            onDownloadClickRef={downloadFnRef}
            canDownload={template.get("canDownload", false)}
            isClickThrough={component.getIn(["dataConfig", "isClickThrough"], false)}
            canFullScreen={template.get("canFullScreen", false)}
            isFullScreen={component.get("fullScreen")}
            enableCharts={enableCharts}
            onFullScreenClick={handleFullScreen}
            onDuplicateClick={onDuplicateClick}
            isEditing={isEditing}
            titleIsEditable={template.get("titleIsEditable", true)}
            title={template.get("getTitle")(component)}
            variant={template.get("variant")}
            onTitleChange={handleTitleChange} />
        <div
            ref={dimRef} style={{
          position: "relative",
          flex: 1,
          width: "100%",
          overflow: template.get("variant") !== "standard" ? "hidden" : "visible",
          paddingTop: template.get("getTitle")(component) || template.get("titleIsEditable", true) ? 34 : 0
        }}>
          {content}
        </div>
      </ComponentTile>
    </ErrorBoundary>;
  }
});

export default DashboardDisplay;
