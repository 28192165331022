import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";

import Dropzone from "js/common/views/inputs/dropzone";
import {Column, Layout, Row} from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import Err from "js/common/views/error";
import Overlay from "js/common/views/overlay";
import * as popups from "js/common/popups";

import {endsWith} from "js/common/utils/strings";
import {formatStorageSize} from "js/common/utils/numbers";
import {TextField} from "@mui/material";
import {apiUrl} from "js/app-configuration";

const maxFileNameLength = 255;
const maxFileSizeInBytes = 20e6;
const maxFileSizeStr = formatStorageSize(maxFileSizeInBytes, 0);

export default createReactClass({

  propTypes: {
    onImageUploaded: ReactPropTypes.func.isRequired
  },

  getInitialState() {
    return {
      file: null,
      description: "",
      isUploading: false
    };
  },

  componentWillUnmount() {
    const {file} = this.state;
    if (file) {
      URL.revokeObjectURL(file.preview);
    }
  },

  render() {
    const file = this.state.file;
    const content = file ? getImagePreview(file) : <span>Drop a new background image here or click to choose a file to upload.</span>;
    const fileIsInvalid = file && validateFile(file);

    return (
        <div style={{position: "relative"}}>
          {this.state.isUploading && <Overlay />}
          <Layout allSmall={12} allMedium={10} mediumCentered={true} rowStyle={{marginTop: "1rem"}}>
            <Dropzone
                multiple={true}
                accept={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpg", ".jpeg"]
                }}
                onDrop={this.handleDrop}
                className="react-dropzone"
                activeClassName="react-dropzone-active">
              {content}
            </Dropzone>
            {fileIsInvalid}
          </Layout>
          <Row>
            <Column small={12} medium={12} mediumCentered={true}>
              <div className="small-6 columns">
                <TextField
                    variant="standard" style={{marginTop: 15}} InputLabelProps={{style: {color: "#757575"}}}
                    fullWidth={true}
                    style={{paddingBottom: "0.5rem"}}
                    label="File Description"
                    disabled={!file || fileIsInvalid}
                    value={this.state.description}
                    onChange={e => this.setState({description: e.target.value.substring(0, maxFileNameLength)})} />
              </div>
              <div className="small-6 columns" style={{marginTop: "0.5rem"}}>
                <TextButton
                    type="dark"
                    icon="upload"
                    label="Upload"
                    disabled={!file || fileIsInvalid}
                    onClick={this.handleUploadClick} />
                <TextButton
                    style={{marginLeft: "1em"}}
                    icon="trash"
                    label="Remove"
                    disabled={!file}
                    onClick={this.removeFile} />
              </div>
            </Column>
          </Row>
        </div>);
  },

  handleDrop(files) {
    const file = files[0];  // File object returned by Dropzone component is read-only
    const preview = URL.createObjectURL(file);
    this.setState({
      file: Object.assign(file, {preview}),
      description: file.name
    });
  },

  handleUploadClick() {
    this.setState({isUploading: true});
    const {file, description} = this.state;
    const req = window.superagent
        .post(apiUrl + "/ApplicationLayer/carousels/backgrounds/upload")
        .withCredentials();
    req.attach("file", file);
    req.field("description", description.substring(0, maxFileNameLength));
    req.end((error) => {
      this.setState({isUploading: false});
      if (error) {
        popups.contactSupport();
      } else {
        this.props.onImageUploaded();
        this.removeFile();
        popups.success("Your image has been uploaded");
      }
    });
  },

  removeFile() {
    URL.revokeObjectURL(this.state.file.preview);
    this.setState(this.getInitialState());
  }

});

const getImagePreview = file => (
    <div>
      <img src={file.preview} style={{maxHeight: "100px"}} />
      <p>{file.name}</p>
    </div>);

const errorStyle = {
  marginTop: "1rem",
  marginBottom: 0
};

const validateFile = file => {
  if (!hasValidExtension(file.name)) {
    const text = `You can only upload PNG (.png) or JPEG (.jpg, .jpeg) files. '${file.name}' is not a supported image file format.`;
    return <Err style={errorStyle} text={text} />;
  }

  if (!isValidSize(file.size)) {
    const text = `You can only upload files with a maximum size of ${maxFileSizeStr}. '${file.name}' is ${formatStorageSize(
        file.size)}.`;
    return <Err style={errorStyle} text={text} />;
  }

  return;
};

const isValidSize = fileSizeInBytes => fileSizeInBytes <= maxFileSizeInBytes;
const hasValidExtension = fileName => {
  const allowedFileExtensions = [".png", ".jpg", ".jpeg"];
  return allowedFileExtensions.some(ext => endsWith(ext.toLowerCase(), fileName.toLowerCase()));
};
