import numbro from "numbro";
import Immutable from "immutable";

import * as currencyRepo from "js/common/repo/backbone/currency-repo";
import * as numbers from "js/common/utils/numbers";
import {roundTo} from "js/common/utils/numbers";
import {firstWithValue} from "js/common/utils/value-checking";

const numbroOptions = {thousandSeparated: true};

const getType = (valueFormat, currencyCode, currencySymbol) => {
  if (valueFormat) {
    return valueFormat;
  } else if (currencyCode || currencySymbol) {
    return "CURRENCY";
  } else {
    return "NUMBER";
  }
};

const format = (
    wrapper,
    {
      valueFormat,
      maxDisplayLength,
      decimalPlaces,
      showCurrency = true,
      currencySymbol,
      currencyCode,
      abbreviationOptions
    } = {}
) => {
  if (!wrapper) {
    return "N/A";
  }

  if (Immutable.isImmutable(wrapper)) {
    wrapper = wrapper.toJS();
  }

  const {value, currency, decimalPlaces: valueDecimalPlaces} = wrapper;
  const currencyCodeToUse = currency || currencyCode;
  // TODO PRECISION should this just check if value is a not a number?
  if (value === "N/A") {
    return value;
  }

  const decimalPlacesToUse = firstWithValue(decimalPlaces, valueDecimalPlaces, 0);
  const type = getType(valueFormat, currencyCodeToUse, currencySymbol);

  const number = value || 0;
  switch (type) {
    case "PERCENT":
      return numbers.toPercentageStr(number, decimalPlacesToUse);
    case "CURRENCY":
      if (showCurrency && (currencyCodeToUse || currencySymbol)) {
        const currencySymbolToUse = currencySymbol || currencyRepo.getSymbol(currencyCodeToUse);
        const roundedNumber = roundTo(number, decimalPlacesToUse);
        const absoluteNumber = Math.abs(roundedNumber);
        const formattedAbsoluteNumber = numbro(absoluteNumber).format(numbroOptions);
        const sign = roundedNumber < 0 ? "-" : "";
        const formattedValue = sign + currencySymbolToUse + formattedAbsoluteNumber;
        if (maxDisplayLength && formattedValue.length > maxDisplayLength) {
          const formattedBigNumber = numbers.formatBigNumber(
              absoluteNumber,
              {
                fallbackValue: formattedAbsoluteNumber,
                ...abbreviationOptions
              });
          return sign + currencySymbolToUse + formattedBigNumber;
        } else {
          return formattedValue;
        }
      } else {
        const options = {valueFormat: "NUMBER", maxDisplayLength, abbreviationOptions};
        return format({value: number, decimalPlaces: decimalPlacesToUse}, options);
      }
    case "NUMBER":
      // TODO PRECISION we cannot format all numbers with commas at the moment
      //    some numbers represent IDs and especially in data explorer these need to be easily copy pasteable
      //    we could have a new DataType for columns representing NUMBER_ID that formats without commas
      //    naive code:
      //    const formattedValue = numbro(numbers.roundTo(number, decimalPlacesToUse)).format(numbroOptions);
      const formattedValue = numbers.roundTo(number, decimalPlacesToUse).toString();
      if (maxDisplayLength && formattedValue.length > maxDisplayLength) {
        return numbers.formatBigNumber(
            number,
            {
              fallbackValue: formattedValue,
              ...abbreviationOptions
            });
      } else {
        return formattedValue;
      }
    default:
      return "";
  }
};

export {
  format
};
