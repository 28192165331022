import React from "react";
import createReactClass from "create-react-class";

import GetContainerDimensions from "react-dimensions";
import PureRenderMixin from "react-addons-pure-render-mixin";

import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import { Layout, Row, Column } from "js/common/views/foundation-column-layout";
import { TextButton } from "js/common/views/inputs/buttons";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import SimpleDataTable from "js/common/views/tables/simple-data-table";

export default createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            categories,
            newCategoryName,
            onNewCategoryNameChange,
            onCreateNewCategoryRequest,
            onEditCategoryClick,
            onDeleteCategoryClick,
            isLoading,
            isCreatingNewCategory,
            categoryMgmtError,
            categoryMgmtSuccess,
            onCategoryMgmtSuccessMsgTimeout
        } = this.props;
        const createCategoryBtnLabel = isCreatingNewCategory ?
            <span><i className="fa fa-spinner fa-pulse fa-1x" />&nbsp;Creating Category</span> :
            <span>Create Category</span>;
        return (
            <div>
                <AdminHeader>
                    <Icon icon="category-tags" />
                    Create Tag Categories
                </AdminHeader>
                <Row style={{ marginTop: "1rem", marginBottom: "1.5rem" }}>
                    <Column small={12} large={10} smallCentered={true}>
                        <SimpleTextInput
                            type="text"
                            placeholder="Enter new category name here"
                            value={newCategoryName}
                            onChange={onNewCategoryNameChange}
                            onKeyPress={keyPressed => {
                                if (keyPressed === "Enter") {
                                    onCreateNewCategoryRequest();
                                }
                            }}
                            customStyle={{
                                display: "inline-block",
                                verticalAlign: "bottom",
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                                maxWidth: 450
                            }} />
                        <TextButton
                            type="primary"
                            icon={isCreatingNewCategory ? "" : "plus" }
                            label={createCategoryBtnLabel}
                            onClick={onCreateNewCategoryRequest}
                            disabled={!newCategoryName || !!categoryMgmtError || isLoading}
                            style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }} />
                    </Column>
                    <Column small={10} large={8} smallCentered={true}>
                        {categoryMgmtError && <ErrorMsg text={categoryMgmtError} />}
                        {categoryMgmtSuccess &&
                            <SuccessMsg text={categoryMgmtSuccess} onMessageTimeout={onCategoryMgmtSuccessMsgTimeout} />}
                    </Column>
                </Row>
                <Layout
                    allSmall={12}
                    allMedium={10}
                    allLarge={8}
                    smallCentered={true}
                    rowStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <TagCategoriesTable
                        categories={categories}
                        isActionsDisabled={isLoading || isCreatingNewCategory}
                        onEditCategoryClick={onEditCategoryClick}
                        onDeleteCategoryClick={onDeleteCategoryClick} />
                </Layout>
            </div>
        );
    }

});

const TagCategoriesTable = GetContainerDimensions()(createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const { categories, containerWidth } = this.props;
        const actionsColumnWidth = 300;
        const columns = [{
            label: "Name",
            textAlign: "left",
            width: containerWidth - actionsColumnWidth
        },{
            label: "Actions",
            sortMapper: () => {},
            width: actionsColumnWidth
        }];
        const rows = categories
            .map(this.tagCategoryToRow)
            .toJS();
        return (
            <div style={{ textAlign: "left" }}>
                <SimpleDataTable columns={columns} rows={rows} maxTableHeight={700} />
            </div>
        );
    },

    tagCategoryToRow(category) {
        const { isActionsDisabled, onEditCategoryClick, onDeleteCategoryClick } = this.props;
        const id = category.get("id");
        const buttonStyle = {
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: "0.8rem",
            paddingLeft: "0.8rem",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
            height: 25,
            lineHeight: 1
        };
        return [
            category.get("name"),
            <div key="actions" style={{ textAlign: "center" }}>
                <TextButton
                    icon="edit"
                    label="Edit"
                    type="defaultInner"
                    disabled={isActionsDisabled}
                    onClick={() => onEditCategoryClick(category)}
                    style={buttonStyle} />
                <TextButton
                    icon="trash-o"
                    label="Delete"
                    type="alertInner"
                    disabled={isActionsDisabled}
                    onClick={() => onDeleteCategoryClick(id)}
                    style={{ ...buttonStyle}} />
            </div>
        ];
    }

}));
