/** @jsxImportSource @emotion/react */
import React from "react";
import Immutable from "immutable";
import {Sparklines, SparklinesCurve} from "react-sparklines";

import * as DashboardUtils from "js/dashboards/utils";
import {fullReloadForTrendChart} from "js/charting/trend/data-loader";
import * as RatioRepo from "js/common/repo/ratio-repo";
import RatioPicker from "js/common/views/inputs/ratio-picker";
import DataConfigToolbar from "js/dashboards/data-config-toolbar";

import {between} from "js/common/utils/numbers";
import moment from "moment";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {getThemeColorsList} from "js/common/colors-list";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as KpiCalculator from "js/common/kpi-calculator";
import * as ratioFormatter from "js/common/utils/ratio-formatter";
import {renderUserOrGroupDetails} from "js/dashboards/utils";
import KpiDetailsDialog from "js/oneview/kpi-details/dialog";
import RatioDetailsDialog from "js/oneview/ratio-details/dialog";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import {css} from "@emotion/react";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";

const RatioComparison = React.memo(({
  dimensions,
  data,
  config,
  onRequestDialog,
  onParentConfigChange
}) => {
  const {theme} = React.useContext(CustomThemeContext);
  // TODO: Move colors to a more sensible location
  const backgroundColors = [
    "rgb(130, 127, 227)",
    "#E66F96",
    "#F4928E",
    "#657AD2",
    "#175A7B",
    "#E66F96",
    "#F4928E",
    "#657AD2",
    "#175A7B",
    "rgb(130, 127, 227)"];
  return <div style={{display: "flex", flexDirection: "column", padding: 10}}>
    <div style={{display: "flex", flexDirection: "column", height: dimensions.height - 55, overflow: "auto"}}>
      {config.get("ratiosList").map((r, i) => {
        const ratio = RatioRepo.get(r.get("id"));
        const percentageValue = ratioFormatter.format(
            "PERCENTAGE",
            data.getIn(["value", 0, i])[0].total,
            data.getIn(["value", 0, i])[1].total);
        let sparkData = [];
        if (data.getIn(["value", 1, "trends", i, "trend"])) {
          data.getIn(["value", 1, "trends", i, "trend"]).map(t => sparkData.push(t.value));
        }
        // Note, this code strips off the 0 value data for future dates if the following for:
        // ytd, qtd, mtd, wtd
        const daysLeftThisWeek = moment(moment().endOf("week")).diff(moment(new Date()), "days");
        const weeksLeftThisQuarter = moment(moment().endOf("quarter")).diff(moment(new Date()), "weeks");
        const monthsLeftThisYear = moment(moment().endOf("year")).diff(moment(new Date()), "months");
        const weeksLeftThisMonth = moment(moment().endOf("month")).diff(moment(new Date()), "weeks");

        const sparkOffsets = {
          ytd: -monthsLeftThisYear,
          qtd: -weeksLeftThisQuarter,
          mtd: -weeksLeftThisMonth,
          wtd: -daysLeftThisWeek
        };

        const sparkOffset = sparkOffsets[config.get("timeframe").get("type")];
        sparkData = sparkOffset ? sparkData.slice(0, sparkOffset) : sparkData;

        const calculateMaxRange = Math.max(...data.get("value")[0].map(o => {
          // Avoid trying to divide by 0
          if (o[0].total.value === 0) {
            return 0;
          }
          return (o[1].total.value / o[0].total.value) * 100;
        }));

        const maxRange = config.get("maxRange") === "100-percent" ? 100 : calculateMaxRange;
        const timeframeType = config.get("timeframe").get("type");

        const LinearGradientFill = stopColor => {
          return (
              <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor={backgroundColors[i]} stopOpacity="1" />
                <stop offset="100%" stopColor={backgroundColors[i]} stopOpacity="0.2" />
              </linearGradient>
          );
        };

        const firstRatioKpi = KpiRepo.get(ratio.get("firstKpiId"));
        const secondRatioKpi = KpiRepo.get(ratio.get("secondKpiId"));
        const isRatioTrendable = firstRatioKpi.get("trendable") && secondRatioKpi.get("trendable");
        const isTodayorYesterday = timeframeType === "today" || timeframeType === "yesterday";
        const minWidthLimit = Math.round(maxRange / 100 * 6);
        return (
            <div
                style={{display: "flex", marginBottom: "1rem", alignItems: "center"}}
                onClick={() => renderDialog(
                    ratio,
                    data.getIn(["value", 0, i])[0],
                    data.getIn(["value", 0, i])[1],
                    config,
                    data,
                    onParentConfigChange,
                    onRequestDialog)}>
              <div style={{minWidth: 220, fontSize: 13}}>{ratio.get("name")}</div>
              <div style={{width: 220, marginRight: "24px", position: "relative"}}>
                {sparkData.length > 0 && !isTodayorYesterday ?
                    <Sparklines data={sparkData} width={190} height={50}>
                      <svg data-test-id={"sparklines-curve"}>
                        <defs>
                          <LinearGradientFill />
                        </defs>
                      </svg>
                      <SparklinesCurve
                          style={{
                            strokeWidth: 3,
                            fill: "url(#gradient)"
                          }}
                          color={backgroundColors[i]}
                      />
                    </Sparklines> :
                    <div style={{position: "relative"}}>
                      <div style={{opacity: 0.3}}>
                        <Sparklines data={[0, 10, 0, 10]} width={190} height={50}>
                          <svg>
                            <defs>
                              <LinearGradientFill />
                            </defs>
                          </svg>
                          <SparklinesCurve
                              style={{
                                strokeWidth: 3,
                                fill: "url(#gradient)"
                              }}
                              color="#ccc"
                          />
                        </Sparklines>
                      </div>
                      <div
                          style={{
                            position: "absolute",
                            fontSize: 10,
                            width: "100%",
                            textAlign: "center",
                            bottom: 14
                          }}>{isTodayorYesterday ? "Not Trendable" : isRatioTrendable
                          ? "No Data"
                          : "Not Trendable"}</div>
                    </div>
                }
                <div
                    style={{
                      position: "absolute",
                      left: 0,
                      width: "100%",
                      bottom: -8,
                      fontSize: 10,
                      display: "flex",
                      justifyContent: "space-between"
                    }}>
                  <span>{sparkData.length > 0 && timeframeType !== "today" ?
                      `${Math.round((sparkData[0] * 100) / 10)}%` : ""}</span>
                  <span>{sparkData.length > 0 && timeframeType !== "today" ?
                      `${Math.round(sparkData[sparkData.length - 1] * 100) / 10}%` : ""}</span>
                </div>
              </div>
              <div
                  style={{
                    display: "flex",
                    width: "100%",
                    position: "relative",
                    alignItems: "center",
                    height: 40
                  }}>
                <div
                    style={{
                      zIndex: 0,
                      borderRadius: 3,
                      backgroundColor: backgroundColors[i],
                      opacity: 0.33,
                      position: "absolute",
                      left: 0,
                      top: 0,
                      width: "100%",
                      height: "34px"
                    }} />
                <div
                    style={{
                      marginTop: "-6px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      zIndex: 1,
                      borderRadius: 3,
                      backgroundColor: backgroundColors[i],
                      width: config.get("maxRange") === "100-percent" ? percentageValue :
                          `${Math.round(Number(percentageValue.replace("%", "")) / maxRange * 100)}%`,
                      height: "24px",
                      minWidth: "1%"
                    }}>
                                <span
                                    style={{
                                      display: "flex",
                                      fontWeight: "600",
                                      color: Number(percentageValue.replace("%", "")) < minWidthLimit ? theme.palette.text.main :
                                          "#fff",
                                      fontSize: 12,
                                      marginRight: Number(percentageValue.replace("%", "")) < minWidthLimit ? -24 : 6
                                    }}>{percentageValue}</span>
                </div>
                <div
                    style={{
                      position: "absolute",
                      zIndex: 0.5,
                      width: "100%",
                      bottom: 6,
                      height: "34px",
                      overflow: "hidden"
                    }}>
                                <span
                                    style={{
                                      background: "#000",
                                      opacity: 0.12,
                                      left: 0,
                                      position: "absolute",
                                      width: "1px",
                                      height: "100%"
                                    }} />
                  <span
                      style={{
                        background: "#000",
                        opacity: 0.12,
                        left: "10%",
                        position: "absolute",
                        width: "1px",
                        height: "100%"
                      }} />
                  <span
                      style={{
                        background: "#000",
                        opacity: 0.12,
                        left: "20%",
                        position: "absolute",
                        width: "1px",
                        height: "100%"
                      }} />
                  <span
                      style={{
                        background: "#000",
                        opacity: 0.12,
                        left: "30%",
                        position: "absolute",
                        width: "1px",
                        height: "100%"
                      }} />
                  <span
                      style={{
                        background: "#000",
                        opacity: 0.12,
                        left: "40%",
                        position: "absolute",
                        width: "1px",
                        height: "100%"
                      }} />
                  <span
                      style={{
                        background: "#000",
                        opacity: 0.12,
                        left: "50%",
                        position: "absolute",
                        width: "1px",
                        height: "100%"
                      }} />
                  <span
                      style={{
                        background: "#000",
                        opacity: 0.12,
                        left: "60%",
                        position: "absolute",
                        width: "1px",
                        height: "100%"
                      }} />
                  <span
                      style={{
                        background: "#000",
                        opacity: 0.12,
                        left: "70%",
                        position: "absolute",
                        width: "1px",
                        height: "100%"
                      }} />
                  <span
                      style={{
                        background: "#000",
                        opacity: 0.12,
                        left: "80%",
                        position: "absolute",
                        width: "1px",
                        height: "100%"
                      }} />
                  <span
                      style={{
                        background: "#000",
                        opacity: 0.12,
                        left: "90%",
                        position: "absolute",
                        width: "1px",
                        height: "100%"
                      }} />
                  <span
                      style={{
                        background: "#000",
                        opacity: 0.12,
                        right: 0,
                        position: "absolute",
                        width: "1px",
                        height: "100%"
                      }} />
                </div>
                <div
                    style={{
                      position: "absolute",
                      left: 0,
                      width: "100%",
                      bottom: -8,
                      fontSize: 10,
                      display: "flex",
                      justifyContent: "space-between"
                    }}>
                  <span>0%</span>
                  <span>{Math.round(maxRange)}%</span>
                </div>
              </div>
            </div>);
      })}
    </div>
  </div>;
});

const RatioComparisonEditor = React.memo(({
  component,
  onComponentChange
}) => {
  const dataConfig = component.get("dataConfig");

  const {theme} = React.useContext(CustomThemeContext);
  const [addNewRatio, setAddNewRatio] = React.useState(dataConfig.get("ratiosList").count() < 2);

  const onDataConfigChange = React.useCallback(dataConfig => {
    onComponentChange(component.set("dataConfig", dataConfig));
  }, [onComponentChange, component]);

  const getRatios = () => RatioRepo.getAll();
  const maxRatios = 10;
  const ratiosList = dataConfig.get("ratiosList");

  const reorderArray = (event, originalArray) => {
    const movedItem = originalArray.find((item, index) => index === event.oldIndex);
    const remainingItems = originalArray.filter((item, index) => index !== event.oldIndex);

    const reorderedItems = [
      ...remainingItems.slice(0, event.newIndex),
      movedItem,
      ...remainingItems.slice(event.newIndex)
    ];

    return reorderedItems;
  };

  function changeOrder(index, direction) {
    const newOrderedArray = reorderArray({
      oldIndex: index,
      newIndex: index + (direction === "UP" ? (-1) : 1)
    }, ratiosList.toJS());
    onDataConfigChange(dataConfig.set("ratiosList", Immutable.fromJS(newOrderedArray)));
  }

  const switcherStyle = css`
    border-radius: 8px;
    background: ${theme.palette.background.card};
    border: 1px solid ${theme.palette.background.paper};
    box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    z-index: 1;
  `;

  const panelStyle = (attribute, value) => css`
    background: ${dataConfig.get(attribute) === value ? theme.themeId === "light" ? "#e4ecf7" : "#000" : "transparent"};
    display: flex;
    justify-content: center;
    flex-grow: 1;
    font-size: 16px;
    text-align: center;
    padding: 10px 10px;
    cursor: pointer;
    border-left: 1px solid ${theme.palette.background.paper};

    &:first-of-type {
      border-left: none;
    }

    &:hover {
      background: ${theme.palette.background.paper};
    }
  `;

  return <div style={{overflowY: "auto", height: "calc(100% - 130px)", padding: "0.5rem"}}>
    <DataConfigToolbar config={dataConfig} onConfigChange={onDataConfigChange} />
    <h3 style={{fontSize: "0.8rem", margin: "1.5rem 0 10px 0"}}>Range</h3>
    <div style={{width: "50%", marginBottom: "0.3rem"}}>
      <div style={{zIndex: 1, width: 440}}>
        <div css={switcherStyle}>
              <span
                  css={panelStyle("maxRange", "100-percent")}
                  onClick={() => onDataConfigChange(dataConfig.set("maxRange", "100-percent"))}>
                <span style={{fontSize: 11, minWidth: 150}}>0 to 100%</span>
              </span>
          <span
              css={panelStyle("maxRange", "maximum")}
              onClick={() => onDataConfigChange(dataConfig.set("maxRange", "maximum"))}>
                      <span style={{fontSize: 11, minWidth: 150}}>0 to Maximum Value</span>
              </span>
        </div>
        {dataConfig.get("maxRange") === "maximum" &&
            <Hint style={{marginTop: 10}}>
              <Icon icon="info" style={{color: theme.palette.hints.text}} />
              This will display based on the maximum value across all ratios. For example, if the maximum value is 25%,
              then the line bar will display percentages up to 25%. If the maximum value is 250%, then the line bar will
              display percentages up to 250%.
            </Hint>
        }
      </div>
      <h3 style={{fontSize: "0.8rem", margin: "1.5rem 0 10px 0"}}>Ratios</h3>
      {ratiosList.map((ratio, index) =>
          <div style={{display: "flex", marginBottom: "0.5rem", alignItems: "center"}}>
            <div style={{width: 40}}>
                        <span onClick={() => changeOrder(index, "UP")}><i
                            style={{
                              fontSize: 12,
                              marginRight: 5,
                              opacity: index === 0 ? "0.2" : "1",
                              pointerEvents: index === 0 ? "none" : "auto"
                            }} className="bhi-arrow-up" /></span>
              <span onClick={() => changeOrder(index, "DOWN")}><i
                  style={{
                    fontSize: 12,
                    marginRight: 5,
                    opacity: index + 1 === ratiosList.count() ? "0.2" : "1",
                    pointerEvents: index + 1 === ratiosList.count() ? "none" : "auto"
                  }} className="bhi-arrow-down" /></span>
            </div>
            <div style={{width: 24, fontSize: "1.2em", opacity: 0.3, fontWeight: 600}}>{index + 1}.</div>
            <div style={{width: 400}}>
              <RatioPicker
                  label={RatioRepo.get(ratio.get("id")).get("name") || "Select Ratio"}
                  ratios={getRatios()
                      .deleteAll(ratiosList.map(r => r.get("id")))
                      .toList()
                      .filter(r => !r.get("isDeleted"))}
                  onRatioSelect={(r) => {
                    const updatedRatiosList = ratiosList.set(index, r);
                    onDataConfigChange(
                        dataConfig
                            .set("ratiosList", updatedRatiosList)
                    );
                    setAddNewRatio(false);
                  }}
                  closeOnSelect={true} />
            </div>

            {index > 0 &&
                <i
                    className="bhi-times"
                    style={{marginLeft: "0.5rem", fontSize: "0.8rem", color: theme.palette.error.main}}
                    onClick={() => {
                      onDataConfigChange(dataConfig.set("ratiosList", ratiosList.delete(index)));
                    }} />
            }
            {index + 1 === ratiosList.count() && !addNewRatio && ratiosList.count() !== maxRatios &&
                <div
                    style={{
                      position: "relative",
                      right: -50,
                      lineHeight: "25px",
                      textAlign: "center",
                      width: 30,
                      height: 30,
                      borderRadius: "50%",
                      background: theme.palette.primary.main,
                      color: theme.palette.text.inverted
                    }}
                    onClick={() => setAddNewRatio(true)}>
                  <i className="bhi-plus" style={{fontSize: "12px"}} />
                </div>
            }
          </div>
      )}
      {addNewRatio && <div>
        {ratiosList.count() < maxRatios ?
            <div style={{display: "flex", alignItems: "center"}}>
              {ratiosList.count() > 0 && <div style={{width: 40}} />}
              <div
                  style={{
                    width: 24,
                    fontSize: "1.2em",
                    opacity: 0.3,
                    fontWeight: 600
                  }}>{ratiosList.count() + 1}.
              </div>
              <div style={{width: 400}}>
                <RatioPicker
                    label={"Select Ratio"}
                    ratios={getRatios()
                        .deleteAll(ratiosList.map(r => r.get("id")))
                        .toList()
                        .filter(r => !r.get("isDeleted"))}
                    onRatioSelect={(r) => {
                      onDataConfigChange(
                          dataConfig
                              .set("ratiosList", ratiosList.push(r.set("order", ratiosList.count() + 1)))
                      );
                      setAddNewRatio(false);
                    }}
                    closeOnSelect={true} />
              </div>
              {ratiosList.count() > 1 && <i
                  className="bhi-times" style={{
                marginLeft: "0.5rem",
                fontSize: "0.8rem",
                color: theme.palette.error.main
              }} onClick={() => setAddNewRatio(false)} />}
            </div>
            : <div>You can only trend {maxRatios} ratios at a time.</div>
        }
      </div>}

      {ratiosList.count() >= maxRatios &&
          <div style={{maxWidth: 500, margin: "20px 0 10px 0"}}>
            <Hint>
              <Icon icon="info" style={{color: theme.palette.hints.text}} />
              You can only trend {maxRatios} ratios at a time.
            </Hint>
          </div>
      }

    </div>
  </div>;
});

const renderDialog = (ratio, firstData, secondData, config, data, onParentConfigChange, onRequestDialog) => {
  const kpiDetailsOptions = {
    timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
    clientIds: config.getIn(["clientFilter", "allClientIds"], Immutable.List()),
    entityToIds: config.get("entityToIds", Immutable.Map()).toObject(),
    ...DashboardUtils.configToQualifierAjaxParams(config)
  };

  const ratioDetailsOptions = {
    initialTab: "DETAILS",
    ratio: Immutable.Map({
      name: ratio.get("name"),
      firstKpiId: ratio.get("firstKpiId"),
      secondKpiId: ratio.get("secondKpiId"),
      displayType: ratio.get("displayType")
    }),
    ratioData: Immutable.Map({
      firstKpiData: Immutable.Map(Immutable.fromJS(firstData)),
      secondKpiData: Immutable.Map(Immutable.fromJS(secondData))
    }),
    timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
    clientIds: config.getIn(["clientFilter", "allClientIds"], Immutable.List()),
    ...DashboardUtils.configToQualifierAjaxParams(config)
  };

  const title = (
      <div style={{display: "inline-block"}}>
      <span
          style={{
            fontSize: 20,
            textTransform: "none",
            fontWeight: 500
          }}>
          {ratio.get("name")}
      </span>
        <span style={{fontWeight: 500}}>
        {` (${TimeframeRepo.parse(config.get("timeframe").toJS()).attributes.name})`}
      </span>
        {renderUserOrGroupDetails(config)}
      </div>
  );

  const kpiDialog = (kpiId) => {
    const closeDialog = onRequestDialog(
        <KpiDetailsDialog
            renderContentOnly
            kpiId={kpiId}
            {...kpiDetailsOptions}
            matchAnyTagIds={config.getIn(["tagFilter", "matchAnyTagIds"], Immutable.List())}
            matchAllTagIds={config.getIn(["tagFilter", "matchAllTagIds"], Immutable.List())}
            excludedTagIds={config.getIn(["tagFilter", "excludedTagIds"], Immutable.List())}
            onGroupClick={groupId => {
              onParentConfigChange(parentConfig => parentConfig.set("qualifier", Immutable.Map({
                type: "GROUP",
                id: groupId
              })), true);
              closeDialog();
            }}
            onUserClick={userId => {
              onParentConfigChange(parentConfig => parentConfig.set("qualifier", Immutable.Map({
                type: "USER",
                id: userId
              })), true);
              closeDialog();
            }} />,
        {title: title});
  };

  const closeDialog = onRequestDialog(
      <RatioDetailsDialog
          renderContentOnly
          {...ratioDetailsOptions}
          matchAnyTagIds={config.getIn(["tagFilter", "matchAnyTagIds"], Immutable.List())}
          matchAllTagIds={config.getIn(["tagFilter", "matchAllTagIds"], Immutable.List())}
          excludedTagIds={config.getIn(["tagFilter", "excludedTagIds"], Immutable.List())}
          onKpiClick={kpiId => {
            closeDialog();
            kpiDialog(kpiId);
          }}
      />,
      {title: title, minWidth: "fit-content", minHeight: 230});
};

const getInitialDataAggregation = (timeframe) => {
  const start = moment(timeframe.start);
  const end = moment(timeframe.end);
  const noOfDays = end.diff(start, "days") + 1;
  let dataAggregation;

  if (between(noOfDays, 1, 31)) {
    dataAggregation = "DAILY";
  }
  if (between(noOfDays, 31, 180)) {
    dataAggregation = "WEEKLY";
  }
  if (between(noOfDays, 180, 365 * 2)) {
    dataAggregation = "MONTHLY";
  }
  if (between(noOfDays, 365 * 2, 365 * 5)) {
    dataAggregation = "QUARTERLY";
  }
  if (noOfDays > 365 * 5) {
    dataAggregation = "YEARLY";
  }

  return dataAggregation;
};
const getDefaultRatiosDataGroup = config => {
  const dataGroupIndex = 0;
  const howToPlot = config.displayDataCumulatively ? "cumulative" : "non-cumulative";

  const getDefaultColour = (trendIndex, dataGroupIndex = 0) => {
    const coloursList = getThemeColorsList(dataGroupIndex);
    return coloursList[trendIndex % coloursList.length];
  };

  const ratiosList = config.ratiosList.map((r, index) => {
    const ratio = RatioRepo.get(r.id).toJS();
    return {
      cid: Math.random(),
      type: "RATIO",
      ratio: ratio,
      whatToPlot: config.averageByUser ? "average-by-user" : "value",
      seriesColour: getDefaultColour(index, dataGroupIndex),
      lineStyle: "Solid",
      dataPointMarker: null,
      howToPlot,
      yAxis: 0
    };
  });

  return [
    {
      cid: Math.random(),
      name: "",
      timeframe: config.timeframe,
      trendConfigs: ratiosList,
      qualifierType: config.qualifier.type,
      qualifierId: config.qualifier.id,
      clientIds: config.clientFilter.allClientIds,
      matchAnyTagIds: config.tagFilter.matchAnyTagIds,
      matchAllTagIds: config.tagFilter.matchAllTagIds,
      excludedTagIds: config.tagFilter.excludedTagIds,
      dateDisplay: "ABSOLUTE"
    }];
};

export default Immutable.fromJS({
  type: "RatioComparison",
  label: "Ratio Comparison",
  getDefaultData: () => ({isLoading: false, trends: []}),
  getReactComponent: () => RatioComparison,
  getEditorReactComponent: () => RatioComparisonEditor,
  getTitle: component => component.get("title"),
  variant: "scrollable",
  canFullScreen: true,
  layout: {
    min: {width: 20, height: 6},
    max: {width: 48, height: 24},
    init: {width: 24, height: 12}
  },
  applyParentConfig: (parentConfig, dataConfig) => DashboardUtils.applyInheritanceForKeys(parentConfig, dataConfig),
  load: component => {
    const config = component.get("dataConfig");

    const ajaxParams = {
      dateFromUI: moment().format("YYYY-MM-DD"),
      timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
      clientIds: config.getIn(["clientFilter", "allClientIds"]).toArray(),
      matchAnyTagIds: config.getIn(["tagFilter", "matchAnyTagIds"]).toArray(),
      matchAllTagIds: config.getIn(["tagFilter", "matchAllTagIds"]).toArray(),
      excludedTagIds: config.getIn(["tagFilter", "excludedTagIds"]).toArray(),
      entityToIds: config.get("entityToIds", Immutable.Map()).toObject(),
      ...DashboardUtils.configToQualifierAjaxParams(config)
    };

    const ratioValues = Promise.all(config.get("ratiosList").map(r => {
      const ratio = RatioRepo.get(r.get("id"));
      if (ratio.has("firstKpiId") && ratio.has("secondKpiId")) {
        const firstPromise = KpiCalculator
            .summary(ratio.get("firstKpiId"), ajaxParams)
            .then(response => response);

        const secondPromise = KpiCalculator
            .summary(ratio.get("secondKpiId"), ajaxParams)
            .then(response => response);

        return Promise.all([firstPromise, secondPromise]);

      } else {
        return Promise.resolve(Immutable.Map());
      }
    }));

    const jsConfig = config.toJS();

    const chartConfig = {
      ...jsConfig,
      trendType: "ADVANCED",
      dataAggregation: getInitialDataAggregation(jsConfig.timeframe),
      dataGroups: getDefaultRatiosDataGroup(jsConfig)
    };

    const {promiseForFinalData} = fullReloadForTrendChart(chartConfig, true);

    return Promise.all([ratioValues, promiseForFinalData]);
  }
});
