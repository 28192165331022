import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import ActiveUsersList from "js/oneview/header/active-users-list";

const Breadcrumbs = createReactClass({

  mixins: [PureRenderMixin],

  props: {
    breadcrumbs: ReactPropTypes.array.isRequired,
    onClick: ReactPropTypes.func.isRequired,
    dividerIcon: ReactPropTypes.string,
    customDividerStyle: ReactPropTypes.object,
    showDividerAfterLastItem: ReactPropTypes.bool,
    isDisabled: ReactPropTypes.bool
  },

  getDefaultProps() {
    return {
      dividerIcon: "chevron-right",
      showDividerAfterLastItem: false,
      isDisabled: false
    };
  },

  render() {
    const {
      canOnlySeeSelf,
      currentGroup,
      onUserClick,
      breadcrumbs,
      onClick,
      dividerIcon,
      customDividerStyle,
      showDividerAfterLastItem,
      isDisabled,
      theme
    } = this.props;

    return (
        <div style={{display: "flex", alignItems: "center", lineHeight: 2, flexWrap: "wrap"}}>
          {breadcrumbs.map((crumb, index) => {
            const id = crumb.id;
            const isLast = index === breadcrumbs.length - 1;
            const dividerStyle = {
              fontSize: "0.825rem",
              color: theme.palette.text.main,
              paddingLeft: "10px",
              paddingRight: "10px",
              ...customDividerStyle
            };
            return (
                <span key={`breadcrumb-${id}`} style={{whiteSpace: "nowrap"}}>
                            <Link theme={theme} crumb={crumb} isDisabled={isDisabled} onClick={onClick} />
                  {(!isLast || showDividerAfterLastItem) &&
                      <i key={`divider-${id}`} className={`fa fa-${dividerIcon}`} style={dividerStyle} />}
                        </span>
            );
          })}
          {!canOnlySeeSelf && (
              <span>
                        <ActiveUsersList
                            currentGroup={currentGroup}
                            onUserClick={id => onUserClick(id)} />
                    </span>)}
        </div>);
  }

});

const Link = pure(({
  crumb,
  isDisabled,
  onClick,
  theme
}) => {
  const style = {
    cursor: isDisabled ? "default" : "pointer",
    color: theme.palette.text.main,
    fontSize: "15px",
    ":hover": {
      color: isDisabled ? theme.palette.text.main : theme.palette.primary.main,
      textDecoration: isDisabled ? "none" : "underline"
    }
  };
  return <span style={style} onClick={isDisabled ? () => {} : () => onClick(crumb.id)}>{crumb.name}</span>;
});

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <Breadcrumbs theme={theme} {...props} />;
};
