import store from "store";
import moment from "moment";

import * as Users from "js/common/users";

const getUserLocalStorageKey = id => {
    const currentUser = Users.getCurrentUser();
    return `user-${currentUser.get("id")}-car-${id}`;
};

const saveChannelToLocalStorage = channel => {
    const now = moment().format("YYYY-MM-DD HH:mm:ss");
    const updatedChannel = channel.set("lastModified", now);
    return store.set(getUserLocalStorageKey(channel.get("id")), updatedChannel);
};

const removeChannelFromLocalStorage = id => store.remove(getUserLocalStorageKey(id));

const getChannelFromLocalStorage = id => store.get(getUserLocalStorageKey(id));

export {
    saveChannelToLocalStorage,
    removeChannelFromLocalStorage,
    getChannelFromLocalStorage
};
