import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";

import NumberField from "js/common/views/inputs/number-field";
import SectionHeader from "js/admin/cubetv/section-header";
import Layout from "js/common/views/foundation-column-layout";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import {TextButton} from "js/common/views/inputs/buttons";
import {startsWith} from "js/common/utils/strings";
import {getAutoplayVideoUrl} from "js/common/utils/autoplay-youtube-video-url";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import { TextField } from '@mui/material';
import Checkbox from "js/common/views/inputs/checkbox";

const pathToConfig = ["layout", "fullscreen", 0];
const pathToVideoUrl = [...pathToConfig, "videoUrl"];
const pathToMuteVideoFlag = [...pathToConfig, "muteVideo"];
const pathToShowSubtitlesFlag = [...pathToConfig, "showSubtitles"];

const EditSlide = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    slide: ReactPropTypes.object.isRequired,
    onChange: ReactPropTypes.func.isRequired,
    theme: ReactPropTypes.object
  },

  getInitialState() {
    return {
      isInvalidVideoUrl: false
    };
  },

  render() {
    return (
        <div>
          <SectionHeader text="Display a YouTube video" />
          <EditEmbeddedVideoSlide
              theme={this.props.theme}
              displayTimeInMillis={this.props.slide.get("timing")}
              onDisplayTimeChange={this.handleDisplayTimeChange}
              videoUrl={this.props.slide.getIn(pathToVideoUrl)}
              onVideoUrlChange={this.handleVideoUrlChange}
              muteVideo={this.props.slide.getIn(pathToMuteVideoFlag)}
              onMuteVideoClick={this.handleMuteVideoClick}
              showSubtitles={this.props.slide.getIn(pathToShowSubtitlesFlag)}
              onShowSubtitlesClick={this.handleShowSubtitlesClick}
              isInvalidVideoUrl={this.state.isInvalidVideoUrl} />
        </div>
    );
  },

  handleDisplayTimeChange(newDisplayTime) {
    this.props.onChange(this.props.slide.set("timing", newDisplayTime));
  },

  handleVideoUrlChange(newVideoUrl) {
    this.props.onChange(this.props.slide.setIn(pathToVideoUrl, newVideoUrl));
    const isValidVideoUrl = validateVideoUrl(newVideoUrl);
    this.setState({
      isInvalidVideoUrl: !isValidVideoUrl
    });
  },

  handleMuteVideoClick(isChecked) {
    this.props.onChange(this.props.slide.setIn(pathToMuteVideoFlag, isChecked));
  },

  handleShowSubtitlesClick(isChecked) {
    this.props.onChange(this.props.slide.setIn(pathToShowSubtitlesFlag, isChecked));
  }

});

const EditEmbeddedVideoSlide = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const { theme } = this.props;
    const invalidUrlMsg = "Please enter a valid YouTube video URL (e.g. https://www.youtube.com/watch?v=HSIxdeR9Dzg)";
    return (
        <div>
          <Hint>
            <Icon icon="info" style={{color: theme.palette.hints.text}} />
            <HelpInfoMsg theme={theme} />
          </Hint>
          <Layout allSmall={12} medium={[12, 6, 4]} large={[3, 5, 4]} floatLastColumnLeft={true}>
            <NumberField
                range={[1, 10800]}
                label="Seconds to Display Slide"
                value={this.props.displayTimeInMillis / 1000}
                onChange={displayTimeInSecs => this.props.onDisplayTimeChange(displayTimeInSecs * 1000)} />
            <TextField variant="standard" InputLabelProps={{style: {color: "#757575"}}}
                fullWidth={true}
                label="YouTube Video Link"
                value={this.props.videoUrl}
                error={this.props.isInvalidVideoUrl}
                helperText={this.props.isInvalidVideoUrl ? invalidUrlMsg : ""}
                onChange={e => this.props.onVideoUrlChange(e.target.value)} />
            <div>
              <Checkbox
                  label="Mute Video"
                  style={{width: 131, paddingTop: "0.3rem", marginRight: "1.5rem"}}
                  checked={this.props.muteVideo}
                  onCheck={(e, isChecked) => this.props.onMuteVideoClick(isChecked)} />
              <Checkbox
                  label="Show Subtitles"
                  style={{width: 153, paddingTop: "0.3rem", marginRight: "1.5rem"}}
                  checked={this.props.showSubtitles}
                  onCheck={(e, isChecked) => this.props.onShowSubtitlesClick(isChecked)} />
            </div>
          </Layout>
          <Layout allSmall={12} rowStyle={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
            <Hint>
              <Icon icon="idea" style={{color: theme.palette.hints.text}} />
              <div>
                <p style={{...paragraphStyle, fontFamily: theme.typography.fontFamilyBold}}>
                  Want the video start from a specific point?
                </p>
                <p style={paragraphStyle}>
                  You can get a YouTube video link with the start time appended by pausing your video at the point
                  you want, click on <strong style={{
                  ...strongTextStyle(theme),
                  textTransform: "uppercase"
                }}><i className="fa fa-share" /> Share</strong> then click on the <strong style={strongTextStyle(theme)}>Start
                  at</strong> checkbox.
                </p>
              </div>
            </Hint>
          </Layout>
          <Layout allSmall={12} rowStyle={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
            <TextButton
                label="Preview Slide"
                onClick={this.showSlidePreview}
                disabled={!validateVideoUrl(this.props.videoUrl)} />
          </Layout>
        </div>
    );
  },

  showSlidePreview() {
    const {videoUrl, muteVideo, showSubtitles, displayTimeInMillis} = this.props;
    const displayTimeInSecs = displayTimeInMillis / 1000;
    const finalVideoUrl = getAutoplayVideoUrl(videoUrl, muteVideo, showSubtitles, displayTimeInSecs);
    const win = window.open();
    win.document.write(
        `<html>
                <head>
                    <meta charset="utf-8">
                    <link rel="icon" href="/favicon.ico" sizes="32x32">
                    <link rel="icon" href="/favicon.ico" sizes="16x16">
                    <title>Gamification Video Slide Preview</title>
                    <!-- LOCAL ENV -->
                    <link rel="stylesheet" type="text/css" href="src/css/app.css" />
                    <!-- PROD ENV -->
                    <link rel="stylesheet" type="text/css" href="app.min.css" />
                </head>
                <body>
                    <div>
                        <iframe
                            type="text/html"
                            width="${window.innerWidth}"
                            height="${window.innerHeight}"
                            src="${finalVideoUrl}"
                            frameborder="0"
                            allow="autoplay; encrypted-media"
                            allowfullscreen />
                    </div>
                </body>
            </html>`);
  }

});

const HelpInfoMsg = ({theme}) => (
    <div>
      <p style={paragraphStyle}>To ensure that the video autoplays as the slide transitions in Google Chrome, you will need to amend your Google Chrome Sound Settings by pasting the following link into your address bar:</p>
      <p style={paragraphStyle}><code style={strongTextStyle(theme)}>chrome://settings/content/sound</code></p>
      <p style={{...paragraphStyle, marginBottom: "0.5rem"}}>Then, add <code style={strongTextStyle(theme)}>[*.]cube19.io</code> under the 'Allow' section.</p>
      <p style={paragraphStyle}>Please also make sure that you change the <span style={strongTextStyle(theme)}>Seconds to Display Slide</span> field to match the length of your video, in seconds.</p>
    </div>
);

const paragraphStyle = {
  fontSize: "inherit",
  marginBottom: 0
};

const strongTextStyle = theme =>({
  fontFamily: theme.typography.fontFamilyBold,
  color: theme.palette.primary.main
});

const validateVideoUrl = videoUrl => {
  const acceptedYouTubeUrls = ["https://youtu.be/", "https://www.youtube.com/"];
  return acceptedYouTubeUrls.some(ytUrl => startsWith(ytUrl, videoUrl) && getYouTubeVideoId(videoUrl).length > 0);
};

const getYouTubeVideoId = videoUrl => {
  let youTubeId = "";
  const url = videoUrl.replace(/(>|<)/gi, "").split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    youTubeId = url[2].split(/[^0-9a-z_-]/i);
    youTubeId = youTubeId[0];
  }
  return youTubeId;
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <EditSlide theme={theme} {...props} />;
};