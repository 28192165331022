import Immutable from "immutable";
import getKpiDataSafely from "js/onpoint/get-kpi-data-safely";
import * as numbers from "js/common/utils/numbers";
import {format} from "js/common/utils/ratio-formatter";

export const formatRowDataForCsv = (row, columns) => {
  let rowData = Immutable.List();
  columns.forEach(col => {
    if (col.get("type") === "KPI") {
      const kpiId = col.getIn(["kpi", "id"]);
      const kpiData = row.getIn(["dataByKpiId", kpiId]);
      const total = getKpiDataSafely(kpiData, "total");
      const isMonetary = !!total.get("currency");
      const totalValue = total.get("value");
      const target = getKpiDataSafely(kpiData, "target");
      const targetValue = target.get("value");
      const hasTarget = targetValue > 0;
      const targetPercent = getPercentageValue(totalValue, targetValue);
      const expected = getKpiDataSafely(kpiData, "expectedValue");
      const expectedTargetValue = expected.get("value");
      const expectedTargetPercent = getPercentageValue(totalValue, expectedTargetValue);
      rowData = rowData
          .push(roundValue(totalValue, isMonetary))
          .push(hasTarget ? roundValue(targetValue, isMonetary) : "")
          .push(hasTarget ? numbers.toPercentageStr(targetPercent) : "")
          .push(hasTarget ? numbers.toPercentageStr(expectedTargetPercent) : "");
    } else if (col.get("type") === "RATIO") {
      const ratio = col.get("ratio");
      const displayType = ratio.get("displayType");
      const firstKpiId = ratio.get("firstKpiId");
      const firstKpiData = row.getIn(["dataByKpiId", firstKpiId]);
      const firstKpiTotal = getKpiDataSafely(firstKpiData, "total");
      const secondKpiId = ratio.get("secondKpiId");
      const secondKpiData = row.getIn(["dataByKpiId", secondKpiId]);
      const secondKpiTotal = getKpiDataSafely(secondKpiData, "total");
      // space added before ratio value to prevent value being converted to datetime format by Excel
      rowData = rowData.push(` ${format(displayType, firstKpiTotal.toJS(), secondKpiTotal.toJS())}`);
    }
  });
  return rowData;
};

const getPercentageValue = (numerator, denominator) => {
  const percentage = denominator === 0 ? 0 : numerator / denominator;
  return Math.floor(percentage * 100) / 100;
};

const roundValue = (value, isMonetary) => {
  if (isMonetary) {
    return numbers.roundTo(value, 0);
  } else {
    return numbers.roundTo(value, 2);
  }
};
