import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {betterMemo} from "js/common/utils/more-memo";
import Icon from "js/admin/common/icon";

/**
 * Common component for rendering a hint section
 * Assumes you want a bottom margin, can be overridden by passing a style prop
 */
// TODO: Make this a common component to replace src/js/common/views/info-text-box.js

const containerStyle = (theme, style) => {
  return {
    background: theme.palette.hints.background,
    color: theme.palette.hints.text,
    fontSize: "0.8rem",
    lineHeight: 1.6,
    display: "flex",
    maxWidth: "100%",
    padding: "0.5em 0.75em",
    border: `1px solid ${theme.palette.hints.border}`,
    borderRadius: 5,
    alignItems: "center",
    gap: "0 0.8em",
    marginBottom: "1.5em",
    ...style
  };
};

const Hint = betterMemo(
    {displayName: "Hint"},
    ({icon, children, style}) => {
      const {theme} = React.useContext(CustomThemeContext);

      return (
          <div style={containerStyle(theme, style)}>
            {icon && <Icon icon={icon} style={{color: theme.palette.hints.text}} />}
            {children}
          </div>
      );
    });

export default Hint;
