import Immutable from "immutable";

export default () => {
    const cid = Math.random();

    return Immutable.fromJS({
        cid: cid,
        id: `slide-${cid}`,
        pageTitle: {
            title: "",
            subTitle: "",
            timeframeId: ""
        },
        description: "Custom Text Slide",
        timing: 30000,
        layout: {
            id: "Fullscreen",
            fullscreen: [{
                visualizationId: "FreeText",
                content: "<p class='ql-align-center'><strong class='ql-size-normal'>Enter your text here</strong></p>",
                backgroundUrl: undefined,
                backgroundColor: "#000",
                backgroundSize: "contain"
            }]
        },
        showNewsticker: true
    });
};
