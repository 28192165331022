import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Immutable from "immutable";
import * as Rata from "js/common/utils/remote-data";

const CustomTooltip = ({value, rowIndex}) => {
  const {theme} = React.useContext(CustomThemeContext);

  const isHeading = rowIndex === undefined;
  const isDataCell = Immutable.isImmutable(value);

  let valueToDisplay;
  if (!isHeading && isDataCell) {
    if (Rata.getValue(value)?.notFilterableByClient) {
      valueToDisplay = "Metric not filterable by client.";
    } else {
      // TODO JC - can this return an empty fragment instead?
      return <div />;
    }
  } else {
      valueToDisplay = value;
  }

  return (
      <div
          style={{
            borderRadius: 5,
            background: theme.palette.text.main,
            color: theme.palette.text.inverted,
            padding: "10px 10px",
            fontSize: 12,
            width: 140,
            textAlign: "center",
            marginLeft: -60
          }}>
        <div
            style={{
              display: "block",
              width: 0,
              height: 0,
              position: "absolute",
              borderLeft: "8px solid transparent",
              borderRight: "8px solid transparent",
              borderBottom: `8px solid ${theme.palette.text.main}`,
              top: -8,
              left: "calc(50% - 38px)"
            }} />
        {valueToDisplay}
      </div>
  );
};

export default CustomTooltip;