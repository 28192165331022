import React from "react";
import pure from "js/common/views/pure";
import { TextButton } from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const NoKpisSelectedWarning = pure(({ theme, onRequestClose }) => (
    <Dialog
        title="Metrics required"
        titleStyle={{ color: theme.palette.primary.main, fontSize: "1rem" }}
        autoDetectWindowHeight={true}
        actions={
            <TextButton type="success" label="OK" icon="check" onClick={onRequestClose} />}
        modal={false}
        open={true} >
        <i className="fa fa-info-circle" style={{ display: "table-cell", paddingRight: 8 }} />
        <span style={{ display: "table-cell", lineHeight: 1.5 }}>
            At least one Metric is required to plot a Trend Chart.<br />
            Use the dropdown in the <strong style={{ color: theme.palette.primary.main }}>Add Metrics</strong> section to choose some for your chart.
        </span>
    </Dialog>));


export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <NoKpisSelectedWarning theme={theme} {...props} />;
}
