import React from "react";
import Immutable from "immutable";

import * as DashboardUtils from "js/dashboards/utils";
import {columnSpacing, dashboardColumns} from "js/dashboards/utils";
import useMountEffect from "js/common/utils/use-mount-effect";

import DashboardDisplay from "js/dashboards/dashboard-display";
import {toCamel} from "js/common/utils/strings";
import useDimensions from "js/common/utils/use-dimensions";

const CellDialogContent = React.memo(({
  fileName,
  cellLoadError,
  rows,
  columns,
  rowLimitReached,
  parentGridConfig,
  parentConfig,
  componentTypeToTemplate,
  onRequestDialog,
  queueDataLoad
}) => {
  const entityToIds = {};
  const linkEntities = parentGridConfig.getIn(["cellDialog", "entitiesToFilterBy"], Immutable.List());
  const [ref, {width}] = useDimensions();
  for (const row of rows) {
    for (const linkEntity of linkEntities) {
      const rootEntity = row["__rootGroupingEntity__"].value;
      const isRootEntity = linkEntity === rootEntity;
      let cell;
      if (isRootEntity) {
        cell = row["__id__"];
      } else {
        cell = row["__" + toCamel(linkEntity) + "Id__"];
      }
      if (cell) {
        const id = cell.value;
        if (id) {
          if (!entityToIds[linkEntity]) {
            entityToIds[linkEntity] = [];
          }
          entityToIds[linkEntity].push(id);
        }
      }
    }
  }

  // NOTE If no row has a link id in it then the backend will receive either an empty list or no list for that entity.
  //  This will result in that kpi running without being filtered by the entity.
  //  Which will return data we don't want to see and way too much of it.
  //  We add a fake id value, this ensures the filter is applied and returns no rows for any relevant kpi.
  //  -1 matches no record we cannot use 0 because link ids are non-nullable therefore 0 indicates nothing.
  for (const linkEntity of linkEntities) {
    if (!entityToIds[linkEntity]) {
      entityToIds[linkEntity] = [-1];
    }
  }
  parentConfig = parentConfig.set("entityToIds", Immutable.fromJS(entityToIds));

  const uiGridTemplate = componentTypeToTemplate.get("UiGrid");
  const preloadedUiGridType = "PreloadedUiGrid";
  const hardcodedUiGridTemplate = uiGridTemplate
      .set("type", preloadedUiGridType)
      .set("load", () => {
        if (cellLoadError) {
          return Promise.reject(cellLoadError);
        } else {
          return Promise.resolve({rows, columns, rowLimitReached});
        }
      });
  componentTypeToTemplate = componentTypeToTemplate.set(preloadedUiGridType, hardcodedUiGridTemplate);

  const componentIdToLoadIdRef = React.useRef(Immutable.Map());

  const dataDetailYOffset = 0;
  const dataDetailHeight = 12;

  // TODO potentially allow overriding of this component via config
  //    either placeholder in clickThroughComponents or separate attribute
  const dataDetailGrid = Immutable.fromJS({
    id: -1,
    type: preloadedUiGridType,
    dataConfig: {
      isClickThrough: true,
      inheritKeys: ["qualifier", "timeframe", "tagFilter", "clientFilter"],
      gridProps: {
        pivotMode: false
      },
      fileName,
      groupDefaultExpanded: -1,
      showAllColumns: true
    },
    layout: {
      x: 0,
      y: dataDetailYOffset,
      width: 48,
      height: dataDetailHeight
    }
  });

  const otherComponents = parentGridConfig
      .getIn(["cellDialog", "components"], Immutable.List())
      .map((c, index) => c
          .set("id", index)
          .setIn(["dataConfig", "isClickThrough"], true)
          .setIn(["layout", "width"], 48)
          .setIn(["layout", "height"], dataDetailYOffset + dataDetailHeight));

  const components = Immutable.List.of(dataDetailGrid).concat(otherComponents);
  const layoutType = otherComponents.isEmpty() ? "window-only" : "infinite";
  const [dashboard, setDashboard] = React.useState(() => DashboardUtils.applyParentConfigToDashboard(
      Immutable.fromJS({json: {components, layoutType}}),
      parentConfig,
      componentTypeToTemplate));

  const [idToData, setIdToData] = React.useState(
      () => DashboardUtils.getInitialComponentIdToData(
          componentIdToLoadIdRef.current,
          dashboard,
          componentTypeToTemplate));

  useMountEffect(() => {
    DashboardUtils.loadDataForDashboard(
        componentIdToLoadIdRef,
        dashboard,
        queueDataLoad,
        setIdToData,
        componentTypeToTemplate);
  });
  return <div ref={ref} style={{display: "flex", width: "100%", height: "100%"}}>
    <DashboardDisplay
        componentIdToLoadId={componentIdToLoadIdRef.current}
        dashboard={dashboard}
        onDashboardChange={setDashboard}
        isEditing={false}
        componentTypeToTemplate={componentTypeToTemplate}
        queueDataLoad={queueDataLoad}
        onRequestDialog={onRequestDialog}
        rowHeight={(width / dashboardColumns) - columnSpacing}
        idToData={idToData} />
  </div>;
});

export default CellDialogContent;
