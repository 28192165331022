import eventBus from "js/cube19.event-bus";

const Backbone = window.Backbone;
const _ = window._;

const IpCollection = Backbone.Collection.extend({
  url: "clients/ipAddresses"
});

const ClientModel = Backbone.Model.extend({
  url: "clients/current",

  parse(response) {
    response.ipAddresses = new IpCollection(response.ipAddresses);
    response.appAssignments = new Backbone.Collection(response.appAssignments);
    return response;
  },

  toJSON() {
    return _.omit(this.attributes, ["ipAddresses", "appAssignments"]);
  },

  getClientName() {
    return this.get("name");
  },

  getLogoUrl() {
    return this.get("logoURL");
  },

  getTheme() {
    return this.get("theme");
  },

  hasPermission(permissionName) {
    permissionName = permissionName.toUpperCase();
    return this.get("permissions").some(permission => permission.name.toUpperCase() === permissionName);
  },

  canAccessApp(appName) {
    appName = appName.toUpperCase();
    return this.get("appAssignments").some(appAssignment => appAssignment.get("app").toUpperCase() === appName);
  },

  getType() {
    return this.get("type");
  },

  getDecimalPlaces(kpiType) {
    switch (kpiType) {
      case "NUMBER":
        return this.get("numericDecimalPlaces");
      case "CURRENCY":
        return this.get("monetaryDecimalPlaces");
      case "PERCENT":
        return this.get("percentageDecimalPlaces");
      default:
        return null;
    }
  },

  isUserAssignedCtTabs() {
    return this.get("userAssignedCtTabs");
  },

  areExplanationsEnabled() {
    return this.get("explanationsEnabled");
  },

  isGainsightEnabled() {
    return this.get("gainsightEnabled");
  },

  isBullhornCrm() {
    return this.get("crmId") === 32;
  },

  getImplementationStatus() {
    return this.get("reconfigurationTool");
  }
});

let client = new ClientModel();
const load = () => client
    .fetch()
    .then(() => {
      window.expectedClientId = client.get("id");
      eventBus.trigger("data-loaded", "current-client");

      const localTheme = localStorage.getItem("appTheme");
      const clientTheme = client.getTheme();

      if (!localTheme && clientTheme) {
        localStorage.setItem("appTheme", clientTheme.toLowerCase());
      }
    }, error => {
      eventBus.trigger("error:fatal", error);
    });

const get = () => client;

export {
  load,
  get
};

export default client;
