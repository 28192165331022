import React from "react";
import ReactDOM from "react-dom";
import Immutable from "immutable";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import {getDefaultRendererOptions, getDefaultRenderers, getSortersForSliceAndDice} from "js/common/pivot-utils";

const $ = window.$;
const _ = window._;

const rowTotals = ".pvtRowTotalLabel, .rowTotal";
const colTotals = ".pvtColTotalLabel, .colTotal";
const grandTotal = ".pvtGrandTotal";
const rowOrColumnTotals = `${rowTotals}, ${colTotals}, ${grandTotal}`;

const ReportingPivotTable = createReactClass({

  mixins: [PureRenderMixin],
  displayName: "ReportingPivotTable",

  componentDidMount() {
    if (this.props.data) {
      this.renderPivotUI();
    } else {
      $(ReactDOM.findDOMNode(this)).empty();
    }
    this.handleTableRefresh();
  },

  componentDidUpdate(prevProps) {
    const isDataChanged = this.props.data !== prevProps.data;
    if (isDataChanged && !!this.props.data) {
      this.renderPivotUI();
    } else if (isDataChanged && !this.props.data) {
      $(ReactDOM.findDOMNode(this)).empty();
    }
    this.handleTableRefresh();
  },

  hideTotalsColumnAndRow() {
    $(ReactDOM.findDOMNode(this))
        .find(rowOrColumnTotals)
        .hide();
  },

  showTotalsColumnAndRow() {
    $(ReactDOM.findDOMNode(this))
        .find(rowOrColumnTotals)
        .show();
  },

  render() {
    return <div id="pivot-table" style={{overflow: "auto"}} />;
  },

  renderPivotUI() {
    const $this = $(ReactDOM.findDOMNode(this));
    $this.pivotUI(this.props.data, this.getPivotUIOptions());

    $this.find("td.pvtCols").prepend("<h4 class=\"area-label\">Columns</h4>");
    $this.find("td.pvtRows").prepend("<h4 class=\"area-label\">Rows</h4>");
  },

  getPivotUIOptions() {
    const {config, theme} = this.props;
    const cellValues = config.get("cellValue") ? [config.get("cellValue")] : [];
    // These need to true for the first render so that they are available in the DOM for toggling
    // This is fine because we later read the config and toggle them off if required
    const showTotals = Immutable.fromJS({
      rowTotals: true,
      colTotals: true,
      grandTotal: true
    });
    const rendererOptions = getDefaultRendererOptions(theme).set("table", showTotals);
    return {
      renderers: this.getRenderers(),
      rendererOptions: rendererOptions.toJS(),
      rows: config.get("rows").toJS(),
      cols: config.get("columns").toJS(),
      vals: cellValues,
      aggregatorName: config.get("aggregator"),
      rendererName: config.get("renderer"),
      hiddenAttributes: ["rowType", "__tagCache__"],
      sorters: getSortersForSliceAndDice,
      derivedAttributes: this.props.derivedPivotTableAttributes,
      autoSortUnusedAttrs: true,
      unusedAttrsVertical: false,
      menuLimit: 9999,
      onRefresh: pivotConfig => {
        const newPivotConfig = Immutable
            .fromJS(pivotConfig)
            .delete("sorters")          // delete some values which are functions
            .delete("aggregators")
            .delete("renderers")
            .delete("rendererOptions")  // delete some bulky default values
            .delete("localeStrings")
            .delete("colOrder")
            .delete("rowOrder")
            .delete("hiddenAttributes")
            .delete("hiddenFromAggregators")
            .delete("hiddenFromDragDrop");
        this.props.onPivotTableRefresh(newPivotConfig);
      }
    };
  },

  getRenderers() {
    const renderers = getDefaultRenderers();
    const pivotTableExtension = new window.NRecoPivotTableExtensions({
      drillDownHandler: _.bind(dataFilter => this.props.onCellClick(Immutable.fromJS(dataFilter)), this),
      onRefresh: _.bind(this.handleTableRefresh, this)
    });
    renderers["Table"] = pivotTableExtension.wrapTableRenderer(renderers["Table"]);
    renderers["Table Barchart"] = pivotTableExtension.wrapTableRenderer(renderers["Table Barchart"]);
    renderers["Heatmap"] = pivotTableExtension.wrapTableRenderer(renderers["Heatmap"]);
    renderers["Row Heatmap"] = pivotTableExtension.wrapTableRenderer(renderers["Row Heatmap"]);
    renderers["Col Heatmap"] = pivotTableExtension.wrapTableRenderer(renderers["Col Heatmap"]);
    return renderers;
  },

  handleTableRefresh() {
    const isTotalsColOrRowHidden = this.props.config.get("total").every(showColOrRow => !showColOrRow);
    if (isTotalsColOrRowHidden) {
      this.hideTotalsColumnAndRow();
    } else {
      this.showTotalsColumnAndRow();
    }
  }

});


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ReportingPivotTable theme={theme} {...props} />;
};

export default Wrapper;