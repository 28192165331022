import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import moment from "moment";
import store from "store";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import {TextButton} from "js/common/views/inputs/buttons";
import ErrorMsg from "js/common/views/error";
import TargetsList from "js/oneview/targets/targets-list";
import TargetCreator from "js/oneview/targets/add-new-target";
import eventBus from "js/cube19.event-bus";
import * as Ajax from "js/common/ajax";
import * as Users from "js/common/users";

const path = window.path;

export default createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    kpiId: ReactPropTypes.number.isRequired,
    timeframe: ReactPropTypes.object.isRequired,
    qualifierType: ReactPropTypes.string.isRequired,
    qualifierId: ReactPropTypes.number.isRequired,
    initialTargets: ImmutablePropTypes.list.isRequired,
    reloadTargets: ReactPropTypes.func,
    onNewTargetNoteAdded: ReactPropTypes.func
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.initialTargets !== this.props.initialTargets) {
      this.setState({
        targets: nextProps.initialTargets
      });
    }
  },

  getInitialState() {
    return {
      targetsViewType: "SIMPLE",
      isTargetCreatorOpen: false,
      selectedTargetId: null,
      targets: this.props.initialTargets,
      errorsByTargetId: Immutable.Map(),
      isUpdatingTargets: false,
      isUpdatingNotes: false,
      updateError: null
    };
  },

  componentDidMount() {
    eventBus.on("targets-tab:save-changes", this.saveChanges, this);
  },

  render() {
    const {isTargetCreatorOpen, updateError} = this.state;
    const {kpiId, qualifierType, qualifierId} = this.props;
    return (
        <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
          {!isTargetCreatorOpen && this.renderButtons()}
          {updateError && <ErrorMsg text={updateError} />}
          {isTargetCreatorOpen &&
              <TargetCreator
                  kpiId={kpiId}
                  qualifierType={qualifierType}
                  qualifierId={qualifierId}
                  onNewTargetCreated={this.onNewTargetCreated}
                  onCloseRequest={this.closeTargetCreator} />}
          {this.renderTargets()}
        </div>);
  },

  renderButtons() {
    const {targetsViewType, isTargetCreatorOpen, targets, isUpdating} = this.state;
    const {initialTargets} = this.props;
    const spacing = {
      marginLeft: "0.5rem",
      marginRight: "0.5rem"
    };
    const buttonStyle = {marginBottom: 0, ...spacing};
    const hasChanges = targets === initialTargets;
    const currentUser = Users.getCurrentUser();
    const showTargetAdminButtons = Users.canAccessApp(currentUser, "TARGET_ADMIN")
        && targetsViewType === "SIMPLE" && !isTargetCreatorOpen;
    return (
        <div style={{margin: "1rem"}}>
          {(!targets.isEmpty() && showTargetAdminButtons) &&
              <TextButton
                  type="secondary"
                  icon="pencil"
                  label="Edit Targets"
                  onClick={this.switchToEditView}
                  style={buttonStyle} />}
          {targetsViewType === "EDIT" &&
              <span>
              <TextButton
                  type="secondary"
                  icon="history"
                  label="Cancel Changes"
                  onClick={this.cancelChanges}
                  style={buttonStyle} />
              <TextButton
                  type="primary"
                  icon={isUpdating ? "spinner fa-spin" : "floppy-o"}
                  label="Save Changes"
                  onClick={this.saveChanges}
                  disabled={hasChanges}
                  style={buttonStyle} />
            </span>}
          {showTargetAdminButtons &&
              <TextButton
                  type="primary"
                  icon="plus"
                  label="Add New Target"
                  onClick={this.openTargetCreator}
                  style={buttonStyle} />}
        </div>);
  },

  renderTargets() {
    const {targets, targetsViewType, selectedTargetId, errorsByTargetId} = this.state;
    const {timeframe, isAddingNewTargetNote, addTargetNoteError} = this.props;
    return (
        <TargetsList
            viewType={targetsViewType}
            timeframe={timeframe}
            selectedTargetId={selectedTargetId}
            targets={targets.filter(t => !t.get("deleted"))}
            onTargetClick={this.onTargetClick}
            onCloseNotesRequest={this.onCloseNotesRequest}
            onAddTargetNoteRequest={this.onAddTargetNoteRequest}
            onTargetChange={this.handleTargetChange}
            errorsByTargetId={errorsByTargetId}
            isUpdatingNotes={isAddingNewTargetNote}
            addTargetNoteError={addTargetNoteError} />);
  },

  switchToEditView() {
    this.setState({
      targetsViewType: "EDIT"
    });
  },

  onTargetClick(targetId) {
    this.setState({
      selectedTargetId: targetId,
      addTargetNoteError: null
    });
  },

  onCloseNotesRequest() {
    this.setState({
      selectedTargetId: null,
      addTargetNoteError: null
    });
  },

  onNewTargetCreated() {
    this.closeTargetCreator();
    this.props.reloadTargets();
  },

  openTargetCreator() {
    this.setState({
      isTargetCreatorOpen: true
    });
  },

  closeTargetCreator() {
    this.setState({
      isTargetCreatorOpen: false
    });
  },

  cancelChanges() {
    if (store.enabled && store.get("targetsTab.hasUnsavedChanges")) {
      store.remove("targetsTab.hasUnsavedChanges");
    }
    this.setState(this.getInitialState());
  },

  handleTargetChange(target) {
    if (store.enabled) {
      store.set("targetsTab.hasUnsavedChanges", true);
    }
    const {targets} = this.state;
    const index = targets.findIndex(t => t.get("id") === target.get("id"));
    this.setState({
      targets: targets.set(index, target)
    });
  },

  saveChanges() {
    const {kpiId, reloadTargets} = this.props;
    const {targets} = this.state;
    const someTargetChangesAreInvalid = targets.some(t => {
      const targetValue = t.get("value").get("value");
      const targetStartDate = t.get("targetStart");
      const targetEndDate = t.get("targetEnd");
      return isNaN(targetValue) || targetValue < 0 || !targetStartDate.isValid() || !targetEndDate.isValid();
    });
    if (someTargetChangesAreInvalid) {
      return;
    }

    this.setState({
      isUpdating: true,
      errorsByTargetId: this.getInitialState().errorsByTargetId
    }, () => {
      updateTargets(kpiId, targets)
          .then(() => {
            if (store.enabled && store.get("targetsTab.hasUnsavedChanges")) {
              store.remove("targetsTab.hasUnsavedChanges");
            }
            reloadTargets();
            eventBus.trigger("kpi:targets-changed", kpiId);
          })
          .catch(error => {
            if (error.responseJSON && error.responseJSON.type === "INVALID_TARGETS") {
              const errorsByTargetId = Immutable.fromJS(error.responseJSON.data)
                  .mapKeys(targetId => parseInt(targetId, 10));
              this.setState({
                isUpdating: false,
                updateError: null,
                errorsByTargetId
              });
            } else {
              this.setState({
                isUpdating: false,
                updateError: "Unable to save changes"
              });
            }
          });
    });
  },

  onAddTargetNoteRequest(targetId, note) {
    const {targets, targetsViewType} = this.state;
    const target = targets.get(targets.findIndex(t => t.get("id") === targetId));
    const targetNotes = target.get("noteAssignmentDtos");
    if (targetsViewType === "EDIT") {
      const newNote = getDefaultNote(note);
      this.handleTargetChange(target.set("noteAssignmentDtos", targetNotes.push(newNote)));
    } else {
      this.setState({
        isAddingNewTargetNote: true,
        addNewTargetNoteError: null
      });
      addTargetNote(targetId, note)
          .then(result => {
            this.setState({
              isAddingNewTargetNote: false
            });
            const newNote = Immutable.fromJS(result);
            this.props.onNewTargetNoteAdded(target.set("noteAssignmentDtos", targetNotes.push(newNote)));
          })
          .catch(e => {
            this.setState({
              isAddingNewTargetNote: false,
              addNewTargetNoteError: "Unable to add note"
            });
          });
    }
  }

});

const formatDate = (date, format = "YYYY-MM-DD") => date.format(format);
const updateTargets = (kpiId, targets) => {
  const url = path("kpi", kpiId, "targets?source=ClickThrough");
  const json = targets.map(target => {
    const valueWrapper = target.get("value");
    return target
        .set("targetStart", formatDate(target.get("targetStart")))
        .set("targetEnd", formatDate(target.get("targetEnd")))
        .set("value", valueWrapper.set("value", valueWrapper.get("value") + ""))
        .toJS();
  });
  return Ajax.put({url, json});
};

const addTargetNote = (targetId, note) => Ajax.post({
  url: path("kpi/target", targetId, "note?source=ClickThrough"),
  json: {note}
});

const getDefaultNote = note => Immutable.fromJS({
  automatedNote: false,
  createdDate: moment().format("YYYY-MM-DD HH:mm:ss"),
  userId: Users.getCurrentUser().get("id"),
  note
});
