import Immutable from "immutable";

import Cube19 from "js/cube19.app";
import * as Ajax from "js/common/ajax";
import * as Groups from "js/common/groups";
import {maybeBackboneToImmutable} from "js/common/utils/backbone-migration";

const path = window.path;

const isFullUser = u => !!u.get("groupId") && (u.get("state") === "VISIBLE" && u.get("hasLogin"));
const isObserver = u => !!u.get("groupId") && (u.get("state") === "INVISIBLE" && u.get("hasLogin"));
const isActive = u => !!u.get("groupId") && (u.get("state") === "VISIBLE" || u.get("hasLogin"));
const isOrphan = u => !u.get("groupId") && (u.get("state") === "VISIBLE" || u.get("hasLogin"));
const isInactive = u => u.get("state") === "INVISIBLE" && !u.get("hasLogin");
const isNew = u => u.get("state") === "NEW";
const isCube19User = u => u.get("cube19User");
const isCrmUser = u => u.get("originalCrmUserId") > 0 && !u.get("originalCrmUserId").includes("909090");

const getUsers = () => Cube19.request("users");
const getUser = userId => Cube19.request("user", userId);
const getCurrentUser = () => Cube19.request("user:current");
const getSourceUser = () => Cube19.request("user:source");
const getNextUser = user => Cube19.request("user:next", user);
const getPreviousUser = user => Cube19.request("user:previous", user);
const getActiveUsers = () => Cube19.request("users:active");
const getUserOrCurrent = userId => userId === 0 ? getCurrentUser() : getUser(userId);

const getType = user => {
  if (isFullUser(user)) {
    return "Full User";
  } else if (isObserver(user)) {
    return "Observer";
  } else if (isInactive(user)) {
    return "Inactive";
  } else if (isNew(user)) {
    return "New";
  } else {
    return "Visible but no Login";
  }
};

const canAccessApp = (user, app) => {
  return (maybeBackboneToImmutable(user.get("appAssignments")) ?? Immutable.List())
      .map(appAssignment => appAssignment.get("app"))
      .toSet()
      .has(app.toUpperCase());
};

const hasPermission = (user, permission) => {
  return (maybeBackboneToImmutable(user.get("permissions")) ?? Immutable.List())
      .map(p => p.get("name"))
      .toSet()
      .has(permission.toUpperCase());
};
const currentHasPermission = permission => hasPermission(getCurrentUser(), permission);

const load = () => Ajax
    .get({url: "users"})
    .then(x => Immutable.fromJS(x));

const setupAs = (userId, type) => Ajax
    .put({url: path("users", userId, "setup", type.toUpperCase())})
    .then(x => Immutable.fromJS(x));

const fixOrphan = userId => Ajax
    .put({url: path("users", userId, "fix-orphan")})
    .then(x => Immutable.fromJS(x));

const deactivateOrphan = userId => Ajax
    .put({url: path("users", userId, "deactivate-orphan")})
    .then(x => Immutable.fromJS(x));

const update = user => Ajax
    .put({
      url: path("users", user.get("id")),
      json: user.toJS()
    })
    .then(x => Immutable.fromJS(x));


const updateEmail = (user) => Ajax
    .put({
      url: path("users", user.get("id"), "email"),
      data: user.get("email")
    })
    .then(x => Immutable.fromJS(x));

const updateSettings = settings => Ajax
    .put({
      url: "users/settings",
      json: settings.toJS()
    })
    .then(x => Immutable.fromJS(x));

const getInherited = (user, getter) => {
  const userValue = getter(user);
  if (userValue) {
    return userValue;
  } else {
    const group = Groups.getGroup(user.get("groupId"));
    return getter(group);
  }
};

const fetchFallbackUsers = () => {
  return Ajax
      .get({
        url: "users/fallback",
        fatalError: true
      })
      .then(x => Immutable
          .fromJS(x)
          .map(user => user.set("isFallback", true)));
};

export {
  load,
  setupAs,
  update,
  updateEmail,
  updateSettings,
  fixOrphan,
  deactivateOrphan,

  getType,

  getUsers,
  getUser,
  getCurrentUser,
  getSourceUser,
  getNextUser,
  getPreviousUser,
  getActiveUsers,
  getUserOrCurrent,
  fetchFallbackUsers,

  canAccessApp,
  hasPermission,
  currentHasPermission,

  isObserver,
  isFullUser,
  isActive,
  isOrphan,
  isInactive,
  isNew,
  isCube19User,
  isCrmUser,

  getInherited
};
