import React from "react";
import Immutable from "immutable";
import Select from "js/common/views/inputs/immutable-react-select";
import pure from "js/common/views/pure";

import * as currencyRepo from "js/common/repo/backbone/currency-repo";

export default pure(({value, onChange}) => {
    return (
        <div style={{ maxWidth: 110 }}>
            <Select
                placeholder="Choose a currency"
                options={getCurrencyOptions()}
                isClearable={false}
                isMulti={false}
                isSearchable={true}
                selectedValue={value}
                onChange={onChange} />
        </div>
    );
});

const getCurrencyOptions = () => Immutable
    .fromJS(currencyRepo.getAll().toJSON())
    .map(currency => (Immutable.Map({
        label: currency.get("code"),
        value: currency.get("code")
    })));
