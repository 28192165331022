import React from "react";
import createReactClass from "create-react-class";
import ReactHighcharts from "react-highcharts";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import { formatBigNumber } from "js/common/utils/numbers";
import * as Formatter from "js/common/utils/formatter";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

export default pure(({
    trendConfig,
    trendData,
    kpiSummariesConfig,
    kpiSummariesDataByIndex,
    containerHeight
}) => {
    const containerStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        ...containerPadding
    };
    const heightOffset = 50;
    const height = containerHeight - heightOffset;
    return (
        <div style={{...containerStyle, height: containerHeight}}>
            <div style={{width: "70%", ...containerPadding}}>
                <TrendChart config={trendConfig} data={trendData} height={height} />
            </div>
            <div style={{width: "30%", ...containerPadding}}>
                <KpiSummaries
                    configs={kpiSummariesConfig.kpis}
                    dataByIndex={kpiSummariesDataByIndex}
                    height={height} />
            </div>
        </div>
    );
});

const TrendChart = createReactClass({

    mixins: [PureRenderMixin],

    render() {
        const { config, data, height } = this.props;
        const trendsData = data.get("trends");
        if (!trendsData || !trendsData.length) {
            return;
        }

        const paddingTop = 0.02 * height;
        const borderWidth = 3;
        const chartContainerStyle = {
            background: "linear-gradient(to bottom, #0c2694 0%, #060051 34%, #060051 77%, #09012f 100%)",
            borderTop: `${borderWidth}px solid #f9ec33`,
            borderBottom: `${borderWidth}px solid #f9ec33`,
            borderLeft: "1px solid rgba(255, 255, 255, 0)",
            borderRight: "1px solid rgba(255, 255, 255, 0)",
            paddingTop,
            paddingLeft: 20,
            paddingRight: 20
        };
        const axisLabelStyle = {
            fontSize: "1.5vw",
            fontWeight: "bold",
            color: "#EEEEEE"
        };
        const yAxisValuePrefix = data.get("yAxisValuePrefix");
        const chartConfig = {
            chart: {
                type: "areaspline",
                backgroundColor: null,
                height: height - (paddingTop * 2) - (borderWidth * 2),
                marginTop: 20,
                animation: false
            },
            title: {
                text: ""
            },
            credits: {
                enabled: false
            },
            exporting: {
                enabled: false
            },
            legend: {
                floating: true,
                align: "right",
                verticalAlign: "top",
                x: -30,
                y: 0,
                itemStyle: {
                    fontSize: "1.5rem",
                    color: "#E0E0E3",
                    cursor: "default"
                },
                itemHoverStyle: {
                    color: "#E0E0E3",
                }
            },
            tooltip: {
                enabled: false
            },
            xAxis: {
                allowDecimals: false,
                lineColor: "#9a9a9a",
                tickLength: 0,
                lineWidth: 2,
                align: "left",
                units: "day",
                labels: {
                    style: axisLabelStyle,
                    y: 30,
                    formatter: function() {
                        return this.value;
                    }
                },
                title: {
                    text: config.xLabel || "Days From Start",
                    margin: trendsData.every(t => t.data.length > 0) ? null : 30,
                    style: axisLabelStyle
                }
            },
            yAxis: {
                allowDecimals: false,
                gridLineColor: "#707073",
                gridZIndex: 0,
                tickLength: 0,
                tickInterval: Math.round(data.get("maxValue") / 2),
                endOnTick: false,
                labels: {
                    style: {
                        fontSize: "1.5vw",
                        fontWeight: "bold",
                        color: "#EEEEEE",
                        textOverflow: "none"
                    },
                    align: "right",
                    x: 0,
                    formatter: function() {
                        return yAxisValuePrefix + formatBigNumber(this.value);
                    }
                },
                title: {
                    text: config.yLabel || "",
                    style: axisLabelStyle
                }
            },
            plotOptions: {
                series: {
                    pointStart: 0,
                    marker: {
                        enabled: false
                    },
                    enableMouseTracking: false,
                    lineWidth: 5,
                    animation: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                    events: {
                        legendItemClick: function() {
                            return false;
                        }
                    }
                },
                allowPointSelect: false
            },
            series: trendsData
        };
        return (
            <div style={chartContainerStyle}>
                <ReactHighcharts config={chartConfig} />
            </div>
        );
    }

});

const KpiSummaries = React.memo(({ configs, dataByIndex, height }) => {
    const { theme } = React.useContext(CustomThemeContext);
    return (
        <div>
            {configs.map((config, index) => {
                const { expectedData } = config;
                const kpiSummary = dataByIndex.get(index);
                const kpiSummaryContainerStyle = {
                    background: "linear-gradient(to bottom, #0c2694 0%, #060051 34%, #060051 77%, #09012f 100%)",
                    borderTop: "3px solid #f9ec33",
                    borderBottom: "3px solid #f9ec33",
                    borderLeft: "1px solid rgba(255, 255, 255, 0)",
                    borderRight: "1px solid rgba(255, 255, 255, 0)",
                    height: "100%",
                    overflow: "hidden",
                    fontFamily: theme.typography.fontFamilyBold,
                    textAlign: "center"
                };
                const marginBottom = 0.02 * height;
                return (
                    <div key={index} style={{ height: (height / 4) - marginBottom, marginBottom }}>
                        <div style={kpiSummaryContainerStyle}>
                            <div style={kpiSummaryTitleStyle}>
                                {kpiSummary.get("title")}
                            </div>
                            <div style={{ display: "table", height: "75%", width: "100%", overflow: "hidden" }}>
                                <div style={kpiSummaryValueStyle}>
                                    {getKpiDisplayValue(kpiSummary, expectedData)}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
});

const containerPadding = {
    paddingRight: "1rem",
    paddingLeft: "1rem"
};

const kpiSummaryTitleStyle = {
    background: "#900000",
    borderBottom: "1px solid #fff",
    color: "#f9ec33",
    fontFamily: "inherit",
    fontSize: "1.5vw",
    textTransform:" uppercase",
    padding: "0.5rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
};

const kpiSummaryValueStyle = {
    fontFamily: "inherit",
    fontSize: "3.8vw",
    color: "#fff",
    display: "table-cell",
    verticalAlign: "middle",
    textAlign: "center",
    padding: "1rem"
};

const getKpiDisplayValue = (kpiSummary, expectedData) => {
    const kpiData = kpiSummary.get("kpiData");
    if (kpiData.get("type") === "error") {
        return kpiData.get("value");
    } else {
        const kpi = kpiRepo.get(kpiSummary.get("kpiId"));
        const valueFormat = kpi.get("type").valueFormat;
        const kpiValueData = kpiData.get(expectedData);
        const maxValueLabelLength = 15;
        return Formatter.format(kpiValueData.toJS(), {valueFormat, maxDisplayLength: maxValueLabelLength});
    }
};
