import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ReactPropTypes from "prop-types";
import Pluralize from "pluralize";
import ImmutablePropTypes from "react-immutable-proptypes";
import moment from "moment";
import * as Immutable from "immutable";

import Dialog from "js/common/views/tabs-dialog";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import GroupAndUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import LoadingSpinner from "js/common/views/loading-spinner";
import GroupAndUserList from "js/common/views/group-and-user-list";
import {TextButton} from "js/common/views/inputs/buttons";
import {greyDark} from "js/common/cube19-colors";
import pure from "js/common/views/pure";
import * as Ajax from "js/common/ajax";
import * as Popups from "js/common/popups";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const DeletionDialog = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    group: ImmutablePropTypes.map.isRequired,
    onCancelClick: ReactPropTypes.func.isRequired,
    onDeleteGroup: ReactPropTypes.func.isRequired,
    theme: ReactPropTypes.object
  },

  getInitialState() {
    return {
      groupEndDate: moment().subtract(1, "days"),
      newGroupId: this.props.group.get("parentId"),
      isUpdatingSummary: false,
      summaryData: Immutable.Map()
    };
  },

  componentDidMount() {
    this.updateSummary();
  },

  render() {
    const {newGroupId, groupEndDate, summaryData} = this.state;
    const {group, onCancelClick, onDeleteGroup, theme} = this.props;
    return (
        <Dialog
            label={<span>Delete Group</span>}
            content={
              <DeleteWizard
                  theme={theme}
                  deletingGroupId={group.get("id")}
                  newGroupId={newGroupId}
                  groupEndDate={groupEndDate}
                  summaryData={summaryData}
                  onNewGroupIdChange={this.onNewGroupIdChange}
                  onGroupEndDateChange={this.onGroupEndDateChange}
                  onCancelClick={onCancelClick}
                  onDeleteGroup={onDeleteGroup} />}
            flex={1}
            onRequestClose={onCancelClick} />
    );
  },

  updateSummary() {
    const {group} = this.props;
    const {groupEndDate, newGroupId} = this.state;

    this.setState({summaryData: Immutable.Map()});

    if (groupEndDate.isValid()) {
      // TODO can stop sending newGroupId once backend is updated+released that doesn't require it
      const options = {
        url: window.path("group", group.get("id"), "deletion-summary"),
        data: {groupEndDate: groupEndDate.format("YYYY-MM-DD"), newGroupId}
      };
      Ajax.get(options)
          .then(summaryData => Immutable.fromJS(summaryData))
          .then(summaryData => this.setState({summaryData}));
    }
  },

  onNewGroupIdChange(groupId) {
    this.setState({newGroupId: groupId});
  },

  onGroupEndDateChange(date) {
    this.setState({groupEndDate: date}, this.updateSummary);
  }

});

const DeleteWizard = pure(({
  deletingGroupId,
  newGroupId,
  groupEndDate,
  summaryData,
  onNewGroupIdChange,
  onGroupEndDateChange,
  onCancelClick,
  onDeleteGroup,
  theme
}) => {
  const wizardStyle = {
    display: "flex",
    flexDirection: "column",
    flex: 1
  };
  return (
      <div style={wizardStyle}>
        <DeleteOptions
            deletingGroupId={deletingGroupId}
            newGroupId={newGroupId}
            groupEndDate={groupEndDate}
            onNewGroupIdChange={onNewGroupIdChange}
            onGroupEndDateChange={onGroupEndDateChange} />
        <DeleteSummary
            theme={theme}
            deletingGroupId={deletingGroupId}
            newGroupId={newGroupId}
            groupEndDate={groupEndDate}
            summaryData={summaryData} />
        <DeleteButtons
            deletingGroupId={deletingGroupId}
            newGroupId={newGroupId}
            groupEndDate={groupEndDate}
            onCancelClick={onCancelClick}
            onDeleteGroup={onDeleteGroup} />
      </div>
  );
});

const DeleteOptions = pure(({
  deletingGroupId,
  newGroupId,
  groupEndDate,
  onNewGroupIdChange,
  onGroupEndDateChange
}) => {
  return (
      <div style={{...wizardSectionStyle, minHeight: "200px", flexDirection: "row", display: "flex", overflowY:"visible"}}>
        <div style={{marginRight: "2rem", minWidth: "12rem"}}>
          <label style={{
            fontSize: "0.7rem",
            pointerEvents: "none",
            userSelect: "none",
            color: "rgb(117, 117, 117)"
          }}>
            Group membership end date
          </label>
          <DatePicker
              value={groupEndDate}
              maxDate={moment().subtract(1, "days")}
              onDateChange={onGroupEndDateChange} />
        </div>

        <div style={{marginRight: "2rem", minWidth: "25rem"}}>
          <label style={{
            fontSize: "0.7rem",
            pointerEvents: "none",
            userSelect: "none",
            color: "rgb(117, 117, 117)"
          }}>
            New group
          </label>
          <GroupAndUserPicker
              qualifierId={newGroupId}
              qualifierType={"GROUP"}
              onGroupClick={onNewGroupIdChange}
              excludedGroupIds={Immutable.Set([deletingGroupId])}
              excludeUsers={true} />
        </div>

        <p>{deletionDescription}</p>
      </div>
  );
});

const DeleteSummary = pure(({
  deletingGroupId,
  newGroupId,
  groupEndDate,
  summaryData,
  theme
}) => {
  const affectedEntitiesStyle = {
    display: "flex",
    overflow: "hidden",
    justifyContent: "space-between"
  };
  const affectedEntityStyle = theme =>({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginRight: "2rem",
    padding: "20px",
    backgroundColor: theme.themeId === "light" ? "#f3f3f3" : greyDark,
    borderRadius: 5
  });

  if (summaryData.size === 0) {
    return (<LoadingSpinner />);
  } else {
    const alteredTargets = summaryData.get("targetsToAlter");
    const deletedTargets = summaryData.get("targetsToDelete");
    const newGroupStartDateString = groupEndDate.clone().add(1, "days").format("L");
    const usersAffectedInPresent = summaryData
        .get("groupAssignmentsToAlter")
        .toSet()
        .map(assignment => assignment.get("userId"))
        .map(userId => Users.getUser(userId));
    const usersAffectedInFuture = summaryData
        .get("groupAssignmentsToReplace")
        .toSet()
        .map(assignment => assignment.get("userId"))
        .map(userId => Users.getUser(userId))
        .subtract(usersAffectedInPresent);
    const usersAffected = usersAffectedInPresent.concat(usersAffectedInFuture);

    return (
        <div style={{...wizardSectionStyle, minHeight: "250px", display: "block"}} className="scroll">
          <h2>Changes:</h2>
          {usersAffected.isEmpty() && alteredTargets.isEmpty() && deletedTargets.isEmpty() ?
              <p>None</p> :

              <ul style={{marginLeft: "1rem"}}>
                {!usersAffectedInPresent.isEmpty() &&
                <li>
                  {Pluralize("user", usersAffectedInPresent.size, true)} will be moved
                  from '{Groups.getGroupBreadcrumbsStr(deletingGroupId, "▶")}'
                  to '{Groups.getGroupBreadcrumbsStr(newGroupId, "▶")}'
                  on {newGroupStartDateString}.
                </li>}

                {!usersAffectedInFuture.isEmpty() &&
                <li>
                  {Pluralize("user", usersAffectedInFuture.size, true)} will have their future
                  assignments
                  to '{Groups.getGroupBreadcrumbsStr(deletingGroupId, "▶")}'
                  replaced with '{Groups.getGroupBreadcrumbsStr(newGroupId, "▶")}'.
                </li>}

                {!alteredTargets.isEmpty() &&
                <li>
                  {Pluralize("target", alteredTargets.size, true)} for this Group will be given an end
                  date
                  of {groupEndDate.format("L")}.
                </li>}

                {!deletedTargets.isEmpty() &&
                <li>
                  {Pluralize("target", deletedTargets.size, true)} for this Group will be deleted.
                </li>}
              </ul>}

          <div style={affectedEntitiesStyle}>
            {usersAffected.size > 0 &&
            <div style={affectedEntityStyle(theme)}>
              <h3 className="affected">Affected Users ({usersAffected.size}):</h3>
              <div style={listParentStyle}>
                <GroupAndUserList users={usersAffected} />
              </div>
            </div>}

            {alteredTargets.size > 0 &&
            <div style={affectedEntityStyle(theme)}>
              <h3 className="affected">Affected Targets ({alteredTargets.size}):</h3>
              <TargetList targets={alteredTargets} />
            </div>}

            {deletedTargets.size > 0 &&
            <div style={affectedEntityStyle(theme)}>
              <h3 className="affected">Deleted Targets:</h3>
              <TargetList targets={deletedTargets} />
            </div>}
          </div>
        </div>
    );
  }
});

const TargetList = pure(({
  targets
}) => {
  const listItems = targets.map(target => {
    const targetStart = moment(target.get("targetStart"));
    const targetEnd = moment(target.get("targetEnd"));
    const kpiId = target.get("kpiId");
    const kpi = KpiRepo.get(kpiId);
    return (
        <li key={kpiId} style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
          {kpi.get("name")} ({targetStart.format("L")} - {targetEnd.format("L")})
        </li>);
  });
  return (
      <div style={listParentStyle}>
        <ul style={listStyle}>
          {listItems}
        </ul>
      </div>
  );
});

const DeleteButtons = pure(({deletingGroupId, newGroupId, groupEndDate, onDeleteGroup, onCancelClick}) => {
  return (
      <div style={{margin: "1.5rem", display: "flex", justifyContent: "flex-end"}}>
        <TextButton
            label="Cancel"
            onClick={onCancelClick}
            style={{marginRight: "1rem"}} />
        <TextButton
            label="Delete Group"
            disabled={!groupEndDate.isValid()}
            type={"alert"}
            onClick={() => handleDeleteGroupClick(deletingGroupId, newGroupId, groupEndDate, onDeleteGroup)} />
      </div>
  );
});

const handleDeleteGroupClick = (deletingGroupId, newGroupId, groupEndDate, onDeleteGroup) => {
  Popups.confirm("Are you sure you want to delete this Group?", {
    title: "Delete this Group?",
    labels: {
      cancel: "No",
      ok: "Yes"
    },
    onok: () => onDeleteGroup(deletingGroupId, newGroupId, groupEndDate)
  });
};

const deletionDescription = "When you delete a Group, we will move any Users and sub-Groups that are members of that Group " +
    "to the Group you specify from the day after the Group is deleted. Any targets for this Group will also be ended. " +
    "You can see below the exact effects of deleting this Group before confirming the deletion.";

const wizardSectionStyle = {
  borderStyle: "solid",
  borderSize: 1,
  borderRadius: 5,
  borderColor: "rgb(204, 204, 204)",
  margin: "1.5rem",
  padding: "1.5rem",
  display: "flex",
  overflowY: "scroll",
};

const listParentStyle = {listStyle: "none"};

const listStyle = {marginRight: "1rem", listStyle: "none"};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <DeletionDialog theme={theme} {...props} />;
};