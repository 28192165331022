import React from "react";
import Immutable from "immutable";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-enterprise";
import moment from "moment/moment";

import * as GridUtils from "js/dashboards/components/grids/utils";
import {isNumber} from "js/dashboards/components/grids/utils";
import * as DashboardUtils from "js/dashboards/utils";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Rata from "js/common/utils/remote-data";
import useDimensions from "js/common/utils/use-dimensions";
import usePrevious from "js/common/hooks/use-previous";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import PerformanceSnapshotEditor from "js/dashboards/components/performance-snapshot/editor";

import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import * as KpiCalculator from "js/common/kpi-calculator";
import * as RatioRepo from "js/common/repo/ratio-repo";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import CustomCell from "js/dashboards/components/performance-snapshot/custom-cell";
import CustomColumnHeader from "js/dashboards/components/performance-snapshot/custom-column-header";
import CustomTooltip from "js/dashboards/components/performance-snapshot/custom-tooltip";
import {betterMemo} from "js/common/utils/more-memo";

import KpiDetailsDialog from "js/oneview/kpi-details/dialog";
import RatioDetailsDialog from "js/oneview/ratio-details/dialog";
import * as RatioFormatter from "js/common/utils/ratio-formatter";
import {roundTo} from "js/common/utils/numbers";
import * as Formatter from "js/common/utils/formatter";

const getRowId = params => params.data.id;
const getDataPath = data => data.dataPathForRowGrouping;

const autoGroupColumnDef = {
  sortable: false,
  minWidth: 300,
  rowGroup: true,
  headerName: "Group/User",
  tooltipComponent: CustomTooltip,
  tooltipValueGetter: function(params) {
    return params.valueFormatted || params.value;
  },
  pinned: "left",
  suppressMenu: true,
  cellRendererParams: {
    suppressCount: true
  }
};

const createColumns = (kpiIds, ratios, sortType) => {
  let columns = [];
  kpiIds
      .map(kpiId => KpiRepo.get(kpiId))
      .forEach(kpi => columns.push({
        headerComponent: CustomColumnHeader,
        cellRenderer: CustomCell,
        headerName: kpi.get("name"),
        field: "kpi-" + kpi.get("id"),
        comparator: customComparator("kpi-" + kpi.get("id"), sortType),
        headerTooltip: kpi.get("name"),
        tooltipField: "kpi-" + kpi.get("id"),
        tooltipComponent: CustomTooltip,
        sortable: true,
        rowGroup: false
      }));
  ratios
      .forEach(ratio => columns.push({
        headerComponent: CustomColumnHeader,
        cellRenderer: CustomCell,
        headerName: ratio.get("name"),
        field: "ratio-" + ratio.get("id"),
        comparator: customComparator("ratio-" + ratio.get("id"), sortType),
        headerTooltip: ratio.get("name"),
        tooltipComponent: CustomTooltip,
        sortable: true,
        rowGroup: false
      }));
  return columns;
};
export const getPercentageOfTargetComplete = (target, value) => {
  const roundedTarget = roundTo(target, 3);
  const percent = roundedTarget === 0 ? 0 : value / roundedTarget;
  return Math.round(percent * 100) / 100;
};

const customComparator = (key, sortType) => (a, b) => {
  if (!a || !b) {
    return 0;
  }
  if (a.get("status") === "LOADING" || b.get("status") === "LOADING") {
    return 0;
  }
  const dataType = key.split("-")[0];
  if (dataType === "kpi") {
    if (sortType === "target") {
      if ((!a.get("value").target.value || a.get("value").target.value === "0") && (!b.get("value").target.value
          || b.get("value").target.value
          === "0")) {
        a = a.get("value").total.value;
        b = b.get("value").total.value;
      } else {
        a = a.get("value").target.value;
        b = b.get("value").target.value;
      }
    } else if (sortType === "achieved") {
      a = getPercentageOfTargetComplete(a.get("value").target.value, a.get("value").total.value);
      b = getPercentageOfTargetComplete(b.get("value").target.value, b.get("value").total.value);
    } else {
      a = a.get("value").total.value;
      b = b.get("value").total.value;
    }
  } else {
    a = RatioFormatter.format("DECIMAL", a.get("value").firstKpiResponse.total, a.get("value").secondKpiResponse.total);
    b = RatioFormatter.format("DECIMAL", b.get("value").firstKpiResponse.total, b.get("value").secondKpiResponse.total);
  }
  if (!a && !b) {
    return 0;
  } else if (!a && b) {
    return -1;
  } else if (a && !b) {
    return 1;
  } else {
    if (isNumber(a) && isNumber(b)) {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (typeof Immutable.Map()) {
        return a.localeCompare(b, undefined, {numeric: true});
      }
    }
  }
};

const createUserRow = (user, parentKey, path, expanded) => {
  return {
    expanded,
    key: user.get("fullName"),
    parentKey: parentKey,
    dataPathForRowGrouping: [...path, user.get("fullName")],
    qualifier: {type: "USER", id: user.get("id")},
    id: "USER-" + user.get("id")
  };
};

const createGroupRow = (group, parentKey, path, expanded) => {
  return {
    expanded,
    key: group.get("name"),
    parentKey: parentKey,
    dataPathForRowGrouping: path,
    qualifier: {type: "GROUP", id: group.get("id")},
    id: "GROUP-" + group.get("id")
  };
};

const pushRowsForGroupAndChildren = (rows, currentPath, group, parentKey) => {
  const newPath = [...currentPath, group.get("name")];

  rows.push(createGroupRow(group, parentKey, newPath, currentPath.length === 0));

  Groups
      .getGroups()
      .filter(g => g.get("parentId") === group.get("id")
          && !g.get("deleted"))
      .forEach(childGroup => pushRowsForGroupAndChildren(rows, newPath, childGroup, group.get("name")));

  Users
      .getUsers()
      .filter(child => !child.get("cube19User")
          && child.get("state") !== "INVISIBLE"
          && child.get("groupId") === group.get("id"))
      .forEach(user => rows.push(createUserRow(user, group.get("name"), newPath, false)));
};

const createRows = qualifier => {
  const rows = [];
  if (qualifier.get("type") === "USER") {
    const user = Users.getUser(qualifier.get("id"));
    rows.push(createUserRow(user, "root", [], true));
  } else {
    pushRowsForGroupAndChildren(rows, [], Groups.getGroup(qualifier.get("id")), "root");
  }
  return rows;
};

const loadKpiDataForRow = (node, config, queueDataLoad, setDataForRowAndField, componentLoadId, componentId, api) => {
  const qualifier = node.data.qualifier;
  const hasClientFilters = !config.getIn(["clientFilter", "allClientIds"]).isEmpty();
  const hasTagFilters = !config.getIn(["tagFilter", "matchAnyTagIds"]).isEmpty() || !config.getIn([
    "tagFilter",
    "matchAllTagIds"]).isEmpty() || !config.getIn(["tagFilter", "excludedTagIds"]).isEmpty();

  const ajaxParams = {
    dateFromUI: moment().format("YYYY-MM-DD"),
    timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
    clientIds: config.getIn(["clientFilter", "allClientIds"]).toArray(),
    matchAnyTagIds: config.getIn(["tagFilter", "matchAnyTagIds"]).toArray(),
    matchAllTagIds: config.getIn(["tagFilter", "matchAllTagIds"]).toArray(),
    excludedTagIds: config.getIn(["tagFilter", "excludedTagIds"]).toArray(),
    entityToIds: config.get("entityToIds", Immutable.Map()).toObject(),
    alwaysAllowClientFilter: true,
    ...DashboardUtils.qualifierToAjaxParams(qualifier.id, qualifier.type)
  };

  config
      .get("kpiIds")
      ?.forEach(kpiId => {
        const field = "kpi-" + kpiId;
        const cell = node.data[field];
        const kpi = kpiRepo.get(kpiId);
        const notFilterableByClient = hasClientFilters && !kpi.get("filterableByClient");
        // NOTE Due to the separation of rows and data the Rata initial value may not have been copied in yet.
        //  Therefore, we have to check for the cell being falsey too
        const loadingNotStarted = !cell || Rata.isInitial(cell);
        if (loadingNotStarted) {
          setDataForRowAndField(node, field, x => Rata.toLoading(x));
          queueDataLoad(() => KpiCalculator.summary(kpiId, ajaxParams), {componentId, componentLoadId})
              .then(
                  result => {
                    setDataForRowAndField(
                        node,
                        field,
                        x => Rata.toLoaded(x, {...result, hasTagFilters, hasClientFilters, notFilterableByClient}
                        )
                    );
                  },
                  error => setDataForRowAndField(node, field, x => Rata.toError(x, error)))
              .then(() => api.onSortChanged())
          ;
        }
      });

  config
      .get("ratiosList")
      ?.forEach(ratio => {
        const field = "ratio-" + ratio.get("id");
        const cell = node.data[field];
        if (ratio.has("firstKpiId") && ratio.has("secondKpiId")) {
          const firstKpi = kpiRepo.get(ratio.get("firstKpiId"));
          const secondKpi = kpiRepo.get(ratio.get("secondKpiId"));
          const notFilterableByClient = hasClientFilters && (!firstKpi.get("filterableByClient") || !secondKpi.get(
              "filterableByClient"));
          // NOTE Due to the separation of rows and data the Rata initial value may not have been copied in yet.
          //  Therefore, we have to check for the cell being falsey too
          const loadingNotStarted = !cell || Rata.isInitial(cell);
          if (loadingNotStarted) {
            setDataForRowAndField(node, field, x => Rata.toLoading(x));
            Promise
                .all([
                  queueDataLoad(
                      () => KpiCalculator.summary(ratio.get("firstKpiId"), ajaxParams),
                      {
                        componentId,
                        componentLoadId
                      }),
                  queueDataLoad(
                      () => KpiCalculator.summary(ratio.get("secondKpiId"), ajaxParams),
                      {
                        componentId,
                        componentLoadId
                      })])
                .then(
                    ([first, second]) => {
                      setDataForRowAndField(
                          node,
                          field,
                          x => Rata.toLoaded(
                              x,
                              {
                                displayType: ratio.get("displayType"),
                                firstKpiResponse: first,
                                secondKpiResponse: second,
                                notFilterableByClient
                              }));
                    },
                    error => setDataForRowAndField(
                        node,
                        field,
                        x => Rata.toError(x, error)))
                .then(() => api.onSortChanged());
          }
        } else {
          setDataForRowAndField(
              node,
              field,
              x => Rata.toError(x, {message: "Invalid ratio config"}));
        }
      });
};

const loadAndSetDataForNode = (node, config, queueDataLoad, setDataForRowAndField, componentLoadId, componentId, api) => {
  loadKpiDataForRow(
      node,
      config,
      queueDataLoad,
      setDataForRowAndField,
      componentLoadId,
      componentId,
      api);
};

const loadAndSetDataForNodeAndChildren = (node, config, queueDataLoad, setDataForRowAndField, componentLoadId, componentId, api) => {
  loadAndSetDataForNode(
      node,
      config,
      queueDataLoad,
      setDataForRowAndField,
      componentLoadId,
      componentId,
      api);
  node.childrenAfterSort.forEach(childNode => loadAndSetDataForNode(
      childNode,
      config,
      queueDataLoad,
      setDataForRowAndField,
      componentLoadId,
      componentId,
      api
  ));
};

const getInitialRowsWithData = rows => {
  const rowsWithData = [];
  for (let i = 0; i < rows.length; i++) {
    rowsWithData[i] = {...rows[i]};
  }
  return rowsWithData;
};

const getRowIdToIndex = rows => {
  const rowIdToIndex = {};
  for (let i = 0; i < rows.length; i++) {
    rowIdToIndex[rows[i].id] = i;
  }
  return rowIdToIndex;
};

const applyColumnSortAndOrder = (config, api) => {
  if (config.get("colSort")) {
    api.applyColumnState({
      state: config.get("colSort").toJS(),
      applyOrder: true
    });
  }
};

const PerformanceSnapshot = betterMemo({displayName: "PerformanceSnapshot"}, ({
  title,
  config,
  componentId,
  onConfigChange,
  queueDataLoad,
  clearDataLoad,
  componentLoadId,
  onParentConfigChange,
  onRequestDialog,
  downloadFnRef
}) => {
  const gridRef = React.useRef();
  const [dimRef, {height}] = useDimensions();

  const {theme} = React.useContext(CustomThemeContext);
  const {gridTheme} = GridUtils.getThemes(theme.themeId);


  const kpiIds = config.get("kpiIds", Immutable.List());
  const ratios = config.get("ratiosList", Immutable.List());
  const sortType = config.get("metricSortType", "value");
  const columns = React.useMemo(() => createColumns(kpiIds, ratios, sortType), [kpiIds, ratios, sortType]);

  const qualifier = DashboardUtils.updateQualifierForLoggedInUserOrGroup(config.get("qualifier"));
  const rows = React.useMemo(() => createRows(qualifier), [qualifier]);
  const rowIdToIndex = React.useMemo(() => getRowIdToIndex(rows), [rows]);

  const getDownloadObject = React.useCallback(() => {
    let cells = [];
    if (gridRef.current) {
      gridRef.current.api?.forEachNode(node => {
        if (node.parent.expanded || node.expanded) {
          config
              .get("kpiIds")
              ?.forEach(kpiId => {
                const field = "kpi-" + kpiId;
                const cell = node.data[field];
                cells.push(cell);
              });
          config
              .get("ratiosList")
              ?.forEach(ratio => {
                const field = "ratio-" + ratio.get("id");
                const cell = node.data[field];
                cells.push(cell);
              });
        }
      });
    }

    const hasPermission = Users.currentHasPermission("EXPORT_FILE");
    const percentageLoaded = Math.round(cells.filter(cell => Rata.isLoaded(cell)).length / cells.length * 100);
    const downloadFnEnabled = !cells.some(cell => Rata.isLoading(cell));
    const isDisabled = !hasPermission || !downloadFnEnabled;
    let tooltipText;

    if (!hasPermission) {
      tooltipText = "Ask an admin user for the 'Export To File' permission to download this data";
    } else {
      if (!downloadFnEnabled) {
        tooltipText = `The data to be downloaded is still being retrieved. ${percentageLoaded}% loaded.`;
      } else {
        tooltipText = "Download";
      }
    }

    return ({
      enabled: !isDisabled,
      tooltip: tooltipText,
      downloadFn: () => gridRef.current.api.exportDataAsExcel({
        fileName: GridUtils.getExportFileName(config, title),
        rowGroupExpandState: "match",
        processCellCallback(params) {
          const node = params;
          if (node.value || Rata.isLoaded(node.value)) {
            if (node.value) {
              const fieldType = node.column.colId.split("-")[0];
              switch (fieldType) {
                case "kpi":
                  const kpiData = node.value.get("value");
                  if (kpiData) {
                    const {hasClientFilters, hasTagFilters, target, total} = kpiData;
                    if (kpiData.notFilterableByClient) {
                      return "N/A";
                    }
                    const hasTarget = !hasClientFilters && !hasTagFilters && target.value > 0;
                    const formattedTotal = Formatter.format(
                        total,
                        {maxDisplayLength: 6});
                    const formattedTarget = Formatter.format(
                        target,
                        {maxDisplayLength: 6});
                    return hasTarget ? `${formattedTotal} / ${formattedTarget}` : formattedTotal;
                  } else {
                    break;
                  }
                case "ratio":
                  const ratioData = node.value.get("value");
                  if (ratioData) {
                    const {displayType, firstKpiResponse, secondKpiResponse, notFilterableByClient} = ratioData;
                    if (notFilterableByClient) {
                      return "N/A";
                    }
                    return RatioFormatter.format(displayType, firstKpiResponse.total, secondKpiResponse.total);
                  } else {
                    break;
                  }
                default:
                  if (node.node.parent.expanded || node.node.expanded) {
                    return node.value;
                  } else {
                    break;
                  }
              }
            }
          }
        }
      })
    });
  }, [config, gridRef, title]);

  downloadFnRef.current = () => getDownloadObject();

  const rowsWithDataRef = React.useRef(null);
  if (rowsWithDataRef.current === null) {
    rowsWithDataRef.current = getInitialRowsWithData(rows);
  }

  React.useEffect(() => () => clearDataLoad(item => item.componentId
      === componentId
      && item.componentLoadId
      === componentLoadId), [componentId, componentLoadId, clearDataLoad]);

  const setDataForRowAndField = React.useCallback((rowNode, fieldId, valueMapper) => {
    const rowIndex = rowIdToIndex[rowNode.id];
    const cell = rowsWithDataRef.current[rowIndex][fieldId];
    const newValue = valueMapper(cell);
    rowsWithDataRef.current[rowIndex][fieldId] = newValue;
    // NOTE updating grid in timeout to ensure it doesn't trigger ag-grid error about interrupting rendering
    setTimeout(() => rowNode.setDataValue(fieldId, newValue), 0);
  }, [rowIdToIndex]);

  const previousComponentLoadId = usePrevious(componentLoadId);
  React.useEffect(
      () => {
        // NOTE this component will not respond to changes in rows / columns without a reload
        if (previousComponentLoadId !== componentLoadId) {
          rowsWithDataRef.current = getInitialRowsWithData(rows);
        }
        if (gridRef.current.api) {
          gridRef.current.api.forEachNode(node => {
            if (node.expanded) {
              loadAndSetDataForNodeAndChildren(
                  node,
                  config,
                  queueDataLoad,
                  setDataForRowAndField,
                  componentLoadId,
                  componentId,
                  gridRef.current.api);
            }
          });
        }
      },
      [
        rows,
        columns,
        componentLoadId,
        previousComponentLoadId,
        config,
        queueDataLoad,
        setDataForRowAndField,
        componentId]);

  const handleFirstDataRendered = React.useCallback(e => {
    for (const row of rows) {
      if (row.expanded) {
        e.api.setRowNodeExpanded(e.api.getRowNode(row.id), true);
      }
    }
  }, [rows]);

  const handleRowGroupOpened = React.useCallback(e => {
    const node = e.node;
    if (node.expanded) {
      loadAndSetDataForNodeAndChildren(
          node,
          config,
          queueDataLoad,
          setDataForRowAndField,
          componentLoadId,
          componentId,
          e.api);
    }
  }, [config, queueDataLoad, setDataForRowAndField, componentLoadId, componentId]);

  const handleGridReady = React.useCallback(e => {
    applyColumnSortAndOrder(config, e.columnApi);
  }, [config]);

  React.useEffect(() => {
    const api = gridRef && gridRef.current && gridRef.current.columnApi;
    if (api) {
      applyColumnSortAndOrder(config, gridRef.current.columnApi);
    }
  }, [config, gridRef]);

  const handleSortChanged = React.useCallback(e => {
    const colSort = e.columnApi.getColumnState();
    // Only fire when it's a UI change, this prevents handleSortChanged being called when gridReady applies the state
    if (e.source === "uiColumnSorted") {
      onConfigChange(componentId, config => config.set("colSort", Immutable.List(colSort)));
    }
  }, [onConfigChange, componentId]);

  const handleColumnMoved = React.useCallback(e => {
    const colOrder = e.columnApi.getColumnState();
    // Only fire when manually dragging, this prevents handleColumnMoved being called when gridReady applies the state
    if (e.toIndex) {
      onConfigChange(componentId, config => config.set("colSort", Immutable.List(colOrder)));
    }
  }, [componentId, onConfigChange]);

  const handleCellClick = React.useCallback(e => {
    const dataType = e.column.colId.split("-")[0];
    const dataId = Number(e.column.colId.split("-")[1]);
    const qualifier = e.node.data.qualifier;
    if (dataType === "kpi") {
      return kpiDialog(dataId, config, onRequestDialog, onParentConfigChange, qualifier);
    } else {
      const ratioData = e.node.data[`ratio-${dataId}`];
      return ratioDialog(dataId, ratioData, config, onRequestDialog, onParentConfigChange, queueDataLoad, qualifier);
    }
  }, [onRequestDialog, queueDataLoad, onParentConfigChange, config]);

  return <div style={{width: "100%", height: "100%", display: "flex", flexFlow: "column"}}>
    <div style={{display: "flex", height: "100%", overflow: "hidden"}}>
      <div ref={dimRef} style={{flex: 1, width: "100%", height: "100%"}}>
        <div className={gridTheme} style={{display: "flex", flexDirection: "column", height}}>
          <AgGridReact
              {...GridUtils.defaultGridProps}
              {...config.get("gridProps", Immutable.Map()).toJS()}
              ref={gridRef}
              groupDisplayType="singleColumn"
              pivotMode={false}
              enableCharts={false}
              sideBar={false}
              treeData={true}
              getRowId={getRowId}
              getDataPath={getDataPath}
              autoGroupColumnDef={autoGroupColumnDef}
              groupMaintainOrder={true}
              rowData={rowsWithDataRef.current}
              headerHeight={34}
              animateRows={true}
              tooltipMouseTrack={true}
              columnDefs={columns}
              onGridReady={handleGridReady}
              suppressScrollOnNewData={true}
              suppressBrowserResizeObserver={true}
              suppressColumnMoveAnimation={true}
              onRowGroupOpened={handleRowGroupOpened}
              onCellClicked={handleCellClick}
              onFirstDataRendered={handleFirstDataRendered}
              onSortChanged={handleSortChanged}
              onColumnMoved={handleColumnMoved}
          />
        </div>
      </div>
    </div>
  </div>;
});

const kpiDialog = (kpiId, config, onRequestDialog, onParentConfigChange, qualifier) => {
  const kpi = kpiRepo.get(kpiId);

  // TODO JC - why are the tag id params not set here?
  // TODO JC - is it expected for client / tag id params to be immutable when passed to the dialog?
  const kpiDetailsOptions = {
    timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
    clientIds: config.getIn(["clientFilter", "allClientIds"], Immutable.List()),
    entityToIds: config.get("entityToIds", Immutable.Map()).toObject(),
    ...DashboardUtils.qualifierToAjaxParams(qualifier.id, qualifier.type)
  };

  const title = (
      <div style={{display: "inline-block"}}>
        <span
            style={{
              fontSize: 20,
              textTransform: "none",
              fontWeight: 500
            }}>
            {kpi.get("name")}
        </span>
        <span style={{fontWeight: 500}}>
        {` (${TimeframeRepo.parse(config.get("timeframe").toJS()).attributes.name})`}
        </span>
        <span style={{marginLeft: 5}}>for {qualifier.type === "GROUP"
            ? Groups.getGroup(qualifier.id).get("name")
            : Users.getUser(qualifier.id)?.attributes.fullName}</span>
      </div>
  );

  const closeDialog = onRequestDialog(
      <KpiDetailsDialog
          renderContentOnly
          kpiId={kpiId}
          {...kpiDetailsOptions}
          matchAnyTagIds={config.getIn(["tagFilter", "matchAnyTagIds"], Immutable.List())}
          matchAllTagIds={config.getIn(["tagFilter", "matchAllTagIds"], Immutable.List())}
          excludedTagIds={config.getIn(["tagFilter", "excludedTagIds"], Immutable.List())}
          onGroupClick={groupId => {
            onParentConfigChange(parentConfig => parentConfig.set("qualifier", Immutable.Map({
              type: "GROUP",
              id: groupId
            })), true);
            closeDialog();
          }}
          onUserClick={userId => {
            onParentConfigChange(parentConfig => parentConfig.set("qualifier", Immutable.Map({
              type: "USER",
              id: userId
            })), true);
            closeDialog();
          }} />,
      {title: title});
};

const ratioDialog = (dataId, ratioData, config, onRequestDialog, onParentConfigChange, queueDataLoad, qualifier) => {
  const ratio = RatioRepo.get(dataId);
  // Return (don't error) if the cell is still loading
  if (!ratio || !ratioData.get("value")) {
    return;
  }

  const ajaxParams = {
    dateFromUI: moment().format("YYYY-MM-DD"),
    timeframe: TimeframeRepo.parse(config.get("timeframe").toJS()),
    clientIds: config.getIn(["clientFilter", "allClientIds"]).toArray(),
    matchAnyTagIds: config.getIn(["tagFilter", "matchAnyTagIds"]).toArray(),
    matchAllTagIds: config.getIn(["tagFilter", "matchAllTagIds"]).toArray(),
    excludedTagIds: config.getIn(["tagFilter", "excludedTagIds"]).toArray(),
    entityToIds: config.get("entityToIds", Immutable.Map()).toObject(),
    ...DashboardUtils.qualifierToAjaxParams(qualifier.id, qualifier.type)
  };

  const title = (
      <div style={{display: "inline-block"}}>
        <span
            style={{
              fontSize: 20,
              textTransform: "none",
              fontWeight: 500
            }}>
            {ratioData.get("value").name}
        </span>
        <span style={{fontWeight: 500}}>
          {` (${TimeframeRepo.parse(config.get("timeframe").toJS()).attributes.name})`}
        </span>
        <span style={{marginLeft: 5}}>for {qualifier.type === "GROUP"
            ? Groups.getGroup(qualifier.id).get("name")
            : Users.getUser(qualifier.id)?.attributes.fullName}</span>
      </div>
  );

  const ctOptions = {
    initialTab: "DETAILS",
    ratio: Immutable.Map({
      name: ratio.get("name"),
      firstKpiId: ratio.get("firstKpiId"),
      secondKpiId: ratio.get("secondKpiId"),
      displayType: ratio.get("displayType")
    }),
    ratioData: Immutable.Map({
      firstKpiData: Immutable.fromJS(ratioData.get("value").firstKpiResponse),
      secondKpiData: Immutable.fromJS(ratioData.get("value").secondKpiResponse)
    }),
    ...ajaxParams,
    ...DashboardUtils.configToQualifierAjaxParams(config)
  };


  const closeDialog = onRequestDialog(
      <RatioDetailsDialog
          renderContentOnly
          {...ctOptions}
          matchAnyTagIds={config.getIn(["tagFilter", "matchAnyTagIds"], Immutable.List())}
          matchAllTagIds={config.getIn(["tagFilter", "matchAllTagIds"], Immutable.List())}
          excludedTagIds={config.getIn(["tagFilter", "excludedTagIds"], Immutable.List())}
          onKpiClick={kpiId => {
            closeDialog();
            kpiDialog(kpiId, config, onRequestDialog, onParentConfigChange, qualifier);
          }}
      />,
      {title: title, minWidth: "fit-content", minHeight: 230});
};

export default Immutable.fromJS({
  type: "PerformanceSnapshot",
  label: "Performance Snapshot",
  getDefaultData: () => ({rows: [], columns: []}),
  getReactComponent: () => PerformanceSnapshot,
  getEditorReactComponent: () => PerformanceSnapshotEditor,
  getTitle: component => component.get("title") || "Performance Snapshot",
  canFullScreen: true,
  canDownload: true,
  layout: {
    min: {width: 12, height: 12},
    max: {width: 48, height: 24},
    init: {width: 28, height: 16}
  },
  applyParentConfig: (parentConfig, dataConfig) => DashboardUtils.applyInheritanceForKeys(parentConfig, dataConfig),
  load: () => Promise.resolve({})
});
