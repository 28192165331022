import * as Immutable from "immutable";

const getActivityCategories = limit => Immutable.fromJS([{
  label: "All",
  dayRange: {
    min: 0,
    max: limit
  },
  allowJobsWithNoActivity: true
}, {
  label: "None",
  allowJobsWithNoActivity: true
}, {
  label: "<= 7",
  dayRange: {
    min: 0,
    max: 7
  },
  allowJobsWithNoActivity: false
}, {
  label: "8 - 30",
  dayRange: {
    min: 8,
    max: 30
  },
  allowJobsWithNoActivity: false
}, {
  label: "31 - " + (limit - 1),
  dayRange: {
    min: 31,
    max: limit - 1
  },
  allowJobsWithNoActivity: false
}, {
  label: (limit - 1) + "+",
  dayRange: {
    min: limit - 1,
    max: limit
  },
  allowJobsWithNoActivity: false
}]);

const filterJobRowByActivity = (jobRow, dayRange, limit, allowJobsWithNoActivity) => {
  const jobHasActivity = !jobRow.get("candidateRows").isEmpty();

  let withinRange;
  if (dayRange) {
    const min = dayRange.get("min") === 0 ? Number.MIN_SAFE_INTEGER : dayRange.get("min");
    const max = dayRange.get("max") === limit ? Number.MAX_SAFE_INTEGER : dayRange.get("max");
    withinRange = jobRow.get("daysSinceLastActivity") >= min
        && jobRow.get("daysSinceLastActivity") <= max;
  } else {
    withinRange = false;
  }

  return (jobHasActivity && withinRange) || (!jobHasActivity && allowJobsWithNoActivity);
};

export {
  getActivityCategories,
  filterJobRowByActivity
};