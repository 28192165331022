import React from "react";
import pure from "js/common/views/pure";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import GroupAndUserList from "js/common/views/group-and-user-list";
import Dialog from "js/common/views/dialog";

export default pure(({
  theme,
  page,
  onRequestClose
}) => {
  const groups = page.get("usages")
      .filter(usage => usage.get("qualifier") === "GROUP")
      .map(usage => Groups.getGroup(usage.get("id")))
      .sortBy(group => group.get("name"));
  const users = page.get("usages")
      .filter(usage => usage.get("qualifier") === "USER")
      .map(usage => Users.getUser(usage.get("id")))
      .sortBy(user => user.get("fullName"));
  const actions = [<TextButton
      label="Back"
      onClick={onRequestClose}
      style={{marginRight: 8}} />];
  return <Dialog
      bodyStyle={{overflow: "auto"}}
      paperStyle={{backgroundColor: theme.palette.background.card}}
      actionsContainerStyle={{paddingRight: "2rem"}}
      actions={actions}
      title={`Usages of ${page.get("name")}`}
      autoDetectWindowHeight={true}
      open={true}
      onBackdropClick={onRequestClose}
      enableScroll={true}>
    <div style={{color: theme.palette.textColor}}>
      <p>Listed below are the Groups and Users with this OneView Page assigned. </p>
      <p>Users or Groups underneath the assigned Groups may be inheriting this Page.</p>
      <GroupAndUserList
          groups={groups}
          users={users} />
    </div>
  </Dialog>;
});
