import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import Immutable from "immutable";

import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import ButtonBar from "js/common/views/button-bar";
import ErrorMsg from "js/common/views/error";
import GroupAndUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import Tooltip from "js/common/views/tooltips";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import LoadingSpinner from "js/common/views/loading-spinner";
import InteractiveTable from "js/common/views/tables/interactive-table";
import GroupHistoryCalculationTable from "js/admin/groups/group-history-calculation-table";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import Layout from "js/common/views/foundation-column-layout";
import Checkbox from "js/common/views/inputs/checkbox";
import InfiniteListSelect from "js/common/views/infinite-loading/infinite-list-select";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as Ajax from "js/common/ajax";
import * as Popups from "js/common/popups";
import * as Auditor from "js/common/auditer";
import * as Time from "js/common/utils/time";
import Cube19 from "js/cube19.app";


const path = window.path;

const spacing = {
  marginTop: "0.5rem",
  marginBottom: "0.5rem"
};

export default createReactClass({

  displayName: "EditUserHistory",

  getInitialState() {
    return {
      userId: null,
      groupAssignments: Immutable.List(),
      uneditedGroupAssignments: Immutable.List(),
      loadingUsers: false,
      loadingGroupAssignments: false,
      validationErrors: [],
      showInactiveUsers: false,
      userById: Immutable.Map()
    };
  },

  componentDidMount() {
    this.loadAndSetUsers();
  },

  loadAndSetUsers() {
    this.setState({
      loadingUsers: true
    });
    return Users.load().then(users => {
      const userById = users.groupBy(usr => usr.get("id")).map(u => u.first());
      this.setState({
        userById,
        loadingUsers: false
      });
    });
  },

  getUser(userId) {
    return this.state.userById.get(userId);
  },

  render() {
    const {userId, showInactiveUsers, validationErrors, loadingGroupAssignments, loadingUsers} = this.state;
    const user = this.getUser(userId);

    const isActiveUser = user && Users.isActive(user);
    const canSee = user && (isActiveUser || showInactiveUsers);

    const users = this.state.userById
        .valueSeq()
        .filter(u => Users.isActive(u) || showInactiveUsers)
        .sortBy(u => u.get("fullName"));

    let placeholder = "Loading...";
    if (!loadingUsers) {
      if (canSee && userId) {
        placeholder = this.state.userById.get(userId).get("fullName");
      } else {
        placeholder = "Select User";
      }
    }

    return (
        <div>
          <section className="page-section">

            <AdminHeader>
              <Icon icon="users" />
              Edit a User's Group History
            </AdminHeader>
            <Layout allSmall={12} allMedium={4} floatLastColumnLeft={true} rowStyle={{marginTop: "1rem"}}>
              <InfiniteListSelect
                  showSelectedInDropdown={false}
                  placeholder={placeholder}
                  bgColor="paper"
                  startItems={users.map(u => Immutable.Map({
                    name: u.get("fullName"),
                    id: u.get("id")
                  }))}
                  menuFooterCreatorFn={() => null}
                  onChange={this.handleUserSelect}
                  closeOnSelect={true}
                  disabled={loadingUsers} />
              <div style={spacing}>
                <Checkbox
                    label="Show Inactive/Orphan Users"
                    checked={showInactiveUsers}
                    style={{marginTop: "-10px"}}
                    onCheck={(e, isChecked) => this.setState({showInactiveUsers: isChecked})} />
              </div>
            </Layout>
            {(canSee && !isActiveUser) && this.renderAutoUpdateGroupToggle()}
            {validationErrors.length > 0 && this.renderErrors()}
            {canSee && (loadingGroupAssignments ? <LoadingSpinner /> : this.renderGroupAssignments())}
          </section>
          <GroupHistoryCalculationTable />
        </div>);
  },

  renderErrors() {
    const {validationErrors} = this.state;
    return (
        <Layout allSmall={12} rowStyle={spacing}>
          {validationErrors.map((error, index) => {
            const errorText = `Row ${error.lineNumber + 1}: ${error.error}`;
            return <ErrorMsg key={`err-${index}`} text={errorText} />;
          })}
        </Layout>);
  },

  renderGroupAssignments() {
    const {userId, groupAssignments} = this.state;
    const user = this.getUser(userId);
    const canEditGroupAssignments = Users.isActive(user) || !user.get("autoUpdateGroup");
    const groupIds = groupAssignments.map(grp => grp.get("groupId"));
    const visibleGroupIds = Groups.getGroups().map(grp => grp.get("id"));
    const userCannotEditAnyGroupAssignment = groupIds.every(grpId => !visibleGroupIds.includes(grpId));
    const hasInvalidGroupAssignment = groupAssignments.some(grp => !grp.get("startDate").isValid() ||
        !grp.get("endDate").isValid());

    return (
        <div>
          <Layout allSmall={12} rowStyle={spacing}>
            <HistoryTable
                isDisabled={!canEditGroupAssignments}
                groupAssignments={groupAssignments}
                onChange={groupAssignments => this.setState({groupAssignments})} />
          </Layout>
          <ButtonBar style={spacing}>
            <TextButton
                icon="history"
                label="Cancel"
                onClick={this.handleCancelClick}
                disabled={!canEditGroupAssignments || userCannotEditAnyGroupAssignment} />
            <TextButton
                icon="floppy-o"
                label="Save"
                onClick={this.handleSaveClick}
                disabled={!canEditGroupAssignments || groupAssignments.isEmpty() || hasInvalidGroupAssignment ||
                    userCannotEditAnyGroupAssignment} />
          </ButtonBar>
          {groupAssignments.isEmpty() && <ErrorMsg text="A User must have at least one Group assignment" />}
        </div>);
  },

  renderAutoUpdateGroupToggle() {
    const user = this.getUser(this.state.userId);
    return (
        <Layout allSmall={12} allMedium={6} rowStyle={spacing}>
          <Checkbox
              label="Keep this invisible User’s start and end dates in sync with their data"
              checked={user.get("autoUpdateGroup")}
              onCheck={(e, autoUpdateGroup) => this.handleAutoUpdateGroupChange(autoUpdateGroup)} />
        </Layout>);
  },

  handleAutoUpdateGroupChange(autoUpdateGroup) {
    this.setState({
      loadingGroupAssignments: true
    });
    const user = this.getUser(this.state.userId);
    Users.update(user.set("autoUpdateGroup", autoUpdateGroup)).then(user => {
      this.setState(state => ({
        userById: state.userById.set(user.get("id"), user),
        loadingGroupAssignments: false
      }));
    });
  },

  handleUserSelect(user) {
    const userId = user.first().get("id");
    this.setState({
      userId,
      loadingGroupAssignments: true
    });
    loadHistoryForUser(userId).then(groupAssignments => {
      this.setState({
        groupAssignments,
        uneditedGroupAssignments: groupAssignments,
        validationErrors: [],
        loadingGroupAssignments: false
      });
    });
  },

  handleSaveClick() {
    this.setState({
      loadingGroupAssignments: true,
      validationErrors: []
    });
    const {userId, uneditedGroupAssignments} = this.state;
    updateHistoryForUser(userId, this.state.groupAssignments)
        .then(groupAssignments => {
          this.setState({
            groupAssignments
          });

          Cube19.request("users:hierarchy-changed");
          Popups.success(`${this.getUser(userId).get("fullName")}'s Group history has been updated`);

          const oldGroupAssignments = uneditedGroupAssignments.map(formatDatesInGroupAssignment).toJS();
          const newGroupAssignments = groupAssignments.map(formatDatesInGroupAssignment).toJS();
          Auditor.audit("user_history_updated", {
            userId,
            oldGroupAssignments,
            newGroupAssignments
          });
          this.loadAndSetUsers().then(() => {
            this.setState({
              loadingGroupAssignments: false
            });
          });
        })
        .catch(e => {
          if (e && e.responseJSON) {
            const {validationErrors} = e.responseJSON.data;
            const {message} = e.responseJSON;

            if (validationErrors === null) {
              if (message != null) {
                Popups.error(message);
              } else {
                Popups.contactSupport();
              }
            } else {
              this.setState({validationErrors});
            }
          } else {
            Popups.contactSupport();
          }
          this.setState({loadingGroupAssignments: false});
        });
  },

  handleCancelClick() {
    loadHistoryForUser(this.state.userId).then(groupAssignments => {
      this.setState({
        groupAssignments,
        uneditedGroupAssignments: groupAssignments,
        validationErrors: [],
        loadingGroupAssignments: false
      });
    });
  }

});


const HistoryTable = createReactClass({

  render() {
    const columns = [
      {label: "Group", foundationClasses: "medium-3"},
      {label: "Start Date", foundationClasses: "medium-3"},
      {label: "End Date", foundationClasses: "medium-3"},
      {label: "Actions", foundationClasses: "medium-3"}];

    const rows = this.props.groupAssignments
        .sortBy(ga => moment(ga.get("startDate")))
        .map(this.groupAssignmentToRow)
        .toArray();
    return <InteractiveTable columns={columns} rows={rows} />;
  },

  groupAssignmentToRow(groupAssignment, index, groupAssignments) {
    const {isDisabled, onChange} = this.props;
    const groups = Groups.getGroups();
    const userAllowedToViewGroup = groups
        .map(g => g.get("id"))
        .includes(groupAssignment.get("groupId"));
    return [
      <div key={`grp-usr-picker-${index}`} style={{minWidth: 400}}>
        {userAllowedToViewGroup ?
            renderGroupPicker(groupAssignment, index, isDisabled, groupAssignments, onChange) :
            <Tooltip text="You do not have permission to see this Group" position="top">
              {renderGroupPicker(groupAssignment, index, true, groupAssignments, onChange)}
            </Tooltip>}
      </div>,
      <DatePicker
          key={`start-date-picker-${index}`}
          isDisabled={isDisabled || !userAllowedToViewGroup}
          onDateChange={startDate => {
            onChange(groupAssignments.setIn([index, "startDate"], startDate));
          }}
          value={groupAssignment.get("startDate")} />,
      <DatePicker
          key={`end-date-picker-${index}`}
          isDisabled={isDisabled || !userAllowedToViewGroup}
          onDateChange={(endDate, inputValue) => {
            if (!endDate.isValid() && inputValue === "") {
              endDate = getFarFutureDate();
            }
            onChange(groupAssignments.setIn([index, "endDate"], endDate));
          }}
          value={isFarFutureDate(groupAssignment.get("endDate")) ? null : groupAssignment.get("endDate")} />,
      <div key={`actions-${index}`}>
        <IconButton
            label="Add"
            icon="plus"
            type="bright"
            hoverType="bright"
            disableClick={isDisabled || !userAllowedToViewGroup}
            container="column"
            onClick={() => onChange(addNewGroupAssignmentAfter(index, groupAssignment, groupAssignments))}
            size="large" />
        <IconButton
            label="Delete"
            icon="trash"
            type="bright"
            hoverType="alert"
            disableClick={isDisabled || !userAllowedToViewGroup || groupAssignments.count() === 1}
            container="column"
            onClick={() => onChange(groupAssignments.delete(index))}
            size="large" />
      </div>
    ];
  }

});

const renderGroupPicker = (groupAssignment, index, isDisabled, groupAssignments, onChange) => {
  return (
      <GroupAndUserPicker
          breadcrumbMode={true}
          excludeUsers={true}
          isDisabled={isDisabled}
          qualifierId={groupAssignment.get("groupId")}
          qualifierType={"GROUP"}
          onGroupClick={groupId => onChange(groupAssignments.setIn([index, "groupId"], groupId))} />);
};

const addNewGroupAssignmentAfter = (index, previousGroupAssignment, groupAssignments) => {
  const today = moment();
  const previousEndDate = previousGroupAssignment.get("endDate").clone();
  const newGroupAssignment = previousGroupAssignment
      .delete("id")
      .set("startDate", isFarFutureDate(previousEndDate) ? today : previousEndDate.add(1, "days"))
      .set("endDate", getFarFutureDate());
  return groupAssignments.insert(index + 1, newGroupAssignment);
};

const getFarFutureDate = () => Time.parseDate("2099-01-01");
const isFarFutureDate = date => date.isSame(getFarFutureDate(), "day");

const loadHistoryForUser = userId => Ajax
    .get({url: path("users", userId, "history")})
    .then(x => Immutable.fromJS(x))
    .then(gs => gs.map(parseDatesInGroupAssignment));

const updateHistoryForUser = (userId, groupAssignments) => {
  const url = path("users", userId, "history");
  const json = groupAssignments
      .map(formatDatesInGroupAssignment)
      .toJS();
  return Ajax
      .put({url, json})
      .then(x => Immutable.fromJS(x))
      .then(gs => gs.map(parseDatesInGroupAssignment));
};

const parseDatesInGroupAssignment = ga => ga
    .update("startDate", Time.parseDate)
    .update("endDate", Time.parseDate);

const formatDatesInGroupAssignment = ga => ga
    .update("startDate", Time.formatDate)
    .update("endDate", Time.formatDate);

