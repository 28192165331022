/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import {css, jsx} from "@emotion/react";

import * as Ajax from "js/common/ajax";
import {betterMemo} from "js/common/utils/more-memo";

import LoadingSpinner from "js/common/views/loading-spinner";
import {Card, CardContent, TextField} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import {ArrowDropDown} from "@mui/icons-material";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const loadTrace = traceId => Ajax.put({url: "/tracing/nested", json: [traceId]});

const traceMatches = (filterText, trace) => trace.get("category", "").indexOf(filterText) !== -1;

const findPathsToMatchingTraces = (filterText, trace, pathToCurrentTrace = Immutable.List()) => {
  let paths;
  if (traceMatches(filterText, trace)) {
    paths = Immutable.Set.of(pathToCurrentTrace.push(trace.get("id")));
  } else {
    paths = Immutable.Set();
  }

  const pathsForChildren = trace
      .get("childTraces", Immutable.List())
      .flatMap(childTrace => findPathsToMatchingTraces(filterText, childTrace, pathToCurrentTrace.push(trace.get("id"))));

  return paths.union(pathsForChildren);
};

const TraceViewer = betterMemo(
    {displayName: "TraceViewer"},
    ({traceId}) => {
      const [openTraceIds, setOpenTraceIds] = React.useState(Immutable.Set());
      const [rootTrace, setRootTrace] = React.useState(Immutable.Map());
      const [isLoading, setIsLoading] = React.useState(true);
      const [error, setError] = React.useState("");

      const [filterText, setFilterText] = React.useState("");
      const { theme } = React.useContext(CustomThemeContext);

      React.useEffect(
          () => {
            if (filterText) {
              const paths = findPathsToMatchingTraces(filterText, rootTrace);
              setOpenTraceIds(paths.reduce((tids, path) => tids.union(path), Immutable.Set()));
            }
          },
          [rootTrace, filterText]);

      const parseTraceFromServer = traceJS => {
        return Immutable
            .fromJS(traceJS[0])
            .updateIn(["data"], d => Immutable.fromJS(JSON.parse(d)))
            .updateIn(["childTraces"], cts => Immutable.fromJS(JSON.parse(cts)));
      };

      React.useEffect(() => {
        setIsLoading(true);
        const traceDataPromise = loadTrace(traceId);

        traceDataPromise.then(
            trace => {
              if (trace.length > 0) {
                setRootTrace(parseTraceFromServer(trace));
                setError("");
              } else {
                setError("No matching trace found for id " + traceId);
              }
              setIsLoading(false);
            },
            e => {
              setError("Error whilst loading trace with id " + traceId + ": " + e);
              setIsLoading(false);
            });
      }, [traceId]);

      const onClickExpandTrace = (traceId) => {
        setOpenTraceIds(openTraceIds.add(traceId));
      };

      const onClickMinimiseTrace = (traceId) => {
        setOpenTraceIds(openTraceIds.remove(traceId));
      };

      if (error) {
        return <div>{error}</div>;
      } else if (isLoading) {
        return <LoadingSpinner />;
      } else {
        return <>
          <TextField variant="standard" style={{margin: "1rem"}}
                     label="Search by category"
                     value={filterText}
                     onChange={e => setFilterText(e.target.value)} />
          <TraceSection
              theme={theme}
              traces={Immutable.List.of(rootTrace)}
              onClickExpandTrace={onClickExpandTrace}
              onClickMinimiseTrace={onClickMinimiseTrace}
              openTraceIds={openTraceIds} />
        </>;
      }
    });

const TraceHeader = ({theme, trace, onClickExpandTrace, onClickMinimiseTrace, openTraceIds}) => {
  const open = openTraceIds.includes(trace.get("id"));
  const isExpandable = !trace.get("childTraces", Immutable.List()).isEmpty() ||
      !trace.get("data", Immutable.Map()).isEmpty();

  let headerDivStyle = {
    padding: "0.4rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    display: "flex",
    color: theme.palette.textColor
  };

  let onHeaderClick;
  if (isExpandable) {
    if (open) {
      onHeaderClick = () => onClickMinimiseTrace(trace.get("id"));
      headerDivStyle = {...headerDivStyle, cursor: "pointer", backgroundColor: theme.themeId === "light" ? "#c3c3c3" : "#1c1c23"};
    } else {
      onHeaderClick = () => onClickExpandTrace(trace.get("id"));
      headerDivStyle = {...headerDivStyle, cursor: "pointer", backgroundColor: theme.themeId === "light" ? "#e5e5e5" : "#272731"};
    }
  }

  return (
      <div
          onClick={onHeaderClick}
          style={headerDivStyle}
          css={isExpandable ? css`:hover {
            background-color: ${theme.themeId === "light" ? theme.palette.background.paper : "#111"} !important;
          }` : null}
      >
        <div>{trace.get("category")}</div>
        <div style={{marginLeft: "auto", marginRight: 0}}>{trace.get("durationMillis") + " ms"}</div>
      </div>
  );
};

const TraceSection = ({theme, traces, onClickExpandTrace, onClickMinimiseTrace, openTraceIds}) => {
  return (
      <div>
        {traces.map(trace => {
          const childTraces = trace.get("childTraces", Immutable.List());
          const hasChildren = !childTraces.isEmpty();

          const traceData = trace.get("data", Immutable.Map());
          const hasData = !traceData.isEmpty();

          return (
              <Card key={trace.get("id")} style={{lineHeight: 1.6, backgroundColor: theme.themeId === "light" ? "#e5e5e5" : "#32323f", color: theme.palette.textColor, border: "1px solid #464646"}} variant="outlined">
                <CardContent style={{padding: 0}}>
                  <TraceHeader
                      theme={theme}
                      trace={trace}
                      onClickExpandTrace={onClickExpandTrace}
                      onClickMinimiseTrace={onClickMinimiseTrace}
                      openTraceIds={openTraceIds} />
                  <Collapse
                      in={openTraceIds.includes(trace.get("id")) && (hasChildren || hasData)}
                      timeout={300}
                      unmountOnExit
                      style={{marginLeft: "20px"}}>
                    <div style={{padding: 10, paddingRight: 0}}>
                      <TraceDataContent theme={theme} traceData={traceData} />
                      {hasChildren && <div>
                        <TraceSection
                            theme={theme}
                            traces={childTraces}
                            onClickExpandTrace={onClickExpandTrace}
                            onClickMinimiseTrace={onClickMinimiseTrace}
                            openTraceIds={openTraceIds} />
                      </div>}
                    </div>
                  </Collapse>
                </CardContent>
              </Card>
          );
        })}
      </div>
  );
};

const TraceDataContent = ({traceData, theme}) => {
  const [openDataKeys, setOpenDataKeys] = React.useState(Immutable.Set());

  const cutoffLength = 200;
  const prettifyLength = 120;

  return (
      <div style={{padding: "5px"}}>
        {traceData.entrySeq().map(([key, value]) => {
              let customRender;
              let formattedValue;
              if (Immutable.isMap(value) || Immutable.isList(value)) {
                if (value.count() === 2 && value.has("rows") && value.has("columns")) {
                  formattedValue = value;
                  customRender = "table";
                } else {
                  formattedValue = JSON.stringify(value.toJS(), null, 2);
                }
              } else {
                formattedValue = String(value);
              }

              const showAllData = openDataKeys.includes(key);

              let valueEl;
              if (customRender === "table") {
                const columns = value
                    .get("columns")
                    .map(c => {
                      if (c.indexOf(" AS ") === -1) {
                        return c;
                      } else {
                        return c.split(" AS ")[1];
                      }
                    })
                    .toJS();
                const rows = value.get("rows").toJS();
                valueEl = <SimpleDataTable columns={columns} rows={rows} />;
              } else if (formattedValue.length > cutoffLength) {
                const arrowEl = <>
                  {formattedValue.charAt(cutoffLength - 1) === "\n" ? null : <br />}
                  <ArrowDropDown />
                </>;
                valueEl = <pre css={css`
                  cursor: pointer;
                  color: ${theme.themeId === "light" ? "#000" : "#ddd"};

                  :hover {
                    color: ${theme.palette.textColor};
                  }`}>
                  {showAllData ? formattedValue : formattedValue.substring(0, cutoffLength)}
                  {!showAllData && arrowEl}
                </pre>;
              } else if (formattedValue.length > prettifyLength) {
                valueEl = <pre>{formattedValue}</pre>;
              } else {
                valueEl = <span>&nbsp;{formattedValue}</span>;
              }

              return (
                  <div
                      key={key}
                      onClick={() => {
                        if (showAllData) {
                          setOpenDataKeys(openDataKeys.remove(key));
                        } else {
                          setOpenDataKeys(openDataKeys.add(key));
                        }
                      }}>
                    <b>{key}</b>:
                    {valueEl}
                  </div>
              );
            }
        )}
      </div>
  );
};

export default TraceViewer;
