import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import moment from "moment";
import PureRenderMixin from "react-addons-pure-render-mixin";

import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";
import Layout from "js/common/views/foundation-column-layout";
import LoadingSpinner from "js/common/views/loading-spinner";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import JobErrorsTable from "js/admin/job-errors-table";
import * as Branding from "js/common/branding-constants";
import {capitaliseWords} from "js/common/utils/strings";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import * as Users from "js/common/users";
import * as Groups from "js/common/groups";

const TagRecalculationJobs = createReactClass({

  displayName: "TagRecalculationJobs",

  mixins: [PureRenderMixin],

  render() {
    return (
        <div>
          <AdminHeader>
            <Icon icon="category-tags" />
            Tag Recalculation Jobs
          </AdminHeader>
          <Layout allSmall={12}>
            <Hint style={{margin: "1rem"}}>
              <Icon icon="info" style={{color: this.props.theme.palette.hints.text}} />
              Click on a row in the table above to see further details about that Tag recalculation job, e.g. a detailed
              error report (if any errors are found).
            </Hint>
            <div style={{marginTop: 0, marginBottom: "2rem"}}>
              {this.props.isLoading ? <LoadingSpinner /> : this.renderTable()}
            </div>
            {this.props.selectedJobId && this.renderJobDetails()}
          </Layout>
        </div>
    );
  },

  renderTable() {
    const columns = ["ID", "Source", "Started by", "Started on", "Status"];
    const rows = this.props.jobs
        .map(jobToRow)
        .toJS();
    return (
        <SimpleDataTable
            columns={columns}
            rows={rows}
            onCellClick={this.handleJobRowClick}
            initialSortDirection="DESC" />
    );
  },

  handleJobRowClick(cellValue, row) {
    const jobId = row[0].originalValue;
    this.props.onJobSelect(jobId);
  },

  renderJobDetails() {
    const {jobs, selectedJobId} = this.props;
    const index = jobs.findIndex(j => j.get("id") === selectedJobId);
    const job = jobs.get(index);
    const jobId = job.get("id");
    if (job.has("errors") && !job.get("errors").isEmpty()) {
      return (
          <Layout allSmall={12} rowStyle={{marginTop: "1rem", marginBottom: "1rem"}}>
            <p>
              <i className="fa fa-exclamation-triangle" style={{color: "#f00", paddingRight: 6}} />
              The following errors were found in the file uploaded for job ID {selectedJobId}
            </p>
            <JobErrorsTable errors={job.get("errors")} />
          </Layout>
      );
    } else {
      const payload = job.get("payload");
      const groupIds = payload.get("groupIds") || Immutable.List();
      const userIds = payload.get("userIds") || Immutable.List();
      const status = job.get("status");
      const labelsByStatus = {
        PENDING: `is queued for processing`,
        PROCESSING: `is currently being processed`,
        FAILED: `was not processed. ${Branding.submitTicketString}`,
        COMPLETED: `was processed successfully`
      };
      const listStyle = {
        marginTop: "0.625rem",
        marginRight: "1.25rem",
        marginLeft: "1.25rem",
        marginBottom: "1.875rem"
      };
      return (
          <Layout allSmall={12} rowStyle={{marginTop: "1rem", marginBottom: "1rem"}}>
            <p>{`Job ID ${jobId} ${labelsByStatus[status]}`}</p>
            <div>
              <p>The following Groups and/or Users {`${status === "PENDING" ? "are" : "were"}`} affected:</p>
              <ol style={listStyle}>
                {groupIds.map(groupId => <li key={groupId}>{Groups.getGroupBreadcrumbsStr(groupId, ">")}</li>)}
                {userIds.map(userId => <li key={userId}>{getUserFullName(userId)}</li>)}
              </ol>
            </div>
          </Layout>
      );
    }
  }

});

const jobToRow = job => {
  const source = job.getIn(["payload", "source"]).replace(/_/g, " ");
  const startedBy = Users.getUser(job.get("userId")).get("fullName");
  const startedOn = timestampToDateTime(job.get("startTimestamp"));
  const lastStatusChange = timestampToDateTime(job.get("lastStatusChangeTimestamp"));
  const jobStatus = job.get("status");
  const status = `Moved to ${jobStatus} at ${lastStatusChange}`;
  return [
    job.get("id"),
    capitaliseWords(source),
    startedBy,
    startedOn,
    status
  ];
};

const timestampToDateTime = (timestamp, pattern = "YYYY-MM-DD HH:mm:ss") => {
  const dateTime = moment(timestamp).local();
  return dateTime.format(pattern);
};

const getUserFullName = userId => {
  const user = Users.getUser(userId);
  return user.get("fullName");
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <TagRecalculationJobs theme={theme} {...props} />;
};