import React from "react";
import Pluralize from "pluralize";

import DropdownToggle from "js/common/views/react-dropdown-toggle";
import {Checkbox} from "@mui/material";

import {betterMemo} from "js/common/utils/more-memo";

const StatePicker = betterMemo({displayName: "StatePicker"}, ({
  onChange
}) => {
  const [selectedStates, setSelectedStates] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  const onRequestClose = () => {
    setIsOpen(false);
    onChange(selectedStates);
  };

  const onToggle = () => {
    if (isOpen) {
      onRequestClose();
    } else {
      setIsOpen(true);
    }
  };

  const onStateClick = event => {
    const value = event.target.value;
    const states = selectedStates;
    if (states.includes(value)) {
      states.splice(states.indexOf(value), 1);
    } else {
      states.push(value);
    }
    setSelectedStates(states);
  };

  const stateItem = (state) => {
    return (
        <div style={{display: "block"}}>
          <Checkbox
              style={{padding: 5, margin: 0}}
              value={state}
              defaultChecked={selectedStates.includes(state)}
              onClick={(e) => onStateClick(e)} />
          {state}
        </div>
    );
  };

  const states = ["Full User", "Observer", "Orphan", "Full User (w/o login)"];

  const label = selectedStates.length === 0 ? "Filter by State" : `Filtering by ${selectedStates.length} ${Pluralize(
      "State",
      selectedStates.length,
      false)}`;

  return <div>
    <DropdownToggle
        primaryText={label}
        isOpen={isOpen}
        tooltipDisabled={true}
        onToggleButtonClick={onToggle}
        onRequestClose={onRequestClose}>
      {states.map(state => stateItem(state))}
    </DropdownToggle>
  </div>;
});

export default StatePicker;