import React from "react";

import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import ErrorMsg from "js/common/views/error";

export default ({
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange
}) => {
    const isStartDateAfterEndDate = startDate && startDate.isAfter(endDate);
    return (
        <div style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}>
            <label style={{ cursor: "default" }}>Replace data between</label>
            <div>
                <div style={{ display: "inline-block", width: 180 }}>
                    <DatePicker value={startDate} onDateChange={onStartDateChange} maxDate={endDate} />
                </div>
                <span style={{ fontSize: "0.875em", paddingLeft: "0.5rem", paddingRight: "0.5rem" }}>
                    to
                </span>
                <div style={{ display: "inline-block", width: 180 }}>
                    <DatePicker isDisabled={!startDate} value={endDate} onDateChange={onEndDateChange} minDate={startDate} />
                    {(startDate && !endDate) && <ErrorMsg text="End date required" />}
                </div>
                {isStartDateAfterEndDate && <ErrorMsg text="Start date cannot be after end date" />}
            </div>
        </div>
    );
};
