import React from "react";
import pure from "js/common/views/pure";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const SECTION_HEADER_STYLE = theme =>({
    width: "100%",
    borderTop: `2px solid ${theme.palette.primary.main}`,
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    padding: 15,
    marginTop: "1rem"
});
const SectionHeader = pure(({
    text,
    theme
}) => (
    <div style={SECTION_HEADER_STYLE(theme)}>
        <span style={{ color: theme.palette.primary.main, fontSize: 15 }}>{text}</span>
    </div>));

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <SectionHeader theme={theme} {...props} />;
}