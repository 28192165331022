import React from "react";
import ReactTooltip from "react-tooltip";
import pure from "js/common/views/pure";

import {getLabelForApp} from "js/admin/users/app-assignment-labels";
import Checkbox from "js/common/views/inputs/checkbox";

export default pure(({allowedPerms, appAssignments, onChange, landingPage, theme}) => {
  const landingPageIsAdmin = landingPage === "ADMIN";
  const singlePermission = appAssignments.filter(a => a !== "ADMIN").count() === 1;
  const singleAdminPermission = appAssignments.filter(a => a.includes("_ADMIN")).count() === 1;
  return (
      <div>
        <h3 style={{marginBottom: 5, color: theme.palette.primary.main, textTransform: "uppercase"}}>Admin Permissions</h3>
        {allowedPerms.map(app => {
          const hasPermission = appAssignments.has(app);
          let tooltip = "";
          let disabled = false;
          if (hasPermission) {
            if (singlePermission) {
              tooltip = lastPermissionWarning;
              disabled = true;
            } else if (singleAdminPermission && landingPageIsAdmin) {
              tooltip = landingPageWarning;
              disabled = true;
            }
          }
          return <div
              key={app}
              data-tip={!!tooltip}
              data-for={app}>
            <Checkbox
                label={getLabelForApp(app)}
                checked={hasPermission}
                style={{marginTop: "-12px", width: 210}}
                onCheck={(e, isInputChecked) => {
                  const hasAdminPermission = appAssignments.has("ADMIN");
                  let apps = isInputChecked ? appAssignments.add(app) : appAssignments.remove(app);
                  if (isInputChecked && !hasAdminPermission) {
                    apps = apps.add("ADMIN");
                  } else if (!isInputChecked && singleAdminPermission) {
                    apps = apps.remove("ADMIN");
                  }
                  onChange(apps);
                }}
                disabled={disabled} />
            {!!tooltip && <ReactTooltip id={app}>
              <span>{tooltip}</span>
            </ReactTooltip>}
          </div>;
        })}
      </div>);
});

const landingPageWarning = "Change the landing page to modify this permission";
const lastPermissionWarning = "Users must have at least one permission. To prevent activity please disable login or deactivate this user";
