import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";

import { format } from "js/common/utils/ratio-formatter";
import getKpiDataSafely from "js/onpoint/get-kpi-data-safely";

import * as Users from "js/common/users";
import * as Colors from "js/common/cube19-colors";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const scrollBarWidth = "17px";

const flexBoxWrapperStyle = {
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
    alignContent: "space-around"
};

const columnStyle = {
    padding: 0,
    marginTop: 0,
    marginRight: "0.25rem",
    marginBottom: 0,
    marginLeft: "0.25rem",
    ...flexBoxWrapperStyle
};

const labelStyle = {
    fontFamily: "Oxygen-light",
    lineHeight: "45px"
};

const RatioColumn = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const { usersKpiData, groupsKpiData } = this.props;
        const borderTopStyle = `1px solid ${Colors.grey}`;
        const userCellsContainerStyle = {
            marginTop: groupsKpiData.isEmpty() ? 0 : "0.5rem" ,
            borderTop: usersKpiData.isEmpty() ? 0 : borderTopStyle
        };
        return (
            <div style={{ ...columnStyle, borderTop: borderTopStyle }}>
                <div style={{ marginBottom: groupsKpiData.isEmpty() ? 0 : "0.5rem" }}>
                    {groupsKpiData.map( this.renderGroupDataCell)}
                </div>
                <div style={userCellsContainerStyle}>
                    {usersKpiData.map(this.renderUserDataCell)}
                </div>
            </div>
        );
    },

    renderGroupDataCell(groupKpiData) {
        const { ratio, onCellClick, cellHeight, width, theme } = this.props;
        const groupId = groupKpiData.get("groupId");
        const dataByKpiId = groupKpiData.get("dataByKpiId");
        const groupRatioData = Immutable.Map()
            .set("firstKpiData", dataByKpiId.get(ratio.get("firstKpiId")))
            .set("secondKpiData", dataByKpiId.get(ratio.get("secondKpiId")));
        const cell = Immutable.Map({
            groupId,
            ratio,
            data: groupRatioData
        });
        return (
            <RatioDataDisplay
                key={`group-${groupId}-ratio-${ratio.get("id")}`}
                displayType={ratio.get("displayType")}
                data={groupRatioData}
                onClick={() => onCellClick(cell)}
                cellHeight={cellHeight}
                width={width}
                theme={theme} />
        );
    },

    renderUserDataCell(userKpiData) {
        const { ratio, onCellClick, cellHeight, width, theme } = this.props;
        const userId = userKpiData.get("userId");
        const dataByKpiId = userKpiData.get("dataByKpiId");
        const userRatioData = Immutable.Map()
            .set("firstKpiData", dataByKpiId.get(ratio.get("firstKpiId")))
            .set("secondKpiData", dataByKpiId.get(ratio.get("secondKpiId")));
        const cell = Immutable.Map({
            userId,
            ratio,
            data: userRatioData
        });
        return (
            <RatioDataDisplay
                key={`user-${userId}-ratio-${ratio.get("id")}`}
                displayType={ratio.get("displayType")}
                data={userRatioData}
                onClick={() => onCellClick(cell)}
                cellHeight={cellHeight}
                width={width}
                theme={theme} />
        );
    }

});

const RatioDataDisplay = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const { data, onClick, cellHeight, width, theme } = this.props;
        const cellStyle = {
            height: cellHeight,
            width,
            padding: `0.25rem ${scrollBarWidth}`,
            textAlign: "center",
            backgroundColor: theme.palette.background.card,
            marginBottom: "5px",
            borderRadius: "5px",
            borderLeftColor: Colors.greyDark,
            borderRightColor: Colors.greyDark,
            borderBottomColor: Colors.greyDark
        };

        const hasData = !!data.get("firstKpiData") && !!data.get("secondKpiData");
        if (hasData) {
            const isLoadingData = data.some(item => item.get("isLoading"));
            const hasError = data.some(item => item.has("error"));
            const isClickable = Users.currentHasPermission("CLICK_THROUGH") && !isLoadingData && !hasError;
            const finalCellStyle = {
                ...cellStyle,
                color: isLoadingData ? "#999" : theme.palette.text.primary,
                cursor: isClickable ? "pointer" : "default"
            };
            return (
                <div style={finalCellStyle} onClick={isClickable ? e => onClick(e) : () => {}}>
                    {this.renderRatioDataDisplay()}
                </div>
            );
        } else {
            return <div style={{ ...cellStyle, ...labelStyle, color: "#999" }}> -- </div>;
        }
    },

    renderRatioDataDisplay() {
        const { data, displayType, theme } = this.props;
        if (data.some(item => item.get("isLoading"))) {
            return <div style={labelStyle}>LOADING...</div>;
        }

        if (data.some(item => item.has("error"))) {
            return <div style={labelStyle}>Error</div>;
        }

        const firstKpiTotal = getKpiDataSafely(data.get("firstKpiData"), "total");
        const secondKpiTotal = getKpiDataSafely(data.get("secondKpiData"), "total");
        return (
            <div style={{ ...labelStyle, textAlign: "right", fontFamily: theme.typography.fontFamilyBold, fontWeight: 600 }}>
                {format(displayType, firstKpiTotal.toJS(), secondKpiTotal.toJS())}
            </div>
        );
    }

});


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <RatioColumn theme={theme} {...props} />;
};
  
export default Wrapper;