/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import getContainerDimensions from "react-dimensions";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faAngleUp} from "@fortawesome/pro-regular-svg-icons";
import {Card} from "@mui/material";

import {Layout, Row} from "js/common/views/foundation-column-layout";
import Header from "js/oneview/section-header";
import KpiSummary from "js/oneview/kpi-sections/kpi-summary";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import pure from "js/common/views/pure.js";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {css} from "@emotion/react";


const KpiSections = getContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],
  displayName: "KpiSections",

  propTypes: {
    sectionConfigs: ImmutablePropTypes.list.isRequired,
    expandedSectionConfigIds: ImmutablePropTypes.set.isRequired,
    currentTimeframe: ReactPropTypes.object.isRequired,
    kpiDataById: ImmutablePropTypes.map.isRequired,
    onKpiSummaryClick: ReactPropTypes.func.isRequired,
    containerWidth: ReactPropTypes.number.isRequired,
    clientIds: ImmutablePropTypes.set,
    hasQuickView: ReactPropTypes.bool,
    onQuickViewClick: ReactPropTypes.func,
    onExpandToggleClick: ReactPropTypes.func
  },

  getDefaultProps() {
    return {
      hasQuickView: false
    };
  },

  render() {
    const {sectionConfigs, expandedSectionConfigIds, theme} = this.props;
    const {palette} = theme;
    return (
        <div style={{width: "98%", margin: "0 auto"}}>
          {sectionConfigs.map((config, index) => {
            // TODO remove filter when we remove kpiRepo
            //Workaround to prevent app crash when a section contains a kpi that hasn't been loaded
            const sectionIsExpanded = expandedSectionConfigIds.has(config.get("id"));
            return (
                <KpiSection
                    key={index}
                    config={config}
                    sectionIsExpanded={sectionIsExpanded}
                    onExpandToggleClick={this.props.onExpandToggleClick}
                    onKpiSummaryClick={this.props.onKpiSummaryClick}
                    onQuickViewClick={this.props.onQuickViewClick}
                    containerWidth={this.props.containerWidth}
                    kpiDataById={this.props.kpiDataById}
                    clientIds={this.props.clientIds}
                    currentGroupId={this.props.currentGroupId}
                    currentTimeframe={this.props.currentTimeframe}
                    hasQuickView={this.props.hasQuickView}
                    palette={palette}
                    theme={theme}
                />);
          })}
        </div>);
  }
}));

const KpiSection = pure(
    ({
      config,
      sectionIsExpanded,
      onExpandToggleClick,
      onKpiSummaryClick,
      onQuickViewClick,
      containerWidth,
      kpiDataById,
      clientIds,
      currentGroupId,
      currentTimeframe,
      hasQuickView,
      palette,
      theme
    }) => {
      const assignedKpis = config.get("assignedKpis")
          .filter(assignedKpi => !!kpiRepo.get(assignedKpi.get("kpiId")));
      let shownKpis = assignedKpis;
      let buttonIsRequired = shownKpis.count() > 6;
      if (buttonIsRequired && !sectionIsExpanded) {
        shownKpis = shownKpis
            .filter(k => k.get("minimisedOrder") !== null)
            .sortBy(k => k.get("minimisedOrder"));
      }
      const remainingKpisCount = assignedKpis.count() - shownKpis.count();
      const containerStyle = {
        width: "100%",
        borderRadius: "8px",
      };

      return <div className="row" style={{marginBottom: "2em", position: "relative", padding: "0 15px"}}>
        <Header label={config.get("name")} palette={palette} />
        <div style={sectionLayoutColumnStyle}>
          <div style={containerStyle}>
            <Card style={{background: palette.background.card, boxShadow: "0 -1px 3px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%)"
            }}>
              <VelocityTransitionGroup component="div"
                                       style={{marginTop: "-2px"}}
                                       enter="slideDown"
                                       leave="slideUp">
                {shownKpis.map((kpiSummary, index) => {
                  const kpiId = kpiSummary.get("kpiId");
                  return (
                      <KpiSummary
                          key={index}
                          hasQuickView={hasQuickView}
                          currentTimeframe={currentTimeframe}
                          currentGroupId={currentGroupId}
                          clientIds={clientIds}
                          isLastKpi={index === shownKpis.count() - 1}
                          kpiId={kpiId}
                          data={kpiDataById.get(kpiId)}
                          onClick={onKpiSummaryClick}
                          onQuickViewClick={onQuickViewClick}
                      />);
                })}
              </VelocityTransitionGroup>
            </Card>
          </div>

          {buttonIsRequired &&
          <SectionDrawerButton
              theme={theme}
              sectionIsExpanded={sectionIsExpanded}
              onExpandToggleClick={onExpandToggleClick}
              config={config}
              remainingKpisCount={remainingKpisCount}
              palette={palette} />}
        </div>
      </div>;
    },
    "KpiSection");

const SectionDrawerButton = pure(
    ({sectionIsExpanded, onExpandToggleClick, config, remainingKpisCount, palette, theme}) => {
      const expandSectionButtonStyle = css`
        cursor: pointer;
        display: flex;
        justify-content: center;
        border-radius: 0 0 5px 5px;
        position: absolute;
        bottom: -46px;
        font-size: 12px;
        padding: 8px 24px 6px 24px;
        margin-top: 0;
        font-family: inherit;
        color: ${palette.background.card};
        text-transform: uppercase;
        background: ${palette.primary.main};

        &:hover {
          background: ${theme.themeId === "light" ? palette.background.card : palette.background.light};
          color: ${palette.primary.main}
        }
      `;
      return (
          <button
              css={expandSectionButtonStyle}
              className="TESTCAFE_section_button"
              onClick={() => onExpandToggleClick(config)}>
            {sectionIsExpanded
                ? <FontAwesomeIcon icon={faAngleUp} size={"lg"} />
                : <span>
                    {remainingKpisCount} more
                    <FontAwesomeIcon icon={faAngleDown} size={"lg"} css={css({marginLeft: "0.3rem"})} />
                  </span>}
          </button>);
    },
    "SectionDrawerButton");

const sectionLayoutColumnStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <KpiSections theme={theme} {...props} />;
};

export default Wrapper;
