import * as ajax from "js/common/ajax";
import * as auth from "js/common/auth";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as ratioRepo from "js/common/repo/ratio-repo";
import * as ratioFormatter from "js/common/utils/ratio-formatter";
import * as reportRepo from "js/common/repo/report-repo";
import * as tagRepo from "js/common/repo/backbone/tag-repo";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as events from "js/common/events";
import client from "js/common/repo/backbone/current-client";
import * as currencyRepo from "js/common/repo/backbone/currency-repo";
import eventBus from "js/cube19.event-bus";

export default function() {
    window.c19 = {
        ajax,
        auth,
        kpiRepo,
        ratioRepo,
        ratioFormatter,
        reportRepo,
        tagRepo,
        timeframeRepo,
        currencyRepo,
        events,
        eventBus,
        client
    };
}
