import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';

import { TextField } from '@mui/material';
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const PlaceHolderTextField = createReactClass({

    propTypes: {
        value: ReactPropTypes.string.isRequired,
        onChange: ReactPropTypes.func.isRequired,
        className: ReactPropTypes.string,
        label: ReactPropTypes.string,
        hintText: ReactPropTypes.string,
        errorText: ReactPropTypes.string,
        maxLength: ReactPropTypes.number,
        inputStyle: ReactPropTypes.object,
        fullWidth: ReactPropTypes.bool,
        disabled: ReactPropTypes.bool,
        type: ReactPropTypes.string
    },

    getInitialState() {
        return {
            value: this.props.value
        };
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            value: nextProps.value
        });
    },

    render() {
        const { value } = this.state;
        const {
            disabled,
            inputStyle,
            fullWidth,
            className,
            label,
            hintText,
            errorText,
            maxLength,
            type
        } = this.props;
        return (
            <div>
                {/* this is a stopgap to fix a known issue with React and/or Material-UI for IE11
                    https://github.com/facebook/react/issues/6822 */}
                <style type="text/css" dangerouslySetInnerHTML={{__html: "::-ms-clear {display: none;}"}} />
                <TextField variant="standard"
                    disabled={disabled}
                    fullWidth={fullWidth}
                    className={className}
                    label={label}
                    type={type}
                    placeholder={hintText}
                    error={!!errorText}
                    helperText={errorText}
                    maxLength={maxLength}
                    value={value}
                    margin="normal"
                    variant="standard"
                    size="medium"
                    style={{width: 256, ...inputStyle}}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange} />
            </div>);
    },

    handleChange(e) {
        this.setState({
            hasChanged: true,
            value: e.target.value
        });
    },

    handleBlur() {
        const { hasChanged, value } = this.state;
        const { maxLength, onChange } = this.props;
        if (hasChanged) {
            this.setState({
                hasChanged: false
            });
            const changedValue = maxLength ? value.substring(0,maxLength) : value;
            onChange(changedValue);
        }
    }

});

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <PlaceHolderTextField theme={theme} {...props} />;
};
