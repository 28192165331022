import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as numbers from "js/common/utils/numbers";
import * as Formatter from "js/common/utils/formatter";
import * as Users from "js/common/users";
import * as Colors from "js/common/cube19-colors";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const rowsPerPage = 10;

const NewLeaderboard = GetContainerDimensions()(createReactClass({

    mixins: [PureRenderMixin],

    getInitialState() {
        return {
            displayPercentOfTarget: false
        };
    },

    componentDidMount() {
        if (this.props.displayTotalAndPercentOfTarget) {
            this.displayPercentOfTargetIntervalId = window.setInterval(() => {
                this.setState({
                    displayPercentOfTarget: !this.state.displayPercentOfTarget
                });
            }, 4500);
        }
    },

    componentWillUnmount() {
        window.clearInterval(this.displayPercentOfTargetIntervalId);
    },

    render() {
        return (
            <div style={{ height: "100%" }}>
                {this.renderLeaderboardHeaderRow()}
                {this.renderLeaderboardDataRows()}
            </div>
        );
    },

    renderLeaderboardHeaderRow() {
        const { rankedKpiColumnHeading, otherKpiColumnHeading, otherKpiId, theme } = this.props;
        const headerRowStyle = theme => ({
            ...headerTextStyle(theme),
            height: "6vh",
            display: "flex",
            paddingBottom: 5
        });
        const headerCellStyle = {
            ...cellStyle,
            alignSelf: "flex-end",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap"
        };
        const rankedKpiColWidth = otherKpiId ? "15vw" : "25vw";
        return (
            <div style={headerRowStyle(theme)}>
                <div style={{ ...headerCellStyle, width: otherKpiId ? "10vw" : "12vw" }}>
                    Rank
                </div>
                <div style={{ ...headerCellStyle, textAlign: "left", width: otherKpiId ? "30vw" : "60vw" }}>
                    Name
                </div>
                {otherKpiId &&
                    <div style={{ ...headerCellStyle, width: "15vw" }}>
                        {otherKpiColumnHeading}
                    </div>}
                <div style={{ ...headerCellStyle, width: rankedKpiColWidth }}>
                    {rankedKpiColumnHeading}
                </div>
            </div>
        );
    },

    renderLeaderboardDataRows() {
        const {
            rankedKpiId,
            otherKpiId,
            pageRows,
            currentTopPerformerIndex,
            highlightCurrentTopPerformerRow,
            theme,
        } = this.props;

        const totalVisiblePageRows = pageRows.count();
        const rankedKpiConfig = kpiRepo.get(rankedKpiId);
        const rankedKpiValueFormat = rankedKpiConfig.get("type").valueFormat;
        const otherKpiConfig = kpiRepo.get(otherKpiId);
        const otherKpiValueFormat = otherKpiConfig ? otherKpiConfig.get("type").valueFormat : null;
        const maxCharLength = this.props.containerWidth < 800 && otherKpiConfig ? 6 : 10;
        const rowsContainerStyle = {
            display: "flex",
            flexDirection: "column",
            height: `calc(100% - ${pageRows.count() === 9 ? "13vh" : "6vh"})`,
            borderTop: `3px solid ${Colors.c19Yellow}`,
            paddingTop: "0.5vh",
            ...textStyle(theme)
        };
        const dataCellStyle = {
            ...cellStyle,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "2.25vw",
            lineHeight: 1.5
        };
        return (
            <div style={rowsContainerStyle}>
                {pageRows.map((row, index) => {
                    const userId = row.get("userId");
                    const user = Users.getUser(userId);
                    const isRowHighlighted = highlightCurrentTopPerformerRow && currentTopPerformerIndex === index;
                    const rowStyle = {
                        display: "flex",
                        flexDirection: "row",
                        flexGrow: totalVisiblePageRows === rowsPerPage ? 1 : null,
                        flexShrink: 1,
                        overflow: "hidden",
                        alignItems: "center",
                        justifyContent: "space-around",
                        border: isRowHighlighted ? 0: "3px solid #B9B9B9",
                        background: isRowHighlighted ? "#f9ec33" : "rgba(0, 28, 138, 0.75)",
                        color: isRowHighlighted ? "#000" : "#fff",
                        textShadow: isRowHighlighted ? "none" : "2px 2px 8px #000",
                        marginTop: "0.5vh",
                        marginBottom: "0.5vh"
                    };
                    return (
                        <div key={userId} style={rowStyle}>
                            <div style={{...dataCellStyle, width: otherKpiId ? "10vw" : "12vw"}}>
                                {row.get("rank")}
                            </div>
                            <div style={{...dataCellStyle, textAlign: "left", width: otherKpiId ? "30vw" : "60vw"}}>
                                {user.get("fullName")}
                            </div>
                            {otherKpiId &&
                                <div style={{...dataCellStyle, width: "15vw"}}>
                                    {this.getOtherKpiDisplayValue(row.get("otherKpi"), otherKpiValueFormat, maxCharLength)}
                                </div>}
                            <div style={{...dataCellStyle, width: otherKpiId ? "15vw" : "25vw" }}>
                                {this.getRankedKpiDisplayValue(row.get("rankedKpi"), rankedKpiValueFormat, maxCharLength)}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    },

    getRankedKpiDisplayValue(rankedKpi, rankedKpiValueFormat, maxCharLength) {
        const rankedKpiTotal = rankedKpi.get("total");
        if (this.state.displayPercentOfTarget) {
            const rankedKpiTarget = rankedKpi.get("target");
            const rankedKpiHasTarget = rankedKpiTarget.get("value") > 0;
            if (rankedKpiHasTarget) {
                return numbers.toPercentageStr(rankedKpiTotal.get("value") / rankedKpiTarget.get("value"));
            } else {
                return "0%";
            }
        } else {
            return Formatter.format(rankedKpiTotal.toJS(), {valueFormat: rankedKpiValueFormat, maxDisplayLength: maxCharLength});
        }
    },

    getOtherKpiDisplayValue(otherKpi, otherKpiValueFormat, maxCharLength) {
        const otherKpiTotal = otherKpi.get("total");
        if (this.state.displayPercentOfTarget) {
            const otherKpiTarget = otherKpi.get("target");
            const otherKpiHasTarget = otherKpiTarget.get("value") > 0;
            if (otherKpiHasTarget) {
                return numbers.toPercentageStr(otherKpiTotal.get("value") / otherKpiTarget.get("value"));
            } else {
                return "No Target";
            }
        } else {
            return Formatter.format(otherKpiTotal.toJS(), {valueFormat: otherKpiValueFormat, maxDisplayLength: maxCharLength});
        }
    }

}));

const cellStyle = {
    textAlign: "center",
    paddingLeft: "1.5vmin",
    paddingRight: "1.5vmin"
};

const textStyle = theme => ({
    fontFamily: theme.typography.fontFamilyBold,
    fontSize: "2vw",
    color: Colors.white,
    textShadow: "2px 2px 8px #000",
    textRendering: "optimizeLegibility"
});

const headerTextStyle = theme => ({
    ...textStyle(theme),
    fontSize: "1.5vw",
    color: Colors.c19Yellow,
    textTransform: "uppercase",
    lineHeight: 1
});

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <NewLeaderboard theme={theme} {...props} />;
};
