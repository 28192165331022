import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import * as Immutable from "immutable";

import {Column, Row} from "js/common/views/foundation-column-layout";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import Drawer from "js/common/views/drawer";
import Sortable from "react-sortable-mixin";

import SaveAsDialog from "js/charting/save-dialog";
import ChartContainer from "js/charting/chart-container";
import ChartConfig from "js/charting/trend/chart-config";

import * as charting from "js/charting/charting-defaults";
import * as popups from "js/common/popups";
import * as auditor from "js/common/auditer";
import {getUniqueName} from "js/common/utils/unique-naming";
import {targetInheritsValueColour} from "js/charting/trend/target-trend-color-util";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as savedConfigs from "js/common/saved-configs";
import * as Branding from "js/common/branding-constants";

const _ = window._;

const ChartingPage = createReactClass({
  mixins: [Sortable.ListMixin],

  propTypes: {
    renderId: ReactPropTypes.number.isRequired,
    isSmallScreen: ReactPropTypes.bool.isRequired,
    pageId: ReactPropTypes.number,
    initialChartConfig: ReactPropTypes.object,
    containerWidth: ReactPropTypes.number,
    savedPages: ImmutablePropTypes.map.isRequired,
    onUpdateSavedPages: ReactPropTypes.func
  },

  getInitialState() {
    const {pageId, savedPages, initialChartConfig} = this.props;

    return {
      currentPage: getPageOrDefault(pageId, savedPages, initialChartConfig),
      currentlyEditingChartConfig: initialChartConfig,
      dataByChartId: {},
      errorByChartId: {},
      isSavePageAsDialogOpen: false
    };
  },

  componentDidMount() {
    this.reloadCurrentPage();
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.renderId !== this.props.renderId && nextProps.pageId) {
      const page = getPageOrDefault(nextProps.pageId, nextProps.savedPages);
      this.setState({
        currentPage: page
      });
      const pageId = page.get("id");
      if (pageId) {
        const advancedTrendCharts = page
            .get("charts")
            .filter(c => c.get("type") === "TREND" && c.get("trendType") === "ADVANCED");
        const options = {
          id: pageId,
          name: page.get("name"),
          hasAdvancedTrends: !advancedTrendCharts.isEmpty()
        };
        auditor.audit("charting:saved-page-loaded", options);
      }
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (this.props.theme.themeId !== prevProps.theme.themeId) {
      this.reloadCurrentPage();
    }
    if (prevState.currentPage.get("id") !== this.state.currentPage.get("id")) {
      this.reloadCurrentPage();
    }
  },

  getItems() {
    return this.state.currentPage.get("charts").toArray();
  },

  onResorted(charts) {
    this.setState({
      currentPage: this.state.currentPage.set("charts", Immutable.List(charts))
    });
  },

  render() {
    const {theme} = this.props;
    const {currentPage, isSavePageAsDialogOpen, currentlyEditingChartConfig} = this.state;
    const isChartConfigEditorOpen = !!currentlyEditingChartConfig;
    return (
        <div>
          {this.renderToolbar()}
          <div
              style={{
                borderTop: `1px solid ${theme.palette.border.main}`,
                borderBottom: `1px solid ${theme.palette.border.main}`,
                padding: "10px 20px",
                fontSize: "0.875rem",
                color: theme.palette.primary.main
              }}>
            {currentPage.get("name")}
          </div>
          <div
              className="row" style={{
            maxWidth: "100%",
            padding: "0.5rem",
            backgroundColor: theme.palette.background.paper,
            display: "flex",
            flexWrap: "wrap",
            marginBottom: "60px"
          }}>
            {this.getItems().map(this.configToChart)}
          </div>

          <Drawer
              open={isChartConfigEditorOpen}
              onRequestOpen={this.openChartConfigEditor}
              onRequestClose={this.closeChartConfigEditor}
              openRight={true}
              showCloseButton={true}
              wideWidth={true}>
            {isChartConfigEditorOpen &&
                <ChartConfig
                    isSmallScreen={this.props.isSmallScreen}
                    initialConfig={currentlyEditingChartConfig}
                    onPlotChartClick={this.plotChart} />}
          </Drawer>

          <SaveAsDialog
              isOpen={isSavePageAsDialogOpen}
              onSaveClick={this.saveCurrentPageAs}
              onCancelClick={this.closeSavePageAsDialog}
              currentPageName={currentPage.get("name")} />
        </div>);
  },

  renderToolbar() {
    const {currentPage} = this.state;
    const {isSmallScreen, savedPages, theme} = this.props;
    const Button = isSmallScreen ? IconButton : TextButton;
    const buttonStyle = {marginLeft: "0.25rem", marginRight: "0.25rem"};
    const currentChartTheme = this.state.currentPage.get("charts").first().get("chartTheme");
    return (
        <Row style={{paddingTop: "1rem", paddingBottom: "1rem", maxWidth: "100%"}}>
          <Column small={12} smallCentered={isSmallScreen} medium={6}>
            <Button
                label="New Page"
                icon={isSmallScreen ? "file-o" : ""}
                type="primary"
                onClick={this.handleNewPageClick}
                style={buttonStyle}
                labelStyle={isSmallScreen ? {flexDirection: "column"} : {}} />
            <Button
                label="Save Page As"
                icon={isSmallScreen ? "files-o" : ""}
                type="default"
                onClick={this.handleSavePageAsClick}
                style={buttonStyle}
                labelStyle={isSmallScreen ? {flexDirection: "column"} : {}} />
            <Button
                label="Save Page"
                type="default"
                icon={isSmallScreen ? "floppy-o" : ""}
                disabled={!currentPage.get("id") || !findPage(currentPage.get("id"), savedPages)}
                onClick={this.handleSaveCurrentPageClick}
                style={buttonStyle}
                labelStyle={isSmallScreen ? {flexDirection: "column"} : {}} />
          </Column>
          <Column
              small={12}
              medium={6}
              smallCentered={isSmallScreen}
              className={isSmallScreen ? "" : "text-right"}
              style={{
                paddingTop: isSmallScreen ? "1rem" : 0,
                textAlign: isSmallScreen ? "left" : "right"
              }}>
            <TextButton
                type="primary"
                icon="plus" label="Add Trend Chart"
                style={buttonStyle}
                onClick={() => this.addBlankChart("TREND")} />
          </Column>
        </Row>);
  },

  configToChart(config, index) {
    const chartId = config.get("cid");
    const data = this.state.dataByChartId[chartId] || {};
    const error = this.state.errorByChartId[chartId] || null;
    const forThisChart = fn => _(fn).partial(chartId);
    const removeChart = forThisChart(this.removeChart);
    const ViewClass = charting.getViewClassForChartType(config.get("type"));
    return (
        <ChartContainer
            index={index}
            handle=".drag-handle"
            {...this.movableProps}
            key={chartId}
            config={config.toJS()}
            onConfigChange={this.handleChartContainerChange}
            data={data}
            error={error}
            onRemoveChartClick={removeChart}
            onEditChartConfigClick={() => this.openChartConfigEditor(config)}
            onCloneChartClick={this.handleCloneChartClick}
            containerWidth={this.props.containerWidth}>
          <ViewClass
              config={config.toJS()}
              onConfigChange={this.handleChartConfigChange}
              data={data}
              onEditChartConfigClick={() => this.openChartConfigEditor(config)}
              onSavedPagesMenuClick={this.props.onSavedPagesMenuClick} />
        </ChartContainer>);
  },

  plotChart(config) {
    auditor.audit("charting:run-custom-page", {});
    const chartId = config.get("cid");
    const {currentPage} = this.state;
    const index = currentPage.get("charts").findIndex(c => c.get("cid") === chartId);
    this.setState({
      currentPage: currentPage.setIn(["charts", index], config)
    }, () => {
      this.closeChartConfigEditor();
      const {initialData, promiseForFinalData} = charting.fullReloadForChart(config.toJS());
      this.applyReloadChangesToChart(chartId, initialData, promiseForFinalData);
    });
  },

  openChartConfigEditor(config) {
    const updatedConfig = config.get("isExampleChart")
        ? config.set("isExampleChart", false)
        : config;
    this.setState({
      currentlyEditingChartConfig: updatedConfig
    });
  },

  closeChartConfigEditor() {
    this.setState({
      currentlyEditingChartConfig: null
    });
  },

  switchChartTheme(theme) {
    const {currentPage} = this.state;
    const newCharts = currentPage.get("charts").map(config => {
      const newChartTheme = config.get("chartTheme") === "DEFAULT" ? "WHITE" : "DEFAULT";
      //const newChartTheme = theme.themeId === "light" ? "DEFAULT" : "WHITE";
      let dataGroups = config.get("dataGroups");
      if (config.get("trendType") === "ADVANCED") {
        dataGroups = parseDataGroups(dataGroups, newChartTheme);
      }
      const newConfig = config
          .set("chartTheme", newChartTheme)
          .set("dataGroups", dataGroups);
      this.handleChartConfigChange(config.get("cid"), newConfig);
      return newConfig;
    });
    this.setState({
      currentPage: currentPage.set("charts", newCharts)
    });
  },

  reloadCurrentPage() {
    this.state.currentPage.get("charts").map(config => {
      const {initialData, promiseForFinalData} = charting.fullReloadForChart(config.toJS());
      this.applyReloadChangesToChart(config.get("cid"), initialData, promiseForFinalData);
    });
  },

  handleNewPageClick() {
    this.setState({
      currentPage: parsePage(charting.getDefaultPage()),
      currentlyEditingChartConfig: null
    });
  },

  handleSaveCurrentPageClick() {
    this.saveCurrentPageChanges();
  },

  handleSavePageAsClick() {
    this.openSavePageAsDialog();
  },

  openSavePageAsDialog() {
    this.setState({
      isSavePageAsDialogOpen: true
    });
  },

  closeSavePageAsDialog() {
    this.setState({
      isSavePageAsDialogOpen: false
    });
  },

  saveCurrentPageChanges() {
    const {currentPage} = this.state;
    const {savedPages} = this.props;
    const existingSavedChartPage = findPage(currentPage.get("id"), savedPages);
    if (existingSavedChartPage) {
      const id = currentPage.get("id");
      const name = currentPage.get("name");
      savedConfigs
          .update(Immutable.fromJS({configType: "CHART_PAGE", id, name, json: currentPage.get("charts")}))
          .then(updatedPage => {
            const index = savedPages.get("ownedConfigs").findIndex(ownedConfig => ownedConfig.get("id") === id);
            this.props.onUpdateSavedPages(savedPages.setIn(["ownedConfigs", index], updatedPage));
            popups.success(`'<strong>${name}</strong>' updated`);
            auditor.audit("charting:save", {
              isSaveAs: false,
              pageId: currentPage.get("id"),
              pageName: currentPage.get("name")
            });
          }, err => {
            popups.contactSupport();
          });
    } else {
      console.log("Should never be reached since save button should be disabled");
    }
    this.closeSavePageAsDialog();
  },

  saveCurrentPageAs(pageName) {
    const {currentPage} = this.state;
    const {savedPages} = this.props;

    // Overwrite existing page
    if (currentPage.get("id") && (savedPages && savedPages.get("name") === pageName)) {
      this.saveCurrentPageChanges();
    } else {
      this.createNewSavedPage(pageName);
    }
    this.closeSavePageAsDialog();
  },

  createNewSavedPage(pageName) {
    const {currentPage} = this.state;
    const {savedPages} = this.props;

    savedConfigs
        .create(Immutable.fromJS({configType: "CHART_PAGE", name: pageName, json: currentPage.get("charts")}))
        .then(newPage => {
          let page = newPage.delete("configType");
          page = page.set("charts", newPage.get("json")).delete("json");
          page = page.set("createdByUserId", newPage.get("ownerId")).delete("ownerId");
          this.props.onUpdateSavedPages(savedPages.update("ownedConfigs", ownedConfigs => ownedConfigs.push(newPage)));
          this.setState({
            savedPages: savedPages.update("ownedConfigs", ownedConfigs => ownedConfigs.push(newPage)),
            currentPage: page
          });
          popups.success(`'<strong>${pageName}</strong>' saved`);
          auditor.audit("charting:save-as", {pageName});
        }, err => {
          popups.contactSupport();
        });
  },

  handleCloneChartClick(config) {
    const {currentPage} = this.state;
    const listOfCurrentNames = currentPage.get("charts").map(c => c.get("name"));
    const clonedChartName = getUniqueName(config.name, listOfCurrentNames.toJS());
    const clonedChart = Immutable.fromJS(config)
        .set("cid", Math.random())
        .set("widthScale", this.getNewChartWidthScale())
        .set("name", clonedChartName);
    const newCharts = currentPage.get("charts").push(clonedChart);
    this.setState({
      currentPage: currentPage.set("charts", newCharts)
    }, () => {
      this.plotChart(clonedChart);
      popups.success(`${clonedChartName} added <i class='fa fa-arrow-down' />`);
    });
  },

  addBlankChart(type) {
    const newChartName = this.getDefaultNewChartName();
    const newChartWidthScale = this.getNewChartWidthScale();
    const chartTheme = this.state.currentPage.get("charts").first().get("chartTheme");
    const newChart = Immutable.fromJS(charting.getDefaultConfigForChartType(type, newChartName, newChartWidthScale))
        .merge({chartTheme});
    const {currentPage} = this.state;
    const newCharts = currentPage.get("charts").push(newChart);
    this.setState({
      currentPage: currentPage.set("charts", newCharts)
    }, () => {
      popups.success(`${newChartName} added <i class='fa fa-arrow-down' />`);
    });
  },

  getNewChartWidthScale() {
    const charts = this.state.currentPage.get("charts");
    const last2Charts = charts.takeLast(2);
    if (charts.count() > 1
        && last2Charts.getIn([0, "widthScale"]) === 0.5
        && last2Charts.getIn([1, "widthScale"]) === 0.5) {
      return 1;
    }
    return charts.last().get("widthScale");
  },

  getDefaultNewChartName() {
    const chartNames = this.state.currentPage.get("charts").map(c => c.get("name"));
    const numbersInUse = chartNames.map(name => {
      return name.match(/Trend Chart /) ? parseInt(name.match(/\d+/), 10) : 0;
    });
    const nextNumber = _.max(numbersInUse.toArray()) + 1;
    return `Trend Chart ${nextNumber}`;
  },

  removeChart(chartId) {
    const {currentPage} = this.state;
    const charts = currentPage.get("charts");
    const index = charts.findIndex(c => c.get("cid") === chartId);
    let newCharts = charts.delete(index);
    if (newCharts.isEmpty()) {
      const newChart = Immutable.fromJS(charting.getExampleTrendChartConfig());
      newCharts = newCharts.push(newChart);
    }
    this.setState({
      currentPage: currentPage.set("charts", newCharts)
    });
  },

  handleChartContainerChange(chartId, config) {
    this.handleChartConfigChange(chartId, Immutable.fromJS(config));
  },

  handleChartConfigChange(chartId, newConfig) {
    const {currentPage} = this.state;
    const index = currentPage.get("charts").findIndex(c => c.get("cid") === chartId);
    this.setState({
      currentPage: currentPage.setIn(["charts", index], newConfig)
    }, () => {
      const {initialData, promiseForFinalData} = charting.fullReloadForChart(newConfig.toJS());
      this.applyReloadChangesToChart(chartId, initialData, promiseForFinalData);
    });
  },

  applyReloadChangesToChart(chartId, initialData, promiseForFinalData) {
    const {errorByChartId} = this.state;
    errorByChartId[chartId] = null;
    this.setState({
      errorByChartId
    });

    const setData = _(this.setDataForChart).partial(chartId);
    setData(initialData);
    promiseForFinalData.then(setData, error => this.setErrorForChart(chartId, error));
  },

  setDataForChart(chartId, chartData) {
    const dataByChartId = this.state.dataByChartId;
    dataByChartId[chartId] = chartData;
    this.setState({
      dataByChartId
    });
  },

  setErrorForChart(chartId, error) {
    const {errorByChartId, dataByChartId} = this.state;
    dataByChartId[chartId] = {
      isLoading: false,
      trends: []
    };
    const errorJSON = error.responseJSON;
    if (errorJSON && errorJSON.type === "DATA_VISIBILITY") {
      errorByChartId[chartId] =
          "You no longer have permission to view one or more of the intended Users/Groups. Please select another User or Group from the report picker. You can save a copy of this page to avoid seeing this message again.";
    } else if (errorJSON && errorJSON.type === "METRIC_NOT_FOUND") {
      errorByChartId[chartId] =
          "Some Metrics in this chart are no longer available, please edit the chart and remove them.";
    } else {
      errorByChartId[chartId] = `An unexpected error has occurred. ${Branding.submitTicketString}`;
    }
    this.setState({
      errorByChartId,
      dataByChartId
    });
  }
});

const parsePage = page => {
  const charts = page.get("charts");
  const parsedCharts = parseCharts(charts);
  return page
      .delete("isDefaultTheme")
      .set("charts", parsedCharts);
};
const parseCharts = charts => charts.map((config, index) => {
  let newConfig = config.delete("isConfigVisible");
  if (!config.has("chartTheme")) {
    newConfig = newConfig.set("chartTheme", "DEFAULT");
  }
  if (!config.has("dataAggregation")) {
    newConfig = newConfig.set("dataAggregation", "DAILY");
  }
  if (!config.has("name") || config.get("name").length === 0) {
    newConfig = newConfig.set("name", `Trend Chart ${index + 1}`);
  }
  if (config.get("trendType") === "ADVANCED") {
    const newDataGroups = parseDataGroups(newConfig.get("dataGroups"), newConfig.get("chartTheme"));
    newConfig = newConfig.set("dataGroups", newDataGroups);
  }
  if (config.get("dataAggregation") !== "DAILY") {
    const kpiIds = config.get("trendType") === "STANDARD" && config.get("kpiIds") ||
        config.get("trendType") === "ADVANCED" && config.get("dataGroups").flatMap(dg =>
            dg.get("trendConfigs").flatMap(tc => {
              if (tc.get("type") === "KPI") {
                return Immutable.List([tc.get("kpiId")]);
              } else {
                const ratio = tc.get("ratio");
                return Immutable.List([ratio.get("firstKpiId"), ratio.secondKpiId]);
              }
            }));
    const validKpis = kpiIds.map(kpiId => kpiRepo.get(kpiId)).filter(kpi => kpi);
    if (!validKpis.every(kpi => kpi.get("trendDataAggregable"))) {
      newConfig = newConfig.set("dataAggregation", "DAILY");
    }
  }
  return newConfig;
});
const parseDataGroups = (dataGroups, chartTheme) => {
  const newDataGroups = dataGroups.map((dg, i) => {
    if (dg.has("trendConfigs")) {
      return dg;
    }
    const newTrendConfigs = dg.get("kpiTrendConfigs").map(tc => {
      return tc
          .set("cid", Math.random())
          .set("dataGroupIndex", i)
          .set("type", "KPI")
          .set("kpiId", tc.get("kpiId"));
    });
    return dg
        .delete("kpiTrendConfigs")
        .set("trendConfigs", newTrendConfigs);
  });

  const trendConfigs = newDataGroups.flatMap(dg => dg.get("trendConfigs"));

  const chartHasCustomColors = newDataGroups
      .map(dg => dg.get("trendConfigs")
          .map(tc => tc.get("hasCustomColor")))
      .reduce((a, b) => { return a.concat(b); })
      .includes(true);
  const parsedTrendConfigs = targetInheritsValueColour(trendConfigs, chartTheme, chartHasCustomColors);

  let parsedDataGroups = newDataGroups;
  newDataGroups.forEach((dataGroup, index) => {
    const newTrendConfigs = dataGroup.get("trendConfigs").map(trendConfig => {
      const index = parsedTrendConfigs.findIndex(tc => tc.get("cid") === trendConfig.get("cid"));
      return parsedTrendConfigs.get(index);
    });
    parsedDataGroups = parsedDataGroups.set(index, dataGroup.set("trendConfigs", newTrendConfigs));
  });
  return parsedDataGroups;
};

const getPageOrDefault = (pageId, savedPages, initialChartConfig) => {
  let page;
  if (pageId) {
    page = findPage(pageId, savedPages);
    page = page.set("charts", page.get("json")).delete("json") || charting.getDefaultPage();

  } else if (initialChartConfig) {
    const newPage = charting.getDefaultPage();
    page = newPage.set("charts", Immutable.List.of(Immutable.fromJS(initialChartConfig)));

  } else {
    page = charting.getDefaultPage();
  }
  return parsePage(page);
};

const findPage = (pageId, savedPages) => {
  let page = savedPages.get("ownedConfigs").find(config => config.get("id") === pageId);
  if (!page) {
    page = savedPages.get("sharedGroupConfigs").find(config => config.get("id") === pageId);
  }
  if (!page) {
    page = savedPages.get("sharedUserConfigs").find(config => config.get("id") === pageId);
  }
  return page;
};


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ChartingPage theme={theme} {...props} />;
};

ChartingPage.displayName = "ChartingPage";
export default Wrapper;
