import React from "react";
import Layout from "js/common/views/foundation-column-layout";
import * as Branding from "js/common/branding-constants"

export default ({theme}) => (
    <Layout allSmall={12} allLarge={10} smallCentered={true} collapseRow={true} rowStyle={rowStyle}>
        <ol style={listStyle}>
            <li>Download your company&apos;s current Groups History and open the CSV file.</li>
            <li>
                Go to the User that needs to change teams and add a new row underneath putting in their User ID and name in the format required.
            </li>
            <li>Set the dates they were in that team so the data stays and does not move with the User.</li>
            <li>
                Repeat the process for every User who needs to be moved to a different Group, starting a new row underneath that User&apos;s existing Group history to make sure there are no gaps or overlapping dates in the User&apos;s start/end dates between Groups.
            </li>
            <li>Upload your new CSV file by selecting the file.</li>
        </ol>

        <p style={{ textAlign: "left" }}>The accepted data format for each column are as follows:</p>

        <table style={tableStyle}>
            <thead>
                <tr style={{ ...tableColHeadingStyle(theme), textAlign: "left" }}>
                    <th style={tableColHeadingStyle(theme)}>
                        Column
                    </th>
                    <th style={tableColHeadingStyle(theme)}>
                        User
                    </th>
                    <th style={tableColHeadingStyle(theme)}>
                        Group
                    </th>
                    <th style={tableColHeadingStyle(theme)}>
                        Start Date
                    </th>
                    <th style={tableColHeadingStyle(theme)}>
                        End Date <em>(optional)</em>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr style={tableRowStyle(theme)}>
                    <th style={tableRowHeadingStyle(theme)}>
                        Accepted Format
                    </th>
                    <td style={tableColStyle(theme)}>
                        &ldquo;User ID&rdquo;.&ldquo;User Fullname&rdquo;
                    </td>
                    <td style={tableColStyle(theme)}>
                        &ldquo;Group ID&rdquo;.&ldquo;Group Hierarchy Trail&rdquo;
                    </td>
                    <td style={tableColStyle(theme)}>
                        DD/MM/YYYY
                    </td>
                    <td style={tableColStyle(theme)}>
                        DD/MM/YYYY
                    </td>
                </tr>
                <tr style={tableRowStyle(theme)}>
                    <th style={tableRowHeadingStyle(theme)}>
                        Example
                    </th>
                    <td style={tableColStyle(theme)}>
                        <p style={exampleTextStyle}>123.Jane Doe</p>
                        <p style={refFileInfoStyle}>(reference file: Users List - users.csv)</p>
                    </td>
                    <td style={tableColStyle(theme)}>
                        <p style={exampleTextStyle}>9.{Branding.brandingName}-London-Client Services</p>
                        <p style={refFileInfoStyle}>(reference file: Groups List - groups.csv)</p>
                    </td>
                    <td style={tableColStyle(theme)}>
                        25/05/2012
                    </td>
                    <td style={tableColStyle(theme)}>
                        25/05/2012
                    </td>
                </tr>
            </tbody>
        </table>

        <p style={{ textAlign: "left" }}>
            When assigning a new Group to a User, it is recommended to add the new row below the User's latest Group history to make sure there are no gaps or overlapping dates in the User's start/end dates between Groups.
        </p>

        <p style={{ textAlign: "left" }}>
            If a given User/Group ID does not match the proceeding User Name/Group Hierarchy Trail, this will be reported as an error.
        </p>

        <p style={{ textAlign: "left" }}>
            Once uploaded, any errors found by the system will be listed in a report with the line numbers of each error found in the file. If no errors were found, your company's User Groups History will be updated accordingly.
        </p>
    </Layout>
);

const rowStyle = {
    margin: "1rem",
    border: "1px solid #555",
    fontSize: "0.75rem"
};

const listStyle = {
    textAlign: "left",
    marginTop: "0.625rem",
    marginRight: "1.25rem",
    marginLeft: "1.25rem",
    marginBottom: "1.875rem"
};

const tableStyle = {
    borderCollapse: "separate",
    margin: "0 auto",
    paddingBottom: 25
};

const tableRowStyle = theme =>({
    backgroundColor: theme.themeId === "light" ? "#dbdbdb" : "#353535"
});

const tableColStyle = theme =>({
    padding: 10,
    textAlign: "center",
    color: theme.palette.textColor
});

const tableColHeadingStyle = theme =>({
    ...tableColStyle(theme),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.inverted
});

const tableRowHeadingStyle = theme =>({
    ...tableColStyle(theme),
    backgroundColor: "#f9f9f9",
    color: "#000",
    textAlign: "left"
});

const exampleTextStyle = {
    fontWeight: "bold",
    letterSpacing: "3px"
};

const refFileInfoStyle = {
    fontStyle: "italic"
};
