import React from "react";
import ReactDOM from "react-dom";
import createReactClass from "create-react-class";
import Tooltip from "react-tooltip";
import {DragDropContext, Droppable, Draggable} from "react-beautiful-dnd";
import PureRenderMixin from "react-addons-pure-render-mixin";

import KpiColumn from "js/onpoint/kpi-column";
import RatioColumn from "js/onpoint/ratio-column";
import SortingPicker from "js/onpoint/sorting-picker";
import RemoveColumnButton from "js/onpoint/remove-item-button";
import EditRatioColumn from "js/onpoint/edit-ratio-column";
import getKpiDataSafely from "js/onpoint/get-kpi-data-safely";
import * as Formatter from "js/common/utils/formatter";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const $ = window.$;

const scrollBarWidth = "17px";
const targetBarWidth = 310;
const targetBarMargin = 10;
const maxColumnWidth = targetBarWidth + targetBarMargin;
const minColumnWidth = 140;
const columnHeaderHeight = 80;
const pixelsPerChar = 12;
const removeColBtnWidth = 26;

const flexBoxWrapperStyle = {
  display: "flex",
  flexDirection: "column",
  flexShrink: 0,
  alignContent: "space-around"
};

const columnStyle = {
  padding: 0,
  ...flexBoxWrapperStyle
};

const columnNameStyle = (theme) => ({
  fontFamily: theme.typography.fontFamilyBold,
  fontWeight: 600,
  fontSize: "0.9rem",
  paddingRight: 10,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap"
});

const DataColumns = createReactClass({

  mixins: [PureRenderMixin],

  componentDidUpdate(prevProps) {
    const {scrollTop, scrollLeft} = this.props;
    if (prevProps.scrollLeft !== scrollLeft) {
      const scrollTo = 0 - scrollLeft;
      $(ReactDOM.findDOMNode(this)).find("#kpi-ratio-header-row").css({transform: `translateX(${scrollTo}px)`});
    }
    if (prevProps.scrollTop !== scrollTop) {
      $(ReactDOM.findDOMNode(this)).find("#data-column-body").scrollTop(scrollTop);
    }
  },

  render() {
    const {
      viewType,
      columnConfigs,
      groupsKpiData,
      usersKpiData,
      onCellClick,
      cellHeight,
      borderStyle,
      width,
      height
    } = this.props;
    const containerStyle = {
      display: "inline-block",
      height: height ? height : null,
      maxWidth: width - scrollBarWidth,
      overflowX: "hidden",
      overflowY: "hidden",
      marginRight: "0.5rem",
      marginTop: "0.5rem",
      marginLeft: groupsKpiData.isEmpty() && usersKpiData.isEmpty() ? "0.5rem" : 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: scrollBarWidth
    };
    return (
        <div style={containerStyle}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            {this.renderColumnHeaderRow()}
          </DragDropContext>
          <div id="data-column-body"
               onScroll={(e) => this.handleScroll(e)}
               style={{
                 maxHeight: height - columnHeaderHeight,
                 maxWidth: width + scrollBarWidth,
                 overflow: "auto",
                 marginBottom: scrollBarWidth
               }}>
            <div style={{display: "flex", flexDirection: "row", maxHeight: height - columnHeaderHeight}}>
              {columnConfigs.map(column => {
                const columnName = column.get("name");
                const columnNameStrWidth = (columnName.length * pixelsPerChar) + removeColBtnWidth;
                const minWidth = minColumnWidth < columnNameStrWidth ? columnNameStrWidth : minColumnWidth;
                const columnType = column.get("type");
                if (columnType === "KPI") {
                  const kpi = column.get("kpi");
                  const kpiId = kpi.get("id");
                  const longestValueStr = this.getLongestDataValueDisplayWidthForKpi(kpiId);
                  const longestValueStrWidth = !!longestValueStr && viewType === "COMPACT" ?
                      longestValueStr.length * pixelsPerChar : null;
                  const colWidth = minWidth < longestValueStrWidth ? longestValueStrWidth : minWidth;
                  const groupsHaveTargets = groupsKpiData
                      .some(row => kpiDataHasTarget(row.getIn(["dataByKpiId", kpiId])));
                  const usersHaveTargets = usersKpiData
                      .some(row => kpiDataHasTarget(row.getIn(["dataByKpiId", kpiId])));
                  const hasTargetData = groupsHaveTargets || usersHaveTargets;
                  const isDetailedView = viewType === "DETAILED";
                  const finalKpiColWidth = (isDetailedView && hasTargetData) || (colWidth > maxColumnWidth) ?
                      maxColumnWidth : colWidth;
                  return (
                      <KpiColumn
                          key={`kpi-${kpiId}`}
                          kpi={kpi}
                          viewType={viewType}
                          groupsKpiData={groupsKpiData}
                          usersKpiData={usersKpiData}
                          onCellClick={cell => onCellClick(columnType, cell)}
                          cellHeight={cellHeight}
                          width={finalKpiColWidth}
                          borderStyle={borderStyle} />
                  );
                } else if (columnType === "RATIO") {
                  const ratio = column.get("ratio");
                  const ratioId = ratio.get("id");
                  const finalRatioColWidth = minWidth > maxColumnWidth ? maxColumnWidth : minWidth;
                  return (
                      <RatioColumn
                          key={`ratio-${ratioId}`}
                          ratio={ratio}
                          groupsKpiData={groupsKpiData}
                          usersKpiData={usersKpiData}
                          onCellClick={cell => onCellClick(columnType, cell)}
                          cellHeight={cellHeight}
                          width={finalRatioColWidth}
                          borderStyle={borderStyle} />
                  );
                } else {
                  return <p>Unexpected column type</p>;
                }
              })}
            </div>
          </div>
        </div>
    );
  },

  handleScroll(e) {
    const target = e.target;
    this.props.onScroll(target);
  },

  renderColumnHeaderRow() {
    const {
      viewType,
      columnConfigs,
      sortBy,
      onSortingChange,
      onRatioColumnNameChange,
      onRatioDisplayChange,
      onRemoveColumnRequest,
      groupsKpiData,
      usersKpiData,
      theme
    } = this.props;
    const renderItem = this.getRenderColumnHeaderRow(columnConfigs, viewType, sortBy, groupsKpiData, usersKpiData, onSortingChange, onRemoveColumnRequest, onRatioColumnNameChange, onRatioDisplayChange, theme);
    return (
        <Droppable
            droppableId="droppable-area"
            type="COLUMN"
            direction="horizontal"
            renderClone={renderItem}>
          {(provided, snapshot) => (
              <div ref={provided.innerRef}
                   style={{
                     overflow: "visible",
                     backgroundColor: snapshot.isDraggingOver ? theme.palette.background.paper : "transparent"
                   }}>
                <div
                    id="kpi-ratio-header-row"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      overflow: "visible",
                      position: "relative"
                    }}>
                  {columnConfigs.map((column, index) => {
                    let draggableId;
                    const columnType = column.get("type");
                    if (columnType === "KPI") {
                      const kpi = column.get("kpi");
                      const kpiId = kpi.get("id");
                      draggableId = `kpi-${kpiId}`;
                    } else if (columnType === "RATIO") {
                      const ratio = column.get("ratio");
                      const ratioId = ratio.get("id");
                      draggableId = `ratio-${ratioId}`;
                    }
                    return (
                        <Draggable key={draggableId} draggableId={draggableId} index={index} type="COLUMN">
                          {renderItem}
                        </Draggable>
                    );
                  })
                  }
                  {provided.placeholder}
                </div>
              </div>
          )}
        </Droppable>
    );
  },

  getRenderColumnHeaderRow(columnConfigs, viewType, sortBy, groupsKpiData, usersKpiData, onSortingChange, onRemoveColumnRequest, onRatioColumnNameChange, onRatioDisplayChange, theme) {
    return (provided, snapshot, rubric) => {

      const column = columnConfigs.get(rubric.source.index);
      const columnName = column.get("name");
      const columnNameStrWidth = (columnName.length * pixelsPerChar) + removeColBtnWidth;
      const minWidth = minColumnWidth < columnNameStrWidth ? columnNameStrWidth : minColumnWidth;
      const columnType = column.get("type");
      if (columnType === "KPI") {
        const kpi = column.get("kpi");
        const kpiId = kpi.get("id");
        const isDataSortedByThisKpi = sortBy &&
            sortBy.get("columnType") === "KPI" &&
            sortBy.get("id") === kpiId;
        const longestValueStr = this.getLongestDataValueDisplayWidthForKpi(kpiId);
        const isCompactView = viewType === "COMPACT";
        const longestValueStrWidth = !!longestValueStr && isCompactView ?
            longestValueStr.length * pixelsPerChar : null;
        const colWidth = minWidth < longestValueStrWidth ? longestValueStrWidth : minWidth;
        const groupsHaveTargets = groupsKpiData
            .some(row => kpiDataHasTarget(row.getIn(["dataByKpiId", kpiId])));
        const usersHaveTargets = usersKpiData
            .some(row => kpiDataHasTarget(row.getIn(["dataByKpiId", kpiId])));
        const hasTargetData = groupsHaveTargets || usersHaveTargets;
        const isDetailedView = viewType === "DETAILED";
        const finalColWidth = (isDetailedView && hasTargetData) || (colWidth > maxColumnWidth) ?
            maxColumnWidth : colWidth;

        return (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={{...columnStyle, ...provided.draggableProps.style}}>
              <KpiColumnHeader
                  kpiId={kpiId}
                  columnName={columnName}
                  sortOptions={this.getKpiSortByOptions(kpi)}
                  sortBy={isDataSortedByThisKpi ? sortBy : null}
                  onSortingChange={(sortByStr) => {
                    const sortBySettings = sortByStr.split("-");
                    const sortField = sortBySettings[0];
                    const sortOrder = sortBySettings[1];
                    onSortingChange("KPI", sortOrder, kpiId, sortField);
                  }}
                  onRemoveColumnClick={() => {
                    onRemoveColumnRequest(columnType, kpiId);
                  }}
                  width={finalColWidth}
                  isDragging={snapshot.isDragging}
                  dragHandleProps={provided.dragHandleProps}
                  theme={theme} />
            </div>
        );
      } else if (columnType === "RATIO") {
        const ratio = column.get("ratio");
        const ratioId = ratio.get("id");
        const isDataSortedByThisRatio = sortBy &&
            sortBy.get("columnType") === "RATIO" &&
            sortBy.get("id") === ratioId;
        const finalColWidth = minWidth > maxColumnWidth ? maxColumnWidth : minWidth;
        return (
            <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                style={{...columnStyle, ...provided.draggableProps.style}}>
              <RatioColumnHeader
                  columnName={columnName}
                  onColumnNameChange={onRatioColumnNameChange}
                  ratio={ratio}
                  onRatioDisplayChange={onRatioDisplayChange}
                  onRemoveColumnClick={() => onRemoveColumnRequest(columnType, ratioId)}
                  sortOrder={isDataSortedByThisRatio ? sortBy.get("sortOrder") : null}
                  onSortingChange={(sortOrder) => {
                    onSortingChange("RATIO", sortOrder, ratioId, "RATIO");
                  }}
                  width={finalColWidth}
                  isDragging={snapshot.isDragging}
                  theme={theme}
                  dragHandleProps={provided.dragHandleProps} />
            </div>
        );
      }
    };
  },

  getLongestDataValueDisplayWidthForKpi(kpiId) {
    const {groupsKpiData, usersKpiData} = this.props;
    const formattedGroupsKpiData = groupsKpiData
        .map(groupKpiData => {
          const dataByKpiId = groupKpiData.get("dataByKpiId");
          const kpiData = dataByKpiId.get(kpiId);
          let valueDisplay = "";
          if (kpiData && !kpiData.get("isLoading") && !kpiData.has("error")) {
            const total = getKpiDataSafely(kpiData, "total");
            const target = getKpiDataSafely(kpiData, "target");
            const hasTarget = target.get("value") > 0;
            if (hasTarget) {
              valueDisplay = `${Formatter.format(total.toJS())} / ${Formatter.format(target.toJS())}`;
            } else {
              valueDisplay = `${Formatter.format(total.toJS())}`;
            }
          }
          return valueDisplay;
        });
    const formattedUsersKpiData = usersKpiData
        .map(userKpiData => {
          const dataByKpiId = userKpiData.get("dataByKpiId");
          const kpiData = dataByKpiId.get(kpiId);
          let valueDisplay = "";
          if (kpiData && !kpiData.get("isLoading") && !kpiData.has("error")) {
            const total = getKpiDataSafely(kpiData, "total");
            const target = getKpiDataSafely(kpiData, "target");
            const hasTarget = target.get("value") > 0;
            if (hasTarget) {
              valueDisplay = `${Formatter.format(total.toJS())} / ${Formatter.format(target.toJS())}`;
            } else {
              valueDisplay = `${Formatter.format(total.toJS())}`;
            }
          }
          return valueDisplay;
        });
    return formattedGroupsKpiData
        .concat(formattedUsersKpiData)
        .maxBy(str => str.length);
  },

  onDragEnd(result) {
    if (!result.destination) {  // dropped outside the list area, do nothing
      return;
    }

    const {columnConfigs, onColumnsReorder} = this.props;
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const movedColumn = columnConfigs
        .get(startIndex)
        .set("order", endIndex);
    const reorderedColumnConfigs = columnConfigs
        .delete(startIndex)
        .insert(endIndex, movedColumn);
    onColumnsReorder(reorderedColumnConfigs);
  },

  getKpiSortByOptions(kpi) {
    const {groupsKpiData, usersKpiData} = this.props;
    const kpiId = kpi.get("id");
    const groupsHaveTargets = groupsKpiData.some(row => kpiDataHasTarget(row.getIn(["dataByKpiId", kpiId])));
    const usersHaveTargets = usersKpiData.some(row => kpiDataHasTarget(row.getIn(["dataByKpiId", kpiId])));
    const kpiHasTargets = groupsHaveTargets || usersHaveTargets;

    const sortOptions = [{
      value: "NONE",
      primaryText: "Do Not Sort",
      label: "NOT SORTED"
    }, {
      value: "TOTAL-ASC",
      primaryText: "Total (Ascending)",
      label: <span>TOTAL ASC <i className="fa fa-sort-amount-asc" /></span>
    }, {
      value: "TOTAL-DESC",
      primaryText: "Total (Descending)",
      label: <span>TOTAL DESC <i className="fa fa-sort-amount-desc" /></span>
    }, {
      value: "TARGET-ASC",
      primaryText: "Target (Ascending)",
      label: <span>TARGET ASC <i className="fa fa-sort-amount-asc" /></span>
    }, {
      value: "TARGET-DESC",
      primaryText: "Target (Descending)",
      label: <span>TARGET DESC <i className="fa fa-sort-amount-desc" /></span>
    }, {
      value: "TARGET_PERCENT-ASC",
      primaryText: "Target % (Ascending)",
      label: <span>TARGET % ASC <i className="fa fa-sort-amount-asc" /></span>
    }, {
      value: "TARGET_PERCENT-DESC",
      primaryText: "Target % (Descending)",
      label: <span>TARGET % DESC <i className="fa fa-sort-amount-desc" /></span>
    }];

    return kpiHasTargets ? sortOptions : sortOptions.filter(sortBy => !sortBy.value.includes("TARGET"));
  }

});

const KpiColumnHeader = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {
      kpiId,
      columnName,
      sortOptions,
      sortBy,
      onSortingChange,
      onRemoveColumnClick,
      width,
      isDragging,
      dragHandleProps,
      theme
    } = this.props;
    const doNotSort = "NONE";
    const sortField = sortBy && sortBy.has("sortField") ? sortBy.get("sortField") : doNotSort;
    const sortOrder = sortBy && sortBy.has("sortOrder") ? sortBy.get("sortOrder") : null;
    const sortByValue = sortBy ? `${sortField}-${sortOrder}` : doNotSort;
    const tooltipId = `kpi-col-${kpiId}`;
    return (
        <div style={getHeaderStyle(isDragging, width, columnHeaderHeight, theme)} {...dragHandleProps}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div style={columnNameStyle(theme)} data-tip data-for={tooltipId}>
              <span>{columnName}</span>
              <Tooltip id={tooltipId} place="top" type="light" effect="solid">
                {columnName}
              </Tooltip>
            </div>
            <RemoveColumnButton onClick={onRemoveColumnClick} />
          </div>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <SortingPicker options={sortOptions} value={sortByValue} onChange={onSortingChange} width={125} />
          </div>
        </div>
    );
  }

});

const RatioColumnHeader = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {
      columnName,
      ratio,
      sortOrder,
      onSortingChange,
      onRemoveColumnClick,
      width,
      isDragging,
      dragHandleProps,
      theme
    } = this.props;

    const sortByOptions = [{
      value: "NONE",
      primaryText: "Do Not Sort",
      label: "NOT SORTED"
    }, {
      value: "ASC",
      primaryText: "Ascending",
      label: <span>ASC <i className="fa fa-sort-amount-asc" /></span>
    }, {
      value: "DESC",
      primaryText: "Descending",
      label: <span>DESC <i className="fa fa-sort-amount-desc" /></span>
    }];
    const tooltipId = `ratio-col-${ratio.get("id")}`;
    return (
        <div style={getHeaderStyle(isDragging, width, columnHeaderHeight, theme)} {...dragHandleProps}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div style={columnNameStyle(theme)} data-tip data-for={tooltipId}>
              <span>{columnName}</span>
              <Tooltip id={tooltipId} place="top" type="light" effect="solid">
                {columnName}
              </Tooltip>
            </div>
            <RemoveColumnButton onClick={onRemoveColumnClick} />
          </div>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <SortingPicker
                options={sortByOptions}
                value={sortOrder ? `${sortOrder}` : "NONE"}
                onChange={sortOrder => onSortingChange(sortOrder === "NONE" ? null : sortOrder)}
                width={110} />
            <EditRatioColumn
                columnName={columnName}
                displayType={ratio.get("displayType")}
                onColumnNameChange={this.handleColumnNameChange}
                onRatioDisplayChange={this.handleRatioDisplayChange} />
          </div>
        </div>
    );
  },

  handleColumnNameChange(newColumnName) {
    const {ratio, onColumnNameChange} = this.props;
    onColumnNameChange(ratio.get("id"), newColumnName);
  },

  handleRatioDisplayChange(newDisplayType) {
    const {ratio, onRatioDisplayChange} = this.props;
    onRatioDisplayChange(ratio.set("displayType", newDisplayType));
  }

});

const kpiDataHasTarget = kpiData => {
  if (kpiData) {
    const target = getKpiDataSafely(kpiData, "target");
    return target.get("value") > 0;
  } else {
    return false;
  }
};

const getHeaderStyle = (isDragging, width, columnHeaderHeight, theme) => {
  return ({
    userSelect: "none",
    cursor: "move",
    backgroundColor: isDragging ? theme.palette.background.paper : theme.palette.background.card,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    padding: 7,
    marginLeft: "0.25rem",
    marginRight: "0.25rem",
    height: columnHeaderHeight,
    width,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    ":hover": {
      backgroundColor: "#678daf"
    }
  });
};


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <DataColumns theme={theme} {...props} />;
};

export default Wrapper;
