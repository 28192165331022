import * as Colors from "js/common/cube19-colors";

export const squidLight = {
  id: "light",
  page: {
    background: Colors.lightBackground,
    titleColor: Colors.grey
  },
  edge: {
    style: {stroke: Colors.greyText},
    type: "custom"
  },
  label: {
    labelStyle: {
      color: Colors.white
    },
    labelBgStyle: {
      border: `1px solid ${Colors.java}`,
      background: Colors.java
    },
    labelBgPadding: [10, 6]
  },
  nodeValueStyle: {
    color: Colors.java,
    fontWeight: "bold"
  },
  node: {
    highlight: {
      id: "highlight",
      border: "2px solid #FFAB2E",
      backgroundColor: Colors.white,
      color: Colors.darkestGrey,
      borderRadius: 6,
      boxShadow: `0px 2px ${Colors.lightestGrey}`
    },
    dark: {
      id: "dark",
      border: "2px solid transparent",
      opacity: 0.5,
      backgroundColor: Colors.white,
      color: Colors.darkestGrey,
      borderRadius: 6,
      boxShadow: `0px 2px ${Colors.lightestGrey}`
    },
    edit: {
      border: "2px solid #FFAB2E",
      backgroundColor: Colors.white,
      color: Colors.darkestGrey,
      borderRadius: 6,
      boxShadow: `0px 2px ${Colors.lightestGrey}`
    },
    default: {
      border: "2px solid transparent",
      color: Colors.darkestGrey,
      id: "default",
      backgroundColor: Colors.white,
      borderRadius: 6,
      boxShadow: `0px 2px ${Colors.lightestGrey}`
    }
  },
  steps: {
    backgroundColor: Colors.white,
    titleColor: Colors.darkestGrey,
    activeStepColor: Colors.lightestGrey,
    accentColor: Colors.accentPurple,
    inactiveAccentColor: Colors.lightPurple,
    activeStepNumberColor: Colors.white,
    inactiveStepNumberColor: Colors.white,
    activeStepTextColor: Colors.lightText,
    inactiveStepTextColor: Colors.greyText,
    iconLabelColor: Colors.blueBorder,
    dividerColor: Colors.lightestGrey
  },
  controls: {
    backgroundColor: Colors.white,
    inputColor: Colors.lightBackground,
    fontColor: Colors.greyDark,
    fontHighlightColor: Colors.midGrey,
    iconColor: Colors.midGrey,
    secondaryIconColor: Colors.java,
    editModeActive: Colors.java,
    editModeInactive: Colors.lightBackground
  }
};