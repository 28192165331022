const $ = window.$;

const DEFAULT_OPTIONS = {
    chart: {
        backgroundColor: null
    },
    credits: {
        enabled: false
    },
    title: {
        text: ""
    },
    tooltip: {
        shared: true,
        split: false
    },
    exporting: {
        buttons: {
            contextButton: {
                theme: {
                    states: {
                        hover: {
                            fill: "#707073",
                            stroke: "none"
                        }
                    }
                },
                menuItems: [{
                    text: "Download Trend Chart (.jpeg)",
                    onclick() {
                        this.exportChart({ type: "image/jpeg" });
                    }
                },{
                    text: "Download Trend Chart (.pdf)",
                    onclick() {
                        this.exportChart({ type: "application/pdf" });
                    }
                },{
                    text: "Download Trend Chart (vector)",
                    onclick() {
                        this.exportChart({ type: "image/svg+xml" });
                    }
                }]
            }
        },
        chartOptions: {
            rangeSelector: {
                enabled: false
            },
            scrollbar: {
                liveRedraw: true,
                enabled: true
            },
        },
        scale: 2,
        fallbackToExportServer: false
    }
};

export default (...options) => {
    return $.extend(true, {}, DEFAULT_OPTIONS, ...options);
};
