import {css} from "@emotion/react";


export const dropdownHeadingCss = theme => css`
  color: ${theme.palette.text.main};
  border-bottom: 1px solid ${theme.palette.background.paper};
  text-transform: uppercase;
  font-weight: bold;
  font-weight: 700;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  margin-top: -7px;
  font-size: 12px;
`;

export const tagItemCss = palette => css`
  background-color: ${palette.background.paper} !important;
  margin: 0 0 0.5rem 0.5rem;

  :hover {
    background-color: ${palette.action.focus} !important;
  }
;
`;


export const selectWrapperStyles = (theme, bgColor, disabled) => (
    css`
      align-items: center;
      cursor: pointer;
      display: flex;
      border-radius: 4px;
      font-size: 0.875rem;
      padding: 0.4em 0.875em;
      cursor: ${disabled ? "not-allowed" : "pointer"};
      background: ${disabled ? theme.palette.background.paper : theme.palette.background.card};
      min-height: 39px;
      max-height: 200px;
      overflow-y: auto;
      transition: background 0.3s ease;
      border: 2px solid ${theme.palette.background.paper};
    `);

/*
&:hover {
background: ${theme.palette.hover[bgColor]
} this use to be above*/

export const selectedValueStyles = (theme, bgColor) => (
    css`
      border-radius: 3px;
      background-color: ${theme.palette.background.paper};
      display: inline-block;
      margin-left: 3px;
      margin-right: 3px;
      margin-top: 5px;
    `);

export const selectInputStyle = (theme, searchInput, isOpen) => (
    css`
      background: transparent;
      border: none;
      color: ${theme.palette.text.main};
      font-family: inherit;
      font-size: 0.875rem;
      width: ${searchInput.length === 0 && !isOpen ? "0.25rem" : "-webkit-fill-available"};
    `
);

export const selectedValueLabelStyle = css`
  cursor: default;
  padding: 2px 5px 2px;
  display: inline-block;
  max-width: 330px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: bottom;
`;

export const selectedValueRemoveStyle = css`
  cursor: pointer;
  padding: 1px 5px 3px;
  display: inline-block;
`;

export const iconZoneStyle = css`
  display: flex;
  align-items: center;
  padding-right: 5px;

  > span {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
`;

export const dropdownClosedStyle = (theme) => css({
  position: "absolute",
  zIndex: 99999,
  width: "100%",
  background: theme.palette.background.card
});

export const dropdownOpenStyle = (theme, showSelectedInDropdown) => css({
  position: "absolute",
  zIndex: 99999,
  width: "100%",
  background: theme.palette.background.card,
  boxShadow: "0px 2px 2px rgb(0 0 0 / 10%)",
  border: showSelectedInDropdown ? `2px solid ${theme.palette.background.paper} !important` : "none"
});
