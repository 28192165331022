import React from "react";
import Select from "js/common/views/inputs/immutable-react-select";
import Immutable from "immutable";

const YAxisPositionPicker = ({ onChange, value = 0, isDisabled = false }) => {
    let options = Immutable.fromJS([{
        value: 0,
        label: "Left"
    },{
        value: 1,
        label: "Right"
    }]);

    if (value === 2) {
        options.push(Immutable.Map({
            value: 2,
            label: "Right"
        }));
    }

    return (
        <Select
            name="y-axis"
            placeholder="Select y-axis position"
            selectedValue={value}
            isClearable={false}
            isearchable={false}
            isMulti={false}
            isDisabled={isDisabled}
            options={options}
            onChange={newOption => onChange(newOption)} />);
};

export default YAxisPositionPicker;
