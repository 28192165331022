import React from "react";
import * as Immutable from "immutable";
import pure from "js/common/views/pure";
import { Alert } from '@mui/material';
import Breadcrumbs from "js/admin/simple-breadcrumbs";
import * as Groups from "js/common/groups";

export default pure(({theme, originalGroupId, style}) => {
  const group = Groups.getGroup(originalGroupId);
  if (!group.get("breadcrumbs")) {
    return false;
  }
  return (
      <Alert severity="info" style={{marginBottom: "0.5rem", ...style}}>
            <span>
              <span style={{paddingRight: 8}}>Inherited from:</span>
              <Breadcrumbs trail={Immutable.fromJS(group.get("breadcrumbs"))} dividerColor={theme.palette.textColor}/>
            </span>
           </Alert>
  );
});
