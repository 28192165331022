/** @jsxImportSource @emotion/react */

import React from "react";
import {jsx, css} from "@emotion/react";
import Immutable from "immutable";

import pure from "js/common/views/pure";
import * as Groups from "js/common/groups";

export default pure(({ groupId, onGroupCrumbClick, isLastCrumbClickable, theme }) => {
    const groupBreadcrumbs = Immutable.fromJS(Groups.getGroupBreadcrumbs(groupId));
    if (!groupBreadcrumbs) {
        return;
    } else {
        return (
            <div style={{ marginTop: "1rem", marginBottom: "1rem", fontSize: "0.9375rem" }}>
                {groupBreadcrumbs
                    .filter(t => !t.get("deleted"))
                    .map((group, index) => {
                        const lastIndex = groupBreadcrumbs.count() - 1;
                        const isLast = index === lastIndex;
                        const isCrumbClickable = isLast && !isLastCrumbClickable;
                        const crumbStyle = css`
                            color: ${theme.palette.textColor};
                            letter-spacing: 1px;
                            font-weight: ${isLast ? "bold" : "normal"};
                            cursor: ${isCrumbClickable ? "default" : "pointer"};
                            pointer-events: ${isCrumbClickable ? "none" : "all"};

                            &:hover {
                                color: ${isCrumbClickable ? theme.palette.textColor : theme.palette.primary.main};
                                text-decoration: ${isCrumbClickable ? "none" : "underline"};
                            }
                        }`;
                        return (
                            <span key={group.get("id")}>
                                <span
                                    key={`${index}-name`}
                                    onClick={isCrumbClickable ? () => {} : () => onGroupCrumbClick(group.get("id"))}
                                    css={crumbStyle}>
                                    {group.get("name")}
                                </span>
                                {!isLast &&
                                    <i key={`${index}-div`}
                                        className="fa fa-chevron-circle-right"
                                        style={{ paddingLeft: 8, paddingRight: 8, color: theme.palette.primary.main }} />}
                            </span>
                        );
                    })}
            </div>
        );
    }
});
