import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import PureRenderMixin from "react-addons-pure-render-mixin";
import C19Tooltip from "js/common/views/tooltips";
import { TextButton } from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const DataGroupNameInput = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        name: ReactPropTypes.string.isRequired,
        onChange: ReactPropTypes.func.isRequired
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.name !== this.props.name) {
            this.setState({
                name: nextProps.name
            });
        }
    },

    getInitialState() {
        return {
            name: this.props.name,
            showValidationDialog: false
        };
    },

    render() {
        const { name, showValidationDialog } = this.state;
        const {theme} = this.props;
        const isNotDefaultName = !/Data Group [0-9]+/i.test(name);
        return (
            <div>
                <C19Tooltip
                    text="Name this Data Group"
                    position="top"
                    showOnHover={false}
                    disable={ isNotDefaultName }
                    styleType="light">
                    <div>
                        <i className="fa fa-pencil left" style={iconStyle} />
                        <div className="left">
                            <input
                                autoFocus
                                type="text"
                                style={textInputStyle(theme)}
                                placeholder="Name this Data Group"
                                value={name}
                                onFocus={this.moveCaretToEnd}
                                onBlur={this.saveChange}
                                onChange={this.handleChange} />
                        </div>
                    </div>
                </C19Tooltip>
                {showValidationDialog &&
                    <Dialog
                        title="Data Group Naming"
                        titleStyle={{ color: "#f9ec33", fontSize: "1rem" }}
                        bodyStyle={{ color: "#fff" }}
                        autoDetectWindowHeight={true}
                        actions={<TextButton type="success" label="OK" icon="check" onClick={this.closeDialog} />}
                        modal={false}
                        open={true} >
                        <p>
                            The Metrics displayed on the chart will inherit their name from the data group they relate to distinguish between 2 data series using the same Metric.
                        </p>
                        <p>For example:</p>
                        <p>
                            <span>
                                <i className="fa fa-line-chart" style={{ color:"#FF0068", paddingRight: 8 }} />
                                Data Group 1 - CVs Sent
                            </span>
                            <br />
                            <span>
                                <i className="fa fa-line-chart" style={{ color:"#00FFF5", paddingRight: 8 }} />
                                Data Group 2 - CVs Sent
                            </span>
                        </p>
                    </Dialog>}
            </div>
        );
    },

    handleChange(e) {
        const inputText = e.target.value;
        this.setState({
            name: inputText
        });
    },

    saveChange() {
        const previousName = this.props.name;

        if (previousName === this.state.name) {
            return;
        }

        if (/[a-z0-9]/ig.test(this.state.name)) {
            this.props.onChange(this.state.name);
        } else {
            this.setState({
                name: previousName,
                showValidationDialog: true
            });
        }
    },

    closeDialog() {
        this.setState({
            showValidationDialog: false
        });
    },

    moveCaretToEnd(event) {
        let value = event.target.value;
        event.target.value = "";
        event.target.value = value;
    }

});

const inputHeight = "34px";
const iconStyle = {
    paddingLeft: 5,
    color: "#626262",
    height: inputHeight,
    lineHeight: inputHeight
};
const textInputStyle = theme => ({
    fontSize: "0.9rem",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
    backgroundColor: "transparent",
    border: "none",
    borderBottom: "1px solid #9e9e9e",
    marginBottom: 0,
    marginLeft: 5,
    height: inputHeight,
    borderRadius: 0,

    ":focus": {
        border: "none",
        boxShadow: "none"
    }
});


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <DataGroupNameInput theme={theme} {...props} />;
};

export default Wrapper;
