import {css} from "@emotion/react";

export const cardStyle = (props, theme) => css`
  height: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: ${props.mode === "grid" ? "column" : "row"};
  justify-content: ${props.mode === "grid" ? "flex-start" : "space-between"};
  align-items: center;
  text-align: ${props.mode === "grid" ? "center" : "left"};
  cursor: pointer;
  position: relative;
  background-color: ${theme.palette.background.card};
  color: ${theme.palette.text.main};
  border-radius: ${props.mode === "grid" ? 10 : 0};
  box-shadow: 0 -1px 3px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  transition: 0.1s ease-in-out;
  overflow: visible;

  &:hover {
    background-color: ${theme.palette.background.paper};
  }
`;

export const cardContainerStyle = (props, theme) => css`
  border-top: ${props.mode === "grid" ? "none" : `1px solid ${theme.palette.background.paper}`};
  overflow: visible;

  &:first-of-type {
    border: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  &:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`;

export const cardInnerStyle = (props, theme) => css`
  display: flex;
  flex-direction: ${props.mode === "grid" ? "column" : "row"};
  flex: 1;
  width: 100%;
  padding: 20px;
  align-items: ${props.mode === "grid" ? "center" : "center"};
  color: ${theme.palette.text.main};
`;

export const cardTitleStyle = (props, theme) => css`
  font-weight: ${props.mode === "grid" ? "700" : "normal"};
  font-size: ${props.mode === "grid" ? "22px" : "14px"};
  margin: ${props.mode === "grid" ? "20px auto 10px auto" : "0 40px 0 40px"};
  color: ${theme.palette.text.main};
  white-space: ${props.mode === "grid" ? "normal" : "nowrap"};
  width: 240px;
  flex: 1;
  word-break: break-word;
  overflow: hidden;
`;

export const cardDescriptionStyle = (props, theme) => css`
  display: block;
  font-size: 14px;
  word-break: break-all;
  flex: 1;
  color: ${theme.palette.text.main};
  margin-right: ${props.mode === "grid" ? "0" : "100px"};
  margin-bottom: ${props.mode === "grid" ? "10px" : "0"};
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const cardMenuStyle = (props) => css`
  position: absolute;
  right: 14px;
  top: ${props.mode === "grid" ? "14px" : "50%"};
  margin-top: ${props.mode === "grid" ? "0" : "-14px"};
  min-width: auto;
  background-color: transparent !important;
  border-radius: 50%;
  box-shadow: none;
  padding: 0 8px;
  transition: 0.1s ease-in-out;

  &:hover {
    background-color: rgba(200, 200, 200, 0.1)
  }

  &:active {
    box-shadow: none;
  }
`;

export const cardImageStyle = (props) => css`
  background-color: #32323F;
  border-radius: 50%;
  height: ${props.mode === "grid" ? "100px" : "36px"};
  width: ${props.mode === "grid" ? "100px" : "36px"};
  line-height: ${props.mode === "grid" ? "100px" : "36px"};
  overflow: hidden;
  margin: ${props.mode === "grid" ? "0 auto" : 0};
  flex-shrink: 0;
  text-align: center;

  img {
    height: ${props.mode === "grid" ? "76px" : "30px"};
  }
`;

export const unsavedChangesStyle = (props) => css`
  position: absolute;
  text-align: center;
  left: ${props.mode === "grid" ? "10px" : null};
  right: ${props.mode === "grid" ? null : "40px"};
  margin: 20px 10px;
  width: 60px;
  font-size: 0.85rem;
  color: orange;
`;
