/** @jsxImportSource @emotion/react */

import React from "react";
import LoadingSpinner from "js/oneview/loading-spinner";
import * as Formatter from "js/common/utils/formatter";
import {css} from "@emotion/react";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {roundTo} from "js/common/utils/numbers";
import Tooltip from "js/common/views/tooltips";
import {v4 as uuid} from "uuid";

const PriorityMetric = React.memo(({dimensions, kpiId, kpiData, clientIds, onClick, hasAppliedTagFilters}) => {
  const {theme} = React.useContext(CustomThemeContext);

  const kpi = kpiRepo.get(kpiId);
  const hasData = kpiData && !kpiData.get("error");
  const name = kpi.get("name");
  const hasClientFilter = clientIds && clientIds.count() > 0;
  const totalValue = hasData ? kpiData.get("total").value : 0;
  const expectedValue = hasData ? kpiData.get("expectedValue").value : 0;
  const targetValue = hasData ? kpiData.get("target").value : 0;
  const hasTarget = !hasClientFilter && targetValue > 0 && !hasAppliedTagFilters;
  const hasTargettedData = hasData && hasTarget;
  const totalPercentage = getPercentageOfTargetComplete(targetValue, totalValue);
  const expectedPercentage = getPercentageOfTargetComplete(targetValue, expectedValue);
  const targetState = getTargetState(totalValue, expectedValue, hasAppliedTagFilters, hasClientFilter, theme);

  // Note: We use this to reset tooltip position afterHide to prevent alignment issues
  // Issue: https://github.com/ReactTooltip/react-tooltip/issues/219
  const tooltipComponent = React.useRef();


    const tooltipId = `vertical-tooltip-${kpi.get("id")}-${uuid()}`;
    return (
        <div
            css={curveContainerStyle}
            onClick={() => onClick(kpiId)}>

          {!hasData && <LoadingSpinner customIconStyle={{padding: "50px"}} />}
          <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            flexShrink: 0,
            height: "calc(100% - 42px)",
            minHeight: 22,
            position: "relative",
            top: -4,
          }}>
              <Tooltip
                  wrap={true}
                  width={200}
                  tooltipHtml={() => (
                    <div style={{textAlign: "left"}}>
                        {hasTargettedData && <span style={{fontSize: 14, fontWeight: 600}}>{Math.round(totalPercentage * 100)}%</span>}<br/>
                        Total: {getFormattedTotalValue(kpiData)}<br/>
                        Target: {Formatter.format(getSafely(kpiData, "target"))}
                    </div>
                  )}
                  styleType={theme.themeId === "light" ? "dark" : "light"}
                  position="bottom">
              {hasTargettedData && <h2 style={{
                  color: theme.palette.text.main,
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: 1,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  width: "calc(100%)",
                  overflow: "hidden"
              }}>{getFormattedTotalValue(kpiData)}/{Formatter.format(getSafely(kpiData, "target"), {maxDisplayLength: 6})}</h2>}

            {hasTargettedData && renderBar(targetState, totalPercentage, expectedPercentage)}
            {(hasData && !hasTarget) && renderNoTarget(kpiData, name, tooltipId, tooltipComponent, theme)}
              </Tooltip>
          </div>

          <div style={{width: "100%", position: "absolute", bottom: 9, left: 9}}>
              {hasTargettedData && renderDifferenceFromExpectedText(kpiData, hasAppliedTagFilters, hasClientFilter, theme)}          </div>
          <div style={{position: "absolute", bottom: 5, right: 9}}>
              {hasTargettedData && <span style={{fontWeight: 600, fontSize: 14, color: theme.palette.text.main}}>{Math.round(totalPercentage * 100)}%</span>}
          </div>
        </div>
    );
});

const renderBar = (targetState, totalPercentage, expectedPercentage) => {
  const barColor = targetState.color;

  return (
      <div style={{position: "relative", marginTop: 5}}>
        <div style={{position: "absolute", top: 0, left: 0, borderRadius: 10, width: "100%", border: "1px dashed #AFB9C0", height: 8}} />
        <div style={{overflow: "hidden", maxWidth: "100%", minWidth: 10, position: "absolute", top: 0, left: 0, borderRadius: 10, height: 8, opacity: 0.9, background: "#AFB9C0", width: `${expectedPercentage * 100}%`}} />
        <div style={{overflow: "hidden", maxWidth: "100%", minWidth: 10, position: "absolute", top: 0, left: 0, borderRadius: 10, height: 8, background: barColor, width: `${totalPercentage * 100}%`}} />
      </div>
  )
};

const getFormattedTotalValue = kpiData => {
  const total = getSafely(kpiData, "total");
  return Formatter.format(total, {maxDisplayLength: 6});
};

const getTargetState = (totalValue, expectedValue, hasAppliedTagFilters, hasClientFilter, theme) => {
  const percentageToExpectedTarget = totalValue / expectedValue;
  let targetState = {};

  if (hasAppliedTagFilters || hasClientFilter) {
    return targetState = {label: "no target", background: theme.palette.background.card, color: theme.palette.text.main};
  }

  if (totalValue < expectedValue) {
    if (percentageToExpectedTarget < 0.5) {
      targetState = {label: "behind", background: "#F1D5D8", color: theme.palette.dashboards.behind};
    } else if (percentageToExpectedTarget >= 0.5 && percentageToExpectedTarget < 1.0) {
      targetState = {label: "ok", background: "#fff0d8", color: theme.palette.dashboards.ok};
    } else if (percentageToExpectedTarget >= 1.0) {
      targetState = {label: "ahead", background: "#107C10", color: theme.palette.dashboards.ahead};
    }
  } else if (totalValue > expectedValue) {
    targetState = {label: "ahead", background: "#107C10", color: theme.palette.dashboards.ahead};
  } else if (totalValue === 0 && expectedValue === 0) {
    targetState = {label: "not filterable", background: "#cccccc", color: "#333333"};
  } else if (totalValue === expectedValue) {
    targetState = {label: "ok", background: "#fff0d8", color: theme.palette.dashboards.ok};
  }
  return targetState;
};

const getTotalToExpectedTargetDifference = kpiData => {
  const totalValue = getSafely(kpiData, "total");
  const expectedValue = getSafely(kpiData, "expectedValue");
  const difference = {
    value: Math.abs(totalValue.value - expectedValue.value),
    currency: totalValue.currency,
    decimalPlaces: totalValue.decimalPlaces
  };
  return Formatter.format(difference);
};

const renderDifferenceFromExpectedText = (kpiData, hasAppliedTagFilters, hasClientFilter, theme) => {
  const totalValue = getSafely(kpiData, "total").value;
  const expectedValue = getSafely(kpiData, "expectedValue").value;
  if (totalValue < expectedValue) {
    return (
        <span style={{
            whiteSpace: "nowrap",
            fontSize: 10,
            background: getTargetState(totalValue, expectedValue, hasAppliedTagFilters, hasClientFilter, theme).background,
            borderRadius: 4,
            overflow: "hidden",
            display: "block",
            height: 20,
            lineHeight: "18px",
            width: "fit-content",
            color: getTargetState(totalValue, expectedValue, hasAppliedTagFilters, hasClientFilter, theme).color,
            padding: "0 4px 2px 4px"}}>
          <i
              className={`fa fa-caret-${totalValue > expectedValue ? "up" : "down"}`}
              style={{
                fontSize: "1.1rem",
                paddingRight: 4,
                position: "relative",
                top: 1,
                color: getTargetState(totalValue, expectedValue, hasAppliedTagFilters, hasClientFilter, theme).color
              }} />
            <span style={{position: "relative", top: -1}}>{getTotalToExpectedTargetDifference(kpiData)} {totalValue > expectedValue ? "ahead" : "behind"}</span>
        </span>);
  }
};

export const getPercentageOfTargetComplete = (target, value) => {
  const roundedTarget = roundTo(target, 3);
  const percent = roundedTarget === 0 ? 0 : value / roundedTarget;
  return Math.round(percent * 100) / 100;
};

const valueIsAboveTarget = kpiData => {
  if (kpiData) {
    const targetValue = kpiData.get("target").value;
    const totalValue = kpiData.get("total").value;
    return (totalValue >= targetValue && targetValue > 0);
  } else {
    return false;
  }
};

const getSafely = (kpiData, key) => {
  if (!kpiData.has(key)) {
    return {
      value: 0
    };
  }

  let obj = kpiData.get(key);
  if (!obj.value) {
    obj.value = 0;
  }
  return obj;
};

const renderNoTarget = (kpiData, name, tooltipId, tooltipComponent, theme) => {
  return (
      <h2 style={{
        color: theme.palette.text.main,
        fontSize: 18,
        fontWeight: 600,
        lineHeight: 1,
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "calc(100%)",
        overflow: "hidden"
      }}>{getFormattedTotalValue(kpiData)}</h2>
  );
};

const curveContainerStyle = css`
    color: #333;
    position: relative;
    padding: 0 10px 0 10px;
    cursor: pointer;
    transition: all 0.5s ease;
    height: 100%;
  `;

const noTargetNameStyle = theme => css`
  font-size: 0.9em;
  font-weight: 700;
  color: ${theme.palette.text.main}
`;

const noTargetStyle = theme => ({
  fontSize: "1.625em",
  textAlign: "left",
  fontWeight: 700,
  color: theme.palette.text.main,
});

export default PriorityMetric;
