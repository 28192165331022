/** @jsxImportSource @emotion/react */

import React from "react";
import pure from "js/common/views/pure";
import { Grid, Card, List, ListItem} from "@mui/material";
import ErrorBoundary from "js/common/views/error-boundary";
import {IconButton} from "js/common/views/inputs/buttons";
import DateTimeSettings from "js/user-settings/settings-pages/date-time-settings";
import DisplaySettings from "js/user-settings/settings-pages/display-settings";
import SettingsPage from "js/user-settings/settings-page";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {css} from "@emotion/react";

export default pure(({...props}) => (
      <ErrorBoundary>
        <Page {...props} />
      </ErrorBoundary>
));

const selectPageStyle = css`
  @media (max-width: 720px) {
    margin: 5px 0 0 0px;
    width: 100%;
    max-width: 97vw;
  }
`;

const settingsPages = [
  {
    name: "Date & Time",
    icon: "clock",
    page: <DateTimeSettings/>
  },{
    name: "Display",
    icon: "desktop",
    page: <DisplaySettings/>
  },
];

const Page = React.memo(() => {

  const [selectedPage, setSelectedPage] = React.useState(settingsPages[0]);
  const { theme } = React.useContext(CustomThemeContext);

    return (
      <div>
        <Grid container spacing={1} style={{margin: 0}}>
          <Grid item xs={12}>
            <Card style={{margin: 5, boxShadow: "none", backgroundColor: "transparent"}}>
              <List style={{display: "flex", margin: "5px 0", padding: 0}}>
                {settingsPages.map( page => {
                  const isSelected = page === selectedPage;
                  const selectedStyle = isSelected && {color: theme.palette.primary.main};
                  return (
                    <ListItem style={{padding: 0, margin: 0, width: "38%"}}>
                      <IconButton
                        hoverType="default"
                        label={page.name}
                        icon={page.icon}
                        style={isSelected ? {borderRadius: 0, paddingBottom: "5px", borderBottom: `2px solid ${theme.palette.primary.main}`} : {paddingBottom: 5, borderBottom: `2px solid transparent`}}
                        iconStyle={{fontSize: "1.2rem", ...selectedStyle}}
                        labelStyle={{fontFamily: theme.typography.fontFamily, fontSize: "1rem", marginLeft: 15, ...selectedStyle}}
                        onClick={() => setSelectedPage(page)}
                        size="medium" />
                    </ListItem>
                  );}
                )}
              </List>
            </Card>
          </Grid>
          <Grid item xs={12} css={selectPageStyle} style={{backgroundColor: theme.palette.background.paper}}>
            <SettingsPage
              title={selectedPage.name}
              content={selectedPage.page}/>
          </Grid>
        </Grid>
      </div>
    );
});
