import React from "react";
import Immutable from "immutable";

import {indexBy} from "js/common/utils/collections";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
const getQueryParam = (kpi, key, defaultValue = null) => kpi.getIn(["queryParams", key], defaultValue);
const setQueryParam = (kpi, key, value) => {
  const oldValue = getQueryParam(kpi, key);
  if (oldValue === value) {
    return kpi;
  } else {
    if (value == null || value === "" || value === "[]" || value === "{}") {
      return deleteQueryParam(kpi, key);
    } else {
      return kpi.setIn(["queryParams", key], value);
    }
  }
};
const deleteQueryParam = (kpi, key) => kpi.deleteIn(["queryParams", key]);

const getQueryType = (kpi, idToTemplate) => {
  const template = idToTemplate.get(kpi.get("templateId")) || Immutable.Map();
  return template.get("queryType");
};

const useMountUnmountEffect = (effectFn, dependencies) => {
  const ref = React.useRef(dependencies);
  React.useEffect(() => {
    ref.current = dependencies;
  }, dependencies);
  React.useEffect(() => {
    return effectFn(ref);
  }, []);
};
const useCalculatedImmutableState = (generatorFn, dependencies) => {
  const valueRef = React.useRef(null);
  const generatedValue = React.useMemo(generatorFn, dependencies);

  if (!Immutable.is(generatedValue, valueRef.current)) {
    if (Immutable.Map.isMap(generatedValue)) {
      const mergedValue = generatedValue.map((newVal, key) => {
        const oldVal = valueRef.current.get(key);
        if (Immutable.is(newVal, oldVal)) {
          return oldVal;
        } else {
          return newVal;
        }
      });
      valueRef.current = mergedValue;
      return mergedValue;
    } else {
      valueRef.current = generatedValue;
      return generatedValue;
    }
  } else {
    return valueRef.current;
  }
};

const getDefaultTestConfig = () => {
  const userId = Immutable.fromJS(Users.getActiveUsers().toJSON())
      .filter(u => u.get("hasLogin"))
      .map(u => u.get("id"))
      .sort()
      .first();
  const groupId = Groups.getRootGroup().get("id");
  const timeframe = TimeframeRepo.getDefaultForClient().getRawJson();
  const traceLevel = "NONE";
  return Immutable.fromJS({
    userId,
    groupId,
    timeframe,
    traceLevel
  });
};

const flattenForwardReport = node => node.get("children", Immutable.List()).flatMap(flattenForwardReport).push(node);
export {
  useMountUnmountEffect,
  useCalculatedImmutableState,
  getQueryParam,
  setQueryParam,
  deleteQueryParam,
  getQueryType,
  getDefaultTestConfig,
};
