import React from "react";

import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import Immutable from "immutable";

const MetricForm = React.memo(({dataConfig, onDataConfigChange}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const [addNewMetric, setAddNewMetric] = React.useState(dataConfig.get("kpiIds").count() < 2);
  const maxMetrics = 10;
  const metricsList = dataConfig.get("kpiIds");
  const getMetrics = () => Immutable.fromJS(kpiRepo.getAll().toJSON())
      .filter(kpi => !metricsList.includes(kpi.get("id")))
      .filter(kpi => kpi.get("visible"))
      .filter(kpi => kpi.get("trendable"))

  return(
      <div style={{width: 500, marginBottom: "0.3rem"}}>
        {metricsList.map((id, index) =>
            <div style={{display: "flex", marginBottom: "0.5rem", alignItems: "center"}}>
              <div style={{width: 30, fontSize: "1.2em", opacity: 0.3, fontWeight: 600}}>{index + 1}.</div>
              <div style={{width: 400}}>
                <KpiPicker
                    placeholder={id ? KpiRepo.get(id).get("name") : "Select Metric"}
                    multiSelect={false}
                    clearable={false}
                    kpis={getMetrics()}
                    selectedKpiId={id}
                    closeOnSelect={true}
                    onChange={kpiId => {
                      const updatedMetricList = metricsList.set(index, kpiId);
                      onDataConfigChange(dataConfig.set("kpiIds", updatedMetricList));
                      setAddNewMetric(false);
                    }} />
              </div>

              {index > 0 && <i className="bhi-times"
                               style={{marginLeft: "0.5rem", fontSize: "0.8rem", color: theme.palette.error.main}}
                               onClick={() => {
                                 onDataConfigChange(dataConfig.set("kpiIds", metricsList.delete(index)));
                               }} />}
              {index + 1 === metricsList.count() && !addNewMetric && metricsList.count() !== maxMetrics &&
                  <div
                      style={{
                        position: "relative",
                        right: -50,
                        lineHeight: "25px",
                        textAlign: "center",
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        background: theme.palette.primary.main,
                        color: theme.palette.text.inverted
                      }}
                      onClick={() => setAddNewMetric(true)}>
                    <i className="bhi-plus" style={{fontSize: "12px"}} />
                  </div>
              }
            </div>
        )}
        {addNewMetric && <div>
          {metricsList.count() < maxMetrics
              ? <div style={{display: "flex", alignItems: "center"}}>
                <div style={{width: 30, fontSize: "1.2em", opacity: 0.3, fontWeight: 600}}>{metricsList.count() + 1}</div>
                <div style={{width: 400}}>
                  <KpiPicker
                      placeholder="Select Metric"
                      multiSelect={false}
                      clearable={false}
                      kpis={getMetrics()}
                      closeOnSelect={true}
                      onChange={kpiId => {
                        onDataConfigChange(dataConfig.set("kpiIds", metricsList.push(kpiId)));
                        setAddNewMetric(false);
                      }} />
                </div>
                {metricsList.count() > 1 && <i className="bhi-times"
                                              style={{
                                                marginLeft: "0.5rem",
                                                fontSize: "0.8rem",
                                                color: theme.palette.error.main
                                              }}
                                              onClick={() => setAddNewMetric(false)} />}
              </div>
              : <div>You can only trend {maxMetrics} metrics at a time.</div>
          }
        </div>}
        {metricsList.count() >= maxMetrics &&
            <div style={{maxWidth: 500, margin: "20px 0 10px 0"}}>
              <Hint>
                <Icon icon="info" style={{color: theme.palette.hints.text}} />
                You can only trend {maxMetrics} metrics at a time.
              </Hint>
            </div>
        }
      </div>
  );
});

export default MetricForm;