import React from "react";
import {Global, css} from "@emotion/react";
import createReactClass from "create-react-class";
import store from "store";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";
import pure from "js/common/views/pure";
import LightLogo from "img/logo/light-mode-horizontal.svg";
import DarkLogo from "img/logo/dark-mode-horizontal.svg";

import Layout from "js/common/views/foundation-column-layout";
import UncaughtErrorMsg from "js/common/views/uncaught-error-msg";

import {trackError} from "js/common/error-tracking";
import * as auth from "js/common/auth";
import eventBus from "js/cube19.event-bus";

import {TextButton} from "js/common/views/inputs/buttons";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {loginViaSso} from "js/login/bullhorn-sso";
import * as Branding from "js/common/branding-constants"
import * as Colors from "js/common/cube19-colors";

export default React.memo(({initialView = "LOGIN"}) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ErrorBoundaryPage initialView={initialView} theme={theme} />
});

const ErrorBoundaryPage = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      uncaughtError: false
    };
  },

  componentDidCatch(error, {componentStack}) {
    trackError("REACT_RENDER", error, {componentStack});
    this.setState({
      uncaughtError: true
    });
  },

  render() {
    if (this.state.uncaughtError) {
      return <UncaughtErrorMsg />;
    } else {
      return <Page initialView={this.props.initialView} theme={this.props.theme} />;
    }
  }

});

const smallScreenWidthBreakpoint = 800;
const unexpectedError = `An unexpected error has occurred. ${Branding.submitTicketString}`;
const missingFieldsError = "Please fill in the required fields.";
const newPasswordConfirmationFailed = "New and confirmed password do not match.";
const newPasswordSameAsCurrentPasswordError = "New password cannot be the same as current password";

const Page = GetContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      currentForm: this.props.initialView,
      username: "",
      password: "",
      newPassword: "",
      newPasswordDuplicate: "",
      isLoggingIn: false,
      isUpdatingPassword: false,
      isSendingResetPasswordEmail: false,
      showResetPasswordEmailSentMsg: false,
      loginFormError: null,
      changePasswordFormErrors: Immutable.Map(),
      resetPasswordFormError: null
    };
  },

  componentDidMount() {
    if (window.location.hash === "#reset") {
      window.location.hash = "";
      this.setState({
        currentForm: "RESET_PASSWORD"
      });
      return;
    }

    if (store.get("ipRestricted")) {
      this.setState({
        loginFormError: `Your current IP Address is not allowed to access ${Branding.brandingName}.`
      });
    }

    const $ = window.$;
    const theme = localStorage.getItem('appTheme')
    $("body")
        .addClass(`theme-${theme}`);
  },

  render() {
    const { theme } = this.props;
    const isSmallScreen = this.props.containerWidth < smallScreenWidthBreakpoint;
    const containerStyle = {
      background: this.props.theme.palette.background.card,
      paddingTop: "1.5rem",
      paddingBottom: "1rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      position: isSmallScreen ? "relative" : "absolute",
      top: isSmallScreen ? 0 : "44%",
      left: isSmallScreen ? 0 : "50%",
      marginRight: isSmallScreen ? 0 : "-50%",
      transform: isSmallScreen ? "none" : "translate(-50%, -50%)",
      width: isSmallScreen ? "100%" : "550px",
      height: isSmallScreen ? "100%" : "auto",
      minHeight: isSmallScreen ? "100vh" : "auto",
      borderRadius: "15px",
      textAlign: "center",
    };
    const logoImgStyle = {
      display: "block",
      height: 64,
      width: "auto",
      textAlign: "center",
      margin: "0 auto",
      marginTop: "0.5rem",
      marginBottom: "1.5rem",
    };
    const renderFnByFormType = {
      LOGIN: this.renderLoginForm,
      CHANGE_PASSWORD: this.renderChangePasswordForm,
      RESET_PASSWORD: this.renderResetPasswordForm
    };
    const renderFormView = renderFnByFormType[this.state.currentForm];
    const cubeLogo = theme.themeId === "light" ? LightLogo : DarkLogo;
    return (
        <div style={containerStyle}>
          <img src={cubeLogo} alt={`${Branding.brandingName} logo`} style={logoImgStyle} />
          <div style={{paddingBottom: "1rem"}}>
            {renderFormView()}
          </div>
        </div>
    );
  },

  renderLoginForm() {
    const {username, password, isLoggingIn, loginFormError} = this.state;
    const { theme } = this.props;
    const isSmallScreen = this.props.containerWidth < smallScreenWidthBreakpoint;
    const flatButtonContainerStyle = {
      display: isSmallScreen ? "block" : "inline-block",
      textAlign: "center",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      marginTop: "20px",
    };

    const separatorTextCss = css(`
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid ${theme.palette.border.main};
}

.separator:not(:empty)::before {
  margin-right: .5em;
}

.separator:not(:empty)::after {
  margin-left: .5em;
}
`);
    return (
        <div>
          <Global styles={separatorTextCss} />
          <TextButton
              type="inverted"
              iconType={"bhi"}
              icon={"bull"}
              iconStyle={{color: Colors.bullhornOrange, fontSize: "1.5em", position: "relative", top: -1}}
              label="Sign in with Bullhorn"
              labelStyle={{textTransform: "none"}}
              onClick={loginViaSso} />

          <div className={"separator"}>or</div>

          {loginFormError && <ErrorMsg text={loginFormError} />}

          <div style={textInputContainerStyle}>
            <TextInput
                id={"username"}
                autoComplete={"username"}
                autoFocus={true}
                theme={theme}
                placeholder="Username"
                value={username}
                onChange={this.handleUsernameChange}
                onKeyPress={keyPressed => {
                  if (keyPressed === "Enter") {
                    this.handleLoginRequest();
                  }
                }}
                style={loginFormError && !username ? textInputErrorStyle(theme) : textInputStyle(theme)} />
          </div>
          <div style={textInputContainerStyle}>
            <TextInput
                id={"password"}
                autoComplete={"current-password"}
                theme={theme}
                type="password"
                placeholder="Password"
                onChange={this.handlePasswordChange}
                onKeyPress={keyPressed => {
                  if (keyPressed === "Enter") {
                    this.handleLoginRequest();
                  }
                }}
                style={loginFormError && !password ? textInputErrorStyle(theme) : textInputStyle(theme)} />
          </div>
          <TextButton
              style={primaryButtonStyle}
              disabled={isLoggingIn}
              type="primary"
              label="Login"
              onClick={this.handleLoginRequest} />
          <div style={{display: "flex", justifyContent: "space-evenly", flexWrap: "wrap"}}>
            <TextButton
                style={flatButtonContainerStyle}
                type="secondary"
                label="Change Password"
                labelStyle={{textTransform: "none"}}
                onClick={this.switchToChangePasswordForm} />
            <TextButton
                style={flatButtonContainerStyle}
                type="secondary"
                label="Reset Password"
                labelStyle={{color: `${theme.palette.primary.main}`, textTransform: "none"}}
                onClick={this.switchToResetPasswordForm} />
          </div>
        </div>
    );
  },

  renderChangePasswordForm() {
    const {
      username,
      password,
      newPassword,
      newPasswordDuplicate,
      isUpdatingPassword,
      changePasswordFormErrors
    } = this.state;
    const { theme } = this.props;
    const formError = changePasswordFormErrors.get("formError");
    const newPasswordValidationErrors = changePasswordFormErrors.get("newPasswordValidationErrors");
    return (
        <div>
          <div>
            {isUpdatingPassword && <LoadingMsg text="Updating password" />}
            {newPasswordValidationErrors &&
            this.renderNewPasswordValidationErrors(newPasswordValidationErrors)}
            {formError && <ErrorMsg text={formError} />}
          </div>
          <Layout allSmall={12} allMedium={6} rowStyle={rowStyle}>
            <TextInput
                id={"username"}
                autoComplete={"username"}
                theme={theme}
                placeholder="Username"
                value={username}
                onChange={this.handleUsernameChange}
                onKeyPress={keyPressed => {
                  if (keyPressed === "Enter") {
                    this.handleChangePasswordRequest();
                  }
                }}
                disabled={isUpdatingPassword}
                style={formError && !username ? textInputErrorStyle(theme) : textInputStyle(theme)} />
            <TextInput
                id={"password"}
                theme={theme}
                type="password"
                placeholder="Current Password"
                autoComplete={"current-password"}
                onChange={this.handlePasswordChange}
                onKeyPress={keyPressed => {
                  if (keyPressed === "Enter") {
                    this.handleChangePasswordRequest();
                  }
                }}
                disabled={isUpdatingPassword}
                style={formError && !password ? textInputErrorStyle(theme) : textInputStyle(theme)} />
          </Layout>
          <Layout allSmall={12} allMedium={6} rowStyle={rowStyle}>
            <TextInput
                id={"new-password"}
                theme={theme}
                type="password"
                placeholder="New Password"
                autoComplete={"new-password"}
                onChange={this.handleNewPasswordChange}
                onKeyPress={keyPressed => {
                  if (keyPressed === "Enter") {
                    this.handleChangePasswordRequest();
                  }
                }}
                disabled={isUpdatingPassword}
                style={(formError && !newPassword) || newPasswordValidationErrors ?
                    textInputErrorStyle(theme) : textInputStyle(theme)} />
            <TextInput
                id={"confirm-new-password"}
                theme={theme}
                type="password"
                placeholder="Confirm New Password"
                autoComplete={"new-password"}
                onChange={newPasswordDuplicate => this.setState(state => ({
                  newPasswordDuplicate,
                  changePasswordFormErrors: state.changePasswordFormErrors.delete("formError")
                }))}
                onKeyPress={keyPressed => {
                  if (keyPressed === "Enter") {
                    this.handleChangePasswordRequest();
                  }
                }}
                disabled={isUpdatingPassword}
                style={formError && !newPasswordDuplicate ? textInputErrorStyle(theme) : textInputStyle(theme)} />
          </Layout>
          <TextButton
              label="Change Password"
              type="primary"
              labelStyle={{fontSize: "14px"}}
              style={primaryButtonStyle}
              disabled={isUpdatingPassword}
              onClick={this.handleChangePasswordRequest} />
          <br />
          <TextButton
              variant="text"
              label="Back to Login"
              labelStyle={{color: "#BDBDBD", textTransform: "none"}}
              type="secondary"
              backgroundColor="transparent"
              style={flatButtonStyle}
              onClick={this.switchToLoginForm} />
        </div>
    );
  },

  renderNewPasswordValidationErrors(unmetPasswordCriterias) {
    const totalUnmetCriterias = unmetPasswordCriterias.count();
    let criteriasCountLabel = " ";
    if (totalUnmetCriterias > 3) {
      criteriasCountLabel = " at least 3 of ";
    } else if (totalUnmetCriterias === 3) {
      criteriasCountLabel = " at least 2 of ";
    } else if (totalUnmetCriterias === 2) {
      criteriasCountLabel = " at least 1 of ";
    }
    const columnStyle = {
      marginBottom: "0.5rem",
      borderBottom: "1px solid #cfcfcf"
    };

    const { theme } = this.props;

    return (
        <div>
          <Layout allSmall={12} allMedium={7} smallCentered={true} columnStyle={columnStyle}>
            <div style={{textAlign: "center", color: theme.palette.primary.main, lineHeight: 1.5}}>
              Your data security is our priority
            </div>
          </Layout>
          <Layout allSmall={12} allMedium={7} smallCentered={true}>
            <div style={{fontSize: "0.875rem", lineHeight: 1.5, textAlign: "left", paddingBottom: "0.5rem"}}>
              {`Your new password must meet${criteriasCountLabel}the following ${totalUnmetCriterias > 1 ? "criterias" :
                  "criteria"}:`}
            </div>
          </Layout>
          <Layout allSmall={12} allMedium={7} smallCentered={true} columnStyle={columnStyle}>
            <div style={{fontSize: "0.875rem", textAlign: "left"}}>
              {unmetPasswordCriterias
                  .map((criteria, index) =>
                      <ErrorMsg key={index} text={criteria} customStyle={{paddingTop: 0}} />)}
            </div>
          </Layout>
        </div>
    );
  },

  renderResetPasswordForm() {
    const {
      username,
      isSendingResetPasswordEmail,
      showResetPasswordEmailSentMsg,
      resetPasswordFormError
    } = this.state;
    const { theme } = this.props;
    const isSmallScreen = this.props.containerWidth < smallScreenWidthBreakpoint;
    const resetPasswordButtonStyle = {
      ...primaryButtonStyle,
      marginTop: isSmallScreen ? "1rem" : 0,
      marginBottom: 0
    };
    return (
        <div>
          <div>
            {resetPasswordFormError && <ErrorMsg text={resetPasswordFormError} />}
            {isSendingResetPasswordEmail && <LoadingMsg text="Sending reset password email" />}
            {showResetPasswordEmailSentMsg &&
            <ResetPasswordEmailSent theme={theme} onMsgTimeout={() => this.setState({showResetPasswordEmailSentMsg: false})} />}
          </div>
          <Layout allSmall={12} medium={[6, 6]} rowStyle={rowStyle}>
            <TextInput
                id={"username"}
                theme={theme}
                placeholder="Username"
                autoComplete={"username"}
                value={username}
                onChange={this.handleUsernameChange}
                onKeyPress={keyPressed => {
                  if (keyPressed === "Enter") {
                    this.handleResetPasswordRequest();
                  }
                }}
                style={resetPasswordFormError ? textInputErrorStyle(theme) : textInputStyle(theme)} />
            <TextButton
                type="primary"
                variant="contained"
                label="Reset Password"
                disabled={!!resetPasswordFormError}
                onClick={this.handleResetPasswordRequest} />
          </Layout>
          <TextButton
              variant="text"
              label="Back to Login"
              labelStyle={{color: "#BDBDBD", textTransform: "none"}}
              type="secondary"
              backgroundColor="transparent"
              style={flatButtonStyle}
              onClick={this.switchToLoginForm} />
        </div>
    );
  },

  switchToChangePasswordForm() {
    this.setState(state => ({
      ...this.getInitialState(),
      username: state.username,
      currentForm: "CHANGE_PASSWORD",
      changePasswordFormErrors: state.changePasswordFormErrors
    }));
  },

  switchToResetPasswordForm() {
    this.setState(state => ({
      ...this.getInitialState(),
      username: state.username,
      currentForm: "RESET_PASSWORD"
    }));
  },

  switchToLoginForm() {
    this.setState(state => ({
      ...this.getInitialState(),
      username: state.username,
      currentForm: "LOGIN"
    }));
  },

  handleUsernameChange(username) {
    this.setState(state => ({
      username,
      loginFormError: null,
      changePasswordFormErrors: state.changePasswordFormErrors.delete("formError"),
      resetPasswordFormError: null
    }));
  },

  handlePasswordChange(password) {
    this.setState(state => ({
      password,
      loginFormError: null,
      changePasswordFormErrors: state.changePasswordFormErrors.delete("formError")
    }));
  },

  handleNewPasswordChange(newPassword) {
    const unmetPasswordCriterias = checkNewPasswordComplexity(newPassword);
    this.setState(state => {
      const changePasswordFormErrors = unmetPasswordCriterias.isEmpty() ?
          state.changePasswordFormErrors.delete("newPasswordValidationErrors") :
          state.changePasswordFormErrors.set("newPasswordValidationErrors", unmetPasswordCriterias);
      return {
        newPassword,
        changePasswordFormErrors
      };
    });
  },

  handleLoginRequest() {
    const {username, password} = this.state;
    if (!username || !password) {
      this.setState({
        loginFormError: missingFieldsError
      });
    } else {
      this.setState({
        isLoggingIn: true,
        loginFormError: null
      });
      auth
          .login(username, password)
          .then(() => {
            eventBus.trigger("auth:logged-in-new");
          }, error => {
            if (error && error.type === "CHANGE_PASSWORD_REQUIRED") {
              this.setState(state => ({
                changePasswordFormErrors: state.changePasswordFormErrors.set("formError", error.message)
              }), this.switchToChangePasswordForm);
            } else {
              const errorMsg = error ? error.message : unexpectedError;
              this.setState({
                isLoggingIn: false,
                loginFormError: errorMsg
              });
            }
          });
    }
  },

  handleChangePasswordRequest() {
    const {
      username,
      password,
      newPassword,
      newPasswordDuplicate
    } = this.state;
    if (!username || !password || !newPassword || !newPasswordDuplicate) {
      this.setState(state => ({
        changePasswordFormErrors: state.changePasswordFormErrors.set("formError", missingFieldsError)
      }));
      return;
    }

    if (newPassword === password) {
      this.setState(state => ({
        changePasswordFormErrors: state.changePasswordFormErrors.set("formError", newPasswordSameAsCurrentPasswordError)
      }));
    } else if (newPassword !== newPasswordDuplicate) {
      this.setState(state => ({
        changePasswordFormErrors: state.changePasswordFormErrors.set("formError", newPasswordConfirmationFailed)
      }));
    } else {
      this.setState(state => ({
        isUpdatingPassword: true,
        changePasswordFormErrors: state.changePasswordFormErrors.clear()
      }));
      const unmetPasswordCriterias = checkNewPasswordComplexity(newPassword);
      if (unmetPasswordCriterias.isEmpty()) {
        auth
            .changePassword(username, password, newPassword)
            .then(() => {
              eventBus.trigger("auth:logged-in-new");
            }, error => {
              if (error.data.validationErrors) {
                this.setState(state => ({
                  isUpdatingPassword: false,
                  changePasswordFormErrors: state.changePasswordFormErrors.set(
                      "newPasswordValidationErrors",
                      Immutable.List(error.data.validationErrors))
                }));
              } else {
                const errorMsg = error.message || unexpectedError;
                this.setState(state => ({
                  isUpdatingPassword: false,
                  changePasswordFormErrors: state.changePasswordFormErrors.set("formError", errorMsg)
                }));
              }
            });
      } else {
        this.setState(state => ({
          isUpdatingPassword: false,
          changePasswordFormErrors: state.changePasswordFormErrors.set("newPasswordValidationErrors", unmetPasswordCriterias)
        }));
      }
    }
  },

  handleResetPasswordRequest() {
    const {username} = this.state;
    if (!username) {
      this.setState({
        resetPasswordFormError: missingFieldsError
      });
    } else {
      this.setState({
        isSendingResetPasswordEmail: true,
        resetPasswordFormError: null
      });
      auth
          .requestPasswordReset(username)
          .then(() => {
            this.setState({
              isSendingResetPasswordEmail: false,
              showResetPasswordEmailSentMsg: true
            });
          })
          .catch(error => {
            const errorMsg = error?.responseJSON?.message ?? error?.message ?? unexpectedError;
            this.setState({
              isSendingResetPasswordEmail: false,
              resetPasswordFormError: errorMsg
            });
          });
    }
  }

}));

const ResetPasswordEmailSent = createReactClass({

  mixins: [PureRenderMixin],

  componentDidMount() {
    this.msgTimeoutId = window.setTimeout(() => {
      this.props.onMsgTimeout();
    }, 3000);
  },

  componentWillUnmount() {
    window.clearTimeout(this.msgTimeoutId);
  },

  render() {
    const { theme } = this.props;

    return (
        <div style={infoMsgStyle(theme)}>
          <i className="fa fa-exclamation-triangle" style={{paddingRight: 8}} />
          <span>Reset password email sent. Please check your email.</span>
        </div>
    );
  }

});

const TextInput = pure(({
  id,
  type = "text",
  placeholder = "",
  value,
  onChange,
  onKeyPress = () => {},
  style = {},
  autoComplete,
  autoFocus,
  theme
}) => (
    <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={evt => onChange(evt.target.value)}
        onKeyPress={evt => onKeyPress(evt.key)}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        style={{
          ...textInputStyle(theme),
          ...style
        }} />
));

const LoadingMsg = React.memo(({text}) => {
  const { theme } = React.useContext(CustomThemeContext);

  return (
      <div style={infoMsgStyle(theme)}>
        <i className="fa fa-spinner fa-pulse" />
        <span style={{paddingLeft: 8}}>{text}</span>
      </div>
  )
});

const ErrorMsg = React.memo(({text, customStyle = {}}) => {
  const { theme } = React.useContext(CustomThemeContext);

  return (
      <div style={{...infoMsgStyle(theme), ...customStyle}}>
        <i className="fa fa-exclamation-triangle" style={{paddingRight: 8}} />
        {text}
      </div>
  )
});

export const checkNewPasswordComplexity = newPassword => {
  let unmetCriterias = Immutable.List();

  const minPasswordLength = 10;
  if (newPassword.length < minPasswordLength) {
    unmetCriterias = unmetCriterias.push(`be at least ${minPasswordLength} characters long`);
  }

  const maxPasswordLength = 128;
  if (newPassword.length > maxPasswordLength) {
    unmetCriterias = unmetCriterias.push(`be less than ${maxPasswordLength} characters long`);
  }

  return unmetCriterias;
};

const rowStyle = {
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem"
};

const infoMsgStyle = (theme) => ({
  fontSize: "0.875rem",
  color: theme.themeId === "light" ? theme.palette.text.main : "#CFCFCF",
  lineHeight: 1.5,
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem"
});

const textInputContainerStyle = {
  padding: "0.5rem"
};

const textInputStyle = (theme) => ({
  border: `2px solid ${theme.palette.background.paper}`,
  display: "block",
  color: "#000",
  fontSize: "1rem",
  fontFamily: "inherit",
  height: "48px",
  width: "100%",
  maxWidth: "350px",
  margin: "0 auto",
  padding: "0.5rem",
  borderRadius: 3,
  backgroundColor: "#fff",
  transition: "all 0.25s ease-in-out",

  ":active": {
    border: "1px solid #777"
  },

  ":focus": {
    border: "1px solid #777"
  }
});

const textInputErrorStyle = (theme) => ({
  ...textInputStyle(theme),
  border: "1px solid #f00",
  backgroundColor: "#fbb",
  ":active": {
    boxShadow: "0 0 5px #999"
  },
  ":focus": {
    boxShadow: "0 0 5px #999"
  }
});

const raisedButtonHeight = "48px !important";
const primaryButtonStyle = {
  height: raisedButtonHeight,
  minWidth: "165px",
  marginTop: "1rem",
  marginBottom: "1rem"
};

const flatButtonStyle = {
  marginTop: "0.5rem",
  marginBottom: "0.5rem"
};
