/** @jsxImportSource @emotion/react */

import React from "react";
import * as Colors from "js/common/cube19-colors";
import Dialog from "js/common/views/dialog";
import {IconButton} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRedo, faTrashAlt, faUndo} from "@fortawesome/pro-regular-svg-icons";
import {faCog, faSave} from "@fortawesome/pro-solid-svg-icons";
import Tooltip from "js/common/views/tooltips";
import ErrorTooltip from "js/squids/squid-display/edit-mode/error-tooltip";
import {TextButton} from "js/common/views/inputs/buttons";
import {css} from "@emotion/react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const ErrorToolbar = React.memo(({
  errors,
  undo,
  redo,
  numberOfUndoChanges,
  numberOfRedoChanges,
  saveSquid,
  deleteSquid,
  mode,
  setIsDrawerOpen,
  isDeleteDialogOpen,
  setIsDeleteDialogOpen,
  style,
  theme
}) => {

  const backgroundColor = style ? style.get("backgroundColor") : (theme.themeId === "dark" ? Colors.greyDark : "#fff");
  const iconColor = style ? style.get("iconColor") : (theme.themeId === "dark" ? Colors.white : Colors.greyDark);

  const errorTooltipContent = () => {
    const duplicateFormulaErrors = errors.filter(e => e.get("category") === "custom-formula" && e.get("type") ===
        "duplicate-id");
    const templateStringErrors = errors.filter(e => e.get("type") !== "duplicate-id");

    const getTemplateStringError = category => {
      if (templateStringErrors.some(e => e.get("category") === category)) {
        switch (category) {
          case "description":
            return <ul>
              <li>See Description: Error in template String</li>
            </ul>;
          case "node":
            return <ul>{templateStringErrors
                .filter(e => e.get("category") === "node")
                .map((ne, index) => <li key={index}>
                  <span>See Node <b>{ne.getIn(["location", "nodeId"])}</b> - {ne.get("message")}</span>
                </li>)}
            </ul>;
          case "edge":
            return <ul>{templateStringErrors
                .filter(e => e.get("category") === "edge")
                .map((ee, index) => <li key={index}>
                  <span>See EdgesOut in node <b>{ee.getIn(["location", "nodeId"])}</b> - {ee.get("message")}</span>
                </li>)}
            </ul>;
          case "step":
            return <ul>{templateStringErrors
                .filter(e => e.get("category") === "step")
                .map((se, index) => <li key={index}>
                  <span>See Step {se.getIn(["location", "stepIndex"]) + 1} - {se.get("message")}</span>
                </li>)}
            </ul>;
          case "custom-formula":
            return <ul>{templateStringErrors
                .filter(e => e.get("category") === "custom-formula")
                .map((fe, index) => <li key={index}>
                  <span>See Custom Formula <b>{fe.getIn(["location", "formulaId"])}</b> - {fe.get("message")}</span>
                </li>)}
            </ul>;
          default:
            return;
        }
      }
    };

    return <div css={errorTooltipStyles}>
      {duplicateFormulaErrors.count() ?
          <React.Fragment>
            <h4>Duplicate Formula ID ({duplicateFormulaErrors.count()})</h4>
            <ul>
              {duplicateFormulaErrors
                  .map((e, index) => <li key={index}>
                    <span>{e.get("message")}</span>
                  </li>)}
            </ul>
          </React.Fragment> : ""}
      {templateStringErrors.count() ?
          <React.Fragment>
            <h4>Template String Errors ({templateStringErrors.count()})</h4>
            {getTemplateStringError("description")}
            {getTemplateStringError("custom-formula")}
            {getTemplateStringError("node")}
            {getTemplateStringError("step")}
            {getTemplateStringError("edge")}
          </React.Fragment> : ""}
    </div>;
  };

  const checkForErrors = () => {
    if (errors.count()) {
      return <div style={toolbarStatusStyle}>
        <ErrorTooltip
            content={errorTooltipContent()} position="top">
        <span
            css={{
              paddingTop: "0.5rem",
              [`@media(max-width: 1199px)`]: {display: "none"}
            }}>Please resolve ({errors.count()} issue{errors.count() >
        1 ? "s" : ""}) before saving</span>
          <span
              css={{
                paddingTop: "0.5rem",
                [`@media(min-width: 1200px)`]: {display: "none"}
              }}>Resolve ({errors.count()} issue{errors.count() >
          1 ? "s" : ""}) to save</span>
        </ErrorTooltip>
      </div>;
    } else {
      return numberOfUndoChanges ?
          <div style={{...toolbarStatusStyle, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <span>{numberOfUndoChanges +
              " unsaved change"}{numberOfUndoChanges === 1 ?
                  "" : "s"}</span>
            <div style={{display: "inline-block"}}>
              <Tooltip
                  text="Save Squid"
                  position="top"
                  styleType="dark"
                  customStyle={{left: "-1.5rem"}}>
                <IconButton css={saveIconButtonStyle} onClick={saveSquid}>
                  <FontAwesomeIcon icon={faSave} color={iconColor} style={{fontSize: "0.8em"}} />
                </IconButton>
              </Tooltip>
            </div>
          </div> :
          <div style={{...toolbarStatusStyle, color: Colors.greenLight}}>
            <span>✓ All changes saved</span>
          </div>;
    }
  };

  const deleteDialogActions = <>
    <TextButton
        key="cancel-btn"
        label="Cancel"
        style={{backgroundColor: Colors.greyLight, color: "#fff", textTransform: "none"}}
        onClick={() => setIsDeleteDialogOpen(false)} />
    <TextButton
        key="delete-btn"
        style={{backgroundColor: Colors.java, textTransform: "none"}}
        label="Delete"
        onClick={deleteSquid} />
  </>;


  return <div
      style={{
        position: "absolute",
        bottom: "2rem",
        left: "50%",
        zIndex: 99,
        transform: "translateX(-50%)",
        marginLeft: "-185px",
        width: "40%",
        minWidth: "400px",
        maxWidth: "540px",
        opacity: mode === "EDIT" ? 100 : 0
      }}>
    <div
        style={{
          display: "flex",
          alignItems: "center",
          height: 54,
          borderRadius: 5,
          backgroundColor: backgroundColor,
          padding: "0.5rem 0.5rem 0.5rem 1rem",
          transition: "all 0.3s"
        }}>
      {checkForErrors()}
      <div style={{minWidth: 70, marginRight: "1rem"}}>
        <IconButton
            style={{width: 20, height: 15, marginRight: 10, backgroundColor: "transparent"}}
            onClick={undo}
            disabled={!numberOfUndoChanges}>
          <FontAwesomeIcon icon={faUndo} color={iconColor} style={{fontSize: "0.8em"}} />
        </IconButton>
        <IconButton
            css={{width: 20, height: 15, backgroundColor: "transparent"}}
            onClick={redo}
            disabled={!numberOfRedoChanges}>
          <FontAwesomeIcon icon={faRedo} color={iconColor} style={{fontSize: "0.8em"}} />
        </IconButton>
      </div>
      <div>
        <IconButton
            style={{width: 20, height: 15, marginRight: 15, backgroundColor: "transparent"}}
            disableRipple
            onClick={() => setIsDrawerOpen(true)}>
          <FontAwesomeIcon icon={faCog} color={iconColor} style={{fontSize: "0.8em"}} />
        </IconButton>
        <div style={{display: "inline-block"}}>
          <Tooltip text="Delete Squid" position="top" styleType="dark">
            <IconButton
                style={{width: 20, height: 15, backgroundColor: "transparent"}}
                onClick={() => setIsDeleteDialogOpen(true)}>
              <FontAwesomeIcon icon={faTrashAlt} color={iconColor} style={{fontSize: "0.8em"}} />
            </IconButton>
          </Tooltip>
        </div>
        <Dialog
            open={isDeleteDialogOpen}
            onBackdropClick={() => setIsDeleteDialogOpen(false)}
            theme="cube2021"
            iconStyle="warning"
            title="Are you sure you want to delete this squid?"
            actions={deleteDialogActions} />
      </div>
      <hr style={{backgroundColor: errors.count() ? Colors.redIcon : Colors.java, ...statusColorBarStyles}} />
    </div>
  </div>;
});

ErrorToolbar.displayName = "ErrorToolbar";

const toolbarStatusStyle = {
  minWidth: 150,
  flex: 1,
  color: Colors.redLight,
  whiteSpace: "nowrap"
};

const errorTooltipStyles = css`
  h4 {
    margin-bottom: 0.5rem;
  }

  ul {
    > li {
      list-style-type: none;

      &::before {
        content: " ";
        background-color: red;
        border-radius: 2px;
        margin-right: 1rem;
        width: 7px;
        height: 7px;
        padding: 5px;
        display: inline-block;
      }
    }
  }
`;

const saveIconButtonStyle = css`
  &:hover {
    background-color: transparent !important;
  }
`;

const statusColorBarStyles = {
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  margin: 0,
  height: "4px",
  border: 0,
  borderRadius: "0 0 5px 5px"
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ErrorToolbar theme={theme} {...props} />;
};
