import Immutable from "immutable";

import eventBus from "js/cube19.event-bus";
import * as ajax from "js/common/ajax";

const path = window.path;
const baseUrl = "ratio-configs";

let ratioConfigsById = Immutable.Map();
let displayTypes = Immutable.List();

const load = () => Promise.all([ loadDisplays(), loadConfigs() ]);

const loadDisplays = () => ajax
    .get({ url: `${baseUrl}/displays` })
    .then(response => {
        displayTypes = Immutable.fromJS(response);
    });

const loadConfigs = () => ajax
    .get({ url: baseUrl })
    .then(response => {
        const ratioConfigs = Immutable.fromJS(response);
        ratioConfigs.forEach(ratio => {
            ratioConfigsById = ratioConfigsById.set(ratio.get("id"), ratio);
        });
        eventBus.trigger("data-loaded", "ratio-configs");
    }, error => {
        eventBus.trigger("error:fatal", error);
    });

const get = id => ratioConfigsById.get(id);

const getAll = () => ratioConfigsById;

const getDisplayTypes = () => displayTypes;

const add = ratioConfig => ajax
    .post({
        url: baseUrl,
        json: ratioConfig.toJSON()
    })
    .then(response => {
        const newRatioConfig = Immutable.fromJS(response);
        ratioConfigsById = ratioConfigsById.set(newRatioConfig.get("id"), newRatioConfig);
        return ratioConfigsById;
    });

const update = ratioConfig => {
    const ratioId = ratioConfig.get("id");
    return ajax
        .put({
            url: path(baseUrl, ratioId),
            json: ratioConfig.toJSON()
        })
        .then(response => {
            const updatedRatioConfig = Immutable.fromJS(response);
            ratioConfigsById = ratioConfigsById.set(ratioId, updatedRatioConfig);
            return ratioConfigsById;
        });
};

const remove = ratioId => ajax
    .del({ url: path(baseUrl, ratioId) })
    .then(() => {
      ratioConfigsById = ratioConfigsById.setIn([ratioId, "isDeleted"], true);
      return ratioConfigsById;}
    );

export {
    load,
    get,
    getAll,
    add,
    update,
    remove,
    getDisplayTypes
};
