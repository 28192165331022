import Immutable from "immutable";
import { color } from "novo-design-tokens";

// backgrounds
export const charade = "#272731";
export const trout = "#484856";
export const boulder = "#757575";
export const wildSand = "#f2f2f2";
export const tuna = "#32323f";

export const bullhornNavy = color.midnight;
export const bullhornLightGrey = "#f2f2f2";
export const bullhornGrey = "#d6d6d6";
export const bullhornLightBlue = "#4a89dc";
export const bullhornOrange = color.bullhorn;


export const c19Yellow = "#f9ec33";
export const c19Green = "#61C152";
export const c19Red = "#F12F2F";

// Text
export const disabledColor = "#757575";
export const errorColor = "#f44336"

// App area accents
export const oneViewColor = c19Yellow;
export const tvColor = "#ea4040";
export const sliceDiceColor = "#956bf5";
export const chartingColor = "#52dcf5";
export const jobsPipelineColor = "#48efa5";
export const onPointColor = "#ff7926";

// Group inheritance accents
export const musicColor = "#188c87";
export const pageColor = "#8350e6";
export const currencyColor = "#c74a4a";
export const localeColor = "#ff6a3e";
export const attributeNotSetColor = "rgba(0,0,0,0.1)";
export const attributeNotSetColorLight = "rgba(242,242,242,1)";

// Cube 2021 Theme
export const darkestGrey = "#1B1B1E";
export const greyDark = charade;
export const grey = tuna;
export const greyLight = "#484856";
export const greyBorder = "#3F536E";
export const greyText = "#8DABC4";
export const java = "#26c1c9";
export const greenLight = "#9AC379";
export const redLight = "#FF7A7A";
export const lightestRed = "#F4B8C6"
export const accentPurple = "#AB7DF6";
export const lightPurple = "#D9C2FF"
export const tableBorder = "#334B69";
export const blueBorder = "#67859E";

//Squids
export const labelBg = tuna;
export const stepBg = "#314764";
export const lightText = "#5A6574";
export const accentBlue = "#5452f7";
export const offWhite = "#ecf5fd";
export const lightBackground = "#F6F6F6";
export const lightestGrey = "#d4d4d4";
export const turquoiseLight = "#2fe1eb"
export const midGrey = "#989898";
export const futuristicPink = "#E80A89";

//React Select Colors


// Legacy
export const yellowText = c19Yellow;

// Light Theme
export const light1 = "#00ff00";
export const light2 = "#ff0000";
export const light3 = "#000ff0";
// Base
export const white = "#FFFFFF";
export const black = "#000000"

//icon picker colours
export const blueIcon = "#26C1C7";
export const yellowIcon = "#F9EC33";
export const pinkIcon = "#F67DDB";
export const purpleIcon = "#AB7DF6";
export const greenIcon = "#93D45F";
export const redIcon = "#E1453B";
export const orangeIcon = "#fc9403"
export const darkGreenIcon = "#2c9132"

export const iconColors = Immutable.fromJS([redIcon, redLight, orangeIcon, yellowIcon, greenIcon, darkGreenIcon, accentBlue, blueIcon, purpleIcon, pinkIcon, white, greyLight]);

// named colors

//branding
export const goldenFizz = "#f9ec33"; //worth renaming to c19Yellow? seems like no because theme references as accent color
export const malachite = "#61C152"; //success cube green
export const pomegranate = "#F12F2F"; //error cube red

//app headings
export const cinnabar = "#ea4040"; //cubeTv
export const crusta = "#ff7926"; // onpoint
export const turquoise = "#48efa5"; //jobePipeline
export const malibu = "#52dcf5"; // charting
export const portage = "#956bf5"; // sliceandDice

//oneView
export const silverChalice = "#9e9e9e";

export const tidal = "#e1ffb0"; //matchany
export const anakiwa = "#b0ecff"; // matchblue
export const romantic = "#ffcdb0"; // matchred

// Notifications
export const ocean = "#D7E3F5";
export const banana = "#FFF3B2";

//cubeTv
const cubeTvBg = tuna;
// all colors used outside of theming rename them again contextually to app so we can change later if need
