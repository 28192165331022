import React from 'react';
import pure from "js/common/views/pure";
import PanZoom from './pan-zoom';

/*
  This component provides google maps like interaction to any content that you place in it. It will let
  the user zoom and pan the children by scaling and translating props.children using css.
*/
const PanZoomCSS = pure((props) => {
  return (
      <PanZoom {...props} parentIdentifier="pan-zoom-container">
        {
          ({ translation, scale }) => {
            // Translate first and then scale.  Otherwise, the scale would affect the translation.
            const transform = `translate(${translation.x}px, ${translation.y}px) scale(${scale})`;
            return (
                <div
                    id="pan-zoom-container"
                    style={{
                      height: '100%',
                      width: '100%',
                      position: 'relative', // for absolutely positioned children
                      overflow: 'hidden',
                      touchAction: 'none', // Not supported in Safari :(
                      msTouchAction: 'none',
                      cursor: 'all-scroll',
                      WebkitUserSelect: 'none',
                      MozUserSelect: 'none',
                      msUserSelect: 'none',
                      transition: 'all 0.2s',
                    }}
                >
                  <div
                      style={{
                        transform: transform,
                        transformOrigin: '0 0 '
                      }}
                  >
                    {props.children}
                  </div>
                </div>
            );
          }
        }
      </PanZoom>
  );
}, "PanZoomCSS");

export default PanZoomCSS;
