import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import eventBus from "js/cube19.event-bus";

const SimpleAudioPlayer = createReactClass({

    propTypes: {
        source: ReactPropTypes.string.isRequired,
        theme: ReactPropTypes.object
    },

    getInitialState() {
        const audio = new Audio(this.props.source);
        audio.id = Math.random();
        return {
            audio,
            isPlaying: false
        };
    },

    componentDidMount() {
        this.state.audio.addEventListener("ended", this.onAudioEnded);
        eventBus.on("audio:play", this.onPlayAudio);
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.source !== nextProps.source) {
            this.stopPlayingAudio();
            this.setState({
                audio: new Audio(nextProps.source),
                isPlaying: false
            });
        }
    },

    render() {
        const { isPlaying } = this.state;
        const { theme } = this.props;
        const onClick = isPlaying ? this.onStopClick : this.onPlayClick;
        const toggleClass = `fa fa-${isPlaying ? "stop" : "play"}-circle`;
        return <i className={toggleClass} style={style(theme)} onClick={onClick} />;
    },

    onPlayClick(e) {
        e.stopPropagation();
        eventBus.trigger("audio:play", this.state.audio.id);
        this.state.audio.play();
        this.setState({
            isPlaying: true
        });
    },

    onPlayAudio(audioId) {
        const { audio, isPlaying } = this.state;
        if (isPlaying && audio.id !== audioId) {
            this.stopPlayingAudio();
            this.setState({
                isPlaying: false
            });
        }
    },

    onStopClick(e) {
        e.stopPropagation();
        this.stopPlayingAudio();
        this.setState({
            isPlaying: false
        });
    },

    stopPlayingAudio() {
        const { audio } = this.state;
        audio.pause();
        audio.currentTime = 0;
    },

    onAudioEnded() {
        this.setState({
            isPlaying: false
        });
    },

    componentWillUnmount() {
        const { audio, isPlaying } = this.state;
        if (isPlaying) {
            this.stopPlayingAudio();
        }
        audio.removeEventListener("ended", this.onAudioEnded);
    }

});

const style = theme => ({
    cursor: "pointer",
    fontSize: "1.25rem",
    color: theme.palette.textColor,
    transition: "all 0.3s ease",

    ":hover": {
        color: theme.palette.primary.main
    }
});


export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <SimpleAudioPlayer theme={theme} {...props} />;
};