import React from "react";

import Layout from "js/common/views/foundation-column-layout";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants"

export default React.memo(() => {
    const {theme} = React.useContext(CustomThemeContext);
    return(
    <Layout allSmall={12} allLarge={10} smallCentered={true} collapseRow={true} rowStyle={rowStyle(theme)}>
        <ol style={listStyle}>
            <li>
                Download your company&apos;s current Tags History (or the Example Tags History file if your company does
                not have any Tags assigned yet) and open the CSV file.
            </li>
            <li>
                In the CSV file, go to the User or Group whose Tag assignment you want to change or add a new row for
                the User or Group you want to assign a Tag to. Put in the User&apos;s ID and name or the Group&apos;s ID
                and hierarchy trail in the format required.
            </li>
            <li>
                Set the dates that the Tag assignment is effective for so that the data is recalculated correctly. All
                Tag assignments must have a Start Date assigned. The End Date is optional.
            </li>
            <li>In the Tag column, assign the Tag to the User or Group in the format required.</li>
            <li>Repeat the process for every User and/or Group who needs to be assigned a Tag.</li>
            <li>Upload your new CSV file by selecting the file.</li>
        </ol>

        <p style={{textAlign: "left"}}>The accepted data format for each column are as follows:</p>

        <table style={tableStyle}>
            <thead>
            <tr style={tableRowStyle(theme)}>
                <th style={{...tableColHeadingStyle(theme), textAlign: "left"}}>
                    Column
                </th>
                <th style={tableColHeadingStyle(theme)}>
                    Type
                </th>
                <th style={tableColHeadingStyle(theme)}>
                    Name
                </th>
                <th style={tableColHeadingStyle(theme)}>
                    Start Date
                </th>
                <th style={tableColHeadingStyle(theme)}>
                    End Date <em>(optional)</em>
                </th>
                <th style={tableColHeadingStyle(theme)}>
                    Tag
                </th>
            </tr>
            </thead>
            <tbody>
            <tr style={tableRowStyle(theme)}>
                <th style={tableRowHeadingStyle(theme)}>
                    Accepted Format
                </th>
                <td style={tableColStyle(theme)}>
                    &ldquo;User&rdquo;
                    <br />
                    or
                    <br />
                    &ldquo;Group&rdquo;
                </td>
                <td style={tableColStyle(theme)}>
                    &ldquo;User ID&rdquo;.&ldquo;User Fullname&rdquo;
                    <br />
                    or
                    <br />
                    &ldquo;Group ID&rdquo;.&ldquo;Group Hierarchy Trail&rdquo;
                </td>
                <td style={tableColStyle(theme)}>
                    DD/MM/YYYY
                </td>
                <td style={tableColStyle(theme)}>
                    DD/MM/YYYY
                </td>
                <td style={tableColStyle(theme)}>
                    &ldquo;Category Name&rdquo;/&ldquo;Tag Name&rdquo;
                </td>
            </tr>
            <tr style={tableRowStyle(theme)}>
                <th style={tableRowHeadingStyle(theme)}>
                    Example 1
                </th>
                <td style={tableColStyle(theme)}>
                    User
                </td>
                <td style={tableColStyle(theme)}>
                    <p style={exampleTextStyle}>123.John Doe</p>
                    <p style={refFileInfoStyle}>(reference file: Users List - users.csv)</p>
                </td>
                <td style={tableColStyle(theme)}>
                    25/05/2012
                </td>
                <td style={tableColStyle(theme)}>
                    25/05/2012
                </td>
                <td style={tableColStyle(theme)}>
                    INDUSTRY/OIL AND GAS
                </td>
            </tr>
            <tr style={tableRowStyle(theme)}>
                <th style={tableRowHeadingStyle(theme)}>
                    Example 2
                </th>
                <td style={tableColStyle(theme)}>
                    Group
                </td>
                <td style={tableColStyle(theme)}>
                    <p style={exampleTextStyle}>9.{Branding.brandingName}-London</p>
                    <p style={refFileInfoStyle}>(reference file: Groups List - groups.csv)</p>
                </td>
                <td style={tableColStyle(theme)}>
                    01/04/2010
                </td>
                <td style={tableColStyle(theme)}>
                    &nbsp;
                </td>
                <td style={tableColStyle(theme)}>
                    REGION/EUROPE
                </td>
            </tr>
            </tbody>
        </table>

        <p style={{textAlign: "left"}}>
            When adding a new Tag for a User or Group, please ensure there are no duplicate Tag assignments for a User
            or Group.
        </p>

        <p style={{textAlign: "left"}}>
            If a given User/Group ID does not match the proceeding User Name/Group Hierarchy Trail, this will be
            reported as an error.
        </p>

        <p style={{textAlign: "left"}}>
            Once uploaded, any errors found by the system will be listed in a report with the line numbers of each error
            found in the file. If no errors were found, your company&apos;s Tags History will be updated accordingly.
        </p>
    </Layout>)
});

const rowStyle = (theme) => ({
    margin: "1rem",
    padding: "1rem",
    border: `2px solid ${theme.palette.background.paper}`,
});

const listStyle = {
    textAlign: "left",
    marginTop: "0.625rem",
    marginRight: "1.25rem",
    marginLeft: "1.25rem",
    marginBottom: "1.875rem"
};

const tableStyle = {
    borderCollapse: "separate",
    margin: "0 auto",
    paddingBottom: 25
};

const tableRowStyle = theme => ({
    color: theme.palette.text.main,
});

const tableColStyle = theme => ({
    padding: 10,
    textAlign: "center",
    backgroundColor: theme.themeId === "light" ? theme.palette.background.paper : theme.palette.background.card,
});

const tableColHeadingStyle = (theme) => ({
    ...tableColStyle(theme),
    backgroundColor: theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.paper,
    color: theme.palette.text.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`
});

const tableRowHeadingStyle = theme => ({
    ...tableColStyle(theme),
    textAlign: "left"
});

const exampleTextStyle = {
    fontWeight: "bold",
    letterSpacing: "3px"
};

const refFileInfoStyle = {
    fontStyle: "italic"
};
