/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import {Global, css} from "@emotion/react";
import MenuBar from "js/common/views/menu-bar";
import LoadingSpinner from "js/common/views/loading-spinner";
import * as KeyKpis from "js/admin/kpis/key-kpis/key-kpis";
import * as SavedConfigs from "js/common/saved-configs";
import * as Groups from "js/common/groups";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Users from "js/common/users";
import {indexBy} from "js/common/utils/collections";
import useMountEffect from "js/common/utils/use-mount-effect";
import * as Popups from "js/common/popups";
import * as Auditor from "js/common/auditer";
import {v4 as uuid} from "uuid";
import useErrorBoundary from "use-error-boundary";
import SquidError from "js/squids/squid-error";
import SquidSelection from "js/squids/squid-selection/squid-selection";
import {useMountUnmountEffect} from "js/admin/kpis/edit-kpis/utils";
import store from "store";
import moment from "moment";
import currentClient from "js/common/repo/backbone/current-client";
import PageDisplay from "js/squids/squid-display/squid-display";
import Dialog from "js/common/views/dialog";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Colors from "js/common/cube19-colors";

const SquidApp = () => {

  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const [masterKpiTypeToKpiId, setMasterKpiTypeToKpiId] = React.useState(new Immutable.Map());
  const [idToPageWrapper, setIdToPageWrapper] = React.useState(new Immutable.Map());
  const [idToUndoStack, setIdToUndoStack] = React.useState(Immutable.Map());
  const [squidViewStyle, setSquidViewStyle] = React.useState("grid");

  const storeKeyRoot = React.useMemo(() => {
    const currentClientId = currentClient.get("id");
    const currentUserId = Users.getCurrentUser().get("id");
    return "cube19.squids." + currentClientId + "." + currentUserId;
  }, []);

  const [storedChangeDateTime, setStoredChangeDateTime] = React.useState(() => retrieveStoredChangeDateTime(storeKeyRoot));

  useMountEffect(() => {
    setLoading(true);

    Promise
        .all([KeyKpis.getAll(), SavedConfigs.getAll("SQUID_PAGE")])
        .then(([masterKpis, savedConfigWrapper]) => {
          setMasterKpiTypeToKpiId(indexBy(mk => mk.get("type"), masterKpis).map(mk => mk.get("selectedKpiId")));
            setIdToPageWrapper(
                indexBy(
                    p => p.get("id"),
                    savedConfigWrapper.get("sharedGroupConfigs")
                        .concat(savedConfigWrapper.get("sharedUserConfigs"))
                        .concat(savedConfigWrapper.get("ownedConfigs")))
                    .toMap()
                    .map(page => addStepIds(page)));
          setLoaded(true);
          setLoading(false);
        });
  });

  const addStepIds = (page) => {
    const steps = page.getIn(["json", "steps"], Immutable.List());

    const stepsWithIds = steps.map(step => {
      if (step.has("id")) {
        return step;
      } else {
        return step.set("id", uuid());
      }
    });

    return page.setIn(["json", "steps"], stepsWithIds);
  };

  const hasSquidEditorPermission = React.useMemo(() => {
    return Users.currentHasPermission("SQUID_EDITOR");
  }, []);

  // TODO get defaults same way jobs pipeline does it
  //  possibly receive as props + sensible defaults
  const initialControlConfig = new Immutable.Map({
    timeframe: TimeframeRepo.getDefaultForClient().getRawJson(),
    qualifierId: Groups.getRootGroup().get("id"),
    qualifierType: "GROUP",
    allClientIds: new Immutable.Set(),
    clientIds: new Immutable.Set(),
    clientSetIds: new Immutable.Set(),
    matchAnyTagIds: new Immutable.Set(),
    matchAllTagIds: new Immutable.Set(),
    excludedTagIds: new Immutable.Set(),
  });
  const [controlConfig, setControlConfig] = React.useState(initialControlConfig);
  const [selectedPageId, setSelectedPageId] = React.useState(null);
  const [mode, setMode] = React.useState("VIEW");

  const pageWrapper = idToPageWrapper.get(selectedPageId);
  const previousValues = React.useRef({ selectedPageId, pageWrapper });

  React.useEffect(() => {
    if (
        previousValues.current.selectedPageId !== selectedPageId &&
        previousValues.current.pageWrapper !== pageWrapper
    ) {
      if(selectedPageId) {
        Auditor.audit("squid:loaded", {id: selectedPageId, name: pageWrapper.get("name")});
      }
      previousValues.current = { selectedPageId, pageWrapper };
    }
  });

  const finishCreatingSquid = (newReport, auditString) => {
    Auditor.audit(auditString, {id: newReport.get("id"), name: newReport.get("name")});
    setSelectedPageId(newReport.get("id"));
    setIdToPageWrapper(idToPageWrapper.set(newReport.get("id"), newReport));
    setMode("EDIT");
    setIsDrawerOpen(true);
  };

  const errorCreatingSquid = (err, errorMsg) => {
    const {responseJSON} = err;
    if (responseJSON && responseJSON.errors) {
      Popups.alert(responseJSON.errors, {title: errorMsg});
    } else {
      Popups.error(errorMsg);
    }
  };

  const createEmptySquid = () => {
    const squidNames = idToPageWrapper.valueSeq().map(page => page.get("name")).toList();
    const name = getUniqueName(squidNames, "New Squid ");

    SavedConfigs
        .create(Immutable.fromJS(
            {
              configType: "SQUID_PAGE",
              name: name,
              json: {
                "description": "Add a squid description in settings",
                "defaultDisplay": {
                  "defaultNodeStyle": "dark",
                  "graphDirection": "horizontal",
                  "nodes": [
                    {
                      "id": "initial-node",
                      "style": "highlight"
                    }
                  ]
                },
                "nodes": [
                  {
                    "id": "initial-node",
                    "label": "Start here"
                  }
                ]
              }
            }))
        .then(newReport => {
          finishCreatingSquid(newReport, "squid:created");
        }, err => {
          errorCreatingSquid(err, "Unable to save squid");
        });
  };


  const duplicateSquid = squidId => {
    const squidNameTooLong = idToPageWrapper.getIn([squidId, "name"]).length > 250;
    if(!squidNameTooLong){
    const squidNames = idToPageWrapper.valueSeq().map(page => page.get("name")).toList();
    const name = getUniqueName(squidNames, idToPageWrapper.getIn([squidId, "name"]) + "-copy ");
    const copyOfSquid = Immutable.fromJS(
        {
          configType: "SQUID_PAGE",
          name: name,
          json: idToPageWrapper.getIn([squidId, "json"])
        });

    SavedConfigs
        .create(copyOfSquid)
        .then(newReport => {
          finishCreatingSquid(newReport, "squids:duplicate-report");
        }, err => {
          errorCreatingSquid(err, "Unable to duplicate squid");
        });
    } else{
      Popups.error("Squid name is too long to duplicate");
    }
  };

  const handleEditPageWrapper = React.useCallback((newPageWrapper) => {
    setIdToPageWrapper(idToPageWrapper.set(selectedPageId, newPageWrapper));
  }, [selectedPageId, idToPageWrapper]);

  const squidNames = idToPageWrapper.filter(page => page.get("id") !== selectedPageId).valueSeq()
      .map(p => p.get("name")).toList();

  const deleteSquid = (id = selectedPageId) => {
    const name = idToPageWrapper.getIn([id, "name"])
    SavedConfigs
        .remove(id)
        .then(response => {
          setSelectedPageId(id);
          setSelectedPageId(null);
          setIdToPageWrapper(idToPageWrapper.delete(id));
          Popups.success(`'<strong>${name}</strong>'  deleted`);
        });
  };

  const {ErrorBoundary, didCatch} = useErrorBoundary();

  const MYSQL_DATETIME_PATTERN = "YYYY-MM-DD HH:mm:ss";

  const saveChangesToLocalStorage = (storeKeyRoot, idToPageWrapper, idToUndoStack) => {
    store.set(storeKeyRoot + ".unsaved-changes-datetime", moment().format(MYSQL_DATETIME_PATTERN));
    store.set(storeKeyRoot + ".page-wrapper", idToPageWrapper.toJS());
    store.set(storeKeyRoot + ".undo-stack", idToUndoStack.toJS());
  };

  const unsavedChangesMessage = "You have unsaved changes. Don't worry, we'll hold on to them until you come back.";

  useMountUnmountEffect(ref => {
    window.onbeforeunload = () => {
      const [idToPageWrapper, idToUndoStack] = ref.current;
      if (!idToUndoStack.isEmpty()) {
        saveChangesToLocalStorage(storeKeyRoot, idToPageWrapper, idToUndoStack);
        return unsavedChangesMessage;
      }
    };
    return () => {
      window.onbeforeunload = null;
      const [idToPageWrapper, idToUndoStack] = ref.current;
      if (!idToUndoStack.isEmpty()) {
        alert(unsavedChangesMessage);
        saveChangesToLocalStorage(storeKeyRoot, idToPageWrapper, idToUndoStack);
      }
    };
  }, [idToPageWrapper, idToUndoStack]);

  const handleDiscardStoredChanges = React.useCallback(() => {
    clearStoredChanges(storeKeyRoot);
    setStoredChangeDateTime(null);
  }, [storeKeyRoot]);

  const handleRetrieveStoredChanges = React.useCallback(() => {

    const {storedIdToPageWrapper, storedIdToUndoStack} = retrieveStoredChanges(storeKeyRoot);
    setIdToPageWrapper(storedIdToPageWrapper.mapKeys(key => parseInt(key)));
    setIdToUndoStack(storedIdToUndoStack
        .mapKeys(key => parseInt(key))
        .map(stack => stack.toStack()));

    handleDiscardStoredChanges();
  }, [storeKeyRoot, handleDiscardStoredChanges]);

  let content;
  if (loaded) {
    if (pageWrapper) {
      content = <PageDisplay
          mode={mode}
          setMode={setMode}
          pageWrapper={pageWrapper}
          selectedPageId={selectedPageId}
          idToUndoStack={idToUndoStack}
          setIdToUndoStack={setIdToUndoStack}
          handleEditPageWrapper={handleEditPageWrapper}
          setSelectedPageId={setSelectedPageId}
          masterKpiTypeToKpiId={masterKpiTypeToKpiId}
          controlConfig={controlConfig}
          setControlConfig={setControlConfig}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
          hasSquidEditorPermission={hasSquidEditorPermission}
          squidNames={squidNames}
          deleteSquid={() => deleteSquid(selectedPageId, pageWrapper.get("name"))} />;
    } else {
      content = <SquidSelection
          idToPageWrapper={idToPageWrapper}
          idToUndoStack={idToUndoStack}
          onSelect={setSelectedPageId}
          masterKpiTypeToKpiId={masterKpiTypeToKpiId}
          controlConfig={controlConfig}
          setControlConfig={setControlConfig}
          hasSquidEditorPermission={hasSquidEditorPermission}
          squidViewStyle={squidViewStyle}
          setSquidViewStyle={setSquidViewStyle}
          createEmptySquid={createEmptySquid}
          deleteSquid={deleteSquid}
          duplicateSquid={duplicateSquid} />;
    }
  } else if (loading) {
    content = <LoadingSpinner />;
  } else {
    content = null;
  }

  const unsavedChangesDialogActions = <>
    <TextButton
        key="cancel-btn"
        type="inverted"
        style={{textTransform: "none"}}
        label="Discard Changes"
        onClick={handleDiscardStoredChanges} />
    <TextButton
        key="delete-btn"
        style={{backgroundColor: Colors.java, color: "#fff", textTransform: "none"}}
        label="Retrieve Changes"
        onClick={handleRetrieveStoredChanges} />
  </>;

  // TODO pass control config into menu bar if necessary for page switches
  return (
      <>
        <Global styles={css`
          body, #content-region {
            height: 100%;
          }

          #content-region > div {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
        `} />
        <MenuBar appView="squid" />
        {didCatch ? <SquidError selectedPageId={selectedPageId} pageWrapper={pageWrapper} /> :
            <ErrorBoundary>
              <Dialog
                  open={!loading && !!storedChangeDateTime}
                  theme="cube2021"
                  iconStyle="warning"
                  title={"You have unsaved changes" + (storedChangeDateTime ? " from " + storedChangeDateTime.format("LLL") : "")}
                  actions={unsavedChangesDialogActions} />
              {content}
            </ErrorBoundary>}
      </>);
};

const getUniqueName = (listOfNames, template) => {
  let number;
  for (let i = 1; !listOfNames.every(n => n !== (template + i)); i++) {
    number = i;
  }
  number = number ? number + 1 : 1;
  return template + number;
};

export const findDuplicates = arr => arr
    .countBy(x => x)
    .filter(count => count > 1)
    .keySeq();

const retrieveStoredChanges = (storeKeyRoot) => {
  const storedIdToPageWrapper = Immutable.fromJS(store.get(storeKeyRoot + ".page-wrapper"));
  const storedIdToUndoStack = Immutable.fromJS(store.get(storeKeyRoot + ".undo-stack"));
  return {storedIdToPageWrapper, storedIdToUndoStack};
};

const MYSQL_DATETIME_PATTERN = "YYYY-MM-DD HH:mm:ss";

const retrieveStoredChangeDateTime = (storeKeyRoot) => {
  const dateStr = store.get(storeKeyRoot + ".unsaved-changes-datetime");
  return dateStr ? moment(dateStr, MYSQL_DATETIME_PATTERN) : null;
};

const clearStoredChanges = (storeKeyRoot) => {
  store.remove(storeKeyRoot + ".unsaved-changes-datetime");
  store.remove(storeKeyRoot + ".page-wrapper");
  store.remove(storeKeyRoot + ".undo-stack");
};

export default SquidApp;
