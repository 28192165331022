import React from "react";
import {Button, Menu, MenuItem} from "@mui/material";
import Icon from "js/admin/common/icon";
import Immutable from "immutable";

const DownloadReferenceButton = ({options = Immutable.Map()}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return <div>
    <Button
        id="basic-button"
        style={{padding: "4px 14px"}}
        variant={"outlined"}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<Icon icon={"collapse"} />}>
      Download Reference Files
    </Button>
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button"
        }}>
      {options.map(o => <MenuItem
          disabled={isLoading}
          key={o.get("label")}
          onClick={() => {
            setIsLoading(true);
            o.get("onClick")(setIsLoading);
          }}>{o.get("label")}</MenuItem>)}
    </Menu>
  </div>;
};

export default DownloadReferenceButton;