import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "js/common/utils/event-ie-polyfill"
import "css/app.css";
import "css/fixed-data-table.css";
import "css/react-input-range.css";
import "css/newsticker.css";
import "css/react-textarea-autocomplete.css";
import "css/top-level.css";
import "css/narrow-tooltip.css";
import "css/priority-curve.css";

import Highcharts from "highcharts/highcharts";
import ReactHighcharts from "react-highcharts/ReactHighcharts";
import ReactHighstock from "react-highcharts/ReactHighstock";

import HighchartsMore from "highcharts/highcharts-more";
import HighchartsSolidGuage from "highcharts/modules/solid-gauge";
import HighchartsNoDataDisplay from "highcharts/modules/no-data-to-display";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsOfflineExporting from "highcharts/modules/offline-exporting";

import Cube19 from "js/cube19.app";
import "js/startup";

import "js/common/utils/fn";

import "js/common/repo/backbone/Users";
import "js/common/repo/backbone/Group";

import "js/cubetv/page.view";
import "js/cubetv/layouts/LeftBigRightSmall";
import "js/cubetv/layouts/LeftBigRight4";
import "js/cubetv/layouts/fullscreen";

HighchartsMore(Highcharts);
HighchartsSolidGuage(Highcharts);
HighchartsNoDataDisplay(Highcharts);
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);

HighchartsMore(ReactHighcharts.Highcharts);
HighchartsSolidGuage(ReactHighcharts.Highcharts);
HighchartsNoDataDisplay(ReactHighcharts.Highcharts);
HighchartsExporting(ReactHighcharts.Highcharts);
HighchartsOfflineExporting(ReactHighcharts.Highcharts);

HighchartsMore(ReactHighstock.Highcharts);
HighchartsSolidGuage(ReactHighstock.Highcharts);
HighchartsNoDataDisplay(ReactHighstock.Highcharts);
HighchartsExporting(ReactHighstock.Highcharts);
HighchartsOfflineExporting(ReactHighstock.Highcharts);

const $ = window.$;

$(document).ready(function() {
    Cube19.start();
});
