import React from "react";
import PureRenderMixin from "react-addons-pure-render-mixin";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";
import howler from "howler";
import moment from "moment";
import createReactClass from "create-react-class";

import BaseCube from "js/cubetv/cubes/models/base-cube";
import UncaughtErrorMsg from "js/common/views/uncaught-error-msg";
import * as Auditor from "js/common/auditer";
import * as Events from "js/common/events";
import * as Ajax from "js/common/ajax";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as Colors from "js/common/cube19-colors";
import currentClient from "js/common/repo/backbone/current-client";
import pure from "js/common/views/pure";
import * as Formatter from "js/common/utils/formatter";
import {toPercentageStr} from "js/common/utils/numbers";
import {capitaliseWords} from "js/common/utils/strings";

import clockwork1 from "img/cubetv/clockwork/clockwork-1.png";
import clockwork2 from "img/cubetv/clockwork/clockwork-2.png";
import clockwork3 from "img/cubetv/clockwork/clockwork-3.png";
import clockwork4 from "img/cubetv/clockwork/clockwork-4.png";
import oldTextureLeft from "img/cubetv/old-style/texture-left.png";
import oldTextureRight from "img/cubetv/old-style/texture-right.png";
import countdownVideo from "video/countdown.mp4";
import silentAudio from "audio/silent.mp3";

import Cube19 from "js/cube19.app";
import UserAvatar from "js/common/views/user-avatar";
import {getSilentMp3Id} from "js/common/repo/deal-music-repo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const $ = window.$;
const path = window.path;

const DealFlashModel = BaseCube.extend({});

Cube19.module("Models.Cubes", function(Cubes, App, Backbone, Marionette, $, _) {

  Cubes.DealFlash = DealFlashModel;

});

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ErrorBoundaryPage theme={theme} {...props} />;
};


const ErrorBoundaryPage = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      uncaughtError: false
    };
  },

  componentDidCatch() {
    this.setState({
      uncaughtError: true
    });
  },

  render() {
    if (this.state.uncaughtError) {
      return <UncaughtErrorMsg />;
    } else {
      return <div style={{height: "100vh"}}><Slide {...this.props} /></div>;
    }
  }

});

const gradient = "linear-gradient(#110662, #030013)";

const Slide = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    const deal = getDealDetails();
    return {
      showCountdownVideo: this.canShowDealFlashAnimation(),
      showDealAnnouncement: false,
      showDealDetails: false,
      dealMusicFileUrl: null,
      sound: null,
      deal
    };
  },

  componentDidMount() {
    $("body").addClass("dealflash");
    this.loadDealSound();
    if (!this.canShowDealFlashAnimation()) {
      this.dealMusicPlaybackMinDelayTimeoutId = window.setTimeout(() => {
        const {dealMusicFileUrl, sound, deal} = this.state;
        if (dealMusicFileUrl) {
          sound.play();
        } else {
          Auditor.audit("deal_flash:sound-file-not-loaded", {
            dealFlashId: deal.flashId,
            channelId: this.props.channelId,
            error: "Deal music not loaded yet after min delay of 2000ms"
          });
        }
        this.setState({showDealDetails: true});
      }, 2000);
    }
  },

  componentWillUnmount() {
    $("body").removeClass("dealflash");
    window.clearTimeout(this.dealMusicPlaybackMinDelayTimeoutId);
    window.clearTimeout(this.dealAnnouncementTimeoutId);
    this.state.sound.stop();
    this.audit("deal_flash:end");
  },

  render() {
    const {showCountdownVideo, showDealAnnouncement, showDealDetails} = this.state;
    const fadeInEffect = {
      animation: "fadeIn",
      duration: 1000
    };
    const fadeOutEffect = {
      animation: "fadeOut",
      duration: 3000,
      style: {display: "none"}
    };
    return (
        <div style={{height: "100%"}}>
          {showCountdownVideo && this.renderCountdownVideo()}
          <VelocityTransitionGroup component="div" enter={fadeInEffect} leave={fadeOutEffect}>
            {showDealAnnouncement && this.renderDealAnnouncement()}
          </VelocityTransitionGroup>
          <VelocityTransitionGroup component="div" enter={fadeInEffect} leave={fadeOutEffect}>
            {showDealDetails && this.renderDealDetails()}
          </VelocityTransitionGroup>
        </div>
    );
  },

  renderCountdownVideo() {
    return (
        <div style={{width: "100%", height: "100%", position: "absolute", zIndex: 9999}}>
          <video
              className="video-js vjs-default-skin"
              autoPlay={true}
              src={countdownVideo}
              onEnded={this.handleCountdownVideoEnded}
              style={{height: "100%", width: "100%", objectFit: "fill"}} />
        </div>
    );
  },

  renderDealAnnouncement() {
    return (
        <div id="announcement-region" className="layer-fade-out">
          <section className="deal-flash 3layer-fade-out">
            <div className="clockwork-anim clearfix" id="clockwork">
              <img src={clockwork1} className="clockwork-piece rotating" />
              <img src={clockwork2} className="clockwork-piece rotating-slow" />
              <img src={clockwork3} className="clockwork-piece rotate-x" />
              <img src={clockwork4} className="clockwork-piece rotate-x-alt" />
            </div>
            <div className="deal-notification layer-fade-out">
              <h1 className="note-anim" style={{
                color: "#fff",
                fontSize: "28vw",
                fontFamily: "freshmannormal",
                textShadow: "0 0 50px #506cb3",
                textAlign: "center"
              }}>
                DEAL!
              </h1>
            </div>
          </section>
        </div>
    );
  },

  renderDealDetails() {
    const {deal} = this.state;
    const user = Users.getUser(deal.userId);
    const maxValueDisplayLength = 10;
    const feeMarginPercent = toPercentageStr(deal.feeMargin.value);
    const employmentType = deal.employmentType;
    const feeMarginLabel = `${feeMarginPercent} ${employmentType.toUpperCase() === "PERMANENT" ? "Fee" : "Margin"}`;
    const clientHasNewSlides = currentClient.hasPermission("HAS_NEW_LOOK_CUBETV");
    const panelBackground = clientHasNewSlides ?
        "rgba(44, 53, 90, 0.56)" :
        `url("${oldTextureLeft}"), url("${oldTextureRight}"), ${gradient}`;
    const panelBorder = clientHasNewSlides ? "3px solid #B9B9B9" : "5px solid #7d79a7";
    const dealInfoStyle = {
      fontFamily: this.props.theme.typography.fontFamily,
      fontSize: "3vw",
      fontStyle: "italic",
      color: "#fff",
      lineHeight: 1.4,
      position: "relative",
      textAlign: "center"
    };
    return (
        <div style={{zIndex: 200, height: "100vh"}}>
          <section className="row" style={{marginTop: "4vh", paddingLeft: "5vw", height: "56vh"}}>
            <UserAvatar
                className="columns small-4 large-4"
                style={{
                  border: panelBorder,
                  borderRadius: 30,
                  padding: 0,
                  objectFit: "cover",
                  maxHeight: "55vh",
                  maxWidth: "68vh"
                }}
                user={user} />

            <div className="columns small-8 large-8"
                 style={{
                   marginTop: 0,
                   paddingTop: "3vh",
                   paddingBottom: "3vh",
                   paddingLeft: "2vw",
                   position: "relative",
                   top: "5vh",
                   textRendering: "optimizeLegibility",
                   fontFamily: this.props.theme.fontFamily,
                   background: panelBackground,
                   borderTop: panelBorder,
                   borderBottom: panelBorder
                 }}>
              <div style={{height: "20vh"}}>
                <h3 style={{
                  fontSize: "3.8vw",
                  color: Colors.c19Yellow,
                  lineHeight: 1.4,
                  textTransform: "uppercase",
                  ...textOverflowStyle
                }}>
                  {user.get("fullName")}
                </h3>
                <h4 style={{
                  fontSize: "3.25vw",
                  fontStyle: "italic",
                  fontWeight: "bold",
                  color: Colors.c19Yellow,
                  lineHeight: 1.3,
                  ...textOverflowStyle
                }}>
                  {getGroupBreadcrumbs(user.get("groupId"))}
                </h4>
              </div>

              <div style={{height: "16vh", color: "#fff"}}>
                <div style={{fontSize: "2.75vw", lineHeight: 1.6, ...textOverflowStyle}}>
                  {deal.organisation}
                </div>
                <div style={{fontSize: "2.75vw", lineHeight: 1.6, ...textOverflowStyle}}>
                  <span>{deal.candidateName},</span> <em>{deal.jobTitle}</em>
                </div>
              </div>
            </div>
          </section>

          <section style={{
            margin: "1vh 0",
            padding: "0 3vw",
            height: "35vh",
            background: panelBackground,
            backgroundRepeat: "no-repeat no-repeat",
            backgroundPosition: "bottom left, center right, top",
            borderTop: panelBorder,
            borderBottom: panelBorder
          }}>
            {(deal.contractValueType === "DO_NOT_SHOW" && employmentType === "Contract") ? "" :
                <h2 style={{
                  color: "#fff",
                  fontFamily: this.props.theme.typography.fontFamily,
                  fontWeight: "bold",
                  fontSize: "8.5vw",
                  textAlign: "center"
                }}>
                  {Formatter.format(
                      deal.commissionTotalValue,
                      {valueFormat: "CURRENCY", maxDisplayLength: maxValueDisplayLength})}
                </h2>}

            <div style={(deal.contractValueType === "DO_NOT_SHOW" && employmentType === "Contract") ?
                {...dealInfoStyle, marginTop: "10%"} : dealInfoStyle}>
              {(clientHasNewSlides && deal.startDate) &&
              <span style={{position: "absolute", left: 0}}>
<strong>Starts</strong> {formatDate(deal.startDate)}
</span>}
              <span style={{fontFamily: this.props.theme.typography.fontFamily}}>
{`${capitaliseWords(deal.employmentType)} Placement`}
</span>
              <span style={{position: "absolute", right: 0}}>
{feeMarginLabel}
</span>
            </div>
          </section>
        </div>
    );
  },

  canShowDealFlashAnimation() {
    const deviceModel = Auditor.getUserAgent().getDevice().model || "";
    const isIpad = deviceModel.toLowerCase() === "ipad";
    const client = currentClient;
    const clientHasGoodComputer = client.get("showDealFlashAnimation");
    return !isIpad && clientHasGoodComputer;
  },

  loadDealSound() {
    const {userId, flashId} = this.state.deal;
    getDealMusicUrlForUser(userId)
        .then(dealMusicFileUrl => {
          const channelId = this.props.channelId;
          const sound = new howler.Howl({
            src: [dealMusicFileUrl],
            loop: false,
            html5: true,
            preload: true,
            onloaderror: (id, error) => {
              console.error("Error loading sound file", id, error);
              Auditor.audit("deal_flash:load-sound-failed", {
                dealFlashId: flashId,
                channelId,
                soundFileUrl: dealMusicFileUrl,
                error
              });
            },
            onplay: id => {
              console.log("Playing sound file:", id, dealMusicFileUrl);
            },
            onplayerror: (id, error) => {
              console.error("Error playing sound file", id, error);
              Auditor.audit("deal_flash:play-sound-failed", {
                dealFlashId: flashId,
                channelId,
                soundFileUrl: dealMusicFileUrl,
                error
              });
            },
            onend: () => {
              console.log("Finished playing sound file:", dealMusicFileUrl);
            }
          });
          this.setState({
            dealMusicFileUrl,
            sound
          }, () => this.audit("deal_flash:start"));
        });
  },

  handleCountdownVideoEnded() {
    console.log("Finished playing countdown video");
    const {dealMusicFileUrl, sound, deal} = this.state;
    if (dealMusicFileUrl) {
      sound.play();
    } else {
      Auditor.audit("deal_flash:sound-file-not-loaded", {
        dealFlashId: deal.flashId,
        channelId: this.props.channelId,
        error: "Countdown video ended but deal music not loaded yet"
      });
    }
    this.setState({
      showCountdownVideo: false,
      showDealAnnouncement: true
    });
    this.dealAnnouncementTimeoutId = window.setTimeout(() => {
      this.setState({
        showDealAnnouncement: false,
        showDealDetails: true
      });
    }, 5000);
  },

  audit(category) {
    const {dealMusicFileUrl, deal} = this.state;
    if (dealMusicFileUrl) {
      const payload = {
        channelId: this.props.channelId,
        parentId: deal.parentId,
        flashId: deal.flashId,
        pubnubId: Events.getAuthKey(),
        soundFilePlayed: dealMusicFileUrl
      };
      Auditor.audit(category, payload);
    }
  }

});

const textOverflowStyle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
};

const formatDate = mysqlDateStr => moment(mysqlDateStr, "YYYY-MM-DD").format("ll").replace(",", "");

const getDealDetails = () => Cube19.request("cubetv:next-deal");

const getDealMusicUrlForUser = userId => {
  const user = Users.getUser(userId);
  const dealMusicId = user.get("dealMusicId");
  const userHasDealMusic = !!dealMusicId;
  if (userHasDealMusic) {
    return getDealMusicUrl(dealMusicId);
  } else {
    return getDealMusicUrlForGroup(user.get("groupId"));
  }
};
const getDealMusicUrlForGroup = groupId => {
  // TODO: downloading a silent MP3 is a waste of resources, we should not attempt to play anything in these cases
  const silentMp3Id = getSilentMp3Id();
  if (!groupId) {
    return getDealMusicUrl(silentMp3Id);
  }

  const dealMusicId = Groups.getGroup(groupId).get("dealMusicId") ?? silentMp3Id;
  return getDealMusicUrl(dealMusicId);
};
const getDealMusicUrl = dealMusicId => {
  // We force ALL silent.mp3 requests to be from the packaged MP3 until deal flashes are rewritten to not play a silent
  //  MP3. This is to avoid rewriting lots of code just to not need to craft assetsUrl + silent.mp3 URL.
  const dealMusicIsSilent = dealMusicId === getSilentMp3Id();
  return dealMusicIsSilent
      ? Promise.resolve(silentAudio)
      : Ajax
          .get({url: path("dealmusic", dealMusicId)})
          .then(dealMusic => dealMusic.url);
};

const getGroupBreadcrumbs = groupId => {
  if (groupId === null) {
    return Groups.getRootGroup().get("name");
  }
  const group = Groups.getGroup(groupId);
  const parentGroupId = group.get("parentId");
  const hasParentGroup = !!parentGroupId;
  if (hasParentGroup) {
    const parentGroup = Groups.getGroup(parentGroupId);
    return `${parentGroup.get("name")} > ${group.get("name")}`;
  } else {
    return group.get("name");
  }
};
