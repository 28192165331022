import React from "react";
import pure from "js/common/views/pure";
import Immutable from "immutable";
import Select from "js/common/views/inputs/immutable-react-select";

export default pure(({
    categories,
    selectedCategoryId,
    onChange,
    isDisabled
}) => (
    <Select
        isMulti={false}
        isClearable={false}
        isSearchable={categories.count() > 5}
        disabled={isDisabled}
        placeholder="Select a Category"
        options={categories.map(categoryToOption)}
        selectedValue={selectedCategoryId}
        onChange={onChange} />
));

const categoryToOption = category => (Immutable.Map({
    label: category.get("name"),
    value: category.get("id")
}));
