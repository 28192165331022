import React from "react";
import Dropzone from "react-dropzone";

import pure from "js/common/views/pure";

export default pure(({
  onDrop,
  onDropRejected,
  multiple,
  disablePreview,
  className,
  style,
  children,
  accept,
  maxSize
}) => {
  return <Dropzone
      onDrop={onDrop}
      onDropRejected={onDropRejected}
      multiple={multiple}
      accept={accept}
      disablePreview={disablePreview}
      maxSize={maxSize}>
    {({getRootProps, getInputProps}) =>
        <div
            className={className}
            {...getRootProps({style})}>
          <input {...getInputProps()} />
          {children}
        </div>}
  </Dropzone>;
});