import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import PureRenderMixin from "react-addons-pure-render-mixin";
import Tooltip from "js/common/views/tooltips";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const ChartNameInput = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        name: ReactPropTypes.string.isRequired,
        onChange: ReactPropTypes.func.isRequired
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.name !== this.props.name) {
            this.setState({
                name: nextProps.name
            });
        }
    },

    getInitialState() {
        return {
            name: this.props.name
        };
    },

    render() {
        const isNotDefaultName = !/Trend Chart [0-9]+/i.test(this.state.name);
        const { theme } = this.props;
        return (
            <div style={{ display:"inline-block", padding:"5px 15px" }}>
                <Tooltip text="Name this Chart" position="right" showOnHover={false} styleType="light" disable={ isNotDefaultName }>
                    <span className="left" style={labelStyle(theme)}>
                        <i className="fa fa-pencil" style={{ paddingRight: 8, color: theme.palette.text.main }} />
                        Editing Chart:
                    </span>
                    <span className="left">
                        <input
                            autoFocus
                            ref="chartNameInput"
                            type="text"
                            style={{
                                ...textInputStyle(theme),
                                color: theme.themeId === "light" ? theme.palette.text.main : theme.palette.primary.main
                            }}
                            placeholder="Untitled"
                            value={this.state.name}
                            onBlur={this.saveChange}
                            onFocus={this.moveCaretToEnd}
                            onChange={this.handleChange} />
                    </span>
                </Tooltip>
            </div>);
    },

    handleChange(e) {
        const inputText = e.target.value;
        this.setState({
            name: inputText
        });
    },

    saveChange() {
        const previousName = this.props.name;

        if (previousName === this.state.name) {
            return;
        }

        if (/[a-z0-9]/ig.test(this.state.name)) {
            this.props.onChange(this.state.name);
        } else {
            this.setState({
                name: previousName,
                showValidationDialog: true
            });
        }
    },

    moveCaretToEnd(event) {
        let value = event.target.value;
        event.target.value = "";
        event.target.value = value;
    }
});

const inputHeight = "28px";
const fontSize = "0.875rem";
const labelStyle = theme => ({
    fontSize,
    color: theme.palette.action.active,
    height: inputHeight,
    lineHeight: inputHeight
});
const textInputStyle = theme => ({
    fontSize,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.main,
    borderLeft: 0,
    borderRight: 0,
    borderTop: 0,
    borderBottom: "1px solid #9e9e9e",
    backgroundColor: "transparent",
    marginBottom: 0,
    marginLeft: 5,
    height: inputHeight,
    borderRadius: 0,

    ":focus": {
        border: "none",
        boxShadow: "none"
    }
});



const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <ChartNameInput theme={theme} {...props} />;
};

export default Wrapper;
