import React from "react";

import {TextButton} from "js/common/views/inputs/buttons";
import Dialog from "js/onpoint/dialog";

export default React.memo(({
  reportName,
  onRemoveRequest,
  onCancelRequest
}) => (
    <Dialog headerLabel={`Remove ${reportName}?`} bodyStyle={{color: "#fff"}}>
      <p style={{marginTop: "1rem"}}>
        Are you sure you want to remove '<strong>{reportName}</strong>' permanently?
      </p>

      <div style={{float: "right", marginTop: "1.5rem"}}>
        <TextButton icon="history" label="Cancel" onClick={onCancelRequest} style={{margin: "0.5rem"}} />
        <TextButton icon="trash" type="primary" label="Remove" onClick={onRemoveRequest} style={{margin: "0.5rem"}} />
      </div>
    </Dialog>
));
