import * as numbers from "js/common/utils/numbers";

const toCumulative = (valueTrend, targetTrend) => {
	const totalPeriodTarget = targetTrend.reduce((total, daily) => total + daily.value.value, 0);

	let cumulativeTotal = 0;
	return valueTrend.map(item => {
		const dailyValue = item.value.value;
		cumulativeTotal += dailyValue;
		return {
			...item,
			value: {
				value: totalPeriodTarget > 0 ? (cumulativeTotal / totalPeriodTarget) * 100 : 0
			}
		};
	});
};

const toNonCumulative = (valueTrend, targetTrend) => {
	return valueTrend.map((item, index) => {
		const dailyValue = item.value.value;
		const targetValue = targetTrend[index].value.value;
        return {
            ...item,
            value: {
                value: targetValue > 0 ? (dailyValue / targetValue) * 100 : 0
            }
        };
    });
};

const toAggregatedCumulative = (valueTrend, targetTrend) => {
    let cumulativeTargetTotal = 0;
    const cumulativeTargetValues = targetTrend.map(item => {
        cumulativeTargetTotal += item.value.value;
        return cumulativeTargetTotal;
    });

    let cumulativeTotal = 0;
    const cumulativeValueTrend = valueTrend.map(item => ({
        ...item,
        value: {
            value: cumulativeTotal += item.value.value
        }
    }));

    return cumulativeValueTrend.map((item, index) => {
        const cumulativeValueForDay = item.value.value;
        const cumulativeTargetForDay = cumulativeTargetValues[
			(item.daysFromStart
			|| item.weeksFromStart
            || item.monthsFromStart
            || item.quartersFromStart
            || item.yearsFromStart) - 1];

		const percentForDay = cumulativeTargetForDay > 0 ? round((round(cumulativeValueForDay) / round(cumulativeTargetForDay)) * 100) : 0;
        return {
            ...item,
            value: { value: percentForDay }
        };
    });
};

const round = num => numbers.roundTo(num, 2);

export {
	toCumulative,
	toNonCumulative,
    toAggregatedCumulative
};
