/** @jsxImportSource @emotion/react */
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import EditFormula from "js/squids/squid-display/edit-mode/formula-tab/formula-editor";
import ErrorIcon from "js/squids/squid-display/error-icon";
import Tooltip from "js/squids/squid-display/edit-mode/error-tooltip";
import {IconButton} from "js/common/views/inputs/buttons";
import {jsx, css} from "@emotion/react";
import * as Colors from "js/common/cube19-colors";
import * as Formulas from "js/common/formulas/formulas";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const FormulaList = React.memo(({
  toggleFormula,
  inProgressFormulaIndexes,
  setInProgressFormulaIndexes,
  openSteps,
  setOpenSteps,
  deleteStep,
  handleEditPage,
  page,
  setActiveTab,
  setIsEditNodeOpen,
  setEditNodeIndex,
  formulaErrors,
  inputDelayMillis,
  theme
}) => {
  const formulas = page.get("formulas");
  const listOfFormulaUses = page && Formulas.getUsagesOfAllFormulasInConfig(page);

  return (
      <TableContainer style={{overflowX: "visible"}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell css={tableHeadingStyle}>ID</TableCell>
              <TableCell css={tableHeadingStyle}>Type</TableCell>
              <TableCell css={tableHeadingStyle}>Usages</TableCell>
              <TableCell css={tableHeadingStyle}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{padding: "3rem"}}>
            {formulas && formulas.map((formula, index) => {
              const errors = formulaErrors.filter(fe => fe.getIn(["location", "formulaId"]) === formula.get("id"));
              const isBeingEdited = inProgressFormulaIndexes.includes(index);
              return <React.Fragment key={index}>
                <Formula
                    theme={theme}
                    formula={formula}
                    index={index}
                    isBeingEdited={isBeingEdited}
                    inProgressFormulaIndexes={inProgressFormulaIndexes}
                    setInProgressFormulaIndexes={setInProgressFormulaIndexes}
                    openSteps={openSteps}
                    setOpenSteps={setOpenSteps}
                    toggleFormula={toggleFormula}
                    page={page}
                    setActiveTab={setActiveTab}
                    setIsEditNodeOpen={setIsEditNodeOpen}
                    setEditNodeIndex={setEditNodeIndex}
                    deleteStep={deleteStep}
                    listOfFormulaUses={listOfFormulaUses}
                    hasErrors={errors.count()} />
                {isBeingEdited &&
                <EditFormula
                    formula={formula}
                    index={index}
                    handleEditPage={handleEditPage}
                    page={page}
                    errors={errors}
                    inputDelayMillis={inputDelayMillis} />}
              </React.Fragment>;
            })}
          </TableBody>
        </Table>
      </TableContainer>
  );
});

const Formula = React.memo(({
  theme,
  formula,
  index,
  isBeingEdited,
  inProgressFormulaIndexes,
  setInProgressFormulaIndexes,
  openSteps,
  setOpenSteps,
  toggleFormula,
  page,
  setActiveTab,
  setIsEditNodeOpen,
  setEditNodeIndex,
  deleteStep,
  listOfFormulaUses,
  hasErrors
}) => {
  const formulaUses = listOfFormulaUses.get(formula.get("id"));
  const numberOfUses = formulaUses ? formulaUses.count() : 0;

  const handleNodeUsageClick = (e, nodeId) => {
    e.stopPropagation();
    const nodeIndex = page.get("nodes").findIndex(node => node.get("id") === nodeId);
    setIsEditNodeOpen(true);
    setEditNodeIndex(nodeIndex);
  };

  const handleStepUsageClick = (e, stepId) => {
    e.stopPropagation();
    setActiveTab("Steps");
    setOpenSteps(openSteps.add(stepId));
  };

  const handleFormulaUsageClick = (e, formulaId) => {
    e.stopPropagation();
    const formulaIndex = page.get("formulas").findIndex(formula => formula.get("id") === formulaId);
    setInProgressFormulaIndexes(inProgressFormulaIndexes.add(formulaIndex));
  };

  const handleDescriptionUsageClick = (e) => {
    e.stopPropagation();
    setActiveTab("Settings");
  };

  const usageGroupedByArea = formulaUses &&
      formulaUses
          .map(use => use.get("location"))
          .groupBy(location => location.get("area"));

  const getUsageDetails = location => {
    const usage = usageGroupedByArea && usageGroupedByArea.get(location);
    if (usage) {
      switch (location) {
        case "nodes":
          return usage.map(node => {
            const nodeId = node.get("areaId");
            return <li key={nodeId} onClick={(e) => handleNodeUsageClick(e, nodeId)}>Node: {nodeId} ({node.get("path")
                .join(" > ")})</li>;
          });
        case "steps":
          const stepIdArray = usage.map(use => use.get("stepId")).toSet();
          return stepIdArray.map(id => {
            const index = page.get("steps").findIndex(step => step.get("id") === id) + 1;
            const label = page.get("steps").filter(step => step.get("id") === id).get(0).get("label");
            const trimmedLabel = label.length > 10 ? label.substring(0, 10).trim() + "..." : label;
            return <li key={id} onClick={(e) => handleStepUsageClick(e, id)}>Step {index}: {trimmedLabel} (
              {usage
                  .filter(stepUse => stepUse.get("stepId") === id)
                  .map(use => use.get("path", []).join(" > "))
                  .toSet()
                  .join(", ")}
              )</li>;
          });
        case "formulas":
          const formulaId = usage.getIn([0, "areaId"]);
          return <li key={formulaId}
                     onClick={(e) => handleFormulaUsageClick(e, formulaId)}>Formula: {formulaId} (string)</li>;
        default:
          return <li key="description-use" onClick={e => handleDescriptionUsageClick(e)}>Squid description</li>;
      }
    }
  };

  const listOfUsageDetails = <div>
    <ol css={usageListStyle}>
      {getUsageDetails("description")}
      {getUsageDetails("nodes")}
      {getUsageDetails("steps")}
      {getUsageDetails("formulas")}
    </ol>
  </div>;

  const deleteFormulaTooltipContent = <div style={{paddingBottom: "1.25em"}}>Cannot delete a formula with usages</div>;

  return (
      <TableRow css={isBeingEdited ? activeTableRowStyle : tableRowStyle} onClick={() => { toggleFormula(index); }}>
        <TableCell>{formula.get("id")} {hasErrors ? <ErrorIcon /> : ""}</TableCell>
        <TableCell>{formula.get("formatAs")}</TableCell>
        <TableCell>
          {numberOfUses ?
              <Tooltip content={listOfUsageDetails} position="top" customStyle={{left: -4, padding: "1rem 2rem 0"}}>
                {numberOfUses}
              </Tooltip> : numberOfUses}
        </TableCell>
        <TableCell style={{textAlign: "right"}}>
          <div>
            <IconButton
                icon="pencil"
                style={isBeingEdited ? {...iconStyle(theme), color: theme.palette.background.inverted} : iconStyle(theme)} />
            {numberOfUses ?
                <Tooltip content={deleteFormulaTooltipContent}
                         position="left"
                         customStyle={{left: 0, padding: "1rem 2rem 0"}}>
                  <IconButton
                      icon="trash"
                      type="alert"
                      style={{...iconStyle(theme), marginRight: 0}}
                      disableClick={numberOfUses}
                      onClick={e => {
                        e.stopPropagation();
                        deleteStep(index);
                      }}
                  />
                </Tooltip> :
                <IconButton
                    icon="trash"
                    type="alert"
                    style={{...iconStyle(theme), marginRight: 0}}
                    disableClick={numberOfUses}
                    onClick={e => {
                      e.stopPropagation();
                      deleteStep(index);
                    }}
                />
            }
          </div>
        </TableCell>
      </TableRow>
  );
});

const usageListStyle = css`
  cursor: auto;

  > li {
    color: ${Colors.white};
    cursor: pointer;

    &:hover {
      color: ${Colors.accentBlue};
    }
  }
`;

const tableRowStyle = css`
  cursor: pointer;

  > td {
    color: ${Colors.greyText};
    padding: 0rem 1rem;
    border-color: ${Colors.tableBorder};
    height: 63px;

    &:first-of-type {
      padding-left: 30px;
    }

    &:last-of-type {
      padding-right: 30px;
      text-align: right;
    }
  }
`;

const activeTableRowStyle = css`
  cursor: pointer;
  background-color: ${Colors.attributeNotSetColor};

  > td {
    color: ${Colors.greyText} !important;
    padding: 0.5rem 1rem;
    border-bottom: none;

    &:first-of-type {
      padding-left: 30px;
    }

    &:last-of-type {
      padding-right: 30px;
      text-align: right;
    }
  }
`;

const tableHeadingStyle = css`
  &.MuiTableCell-root {
    border: none;
    padding-bottom: 5px;
  }

  &:first-of-type {
    padding-left: 30px;
  }

  &:last-of-type {
    text-align: right;
    padding-right: 30px;
  }
`;

const iconStyle = theme =>({
  display: "inline-block",
  color: theme.palette.textColor,
  backgroundColor: theme.palette.background.card,
  margin: 5,
  padding: 10,
  fontSize: "1.25em",
  borderRadius: 5
});

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <FormulaList theme={theme} {...props} />;
};