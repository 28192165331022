import React from "react";
import SelectField from "@mui/material/Select";
import pure from "js/common/views/pure";
import { MenuItem } from '@mui/material';

export default pure(({
    options,
    value,
    onChange,
    width = "auto"
}) => (
    <SelectField
        variant="standard"
        autoWidth={true}
        style={{ fontSize: "0.7rem", width }}
        value={value}
        renderValue={value => {
            const selectedOption = options.find(option => option.value === value);
            return selectedOption && selectedOption.label;
        }}
        onChange={e => onChange(e.target.value)}>
        {options
            .map((opt, i) =>
                <MenuItem key={i} value={opt.value}>{opt.primaryText}</MenuItem>)}
    </SelectField>
));
