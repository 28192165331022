import lightTheme from 'js/common/themes/light-theme';
import darkTheme from 'js/common/themes/dark-theme';
import {bullhornLightBlue, c19Yellow} from "js/common/cube19-colors";

export default function getTheme(theme) {

  const themes = {
    light: lightTheme(),
    dark: darkTheme()
  };

  return themes[theme]
}
