import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";
import { TextField } from '@mui/material';

import currentClient from "js/common/repo/backbone/current-client";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import { Layout, Row, Column } from "js/common/views/foundation-column-layout";
import SlideTimeframePicker from "js/admin/cubetv/slide-timeframe-picker";
import ImmutableTagFilter from "js/common/views/inputs/tag-picker/immutable-tag-filter";
import GroupPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import { TextButton, IconButton } from "js/common/views/inputs/buttons";
import LabelledComponent from "js/common/views/labelled-component";
import { getDefaultKpiTrend } from "js/admin/cubetv/trend/default-trend-slide";
import * as Groups from "js/common/groups";
import Checkbox from "js/common/views/inputs/checkbox";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const borderStyle = "2px solid #a5a5a5";

const EditKpiTrends = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        kpiTrends: ReactPropTypes.object.isRequired,
        onChange: ReactPropTypes.func.isRequired,
        timeframes: ImmutablePropTypes.list.isRequired
    },

    render() {
        const { kpiTrends, timeframes, theme } = this.props;
        return (
            <div style={{ marginTop: "1rem" }} >
                <Layout allSmall={12} allMedium={6} rowStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <LabelledKpiPicker
                        label="Metric"
                        placeholder="Select a Metric"
                        kpis={getTrendableKpis()}
                        clearable={false}
                        multiSelect={false}
                        selectedKpiId={kpiTrends.getIn([ 0, "kpiId" ])}
                        onChange={kpiId => this.onKpiSelect(kpiId)} />
                </Layout>
                <Row>
                    <Column small={12}>
                        <i className="fa fa-info-circle" style={{ display:"table-cell", paddingRight:8 }} />
                        <span style={{ display:"table-cell", fontSize:"0.825rem" }}>
                            The order of the trend settings listed below determines the display priority in the chart.
                        </span>
                    </Column>
                </Row>
                {kpiTrends.map((kpiTrend, index) => (
                    <EditKpiTrend
                        theme={theme}
                        key={kpiTrend.get("cid")}
                        initiallyExpanded={index === 0}
                        timeframes={timeframes}
                        kpiTrend={kpiTrend}
                        onChange={this.updateTrend}
                        onDeleteClick={this.deleteTrend}
                        onUpClick={this.handleMoveUp}
                        onDownClick={this.handleMoveDown}
                        numberOfTrends={kpiTrends.count()} />))}
                <Layout allSmall={12} smallCentered={true} rowStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <TextButton
                        icon="plus"
                        label="Add Another Trend (max 4)"
                        disabled={kpiTrends.count() === 4}
                        onClick={this.addTrend} />
                </Layout>
            </div>);
    },

    onKpiSelect(kpiId) {
        this.props.onChange(this.props.kpiTrends.map(trend => trend.set("kpiId", kpiId)));
    },

    addTrend() {
        const { kpiTrends, onChange } = this.props;
        const order = kpiTrends.count() + 1;
        const kpiId = kpiTrends.getIn([ 0, "kpiId" ]);
        const newTrend = getDefaultKpiTrend(order, kpiId);
        onChange(kpiTrends.push(newTrend));
    },

    updateTrend(kpiTrend) {
        this.props.onChange(this.props.kpiTrends.set(this.findIndex(kpiTrend), kpiTrend));
    },

    deleteTrend(kpiTrend) {
        this.props.onChange(this.props.kpiTrends.delete(this.findIndex(kpiTrend)));
    },

    handleMoveUp(kpiTrend) {
        this.moveBy(kpiTrend, -1);
    },

    handleMoveDown(kpiTrend) {
        this.moveBy(kpiTrend, 1);
    },

    moveBy(kpiTrend, distance) {
        const index = this.findIndex(kpiTrend);
        const { kpiTrends, onChange } = this.props;
        onChange(kpiTrends.delete(index).insert(index + distance, kpiTrend));
    },

    findIndex(kpiTrend) {
        return this.props.kpiTrends.findIndex(c => c.get("cid") === kpiTrend.get("cid"));
    }

});

const EditKpiTrend = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        kpiTrend: ReactPropTypes.object.isRequired,
        onChange: ReactPropTypes.func.isRequired,
        onUpClick: ReactPropTypes.func.isRequired,
        onDownClick: ReactPropTypes.func.isRequired,
        onDeleteClick: ReactPropTypes.func.isRequired,

        numberOfTrends: ReactPropTypes.number.isRequired,
        timeframes: ImmutablePropTypes.list.isRequired,
        initiallyExpanded: ReactPropTypes.bool,
        theme: ReactPropTypes.object
    },

    getDefaultProps() {
        return {
            initiallyExpanded: false
        };
    },

    getInitialState() {
        return {
            expanded: this.props.initiallyExpanded
        };
    },

    render() {
        return (
            <div style={{ margin: "1rem" }}>
                {this.renderHeader()}
                {this.state.expanded && this.renderBody()}
            </div>);
    },

    renderHeader() {
        const {
            kpiTrend,
            onDeleteClick,
            onUpClick,
            onDownClick,
            theme
        } = this.props;

        return (
            <Layout
                small={[ 9,3 ]}
                rowClass="clearfix"
                rowStyle={{ border: borderStyle, paddingTop: "1rem", paddingBottom: "1rem" }}
                onRowClick={() => this.setState({ expanded: !this.state.expanded })}>
                <div className="left" style={{ color: theme.palette.primary.main }}>
                    {kpiTrend.get("key")}
                </div>
                {this.props.numberOfTrends > 1 &&
                    <div className="right">
                        <IconButton
                            icon="arrow-up"
                            size="small"
                            onClick={e => {
                                e.stopPropagation();
                                onUpClick(kpiTrend);
                            }} />
                        <IconButton
                            icon="arrow-down"
                            size="small"
                            onClick={e => {
                                e.stopPropagation();
                                onDownClick(kpiTrend);
                            }} />
                        <IconButton
                            icon="times"
                            size="small"
                            hoverType="alert"
                            onClick={e => {
                                e.stopPropagation();
                                onDeleteClick(kpiTrend);
                            }} />
                    </div>
                }
            </Layout>);
    },

    renderBody() {
        const { timeframes, kpiTrend, onChange, theme } = this.props;
        const containerStyle = {
            borderLeft: borderStyle,
            borderRight: borderStyle,
            borderBottom: borderStyle,
            paddingTop: "1rem",
            paddingBottom: "1rem"
        };
        const currentTimeframeId = kpiTrend.get("timeframeId").toLowerCase();
        const visibleTimeframes = timeframes
            .filter(t => (t.get("visible") && !t.get("isDeleted")) || t.get("id") === currentTimeframeId);
        return (
            <div style={containerStyle}>
                <Layout allSmall={12} allMedium={6} columnClass="end">
                    <TextField variant="standard" style={{marginTop: 15}}
                        type="text"
                        label="Trend Label"
                        value={kpiTrend.get("key")}
                        onChange={e => onChange(kpiTrend.set("key", e.target.value.substring(0, 15)))} />
                    <div>
                        <Checkbox
                            label="Show Target"
                            checked={kpiTrend.get("showTarget")}
                            onCheck={(e, isChecked) => onChange(kpiTrend.set("showTarget", isChecked))}
                            style={{display: "inline-block", marginTop: "1.5rem", marginRight: "0.5rem", width: 140, verticalAlign: "bottom"}} />
                        {kpiTrend.get("showTarget") &&
                            <TextField variant="standard"
                                type="text"
                                label="Target Label"
                                value={kpiTrend.get("targetKey")}
                                onChange={e => onChange(kpiTrend.set("targetKey", e.target.value.substring(0, 15)))}
                                style={{display: "inline-block", marginTop: 15}} />}
                    </div>
                </Layout>

                <Layout allSmall={12} allMedium={6} columnClass="margin-top">
                    <LabelledTimeframePicker
                        selectedTimeframeId={currentTimeframeId}
                        timeframes={visibleTimeframes}
                        onChange={newTimeframeId => {
                            const currentTimeframe = this.getTimeframe(currentTimeframeId);
                            const currentTimeframeName = currentTimeframe.get("shortName") || currentTimeframe.get("name");
                            const newTimeframe = this.getTimeframe(newTimeframeId);
                            const newTimeframeName = newTimeframe.get("shortName") || newTimeframe.get("name");
                            let newKpiTrend = kpiTrend.set("timeframeId", newTimeframeId);
                            if (kpiTrend.get("key") === currentTimeframeName) {
                                newKpiTrend = newKpiTrend.set("key", newTimeframeName);
                            }
                            onChange(newKpiTrend);
                        }} />
                    <LabelledGroupPicker
                        qualifierId={kpiTrend.get("groupId")}
                        onGroupClick={groupId => onChange(kpiTrend.set("groupId", groupId))} />
                </Layout>

                {currentClient.canAccessApp("TAGS_ADMIN") && this.renderTagFilters()}
            </div>);
    },

    renderTagFilters() {
        const { kpiTrend, onChange } = this.props;
        return (
            <Layout allSmall={12} allMedium={4} columnClass="margin-top">
                <ImmutableTagFilter
                    label="Match Any Tags"
                    onChange={tagIds => onChange(kpiTrend.set("matchAnyTagIds", tagIds))}
                    multiple={true}
                    tagIds={kpiTrend.get("matchAnyTagIds")} />
                <ImmutableTagFilter
                    label="Match All Tags"
                    onChange={tagIds => onChange(kpiTrend.set("matchAllTagIds", tagIds))}
                    multiple={true}
                    tagIds={kpiTrend.get("matchAllTagIds")} />
                <ImmutableTagFilter
                    label="Exclude Tags"
                    onChange={tagIds => onChange(kpiTrend.set("excludedTagIds", tagIds))}
                    multiple={true}
                    tagIds={kpiTrend.get("excludedTagIds")} />
            </Layout>);
    },

    getTimeframe(timeframeId) {
        const { timeframes } = this.props;
        return timeframes.get(timeframes.findIndex(t => t.get("id") === timeframeId));
    }

});

const LabelledKpiPicker = pure(({ ...props }) => (
    <LabelledComponent label="Metric" style={{ marginTop:"15px" }}>
        <KpiPicker {...props} />
    </LabelledComponent>));

const LabelledGroupPicker = pure(({ ...props }) => (
    <LabelledComponent label="Group" icon="group">
        <GroupPicker
            hierarchy={Groups.getHierarchyWithUsers()}
            qualifierType="GROUP"
            excludeUsers={true}
            {...props} />
    </LabelledComponent>));

const LabelledTimeframePicker = pure(({ ...props }) => (
    <LabelledComponent label="Timeframe" icon="calendar-o">
        <SlideTimeframePicker {...props} />
    </LabelledComponent>));

const getTrendableKpis = () => Immutable.fromJS(kpiRepo.getTrendableKpis());

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <EditKpiTrends theme={theme} {...props} />;
};