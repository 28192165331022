import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import IconButton from "@mui/material/IconButton";

const InheritSwitch = React.memo(({inheritKey, inheritKeys, onToggle, section = "dashboard", disabled, style}) => {
    const { theme } = React.useContext(CustomThemeContext);
    const toggle = () => onToggle(inheritKey);
    const inheriting = inheritKeys.includes(inheritKey);
    const iconClass = inheriting ? "bhi-lock" : "bhi-unlock";

    return <div
        onClick={!disabled ? toggle : undefined}
        style={{
            cursor: !disabled ? "pointer" : "default",
            display: "flex",
            minWidth: 250,
            alignSelf: "flex-end",
            alignItems: "center",
            justifyContent: "space-between",
            background: inheriting ? theme.palette.background.card : "#4a89dc1a",
            color: inheriting ? theme.palette.text.main : theme.palette.primary.main,
            padding: "0 5px 0 10px",
            marginRight: "2px",
            marginBottom: 5,
            borderRadius: 6,
            fontSize: 11,
            ...style
        }}>
        <i className={iconClass} style={{
            marginRight: 5,
            ...(disabled && {opacity: 0.5})
        }}/>
        <span style={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "flex-start",
            fontSize: "10px",
            ...(disabled && {opacity: inheriting ? 0.5 : 0.7}),
            margin: "0 5px",
            whiteSpace: "nowrap"
        }}>{inheriting ? `Using inherited ${section} settings` : "Using custom settings"}</span>
        <IconButton
            style={{
                color: inheriting ? theme.palette.text.main : theme.palette.primary.main,
                fontSize: "11px"
            }}
            disabled={disabled}
            title={`Switch to using ${inheriting ? 'custom' : 'inherited'} settings`}
            size="small">
            <i className="fas fa-retweet" />
        </IconButton>
    </div>;
});

export default InheritSwitch;