/** @jsxImportSource @emotion/react */
import React from "react";
import {
  Box,
  Grid,
  Pagination,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewListIcon from "@mui/icons-material/ViewList";

import * as Styles from "js/common/views/index-view/index-view.styles";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const IndexView = (props) => {
  const {
    controls,
    pagination,
    data,
    card,
    onClick,
    squidViewStyle,
    setSquidViewStyle,
    duplicateSquid,
    deleteSquid
  } = props;

  const { theme } = React.useContext(CustomThemeContext);

  return (
    <Box sx={Styles.viewContainer(theme)}>
      <Box>
        <Box sx={Styles.viewControls(theme)}>
          {controls && controls}
          <ToggleButtonGroup
              exclusive
              size="small"
              value={squidViewStyle}
              css={Styles.viewToggle(theme)}
              style={{marginLeft: "1rem"}}>
            <ToggleButton value="grid" title="Grid view" onClick={() => setSquidViewStyle("grid")}>
              <ViewModuleIcon fontSize="small" />
            </ToggleButton>
            <ToggleButton value="list" title="List view" onClick={() => setSquidViewStyle("list")}>
              <ViewListIcon fontSize="small" />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>
      <Grid container spacing={squidViewStyle === "grid" ? 3 : 0}>
        {data.map((item) => React.cloneElement(card, {
          key: item.get("id"),
          data: item,
          mode: squidViewStyle,
          onClick: onClick,
          duplicateSquid: duplicateSquid,
          deleteSquid: deleteSquid
        }))}
      </Grid>
      {pagination && <Box>
        <Pagination count={3} />
      </Box>}
    </Box>
  );
};

export default IndexView;
