const Backbone = window.Backbone;
const _ = window._;

export default Backbone.Model.extend({

    load() {
        if (!_.isFunction(this.loadData)) {
            var viewName = this.get("visualizationId") || "Unknown Cubes";
            console.debug("No 'loadData' function available for '" + viewName + "', cannot preload data.");
            return;
        }
        this.loadData();
    }

});
