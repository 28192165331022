import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "css/alertify.css";
// TODO: Replace AlertifyJS with something aimed at React

const _ = window._;

const DEFAULT_OPTIONS = {
  title: "",
  transition: "slide",
  closable: false,
  movable: false,
  resizable: false,
  reverseButtons: true,
  onok: function() {},
  oncancel: function() {}
};

function changesSaved() {
  return alertify.success("Your changes have been saved.", "bottom-right");
}

function changesReset() {
  return alertify.success("Your changes have been reset.", "bottom-right");
}

function contactSupport() {
  return alertify.error("An error occurred.<br />Please contact support.");
}

function error(message, position = "bottom-right") {
  alertify.set("notifier", "position", position);
  return alertify.error(message);
}

function success(message, position = "bottom-right") {
  alertify.set("notifier", "position", position);
  return alertify.success(message);
}

function warning(message, position = "bottom-right") {
  alertify.set("notifier", "position", position);
  return alertify.message(message);
}

function confirm(message, options = {}) {
  options = _.extend({}, DEFAULT_OPTIONS, options);
  options.message = message;
  let dialog = alertify.confirm();
  dialog.set(options);
  dialog.show(true, `popup-${localStorage.getItem('appTheme') || 'light'}`);
  return dialog;
}

function prompt(message, options = {}) {
  options = _.extend({}, DEFAULT_OPTIONS, options);
  options.message = message;
  let dialog = alertify.dialog("prompt");
  dialog.set(options);
  dialog.show(true, `popup-${localStorage.getItem('appTheme') || 'light'}`);
  return dialog;
}

function alert(message, options = {}) {
  options = _.extend({}, DEFAULT_OPTIONS, options);
  options.message = message;

  let timeoutId;
  const originalOnShow = options.onshow;
  let dialog;
  options.onshow = function() {
    if (options.delay) {
      timeoutId = setTimeout(function() {
        if (dialog.isOpen()) {
          dialog.close();
        }
      }, options.delay);
    }
    if (_.isFunction(originalOnShow)) {
      originalOnShow();
    }
  };
  const originalOnOk = options.onok;
  options.onok = function() {
    window.clearTimeout(timeoutId);
    if (_.isFunction(originalOnOk)) {
      originalOnOk();
    }
  };

  dialog = alertify.alert();
  dialog.set(options);
  dialog.show(true, `popup-${localStorage.getItem('appTheme') || 'light'}`);
  return dialog;
}

export {
  confirm,
  alert,
  prompt,
  error,
  success,
  warning,
  contactSupport,
  changesSaved,
  changesReset
};
