/** @jsxImportSource @emotion/react */

import React from "react";
import {css} from "@emotion/react";
import ReactTooltip from "react-tooltip";
import {timeframeAffectsKpiData} from "js/oneview/kpi-sections/kpi-summary";
import * as ratioFormatter from "js/common/utils/ratio-formatter";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as ratioRepo from "js/common/repo/ratio-repo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const RatioPanel = React.memo((
    {
      config,
      ratioData,
      clientIds,
      currentTimeframe,
      onClick,
      componentId
    }) => {
  const {theme} = React.useContext(CustomThemeContext);
  const hasClientFilter = clientIds && clientIds.count() > 0;
  const tooltipId = `ratio-${componentId}`;
  const ratioCube = css`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-align: left;
    padding: 10px;
    justify-content: flex-end;
    height: 100%;
  `;

  if (hasClientFilter) {
    const firstkpiId = config.get("type") === "global" ? ratioRepo.get(config.get("id")).get("firstKpiId") : config.get(
        "firstKpiId");
    const secondKpiId = config.get("type") === "global"
        ? ratioRepo.get(config.get("id")).get("secondKpiId")
        : config.get("secondKpiId");
    const isFirstKpiClientFilterable = kpiRepo.get(firstkpiId).get("filterableByClient");
    const isSecondKpiClientFilterable = kpiRepo.get(secondKpiId).get("filterableByClient");

    if (!isFirstKpiClientFilterable || !isSecondKpiClientFilterable) {
      return (
          <div css={ratioCube}>
            <ReactTooltip
                textColor={theme.palette.text.inverted}
                backgroundColor={theme.palette.background.inverted}
                effect="solid"
                id={tooltipId}>
              <div style={{width: 200}}>This Ratio contains a Metric that can't be filtered by client</div>
            </ReactTooltip>
            <h2 data-tip data-for={tooltipId} style={{opacity: 0.6, lineHeight: 1}}>N/A</h2>
          </div>
      );
    }
  }

  const isDataLoaded = ratioData.get("firstKpiData").get("total") && ratioData.get("secondKpiData").get("total");

  if (isDataLoaded) {
    const firstKpiTotal = isDataLoaded && ratioData.get("firstKpiData").get("total");
    const secondKpiTotal = isDataLoaded && ratioData.get("secondKpiData").get("total");

    // Get from different data source if custom vs global
    const displayType = config.get("type") === "global"
        ? ratioRepo.get(config.get("id")).get("displayType")
        : config.get("displayType");
    const firstkpiId = config.get("type") === "global" ? ratioRepo.get(config.get("id")).get("firstKpiId") : config.get(
        "firstKpiId");
    const secondKpiId = config.get("type") === "global"
        ? ratioRepo.get(config.get("id")).get("secondKpiId")
        : config.get("secondKpiId");

    const firstKpiName = kpiRepo.get(firstkpiId).get("name");
    const secondKpiName = kpiRepo.get(secondKpiId).get("name");
    const ratioValueDisplay = ratioFormatter.format(displayType || "NORMALISED", firstKpiTotal, secondKpiTotal)
        .replace(/\s/g, "");
    const firstKpiType = kpiRepo.get(firstkpiId).get("type").dateType;
    const secondKpiType = kpiRepo.get(secondKpiId).get("type").dateType;

    const isInstant = firstKpiType === "INSTANT" || secondKpiType === "INSTANT";
    const isDataAffectedByTimeframe = timeframeAffectsKpiData(isInstant, currentTimeframe);

    let message = "";

    if (!isDataAffectedByTimeframe) {
      if (firstKpiType === "INSTANT" && secondKpiType === "INSTANT") {
        message = firstKpiName + "' and '" + secondKpiName;
      } else if (firstKpiType === "INSTANT" && secondKpiType !== "INSTANT") {
        message = firstKpiName;
      } else if (secondKpiType === "INSTANT" && firstKpiType !== "INSTANT") {
        message = secondKpiName;
      }
    }

    const fontOpacityStyle = !isDataAffectedByTimeframe ? {opacity: 0.6} : {};

    return (
        <div css={ratioCube} data-tip data-for={tooltipId} onClick={() => onClick(config.get("id"))}>
          {!isDataAffectedByTimeframe &&
              <ReactTooltip
                  textColor={theme.palette.text.inverted}
                  backgroundColor={theme.palette.background.inverted}
                  place="top"
                  effect="solid"
                  id={tooltipId}>
                {"Always current data for '" + message + "'; timeframe selection not applied."}
              </ReactTooltip>}
          <h2 data-test-id="ratio-value" style={{fontSize: 18, fontWeight: 600, lineHeight: 1}}>{ratioValueDisplay}</h2>
          <p
              title={`${firstKpiName} / ${secondKpiName}`}
              data-test-id="ratio-names"
              style={{
                fontSize: 10,
                margin: 0,
                width: "calc(100%)",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}>{firstKpiName} / {secondKpiName}</p>
        </div>
    );
  } else {
    return (
        <div css={ratioCube}>
          <h2 style={{fontSize: 22, fontWeight: 700}}>N/A</h2>
        </div>
    );
  }
});

export default RatioPanel;