import React from "react";
import pure from "js/common/views/pure";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const ConfigContainer = pure(({
    label,
    onRemoveClick,
    children,
    theme
}) => {
    const defaultBackgroundColor = theme.themeId === "light" ? theme.palette.background.paper : "#555";
    const containerStyle = {
        border: `2px solid ${defaultBackgroundColor}`,
        paddingBottom: "1rem",
        marginBottom: "1rem"
    };
    const labelStyle = theme =>({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.6rem",
        color: theme.palette.primary.main,
        backgroundColor: defaultBackgroundColor
    });
    return (
        <div style={containerStyle}>
            <div style={labelStyle(theme)}>
                <span>{label}</span>
                <RemoveBtn theme={theme} onClick={onRemoveClick} />
            </div>
            {children}
        </div>
    );
});

const RemoveBtn = pure(({ onClick, theme }) => {
    const buttonStyle = theme =>({
        cursor: "pointer",
        fontSize: "1.25rem",
        color: theme.palette.textColor,
        ":hover": {
            color: theme.palette.text.inverted
        }
    });
    return <i className="fa fa-remove" style={buttonStyle(theme)} onClick={onClick} />;
});

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <ConfigContainer theme={theme} {...props} />;
};