/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import MediaQuery from "react-responsive";
import PureRenderMixin from "react-addons-pure-render-mixin";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";
import onClickOutside from "react-onclickoutside";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faOctopusDeploy} from "@fortawesome/free-brands-svg-icons";
import {Card} from "@mui/material";
import {css} from "@emotion/react";

import {
  faCodeCommit,
  faColumns,
  faTvRetro,
  faBolt,
  faChartLine,
  faLocation,
  faTable
} from "@fortawesome/pro-regular-svg-icons";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import LightLogo from "img/logo/light-mode-horizontal.svg";
import DarkLogo from "img/logo/dark-mode-horizontal.svg";
import Drawer from "js/common/views/drawer";
import * as Branding from "js/common/branding-constants"

import {reactAppToMarionetteComponent} from "js/common/views/react-to-marionette";
import JobPipelinesApp from "js/job-pipelines/app";
import UsageApp from "js/usage-app/app";
import AdminApp from "js/admin/app";
import ReportingApp from "js/reporting/app";
import DashboardApp from "js/dashboards/app";
import OneViewApp from "js/oneview/main";
import CubeTvChannelsSelectionApp from "js/cubetv/channel-selection/app";
import ChartingApp from "js/charting/app";
import OnPointApp from "js/onpoint/app";
import SquidApp from "js/squids/app";
import UserSettingsApp from "js/user-settings/app";

import {isFirefox} from "js/common/utils/detect-browser";
import {isDesktop, isTablet} from "js/common/ua-parser";
import currentClient from "js/common/repo/backbone/current-client";

import Cube19 from "js/cube19.app";

import UserAvatar from "js/common/views/user-avatar";
import * as Auth from "js/common/auth";
import * as Auditor from "js/common/auditer";
import * as Colors from "js/common/cube19-colors";
import * as Users from "js/common/users";
import * as Permissions from "js/common/permissions";
import * as Strings from "js/common/utils/strings";
import {currentQueryString} from "js/common/utils/query-strings";
import SwitchingUserPicker, {
  SwitchingUserItem,
  handleUserClick,
  getUsersForSwitching,
  setGroupBreadcrumbsLabel
} from "js/common/views/switching-user/switching-user-picker";
import {showLogoutButton} from "js/login/bullhorn-sso";
import {currentUserCanAccessApp, userCanAccessApp} from "js/common/permissions";

const IS_DESKTOP = isDesktop();
const IS_TABLET = isTablet();
const testEnvironmentBackgroundColour = "#f37fc4";

const MenuBarStyle = (currentTheme, palette) => {
  const prodBackgroundColour = currentTheme === "light" ? palette.white : palette.background.card;
  const isProd = window.location.hostname === "app.cube19.io" || window.location.hostname === "localhost";
  const isDemoMode = currentQueryString.demoMode?.toLowerCase() === "true";
  const useProdBackgroundColour = isProd || isDemoMode;
  const backgroundColour = useProdBackgroundColour ? prodBackgroundColour : testEnvironmentBackgroundColour;
  return {
    backgroundColor: backgroundColour,
    maxHeight: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: isFirefox() ? "56px" : "50px",
    position: "relative",
    zIndex: "100",
    overflow: "visible",
    boxShadow: currentTheme === "light" ? `0px 1px 0px ${Colors.bullhornLightGrey}` : "none",
    borderRadius: 0
  };
};

const MenuBarInnerStyle = (currentTheme) => css`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: ${currentTheme === "light" ? "100%" : "90em"};
  width: 98%;
  padding-left: 0.9375em;
  padding-right: 0.9375em;
  height: 100%;
`;

const showApp = app => Cube19.contentRegion.show(reactAppToMarionetteComponent(app));

const menuBarLogoStyle = {
  height: "30px",
  width: "auto"
};

const MenuBar = createReactClass({
  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      appView: ""
    };
  },

  getInitialState() {
    const {theme} = this.context;
    const {options, appView} = this.props;

    const currentPage = window.currentPage = appView
        .replace("group-", "")
        .replace("user-", "")
        .replace(" ", "_")
        .replace("-", "_")
        .toUpperCase();

    const links = Immutable.fromJS([
      {
        id: "ONEVIEW",
        label: "OneView",
        iconFn: () => faColumns,
        color: theme.palette.primary.main,
        onClick: () => showApp(<OneViewApp />),
        canShowInHeader: true,
        shouldShowFn: () => currentUserCanAccessApp(Permissions.apps.oneView)
      }, {
        id: "DASHBOARDS",
        label: "Dashboards",
        iconFn: () => faTable,
        color: "#9D3191",
        onClick: () => showApp(<DashboardApp {...options} />),
        canShowInHeader: true,
        shouldShowFn: () => currentUserCanAccessApp(Permissions.apps.dashboards) && (IS_DESKTOP || IS_TABLET)
      }, {
        id: "SQUID",
        label: "Squids",
        iconFn: () => faOctopusDeploy,
        color: Colors.blueIcon,
        onClick: () => showApp(<SquidApp />),
        canShowInHeader: true,
        shouldShowFn: () => currentUserCanAccessApp(Permissions.apps.squids) && (IS_DESKTOP || IS_TABLET)
      }, {
        id: "JOBS_PIPELINE",
        label: "Jobs Pipeline",
        iconFn: () => faCodeCommit,
        color: Colors.jobsPipelineColor,
        onClick: () => showApp(<JobPipelinesApp />),
        canShowInHeader: true,
        shouldShowFn: () => currentUserCanAccessApp(Permissions.apps.jobsPipeline)
      }, {
        id: "REPORTING",
        label: "Slice & Dice",
        iconFn: () => faBolt,
        color: Colors.sliceDiceColor,
        onClick: () => showApp(<ReportingApp {...options} />),
        canShowInHeader: true,
        shouldShowFn: () => currentUserCanAccessApp(Permissions.apps.sliceAndDice) && (IS_DESKTOP || IS_TABLET)
      }, {
        id: "ONPOINT",
        label: "OnPoint",
        iconFn: () => faLocation,
        color: Colors.onPointColor,
        onClick: () => showApp(<OnPointApp />),
        canShowInHeader: true,
        shouldShowFn: () => currentUserCanAccessApp(Permissions.apps.onPoint) && (IS_DESKTOP || IS_TABLET)
      }, {
        id: "CHARTING",
        label: "Trends",
        iconFn: () => faChartLine,
        color: Colors.chartingColor,
        onClick: () => showApp(<ChartingApp />),
        canShowInHeader: true,
        shouldShowFn: () => currentUserCanAccessApp(Permissions.apps.trends)
      }, {
        id: "CUBETV",
        label: "Gamification",
        iconFn: () => faTvRetro,
        color: Colors.tvColor,
        onClick: () => showApp(<CubeTvChannelsSelectionApp />),
        canShowInHeader: true,
        shouldShowFn: () => currentUserCanAccessApp(Permissions.apps.gamification)
      }, {
        id: "USER_SETTINGS",
        label: "Your Settings",
        onClick: () => {
          this.setState({showUserSettingsDrawer: true});
        },
        canShowInHeader: false,
        shouldShowFn: () => true
      }, {
        id: "USAGE",
        label: "Usage Stats",
        onClick: () => showApp(<UsageApp />),
        canShowInHeader: false,
        shouldShowFn: () => currentUserCanAccessApp(Permissions.apps.usage)
      }, {
        id: "ADMIN",
        label: "Admin",
        onClick: () => showApp(<AdminApp />),
        canShowInHeader: false,
        shouldShowFn: () => currentUserCanAccessApp(Permissions.apps.admin)
      }, {
        id: "USERS",
        label: "Users",
        onClick: () => console.log("CLICKED"),
        canShowInHeader: false,
        shouldShowFn: () => true
      }, {
        id: "LOGOUT",
        label: "Logout",
        onClick: () => Auth.logout(),
        canShowInHeader: false,
        shouldShowFn: showLogoutButton
      }]);

    return {
      currentPage,
      links,
      showUserSettingsDrawer: false
    };
  },

  render() {
    const {theme} = this.context;
    const {options} = this.props;
    const {themeId, palette} = theme;
    const currentTheme = themeId;

    const Logo = currentTheme === "light" ? LightLogo : DarkLogo;
    return [
      <Drawer
          key="user-settings-drawer"
          showCloseButton
          title="User Settings"
          openRight={true}
          width={490}
          open={this.state.showUserSettingsDrawer}
          onRequestClose={() => this.setState({showUserSettingsDrawer: false})}>
        <UserSettingsApp />
      </Drawer>,
      <Card className="clearfix" style={MenuBarStyle(currentTheme, palette)} key="menu-bar">
        <div css={MenuBarInnerStyle(currentTheme)}>
          <MediaQuery minWidth={1025}>
            <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexBasis: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexGrow: "1",
                  margin: "0 3% 0 0",
                  height: "100%"
                }}>
              <div style={{display: "flex", alignItems: "center"}}>
                {currentUserCanAccessApp(Permissions.apps.oneView)
                    ? <a onClick={() => showApp(<OneViewApp />)}><img
                        src={Logo}
                        alt={Branding.brandingName}
                        style={menuBarLogoStyle} /></a>
                    : <img src={Logo} alt={Branding.brandingName} style={menuBarLogoStyle} />}
              </div>
              {this.state.links
                  .filter(link => link.get("canShowInHeader"))
                  .filter(link => link.get("shouldShowFn")())
                  .map(link => (<HeaderMenuItem
                      id={link.get("id")}
                      key={link.get("id")}
                      label={link.get("label")}
                      icon={link.get("iconFn")()}
                      color={link.get("color")}
                      onClick={link.get("onClick")}
                      theme={theme}
                  />))}
            </div>
            <div style={{display: "flex", alignItems: "center", justifyContent: "flex-end", marginLeft: "2em"}}>
              <DropdownNavMenu
                  palette={palette}
                  currentTheme={currentTheme}
                  links={this.state.links}
                  options={options}
                  key="dropdown-desktop" />
            </div>
          </MediaQuery>
          <MediaQuery maxWidth={1024}>
            <div
                style={{
                  display: "flex",
                  marginRight: "5px",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  flexGrow: "1"
                }}>
              <DropdownNavMenu
                  palette={palette}
                  currentTheme={currentTheme}
                  links={this.state.links}
                  options={options}
                  collapsed={true}
                  key="dropdown-mobile" />
            </div>
          </MediaQuery>

        </div>
      </Card>,
      this.renderMenuBarLeftElement()
    ];
  },

  renderMenuBarLeftElement() {
    const {theme} = this.context;
    const {appView} = this.props;
    const {palette} = theme;
    const subNavStyle = {
      position: "fixed",
      zIndex: "9999",
      left: "1em",
      bottom: "1em",
      borderRadius: "35px",
      overflow: "hidden",
      transition: "background-color 0.5s ease"
    };

    // TODO why is this in here instead of being a common component used in each app area?
    switch (appView) {
      case "reporting":
        return <div
            key="sub-nav-rep"
            style={{...subNavStyle}}>{this.renderSavedReportsOrPagesButton(palette.sliceAndDice.main)}
        </div>;
      case "charting":
        return <div
            key="sub-nav-chart"
            style={{...subNavStyle}}>{this.renderSavedReportsOrPagesButton(palette.trends.main)}
        </div>;
      case "onpoint":
        return <div
            key="sub-nav-op"
            style={{...subNavStyle}}>{this.renderSavedReportsOrPagesButton(palette.onPoint.main)}
        </div>;
      default:
        return <EmptyPlaceholder key="empty" />;
    }
  },

  renderSavedReportsOrPagesButton(color) {
    const {theme} = this.context;
    const {appView} = this.props;
    const {palette} = theme;
    let buttonLabel;
    switch (appView) {
      case "charting":
        buttonLabel = "Saved Trends";
        break;
      default:
        buttonLabel = "Saved Reports";
        break;
    }
    const appViewToClickEventName = {
      charting: "saved-charting-pages",
      onpoint: "saved-onpoint-reports",
      reporting: "saved-reports"
    };

    return (
        <a
            key={`button-${appViewToClickEventName[appView]}`}
            style={{
              color: palette.text.main,
              backgroundColor: palette.background.card,
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: "0.8em 1em",
              ":hover": {
                backgroundColor: palette.background.light,
                boxShadow: "0 2px 13px rgba(0, 0, 0, 0.3)"
              }
            }}
            onClick={() => this.props.onClick(appViewToClickEventName[appView])}>
          <i className="fa fa-list" style={{color, marginRight: "8px", fontSize: "13px", verticalAlign: "middle"}} />
          <span style={{fontSize: "13px"}}>{buttonLabel}</span>
        </a>
    );
  }
});
MenuBar.contextType = CustomThemeContext;

const EmptyPlaceholder = () => <span key="empty-placeholder" />;

const DropdownNavMenu = onClickOutside(createReactClass({

  mixins: [PureRenderMixin],
  displayName: "DropdownNavMenu",

  getInitialState() {
    return {
      showMenu: false,
      isUserDrawerOpen: false
    };
  },

  render() {
    return (
        <div style={{position: "relative", zIndex: 999999}}>
          <AnimatedMenuIcon
              alignment="right"
              animation="flyOut"
              isMenuOpen={this.state.showMenu}
              palette={this.props.palette}
              onClick={this.handleOnIconButtonClick} />
          <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
            {this.state.showMenu && this.renderMenuItems()}
          </VelocityTransitionGroup>
        </div>);
  },

  renderMenuItems() {
    const {links, palette, currentTheme} = this.props;

    const menuStyle = {
      paddingTop: "16px",
      paddingBottom: "16px",
      position: "absolute",
      right: 0,
      top: "48px",
      backgroundColor: palette.background.card,
      boxShadow: "rgba(0, 0, 0, 0.5) 0 10px 20px",
      borderLeft: `2px solid ${palette.border.light}`,
      borderRight: `2px solid ${palette.border.light}`,
      borderTop: `2px solid ${palette.border.light}`,
      borderBottom: `4px solid ${palette.primary.main}`,
      minWidth: 300
    };

    const cube19User = Users.isCube19User(Users.getCurrentUser());
    const productType = currentClient.getType();

    return (
        <div style={menuStyle}>
          {cube19User && <div
              key="product-type" style={{
            borderBottom: `1px solid ${palette.border.main}`,
            marginBottom: "8px",
            paddingBottom: "8px",
            marginTop: "-8px",
            textAlign: "center"
          }}>
            <p
                style={{
                  marginBottom: "0",
                  fontSize: "0.75rem",
                  color: `${palette.text.disabled}`
                }}>
              {Strings.capitaliseWords(productType)}
            </p>
          </div>}
          <MediaQuery maxWidth={1024}>
            {links
                .filter(link => link.get("canShowInHeader"))
                .filter(link => link.get("shouldShowFn")())
                .map(link => <MenuItem
                    label={link.get("label")}
                    key={link.get("id")}
                    id={`${link.get("id")}-mobile`}
                    onClick={link.get("onClick")} />)}
          </MediaQuery>
          {links
              .filter(link => !link.get("canShowInHeader"))
              .filter(link => link.get("shouldShowFn")())
              .map(link => link.get("id") === "USERS" ? <UserMenu
                      label={link.get("label")}
                      key={link.get("id")}
                      id={link.get("id")}
                      palette={palette}
                      theme={currentTheme}
                      collapsed={this.props.collapsed}
                      isDrawerOpen={this.state.isUserDrawerOpen}
                      setIsDrawerOpen={isDrawerOpen => this.setState({
                        isUserDrawerOpen: isDrawerOpen
                      })}
                      onClick={link.get("onClick")}
                  /> : <MenuItem
                      label={link.get("label")}
                      key={link.get("id")}
                      id={link.get("id")}
                      onClick={link.get("onClick")} />
              )}
        </div>);
  },

  handleClickOutside() {
    if (!this.state.isUserDrawerOpen) {
      this.setState({
        showMenu: false
      });
    }
  },

  handleOnIconButtonClick() {
    if (!this.state.showMenu) {
      Auditor.audit("menu:opened");
    }
    this.setState(state => ({
      showMenu: !state.showMenu
    }));
  }

}));


const AnimatedMenuIcon = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    isMenuOpen: ReactPropTypes.bool.isRequired,
    onClick: ReactPropTypes.func.isRequired,
    alignment: ReactPropTypes.string,
    animation: ReactPropTypes.string
  },

  getDefaultProps() {
    return {
      animation: "basic",
      alignment: "left"
    };
  },

  render() {
    const {isMenuOpen, animation, alignment, onClick} = this.props;
    const containerStyle = {
      width: "30px",
      height: "30px",
      position: "relative",
      transform: "rotate(0deg)",
      transition: "0.5s ease-in-out",
      cursor: "pointer"
    };

    // added styles instead of class for the 'walk me' UI test
    const pullDirection = {float: alignment};

    const spanStyle = {
      display: "block",
      position: "absolute",
      height: "3px",
      width: "100%",
      backgroundColor: this.props.palette.text.main,
      borderRadius: "3px",
      opacity: 1,
      left: 0,
      transform: "rotate(0deg)",
      transformOrigin: animation === "basic" ? "left center" : "initial",
      transition: "0.25s ease-in-out"
    };
    const span1Style = isMenuOpen ? span1Animation[animation] : {};
    const span2Style = isMenuOpen ? span2Animation[animation] : {};
    const span3Style = isMenuOpen ? span3Animation[animation] : {};
    return (
        <button
            id="TESTCAFE_menu_button"
            style={{...pullDirection, ...containerStyle, background: "none", top: "8px"}}
            onClick={onClick}>
          <span key="span-1" style={{top: "5px", ...spanStyle, ...span1Style}}></span>
          <span key="span-2" style={{top: "15px", ...spanStyle, ...span2Style}}></span>
          <span key="span-3" style={{top: "25px", ...spanStyle, ...span3Style}}></span>
        </button>);
  }

});

const HeaderMenuItem = (props) => {
  const {label, onClick, id, icon, color, theme} = props;
  const showBorder = id === window.currentPage;
  const currentTheme = theme.themeId;

  const menuItemStyle = css`
    cursor: pointer;
    padding: 0;
    text-align: left;
    font-size: 12px;
    font-family: ${theme.typography.fontFamilyBold};
    display: flex;
    text-decoration: none;
    height: 100%;
    align-items: center;
    position: relative;
    text-transform: uppercase;
    font-weight: 500;

    &:after {
      display: block;
      content: "";
      border-bottom: 2px solid ${id === "ONEVIEW" ? theme.palette.primary.main : color};
      transform: ${showBorder ? "scaleX(1)" : "scaleX(0)"};
      transition: transform 250ms ease-in-out;
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      z-index: 999999;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  `;

  // note: bullhorn hidden icons
  const iconStyle = {
    display: currentTheme === "light" ? "block" : "block",
    color: id === "ONEVIEW" ? theme.palette.primary.main : color,
    fontSize: "16px",
    marginRight: "8px",
    position: "relative",
    bottom: "-1px",
    opacity: "0.9"
  };

  const textStyle = {
    color: currentTheme === "light" ? theme.palette.text.main : Colors.white,
    position: "relative",
    bottom: "-2px"
  };

  const handleClick = () => {
    onClick();
  };

  return <a css={menuItemStyle} id={id} key={`header-menu-${id}`} data-product-id={`header-menu-${id}`} onClick={handleClick}>
    <FontAwesomeIcon icon={icon} style={iconStyle} key={`icon-${icon}`} />
    <span style={textStyle}>{label}</span>
  </a>;
};

const MenuItem = createReactClass({
  displayName: "MenuItem",
  mixins: [PureRenderMixin],

  propTypes: {
    label: ReactPropTypes.string.isRequired,
    onClick: ReactPropTypes.func.isRequired,
    id: ReactPropTypes.string
  },

  render() {
    const {theme: {palette}} = this.context;
    const {label, onClick, id} = this.props;
    const padding = IS_DESKTOP ? "15px" : "25px";
    const menuItemStyle = {
      cursor: "pointer",
      color: palette.text.main,
      paddingLeft: padding,
      paddingRight: padding,
      textAlign: "left",
      display: "block",
      textDecoration: "none",
      lineHeight: IS_DESKTOP ? 2 : 3,
      whiteSpace: "nowrap",
      fontSize: 15
    };
    return <a id={id} style={menuItemStyle} key={`header-menu-item-${id}`} onClick={onClick}>{label}</a>;
  }
});
MenuItem.contextType = CustomThemeContext;

const UserMenu = createReactClass({
  displayName: "UserMenu",
  mixins: [PureRenderMixin],

  propTypes: {
    label: ReactPropTypes.string.isRequired,
    onClick: ReactPropTypes.func.isRequired,
    id: ReactPropTypes.string,
    collapsed: ReactPropTypes.bool
  },

  getInitialState() {
    return {
      isDrawerOpen: false
    };
  },

  render() {
    const {theme} = this.context;
    const userDisplayLimit = 5;

    const currentUser = setGroupBreadcrumbsLabel(Users.getCurrentUser());
    const sourceUser = setGroupBreadcrumbsLabel(Users.getSourceUser());

    const usersToDisplay = getUsersForSwitching()
        .filterNot(user => user.get("id") === currentUser.get("id"));
    const useDrawer = usersToDisplay.size > userDisplayLimit || (usersToDisplay.size > 0 && this.props.collapsed);

    const {id, palette} = this.props;
    const padding = IS_DESKTOP ? "15px" : "25px";

    return <div>
      <hr style={{marginBottom: 0}} />
      <div style={getMenuItemStyle(palette, padding, theme)} key={`header-menu-item-${id}`}>

        <span style={{fontSize: "0.8rem"}}>Logged in as</span>
        <SwitchingUserMenuItem
            user={currentUser}
            isSourceUser={false}
            isCurrentUser={true}
            onUserClick={handleUserClick} />

        {useDrawer
            ? <>
              <div>
                <hr style={{marginTop: 5, marginBottom: 0}} />
                <span style={{fontSize: "0.8rem"}}>Available Users</span>
              </div>
              {(currentUser.get("id") !== sourceUser.get("id")) && <SwitchingUserMenuItem
                  user={sourceUser}
                  isSourceUser={true}
                  isCurrentUser={false}
                  onUserClick={handleUserClick} />}
              <div style={{cursor: "pointer"}} onClick={() => this.props.setIsDrawerOpen(true)}>
                <i style={{fontSize: "1.6rem", marginRight: 7, padding: "4px 8px 8px"}} className="fa fa-users" />
                View All
              </div>
              <Drawer
                  open={this.props.isDrawerOpen}
                  onRequestClose={() => this.props.setIsDrawerOpen(false)}
                  showCloseButton
                  openRight={true}
                  width={598}
                  title="Choose A User To Log In As">
                <div style={{padding: 20}}>
                  <SwitchingUserPicker listStyle={{maxHeight: "75vh", overflow: "scroll"}} />
                </div>
              </Drawer>
            </>
            : <>
              {!usersToDisplay.isEmpty() && <div>
                <hr style={{marginTop: 5, marginBottom: 0}} />
                <span style={{fontSize: "0.8rem"}}>Available Users</span>
              </div>}
              {usersToDisplay.map(user => {
                const userId = user.get("id");
                const isCurrent = currentUser.get("id") === userId;
                const isSource = sourceUser.get("id") === userId;
                return <SwitchingUserMenuItem
                    key={userId}
                    user={user}
                    isSourceUser={isSource}
                    isCurrentUser={isCurrent}
                    onUserClick={handleUserClick} />;
              })}
            </>}
      </div>
      <hr />
    </div>;
  }
});
UserMenu.contextType = CustomThemeContext;

const SwitchingUserMenuItem = React.memo(({user, isSourceUser, isCurrentUser, onUserClick}) => {
  const icon = <UserAvatar user={user} style={avatarStyle} />;
  return <div
      onClick={() => !isCurrentUser && onUserClick(user.get("id"))}
      style={isCurrentUser ? currentUserMenuItemStyle : userMenuItemStyle}>
    <SwitchingUserItem user={user} disableEmail={true} icon={icon} />
    {isSourceUser && <i className="fa fa-home" style={{marginTop: 2, marginLeft: 10}} />}
  </div>;
});

const userMenuItemStyle = {
  marginBottom: 10,
  display: "flex",
  cursor: "pointer"
};
const currentUserMenuItemStyle = {
  ...userMenuItemStyle,
  fontWeight: 700,
  cursor: "auto"
};

const avatarStyle = {
  height: 50,
  width: 50,
  maxWidth: "none",
  borderRadius: 10,
  marginRight: 7,
  objectFit: "cover"
};

const getMenuItemStyle = (palette, padding, theme) => ({
  color: palette.text.main,
  paddingLeft: padding,
  paddingRight: padding,
  textAlign: "left",
  display: "block",
  textDecoration: "none",
  lineHeight: 1.4,
  whiteSpace: "nowrap",
  fontSize: 15
});

const span1Animation = {
  basic: {
    transform: "rotate(45deg)",
    left: "6px"
  },
  flyOut: {
    transform: "rotate(135deg)",
    top: "15px"
  }
};

const span2Animation = {
  basic: {
    width: 0,
    opacity: 0
  },
  flyOut: {
    opacity: 0,
    left: "-30px"
  }
};

const span3Animation = {
  basic: {
    transform: "rotate(-45deg)",
    top: "26px",
    left: "6px"
  },
  flyOut: {
    transform: "rotate(-135deg)",
    top: "15px"
  }
};

export {MenuBarStyle, menuBarLogoStyle};
export default MenuBar;

