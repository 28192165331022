import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import Immutable from "immutable";
import moment from "moment";
import PureRenderMixin from "react-addons-pure-render-mixin";

import { Layout } from "js/common/views/foundation-column-layout";
import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import LoadingSpinner from "js/common/views/loading-spinner";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";

const path = window.path;

export default createReactClass({

  mixins: [ PureRenderMixin ],

  propTypes: {
    assignedTo: ReactPropTypes.string.isRequired,
    assignedToId: ReactPropTypes.number.isRequired
  },

  getInitialState() {
    return {
      isLoading: false,
      inheritedTimezones: Immutable.List()
    };
  },

  componentDidMount() {
    const { assignedTo, assignedToId } = this.props;
    const isUser = assignedTo === "USER";
    const groupId = isUser ? Users.getUser(assignedToId).get("groupId") : Groups.getGroup(assignedToId).get("parentId");
    this.loadInheritedTimezones(groupId);
  },

  UNSAFE_componentWillReceiveProps(nextProps){
    const nextAssignedTo = nextProps.assignedTo;
    const nextAssignedToId = nextProps.assignedToId;
    if (this.props.assignedTo === nextAssignedTo && this.props.assignedToId === nextAssignedToId) {
      return;
    }

    const isUser = nextAssignedTo === "USER";
    const groupId = isUser
        ? Users.getUser(nextAssignedToId).get("groupId")
        : Groups.getGroup(nextAssignedToId).get("parentId");
    this.loadInheritedTimezones(groupId);
  },

  render() {
    return (
        <div>
          <AdminHeader>
            <Icon icon="map"/>
            Inherited timezone locations
          </AdminHeader>
        <Layout allSmall={12} rowStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
    {this.state.isLoading ? <LoadingSpinner /> : this.renderTable()}
  </Layout>
    </div>);
  },

  renderTable() {
    const COLUMNS = [ "Timezone Location", "Inherited From", "Start Date", "End Date" ];
    return (
        <SimpleDataTable
    columns={COLUMNS}
    rows={this.state.inheritedTimezones.map(inheritedTimezoneToRow).toJS()}
    initialSortBy={COLUMNS.indexOf("Start Date")}
    initialSortDirection="ASC" />);
  },

  loadInheritedTimezones(groupId) {
    this.setState({
      isLoading: true
    }, () => {
      loadInheritedTimezones(groupId)
          .then(inheritedTimezones => {
            this.setState({
              isLoading: false,
              inheritedTimezones
            });
          });
    });
  }

});

const inheritedTimezoneToRow = inheritedTimezone => {
  const location = inheritedTimezone.get("location");
  const breadcrumbs = Groups.getGroupBreadcrumbs(inheritedTimezone.get("assignedToId"));
  const inheritedFrom = <Breadcrumbs trail={breadcrumbs} />;
  const startDate = formatDate(inheritedTimezone.get("startDate"));
  const endDate = formatDate(inheritedTimezone.get("endDate"));

  return [
    location,
    inheritedFrom,
    startDate,
    endDate
  ];
};

const Breadcrumbs = ({ trail }) => (
    <span>
    {trail.map((group, i) => {
            const isLast = i === trail.length - 1;
            const groupId = group.id;
            return (
                <span key={groupId} id={groupId}>
            <span>{group.name}</span>
            {!isLast &&
            <i className="fa fa-chevron-right" style={{ fontSize: "0.725rem", padding: "0 5px" }} />}
            </span>);
            })}
          </span>
);

const loadInheritedTimezones = initialGroupId => {
  let groupIds = [];
  let group = Groups.getGroup(initialGroupId);
  while (group) {
    groupIds.push(group.get("id"));
    group = Groups.getGroup(group.get("parentId"));
  }
  const promises = groupIds.map(loadTimezoneHistoryForGroup);
  return Promise.all(promises).then(results => Immutable.fromJS(results).flatten(1));
};
const loadTimezoneHistoryForGroup = groupId => ajax
    .get({ url: path("groups", groupId, "locations") })
    .then(response => Immutable.fromJS(response).map(parseDatesInTimezoneAssignment));

const parseDatesInTimezoneAssignment = timezoneAssignment => timezoneAssignment
    .set("startDate", parseDate(timezoneAssignment.get("startDate")))
    .set("endDate", parseDate(timezoneAssignment.get("endDate")));
const parseDate = (dateStr, pattern = "YYYY-MM-DD HH:mm:ss") => moment(dateStr, pattern);
const formatDate = (date, pattern = "L") => date.format(pattern);
