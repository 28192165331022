/** @jsxImportSource @emotion/react */

import React from "react";
import {Tab, Tabs} from "@mui/material";
import Immutable from "immutable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBracketsCurly} from "@fortawesome/pro-solid-svg-icons";

import SettingsTab from "js/squids/squid-display/edit-mode/settings-tab";
import FormulaeTab from "js/squids/squid-display/edit-mode/formula-tab/formulae-tab";
import StepsTab from "js/squids/squid-display/edit-mode/steps-tab";
import JSONTab from "js/squids/squid-display/edit-mode/json-tab";
import ErrorIcon from "js/squids/squid-display/error-icon";
import Drawer from "js/common/views/drawer";
import * as Users from "js/common/users";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";


const SettingsDrawer = React.memo(({
  isDrawerOpen,
  setIsDrawerOpen,
  setIsEditNodeOpen,
  setEditNodeIndex,
  openSteps,
  setOpenSteps,
  tabStyle,
  handleEditPage,
  handleEditTitle,
  page,
  evaluatedPage,
  title,
  squidNames,
  listOfFormulaUses,
  masterKpiTypeToKpiId,
  kpiIdToValue,
  idToFormula,
  idToNode,
  inputDelayMillis,
  errors
}) => {
  const [activeTab, setActiveTab] = React.useState("Settings");

  const tabData = Immutable.fromJS([
    {label: "Settings", icon: "fa fa-cog", hasErrors: errors.some(e => e.get("category") === "description")},
    {label: "Formulae", icon: "fa fa-square-root-alt", hasErrors: errors.some(e => e.get("category") === "custom-formula")},
    {label: "Steps", icon: "fa fa-stream", hasErrors: errors.some(e => e.get("category") === "step")},
    {label: "JSON", icon: "fa fa-brackets-curly", newIcon: () => faBracketsCurly }
  ]);

  const { palette } = React.useContext(CustomThemeContext).theme;

  const hasJSONEditorPermission = React.useMemo(() => {
    return Users.currentHasPermission("SQUID_JSON_EDITOR");
  }, []);

  return <Drawer open={isDrawerOpen}
                 title="Settings"
                 showCloseButton
                 onRequestClose={() => setIsDrawerOpen(false)}
                 onRequestOpen={() => setIsDrawerOpen(true)}
                 openRight={true}
                 minWidth={800}
                 width={"70%"}>
    <div style={{minHeight: "100%"}}>
      <div>
        <Tabs style={{marginBottom: 5, marginTop: -10}}
              value={activeTab}
              onChange={(event, value) => setActiveTab(value)}
              TabIndicatorProps={{
                style: {
                  display: "none"
                }
              }}>
          {tabData
              .filter(tab => !(tab.get("label") === "JSON" && !hasJSONEditorPermission))
              .map(tab => {
            const label = tab.get("label");
            const labelComponent = <div>
              {tab.get("newIcon") ?
                  <FontAwesomeIcon icon={tab.get("newIcon")()} style={{marginRight: 5}} key={`icon-${tab.get("newIcon")}`}/>
                  :
                  <i className={tab.get("icon")} style={{paddingRight: "5px"}}/>
              }{label} {tab.get("hasErrors") &&
            <ErrorIcon style={{position: "relative", top: "-10px", left: "-5px"}} />}</div>;
            return <Tab style={label === activeTab ? {...tabStyle, color: palette.text.main, borderTop: `0 !important`, borderBottom: `2px solid ${palette.primary.main}`} : tabStyle}
                        label={labelComponent}
                        value={label}
                        key={label} />;
          })}
        </Tabs>
      </div>
      {activeTab === "Settings" &&
      <SettingsTab
          page={page}
          title={title}
          squidNames={squidNames}
          handleEditPage={handleEditPage}
          handleEditTitle={handleEditTitle}
          inputDelayMillis={inputDelayMillis}
          errors={errors.filter(e => e.get("category") === "description")} />}
      {activeTab === "Formulae" &&
      <FormulaeTab
          page={page}
          listOfFormulaUses={listOfFormulaUses}
          setActiveTab={setActiveTab}
          setIsEditNodeOpen={setIsEditNodeOpen}
          setEditNodeIndex={setEditNodeIndex}
          openSteps={openSteps}
          setOpenSteps={setOpenSteps}
          handleEditPage={handleEditPage}
          inputDelayMillis={inputDelayMillis}
          formulaErrors={errors.filter(e => e.get("category") === "custom-formula")} />}
      {activeTab === "Steps" &&
      <StepsTab
          page={page}
          evaluatedPage={evaluatedPage}
          handleEditPage={handleEditPage}
          openSteps={openSteps}
          setOpenSteps={setOpenSteps}
          masterKpiTypeToKpiId={masterKpiTypeToKpiId}
          kpiIdToValue={kpiIdToValue}
          idToFormula={idToFormula}
          idToNode={idToNode}
          errors={errors.filter(e => e.get("category") === "step")}
          inputDelayMillis={inputDelayMillis}/>}
      {activeTab === "JSON" && hasJSONEditorPermission
      && <JSONTab
          page={page}
          handleEditPage={handleEditPage} />}
    </div>
  </Drawer>;
});

export default SettingsDrawer;
