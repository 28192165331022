import React from "react";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants"

const NewUsersGuide = React.memo(() => {
    const {theme} = React.useContext(CustomThemeContext);
    const boxStyle = theme =>({
        border: theme.themeId === "light" ? `1px solid ${theme.palette.background.paper}` : "none",
        backgroundColor: theme.themeId === "dark" ? "#272731" : "#eeeeee"
    });

    return (
        <div className="text-box-wrapper" style={{ ...boxStyle(theme), padding: "1rem"}}>
            <h2 style={{ fontSize: "1rem", color: theme.palette.primary.main, textTransform: "uppercase", paddingBottom: "1.25rem" }}>
                Activating Users
            </h2>
            <p>
                All Users are automatically added once they are marked as Active on your CRM. You can then choose the type of User:
            </p>

            <dl>
                <dt style={labelStyle(theme)}>
                    <i className="fa fa-user" style={iconStyle(theme)} /> Full User
                </dt>
                <dd>
                    A User who adds data within the CRM <em>(Revenue or Activity)</em>
                </dd>

                <dt style={labelStyle(theme)}>
                    <i className="fa fa-user-secret" style={iconStyle(theme)} /> Observer
                </dt>
                <dd>
                    A User who can review the data but doesn't add data to CRM
                </dd>

                <dt style={labelStyle(theme)}>
                    <i className="fa fa-moon-o" style={iconStyle(theme)} /> Inactive User
                </dt>
                <dd style={{ marginBottom: 0 }}>
                    A User who doesn't need any access to {Branding.brandingName}
                </dd>
            </dl>
        </div>
    );
});
    

const iconStyle = theme => ({
    color: theme.palette.textColor,
    paddingRight: 5
});

const labelStyle = theme => ({
    fontWeight: "normal",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    marginBottom: 0,
    marginTop: "1.25rem"
});

export default NewUsersGuide;
