import eventBus from "js/cube19.event-bus";

const Backbone = window.Backbone;

const CurrencyModel = Backbone.Model.extend({
    idAttribute: "code",

    getSymbol: function() {
        return toSymbol(this);
    }

});

const CurrencyCollection = Backbone.Collection.extend({
    url: "currencies",
    model: CurrencyModel
});

const collection = new CurrencyCollection();
function load() {
    return collection.fetch()
    .then(() => {
        eventBus.trigger("data-loaded", "currencies");
    }, error => {
        eventBus.trigger("error:fatal", error);
    });
}

function get(code) {
    return collection.get(code);
}

function getSymbol(code) {
    if (!code) {
        return "";
    }
    const currency = get(code);
    if (!currency) {
        return "";
    }
    return currency.getSymbol();
}

const toSymbol = currency => {
    let symbol = currency.get("display") || currency.get("symbol") || currency.get("code");

    // add a space between the currency display and the value if the string contains alphabetical characters [#750]
    if (symbol.match(/^[A-Za-z]+$/)) {
        symbol += " ";
    }
    return symbol;
}

function getAll() {
    return collection;
}

export {
    load,
    get,
    getSymbol,
    toSymbol,
    getAll
};
