import React from "react";

import {betterMemo} from "js/common/utils/more-memo";
import userProfile from "img/user-profile.jpg";

const UserAvatar = betterMemo(
    {displayName: "UserAvatar"},
    ({user, ...props}) => <img
    src={user.get("photoURL") || userProfile}
    onError={event => event.target.src = userProfile}
    loading={"lazy"}
    alt={"User photo"}
    {...props} />);

export default UserAvatar;