import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import pure from "js/common/views/pure";
import CloseButton from "js/common/views/inputs/close-button";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const TabsDialog = createReactClass({

  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      preventScrolling: true
    };
  },

  componentDidMount() {
    if (this.props.preventScrolling) {
      preventScrolling();
    }
  },

  componentWillUnmount() {
    if (this.props.preventScrolling) {
      allowScrolling();
    }
  },


  render() {
    const onClick = this.props.closeOnClickOutside ? () => this.props.onRequestClose() : () => {};
     return (
          <div className="dialog-overlay" onClick={onClick}>
            <Dialog
                theme={this.context.theme}
                label={this.props.label}
                content={this.props.content}
                onRequestClose={this.props.onRequestClose}
                minWidth={this.props.minWidth}
                width={this.props.width}
                minHeight={this.props.minHeight}
                height={this.props.height}
                flex={this.props.flex}
                titleStyle={this.props.titleStyle}
                contentOverflowAutoScroll={this.props.contentOverflowAutoScroll} />
          </div>
      );
  }

});

const Dialog = pure(({
  theme,
  label,
  content,
  onRequestClose,
  minWidth,
  width,
  minHeight,
  height,
  titleStyle,
  contentOverflowAutoScroll = true,
  flex
}) => {
  const dialogStyle = {
    display: "inline-block",
    verticalAlign: "middle",
    overflow: contentOverflowAutoScroll ? "auto" : "hidden",
    padding: "10px 15px",
    borderRadius: 5,
    boxShadow: "0px 25.6px 57.6px 0px rgba(0, 0, 0, 0.22), 0px 4.8px 14.4px 0px rgba(0, 0, 0, 0.18)",
    backgroundColor: theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.paper,
    textAlign: "left",
    WebkitOverflowScrolling: "touch"
  };

  if (minWidth) {
    dialogStyle.minWidth = minWidth;
  } else {
    dialogStyle.width = width || "95vw";
  }
  if (minHeight) {
    dialogStyle.minHeight = minHeight;
  } else {
    dialogStyle.height = height || "95vh";
  }

  return (
      <div className="dialog-container" style={dialogStyle} onClick={event => {event.stopPropagation();}}>
        <DialogContent
            theme={theme}
            label={label}
            content={content}
            titleStyle={titleStyle}
            flex={flex}
            onCloseClick={onRequestClose} />
      </div>
  );
});

const DialogContent = pure(({
  theme,
  label,
  content,
  onCloseClick,
  titleStyle = {},
  flex
}) => {
  const closeBtnContainerStyle = {
    display: "inline-block",
    height: 12,
    width: 12
  };
  const labelContainerStyle = {
    display: "inline-block",
    minHeight: closeBtnContainerStyle.height,
    paddingRight: "1rem",
    color: theme.palette.textColor,
    ...titleStyle
  };
  const parentStyle = flex ?
      {
        display: "flex",
        flex: flex,
        flexDirection: "column",
        height: "100%"
      } :
      {height: "100%"};
  const titleBarStyle = {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "0.5rem",
    borderBottom: `1px solid ${theme.palette.border.light}`,
  };
  const contentContainerStyle = {
    overflow: "auto",
    display: flex ? "flex" : "block",
    height: flex ? "100%" : "calc(100% - 42px)"
  };

  return (
      <div style={parentStyle}>
        <div style={titleBarStyle}>
          <div style={labelContainerStyle} className="dialogTitle">
            {label}
          </div>

          <div style={closeBtnContainerStyle}>
            <CloseButton
                onClick={onCloseClick}
                style={{
                  position: "relative",
                  top: "-0.2rem",
                  padding: 0,
                  fontSize: 10,
                  opacity: 0.8
            }}/>
          </div>
        </div>

        <div className="tabs-content-container" style={contentContainerStyle}>
          {content}
        </div>
      </div>
  );
});

const preventScrolling = () => document.body.style.overflow = "hidden";
const allowScrolling = () => !document.body.classList.contains("forceOverflowHidden") ? document.body.style.overflow = "auto" : null;

TabsDialog.contextType = CustomThemeContext;

export default TabsDialog;
