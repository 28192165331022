import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import * as Immutable from "immutable";

import Layout from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import Link from "js/admin/link";
import PlaceholderTextField from "js/common/views/inputs/placeholder-text-field";
import Breadcrumbs from "js/admin/simple-breadcrumbs";
import CurrencyPicker from "js/admin/groups/currency-picker";
import InlineRadioButtons from "js/common/views/inputs/inline-radio-buttons";
import AudioPlayer from "js/admin/simple-audio-player";
import DealMusicPicker from "js/admin/deal-music-picker";
import InheritedFromGroupInfo from "js/admin/inherited-from-group-info";
import Overlay from "js/common/views/overlay";
import PagePicker from "js/common/views/inputs/page-picker";
import InheritedLocalePicker from "js/common/views/inputs/inherited-locale-picker";
import DeletionDialog from "js/admin/groups/deletion-dialog";
import currentClient from "js/common/repo/backbone/current-client";
import * as currencyRepo from "js/common/repo/backbone/currency-repo";
import * as dealMusicRepo from "js/common/repo/deal-music-repo";
import * as Groups from "js/common/groups";
import RadioButton from "js/common/views/inputs/radio-button";
import Checkbox from "js/common/views/inputs/checkbox";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import { RadioGroup as RadioButtonGroup } from '@mui/material';

const silentMp3Id = dealMusicRepo.getSilentMp3Id();

const EditGroupPanel = createReactClass({

  displayName: "EditGroupPanel",

  mixins: [PureRenderMixin],

  propTypes: {
    group: ImmutablePropTypes.map.isRequired,
    pages: ImmutablePropTypes.list.isRequired,
    idToLocale: ImmutablePropTypes.map.isRequired,
    onChange: ReactPropTypes.func.isRequired,
    onDeleteGroup: ReactPropTypes.func.isRequired,
    onAddSubGroupClick: ReactPropTypes.func.isRequired,
    isUpdating: ReactPropTypes.bool.isRequired,
    activeUsersInGroupCount: ReactPropTypes.number,
    isSmallScreen: ReactPropTypes.bool,
    highlightSection: ReactPropTypes.string,
    theme: ReactPropTypes.object
  },

  getInitialState() {
    return {
      editableGroupName: this.props.group.get("name"),
      isDealMusicPickerOpen: false,
      isDeleteDialogOpen: false
    };
  },

  componentDidUpdate(prevProps) {
    const newGroup = this.props.group;
    const oldGroup = prevProps.group;
    if (newGroup.get("id") !== oldGroup.get("id") || newGroup.get("name") !== oldGroup.get("name")) {
      this.setState(this.getInitialState());
    }
  },

  render() {
    const {group, activeUsersInGroupCount, onAddSubGroupClick, isUpdating, isSmallScreen} = this.props;
    const {isDeleteDialogOpen} = this.state;
    const groupName = this.state.editableGroupName;
    const isDeleted = group.get("deleted");
    const parentGroup = Groups.getGroup(group.get("parentId"));
    const canRestore = parentGroup && !parentGroup.get("deleted");
    const client = currentClient;
    const hasChildren = Immutable.fromJS(Groups.getChildGroups(group.get("id")))
        .filter(childGroup => !childGroup.get("deleted")).size > 0;

    const getErrorMsg = () => {
      if (groupName.length === 0) {
        return `Group name required. Change to "${group.get("name")}" was not saved.`;
      } else if (groupName.replace("[deleted] ", "").length > 64) {
        return "Please provide a name of 64 characters or less.";
      } else {
        return null;
      }
    };

    return (
        <div>
          <div style={{margin: "0 1rem"}}>
            <PlaceholderTextField
                label="Group name"
                hintText="Name this group"
                errorText={getErrorMsg()}
                value={groupName}
                onChange={this.onNameChange} />
          </div>
          {group.get("parentId") && this.renderBreadcrumbsToParentGroup()}
          {!!activeUsersInGroupCount &&
          <div style={{margin: "1rem"}}>
            <i className="fa fa-info-circle" />
            <em style={{paddingLeft: 8, paddingRight: 8}}>{activeUsersInGroupCount} active user(s)</em>
          </div>}
          {isDeleted &&
          <div style={{margin: "1rem"}}>
            <i className="fa fa-info-circle" />
            <span style={{paddingLeft: 8}}>This Group has been deleted</span>
          </div>}

          {this.renderCurrencySection()}

          {this.renderWeekStartsOnSection()}

          {this.renderLocaleSection()}

          {client.canAccessApp("CAROUSEL_ADMIN") && this.renderDealMusicSection()}

          {this.renderOneviewPageSection()}

          <div style={{textAlign: "center", ...spacingStyle}}>
            {isDeleted ?
                <TextButton
                    label="Restore Group"
                    onClick={() => this.onRestoreGroupClick()}
                    disabled={!canRestore}
                    tooltipContent={canRestore ? "" : "Can't restore as parent is deleted"}
                    fullWidth={isSmallScreen}
                    style={{margin: isSmallScreen ? "0.5rem 0" : "0 0.5rem"}} />
                :
                <div>
                  <TextButton
                      label="Add a Sub-Group"
                      onClick={() => onAddSubGroupClick(group.get("id"))}
                      fullWidth={isSmallScreen}
                      style={{margin: isSmallScreen ? "0.5rem 0" : "0 0.5rem"}} />
                  <TextButton
                      label="Delete Group"
                      onClick={() => this.onDeleteGroupClick()}
                      fullWidth={isSmallScreen}
                      disabled={hasChildren}
                      type={"alert"}
                      tooltipContent={hasChildren ? "Can't delete Group with sub-Groups" : ""}
                      style={{margin: isSmallScreen ? "0.5rem 0" : "0 0.5rem"}} />
                </div>}
          </div>

          {isDeleteDialogOpen &&
          <DeletionDialog
              group={group}
              onCancelClick={this.onCloseDeleteDialogClick}
              onDeleteGroup={this.onDeleteGroup} />}

          {isUpdating && <Overlay />}
        </div>
    );
  },

  renderBreadcrumbsToParentGroup() {
    const {theme} = this.props;
    return (
        <Layout allSmall={12} rowStyle={{fontSize: "0.9rem", marginBottom: "0.5rem", ...spacingStyle}}>
          <div>
            <span style={{color: "#999", paddingRight: 8}}>Sub-group of:</span>
            <Breadcrumbs trail={this.props.group.get("breadcrumbs").pop()} dividerColor={theme.palette.textColor}/>
          </div>
        </Layout>
    );
  },

  renderCurrencySection() {
    const {group, highlightSection, theme} = this.props;
    const isRootGroup = !group.get("parentId");
    const isInherited = group.get("inheritedCurrencyGroupId") !== group.get("id");
    const highlightStyle = highlightSection === "currency" ? { border: `2px solid ${theme.palette.primary.main}`} : {};

    return (
        <div style={{...sectionContainerStyle, ...highlightStyle}}>
          <Layout allSmall={12} medium={[3, 9]} floatLastColumnLeft={true}>
            <label style={labelStyle}>Currency</label>
            {isInherited ?
                <span style={textStyle}>{group.get("currencyCode")}</span> :
                <CurrencyPicker value={group.get("currencyCode")} onChange={this.onCurrencyCodeChange} />}
            {!isRootGroup &&
            <Checkbox
                label="Inherit from Parent Group"
                style={{marginTop: -4}}
                checked={isInherited}
                onCheck={(e, isChecked) => this.onInheritCurrencyChange(isChecked)} />}
          </Layout>
          {isInherited &&
          <Layout allSmall={12} medium={[3, 9]} floatLastColumnLeft={true} rowStyle={{paddingTop: 0}}>
            <div>&nbsp;</div>
            <InheritedFromGroupInfo originalGroupId={group.get("inheritedCurrencyGroupId")} theme={theme} />
          </Layout>}
        </div>
    );
  },

  renderWeekStartsOnSection() {
    const {group, theme} = this.props;
    const isRootGroup = !group.get("parentId");
    const isInherited = group.get("inheritedWeekdayGroupId") !== group.get("id");
    const options = [{
      label: "Sun",
      value: "SUNDAY"
    }, {
      label: "Mon",
      value: "MONDAY"
    }, {
      label: "Tue",
      value: "TUESDAY"
    }, {
      label: "Wed",
      value: "WEDNESDAY"
    }, {
      label: "Thu",
      value: "THURSDAY"
    }, {
      label: "Fri",
      value: "FRIDAY"
    }, {
      label: "Sat",
      value: "SATURDAY"
    }];
    return (
        <div style={sectionContainerStyle}>
          <Layout allSmall={12} medium={isInherited ? [3, 3, 6] : [3, 9]}>
            <label style={labelStyle}>Week starts on</label>
            {isInherited ?
                <span style={textStyle}>{group.get("weekStartsOn")}</span> :
                <InlineRadioButtons
                    options={options}
                    selected={group.get("weekStartsOn")}
                    onChange={this.onWeekStartsOnChange} />}
            {(!isRootGroup && isInherited) &&
            <Checkbox
                label="Inherit from Parent Group"
                style={{marginTop: -4}}
                checked={isInherited}
                onCheck={(e, isChecked) => this.onInheritWeekStartsOnChange(isChecked)} />}
          </Layout>
          {(!isRootGroup && !isInherited) &&
          <Layout allSmall={12} medium={[3, 9]} rowStyle={{paddingTop: "0.5rem"}}>
            <div>&nbsp;</div>
            <Checkbox
                label="Inherit from Parent Group"
                style={{paddingTop: isInherited ? 6 : 0}}
                checked={isInherited}
                onCheck={(e, isChecked) => this.onInheritWeekStartsOnChange(isChecked)} />
          </Layout>}
          {isInherited &&
          <Layout allSmall={12} medium={[3, 9]} floatLastColumnLeft={true}>
            <div>&nbsp;</div>
            <InheritedFromGroupInfo  originalGroupId={group.get("inheritedWeekdayGroupId")} theme={theme} />
          </Layout>}
        </div>
    );
  },

  renderDealMusicSection() {
    const {group, highlightSection, theme} = this.props;
    const isRootGroup = !group.get("parentId");
    const hasNoDealMusic = group.get("dealMusicId") === silentMp3Id;
    const isInherited = group.get("inheritedMusicGroupId") !== group.get("id");
    const dealMusic = getGroupDealMusic(group);
    const radioButtonStyle = {width: 250, height:30, color: theme.palette.textColor};
    const highlightStyle = highlightSection === "music" ? { border: `2px solid ${theme.palette.primary.main}`} : {};

    return (
        <div style={{...sectionContainerStyle, ...highlightStyle}}>
          <Layout allSmall={12} medium={[3, 9]}>
            <label style={labelStyle}>Deal music</label>
            <div>
              <div>
                {hasNoDealMusic && <span style={textStyle}>No deal music</span>}
                {!hasNoDealMusic &&
                <span>
                  {!dealMusic &&
                  <span style={textStyle}>
                          <i className="fa fa-exclamation-triangle"
                             style={{color: "#f00", paddingRight: 8}} />
                          Deal music not found
                  </span>}
                  {dealMusic &&
                  <span>
                    <span style={textStyle}>
                        {dealMusic.get("description")}
                    </span>
                    <span style={{paddingLeft: "0.5rem", paddingRight: "1rem"}}>
                        <AudioPlayer source={dealMusic.get("url")} />
                    </span>
                  </span>}
                </span>}
                {(!this.state.isDealMusicPickerOpen && !isInherited && !hasNoDealMusic) &&
                <Link
                    theme={theme}
                    label="Change"
                    onClick={this.openDealMusicPicker}
                    customStyle={{paddingLeft: "0.5rem", paddingRight: "0.5rem"}} />}
              </div>
              {(!isRootGroup && isInherited) &&
              <InheritedFromGroupInfo  originalGroupId={getOriginalDealMusicGroupId(group)} theme={theme} />}
              <Layout allSmall={12} medium={[5, 7]} collapseRow={true} rowStyle={{paddingTop: 5}}>
                <RadioButtonGroup
                    name="deal-music-setting"
                    onChange={e => this.onDealMusicSettingChange(e.target.value)}
                    value={this.getGroupDealMusicSetting()}>
                  <RadioButton
                      value="silent"
                      label="No deal music"
                      style={radioButtonStyle} />
                  <RadioButton
                      value="inherit"
                      label="Inherit from Parent Group"
                      disabled={isRootGroup}
                      style={radioButtonStyle} />
                  <RadioButton
                      value="custom"
                      label="Custom deal music"
                      style={radioButtonStyle} />
                </RadioButtonGroup>
              </Layout>
            </div>
          </Layout>
          {this.state.isDealMusicPickerOpen &&
          <DealMusicPicker
              selectedDealMusicId={group.get("dealMusicId")}
              onChange={this.onDealMusicChange}
              onCloseRequest={this.closeDealMusicPicker} />}
        </div>
    );
  },

  renderOneviewPageSection() {
    const {group, pages, onChange, highlightSection, theme} = this.props;
    const highlightStyle = highlightSection === "page" ? { border: `2px solid ${theme.palette.primary.main}`} : {};

    return (
        <div style={{...sectionContainerStyle, ...highlightStyle}}>
          <Layout allSmall={12} medium={[3, 9]} floatLastColumnLeft={true}>
            <label style={labelStyle}>OneView Page</label>
            <PagePicker
                pages={pages}
                userOrGroup={group}
                onChange={onChange}
                columnStyle={{paddingLeft: 0}} />
          </Layout>
        </div>
    );
  },

  renderLocaleSection() {
    const {group, idToLocale, onChange, highlightSection, theme} = this.props;
    const highlightStyle = highlightSection === "locale" ? {border: `2px solid ${theme.palette.primary.main}`} : {};
    return (
        <div style={{...sectionContainerStyle, ...highlightStyle}}>
          <Layout allSmall={12} medium={[3, 9]} floatLastColumnLeft={true}>
            <label style={labelStyle}>Date Format</label>
            <InheritedLocalePicker
                idToLocale={idToLocale}
                group={group}
                onChange={onChange}
                columnStyle={{paddingLeft: 0}} />
          </Layout>
        </div>
    );
  },

  getGroupDealMusicSetting() {
    const {group} = this.props;
    const hasNoDealMusic = group.get("dealMusicId") === silentMp3Id;
    const isInherited = group.get("inheritedMusicGroupId") !== group.get("id");
    if (!isInherited && hasNoDealMusic) {
      return "silent";
    }
    if (isInherited) {
      return "inherit";
    }
    return "custom";
  },

  onDealMusicSettingChange(setting) {
    switch (setting) {
      case "silent":
        this.onDealMusicChange(silentMp3Id);
        this.closeDealMusicPicker();
        break;
      case "inherit":
        this.onDealMusicChange(null);
        this.closeDealMusicPicker();
        break;
      case "custom":
        this.onDealMusicChange(getDefaultDealMusicId());
        this.openDealMusicPicker();
        break;
      default:
        throw new Error("Unsupported deal music setting:", setting);
    }
  },

  onCloseDeleteDialogClick() {
    this.setState({isDeleteDialogOpen: false});
  },

  onDeleteGroupClick() {
    this.setState({isDeleteDialogOpen: true});
  },

  onDeleteGroup(deletingGroupId, newGroupId, groupEndDate) {
    this.onCloseDeleteDialogClick();
    this.props.onDeleteGroup(deletingGroupId, newGroupId, groupEndDate);
  },

  onRestoreGroupClick() {
    const {group, onChange} = this.props;
    const name = group.get("name").replace(/^\[deleted]/, "").trim();
    onChange({
      deleted: false,
      name
    });
  },

  openDealMusicPicker() {
    this.setState({
      isDealMusicPickerOpen: true
    });
  },

  closeDealMusicPicker() {
    this.setState({
      isDealMusicPickerOpen: false
    });
  },

  onNameChange(name) {
    const {group, onChange} = this.props;
    this.setState({
      editableGroupName: name
    });
    if (name.length > 0 && name.replace("[deleted] ", "").length <= 64) {
      onChange({name});
    }
  },

  onInheritCurrencyChange(isInherited) {
    const currencyCode = isInherited ? null : this.props.group.get("currencyCode");
    this.onCurrencyCodeChange(currencyCode);
  },

  onCurrencyCodeChange(currencyCodeStr) {
    const {group} = this.props;
    const isRootGroup = !group.get("parentId");
    const currencyCode = currencyCodeStr ? currencyCodeStr : (isRootGroup ? getDefaultCurrencyCode() : null);
    this.props.onChange({currencyCode});
  },

  onInheritWeekStartsOnChange(isInherited) {
    const dayOfWeek = isInherited ? null : this.props.group.get("weekStartsOn");
    this.onWeekStartsOnChange(dayOfWeek);
  },

  onWeekStartsOnChange(weekStartsOn) {
    this.props.onChange({weekStartsOn});
  },

  onDealMusicChange(dealMusicId) {
    this.props.onChange({dealMusicId});
  },

  onPageChange(pageId) {
    this.props.onChange({pageId});
  },

  onInheritPageChange(isInherited) {
    const pageId = isInherited ? null : this.props.group.get("pageId");
    this.onPageChange(pageId);
  }

});

const getGroupDealMusic = group => {
  if (!group.get("parentId")) {
    return group.get("dealMusicId") ? dealMusicRepo.get(group.get("dealMusicId")) : getDefaultDealMusic();
  }
  if (!group.get("dealMusicId") && !group.get("inheritedMusicGroupId")) {
    return getDefaultDealMusic();
  }
  if (group.get("inheritedMusicGroupId") !== group.get("id")) {
    return getInheritedDealMusic(group.get("inheritedMusicGroupId"));
  }
  return group.get("dealMusicId") ? dealMusicRepo.get(group.get("dealMusicId")) : getDefaultDealMusic();
};

const getInheritedDealMusic = inheritedMusicGroupId => {
  const group = Groups.getGroup(inheritedMusicGroupId);
  return dealMusicRepo.get(group.get("dealMusicId")) || getDefaultDealMusic();
};

const getDefaultDealMusic = () => dealMusicRepo.getAll().first();
const getDefaultDealMusicId = () => {
  const dealMusic = getDefaultDealMusic();
  return dealMusic ? dealMusic.get("dealMusicId") : null;
};

const getOriginalDealMusicGroupId = group => {
  if (group.get("inheritedMusicGroupId")) {
    return group.get("inheritedMusicGroupId");
  }
  if (!group.get("parentId")) {
    return group.get("id");
  }

  const parentGroup = Groups.getGroup(group.get("parentId"));
  return parentGroup.get("inheritedMusicGroupId") ?
      parentGroup.get("inheritedMusicGroupId") : getOriginalDealMusicGroupId(parentGroup);
};

const getDefaultCurrencyCode = () => currencyRepo.getAll().first().get("code");

const spacingStyle = {
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem"
};

const labelStyle = {
  color: "#999",
  fontSize: "0.9rem",
  lineHeight: "34px",
  verticalAlign: "middle",
  marginBottom: 0
};

const textStyle = {
  fontSize: "0.9rem",
  height: 34,
  lineHeight: "34px"
};

const sectionContainerStyle = {
  border: "1px solid #ccc",
  borderRadius: 5,
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  marginBottom: "0.5rem"
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <EditGroupPanel theme={theme} {...props} />;
};
