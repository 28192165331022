import React from "react";
import {betterMemo} from "js/common/utils/more-memo";

import CubeTextField from "js/common/views/inputs/text-field";

export default betterMemo(
  {displayName: "DelayedTextField"},
  ({delayInMillis = 100, value, onChange, ...props}) => {
    const [str, setStr] = React.useState(value);
    React.useEffect(() => setStr(value), [value]);

    const timeoutIdRef = React.useRef(null);

    const flushChanges = () => {
      clearTimeout(timeoutIdRef.current);
      onChange(str);
    };

    const handleChange = e => {
      const newStr = e.target.value;
      setStr(newStr);

      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        onChange(newStr);
      }, delayInMillis);
    };

    return <CubeTextField
      value={str}
      onChange={handleChange}
      onBlur={flushChanges}
      {...props} />;
  });
