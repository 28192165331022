import React from "react";
import pure from "js/common/views/pure";
import {IconButton} from "js/common/views/inputs/buttons";

export default pure(({itemsPerPage, currentPage, totalItems, onChange}) => {
    const offset = itemsPerPage * currentPage;
    const baseWidth = 3;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const digitWidth = digits(totalPages * itemsPerPage) * 1.3;
    return (
        <div style={{display: "inline-block", textAlign: "center"}}>
            <IconButton
                icon="chevron-left"
                labelStyle={{backgroundColor: "transparent !important"}}
                onClick={() => {
                    const newPage = Math.max(0, currentPage - 1);
                    if (newPage !== currentPage) {
                        onChange(newPage);
                    }
                }}
                size="large" />
            <div style={{display: "inline-block", minWidth: (baseWidth + digitWidth) + "rem"}}>
                {Math.min(offset + 1, totalItems)} - {Math.min(offset + itemsPerPage, totalItems)} /  {totalItems}
            </div>
            <IconButton
                icon="chevron-right"
                labelStyle={{backgroundColor: "transparent !important"}}
                onClick={() => {
                    const newPage = Math.min(totalPages - 1, currentPage + 1);
                    if (newPage !== currentPage) {
                        onChange(newPage);
                    }
                }}
                size="large" />
        </div>
    );
});

const digits = x => Math.log(x) * Math.LOG10E + 1 | 0;
