import * as Formatter from "js/common/utils/formatter"
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import Immutable from "immutable";

const getKpiIdFromArg = (arg, masterKpiTypeToKpiId) => {
  const num = parseInt(arg);

  let kpiId;
  if (isNaN(num)) {
    kpiId = masterKpiTypeToKpiId.get(arg);
  } else {
    kpiId = num;
  }

  if (!kpiId) {
    throw new Error(`cannot find metric from arg "${arg}"`);
  } else if (!KpiRepo.get(kpiId)) {
    throw new Error(`invalid metric ID "${kpiId}"`);
  } else {
    return kpiId
  }
};

const getKpiValue = (kpiId, kpiIdToValue) => {
  const value = kpiIdToValue.get(kpiId);
  if(!value) {
    throw new Error(`cannot find value for metric id "${kpiId}"`);
  } else if (value.get("isError")){
    throw new Error ("failure running metric")
  }
  else {
    return value;
  }
};

const argPattern = /{(\d+)}/g;

const applyArgsToFormula = (formulaStr, args) => {
  return formulaStr.replace(argPattern, (match, [index]) => args.get(index));
};

const formatResult = (result, formulaId) => {
  const value = result.get("value");
  switch (result.get("formatAs")) {
    case "CURRENCY":
      return {result: Formatter.format(result.toJS()), errors: Immutable.Set()};
    case "PERCENT":
      return {result: Formatter.format(result.toJS(), {valueFormat: "PERCENT"}), errors: Immutable.Set()};
    case "NUMBER":
      if(!result.get("decimalPlaces")){
        result = result.set("decimalPlaces", 2);
      }
      return {result: Formatter.format(result.toJS()), errors: Immutable.Set()};
    case "STRING":
      return {result: value, errors: Immutable.Set()};
    case "REACT_ELEMENTS":
      return {result: value, errors: Immutable.Set()};
    case "":
      return {
        result: 0,
            errors: Immutable
          .fromJS([{
            location: {formulaId: formulaId},
            category: "custom-formula",
            type: "invalid-format-type",
            message: `Formula type not provided`
          }])
          .toSet()
      };
    default:
      return {
        result: 0,
        errors: Immutable
            .fromJS([{
              location: {formulaId: formulaId},
              category: "custom-formula",
              type: "invalid-format-type",
              message: "Invalid formula type supplied"
            }])
            .toSet()
      };
  }
};

export {
  getKpiIdFromArg,
  getKpiValue,
  formatResult,
  applyArgsToFormula
};
