import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import * as Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";

import StandardChartingConfig from "js/charting/trend/standard-charting";
import AdvancedChartingConfig from "js/charting/trend/advanced-charting/advanced-config-container";
import * as auditor from "js/common/auditer";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import {getDefaultColour} from "js/charting/trend/target-trend-color-util";

export default createReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        initialConfig: ReactPropTypes.object.isRequired,
        isSmallScreen: ReactPropTypes.bool.isRequired,
        onPlotChartClick: ReactPropTypes.func.isRequired
    },

    getInitialState() {
        return {
            config: Immutable.fromJS(this.props.initialConfig),
            noKpisSelectedWarningIsOpen: false
        };
    },

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.initialConfig !== nextProps.initialConfig) {
            this.setState({
                config: Immutable.fromJS(nextProps.initialConfig)
            });
        }
    },

    render() {
        const isAdvancedChartConfig = this.state.config.get("trendType") === "ADVANCED";
        return (
            <div style={{marginBottom: "1rem"}}>
                {isAdvancedChartConfig ? this.renderAdvancedChartConfig() : this.renderStandardChartConfig()}
            </div>);
    },

    renderStandardChartConfig() {
        return (
            <StandardChartingConfig
                initialConfig={this.state.config}
                onPlotChartClick={this.props.onPlotChartClick}
                onAdvancedChartClick={this.switchToAdvancedConfig} />);
    },

    renderAdvancedChartConfig() {
        return (
            <AdvancedChartingConfig
                isSmallScreen={this.props.isSmallScreen}
                initialConfig={this.state.config}
                onPlotChartClick={this.props.onPlotChartClick}
                onStandardChartClick={this.switchToStandardConfig} />);
    },

    switchToAdvancedConfig(config) {
        auditor.audit("charting:advanced_clicked");
        let newConfig = config.set("trendType", "ADVANCED");
        const dataGroups = newConfig.get("dataGroups");
        if (dataGroups.isEmpty()) {
            const newDataGroup = getInitialDataGroup(config);
            newConfig = newConfig.set("dataGroups", dataGroups.push(newDataGroup));
        }
        this.setState({
            config: newConfig
        });
    },

    switchToStandardConfig(config) {
        auditor.audit("charting:standard-edit-clicked");
        this.setState({
            config: config.set("trendType", "STANDARD")
        });
    }

});

const getInitialDataGroup = config => {
    const dataGroupIndex = 0;
    const dataGroupName = "Data Group 1";
    return Immutable.Map({
        cid: Math.random(),
        dataGroupIndex,
        name: dataGroupName,
        timeframe: config.get("timeframe"),
        trendConfigs: getInitialTrendConfigs(
            dataGroupName,
            config.get("kpiIds"),
            config.get("displayDataCumulatively"),
            dataGroupIndex,
            config.get("chartTheme")
        ),
        qualifierType: config.get("qualifierType"),
        qualifierId: config.get("qualifierId"),
        matchAnyTagIds: config.get("matchAnyTagIds"),
        matchAllTagIds: config.get("matchAllTagIds"),
        excludedTagIds: config.get("excludedTagIds"),
        clientIds: config.get("clientIds"),
        dateDisplay: "ABSOLUTE"
    });
};

const getInitialTrendConfigs = (dataGroupName, kpiIds, isCumulative, dataGroupIndex, chartTheme) => {
    return Immutable.List(kpiIds.map((kpiId, kpiIndex) => {
        return Immutable.Map({
            cid: Math.random(),
            type: "KPI",
            kpiId: kpiId,
            ratio: null,
            rootTrendName: `${dataGroupName} - ${kpiRepo.get(kpiId).get("name")}`,
            whatToPlot: "value",
            howToPlot: isCumulative ? "cumulative" : "non-cumulative",
            seriesColour: getDefaultColour(kpiIndex, dataGroupIndex, chartTheme),
            hasCustomColor: false
        });
    }));
};
