/** @jsxImportSource @emotion/react */
import React from "react";
import * as Kpis from "js/common/kpis";
import {indexBy} from "js/common/utils/collections";
import {EditExplanation} from "js/admin/kpis/edit-kpis/tabs/explanation";
import Immutable from "immutable";
import * as Colors from "js/common/cube19-colors";
import LoadingSpinner from "js/oneview/loading-spinner";
import Checkbox from "js/common/views/inputs/checkbox";
import {TextButton} from "js/common/views/inputs/buttons";
import Error from "js/common/views/error";
import currentClient from "js/common/repo/backbone/current-client";
import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import * as popups from "js/common/popups";
import {useMountUnmountEffect} from "js/admin/kpis/edit-kpis/utils";
import store from "store";
import * as Time from "js/common/utils/time";
import moment from "moment";
import * as Users from "js/common/users";
import UnsavedChangesDialog from "js/common/views/unsaved-changes-dialog";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";

export const ExplanationOverview = () => {

  const storeKeyRoot = React.useMemo(() => {
    const currentClientId = currentClient.get("id");
    const currentUserId = Users.getCurrentUser().get("id");
    return "cube19.admin.metric.explanations." + currentClientId + "." + currentUserId;
  }, []);

  const { theme } = React.useContext(CustomThemeContext);
  const [loading, setLoading] = React.useState(false);
  const [idToKpi, setIdToKpi] = React.useState(Immutable.Map());
  const [showOnlyExplanationMetrics, setShowOnlyExplanationMetrics] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");
  const [storedChangeDateTime, setStoredChangeDateTime] = React.useState(() => retrieveStoredChangeDateTime(storeKeyRoot));

  const areExplanationsEnabled = currentClient.areExplanationsEnabled();
  const hasChanges = idToKpi.some(kpi => kpi.get("hasChanged"));
  const hasExplanations = idToKpi.some(kpi => kpi.get("explanation"));
  const unsavedChangesMessage = "You have unsaved changes. Don't worry, we'll hold on to them until you come back.";

  const loadAndSetData = React.useCallback(() => {
    setLoading(true);
    return Kpis.loadEditableKpis()
        .then(kpis => {
          const idToKpi = indexBy(kpi => kpi.get("id"), kpis);
          setIdToKpi(idToKpi);
          setLoading(false);
        });
  }, []);

  React.useEffect(() => loadAndSetData(), [loadAndSetData]);

  useMountUnmountEffect(ref => {
    window.onbeforeunload = () => {
      const [hasChanges, idToKpi] = ref.current;
      if (hasChanges) {
        saveChangesToLocalStorage(storeKeyRoot, idToKpi);
        return unsavedChangesMessage;
      }
    };
    return () => {
      window.onbeforeunload = null;
      const [hasChanges, idToKpi] = ref.current;
      if (hasChanges) {
        alert(unsavedChangesMessage);
        saveChangesToLocalStorage(storeKeyRoot, idToKpi);
      }
    };
  }, [hasChanges, idToKpi]);

  const handleDiscardStoredChanges = React.useCallback(() => {
    clearStoredChanges(storeKeyRoot);
    setStoredChangeDateTime(null);
  }, [storeKeyRoot]);

  const handleRetrieveStoredChanges = React.useCallback(() => {
    const storedIdToKpi = retrieveStoredChanges(storeKeyRoot)
        .sortBy(kpi => kpi.get("id"));
    setIdToKpi(storedIdToKpi);

    handleDiscardStoredChanges();
  }, [storeKeyRoot, handleDiscardStoredChanges]);

  const handleUpdateExplanation = React.useCallback((explanation, id) => {
    setIdToKpi(
        idToKpi => {
          if (idToKpi.getIn([id, "explanation"]) !== explanation) {
            return idToKpi
                .setIn([id, "explanation"], explanation || null)
                .setIn([id, "hasChanged"], true);
          } else {
            return idToKpi;
          }
        });
  }, []);

  const handleSaveClick = React.useCallback(() => {

    const changedKpis = idToKpi
        .toList()
        .filter(kpi => kpi.get("hasChanged"))
        .map(kpi => Immutable.Map({config: kpi
            .delete("hasChanged")
            .delete("hidden")}));

    Kpis.submitKpis(changedKpis, "Updated metric explanation via admin UI")
        .then(response => {
          const savedKpis = response.get("kpisAndErrors").map(kpiAndError => kpiAndError.get("config"));
          const idToSavedKpis = indexBy(k => k.get("id"), savedKpis);
          setIdToKpi(idToKpi => idToKpi
              .filter(kpi => !kpi.get("hasChanged"))
              .merge(idToSavedKpis)
              .sortBy(kpi => kpi.get("id"))
          );
          popups.success("Your changes have been saved");
        });
  }, [idToKpi]);

  const filteredKpis = React.useMemo(
      () => {
        const filterWords = Immutable.List(filterText.toLowerCase().split(" "));
        return idToKpi.map(kpi => {

          let matchesFilter;
          if (filterText) {
            const matchTerms = (kpi.get("id").toString() + " " + kpi.get("name")).toLowerCase();
            matchesFilter = filterWords.every(word => matchTerms.indexOf(word) !== -1);
          } else {
            matchesFilter = true;
          }

          const matchesShowOnlyWithExplanation = !showOnlyExplanationMetrics || kpi.get("explanation");
          const hasChanged = kpi.get("hasChanged");

          if (matchesFilter && (matchesShowOnlyWithExplanation || hasChanged)) {
            return kpi;
          } else {
            return kpi.set("hidden", true);
          }
        })
            .valueSeq().toList();
      },
      [filterText, idToKpi, showOnlyExplanationMetrics]);


  return <div>
    {!areExplanationsEnabled && <Error
        text="Metric Explanations are currently disabled and only visible to Admin Users.  Enable them in Company Settings to make them visible to all Users."
        type="warn"
        style={{margin: 10}} />}
    <Hint style={{margin: "1rem"}}>
      <Icon icon="info" style={{color: theme.palette.hints.text}} />
      {explanationDescription}
    </Hint>
    <Controls
        onSaveClick={handleSaveClick}
        onCancelClick={loadAndSetData}
        showOnlyExplanationMetrics={showOnlyExplanationMetrics}
        setShowOnlyExplanationMetrics={setShowOnlyExplanationMetrics}
        hasExplanations={hasExplanations}
        hasChanges={hasChanges}
        filterText={filterText}
        setFilterText={setFilterText} />
    <div style={headerRowStyle(theme)} data-test-id={"metric-explanation-table"}>
      {rowData.map(row => <div
          key={row.get("label")}
          style={{
            ...row.get("style"),
            paddingLeft: row.get("value") === "explanation" ? 10 : 0
          }}>{row.get("label")}</div>)}
    </div>
    {loading ? <LoadingSpinner /> : <ExplanationList
        theme={theme}
        onUpdateExplanation={handleUpdateExplanation}
        kpis={filteredKpis} />}
    <UnsavedChangesDialog
        onRetrieveClick={handleRetrieveStoredChanges}
        onDiscardClick={handleDiscardStoredChanges}
        lastUpdated={storedChangeDateTime && storedChangeDateTime.format("LLL")}
        open={!loading && !!storedChangeDateTime} />
  </div>;
};

const Controls = ({
  showOnlyExplanationMetrics,
  setShowOnlyExplanationMetrics,
  onSaveClick,
  onCancelClick,
  hasExplanations,
  hasChanges,
  filterText,
  setFilterText
}) => <div
    style={{
      display: "flex",
      justifyContent: "space-between"
    }}>
  <Checkbox
      style={{margin: 10}}
      label="Show Only Metrics With Explanation"
      disabled={!hasExplanations}
      checked={showOnlyExplanationMetrics}
      onCheck={() => setShowOnlyExplanationMetrics(!showOnlyExplanationMetrics)} />
  <DelayedTextField
      variant="standard"
      style={{width: 300, marginRight: "0.5rem"}}
      label="Search Metrics..."
      value={filterText}
      onChange={setFilterText}
      data-test-id={"search-metrics-input"} />
  <div style={{marginRight: 10, marginLeft: 10}}>
    <TextButton
        icon="history"
        label="Cancel"
        onClick={onCancelClick}
        disabled={!hasChanges}
        style={{margin: 5, width: 120}} />
    <TextButton
        icon="floppy-o"
        label={"Save"}
        onClick={onSaveClick}
        disabled={!hasChanges}
        style={{margin: 5, width: 120}} />
  </div>
</div>;

const ExplanationList = React.memo(
    ({theme, kpis, onUpdateExplanation}) => kpis
        .map(kpi => {
          return <ExplanationRow
              theme={theme}
              key={kpi.get("id")}
              onUpdateExplanation={onUpdateExplanation}
              kpi={kpi}
          />;
        }));

const ExplanationRow = React.memo(
    ({kpi, onUpdateExplanation}) => <div
        style={{
          display: kpi.get("hidden") ? "none" : "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: 10
        }}>
      {rowData.map(row => {
            let content;
            if (row.get("value") === "explanation") {
              content = <EditExplanation
                  explanation={kpi.get("explanation")}
                  onChange={exp => onUpdateExplanation(exp, kpi.get("id"))} />;
            } else if (row.get("value") === "status") {
              content = kpi.get("hasChanged")
                  ? <span style={{fontSize: "0.8rem", color: Colors.orangeIcon, display: "flex", alignItems: "center"}}>
          <i className="fa fa-exclamation" style={{marginLeft: "0.3rem", marginRight: 8}} />
          <span css={statusCss}>{" Unsaved Changes"}</span>
        </span>
                  : "";
            } else {
              content = kpi.get(row.get("value"));
            }
            return <div key={kpi.get("id") + "-" + row.get("value")} style={row.get("style")}>{content}</div>;
          }
      )}
    </div>);

const saveChangesToLocalStorage = (storeKeyRoot, idToKpi) => {
  store.set(storeKeyRoot + ".unsaved-changes-datetime", Time.formatDateTime(moment()));
  store.set(storeKeyRoot + ".id-to-kpi", idToKpi.toJS());
};

const retrieveStoredChanges = (storeKeyRoot) => Immutable.fromJS(store.get(storeKeyRoot + ".id-to-kpi"));

const clearStoredChanges = (storeKeyRoot) => {
  store.remove(storeKeyRoot + ".unsaved-changes-datetime");
  store.remove(storeKeyRoot + ".id-to-kpi");
};

const retrieveStoredChangeDateTime = (storeKeyRoot) => {
  const dateStr = store.get(storeKeyRoot + ".unsaved-changes-datetime");
  return dateStr ? Time.parseDateTime(dateStr) : null;
};

const rowData = Immutable.List([
  Immutable.Map({
    label: "ID",
    value: "id",
    style: {width: "5%"}
  }),
  Immutable.Map({
    label: "Name",
    value: "name",
    style: {width: "15%"}
  }),
  Immutable.Map({
    label: "Explanation",
    value: "explanation",
    style: {width: "70%"}
  }),
  Immutable.Map({
    label: "Status",
    value: "status",
    style: {width: "10%"}
  })
]);

const explanationDescription = "Metric explanations provide details of what a Metric does and are visible in click-throughs and Metric Admin.  Default explanations are provided for Metrics assigned to Master Metrics - changing the explanation for these will update for the Metric only and will not affect the default Master Metric explanation.";

const headerRowStyle = theme => ({
  color: "#999",
  fontSize: ".7em",
  fontWeight: "bold",
  paddingBottom: "0.25rem",
  borderBottom: `2px solid ${theme.palette.primary.main}`,
  display: "flex",
  justifyContent: "space-between",
  margin: 10
});

const statusCss = {display: "none", "@media(min-width: 1000px)": {display: "inline"}};
