import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import Section from "js/admin/oneview-pages/section";

export default createReactClass({

  propTypes: {
    loadedKpis: ImmutablePropTypes.list.isRequired,
    sections: ImmutablePropTypes.list.isRequired,
    onDeleteSectionClick: ReactPropTypes.func.isRequired,
    onMoveSectionDownClick: ReactPropTypes.func.isRequired,
    onMoveSectionUpClick: ReactPropTypes.func.isRequired,
    onSectionChange: ReactPropTypes.func.isRequired,
    onSectionExpandToggle: ReactPropTypes.func.isRequired,
    expandedSections: ImmutablePropTypes.set.isRequired,
    isSmallScreen: ReactPropTypes.bool
  },

  render() {
    return <ul>{this.getSectionElements()}</ul>;
  },

  getSectionElements() {
    return this.props.sections
        .sortBy(section => section.get("order"))
        .map(section => {
          const id = section.get("id") || section.get("cid");
          return <Section
              key={id}
              loadedKpis={this.props.loadedKpis}
              section={section}
              onMoveSectionDownClick={() => this.props.onMoveSectionDownClick(id)}
              onMoveSectionUpClick={() => this.props.onMoveSectionUpClick(id)}
              onChange={section => this.props.onSectionChange(id, section)}
              onDeleteSectionClick={() => this.props.onDeleteSectionClick(id)}
              onSectionExpandToggle={this.props.onSectionExpandToggle}
              isSmallScreen={this.props.isSmallScreen}
              isExpanded={this.props.expandedSections.has(id)} />;
        }
    );
  }

});
