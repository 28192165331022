import React from "react";
import moment from "moment";

import InvalidStatePage from "js/invalid-state/invalid-state-page";
import * as Branding from "js/common/branding-constants";
import * as ajax from "js/common/ajax";

const MaintenancePageContent = React.memo(() => {
  const [millisTilRefresh, setMillisTilRefresh] = React.useState(() => {
    const twoMinutes = 2 * 60 * 1000;
    const twentyEightMinutes = 28 * 60 * 1000;
    return (Math.random() * twentyEightMinutes) + twoMinutes;
  });

  if (millisTilRefresh <= 0) {
    ajax.isCubeAvailable().then(
        () => {
          window.location.reload(true);
        },
        () => {
          const twoMinutes = 2 * 60 * 1000;
          const twentyEightMinutes = 28 * 60 * 1000;
          setMillisTilRefresh((Math.random() * twentyEightMinutes) + twoMinutes);
        });
  }

  React.useEffect(() => {
    const intervalId = window.setInterval(() => {
      setMillisTilRefresh(x => x <= 1000 ? 0 : x - 1000);
    }, 1000);
    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  return (
      <>
        <p>Refresh the page in a few minutes to see what’s new.</p>
        <p>{Branding.submitTicketInstructions}</p>
        <p>This page will auto refresh in {moment.utc(millisTilRefresh).format("mm:ss")}</p>
      </>
  );
});

const MaintenancePage = () => <InvalidStatePage
    heading={"We’ll be right back..."}
    subheading={"Great news, we're adding new features!"}
    content={<MaintenancePageContent />} />;

export default MaintenancePage;