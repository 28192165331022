const devApiUrl = process.env.REACT_APP_API_URL ?? window.location.origin;
const prodApiUrl = "C19_REPLACE_API_URL";
const apiUrl = prodApiUrl.indexOf("http") === -1 ? devApiUrl : prodApiUrl;

const rssToApiUrl = process.env.REACT_APP_DEV_RSS_TO_API_HOST ?? "C19_REPLACE_RSS_TO_API_URL";

// Bullhorn SSO
const bullhornSsoClientId = process.env.REACT_APP_BULLHORN_SSO_CLIENT_ID ?? "C19_REPLACE_BULLHORN_SSO_CLIENT_ID";
const bullhornSsoAuthorizationEndpoint = process.env.REACT_APP_BULLHORN_SSO_AUTHORIZATION_ENDPOINT
    ?? "C19_REPLACE_BULLHORN_SSO_AUTHORIZATION_ENDPOINT";
const devBullhornSsoRedirectUri = process.env.REACT_APP_BULLHORN_SSO_REDIRECT_URI ?? process.env.PUBLIC_URL;
const prodBullhornSsoRedirectUri = "C19_REPLACE_BULLHORN_SSO_REDIRECT_URI";
const bullhornSsoRedirectUri = prodBullhornSsoRedirectUri.indexOf("http") === -1
    ? devBullhornSsoRedirectUri
    : prodBullhornSsoRedirectUri;

export {apiUrl, rssToApiUrl, bullhornSsoClientId, bullhornSsoAuthorizationEndpoint, bullhornSsoRedirectUri};
