import React from "react";
import pure from "js/common/views/pure";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const defaultBackgroundColor = theme => (theme.themeId === "light" ? theme.palette.background.default : "#555");
const borderWidth = 2;

const containerStyle = theme =>({
    border: borderWidth + "px solid " + defaultBackgroundColor(theme),
    paddingBottom: "1rem",
    marginTop: 0 - borderWidth
});

const defaultLabelStyle = theme =>({
    padding: "0.6rem",
    color: theme.palette.primary.main,
    backgroundColor: defaultBackgroundColor(theme),
    marginBottom: 0 - borderWidth
});
const ConfigContainer = pure(({
    label,
    children,
    style = {},
    labelStyle = {},
    theme
}) => {
    return (
        <div style={{ ...containerStyle(theme), ...style }}>
            <div style={{ ...defaultLabelStyle(theme), ...labelStyle }}>{label}</div>
            {children}
        </div>
    );
});
//

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <ConfigContainer theme={theme} {...props} />;
}

