import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import pure from "js/common/views/pure";

import getColWidthClasses from "js/oneview/quick-view/column-widths";
import * as kpiValuesDisplay from "js/oneview/kpi-values-display";
import * as Formatter from "js/common/utils/formatter";
import * as kpiCalculator from "js/common/kpi-calculator";
import * as Groups from "js/common/groups";
import * as numbers from "js/common/utils/numbers"
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const BreakdownTreeInner = createReactClass({

  propTypes: {
    kpiId: ReactPropTypes.number.isRequired,
    timeframe: ReactPropTypes.object.isRequired,
    clientIds: ReactPropTypes.array,
    groups: ReactPropTypes.array,
    onGroupClick: ReactPropTypes.func.isRequired,
    groupsByParentId: ReactPropTypes.object.isRequired,
    users: ReactPropTypes.array,
    onUserClick: ReactPropTypes.func.isRequired,
    usersByGroupId: ReactPropTypes.object.isRequired,
    expandToLevel: ReactPropTypes.number,
    width: ReactPropTypes.number,
    isSmallScreen: ReactPropTypes.bool,
    theme: ReactPropTypes.object
  },

  getDefaultProps() {
    return {
      groups: [],
      users: [],
      expandToLevel: 0
    };
  },

  getInitialState() {
    return {
      expandedGroupIds: {},
      kpiDataByUserId: {},
      kpiDataByGroupId: {}
    };
  },

  componentDidMount() {
    if (this.props.expandToLevel > 0) {
      const groupIds = this.props.groups.map(group => group.id);
      this.expandGroups(groupIds);
    }

    const {kpiId, timeframe, clientIds, groups, users, matchAnyTagIds, matchAllTagIds, excludedTagIds} = this.props;
    const promisesWithGroupIds = loadKpiForGroups(kpiId, timeframe, clientIds, matchAnyTagIds, matchAllTagIds, excludedTagIds, groups);
    this.updateDataAsEachGroupSummaryLoads(promisesWithGroupIds);

    const promisesWithUserIds = loadKpiForUsers(kpiId, timeframe, clientIds, matchAnyTagIds, matchAllTagIds, excludedTagIds, users);
    this.updateDataAsEachUserSummaryLoads(promisesWithUserIds);
  },

  render() {
    const {theme} = this.props;
    const listStyle = {
      listStyleType: "none",
      lineHeight: 1,
      marginBottom: 0,
      marginLeft: this.props.isSmallScreen ? 0 : null
    };
    return (
      <ul className={this.props.isSmallScreen ? "" : `table-${theme.themeId} striped-list`}
          style={listStyle}>
          {this.renderGroupBreakdownElements()}
          {this.renderUserBreakdownElements()}
        </ul>
    );
  },

  renderGroupBreakdownElements() {
    const {
      groups,
      groupsByParentId,
      usersByGroupId,
      onGroupClick,
      onUserClick,
      kpiId,
      timeframe,
      clientIds,
      matchAnyTagIds,
      matchAllTagIds,
      excludedTagIds,
      expandToLevel,
      width,
      isSmallScreen,
      theme
    } = this.props;
    const {expandedGroupIds, kpiDataByGroupId} = this.state;
    const listItemStyle = getListItemStyle(isSmallScreen, theme);
    return groups
        .map(group => {
          const groupId = group.id;
          const allChildGroups = groupsByParentId[groupId] || [];
          const childGroupsToDisplay = allChildGroups.filter(group => !group.deleted);
          const usersInGroup = usersByGroupId[groupId] || [];
          const isExpanded = expandedGroupIds[groupId];
          const isExpandable = childGroupsToDisplay.length > 0 || usersInGroup.length > 0;

          return (
              <li key={groupId} style={listItemStyle}>
                {isSmallScreen ?
                    <CompactBreakdownTreeItem
                        theme={theme}
                        label={getBreadcrumbsLabel(group.breadcrumbs)}
                        kpiData={kpiDataByGroupId[groupId]}
                        clientIds={clientIds}
                        isExpandable={isExpandable}
                        isExpanded={isExpanded}
                        onClick={() => onGroupClick(groupId)}
                        onExpandClick={() => this.handleExpandClick(groupId)} />
                    :
                    <BreakdownTreeItem
                        theme={theme}
                        name={group.name}
                        kpiData={kpiDataByGroupId[groupId]}
                        clientIds={clientIds}
                        isExpandable={isExpandable}
                        isExpanded={isExpanded}
                        onClick={() => onGroupClick(groupId)}
                        onExpandClick={() => this.handleExpandClick(groupId)} />}
                {isExpanded &&
                <BreakdownTreeInner
                    theme={theme}
                    groupsByParentId={groupsByParentId}
                    usersByGroupId={usersByGroupId}
                    kpiId={kpiId}
                    timeframe={timeframe}
                    clientIds={clientIds}
                    matchAnyTagIds={matchAnyTagIds}
                    matchAllTagIds={matchAllTagIds}
                    excludedTagIds={excludedTagIds}
                    groups={childGroupsToDisplay}
                    onGroupClick={onGroupClick}
                    users={usersInGroup}
                    onUserClick={onUserClick}
                    expandToLevel={expandToLevel - 1}
                    width={width}
                    isSmallScreen={isSmallScreen} />}
              </li>);
        });
  },

  handleExpandClick(groupId) {
    this.expandGroups([groupId], () => {
      // HACK to reposition dialog when content size changes
      // trigger a window resize event: https://github.com/callemall/material-ui/issues/1676
      window.dispatchEvent(new Event("resize"));
    });
  },

  expandGroups(groupIds, callback = () => {}) {
    let expandedGroupIds = this.state.expandedGroupIds;
    groupIds.forEach(groupId => {
      expandedGroupIds[groupId] = !expandedGroupIds[groupId];
    });
    this.setState({
      expandedGroupIds
    }, callback);
  },

  renderUserBreakdownElements() {
    const theme = this.props.theme;
    const listItemStyle = getListItemStyle(this.props.isSmallScreen, theme);
    return this.props.users
        .map(user => {
          const isUserInRootGroup = user.groupId === Groups.getRootGroup().get("id");

          // const isEvenRow = rowIndex % 2 === 0;
          // const stripedRowStyle = { backgroundColor: isEvenRow ? theme.palette.background.card : theme.palette.background.paper };

          return (
              <li key={user.id} style={listItemStyle}>
                {this.props.isSmallScreen ?
                    <CompactBreakdownTreeItem
                        theme={theme}
                        label={
                          <span style={isUserInRootGroup ? {marginLeft: -26} : {}}>
                                        <i className="fa fa-level-up fa-rotate-90" style={{fontSize: 20}} />
                                        <span style={{marginLeft: 10}}>{user.fullName}</span>
                                    </span>
                        }
                        kpiData={this.state.kpiDataByUserId[user.id]}
                        clientIds={this.props.clientIds}
                        onClick={() => this.props.onUserClick(user.id)} />
                    :
                    <BreakdownTreeItem
                        theme={theme}
                        name={user.fullName}
                        kpiData={this.state.kpiDataByUserId[user.id]}
                        clientIds={this.props.clientIds}
                        onClick={() => this.props.onUserClick(user.id)} />}
              </li>
          );
        });
  },

  updateDataAsEachGroupSummaryLoads(promisesWithGroupIds) {
    let kpiDataByGroupId = {};
    promisesWithGroupIds.forEach(({promise, groupId}) => {
      promise
          .then(result => {
            kpiDataByGroupId[groupId] = result;
            this.setState({
              kpiDataByGroupId
            });
          }, () => {
            kpiDataByGroupId[groupId] = {error: true};
            this.setState({
              kpiDataByGroupId
            });
          });
    });
  },

  updateDataAsEachUserSummaryLoads(promisesWithUserIds) {
    let kpiDataByUserId = {};
    promisesWithUserIds.forEach(({promise, userId}) => {
      promise
          .then(result => {
            kpiDataByUserId[userId] = result;
            this.setState({
              kpiDataByUserId
            });
          }, () => {
            kpiDataByUserId[userId] = {error: true};
            this.setState({
              kpiDataByUserId
            });
          });
    });
  }

});

const BreakdownTree = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <BreakdownTreeInner theme={theme} {...props} />;
};

export default BreakdownTree;

const BreakdownTreeItem = pure(({
  theme,
  isExpanded,
  onExpandClick,
  isExpandable,
  name,
  onClick,
  kpiData,
  clientIds
}) => {

  const rowStyle = {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: 25,
    textAlign: "center",
    ":hover": {
      backgroundColor: "#424242"
    },
  };

  const colWidthClassByName = getColWidthClasses();
  const icon = isExpanded ? "minus-circle" : "plus-circle";
  return (
      <div style={rowStyle}>
        <div className={colWidthClassByName.groupOrUser} style={{display: "flex", alignItems: "center"}}>
          {isExpandable &&
          <i key="icon" className={`fa fa-${icon}`} onClick={onExpandClick} style={iconStyle(theme)} />}
          <span key="link" style={linkStyle(theme)} onClick={onClick}>
                    {name}
                </span>
        </div>
        <KpiValueColumns theme={theme} data={kpiData} clientIds={clientIds} />
      </div>
  );
});

const CompactBreakdownTreeItem = pure(({
  theme,
  isExpanded,
  onExpandClick,
  isExpandable,
  label,
  onClick,
  kpiData,
  clientIds
}) => {
  const rowStyle = {
    width: "100%",
    minHeight: 25,
    padding: "5px 0px",
    borderBottom: "1px solid #000",
    backgroundColor: "inherit",

    ":hover": {
      backgroundColor: "#424242"
    }
  };

  const icon = isExpanded ? "minus-circle" : "plus-circle";
  return (
      <div className="small-12 columns" style={rowStyle}>
        <div style={{display: "flex", alignItems: "center", width: "100%"}}>
          {isExpandable &&
          <i key="icon"
             className={`fa fa-${icon}`}
             onClick={onExpandClick}
             style={{
               ...iconStyle(theme),
               fontSize: "1.25rem",
               marginRight: 0,
               marginLeft: "1rem"
             }} />}
          <span
              key="link"
              onClick={onClick}
              style={{...linkStyle(theme), fontWeight: "bold", marginLeft: isExpandable ? 5 : 45}}>
                    {label}
                </span>
        </div>
        <div className="row collapse" style={{textAlign: "center", paddingTop: 8}}>
          <KpiValueColumns theme={theme} data={kpiData} isSmallScreen={true} clientIds={clientIds} />
        </div>
      </div>
  );
});

const KpiValueColumns = pure(({theme, data, clientIds, isSmallScreen = false}) => {
  const dataHasLoaded = !!data;
  if (!dataHasLoaded) {
    return (
        <div className="small-9 columns" style={{color: "#999", textAlign: "left", padding: "0 1rem"}}>
          <i className="fa fa-spinner fa-pulse" />
        </div>
    );
  }

  if (data.error) {
    return <div className="small-9 columns">Error</div>;
  }

  const hasClientFilter = clientIds && clientIds.length > 0;
  const total = data.total;
  const expected = data.expectedValue;
  const expectedValue = numbers.roundTo(expected.value, 3);
  const target = data.target;
  const hasTarget = !hasClientFilter && target.value > 0;
  const colWidthClassByName = getColWidthClasses(isSmallScreen);
  let color = "";
  if (expectedValue > 0) {
    const percentageOfExpected = total.value / expectedValue;
    color = kpiValuesDisplay.getColorForPercentage(percentageOfExpected, theme);
  }

  return [
    <div key="target" className={colWidthClassByName.target}>
      {hasTarget ? Formatter.format(target) : <NoDataLabel />}
    </div>,
    <div key="percent-of-target" className={`${colWidthClassByName.percentOfTarget}`} style={{color:`${color}`}}>
      {hasTarget ? kpiValuesDisplay.getPercentageOfTargetCompleteString(total.value, target.value) : <NoDataLabel />}
    </div>,
    <div key="difference" className={`${colWidthClassByName.diffFromExpected}`} style={{color:`${color}`}}>
      {hasTarget ? getDifferenceFromExpectedTarget(total, expected) : <NoDataLabel />}
    </div>,
    <div key="total" className={`${colWidthClassByName.total}`}>
      {Formatter.format(total)}
    </div>
  ];
});

const NoDataLabel = pure(() => <span style={{color: "#999"}}> -- </span>);

const iconStyle = theme => ({
  cursor: "pointer",
  height: 25,
  width: 25,
  lineHeight: "25px",
  marginRight: 5,

  ":hover": {
    color: theme.palette.primary.main
  }
});

const linkStyle = theme => {
  return {
    cursor: "pointer",
    textAlign: "left",
    color: theme.palette.text.main,
    lineHeight: 1.25,
    wordBreak: "break-word",

    ":hover": {
      color: theme.palette.primary.main
    }
  }
};

const getListItemStyle = (isSmallScreen, theme) => {
  const additionalStyle = isSmallScreen ? {backgroundColor: theme.palette.background.card} : {borderLeft: "1px dotted #757575"};
  return {
    listStyleType: "none",
    padding: 0,
    textAlign: "left",
    ...additionalStyle
  };
};

const getBreadcrumbsLabel = breadcrumbs => {
  if (breadcrumbs.length > 1) {
    return (
        <span>
                {breadcrumbs
                    .slice(1)
                    .map((grp, index) => {
                      if (index === breadcrumbs.length - 2) {
                        return <span key={index}>{grp}</span>;
                      } else {
                        return (
                            <span key={index}>
                                    {grp}
                              <i className="fa fa-chevron-right" style={{paddingLeft: 5, paddingRight: 5}} />
                                </span>
                        );
                      }
                    })}
            </span>
    );
  } else {
    return breadcrumbs[0];
  }
};

const getDifferenceFromExpectedTarget = (total, expected) => {
  const difference = total.value - expected.value;
  const wrapper = {
    ...total,
    value: difference
  };
  return Formatter.format(wrapper);
};

const loadKpiForGroups = (kpiId, timeframe, clientIds, matchAnyTagIds, matchAllTagIds, excludedTagIds, groups) => groups.map(group => {
  const groupId = group.id;
  const kpiOptions = {
    timeframe,
    clientIds,
    matchAnyTagIds,
    matchAllTagIds,
    excludedTagIds,
    groupId
  };

  return {
    groupId,
    promise: kpiCalculator.summary(kpiId, kpiOptions)
  };
});

const loadKpiForUsers = (kpiId, timeframe, clientIds, matchAnyTagIds, matchAllTagIds, excludedTagIds, users) => users.map(user => {
  const userId = user.id;
  const kpiOptions = {
    timeframe,
    clientIds,
    matchAnyTagIds,
    matchAllTagIds,
    excludedTagIds,
    userId,
    groupId: user.groupId
  };

  return {
    userId,
    promise: kpiCalculator.summary(kpiId, kpiOptions)
  };
});
