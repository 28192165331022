import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import memoize from "memoize-immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import InteractiveTable from "js/common/views/tables/interactive-table";
import * as ajax from "js/common/ajax";
import * as popups from "js/common/popups";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";

import {TextButton} from "js/common/views/inputs/buttons";
import Layout from "js/common/views/foundation-column-layout";
import Dialog from "js/common/views/dialog";
import BackgroundImageUploader from "js/admin/cubetv/free-text/background-image-uploader";

import { Popover } from '@mui/material';
import Checkbox from "js/common/views/inputs/checkbox";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";


const EditBackgroundImage = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        onChange: ReactPropTypes.func.isRequired,
        url: ReactPropTypes.string,
        channels: ImmutablePropTypes.list.isRequired,
        hasUnsavedChanges: ReactPropTypes.bool.isRequired,
        theme: ReactPropTypes.object
    },

    getInitialState() {
        return {
            images: Immutable.List(),
            showDeleteImageConfirmation:false,
            imageIdToDelete: null
        };
    },

    componentDidMount() {
        loadBackgroundImages().then(images => this.setState({ images }));
    },

    render() {
        const {theme} = this.props;
        return (
            <div>
                {!this.state.images.isEmpty() &&
                    <ImageGrid
                        theme={theme}
                        url={this.props.url}
                        images={this.state.images}
                        usages={getUsagesForBackgroundImages(this.state.images, this.props.channels)}
                        onSlideChange={this.props.onChange}
                        onDeleteImage={this.onDeleteImageClick} />
                }
                <Layout allSmall={12} rowStyle={{ marginTop: "1rem" }}>
                    <Hint style={{margin: "1rem"}}>
                        <Icon icon="info" style={{color: theme.palette.hints.text}} />
                        <span>
                                Background images must be either a PNG (.png) or JPEG (.jpg, .jpeg) file of maximum 20MB in size.<br/>
                                Recommended background image resolution: <strong>1920px (width) by 1080px (height)</strong>.
                            </span>
                    </Hint>
                </Layout>
                <BackgroundImageUploader onImageUploaded={this.onImageUploaded} />
                <ConfirmImageDeleteDialog
                    onConfirmClick={this.deleteImage}
                    onCancelClick={this.cancelDeleteImage}
                    open={this.state.showDeleteImageConfirmation} />
            </div>);
    },

    onDeleteImageClick(id) {
        if (this.props.hasUnsavedChanges) {
            popups.warning("Please save any changes before deleting background images.");
        } else {
            this.setState({
                showDeleteImageConfirmation:true,
                imageIdToDelete: id
            });
        }
    },

    cancelDeleteImage() {
        this.setState({
            showDeleteImageConfirmation:false,
            imageIdToDelete: null
        });
    },

    deleteImage() {
        const id = this.state.imageIdToDelete;
        deleteImage(id)
            .then(() => {
                const images = this.state.images;
                const index = images.findIndex(image => image.get("id") === id);
                this.setState({
                    images: images.remove(index),
                    showDeleteImageConfirmation:false,
                    imageIdToDelete: null
                });
                popups.success("Your image has been successfully deleted");
            }, e => {
                console.error(e);
                popups.contactSupport();
            });
    },

    onImageUploaded() {
        loadBackgroundImages().then(images => this.setState({ images }));
    }

});

const ImageGrid = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        onSlideChange: ReactPropTypes.func.isRequired,
        onDeleteImage: ReactPropTypes.func.isRequired,
        url: ReactPropTypes.string,
        images:  ImmutablePropTypes.list.isRequired,
        usages: ImmutablePropTypes.map.isRequired,
        theme: ReactPropTypes.object
    },

    getInitialState() {
        return {
            popoverAnchorEl: null,
            popoverImageUsages: Immutable.List(),
            popoverOpen: false
        };
    },


    render() {
        const rows = this.props.images.map(this.imageToListItem);
        return (
            <Layout allSmall={12}>
                <div style={{padding: "1em 0"}}>
                    <InteractiveTable
                        columns={[
                            "Image",
                            "Date Uploaded",
                            {
                                label: "High Resolution",
                                textAlign: "center"
                            },{
                                label: "Actions",
                                textAlign: "center"
                            }
                        ]}
                        rows={rows.toArray()} />
                    { this.renderPopover() }
                </div>
            </Layout>);
    },

    imageToListItem(imageConfig) {
        const {theme} = this.props;
        const imageUrl = imageConfig.get("url");
        const id = imageConfig.get("id");
        const image = new Image();
        const isSelected = imageUrl === this.props.url;
        const fontColor = isSelected ? theme.palette.primary.main : theme.palette.textColor;
        const imgName = imageConfig.get("description");
        const imageUsages = this.props.usages.get(id);
        const row = [
            <div
                key={`checkbox-${id}`}
                style={{ cursor: "pointer", color: fontColor }}
                onClick={ () => this.props.onSlideChange(imageUrl) }>
                { <Checkbox color={ fontColor } label={ imgName  } checked={ isSelected } /> }
            </div>,
            <div key={`last-modified-${id}`}>
                { imageConfig.get("lastModified") }
            </div>,
            <div key={`icon-${id}`}>
                { !isHighRes(image)
                    ? <i className="fa fa-warning" style={{ color:"#f00" }} />
                    : <i className="fa fa-check" style={{ color:"#2ad600" }} />
                }
            </div>,
            <div key={`actions-${id}`}>
                { !imageUsages.isEmpty()
                    ? <TextButton extraStyle= {{ background: theme.themeId === "light" ? "#e7e7e7" : ""}} label="Usages" onClick={e => {
                        e.preventDefault();
                        this.setState({
                            popoverAnchorEl: e.currentTarget,
                            popoverImageUsages: imageUsages,
                            popoverOpen: true
                        });
                    }} />
                    : <TextButton extraStyle= {{ background: theme.themeId === "light" ? "#e7e7e7" : ""}} label="Delete" onClick={() => this.props.onDeleteImage(id)} />

                }
            </div>
        ];
        return row;
    },

    renderPopover() {
        const { popoverOpen, popoverAnchorEl, popoverImageUsages } = this.state;
        return (
            <Popover
                open={popoverOpen}
                anchorEl={popoverAnchorEl}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                onClose={() => this.setState({ popoverOpen: false })}>
                <div style={{maxHeight: 300, overflowY: "auto", width: 305, border: "1px solid #555", padding: "0.5rem" }}>
                    <InteractiveTable
                        columns={ ["Channel", "Slide"] }
                        rows={popoverImageUsages.map(this.usageToRow).toArray()} />
                </div>
            </Popover>
        );
    },

    usageToRow(usage) {
        return [
            <div key="channel-name">
                { usage.getIn(["channel","name"])}
            </div>,
            <div key="slide-description">
                { usage.getIn(["slide","description"])}
            </div>
        ];
    }

});

const ConfirmImageDeleteDialog = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        onConfirmClick: ReactPropTypes.func.isRequired,
        onCancelClick: ReactPropTypes.func.isRequired,
        open: ReactPropTypes.bool.isRequired
    },

    render() {
        const actions = [
            <TextButton
                key="cancel-delete"
                label="Cancel"
                style={{ marginRight:8 }}
                onClick={() => this.props.onCancelClick()} />,
            <TextButton
                key="confirm-delete"
                type="alert"
                label="Delete"
                style={{ marginRight:8 }}
                onClick={() => this.props.onConfirmClick()} />,
        ];

        return (
            <Dialog
                title="Delete image?"
                actions={actions}
                modal={false}
                open={this.props.open}
                onRequestClose={() => this.props.onCancelClick()}>
              This cannot be undone.
            </Dialog>
        );
    }

});

const loadBackgroundImages = () => ajax.get({ url: "carousels/backgrounds" }).then(response => Immutable.fromJS(response));

const deleteImage = id => {
    const url = window.path("carousels/backgrounds/", id);
    return ajax.del({ url });
};

const isHighRes = image => {
    const width = image.naturalWidth || image.width;
    const height = image.naturalHeight || image.height;
    return (width === 0 && height === 0) || (width * height) >= (1024 * 768);
};

const getUsagesForBackgroundImages = memoize((images, channels) => {
    return Immutable.Map(images.map(image => [
        image.get("id"),
        channels.flatMap(channel => {
            const slides = channel.getIn(["json", "pages"]);
            return slides
                .filter(slide => containsImage(slide, image.get("url")))
                .map(slide => Immutable.Map({channel, slide}));
        })
    ]));
});

const containsImage = (slide, url) => {
    const backgroundUrl = slide.getIn(["layout", "fullscreen", 0, "backgroundUrl"]);
    return url === backgroundUrl;
};

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <EditBackgroundImage theme={theme} {...props} />;
};