import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";

import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";
import { Layout } from "js/common/views/foundation-column-layout";
import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import LoadingSpinner from "js/common/views/loading-spinner";
import SimpleDataTable from "js/common/views/tables/simple-data-table";

const GroupHistoryCalculationTable = createReactClass({

    getInitialState() {
        return {
            isLoading: false,
            asyncJobs: []
        };
    },

    componentDidMount() {
        this.setState({ isLoading: true });
        this.reloadJobs();
        this.intervalId = setInterval(() => this.reloadJobs(), 30*1000);
        clearInterval(this.intervalId);
    },

    render() {
        return (
            <section className="page-section">
                <AdminHeader>
                    <Icon icon="users" />
                    Status of Updates to Group History
                </AdminHeader>
                <Layout allSmall={12} collapseRow={false} rowStyle={{ marginTop: "1rem" }}>
                    {this.state.isLoading ? <LoadingSpinner /> : this.renderTable()}
                </Layout>
            </section>);
    },

    renderTable() {
        const columns = [ "ID", "Started By", "Started On", "Status" ];
        const { asyncJobs } = this.state;
        return (
            <div>
                <Hint style={{margin: "1rem"}}>
                    <Icon icon="info" style={{color: this.props.theme.palette.hints.text}} />
                    <span>Click on a row in the table below to see further details about that Group history recalculation job, e.g. a detailed error report (if any errors are found).</span>
                </Hint>
                <SimpleDataTable columns={columns} rows={asyncJobs.map(asyncJobToRow)} initialSortDirection="DESC" />
            </div>
        );
    },

    reloadJobs() {
        loadGroupHistoryCalculationJobs().then(asyncJobs => {
            this.setState({ asyncJobs, isLoading: false });
        });
    }

});

const loadGroupHistoryCalculationJobs = () => loadAsyncJobs()
    .then(asyncJobs => asyncJobs.filter(asyncJob => asyncJob.payload.source === "GROUP_HISTORY"));

const loadAsyncJobs = () => ajax.get({ url: "async-calculation-jobs" })
    .then(asyncJobs => asyncJobs.map(parsePayload));

const parsePayload = asyncJob => {
    return {
        ...asyncJob,
        payload: JSON.parse(asyncJob.payload)
    };
};

const asyncJobToRow = asyncJob => {
    let statusStr = "Moved to ";
    statusStr += asyncJob.status;
    statusStr += " at ";
    statusStr += formatDateTime(moment(asyncJob.lastStatusChangeTimestamp).local());

    const startedBy = Users.getUser(asyncJob.userId).get("fullName");

    return [
        asyncJob.id,
        startedBy,
        formatDateTime(moment(asyncJob.startTimestamp).local()),
        statusStr
    ];
};

const formatDateTime = (date, pattern = "YYYY-MM-DD HH:mm:ss") => date.format(pattern);

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <GroupHistoryCalculationTable theme={theme} {...props} />;
};
