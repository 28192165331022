import React from "react";
import Immutable from "immutable";
import Drawer from "js/common/views/drawer";
import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import * as Colors from "js/common/cube19-colors";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const EditEdgeDrawer = React.memo(({
  isEditEdgeOpen,
  editEdge,
  setEditEdge,
  setIsEditEdgeOpen,
  page,
  handleEditPage,
  tabStyle,
  inputDelayMillis,
  errors
}) => {

  const parentNodeIndex = page.get("nodes", Immutable.List())
      .findIndex(node => node.get("id") === editEdge.get("source"));

  const edge = page
      .getIn(["nodes", parentNodeIndex, "edgesOut"], Immutable.Map())
      .map((edge, index) => edge.set("index", index))
      .filter(e => e.get("nodeId") === editEdge.get("target"))
      .first();

  const label = edge && edge.get("label");

  const editEdgeErrors = errors.filter(e =>
      e.getIn(["location", "nodeId"]) === editEdge.get("source")
      && e.getIn(["location", "param", 1]) === edge.get("index"));
  const isEdgeErroring = editEdgeErrors.size > 0;

  const handleUpdateEdgeLabel = (value) => {
    const childNodeId = editEdge.get("target");
    const updatedEdges = page
        .getIn(["nodes", parentNodeIndex, "edgesOut"])
        .map(e => e.get("nodeId") === childNodeId ? e.set("label", value) : e);
    handleEditPage(page.setIn(["nodes", parentNodeIndex, "edgesOut"], updatedEdges));
  };

  const handleDrawerClose = () => {
    setIsEditEdgeOpen(false);
    setEditEdge(Immutable.Map());
  };

  return <Drawer open={isEditEdgeOpen}
                 title={"Edit Edge"}
                 showCloseButton
                 onRequestClose={handleDrawerClose}
                 onRequestOpen={() => setIsEditEdgeOpen(true)}
                 openRight={true}
                 minWidth={700}
                 width="60%"
                 bgColor="card">
    <>
      <div style={{borderBottom: `1px solid ${Colors.blueBorder}`}}>
        <Tabs style={{marginBottom: 5, marginTop: -16}}
              value="Settings"
              TabIndicatorProps={{
                style: {
                  display: "none"
                }
              }}>
          <Tab style={{...tabStyle, color: Colors.accentPurple}}
               label={<div><i className="fa fa-cog" style={{paddingRight: "5px"}} />Settings</div>}
               value="Settings"
               key="Settings" />;
        </Tabs>
      </div>
      <div style={{
        margin: 30,
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}>
        <DelayedTextField
            theme="cube2021"
            delayInMillis={inputDelayMillis}
            label="Source Node"
            disabled
            style={{width: "100%", marginRight: 15}}
            InputProps={{disableUnderline: true}}
            value={editEdge.get("source") || ""} />
        <DelayedTextField
            theme="cube2021"
            delayInMillis={inputDelayMillis}
            label="Target Node"
            disabled
            style={{width: "100%", marginRight: 15}}
            InputProps={{disableUnderline: true}}
            value={editEdge.get("target") || ""} />
        <DelayedTextField
            theme="cube2021"
            delayInMillis={inputDelayMillis}
            label="Label"
            error={isEdgeErroring}
            helperText={isEdgeErroring && editEdgeErrors.first().get("message")}
            style={{width: "100%"}}
            InputProps={{disableUnderline: true}}
            onChange={handleUpdateEdgeLabel}
            value={label || ""} />
      </div>
    </>
  </Drawer>;
});

export default EditEdgeDrawer;