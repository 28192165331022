import React from "react";
import pure from "js/common/views/pure";
import * as Branding from "js/common/branding-constants"
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

export default React.memo(() => {
    const {theme} = React.useContext(CustomThemeContext);

    return (
        <div style={{ margin: "1rem" }}>
            <h2 style={{ color: theme.themeId === "dark" ? theme.palette.primary.main : theme.palette.textColor, fontSize: "1rem", fontWeight: "bold" }}>
                Oops! An unexpected error has occurred.
            </h2>
            <p style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                Please try refreshing the page in a few minutes or contact
                &nbsp;<a style={{color: theme.themeId === "light" ? "#000" : "grey", textDecoration: "underline"}} href={`mailto:${Branding.emailSupport}?subject=${Branding.brandingName} error`}>{Branding.emailSupport}</a>&nbsp;
                with details of what happened and what you were doing when this happened.<br/>
                {Branding.submitTicketInstructions}
            </p>
            <p>Our support team will get back to you soon.</p>
        </div>)
});
