/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import {jsx, css, keyframes} from "@emotion/react";
import * as Colors from "js/common/cube19-colors";
import * as dealMusicRepo from "js/common/repo/deal-music-repo";
import * as Groups from "js/common/groups";

import GroupNodeUserDisc from "js/admin/groups/group-inheritance/group-node/group-node-user-disc";
import GroupNodeLabel from "js/admin/groups/group-inheritance/group-node/group-node-label";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDoubleLeft, faChevronDown, faChevronUp, faPencil} from "@fortawesome/pro-solid-svg-icons";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const GroupNodeInner = createReactClass({
  displayName: "GroupNode",

  getInitialState() {
    return {
      showChildNodes: true,
      showUsers: false
    };
  },

  UNSAFE_componentWillReceiveProps(newProps) {
    const {group, searchTerm} = newProps;
    if (searchTerm !== this.props.searchTerm) {
      this.setState({showUsers: usersMatchSearch(group, searchTerm)});
    }
  },

  render() {
    const {group} = this.props;

    const nodeStyle = css`
        display: block;
        min-width: 312px;
        max-width: 312px;
        padding: 10px 0;
        text-align: center;
        position: absolute;
        left: 0;
        font-size: 11px;
        letter-spacing: 1px;
        text-transform: uppercase;
        top: calc(50% - 46px);
        h3 {
          color: #fff;
          font-family: ${this.props.theme.typography.fontFamilyBold};
        }
        &:hover {
          .actions {
            display: block;
          }
        }
      `;

    const rootNodeStyle = css`
        ${nodeStyle};
        top: calc(50% - 46px);
      `;

    const collapsedNode = css`
      ${nodeStyle};
      background: ${this.props.theme.palette.background.paper};
      border-radius: 10px;
      border: 2px dashed ${this.props.theme.themeId === "light" ? "#fff": "#3b3b4a"};
      color: ${this.props.theme.themeId === "light" ? "#000" : "#fff"};
      height: 60px;
      min-width: 60px;
      width: 60px;
      left: -65px;
      top: calc(50% - 28px);
      z-index: 1;
      strong {
        display: block;
        font-family: ${this.props.theme.typography.fontFamilyBold};
        color: ${this.props.theme.themeId === "light" ? "#000" : "#fff"};
        font-size: 18px;
        margin: 0;
        opacity: 0.3;
      }
      span {
        opacity: 0.3;
        margin-top: -2px;
        margin-bottom: 2px;
      }
    `;

    const branchStyle = css`
        position: relative;
        margin-left: 426px;
        &:before {
            content: "";
            width: 60px;
            border-top: 2px solid ${this.props.theme.themeId === "light" ? "#fff": "#3b3b4a"};
            position: absolute;
            left: -120px;
            top: 50%;
        }
        &:empty { 
          &:before {
              display: none;
          }
        }
      `;

    const entryStyle = css`
        position: relative;
        min-height: ${this.state.showUsers ? "320px" : "200px"};
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:before {
          content: "";
          height: 100%;
          border-left: 2px solid ${this.props.theme.themeId === "light" ? "#fff": "#3b3b4a"};
          position: absolute;
          left: -60px;
          top: 0;
        }
        &:after {
          content: "";
          width: 60px;
          border-top: 2px solid ${this.props.theme.themeId === "light" ? "#fff": "#3b3b4a"};
          position: absolute;
          left: -60px;
          top: 50%;
        }
        &:first-child {
          &:before {
            width: 10px;
            height: 50%;
            top: 50%;
            border-radius: 10px 0 0 0;
          }
          &:after {
            height: 10px;
            border-radius: 10px 0 0 0;
          }
        }
        &:last-child {
          &:before {
            width: 10px;
            height: 50%;
            border-radius: 0 0 0 10px;
          }
          &:after {
            height: 10px;
            border-top: none;
            border-bottom: 2px solid ${this.props.theme.themeId === "light" ? "#fff": "#3b3b4a"};
            border-radius: 0 0 0 10px;
            margin-top: -8px;
          }
          &:only-child {
            &:before {
              display: none !important;
            }
            &:after {
              width: 60px !important;
              height: 0 !important;
              margin-top: 0 !important;
              border-radius: 0 !important;
            }
          }
        }
      `;

    const entryStyleSolo = css`
      ${entryStyle};
      &:only-child {      
        &:before {
          display: none !important;
        }
        &:after {
          width: 60px !important;
          height: 0 !important;
          border-radius: 0 !important;
        }
      }
     }
    `;

    // single Root node
    // edge case but may happen for new org
    if (!group.get("parentId") && group.get("children") && group.get("children").size === 0) {
      return (
          <div style={{position: "absolute", padding: "1000px 0"}} className="tree-container">
            <div css={rootNodeStyle} id={`group-${group.get("id")}`}>
              {this.renderNodeContent(group)}
            </div>
          </div>
      );
    }

    // Root node with children
    if (!group.get("parentId") && group.get("children") && group.get("children").size > 0) {
      return (
          <div style={{position: "absolute", padding: "0", top: this.props.offsetHeight}} className="tree-container">
            <div css={rootNodeStyle} id={`group-${group.get("id")}`}>
              {this.renderNodeContent(group)}
            </div>
            <div css={branchStyle}>
              {this.renderChildren(group.get("children"))}
            </div>
          </div>);
    }

    // Branch & Nodes
    if (group.get("parentId") && group.get("children").size > 0) {
      if (this.state.showChildNodes) {
        return (
            <div css={entryStyle}>
              <div css={nodeStyle} id={`group-${group.get("id")}`}>
                {this.renderNodeContent(group)}
              </div>
              <div css={branchStyle}>
                {this.renderChildren(group.get("children"))}
              </div>
            </div>
        );
      } else {
        return (
            <div css={entryStyle}>
              <div css={nodeStyle} id={`group-${group.get("id")}`}>
                {this.renderNodeContent(group)}
              </div>
              <div css={branchStyle} style={{position: "absolute", top: "50%"}}>
                <a onClick={() => this.toggleExpanded()}>
                  <div css={collapsedNode}>
                    <strong>{countChildren(group)}</strong>
                    <span>more</span>
                  </div>
                </a>
              </div>
            </div>
        );
      }
    }

    // Solo Node
    if (group.get("parentId") && group.get("children").size === 0) {
      return (
          <div css={entryStyleSolo}>
            <div css={nodeStyle} id={`group-${group.get("id")}`}>
              {this.renderNodeContent(group)}
            </div>
          </div>
      );
    }

  },

  renderChildren(children) {
    return (
        children.map(group => <GroupNodeInner {...this.props} group={group} key={`group-${group.get("id")}`} />)
    );
  },

  renderNodeContent(group) {
    const fullColor = this.props.theme.themeId === "light" ? "rgba(74, 137, 220, 0.5)" : "rgba(249, 236, 51, 0.5)";
    const halfColor = this.props.theme.themeId === "light" ? "rgba(74, 137, 220, 0)" : "rgba(249, 236, 51, 0)";
    const pulse = keyframes`
      0% { box-shadow: 0 0 0 0 ${fullColor}, 0 0 0 0 ${fullColor}; }
      40% { box-shadow: 0 0 0 50px ${halfColor}, 0 0 0 0 ${fullColor}; }
      80% { box-shadow: 0 0 0 50px ${halfColor}, 0 0 0 30px ${halfColor}; }
      100% { box-shadow: 0 0 0 0 ${halfColor}, 0 0 0 30px ${halfColor}; }
    `;

    const {showUsers, showChildNodes} = this.state;
    const icon = showUsers ? faChevronUp : faChevronDown;

    const shouldHighlight = () => {
      const searchTerm = this.props.searchTerm;
      if (!searchTerm) {
        return css``;
      }
      const highlight = css`animation: ${pulse} 2.5s linear 2`;
      return this.matchesSearch(group) ? highlight : css``;
    };

    const titleStyle = css`
      position: relative;
      color: ${this.props.theme.palette.text.main} !important;
      svg {
        margin-right: 4px;
        float: right;
      }
    `;

    const nodeContent = css`
        background: ${this.props.theme.palette.background.card};
        box-shadow: 0 2px 27px 3px rgba(0,0,0, 0.08);
        ${shouldHighlight()};
        border-radius: 5px;
        padding: 0.5rem;
      .actions {
        display: none;
      }
    `;

    const usersContainer = css`
      font-size: 10px;
      padding: 5px 0 0 0;
    `;

    const userList = group.get("users")
        .filter(u => !usersMatchSearch(group, this.props.searchTerm) ||
            u.get("fullName").toLowerCase().includes(this.props.searchTerm.toLowerCase()));

    const attributeUsers = userList
        .filter(u => this.props.showAttributes.includes("page") && u.get("pageId") !== null ||
            this.props.showAttributes.includes("music") && u.get("dealMusicId") !== null ||
            this.props.showAttributes.includes("locale") && u.get("localeId") !== null);

    const thisAttribute = this.props.showAttributes.count() === 1 ? "this attribute" : "any of these attributes";
    const squashLabel = `${attributeUsers.count()}/${group.get("users").count()} users with ${thisAttribute}`;

    const usersToDisplay = attributeUsers.isEmpty() ? userList.take(6) : attributeUsers;

    return (
        <div css={nodeContent}>
          <div className="actions">{this.renderActions(group)}</div>
          <h3 css={titleStyle}>{group.get("name")}</h3>
          {this.renderTags(group)}
          {group.get("users") && group.get("users").count() > 0 &&
          <div css={usersContainer}>
            {showUsers && this.renderUsers(usersToDisplay, group)}
            {userList.count() > 0 ? (
                    <a className={"TESTCAFE-group-squash-toggle"} onClick={() => this.toggleSquashed()} style={{color: this.props.theme.palette.text.main}}>
                      {squashLabel}&nbsp;&nbsp;
                      <FontAwesomeIcon icon={icon} key={`icon-${icon}`} />
                    </a>)
                : squashLabel}
          </div>
          }
          {(showChildNodes && group.get("children").size > 0 && group.get("parentId") !== null) &&
          <a style={{
            background: this.props.theme.palette.background.card,
            position: "absolute",
            right: "-42px",
            padding: 7,
            top: "34px",
            borderRadius: 3,
            zIndex: 99
          }}
             onClick={() => this.setState({showChildNodes: false})}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} key={`icon-${icon}`} />
          </a>}
        </div>
    );
  },

  renderTags(group) {
    const {pages, showAttributes, theme} = this.props;
    const page = pages.find(page => page.get("id") === group.get("pageId"));
    const labelColor = (a, b, c) => a === b ? c : theme.palette.background.paper;
    const labelTextColor =  (a, b, c) => a === b ? "#fff" : theme.palette.text.main;
    const dealMusic = this.getGroupDealMusic(group);
    const hasNoDealMusic = group.get("dealMusicId") === dealMusicRepo.getSilentMp3Id();

    return (
        <div style={{paddingTop: "5px"}}>
          {showAttributes.includes("page") &&
          <GroupNodeLabel
              theme={theme}
              className={"TESTCAFE-group-page-label"}
              icon="columns"
              title={page.get("name")}
              color={labelColor(group.get("inheritedPageGroupId"), group.get("id"), Colors.pageColor)}
              labelTextColor={labelTextColor(group.get("inheritedPageGroupId"), group.get("id"), Colors.pageColor)}
              onClick={() => this.props.handleClick(group.get("id"), "page")}
          />}
          {showAttributes.includes("currency") &&
          <GroupNodeLabel
              theme={theme}
              className={"TESTCAFE-group-currency-label"}
              icon="coins"
              title={group.get("currencyCode")}
              color={labelColor(group.get("inheritedCurrencyGroupId"), group.get("id"), Colors.currencyColor)}
              labelTextColor={labelTextColor(group.get("inheritedCurrencyGroupId"), group.get("id"), Colors.pageColor)}
              onClick={() => this.props.handleClick(group.get("id"), "currency")}
          />}
          {showAttributes.includes("music") &&
          <GroupNodeLabel
              theme={theme}
              className={"TESTCAFE-group-music-label"}
              icon="music"
              title={hasNoDealMusic ? "No deal music" : dealMusic ? dealMusic.get("description") : "Music not found"}
              color={labelColor(group.get("inheritedMusicGroupId"), group.get("id"), Colors.musicColor)}
              labelTextColor={labelTextColor(group.get("inheritedMusicGroupId"), group.get("id"), Colors.pageColor)}
              onClick={() => this.props.handleClick(group.get("id"), "music")}
          />}
          {showAttributes.includes("locale") &&
          <GroupNodeLabel
              theme={theme}
              className={"TESTCAFE-group-locale-label"}
              icon="globe"
              title={this.props.idToLocale.get(group.get("localeId")).get("code")}
              color={labelColor(group.get("inheritedLocaleGroupId"), group.get("id"), Colors.localeColor)}
              labelTextColor={labelTextColor(group.get("inheritedLocaleGroupId"), group.get("id"), Colors.pageColor)}
              onClick={() => this.props.handleClick(group.get("id"), "locale")}
          />}
        </div>
    );
  },

  renderActions(group) {
    const actionStyles = css`
      background: none;
      position: absolute;
      top: -1rem;
      right: 0;
      padding: 20px;
      padding: 0;
      li {
        list-style: none;
        display: inline-block;
        font-size: 10px;
        margin: 0 8px;
        a {
          display: block;
        }
      }
    `;

    return (
        <ul css={actionStyles}>
          <li className="edit">
            <a
                className={"TESTCAFE-edit-group"}
                onClick={() => this.props.handleClick(group.get("id"))}>
              <FontAwesomeIcon icon={faPencil} key={`icon-edit-group-${group.get("id")}`} size="lg" />
            </a>
          </li>
        </ul>
    );
  },

  renderUsers(users, group) {
    const usersContainer = css`
      margin: 10px 0;
    `;

    const userStyles = css`
      margin: 0;
      padding: 0;
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li {
        list-style: none;
        text-align: left;
        font-size: 12px;
        font-weight: normal;
        text-transform: capitalize;
        padding: 4px;
        display: flex;
        flex-shrink: no-shrink;
        justify-content: center;
        img {
          border-radius: 50%;
          height: 32px;
          width: 32px;
        }
        p {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 90px;
          color: ${this.props.theme.palette.text.main};
          margin-bottom: 0;
          position: relative;
          top: 6px;
          left: 6px;
        }
      }
    `;

    return (
        <div css={usersContainer}>
          <ul css={userStyles}>
            {users.map(user =>
                <GroupNodeUserDisc
                    key={user.get("id")}
                    user={user}
                    handleUserClick={this.props.handleUserClick}
                    showUserPics={this.props.showUserPics}
                    showUserNames={this.props.showUserNames} />)}
          </ul>
          {users.count() < group.get("users").count() &&
          <button
              style={{background: Colors.grey, color: "#fff", marginTop: "10px"}}
              className={"TESTCAFE-all-users-button"}
              onClick={() => this.props.handleDisplayAllUsers(group.get("id"))}>
            View all users
          </button>}
        </div>
    );
  },

  matchesSearch(group) {
    const searchTerm = this.props.searchTerm;
    if (!searchTerm || !group) {
      return false;
    }
    return group.get("id") === this.props.currentSearchResultId;
  },

  getGroupDealMusic(group) {
    if (!group.get("parentId")) {
      return group.get("dealMusicId") ? dealMusicRepo.get(group.get("dealMusicId")) : this.getDefaultDealMusic();
    }
    if (!group.get("dealMusicId") && !group.get("inheritedMusicGroupId")) {
      return this.getDefaultDealMusic();
    }
    if (group.get("inheritedMusicGroupId") !== group.get("id")) {
      return this.getInheritedDealMusic(group.get("inheritedMusicGroupId"));
    }
    return group.get("dealMusicId") ? dealMusicRepo.get(group.get("dealMusicId")) : this.getDefaultDealMusic();
  },

  getInheritedDealMusic(inheritedMusicGroupId) {
    const group = Groups.getGroup(inheritedMusicGroupId);
    return dealMusicRepo.get(group.get("dealMusicId")) || this.getDefaultDealMusic();
  },

  getDefaultDealMusic() {
    return dealMusicRepo.getAll().first();
  },

  toggleExpanded() {
    this.setState(state => ({showChildNodes: !state.showChildNodes}));
  },

  toggleSquashed() {
    this.setState(state => ({
      showUsers: !state.showUsers
    }));
  }
});

const countChildren = (group) => {
  const directChildren = group.get("children");
  const countOfIndirectChildren = directChildren
      .map(c => countChildren(c))
      .reduce((sum, x) => sum + x, 0);
  return directChildren.size + countOfIndirectChildren;
};

const usersMatchSearch = (group, searchTerm) => {
  if (!searchTerm || !group || !group.get("users")) {
    return false;
  }
  const users = group.get("users");
  const usersMatchingSearch = users.filter(u => u.get("fullName").toLowerCase().includes(searchTerm.toLowerCase()));
  return !usersMatchingSearch.isEmpty();
};


export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <GroupNodeInner theme={theme} {...props} />;
};
