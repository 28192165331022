/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import {
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import * as Styles from "js/squids/styles/styles";
import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import {v4 as uuid} from "uuid";
import {css} from "@emotion/react";
import * as Colors from "js/common/cube19-colors";
import LabelledSelect from "js/common/views/inputs/labelled-select";
import ErrorIcon from "js/squids/squid-display/error-icon";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider"

const InfoBox = ({text}) => {
   const style = css`
  border-radius: 5px;
  padding: 20px;
  display: flex !important;
     flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
     text-align: center;
     font-size: 0.9rem;
  border: 2px dashed #6D8296;
  color: #6D8296;
  .fa {
    size: 24px;
    padding: 5px 10px;
    border-radius: 50%;
    color: ${Colors.greyDark};
    background-color: #6D8296;
    margin-bottom: 10px;
  }
`
    return <div css={style}><i className="fa fa-info"/>{text}</div>
}

const EditIcons = ({handleEditPage, page, theme, index, icons, errors, inputDelayMillis}) => {

  const [iconPickerStatus, setIconPickerStatus] = React.useState(Immutable.fromJS({open: false}));

  const iconColors = Colors.iconColors;
  const iconOptions = Immutable.fromJS(
      [
          "bar-chart",
        "chart-pie",
        "project-diagram",
        "shopping-cart",
        "hand-holding-heart",
        "chart-line",
          "check-circle",
          "percentage",
          "piggy-bank",
          "address-book",
          "building",
          "envelope",
          "phone",
          "calendar-alt",
          "at",
          "bell",
          "inbox",
          "exclamation"
      ]);

  const createNewIcon = (stepIndex) => {

    handleEditPage(page.updateIn(["steps", stepIndex, "icons"], iconList => {
          if (iconList) {
            return iconList.push(Immutable.fromJS({
              color: Colors.white,
              icon: "bar-chart"
            }));
          } else {
            return Immutable.fromJS([{
              color: Colors.white,
              icon: "bar-chart"
            }]);
          }
        }
    ));
  };

  const deleteIcon = (stepIndex, iconIndex) => {
    handleEditPage(page.updateIn(["steps", stepIndex, "icons"], iconList => iconList.delete(iconIndex)));
  };

  const moveIconDown = (stepIndex, iconIndex, icon) => {
    const newOrder = page.updateIn(["steps", stepIndex, "icons"], icons => icons.delete(iconIndex)
        .insert(iconIndex + 1, icon));
    handleEditPage(newOrder);
  };

  const moveIconUp = (stepIndex, iconIndex, icon) => {
    const newOrder = page.updateIn(["steps", stepIndex, "icons"], icons => icons.delete(iconIndex)
        .insert(iconIndex - 1, icon));
    handleEditPage(newOrder);
  };


  return <>
    <div style={{display: "flex", flexDirection: "column"}}>
      <div style={{display: "flex"}}>
        <div style={{fontSize: "0.85em", paddingTop: 10}}>Icons</div>
        <IconButton
          icon="plus"
          style={{color: theme.palette.textColor, fontSize: "0.75rem"}}
          onClick={() => createNewIcon(index)}
          size="large" />
      </div>
      {icons.size > 0 && <div style={{display: "flex", paddingLeft: 60, fontSize: "0.75rem", paddingBottom: 5}}>
        <div style={{width: "47%"}}>Value</div>
        <div>Label</div>
      </div>}
      {icons.size === 0 ? <InfoBox text="Click the + button to add an icon.  Icons let you display key statistics to add further detail to your step."/> :
        icons.map((icon, iconIndex) => {

        const iconErrors = errors.filter(e => e.getIn(["location", "param", 1]) === iconIndex);
        const isIconErroring = iconErrors.size > 0;

        return (
          <div key={iconIndex}
                      style={{zIndex: 10000, marginBottom: 10, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
            <div style={{display: "flex", flexDirection: "column"}}>
              <IconButton
                icon="chevron-up"
                onClick={() => moveIconUp(index, iconIndex, icon)}
                disableClick={iconIndex === 0}
                style={{
                  padding: 2,
                  marginBottom: 5,
                  color: Colors.white,
                  backgroundColor: Colors.grey,
                  fontSize: "0.7rem"
                }}
                size="large" />
              <IconButton
                icon="chevron-down"
                onClick={() => moveIconDown(index, iconIndex, icon)}
                disableClick={iconIndex ===
                page.getIn(["steps", index, "icons"]).size - 1}
                style={{padding: 2, color: Colors.white, backgroundColor: Colors.grey, fontSize: "0.7rem"}}
                size="large" />
            </div>
            <div>
              <IconButton
                icon={icon.get("icon", "bar-chart")}
                style={{color: icon.get("color"), fontSize: "1.2rem", width: 45}}
                onClick={() => setIconPickerStatus(Immutable.fromJS({open: true, iconIndex: iconIndex}))}
                size="large" />
            </div>
            <div style={{display: "flex", width: "100%", marginRight: 5}}>
              <DelayedTextField
                  placeholder="<formula string>"
                  theme="cube2021"
                  delayInMillis={inputDelayMillis}
                  style={{width: "100%"}}
                  InputProps={{disableUnderline: true}}
                  value={icon.get("value") || ""}
                  error={isIconErroring}
                  helperText={isIconErroring && iconErrors.first().get("message")}
                  onChange={value => handleEditPage(page.setIn(["steps", index, "icons", iconIndex, "value"], value))} />
            </div>
            <div style={{display: "flex", width: "100%", marginRight: 5}}>
              <DelayedTextField
                  placeholder="Label"
                  delayInMillis={inputDelayMillis}
                  style={{width: "100%"}}
                  theme="cube2021"
                  InputProps={{disableUnderline: true}}
                  value={icon.get("label") || ""}
                  onChange={value => handleEditPage(page.setIn(["steps", index, "icons", iconIndex, "label"], value))} />
            </div>
            <IconButton
              icon="trash"
              style={{color: Colors.redLight, fontSize: "0.9em"}}
              onClick={() => deleteIcon(index, iconIndex)}
              size="large" />
          </div>
        );
      })}
    </div>
    <Dialog open={iconPickerStatus.get("open")} onClose={() => setIconPickerStatus(Immutable.fromJS({"open": false}))}>
      <div style={{display: "flex", flexDirection: "column", margin: 10, width: 300}}>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <IconButton
            style={{paddingTop: 0, paddingRight: 0, paddingBottom: 3}}
            icon="times"
            onClick={() => setIconPickerStatus(Immutable.fromJS({"open": false}))}
            size="large" />
        </div>
        <div style={{display: "flex", flexWrap: "wrap"}}>{iconColors.map(color => <div style={{margin: 5}}>
          <IconButton
            onClick={() => handleEditPage(page.setIn(["steps", index, "icons", iconPickerStatus.get("iconIndex"), "color"], color))}
            icon=""
            style={{
              backgroundColor: color,
              width: "40px",
              height: "40px"
            }}
            size="large" /></div>)}</div>
        <hr style={{width: "100%", border: `1px solid ${Colors.tableBorder}`}} />
        <div style={{display: "flex", flexWrap: "wrap"}}>{iconOptions.map(icon => <div style={{margin: 5}}>
          <IconButton
            icon={icon}
            onClick={() => handleEditPage(page.setIn(["steps", index, "icons", iconPickerStatus.get("iconIndex"), "icon"], icon))}
            style={{
              type: "bright",
              fontSize: "1.5rem",
              width: "40px",
              height: "40px"
            }}
            size="large" /></div>
        )}</div>
      </div>
    </Dialog>
  </>;
};

const EditNodes = ({handleEditPage, nodeConfig, index, page, theme}) => {

  const themeId = page.getIn(["defaultDisplay", "theme"], "default");

  const visibilityOptions = Immutable.fromJS(["display-all", "hide-parents", "hide-children", "hide-children-and-self", "hide-parents-and-self"]);
  const styles = Styles.getIdToNodeStyle(themeId).keySeq().toList().filter(style => style !== "edit");
  const nodes = page.get("nodes", Immutable.List());

  const createNewNode = () => {
    handleEditPage(page.updateIn(["steps", index, "display", "nodes"], nodeList => nodeList.push(Immutable.Map())));
  };

  const deleteNode = (nodeIndex) => {
    handleEditPage(page.updateIn(["steps", index, "display", "nodes"], nodeList => nodeList.delete(nodeIndex)));
  };

  const handleVisibilityChange = (value, nodeIndex) => {
    if (value === "display-all") {
      handleEditPage(page.deleteIn(["steps", index, "display", "nodes", nodeIndex, "visibility"]));
    } else {
      handleEditPage(page.setIn(["steps", index, "display", "nodes", nodeIndex, "visibility"], value));
    }
  };

  return <>
    <span style={{fontSize: "0.85em"}}>Nodes</span>
    <IconButton
      icon="plus"
      style={{color: theme.palette.textColor, fontSize: "0.75rem"}}
      onClick={createNewNode}
      size="large" />
    {nodeConfig.size === 0 ? <InfoBox text="Click the + button to define which nodes to display for this step.  Leaving this section blank will display all nodes."/> : <>
    <div style={{flexGrow: 4, justifyContent: "space-between", display: "flex", fontSize: "0.75rem", marginBottom: 5}}>
      <div style={{width: "38%"}}>Node ID</div>
      <div style={{width: "24%"}}>Style</div>
      <div style={{width: "28%"}}>Visibility</div>
    </div>
    {nodeConfig.map((node, nodeIndex) => <div style={{display: "flex"}} key={nodeIndex}>
          <div style={{flexGrow: 4, justifyContent: "space-between", display: "flex", marginBottom: 10}}>
            <div style={{width: "40%"}}>
              <LabelledSelect
                  placeholder="Node ID"
                  theme="cube2021"
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  selectedValue={node.get("id") || ""}
                  onChange={value => handleEditPage(page.setIn(["steps", index, "display", "nodes", nodeIndex, "id"], value))}
                  options={nodes.map(node => Immutable.Map({label: node.get("id"), value: node.get("id")}))} />
            </div>
            <div style={{width: "25%"}}>
              <LabelledSelect
                  placeholder="Style"
                  theme="cube2021"
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  selectedValue={node.get("style") || "default"}
                  onChange={value => handleEditPage(page.setIn(["steps", index, "display", "nodes", nodeIndex, "style"], value))}
                  options={styles.map(style => Immutable.Map({label: style, value: style}))} />
            </div>
            <div style={{width: "25%"}}>
              <LabelledSelect
                  placeholder="Visibility"
                  theme="cube2021"
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false}
                  selectedValue={node.get("visibility") || "display-all"}
                  onChange={value => handleVisibilityChange(value, nodeIndex)}
                  options={visibilityOptions.map(option => Immutable.Map({label: option, value: option}))} />
            </div>
          </div>
          <div style={{display: "flex", alignItems: "center", marginBottom: 10, marginLeft: 20}}>
            <IconButton
              icon="trash"
              style={{color: Colors.redLight, fontSize: "0.9em"}}
              onClick={() => deleteNode(nodeIndex)}
              size="large" />
          </div>
        </div>
    )}</>}
  </>;
};


const StepsTab = React.memo(({
  handleEditPage,
  page,
  evaluatedPage,
  openSteps,
  setOpenSteps,
  errors,
  inputDelayMillis
}) => {

  const { theme } = React.useContext(CustomThemeContext);
  const createNewStep = () => {
    const id = uuid();

    const newStep = Immutable.fromJS(
        {
          id,
          label: "New Step",
          icons: [],
          display: {
            nodes: []
          }
        }
    );

    if (page.get("steps")) {
      handleEditPage(page.update("steps", steps => steps.push(newStep)));
    } else {
      handleEditPage(page.set("steps", Immutable.fromJS([newStep])));
    }

    setOpenSteps(openSteps.add(id));

  };

  const toggleEditStep = (id) => {
    if (openSteps.contains(id)) {
      setOpenSteps(openSteps.filter(step => step !== id));
    } else {
      setOpenSteps(openSteps.add(id));
    }
  };

  const deleteStep = (id) => {
    const updatedSteps = page.get("steps").filter(step => step.get("id") !== id);
    handleEditPage(page.set("steps", updatedSteps));
    setOpenSteps(openSteps.filter(step => step !== id));
  };

  const renderStepRow = (step, index, theme) => {

    const stepErrors = errors.filter(e => e.getIn(["location", "stepIndex"]) === index);
    const isStepErroring = stepErrors.size > 0;

    const id = step.get("id");

    const moveStepDown = e => {
      e.stopPropagation();
      const newOrder = page.update("steps", steps => steps.delete(index)
          .insert(index + 1, step));
      handleEditPage(newOrder);
    };

    const moveStepUp = e => {
      e.stopPropagation();
      const newOrder = page.update("steps", steps => steps.delete(index)
          .insert(index - 1, step));
      handleEditPage(newOrder);
    };

    const stepStyle = css`
      cursor: pointer;
      .MuiTableCell-root {
        border-bottom: 1px solid ${Colors.tableBorder};
      }
    `;

    const editStepStyle = css`
      cursor: pointer;
      background-color: ${Colors.attributeNotSetColor};

      .MuiTableCell-root {
        border-bottom: none;
      }
    `;

    const iconStyle = theme =>({
      color: theme.palette.textColor,
      backgroundColor: theme.palette.background.card,
      margin: 5,
      padding: 10,
      fontSize: "1.25em",
      borderRadius: 5
    });

    const description = evaluatedPage.getIn(["steps", index, "evaluatedDescription"]);

    return (
      <React.Fragment key={step.get("id")}>
        <TableRow css={openSteps.contains(step.get("id")) ? editStepStyle : stepStyle} onClick={() => toggleEditStep(id)}>
          <TableCell style={{color: Colors.greyText, paddingLeft: 30}}>
            <div style={{display: "flex", alignItems: "center"}}>
              <div style={{display: "flex", flexDirection: "column", marginRight: 20}}>
                <IconButton
                  icon="chevron-up"
                  onClick={moveStepUp}
                  disableClick={index === 0}
                  style={{
                    padding: 2,
                    marginBottom: 5,
                    color: theme.palette.textColor,
                    backgroundColor: theme.palette.background.card,
                    fontSize: "0.7rem"
                  }}
                  size="large" />
                <IconButton
                  icon="chevron-down"
                  onClick={moveStepDown}
                  disableClick={index === page.get("steps").size - 1}
                  style={{
                    padding: 2,
                    color: theme.palette.textColor,
                    backgroundColor: theme.palette.background.card,
                    fontSize: "0.7rem"
                  }}
                  size="large" />
              </div>
              <div style={{display: "flex"}}>
                {step.get("label")}{isStepErroring ? <ErrorIcon /> : ""}
              </div>
            </div>
          </TableCell>
          <TableCell style={{color: Colors.greyText}}>{!openSteps.contains(step.get("id")) && description}</TableCell>
          <TableCell>
            <div style={{display: "flex", justifyContent: "flex-end", marginRight: 7}}>
              <div>
                <IconButton
                  icon="pencil"
                  type="bright"
                  style={openSteps.contains(step.get("id")) ? {...iconStyle(theme), color: theme.palette.background.inverted} : iconStyle(theme)}
                  size="large" />
              </div>
              <div>
                <IconButton
                  icon="trash"
                  type="alert"
                  style={{...iconStyle(theme), marginRight: 0}}
                  onClick={e => {e.stopPropagation(); deleteStep(id);}}
                  size="large" />
              </div>
            </div>
          </TableCell>
        </TableRow>
        {openSteps.contains(step.get("id")) &&
        <TableRow style={{borderBottom: `1px solid ${Colors.tableBorder}`}}>{renderEditStep(id, index, stepErrors, theme)}</TableRow>}
      </React.Fragment>
    );
  };

  const renderEditStep = (id, index, stepErrors, theme) => {

    const step = page.getIn(["steps", index]);

    const descriptionErrors = stepErrors.filter(e => e.getIn(["location", "param"]) === "description");
    const isDescriptionErroring = descriptionErrors.size > 0;
    const iconErrors = stepErrors.filter(e => e.getIn(["location", "param", 0]) === "icons");

    return <td colSpan="3" style={{backgroundColor: Colors.attributeNotSetColor}}>
      <div style={{display: "flex", flexDirection: "column", margin: "0 30px 20px 30px"}}>
        <div style={{display: "flex", marginTop: 10, justifyContent: "space-between"}}>
          <div style={{display: "flex", flexDirection: "column", flexBasis: "40%"}}>
            <DelayedTextField
                label="Step Name"
                theme="cube2021"
                delayInMillis={inputDelayMillis}
                InputProps={{disableUnderline: true}}
                value={step.get("label") || ""}
                onChange={value => handleEditPage(page.setIn(["steps", index, "label"], value))} />
            <DelayedTextField
                label="Step Description"
                theme="cube2021"
                delayInMillis={inputDelayMillis}
                multiline
                rows={4}
                error={isDescriptionErroring}
                helperText={isDescriptionErroring && descriptionErrors.first().get("message")}
                InputProps={{disableUnderline: true}}
                value={step.get("description") || ""}
                onChange={value => handleEditPage(page.setIn(["steps", index, "description"], value))} />
          </div>
          <div style={{flexBasis: "55%"}}>
            <EditIcons page={page}
                       theme={theme}
                       inputDelayMillis={inputDelayMillis}
                       handleEditPage={handleEditPage}
                       index={index}
                       errors={iconErrors}
                       icons={step.get("icons", Immutable.Map())} />
          </div>
        </div>
        <div style={{marginTop: 30}}>
          <EditNodes page={page}
                     theme={theme}
                     handleEditPage={handleEditPage}
                     nodeConfig={step.getIn(["display", "nodes"], Immutable.fromJS([]))}
                     index={index} />
        </div>
      </div>
    </td>;
  };

  const buttonStyle = theme =>({
    margin: 20,
    width: 130,
    color: theme.palette.textColor,
    backgroundColor: Colors.java,
    float: "right",
    textTransform: "none"
  });

  const headerStyle = css`

    .MuiTableCell-root {
      border-bottom: none;
    }
  `;

  return <div>
    <TextButton label={"New Step"}
                style={buttonStyle(theme)}
                onClick={createNewStep} />
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow css={headerStyle}>
            <TableCell key="label" style={{paddingLeft: 60, paddingBottom: 5}}>Label</TableCell>
            <TableCell key="description" style={{paddingBottom: 5}}>Description</TableCell>
            <TableCell key="actions" style={{paddingBottom: 5, paddingRight: 30}} align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {page.get("steps", Immutable.List())
              .map((step, index) => renderStepRow(step, index, theme))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>;
});

export default StepsTab;
