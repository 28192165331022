import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import ReactHighstock from "react-highcharts/ReactHighstock";
import moment from "moment";
import onClickOutside from "react-onclickoutside";

import * as numbers from "js/common/utils/numbers";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as auditer from "js/common/auditer";
import * as Colors from "js/common/cube19-colors";
import getHighchartsConfig from "js/common/highcharts-config";
import {setC19Theme} from "js/common/setup-highcharts-theme";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";
import {getAdvancedTrendSeriesName} from "js/charting/trend/display-name-util";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const _ = window._;

const trendDefaults = theme => ({
    chart: {
        height: 550,
        pinchType: null // temp workaround for tooltips after zooming: https://github.com/highcharts/highcharts/issues/3905
    },
    legend: {
        enabled: true,
        align: "center",
        borderColor: "transparent",
        borderWidth: 0,
    },
    plotOptions: {
        series: {
            dataLabels: {
                enabled: false,
                y: 1,
                formatter: function() {
                    return numbers.roundTo(this.y, 2);
                }
            }
        }
    },
    exporting: {
        enabled: false, // only makes button invisible
        fallbackToExportServer: false,
        sourceWidth: 800,
        scale: 2,
        chartOptions: {
            rangeSelectorZoom: "Zoom Range",
            chart: {
                style: {
                    fontFamily: theme.typography.fontFamily,
                }
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: false,
                        y: 1,
                        formatter: function() {
                            return numbers.roundTo(this.y, 2);
                        }
                    }
                }
            }
        }
    }
});

const whiteChartThemeAxesDefaults = {
    labels: {
        style: { color: "#282828" }
    },
    lineColor: "#DBDBDB",
    tickColor: "#DBDBDB"
};

const yAxisDefaults = {
    gridLineDashStyle: "Dot",
    tickPosition: "inside"
};

const DownloadMenu = onClickOutside(createReactClass({

    getInitialState() {
        return {
            downloadOptionsIsOpen: false,
            showUnsupportedDialog: false
        };
    },

    shouldComponentUpdate(nextProps, nextState) {
        return this.state.downloadOptionsIsOpen !== nextState.downloadOptionsIsOpen
            || this.state.showUnsupportedDialog !== nextState.showUnsupportedDialog
    },

    render() {
        const {theme} = this.props;
        const downloadBg = theme.themeId === "light" ? "#ebebeb" : "#505053";
        return (
            <div
                style={{ position: "relative", display: "inline", background: downloadBg, zIndex: 2 }}
                onClick={ this.openMenu }>
                <IconButton
                    style={{ color: "#b3b3b4" }}
                    type="bright"
                    icon="download"
                    onClick={ () => this.setState({ downloadOptionsIsOpen: true }) }
                    size="large" />
                { this.state.downloadOptionsIsOpen &&
                    <div style={{
                        position: "absolute",
                        right: 0,
                        background: downloadBg,
                        border:`1px solid ${downloadBg}`,
                        borderRadius: 3
                    }}>
                        <ul onMouseLeave={ this.closeMenu }
                            style={{
                                listStyle: "none",
                                textAlign: "left",
                                margin: 0,
                                padding: "0px 10px",
                                fontSize: ".8rem"
                            }}>
                            <li onClick={() => this.onClickDownloadChart("image/png") }><span>.png</span></li>
                            <li onClick={() => this.onClickDownloadChart("image/svg+xml") }><span>.svg</span></li>
                        </ul>
                    </div>
                }
                <Dialog
                    bodyStyle={{ overflow: "visible" }}
                    actionsContainerStyle={{ paddingRight: "2rem" }}
                    title={"Not supported"}
                    actions={[
                        <TextButton
                            key="dialog-ok-btn"
                            type="dark"
                            label="Ok"
                            style={{ marginRight:8 }}
                            onClick={ this.closeUnsupportedDialog  } />
                    ]}
                    autoDetectWindowHeight={true}
                    titleStyle={{ color: "#f9ec33", fontSize: "1rem" }}
                    open={ this.state.showUnsupportedDialog }>
                    <span>
                        This functionality is not available on tablets or mobiles, or Internet Explorer browsers.
                    </span>
                </Dialog>
            </div>
        );
    },

    handleClickOutside() {
        this.closeMenu();
    },

    openMenu() {
        this.setState({ downloadOptionsIsOpen: true });
    },

    closeMenu() {
        this.setState({ downloadOptionsIsOpen: false });
    },

    closeUnsupportedDialog() {
        this.setState({ showUnsupportedDialog: false });
    },

    onClickDownloadChart(type) {
        if (this.isSupported()) {
            this.props.onClickDownloadChart(type);
            this.closeMenu();
        } else {
            this.setState({ showUnsupportedDialog: true });
        }
    },

    isSupported() {
        const browser = auditer.getUserAgent().getResult().browser;
        if (browser.name === "IE" || browser.name === "Edge") {
            return false;
        }
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return false;
        }
        return true;
    }
}));

const TrendChart = createReactClass({

    propTypes: {
        startDate: ReactPropTypes.object.isRequired,
        endDate: ReactPropTypes.object.isRequired,
        dataAggregation: ReactPropTypes.string.isRequired,
        trendType: ReactPropTypes.string,
        isAbsoluteDates: ReactPropTypes.bool,
        chartTheme:  ReactPropTypes.string,
        trends: ReactPropTypes.array,
        title: ReactPropTypes.string
    },

    componentDidMount() {
        this.chart = this.refs.chart.getChart();
    },

    shouldComponentUpdate(nextProps) {
        return this.props.trends !== nextProps.trends || this.props.theme.themeId !== nextProps.theme.themeId || this.props.width !== nextProps.width || this.props.height !== nextProps.height;
    },

    getDefaultProps() {
        return {
            trends: [],
            title: ""
        };
    },

    render() {
        const {
            trendType,
            trends,
            chartTheme,
            startDate,
            endDate,
            isAbsoluteDates,
            dataAggregation,
            showDataPoints,
            theme,
            rangeEnabled,
            zoomEnabled,
            exportEnabled,
            yAxisEnabled,
            height
        } = this.props;

        let cTheme = theme.themeId === "light" ? "WHITE" : chartTheme;
        let xAxisSettings;
        if (trendType === "STANDARD" || isAbsoluteDates) {
            xAxisSettings = {
                minRange: 0.1,
                type: "datetime",
                ordinal: false,
                dateTimeLabelFormats: {
                    millisecond: "%H:%M:%S.%L",
                    second: "%H:%M:%S",
                    minute: "%H:%M",
                    hour: "%H:%M",
                    day: "%e %b \'%y",
                    week: "%e %b \'%y",
                    month: "%b \'%y",
                    year: "%Y"
                }
            };
        } else {
            xAxisSettings = {
                minRange: 0.1,
                showFirstLabel: true,
                showLastLabel: true,

                tickPositioner: function () {
                    let positions = [];
                    let tick = 0;
                    let increment = 1;

                    if (this.dataMax >= 30) {
                        increment = 7;
                    }
                    if (this.dataMax > 90) {
                        increment = 14;
                    }
                    if (this.dataMax > 180) {
                        increment = 30;
                    }

                    if (this.dataMax !== null && this.dataMin !== null) {
                        for (tick; tick - increment <= this.dataMax; tick += increment) {
                            positions.push(tick);
                        }
                    }
                    return positions;
                }
            };
        }

        let yAxisSettings = trendType === "ADVANCED" ? getAdvancedChartYAxes(trends, cTheme, yAxisEnabled) : yAxisDefaults;
        if (cTheme === "WHITE") {
            xAxisSettings = {
                ...xAxisSettings,
                ...whiteChartThemeAxesDefaults
            };
            if (trendType !== "ADVANCED") {
                yAxisSettings = {
                    ...yAxisSettings,
                    ...whiteChartThemeAxesDefaults
                };
            }
        }

        const rangeSelectorButtons = getRangeSelectorButtons(startDate, endDate, isAbsoluteDates);
        const seriesData = trends.map((trend, index) => toHighChartSeries(
            index,
            trend,
            isAbsoluteDates,
            trendType,
            dataAggregation,
            yAxisSettings,
            showDataPoints
        ));

        const chartConfig = {...trendDefaults(theme)};
        if (cTheme === "WHITE") {
            setC19Theme(theme);
            chartConfig.chart.backgroundColor = "#fff";
            chartConfig.legend.backgroundColor = "#fff";
            chartConfig.plotOptions.series.dataLabels.color = "#000";
        } else {
            setC19Theme(theme);
            chartConfig.chart.backgroundColor = Colors.greyDark;
            chartConfig.legend.backgroundColor = "transparent";
            chartConfig.plotOptions.series.dataLabels.color = "#fff";
        }
        chartConfig.plotOptions.series.dataLabels.enabled = showDataPoints;
        chartConfig.exporting.chartOptions.plotOptions = { ...chartConfig.plotOptions };


        const config = getHighchartsConfig(chartConfig, xAxisSettings, {
            chart: {
                height: this.props.height ? this.props.height - 50 : 550,
            },
            scrollbar: {
                enabled: rangeEnabled !== false,
            },
            rangeSelector: rangeEnabled !== false ? {
                selected: rangeSelectorButtons.length - 1,
                allButtonsEnabled: true,
                inputEnabled: false,
                buttons: rangeSelectorButtons,
                x: 0,
                labelStyle: { color: cTheme === "WHITE" ? "black" : "silver" }
            } : { enabled : false},
            navigator: zoomEnabled !== false ? {
                xAxis: xAxisSettings
            } : {enabled: false},
            xAxis: xAxisSettings,
            yAxis: yAxisSettings,
            series: seriesData
        });

        return(
            <div style={{ position: "relative" }}>
                {exportEnabled !== false &&
                    <div style={{ position:"absolute", top: 15, right: 10 }}>
                        <DownloadMenu theme={theme} onClickDownloadChart={ this.handleDownloadChart } />
                    </div>
                    }
                <ReactHighstock config={config} isPureConfig={true} ref="chart" />
            </div>
        );
    },

    handleDownloadChart(type) {
        this.chart.exportChartLocal({ type });
    }

});

const getAdvancedChartYAxes = (trends, chartTheme, enabled = true) => {
    const yAxesInTrendConfig = trends.map(t => t.trendConfig.yAxis || 0);
    const uniqueYAxesSet = new Set(yAxesInTrendConfig);

    const yAxisTitleDefaults = {
        style: {
            fontSize: "12px",
            fontWeight: "bold",
            color: chartTheme === "WHITE" ? "#000" : "#A0A0A3"
        },
        rotation: 0,
        enabled: enabled
    };
    let yAxisSettings = yAxisDefaults;
    if (chartTheme === "WHITE") {
        yAxisSettings = {
            ...yAxisSettings,
            ...whiteChartThemeAxesDefaults
        };
    }
    const axesByIndex = {
        0: {
            ...yAxisSettings,
            order: 0,
            opposite: false,
            title: {
                ...yAxisTitleDefaults,
                text: "L"
            }
        },
        1: {
            ...yAxisSettings,
            order: 1,
            opposite: true,
            title: {
                ...yAxisTitleDefaults,
                text: "R1"
            }
        },
        2: {
            ...yAxisSettings,
            order: 2,
            opposite: true,
            title: {
                ...yAxisTitleDefaults,
                text: "R2"
            }
        }
    };

    const leftYAxisIndex = 0;
    const leftYAxis = Object.assign({}, axesByIndex[leftYAxisIndex]);
    const right1YAxisIndex = 1;
    const right1YAxis = Object.assign({}, axesByIndex[right1YAxisIndex]);
    const right2YAxisIndex = 2;
    const maxYAxisIndex = Math.max(...[ ...uniqueYAxesSet ]);
    let minRequiredYAxesForHighCharts = [];
    uniqueYAxesSet.forEach(yAxisIndex => {
        minRequiredYAxesForHighCharts.push(axesByIndex[yAxisIndex]);
    });
    if (maxYAxisIndex === right1YAxisIndex && minRequiredYAxesForHighCharts.length === 1) {
        delete leftYAxis.title;
        minRequiredYAxesForHighCharts.push(leftYAxis);
    } else if (maxYAxisIndex === right2YAxisIndex) {
        if (!uniqueYAxesSet.has(leftYAxisIndex)) {
            delete leftYAxis.title;
            minRequiredYAxesForHighCharts.push(leftYAxis);
        }
        if (!uniqueYAxesSet.has(right1YAxisIndex)) {
            delete right1YAxis.title;
            minRequiredYAxesForHighCharts.push(right1YAxis);
        }
    }
    return _(minRequiredYAxesForHighCharts).sortBy("order");
};

const getYAxisLabel = (yAxisIndex = 0, yAxisSettings) => {
    const yAxis = yAxisSettings[yAxisIndex];
    return yAxis.title ? ` (${yAxis.title.text})` : "";
};

const toHighChartSeries = (
    trendIndex,
    trendWrapper,
    isAbsoluteDates,
    trendType,
    dataAggregation,
    yAxisSettings,
    showDataPoints
) => {
    let seriesName;
    let yAxis;
    let headerFormat;
    let xDateFormat;
    let color;
    let dashStyle;
    let marker;
    let data;

    switch (trendType) {
        case "ADVANCED":
            const trendConfig = trendWrapper.trendConfig;
            const {
                dataPointMarker,
                lineStyle,
                seriesColour,
                whatToPlot,
                howToPlot
            } = trendConfig;
            dashStyle = lineStyle;
            color = seriesColour;
            yAxis = trendConfig.yAxis;
            const yAxisLabel = getYAxisLabel(yAxis, yAxisSettings);
            const rootTrendName = trendWrapper.rootTrendName;
            seriesName = `${getAdvancedTrendSeriesName(rootTrendName, whatToPlot, howToPlot)} ${yAxisLabel}`;

            if (isAbsoluteDates) {
                data = mapTrendDataToAbsoluteDate(trendWrapper.trend);
                xDateFormat = xDateFormats[dataAggregation];
            } else {
                data = trendWrapper.trend.map(item => [ item.offsetFromStart, item.value ]);
                headerFormat = tooltipHeaderFormats[dataAggregation];
            }

            marker = {
                enabled: !!dataPointMarker && dataPointMarker !== "none",
                symbol: dataPointMarker || null,
                radius: 3
            };
            break;
        case "STANDARD":
            const kpi = kpiRepo.get(trendWrapper.kpiId);
            seriesName = kpi.get("name");
            data = mapTrendDataToAbsoluteDate(trendWrapper.trend);
            xDateFormat = xDateFormats[dataAggregation];
            marker = {
                enabled: showDataPoints,
                symbol: "circle",
                radius: 3
            };
            break;
        default:
            throw new Error("Unsupported Trend Type: " + trendType);
    }

    return {
        type: "spline",
        data,
        yAxis,
        name: seriesName,
        color,
        dashStyle,
        marker,
        dataGrouping: {
            approximation: "sum"
        },
        dataLabels: {
            formatter() {
                return getDataLabelTemplate(this.y);
            }
        },
        tooltip: {
            followPointer: true,
            headerFormat,
            xDateFormat,
            pointFormatter() {
                const point = this;
                return getTooltipTemplate(point.color, point.series.name, point.y);
            }
        }
    };
};

const mapTrendDataToAbsoluteDate = trendData => trendData.map(item => [ toUTC(item.date), item.value ]);
const toUTC = date => Date.UTC(date.year(), date.month(), date.date());

const getDataLabelTemplate = y => {
    return numbers.formatBigNumber(y, {decimalPlaces: 2});
};

const getTooltipTemplate = (color, name, value) => {
    let newVal = numbers.roundTo(value, 2).toLocaleString('en');
    return "<span style=\"color:" + color + "\">\u25CF</span> " + name + ": <b>" + newVal + "</b><br/>";
};

const getRangeSelectorButtons = (start, end, isAbsoluteDates) => {
    const daysBetweenStartAndEndDate = end.diff(start, "days") + 1;
    const rangeSelectors = [];

    //NOTE: This is a Highstock hack advised by Highsoft support to display milliseconds as days
    const dayType = isAbsoluteDates ? "day" : "millisecond";
    const monthType = isAbsoluteDates ? "month" : "millisecond";

    if (daysBetweenStartAndEndDate > 1) {
        rangeSelectors.push({
            type: dayType,
            count: 1,
            text: "1day"
        });
    }
    if (daysBetweenStartAndEndDate > 8) {
        rangeSelectors.push({
            type: dayType,
            count: 7,
            text: "7day"
        });
    }
    if (daysBetweenStartAndEndDate > 32) {
        rangeSelectors.push({
            type: dayType,
            count: 32,
            text: "30day"
        });
    }
    if (daysBetweenStartAndEndDate > 91) {
        rangeSelectors.push({
            type: dayType,
            count: 90,
            text: "90day"
        });
    }

    const TODAY = moment();
    const DAYS_IN_LAST_6_MONTHS = TODAY.clone().diff(moment().subtract(7, "months"), "days");
    const DAYS_IN_LAST_12_MONTHS = TODAY.clone().diff(moment().subtract(13, "months"), "days");
    if (daysBetweenStartAndEndDate > DAYS_IN_LAST_6_MONTHS) {
        const Days181OrMonths6 = isAbsoluteDates ? 6 : 181;
        const Days181OrMonths6Text = isAbsoluteDates ? "6mth" : "181day";
        rangeSelectors.push({
            type: monthType,
            count: Days181OrMonths6,
            text: Days181OrMonths6Text
        });
    }
    if (daysBetweenStartAndEndDate > DAYS_IN_LAST_12_MONTHS) {
        const Days365OrMonths12 = isAbsoluteDates ? 12 : 365;
        const Days365OrMonths12Text = isAbsoluteDates ? "12mth" : "365day";
        rangeSelectors.push({
            type: monthType,
            count: Days365OrMonths12,
            text: Days365OrMonths12Text
        });
    }

    rangeSelectors.push({
        type: "all",
        text: "All"
    });

    return rangeSelectors;
};

const tooltipHeaderFormats = {
    "DAILY"     : "<small>Day {point.x}</small><br/>",
    "WEEKLY"    : "<small>Week {point.x}</small><br/>",
    "MONTHLY"   : "<small>Month {point.x}</small><br/>",
    "QUARTERLY" : "<small>Quarter {point.x}</small><br/>",
    "YEARLY"    : "<small>Year {point.x}</small><br/>",
    "FINANCIAL_QUARTERLY"   : "<small>Financial Quarter {point.x}</small><br/>",
    "FINANCIAL_YEARLY"      : "<small>Financial Year {point.x}</small><br/>"
};

const xDateFormats = {
    "WEEKLY"                : "Week from %A, %e %b %Y",
    "QUARTERLY"             : "Quarter from %b %Y",
    "FINANCIAL_QUARTERLY"   : "Financial Quarter from %b %Y",
    "FINANCIAL_YEARLY"      : "Financial Year from %b %Y"
};


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <TrendChart theme={theme} {...props} />;
};

TrendChart.displayName = "TrendChart";
export default Wrapper;
