import Cube19 from "js/cube19.app";
import * as ajax from "js/common/ajax";
import eventBus from "js/cube19.event-bus";
import Immutable from "immutable";
import {indexBy} from "js/common/utils/collections";

const path = window.path;

Cube19.module("Group", function(Group, App, Backbone, Marionette, $, _) {

  const TagAssignment = Backbone.Model.extend({});

  const GroupTagHistory = Backbone.Collection.extend({
    model: TagAssignment,

    initialize(models, options) {
      this.groupId = options.groupId;
    },

    url() {
      return path(App.urlRoot, "group", this.groupId, "tags");
    }

  });

  const GroupModel = Backbone.Model.extend({

    toJSON(options) {
      return _.omit(this.attributes, ["tags", "history"]);
    },

    initialize() {
      this.set("tags", new GroupTagHistory([], {groupId: this.id}));
    }

  });

  const GroupCollection = Backbone.Collection.extend({
    url: "group",
    model: GroupModel
  });

  Group.Controller = Marionette.Controller.extend({

    initialize(options) {
      App.reqres.setHandler("groups", this.getGroups, this);
      App.reqres.setHandler("group", this.getGroup, this);
      App.reqres.setHandler("group:root", this.getRootGroup, this);
      App.reqres.setHandler("group:child-groups", this.getChildGroups, this);

      App.reqres.setHandler("hierarchy", this.getHierarchy, this);
      App.reqres.setHandler("hierarchy:groupUserPicker", this.getHierarchyWithUsers, this);
      App.reqres.setHandler("hierarchy:breadcrumbs", this.getBreadcrumbs, this);
      App.reqres.setHandler("hierarchy:breadcrumbs-str", this.getBreadcrumbsStr, this);

      App.vent.on("hierarchy:changed", this.load, this);
      eventBus.on("group:changed", this.reloadGroups, this);
      eventBus.on("logged-in-user:data-visibility-changed", this.loadHierarchy, this);

      this.groups = new GroupCollection();
      this.idToNameOnlyGroup = new Immutable.Map();
    },

    load() {
      return Promise
          .all([
            this.loadGroups(),
            this.loadHierarchy(),
            this.loadFallbackGroups(),
          ])
          .then(([_groups, _hierarchy, _fallbackGroups]) => {
            const rootGroupId = this.hierarchy.id;
            this.rootGroup = this.getGroup(rootGroupId);
            App.vent.trigger("data-loaded", "hierarchy");
            App.vent.trigger("data-loaded", "groups");
          });
    },

    loadGroups() {
      return this.groups
          .fetch()
          .catch(xhr => {
            if (xhr.responseJSON?.type === "USER_CANNOT_BE_ORPHAN") {
              // ignore error, leave collection blank
            } else {
              throw xhr;
            }
          });
    },

    loadFallbackGroups() {
      return ajax
          .get({
            url: "group/fallback",
            fatalError: true
          })
          .then(response => {
            const fallbackGroups = Immutable.fromJS(response)
                .map(group => group
                    .set("breadcrumbs", Immutable.List.of(group.get("name")))
                    .set("isFallback", true))
            this.idToNameOnlyGroup = indexBy(u => u.get("id"), fallbackGroups);
            return fallbackGroups;
          });
    },

    reloadGroups() {
      this.loadGroups();
    },

    loadHierarchy() {
      return ajax
          .get({url: "group/hierarchy"})
          .then(response => {
            this.hierarchy = response;
          });
    },

    getGroups() {
      return this.groups;
    },

    getGroup(groupId) {
      return this.groups.get(groupId) ?? this.idToNameOnlyGroup.get(groupId);
    },

    getRootGroup() {
      return this.rootGroup;
    },

    getChildGroups(groupId) {
      let childGroups = [];

      this.groups.each(function(group) {
        if (group.get("parentId") && group.get("parentId") === groupId) {
          childGroups.push(group);
        }
      });

      _.each(childGroups, _.bind(function(childGroup) {
        var descendantGroups = this.getChildGroups(childGroup.id);
        if (descendantGroups.length === 0) {
          return;
        }

        _.each(descendantGroups, function(descendantGroup) {
          childGroups.push(descendantGroup);
        });
      }, this));

      return childGroups;
    },

    getBreadcrumbs(groupId) {
      let breadcrumbs = [];
      let group = this.getGroup(groupId);
      if (!group) {
        breadcrumbs.push({
          id: "ERROR",
          name: "ERROR: Group Not Found"
        });
        return breadcrumbs;
      } else {
        do {
          breadcrumbs.push({
            id: group.id,
            name: group.get("name")
          });
          group = this.getGroup(group.get("parentId"));
        } while (group);

        return breadcrumbs.reverse();
      }
    },

    getBreadcrumbsStr(groupId, delimiter) {
      const delimiterStr = delimiter || "&#9654;";
      const breadcrumbs = this.getBreadcrumbs(groupId);
      if (!breadcrumbs) {
        return "ERROR: Group Not Found";
      } else {
        let breadcrumbsStr = "";
        _.each(breadcrumbs, function(breadcrumb) {
          const firstBreadcrumb = _.first(breadcrumbs);
          if (breadcrumb.id === firstBreadcrumb.id) {
            breadcrumbsStr = breadcrumb.name;
          } else {
            breadcrumbsStr = breadcrumbsStr + " " + delimiterStr + " " + breadcrumb.name;
          }
        });
        return breadcrumbsStr;
      }
    },

    getHierarchy() {
      return this.hierarchy;
    },

    getHierarchyWithUsers(showInvisibleUsers = false) {
      const users = showInvisibleUsers ? App.request("users") : App.request("users:active");
      const usersByGroupId = _(users.toJSON()).groupBy(user => user.groupId);
      const rootGroup = $.extend(true, {}, this.hierarchy);
      this.populateGroupWithUsers(rootGroup, usersByGroupId);
      return rootGroup;
    },

    populateGroupWithUsers(group, usersByGroupId) {
      group.qualifierType = "GROUP";
      group.visible = true;

      group.values.forEach(childGroup => {
        this.populateGroupWithUsers(childGroup, usersByGroupId);
      });

      const usersInGroup = usersByGroupId[group.id] || [];
      usersInGroup.forEach(user => {
        user.qualifierType = "USER";
        user.visible = true;
      });
      group.values = group.values.concat(usersInGroup);
    }

  });

});
