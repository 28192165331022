import React from "react";
import * as Immutable from "immutable";

import CubeTextField from "js/common/views/inputs/text-field";

const validateJsonObject = str => {
  if (!str) {
    return null;
  } else {
    try {
      const parsedValue = JSON.parse(str);
      if (typeof parsedValue === "object" && !Array.isArray(parsedValue) && parsedValue !== null) {
        return null;
      } else {
        return "JSON object required";
      }
    } catch (e) {
      return e.message || "Invalid JSON";
    }
  }
};

const validateJsonArray = str => {
  if (!str) {
    return null;
  } else {
    try {
      const parsedValue = JSON.parse(str);
      if (Array.isArray(parsedValue)) {
        return null;
      } else {
        return "JSON array required";
      }
    } catch (e) {
      return e.message || "Invalid JSON";
    }
  }
};

const validateJson = str => {
  if (!str) {
    return null;
  } else {
    try {
      JSON.parse(str);
      return null;
    } catch (e) {
      return e.message || "Invalid JSON";
    }
  }
};

export {
  validateJsonObject,
  validateJsonArray,
  validateJson
};

export default React.memo(({
  value,
  onChange,
  validator = validateJsonObject,
  emptyValue = new Immutable.Map(),
  ...props
}) => {
  const [str, setStr] = React.useState("");

  React.useEffect(() => {
    const initialStr = (value && value.toJS) ? JSON.stringify(value.toJS(), null, 2) : "";
    setStr(initialStr);
  }, [value]);

  const setStrIfChanged = React.useCallback(newStr => {
    if (newStr !== str) {
      setStr(newStr);
    }
  }, [str]);

  const handleChange = React.useCallback(event => {
    const newStr = event.target.value;
    setStrIfChanged(newStr);
  }, [setStrIfChanged]);

  const handleBlur = React.useCallback(() => {
    if (str === "") {
      // TODO is this an appropriate place for emptyValue? why is this not handled outside the component?
      const emptyStr = (emptyValue && emptyValue.toJS) ? JSON.stringify(emptyValue.toJS(), null, 2) : "";
      setStr(emptyStr);
      onChange(emptyValue);
    } else {
      const jsonError = validator(str);
      // NOTE we don't care about json errors here, we just don't want to propagate them
      if (!jsonError) {
        onChange(Immutable.fromJS(JSON.parse(str)));
      }
    }
  }, [str, onChange, emptyValue, validator]);

  const jsonError = React.useMemo(() => validator(str), [str, validator]);

  return <CubeTextField
      fullWidth={true}
      multiline={true}
      {...props}
      helperText={jsonError || ""}
      error={!!jsonError}
      value={str}
      onChange={handleChange}
      onBlur={handleBlur} />;
});
