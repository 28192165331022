import Immutable from "immutable";

import getDefaultRssFeeds from "js/admin/cubetv/rss-feeds/default-rss-feeds";
import * as ajax from "js/common/ajax";
import eventBus from "js/cube19.event-bus";

const path = window.path;

let rssFeeds = Immutable.List();

function load() {
    return ajax.get({ url: "rss-feeds" })
        .then(response => {
            const customRssFeeds = Immutable.fromJS(response);
            const defaultRssFeeds = getDefaultRssFeeds();
            rssFeeds = defaultRssFeeds.concat(customRssFeeds);
            eventBus.trigger("data-loaded", "rss-feeds");
        }, error => {
            eventBus.trigger("error:fatal", error);
        });
}

function get(id) {
    const index = rssFeeds.findIndex(f => f.get("id") === id);
    return rssFeeds.get(index);
}

function getAll() {
    return rssFeeds;
}

function create(rssFeed) {
    const url = "rss-feeds";
    const json = rssFeed
        .delete("id")
        .delete("titleErrorMessage")
        .delete("urlErrorMessage")
        .toJS();
    return ajax.post({ url, json }).then(response => {
        const newRssFeed = Immutable.fromJS(response);
        rssFeeds = rssFeeds.push(newRssFeed);
        return rssFeeds;
    });
}

function update(rssFeed) {
    const url = path("rss-feeds/", rssFeed.get("id"));
    const json = rssFeed
        .delete("cid")
        .delete("titleErrorMessage")
        .delete("urlErrorMessage")
        .toJS();
    return ajax.put({ url, json }).then(response => {
        const savedRssFeed = Immutable.fromJS(response);
        const index = rssFeeds.findIndex(r => r.get("id") === savedRssFeed.get("id"));
        rssFeeds = rssFeeds.set(index, savedRssFeed);
        return rssFeeds;
    });
}

function remove(id) {
    const url = path("rss-feeds/", id);
    return ajax.del({ url }).then(() => {
        const index = rssFeeds.findIndex(r => r.get("id") === id);
        rssFeeds = rssFeeds.delete(index);
        return rssFeeds;
    });
}

export {
    load,
    get,
    getAll,
    create,
    update,
    remove
};
