import {ControlButton, Controls, useZoomPanHelper} from "react-flow-renderer";
import React from "react";
import * as Colors from "js/common/cube19-colors";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

export const ViewControls = ({isFullScreen, setIsFullScreen, showFullScreen = true}) => {
  const {zoomIn, zoomOut, fitView} = useZoomPanHelper();
  const {theme} = React.useContext(CustomThemeContext);

  return <Controls
      style={{boxShadow: "none"}}
      showZoom={false}
      showInteractive={false}
      showFitView={false}>
    <ViewControl
        theme={theme}
        onClick={() => zoomIn()}
        icon="fa fa-plus"
        tooltipMessage="Zoom In"
    />
    <ViewControl
        theme={theme}
        onClick={() => zoomOut()}
        icon="fa fa-minus"
        tooltipMessage="Zoom Out"
    />
    <ViewControl
        theme={theme}
        onClick={() => fitView()}
        icon="fa fa-crosshairs"
        tooltipMessage="Center Graph"
    />
    {showFullScreen && <ViewControl
        theme={theme}
        onClick={() => {
          setIsFullScreen(!isFullScreen);
          setTimeout(fitView, 1000);
        }}
        icon="bhi-card-expand"
        tooltipMessage="Toggle Fullscreen"
    />}
  </Controls>;
};

const ViewControl = ({theme, onClick, icon, tooltipMessage}) => <ControlButton
    style={buttonStyle(theme)}
    onClick={onClick}
    title={tooltipMessage}>
  <i className={icon} />
</ControlButton>;

const buttonStyle = theme =>({
  backgroundColor: theme.themeId === "light" ? theme.palette.background.paper : Colors.greyLight,
  color: theme.palette.textColor,
  borderBottom: "none",
  boxShadow: "2px 2px 5px black"
});