import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import ImmutablePropTypes from "react-immutable-proptypes";
import getContainerDimensions from "react-dimensions";
import PureRenderMixin from "react-addons-pure-render-mixin";

import { Layout } from "js/common/views/foundation-column-layout";
import PriorityKpiCube from "js/oneview/priority-kpi/priority-kpi-cube";
import { clamp } from "js/common/utils/numbers";

export default getContainerDimensions()(createReactClass({

    mixins: [ PureRenderMixin ],
    displayName: "PriorityKpis",

    propTypes: {
        priorityKpiIds: ImmutablePropTypes.list,
        kpiDataById: ImmutablePropTypes.map.isRequired,
        onPriorityKpiCubeClick: ReactPropTypes.func,
        onQuickViewClick: ReactPropTypes.func,
        clientIds: ImmutablePropTypes.set,
        hasAppliedTagFilters: ReactPropTypes.bool
    },

    render() {
        const { priorityKpiIds, containerWidth } = this.props;
        return(
            <div style={{width: "98%", margin: "0px auto"}}>
                {priorityKpiIds && priorityKpiIds.count() === 0 &&
                    <Layout allSmall={12}>
                        <p>Priority Metrics have not been set</p>
                    </Layout>}
                {priorityKpiIds && priorityKpiIds.count() > 0 &&
                    <div className="row" style={{ padding: "0 15px" }}>
                        <ul className={`block-grid-${calculateNumberOfColumns(containerWidth)}`}>
                            {priorityKpiIds.map(this.kpiToCube)}
                        </ul>
                    </div>}
            </div>
        );
    },

    kpiToCube(kpiId) {
        const { kpiDataById, clientIds } = this.props;
        return (
            <li key={kpiId}>
                <PriorityKpiCube
                    kpiId={kpiId}
                    kpiData={kpiDataById.get(kpiId)}
                    clientIds={clientIds}
                    hasAppliedTagFilters={this.props.hasAppliedTagFilters}
                    onClick={this.props.onPriorityKpiCubeClick}
                    onQuickViewClick={this.props.onQuickViewClick} />
            </li>
        );
    }
}));

const minWidth = 220;
const minColumns = 2;
const maxColumns = 5;
const calculateNumberOfColumns = width => {
    return clamp(
        Math.floor(width / minWidth),
        minColumns,
        maxColumns);
};

