import React from "react";
import * as Rata from "js/common/utils/remote-data";
import * as Formatter from "js/common/utils/formatter";
import * as RatioFormatter from "js/common/utils/ratio-formatter";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const colouredValue = (expected, total, palette) => {
  const percentageToExpectedTarget = total / expected;
  let targetColor;
  if (total < expected) {
    if (percentageToExpectedTarget < 0.5) {
      targetColor = palette.dashboards.behind;
    } else if (percentageToExpectedTarget >= 0.5 && percentageToExpectedTarget < 1.0) {
      targetColor = palette.dashboards.ok;
    } else if (percentageToExpectedTarget >= 1.0) {
      targetColor = palette.dashboards.ahead;
    }
  } else if (total > expected) {
    targetColor = palette.dashboards.ahead;
  } else if (total === 0 && expected === 0) {
    targetColor = "#333333";
  } else if (total === expected) {
    targetColor = palette.dashboards.ok;
  }
  return targetColor;
};

const CustomCell = node => {
  const {theme} = React.useContext(CustomThemeContext);

  if (!node.value || Rata.isLoading(node.value)) {
    return <div
        className="skeleton"
        style={{height: 18, width: "100%", margin: "5px 5px -2px 5px", borderRadius: 3}}>
    </div>;
  } else if (Rata.hasError(node.value)) {
    return <div>Error</div>;
  } else {
    const fieldType = node.column.colId.split("-")[0];
    switch (fieldType) {
      case "kpi":
        const kpiData = node.value.get("value");
        const {hasClientFilters, hasTagFilters, target, total, expectedValue} = kpiData;

        if (kpiData.notFilterableByClient) {
          return <div>N/A</div>;
        }

        const hasTarget = !hasClientFilters && !hasTagFilters && target.value > 0;
        const formattedTotal = Formatter.format(
            total,
            {maxDisplayLength: 6});
        const formattedTarget = Formatter.format(
            target,
            {maxDisplayLength: 6});
        const formattedValue = hasTarget ? `${formattedTotal} / ${formattedTarget}` : formattedTotal;
        return <div
            style={{
              color: hasTarget ? colouredValue(
                  expectedValue.value,
                  total.value,
                  theme.palette) : "inherit"
            }}>{formattedValue}</div>;
      case "ratio":
        const ratioData = node.value.get("value");
        const {displayType, firstKpiResponse, secondKpiResponse, notFilterableByClient} = ratioData;

        if (notFilterableByClient) {
          return <div>N/A</div>;
        }

        return <div>{RatioFormatter.format(displayType, firstKpiResponse.total, secondKpiResponse.total)}</div>;
      default:
        return <div>Error</div>
    }
  }
};

export default CustomCell;
