import { capitaliseWords } from "js/common/utils/strings";

const labelsByAppName = {
    CHARTING: "Trends",
    BIGSCREENS: "Gamification",
    ONEVIEW: "OneView",
    REPORTING: "Slice & Dice",
    ONPOINT: "OnPoint",
    JOB_PIPELINE: "Jobs Pipeline",
    IP_ADMIN: "IP Access Admin",
    KPI_ADMIN: "Metrics Admin",
    REACT_USER_ADMIN: "User Admin",
    CAROUSEL_ADMIN: "Gamification Admin",
    CLIENT_ADMIN: "Company Settings Admin",
    SECTION_ADMIN: "OneView Page Admin",
    JOB_PIPELINE_ADMIN: "Jobs Pipeline Admin"
};

export const getLabelForApp = appName => labelsByAppName[appName] || capitaliseWords(appName.replace(/_/g, " "));
