import React from "react";

import {betterMemo} from "js/common/utils/more-memo";
import Immutable from "immutable";
import {KpiPicker} from "js/admin/kpis/edit-kpis/tabs/edit-config";
import {getIdToNode} from "js/admin/kpis/edit-kpis/utils";
import TooltipLabel from "js/admin/kpis/edit-kpis/tabs/common/tooltip-label";



const ConfigInheritsFrom = betterMemo(
    {displayName: "ConfigInheritsFrom"},
    ({kpis, kpi, label, labelTooltip, onKpiChange, kpiIdToDependentKpis, rootNodes, cyclingKpis, ...props}) => {
        const combineOptions = ["combineWithKpiId", "combineWithMasterMetricType", "combineType", "combineOptions"];
        const combineType = "MERGE_WITH_AND";
        const isDisabled = !!kpi.get("combineWithMasterMetricType") || (!!kpi.get("combineType") && kpi.get("combineType") !== combineType);
        const dependentKpis = !cyclingKpis ? getIdToNode(kpi.get("id"), rootNodes, kpiIdToDependentKpis) : new Immutable.Map();
        const toRemove = dependentKpis.map(k => k.get("id")).valueSeq().toArray();
        const kpisToInheritFrom = kpis.filter(k => k.get("id") === kpi.get("combineWithKpiId") || (!!k.get("config") === !!kpi.get("config") && (!cyclingKpis && !toRemove.includes(k.get("id")))));
        const handleCombineMetricChange = value => {
            if (value === null) {
                onKpiChange(kpi.deleteAll(combineOptions))
            } else {
                onKpiChange(kpi
                    .set("combineWithKpiId", value)
                    .set("combineType", combineType)
                    .delete("combineWithMasterMetricType")
                );
            }
        };

        return <>
          {label && <TooltipLabel label={label} tooltip={labelTooltip}/>}
          <KpiPicker
            containerStyle={{flex: 1, textAlign: "left"}}
            kpis={kpisToInheritFrom}
            kpiId={kpi.get("combineWithKpiId")}
            labelStyle={{display: "none"}}
            isDisabled={isDisabled}
            onChange={value => handleCombineMetricChange(value)}
            {...props}
        /></>;

    });

export default ConfigInheritsFrom;
