import Immutable from "immutable";

import eventBus from "js/cube19.event-bus";
import * as ajax from "js/common/ajax";
import * as auditer from "js/common/auditer";
import * as popups from "js/common/popups";

const path = window.path;

let dealMusicOptions = Immutable.List();

const load = () => ajax
    .get({ url: "dealmusic" })
    .then(response => {
        dealMusicOptions = Immutable.fromJS(response).filter(dm => dm.get("description") !== "Silent");
        eventBus.trigger("data-loaded", "dealmusic");
    }, error => {
        eventBus.trigger("error:fatal", error);
    });

const get = dealMusicId => {
    const index = dealMusicOptions.findIndex(dm => dm.get("dealMusicId") === dealMusicId);
    return dealMusicOptions.get(index);
};

const getAll = () => dealMusicOptions;

const add = newDealMusic => {
    dealMusicOptions = dealMusicOptions.push(newDealMusic);
    return dealMusicOptions;
};

const remove = dealMusicId => ajax
    .del({ url: path("dealmusic", dealMusicId) })
    .then(() => {
        const index = dealMusicOptions.findIndex(dm => dm.get("dealMusicId") === dealMusicId);
        const dealMusic = dealMusicOptions.get(index);
        popups.success(`Deal music file ${dealMusic.get("description")} deleted"`);
        auditer.audit("deal_music_deleted", {
            dealMusicId,
            description: dealMusic.get("description")
        });
        dealMusicOptions = dealMusicOptions.delete(index);
        return dealMusicOptions;
    });

const SILENT_MP3_ID = 1;
const getSilentMp3Id = () => SILENT_MP3_ID;

export {
    load,
    get,
    getAll,
    add,
    remove,
    getSilentMp3Id
};
