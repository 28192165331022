import moment from "moment";
import Immutable from "immutable";
import StackGPS from "stacktrace-gps";
import ErrorStackParser from "error-stack-parser";

import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";
import currentClient from "js/common/repo/backbone/current-client";
import buildInfo from "js/build-info";

const MYSQL_DATETIME_PATTERN = "YYYY-MM-DD HH:mm:ss";
const BUFFER_TIME_IN_SECONDS = 60;

let errorBuffer = Immutable.Map();
let ignoredCases = Immutable.List();

const trackError = (type, error, options = {}) => {
  if (error) {
    buildPayload(type, error, options).then(addToBuffer);
  } else {
    console.warn("Can't track null error", type, error, options);
  }
};

const gps = new StackGPS();
const buildPayload = (
    type,
    error,
    {componentStack} = {}
) => {
  const stackframes = ErrorStackParser.parse(error);
  return Promise
      .all(stackframes.map(sf => {
        return gps
            .getMappedLocation(sf)
            .then(
                x => x,
                e => {
                  console.error("unable to get source mapping for stack frame", e);
                  return sf;
                });
      }))
      .then(sfs => {
        const firstFrame = sfs[0] || error;
        return Immutable.Map({
          type,
          message: error.message,
          source: firstFrame.fileName,
          lineNumber: firstFrame.lineNumber,
          columnNumber: firstFrame.columnNumber,
          stackTrace: JSON.stringify(sfs),
          componentStack,
          timestamp: moment.utc().format(MYSQL_DATETIME_PATTERN),
          count: 1,
          ciBuildId: buildInfo.buildId
        });
      });
};

const shouldSendToServer = payload => {
  payload = payload
      .set("userId", Users.getCurrentUser().id)
      .set("cube19ClientId", currentClient.id);
  return !ignoredCases.some(ignoreCase => ignoreCase.keySeq().every(key => ignoreCase.get(key) === payload.get(key)));
};

const addToBuffer = payload => {
  const stackTrace = payload.get("stackTrace");
  if (errorBuffer.has(stackTrace)) {
    errorBuffer = errorBuffer.update(
        stackTrace,
        existingPayload => existingPayload.update("count", count => count + payload.get("count")));
  } else {
    errorBuffer = errorBuffer.set(stackTrace, payload);
  }
};

const flushBuffer = () => {
  const dtos = errorBuffer.valueSeq().filter(shouldSendToServer);
  errorBuffer = Immutable.Map();
  if (!dtos.isEmpty()) {
    postErrors(dtos);
  }
};

const postErrors = errorDtos => ajax
    .post({
      url: "error-tracking",
      json: errorDtos.toJS()
    })
    .catch(e => {
      errorDtos.forEach(addToBuffer);
    });

const getIgnoreList = () => ajax
    .get({url: "error-tracking/ignore"})
    .then(response => Immutable.fromJS(response))
    .catch(error => console.error("Could not fetch error ignore list", error));

const startTracking = () => {
  window.addEventListener("unhandledrejection", event => {
    const error = event.reason;
    const isException = error.message && error.stack;
    if (isException) {
      trackError("UNHANDLED_REJECTION", error);
    } else {
      console.log("received unknown unhandled rejection value, what should we do with this?", event);
    }
  });
  window.onerror = (message, source, lineNumber, columnNumber, error) => {
    if (message !== "ResizeObserver loop limit exceeded") {
      trackError("UNCAUGHT_EXCEPTION", error);
    }
  };
};

const startSendingToServer = () => {
  return getIgnoreList().then(ignoreList => {
    ignoredCases = ignoreList;
    setInterval(flushBuffer, BUFFER_TIME_IN_SECONDS * 1000);
  });
};

export {
  startTracking,
  startSendingToServer,
  trackError
};