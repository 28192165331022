/** @jsxImportSource @emotion/react */

import React from "react";
import {css} from "@emotion/react";
import GroupUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import * as Users from "js/common/users";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import ErrorToolbar from "js/squids/squid-display/edit-mode/toolbar";
import {FormControlLabel, Switch} from "@mui/material";
import * as Colors from "js/common/cube19-colors";
import * as Styles from "js/squids/styles/styles";
import currentClient from "js/common/repo/backbone/current-client";
import {TextButton} from "js/common/views/inputs/buttons";
import FilterButton from "js/common/views/filter-drawer/filter-button";
import FiltersDrawer from "js/common/views/filter-drawer/filters-drawer";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Controls = React.memo(({
  config,
  onChange,
  setSelectedPageId,
  withReturnToSquidsIndex,
  deleteSquid,
  saveSquid,
  undo,
  redo,
  undoStack,
  redoStack,
  mode,
  hasSquidEditorPermission,
  setMode,
  setIsDrawerOpen,
  errors,
  squidTheme
}) => {
  const numberOfUndoChanges = undoStack && undoStack.size;
  const numberOfRedoChanges = redoStack && redoStack.size;
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = React.useState(false);

  const currentUser = Users.getCurrentUser();
  const { theme } = React.useContext(CustomThemeContext);

  const style = Styles.getControlsStyle(squidTheme);

  const backgroundColor = style ? style.get("backgroundColor") : theme.palette.background.card;
  const fontColor = style ? style.get("fontColor") : theme.palette.text.main;
  const fontHighlightColor = style ? style.get("fontHighlightColor") : theme.palette.text.main;
  const editModeActiveColor = style ? style.get("editModeActive") : Colors.java;
  const editModeInactiveColor = style ? style.get("editModeInactive") : theme.palette.text.main;

  const switchContainer = css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    border-radius: 5px;
    background-color: ${backgroundColor};
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    width: 200px;
    transition: all 0.3s;
    @media (min-width: 900px) {
      margin-left: 1rem;
    }
    @media (max-width: 900px) {
      margin-top: 1rem;
    }
  `;

  const switchStyle = css`
    .MuiSwitch-track {
      background-color: ${mode === "EDIT" ? `${editModeActiveColor} !important` : editModeInactiveColor};
      opacity: 1 !important;
    }

    .MuiSwitch-thumb {
      background-color: ${Colors.white};
    }

    .label {
      margin-bottom: 0 !important;
    }`;

  const openFiltersDrawer = () => {
    setIsFiltersDrawerOpen(true);
  };

  const closeFiltersDrawer = () => {
    setIsFiltersDrawerOpen(false);
  };

  const handleFilterChanges = (allClientIds, clientIds, clientSetIds, matchAnyTagIds, matchAllTagIds, excludedTagIds) => {
    onChange(config
        .set("allClientIds", allClientIds)
        .set("clientIds", clientIds)
        .set("clientSetIds", clientSetIds)
        .set("matchAnyTagIds", matchAnyTagIds)
        .set("matchAllTagIds", matchAllTagIds)
        .set("excludedTagIds", excludedTagIds)
    );
  };

  const backArrow = <svg width="30" height="25" viewBox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.84322 0.314791L0.374322 5.39126C-0.124408 5.85439 -0.125141 6.64497 0.374322 7.10874L5.84322 12.1856C6.59029 12.8793 7.81251 12.3505 7.81251 11.3269L7.81251 8.20312L21.0938 8.20312L21.0938 12.3035C21.0937 12.3805 21.1089 12.4567 21.1384 12.5277C21.1678 12.5988 21.211 12.6634 21.2654 12.7178L23.9998 15.4522C24.3689 15.8213 25 15.5599 25 15.0379L25 5.46875C25 4.82153 24.4753 4.29687 23.8281 4.29687L7.81251 4.29687L7.81251 1.17353C7.81251 0.154098 6.59332 -0.381693 5.84322 0.314791Z"
          fill={fontHighlightColor} />
  </svg>;

  const returnButtonStyles = {
    height: 54,
    borderRadius: "5px",
    marginRight: "1rem",
    paddingTop: 10,
    paddingRight: 0,
    paddingLeft: 0,
    backgroundColor: backgroundColor,
    color: fontColor
  };

  const handleBackArrowClick = () => {
    setSelectedPageId(null);
    setMode("VIEW");
  };

  return <div style={{
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    width: "100%"
  }}>
    {withReturnToSquidsIndex &&
    <TextButton
        label={backArrow}
        style={returnButtonStyles}
        onClick={handleBackArrowClick}
    />}
    <div style={{
      flexGrow: 1,
      flexBasis: "60%",
      flex: 1
    }}>
      <div style={{
        display: "flex",
        borderRadius: 5,
        height: 54,
        alignItems: "center",
        backgroundColor: backgroundColor
      }}>
        {clientHasPermission() &&
        <div style={{flex: 1, marginLeft: 5, marginRight: 2.5}}>
          <FilterButton
              openFiltersDrawer={openFiltersDrawer}
              style={style && {backgroundColor: style.get("inputColor"), color: style.get("fontColor")}}
              clientFilterConfig={config}
              config={config}
              isFiltersDrawerOpen={isFiltersDrawerOpen} />
          <FiltersDrawer
              open={isFiltersDrawerOpen}
              onRequestOpen={openFiltersDrawer}
              onRequestClose={closeFiltersDrawer}
              config={config}
              clientFilterConfig={config}
              onFilterChanges={handleFilterChanges}
          />
        </div>}

        <div style={{flex: 1, margin: "0 1em"}}>
          <GroupUserPicker
              isDisabled={currentUser.get("dataVisibility") === "SELF"}
              style={style && {backgroundColor: theme.palette.background.card, color: theme.palette.text.main}}
              qualifierType={config.get("qualifierType")}
              qualifierId={config.get("qualifierId")}
              onGroupClick={groupId => onChange(config.set("qualifierType", "GROUP").set("qualifierId", groupId))}
              onUserClick={userId => onChange(config.set("qualifierType", "USER").set("qualifierId", userId))} />
        </div>

        <div style={{flex: 1, marginRight: 2.5}}>
          <TimeframePicker
              timeframes={TimeframeRepo
                  .getAll()
                  .filter(t => t.get("visible") && !t.get("isDeleted"))}
              timeframe={TimeframeRepo.parse(config.get("timeframe"))}
              onChange={timeframe => onChange(config.set("timeframe", timeframe.getRawJson()))} />
        </div>
      </div>
    </div>
    {hasSquidEditorPermission &&
    <div>
      <div style={{
        display: "flex",
        justifyContent: "flex-end"
      }}>
        <div css={switchContainer}>
          <FormControlLabel
              className="TESTCAFE-squids-edit-mode"
              style={{whiteSpace: "nowrap", color: fontColor}}
              control={<Switch checked={mode === "EDIT"}
                               css={switchStyle}
                               onChange={e => setMode(e.target.checked ? "EDIT" : "VIEW")}
                               name="editMode" />}
              label="Edit Mode" />
          {mode === "EDIT" &&
          <ErrorToolbar
              errors={errors}
              style={style}
              undo={undo}
              redo={redo}
              numberOfUndoChanges={numberOfUndoChanges}
              numberOfRedoChanges={numberOfRedoChanges}
              saveSquid={saveSquid}
              deleteSquid={deleteSquid}
              mode={mode}
              setIsDrawerOpen={setIsDrawerOpen}
              isDeleteDialogOpen={isDeleteDialogOpen}
              setIsDeleteDialogOpen={setIsDeleteDialogOpen}
              theme={theme} />}
          <div
              style={{
                opacity: mode === "EDIT" ? 100 : 0,
                transition: "opacity 0.3s",
                display: "flex",
                flexGrow: 1,
                justifyContent: "space-around",
                alignItems: "center",
                whiteSpace: "nowrap",
                overflowX: "visible",
                flexWrap: "no-wrap"
              }}>
          </div>
        </div>
      </div>
    </div>}
  </div>;
});

const clientHasPermission = () => {
  return currentClient.canAccessApp("TAGS_ADMIN") || currentClient.hasPermission("ORGANISATION_FILTER");
};

export default Controls;
