import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import Immutable from "immutable";
import moment from "moment";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Select from "js/common/views/inputs/immutable-react-select";

import { Layout } from "js/common/views/foundation-column-layout";
import ButtonBar from "js/common/views/button-bar";
import { TextButton, IconButton } from "js/common/views/inputs/buttons";
import InteractiveTable from "js/common/views/tables/interactive-table";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import ErrorMsg from "js/common/views/error";
import * as ajax from "js/common/ajax";
import * as popups from "js/common/popups";

const path = window.path;

const columns = [
    "Timezone Location",
    "Start Date",
    "End Date",
    {
        label: "Actions",
        textAlign: "center"
    }
];

export default createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        assignedTo: ReactPropTypes.string.isRequired,
        assignedToId: ReactPropTypes.number.isRequired,
        initialHistory: ImmutablePropTypes.list.isRequired,
        onChange: ReactPropTypes.func.isRequired
    },

    getInitialState() {
        return {
            locations: Immutable.List(),
            history: this.props.initialHistory,
            errors: Immutable.List()
        };
    },

    componentDidMount() {
        loadTimezoneLocations().then(locations => this.setState({ locations }));
    },

    render() {
        const { history, errors } = this.state;
        const sortedHistory = history.sortBy(tza => tza.get("startDate"));
        const rows = sortedHistory.map(this.timezoneAssignmentToRow).toArray();
        return (
            <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                {!errors.isEmpty() && this.state.errors.map(error => <ErrorMsg text={error} />)}
                <Layout allSmall={12} collapseRow={true} rowStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <TextButton icon="plus" label="Add Timezone" onClick={this.handleAddRowClick} />
                </Layout>
                <Layout allSmall={12} collapseRow={true}>
                    <InteractiveTable columns={columns} rows={rows} />
                </Layout>
                <ButtonBar style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <TextButton icon="history" label="Cancel" onClick={this.undoChanges} />
                    <TextButton icon="floppy-o" label="Save" onClick={this.saveChanges} />
                </ButtonBar>
            </div>
        );
    },

    timezoneAssignmentToRow(timezoneAssignment) {
        const rowId = timezoneAssignment.get("id") || timezoneAssignment.get("cid");
        const endDate = timezoneAssignment.get("endDate");
        const options = this.getLocationOptions();
        return [
            <Select
                placeholder="Search for a location"
                options={options}
                selectedValue={options.size > 0 && timezoneAssignment.get("location")}
                isClearable={false}
                isMulti={false}
                onChange={location => {
                    this.handleLocationChange(rowId, location);
                }} />,
            <DatePicker
                hideError={true}
                onDateChange={startDate => this.handleStartDateChange(rowId, startDate)}
                value={timezoneAssignment.get("startDate")} />,
            <DatePicker
                hideError={true}
                onDateChange={endDate => this.handleEndDateChange(rowId, endDate)}
                value={isFarFutureDate(endDate) ? null : endDate} />,
            <div>
                <IconButton
                    label="Add"
                    icon="plus"
                    type="bright"
                    hoverType="success"
                    container="column"
                    onClick={() => this.addNewRowAfter(rowId)}
                    size="large" />
                <IconButton
                    label="Delete"
                    icon="trash"
                    type="bright"
                    hoverType="alert"
                    container="column"
                    onClick={() => this.deleteRow(rowId)}
                    size="large" />
            </div>
        ];
    },

    handleLocationChange(rowId, location) {
        const index = this.getIndex(rowId);
        this.setState({
            history: this.state.history.update(index, row => row.set("location", location))
        });
    },

    handleStartDateChange(rowId, startDate) {
        const index = this.getIndex(rowId);
        this.setState({
            history: this.state.history.update(index, row => row.set("startDate", startDate))
        });
    },

    handleEndDateChange(rowId, endDate) {
        const index = this.getIndex(rowId);
        this.setState({
            history: this.state.history.update(index, row => row.set("endDate", endDate))
        });
    },

    handleAddRowClick() {
        const { assignedTo, assignedToId } = this.props;
        const TODAY = moment();
        const newRow = Immutable.fromJS({
            cid: Math.random(),
            assignedTo: assignedTo,
            assignedToId: assignedToId,
            location: null,
            startDate: TODAY,
            endDate: getFarFutureDate()
        });
        this.setState({
            history: this.state.history.push(newRow)
        });
    },

    addNewRowAfter(rowId) {
        const index = this.getIndex(rowId);
        const timezoneAssignment = this.state.history.get(index);
        const TODAY = moment();
        const endDate = timezoneAssignment.get("endDate");
        const newRow = timezoneAssignment
            .delete("id")
            .set("cid", Math.random())
            .set("startDate", isFarFutureDate(endDate) ? TODAY : endDate.clone().add(1, "days"))
            .set("endDate", getFarFutureDate());
        this.setState({
            history: this.state.history.insert(index + 1, newRow)
        });
    },

    deleteRow(rowId) {
        this.setState({
            history: this.state.history.delete(this.getIndex(rowId))
        });
    },

    undoChanges() {
        this.setState({
            history: this.props.initialHistory,
            errors: this.state.errors.clear()
        });
    },

    saveChanges() {
        let validationErrors = Immutable.List();
        this.state.history.forEach((timezoneAssignment, index) => {
            const ROW_NUMBER = index + 1;
            if (!timezoneAssignment.get("location")) {
                validationErrors = validationErrors.push(`Select Timezone Location for row ${ROW_NUMBER}`);
            }
            if (!timezoneAssignment.get("startDate").isValid()) {
                validationErrors = validationErrors.push(getDateErrorMessage("Start Date", ROW_NUMBER));
            }
            if (!timezoneAssignment.get("endDate").isValid()) {
                validationErrors = validationErrors.push(getDateErrorMessage("End Date", ROW_NUMBER));
            }
        });
        if (!validationErrors.isEmpty()) {
            this.setState({ errors: validationErrors });
            return;
        }

        const updateHistory = updateHistoryByQualifierType[this.props.assignedTo];
        this.setState({
            errors: this.state.errors.clear(),
            isLoading: true
        }, () => {
            updateHistory(this.props.assignedToId, this.state.history)
                .then(history => {
                    this.setState({
                        isLoading: false
                    }, () => {
                        this.props.onChange(history);
                        popups.success("Timezone history changes saved");
                    });
                })
                .catch(error => {
                    const errorResponse = error.responseJSON;
                    let errors;
                    if (errorResponse && errorResponse.errors) {
                        errors = Immutable.fromJS(Object.keys(errorResponse.errors).map(k => errorResponse.errors[k]));
                    } else {
                        errors = errors.push("Unable to update timezone history");
                    }
                    this.setState({
                        errors,
                        isLoading: false
                    });
                });
        });
    },

    getLocationOptions() {
        return this.state.locations.map(loc => Immutable.Map({
            value: loc,
            label: loc.replace(/_/g, " ")
        }));
    },

    getIndex(rowId) {
        return this.state.history.findIndex(row => (row.get("id") || row.get("cid")) === rowId);
    }

});

const getDateErrorMessage = (dateColumn, rowNumber) => `${dateColumn} in row ${rowNumber} should be in the format ${moment.localeData().longDateFormat.L}`;

const formatDate = (date, pattern = "YYYY-MM-DD HH:mm:ss") => date.clone().startOf("day").format(pattern);
const parseDate = (dateStr, pattern = "YYYY-MM-DD") => moment(dateStr, pattern);
const getFarFutureDate = () => parseDate("2099-01-01");
const isFarFutureDate = date => date.isSame(getFarFutureDate(), "day");

const updateTimezoneHistoryForGroup = (groupId, history) => {
    const url = path("groups", groupId, "locations");
    const json = history.map(timezoneAssignment => {
        return timezoneAssignment
            .set("startDate", formatDate(timezoneAssignment.get("startDate")))
            .set("endDate", formatDate(timezoneAssignment.get("endDate")))
            .delete("cid");
    }).toJS();
    return updateTimezoneAssignmentData(url, json);
};

const updateTimezoneHistoryForUser = (userId, history) => {
    const url = path("users", userId, "locations");
    const json = history.map(timezoneAssignment => {
        return timezoneAssignment
            .set("startDate", formatDate(timezoneAssignment.get("startDate")))
            .set("endDate", formatDate(timezoneAssignment.get("endDate")))
            .delete("cid");
    }).toJS();
    return updateTimezoneAssignmentData(url, json);
};

const updateTimezoneAssignmentData = (url, json) => ajax
    .put({ url, json })
    .then(history => Immutable.fromJS(history).map(parseDatesInTimezoneAssignment));

const parseDatesInTimezoneAssignment = timezoneAssignment => timezoneAssignment
    .set("startDate", parseDate(timezoneAssignment.get("startDate")))
    .set("endDate", parseDate(timezoneAssignment.get("endDate")));

const updateHistoryByQualifierType = {
    USER: updateTimezoneHistoryForUser,
    GROUP: updateTimezoneHistoryForGroup
};

const loadTimezoneLocations = () => ajax.get({ url: "locations" }).then(response => Immutable.fromJS(response));
