/** @jsxImportSource @emotion/react */
import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import {FixedSizeList} from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import GetContainerDimensions from "react-dimensions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import * as ajax from "js/common/ajax";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const InfiniteList = GetContainerDimensions()(createReactClass({

  propTypes: {
    searchPrefix: ReactPropTypes.string.isRequired,
    searchUrl: ReactPropTypes.string,
    searchParams: ReactPropTypes.object,
    searchResultToItem: ReactPropTypes.func,
    startItems: ImmutablePropTypes.list, //pre-filter
    startItemStyle: ReactPropTypes.object,
    startItemIcon: ReactPropTypes.object,
    selectedItemIds: ImmutablePropTypes.set,
    itemIcon: ReactPropTypes.object,
    onItemClick: ReactPropTypes.func.isRequired,
    maxHeight: ReactPropTypes.number,
    width: ReactPropTypes.number,
    itemHeight: ReactPropTypes.number
  },

  getInitialState() {
    return {
      prefixCache: Immutable.Map()
    };
  },

  getDefaultProps() {
    return {
      searchResultToItem: f => f,
      startItems: Immutable.List(),
      startItemStyle: {},
      selectedItemIds: Immutable.Set(),
      keys: ["id", "name", "name"],
      itemHeight: 34,
      maxHeight: 400,
      disabled: false
    };
  },

  render() {
    const {
      searchUrl,
      searchPrefix,
      startItems,
      maxHeight,
      width,
      containerWidth,
      itemHeight,
      disabled
    } = this.props;
    const startItemsCount = startItems.count();
    const prefixItems = this.state.prefixCache.get(searchPrefix) || Immutable.Map();
    let itemCount;
    if (prefixItems.has("loadedItems")) {
      const loadedItemCount = startItems.count() + prefixItems.get("loadedItems").count();
      const hasMoreToLoad = prefixItems.get("loadedItems").count() < prefixItems.get("total");
      itemCount = hasMoreToLoad ? loadedItemCount + 1 : loadedItemCount;
    } else {
      itemCount = searchUrl ? startItems.count() + 1 : startItems.count();
    }

    const ItemComponent = ({index, style}) => {
      const {
        startItemStyle,
        startItemIcon,
        selectedItemIds,
        itemIcon,
        onItemClick,
        keys: [valueKey, labelKey, searchKey],
        disabled
      } = this.props;
      let itemCss = {
        ...style,
        padding: "0.2rem 0.8rem",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontSize: "13px"
      };
      let item;
      let icon;
      let onClick = () => onItemClick(item);
      if (index < startItemsCount) {
        itemCss = {...itemCss, ...startItemStyle};
        item = startItems.get(index);
        icon = startItemIcon;
      } else {
        const prefixItems = this.state.prefixCache.get(searchPrefix) || Immutable.Map();
        item = prefixItems.get("loadedItems", Immutable.Map()).get(index - startItemsCount);
        icon = itemIcon;
      }
      if (!item) {
        return <div css={itemCss}>{"Loading..."}</div>;
      } else if (selectedItemIds.has(item.get("id"))) {
        itemCss = {...itemCss, opacity: 0.5};
        onClick = () => {};
      } else {
        itemCss = {
          ...itemCss,
          cursor: "pointer",
          "&:hover": {
            color: this.props.theme.palette.text.inverted,
            background: `${this.props.theme.palette.primary.main} !important`
          }
        };
      }
      return <div className="TESTCAFE-infinite-list-item" css={itemCss} onClick={onClick}>
        {icon && <FontAwesomeIcon icon={icon} style={{marginRight: "0.5rem"}} />}
        <span>{item.get(labelKey)}</span>
      </div>;
    };

    const adjustedHeight = Math.min(itemCount * itemHeight, maxHeight);

    return <InfiniteLoader
        key={searchPrefix}
        isItemLoaded={index => {
          return index < startItemsCount ||
              prefixItems.get("loadedItems", Immutable.Map()).get(index - startItemsCount);
        }}
        itemCount={itemCount}
        loadMoreItems={this.loadItems}
    >
      {({onItemsRendered, ref}) => (
          <FixedSizeList
              className="List"
              height={adjustedHeight}
              itemCount={itemCount}
              itemSize={itemHeight}
              onItemsRendered={onItemsRendered}
              ref={ref}
              width={width || containerWidth}
          >
            {ItemComponent}
          </FixedSizeList>
      )}
    </InfiniteLoader>;
  },

  loadItems(startIndex, stopIndex) {
    const {searchPrefix, searchUrl, searchResultToItem, startItems} = this.props;
    stopIndex = startIndex === stopIndex ? stopIndex + 30 : stopIndex;
    const startItemsOffset = startItems.count();
    const offsetStartIndex = startIndex - startItemsOffset;
    const offsetStopIndex = stopIndex - startItemsOffset;

    if (searchUrl) {
      ajax.get({
        url: searchUrl,
        data: {
          namePrefix: searchPrefix,
          startIndex: offsetStartIndex,
          stopIndex: offsetStopIndex,
          ...this.props.searchParams
        }
      }).then(response => {
            const total = response.totalCount;
            const items = Immutable.fromJS(response.items).map(searchResultToItem);
            const indexedItems = items.toMap().mapKeys(key => key + offsetStartIndex);
            this.setState(state => {
              const prefixCache = state.prefixCache;
              const prefixedItems = prefixCache.get(searchPrefix) || Immutable.Map();
              const updatedItems = prefixedItems
                  .set("total", total)
                  .update("loadedItems", old => old ? old.merge(indexedItems) : indexedItems);
              return {prefixCache: prefixCache.set(searchPrefix, updatedItems)};
            });
          }
      );
    }


  }
}));

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <InfiniteList theme={theme} {...props} />;
};
