import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import { IconButton } from "js/common/views/inputs/buttons";
import InteractiveTable from "js/common/views/tables/interactive-table";
import TrendConfigSection from "js/charting/trend/advanced-charting/trend-config-section";
import ColorPicker from "js/common/views/inputs/color-picker";
import WhatToPlotPicker from "js/charting/trend/advanced-charting/what-to-plot-picker";
import HowToPlotPicker from "js/charting/trend/advanced-charting/how-to-plot-picker";
import LineStylePicker from "js/charting/trend/advanced-charting/line-style-picker";
import DataPointMarkerPicker from "js/charting/trend/advanced-charting/data-point-marker-picker";
import YAxisPositionPicker from "js/charting/trend/advanced-charting/y-axis-position-picker";
import { getAdvancedTrendSeriesName, getKpiTrendName } from "js/charting/trend/display-name-util";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const _ = window._;

const TrendConfig = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        trendConfigs: ImmutablePropTypes.list.isRequired,
        onTrendConfigChange: ReactPropTypes.func.isRequired,
        dataGroupName: ReactPropTypes.string.isRequired,
        dataGroupQualifierType: ReactPropTypes.string.isRequired,
        dataGroupQualifierId: ReactPropTypes.number.isRequired,
        isSmallScreen: ReactPropTypes.bool.isRequired,
        theme: ReactPropTypes.object
    },

    render() {
        return (
            <div style={{ width:"100%" }}>
                <div className="columns small-12">
                    { this.props.isSmallScreen ? this.getTrendConfigSections() : this.getTrendConfigTable() }
                </div>
            </div>);
    },

    getTrendConfigSections() {
        const { dataGroupQualifierType, dataGroupQualifierId } = this.props;
        return this.props.trendConfigs.map((trendConfig, index) => {
            const isFirstItem = index === 0;
            const id = trendConfig.get("cid");
            const whatToPlot = trendConfig.get("whatToPlot");
            const howToPlot = trendConfig.get("howToPlot");
            const ratio = trendConfig.get("ratio");
            const isKpiTrend = trendConfig.get("type") === "KPI";
            const name = isKpiTrend ?
                getKpiTrendName(trendConfig.get("kpiId"), whatToPlot, dataGroupQualifierType, dataGroupQualifierId) :
                ratio.get("name");
            const rootTrendName = `${this.props.dataGroupName} - ${name}`;
            return (
                <TrendConfigSection
                    key={id}
                    trendConfig={trendConfig}
                    trendConfigOptions={this.getTrendConfigOptions(trendConfig)}
                    name={getAdvancedTrendSeriesName(rootTrendName, whatToPlot, howToPlot)}
                    expandedInitially={isFirstItem}
                    onDeleteTrendConfig={this.handleDeleteTrendConfig} />);
        });
    },

    getTrendConfigTable() {
        const columns = [
            {
                label: "Name",
                foundationClasses: "medium-3 clearfix"
            },
            {
                label: "What to Plot",
                foundationClasses: "medium-2"
            },
            {
                label: "How to Plot",
                foundationClasses: "medium-2"
            },
            {
                label: "Line Style",
                foundationClasses: "medium-1"
            },
            {
                label: "Markers",
                foundationClasses: "medium-1"
            },
            {
                label: "Colour",
                foundationClasses: "medium-1",
                textAlign: "center"
            },
            {
                label: "Y-Axis",
                foundationClasses: "medium-1"
            },
            {
                label: "Remove",
                foundationClasses: "medium-1",
                textAlign: "center"
            }
        ];
        const rows = this.props.trendConfigs.map(this.trendConfigToRow).toJS();
        return <InteractiveTable columns={columns} rows={rows} />;
    },

    trendConfigToRow(trendConfig) {
        const overflowStyle = {
            overflow: "inherit",
            whiteSpace: "inherit"
        };
        const trendConfigOptions= this.getTrendConfigOptions(trendConfig);
        const { dataGroupQualifierType, dataGroupQualifierId, theme } = this.props;
        const id = trendConfig.get("cid");
        const whatToPlot = trendConfig.get("whatToPlot");
        const howToPlot = trendConfig.get("howToPlot");
        const seriesColour = trendConfig.get("seriesColour");
        const ratio = trendConfig.get("ratio");
        const isKpiTrend = trendConfig.get("type") === "KPI";
        const name = isKpiTrend ?
            getKpiTrendName(trendConfig.get("kpiId"), whatToPlot, dataGroupQualifierType, dataGroupQualifierId) :
            ratio.get("name");
        const rootTrendName = `${this.props.dataGroupName} - ${name}`;
        const cells = [
            <div key={`series-name-${id}`} style={overflowStyle}>
                <span style={{ color: seriesColour || theme.palette.textColor }}>
                    { getAdvancedTrendSeriesName(rootTrendName, whatToPlot, howToPlot) }
                </span>
            </div>,
            <div key={`what-to-plot-${id}`} style={overflowStyle}>
                {trendConfigOptions.whatToPlot}
            </div>,
            <div key={`how-to-plot-${id}`} style={overflowStyle}>
                {trendConfigOptions.howToPlot}
            </div>,
            <div key={`line-style-${id}`} style={overflowStyle}>
                {trendConfigOptions.lineStyle}
            </div>,
            <div key={`data-point-marker-${id}`} style={overflowStyle}>
                {trendConfigOptions.dataPointMarkers}
            </div>,
            <div key={`series-colour-${id}`} style={{ ...overflowStyle, paddingRight: 5 }}>
                {trendConfigOptions.seriesColour}
            </div>,
            <div key={`y-axis-pos-${id}`} style={overflowStyle}>
                {trendConfigOptions.yAxisPosition}
            </div>,
            <div key={`remove-row-${id}`} style={overflowStyle} className="text-center">
                <IconButton
                    type="dark"
                    hoverType="alert"
                    icon="times"
                    onClick={() => this.handleDeleteTrendConfig(id)}
                    size="large" />
            </div>
        ];
        cells.id = id;
        return cells;
    },

    getTrendConfigOptions(trendConfig) {
        const type = trendConfig.get("type");
        const whatToPlot = trendConfig.get("whatToPlot");
        const howToPlot = trendConfig.get("howToPlot");
        const lineStyle = trendConfig.get("lineStyle");
        const seriesColour = trendConfig.get("seriesColour");
        const dataPointMarker = trendConfig.get("dataPointMarker");
        const yAxis = trendConfig.get("yAxis");
        return {
            whatToPlot:
                <WhatToPlotPicker
                    trendType={type}
                    onChange={whatToPlot => this.handleWhatToPlotChange(trendConfig, whatToPlot)}
                    qualifierType={this.props.dataGroupQualifierType}
                    value={whatToPlot} />,
            howToPlot:
                <HowToPlotPicker
                    trendType={type}
                    onChange={howToPlot => this.handleTrendConfigChange(trendConfig.set("howToPlot", howToPlot))}
                    whatToPlot={whatToPlot}
                    howToPlot={howToPlot} />,
            lineStyle:
                <LineStylePicker
                    onChange={lineStyle => this.handleTrendConfigChange(trendConfig.set("lineStyle", lineStyle))}
                    value={lineStyle}
                    color={seriesColour} />,
            dataPointMarkers:
                <DataPointMarkerPicker
                    onChange={dataPointMarker => this.handleDataPointMarkerChange(trendConfig, dataPointMarker)}
                    value={dataPointMarker || "none"}
                    color={seriesColour} />,
            seriesColour:
                <ColorPicker
                    initialColor={seriesColour}
                    onChange={seriesColour => this.handleSeriesColourChange(trendConfig, seriesColour)} />,
            yAxisPosition:
                <YAxisPositionPicker
                    onChange={yAxis => this.handleTrendConfigChange(trendConfig.set("yAxis", yAxis))}
                    value={yAxis}
                    isDisabled={whatToPlot === "percentage-target"} />
        };
    },

    handleWhatToPlotChange(trendConfig, whatToPlot) {
        const howToPlot = trendConfig.get("howToPlot");
        const yAxis = trendConfig.get("yAxis");
        const lineStyle = trendConfig.get("lineStyle");
        const newTrendConfig = trendConfig
            .set("whatToPlot", whatToPlot)
            .set("howToPlot", updateHowToPlotValueBasedOnWhatToPlot(whatToPlot, howToPlot))
            .set("yAxis", updateYAxisBasedOnWhatToPlot(whatToPlot, yAxis))
            .set("lineStyle", updateLineStyleBasedOnWhatToPlot(whatToPlot, lineStyle));
        this.handleTrendConfigChange(newTrendConfig);
    },

    handleDataPointMarkerChange(trendConfig, dataPointMarker) {
        const newDataPointMarker = dataPointMarker === "none" ? null : dataPointMarker;
        this.handleTrendConfigChange(trendConfig.set("dataPointMarker", newDataPointMarker));
    },

    handleSeriesColourChange(trendConfig, seriesColour) {
        const newTrendConfig = trendConfig
            .set("seriesColour", seriesColour)
            .set("hasCustomColor", seriesColour ? true : false);
        this.handleTrendConfigChange(newTrendConfig);
    },

    handleTrendConfigChange(trendConfig) {
        const { trendConfigs, onTrendConfigChange } = this.props;
        const index = trendConfigs.findIndex(tc => tc.get("cid") === trendConfig.get("cid"));
        onTrendConfigChange(trendConfigs.set(index, trendConfig));
    },

    handleDeleteTrendConfig(trendConfigId) {
        const { trendConfigs, onTrendConfigChange } = this.props;
        const index = trendConfigs.findIndex(tc => tc.get("cid") === trendConfigId);
        onTrendConfigChange(trendConfigs.delete(index));
    }

});

const updateYAxisBasedOnWhatToPlot = (whatToPlot, yAxis) => {
    if (whatToPlot === "percentage-target") {
        return 2;
    } else if (yAxis === 2) {
        return 1;
    } else if (yAxis) {
        return yAxis;
    }
    return 0;
};

const updateHowToPlotValueBasedOnWhatToPlot = (whatToPlot, howToPlot) => {
    if (whatToPlot === "percentage-target" && (_.isUndefined(howToPlot) || howToPlot.indexOf("cumulative") > -1)) {
        return "EXPECTED_TARGET";
    }
    if (whatToPlot !== "percentage-target" && (_.isUndefined(howToPlot) || howToPlot.indexOf("cumulative") === -1)) {
        return "non-cumulative";
    }
    return howToPlot;
};

const updateLineStyleBasedOnWhatToPlot = whatToPlot => {
    switch (whatToPlot) {
    case "target":
        return "ShortDot";
    case "percentage-target":
        return "ShortDash";
    default:
        return "Solid";
    }
};


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <TrendConfig theme={theme} {...props} />;
};
  
export default Wrapper;