import * as numbers from "js/common/utils/numbers";
import * as Formatter from "js/common/utils/formatter";

const formatters = {
    "ABSOLUTE": absolute,
    "DECIMAL": decimal,
    "FRACTION": fraction,
    "PERCENTAGE": percentage,
    "NORMALISED": normalised
};

function format(type, first, second) {
    const formatter = formatters[type.toUpperCase()];
    return formatter(first, second);
}

function absolute(first, second) {
    return formatValue(first) + " : " + formatValue(second);
}

function decimal(first, second) {
    if (first.value === 0 || second.value === 0) {
        return "0";
    }
    return numbers.roundTo(second.value / first.value, 2).toString();
}

function percentage(first, second) {
    if (first.value === 0 || second.value === 0) {
        return "0%";
    }
    return numbers.toPercentageStr(second.value / first.value, 0);
}

function normalised(first, second) {
    if (first.value === 0 && second.value === 0) {
        return "0 : 0";
    } else if (first.value === 0) {
        return `0 : ${formatValue(second)}`;
    } else if (second.value === 0) {
        return `${formatValue(first)} : 0`;
    }

    let { numerator, denominator } = numbers.normaliseFraction(first.value, second.value);
    first = clone(first);
    second = clone(second);
    first.value = numerator;
    second.value = denominator;

    return `${formatValue(first)} : ${formatValue(second)}`;
}

function fraction(first, second) {
    if (first.value === 0 && second.value === 0) {
        return "0 / 0";
    } else if (first.value === 0) {
        return `0 / ${formatValue(second)}`;
    } else if (second.value === 0) {
        return `${formatValue(first)} / 0`;
    }

    let { numerator, denominator } = numbers.simplifyFraction(first.value, second.value);
    first = clone(first);
    second = clone(second);
    first.value = numerator;
    second.value = denominator;
    return `${formatValue(first)} / ${formatValue(second)}`;
}

const clone = wrapper => ({ ...wrapper });

const formatValue = wrapper => {
    return Formatter.format(wrapper, {maxDisplayLength: 7})
};

export {
    format,
    absolute,
    decimal,
    fraction,
    normalised,
    percentage
};
