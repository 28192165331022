import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ReactDOM from "react-dom";
import moment from "moment";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";

import pure from "js/common/views/pure";
import ErrorMsg from "js/common/views/error";
import Info from "js/common/views/info-text-box";
import NoteTextArea from "js/oneview/targets/note-text-area";
import {TextButton} from "js/common/views/inputs/buttons";
import CloseButton from "js/common/views/inputs/close-button";
import {parseDateTime} from "js/common/utils/time";
import * as Users from "js/common/users";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const $ = window.$;

const TargetNotes = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    viewType: ReactPropTypes.string.isRequired,
    targetId: ReactPropTypes.number.isRequired,
    notes: ImmutablePropTypes.list.isRequired,
    onCloseRequest: ReactPropTypes.func.isRequired,
    onAddNoteClick: ReactPropTypes.func,
    isUpdating: ReactPropTypes.bool,
    error: ReactPropTypes.string
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.targetId !== this.props.targetId
        || nextProps.notes !== this.props.notes
        || (nextProps.viewType === "SIMPLE" && this.props.viewType === "EDIT")) {
      this.setState({
        newNote: "",
        showSavingNotesInEditModeWarning: nextProps.notes !== this.props.notes && this.props.viewType === "EDIT"
      });
    }
  },

  getInitialState() {
    return {
      newNote: "",
      showSavingNotesInEditModeWarning: false
    };
  },

  componentDidMount() {
    if (this.props.notes.isEmpty()) {
      return;
    }
    this.scrollToLatestNote();
  },
  render() {
    const loggedInUser = Users.getCurrentUser();
    const {newNote, showSavingNotesInEditModeWarning} = this.state;
    const {theme, notes, error, isUpdating} = this.props;
    return (
        <div>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <h3 style={{fontSize: "1rem", color: theme.palette.primary.main, textTransform: "uppercase"}}>
              Notes
            </h3>
            <div className="right">
              <CloseButton onClick={this.handleCloseClick} />
            </div>
          </div>
          <div className="target-notes-list">
            <div>
              {notes.isEmpty() && this.renderEmptyStateView()}
              {notes
                  .sortBy(note => parseDateTime(note.get("createdDate")))
                  .map((noteDetails, i) => {
                    const isLastNote = i === notes.count() - 1;
                    return <Note key={i} noteDetails={noteDetails} isLast={isLastNote} theme={theme}/>;
                  })}
            </div>
            {Users.canAccessApp(loggedInUser,"TARGET_ADMIN") &&
            <div>
              {showSavingNotesInEditModeWarning &&
              <Info
                  text="Cancelling target changes will also discard any new notes added"
                  style={{backgroundColor: theme.themeId === "light" ? theme.palette.background.default : "#383228", borderColor: "#ffa500"}} />}
              <NoteTextArea value={newNote} onChange={this.handleNewNoteChange}
                            customStyle={{backgroundColor: theme.palette.background.card, color: theme.palette.textColor}} />
              {error && <ErrorMsg text={error} />}
              <TextButton
                  type="primary"
                  label={<span>Add Note {isUpdating && <i className="fa fa-spinner fa-pulse" />}</span>}
                  onClick={this.handleAddNoteClick}
                  disabled={isUpdating} />
            </div>}
          </div>
        </div>);
  },

  renderEmptyStateView() {
    return (
        <div style={{fontSize: "0.9rem", color: "#bbb", paddingTop: "1rem", paddingBottom: "1rem"}}>
          No notes for this target so far
        </div>
    );
  },

  handleNewNoteChange(newNote) {
    this.setState({newNote});
  },

  handleCloseClick() {
    this.setState(this.getInitialState());
    this.props.onCloseRequest();
  },

  handleAddNoteClick() {
    const {newNote} = this.state;
    if (!newNote || newNote === "") {
      return;
    }
    if (this.props.viewType === "EDIT") {
      this.setState({showSavingNotesInEditModeWarning: true});
    } else {
      this.setState({showSavingNotesInEditModeWarning: false});
    }
    const {targetId, onAddNoteClick} = this.props;
    const MAX_CHAR_LIMIT = 8192;
    onAddNoteClick(targetId, newNote.substring(0, MAX_CHAR_LIMIT));
  },

  scrollToLatestNote() {
    const $this = $(ReactDOM.findDOMNode(this));
    const $notesList = $this.find(".target-notes-list");
    const $lastNote = $this.find("#last-note");
    const offset = $lastNote.offset().top;
    const isLastNoteOutsideOfView = offset >= $notesList.innerHeight();
    if ($lastNote.length && isLastNoteOutsideOfView) {
      $notesList.scrollTop(offset);
    }
  }

});

const Note = pure(({noteDetails, isLast, theme}) => {
  const DIVIDER_STYLE = `1px solid ${theme.palette.text.secondary}`;
  const isAutomatedNote = noteDetails.get("automatedNote");
  const createdBy = getUserName(noteDetails.get("userId"));
  const createdOn = noteDetails.has("createdDate") ? `on ${formatDate(noteDetails.get("createdDate"))}` : "";
  const noteContainerStyle = {
    paddingBottom: "0.5rem",
    marginTop: "0.5rem",
    marginBottom: !isLast ? "1rem" : 0,
    borderBottom: !isLast && DIVIDER_STYLE
  };
  const noteStyle = {
    padding: "0.5rem",
    backgroundColor: theme.palette.background.paper,
    border: isAutomatedNote ? "none" : "1px solid #008faa",
    borderRadius: "3px",
    fontSize: "0.9rem",
    color: isAutomatedNote ? theme.palette.text.primary : theme.palette.text.secondary,
    lineHeight: 1.25
  };
  const createdOnLabelStyle = {
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
    lineHeight: 1.25,
    padding: "0.5rem"
  };

  return (
      <div id={isLast ? "last-note" : ""} style={noteContainerStyle}>
        <div style={noteStyle}>
          {renderNoteText(noteDetails.get("note"))}
        </div>
        <div style={createdOnLabelStyle}>
          {`Added by ${createdBy} ${createdOn}`}
        </div>
      </div>);
});

const renderNoteText = note => {
  note = localiseDates(note);
  return note.split("\n").map((text, index) => <span key={index}>{text}<br /></span>);
};

const localiseDates = noteStr => noteStr.replace(/DATE\([\d-]+\)/g, val => moment(val, "YYYY-MM-DD").format("L"));

const getUserName = userId => Users.getUser(userId).get("fullName");

const formatDate = (
    dateStr,
    dateStrFormat = "YYYY-MM-DD HH:mm:ss",
    displayFormat = "lll"
) => moment(dateStr, dateStrFormat).format(displayFormat);


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <TargetNotes theme={theme} {...props} />;
};

TargetNotes.displayName = "TargetNotes";
export default Wrapper;
