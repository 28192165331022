/** @jsxImportSource @emotion/react */

import React, {memo, useEffect, useState} from "react";
import Immutable from "immutable";
import moment from "moment";
import {Global, css} from "@emotion/react";

import currentClient from "js/common/repo/backbone/current-client";

import LoadingSpinner from "js/common/views/loading-spinner";
import AdminHeader from "js/admin/common/admin-header";

import * as Ajax from "js/common/ajax";

const getActivity = () => {
  return Ajax
      .get({url: "etl/activity"})
      .then(res => {
        const parsedRes = Immutable.fromJS(res).map((value, key) => {
          if (key === "countOfBulksInLast7Days") {
            return value;
          } else if (value !== null) {
            return moment.utc(value).local();
          } else {
            return null;
          }
        });
        return parsedRes;
      });
};

const ActivityData = memo(({activityData}) => {
  return <>
    <Global
        styles={css`
          table, td {
            border: 1px solid black;
            border-collapse: collapse;
          }

          td {
            padding: 8px!important;
          }
        `} />

    <AdminHeader>Recent ETL delta job timings</AdminHeader>

    <table style={{margin: "1rem"}}>
      {currentClient.get("crmId") !== 31 ?
          <tbody>
          <tr>
            <td>We last tried to load data at</td>
            <td>{formatDate(activityData.get("lastDelta"))}</td>
          </tr>
          <tr>
            <td>We last found new data in your CRM at</td>
            <td>{formatDate(activityData.get("lastDeltaWithData"))}</td>
          </tr>
          </tbody> :
          <tbody>
          <tr>
            <td>Data guaranteed complete up until</td>
            <td>{formatDate(maxDate(activityData.get("minDateCovered"), activityData.get("lastCatchUpBulkStart")))}</td>
          </tr>
          <tr>
            <td>We last found new data in your CRM at</td>
            <td>{formatDate(maxDate(activityData.get("lastDeltaWithData"), activityData.get("lastCatchUpBulkStart")))}</td>
          </tr>
          <tr>
            <td>We last tried to load data via the delta process at</td>
            <td>{formatDate(activityData.get("lastDelta"))}</td>
          </tr>
          <tr>
            <td>We last found data via the delta process at</td>
            <td>{formatDate(activityData.get("lastDeltaWithData"))}</td>
          </tr>
          <tr>
            <td>The most recent time our integration can load data via the delta process is</td>
            <td>{formatDate(activityData.get("minDateCovered"))}</td>
          </tr>
          <tr>
            <td>The last time we did a catch up bulk load was
            </td>
            <td>{formatDate(activityData.get("lastCatchUpBulkStart"))}</td>
          </tr>
          <tr>
            <td>Of which was completed at
            </td>
            <td>{formatDate(activityData.get("lastCatchUpBulkEnd"))}</td>
          </tr>
          <tr>
            <td>Number of catch up bulk loads done in the last 7 days</td>
            <td>{activityData.get("countOfBulksInLast7Days")}</td>
          </tr>
          </tbody>
      }
    </table>
  </>;
});

const formatDate = date => {
  return date ? date.format("L LTS") : "No Data Found";
};

const maxDate = (date1, date2) => {
  if (date1 && date2) {
    return date1.isAfter(date2) ?
        date1 : date2;
  } else if (date1) {
    return date1;
  } else if (date2) {
    return date2;
  } else {
    return null;
  }
};

const DataExtraction = memo(() => {
  const [activity, setActivity] = useState();

  useEffect(() => {
    getActivity().then(act => setActivity(act));
  }, []);

  return activity
      ? <ActivityData activityData={activity} />
      : <LoadingSpinner />;
});

export default DataExtraction;
