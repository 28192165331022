import React from "react";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import createReactClass from "create-react-class";
import Select from "js/common/views/inputs/immutable-react-select";
import Immutable from "immutable";
import Layout from "js/common/views/foundation-column-layout";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import InheritedFromGroupInfo from "js/admin/inherited-from-group-info";
import * as Groups from "js/common/groups";
import Checkbox from 'js/common/views/inputs/checkbox';

const PagePicker = createReactClass({

  propTypes: {
    isUserContext: ReactPropTypes.bool,
    pages: ImmutablePropTypes.list.isRequired,
    userOrGroup: ImmutablePropTypes.map.isRequired,
    onChange: ReactPropTypes.func.isRequired,
    columnStyle: ReactPropTypes.object,
    theme: ReactPropTypes.object
  },

  getDefaultProps() {
    return {
      isUserContext: false
    };
  },

  render() {
    const {
      pages,
      isUserContext,
      userOrGroup,
      onChange,
      columnStyle,
      theme
    } = this.props;
    const inheritsPage = isUserContext ?
        !userOrGroup.get("pageId") :
        userOrGroup.get("inheritedPageGroupId") !== userOrGroup.get("id");
    const group = isUserContext ? Groups.getGroup(userOrGroup.get("groupId")) : userOrGroup;
    const isRootGroup = !isUserContext && !group.get("parentId");
    const pageId = inheritsPage ? group.get("pageId") : userOrGroup.get("pageId");
    const assumePageDeleted = !pages.some(page => page.get("id") === pageId);
    const page = assumePageDeleted ? null : pages.find(page => page.get("id") === pageId);
    const pageOptions = renderPageOptions(pages);

    return (
        <div>
          <Layout allSmall={12} allMedium={[5]} columnStyle={columnStyle} floatLastColumnLeft={true}>
            {inheritsPage ?
                <span style={textStyle}>{page ? page.get("name") : "Page not found"}</span> :
                <Select
                    placeholder={"Select a Page"}
                    options={pageOptions}
                    isClearable={false}
                    isMulti={false}
                    isSearchable={false}
                    selectedValue={page.get("id")}
                    onChange={(value) => onChange({pageId: value})}/>
            }
            <Checkbox
                label="Inherit from Parent Group"
                disabled={isRootGroup}
                style={{marginTop: -4}}
                checked={inheritsPage}
                onCheck={(e, isChecked) => this.onInheritPageChange(isChecked, pageId)}/>
          </Layout>

          {inheritsPage &&
          <Layout allSmall={12} columnStyle={columnStyle}>
            <InheritedFromGroupInfo originalGroupId={group.get("inheritedPageGroupId")} theme={theme}/>
          </Layout>}
        </div>);
  },

  onInheritPageChange(isChecked, currentPageId) {
    if (isChecked) {
      this.props.onChange({pageId: null});
    } else {
      this.props.onChange({pageId: currentPageId});
    }
  }

});

const renderPageOptions = pages => {
      return pages
          .filter(page => !page.get("isDeleted"))
          .map(page => (Immutable.Map({
            label: page.get("name"),
            value: page.get("id")
          })))
    }
;

const textStyle = {
  fontSize: "0.9rem",
  height: 34,
  lineHeight: "34px"
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <PagePicker theme={theme} {...props} />;
};
