import * as kpiCalculator from "js/common/kpi-calculator";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";

export const configChangeReloadForLeaderboard = (oldConfig, newConfig, configChanges, oldData) => {
	return fullReloadForLeaderboard(newConfig);
};

export const fullReloadForLeaderboard = config => {
	const options = {
        timeframe: timeframeRepo.parse(config.timeframe),
        qualifierType: "GROUP",
        qualifierId: config.groupId
	};
	const promiseForFinalData = kpiCalculator
		.leaderboard(config.kpiId, options)
		.then(response => toData(false, response));
	return {
		initialData: toData(true),
		promiseForFinalData
	};
};

const toData = (isLoading, users = []) => ({
	isLoading,
	users
});
