import React from "react";
import Immutable from "immutable";
import store from "store";
import pure from "js/common/views/pure";

import * as Users from "js/common/users";
import { formatMillis, getChannelRuntime } from "js/admin/cubetv/channel-time-utils";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import { IconButton } from "js/common/views/inputs/buttons";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import Cube19 from "js/cube19.app";

// NOTE - Mingle ticket #3549: (Big Screen) Carousels === (cubeTV) Channels

const durationToMillis = durationStr => {
    return durationStr
        .split(" ")
        .map(part => {
            const number = part.substring(0, part.length - 1);
            if (part.indexOf("h") !== -1) {
                return number * 3600000;
            } else if (part.indexOf("m") !== -1) {
                return number * 60000;
            } else if (part.indexOf("s") !== -1) {
                return number * 1000;
            } else {
                throw new Error("Unsupported duration format", durationStr);
            }
        })
        .reduce((total, val) => total + val, 0);
};

const columns = [
  {
    label: "Name",
    sortMapper: cell => cell.sortValue,
    displayMapper: cell => cell.displayLabel
  },{
    label: "No. of Slides",
    width: 110
  },
  {
    label: "Running Time",
    sortMapper: durationToMillis
  },
  "Last Modified",
  "Created By",
  {
    label: "Actions",
    sortMapper: () => {},
    width: 200,
    align: "center"
  }
];


const ChannelTable = pure(({
    channels,
    onChannelClick,
    onCloneChannelClick,
    onDeleteChannelClick,
    theme
}) => (
    <SimpleDataTable
        columns={columns}
        initialSortBy={0}
        rows={channels.map(channel => channelToRow(theme, channel, onCloneChannelClick, onDeleteChannelClick)).toArray()}
        onCellClick={(cell, row) => onChannelClick(row.id)} />), "ChannelsTable");

const getUnsavedTitle = (theme, name) => (
    <div>
        <span style={{color:theme.palette.primary.main}}>
            !&nbsp;<i className="fa fa-floppy-o" aria-hidden="true"></i>&nbsp;
        </span>
        <span>{name}</span>
    </div>
);

const channelToRow = (theme, channel, onCloneChannelClick, onDeleteChannelClick) => {
    const currentUser = Users.getCurrentUser();
    const locallyStoredChannel = store.get("user-" + currentUser.id + "-car-" + channel.get("id"));
    if (locallyStoredChannel) {
        channel = Immutable.fromJS(locallyStoredChannel);
    }
    const name = channel.get("name");
    const numberOfSlides = channel.get("json").get("pages").count() + "";   // sortMapper expects a String value
    const createdByUserFullName = Users.getUser(channel.get("createdByUserId")).get("fullName");
    const id = channel.get("id");
    const row = [
        {
            sortValue: name,
            displayLabel: locallyStoredChannel ? getUnsavedTitle(theme, name) : name
        },
        numberOfSlides,
        formatMillis(getChannelRuntime(channel)),
        channel.get("lastModified"),
        createdByUserFullName,
        <div key="actions">
            <IconButton
                size="small"
                icon="clone"
                type="bright"
                hoverType="bright"
                title="Clone"
                onClick={ e => { e.stopPropagation(); onCloneChannelClick(id); } } />
            <IconButton
                icon="trash"
                size="small"
                type="bright"
                hoverType="alert"
                title="Delete"
                onClick={ e => { e.stopPropagation(); onDeleteChannelClick(id); } } />
        </div>
    ];
    row.id = id;
    return row;
};

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <ChannelTable theme={theme} {...props} />;
};