import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";

import RatioPicker from "js/common/views/inputs/ratio-picker";
import GroupUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import TagFilter from "js/common/views/inputs/tag-picker/immutable-tag-filter";
import ClientFilter from "js/common/views/inputs/organisation-picker/react-organisation-picker";
import NonClientFilterableKpisInfoDialog
  from "js/common/views/inputs/organisation-picker/non-client-filterable-kpis-info-dialog";
import KpiPicker from "js/common/views/kpis/custom-kpi-picker";
import TrendConfigs from "js/charting/trend/advanced-charting/trend-configs";
import ThinSectionHeader from "js/common/views/thin-section-header";
import {getDefaultColour} from "js/charting/trend/target-trend-color-util";
import getColumnWidthClassesBasedOnContainerWidth from "js/common/utils/column-width-classes-generator";
import currentClient from "js/common/repo/backbone/current-client";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as ratioRepo from "js/common/repo/ratio-repo";
import * as Users from "js/common/users";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const DataGroup = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    dataGroup: ImmutablePropTypes.map.isRequired,
    onDataGroupChange: ReactPropTypes.func.isRequired,
    isSmallScreen: ReactPropTypes.bool.isRequired
  },

  getInitialState() {
    return {
      isNonClientFilterableKpisInfoDialogOpen: false
    };
  },

  render() {
    const {dataGroup, onDataGroupChange, isSmallScreen} = this.props;
    const isClientFilterActive = dataGroup.get("clientIds") && !dataGroup.get("clientIds").isEmpty();
    const trendConfigs = dataGroup.get("trendConfigs");
    const timeframes = getTimeframes();
    const timeframe = dataGroup.get("timeframe");
    const visibleTimeframes = timeframes
        .filter(t => (t.get("visible") && !t.get("isDeleted")) || t.get("id") === timeframe.get("type"));
    const columnClasses = getColumnWidthClassesBasedOnContainerWidth(2, isSmallScreen);
    const columnSpacing = {
      marginBottom: "0.5rem",
      marginTop: "0.5rem"
    };

    return (
        <div style={{paddingLeft: "0rem", paddingRight: "0rem", paddingTop: "0.5rem", paddingBottom: "0.35rem"}}>
          <ThinSectionHeader icon="line-chart" label="Add Metrics or Ratios" />
          <section className="page-section row collapse" style={{margin: "0.5rem 1rem"}}>
            <div className={`columns ${isSmallScreen ? "small-12" : "medium-5"}`} style={columnSpacing}>
              <KpiPicker
                  label="Add Metric"
                  kpis={getKpis()}
                  onKpiSelect={this.handleKpiSelect}
                  closeOnSelect={false}
                  disableNonClientFilterableKpis={isClientFilterActive} />
            </div>
            <div className={`text-center columns ${isSmallScreen ? "small-12" : "medium-1"}`}
                 style={{height: "35px", lineHeight: "35px", ...columnSpacing}}>
              OR
            </div>
            <div className={`columns ${isSmallScreen ? "small-12" : "medium-6"}`} style={columnSpacing}>
              <RatioPicker
                  label="Add Ratio"
                  ratios={getRatios().toList().filter(r => !r.get("isDeleted"))}
                  kpis={getRatioKpis()}
                  onRatioSelect={this.handleRatioSelect}
                  closeOnSelect={false} />
            </div>
            {!trendConfigs.isEmpty() &&
            <div style={{width: "100%", margin: "1em 0"}} className="clearfix">
              <TrendConfigs
                  dataGroupName={dataGroup.get("name")}
                  dataGroupQualifierType={dataGroup.get("qualifierType")}
                  dataGroupQualifierId={getQualifierId(dataGroup.get("qualifierType"), dataGroup.get("qualifierId"))}
                  trendConfigs={trendConfigs}
                  onTrendConfigChange={this.handleTrendConfigChange}
                  isSmallScreen={isSmallScreen} />
            </div>
            }
          </section>

          <ThinSectionHeader icon="group" label="Choose a User or Group" />
          <section className="page-section row collapse" style={{margin: "0.5rem 1rem"}}>
            <div className={columnClasses} style={columnSpacing}>
              <GroupUserPicker
                  showLoggedInUserOptions={true}
                  qualifierId={dataGroup.get("qualifierId")}
                  qualifierType={dataGroup.get("qualifierType")}
                  onGroupClick={this.handleChangeGroup}
                  onUserClick={this.handleChangeUser} />
            </div>
          </section>

          <ThinSectionHeader icon="clock-o" label="Choose a Timeframe" />
          <section className="page-section row collapse" style={{margin: "0.5rem 1rem"}}>
            <div className={columnClasses} style={columnSpacing}>
              <TimeframePicker
                  timeframes={visibleTimeframes}
                  timeframe={getTimeframe(timeframe)}
                  onChange={timeframe => {
                    onDataGroupChange(dataGroup.set("timeframe", Immutable.fromJS(timeframe.getRawJson())));
                  }} />
            </div>
          </section>

          {currentClient.canAccessApp("TAGS_ADMIN") && this.renderTagFilters()}
          {currentClient.hasPermission("ORGANISATION_FILTER") && this.renderClientFilter()}
        </div>
    );
  },

  handleTrendConfigChange(trendConfigs) {
    this.props.onDataGroupChange(this.props.dataGroup.set("trendConfigs", trendConfigs));
  },

  handleKpiSelect(kpiId) {
    const {dataGroup, onDataGroupChange} = this.props;
    const trendConfigs = dataGroup.get("trendConfigs");
    const newTrendConfig = Immutable.fromJS({
      cid: Math.random(),
      type: "KPI",
      kpiId: kpiId,
      yAxis: 0,
      whatToPlot: "value",
      howToPlot: "non-cumulative",
      seriesColour: this.getSeriesColour(trendConfigs.count(), dataGroup.get("dataGroupIndex"))
    });
    onDataGroupChange(dataGroup.set("trendConfigs", trendConfigs.push(newTrendConfig)));
  },

  handleRatioSelect(ratio) {
    const {dataGroup, onDataGroupChange} = this.props;
    const trendConfigs = dataGroup.get("trendConfigs");
    const newTrendConfig = Immutable.fromJS({
      cid: Math.random(),
      type: "RATIO",
      ratio: ratio,
      yAxis: 0,
      whatToPlot: "value",
      howToPlot: "non-cumulative",
      seriesColour: this.getSeriesColour(trendConfigs.count(), dataGroup.get("dataGroupIndex"))
    });
    onDataGroupChange(dataGroup.set("trendConfigs", trendConfigs.push(newTrendConfig)));
  },

  getSeriesColour(index, dataGroupIndex) {
    const trendConfigs = this.props.dataGroup.get("trendConfigs");
    const coloursInUse = trendConfigs.map(trendConfig => trendConfig.get("seriesColour"));
    let seriesColour = getDefaultColour(index, dataGroupIndex, this.props.chartTheme);
    while (coloursInUse.indexOf(seriesColour) > -1) {
      return this.getSeriesColour(index + 1, dataGroupIndex);
    }
    return seriesColour;
  },

  renderTagFilters() {
    const {dataGroup, onDataGroupChange, isSmallScreen} = this.props;
    const columnWidthClasses = getColumnWidthClassesBasedOnContainerWidth(3, isSmallScreen);
    const columnLayoutClasses = `margin-top ${columnWidthClasses}`;
    const showIcon = false;
    const allowMultiple = true;

    return (
        <section className="page-section clearfix" style={{marginBottom: "1em"}}>
          <ThinSectionHeader icon="tags" label="Filter by tags" />

          <div style={{width: "100%"}}>
            <div className={columnLayoutClasses} style={{marginTop: "1rem"}}>
              <TagFilter
                  label="Match Any Tags"
                  showIcon={showIcon}
                  tagIds={dataGroup.get("matchAnyTagIds")}
                  onChange={matchAnyTagIds => {
                    onDataGroupChange(dataGroup.set("matchAnyTagIds", matchAnyTagIds));
                  }}
                  multiple={allowMultiple} />
            </div>
            <div className={columnLayoutClasses} style={{marginTop: "1rem"}}>
              <TagFilter
                  label="Match All Tags"
                  showIcon={showIcon}
                  tagIds={dataGroup.get("matchAllTagIds")}
                  onChange={matchAllTagIds => {
                    onDataGroupChange(dataGroup.set("matchAllTagIds", matchAllTagIds));
                  }}
                  multiple={allowMultiple} />
            </div>
            <div className={columnLayoutClasses} style={{marginTop: "1rem"}}>
              <TagFilter
                  label="Exclude Tags"
                  showIcon={showIcon}
                  tagIds={dataGroup.get("excludedTagIds")}
                  onChange={excludedTagIds => {
                    onDataGroupChange(dataGroup.set("excludedTagIds", excludedTagIds));
                  }}
                  multiple={allowMultiple} />
            </div>
          </div>
        </section>
    );
  },

  renderClientFilter() {
    const {dataGroup, onDataGroupChange, isSmallScreen, theme} = this.props;
    const nonClientFilterableKpis = this.getNonClientFilterableKpisInDataGroup();
    const hasNonClientFilterableKpis = nonClientFilterableKpis.length > 0;
    return (
        <section className="page-section clearfix" style={{marginBottom: "1rem"}}>
          <ThinSectionHeader icon="building" label="Filter by client" />

          <div style={{width: "100%"}}>
            <div className={`small-12 medium-6 columns table-${theme.themeId}`} style={{marginTop: "1rem"}}>
              <label>
                Clients
                {hasNonClientFilterableKpis &&
                <NonClientFilterableKpisSelectedWarning
                    theme={theme}
                    isSmallScreen={isSmallScreen}
                    onMoreInfoClick={this.openNonClientFilterableKpisInfoDialog} />}
              </label>
              <ClientFilter
                  bgColor="card"
                  clientIds={dataGroup.get("clientIds")}
                  onChange={clientIds => onDataGroupChange(dataGroup.set("clientIds", clientIds))}
                  isDisabled={hasNonClientFilterableKpis} />
              <NonClientFilterableKpisInfoDialog
                  isOpen={this.state.isNonClientFilterableKpisInfoDialogOpen}
                  onClose={this.closeNonClientFilterableKpisInfoDialog}
                  nonClientFilterableKpis={nonClientFilterableKpis} />
            </div>
          </div>
        </section>
    );
  },

  openNonClientFilterableKpisInfoDialog() {
    this.setState({
      isNonClientFilterableKpisInfoDialogOpen: true
    });
  },

  closeNonClientFilterableKpisInfoDialog() {
    this.setState({
      isNonClientFilterableKpisInfoDialogOpen: false
    });
  },

  handleChangeUser(userId) {
    const newTrendConfigs = this.props.dataGroup
        .get("trendConfigs")
        .map(config => {
          const whatToPlot = config.get("whatToPlot");
          const newWhatToPlot = whatToPlot === "average-by-user" ? "value" : whatToPlot;
          return config.set("whatToPlot", newWhatToPlot);
        });
    const newDataGroup = this.props.dataGroup
        .set("qualifierType", "USER")
        .set("qualifierId", userId)
        .set("trendConfigs", newTrendConfigs);
    this.props.onDataGroupChange(newDataGroup);
  },

  handleChangeGroup(groupId) {
    const newDataGroup = this.props.dataGroup
        .set("qualifierType", "GROUP")
        .set("qualifierId", groupId);
    this.props.onDataGroupChange(newDataGroup);
  },

  getNonClientFilterableKpisInDataGroup() {
    let nonClientFilterableKpis = [];
    this.props.dataGroup.get("trendConfigs").forEach(trendConfig => {
      if (trendConfig.get("type") === "KPI") {
        const kpi = kpiRepo.get(trendConfig.get("kpiId"));
        if (!kpi.get("filterableByClient")) {
          nonClientFilterableKpis.push(kpi);
        }
      }
    });
    return nonClientFilterableKpis;
  }

});

const NonClientFilterableKpisSelectedWarning = ({
  theme,
  isSmallScreen,
  onMoreInfoClick
}) => {
  const containerStyle = {color: theme.palette.warning.main};
  const iconStyle = {
    paddingRight: 5,
    paddingTop: isSmallScreen ? 5 : 0,
    paddingLeft: isSmallScreen ? 0 : 5
  };
  return (
      <span style={isSmallScreen ? {display: "block", ...containerStyle} : containerStyle}>
            <i className="fa fa-exclamation-triangle" style={iconStyle} />
            Non Client filterable Metric(s) selected &nbsp;
        <a onClick={onMoreInfoClick}>(more info)</a>
        </span>);
};

const isTrendableRatio = (ratio) => {
  const firstKpiIsTrendable = kpiRepo.get(ratio.get("firstKpiId")).get("trendable");
  const secondKpiIsTrendable = kpiRepo.get(ratio.get("secondKpiId")).get("trendable");

  return firstKpiIsTrendable && secondKpiIsTrendable;
};

const getRatios = () => ratioRepo.getAll().filter(ratio => isTrendableRatio(ratio));

const getKpis = () => Immutable.fromJS(kpiRepo.getTrendableKpis()).filter(kpi => kpi.get("visible"));

const getRatioKpis = () => getKpis().filter(kpi => kpi.get("trendable"));

const getTimeframes = () => {
  const singleDayTimeframes = new Set(["TODAY", "YESTERDAY"]);
  return timeframeRepo
      .getAll()
      .filter(timeframe => !singleDayTimeframes.has(timeframe.get("id")));
};

const getTimeframe = timeframe => timeframeRepo.parse(timeframe.toJS());

export const getQualifierId = (qualifierType, qualifierId) => {
  if (qualifierId) {
    return qualifierId;
  } else if (qualifierType === "GROUP" && qualifierId === 0) {
    return Users.getCurrentUser().get("groupId");
  } else if (qualifierType === "USER" && qualifierId === 0) {
    return Users.getCurrentUser().get("id");
  } else {
    throw new Error("Invalid qualifier ID supplied.");
  }
};


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <DataGroup theme={theme} {...props} />;
};

export default Wrapper;
