import UAParser from "ua-parser-js";

const ua = new UAParser();
const getUserAgent = () => ua;
const device = ua.getDevice();
const isMobile = () => device.type === "mobile";
const isTablet = () => device.type === "tablet";
const isDesktop = () => !isMobile() && !isTablet();

export {
    getUserAgent,
    isMobile,
    isTablet,
    isDesktop
};
