import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";

import { Row, Column } from "js/common/views/foundation-column-layout";
import { TextButton } from "js/common/views/inputs/buttons";

export default createReactClass({

    propTypes: {
        name: ReactPropTypes.string.isRequired,
        expandedInitially: ReactPropTypes.bool.isRequired,
        trendConfig: ReactPropTypes.object.isRequired,
        trendConfigOptions: ReactPropTypes.object.isRequired,
        onDeleteTrendConfig: ReactPropTypes.func.isRequired
    },

    getInitialState() {
        return {
            isExpanded: this.props.expandedInitially
        };
    },

    render() {
        const { isExpanded } = this.state;
        const { trendConfig } = this.props;
        const seriesColour = trendConfig.get("seriesColour");
        return (
            <div style={getSectionStyle(seriesColour)}>
                <Row collapse={true}
                    style={isExpanded ? { borderBottom: "1px solid #555", ...headerStyle } : headerStyle }
                    onClick={this.handleRowClick}>
                    <Column small={11}>
                        <h5 style={{ color: seriesColour, fontWeight: "normal", fontSize: "0.825rem" }}>
                            {this.props.name}
                        </h5>
                    </Column>
                    <Column small={1}>
                        <i className={`fa fa-chevron-${isExpanded ? "up" : "down"}`} style={iconButtonStyle} />
                    </Column>
                </Row>
                { isExpanded && this.getTrendConfig() }
            </div>);
    },

    handleRowClick() {
        this.setState({
            isExpanded: !this.state.isExpanded
        });
    },

    getTrendConfig() {
        const { trendConfig, trendConfigOptions, onDeleteTrendConfig } = this.props;
        const rowStyle = { marginTop: "0.5em" };
        return (
            <div style={{ padding: "0.5em" }}>
                <Row collapse={true}>
                    <Column small={5} style={labelStyle}>
                        What to Plot
                    </Column>
                    <Column small={7}>
                        {trendConfigOptions.whatToPlot}
                    </Column>
                </Row>
                <Row collapse={true} style={rowStyle}>
                    <Column small={5} style={labelStyle}>
                        How to Plot
                    </Column>
                    <Column small={7}>
                        {trendConfigOptions.howToPlot}
                    </Column>
                </Row>
                <Row collapse={true} style={rowStyle}>
                    <Column small={5} style={labelStyle}>
                        Line Style
                    </Column>
                    <Column small={7}>
                        {trendConfigOptions.lineStyle}
                    </Column>
                </Row>
                <Row collapse={true} style={rowStyle}>
                    <Column small={5} style={labelStyle}>
                        Markers
                    </Column>
                    <Column small={7}>
                        {trendConfigOptions.dataPointMarkers}
                    </Column>
                </Row>
                <Row collapse={true} style={rowStyle}>
                    <Column small={5} style={colourPickerLabelStyle}>
                        Colour
                    </Column>
                    <Column small={7}>
                        {trendConfigOptions.seriesColour}
                    </Column>
                </Row>
                <Row collapse={true} style={rowStyle}>
                    <Column small={5} style={labelStyle}>
                        Y-axis Position
                    </Column>
                    <Column small={7}>
                        {trendConfigOptions.yAxisPosition}
                    </Column>
                </Row>
                <Row collapse={true} style={rowStyle}>
                    <Column small={12} style={{ textAlign: "right" }}>
                        <TextButton
                            icon="times"
                            label="Remove"
                            onClick={() => onDeleteTrendConfig(trendConfig.get("cid"))} />
                    </Column>
                </Row>
            </div>);
    }

});

const getSectionStyle = color => ({
    border:"1px solid " + color,
    borderRadius:"3px",
    marginBottom:"2px"
});

const headerStyle = {
    padding:"5px",
    background:"#262626",
    cursor:"pointer"
};

const iconButtonStyle = {
    cursor: "pointer",
    color: "#fff",
    marginRight: 5,
    float: "right"
};

const labelStyle = {
    verticalAlign: "middle",
    lineHeight: "32px",
    fontSize: "0.875rem"
};

const colourPickerLabelStyle = {
    verticalAlign: "middle",
    lineHeight: "24px",
    fontSize: "0.875rem"
};
