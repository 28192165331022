import React from "react";
import {css, Global} from "@emotion/react";
import Immutable from "immutable";

import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Ajax from "js/common/ajax";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as Rata from "js/common/utils/remote-data";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import MenuBar from "js/common/views/menu-bar";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import UiGridTemplate from "js/dashboards/components/grids/ui-grid";
import NovoLoading from "js/dashboards/novo-loading";
import Error from "js/common/views/error";
import {FormControlLabel, Switch} from "@mui/material";

const UiGrid = UiGridTemplate.get("getReactComponent")();
// NOTE displayName is being defined mainly for testcafe to easily identify the grid has loaded without error
UiGrid.displayName = "UiGrid";

const defaultGridConfig = Immutable.fromJS({
  isDefault: true,
  downloadEnabled: true,
  disableCharting: true,
  gridProps: {pivotMode: false}
});

const globalCss = css`
  body, #content-region {
    height: 100%;
  }

  #content-region > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export default React.memo(() => {
  const [timeframe, setTimeframe] = React.useState(() => TimeframeRepo.getDefaultForClient());
  const [userIdToLabelToCountWrapper, setUserIdToLabelToCountWrapper] = React.useState(
      () => Rata.wrapInitialValue(Immutable.Map()));
  const [gridConfig, setGridConfig] = React.useState(defaultGridConfig);

  React.useEffect(() => {
    setGridConfig(gridConfig => gridConfig.set("fileName", getFilename(timeframe)));
    setUserIdToLabelToCountWrapper(Rata.toLoading);
    loadData(timeframe).then(
        userIdToLabelToCount => {
          setUserIdToLabelToCountWrapper(w => Rata.toLoaded(w, userIdToLabelToCount));
          setGridConfig(gridConfig => {
            if (gridConfig.get("isDefault")) {
              const uniqueActivityLabels = userIdToLabelToCount
                  .valueSeq()
                  .flatMap(labelToCount => labelToCount.keySeq())
                  .toSet()
                  .toList()
                  .sort();
              const labels = Immutable.List.of("User", "Group", "Status").concat(uniqueActivityLabels);
              const states = labels.map((label, index) => Immutable.Map({colId: label, hide: false, order: index + 1}));
              return gridConfig
                  .set("columnStates", states)
                  .delete("isDefault");
            } else {
              return gridConfig;
            }
          });
        },
        error => {
          setUserIdToLabelToCountWrapper(w => Rata.toError(w, "Unable to load usage data, try a shorter timeframe"));
        });
  }, [timeframe]);


  const handleGridConfigChange = React.useCallback((componentId, gridConfig) => {
    setGridConfig(gridConfig);
  }, []);

  const columns = React.useMemo(() => {
    if (Rata.isLoaded(userIdToLabelToCountWrapper)) {
      const userIdToLabelToCount = Rata.getValue(userIdToLabelToCountWrapper);
      const uniqueActivityLabels = userIdToLabelToCount
          .valueSeq()
          .flatMap(labelToCount => labelToCount.keySeq())
          .toSet();
      const labels = ["User", "Group", "Status"].concat(uniqueActivityLabels.toArray());
      return labels.map(label => ({label, derivedFor: [], columnFor: "UI"}));
    } else {
      return [];
    }
  }, [userIdToLabelToCountWrapper]);

  const rows = React.useMemo(() => {
    if (Rata.isLoaded(userIdToLabelToCountWrapper)) {
      const userIdToLabelToCount = Rata.getValue(userIdToLabelToCountWrapper);
      return userIdToLabelToCount
          .entrySeq()
          .map(([userId, labelToCount]) => {
            const user = Users.getUser(userId);
            return {
              User: {value: user.get("fullName")},
              Status: {value: Users.getType(user)},
              Group: {value: getBreadcrumbString(user)},
              ...(labelToCount.map(value => ({value})).toObject())
            };
          })
          .toArray();
    } else {
      return [];
    }
  }, [userIdToLabelToCountWrapper]);

  const [isEditing, setEditing] = React.useState(true);
  const downloadFnRef = React.useRef();

  let content;
  if (Rata.isLoaded(userIdToLabelToCountWrapper)) {
    content = <UiGrid
        downloadFnRef={downloadFnRef}
        isEditing={isEditing}
        data={Rata.wrapInitialValue({rows, columns})}
        config={gridConfig}
        onConfigChange={handleGridConfigChange} />;
  } else if (Rata.hasError(userIdToLabelToCountWrapper)) {
    content = <Error style={{width: 400}} text={Rata.getError(userIdToLabelToCountWrapper)} />;
  } else {
    content = <NovoLoading />;
  }

  const {theme} = React.useContext(CustomThemeContext);
  return <>
    <Global styles={globalCss} />
    <MenuBar appView="usage" />
    <div style={{
      display: "flex",
      justifyContent: "center",
      paddingTop: "1rem",
      backgroundColor: theme.palette.background.card
    }}>
      <div style={{width: 420, display: "flex"}}>
        <TimeframePicker timeframe={timeframe} onChange={setTimeframe} />
        <FormControlLabel
            label="Edit"
            style={{whiteSpace: "nowrap", marginLeft: "1rem"}}
            control={<Switch
                checked={isEditing}
                onChange={e => setEditing(e.target.checked)} />} />
      </div>
    </div>
    <div style={{
      position: "relative",
      flex: 1,
      width: "100%",
      overflow: "hidden",
      backgroundColor: theme.palette.background.card
    }}>
      {content}
    </div>
  </>;
});

const loadData = timeframe => Ajax
    .get({
      url: "audit/usage-report",
      data: {
        start: formatDate(timeframe.get("start")),
        end: formatDate(timeframe.get("end").clone().endOf("day"))
      }
    })
    .then(response => Immutable
        .fromJS(response)
        .mapKeys(k => parseInt(k, 10)));

const getFilename = timeframe => {
  const start = timeframe.get("start").format("ll").replace(",", "");
  const end = timeframe.get("end").format("ll").replace(",", "");
  return "Usage Report - " + start + " To " + end;
};

const getBreadcrumbString = user => {
  const breadcrumbs = Groups.getGroupBreadcrumbs(user.get("groupId")) || [];
  if (breadcrumbs.length > 1) {
    return breadcrumbs.slice(1).map(x => x.name).join(" > ");
  } else if (breadcrumbs.length === 1) {
    return breadcrumbs[0].name;
  } else {
    return "";
  }
};

const mysqlDatetimePattern = "YYYY-MM-DD HH:mm:ss";
const formatDate = date => date.format(mysqlDatetimePattern);
