/** @jsxImportSource @emotion/react */

import React from "react";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Colors from "js/common/cube19-colors";
import squidShocked from "img/squids/squid-shocked.svg";
import Cube19 from "js/cube19.app";
import {reactAppToMarionetteComponent} from "js/common/views/react-to-marionette";
import SquidApp from "js/squids/app";

const buttonStyle = {
  display: "block",
  margin: "20px auto 20px 20px",
  color: "white",
  backgroundColor: Colors.java,
  textTransform: "none",
  paddingLeft: 10
};

const SquidError = React.memo(({selectedPageId, pageWrapper}) => {

  const handleBackButtonClick = () => {
    Cube19.contentRegion.show(reactAppToMarionetteComponent(<SquidApp />));
  };

  return <div style={{margin: 30, display: "flex", flexDirection: "column"}}>
    <TextButton onClick={handleBackButtonClick} label={<span><i className="fa fa-chevron-left" style={{marginRight: 10}}/>Main Menu</span>} style={buttonStyle} />
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
      <img style={{margin: "30px auto 30px auto"}}
           width={200}
           height={500}
           src={squidShocked}
           alt="An unexpected error has occurred." />
      <h2 style={{margin: "auto", color: Colors.redLight, marginBottom: 30}}><b>Ooops! Something went wrong.</b></h2>
      <div style={{margin: "auto", marginBottom: 5}}>
        {selectedPageId && "There was an error loading squid " + pageWrapper.get("name") + " with ID: " + selectedPageId + "."}
      </div>
      <div style={{margin: "auto"}}>Head back to the main menu to start again.</div>
    </div>
  </div>;
});

export default SquidError;