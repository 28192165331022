import React from "react";

const useBooleanState = (initial = false) => {
  const [flag, set] = React.useState(initial);
  const setTrue = React.useCallback(() => set(true), []);
  const setFalse = React.useCallback(() => set(false), []);
  const toggle = React.useCallback(() => set(flag => !flag), []);
  return {flag, setTrue, setFalse, set, toggle};
};

export default useBooleanState;
