import React from "react";

const log = (...args) => console.log.apply(console, ["debug-memo", ...args]);

const loggingEquality = displayName => (objA, objB) => {
  if (Object.is(objA, objB)) {
    return true;
  }

  if (typeof objA !== 'object'
    || objA === null
    || typeof objB !== 'object'
    || objB === null) {
    log(displayName + " props are incomparable");
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    log(displayName + " different number of props", keysA, keysB);
    return false;
  }

  for (let i = 0; i < keysA.length; i++) {
    if (!objB.hasOwnProperty(keysA[i]) || !Object.is(objA[keysA[i]], objB[keysA[i]])) {
      log(displayName + " '" + keysA[i] + "' is different", objA[keysA[i]], objB[keysA[i]]);
      return false;
    }
  }

  return true;
};

export const betterMemo = ({displayName, debug = false, debugPropKeys = []}, component) => {
  let memoized;
  if (debug) {
    const debugName = displayName || "AnonComponent";
    memoized = React.memo(props => {
      const debugProps = debugPropKeys.reduce(
        (acc, key) => {
          const value = typeof (key) === "function" ? key(props) : props[key];
          return Object.assign(acc, {[key]: value});
        },
        {});
      log(debugName + ": rendered", debugProps);
      return component(props);
    }, loggingEquality(debugName));
  } else {
    memoized = React.memo(component);
  }
  if (displayName) {
    component.displayName = displayName;
    memoized.displayName = displayName;
  }
  return memoized;
};
