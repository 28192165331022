import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import {ClassNames} from "@emotion/react";

import {IconButton} from "js/common/views/inputs/buttons";
import Layout from "js/common/views/foundation-column-layout";
import Select from "js/common/views/inputs/immutable-react-select";

import EditTrendSlide from "js/admin/cubetv/trend/edit-trend-slide";
import EditLeaderboardSlide from "js/admin/cubetv/leaderboard/edit-leaderboard-slide";
import EditFreeTextSlide from "js/admin/cubetv/free-text/edit-free-text-slide";
import EditVideoSlide from "js/admin/cubetv/embedded-video/edit-slide";
import EditIframeSlide from "js/admin/cubetv/iframe/edit-slide";

import getDefaultLeaderboardSlide from "js/admin/cubetv/leaderboard/default-leaderboard-slide";
import getDefaultFreeTextSlide from "js/admin/cubetv/free-text/default-free-text-slide";
import getDefaultVideoSlide from "js/admin/cubetv/embedded-video/default-slide";
import getDefaultIframeSlide from "js/admin/cubetv/iframe/default-slide";
import {getDefaultTrendSlide} from "js/admin/cubetv/trend/default-trend-slide";
import {formatMillis, getSlideRuntime} from "js/admin/cubetv/channel-time-utils";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

import Sortable from "react-sortable-mixin";

import {TextField} from "@mui/material";

const Slide = createReactClass({

  mixins: [PureRenderMixin, Sortable.ItemMixin],

  propTypes: {
    index: ReactPropTypes.number.isRequired,
    slide: ReactPropTypes.object.isRequired,
    onChange: ReactPropTypes.func.isRequired,
    onDeleteClick: ReactPropTypes.func.isRequired,
    onCloneClick: ReactPropTypes.func.isRequired,
    numberOfSlides: ReactPropTypes.number.isRequired,
    initiallyExpanded: ReactPropTypes.bool,
    timeframes: ImmutablePropTypes.list.isRequired,
    channels: ImmutablePropTypes.list.isRequired,
    hasUnsavedChanges: ReactPropTypes.bool.isRequired,
    theme: ReactPropTypes.object
  },

  getDefaultProps() {
    return {
      initiallyExpanded: false
    };
  },

  getInitialState() {
    return {
      expanded: this.props.initiallyExpanded
    };
  },

  render() {
    return (
        <div style={{marginTop: 2, width: "100%"}}>
          {this.renderHeader()}
          {this.state.expanded && this.renderBody()}
        </div>);
  },

  renderHeader() {
    const {slide, theme} = this.props;
    const headerStyle = theme =>({
      cursor: "pointer",
      backgroundColor:
        this.state.expanded ? theme.palette.primary.main :
       (theme.themeId === "light" ? theme.palette.background.paper : theme.palette.background.card),
      borderRadius: this.state.expanded ? "0px" : "3px",
      padding: 8,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.inverted
      }
    });
    const inheritedColor = this.state.expanded ? theme.palette.text.inverted : "inherit";
    return (
      <ClassNames>
        {({css}) => (
            <Layout
                allSmall={12}
                rowClass={css(headerStyle(theme))}
                onRowClick={() => this.setState({expanded: !this.state.expanded})}
                collapseRow={true}>
              <div>
                <div style={{
                  paddingTop: 8,
                  paddingLeft: 6,
                  float: "left",
                  color: inheritedColor,
                  userSelect: "none"
                }}>
                  {`${this.props.index + 1}. ${getSlideHeaderTitle(slide)}`}
                </div>
                <div style={{float: "right"}}>
                  <IconButton
                    isDragHandle={true}
                    hoverType="dark"
                    customStyle={{color: inheritedColor}}
                    style={{paddingTop: 0, paddingBottom: 0}}
                    label="Re-Order"
                    icon="arrows"
                    container="column"
                    onClick={e => e.stopPropagation()}
                    size="large" />
                  <IconButton
                    customStyle={{color: inheritedColor}}
                    hoverType="dark"
                    style={{paddingTop: 0, paddingBottom: 0}}
                    label="Clone"
                    icon="clone"
                    container="column"
                    onClick={e => {
                      this.props.onCloneClick(slide);
                      e.stopPropagation();
                    }}
                    size="large" />
                  {this.props.numberOfSlides > 1 &&
                  <IconButton
                    customStyle={{color: inheritedColor}}
                    hoverType="dark"
                    style={{paddingTop: 0, paddingBottom: 0}}
                    label="Delete"
                    icon="times"
                    container="column"
                    onClick={e => {
                      this.props.onDeleteClick(slide);
                      e.stopPropagation();
                    }}
                    size="large" />
                  }
                </div>
              </div>
            </Layout>
        )}
      </ClassNames>
    );
  },

  renderBody() {
    const {theme} = this.props;
    const color = this.state.expanded ? theme.palette.primary.main : theme.palette.textColor;
    const border = {
      borderTop: "2px solid " + color,
      borderBottom: "2px solid " + color,
      borderLeft: "1px solid " + color,
      borderRight: "1px solid " + color
    };
    const rowStyle = {
      display: "flex",
      alignItems: "center",
      marginTop: "1rem"
    }
    const slideType = getSlideType(this.props.slide);
    const isTextSlide = slideType === "TEXT";
    const isVideoSlide = slideType === "VIDEO";
    const isIframeSlide = slideType === "IFRAME";
    return (
        <div style={border}>
          <Layout allSmall={6} medium={[2, 4, 6]} columnClass="end" rowStyle={rowStyle}>
            <label style={{marginTop: 21}}>
              <i className="fa fa-film" style={{paddingRight: 8}} />
              Slide Type
            </label>
            <Select
                selectedValue={slideType}
                options={slideTypeOptions}
                onChange={this.handleSlideTypeChange}
                isMulti={false}
                isClearable={false} />
            {(isTextSlide || isVideoSlide || isIframeSlide) &&
            <TextField variant="standard" 
                label="Slide Description"
                value={this.props.slide.get("description")}
                onChange={e => this.handleSlideDescriptionChange(e.target.value.substring(0, 64))} />}
          </Layout>
          {(!isTextSlide && !isVideoSlide && !isIframeSlide) && this.renderPageTitleEditPanel()}
          {this.renderSlideEditPanel()}
        </div>);
  },

  renderPageTitleEditPanel() {
    const pageTitle = this.props.slide.get("pageTitle");
    const maxTitleLength = 64;

    return (
        <Layout allSmall={12} allMedium={4} rowStyle={{marginTop: "0.5rem"}}>
          <TextField variant="standard"
              label="Title (e.g. Metric)"
              style={{width: 250, marginTop: 15}}
              value={pageTitle.get("title")}
              onChange={e => this.handlePageTitleChange("title", e.target.value.substring(0, maxTitleLength))} />
          <TextField variant="standard"
              label="Subtitle (e.g. Team)"
              style={{width: 250, marginTop: 15}}
              value={pageTitle.get("subTitle")}
              onChange={e => this.handlePageTitleChange("subTitle", e.target.value.substring(0, maxTitleLength))} />
          <TextField variant="standard"
              label="Sub-subtitle (e.g. Timeframe)"
              style={{width: 250, marginTop: 15}}
              value={pageTitle.get("timeframeId")}
              onChange={e => this.handlePageTitleChange("timeframeId", e.target.value.substring(0, maxTitleLength))} />
        </Layout>
        );
  },

  handlePageTitleChange(key, value) {
    const updatedSlide = this.props.slide.setIn(["pageTitle", key], value);
    this.props.onChange(updatedSlide);
  },

  handleSlideDescriptionChange(value) {
    const updatedSlide = this.props.slide.set("description", value);
    this.props.onChange(updatedSlide);
  },

  renderSlideEditPanel() {
    const slideType = getSlideType(this.props.slide);
    const View = configBySlideType[slideType].editSlideView;
    return (
        <View
            timeframes={this.props.timeframes}
            slide={this.props.slide}
            onChange={this.props.onChange}
            channels={this.props.channels}
            hasUnsavedChanges={this.props.hasUnsavedChanges} />
    );
  },

  handleSlideTypeChange(slideType) {
    const {slide, onChange} = this.props;
    const newSlide = configBySlideType[slideType]
        .getDefaultSlide()
        .set("id", slide.get("id"))
        .set("cid", slide.get("cid"))
        .set("pageTitle", slide.get("pageTitle").set("title", pageTitleBySlideType[slideType]));
    onChange(newSlide);
  }

});

const pageTitleBySlideType = {
  LEADERBOARD: "Leaderboard",
  TREND: "Trend",
  TEXT: "",
  VIDEO: "",
  IFRAME: ""
};

const slideTypeByLayoutId = {
  LeftBigRightSmall: "LEADERBOARD",
  LeftBigRight4: "TREND",
  Fullscreen: "TEXT",
  EmbeddedVideo: "VIDEO",
  Iframe: "IFRAME"
};

const configBySlideType = {
  LEADERBOARD: {
    editSlideView: EditLeaderboardSlide,
    getDefaultSlide: getDefaultLeaderboardSlide
  },
  TREND: {
    editSlideView: EditTrendSlide,
    getDefaultSlide: getDefaultTrendSlide
  },
  TEXT: {
    editSlideView: EditFreeTextSlide,
    getDefaultSlide: getDefaultFreeTextSlide
  },
  VIDEO: {
    editSlideView: EditVideoSlide,
    getDefaultSlide: getDefaultVideoSlide
  },
  IFRAME: {
    editSlideView: EditIframeSlide,
    getDefaultSlide: getDefaultIframeSlide
  }
};

const getSlideType = slide => slideTypeByLayoutId[slide.getIn(["layout", "id"])];

const getSlideHeaderTitle = slide => {
  const slideType = getSlideType(slide);
  let titleStr;
  if (slideType === "TEXT" || slideType === "VIDEO" || slideType === "IFRAME") {
    titleStr = slide.get("description");
  } else {
    const pageTitle = slide.get("pageTitle");
    titleStr = [
      pageTitle.get("title"),
      pageTitle.get("subTitle"),
      pageTitle.get("timeframeId")
    ].filter(t => !!t).join("  ▶  ");
  }
  const runtime = formatMillis(getSlideRuntime(slide));
  return `${titleStr} - ${runtime}`;
};

const slideTypeOptions = Immutable.fromJS([
  {value: "LEADERBOARD", label: "Leaderboard Slide"},
  {value: "TREND", label: "Trend Slide"},
  {value: "TEXT", label: "Text Slide"},
  {value: "VIDEO", label: "Video Slide"},
  {value: "IFRAME", label: "Web Page Slide"}
]);

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <Slide theme={theme} {...props} />;
};