import Immutable from "immutable";

const clamp = (num, min = Number.NEGATIVE_INFINITY, max = Number.POSITIVE_INFINITY) => {
  if (num > max) {
    return max;
  } else if (num < min) {
    return min;
  } else {
    return num;
  }
};

const formatStorageSize = (bytes, decimalPlaces = 1) => {
  let _str;
  if (bytes >= 1e9) {
    _str = roundTo((bytes / 1e9), decimalPlaces) + "GB";
  } else if (bytes >= 1e6) {
    _str = roundTo((bytes / 1e6), decimalPlaces) + "MB";
  } else if (bytes >= 1e3) {
    _str = roundTo((bytes / 1e3), decimalPlaces) + "kB";
  } else {
    _str = bytes.toString();
  }
  return _str;
};

const firstWithValue = (...xs) => Immutable.List(xs).find(x => x !== null && x !== undefined);

const formatBigNumber = (
    number,
    {
      decimalPlaces,
      decimalPlacesForK,
      decimalPlacesForM,
      fallbackValue
    } = {}) => {
  if (number >= 1e12) {
    const dps = firstWithValue(decimalPlaces, decimalPlacesForM, 2);
    return roundTo((number / 1e12), dps) + "T";
  } else if (number >= 1e9) {
    const dps = firstWithValue(decimalPlaces, decimalPlacesForM, 2);
    return roundTo((number / 1e9), dps) + "B";
  } else if (number >= 1e6) {
    const dps = firstWithValue(decimalPlaces, decimalPlacesForM, 2);
    return roundTo((number / 1e6), dps) + "M";
  } else if (number >= 1e3) {
    const dps = firstWithValue(decimalPlaces, decimalPlacesForK, 1);
    return roundTo((number / 1e3), dps) + "K";
  } else {
    const dps = firstWithValue(decimalPlaces, decimalPlacesForK, decimalPlacesForM, 1);
    return fallbackValue || roundTo(number, dps).toString();
  }
};

const toPercentageStr = (number, decimalPlaces = 0) => {
  const percent = number * 100;
  const rounded = roundTo(percent, decimalPlaces);
  return rounded.toString() + "%";
};

const roundTo = (number, decimalPlaces = 0) => {
  const oom = Math.pow(10, decimalPlaces);
  let num = number * oom;
  num = Math.round(num);
  num = num / oom;
  return num;
};

const normaliseFraction = (numerator, denominator) => {
  const unnormalisedNumerator = numerator;
  const unnormalisedDenominator = denominator;

  if (numerator < denominator) {

    numerator = numerator / unnormalisedNumerator;
    denominator = denominator / unnormalisedNumerator;
  } else {
    numerator = numerator / unnormalisedDenominator;
    denominator = denominator / unnormalisedDenominator;
  }

  return {
    numerator: numerator,
    denominator: denominator
  };
};

const findGreatestCommonDenominator = (a, b) => {
  return b ? findGreatestCommonDenominator(b, a % b) : a;
};

const simplifyFraction = (numerator, denominator) => {
  numerator = Math.round(numerator);
  denominator = Math.round(denominator);

  const gcd = findGreatestCommonDenominator(numerator, denominator);
  return {
    numerator: numerator / gcd,
    denominator: denominator / gcd
  };
};

const between = (n, min, max) => {
  return n >= min && n <= max;
};

export {
  clamp,
  formatStorageSize,
  formatBigNumber,
  roundTo,
  toPercentageStr,
  normaliseFraction,
  simplifyFraction,
  between
};
