import React from "react";
import createReactClass from "create-react-class";
import * as SavedConfigs from "js/common/saved-configs";
import * as Ajax from "js/common/ajax";
import Immutable from "immutable";
import * as Users from "js/common/users";
import {indexBy} from "js/common/utils/collections";
import * as tagRepo from "js/common/repo/backbone/tag-repo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Tooltip = createReactClass({

      getInitialState() {
        return {
          isVisible: false
        };
      },

      getDefaultProps() {
        return {
          disable: false,
          maxWidth: 400
        };
      },

      render() {
        const tooltipStyle = {
          top: "25px",
          left: this.props.tooltipOffset || "50%",
          transform: "translateX(-50%)",
          display: this.state.isVisible ? "block" : "none",
          transition: "opacity .25s ease-in-out",
          position: "absolute",
          whiteSpace: "nowrap",
          padding: "10px",
          borderRadius: "5px",
          fontSize: this.props.textSize ? `${this.props.textSize}px` : "0.8rem",
          zIndex: 999999,
          background: this.props.theme.palette.background.inverted,
          color: this.props.theme.palette.text.inverted,
          border: `2px solid ${this.props.theme.palette.background.inverted}`,
          minWidth: this.props.maxWidth,
          maxWidth: this.props.maxWidth
        };

        const tooltipArrowStyle = {
          position: "absolute",
          width: "12px",
          height: "12px",
          zIndex: -1,
          color: this.props.theme.palette.background.inverted,
          left: this.props.arrowOffset || "50%",
          top: "-10px",
          textShadow: `${this.props.theme.palette.background.inverted} 0px -3px 0px`,
          transform: `translateX(${this.props.theme.themeId === "dark" && this.props.tooltipOffset ? "-25px" : "-50%"})`
        };

        return <div
            style={{position: "relative", ...this.props.customStyle}}
            onMouseOver={this.handleMouseIn}
            onMouseOut={this.handleMouseOut}>
          {!this.props.disable && <div style={tooltipStyle}>
            {this.props.content}
            <div style={tooltipArrowStyle}>▲</div>
          </div>}
          {this.props.children}
        </div>;
      },

      handleMouseIn() {
        if (this.props.disable) {
          return;
        }
        this.setState({isVisible: true});

        const promises = [];

        const existingIdToClientSet = this.props.typeToIdToNamedItem.get("CLIENT_SETS", Immutable.Map());
        const clientSetIdsWithNames = existingIdToClientSet.keySeq().toSet();
        const clientSetIdsWithoutNames = this.props.selectedClientSetIds.toSet().subtract(clientSetIdsWithNames);
        if (clientSetIdsWithoutNames.isEmpty()) {
          promises.push(Promise.resolve(existingIdToClientSet));
        } else {
          promises.push(this.loadIdToClientSet(this.props.selectedClientSetIds));
        }

        const existingIdToClient = this.props.typeToIdToNamedItem.get("CLIENTS", Immutable.Map());
        const clientIdsWithNames = existingIdToClient.keySeq().toSet();
        const clientIdsWithoutNames = this.props.selectedClientIds.toSet().subtract(clientIdsWithNames);
        if (clientIdsWithoutNames.isEmpty()) {
          promises.push(Promise.resolve(existingIdToClient));
        } else {
          promises.push(this.loadIdToClient(clientIdsWithoutNames).then(newIdToClient => {
            return existingIdToClient.merge(newIdToClient);
          }));
        }

        promises.push(Promise.resolve(indexBy(
            tag => tag.get("id"),
            this.props.typeToIdToNamedItem.get("TAGS") || this.getTagOptions())));

        Promise
            .all(promises)
            .then(([idToClientSet, idToClient, idToTag]) => {
              this.props.setNames(Immutable.Map({
                "CLIENTS": idToClient,
                "CLIENT_SETS": idToClientSet,
                "TAGS": idToTag
              }));
            });
      },

      handleMouseOut() {
        if (this.props.disable) {
          return;
        }
        this.setState({isVisible: false});
      },

      closeTooltip() {
        this.setState({isVisible: false});
      },

      getTagOptions() {
        return Immutable
            .fromJS(tagRepo.getAll().toJSON())
            .map(tag => {
              const category = tagRepo.getCategory(tag.get("categoryId"));
              return Immutable.Map({
                category: category.get("name"),
                id: tag.get("id"),
                name: tag.get("name")
              });
            });
      },

      loadIdToClientSet(clientSetIds) {
        const suffixWithNameOwnershipTypes = ["sharedUserConfigs", "sharedGroupConfigs"];
        return SavedConfigs
            .getAll("CLIENT_SET")
            .then(ownershipTypeToSavedClientSets => {
              const ownedIds = ownershipTypeToSavedClientSets.get("ownedConfigs").map(config => config.get("id")).toSet();
              const clientSets = ownershipTypeToSavedClientSets
                  .update("ownedConfigs", configs => configs.map(this.toClientSet))
                  .update("sharedUserConfigs", configs => configs
                      .filter(config => !ownedIds.has(config.get("id")))
                      .map(this.toClientSet))
                  .update("sharedGroupConfigs", configs => configs
                      .filter(config => !ownedIds.has(config.get("id")))
                      .map(this.toClientSet))
                  .map((clientSets, ownershipType) => clientSets
                      .map(clientSet => this.clientSetToItem(clientSet, suffixWithNameOwnershipTypes.includes(ownershipType))))
                  .toList()
                  .flatMap(sets => sets);
              const idToClientSet = indexBy(set => set.get("id"), clientSets);
              const clientSetIdsNotVisible = clientSetIds.toSet().subtract(idToClientSet.keySeq().toSet());
              const idToNamePlaceholder = indexBy(x => x, clientSetIdsNotVisible).map(id => {
                return Immutable.Map({
                  clientSetId: "CLIENT_SET_" + id,
                  name: "[this client set has not been shared with you]",
                  type: "CLIENT_SET",
                  id,
                  clientCount: 0
                });
              });
              return idToClientSet.merge(idToNamePlaceholder);
            });
      },

      loadIdToClient(clientIds) {
        return Ajax
            .post({url: "entity-finder", json: {entityType: "CLIENT", ids: clientIds.toJS()}})
            .then(x => indexBy(c => c.get("id"), Immutable.fromJS(x).map(this.clientToItem)));
      },

      clientToItem(client) {
        return new Immutable.Map({
          id: client.get("id"),
          name: client.get("originalCrmId") + " - " + client.get("name"),
          type: "CLIENT"
        });
      },

      clientSetToItem(clientSet, suffixWithName) {
        const owner = Users.getUser(clientSet.get("ownerId"));
        let name;
        if (suffixWithName && owner) {
          name = clientSet.get("name") + " - " + owner.get("firstName");
        } else {
          name = clientSet.get("name");
        }
        return new Immutable.Map({
          clientSetId: "CLIENT_SET_" + clientSet.get("id"),
          name,
          type: "CLIENT_SET",
          id: clientSet.get("id"),
          clientCount: clientSet.get("json").get("clientIds").size
        });
      },

      toClientSet(config) {
        return config.updateIn(["json", "clientIds"], clientIds => clientIds.toSet());
      }
    }
);

Tooltip.getDerivedStateFromProps = (props, state) => {
  if (props.disable) {
    state.isVisible = false;
  }
  return state;
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <Tooltip theme={theme} {...props} />;
};

