import React from "react";
import Immutable from "immutable";
import pure from "js/common/views/pure";

import TagFilter from "js/common/views/inputs/tag-picker/react-tag-filter";

export default pure(({
    onChange,
    tagIds,
    ...props
}) => (
    <TagFilter
        {...props}
        onChange={tagIds => onChange(Immutable.List(tagIds))}
        tagIds={tagIds ? tagIds.toArray() : []} />));
