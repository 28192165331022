import React from "react";
import ReactPropTypes from 'prop-types';

import {
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody
} from '@mui/material';

const Leaderboard = ({ columns, rows }) => (
    <Table selectable={false} height="500px" fixedHeader={true}>
        <TableHead displaySelectAll={false} adjustForCheckbox={false} >
            <TableRow>
                {columns.map(column => <TableCell key={column}>{column}</TableCell>)}
            </TableRow>
        </TableHead>
        <TableBody displayRowCheckbox={false} >
            {rows.map(({ id, fields }) => (
                <TableRow key={id} >
                    {fields.map(field => <TableCell key={field}>{field}</TableCell>)}
                </TableRow>
            ))}
        </TableBody>
    </Table>
);
Leaderboard.childContextTypes = {
    muiTheme: ReactPropTypes.object
};

export default Leaderboard;
