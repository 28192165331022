import React from "react";
import DropdownToggle from "js/common/views/react-dropdown-toggle";
import * as Immutable from "immutable";

const PipelineActivityFilter = React.memo(({onValueChange, value, isDisabled}) => {

  const optionStyle = {
    fontSize: "0.875rem",
    cursor: "pointer",
    padding: "0.5rem 0.875rem",

    ":hover": {
      background: "#f2e207",
      color: "#111",
      fontWeight: "bold"
    }
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const options = Immutable.List(["With Some Activity By", "Show Only Activity For"]);
  const selectedValueText = value ? "With Some Activity By" : "Show Only Activity For";

  const dropdownOptions = <>
    {options.map(option => <div
        style={optionStyle}
        onClick={() => {
          onValueChange(option);
          setIsOpen(false);
        }}>
      {option}
    </div>)}
  </>;

  return <DropdownToggle
      primaryText={selectedValueText}
      isDisabled={isDisabled}
      style={{fontSize: "0.75rem"}}
      children={dropdownOptions}
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      onToggleButtonClick={() => setIsOpen(!isOpen)}
  />;
});

export default PipelineActivityFilter;