import React from "react";
import Select from "js/common/views/inputs/immutable-react-select";
import Immutable from "immutable";

import { getPercentageOfTargetTypeDisplayName } from "js/charting/trend/display-name-util";

const _ = window._;

export default ({ trendType, onChange, whatToPlot = "value", howToPlot }) => {
    let selectedValue = howToPlot;
    let options =  [{
        value: "non-cumulative",
        label: "Non-cumulative"
    }];
    if (trendType === "KPI" && whatToPlot === "percentage-target") {
        const percentageTargetTypes = [ "EXPECTED_TARGET", "TOTAL_TARGET", "DAILY_TARGET" ];
        options = percentageTargetTypes.map(type => ({
            value: type,
            label: getPercentageOfTargetTypeDisplayName(type)
        }));

        if (options.map(o => o.value).indexOf(selectedValue) < 0) {
            selectedValue = "EXPECTED_TARGET";
        }
    }
    if (trendType === "KPI" && whatToPlot !== "percentage-target") {
        options.push({
            value: "cumulative",
            label: "Cumulative"
        });

        if (_.isBoolean(selectedValue)) {
            selectedValue = selectedValue ? "cumulative" : "non-cumulative";
        } else if (options.map(o => o.value).indexOf(selectedValue) < 0) {
            selectedValue = "non-cumulative";
        }
    }
    if (trendType === "RATIO") {
        selectedValue = "non-cumulative";
    }

    return (
        <Select
            name="how-to-plot"
            placeholder="How to plot"
            selectedValue={selectedValue}
            isMulti={false}
            isClearable={false}
            isSearchable={false}
            options={Immutable.fromJS(options)}
            onChange={newOption => onChange(newOption)} />);
};
