/** @jsxImportSource @emotion/react */

import React from "react";
import {css, jsx} from "@emotion/react";
import {betterMemo} from "js/common/utils/more-memo";
import {IconButton} from "js/common/views/inputs/buttons";

const EditColumnsSubGroup = betterMemo({displayName: "EditColumnsSubGroup"}, ({
  theme,
  group,
  currentDepth,
  searching,
  handleVisibleClick,
  handleToggleCollapse,
  collapsedSectionIds
}) => {

  const childGroups = group
      .filter(c => c.get("readableJoinPath").count() > currentDepth)
      .groupBy(c => c.get("readableJoinPath").get(currentDepth));

  return childGroups
      .entrySeq()
      .sortBy(([label]) => label)
      .map(([label, group], i) => {
        return <EditColumnsSubGroupRow
            index={i}
            key={`sub-group-${i}`}
            theme={theme}
            label={label}
            group={group}
            searching={searching}
            currentDepth={currentDepth}
            handleVisibleClick={handleVisibleClick}
            handleToggleCollapse={handleToggleCollapse}
            collapsedSectionIds={collapsedSectionIds}
        />;
      });
});


const rowStyleSub = (theme) => css`
  border-bottom: 1px solid ${theme.themeId === "light" ? theme.palette.border.light : "#393838"};
  font-size: 13px;
  background: ${theme.themeId === "light" ? theme.palette.background.paper : "#1c1c22"};

  &:first-of-type {
    border-top: none;
  }

  &:last-of-type {
    border-bottom: none;
  }
`;

const columnStyle = {
  paddingLeft: "0.9375em",
  paddingRight: "0.9375em"
};

const groupTitle = theme => ({
  margin: "10px 10px 5px 10px",
  fontFamily: theme.typography.fontFamilyBold,
  fontSize: "14px !important",
  color: theme.palette.text.main,
  textTransform: "capitalize"
});


const EditColumnsSubGroupRow = betterMemo({displayName: "EditColumnsSubGroupRow"}, ({
  index,
  theme,
  label,
  group,
  currentDepth,
  handleVisibleClick,
  searching,
  handleToggleCollapse,
  collapsedSectionIds
}) => {

  const firstGroup = group.filter(g => g.get("readableJoinPath").count() === currentDepth + 1).first();
  const parentLabelPath = firstGroup ? firstGroup.get("readableJoinPath").slice(1).join(" > ") : label;
  const groupId = firstGroup ? firstGroup.get("joinPathStr") : label;

  const toggleCollapse = (id) => {
    handleToggleCollapse && handleToggleCollapse(id);
  };

  const cols = group.filter(g => g.get("readableJoinPath").count() === currentDepth + 1)
      .filter(c => !c.get("visible"));

  return (
      <div key={`sub-${groupId.replace(",","-")}-${index}`}>
        {cols.count() > 0 && <div
            style={{
              background: theme.themeId === "light"
                  ? theme.palette.background.paper
                  : "#1c1c22",
              position: "relative",
              borderRadius: 3,
              margin: "0 0 10px 0",
              padding: "5px 5px",
              display: group.filter(g => g.get("readableJoinPath").count() === currentDepth + 1).count() > 0
                  ? "block"
                  : "none"
            }}>
          <div onClick={() => toggleCollapse(groupId)} style={{cursor: "pointer"}}>
            {!searching && <i
                className={`fa fa-${!collapsedSectionIds.contains(groupId) || searching ? "minus" : "plus"}`}
                style={{fontSize: "1rem", position: "absolute", top: 14, right: 20}} />}
            <h3 css={groupTitle(theme)}>{parentLabelPath}</h3>
          </div>
          <div style={{display: `${!collapsedSectionIds.contains(groupId) || searching ? "block" : "none"}`}}>
            {cols
                .sortBy(g => g.get("label"))
                .map((c, i) =>
                    <div css={rowStyleSub(theme)} key={`column-row-${i}`}>
                      <div style={columnStyle}>
                        {c.get("label")}
                        <div className="right">
                          <IconButton
                              icon="eye"
                              type="bright"
                              title="Make visible"
                              onClick={() => handleVisibleClick(c)} />
                        </div>
                      </div>
                    </div>
                )}
          </div>
        </div>}
        <EditColumnsSubGroup
            theme={theme}
            key={`sub-group-${group}`}
            group={group}
            currentDepth={currentDepth + 1}
            searching={searching}
            handleVisibleClick={handleVisibleClick}
            handleToggleCollapse={handleToggleCollapse}
            collapsedSectionIds={collapsedSectionIds}
        />
      </div>
  );
});

export default EditColumnsSubGroup;
