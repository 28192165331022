/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import {jsx, css} from "@emotion/react";
import Tooltip from "js/common/views/tooltips";

export default createReactClass({

  displayName: "GroupNodeLabel",

  render() {
    const {
      theme,
      className,
      color,
      labelTextColor,
      onClick,
      icon,
      title
    } = this.props;

    const label = theme => css`
      background-color: ${color};
      font-size: 8px;
      font-weight: normal;
      text-transform: uppercase;
      color: ${labelTextColor};
      border-radius: 10px;
      margin: 3px 3px;
      display: inline-block;
      padding: 5px 8px;
      text-align: center;
      max-width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      .fa {
        margin-right: 6px;
      }
      &:hover {
        color: ${labelTextColor};
      }
    `;

      return (
          <div style={{display: "inline-block"}}>
          <Tooltip
              text={title}
              styleType={theme.themeId === "light" ? "light" : "dark"}
              position="top"
              textSize={10}>
              <a className={className} css={label(theme)} onClick={() => onClick && onClick()}>
                <i className={`fa fa-${icon}`}/>
                {title}
              </a>
          </Tooltip>
          </div>
      );
  }
});
