import React from "react";
import Select from "js/common/views/inputs/immutable-react-select";
import Immutable from "immutable";

export default ({ onChange, value = "none", color, isDisabled = false }) => {
    const fillColor = color || "#fff";
    return (
        <Select
            name="data-point-marker"
            placeholder="Select data marker"
            selectedValue={value}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            isDisabled={isDisabled}
            optionRenderer={renderLineTypeAsSvg}
            valueRenderer={renderLineTypeAsSvg}
            options={Immutable.fromJS([{
                value: "none",
                label: "None",
                color: fillColor
            },{
                value: "circle",
                label: "Circle",
                color: fillColor
            },{
                value: "square",
                label: "Square",
                color: fillColor
            },{
                value: "diamond",
                label: "Diamond",
                color: fillColor
            },{
                value: "triangle",
                label: "Triangle",
                color: fillColor
            },{
                value: "triangle-down",
                label: "Triangle Down",
                color: fillColor
            }])}
            onChange={newOption => onChange(newOption)} />
    );

};

const renderLineTypeAsSvg = option => {
    const { color, value } = option;
    const shapesByValue = {
        none: <text x="0" y="12">None</text>,
        circle: <circle cx="10" cy="8" r="8" fill={color} />,
        square: <rect x="0" y="0" width="16" height="16" fill={color} />,
        diamond: <rect x="6" y="-6" width="12" height="12" fill={color} transform="rotate(45)" />,
        triangle: <polygon x="0" y="0" fill={color} points="10, 0 20, 15 0, 15" />,
        "triangle-down": <polygon x="0" y="0" fill={color} points="0, 0 20, 0 10, 15" />
    };
    return <svg width="100%" height="16px" version="1.1" xmlns="http://www.w3.org/2000/svg">{shapesByValue[value]}</svg>;
};
