import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import PureRenderMixin from "react-addons-pure-render-mixin";

import AdminHeader from "js/admin/common/admin-header";
import LoadingSpinner from "js/common/views/loading-spinner";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import { TextButton } from "js/common/views/inputs/buttons";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";

export default createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            kpis,
            onKpiSelect,
            kpiId,
            isLoading
        } = this.props;
        return (
            <div>
                <AdminHeader>
                    View and Edit Current Targets
                </AdminHeader>
                <div style={{ margin: "1rem", maxWidth: 450 }} data-test-id="kpi-picker-current-targets">
                    <KpiPicker
                        kpis={kpis}
                        multiSelect={false}
                        clearable={true}
                        selectedKpiId={kpiId}
                        onChange={onKpiSelect} />
                </div>
                {kpiId &&
                    <div style={{ margin: "1rem" }}>
                        {isLoading ? <LoadingSpinner label="Loading Current Targets" /> : this.renderCurrentTargets()}
                    </div>}
            </div>
        );
    },

    renderCurrentTargets() {
        const { currentTargetsById, isLoading, onEditTargetRequest, onRemoveTargetRequest } = this.props;
        const currentTargets = currentTargetsById
            .filter(t => !t.get("deleted"))
            .toList();
        return (
            <CurrentTargetsTable
                currentTargets={currentTargets}
                isActionsDisabled={isLoading}
                onEditTargetClick={onEditTargetRequest}
                onRemoveTargetClick={onRemoveTargetRequest} />
        );
    }

});

const CurrentTargetsTable = createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const dateColumnMappers = {
            commonMapper: cell => moment(cell, "YYYY-MM-DD"),
            sortMapper: date => date.isValid() ? date.valueOf() : 0,
            displayMapper: date => date.isValid() ? formatDate(date) : ""
        };
        const columns = [
            {
                label: "Priority Metric",
                sortMapper: cell => cell.sortValue,
                displayMapper: cell => cell.priorityLabel
            },{
                label: "Group/User's Name",
                sortMapper: cell => cell.sortValue,
                displayMapper: cell => cell.name
            },{
                label: "Start Date",
                ...dateColumnMappers
            },{
                label: "End Date",
                ...dateColumnMappers
            },
            "Period",
            "Target",
            {
                label: "Actions",
                sortMapper: () => {}
            }
        ];
        const initialSortByIndex = columns.findIndex(col => col.label === "Group/User's Name");
        const rows = this.props.currentTargets
            .map(this.targetToRow)
            .toJS();
        return (
            <SimpleDataTable
                columns={columns}
                rows={rows}
                initialSortBy={initialSortByIndex}
                maxTableHeight={700} />
        );
    },

    targetToRow(target) {
        const { isActionsDisabled, onEditTargetClick, onRemoveTargetClick } = this.props;
        const targetId = target.get("id");

        const isPriorityMetricTarget = target.get("priority");
        const priorityMetricStatusLabel = isPriorityMetricTarget ?
            <span><i className="fa fa-dot-circle-o is-priority" />&nbsp;Yes</span> :
            <span>No</span>;

        const nameGetterFnByQualifierType = {
            USER: getUserName,
            GROUP: getGroupName
        };

        const orderByQualifierType = {
            USER: 1,
            GROUP: 0
        };
        const qualifierType = target.get("qualifierType");
        const getName = nameGetterFnByQualifierType[qualifierType];
        const groupOrUserName = getName(target.get("qualifierId"));

        const targetStartsOn = target.get("targetStart");
        const targetEndsOn = target.get("targetEnd");
        const targetPeriod = target.get("period").toLowerCase();
        const targetValue = target.get("value").get("value");

        const buttonStyle = {
            marginTop : "-5px", // to compensate for the 8px padding that fixed-data-table sets on cells
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
            height: 25,
            lineHeight: 1
        };

        const actions = [
            <TextButton
                key={`edit-target-${targetId}`}
                icon="edit"
                label="Edit"
                disabled={isActionsDisabled}
                onClick={() => onEditTargetClick(target)}
                style={buttonStyle} />,
            <TextButton
                key={`remove-target-${targetId}`}
                icon="remove"
                label="Remove"
                type="alert"
                disabled={isActionsDisabled}
                onClick={() => onRemoveTargetClick(targetId)}
                style={{ ...buttonStyle, border: "1px solid #555" }} />
        ];

        return [
            {
                priorityLabel: priorityMetricStatusLabel,
                sortValue: isPriorityMetricTarget
            },{
                name: groupOrUserName,
                sortValue: `${orderByQualifierType[qualifierType]}: ${groupOrUserName.toLowerCase()} - ${targetStartsOn}`
            },
            targetStartsOn,
            targetEndsOn,
            targetPeriod,
            targetValue,
            actions
        ];
    }

});

const getUserName = userId => Users.getUser(userId).get("fullName");

const getGroupName = groupId => {
    const group = Groups.getGroup(groupId);
    return group ? group.get("name") : "N/A";
};

const formatDate = dateStr => moment(dateStr).local().format("L");
