import Immutable from "immutable";

export default () => {
    const cid = Math.random();
    return Immutable.fromJS({
        cid: cid,
        id: `slide-${cid}`,
        pageTitle: {
            title: "",
            subTitle: "",
            timeframeId: ""
        },
        description: "YouTube Video Slide",
        timing: 30000,
        layout: {
            id: "EmbeddedVideo",
            fullscreen: [{
                visualizationId: "EmbeddedVideo",
                videoUrl: "",
                muteVideo: false,
                showSubtitles: false
            }]
        },
        showNewsticker: true
    });
};
