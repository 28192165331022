import Immutable from "immutable";
import moment from "moment";
import React from "react";

import {indexBy, indexByJs} from "js/common/utils/collections";
import * as DashboardUtils from "js/dashboards/utils";
import * as Users from "js/common/users";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {TextButton} from "js/common/views/inputs/buttons";
import Tooltip from "js/common/views/tooltips";
import {hasValue} from "js/common/utils/value-checking";
import {getIsDeepLinkable} from "js/common/utils/novo-comms";

export const isNumber = x => typeof (x) === "number";

const baseComparator = (a, b) => {
  a = a && a.hasOwnProperty("value") ? a.value : a;
  b = b && b.hasOwnProperty("value") ? b.value : b;
  if (!hasValue(a) && !hasValue(b)) {
    return 0;
  } else if (!hasValue(a) && hasValue(b)) {
    return -1;
  } else if (hasValue(a) && !hasValue(b)) {
    return 1;
  } else {
    if (isNumber(a) && isNumber(b)) {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    } else {
      return a.localeCompare(b, undefined, {numeric: true});
    }
  }
};

export const getGridAndChartWidth = (config) => {
  if(!config.hasIn(["chart", "position"])) {
    return {gridWidth: "100%", chartWidth: 0};
  } else if (config.has("chart") && config.getIn(["chart", "position"]) !== "NONE") {
    const chartPosition = config.getIn(["chart", "position"]);
    if (chartPosition === "LEFT") {
      const chartWidth = "50%";
      const gridWidth = "50%";
      return {gridWidth, chartWidth};
    } else {
      const chartWidth = "100%";
      const gridWidth = 0;
      return {gridWidth, chartWidth};
    }
  } else {
    return {gridWidth: "100%", chartWidth: 0};
  }
};

export const mountChart = (config, chartThemeName, containerDomNode, agGridApi, unlinked) => {
  if (!agGridApi) { return; }
  const pivotChartParams = {
    chartType: config.getIn(["chart", "type"], "stackedColumn"),
    chartContainer: containerDomNode,
    chartThemeName,
    unlinkChart: unlinked,
    chartThemeOverrides: {
      common: {
        title: {
          enabled: false,
        },
        background: {
          fill: chartThemeName === "cube-theme" ? "#fff" : "#272731",
        },
        legend: {
          enabled: true,
          position: "bottom"
        },
        navigator: {
          enabled: true,
          height: 8
        }
      }
    }
  };
  return agGridApi.createPivotChart(pivotChartParams);
};

const defaultColDef = {
  sortable: true,
  filter: true,
  enablePivot: true,
  enableRowGroup: true,
  enableValue: true,
  minWidth: 160,
  cellRendererParams: {
    suppressCount: true
  },
  menuTabs: ["generalMenuTab"],
  pivotComparator: (a, b) => baseComparator(a, b),
  comparator: (a, b) => baseComparator(a, b)
};

export const defaultGridProps = {
  defaultColDef,
  pivotMode: true,
  sideBar: false,
  enableCharts: true,
  suppressPropertyNamesCheck: true,
  groupMaintainOrder: false,
  groupDisplayType: "multipleColumns",
  suppressDragLeaveHidesColumns: true,
  suppressRowDrag: true,
  suppressRowClickSelection: true,
  suppressCellFocus: true,
  suppressFocusAfterRefresh: true,
  tooltipShowDelay: 500,
  chartThemes: ["cube-theme", "ag-material", "ag-material-dark"],
  rowHeight: 30,
  getContextMenuItems: () => [],
  autoGroupColumnDef: {
    tooltipValueGetter: params => params.value,
    menuTabs: ["generalMenuTab"]
  }
};

export const customChartThemes = {
  "cube-theme": {
    baseTheme: 'ag-material',
    palette: {
      fills: ['#AA6699', '#FFAA44', '#3399DD', '#44BB77', '#662255', '#BB5566'],
      strokes: ['#753e68', '#a6691f', '#0f5380', '#1a693c', '#380f2e', '#702431']
    },
    overrides: {
      common: {
        title: {
          enabled: false,
        },
        subtitle: {
          enabled: true,
          fontSize: 12,
          fontFamily: 'Monaco, monospace',
        },
      }
    }
  },
  "cube-theme-dark": {
    baseTheme: 'ag-material-dark',
    palette: {
      fills: ['#AA6699', '#FFAA44', '#3399DD', '#44BB77', '#662255', '#BB5566'],
      strokes: ['#753e68', '#a6691f', '#0f5380', '#1a693c', '#380f2e', '#702431']
    },
    overrides: {
      common: {
        title: {
          enabled: false,
        },
        subtitle: {
          enabled: true,
          fontSize: 12,
          fontFamily: 'Monaco, monospace',
        },
      }
    }
  }
};

export const getRowGroupKeys = node => {
  const rowGroupKeys = [];
  let currentNode = node;
  while (hasValue(currentNode.key)) {
    rowGroupKeys.push(currentNode.key);
    currentNode = currentNode.parent;
  }
  rowGroupKeys.reverse();
  return rowGroupKeys;
};

export const getMatchingRows = (
    rowGroupColIds,
    rowGroupKeys,
    pivotColIds,
    pivotKeys,
    pivotRows,
    quickFilterText = "",
    filterModel = {}
) => {
  const filterModelColIds = Object.keys(filterModel);
  for (let i = 0; i < filterModelColIds.length; i++) {
    const colId = filterModelColIds[i];
    const filter = filterModel[colId];
    filter.values = new Set(filter.values);
  }

  const filterWords = quickFilterText.split(" ").filter(x => !!x).map(x => x.toLowerCase());

  return pivotRows.filter(row => {
    const colIds = Object.keys(row);
    for (let i = 0; i < filterWords.length; i++) {
      const word = filterWords[i];
      let wordMatchesAtLeastOneCell = false;
      for (let j = 0; j < colIds.length; j++) {
        const colId = colIds[j];
        if ((row[colId].value + "").toLowerCase().indexOf(word) !== -1) {
          wordMatchesAtLeastOneCell = true;
        }
      }
      if (!wordMatchesAtLeastOneCell) {
        return false;
      }
    }

    for (let i = 0; i < filterModelColIds.length; i++) {
      const colId = filterModelColIds[i];
      const filter = filterModel[colId];
      if (filter.filterType !== "set" || !filter.values.has(row[colId].value)) {
        return false;
      }
    }
    for (let i = 0; i < Math.min(rowGroupKeys.length, rowGroupColIds.length); i++) {
      const rowGroupColId = rowGroupColIds[i];
      const rowGroupKey = rowGroupKeys[i];
      if (row[rowGroupColId].value !== rowGroupKey) {
        return false;
      }
    }
    for (let i = 0; i < Math.min(pivotKeys.length, pivotColIds.length); i++) {
      const pivotColId = pivotColIds[i];
      const pivotKey = pivotKeys[i];
      if (row[pivotColId].value !== pivotKey) {
        return false;
      }
    }
    return true;
  });
};

export const getFilterTitle = (rowGroupColIds, rowGroupKeys, pivotColIds, pivotKeys) => {
  let titleParts = [];
  for (let i = 0; i < Math.min(rowGroupKeys.length, rowGroupColIds.length); i++) {
    const rowGroupColId = rowGroupColIds[i];
    const rowGroupKey = rowGroupKeys[i];
    titleParts.push(rowGroupColId + ": " + rowGroupKey);
  }
  for (let i = 0; i < Math.min(pivotKeys.length, pivotColIds.length); i++) {
    const pivotColId = pivotColIds[i];
    const pivotKey = pivotKeys[i];
    titleParts.push(pivotColId + ": " + pivotKey);
  }
  return titleParts.join(", ");
};

// TODO add custom comparators for date derived columns, data is derived server side, ui still needs to know ordering
/*
const dayToOrder = {
  "Monday": 1, "Tuesday": 2, "Wednesday": 3, "Thursday": 4, "Friday": 5, "Saturday": 6, "Sunday": 7
};
const dayOfWeekComparator = (a, b) => {
  return (dayToOrder[a] || 0) - (dayToOrder[b] || 0);
};
 */

export const dataTypeToValueFormat = dataType => {
  if (dataType === "PERCENTAGE") {
    return "PERCENT";
  } else if (dataType === "CURRENCY") {
    return "CURRENCY";
  } else {
    return "NUMBER";
  }
};

export const DownloadButton = React.memo(({onClick}) => {
  if (Users.currentHasPermission("EXPORT_FILE")) {
    return <TextButton testId="grid-download" label="Download" type="inverted" onClick={onClick} style={{zIndex: 1}} />;
  } else {
    return <div style={{display: "inline-block"}}>
      <Tooltip
          text="Ask an admin user for the 'Export To File' permission to download this data"
          position="right">
        <TextButton label="Download" disabled={true} type="inverted" onClick={() => {}} />
      </Tooltip>
    </div>;
  }
});

export const getExportFileName = (config, componentTitle) => {
  let name;
  if (config.has("fileName")) {
    name = config.get("fileName", "").replaceAll(": ", " - ");
  } else if (componentTitle) {
    name = componentTitle;
  } else {
    name = config.get("kpiIds", Immutable.List()).map(kpiId => KpiRepo.get(kpiId).get("name")).join(", ");
  }
  return name + ".xlsx";
};

export const keepGridStateInSync = (componentId, columnLabels, onConfigChange, filterModelRef) => {
  return {
    onFilterChanged: e => {
      const filterModel = Immutable.fromJS(e.api.getFilterModel());
      // NOTE We have to update a ref here to avoid regenerating column states on every filter change.
      //  If we don't use a ref then ag-grid will reset the ui state of the filter panel on every change.
      //  This makes it very difficult to use because all open accordions get closed.
      if (filterModelRef) {
        filterModelRef.current = filterModel;
      }
      onConfigChange(componentId, config => config.set("filterModel", filterModel));
    },
    onColumnRowGroupChanged: e => {
      onConfigChange(componentId, config => config.set("columnStates", getColumnStatesFromApi(e.columnApi, columnLabels)));
    },
    onColumnValueChanged: e => {
      onConfigChange(componentId, config => config.set("columnStates", getColumnStatesFromApi(e.columnApi, columnLabels)));
    },
    onSortChanged: e => {
      const colState = e.columnApi.getColumnState();
      const sortState = colState
          .filter(function (s) {
            return s.sort != null;
          })
          .map(function (s) {
            return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
          });
      onConfigChange(componentId, config => config.set("colOrder", Immutable.List(sortState)));
    },
    onColumnPivotChanged: e => {
      onConfigChange(componentId, config => config.set("columnStates", getColumnStatesFromApi(e.columnApi, columnLabels)));
    },
    onColumnPivotModeChanged: e => {
      onConfigChange(componentId, config => config.setIn(["gridProps", "pivotMode"], e.columnApi.isPivotMode()));
    },
    onColumnVisible: e => {
      onConfigChange(componentId, config => config.set("columnStates", getColumnStatesFromApi(e.columnApi, columnLabels)));
    },
    onColumnPinned: e => {
      onConfigChange(componentId, config => config.set("columnStates", getColumnStatesFromApi(e.columnApi, columnLabels)));
    },
    onColumnMoved: e => {
      onConfigChange(componentId, config => config.set("columnStates", getColumnStatesFromApi(e.columnApi, columnLabels)));
    },
  };
};

const getColumnStatesFromApi = (columnApi, validColumnLabels) => {
  return Immutable.fromJS(columnApi.getColumnState().flatMap(c => {
    const shouldKeepInState = (c.rowGroup || c.pivot || !!c.aggFunc || !c.hide)
        && validColumnLabels.has(c.colId);
    if (shouldKeepInState) {
      return [c];
    } else {
      return [];
    }
  }));
};

const defaultAllowedAggFuncs = ["sum", "avg", "count", "min", "max"];

const dataTypeToAllowedAggFuncs = {
  INTEGER: ["sum", "avg", "count", "min", "max"],
  CURRENCY: ["sum", "avg", "count", "min", "max"],
  STRING: ["count", "min", "max"],
  DATE: ["count", "min", "max"],
  PERCENTAGE: ["avg", "sum", "count", "min", "max"]
};

const getAllowedAggFuncs = column => {
  const allowedAggFuncs = dataTypeToAllowedAggFuncs[column.dataType] || defaultAllowedAggFuncs;
  const customAggFuncs = column.customAggregationFunctions || [];
  return customAggFuncs.concat(allowedAggFuncs);
};

const getValueGetterFn = valueGetter => params => {
  const cellData = params.data && params.data[params.colDef.colId];
  const deepLinkingData = cellData && cellData.deepLinkingData;
  if (getIsDeepLinkable(deepLinkingData)) {
    return `Click to open the ATS record for ${deepLinkingData.crmEntityName} ${deepLinkingData.crmId} in a new tab`;
  } else {
    return valueGetter(params);
  }
};

const getCellClass = params => {
  const cellData = params.data && params.data[params.colDef.colId];
  const deepLinkingData = cellData && cellData.deepLinkingData;
  return getIsDeepLinkable(deepLinkingData) ? 'ag-deep-linked-cell' : null;
};

export const columnsToDefs = (columns, columnStates, valueGetterForKey, valueFormatterForKey) => {
  const labelToColumn = indexByJs(c => c.label, columns);
  const columnLabelToState = indexBy(
      s => s.get("colId"),
      Immutable
          .fromJS(columnStates)
          // NOTE add 1 to the order to avoid 0 = falsey issues when checking for order existence
          .map((state, i) => state.set("order", state.get("order") || (i + 1))))
      .toJS();
  return columns
      .slice()
      .filter(c => c.columnFor === "UI")
      .map(c => {
        const state = columnLabelToState[c.label] || {};
        const valueGetter = valueGetterForKey(c.label);
        const allowedAggFuncs = getAllowedAggFuncs(c);
        const defaultAggFunc = allowedAggFuncs[0];
        const dataType = c.dataType === "DATE" ? "time" : null;
        return {
          chartDataType: dataType,
          hide: true,
          filter: "agSetColumnFilter",
          ...state,
          allowedAggFuncs,
          defaultAggFunc,
          cellClass: getCellClass,
          colId: c.label,
          headerName: c.label,
          comparator: c.comparator,
          pivotComparator: c.comparator,
          valueFormatter: valueFormatterForKey(c.label, labelToColumn),
          valueGetter,
          tooltipValueGetter: getValueGetterFn(valueGetter),
          filterValueGetter: valueGetter
        };
      })
      .sort((a, b) => {
        if (a.order && b.order) {
          return a.order - b.order;
        } else if (a.order) {
          return -1;
        } else if (b.order) {
          return 1;
        } else {
          return a.headerName.localeCompare(b.headerName);
        }
      });
};

export const groupOrderComparator = (a, b) => {
  // TODO custom comparators based on field, lookup from:
  //  a.rowGroupColumn.colId
  //  only a label
  //  could lookup all matching columns and then decide if a custom order exists
  //  define order for an entity column, makes sense superficially
  if (a.key && b.key) {
    return a.key.localeCompare(b.key);
  } else if (a.key) {
    return 1;
  } else if (b.key) {
    return -1;
  } else {
    return 0;
  }
};

export const configToReqParams = config => {

  const timeframe = TimeframeRepo.parse(config.get("timeframe").toJS());
  return {
    dateFromUI: moment().format("YYYY-MM-DD"),
    startDate: timeframe.get("start").format("YYYY-MM-DD"),
    endDate: timeframe.get("end").format("YYYY-MM-DD"),
    clientIds: config.getIn(["clientFilter", "allClientIds"], Immutable.List()).toArray(),
    anyOfTheseTagIds: config.getIn(["tagFilter", "matchAnyTagIds"], Immutable.List()).toArray(),
    allOfTheseTagIds: config.getIn(["tagFilter", "matchAllTagIds"], Immutable.List()).toArray(),
    noneOfTheseTagIds: config.getIn(["tagFilter", "excludedTagIds"], Immutable.List()).toArray(),
    entityToIds: config.get("entityToIds", Immutable.Map()).toObject(),
    ...DashboardUtils.configToQualifierAjaxParams(config)
  };
};

export const addRequiredColumns = (request, columns) => {
  const labelToColumn = indexByJs(c => c.label, columns);

  const allGridColumns = []
      .concat(request.rowGroupCols)
      .concat(request.pivotCols)
      .concat(request.valueCols)
      .concat(request.dataCols);
  for (const gridColumn of allGridColumns) {
    gridColumn.requiredColumns = [];
    const column = labelToColumn[gridColumn.displayName];
    // NOTE column may not be defined if the grid config is out of sync with the currently available columns
    //  perhaps the kpis on the grid changed or the columns on the kpis changed
    if (column) {
      for (const columnBeforeMerge of column.columnsBeforeMerge) {
        gridColumn.requiredColumns.push({
          serverColumn: columnBeforeMerge.serverColumn,
          entityColumnId: columnBeforeMerge.entityColumnId,
          joinPathStr: columnBeforeMerge.joinPathStr,
          derivedFor: columnBeforeMerge.derivedFor
        });
      }
    }
  }
};

// TODO: Move to dashboard utils
// TODO: Change variant to not be a string
// TODO: Add hover shadow - work with zach
// TODO: Make min width 1080
export const ComponentTile = React.memo(({variant = "standard", children}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const tileStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundColor: variant === "transparent" ? "transparent" : theme.palette.background.card,
    borderRadius: variant === "transparent" ? 0 : 5,
    overflow: variant !== "standard" ? "hidden" : "visible",
    boxShadow: variant === "transparent" ? "none" : "0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13)"
  };

  return <div style={tileStyle}>
    {children}
  </div>;
});

export const openChartToolPanelsOnEdit = (isEditing, gridRef) => {
  if (!gridRef || !gridRef.current) {
    return;
  }

  const api = gridRef.current.api;
  // checking for api makes sure the chart and required elements exist
  if (!api) {
    return;
  }
  const gridCharts = api.getChartModels();
  if(isEditing) {
    gridCharts.forEach(c => api.openChartToolPanel({chartId: c.chartId, panel: undefined}));
  } else {
    gridCharts.forEach(c => api.closeChartToolPanel({chartId: c.chartId}));
  }
};

export const reMountChartOnThemeChange = (config, gridRef, chartTheme, chartElRef, unlinked) => {
  const api = gridRef.current?.api;
  if (!api) return;
  const gridCharts = api.getChartModels();
  gridCharts.forEach(c => api.getChartRef(c.chartId).destroyChart());
  mountChart(config, chartTheme, chartElRef.current, api, unlinked);
};

export const getThemes = themeId => {
  return {
    gridTheme: themeId === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark",
    chartTheme: themeId === "light" ? "cube-theme" : "cube-theme-dark"
  };
};

export const chartThemes = ["cube-theme", "cube-theme-dark"];
