import React from "react";
import pure from "js/common/views/pure";

import { TextButton } from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";

export default pure(({
    onRetrieveClick,
    onDiscardClick,
    open,
    lastUpdated,
    customMessage,
    actions = [
        <TextButton
            key="retrieve-changes"
            label="Retrieve changes"
            style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
            onClick={onRetrieveClick} />,
        <TextButton
            key="discard-changes"
            type="primary"
            label="Discard changes"
            style={{ marginLeft: "0.5rem", marginRight: "0.5rem" }}
            onClick={onDiscardClick} />
    ]
}) => (
    <Dialog
        bodyStyle={{ overflow: "visible", color: "#fff" }}
        actionsContainerStyle={{ paddingRight: "2rem" }}
        title={"You have unsaved changes" + (lastUpdated ? " from " + lastUpdated : "")}
        actions={actions}
        autoDetectWindowHeight={true}
        titleStyle={{ color: "#f9ec33", fontSize: "1rem" }}
        open={open}>
        <span>{customMessage ?? "Would you like to recover them?"}</span>
    </Dialog>
));
