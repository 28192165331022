import React from "react";
import Immutable from "immutable";
import * as GraphUtils from "js/squids/graph-utils";
import Drawer from "js/common/views/drawer";
import DelayedTextField from "js/common/views/inputs/delayed-text-field";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Colors from "js/common/cube19-colors";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {IconButton} from "js/common/views/inputs/buttons";

const EditNodeDrawer = React.memo(({
  isEditNodeOpen,
  editNodeIndex,
  setEditNodeIndex,
  setIsEditNodeOpen,
  page,
  handleEditPage,
  handleEditNodeId,
  errors,
  inputDelayMillis,
  tabStyle,
  theme
}) => {

  const nodeId = page.getIn(["nodes", editNodeIndex, "id"]);
  const edgesOut = page.getIn(["nodes", editNodeIndex, "edgesOut"], Immutable.List())
      .map((edge, index) => edge
          .set("relationship", "child")
          .set("index", index)
          .set("id", nodeId + "-" + index));
  const parentIds = GraphUtils.getIdToParentIds(page.get("nodes", Immutable.List()))
      .get(nodeId);
  const parentNodes = parentIds &&
      page
          .get("nodes")
          .filter(node => parentIds.includes(node.get("id")))
          .map(n => n.update("edgesOut", edges => edges.map((edge, index) => edge.set("index", index))));
  const edgesIn = parentNodes ? parentNodes.map(p => {
    const index = p.get("edgesOut").filter(edge => edge.get("nodeId") === nodeId).first().get("index")
  return Immutable.Map({
    nodeId: p.get("id"),
    relationship: "parent",
    index: index,
    label: p
        .get("edgesOut")
        .filter(edge => edge.get("nodeId") === nodeId).first().get("label"),
    id: p.get("id") + "-" + index
  })}) : Immutable.Set();

  const edges = edgesIn.concat(edgesOut);

  const nodeErrors = errors.filter(e => e.getIn(["location", "nodeId"]) === nodeId && e.get("category") === "node");

  const nodeValueErrors = nodeErrors.filter( e => e.getIn(["location", "param"]) === "value");
  const hasValueError = nodeValueErrors.size > 0;
  const nodeLabelErrors = nodeErrors.filter( e => e.getIn(["location", "param"]) === "label");
  const hasLabelError = nodeLabelErrors.size > 0;

  const allEdgeErrors = errors
      .filter(e => e.get("category") === "edge")
      .map(e => e.set("id", e.getIn(["location", "nodeId"]) + "-" + e.getIn(["location", "param", 1])));

  const edgeIds = edges.map(e => e.get("id"));
  const edgeErrors = allEdgeErrors.filter(e => edgeIds.includes(e.get("id")));

  const handleUpdateEdgeLabel = (value, edge) => {

    const childNodeId = edge.get("relationship") === "child" ? edge.get("nodeId") : nodeId;
    const parentNodeIndex = edge.get("relationship") === "child" ? editNodeIndex :
        page.get("nodes")
            .findIndex(node => node.get("id") === edge.get("nodeId"));

    const updatedEdges = page
        .getIn(["nodes", parentNodeIndex, "edgesOut"])
        .map(e => e.get("nodeId") === childNodeId ? e.set("label", value) : e);

    handleEditPage(page.setIn(["nodes", parentNodeIndex, "edgesOut"], updatedEdges));
  };

  const handleDrawerClose = () => {
    setIsEditNodeOpen(false);
    setEditNodeIndex(null);
  };

  const handleDeleteNode = () => {
    handleEditPage(
        page
            .update("nodes", nodes =>
                nodes
                    .filter(node => node.get("id") !== nodeId)
                    .map(node => node.update("edgesOut", Immutable.Map(), edges => edges.filter(edge => edge.get("nodeId") !==
                        nodeId)))
            )
    );

    setIsEditNodeOpen(false);
    setEditNodeIndex(null);
  };

  const isOnlyNode = page.get("nodes").size === 1;

  return (
    <Drawer open={isEditNodeOpen}
                   title={"Edit Node " + nodeId}
                   showCloseButton
                   onRequestClose={handleDrawerClose}
                   onRequestOpen={() => setIsEditNodeOpen(true)}
                   openRight={true}
                   minWidth={700}
                   width="60%">
      <>
        <div style={{borderBottom: `1px solid ${Colors.blueBorder}`}}>
          <Tabs style={{marginBottom: 5, marginTop: -10}}
                value="Settings"
                TabIndicatorProps={{
                  style: {
                    display: "none"
                  }
                }}>
            <Tab style={{...tabStyle, color: Colors.accentPurple}}
                 label={<div><i className="fa fa-cog" style={{paddingRight: "5px"}} />Settings</div>}
                 value="Settings"
                 key="Settings" />;
          </Tabs>
        </div>
        <div style={{
          margin: 30,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}>
          <div>
            <div style={{display: "flex"}}>
            <DelayedTextField
                label="Node ID"
                theme="cube2021"
                disabled
                delayInMillis={inputDelayMillis}
                style={{width: "100%"}}
                InputProps={{disableUnderline: true}}
                value={page.getIn(["nodes", editNodeIndex, "id"]) || ""}
                onChange={(value) => handleEditPage(page.setIn(["nodes", editNodeIndex, "id"], value.replaceAll(" ","")))} />
              <IconButton
                size="large"
                icon="pen"
                type="bright"
                hoverType="bright"
                onClick={handleEditNodeId}
                style={{
                  background: theme === "light" ? Colors.white : Colors.charade,
                  color: "#AB7DF6",
                  height: 42,
                  width: 40,
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  alignSelf: "flex-end",
                  marginLeft: 10,
                  marginBottom: 10}} />
            </div>
            <DelayedTextField
                label="Node value formula"
                theme="cube2021"
                style={{width: "100%"}}
                delayInMillis={inputDelayMillis}
                error={hasValueError}
                helperText={hasValueError && nodeValueErrors.first().get("message")}
                InputProps={{disableUnderline: true}}
                value={page.getIn(["nodes", editNodeIndex, "value"]) || ""}
                onChange={(value) => handleEditPage(page.setIn(["nodes", editNodeIndex, "value"], value))} />
            <DelayedTextField
                label="Node label"
                theme="cube2021"
                style={{width: "100%"}}
                delayInMillis={inputDelayMillis}
                error={hasLabelError}
                helperText={hasLabelError && nodeLabelErrors.first().get("message")}
                InputProps={{disableUnderline: true}}
                value={page.getIn(["nodes", editNodeIndex, "label"]) || ""}
                onChange={(value) => handleEditPage(page.setIn(["nodes", editNodeIndex, "label"], value))} />
            <div style={{paddingTop: 25}}>Edges</div>
            <div style={{display: "flex", paddingTop: 20, paddingBottom: 10, fontSize: "0.8rem"}}>
              <div style={{width: "33%"}}>Relationship</div>
              <div style={{width: "33%", paddingLeft: 10}}>Linked Node ID</div>
              <div style={{width: "33%", paddingLeft: 15}}>Edge Label</div>
            </div>
            {edges.map(edge => {

              const isErroring = edgeErrors.map(e => e.get("id")).includes(edge.get("id"));
              const error = edgeErrors.filter(e => e.get("id").includes(edge.get("id")));

              return <div key={edge.get("nodeId")} style={{display: "flex"}}>
                <DelayedTextField
                    theme="cube2021"
                    disabled
                    delayInMillis={inputDelayMillis}
                    style={{width: "100%", marginRight: 15}}
                    InputProps={{disableUnderline: true}}
                    value={edge.get("relationship")} />
                <DelayedTextField
                    theme="cube2021"
                    delayInMillis={inputDelayMillis}
                    disabled
                    style={{width: "100%", marginRight: 15}}
                    InputProps={{disableUnderline: true}}
                    value={edge.get("nodeId")} />
                <DelayedTextField
                    theme="cube2021"
                    delayInMillis={inputDelayMillis}
                    style={{width: "100%"}}
                    InputProps={{disableUnderline: true}}
                    error={isErroring}
                    helperText={isErroring && error.first().get("message")}
                    value={edge.get("label")}
                    onChange={(value) => handleUpdateEdgeLabel(value, edge)} />
              </div>
            })}
          </div>
          <TextButton label="Delete Node"
                      disabled={isOnlyNode}
                      style={{
                        marginTop: "20px",
                        color: Colors.white,
                        backgroundColor: Colors.redIcon,
                        textTransform: "none"
                      }}
                      onClick={handleDeleteNode} />
        </div>
      </>
    </Drawer>
  );
});

export default EditNodeDrawer;
