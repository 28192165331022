import React, {useCallback} from "react";

import * as ajax from "js/common/ajax";
import AdminHeader from "js/admin/common/admin-header";
import Step from "js/admin/common/step-number";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import MultipleUserGroupPicker from "js/common/views/inputs/multiple-user-group-picker/multiple-user-group-picker";
import StatePicker from "js/common/views/inputs/state-picker/state-picker";
import {TextButton} from "js/common/views/inputs/buttons";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {Button} from "@mui/material";
import Immutable from "immutable";
import FileSaver from "browser-filesaver";
import {CsvUpload} from "js/common/csv-upload";
import UploadedFilesTable from "js/admin/common/uploaded-files-table";

const downloadCsv = (csv, header) => {
  const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
  FileSaver.saveAs(blob, header);
};

export default React.memo(() => {
  const {theme} = React.useContext(CustomThemeContext);
  const [selectedUserIds, setSelectedUserIds] = React.useState([]);
  const [selectedStates, setSelectedStates] = React.useState([]);
  const [selectedJobId, setSelectedJobId] = React.useState();
  const [isDownloading, setIsDownloading] = React.useState(false);
  const usersString = selectedUserIds.length > 0 ? `u[]=${selectedUserIds.join("&u[]=")}` : "";
  const statusString = selectedStates.length > 0 ? `&s[]=${selectedStates.join("&s[]=")}` : "";
  const queryString = (usersString.length > 0 || statusString.length > 0) ? `?${usersString}${(usersString.length
      > 0
      && statusString.length
      > 0) ? "&" : ""}${statusString}` : "";

  const handleDownloadAll = useCallback(() => {
    setIsDownloading(true);
    ajax.get({
      url: "/user-upload/backup-users.csv",
      returnFileName: true
    }).then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      setIsDownloading(false);
    });
  }, [setIsDownloading]);

  const handleFilteredDownload = useCallback(() => {
    setIsDownloading(true);
    ajax.get({
      url: "/user-upload/users.csv" + queryString,
      returnFileName: true
    }).then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      setIsDownloading(false);
    });
  }, [setIsDownloading, queryString]);

  return (
      <div>
        <AdminHeader>
          <Step step="1" />
          Backup & Download
        </AdminHeader>
        <div style={{padding: "0 1rem 1rem 1rem"}}>
          <Hint>
            <Icon icon="info" style={{color: theme.palette.hints.text}} />
            We recommend that you use the backup all users button to backup your current user permissions before you
            begin.
          </Hint>
          <div style={{display: "flex", gap: "0 0.8em", marginBottom: "1em"}}>
            <Button variant={"outlined"} disabled={isDownloading} onClick={() => handleDownloadAll()}>
              {isDownloading ? "Downloading" : "Backup All Users"}
            </Button>
          </div>
          <p style={{fontSize: "0.85rem"}}>Modify the downloaded users file by adding, updating, or deleting users to
            prepare it for uploading for bulk updates. New users can be added as a new row in the file. Existing users
            can be updated or flagged for deletion.</p>
          <div style={{display: "flex"}}>
            <div style={{width: 400, marginRight: 10}}>
              <MultipleUserGroupPicker
                  type="USER"
                  onChange={(userIds) => {
                    setSelectedUserIds([]);
                    setSelectedUserIds(userIds);
                  }}
                  excludeNewAndInactive={true} />
            </div>
            <div style={{width: 400, marginRight: 10}}>
              <StatePicker
                  onChange={(states) => {
                    setSelectedStates([]);
                    setSelectedStates(states);
                  }} />
            </div>
            <TextButton
                icon="download"
                label={isDownloading ? "Downloading" : "Download"}
                type="primary"
                disabled={isDownloading}
                onClick={() => handleFilteredDownload()} />
          </div>
        </div>
        <AdminHeader>
          <Step step="2" />
          Upload
        </AdminHeader>
        <CsvUpload
            onUploadSuccess={(request) => setSelectedJobId(request.body.id)}
            theme={theme}
            requiresLocale={false}
            csvLabel="Users"
            uploadUrl="/ApplicationLayer/user-upload/upload" />
        <AdminHeader>
          <Step step="3" />
          Status of uploaded files
        </AdminHeader>
        <UploadedFilesTable type="users" url="user-upload/jobs" selectedJobId={selectedJobId} />
      </div>
  );
});