import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";

import pure from "js/common/views/pure";
import { TextButton } from "js/common/views/inputs/buttons";
import ErrorMsg from "js/common/views/error";

import {apiUrl} from "js/app-configuration";

import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";

import eventBus from "js/cube19.event-bus";
import Checkbox from 'js/common/views/inputs/checkbox';
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants"

const Gdpr = createReactClass({

    mixins: [ PureRenderMixin ],

    getInitialState() {
        const currentUser = Immutable.fromJS(Users.getCurrentUser().toJSON());
        return {
            isUpdating: false,
            currentUser,
            error: null
        };
    },

    render() {
        const { currentUser, isUpdating, error } = this.state;
        const { theme } = this.props;
        const email = currentUser.get("email");
        const isUserSubscribed = currentUser.get("notifySubprocessChange");
        const labelNotSubscribed = `Subscribe to notifications of changes to our sub-processors (will be sent to ${email})`;
        const labelSubscribed = `You are subscribed to receive notification of changes to our sub-processors (will be sent to ${email})`;
        const buttonStyle = {
            textAlign: "left",
            width: 600,
            paddingLeft: "1rem",
            paddingRight: "1rem"
        };
        return (
            <div style={{ padding: "1rem" }}>
                <GdprStatement theme={theme}/>
                <TextButton
                    icon="file-text"
                    label="Download current list of sub-processors"
                    link={apiUrl + "/ApplicationLayer/gdpr/sub-processor-list.pdf"}
                    style={{
                        ...buttonStyle,
                        marginTop: "1rem",
                        marginBottom: "0.5rem"
                    }} />
                <Checkbox
                    label={isUserSubscribed ? labelSubscribed : labelNotSubscribed}
                    checked={isUserSubscribed}
                    onCheck={(e, isChecked) => this.handleOptInToggle(isChecked, theme)}
                    disabled={isUpdating}
                    style={{ marginTop: 0, marginBottom: "1rem", display: "block" }} />
                {error && <ErrorMsg text={error} />}
            </div>
        );
    },

    handleOptInToggle(isChecked, theme) {
        this.setState({
            isUpdating: true
        });
        ajax
            .put({ url: "users/current/notify-subprocess-change" })
            .then(result => {
                const currentUser = Immutable.fromJS(result);
                this.setState({
                    isUpdating: false,
                    currentUser
                });
                eventBus.trigger("current-user:updated");
            }, () => {
                this.setState({
                    isUpdating: false,
                    error: `An unexpected error has occurred. ${Branding.submitTicketString}`
                });
            });
    }

});

const containerStyle = {
    paddingBottom: "1rem",
    textRendering: "optimizeLegibility"
};

const sectionStyle = {
    marginTop: "1rem",
    marginBottom: "2rem"
};

const h1Style = theme =>({
    fontSize: "1.875rem",
    color: theme.palette.primary.main,
    marginTop: 0,
    marginBottom: "1.75rem"
});

const h2Style = theme =>({
    fontSize: "1.2rem",
    color: theme.palette.text.main,
    lineHeight: 2,
    borderBottom: `2px solid ${theme.palette.text.main}`,
    marginTop: "1rem",
    marginBottom: "1.5rem"
});

const h3Style = {
    fontFamily: "inherit",
    fontSize: "1.2rem",
    textTransform: "none",
    lineHeight: 1.4,
    marginBottom: "1.5rem",
    fontWeight: 300
};

const listStyle = {
    margin: "1rem",
    listStyle: "none",
    listStylePosition: "inside",
    marginBottom: "2.25rem"
};

const listItemStyle = theme =>({
    color: theme.palette.primary.main,
    paddingRight: 10,
    fontSize: "0.85rem",
    verticalAlign: "top",
    lineHeight: "30px"
});

const GdprStatement = pure(({theme}) => (
    <div style={containerStyle}>
        <section style={sectionStyle}>
            <h1 style={h1Style(theme)}>
                GDPR Compliance, Contracts and {Branding.brandingName}
            </h1>
            <h3 style={h3Style}>
                The GDPR is an important new piece of legislation that brings significant change to the way that all organisations handle data relating to individuals ("personal data").
            </h3>
            <p>
                At {Branding.brandingName} we have ensured that our product complies with the legislation which was enacted on May 25 2018. If you were a customer before this date your contract may not contain the relevant GDPR clauses.  If this is the case, there is a link below to a pre-signed Data Processing Addendum which you can download, counter-sign and return to us.
            </p>
            <p>
                The information provided in this section is not intended to constitute a "privacy policy" or full and final list; instead it is intended to inform our customers of what we are doing to ensure we comply with GDPR.
            </p>
            <p>
                We'll continue to monitor guidance around GDPR and will update this document accordingly as things change. Please check back here for the latest information.
            </p>
        </section>
        <section style={sectionStyle}>
            <h2 style={h2Style(theme)}>
                Personal Data {Branding.brandingName} Holds
            </h2>
            <p>
                The following personal data is sent to {Branding.brandingName} from your CRM:
            </p>
            <p>
                Your employees:
            </p>
            <ul style={listStyle}>
                <ListItem label="Name" theme={theme}/>
                <ListItem label="Email address" theme={theme}/>
                <ListItem label="Photograph (if you upload it)" theme={theme}/>
            </ul>
            <p>
                Your candidates:
            </p>
            <ul style={listStyle}>
                <ListItem label="Name" theme={theme}/>
                <ListItem label="Email address" theme={theme}/>
                <ListItem label="Phone number" theme={theme}/>
                <ListItem label="Job title" theme={theme}/>
            </ul>
            <p>
                Your client contacts:
            </p>
            <ul style={listStyle}>
                <ListItem label="Name" theme={theme}/>
                <ListItem label="Email address" theme={theme}/>
                <ListItem label="Phone number" theme={theme}/>
                <ListItem label="Job title" theme={theme}/>
            </ul>
            <p>
                {Branding.brandingName} also holds details about the targets set for your consultants, and may hold notes relating to these targets.
            </p>
            <p>
                {Branding.brandingName} does not hold any special categories of personal data (previously known as "sensitive" personal data) in relation to your employees, candidates or client contacts.
            </p>
            <p>
                At your request, {Branding.brandingName} may have been loading 'Additional Data' fields containing personal data. All these fields have been reviewed and will be redacted in {Branding.brandingName} when a delete request is processed, in which case you will see "[deleted]".
            </p>  
        </section>
        <section style={sectionStyle}>
            <h2 style={h2Style(theme)}>
                Why Does {Branding.brandingName} Need This Data
            </h2>
            <p>
                {Branding.brandingName} provides a SaaS based growth analytics, business intelligence and performance management application which has been designed exclusively for recruitment companies.
            </p>
            <p>
                The solution is pre-integrated with multiple recruitment CRM's and is designed to deliver relevant, near real-time performance data to people at all levels of your business.
            </p>
            <p>
                {Branding.brandingName} drives better usage and adoption of your recruitment CRM by creating a data-driven environment and engaging all users, in particular the recruiters who are relied upon to record critical business information.
            </p>
            <p>
                A richer and more accurate data set allows for more advanced analysis of company, team and individual performance as well as fast analysis over client delivery and live jobs. When combined with the advanced reporting capabilities in {Branding.brandingName}, the actionable insights that are provided will help drive data-driven decision making across your business.
            </p>
            <p>
                To deliver this service, we need data about the activities recorded in your CRM to be sent to us.
            </p>
        </section>
        <section style={sectionStyle}>
            <h2 style={h2Style(theme)}>
                {Branding.brandingName} Security Infrastructure
            </h2>
            <p>
                {Branding.brandingName} stores your data inside Amazon Web Services (AWS), currently in Ireland. AWS are renowned for having excellent security practices, and as expected follow current ISO standards (<ExternalLink label="read more about ISO here" url="https://aws.amazon.com/compliance/iso-27001-faqs/" title="ISO 27001 Compliance - Amazon Web Services (AWS)" theme={theme}/> and <ExternalLink label="security practices here" url="https://d0.awsstatic.com/whitepapers/Security/AWS_Security_Whitepaper.pdf" title="Amazon Web Services: Overview of Security Processes" theme={theme}/>).
            </p>
            <p>
                Within AWS your data is kept in accordance with their security best practices (<ExternalLink label="read more here" url="https://docs.aws.amazon.com/quickstart/latest/linux-bastion/architecture.html" title="Architecture - Linux Bastion Hosts on the AWS Cloud" theme={theme}/>).
            </p>
            <p>
                {Branding.brandingName} employees have access to your data on a Least-Privileged basis, meaning only the employees who absolutely need access have access, and they have the lowest level of access possible to do their job.
            </p>
            <p>

            </p>
            <p>

            </p>
        </section>
        <section style={sectionStyle}>
            <h2 style={h2Style(theme)}>
                How Does {Branding.brandingName} Keep Your Data Accurate & Up-to-date
            </h2>
            <p>
                {Branding.brandingName} reflects the data in your CRM. When a record is updated in your CRM, that update is sent through to {Branding.brandingName} - this includes when personal data is redacted or anonymised in our CRM for the purpose of GDPR compliance.
            </p>
            <p>
                If a record is deleted in your CRM, {Branding.brandingName} may keep a copy of that record to allow for tracking of historical activity. To ensure GDPR compliance, {Branding.brandingName} maintains a list of all fields that may contain personal data and upon receiving a deletion request from your CRM (whether GDPR related or not) will redact those fields and mark the record as deleted. This ensures that the personal data is removed while allowing {Branding.brandingName} to continue to give an accurate reflection of the historical activity your business has put in.
            </p>
        </section>
        <section style={sectionStyle}>
            <h2 style={h2Style(theme)}>
                Data Subject Rights
            </h2>
            <p>
                As we are your data processor, we will pass any data subject right requests we receive in respect of your data to you, and will not respond to those requests without your approval.
            </p>
            <p>
                To the extent the data we hold is not a mirror of the personal data contained in your CRM (e.g. where the right to rectification or erasure has been exercised), we will assist you in responding to these requests.
            </p>
        </section>
        <section style={sectionStyle}>
            <h2 style={h2Style(theme)}>
                {Branding.brandingName} Policies & Procedures for GDPR
            </h2>
            <p>
                As noted above, {Branding.brandingName} acts a data processor. You can download our updated data processing terms from the link on this page.
            </p>
            <div style={{ paddingTop: "2rem" }}>
                <BackToTopBtn theme={theme}/>
            </div>
        </section>
    </div>
), "GdprStatement");

const ListItem = pure(({ label, theme }) => (
    <li style={{ lineHeight: 2 }}>
        <i className="fa fa-circle" style={listItemStyle(theme)} />
        <span>{label}</span>
    </li>
));

const ExternalLink = pure(({ label, url, title, theme }) => {
    const linkStyle = theme =>({
        color: theme.palette.textColor,
        fontWeight: "bold",
        textDecoration: "none",

        ":hover": {
            color: theme.palette.primary.main,
            textDecoration: "underline"
        }
    });
    return (
        <a href={url} title={title} target="_blank" style={linkStyle(theme)}>
            {label}
        </a>
    );
});

const BackToTopBtn = pure(({theme}) => {
    const linkStyle = theme =>({
        color: theme.palette.textColor,
        textDecoration: "none",

        ":hover": {
            color: theme.palette.primary.main,
            textDecoration: "underline"
        }
    });
    const labelStyle = {
        textTransform: "uppercase",
        paddingLeft: 5,
        paddingRight: 5
    };
    return (
        <a href="#" title="Back to top" style={linkStyle(theme)}>
            <i key="icon" className="fa fa-arrow-circle-up" />
            <span key="label" style={labelStyle}>
                Back To Top
            </span>
        </a>
    );
});

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <Gdpr theme={theme} {...props} />;
};