import Immutable from "immutable";
import moment from "moment";

import * as currencyRepo from "js/common/repo/backbone/currency-repo";
import * as tagRepo from "js/common/repo/backbone/tag-repo";
import currentClient from "js/common/repo/backbone/current-client";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import {dayOfWeekColumnDeriver, customDateBinDeriver} from "js/common/utils/snd-date-columns";
import * as Formatter from "js/common/utils/formatter"
import {indexBy} from "js/common/utils/collections";
import {getSymbol} from "js/common/repo/backbone/currency-repo";

const $ = window.$;

const getDerivedAttributes = (data, weekStartsOn) => {
    const record = data[0] || {};
    const requiredDerivers = {};

    const client = currentClient;
    const startingMonth = client.get("financialYearStartMonth");
    const days = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY"
    ];
    const dayWeekStartsOn = weekStartsOn.toUpperCase();
    const weekStartsOnIndex = days.findIndex(day => dayWeekStartsOn === day);

    Object
        .keys(record)
        .forEach(columnName => {
            if (!columnName.includes("Date")) {
                return;
            }

            const weekColumnName = `${columnName} (Week)`;
            requiredDerivers[weekColumnName] = record => {
                let actualDate = record[columnName];
                if (moment(actualDate, "YYYY/MM/DD").isValid()) {
                    actualDate = moment(actualDate, "YYYY/MM/DD");
                } else {
                    return "";
                }

                const originalDayOfWeek = actualDate.day();
                const startOfWeek = actualDate.day(weekStartsOnIndex);
                if (originalDayOfWeek < weekStartsOnIndex) {
                    startOfWeek.subtract(1, "weeks");
                }
                return `Week of ${startOfWeek.format("YYYY/MM/DD")}`;
            };
            requiredDerivers[`${columnName} (Day of Week)`] = dayOfWeekColumnDeriver(columnName);

            if (client.hasPermission("USES_445_CALENDAR")) {
                const calendarMonthColumn = `${columnName} (Calendar Month)`;
                requiredDerivers[calendarMonthColumn] = customDateBinDeriver(columnName, "%y %m", startingMonth);
            } else {
                const yearColumn = `${columnName} (Year)`;
                requiredDerivers[yearColumn] = customDateBinDeriver(columnName, "%y", startingMonth);

                const quarterColumn = `${columnName} (Quarter)`;
                requiredDerivers[quarterColumn] = customDateBinDeriver(columnName, "%y %q", startingMonth);

                const monthColumn = `${columnName} (Month)`;
                requiredDerivers[monthColumn] = customDateBinDeriver(columnName, "%y %m", startingMonth);
            }
        });
    return requiredDerivers;
};

const getSortersForSliceAndDice = attrNameStr => {
    const attrName = attrNameStr.length > 0 ? attrNameStr.toLowerCase() : "";
    if (attrName === "kpi name") {
        const kpiNames = Immutable
            .fromJS(kpiRepo.getAll().toJSON())
            .sortBy(k => k.get("order"))
            .map(k => k.get("name"))
            .toArray();
        return $.pivotUtilities.sortAs(kpiNames);
    } else if (attrName.includes("(day of week)")) {
        const daysOfTheWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        return $.pivotUtilities.sortAs(daysOfTheWeek);
    }
};

const cellValueRequired = aggregator => {
    const aggregatorsThatDoNotRequireCellValue = [ "Count as Fraction of Total", "Count All" ];
    return !aggregatorsThatDoNotRequireCellValue.includes(aggregator);
};

const isDataTableTypeReport = renderer => {
    const downloadableReportTypes = Immutable
        .fromJS([
            "Table",
            "Table Barchart",
            "Heatmap",
            "Row Heatmap",
            "Col Heatmap"
        ])
        .toSet();
    return downloadableReportTypes.includes(renderer);
};

const downloadTableAsExcelFile = tableHtml => {
    tableHtml = tableHtml
        .replace(/>\s*=/g, ">")
        .replace(/>\s*\+/g, ">\t+")
        .replace(/>\s*-/g, ">\t-")
        .replace(/>\s*@/g, ">\t@");
    let excelFile = "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:x='urn:schemas-microsoft-com:office:excel' xmlns='http://www.w3.org/TR/REC-html40'>";
    excelFile += "<head>";
    excelFile += "<meta charset=\"utf-8\">";
    excelFile += "<!--[if gte mso 9]>";
    excelFile += "<xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>";
    excelFile += "<x:Name>Data</x:Name>";
    excelFile += "<x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>";
    excelFile += "</x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml>";
    excelFile += "<![endif]-->";
    excelFile += "</head>";
    excelFile += "<body><table>" + tableHtml + "</table></body>";
    excelFile += "</html>";
    return new Blob([excelFile], {type: "application/vnd.ms-excel;charset=utf-8", endings: "native"});
};

const parseToPivotData = (columns, rows) => {
    const parsedRows = [];
    const categoryLabels = new Set();

    rows.forEach((row, rowIndex) => {
        const parsedRow = {
            rowType: "default"
        };

        columns.forEach((col, colIndex) => {
            const currentCell = row[colIndex];
            const dataTypeOfCell = col.dataType;
            let formattedCellValue = formatCellValue(dataTypeOfCell, currentCell.value);

            if (dataTypeOfCell === "CURRENCY") {
                const currencyCodeColName = col.label + " Currency";
                const currencySymbol = currencyRepo.getSymbol(currentCell.currency);
                if (currencySymbol) {
                    parsedRow[currencyCodeColName] = currencySymbol;
                } else {
                    parsedRow[currencyCodeColName] = "N/A";
                }
            }
            if (col.label === "Kpi value currency") {
                formattedCellValue = currencyRepo.getSymbol(formattedCellValue);
            }

            if (currentClient.canAccessApp("TAGS_ADMIN")) {
                if (col.label === "__tagCache__") {
                    const tagCache = currentCell.value;
                    if (tagCache) {
                        tagCache
                            .split(",")
                            .map(tagId => tagRepo.get(tagId))
                            .filter(tag => !!tag)
                            .forEach(tag => {
                                const categoryLabel = "Tag for " + tag.category.get("name");
                                const previous = parsedRow[categoryLabel] ? parsedRow[categoryLabel] + ", " : "";
                                parsedRow[categoryLabel] = previous + tag.get("name");
                                categoryLabels.add(categoryLabel);
                            });
                    }
                }
            }

            parsedRow[col.label] = formattedCellValue;
        });

        parsedRows[rowIndex] = parsedRow;
    });

    if (currentClient.canAccessApp("TAGS_ADMIN")) {
        parsedRows.forEach(parsedRow => {
            categoryLabels.forEach(tagCategoryName => {
                parsedRow[tagCategoryName] = parsedRow[tagCategoryName] || "No tag";
            });
        });
    }

    return parsedRows;
};

const formatCellValue = (type, cellValue) => {
    switch (type) {
    case "DATE":
        return cellValue === "" ? "" : moment(cellValue, "YYYY-MM-DD").format("YYYY/MM/DD");
    case "PERCENTAGE":
        return Formatter.format({value: cellValue}, {valueFormat: "PERCENT", decimalPlaces: 2});
    case "CURRENCY":
        return Formatter.format({value: cellValue}, {showCurrency: false, valueFormat: "CURRENCY", decimalPlaces: 2})
    case "STRING":
        // NOTE this null check was added specifically for additional data columns
        return cellValue === null ? "" : cellValue;
    default:
        return cellValue;
    }
};

const getDefaultRenderers = () => {
    const renderers = {
        ...$.pivotUtilities.renderers,
        ...$.pivotUtilities.gchart_renderers,
        ...$.pivotUtilities.d3_renderers
    };
    delete renderers["Scatter Chart"];
    return renderers;
};

const getDefaultRendererOptions = (theme) => Immutable.fromJS({
    "gchart": {
        "backgroundColor": { fill: theme.palette.background.card },
        "colors": [
            "#668CFF",
            "#D966FF",
            "#BFFF00",
            "#FFBF00",
            "#8C66FF",
            "#00B285",
            "#85004b",
            "#FF5C26",
            "#4D7AFF",
            "#DC73FF",
            "#00FFBF",
            "#e40045",
            "#530fad",
            "#00a383",
            "#6996d3",
            "#f93e58",
            "#3c9dd0",
            "#ffba00",
            "#d235d2",
            "#ffe800",
            "#8ef13c",
            "#04859d",
            "#ff1800",
            "#ff6200",
            "#00a480",
            "#0f4fa8",
            "#ffeb40",
            "#956bd6",
            "#ff3d00",
            "#cd0074",
            "#ffbf40",
            "#5ccccc",
            "#ffff40",
            "#e6399b",
            "#ffd073",
            "#3016b0",
            "#85eb6a",
            "#ffcd00",
            "#f3fd00",
            "#36bbce",
            "#ffc340"
        ],
        "fontName": "Oxygen-regular",
        "fontSize": "12",
        "hAxis": {
            "slantedText": true,
            "slantedTextAngle": 30,
            "baselineColor": "#68737b",
            "gridlines": {
                "color": "#68737b"
            },
            "textStyle": {
                "color": theme.palette.text.main,
                "fontSize": "12"
            }
        },
        "vAxis": {
            "baselineColor": "#68737b",
            "gridlines": {
                "color": "#68737b"
            },
            "textStyle": {
                "color": theme.palette.text.main
            }
        },
        "titleTextStyle": {
            "fontName": "Oxygen-regular",
            "color": theme.palette.text.main
        },
        "legend": {
            "textStyle": {
                "color": theme.palette.text.main,
                "fontSize": "12"
            }
        }
    }
});

const prepareDataForClickThrough = (data, filter, derivedAttributes) => {
    const derivedAttributesToRemove = Immutable.Set(Object.keys(derivedAttributes ?? {}));
    const rawRows = data.rows.filter(row => {
      const colToValue = indexBy((value, index) => data.columns[index].label, Immutable.fromJS(row))
          .map(val => val.get("value"));
      const rowMatchesFilter = filter.every((filterValue, key) => {
          const colIndex = data.columns.findIndex(col => col.label === key);
          if (derivedAttributesToRemove.includes(key)) {
              const derivedValue = derivedAttributes[key](colToValue.toJS());
              return filterValue === derivedValue;
          } else if (filterValue === "" && (row[colIndex].value === "" || row[colIndex].value === null)) {
              return true;
          } else if (key.includes("Tag for")) {
              const tagCache = Immutable.fromJS(colToValue.get("__tagCache__"));
              const tagList = tagCache.length > 0 ? Immutable.fromJS(tagCache.split(",")) : Immutable.List();
              const tags = tagList.map(t => tagRepo.get(t));
              const categoryToTags = tags
                  .groupBy(t => t.category.attributes.name)
                  .map(cat => cat
                      .map(tag => tag.attributes.name)
                      .join(", "));
              const category = categoryToTags.get(key.replace("Tag for ", ""));
              return (filterValue === "No tag" && !category) ||
                  (category && category.includes(filterValue));
          } else if (key.toLowerCase().includes("currency")) {
              if(colIndex === -1) {
                  //if currency column is not found, need to retrieve currency from the relevant row
                  const valueLabel = key.replace(" Currency", "");
                  const valueColIndex = data.columns.findIndex(col => col.label === valueLabel);
                  const valueCurrency = row[valueColIndex].currency;
                  return getSymbol(valueCurrency) === filterValue
              } else {
                  return getSymbol(row[colIndex].value) === filterValue
              }
          }
          else {
              const dataType = data.columns[colIndex].dataType;
              return formatCellValue(dataType, row[colIndex].value) === filterValue;
          }
      });
        const isRealRow = row.rowType !== "userWithNoData";
        return rowMatchesFilter && isRealRow;
    });
    if (rawRows.length === 0) {
        return null;
    }

    let cleanRows = Immutable.List();
    rawRows.forEach(row => {
        const columnLabels = Object.keys(row);
        let cleanRow = {};
        const categoryLabels = new Set();

        // NOTE Remove all blank/invalid cells
        columnLabels.forEach(columnLabel => {
            const value = row[columnLabel];
            let isUseful = true;
            isUseful = isUseful && (!!value || value === 0);
            isUseful = isUseful && value !== "Invalid date";
            isUseful = isUseful && value !== "N/A";
            isUseful = isUseful && columnLabel !== "rowType";
            isUseful = isUseful && columnLabel !== "__tagCache__";
            isUseful = isUseful && !derivedAttributesToRemove.includes(columnLabel);
            // NOTE exclude currency field because it'll be merged with the monetary value cell later
            isUseful = isUseful && columnLabel.toLowerCase().indexOf(" currency") === -1;
            if (isUseful) {
                cleanRow[columnLabel] = value;
            }


            if (currentClient.canAccessApp("TAGS_ADMIN")) {
                if (columnLabel === "__tagCache__") {
                    const tagCache = value;
                    if (tagCache) {
                        tagCache
                            .split(",")
                            .map(tagId => tagRepo.get(tagId))
                            .filter(tag => !!tag)
                            .forEach(tag => {
                                const categoryLabel = "Tag for " + tag.category.get("name");
                                const previous = cleanRow[categoryLabel] ? cleanRow[categoryLabel] + ", " : "";
                                cleanRow[categoryLabel] = previous + tag.get("name");
                                categoryLabels.add(categoryLabel);
                            });
                    }
                }
            }

        });

        if (currentClient.canAccessApp("TAGS_ADMIN")) {
            cleanRows.forEach(parsedRow => {
                categoryLabels.forEach(tagCategoryName => {
                    parsedRow[tagCategoryName] = parsedRow[tagCategoryName] || "No tag";
                });
            });
        }

        columnLabels.forEach(columnLabel => {
            // NOTE delete non-monetary zero valued fields
            if (cleanRow[columnLabel] === 0) {
                delete cleanRow[columnLabel];
            }
        });

        cleanRows = cleanRows.push(cleanRow);
    });

    const columns = data.columns;

    const rows = cleanRows.map(cleanRow => {
        let rowArr = Immutable.List();
        columns.forEach((column, index)=> {
            const value = cleanRow[index];
            const valueLooksNice = !!value || value === 0;
            rowArr = rowArr.push(valueLooksNice ? value : "");
        });
        return rowArr;
    });

    if (columns.length === 0) {
        return null;
    }

    const title = filter
        .map((value, key) => `${key}: ${value}`)
        .join(", ");

    return Immutable.fromJS({
        title,
        columns,
        rows
    });
};

export {
	parseToPivotData,
	prepareDataForClickThrough,
	getDefaultRendererOptions,
	getDefaultRenderers,
    getDerivedAttributes,
    getSortersForSliceAndDice,
    cellValueRequired,
    isDataTableTypeReport,
    downloadTableAsExcelFile
};
