import React, {useState} from "react";
import Immutable from "immutable";
import {Dialog, DialogContent} from "@mui/material";

import {betterMemo} from "js/common/utils/more-memo";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import {templateToLabel} from "js/admin/kpis/edit-kpis/utils";

const keyToLabel = Immutable.Map({
  templateId: "template"
});

const formatData = val => {
  if (!val) {
    return val;
  }

  if (Immutable.isImmutable(val)) {
    return JSON.stringify(val.toJSON());
  }

  if (val === "NONE") {
    return "";
  }

  const mightBeJson = typeof (val) === "string" && (val.indexOf("{") === 0 || val.indexOf("[") === 0);
  if (mightBeJson) {
    try {
      return JSON.stringify(JSON.parse(val), null, 2);
    } catch (e) {
      return val;
    }
  } else {
    return val;
  }
};

const getRowValue = (map, key, idToTemplate) => {
  let val;
  if (key === "templateId") {
    val = templateToLabel(idToTemplate.get(map.get(key)));
  } else {
    val = map.get(key);
  }
  return formatData(val) ?? "";
}

const flattenMapToPaths = (map, path = "") => {
  const prefix = path.length ? path + "." : "";
  return map
      .keySeq()
      .reduce(
          (acc, k) => (Immutable.isMap(map.get(k)) && k !== "forwardReport")
              ? acc.merge(flattenMapToPaths(map.get(k), prefix + k))
              : acc.set(prefix + k, map.get(k)),
          Immutable.Map());
};

const selectKeyPaths = (map, paths) => {
  return paths.reduce((acc, p) => acc.setIn(p, map.getIn(p)), Immutable.Map());
};

const ConfigOverview = betterMemo(
    {displayName: "ConfigOverview"},
    ({columnNameToMap, keyPaths, idToTemplate}) => {
      const [selectedData, setSelectedData] = useState(null);

      const flatMaps = columnNameToMap
          .valueSeq()
          .map(m => keyPaths ? selectKeyPaths(m, keyPaths) : m)
          .map(m => flattenMapToPaths(m));
      const configKeys = flatMaps.flatMap(k => k.keySeq()).toSet();

      // toString displayMapper to workaround default STRING column type making booleans blank
      const columns = Immutable.List.of(Immutable.Map({label: "Config Key", flexGrow: 0}))
          .concat(columnNameToMap
              .keySeq()
              .map(c => Immutable.Map({displayMapper: x => x.toString(), label: c})))
          .toJS();
      const rows = configKeys.map(key => Immutable.List.of(keyToLabel.get(key, key))
          .concat(flatMaps.map(k => getRowValue(k, key, idToTemplate)).valueSeq()))
          .filter(row => row.rest().some(x => x !== ""))
          .toJS();

      return <div style={{width: "100%"}}>
        <SimpleDataTable
            columns={columns}
            rows={rows}
            maxTableHeight={500}
            onCellClick={setSelectedData} />
        <Dialog
            maxWidth={false}
            open={!!selectedData}
            onClose={() => setSelectedData(null)}>
          <DialogContent >
            <pre tabIndex={-1}>
              {formatData(selectedData)}
            </pre>
          </DialogContent>
        </Dialog>
      </div>;
    });

export default ConfigOverview;
