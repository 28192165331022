import * as Auditor from "js/common/auditer";

const isIE = () => {
  return Auditor.getUserAgent().getBrowser().name === "IE";
};

const isFirefox = () => {
  return Auditor.getUserAgent().getBrowser().name === "Firefox";
}

export {
  isIE,
  isFirefox
};