/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {css, jsx} from "@emotion/react";

import Icon from "js/admin/common/icon";
import Hint from "js/admin/common/hint";
import LoadingSpinner from "js/common/views/loading-spinner";
import {Layout, Row, Column} from "js/common/views/foundation-column-layout";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import ErrorMsg from "js/common/views/error";
import Select from "js/common/views/inputs/immutable-react-select";
import {indexBy} from "js/common/utils/collections";
import {MessageOnlyTab} from "js/admin/kpis/edit-kpis/utils";
import EditColumnsGroup from "js/admin/kpis/edit-kpis/tabs/edit-columns-group";
import {TextField} from "@mui/material";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const EditColumns = createReactClass({

  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      kpisAvailableToMirror: Immutable.List(),
      kpisThatShareColumnsWithThisKpi: Immutable.List()
    };
  },

  getInitialState() {
    const subGroups = this.props.columns && this.props.columns.filter(c => c.get("readableJoinPath").count() > 1);
    return {
      collapsedSectionIds: Immutable.Set(subGroups && subGroups.map(c => c.get("joinPathStr"))),
      isSearching: false,
      searchTerm: "",
      collapseAll: false
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.collapsedSectionIds.count()
        === 0
        && nextProps.columns
        !== this.props.columns
        && this.state.expandAll
        !== true) {
      const subGroups = nextProps.columns.filter(c => c.get("readableJoinPath").count() > 1);
      this.setState({
        collapsedSectionIds: Immutable.Set(subGroups.map(c => c.get("joinPathStr")))
      });
    }
  },

  handToggleCollapse(id) {
    const stateIds = this.state.collapsedSectionIds;
    if (stateIds.includes(id)) {
      this.setState({collapsedSectionIds: stateIds.delete(id)});
    } else {
      this.setState({collapsedSectionIds: stateIds.add(id)});
    }
  },

  render() {
    const {columnsEditable, columns} = this.props;
    if (!columnsEditable || (columns && columns.isEmpty())) {
      return <MessageOnlyTab message={"Columns on this Metric are not available for editing."} />;
    } else if (!columns) {
      return this.renderLoadingText();
    } else {
      return this.renderPage();
    }
  },

  renderLoadingText() {
    return (
        <Layout allSmall={6} smallCentered={true}>
          <LoadingSpinner />
        </Layout>);
  },

  renderPage() {
    const {columns, submitError, rootChangedWarning, kpisThatShareColumnsWithThisKpi, kpi, theme} = this.props;
    const {isSearching, searchTerm} = this.state;
    const searchWords = searchTerm.toLowerCase().split(" ");
    const filteredInvisibleColumns = columns.filter(c => searchWords.every(searchBit => c.get("label")
        .toLowerCase()
        .includes(searchBit)));

    const hiddenColumns = isSearching ? filteredInvisibleColumns : columns;
    const sortedVisibleColumns = columns
        .filter(c => c.get("visible"))
        .sortBy(c => c.get("order"));

    return (
        <div style={{padding: "1rem"}}>
          {submitError && <ErrorMsg text={submitError} style={{marginTop: 0}} />}
          {rootChangedWarning && <ErrorMsg text={rootChangedWarning} type={"warn"} style={{marginTop: 0}} />}
          {kpisThatShareColumnsWithThisKpi.isEmpty() ?
              this.renderMirrorSelector() :
              this.renderSharedColumnsWarning()
          }
          <Row style={{lineHeight: "40px"}}>
            <Column small={12} medium={6} style={{padding: "0px 10px"}} key={`visible-columns-${kpi.get("id")}`}>
                    <span style={headerStyle(theme)}>
                        <i className="fa fa-eye"></i>  Visible Columns
                    </span>
              <VisibleColumns
                  theme={theme}
                  columns={sortedVisibleColumns}
                  onReordered={this.handleReordered}
                  onInvisibleClick={this.handleInvisibleClick} />
            </Column>
            <Column small={12} medium={6} style={{padding: "0px 10px"}} key={`hidden-columns-${kpi.get("id")}`}>
                    <span style={headerStyle(theme)}>
                        <i className="fa fa-eye-slash"></i>  Hidden Columns
                    </span>
              <Row>
                <Column
                    small={12}
                    medium={6}
                    style={{padding: "0px 10px"}}
                    key={`hidden-columns-search--${kpi.get("id")}`}>
                  <TextField
                      variant="standard"
                      InputLabelProps={{style: {color: "#757575"}}}
                      id={"metric-column-table-search"}
                      label={"Search columns"}
                      style={{marginTop: "-0.5rem", marginBottom: "1.5rem", width: 300}}
                      onChange={event => this.onSearchChange(event.target.value)} />
                </Column>
                <Column
                    small={12}
                    medium={6}
                    style={{padding: "0px 10px 0px 0px", textAlign: "right"}}
                    key={`hidden-columns-collapse-all-${kpi.get("id")}`}>
                  {!isSearching &&
                      <div>
                        <TextButton
                            icon="sort-asc"
                            iconType="bhi"
                            label="Collapse All"
                            style={{
                              boxShadow: "none",
                              whiteSpace: "nowrap",
                              color: this.state.collapseAll ? theme.palette.text.main : theme.palette.primary.main,
                              background: theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.paper,
                              fontSize: 11,
                              padding: "10px 0",
                              width: 100,
                              height: 30,
                              marginRight: 15
                            }}
                            disabled={this.state.collapseAll || this.state.isSearching}
                            onClick={() => this.toggleCollapseAll(columns)} />
                        <TextButton
                            icon="sort-desc"
                            iconType="bhi"
                            label="Expand All"
                            disabled={this.state.expandAll || this.state.isSearching}
                            style={{
                              boxShadow: "none",
                              whiteSpace: "nowrap",
                              color: this.state.expandAll ? theme.palette.text.main : theme.palette.primary.main,
                              background: theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.paper,
                              fontSize: 11,
                              padding: "10px 0",
                              width: 100,
                              height: 30
                            }}
                            onClick={() => this.toggleExpandAll(columns)} />
                      </div>
                  }
                </Column>
              </Row>
              {this.state.isSearching &&
              hiddenColumns.filter(c => !c.get("visible")).count() === 0 ?
                  <div css={rowStyle(theme)} style={{padding: "5px 10px"}}>No columns found.</div>
                  : hiddenColumns.count() >= 1 && this.renderInvisibleColumns(hiddenColumns, theme)
              }
            </Column>
          </Row>
        </div>
    );
  },

  renderMirrorSelector() {
    const {kpisAvailableToMirror, theme} = this.props;
    if (kpisAvailableToMirror.isEmpty()) {
      return;
    }
    const kpiIdToColumnsKpiId = indexBy(k => k.get("id"), kpisAvailableToMirror)
        .map(kpi => kpi.get("columnsKpiId") || kpi.get("id"));
    const options = kpisAvailableToMirror
        .map(kpi => Immutable.Map({label: kpi.get("name"), value: kpi.get("id")}));
    const infoText = "This Metric can mirror the columns of another Metric. While mirrored, any column changes will be shared";
    return <div style={{display: "flex"}}>
      <div style={{flex: 1, marginRight: "1rem", minWidth: 300}}>
        <Select
            isMulti={false}
            isClearable={false}
            placeholder="Search For Metric To Mirror"
            noOptionsMessage={() => "No results found"}
            onChange={kpiId => this.props.onMirror(kpiIdToColumnsKpiId.get(kpiId))}
            options={options} />
      </div>
      <Hint>
        <Icon icon="info" style={{color: theme.palette.hints.text}} />
        {infoText}
      </Hint>
    </div>;
  },

  renderSharedColumnsWarning() {
    const kpis = this.props.kpisThatShareColumnsWithThisKpi;
    return <>
      <ErrorMsg
          type={"warn"}
          text={
            <>
              <p>Changes to the columns on this Metric will also apply to the below Metric{kpis.count() > 1 ? "s" :
                  ""}:</p>
              <ul>
                {kpis
                    .filter(kpi => !kpi.get("deleted"))
                    .map(kpi => {
                  return <li key={kpi.get("id")}>
                    {kpi.get("name")}
                  </li>;
                })}
              </ul>
            </>} />
      <TextButton
          label={"Stop mirroring"}
          type={"primary"}
          onClick={this.props.onUnmirror} />
    </>;
  },

  onSearchChange(searchTerm) {
    if (searchTerm && searchTerm.length > 2) {
      this.setState({isSearching: true, searchTerm});
    } else {
      this.setState({isSearching: false, searchTerm: ""});
    }
  },


  toggleCollapseAll(columns) {
    const allSectionsIds = Immutable.Set(columns.map(c => c.get("joinPathStr")));
    this.setState({collapsedSectionIds: allSectionsIds, collapseAll: true, expandAll: false});
  },

  toggleExpandAll(columns) {
    this.setState({collapsedSectionIds: Immutable.Set(), collapseAll: false, expandAll: true});
  },

  renderInvisibleColumns(columns, theme) {
    const groupedColumns = columns
        .groupBy(c => c.get("readableJoinPath").first());

    return groupedColumns && groupedColumns
        .entrySeq()
        .map(([label, group]) => {
          return (
              <EditColumnsGroup
                  theme={theme}
                  key={`edit-column-group-${this.props.kpi.get("id")}`}
                  label={label}
                  group={group}
                  handleVisibleClick={this.handleVisibleClick}
                  handleToggleCollapse={this.handToggleCollapse}
                  searching={this.state.isSearching}
                  collapseAll={this.state.collapseAll}
                  collapsedSectionIds={this.state.collapsedSectionIds}
              />);
        });
  },

  handleReordered(visibleColumns) {
    const {columns, onChange} = this.props;
    const invisibleColumns = columns.filter(c => !c.get("visible"));
    const updatedColumns = visibleColumns
        .map((c, index) => c.set("order", index))
        .concat(invisibleColumns);
    onChange(updatedColumns);
  },

  handleInvisibleClick(column) {
    const index = this.findColumnIndex(column);
    const {columns, onChange} = this.props;
    onChange(columns.set(index, column.set("visible", false)));
  },

  handleVisibleClick(column) {
    const index = this.findColumnIndex(column);
    const {columns, onChange} = this.props;
    const visibleColumns = columns.filter(c => c.get("visible"));
    let maxOrder = 0;
    if (!visibleColumns.isEmpty()) {
      maxOrder = columns
          .filter(c => c.get("visible"))
          .maxBy(c => c.get("order"))
          .get("order");
    }
    const order = visibleColumns.isEmpty() ? maxOrder : maxOrder + 1;
    onChange(columns.set(index, column.set("visible", true).set("order", order)));
  },

  findColumnIndex(column) {
    return this.props.columns.findIndex(c => c.get("entityColumnId") === column.get("entityColumnId")
        && c.get("joinPathStr") === column.get("joinPathStr"));
  }

});

const VisibleColumns = createReactClass({

  handleDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const startIndex = result.source.index;
    const newIndex = result.destination.index;

    const columns = this.props.columns;
    const movedColumn = columns.get(startIndex);
    const reorderedColumns = columns
        .delete(startIndex)
        .insert(newIndex, movedColumn);
    this.props.onReordered(reorderedColumns);
  },

  render() {
    const {theme} = this.props;
    return (
        <DragDropContext onDragEnd={this.handleDragEnd}>
          <Droppable droppableId="columns-list" type="ROW" direction="vertical">
            {(droppableProvided, droppableSnapshot) => (
                <div
                    ref={droppableProvided.innerRef}
                    style={{
                      backgroundColor: droppableSnapshot.isDraggingOver ? (theme.themeId === "light"
                          ? theme.palette.background.paper
                          : "#2c3e50") : "transparent",
                      position: "relative"
                    }}
                    {...droppableProvided.droppableProps}>

                  {this.props.columns.map((column, index) => (
                      <Draggable
                          key={column.get("entityColumnId") + column.get("joinPathStr")}
                          draggableId={column.get("entityColumnId") + column.get("joinPathStr")}
                          index={index}
                          type="ROW">
                        {(draggableProvided) =>
                            //inner and outer div so we don't accidentally drag the placeholder too
                            (<div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}>
                              <VisibleColumn
                                  column={column}
                                  onInvisibleClick={this.props.onInvisibleClick}
                                  dragHandleProps={draggableProvided.dragHandleProps} />
                            </div>)}
                      </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>)}
          </Droppable>
        </DragDropContext>);
  }
});

const VisibleColumn = createReactClass({

  render() {
    const {column, onInvisibleClick, dragHandleProps} = this.props;
    return (
        <Row css={rowStyle}>
          <Column small={12} style={columnStyle}>
            {column.get("label")}
            <div className="right">
              <i className="fa fa-arrows" style={{cursor: "move"}} {...dragHandleProps} />
              <IconButton
                  icon="eye-slash"
                  type="bright"
                  title="Hide column"
                  onClick={() => onInvisibleClick(column)} />
            </div>
          </Column>
        </Row>
    );
  }
});

const headerStyle = theme => ({
  textTransform: "uppercase",
  fontSize: ".7rem",
  color: theme.palette.primary.main,
  paddingBottom: "5px",
  marginLeft: ".5rem"
});

const rowStyle = theme => ({
  background: theme.themeId === "light" ? theme.palette.background.paper : "#1c1c22",
  borderBottom: `1px solid ${theme.themeId === "light" ? theme.palette.border.light : "#393838"}`,
  fontSize: "13px"
});

const columnStyle = {
  paddingLeft: "0.9375em",
  paddingRight: "0.9375em"
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <EditColumns theme={theme} {...props} />;
};