import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";
import Layout from "js/common/views/foundation-column-layout";

import Icon from "js/admin/common/icon";
import AdminHeader from "js/admin/common/admin-header";
import Hint from "js/admin/common/hint";
import ErrorMsg from "js/common/views/error";
import GroupPicker from "js/common/views/inputs/group-and-user-picker/filterable-group-user-tree";
import EditGroupPanel from "js/admin/groups/edit-group-panel";
import CloseButton from "js/common/views/inputs/close-button";
import eventBus from "js/cube19.event-bus";
import * as auditor from "js/common/auditer";
import * as Popups from "js/common/popups";
import * as Pages from "js/common/pages";
import * as Groups from "js/common/groups";
import * as Locales from "js/common/locales";
import Cube19 from "js/cube19.app";
import Checkbox from "js/common/views/inputs/checkbox";
import {maybeBackboneToImmutable} from "js/common/utils/backbone-migration";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const GroupManagement = GetContainerDimensions()(createReactClass({

  displayName: "GroupManagement",

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      selectedGroupId: null,
      showDeletedGroups: false,
      pages: Immutable.List(),
      idToLocale: Immutable.List(),
      activeUsersInGroupCount: null,
      isUpdating: false,
      error: null
    };
  },

  componentDidMount() {
    this.setState({
      isUpdating: true
    });

    Promise
        .all([Pages.getAll(), Locales.loadAll()])
        .then(([pages, locales]) => this.setState({
          pages,
          idToLocale: locales.groupBy(l => l.get("id")).map(ls => ls.first()),
          isUpdating: false
        }));
  },

  render() {
    const {
      pages,
      idToLocale,
      selectedGroupId,
      showDeletedGroups,
      activeUsersInGroupCount,
      isUpdating,
      error
    } = this.state;
    const group = maybeBackboneToImmutable(Groups.getGroup(selectedGroupId));
    const {theme} = this.props;
    return (
        <div>
          <AdminHeader>
            <Icon icon="users" />
            Manage Groups in your organisation
          </AdminHeader>
          <Hint style={{margin: "0 1em"}}>
            <Icon icon="info" style={{color: theme.palette.hints.text}} />
            Changes on this page will auto-save
          </Hint>
          <Layout allSmall={12} medium={[5, 7]} rowStyle={{marginBottom: "1rem"}}>
            <div>
              <Checkbox
                  label="Show Deleted Groups"
                  checked={showDeletedGroups}
                  onCheck={(e, isChecked) => this.handleDeletedGroupsCheckboxClick(isChecked)} />
              <GroupPicker
                  expandAll={true}
                  excludeUsers={true}
                  qualifierType="GROUP"
                  qualifierId={selectedGroupId}
                  showDeletedGroups={showDeletedGroups}
                  onGroupClick={this.handleGroupClick}
                  customContainerStyle={{
                    maxHeight: this.props.containerWidth < 800 ? "20rem" : "55rem",
                    overflow: "scroll"
                  }} />
            </div>
            {selectedGroupId &&
                <div style={{margin: "1rem"}}>
                  <div style={{textAlign: "right"}}>
                    <CloseButton onClick={this.closeEditGroupPanel} />
                  </div>
                  {error && <ErrorMsg text={error} />}
                  <EditGroupPanel
                      group={group}
                      pages={pages}
                      idToLocale={idToLocale}
                      activeUsersInGroupCount={activeUsersInGroupCount}
                      onChange={this.updateGroup}
                      onDeleteGroup={this.deleteGroup}
                      onAddSubGroupClick={this.addSubGroup}
                      isUpdating={isUpdating}
                      onCloseRequest={this.closeEditGroupPanel}
                      isSmallScreen={this.props.containerWidth < 800} />
                </div>}
          </Layout>
        </div>
    );
  },

  handleGroupClick(groupId) {
    this.setState({
      isUpdating: true
    });

    Groups.loadActiveUsersInGroup(groupId)
        .then(users => {
          this.setState({
            isUpdating: false,
            selectedGroupId: groupId,
            activeUsersInGroupCount: users.count(),
            error: null
          });
        }, () => {
          this.setState({
            isUpdating: false,
            selectedGroupId: groupId,
            activeUsersInGroupCount: 0,
            error: null
          });
        });
  },

  handleDeletedGroupsCheckboxClick(showDeletedGroups) {
    this.setState({
      showDeletedGroups
    });
  },

  closeEditGroupPanel() {
    this.setState({
      selectedGroupId: null,
      activeUsersInGroupCount: null,
      error: null
    });
  },

  deleteGroup(deletingGroupId, newGroupId, groupEndDate) {
    this.setState({isUpdating: true});
    Groups.deleteGroup(deletingGroupId, newGroupId, groupEndDate)
        .then(() => {
          Popups.success("Group successfully deleted.");
        }, error => {
          if (error.status >= 400 && error.status < 500) {
            const response = error.responseJSON;
            Popups.error(response.message);
          } else {
            Popups.contactSupport();
          }
        })
        .finally(() => {
          this.setState({isUpdating: false});
        });
  },

  updateGroup(change) {
    const group = Groups.getGroup(this.state.selectedGroupId);

    if (!change.pageId && group.get("inheritedPageGroupId") !== group.get("id")) {
      change.pageId = null;
    }
    if (!change.dealMusicId && group.get("inheritedMusicGroupId") !== group.get("id")) {
      change.dealMusicId = null;
    }
    if (!change.weekStartsOn && group.get("inheritedWeekdayGroupId") !== group.get("id")) {
      change.weekStartsOn = null;
    }
    if (!change.currencyCode && group.get("inheritedCurrencyGroupId") !== group.get("id")) {
      change.currencyCode = null;
    }
    if (!change.localeId && group.get("inheritedLocaleGroupId") !== group.get("id")) {
      change.localeId = null;
    }

    this.setState({
      isUpdating: true
    }, () => {
      const group = Groups.getGroup(this.state.selectedGroupId);
      const newGroupSettings = Immutable.fromJS(change);
      const originalGroup = maybeBackboneToImmutable(group);
      const changedAttribute = newGroupSettings.keySeq().toArray()[0];
      group.save(change, {
        wait: true,
        success: model => {
          eventBus.trigger("hierarchy:group-updated", model);

          const newGroup = maybeBackboneToImmutable(model);
          if (!Immutable.is(originalGroup, newGroup)) {
            this.auditGroupChange(changedAttribute, originalGroup, newGroup);
          }
          Cube19.Group.controller.load().then(() => {
            this.setState({
              isUpdating: false,
              error: null
            });
          });
        },
        error: (model, error) => {
          let message;
          if (error.status >= 400 && error.status < 500) {
            message = error.responseJSON.message;
          } else {
            message = "Unable to update group";
          }
          this.setState({
            isUpdating: false,
            error: message
          });
        }
      });
    });
  },

  auditGroupChange(attributeChanged, originalGroup, newGroup) {
    const payload = {
      groupId: this.state.selectedGroupId,
      change: {
        attributeChanged,
        originalValue: originalGroup.get(attributeChanged),
        newValue: newGroup.get(attributeChanged)
      }
    };
    auditor.audit("group-settings:changed", payload);
  },

  addSubGroup(parentId) {
    this.setState({
      isUpdating: true
    }, () => {
      Groups.createGroup({
        name: "New Group",
        parentId
      }, {
        wait: true,
        success: (model, response) => {
          eventBus.trigger("hierarchy:group-added", model);
          eventBus.trigger("hierarchy:changed");

          Popups.success("New Group has been created");
          const newGroupId = model.get("id");
          auditor.audit("org_hierarchy:group_added", {
            groupId: newGroupId
          });
          this.setState({
            isUpdating: false,
            activeUsersInGroupCount: 0,
            selectedGroupId: newGroupId
          });
        },
        error: (model, error) => {
          this.setState({
            isUpdating: false,
            error: "Unable to add sub-group"
          });
        }
      });
    });
  }

}));

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <GroupManagement theme={theme} {...props} />;
};