import React from "react";

import InvalidStatePage from "js/invalid-state/invalid-state-page";
import {TextButton} from "js/common/views/inputs/buttons";
import SwitchingUserPicker from "js/common/views/switching-user/switching-user-picker";
import Drawer from "js/common/views/drawer";
import * as Auth from "js/common/auth";
import * as Users from "js/common/users";
import * as Branding from "js/common/branding-constants";
import {showLogoutButton} from "js/login/bullhorn-sso";

const NewUserPageContent = () => {
  const [switchingUsersDialogIsOpen, setSwitchingUsersDialogIsOpen] = React.useState(false);
  const hasUserSwitching = Users.getSourceUser()?.get("targetUserIds")?.length > 0;
  return (
      <>
        <p>
          Ask one of the {Branding.brandingName} admins in your company to activate you.
          <br />
          They will find you in the &quot;<strong>Activate New Users</strong>&quot; tab on the User admin page.
          <br />
          {hasUserSwitching
              ? <>You can login as another user to continue using {Branding.brandingName}.</>
              : <>Until this is done you will not be able to access {Branding.brandingName}.</>}
        </p>

        {showLogoutButton() &&
            <TextButton label="Back to Login" onClick={Auth.logout} style={{marginRight: "1rem"}} />}
        {hasUserSwitching &&
            <TextButton
                label={"Change User"}
                type={"primary"}
                onClick={() => setSwitchingUsersDialogIsOpen(true)} />}

        <Drawer
            open={switchingUsersDialogIsOpen}
            onRequestClose={() => setSwitchingUsersDialogIsOpen(false)}
            showCloseButton={true}
            openRight={true}
            width={598}
            title="Choose A User To Log In As">
          <SwitchingUserPicker listStyle={{maxHeight: "75vh", overflow: "scroll"}} />
        </Drawer>
      </>
  );
};

const NewUserPage = () => <InvalidStatePage
    heading={"New User"}
    subheading={"Your user has not been activated"}
    content={<NewUserPageContent />} />;

export default NewUserPage;
