import React from "react";
import createReactClass from "create-react-class";
import Tooltip from "react-tooltip";
import * as Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import AdminHeader from "js/admin/common/admin-header";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import Select from "js/common/views/inputs/immutable-react-select";
import {TextButton} from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as ratioRepo from "js/common/repo/ratio-repo";
import {capitaliseWords} from "js/common/utils/strings";
import currentClient from "js/common/repo/backbone/current-client";
import * as Users from "js/common/users";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants";
import * as auditor from "js/common/auditer";

const App = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      configs: getRatios(),
      kpis: getKpis(),
      ratioDisplayTypes: getRatioDisplayTypes(),
      isRatioDisplayTypesInfoOpen: false,
      isSavingChanges: false,
      warning: null,
      error: null,
      success: null
    };
  },

  render() {
    const {configs, isRatioDisplayTypesInfoOpen, isSavingChanges, error, success, warning} = this.state;
    const {theme} = this.props;
    const hasUnsavedChanges = configs.some(rc => rc.get("cid") || rc.get("isChanged") || rc.get("isDeleted"));
    const hasErrors = !!error;
    const buttonSpacing = {
      marginLeft: "0.5rem",
      marginRight: "0.5rem"
    };
    const isCubeX = currentClient.getType() === "X";
    const isCube19User = Users.isCube19User(Users.getCurrentUser());
    const shouldDisplayAddRemoveButtons = !isCubeX || isCube19User;

    const infoText = isCubeX
        ? "This is where you edit your OneView page ratios."
        : "Creating or deleting a ratio will not automatically assign it to / remove it from Dashboards, Trends, OneView pages or OnPoint reports.";
    return (
        <div>
          <AdminHeader>
            Create and Configure Ratios
          </AdminHeader>
          <Hint>
            <Icon icon="info" style={{color: theme.palette.hints.text}} />
            {infoText}
          </Hint>
          <div style={{marginTop: "1rem", marginRight: "1rem", marginBottom: "2rem", marginLeft: "1rem"}}>
            {shouldDisplayAddRemoveButtons && <div style={{textAlign: "center", marginTop: "1rem"}}>
              <TextButton
                  icon="plus"
                  label="Add Ratio"
                  disabled={isSavingChanges}
                  onClick={this.handleAddRatioClick} />
            </div>}
            <div style={{height: "100%"}}>
              {this.renderRatioConfigs(shouldDisplayAddRemoveButtons)}
            </div>
            <div style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}>
              {warning && <ErrorMsg type="warn" text={warning} />}
              {error && <ErrorMsg text={error} />}
              {success && <SuccessMsg text={success} onMessageTimeout={this.handleSuccessMsgTimeout} />}
            </div>
            <div style={{textAlign: "center"}}>
              <TextButton
                  icon="history"
                  label="Cancel"
                  disabled={!hasUnsavedChanges}
                  onClick={this.cancelChanges}
                  style={buttonSpacing} />
              <TextButton
                  icon="floppy-o"
                  label="Save"
                  disabled={!hasUnsavedChanges || hasErrors || isSavingChanges}
                  onClick={this.handleSaveClick}
                  style={buttonSpacing} />
            </div>
            {isRatioDisplayTypesInfoOpen &&
                <RatioDisplayTypesInfoDialog
                    theme={theme}
                    open={isRatioDisplayTypesInfoOpen}
                    onCloseRequest={this.closeRatioDisplayTypesInfoDialog} />}
          </div>
        </div>
    );
  },

  componentDidMount() {
    auditor.audit("ratios-admin:loaded");
  },

  renderRatioConfigs(shouldDisplayAddRemoveButtons) {
    const {configs, kpis, ratioDisplayTypes} = this.state;
    const {theme} = this.props;
    return (
        <div style={{position: "relative", padding: "0.5rem"}}>
          {configs
              .filter(config => !config.get("isDeleted"))
              .map((config) => {
                return (
                    <div key={config.get("id")}>
                      <Ratio
                          theme={theme}
                          kpis={kpis}
                          ratioDisplayTypes={ratioDisplayTypes}
                          config={config}
                          onChange={this.handleRatioConfigChange}
                          onRemoveRatioButtonClick={this.handleRemoveRatioButtonClick}
                          onInfoButtonClick={this.openRatioDisplayTypesInfoDialog}
                          shouldDisplayAddRemoveButtons={shouldDisplayAddRemoveButtons} />
                    </div>
                );
              })}
        </div>
    );
  },

  handleSuccessMsgTimeout() {
    this.setState({success: null});
  },

  handleAddRatioClick() {
    this.setState((state) => {
      const updatedConfigs = state.configs.push(this.getDefaultRatioConfig());
      return {
        configs: updatedConfigs,
        warning: "New Ratio added. Please configure the new Ratio.",
        error: validateConfigs(updatedConfigs),
        success: null
      };
    });
  },

  getDefaultRatioConfig() {
    const {configs, kpis} = this.state;
    const defaultKpiId = kpis.first().get("id");
    return Immutable
        .fromJS({
          cid: Math.random(),
          name: `Ratio ${configs.count()}`,
          displayType: "NORMALISED",
          firstKpiId: defaultKpiId,
          secondKpiId: defaultKpiId,
          isDeleted: false
        });
  },

  handleRemoveRatioButtonClick(configId) {
    const {configs} = this.state;
    const index = configs.findIndex(rc => rc.get("id") === configId || rc.get("cid") === configId);
    const config = configs.get(index);
    this.setState({
      configs: configs.set(index, config.set("isDeleted", true).set("isChanged", true)),
      warning: null,
      error: null
    });
  },

  handleRatioConfigChange(changedConfig) {
    const {configs} = this.state;
    const id = changedConfig.get("id") || changedConfig.get("cid");
    const index = configs.findIndex(rc => rc.get("id") === id || rc.get("cid") === id);
    const updatedConfigs = configs.set(index, changedConfig.set("isChanged", true));
    this.setState({
      configs: updatedConfigs,
      warning: null,
      error: validateConfigs(updatedConfigs)
    });
  },

  cancelChanges() {
    this.setState({
      ...this.getInitialState(),
      success: "Your changes have been reset"
    });
  },

  handleSaveClick() {
    this.setState({
      success: null,
      error: null,
      isSavingChanges: true
    });
    Promise
        .all(this.state.configs.map(ratio => {
          if (ratio.get("cid") && !ratio.get("isDeleted")) {
            return addRatio(ratio);
          } else if (!ratio.get("cid") && ratio.get("isChanged") && !ratio.get("isDeleted")) {
            return updateRatio(ratio);
          } else if (!ratio.get("cid") && ratio.get("isDeleted") && ratio.get("isChanged")) {
            return deleteRatio(ratio.get("id"));
          }
        }))
        .then(() => {
          this.setState({
            configs: getRatios().filter(r => !r.get("isDeleted")),
            success: "Your changes have been saved",
            isSavingChanges: false
          });
        }, () => {
          this.setState({
            error: `An unexpected error has occurred. ${Branding.submitTicketString}`,
            isSavingChanges: false
          });
        });
  },

  openRatioDisplayTypesInfoDialog() {
    this.setState({
      isRatioDisplayTypesInfoOpen: true
    });
  },

  closeRatioDisplayTypesInfoDialog() {
    this.setState({
      isRatioDisplayTypesInfoOpen: false
    });
  }

});

const Ratio = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {
      config,
      kpis,
      ratioDisplayTypes,
      onInfoButtonClick,
      onRemoveRatioButtonClick,
      onChange,
      shouldDisplayAddRemoveButtons,
      theme
    } = this.props;
    const name = config.get("name");
    const failsValidation = !name ||
        name.length > maxNameLength ||
        !config.get("firstKpiId") ||
        !config.get("secondKpiId") ||
        !config.get("displayType");
    const padding = {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem"
    };
    const errorBorderStyle = "1px solid #f00";
    const inputErrorStyle = {
      color: "black",
      backgroundColor: "#502f2f",
      border: errorBorderStyle,
      ":active": {
        boxShadow: "0 0 5px #999"
      },
      ":focus": {
        boxShadow: "0 0 5px #999"
      }
    };
    const ratioContainerStyle = theme => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
      padding: "1rem 0.5rem",
      backgroundColor: failsValidation ? "#5d0d0d" : (theme.themeId === "light"
          ? theme.palette.background.card
          : "#565656"),
      borderRadius: 5,
      border: failsValidation ? errorBorderStyle : "1px solid rgba(255, 255, 255, 0.21)",
      boxShadow: `0px 2px 5px ${theme.palette.border.main}`,
      color: theme.palette.textColor,
      fontSize: "0.825rem"
    });
    const ratioId = config.get("id") || config.get("cid");
    const nameHasError = !name || name.length > maxNameLength;
    return (
        <div style={ratioContainerStyle(theme)}>
          <div style={{width: "21vw", ...padding}}>
            <label style={labelStyle}>Ratio Name</label>
            <SimpleTextInput
                placeholder="Name This Ratio"
                value={config.get("name")}
                onChange={this.handleNameChange}
                customStyle={nameHasError ? inputErrorStyle : {}} />
          </div>
          <div style={{width: "17vw", ...padding}}>
            <label style={labelStyle}>First Metric</label>
            <KpiPicker
                placeholder="Set First Metric"
                kpis={kpis.filter(kpi => kpi.get("visible") || kpi.get("id") === config.get("firstKpiId"))}
                selectedKpiId={config.get("firstKpiId")}
                onChange={kpiId => onChange(config.set("firstKpiId", kpiId))}
                clearable={false}
                multiSelect={false} />
          </div>
          <div style={{width: "3vw", textAlign: "center", paddingTop: labelStyle.fontSize}}> to</div>
          <div style={{width: "17vw", ...padding}}>
            <label style={labelStyle}>Second Metric</label>
            <KpiPicker
                placeholder="Set Second Metric"
                kpis={kpis.filter(kpi => kpi.get("visible") || kpi.get("id") === config.get("secondKpiId"))}
                selectedKpiId={config.get("secondKpiId")}
                onChange={kpiId => onChange(config.set("secondKpiId", kpiId))}
                clearable={false}
                multiSelect={false} />
          </div>
          <div style={{width: "13vw", ...padding}}>
            <label style={labelStyle}>
              <span style={{paddingRight: "0.5rem"}}>Display Format</span>
              <InfoButton theme={theme} onClick={onInfoButtonClick} />
            </label>
            <Select
                placeholder="Select Display"
                isMulti={false}
                isClearable={false}
                selectedValue={config.get("displayType")}
                options={ratioDisplayTypes.map(type => (Immutable.Map({label: capitaliseWords(type), value: type})))}
                onChange={displayType => onChange(config.set("displayType", displayType))} />
          </div>
          {shouldDisplayAddRemoveButtons && <div style={{width: "3vw", textAlign: "right"}}>
            <RemoveRatioButton
                theme={theme}
                tooltipId={`ratio-${ratioId}-remove`}
                onClick={() => onRemoveRatioButtonClick(ratioId)} />
          </div>}
        </div>
    );
  },

  handleNameChange(name) {
    const {config, onChange} = this.props;
    const previousName = config.get("name");
    if (name === previousName) {
      return;
    } else {
      onChange(config.set("name", name));
    }
  }

});

const labelStyle = {
  fontSize: "0.8rem"
};

const buttonStyle = theme => ({
  cursor: "pointer",
  color: theme.palette.textColor,
  opacity: 0.6,
  ":hover": {
    opacity: 1
  }
});

const InfoButton = pure(({theme, onClick}) => {
  return <i className="fa fa-question-circle" style={buttonStyle(theme)} onClick={onClick} />;
});

const RemoveRatioButton = pure(({theme, tooltipId, onClick}) => {
  const removeBtnStyle = theme => ({
    ...buttonStyle(theme),
    paddingTop: labelStyle.fontSize,
    fontSize: "1.25rem",
    color: "#f00"
  });
  return (
      <div data-tip data-for={tooltipId} onClick={onClick}>
        <i className="fa fa-remove" style={removeBtnStyle(theme)} />
        <Tooltip id={tooltipId} type="light" place="top">
          Remove Ratio
        </Tooltip>
      </div>
  );
});

const RatioDisplayTypesInfoDialog = pure(({
  theme,
  open,
  onCloseRequest
}) => {
  const headerStyle = theme => ({
    color: theme.palette.primary.main,
    fontSize: "0.9rem",
    fontWeight: "bold"
  });
  const paragraphStyle = theme => ({fontSize: "0.825rem", color: theme.palette.textColor});
  return (
      <Dialog
          bodyStyle={{overflow: "visible"}}
          paperStyle={{backgroundColor: theme.palette.background.card}}
          actionsContainerStyle={{paddingLeft: "1rem", paddingRight: "1rem"}}
          title="Ratio Display Format Types"
          actions={[<TextButton key="close" label="Got it!" onClick={onCloseRequest} />]}
          onRequestClose={onCloseRequest}
          autoDetectWindowHeight={true}
          titleStyle={{color: theme.palette.primary.main, fontSize: "1rem"}}
          open={open}
          onBackdropClick={onCloseRequest}>
        <div style={{color: "white"}}>
          <h3 style={headerStyle(theme)}>
            Percentage
          </h3>
          <p style={paragraphStyle(theme)}>
            This will show the Ratio as a percentage amount e.g. 2 placements to 10 first interviews to will show 20%.
            This value is rounded to the nearest whole number
          </p>
          <h3 style={headerStyle(theme)}>
            Normalised
          </h3>
          <p style={paragraphStyle(theme)}>
            This will reduce the smaller Metric value to 1 and the other Metric value will be adjusted accordingly e.g.
            2 placements to 10 first interviews to will show 1 : 5
          </p>
          <h3 style={headerStyle(theme)}>
            Fraction
          </h3>
          <p style={paragraphStyle(theme)}>
            This will show the Ratio as a fraction amount e.g. 24 placements to 80 first interviews to will show 3/10.
            23 placements to 80 first interviews to will show 23/80
          </p>
          <h3 style={headerStyle(theme)}>
            Decimal
          </h3>
          <p style={paragraphStyle(theme)}>
            This will show the Ratio as a decimal amount e.g. 2 placements to 10 first interviews to will show 0.2. This
            value is rounded to a maximum of 2 decimal places
          </p>
          <h3 style={headerStyle(theme)}>
            Absolute
          </h3>
          <p style={paragraphStyle(theme)}>
            This will show the Ratio as actual values e.g. 2 placements to 10 first interviews to will show 2 :
            10
          </p>
        </div>
      </Dialog>
  );
});

const maxNameLength = 50;

const validateConfigs = configs => {
  const visibleConfigs = configs.filter(r => !r.get("isDeleted"));
  const allRatiosHaveName = visibleConfigs.every(rc => rc.get("name"));
  if (!allRatiosHaveName) {
    return "Ratio name required. Please update the highlighted ratio.";
  }

  const ratioNameIsTooLong = visibleConfigs.some(rc => rc.get("name").length > maxNameLength);
  if (ratioNameIsTooLong) {
    return `Ratio name may be a maximum of ${maxNameLength} characters.`;
  }

  const uniqueRatioNames = visibleConfigs
      .map(r => r.get("name"))
      .toSet();
  const allRatioNamesUnique = uniqueRatioNames.count() === visibleConfigs.count();
  if (!allRatioNamesUnique) {
    return "Ratios cannot have the same name.";
  }

  const allRatiosHaveKpis = visibleConfigs.every(rc => rc.get("firstKpiId") && rc.get("secondKpiId"));
  if (!allRatiosHaveKpis) {
    return "All Ratios must have first and second Metrics set. Please update the highlighted Ratio.";
  }

  const allRatiosHaveDisplayType = visibleConfigs.every(rc => rc.get("displayType"));
  if (!allRatiosHaveDisplayType) {
    return "All Ratios must have display format set. Please update the highlighted Ratio.";
  }
};

const addRatio = ratio => {
  const newRatio = ratio
      .delete("cid")
      .delete("isChanged");
  return ratioRepo.add(newRatio);
};

const updateRatio = ratio => ratioRepo.update(ratio.delete("isChanged"));

const deleteRatio = ratioId => ratioRepo.remove(ratioId);

const getRatios = () => {
  const ratioConfigsById = ratioRepo.getAll();
  return ratioConfigsById.toList();
};

const getRatioDisplayTypes = () => ratioRepo.getDisplayTypes();

const getKpis = () => {
  const targetableKpis = Immutable.fromJS(kpiRepo.getAll().toJSON());
  return targetableKpis
      .sortBy(kpi => kpi.get("name").toLowerCase());
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <App theme={theme} {...props} />;
};