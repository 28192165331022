import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import UncaughtErrorMsg from "js/common/views/uncaught-error-msg";
import lightLogo from "img/logo/light-mode-horizontal.svg";
import darkLogo from "img/logo/dark-mode-horizontal.svg";
import {trackError} from "js/common/error-tracking";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {menuBarLogoStyle, MenuBarStyle} from "js/common/views/menu-bar";
import * as Branding from "js/common/branding-constants";

const EmptyMenuBar = ({theme}) => {
  return (
      <div style={MenuBarStyle(theme.themeId, theme.palette)}>
        <img
            alt={Branding.brandingName}
            src={theme.themeId === "light" ? lightLogo : darkLogo}
            style={menuBarLogoStyle} />
      </div>
  );
};

const InvalidStatePage = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      uncaughtError: false
    };
  },

  componentDidCatch(error, {componentStack}) {
    trackError("REACT_RENDER", error, {componentStack});
    this.setState({
      uncaughtError: true
    });
  },

  render() {
    const {theme} = this.context;
    const {heading, subheading, content} = this.props;
    if (this.state.uncaughtError) {
      return <UncaughtErrorMsg />;
    } else {

      return (
          <>
            <EmptyMenuBar theme={theme} />

            <div style={{textAlign: "center"}}>
              <header style={{textAlign: "center", paddingTop: "40px", paddingBottom: "40px"}}>
                <h1 style={{color: theme.palette.primary.main}}>{heading}</h1>
                <h2>{subheading}</h2>
              </header>

              {content}
            </div>
          </>);
    }
  }
});
InvalidStatePage.contextType = CustomThemeContext;

export default InvalidStatePage;