import Immutable from "immutable";
import {evaluateFormulaId, getKpiIdsForFormulaId} from "js/common/formulas/formulas";

export default Immutable.fromJS([{
  id: "current-edge-parent-value",
  getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, cache, _) => {
    return getKpiIdsForFormulaId(
        context,
        masterKpiTypeToKpiId,
        idToNode,
        idToFormula,
        cache,
        "node-value",
        Immutable.List.of(context.get("parentNodeId")));
  },
  getLabel: () => {
    console.log('Labels are not supported for edge functions');
    return {result: "n/a", errors: Immutable.Set()};
  },
  fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, _) => {
    return evaluateFormulaId(
        context,
        masterKpiTypeToKpiId,
        idToNode,
        kpiIdToValue,
        idToFormula,
        "node-value",
        Immutable.List.of(context.get("parentNodeId")));
  }
},{
  id: "current-edge-child-value",
  getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, cache, _) => {
    return getKpiIdsForFormulaId(
        context,
        masterKpiTypeToKpiId,
        idToNode,
        idToFormula,
        cache,
        "node-value",
        Immutable.List.of(context.get("childNodeId")));
  },
  getLabel: () => {
    console.log('Labels are not supported for edge functions');
    return {result: "n/a", errors: Immutable.Set()};
  },
  fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, _) => {
    return evaluateFormulaId(
        context,
        masterKpiTypeToKpiId,
        idToNode,
        kpiIdToValue,
        idToFormula,
        "node-value",
        Immutable.List.of(context.get("childNodeId")));
  }
},{
  id: "current-edge-percent-parent-to-child",
  getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, cache, _) => {
    return getKpiIdsForFormulaId(
        context,
        masterKpiTypeToKpiId,
        idToNode,
        idToFormula,
        cache,
        "percent-between-nodes",
        Immutable.List.of(context.get("parentNodeId"), context.get("childNodeId")));
  },
  getLabel: () => {
    console.log('Labels are not supported for edge functions');
    return {result: "n/a", errors: Immutable.Set()};
  },
  fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, _) => {
    return evaluateFormulaId(
        context,
        masterKpiTypeToKpiId,
        idToNode,
        kpiIdToValue,
        idToFormula,
        "percent-between-nodes",
        Immutable.List.of(context.get("parentNodeId"), context.get("childNodeId")));
  }
},{
  id: "current-edge-percent-child-to-parent",
  getKpiIds: (context, masterKpiTypeToKpiId, idToNode, idToFormula, cache, _) => {
    return getKpiIdsForFormulaId(
        context,
        masterKpiTypeToKpiId,
        idToNode,
        idToFormula,
        cache,
        "percent-between-nodes",
        Immutable.List.of(context.get("childNodeId"), context.get("parentNodeId")));
  },
  getLabel: () => {
    console.log('Labels are not supported for edge functions');
    return {result: "n/a", errors: Immutable.Set()};
  },
  fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, _) => {
    return evaluateFormulaId(
        context,
        masterKpiTypeToKpiId,
        idToNode,
        kpiIdToValue,
        idToFormula,
        "percent-between-nodes",
        Immutable.List.of(context.get("childNodeId"), context.get("parentNodeId")));
  }
}]);
