/** @jsxImportSource @emotion/react */

import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Tooltip from "js/common/views/tooltips";
import LoadingSpinner from "js/common/views/loading-spinner";
import moment from "moment";
import {Button as FlatButton} from "@mui/material";
import {css, jsx} from "@emotion/react";

const ChangeSubmissionsMenu = ({
      submissions,
      loading,
      onRefreshClick,
      onCloseClick,
      submissionRenderer
    }
) => {
  const {theme} = React.useContext(CustomThemeContext);

  return (<>
    <div css={drawerHeaderStyle(theme)}>
      <h3 css={drawerHeadingStyle(theme)}>{"Change Submissions"}</h3>
      <Tooltip text="Refresh" position="left">
        <i className="bhi-refresh TESTCAFE-change-submissions-drawer-refresh-button" css={iconButtonStyle(theme)} onClick={() => onRefreshClick()} />
      </Tooltip>
      <Tooltip text="Close" position="left">
        <i className="bhi-times TESTCAFE-change-submissions-drawer-close-button" css={iconButtonStyle(theme)} onClick={() => onCloseClick()} />
      </Tooltip>
    </div>
    {loading ? <LoadingSpinner /> :
        submissions
            .sortBy(s => s.get("submittedDate"))
            .reverse()
            .map(submission => <ChangeSubmission submission={submission} theme={theme} submissionRenderer={submissionRenderer}/>)}
  </>);
};

const ChangeSubmission = ({submission, theme, submissionRenderer}) => {
  const [open, setOpen] = React.useState(false);
  const status = submission.get("status");
  const comment = submission.get("submittedComment");
  const overallPermission = submission.get("approvalPermission");
  const label = <>
    <span>{status + " - "}</span> {comment ? parseHyperlinks(submission.get("submittedComment")) : "(No comment)"}
    {status === "SUBMITTED" && <><br/><span>Requires: {overallPermission}</span></>}
    <br/>
    <span>{`Submitted ${toReadableDate(submission.get("submittedDate"))} by ${submission.get("submittedBy")}`}</span>
    {status === "REJECTED" && <><br/><span>{`Rejected ${toReadableDate(submission.get("reviewedDate"))} by ${submission.get(
      "reviewedBy")}`}</span></>}
    {status === "SUCCEEDED" && <><br/><span>{`Run ${toReadableDate(submission.get("runDate"))} by ${submission.get("runBy")}`}</span></>}
      </>;
  return <div style={{backgroundColor: theme.palette.background.paper}}>
    <FlatButton
        style={{
          width: "100%",
          height: "auto",
          justifyContent: "flex-start",
          textAlign: "left",
          lineHeight: 1,
          padding: "10px 0",
          margin: "1px 0",
          backgroundColor: statusToColor[status],
        }}
        onClick={() => setOpen(!open)}>
        <span
            style={{
              color: "black",
              textTransform: "none",
              justifyContent: "flex-start",
              textAlign: "left",
              padding: "0 13px"
            }}>
          {label}
        </span>
    </FlatButton>
    {open && submissionRenderer(submission)}
  </div>;
};

const drawerHeaderStyle = theme => css`
  background-color: ${theme.palette.background.default};
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 2px solid #A0A0A0;
  margin-top: 0px;
  max-height: 52px;
`;

const drawerHeadingStyle = theme => css`
  flex: 1;
  font-family: theme.typography.fontFamilyBold;
  font-size: 20px;
  color: ${theme.palette.text.main};
  text-transform: none;
`;


const iconButtonStyle = theme => css`
  flex: 1;
  font-size: 20px;
  margin: 0;
  padding-left: 10px;
  cursor: pointer;
  color: ${theme.palette.text.secondary};
  background-color: transparent;
  transition: all 0.3s ease;
`;

const statusToColor = {
  FAILED: "#ff8c8c",
  SUCCEEDED: "#91e094",
  APPROVED: "#abd8ff",
  REJECTED: "#ff8c8c",
  SUBMITTED: "#abd8ff"
};

const parseHyperlinks = str => {
  return str
      .split(/([Zz]\d+)|([Cc]\d+)/)
      .filter(str => !!str)
      .map((str, index) => {
        if (isZendeskId(str)) {
          return createZendeskUrl(str);
        } else if (isClubhouseId(str)) {
          return createClubhouseUrl(str);
        } else {
          return <span key={index}>{str}</span>;
        }
      });
};

const mysqlDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
const toReadableDate = (dateStr) => moment.utc(dateStr, mysqlDateTimeFormat).fromNow();

const isZendeskId = str => /[Zz]\d+/.test(str);
const isClubhouseId = str => /[Cc]\d+/.test(str);

const createZendeskUrl = str => {
  const id = str.substring(1);
  return <a
      key={id}
      css={{color: "#888", "&:hover": {color: "#666"}}}
      target="_blank"
      rel={"noopener noreferrer"}
      href={"https://cube19.zendesk.com/agent/tickets/" + id}>
    {str}
  </a>;
};
const createClubhouseUrl = str => {
  const id = str.substring(1);
  return <a
      key={id}
      css={{color: "#888", "&:hover": {color: "#666"}}}
      target="_blank"
      rel={"noopener noreferrer"}
      href={"https://app.clubhouse.io/cube19/story/" + id}>
    {str}
  </a>;
};

export default ChangeSubmissionsMenu;