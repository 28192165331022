import React from "react";
import createReactClass from "create-react-class";
import {isMobile} from "js/common/ua-parser";

import Tabs from "js/common/views/tabs";
import EditUserHistory from "js/admin/groups/edit-user-history-page";
import GroupHistoryBulkUpdate from "js/admin/groups/bulk-update-page";
import GroupManagement from "js/admin/groups/group-management";
import GroupInheritance from "js/admin/groups/group-inheritance/group-inheritance";
import * as Users from "js/common/users";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {GroupHierarchyBulkUpdate} from "js/admin/groups/bulk-update-hierarchy";
import * as auditor from "js/common/auditer";

const GroupsApp = createReactClass({

  getInitialState() {
    return {
      activeTabIndex: 0
    };
  },

  render() {
    let tabs = [
      {
        title: <TabTitle text="Edit Users History" />,
        content: <EditUserHistory />
      }, {
        title: <TabTitle text="Bulk Update - History" />,
        content: <GroupHistoryBulkUpdate />
      }, {
        title: <TabTitle text="Bulk Update - Hierarchy" />,
        content: <GroupHierarchyBulkUpdate />
      }];

    const currentUser = Users.getCurrentUser();
    if (currentUser.get("dataVisibility") === "ALL" || currentUser.get("dataVisibility") === "GROUP_AND_BELOW") {
      tabs.splice(1, 0, {
        title: <TabTitle text="Group Management" />,
        content: <GroupManagement />
      });

      if (!isMobile()) {
        tabs.splice(2, 0, {
          title: <TabTitle text="Group Inheritance" />,
          content: <GroupInheritance />
        });
      }
    }

    let tabNavStyle = {};
    if (this.state.activeTabIndex === 2) {
      tabNavStyle = {...tabNavStyle, overflow: "hidden", maxHeight: "calc(100vh - 200px)"};
    }

    return <Tabs
        selectedIndex={this.state.activeTabIndex}
        onChangeTab={this.handleChangeTab}
        tabs={tabs}
        saveTabStateOnChange={false}
        contentStyle={{minHeight: "440px"}}
        tabNavStyle={{...tabNavStyle}}
        containerStyle={{margin: 3}} />;
  },

  componentDidMount() {
    auditor.audit("group-admin:loaded");
  },

  handleChangeTab(index) {
    this.setState({activeTabIndex: index});
  }

});

const TabTitle = ({icon, text}) => <span><i className={`fa fa-${icon}`} /> {text}</span>;

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <GroupsApp theme={theme} {...props} />;
};

