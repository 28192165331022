import React from "react";
import * as Immutable from "immutable";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Groups from "js/common/groups";
import {MenuItem, Select} from "@mui/material";
import * as Users from "js/common/users";
import {loadPermissionsForUser} from "js/admin/users/app";

export default React.memo(({
  groups = Immutable.Map(),
  users = Immutable.Map(),
  onGroupPermissionChange,
  onUserPermissionChange,
  onGroupUnshare,
  onUserUnshare,
  columns = 1,
  owner,
  editEnabled,
  readOnly
}) => {
  const listStyle = {
    marginTop: "0rem",
    marginBottom: "0rem",
    columns
  };

  const {theme} = React.useContext(CustomThemeContext);

  return (
      <div data-test-id="shared-with-list">
        {owner &&
            <ul style={listStyle}>
              <li style={baseItemStyle(theme)}>
                <i className="fa fa-user" style={iconStyle} />
                {owner.get("fullName")}
                <span style={{display: "flex", flexGrow: 1}} />
                <span
                    style={{
                      fontSize: 12,
                      color: theme.palette.primary.main,
                      paddingRight: 10
                    }}>Owner</span>
              </li>
            </ul>
        }
        {groups.size > 0 && <ul style={listStyle}>
          {groups.valueSeq().sort((groupA, groupB) => {
            const fullGroupA = Groups.getGroup(groupA.get("id"));
            const fullGroupB = Groups.getGroup(groupB.get("id"));
            const breadcrumbsA = fullGroupA.get("breadcrumbs");
            const breadcrumbsB = fullGroupB.get("breadcrumbs");

            //Note: compare breadcrumbs lexicographically so that we maintain nice grouping/hierachy
            for (let i = 0; i < Math.min(breadcrumbsA.length, breadcrumbsB.length); i++) {
              if (breadcrumbsA[i] < breadcrumbsB[i]) return -1;
              if (breadcrumbsA[i] > breadcrumbsB[i]) return 1;
            }

            return breadcrumbsA.length - breadcrumbsB.length;
          }).map(group => <GroupItem
              key={group.get("id")}
              group={group}
              readOnly={readOnly}
              onGroupPermissionChange={onGroupPermissionChange}
              editEnabled={editEnabled}
              onGroupUnshare={onGroupUnshare} />)}
        </ul>}
        {users.size > 0 && <ul style={listStyle}>
          {users.valueSeq().map(user => <UserItem
              key={user.get("id")}
              user={user}
              readOnly={readOnly}
              onUserPermissionChange={onUserPermissionChange}
              editEnabled={editEnabled}
              onUserUnshare={onUserUnshare} />)}
        </ul>}
      </div>
  );
});

const GroupItem = React.memo(({
  group,
  onGroupPermissionChange,
  onGroupUnshare,
  editEnabled,
  readOnly
}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const clickable = !!onGroupPermissionChange;
  const fullGroup = Groups.getGroup(group.get("id"));
  const breadcrumbs = fullGroup.get("breadcrumbs");
  const itemStyle = clickable ? clickableItemStyle(theme) : baseItemStyle(theme);
  return (
      <li style={itemStyle}>
        <i className="fa fa-group" style={{...iconStyle, display: "table-cell"}} />
        <span style={{display: "table-cell"}}>
                {breadcrumbs
                    .map((crumb, i) => (
                        <span key={i}>
                          {(i > 0) && <i className="fa fa-chevron-right" style={dividerStyle} />}
                          {crumb}
                        </span>
                    ))}
            </span>
        <span style={{display: "flex", flexGrow: 1}} />
        {group.get("permission") &&
            <Select
                variant="standard"
                disableUnderline
                disabled={readOnly}
                MenuProps={{
                  style: {zIndex: 9999999}
                }}
                value={group.get("permission")}
                sx={{
                  display: "flex",
                  justifySelf: "flex-end",
                  fontSize: 12,
                  color: theme.palette.primary.main,
                  borderBottom: "none !important",
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.primary.main
                  }
                }}
                inputProps={{"aria-label": "Without label"}}
            >
              <MenuItem
                  sx={{fontSize: 12}}
                  value="VIEW" onClick={() => onGroupPermissionChange(group.get("id"), "VIEW")}>View</MenuItem>
              {editEnabled && <MenuItem
                  sx={{fontSize: 12}}
                  disabled={readOnly}
                  value="EDIT"
                  onClick={() => onGroupPermissionChange(group.get("id"), "EDIT")}>Edit</MenuItem>}
              <MenuItem
                  sx={{fontSize: 12}}
                  value="REMOVE" onClick={() => onGroupUnshare(group.get("id"))}>Remove</MenuItem>
            </Select>
        }
      </li>
  );
});

const UserItem = React.memo(({
  user,
  onUserPermissionChange,
  onUserUnshare,
  editEnabled,
  readOnly
}) => {
  const clickable = !!onUserPermissionChange;
  const {theme} = React.useContext(CustomThemeContext);
  const [permissions, setPermissions] = React.useState();

  React.useEffect(() => Promise.resolve(loadPermissionsForUser(user.get("id"))).then(x => setPermissions(x)), [user]);

  const isSelectedUserFullDashboardUser = permissions?.includes("DASHBOARD_FULL_EDITOR");
  const itemStyle = clickable ? clickableItemStyle(theme) : baseItemStyle(theme);
  const isObserver = user.get("hasLogin") && user.get("state") === "INVISIBLE";
  const isCurrentUser = Users.getCurrentUser().get("id") === user.get("id");
  const isEditUserAndNotFullEditor = user.get("permission") === "EDIT" && !isSelectedUserFullDashboardUser;
  return (
      <li style={itemStyle} data-test-id="shared-with-user-item">
        <i className={`fa fa-user${isObserver ? "-secret" : ""}`} style={iconStyle} />
        {user.get("fullName")}
        <span style={{display: "flex", flexGrow: 1}} />
        {user.get("permission") &&
            <Select
                data-test-id="shared-with-dropdown"
                variant="standard"
                disableUnderline
                disabled={readOnly && !isCurrentUser}
                MenuProps={{
                  style: {zIndex: 9999999}
                }}
                sx={{
                  display: "flex",
                  justifySelf: "flex-end",
                  fontSize: 12,
                  color: !isEditUserAndNotFullEditor ? theme.palette.primary.main : theme.palette.text.disabled,
                  borderBottom: "none !important",
                  "& .MuiSvgIcon-root": {
                    color: theme.palette.primary.main
                  }
                }}
                value={user.get("permission")}
                inputProps={{"aria-label": "Without label"}}
            >
              <MenuItem
                  value="VIEW"
                  sx={{fontSize: 12}}
                  onClick={() => onUserPermissionChange(user.get("id"), "VIEW")}>View</MenuItem>
              {editEnabled && <MenuItem
                  disabled={readOnly || !isSelectedUserFullDashboardUser}
                  value="EDIT"
                  sx={{fontSize: 12}}
                  onClick={() => onUserPermissionChange(user.get("id"), "EDIT")}>Edit</MenuItem>}
              <MenuItem
                  sx={{fontSize: 12}}
                  value="REMOVE"
                  onClick={() => onUserUnshare(user.get("id"))}>Remove</MenuItem>
            </Select>
        }
      </li>
  );
});

const baseItemStyle = theme => ({
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  borderBottom: `1px solid ${theme.palette.border.lightest}`,
  fontSize: "13px",
  color: theme.palette.text.main,
  display: "flex",
  alignItems: "center"
});

const clickableItemStyle = theme => ({
  ...baseItemStyle(theme),
  cursor: "pointer"
});

const iconStyle = {
  color: "#9e9e9e",
  paddingRight: 10
};

const dividerStyle = {
  fontSize: "0.725rem",
  paddingLeft: 5,
  paddingRight: 5
};
