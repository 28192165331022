import React from "react";
import Select from "js/common/views/inputs/immutable-react-select";
import Immutable from "immutable";

export default ({ trendType, onChange, qualifierType, value = "value" }) => {
    let options = [{
        value: "value",
        label: "Value"
    }];
    if (trendType === "KPI") {
        options = options.concat([{
            value: "target",
            label: "Target"
        },{
            value: "percentage-target",
            label: "% of Target"
        }]);
    }
    if (qualifierType === "GROUP"){
        options.push({
            value: "average-by-user",
            label: "Average by user"
        });
    }

    return (
        <Select
            name="what-to-plot"
            placeholder="What to plot"
            selectedValue={value}
            isClearable={false}
            isSearchable={false}
            isMulti={false}
            options={Immutable.fromJS(options)}
            onChange={newOption => onChange(newOption)} />);
};
