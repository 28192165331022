import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

import Cube19 from "js/cube19.app";
import {apiUrl} from "js/app-configuration";

const $ = window.$;
const _ = window._;
const path = window.path;

const PagedSearchPicker = createReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        searchPath: ReactPropTypes.string.isRequired,
        entity: ReactPropTypes.string.isRequired,
        placeholder: ReactPropTypes.string.isRequired,
        fetchInitialItems: ReactPropTypes.func.isRequired,
        onChange: ReactPropTypes.func.isRequired,
        selectedIds: ImmutablePropTypes.list,
        isDisabled: ReactPropTypes.bool,
        iconClass: ReactPropTypes.string
    },

    componentDidMount() {
        this.node = $(this.select)
            .select2({
                language: {
                    inputTooShort: function(args) {
                        return "";
                    }
                },
                placeholder: this.props.placeholder,
                width: "100%",
                dropdownCssClass: `table-${this.props.theme.themeId}`,
                multiple: true,
                closeOnSelect: false,
                ajax: {
                    url: () => apiUrl + path(Cube19.urlRoot, this.props.searchPath),
                    xhrFields: {withCredentials: true},
                    dataType: "json",
                    delay: 250,
                    data: params => {
                        return {
                            entity: this.props.entity,
                            namePrefix: params.term,    // the search term
                            page: params.page,
                            itemsPerPage: 30    // NOTE: if < 6 Select2 infinite scroll pagination breaks
                        };
                    },
                    processResults: (data, params) => {
                        const more = (params.page * 30) < data.totalCount;
                        this.setState({
                            searchTerm: params.term,
                            totalSearchResults: data.totalCount,
                            moreSearchResults: more
                        });
                        params.page = params.page || 1;

                        return {
                            results: _(data.items).sortBy(item => item.name.toLowerCase()),
                            pagination: {
                                more
                            }
                        };
                    },
                    cache: true
                },
                escapeMarkup: markup => markup, // let Select2 custom formatter work
                minimumInputLength: 1,
                templateResult: this.formatItem,
                templateSelection: this.formatItemSelection
            })
            .on("select2:close", this.handleChange);

        if (this.props.selectedIds && !this.props.selectedIds.isEmpty()) {
            this.setSelectedIds();
        }
    },

    componentWillUnmount() {
        this.node.select2("destroy");
    },

    componentDidUpdate(oldProps) {
        if (this.props.searchPath !== oldProps.searchPath) {
            this.clearSelections();
        }
    },

    render() {
        return <select ref={select => this.select = select} name="search-picker" disabled={this.props.isDisabled}/>;
    },

    formatItem(item) {
        if (item.loading) {
            return item.text;
        } else {
            let formattedItem = "<span class='search'>" + item.name + "</span>";
            if (this.props.iconClass) {
                formattedItem = "<i class='" + this.props.iconClass + "'></i>&nbsp;&nbsp;" + formattedItem;
            }
            return formattedItem;
        }
    },

    formatItemSelection(item) {
        return item.name || item.text;
    },

    handleChange() {
        const selectedIds = Immutable.fromJS(this.node.select2('data').map(item => item.id));
        const hasSelectedIdsChanged = !Immutable.is(selectedIds, this.props.selectedIds);
        if (hasSelectedIdsChanged) {
            this.props.onChange(selectedIds);
        }
    },

    setSelectedIds() {
        this.clearSelections();
        this.props.fetchInitialItems(this.props.selectedIds).then(items => this.setItems(items));
    },


    setItems(items) {
        Immutable.fromJS(items)
            .forEach(item => {
                const option = "<option value='" + item.get("id") + "' selected>" + item.get("name") + "</option>";
                this.node.append(option);
            });
        this.node.find("option").removeData();     // remove any associated caching data
        this.node.trigger("change.select2"); // fire Select2 'change' event
    },

    clearSelections() {
        this.node.val(null).trigger('change');
    }

});

const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <PagedSearchPicker theme={theme} {...props} />;
};

PagedSearchPicker.displayName = "PagedSearchPicker";
export default Wrapper;
