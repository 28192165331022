import React from "react";

import {betterMemo} from "js/common/utils/more-memo";
import MultiSelect from "js/admin/kpis/edit-kpis/tabs/common/multi-select";
import Immutable from "immutable";

const ConfigOwnership = betterMemo(
    {displayName: "ConfigOwnership"},
    ({
      kpi,
      typeToGroupingEntity,
      entityNames,
      onChange,
      disabled
    }) => {
      const formatFromParamString = (linkedEntities) => {
        const values = []
        linkedEntities.forEach((v,k) => {
          v.forEach(assignee => values.push(`${k}:${assignee}`))
        })
        return values;
      };

      const rootGroupingEntity = kpi.get("readOnlyRootGroupingEntity");

      const selectedlinkedEntities = React.useMemo( () => {return Immutable.fromJS(formatFromParamString(kpi.getIn(
            ["config", "entityToOwnerTypes"],
            Immutable.List())))}, [kpi]);

      let linkedEntities = typeToGroupingEntity
          .get(rootGroupingEntity)
          .get("directJoinsTo");

      if (rootGroupingEntity === "PLACEMENT_SPLIT") {
        linkedEntities = linkedEntities
            .concat(typeToGroupingEntity.getIn(["PLACEMENT", "directJoinsTo"]))
            .toSet()
            .toList();
      }

      const linkedEntitiesOptions = [];
      // Add the readOnlyRootGroupingEntity
      if(rootGroupingEntity) {
        const rootOwnerLabel = `${entityNames.get(rootGroupingEntity).get("label")} - Owner`;
        const rootAssigneeLabel = `${entityNames.get(rootGroupingEntity).get("label")} - Assignee`;
        linkedEntitiesOptions.push({value: `RECORD:OWNER`, label: rootOwnerLabel})
        linkedEntitiesOptions.push({value: `RECORD:ASSIGNEE`, label: rootAssigneeLabel})
      }

      linkedEntities
          .filter(entity => typeToGroupingEntity.has(entity) && typeToGroupingEntity.get(entity).get("filterableByDate"))
          .forEach(entity => {
              // Added this check to ensure that the entity exists, due clients not having LEAD entity
            if (entityNames.has(entity)) {
                if(rootGroupingEntity === entity) {
                    linkedEntitiesOptions.push({value: `${entity}:OWNER`, label: `Parent ${entityNames.get(entity).get("label")} - Owner`});
                    linkedEntitiesOptions.push({value: `${entity}:ASSIGNEE`, label: `Parent ${entityNames.get(entity).get("label")} - Assignee`})
                } else {
                    const ownerLabel = `${entityNames.get(entity).get("label")} - Owner`;
                    const assigneeLabel = `${entityNames.get(entity).get("label")} - Assignee`;
                    // Add other entities
                    linkedEntitiesOptions.push({value: `${entity}:OWNER`, label: ownerLabel});
                    linkedEntitiesOptions.push({value: `${entity}:ASSIGNEE`, label: assigneeLabel})
                }
            }
          });
      const updateEntityToOwnerType = (ownerTypes) => {
        const ots = ownerTypes.groupBy(ot => ot.split(":")[0]).map(x => x.map(y => y.split(":")[1]));
        onChange(ots)
      }

      return <MultiSelect
          selectedValues={selectedlinkedEntities}
          onChange={updateEntityToOwnerType}
          placeholder="Select Ownership Type"
          options={Immutable.fromJS(linkedEntitiesOptions)}
          label="Owners"
          labelTooltip="Select the ownership fields that this metric should use to determine who is credited with the activity."
          disabled={disabled} />
    });

export default ConfigOwnership;
