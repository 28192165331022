import React from "react";
import ReactHighcharts from "react-highcharts";
import createHighchartsConfig from "js/common/highcharts-config";
import pure from "js/common/views/pure";

export default pure(({
    theme, xAxisLabel, yAxisLabel, xAxisLabelFormatter, yAxisLabelFormatter, categories, seriesData
}) => {
    const fontColor = theme.palette.text.main;
    const fontFamily = theme.typography.fontFamily;
    const columnColor = "#37BC9B";

    const config = createHighchartsConfig({
        chart: {
            type: "column",
            height: 120,
            spacingTop: 15,
            spacingBottom: 0,
            marginBottom: 50
        },
        xAxis: {
            tickLength: 5,
            gridLineWidth: 0,
            categories: categories,
            title: {
                text: xAxisLabel,
                style: {
                    color: fontColor,
                    fontFamily: fontFamily
                }
            },
            labels: {
                autoRotationLimit: 0,
                formatter: xAxisLabelFormatter || function() {return this.value;},
                useHTML: true,
                style: {
                    color: fontColor,
                    fontFamily: fontFamily
                }
            }
        },
        yAxis: {
            tickLength: 0,
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            min: 0,
            labels: {
                enabled: false,
                formatter: yAxisLabelFormatter || function() {return this.value;},
                useHTML: true
            },
            title: {
                text: yAxisLabel,
                style: {
                    whiteSpace: "nowrap",
                    color: fontColor,
                    fontFamily: fontFamily
                }
            }
        },
        plotOptions: {
            column: {
                dataLabels: {
                    inside: false,
                    crop: false,
                    overflow: "always"
                },
                maxPointWidth: 40,
                pointPadding: 0.2,
                groupPadding: 0,
                borderWidth: 0
            },
            series: {
                animation: false,
                dataLabels: {
                    enabled: true,
                    color: fontColor,
                    style: {
                        fontFamily: fontFamily
                    }
                }
            }
        },
        exporting: {
            enabled: false
        },
        legend: {
            enabled: false
        },
        tooltip: {
            enabled: false
        },
        series: [{
            color: columnColor,
            data: seriesData
        }]
    });

    return (<ReactHighcharts config={config} isPureConfig={true} />);
});
