import React from "react";
import createReactClass from "create-react-class";

import currentClient from "js/common/repo/backbone/current-client";
import {IconButton} from "@mui/material";
import * as Strings from "js/common/utils/strings";
import lightTheme from "img/theme/light-illustration.svg";
import darkTheme from "img/theme/dark-illustration.svg";

export default createReactClass({

    getInitialState() {
        return {
            currentTheme: currentClient.getTheme(),
        };
    },


    render() {
        const { currentTheme } = this.state;

        if (!currentTheme) return <div>Client default theme setting not found.</div>

        const themes = [
            {name: "Light", id: "light", image: lightTheme},
            {name: "Dark", id: "dark", image: darkTheme},
        ];

        return (
            <div style={{paddingLeft: 20}}>
                <h1>Default theme</h1>
                <p>Current default theme: {Strings.capitaliseWords(currentTheme)}</p>
                <div>
                    <div>
                        <div style={{height: 140, display: "flex"}}>
                            {themes.map(option => {
                                const isSelected = option.id === currentTheme.toLowerCase();
                                const selectedStyle = isSelected && {border: `3px solid yellow`};
                                return (
                                    <div style={{display: "flex", flexDirection: "column", margin: "15px 0 10px 10px"}}>
                                        <IconButton
                                            style={{...selectedStyle, borderRadius: 10, padding: 0}}
                                            onClick={() => this.handleChangeTheme(option.id)}
                                            size="large">
                                            <img src={option.image} alt={option.name} width="120px" />
                                        </IconButton>
                                        <div style={{textAlign: "center"}}>{option.name}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>);
    },

    handleChangeTheme(currentTheme) {
        currentClient.save({theme: currentTheme.toUpperCase()}, {wait: true});
        this.setState({currentTheme})
    },


});
