import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";
import * as Pages from "js/common/pages";

import AdminHeader from "js/admin/common/admin-header";
import Page from "js/admin/oneview-pages/page";
import InteractiveTable from "js/common/views/tables/interactive-table";
import PlaceholderTextField from "js/common/views/inputs/placeholder-text-field";
import {TextButton, IconButton} from "js/common/views/inputs/buttons";
import pure from "js/common/views/pure";
import LoadingSpinner from "js/common/views/loading-spinner";
import {getUniqueName} from "js/common/utils/unique-naming";
import * as popups from "js/common/popups";
import * as auditor from "js/common/auditer";
import DeletePageDialog from "js/admin/oneview-pages/delete-page-dialog";
import UsagesDialog from "js/admin/oneview-pages/usages-dialog";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const maxNameLength = 64;

const OneviewPages = createReactClass({

  displayName: "PagesAdmin",

  getInitialState() {
    return {
      isLoading: true,
      pageById: Immutable.Map(),
      selectedPageId: null,
      pageIdToDelete: null,
      pageIdToDisplayUsages: null
    };
  },

  componentDidMount() {
    Pages.getAll()
        .then(pages => {
          this.setState({
            isLoading: false,
            pageById: pages.map(deepSort).groupBy(page => page.get("id")).map(p => p.first())
          });
          auditor.audit("oneview-admin:loaded");
        });
  },

  render() {
    const {selectedPageId, pageById, isLoading} = this.state;
    const {theme} = this.props;
    return (
        <div>
          <Breadcrumbs
              theme={theme}
              selectedPageName={selectedPageId ? pageById.get(selectedPageId).get("name") : ""}
              onClickBreadcrumb={() => this.setState({selectedPageId: null})} />
          {isLoading ? <LoadingSpinner /> : this.renderPage()}

        </div>
    );
  },

  renderPage() {
    const {selectedPageId, pageById, pageIdToDelete, pageIdToDisplayUsages} = this.state;
    const {theme} = this.props;
    if (selectedPageId) {
      return (
          <Page
              page={pageById.get(selectedPageId)}
              onPageChange={this.handlePageChange} />
      );
    } else {
      return (
          <div style={{padding: "1rem"}}>
            <div style={{marginBottom: "1rem", textAlign: "center"}}>
              <TextButton icon="plus" label="Add Page" onClick={this.addPage} />
            </div>
            <InteractiveTable
                columns={[
                  "Name",
                  {label: "In Use", width: 50},
                  {label: "Actions", textAlign: "center", width: 300}]}
                rows={pageById.valueSeq()
                    .filter(page => !page.get("isDeleted"))
                    .map(this.renderPageRow)
                    .toArray()} />
            {pageIdToDelete && <DeletePageDialog
                theme={theme}
                page={pageById.get(pageIdToDelete)}
                onRequestClose={() => this.setState({pageIdToDelete: null})}
                onDelete={() => this.deletePage(pageIdToDelete)} />}
            {pageIdToDisplayUsages && <UsagesDialog
                theme={theme}
                page={pageById.get(pageIdToDisplayUsages)}
                onRequestClose={() => this.setState({pageIdToDisplayUsages: null})} />}
          </div>
      );
    }
  },

  renderPageRow(page) {
    const name = page.get("name");
    const {theme} = this.props;
    const disabled = page.get("isLoading");

    const linkStyle = theme => ({
      color: theme.palette.action.active,
      ":hover": {
        color: `${theme.palette.action.hover}!important`
      }
    });

    return [
      <div>
        <PlaceholderTextField
            hintText="Page Name"
            maxLength={128}
            disabled={disabled}
            value={name}
            onChange={name => this.handleNameChange(page.get("id"), name)} />
      </div>,
      <div style={{width: 100}}>
        {page.get("usages").count() > 0 &&
            <a
                title="View page"
                style={linkStyle(theme)}
                onClick={() => this.setState({pageIdToDisplayUsages: page.get("id")})}>
              <i className="fa fa-check-circle" style={{cursor: "pointer"}} />&nbsp;View
            </a>
        }
      </div>,
      <div style={{width: 300}}>
        <IconButton
            label="Edit"
            icon="edit"
            type="bright"
            disableClick={disabled}
            container="column"
            onClick={() => this.setState({selectedPageId: page.get("id")})}
            size="large" />
        <IconButton
            label="Clone"
            icon="clone"
            type="bright"
            disableClick={disabled}
            container="column"
            onClick={() => this.clonePage(page)}
            size="large" />
        <IconButton
            label="Delete"
            icon="times"
            type="alert"
            disableClick={disabled}
            container="column"
            onClick={() => this.onDeleteClicked(page.get("id"))}
            size="large" />
      </div>
    ];
  },

  addPage() {
    const pageById = this.state.pageById;
    const newPage = Immutable.fromJS({name: "New Page", sections: [], ratioAssignments: []});
    Pages.create(newPage)
        .then(page => {
          this.setState({
            pageById: pageById.set(page.get("id"), page)
          });
        });
  },

  clonePage(page) {
    const pageById = this.state.pageById;
    const existingNames = pageById.valueSeq().map(page => page.get("name"));
    const cleanSections = page.get("sections")
        .map(section => section.delete("id")
            .delete("pageId")
            .update("assignedKpis", kpis => kpis.map(kpi => kpi.delete("id"))));
    const cleanRatioAssignments = page.get("ratioAssignments").map(ra => ra.remove("id"));
    const newPage = Immutable.Map()
        .set("name", getUniqueName(page.get("name"), existingNames, maxNameLength))
        .set("sections", cleanSections)
        .set("ratioAssignments", cleanRatioAssignments);
    this.setState({
      pageById: pageById.set(page.get("id"), page.set("isLoading", true))
    });
    Pages.create(newPage)
        .then(createdPage => {
          auditor.audit("page:clone", {
            clonedPageId: page.get("id"),
            newPageId: createdPage.get("id")
          });
          this.setState({
            pageById: pageById.set(createdPage.get("id"), createdPage)
                .set(page.get("id"), page)
          });
        }, () => {
          popups.contactSupport();
          this.setState({
            pageById: pageById.set(page.get("id"), page)
          });
        });
  },

  handleNameChange(pageId, newName) {
    newName = newName.substring(0, maxNameLength);
    if (!newName) {
      popups.error("Change not saved. Name cannot be empty");
      return;
    }
    const pageById = this.state.pageById;
    const existingNames = pageById.valueSeq().map(page => page.get("name"));
    if (existingNames.includes(newName)) {
      popups.error("Change not saved. Name already in use");
      return;
    }
    const page = pageById.get(pageId);
    const updatedPage = page.set("name", newName);
    this.setState({
      pageById: pageById.set(pageId, updatedPage.set("isLoading", true))
    });
    Pages.update(updatedPage)
        .then(returnedPage => {
          this.setState({
            pageById: pageById.set(pageId, returnedPage)
          });
        }, () => {
          popups.contactSupport();
          this.setState({
            pageById: pageById.set(page.get("id"), page)
          });
        });
  },

  handlePageChange(page) {
    this.setState({
      pageById: this.state.pageById.set(page.get("id"), page)
    });
  },

  onDeleteClicked(pageId) {
    const pageById = this.state.pageById;
    if (pageById.get(pageId).get("usages").count() === 0) {
      this.deletePage(pageId);
    } else {
      this.setState({
        pageIdToDelete: pageId
      });
    }
  },

  deletePage(pageId) {
    const pageById = this.state.pageById;
    const page = pageById.get(pageId);
    this.setState({
      pageIdToDelete: null,
      pageById: pageById.set(pageId, page.set("isLoading", true))
    });
    Pages.del(pageId)
        .then(() => {
          this.setState({
            pageById: pageById.delete(pageId)
          });
        }, () => {
          popups.error("Unable to delete page.");
          this.setState({
            pageById: pageById.set(pageId, page)
          });
        });
  }
});

const Breadcrumbs = pure(({
  theme,
  selectedPageName,
  onClickBreadcrumb
}) => (
    <AdminHeader>
      <span onClick={onClickBreadcrumb} style={{cursor: "pointer"}}>All Pages</span>
      {selectedPageName &&
          <span>
                <i
                    className="fa fa-chevron-right"
                    style={{fontSize: "10px", paddingLeft: "10px", paddingRight: "10px"}}
                    aria-hidden="true" />
                <span style={{color: theme.palette.primary.main}}>{selectedPageName}</span>
            </span>}
    </AdminHeader>
));

const deepSort = page => {
  let sections = page.get("sections");
  sections = sections.sortBy(section => section.get("order"));
  sections = sections.map(section => section.update(
      "assignedKpis",
      assignedKpis => assignedKpis.sortBy(kpiAssignment => kpiAssignment.get("order"))));
  return page
      .set("sections", sections)
      .set("ratioAssignments", page.get("ratioAssignments").sortBy(assignment => assignment.get("order")));
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <OneviewPages theme={theme} {...props} />;
};
