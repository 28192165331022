import React from "react";

import {betterMemo} from "js/common/utils/more-memo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const sectionBarStyle = (theme) => ({
  display: "flex",
  alignItems: "center",
  background: theme.themeId === "light" ? theme.palette.background.paper : theme.palette.background.card,
  padding: 10
});

const sectionHeadingStyle = {
  fontWeight: 700,
  textTransform: "uppercase",
  fontSize: 16,
  marginBottom: 5
};

const sectionDescriptionStyle = {
  fontSize: 13,
  marginBottom: 0
};

const TabSection = betterMemo(
    {displayName: "TabSection"},
    ({title, description, isCollapsible = true, children, autoExpand = false}) => {
      const [expanded, setExpanded] = React.useState(autoExpand);
      const {theme} = React.useContext(CustomThemeContext);
      return (
          <div>
            <div style={sectionBarStyle(theme)}>
              <div>
                <h2 style={sectionHeadingStyle}>{title}</h2>
                <p style={sectionDescriptionStyle}>{description}</p>
              </div>
              <div style={{flexGrow: 1}} />
              <i
                  className={isCollapsible && expanded ? "bhi-sort-asc" : "bhi-sort-desc"}
                  onClick={() => setExpanded(!expanded)}
              />
            </div>
            <div style={{display: expanded ? "block" : "none", padding: 10}}>
              {children}
            </div>
          </div>
      );
    });

export default TabSection;
