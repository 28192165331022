import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const containerStyle = theme => ({
    width: "100%",
    borderTop: `1px solid ${theme.palette.background.paper}`,
    background: theme.themeId === "light" ? theme.palette.background.paper : theme.palette.background.card,
    padding: 10,
    fontWeight: "bold",
    fontSize: "0.8rem",
    display: "flex",
});

const ThinSectionHeader = React.memo(({ id = `section-header-${Math.random()}`, label, icon, style }) => {
    const { theme } = React.useContext(CustomThemeContext);
    return (
        <div id={id} style={containerStyle(theme)}>
        <span style={{ color: theme.palette.text.main }}>
            {icon && <i className={`fa fa-${icon}`} style={{ paddingRight:10, ...style }} />}
            {label}
        </span>
        </div>)
});

ThinSectionHeader.displayName = "ThinSectionHeader";
export default ThinSectionHeader;
