/** @jsxImportSource @emotion/react */

import React from "react";
import Immutable from "immutable";
import {css} from "@emotion/react";
import {Chip} from "@mui/material";

import {indexBy} from "js/common/utils/collections";
import * as Users from "js/common/users";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as TagRepo from "js/common/repo/backbone/tag-repo";
import useBooleanState from "js/common/hooks/use-boolean-state";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import GroupAndUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import ClientFilter from "js/common/views/client-filter/client-filter";
import DropdownToggle from "js/common/views/react-dropdown-toggle";
import PreviewTooltip from "js/oneview/header/tooltip";
import {PreviewTooltipContent} from "js/common/views/filter-drawer/filter-button";
import InheritSwitch from "js/common/inherit-switch";

const rowStyle = {
  justifyContent: "space-between",
  display: "flex",
  columnGap: "0.3rem",
  rowGap: "0.3rem",
  flexWrap: "wrap",
  paddingBottom: "0.3rem"
};

const filtersContainerStyle = () => css`
  position: relative;
  border-top: 1px solid rgba(0,0,0,0.1);
  padding: 12px 0 0 0;
  margin-top: 10px;
`;

const DataConfigToolbar = React.memo(({allowInherit = true, config, onConfigChange, label = "Panel filters", topLevel = false}) => {
  const [filterNames, setFilterNames] = React.useState(Immutable.Map());
  const [showFilters, setShowFilters] = React.useState(true);

  const { theme } = React.useContext(CustomThemeContext);

  const inheritKeys = React.useMemo(() => config.get("inheritKeys", Immutable.Set()).toSet(), [config]);

  const toggleInheritance = React.useCallback(key => {
    onConfigChange(config.update("inheritKeys", Immutable.Set(), keys => {
      keys = keys.toSet();
      if (keys.includes(key)) {
        return keys.remove(key);
      } else {
        return keys.add(key);
      }
    }));
  }, [onConfigChange, config]);

  return <div css={filtersContainerStyle()} data-product-id={topLevel ? "global-filters" : "custom-filters"}>
    <>
      {topLevel &&
      <i onClick={() => setShowFilters(!showFilters)} className={showFilters ? "bhi-sort-asc" : "bhi-sort-desc"}
         style={{
           fontSize: 11,
           position: "absolute",
           top: -16,
           right: 10,
           background: theme.palette.background.paper,
           padding: 5,
           cursor: "pointer"
         }} />
      }
    <span onClick={() => setShowFilters(!showFilters)} style={{
      display: "flex",
      alignItems: "center",
      padding: "0 5px",
      fontSize: 11,
      position: "absolute",
      top: -13,
      left: 10,
      background: topLevel ? theme.palette.background.paper : theme.themeId === "light" ? "#f9f9f8" : theme.palette.background.card,
    }}>
      <span style={{borderRadius: "50%", background: "#4a89dc40", marginRight: 5, width: 21, height: 21, lineHeight: "21px", textAlign: "center"}}><i className="bhi-funnel" style={{color: theme.palette.primary.main}}/></span> {label}</span>
   </>
    <div style={{display: showFilters ? "block" : "none"}}>
    <div style={rowStyle}>
      <div style={{minWidth: 360, display: "flex", flexDirection: "column",  flex: "1 1 0px"}}>
        {allowInherit && <InheritSwitch
            inheritKey="qualifier"
            inheritKeys={inheritKeys}
            onToggle={toggleInheritance} />}
        <div style={{flex: 1}}>
          <GroupAndUserPicker
              showLoggedInUserOptions={true}
              isDisabled={(allowInherit && inheritKeys.includes("qualifier"))
                  || Users.getCurrentUser().get("dataVisibility") === "SELF"}
              qualifierType={config.getIn(["qualifier", "type"])}
              qualifierId={config.getIn(["qualifier", "id"])}
              onGroupClick={groupId => onConfigChange(config.set("qualifier", Immutable.Map({
                type: "GROUP",
                id: groupId
              })))}
              onUserClick={userId => onConfigChange(config.set("qualifier", Immutable.Map({
                type: "USER",
                id: userId
              })))} />
        </div>
      </div>
      <div style={{minWidth: 360, display: "flex", flexDirection: "column", flex: "1 1 0px"}}>
        {allowInherit && <InheritSwitch
            inheritKey="timeframe"
            inheritKeys={inheritKeys}
            onToggle={toggleInheritance} />}
        <div style={{flex: 1}}>
          <TimeframePicker
              isDisabled={allowInherit && inheritKeys.includes("timeframe")}
              timeframe={TimeframeRepo.parse(config.get("timeframe").toJS())}
              onChange={timeframe => onConfigChange(config.set("timeframe", Immutable.fromJS(timeframe.getRawJson())))} />
        </div>
      </div>
      <div style={{minWidth: 360, display: "flex", flexDirection: "column", flex: "1 1 0px"}}>
        {allowInherit && <InheritSwitch
            inheritKey="clientFilter"
            inheritKeys={inheritKeys}
            onToggle={toggleInheritance} />}
        <div style={{flex: 1}}>
          <PreviewableClientFilter
              isDisabled={allowInherit && inheritKeys.includes("clientFilter")}
              clientFilter={config.get("clientFilter", Immutable.Map())}
              onChange={clientFilter => onConfigChange(config.set("clientFilter", clientFilter))}
              filterNames={filterNames}
              setFilterNames={setFilterNames} />
        </div>
      </div>
    </div>

    <div style={{
      display: "flex",
      columnGap: "0.3rem",
      rowGap: "0.3rem",
      flexWrap: "wrap"
    }}>
      <div style={{minWidth: 360, display: "flex", flexDirection: "column", flex: "1 1 0px"}}>
      {allowInherit && <InheritSwitch
          inheritKey="tagFilter"
          inheritKeys={inheritKeys}
          onToggle={toggleInheritance} />}
      <div style={{flex: 1, minWidth: 300}}>
        <TagFilter
            isDisabled={allowInherit && inheritKeys.includes("tagFilter")}
            matchType="ANY"
            filterNames={filterNames}
            setFilterNames={setFilterNames}
            clientFilter={config.get("clientFilter", Immutable.Map())}
            tagFilter={config.get("tagFilter", Immutable.Map())}
            tagIds={config.getIn(["tagFilter", "matchAnyTagIds"], Immutable.Set())}
            onChange={tagIds => onConfigChange(config.setIn(["tagFilter", "matchAnyTagIds"], tagIds))} />
      </div>
      </div>
      <div style={{minWidth: 360, display: "flex", flexDirection: "column", flex: "1 1 0px"}}>
        {allowInherit && <InheritSwitch
            inheritKey="tagFilter"
            inheritKeys={inheritKeys}
            onToggle={toggleInheritance} />}
      <div style={{flex: 1, minWidth: 300}}>
        <TagFilter
            isDisabled={allowInherit && inheritKeys.includes("tagFilter")}
            matchType="ALL"
            filterNames={filterNames}
            setFilterNames={setFilterNames}
            clientFilter={config.get("clientFilter", Immutable.Map())}
            tagFilter={config.get("tagFilter", Immutable.Map())}
            tagIds={config.getIn(["tagFilter", "matchAllTagIds"], Immutable.Set())}
            onChange={tagIds => onConfigChange(config.setIn(["tagFilter", "matchAllTagIds"], tagIds))} />

      </div>
      </div>
      <div style={{minWidth: 360, display: "flex", flexDirection: "column", flex: "1 1 0px"}}>
      {allowInherit && <InheritSwitch
          inheritKey="tagFilter"
          inheritKeys={inheritKeys}
          onToggle={toggleInheritance} />}

      <div style={{flex: 1, minWidth: 300}}>
        <TagFilter
            isDisabled={allowInherit && inheritKeys.includes("tagFilter")}
            matchType="NONE"
            filterNames={filterNames}
            setFilterNames={setFilterNames}
            clientFilter={config.get("clientFilter", Immutable.Map())}
            tagFilter={config.get("tagFilter", Immutable.Map())}
            tagIds={config.getIn(["tagFilter", "excludedTagIds"], Immutable.Set())}
            onChange={tagIds => onConfigChange(config.setIn(["tagFilter", "excludedTagIds"], tagIds))} />
      </div>
      </div>
    </div>
    </div>
  </div>;
});

const clientWord = count => count === 1 ? "client" : "clients";
const clientSetWord = count => count === 1 ? "client set" : "client sets";

const PreviewableClientFilter = React.memo(({
  clientFilter,
  onChange,
  filterNames,
  setFilterNames,
  isDisabled
}) => {
  const clientIds = clientFilter.get("clientIds", Immutable.Set());
  const clientSetIds = clientFilter.get("clientSetIds", Immutable.Set());

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const isFiltering = !(clientIds.isEmpty() && clientSetIds.isEmpty());

  let placeholder;
  if (isFiltering) {
    const placeholderParts = [];
    if (!clientIds.isEmpty()) {
      placeholderParts.push(clientIds.size + " " + clientWord(clientIds.size));
    }
    if (!clientSetIds.isEmpty()) {
      placeholderParts.push(clientSetIds.size + " " + clientSetWord(clientSetIds.size));
    }
    placeholder = "Filtering by " + placeholderParts.join(" and ");
  } else {
    placeholder = <span style={{opacity: isDisabled ? 0.5 : 1}}>
      <i className="bhi-filter" style={{marginRight: "8px", marginTop: "-1px"}} />
      Filter by Client
    </span>;
  }

  const handleChange = React.useCallback(
      (allClientIds, clientIds, clientSetIds) => onChange(
          Immutable.fromJS({allClientIds, clientIds, clientSetIds})),
      [onChange]);

  // TODO grey out client filter when disabled
  return <PreviewTooltip
      disable={!isFiltering || isDropdownOpen}
      typeToIdToNamedItem={filterNames}
      setNames={setFilterNames}
      content={<PreviewTooltipContent
          invertTagColours
          filterNames={filterNames}
          matchAllTagIds={Immutable.Set()}
          matchAnyTagIds={Immutable.Set()}
          excludedTagIds={Immutable.Set()}
          hasShowAll
          clientIds={clientIds}
          clientSetIds={clientSetIds} />}
      selectedClientIds={clientIds}
      selectedClientSetIds={clientSetIds}>
    <ClientFilter
        isDisabled={isDisabled}
        flushChangesImmediately={true}
        showSelectedInDropdown={true}
        customPlaceholder={placeholder}
        onOpenStateChange={setIsDropdownOpen}
        selectedClientIds={clientIds}
        selectedClientSetIds={clientSetIds}
        onChange={handleChange} />
  </PreviewTooltip>;
});

const getTagOptions = () => Immutable
    .fromJS(TagRepo.getAll().toJSON())
    .map(tag => {
      const category = Immutable.fromJS(TagRepo.getCategory(tag.get("categoryId")).toJSON());
      return Immutable.Map({
        categoryName: category.get("name"),
        name: tag.get("name"),
        id: tag.get("id")
      });
    });

const getTagWord = count => count === 1 ? "Tag" : "Tags";

const matchTypeToLabelGetter = Immutable.fromJS({
  ANY: countOfTagIds => countOfTagIds === 0
      ? "Match Any Tags"
      : "Matching Any of " + countOfTagIds + " " + getTagWord(countOfTagIds),
  ALL: countOfTagIds => countOfTagIds === 0
      ? "Match All Tags"
      : "Matching All of " + countOfTagIds + " " + getTagWord(countOfTagIds),
  NONE: countOfTagIds => countOfTagIds === 0
      ? "Exclude Tags"
      : "Excluding " + countOfTagIds + " " + getTagWord(countOfTagIds)
});

const tagSortMapper = tag => tag.get("categoryName") + tag.get("name");

const TagFilter = React.memo(({
  isDisabled,
  matchType,
  tagIds,
  tagFilter,
  onChange,
  filterNames,
  setFilterNames
}) => {
  tagIds = tagIds.toSet();

  const {flag: isDropdownOpen, setFalse: closeDropdown, toggle: toggleDropdown} = useBooleanState();

  const tagOptions = React.useMemo(
      () => getTagOptions().sortBy(tagSortMapper),
      []);
  const idToTagOption = React.useMemo(() => indexBy(t => t.get("id"), tagOptions), [tagOptions]);

  const label = matchTypeToLabelGetter.get(matchType)(tagIds.size);
  const {theme} = React.useContext(CustomThemeContext);

  return <PreviewTooltip
      disable={tagIds.isEmpty() || isDropdownOpen}
      typeToIdToNamedItem={filterNames}
      setNames={setFilterNames}
      content={<PreviewTooltipContent
          invertTagColours
          filterNames={filterNames}
          matchAllTagIds={tagFilter.get("matchAllTagIds", Immutable.Set())}
          matchAnyTagIds={tagFilter.get("matchAnyTagIds", Immutable.Set())}
          excludedTagIds={tagFilter.get("excludedTagIds", Immutable.Set())}
          clientIds={Immutable.Set()}
          clientSetIds={Immutable.Set()} />}
      selectedClientIds={Immutable.Set()}
      selectedClientSetIds={Immutable.Set()}>
    <DropdownToggle
        isDisabled={isDisabled}
        primaryText={label}
        isOpen={isDropdownOpen}
        tooltipDisabled={true}
        onToggleButtonClick={toggleDropdown}
        onRequestClose={closeDropdown}>
      <div>
        <div css={dropdownHeadingCss(theme)}>Selected <span style={{float: "right", color: theme.palette.primary.main, cursor: "pointer"}} onClick={() => onChange(Immutable.Set())}>Remove all</span></div>
        {tagIds
            .map(tagId => idToTagOption.get(tagId))
            .sortBy(tagSortMapper)
            .map(tag => <TagItem
                key={tag.get("id")}
                tag={tag}
                showDelete={true}
                onClick={() => onChange(tagIds.remove(tag.get("id")))} />)}
          {tagIds
            .map(tagId => idToTagOption.get(tagId))
            .count() < 1 &&
            <div style={{paddingBottom: "0.5em", textAlign: "center"}}>No selected tags</div>
          }
        <div css={dropdownHeadingCss(theme)}>Available</div>
        {tagOptions.filter(tag => !tagIds.has(tag.get("id"))).count() < 1 && <div style={{paddingBottom: "0.5em", textAlign: "center"}}>No available tags</div>}
        {tagOptions
            .filter(tag => !tagIds.has(tag.get("id")))
            .map(tag => <TagItem key={tag.get("id")} tag={tag} showDelete={false} onClick={() => onChange(tagIds.add(tag.get("id")))} />)}
      </div>
    </DropdownToggle>
  </PreviewTooltip>;
});

const dropdownHeadingCss = theme => css`
  color: ${theme.palette.text.main};
  border-bottom: 1px solid ${theme.palette.background.paper};
  text-transform: uppercase;
  font-weight: bold;
  font-weight: 700;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`;

const tagItemCss = theme => css`
    background-color: ${theme.palette.background.paper} !important;
    margin: 0 0 0.5rem 0.5rem;
    :hover {
      background-color: ${theme.palette.action.focus} !important;
    };
`;

const availableTagItemCss = theme => css`
    background-color: ${theme.palette.background.card} !important;
    margin: 0;
    width: 100%;
    border-radius: 0;
    text-align: left;
    justify-content: flex-start;
    font-size: 13px;
    padding: 0.5rem;
    cursor: pointer;
    color: ${theme.palette.text.main};
    &:hover {
      background-color: ${theme.palette.primary.main} !important;
      color: ${theme.palette.text.inverted};
    };
`;

const TagItem = React.memo(({tag, showDelete, onClick}) => {
  const {theme} = React.useContext(CustomThemeContext);
  if(showDelete) {
    return <Chip css={tagItemCss(theme)} onClick={onClick} label={`${tag.get("categoryName")}: ${tag.get("name")}`} onDelete={showDelete ? onClick : null}/>
  } else {
    return <div css={availableTagItemCss(theme)} onClick={onClick}>
      <span style={{opacity: 0.7}}>{tag.get("categoryName")}:</span> {tag.get("name")}
    </div>
  }
});

export default DataConfigToolbar;
