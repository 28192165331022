import React from "react";
import createReactClass from "create-react-class";

import Tabs from "js/common/views/tabs";
import ActiveUserPage from "js/admin/users/app";
import OrphanUserPage from "js/admin/users/orphan-user-page";
import InactiveUserPage from "js/admin/users/inactive-user-page";
import BulkUpdatePage from "js/admin/users/bulk-update-page";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

import * as auditor from "js/common/auditer";

const Tabbed = createReactClass({

  getInitialState() {
    return {
      activeTabIndex: 0
    };
  },

  render() {
    const tabs = [
      {
        title: <TabTitle icon="user" text="Users" />,
        content: <ActiveUserPage />
      }, {
        title: <TabTitle icon="exclamation" text="Orphan Users" />,
        content: <OrphanUserPage />
      }, {
        title: <TabTitle icon="moon-o" text="Inactive Users" />,
        content: <InactiveUserPage />
      }, {
        title: <TabTitle icon="upload" text="Bulk Update" />,
        content: <BulkUpdatePage />
      }];
    return (
        <Tabs
            selectedIndex={this.state.activeTabIndex}
            onChangeTab={this.handleChangeTab}
            tabs={tabs}
            saveTabStateOnChange={false}
            containerStyle={{margin: 3}} />
    );
  },

  componentDidMount() {
    auditor.audit("user-admin:loaded");
  },

  handleChangeTab(index) {
    this.setState({activeTabIndex: index});
  }
});

const TabTitle = ({icon, text}) => <span><i className={`fa fa-${icon}`} /> {text}</span>;


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <Tabbed theme={theme} {...props} />;
};

export default Wrapper;
