import React from "react";
import createReactClass from "create-react-class";
import DataAggregationPicker from "js/charting/trend/data-aggregation-picker";

import {Column} from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import {reactComponentToMarionetteComponent} from "js/common/views/react-to-marionette";
import ChartingApp from "js/charting/app";
import {getDataAggregations} from "js/charting/trend/data-aggregator";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import Cube19 from "js/cube19.app";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import Checkbox from 'js/common/views/inputs/checkbox';
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const TrendConfig = createReactClass({

  render() {
    const theme = this.context.theme;
    const {currentOneViewConfig, config, onConfigChange} = this.props;
    const loggedInUser = Users.getCurrentUser();
    const currentGroupName = Groups.getGroup(currentOneViewConfig.groupId).get("name");
    const allKpiIds = config.trendType === "STANDARD" && config.kpiIds ||
        config.trendType === "ADVANCED" && config.dataGroups.flatMap(dg =>
            dg.trendConfigs.flatMap(tc => {
              if (tc.type === "KPI") {
                return [tc.kpiId];
              } else {
                const ratio = tc.ratio;
                return [ratio.firstKpiId, ratio.secondKpiId];
              }
            }));
    const allKpisAggregable = allKpiIds.every(kpiId => kpiRepo.get(kpiId).get("trendDataAggregable"));

    return (
        <div style={containerStyle} className="clearfix">
          <Column small={12} medium={4} style={columnStyle}>
            <Checkbox
                label="Show Data Cumulatively"
                iconStyle={{marginRight: 16, marginLeft: 11, height: 24, width: 24}}
                labelStyle={{lineHeight: "24px"}}
                checked={config.displayDataCumulatively}
                onCheck={(e, isChecked) => onConfigChange({displayDataCumulatively: isChecked})} /><br/>
            <Checkbox
                label="Exclude Weekends"
                iconStyle={{marginRight: 16, marginLeft: 11, height: 24, width: 24}}
                labelStyle={{lineHeight: "24px"}}
                checked={config.excludeWeekends}
                onCheck={(e, isChecked) => onConfigChange({excludeWeekends: isChecked})} /><br/>
            {currentOneViewConfig.qualifierType === "GROUP" &&
            <Checkbox
                label={`Show Average by User in ${currentGroupName}`}
                iconStyle={{marginRight: 16, marginLeft: 11, height: 24, width: 24}}
                labelStyle={{lineHeight: "24px"}}
                checked={config.averageByUser}
                onCheck={(e, isChecked) => onConfigChange({averageByUser: isChecked})} />}
          </Column>
          {allKpiIds.length > 0 &&
          <Column small={12} medium={4} style={columnStyle}>
            {allKpisAggregable ?
                <DataAggregationPicker
                    dataAggregations={getDataAggregations(config.dataAggregation)}
                    value={config.dataAggregation}
                    onChange={dataAggregation => onConfigChange({dataAggregation})} /> :
                <InfoMsg theme={theme} text="Data aggregation changes not available for one or more of the Metrics in this chart" />}
            {config.dataAggregation === "MONTHLY" &&
            <InfoMsg theme={theme} text="Monthly target lines may appear to fluctuate as they are calculated by the number of days in a month which could differ" />}
            {config.dataAggregation === "WEEKLY" &&
            <InfoMsg theme={theme} text="Weekly trend aggregations may start/end with a partial week's data" />}
          </Column>}
          {Users.canAccessApp(loggedInUser, "CHARTING") &&
          <Column small={12} medium={4} className="text-right" style={columnStyle}>
            <TextButton
                type="secondary"
                icon="external-link"
                label="Edit in Trends App"
                onClick={this.goToChartingApp} />
          </Column>
          }
        </div>
    );
  },

  goToChartingApp() {
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(
        <ChartingApp style={{color: "red"}} initialChartConfig={this.props.config} />
    ));
  }

});

const InfoMsg = ({theme, text}) => (
    <span style={{color: theme.palette.text.secondary, fontSize: "0.75rem"}}>
        <i className="fa fa-info-circle" style={{display: "table-cell", paddingRight: 8}} />
        <span style={{display: "table-cell", lineHeight: 1.25}}>{text}</span>
    </span>
);

const containerStyle = {
  width: "100%",
  borderBottom: "1px solid #464646",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem"
};

const columnStyle = {
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem"
};


TrendConfig.contextType = CustomThemeContext;;

export default TrendConfig;
