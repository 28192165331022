import React, {memo, useState} from "react";
import Immutable from "immutable";

import * as Ajax from "js/common/ajax";
import BuildInfoRow from "js/common/views/build-info/build-info-row";
import buildInfo from "js/build-info";
import {currentQueryString} from "js/common/utils/query-strings";
import {apiUrl} from "js/app-configuration";
import useMountEffect from "js/common/utils/use-mount-effect";

const loadServiceBuildInfo = () => Ajax
    .get({url: "build-info"})
    .then(response => Immutable.fromJS({...response, label: "service"}))
    .catch(error => {
      console.error("Failed to fetch build info:", error);
    });

const loadUIBuildInfo = () => {
  return Immutable.Map({
    "label": "ui",
    "branch": buildInfo.buildBranch,
    "id": buildInfo.buildId,
    "url": buildInfo.buildUrl,
    "timestamp": buildInfo.buildTimestamp
  });
};

const BuildInfo = memo(() => {
  const [builds, setBuilds] = useState(Immutable.List());
  const [expanded, setExpanded] = useState(true);

  const isProd = apiUrl === "https://app.cube19.io";
  const isDemoMode = currentQueryString.demoMode?.toLowerCase() === "true";
  const showBuildInfo = !builds.isEmpty() && !(isProd || isDemoMode);
  const buildInfoContainerStyle = {
    display: showBuildInfo ? "flex" : "none",
    zIndex: 9999,
    flexDirection: expanded ? "column" : "row",
    alignItems: "flex-end",
    position: "fixed",
    bottom: 0,
    right: 0,
    padding: "5px 36px 5px 10px"
  };

  const expandStyle = {
    position: "absolute",
    top: expanded ? "20px" : "7px",
    right: "10px"
  };

  useMountEffect(() => loadServiceBuildInfo()
      .then(serviceBuild => setBuilds(Immutable
          .List([serviceBuild, loadUIBuildInfo()])
          .filter(build => build?.get("id")))));

  return (
      <div style={buildInfoContainerStyle} className="build-info-container">
        {builds.map(build => <BuildInfoRow
              key={`info-row-${build.get("label")}`}
              label={build.get("label")}
              branch={build.get("branch")}
              timestamp={build.get("timestamp")}
              id={build.get("id")}
              url={build.get("url")} />)}
        <a onClick={() => setExpanded(isExpanded => !isExpanded)} style={expandStyle}>
          <i className={`fa fa-${expanded ? "compress" : "expand"}`} />
        </a>
      </div>
  );
});

export default BuildInfo;
