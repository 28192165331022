import Immutable from "immutable";

import * as Groups from "js/common/groups"
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";

export const getDefaultTrendSlide = () => {
    const cid = Math.random();
    return Immutable.fromJS({
        cid: cid,
        id: `slide-${cid}`,
        pageTitle: {
            title: "Trend",
            subTitle: "",
            timeframeId: ""
        },
        timing: 60000,
        layout: {
            id: "LeftBigRight4",
            left: [{
                visualizationId: "Trend",
                kpis: [ getDefaultKpiTrend(1) ],
                xLabel: "Days From Start",
                yLabel: ""
            }],
            right: [{
                visualizationId: "MiniCubeContainer",
                kpis: [ getDefaultKpiSummary() ]
            }]
        },
        showNewsticker: true
    });
};

export const getDefaultKpiTrend = (order, kpiId) => {
    const timeframe = timeframeRepo.getDefaultForClient();
    const trendableKpis = kpiRepo.getTrendableKpis();
    const kpi = kpiId ? kpiRepo.get(kpiId) : trendableKpis[0];

    return Immutable.fromJS({
        cid: Math.random(),
        kpiId: kpi.get("id"),
        timeframeId: timeframe.get("id"),
        key: timeframe.get("shortName") || timeframe.get("name"),
        groupId: Groups.getRootGroup().get("id"),
        matchAnyTagIds: [],
        matchAllTagIds: [],
        excludedTagIds: [],
        showTarget: false,
        targetKey: "Target",
        order
    });
};

export const getDefaultKpiSummary = () => {
    const firstKpi = getKpis().first();
    const timeframe = timeframeRepo.getDefaultForClient();
    const timeframeId = timeframe.get("id");
    return Immutable.fromJS({
        cid: Math.random(),
        title: firstKpi.get("name"),
        kpiId: firstKpi.get("id"),
        expectedData: "total",
        timeframeId,
        groupId: Groups.getRootGroup().get("id"),
        matchAnyTagIds: [],
        matchAllTagIds: [],
        excludedTagIds: []
    });
};


const getKpis = () => Immutable.fromJS(kpiRepo.getAll().toJSON()).filter(kpi => kpi.get("visible"));
