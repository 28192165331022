/** @jsxImportSource @emotion/react */

import React from "react";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import createReactClass from "create-react-class";
import Checkbox from "js/common/views/inputs/checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronUp} from "@fortawesome/pro-solid-svg-icons";
import {jsx, css} from "@emotion/react";

import TwoOptionToggle from "js/common/views/inputs/two-option-toggle";
import * as Colors from "js/common/cube19-colors";
import * as Auditor from "js/common/auditer";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const Options = createReactClass({

  displayName: "Options",
  mixins: [PureRenderMixin],
  propTypes: {
    showAllGroups: ReactPropTypes.bool,
    showDeletedGroups: ReactPropTypes.bool,
    showUserPictures: ReactPropTypes.bool,
    showUserNames: ReactPropTypes.bool,
    checkedAttributes: ImmutablePropTypes.set,
    onChangeGroupsToggle: ReactPropTypes.func,
    onChangeUsersToggle: ReactPropTypes.func,
    onCheckDeletedGroups: ReactPropTypes.func,
    onCheckShowUserPictures: ReactPropTypes.func,
    onCheckShowUserNames: ReactPropTypes.func,
    onChangeAttribute: ReactPropTypes.func
  },

  getInitialState() {
    return {
      showAllOptions: false
    }
  },

  render() {
    const {showAllOptions} = this.state;
    const {
      theme,
      showAllGroups,
      showDeletedGroups,
      showUserPictures,
      showUserNames,
      checkedAttributes,
      onChangeGroupsToggle,
      onCheckDeletedGroups,
      onCheckShowUserPictures,
      onCheckShowUserNames,
      onChangeAttribute
    } = this.props;

    return <div css={showContainer(theme)}>
      <div style={{display: "flex", flexDirection: "row"}}>
        <TwoOptionToggle
            leftLabel="All Groups"
            rightLabel="Attributes set"
            isLeft={showAllGroups}
            onColor={theme.palette.primary.main}
            offColor={theme.themeId === "light" ? theme.palette.primary.main : theme.palette.text.main}
            onChange={onChangeGroupsToggle} />
      </div>

      <div style={{display: "flex", marginTop: "10px"}}>
        <Checkbox
            id="TESTCAFE_checkbox_oneview_pages"
            label="OneView pages"
            style={{width: "auto"}}
            iconStyle={{height: "18px", fill: Colors.pageColor}}
            labelStyle={{fontSize: "0.7rem !important"}}
            checked={checkedAttributes.includes("page")}
            onCheck={(e, isChecked) => onChangeAttribute("page", isChecked)} />
        <Checkbox
            id="TESTCAFE_checkbox_currency"
            label="Currency"
            style={{width: "auto", marginLeft: "14px"}}
            iconStyle={{height: "18px", fill: Colors.currencyColor}}
            labelStyle={{fontSize: "0.7rem !important"}}
            checked={checkedAttributes.includes("currency")}
            onCheck={(e, isChecked) => onChangeAttribute("currency", isChecked)} />
        <Checkbox
            id="TESTCAFE_checkbox_locale"
            label="Locale"
            style={{width: "auto", marginLeft: "14px"}}
            iconStyle={{height: "18px", fill: Colors.localeColor}}
            labelStyle={{fontSize: "0.7rem !important"}}
            checked={checkedAttributes.includes("locale")}
            onCheck={(e, isChecked) => onChangeAttribute("locale", isChecked)} />
        <Checkbox
            id="TESTCAFE_checkbox_deal_music"
            label="Deal music"
            iconStyle={{height: "18px", fill: Colors.musicColor}}
            labelStyle={{fontSize: "0.7rem !important"}}
            style={{width: "auto", marginLeft: "14px"}}
            checked={checkedAttributes.includes("music")}
            onCheck={(e, isChecked) => onChangeAttribute("music", isChecked)} />
        <Checkbox
            id="TESTCAFE_checkbox_deleted_groups"
            label="Deleted groups"
            style={{width: "auto", marginLeft: "14px"}}
            iconStyle={{height: "18px"}}
            labelStyle={{fontSize: "0.7rem !important"}}
            checked={showDeletedGroups}
            onCheck={onCheckDeletedGroups} />
      </div>

      <div style={{display: "flex", flexDirection: "column"}}>
        <div style={{display: showAllOptions ? "flex" : "none", marginTop: "5px"}}>
          <Checkbox
              id="TESTCAFE_checkbox_user_pictures"
              label="Show User pictures"
              style={{width: "auto"}}
              iconStyle={{height: "18px"}}
              labelStyle={{fontSize: "0.7rem !important"}}
              checked={showUserPictures}
              disabled={showUserNames === false}
              onCheck={onCheckShowUserPictures} />
          <Checkbox
              id="TESTCAFE_checkbox_user_names"
              label="Show User names"
              style={{width: "auto", marginLeft: "14px"}}
              iconStyle={{height: "18px"}}
              labelStyle={{fontSize: "0.7rem !important"}}
              checked={showUserNames}
              disabled={showUserPictures === false}
              onCheck={onCheckShowUserNames} />
        </div>
        <a
            className={"TESTCAFE-more-options-button"}
            css={moreOptionsButton(theme)}
            onClick={this.toggleMoreOptions}>
          {showAllOptions ? "Fewer" : "More"} options&nbsp;
          <FontAwesomeIcon icon={showAllOptions ? faChevronUp : faChevronDown} />
        </a>
      </div>
    </div>;
  },

  toggleMoreOptions() {
    Auditor.audit("group-inheritance:more_options", {showAllOptions: this.state.showAllOptions});
    this.setState(state => ({showAllOptions: !state.showAllOptions}));
  }

});

const showContainer = theme => css`
      background: ${theme.palette.background.card};
      color: ${theme.palette.text.main};
      pointer-events: auto;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      font-size: 11px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      right: 1rem;
    `;

const moreOptionsButton = theme => css`
      background: ${theme.palette.background.card};
      color: ${theme.palette.text.main};
      font-size: 10px;
      position: absolute;
      bottom: -18px;
      padding: 5px 10px;
      left: calc(50% - 60px);
      width: 120px;
      text-align: center;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    `;

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <Options theme={theme} {...props} />;
};
