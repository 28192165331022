import React from "react";
import * as Immutable from "immutable";

import pure from "js/common/views/pure";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

export default pure(({
  groups = Immutable.List(),
  users = Immutable.List(),
  onGroupClick,
  onUserClick,
  columns = 1
}) => {
  const listStyle = {
    marginTop: "0rem",
    marginBottom: "1rem",
    columns
  };

  return (
      <div>
        {groups.size > 0 && <ul style={listStyle}>
          {groups.map(group => <GroupItem key={group.get("id")} group={group} onGroupClick={onGroupClick} />)}
        </ul>}
        {users.size > 0 && <ul style={listStyle}>
          {users.map(user => <UserItem key={user.get("id")} user={user} onUserClick={onUserClick} />)}
        </ul>}
      </div>
  );
});

const GroupItem = React.memo(({
  group,
  onGroupClick
}) => {
  const { theme } = React.useContext(CustomThemeContext);
  const clickable = !!onGroupClick;
  const onClick = clickable ? () => onGroupClick(group.get("id")) : () => {};
  const itemStyle = clickable ? clickableItemStyle(theme) : baseItemStyle(theme);
  const breadcrumbs = group.get("breadcrumbs");
  return (
      <li style={itemStyle} onClick={onClick}>
        <div>
          {clickable && <i className="fa fa-remove" style={{...removeIconStyle, display: "table-cell"}} />}
          <i className="fa fa-group" style={{...iconStyle, display: "table-cell"}} />
          <span style={{display: "table-cell"}}>
                {breadcrumbs
                    .map((crumb, i) => (
                        <span key={i}>
                          {(i > 0) && <i className="fa fa-chevron-right" style={dividerStyle} />}
                          {crumb}
                        </span>
                    ))}
            </span>
        </div>
      </li>
  );
});

const UserItem = React.memo(({
  user,
  onUserClick
}) => {
  const clickable = !!onUserClick;
  const { theme } = React.useContext(CustomThemeContext);
  const onClick = clickable ? () => onUserClick(user.get("id")) : () => {};
  const itemStyle = clickable ? clickableItemStyle(theme) : baseItemStyle(theme);
  const isObserver = user.get("hasLogin") && user.get("state") === "INVISIBLE";
  return (
      <li style={itemStyle} onClick={onClick}>
        <div>
          {clickable && <i className="fa fa-remove" style={removeIconStyle} />}
          <i className={`fa fa-user${isObserver ? "-secret" : ""}`} style={iconStyle} />
          {user.get("fullName")}
        </div>
      </li>
  );
});

const baseItemStyle = theme => ({
  marginTop: "0.5rem",
  marginBottom: "0.5rem",
  fontSize: "1rem",
  color: theme.palette.text.main,
  display: "table"
});

const clickableItemStyle = theme => ({
  ...baseItemStyle(theme),
  cursor: "pointer"
});

const iconStyle = {
  color: "#9e9e9e",
  paddingRight: 10
};

const removeIconStyle = {
  color: "#B71C1C",
  paddingRight: 15
};

const dividerStyle = {
  fontSize: "0.725rem",
  paddingLeft: 5,
  paddingRight: 5
};
