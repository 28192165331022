import React from "react";

import * as Users from "js/common/users";
import currentClient from "js/common/repo/backbone/current-client";

import Tabs from "js/common/views/tabs";
import OldTargetApp from "js/admin/targets/old-target-app";
import RecalculateGroupTargetTab from "js/admin/targets/recalculate-group-target-tab";
import BulkUpdateTargetsTab from "js/admin/targets/bulk-update-targets-tab";
import * as auditor from "js/common/auditer";
import useMountEffect from "js/common/utils/use-mount-effect";

export default React.memo(() => {
  const userCanCreateTargets = currentClient.hasPermission("SINGLE_TARGET_ADMIN");
  const userCanManageGroupTargets = currentClient.hasPermission("GROUP_TARGET_CALCULATION")
      && Users.getCurrentUser().get("dataVisibility") !== "SELF";
  let initialTabIndex = 0;
  if (userCanManageGroupTargets && !userCanCreateTargets) {
    initialTabIndex = 1;
  }
  const [tabIndex, setTabIndex] = React.useState(initialTabIndex);
  const tabs = [];

  useMountEffect(() => {
    auditor.audit("targets-admin:loaded");
  });

  if (userCanCreateTargets) {
    tabs.push({
      title: <TabTitle text="Create Targets" />,
      content: <OldTargetApp />
    });
  }

  if (userCanManageGroupTargets) {
    tabs.push({
      title: <TabTitle text="Calculate Group Targets From Users" />,
      content: <RecalculateGroupTargetTab />
    });
  }

  tabs.push({
    title: <TabTitle text="Bulk Update" />,
    content: <BulkUpdateTargetsTab />
  });


  return <Tabs
      selectedIndex={tabIndex}
      onChangeTab={setTabIndex}
      tabs={tabs}
      saveTabStateOnChange={false}
      contentStyle={{minHeight: "440px"}}
      containerStyle={{margin: 3}} />;
});

const TabTitle = ({icon, text}) => <span><i className={`fa fa-${icon}`} /> {text}</span>;
