import React from "react";
import * as ajax from "js/common/ajax";
import Immutable from "immutable";
import FileSaver from "browser-filesaver";
import {Button} from "@mui/material";
import AdminHeader from "js/admin/common/admin-header";
import Step from "js/admin/common/step-number";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import DownloadReferenceButton from "js/admin/common/download-reference-button";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import DownloadIcon from "@mui/icons-material/Download";
import {CsvUpload} from "js/common/csv-upload";
import UploadedFilesTable from "js/admin/common/uploaded-files-table";
import eventBus from "js/cube19.event-bus";

export const GroupHierarchyBulkUpdate = () => {

  const {theme} = React.useContext(CustomThemeContext);
  const [selectedJobId, setSelectedJobId] = React.useState();
  const [isDownloading, setIsDownloading] = React.useState(false);

  const downloadGroups = React.useCallback(prefix => {
    setIsDownloading(true);
    ajax
        .get({url: "group-upload/groups.csv", returnFileName: true})
        .then(response => {
          downloadCsv(Immutable.fromJS(response.body), prefix + response.fileName);
          setIsDownloading(false);
        });
  }, []);

  const downloadReferenceOptions = Immutable.fromJS([
    {
      label: "Download Currency Codes",
      onClick: downloadCurrencyCodes
    },
    {
      label: "Download Deal Music",
      onClick: downloadDealMusic
    },
    {
      label: "Download Oneview Pages",
      onClick: downloadOneviewPages
    }
  ]);

  return (
      <div>
        <AdminHeader>
          <Step step="1" />
          Backup & Download
        </AdminHeader>
        <div style={{padding: "0 1rem 1rem 1rem"}}>
          <Hint>
            <Icon icon="info" style={{color: theme.palette.hints.text}} />
            We recommend that you use the backup all groups button to backup your current group hierarchy before you
            begin.
          </Hint>
          <p style={{fontSize: "0.85rem"}}>Download reference files to match the corresponding ID values needed for a
            bulk update.</p>
          <div style={{display: "flex"}}>
            <Button
                style={{marginRight: 10}}
                variant="outlined"
                id="basic-button"
                disabled={isDownloading}
                onClick={() => downloadGroups("backup-")}
            >
              {isDownloading ? "Downloading" : "Backup All Groups"}
            </Button>
            <DownloadReferenceButton options={downloadReferenceOptions} />
          </div>
          <p style={{fontSize: "0.85rem", marginTop: 10}}>Modify the downloaded groups file by adding,
            updating or deleting groups before reuploading the file to perform the updates. New groups can be added as a
            new row in the file. Removing a row will not delete that group, instead it should be marked for deletion in
            the Is Deleted? column.</p>
          <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              label="Download Groups"
              id="basic-button"
              disabled={isDownloading}
              onClick={() => downloadGroups("")}
          >{isDownloading ? "Downloading" : "Download Groups"}</Button>
        </div>
        <AdminHeader>
          <Step step="2" />
          Upload
        </AdminHeader>
        <Hint style={{margin: "0 1rem 1rem 1rem"}}>
          <Icon icon="info" style={{color: theme.palette.hints.text}} />
          When adding a new group leave the ID blank. If you need to reference that group elsewhere in the csv then
          assign it a temporary ID in the format "new-xxx" e.g. new-1 or new-london.
        </Hint>
        <CsvUpload
            theme={theme}
            requiresLocale={false}
            csvLabel="Groups"
            onUploadSuccess={(request) => {
              setSelectedJobId(request.body.id);
              eventBus.trigger("hierarchy:changed");
            }}
            uploadUrl="/ApplicationLayer/group-upload/upload" />
        <AdminHeader>
          <Step step="3" />
          Status of uploaded files
        </AdminHeader>
        <UploadedFilesTable type="groups" url="group-upload/jobs" selectedJobId={selectedJobId} />
        <AdminHeader style={{marginTop: 10}}>
          <i style={{fontSize: "1.2rem", padding: "4px 4px 4px"}} className="fa fa-users" />
          Status of updates to Group Hierarchy
        </AdminHeader>
        <Hint style={{margin: "0 1rem 1rem 1rem"}}>
          <Icon icon="info" style={{color: theme.palette.hints.text}} />
          Running a bulk upload triggers an additional job to recalculate group assignments. You can track the status of
          these jobs below.
        </Hint>
        <UploadedFilesTable type="status-of-updates" url="group-upload/status-of-updates" selectedJobId={null} />
      </div>
  );
};

const downloadCurrencyCodes = (setIsLoading) => ajax
    .get({url: "group-upload/currency-codes.csv", returnFileName: true})
    .then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      setIsLoading(false);
    });

const downloadDealMusic = (setIsLoading) => ajax
    .get({url: "group-upload/deal-music.csv", returnFileName: true})
    .then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      setIsLoading(false);
    });

const downloadOneviewPages = (setIsLoading) => ajax
    .get({url: "group-upload/oneview-pages.csv", returnFileName: true})
    .then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      setIsLoading(false);
    });

const downloadCsv = (csv, header) => {
  const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
  FileSaver.saveAs(blob, header);
};