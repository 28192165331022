/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import Fullscreen from "react-full-screen";
import {css} from "@emotion/react";

import PanZoomCSS from "js/common/pan-zoom-css";
import ErrorMsg from "js/common/views/error";
import EditGroupPanel from "js/admin/groups/edit-group-panel";
import EditUserPanel from "js/admin/users/edit-user-panel";
import * as Ajax from "js/common/ajax";
import * as Auditer from "js/common/auditer";
import * as Auth from "js/common/auth";
import * as Popups from "js/common/popups";
import eventBus from "js/cube19.event-bus";
import * as Pages from "js/common/pages";
import * as Users from "js/common/users";

import GroupNode from "js/admin/groups/group-inheritance/group-node/group-node";
import Dialog from "js/common/views/tabs-dialog";
import Options from "js/admin/groups/group-inheritance/options";
import SearchBar from "js/admin/groups/group-inheritance/search-bar";
import GroupUsersDialog from "js/admin/groups/group-inheritance/group-users-dialog";

import * as Groups from "js/common/groups";
import * as Locales from "js/common/locales";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/pro-solid-svg-icons";
import {apiUrl} from "js/app-configuration";
import {maybeBackboneToImmutable} from "js/common/utils/backbone-migration";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {indexBy} from "js/common/utils/collections";

const path = window.path;

const ThemeReceivingGroupInheritance = createReactClass({

  displayName: "GroupInheritance",

  getInitialState() {
    this.handleSearch = debounce(function(searchTerm) {
      if (searchTerm) {
        this.setState({searchTerm, shouldSearch: true});
        Auditer.audit("group-inheritance:search", {searchTerm});
      } else {
        this.setState({
          searchTerm,
          searchResultIds: [],
          currentSearchResultId: null,
          shouldSearch: false,
          nodesHiddenByFiltersCount: -1
        });
      }
    }, 300);

    return {
      pages: null,
      groups: null,
      selectedGroupId: null,
      highlightSection: null,
      isUpdating: false,
      isLoading: true,
      error: null,
      idToLocale: Immutable.List(),
      drawerOpen: false,
      showUserDrawer: false,
      showGroupDrawer: false,
      usersDialogOpen: false,
      isFullScreen: false,
      showDeletedGroups: false,
      showAttributes: Immutable.Set(["page", "currency", "music", "locale"]),
      showUserNames: true,
      showUserPictures: true,
      searchTerm: null,
      userById: Immutable.Map(),
      userIdsByGroupId: Immutable.Map(),
      showAll: false,
      currentSearchResultId: null,
      searchResultIds: [],
      shouldSearch: false,
      nodesHiddenByFiltersCount: -1,
      offsetHeight: 0,
      shouldRecenter: false,
      userIdsToLogInAs: null
    };
  },

  componentDidMount() {
    Auditer.audit("group-inheritance:loaded");
    document.addEventListener("keydown", this.searchFunc, false);

    Ajax
        .get({url: path("group")})
        .then(groups => {
          this.setState({
            groups: Immutable.fromJS(groups),
            groupsCount: groups.length
          });
        });

    Promise.all([Users.load(), Pages.getAll(), Locales.loadAll(), Users.fetchFallbackUsers()])
        .then(([users, pages, locales, fallbackUsers]) => {
          const userById = users.groupBy(u => u.get("id")).map(us => us.first());
          const userIdsByGroupId = users
              .groupBy(u => u.get("groupId"))
              .map(users => users.map(user => user.get("id")));
          const idToFallbackUser = indexBy(user => user.get("id"), fallbackUsers);
          this.setState({
            userById,
            idToNameOnlyUser: idToFallbackUser,
            userIdsByGroupId,
            idToLocale: locales.groupBy(l => l.get("id")).map(ls => ls.first()),
            pages: pages,
            isLoading: false
          });
        });
  },

  componentWillUnmount() {
    Auditer.audit("group-inheritance:exited");
    document.removeEventListener("keydown", this.searchFunc, false);
  },

  componentDidUpdate(_prevProps, prevState) {
    const {userById, selectedUserId, userIdsToLogInAs, appAssignments, permissions} = this.state;
    const currentUserId = Users.getCurrentUser().get("id")
    const sourceUserId = Users.getSourceUser().get("id");
    const currentUserFromState = userById.get(currentUserId);
    const sourceUserFromState = userById.get(sourceUserId);

    const selectedUserIdChanged = prevState.selectedUserId !== selectedUserId;
    const currentUserWasUpdated = prevState.userById.get(currentUserId, currentUserFromState) !== currentUserFromState
    const sourceUserWasUpdated = prevState.userById.get(sourceUserId, sourceUserFromState) !== sourceUserFromState;
    const userIdsToLogInAsWereUpdated = prevState.userIdsToLogInAs !== userIdsToLogInAs;
    const sourceUserTargetIdsWereUpdated = !selectedUserIdChanged && userIdsToLogInAsWereUpdated;
    const appAssignmentsWereUpdated = prevState.appAssignments !== appAssignments;
    const currentUserAppAssignmentsWereUpdated = !selectedUserIdChanged && appAssignmentsWereUpdated
    const permissionsWereUpdated = prevState.permissions !== permissions;
    const currentUserPermissionsWereUpdated = !selectedUserIdChanged && permissionsWereUpdated

    const triggerCurrentUserUpdate = currentUserWasUpdated
        || currentUserAppAssignmentsWereUpdated
        || currentUserPermissionsWereUpdated;
    const triggerSourceUserUpdate = sourceUserWasUpdated || sourceUserTargetIdsWereUpdated;
    const currentUserIsSourceUser = currentUserId === sourceUserId;

    if (triggerCurrentUserUpdate || (currentUserIsSourceUser && triggerSourceUserUpdate)) {
      eventBus.trigger("current-user:updated")
    }

    if (triggerSourceUserUpdate || (currentUserIsSourceUser && triggerCurrentUserUpdate)) {
      eventBus.trigger("source-user:updated")
    }
  },

  searchFunc(e) {
    if (this.state.searchTerm && this.state.searchResultIds.length > 1) {
      if (e.keyCode === 70 && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.cycleNextResult();
      }
    }
  },

  render() {
    const {
      x,
      y,
      userIdsByGroupId,
      selectedGroupId,
      isLoading,
      isUpdating,
      error,
      pages,
      groups,
      drawerOpen,
      showDeletedGroups,
      showAttributes,
      selectedUserId,
      showGroupDrawer,
      showUserDrawer,
      nodesHiddenByFiltersCount,
      searchResultIds,
      searchTerm,
      showUserNames,
      showUserPictures,
      idToLocale,
      isFullScreen,
      currentSearchResultId,
      highlightSection,
      showAll,
      shouldRecenter,
      usersDialogOpen
    } = this.state;

    const group = maybeBackboneToImmutable(Groups.getGroup(selectedGroupId));

    const treeWrapper = css`
      background: ${this.props.theme.themeId === "light" ? "#e3e3e3" : this.props.theme.palette.background.paper} };
      position: relative;
      margin: 0;
      padding: 0;
      color: white;
      overflow: hidden;
      height: 100%;
    `;

    const filtersPanelContainer = css`
      pointer-events: none;
      padding: 1rem;
      position: relative;
      z-index: 99;
      display: flex;
      justify-content: space-between;

      label {
        font-family: ${this.props.theme.typography.fontFamilyBold} !important;
        font-size: 10px;
        margin-left: -12px;
        line-height: 18px !important;
        white-space: nowrap;
        margin-bottom: 0;
      }
    `;

    const panContainer = css`
      min-height: 100vh;
    `;

    const loadingStyle = css`
      display: flex;
      width: 100%;
      height: 400px;
      justify-content: center;
      align-items: center;
      text-align: center;
    `;

    const yOffset = isFullScreen ? this.treeWrapper.clientHeight / 2 :
        document.getElementsByClassName("tab-content-area")[0].clientHeight / 2;

    if (isLoading || showAttributes === undefined) {
      return <div css={loadingStyle}><i className="fa fa-spinner fa-pulse fa-3x" style={{color: "#555"}} /></div>;
    }

    if (!groups) {
      return <div />;
    }

    const rootNode = this.buildNodes();

    return (
        <Fullscreen
            enabled={isFullScreen}
            onChange={isFullScreen => this.setState(
                {isFullScreen, shouldRecenter: true},
                () => this.setState({shouldRecenter: false}))}>
          <div css={treeWrapper} ref={(ref) => (this.treeWrapper = ref)}>
            <div css={filtersPanelContainer}>
              <SearchBar
                  theme={this.props.theme}
                  results={searchResultIds}
                  currentResult={currentSearchResultId}
                  onChange={this.handleSearch.bind(this)}
                  onClickPrevious={this.cyclePrevResult}
                  onClickNext={this.cycleNextResult}
                  onClickClear={() => this.setState({
                    searchTerm: null,
                    searchResultIds: [],
                    currentSearchResultId: null,
                    nodesHiddenByFiltersCount: -1
                  })} />

              <Options
                  showAllGroups={showAll}
                  checkedAttributes={showAttributes}
                  showDeletedGroups={showDeletedGroups}
                  showUserPictures={showUserPictures}
                  showUserNames={showUserNames}
                  onChangeAttribute={this.changeAttributeState}
                  onChangeGroupsToggle={showAll => {
                    Auditer.audit("group-inheritance:toggle", {showAllGroups: showAll});
                    if (showAll !== this.state.showAll) {
                      this.setState(
                          {showAll},
                          this.resetPan);
                    }
                  }}
                  onCheckDeletedGroups={(e, isChecked) => {
                    Auditer.audit("group-inheritance:filter", {showDeletedGroups: isChecked});
                    this.setState(
                        {showDeletedGroups: isChecked},
                        this.resetPan);
                  }}
                  onCheckShowUserPictures={(e, isChecked) => {
                    this.changeMoreOptionsOption({showUserPictures: isChecked});
                  }}
                  onCheckShowUserNames={(e, isChecked) => {
                    this.changeMoreOptionsOption({showUserNames: isChecked});
                  }}
                  onClickMoreOptions={() => {
                    Auditer.audit("group-inheritance:more-options", {showAllOptions: this.state.showAllOptions});
                    this.setState(state => ({showAllOptions: !state.showAllOptions}));
                  }} />
            </div>

            {nodesHiddenByFiltersCount > -1 && searchResultIds.length === 0 ?
                this.buildNodes() && this.renderHiddenNodesNotice() :
                pages &&
                <PanZoomCSS
                    theme={this.props.theme}
                    nodeIdentifier="group"
                    containerIdentifier="tree-container"
                    shouldRecenter={shouldRecenter}
                    shouldCenterOnLoad
                    centerOffsetY={yOffset}
                    centerOffsetX={(this.treeWrapper && this.treeWrapper.clientWidth / 2)}
                    minScale={0.4}
                    maxScale={2.8}
                    rootId={groups && groups.find(g => g.get("parentId") === null).get("id")}
                    centerNode={currentSearchResultId}
                    showControls
                    handleFullScreen={this.toggleFull}
                    isFullScreen={isFullScreen}
                    translation={x && y ? {x, y} : undefined}>
                  <div css={panContainer}>
                    <GroupNode
                        theme={this.props.theme}
                        group={rootNode}
                        key={`group-${rootNode.get("id")}`}
                        pages={pages}
                        searchTerm={searchTerm}
                        currentSearchResultId={currentSearchResultId}
                        showAttributes={showAttributes}
                        showAll={showAll}
                        showUserNames={showUserNames}
                        showUserPics={showUserPictures}
                        idToLocale={idToLocale}
                        selectedGroupId={selectedGroupId}
                        handleDisplayAllUsers={this.handleDisplayAllUsers}
                        handleClick={this.handleGroupClick}
                        handleUserClick={this.handleUserClick} />
                  </div>
                </PanZoomCSS>}
          </div>
          {(drawerOpen || usersDialogOpen) &&
              <Dialog
                  flex={1}
                  width={680}
                  onRequestClose={this.closeDrawer}
                  closeOnClickOutside={true}
                  content={
                    <div style={{margin: "1rem", width: "100%"}}>
                      {error && <ErrorMsg text={error} />}
                      {usersDialogOpen &&
                          <GroupUsersDialog
                              theme={this.props.theme}
                              group={group}
                              users={this.usersForGroup(selectedGroupId)}
                              handleUserClick={this.handleUserClick} />
                      }
                      {(selectedGroupId && showGroupDrawer && !usersDialogOpen) &&
                          <EditGroupPanel
                              group={group}
                              pages={pages}
                              activeUsersInGroupCount={userIdsByGroupId.get(group.get("id"))
                                  && userIdsByGroupId.get(group.get("id")).count()}
                              idToLocale={this.state.idToLocale}
                              onChange={this.updateGroup}
                              onDeleteGroup={this.deleteGroup}
                              onAddSubGroupClick={this.addSubGroup}
                              isUpdating={isUpdating}
                              highlightSection={highlightSection}
                              isSmallScreen={this.props.containerWidth < 800} />}
                      {(selectedUserId && showUserDrawer && !usersDialogOpen) && [
                        <a
                            className={"TESTCAFE-back-to-all-users"}
                            style={{
                              position: "absolute",
                              paddingBottom: 5,
                              top: 40,
                              fontSize: 14,
                              height: 28
                            }}
                            onClick={() => this.handleDisplayAllUsers(selectedGroupId)}>
                          <i className="fa fa-arrow-left" style={{padding: "0 5px 0 10px"}} />
                          All users</a>,
                        this.renderEditUserPanel()]}
                    </div>
                  } />}
        </Fullscreen>
    );
  },

  changeMoreOptionsOption(keyValObj) {
    Auditer.audit("group-inheritance:more-options", keyValObj);
    this.setState(keyValObj);
  },

  changeAttributeState(attribute, isActive) {
    Auditer.audit("group-inheritance:filter", {[attributeToAuditKey[attribute]]: isActive});
    this.setState(
        state => {
          const oldAttributeSet = state.showAttributes;
          const newAttributeSet = isActive ? oldAttributeSet.add(attribute) : oldAttributeSet.delete(attribute);
          return {showAttributes: newAttributeSet};
        },
        () => this.resetPan(),
        () => this.handleSearch(this.state.searchTerm));
  },

  cycleNextResult() {
    const {currentSearchResultId, searchResultIds} = this.state;
    const currentIndex = searchResultIds.indexOf(currentSearchResultId);
    if (currentIndex < searchResultIds.length - 1) {
      this.centerNode(searchResultIds[currentIndex + 1]);
    } else {
      this.centerNode(searchResultIds[0]);
    }
  },

  cyclePrevResult() {
    const {currentSearchResultId, searchResultIds} = this.state;
    const currentIndex = searchResultIds.indexOf(currentSearchResultId);
    if (currentIndex !== 0) {
      this.centerNode(searchResultIds[currentIndex - 1]);
    } else {
      this.centerNode(searchResultIds[searchResultIds.length - 1]);
    }
  },

  renderEditUserPanel() {
    if (this.state.isLoadingUser) {
      return (
          <span style={{color: "#999"}}>
            <span style={{paddingRight: 8}}>Loading User</span>
            <i className="fa fa-spinner fa-pulse fa-1x" />
          </span>
      );
    } else {
      const {
        selectedUserId,
        permissions,
        appAssignments,
        pages,
        isUpdating,
        userIdsToLogInAs,
        userById,
        idToNameOnlyUser
      } = this.state;
      return (
          <EditUserPanel
              key={selectedUserId}
              user={userById.get(selectedUserId)}
              permissions={permissions}
              appAssignments={appAssignments}
              oneViewPages={pages}
              idToLocale={this.state.idToLocale}
              onChange={this.handleUserChange}
              onPermissionChange={this.handlePermissionChange}
              onAppPermissionChange={this.handleAppAssignmentChange}
              onUsersToLoginAsChange={this.handleUsersToLogInAsChange}
              userIdsToLogInAs={userIdsToLogInAs}
              userById={userById}
              idToNameOnlyUser={idToNameOnlyUser}
              onUploadClick={this.handleUploadClick}
              onResetUserPassword={this.handleResetUserPassword}
              onSetUserPassword={this.showSetPasswordDialog}
              onDeactivateClick={this.showDeactivateDialog}
              onAllUsersClick={this.handleDisplayAllUsers}
              isLoading={isUpdating} />
      );
    }
  },

  showSetPasswordDialog() {
    this.setState({showSetPasswordDialog: true});
  },

  showDeactivateDialog() {
    this.setState({showDeactivateDialog: true});
  },

  getUser(userId) {
    return this.state.userById.get(userId);
  },

  getNewUsers() {
    return this.state.userById
        .valueSeq()
        .filter(Users.isNew);
  },

  centerNode(nodeId) {
    this.setState({currentSearchResultId: nodeId});
  },

  handleDisplayAllUsers(groupId) {
    this.setState({usersDialogOpen: true, selectedGroupId: groupId});
  },

  handleUserClick(selectedUserId) {
    const user = this.getUser(selectedUserId);
    Auditer.audit("group-inheritance:user-clicked", {selectedUserId});
    this.setState({
      selectedGroupId: user.get("groupId"),
      selectedUserId,
      isLoadingUser: true,
      drawerOpen: true,
      error: null,
      showUserDrawer: true,
      showGroupDrawer: false,
      usersDialogOpen: false
    });

    Promise
        .all([
          loadAppsForUser(selectedUserId),
          loadPermissionsForUser(selectedUserId),
          loadUsersToLoginAs(selectedUserId)
        ])
        .then(([appAssignments, permissions, userIdsToLogInAs]) => {
          this.setState({
            permissions,
            appAssignments,
            userIdsToLogInAs,
            isLoadingUser: false
          });
        });
  },

  handleUserChange(changes, localChangeOnly) {
    const immutableChanges = Immutable.fromJS(changes);

    const {selectedUserId, userById} = this.state;
    const user = this.getUser(selectedUserId);
    const mergedUser = user.merge(immutableChanges);
    const changedAttribute = immutableChanges.keySeq().get(0);

    if (localChangeOnly) {
      this.setState({userById: userById.set(selectedUserId, mergedUser)});
    } else {
      this.setState({isUpdating: true});
      Users
          .update(mergedUser)
          .then(updatedUser => {
            const originalValue = user.get(changedAttribute);
            const newValue = updatedUser.get(changedAttribute);
            if (originalValue !== newValue) {
              if (selectedUserId === Users.getCurrentUser().get("id")) {
                if (changedAttribute === "dataVisibility") {
                  eventBus.trigger("logged-in-user:data-visibility-changed");
                }
              }
            }
            this.setState({
              isUpdating: false,
              userById: userById.set(selectedUserId, updatedUser)
            }, () => {
              Popups.success("User successfully updated.");
            });
          }, e => {
            if (e.responseJSON && e.responseJSON.type) {
              Popups.error(e.responseJSON.message + ". Your changes have not been saved");
            } else {
              Popups.contactSupport();
            }
            this.setState({isUpdating: false});
          });
    }
  },

  handlePermissionChange(newPermissions) {
    this.setState({isUpdating: true});

    updatePermissionsForUser(this.state.selectedUserId, newPermissions).then(serverUpdatedPermissions => {
      this.setState({
        isUpdating: false,
        permissions: serverUpdatedPermissions
      });
    }, () => {
      Popups.contactSupport();
      this.setState({isUpdating: false});
    });
  },

  handleAppAssignmentChange(newAppAssignments) {
    this.setState({isUpdating: true});

    updateAppsForUser(this.state.selectedUserId, newAppAssignments).then(serverUpdatedAppAssignments => {
      this.setState({
        isUpdating: false,
        appAssignments: serverUpdatedAppAssignments
      });
    }, () => {
      Popups.contactSupport();
      this.setState({isUpdating: false});
    });
  },

  handleUsersToLogInAsChange(newUserIds) {
    this.setState({isUpdating: true});

    updateUsersToLoginAs(this.state.selectedUserId, newUserIds).then(serverUpdatedUserIdsToLogInAs => {
      this.setState({
        isUpdating: false,
        userIdsToLogInAs: serverUpdatedUserIdsToLogInAs
      });

    }, () => {
      Popups.contactSupport();
      this.setState({isUpdating: false});
    });
  },

  handleActivateUser(user, state) {
    Users.setupAs(user.get("id"), state).then(updatedUser => {
      const stateLabel = state === "OBSERVER" ? state : `${state} USER`;
      const message = "New User <strong>" + updatedUser.get("fullName") + "</strong> activated as " + stateLabel;
      Popups.success(message);
      this.setState({
        userById: this.state.userById.set(updatedUser.get("id"), updatedUser)
      });
    }, () => {
      Popups.contactSupport();
    });

  },

  handleUploadClick(user, file) {
    this.setState({isUpdating: true});
    const req = window.superagent
        .post(apiUrl + "/ApplicationLayer/users/" + user.get("id") + "/upload-profile-photo")
        .withCredentials();
    req.attach("file", file);
    req.end((error, response) => {
      if (error) {
        Popups.contactSupport();
        this.setState({isUpdating: false});
      } else {
        Popups.success("Your file has been uploaded");
        this.handleUserChange({photoURL: response.body.photoURL});
      }
    });
  },

  handleResetUserPassword(user) {
    if (!user.get("hasLogin")) {
      Popups.alert(user.get("username") +
          " has \"Can Login\" selected as 'no', <br/> switch it to 'yes' before requesting a password reset.", {
        title: "Update User Settings"
      });
      return;
    }

    Auth
        .requestPasswordReset(user.get("username"))
        .then(() => {
          Popups.success("An email has been sent to " + user.get("email"), "bottom-left");
        }, e => {
          if (e?.responseJSON?.type === "SSO_LOGIN_REQUIRED") {
            Popups.error(e.responseJSON.message);
          } else {
            Popups.contactSupport();
          }
        });

  },

  handleSetUserPassword(options) {
    this.setState({
      isUpdating: true,
      showSetPasswordDialog: false
    });
    setUserPassword(this.state.selectedUserId, options)
        .then(() => {
          Popups.success("Password updated", "bottom-left");
        }, (e) => {
          if (e.responseJSON && e.responseJSON.type === "PERMISSION_DENIED") {
            Popups.error(e.responseJSON.message + ". Password not set.");
          } else if (e?.responseJSON?.type === "SSO_LOGIN_REQUIRED") {
            Popups.error(e.responseJSON.message);
          } else {
            Popups.contactSupport();
          }
        })
        .finally(() => {
          this.setState({
            isUpdating: false
          });
        });
  },

  handleDeactivateUser(options) {
    this.setState({
      isUpdating: true,
      showDeactivateDialog: false
    });

    const {selectedUserId, userById} = this.state;
    const user = this.getUser(selectedUserId);
    deactivateUser(user, options)
        .then(result => {
          Popups.success(`User ${user.get("fullName")} deactivated`, "bottom-left");
          this.setState({
            isUpdating: false,
            userById: userById.set(selectedUserId, Immutable.fromJS(result)),
            selectedUserId: null
          });
        }, () => {
          Popups.contactSupport();
        });
  },

  closeDrawer() {
    this.setState({drawerOpen: false, usersDialogOpen: false});
  },

  toggleFull() {
    Auditer.audit("group-inheritance:fullscreen", {value: !this.state.isFullScreen});
    this.setState({
      isFullScreen: !this.state.isFullScreen
    });
  },

  resetPan() {
    this.setState({shouldRecenter: true}, () => this.setState({shouldRecenter: false}));
  },

  renderHiddenNodesNotice() {
    const hiddenNodesNotice = css`
      color: ${this.props.theme.palette.text.main};
      background: ${this.props.theme.palette.background.paper};
      padding: 1rem;
      position: relative;
      z-index: 2;
      margin: 100px auto;
      width: 600px;
      border-radius: 8px;
      text-align: center;

      h2 {
        margin: 1rem 0;
      }
    `;

    return (
        <div css={hiddenNodesNotice} id="TESTCAFE_no_results">
          <FontAwesomeIcon
              icon={faExclamationCircle}
              key={`icon-${faExclamationCircle}`}
              size="3x"
              color={this.props.theme.palette.primary.main} />
          {this.state.nodesHiddenByFiltersCount > 0 ?
              <div>
                <h2>No results visible</h2>
                <p>There are <strong>{this.state.nodesHiddenByFiltersCount} hidden Groups</strong>. Groups that do not
                  match
                  your specified filters do not appear in search results.</p>
              </div> :
              <div>
                <h2>No results found</h2>
                <p>No Groups or Users match your search criteria. Try adjusting your search and filter options.</p>
              </div>}
        </div>
    );
  },

  handleGroupClick(groupId, highlightSection) {
    Auditer.audit("group-inheritance:group-clicked", {groupId});
    this.setState({
      isUpdating: true,
      highlightSection
    });

    this.setState({
      isUpdating: false,
      selectedGroupId: groupId,
      selectedGroupUserLimit: 10,
      drawerOpen: true,
      error: null,
      showUserDrawer: false,
      showGroupDrawer: true
    });
  },

  closeEditGroupPanel() {
    this.setState({
      selectedGroupId: null,
      activeUsersInGroupCount: null,
      error: null
    });
  },

  updateGroup(change) {
    const group = Groups.getGroup(this.state.selectedGroupId);

    if (!change.pageId && group.get("inheritedPageGroupId") !== group.get("id")) {
      change.pageId = null;
    }
    if (!change.dealMusicId && group.get("inheritedMusicGroupId") !== group.get("id")) {
      change.dealMusicId = null;
    }
    if (!change.weekStartsOn && group.get("inheritedWeekdayGroupId") !== group.get("id")) {
      change.weekStartsOn = null;
    }
    if (!change.currencyCode && group.get("inheritedCurrencyGroupId") !== group.get("id")) {
      change.currencyCode = null;
    }
    if (!change.localeId && group.get("inheritedLocaleGroupId") !== group.get("id")) {
      change.localeId = null;
    }

    this.setState({
      isUpdating: true
    }, () => {
      const newGroupSettings = Immutable.fromJS(change);
      const originalGroup = maybeBackboneToImmutable(group);
      const changedAttribute = newGroupSettings.keySeq().toArray()[0];
      group.save(change, {
        wait: true,
        success: model => {
          if (newGroupSettings.has("name") || newGroupSettings.has("deleted")) {
            eventBus.trigger("hierarchy:group-updated", model);
          }
          const newGroup = maybeBackboneToImmutable(model);
          if (!Immutable.is(originalGroup, newGroup)) {
            this.auditGroupChange(changedAttribute, originalGroup, newGroup);
          }
          this.setState({
            isUpdating: false,
            error: null
          });
          Ajax
              .get({url: path("group")})
              .then(groups => {
                this.setState({groups: Immutable.fromJS(groups)});
                Popups.success("Group successfully updated.");
              });
        },
        error: (model, error) => {
          let message;
          if (error.status >= 400 && error.status < 500) {
            message = error.responseJSON.message;
          } else {
            message = "Unable to update group";
          }
          this.setState({
            isUpdating: false,
            error: message
          });
        }
      });
    });
  },

  auditGroupChange(attributeChanged, originalValue, newValue) {
    const payload = {
      groupId: this.state.selectedGroupId,
      change: {
        attributeChanged,
        originalValue,
        newValue
      }
    };
    Auditer.audit("group-settings:changed", payload);
  },

  deleteGroup(deletingGroupId, newGroupId, groupEndDate) {
    this.setState({isUpdating: true});
    Groups.deleteGroup(deletingGroupId, newGroupId, groupEndDate)
        .then(() => {
          Popups.success("Group successfully deleted.");
        }, error => {
          if (error.status >= 400 && error.status < 500) {
            const response = error.responseJSON;
            Popups.error(response.message);
          } else {
            Popups.contactSupport();
          }
        })
        .finally(() => {
          this.setState({isUpdating: false});
          Ajax
              .get({url: path("group")})
              .then(groups => {
                this.setState({groups: Immutable.fromJS(groups), drawerOpen: false});
              });
        });
  },

  addSubGroup(parentId) {
    this.setState({
      isUpdating: true
    }, () => {
      Groups.createGroup({
        name: "New Group",
        parentId
      }, {
        wait: true,
        success: (model) => {
          eventBus.trigger("hierarchy:group-added", model);
          const newGroupId = model.get("id");
          Auditer.audit("org_hierarchy:group_added", {
            groupId: newGroupId
          });
          this.setState({
            isUpdating: false,
            selectedGroupId: newGroupId
          });
          Ajax
              .get({url: path("group")})
              .then(groups => {
                this.setState({groups: Immutable.fromJS(groups)});
                Popups.success("New Group has been created");
              });
        },
        error: () => {
          this.setState({
            isUpdating: false,
            error: "Unable to add sub-group"
          });
        }
      });
    });
  },

  usersForGroup(groupId) {
    const {userIdsByGroupId, userById} = this.state;
    const userIds = userIdsByGroupId.get(groupId, Immutable.List());
    const allUsers = userIds.map(uid => userById.get(uid));
    return filterUsers(allUsers);
  },

  buildNodes() {
    const {groups, showDeletedGroups, showAttributes, showAll} = this.state;

    const parentIdToGroups = groups
        .sort((a, b) => a.get("name").localeCompare(b.get("name")))
        .groupBy(g => g.get("parentId"));

    const root = groups.find(g => g.get("parentId") === null);

    const createTree = (parentIdToGroups, node) => {
      const activeUsers = this.usersForGroup(node.get("id"));
      const children = parentIdToGroups
          .get(node.get("id"), Immutable.List())
          .map(childNode => createTree(parentIdToGroups, childNode));
      return node
          .set("children", children)
          .set("users", activeUsers.sort((a, b) => a.get("fullName").localeCompare(b.get("fullName"))));
    };

    const rootNode = createTree(parentIdToGroups, root);
    const filterFn = group => {
      const deletedFlag = !group.get("deleted") || showDeletedGroups;
      if (showAll) {
        return deletedFlag;
      } else {
        const notFiltering = showAttributes.length === 0;
        const isRoot = !group.get("parentId");

        const hasCustomPage = group.get("id") === group.get("inheritedPageGroupId") ||
            usersMatchCriteria(group.get("users"), "page");
        const filterByPage = showAttributes.includes("page");
        const pageFlag = filterByPage && hasCustomPage;

        const hasCustomCurrency = group.get("id") === group.get("inheritedCurrencyGroupId") ||
            usersMatchCriteria(group.get("users"), "currency");
        const filterByCurrency = showAttributes.includes("currency");
        const currencyFlag = filterByCurrency && hasCustomCurrency;

        const hasCustomMusic = group.get("id") === group.get("inheritedMusicGroupId") ||
            usersMatchCriteria(group.get("users"), "music");
        const filterByMusic = showAttributes.includes("music");
        const musicFlag = filterByMusic && hasCustomMusic;

        const hasCustomLocale = group.get("id") === group.get("inheritedLocaleGroupId") ||
            usersMatchCriteria(group.get("users"), "locale");
        const filterByLocale = showAttributes.includes("locale");
        const localeFlag = filterByLocale && hasCustomLocale;

        return deletedFlag
            && (currencyFlag || pageFlag || musicFlag || localeFlag || (isRoot && notFiltering));
      }
    };
    const nodes = traverseFun(rootNode, filterFn);
    if (!nodes) {
      return rootNode;
    }

    if (this.state.shouldSearch) {
      this.setState(state => {
        const searchResultIds = recurseSearch(nodes, state.searchTerm, []);
        const fullSearchResultsIds = recurseSearch(rootNode, state.searchTerm, []);
        const nodesHiddenByFiltersCount = fullSearchResultsIds.length - searchResultIds.length;
        return {
          searchResultIds,
          shouldSearch: false,
          currentSearchResultId: searchResultIds[0],
          nodesHiddenByFiltersCount
        };
      }, () => this.state.searchResultIds.length > 0 && this.centerNode(this.state.searchResultIds[0]));
    }
    return nodes;
  }
});

const usersMatchCriteria = (users, criteria) => {
  if (!users) {
    return false;
  }
  let matches;
  switch (criteria) {
    case "page":
      matches = users.filter(u => u.get("pageId") !== null).count() > 0;
      break;
    case "music":
      matches = users.filter(u => u.get("dealMusicId") !== null).count() > 0;
      break;
    case "locale":
      matches = users.filter(u => u.get("localeId") !== null).count() > 0;
      break;
    default:
      matches = false;
  }
  return matches;
};

const matchesSearch = (group, searchTerm) => {
  if (searchTerm) {
    const groupNameMatches = group.get("name").toLowerCase().includes(searchTerm.toLowerCase());
    const userNameMatches = group.get("users") && group.get("users")
        .some(u => u.get("fullName").toLowerCase().includes(searchTerm.toLowerCase()));
    return groupNameMatches || userNameMatches;
  } else {
    return true;
  }
};

const recurseSearch = (group, searchTerm, searchResultIds) => {
  if (matchesSearch(group, searchTerm)) {
    searchResultIds.push(group.get("id"));
  }
  group.get("children").size > 0 &&
  group.get("children").map(c => recurseSearch(c, searchTerm, searchResultIds));
  return searchResultIds;
};

const traverseFun = (group, pred) => {
  const filteredChildren = group
      .get("children")
      .map(c => traverseFun(c, pred))
      .filter(c => c !== null);
  const hasMatchingChildren = !filteredChildren.isEmpty();
  const groupMatches = pred(group);
  if (hasMatchingChildren || groupMatches) {
    return group.set("children", filteredChildren);
  } else {
    return null;
  }
};

const setUserPassword = (userId, options) => Ajax.put({
  url: path("users", userId, "set-password"),
  data: options
});

const deactivateUser = (user, options) => Ajax.put({
  url: path("users", user.get("id"), "deactivate"),
  json: {
    ...options,
    leavingDate: options.leavingDate.format("YYYY-MM-DD")
  }
});

const loadPermissionsForUser = userId => Ajax
    .get({url: path("users", userId, "permissions")})
    .then(response => Immutable.fromJS(response).toSet());

const updatePermissionsForUser = (userId, permissions) => Ajax
    .put({
      url: path("users", userId, "permissions"),
      json: permissions.toJS()
    })
    .then(response => Immutable.fromJS(response).toSet());

const loadAppsForUser = userId => Ajax
    .get({url: path("users", userId, "apps")})
    .then(response => Immutable.fromJS(response).toSet());

const updateAppsForUser = (userId, apps) => Ajax
    .put({
      url: path("users", userId, "apps"),
      json: apps.toJS()
    })
    .then(response => Immutable.fromJS(response).toSet());

const loadUsersToLoginAs = userId => Ajax
    .get({url: path("users", userId, "can-login-as-users")})
    .then(response => Immutable.fromJS(response).toSet());

const updateUsersToLoginAs = (userId, userIdsToLoginAs) => {
  return Ajax
      .put({
        url: path("users", userId, "can-login-as-users"),
        json: userIdsToLoginAs.toJS()
      })
      .then(response => Immutable.fromJS(response).toSet());
};

const filterUsers = (users) => {
  if (Users.isCube19User(Users.getCurrentUser())) {
    return users
        .valueSeq()
        .filter(Users.isActive);
  } else {
    return users
        .valueSeq()
        .filter(user => Users.isActive(user) && !Users.isCube19User(user));
  }
};

const attributeToAuditKey = {
  "page": "showOneViewPages",
  "currency": "showCurrency",
  "music": "showDealMusic",
  "locale": "showLocale"
};

function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function() {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}

const GroupInheritance = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ThemeReceivingGroupInheritance theme={theme} {...props} />;
};

export default GroupInheritance;