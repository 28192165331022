import React from "react";
import Immutable from "immutable";
import moment from "moment";
import createReactClass from "create-react-class";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";

import {endsWith} from "js/common/utils/strings";
import {formatStorageSize} from "js/common/utils/numbers";

import * as Locales from "js/common/locales";
import * as Users from "js/common/users";

import Dropzone from "js/common/views/inputs/dropzone";
import {TextButton} from "js/common/views/inputs/buttons";
import ButtonBar from "js/common/views/button-bar";
import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import {Layout} from "js/common/views/foundation-column-layout";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import UserGuide from "js/admin/groups/bulk-user-guide";
import GroupHistoryCalculationTable from "js/admin/groups/group-history-calculation-table";
import UploadedBulkFilesTable from "js/admin/groups/uploaded-bulk-files-table";
import LoadingSpinner from "js/common/views/loading-spinner";
import SelectField from "@mui/material/Select";
import {MenuItem} from "@mui/material";
import dropzoneArrow from "img/dropzone-arrow.png";
import {apiUrl} from "js/app-configuration";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants";
import * as ajax from "js/common/ajax";
import FileSaver from "browser-filesaver";

const BulkUpdatePage = createReactClass({

  displayName: "GroupHistoryBulkUpdate",

  getInitialState() {
    return {
      file: null,
      shouldShowUserGuide: false,
      success: null,
      error: null,
      uploadingFile: false,
      downloadingGroupHistoryFile: false,
      downloadingUserFile: false,
      downloadingGroupFile: false,
      loadingLocales: false,
      idToLocale: Immutable.Map(),
      selectedLocaleId: Users.getInherited(Users.getCurrentUser(), x => x.get("localeId"))
    };
  },

  componentDidMount() {
    this.setState({loadingLocales: true});

    Locales
        .loadAll()
        .then(locales => this.setState({
          loadingLocales: false,
          idToLocale: locales.groupBy(l => l.get("id")).map(ls => ls.first())
        }));
  },

  componentDidUpdate() {
    if (this.state.success) {
      this.msgTimeoutId = window.setTimeout(() => this.setState({success: null}), 2000);
    }
  },

  componentWillUnmount() {
    window.clearTimeout(this.msgTimeoutId);
  },

  getLocaleCode() {
    return this.state.idToLocale.get(this.state.selectedLocaleId).get("code");
  },

  render() {
    if (this.state.loadingLocales || this.state.idToLocale.isEmpty()) {
      return <LoadingSpinner />;
    }

    const {theme} = this.props;
    const {
      file,
      shouldShowUserGuide,
      error,
      success,
      downloadingGroupHistoryFile,
      downloadingUserFile,
      downloadingGroupFile
    } = this.state;
    return (
        <div className="bulk-update user-groups-bulk-update">
          <AdminHeader>
            <Icon icon="users" />
            Update in bulk
          </AdminHeader>
          <br />
          <section className="page-section">
            <Layout allSmall={12} allLarge={10} largeCentered={true}>
              <p style={{textAlign: "left", color: "#7E7E7E", fontSize: "0.875rem"}}>
                {`You can use this bulk update feature to easily make multiple changes to a User's Group history or move multiple Users to different Groups by uploading a CSV formatted spreadsheet file with all your Users' Group history changes.`}
              </p>
            </Layout>
            <Layout allSmall={12} allLarge={10} largeCentered={true} style={{margin: "1rem"}}>
              <TextButton
                  icon="info-circle"
                  label={`${shouldShowUserGuide ? "Hide" : "Show"} User Guide`}
                  onClick={() => this.setState({shouldShowUserGuide: !shouldShowUserGuide})}
                  iconStyle={{fontSize: "13px"}}
                  labelStyle={{fontSize: "12px"}}
                  style={{height: "26px", lineHeight: 1, float: "right"}} />
            </Layout>
            <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
              {shouldShowUserGuide && <UserGuide theme={theme} />}
            </VelocityTransitionGroup>
            <ButtonBar style={{marginTop: "1rem", marginBottom: "1rem"}}>
              <SelectField
                  variant="standard"
                  value={this.state.selectedLocaleId}
                  onChange={e => this.setState({selectedLocaleId: e.target.value})}
                  style={{verticalAlign: "middle"}}>
                {this.state.idToLocale.valueSeq().sortBy(l => l.get("name")).map(localeToMenuItem)}
              </SelectField>
              <TextButton
                  icon="download"
                  label={downloadingGroupHistoryFile ? "Downloading" : "Groups History"}
                  disabled={downloadingGroupHistoryFile}
                  onClick={this.handleGroupHistoryDownload} />
              <TextButton
                  icon="user"
                  label={downloadingUserFile ? "Downloading" : "Users List"}
                  disabled={downloadingUserFile}
                  onClick={this.handleUserDownload} />
              <TextButton
                  icon="group"
                  label={downloadingGroupFile ? "Downloading" : "Groups List"}
                  disabled={downloadingGroupFile}
                  onClick={this.handleGroupDownload} />
            </ButtonBar>
            <Layout allSmall={12} allLarge={10} largeCentered={true} style={{textAlign: "left"}}>
              {this.state.uploadingFile
                  ? <LoadingSpinner />
                  : <Dropzone
                      onDrop={files => this.handleFileDrop(files[0])}
                      multiple={false}
                      disablePreview={true}
                      className="react-dropzone"
                      activeClassName="react-dropzone-active"
                      style={{
                        minHeight: file ? 40 : 120,
                        borderStyle: file ? "solid" : "dashed",
                        backgroundColor: file && theme.themeId === "dark" ? "#444" : "transparent",
                        padding: 5
                      }}
                      activeStyle={{borderStyle: "dashed"}}>
                    {file ? this.renderFileUploadPreview() : this.renderDropzoneHelpMessage()}
                  </Dropzone>}
              {error && <ErrorMsg text={error} />}
              {success && <SuccessMsg text={success} />}
            </Layout>
          </section>
          <UploadedBulkFilesTable />
          <GroupHistoryCalculationTable />
        </div>);
  },

  renderDropzoneHelpMessage() {
    return (
        <div style={{textAlign: "center", color: "#bbb", paddingTop: "1rem"}}>
          <img src={dropzoneArrow} />
          <p>Drop your Groups history file here or click to choose a file.</p>
        </div>
    );
  },

  renderFileUploadPreview() {
    const {file} = this.state;
    const fileName = file.name;
    const fileSize = file.size;
    const buttonSpacing = {
      marginLeft: "0.5rem",
      marginRight: "0.5rem"
    };
    return (
        <div style={{display: "flex", alignItems: "center"}}>
          <div style={{width: "45%"}}>
            <i className="fa fa-file-excel-o" />&nbsp;{fileName}
          </div>
          <div style={{width: "10%"}}>
            {`${formatStorageSize(fileSize)}`}
          </div>
          <div>
            <TextButton
                icon="remove"
                label="Remove"
                onClick={this.handleRemoveFileClick}
                style={buttonSpacing} />
            <TextButton
                type="primary"
                icon="upload"
                label="Upload"
                disabled={!!this.state.error}
                onClick={this.handleUploadClick}
                style={buttonSpacing} />
          </div>
        </div>
    );
  },

  handleFileDrop(file) {
    this.setState({
      file,
      error: null,
      success: null
    }, () => this.validateFile());
  },

  validateFile() {
    const {file} = this.state;
    const fileName = file.name;
    if (fileName === "users.csv" || fileName === "groups.csv") {
      this.setState({
        error: `You can only upload the 'history.csv' file, the '${fileName}' file is for reference only.`
      });
    } else if (!endsWith(".csv", fileName.toLowerCase())) {
      this.setState({
        error: `You can only upload CSV (.csv) files, '${fileName}' is not a CSV file.`
      });
    }
  },

  handleRemoveFileClick(e) {
    e.stopPropagation();
    this.setState({
      file: null,
      error: null,
      success: null
    });
  },

  handleUploadClick(e) {
    this.setState({uploadingFile: true});

    e.stopPropagation();
    const req = window.superagent
        .post(apiUrl + "/ApplicationLayer/group-history/upload")
        .withCredentials()
        .attach("file", this.state.file)
        .field("locale-code", this.getLocaleCode())
        .end((error, response) => {
          if (error) {
            if (response.status === 403) {
              this.setState({
                uploadingFile: false,
                error: "You cannot upload a new file whilst an old file is still being processed"
              });
            } else {
              this.setState({
                uploadingFile: false,
                error: `An unexpected error has occurred. ${Branding.submitTicketString}`
              });
            }
          } else {
            this.setState({
              file: null,
              uploadingFile: false,
              success: "Your file has been uploaded"
            });
          }
        });
  },

  handleGroupHistoryDownload() {
    this.setState({
      downloadingGroupHistoryFile: true
    });
    ajax.get({
      url: "/group-history/history.csv?localeCode=" + this.getLocaleCode(),
      returnFileName: true
    }).then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      this.setState({
        downloadingGroupHistoryFile: false
      });
    });
  },

  handleUserDownload() {
    this.setState({
      downloadingUserFile: true
    });
    ajax.get({
      url: "/group-history/users.csv",
      returnFileName: true
    }).then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      this.setState({
        downloadingUserFile: false
      });
    });
  },

  handleGroupDownload() {
    this.setState({
      downloadingGroupFile: true
    });
    ajax.get({
      url: "/group-history/groups.csv",
      returnFileName: true
    }).then(response => {
      downloadCsv(Immutable.fromJS(response.body), response.fileName);
      this.setState({
        downloadingGroupFile: false
      });
    });
  }

});

const downloadCsv = (csv, header) => {
  const blob = new Blob([csv], {type: "text/csv;charset=utf-8"});
  FileSaver.saveAs(blob, header);
};

const localeToMenuItem = locale => <MenuItem
    key={locale.get("id")}
    value={locale.get("id")}>
  {`${locale.get("name")} Dates - ${moment().locale(locale.get("code")).localeData().longDateFormat("L")}`}
</MenuItem>;

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <BulkUpdatePage theme={theme} {...props} />;
};