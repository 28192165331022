import * as Immutable from "immutable";

export default () => {
  const cid = Math.random();
  return Immutable.fromJS({
    cid: cid,
    id: `slide-${cid}`,
    pageTitle: {
      title: "",
      subTitle: "",
      timeframeId: ""
    },
    description: "Web Page Slide",
    timing: 30000,
    layout: {
      id: "Iframe",
      fullscreen: [{
        visualizationId: "Iframe",
        url: "",
        hasTitle: false,
        isCustomSizeEnabled: false,
        pageWidth: 1280,
        pageHeight: 720
      }]
    },
    showNewsticker: true
  });
};
