import React from "react";
import createReactClass from "create-react-class";

import Dropzone from "js/common/views/inputs/dropzone";
import AdminHeader from "js/admin/common/admin-header";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import ErrorMsg from "js/common/views/error";
import {TextButton} from "js/common/views/inputs/buttons";
import {formatStorageSize} from "js/common/utils/numbers";
import * as popups from "js/common/popups";
import currentClient from "js/common/repo/backbone/current-client";
import dropzoneArrow from "img/dropzone-arrow.png"
import {apiUrl} from "js/app-configuration";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Branding from "js/common/branding-constants"
const maxFileSizeInBytes = 10e6;
const maxFileSizeStr = formatStorageSize(maxFileSizeInBytes, 0);

const CompanyLogoUpload = createReactClass({

  getInitialState() {
    return {
      currentLogoUrl: currentClient.get("logoURL"),
      file: null
    };
  },

  componentWillUnmount() {
    const {file} = this.state;
    if (file) {
      URL.revokeObjectURL(file.preview);
    }
  },

  render() {
    const {currentLogoUrl, file} = this.state;
    const {theme} = this.props;
    const hasFile = !!file;
    const fileName = hasFile && file.name;
    const fileSize = hasFile && file.size;
    return (
        <div>
          <AdminHeader>
            Change your company logo in {Branding.brandingName}
          </AdminHeader>
          <div style={{width: "90%", margin: "1rem auto"}}>
            <Hint>
              <Icon icon="info" style={{color: theme.palette.hints.text}} />
              <div>
                Your company logo should be either a JPEG or PNG image file and cannot be larger than {maxFileSizeStr} in size.<br />
                Recommended minimum image resolution: <strong>320px (width and/or height)</strong>
              </div>
            </Hint>
            <div style={{textAlign: "center", margin: "1rem auto"}}>
              <img src={currentLogoUrl} style={{maxHeight: 100, width: "auto"}} />
            </div>
            <Dropzone
                onDrop={files => this.onFileDrop(files[0])}
                multiple={false}
                disablePreview={false}
                className="react-dropzone"
                activeClassName="react-dropzone-active"
                style={{minHeight: file ? 40 : 120, borderStyle: file ? "solid" : "dashed", padding: 5, borderColor: theme.themeId === "light" ? "#b7b7b8" : "#fff"}}
                activeStyle={{borderStyle: "dashed"}}>
              {file ?
                  <FilePreview
                      file={file}
                      onUploadBtnClick={this.onUploadBtnClick}
                      onRemoveBtnClick={this.onRemoveBtnClick} /> :
                  <HelpText theme={theme}/>}
            </Dropzone>
            {(hasFile && !isValidImageFile(fileName)) &&
            <ErrorMsg text={`You can only upload JPEG (.jpg, .jpeg) or PNG (.png) files. '${fileName}' is not an accepted image file.`} />}
            {(hasFile && !isValidFileSize(fileSize)) &&
            <ErrorMsg text={`You can only upload files with a maximum size of ${maxFileSizeStr}. '${fileName}' is ${formatStorageSize(fileSize)}.`} />}
          </div>
        </div>);
  },

  onFileDrop(file) {
    const preview = URL.createObjectURL(file);
    this.setState({file: Object.assign(file, {preview})})
  },

  onUploadBtnClick(e) {
    e.stopPropagation();
    const {file} = this.state;
    if (isValidFile(file)) {
      window.superagent
          .post(`${apiUrl}/ApplicationLayer/clients/current/upload-company-logo`)
          .withCredentials()
          .attach("file", file)
          .end((error, response) => {
            if (error) {
              popups.contactSupport();
            } else {
              const newLogoUrl = response.body.logoURL;
              updateCompanyLogoUrl(newLogoUrl);
              this.setState({
                currentLogoUrl: newLogoUrl,
                file: null
              });
              popups.success("Your company logo has been uploaded");
            }
          });
    } else {
      popups.error("Unable to upload file. Please see error details.");
    }
  },

  onRemoveBtnClick(e) {
    e.stopPropagation();
    URL.revokeObjectURL(this.state.file.preview);
    this.setState({file: null});
  }

});

const FilePreview = ({file, onUploadBtnClick, onRemoveBtnClick}) => (
    <div className="clearfix" style={{padding: "1rem"}}>
      <div style={{float: "left", textAlign: "center", width: "50%"}}>
        <img src={file.preview} style={{maxHeight: 100}} />
        <p>{file.name}</p>
      </div>
      <div style={{float: "right", width: "50%"}}>
        <TextButton
            id="upload-logo-file"
            icon="upload"
            label="Upload"
            onClick={onUploadBtnClick}
            style={{
              display: "inline-block",
              margin: "0.5rem"
            }} />
        <TextButton
            id="remove-logo-file"
            icon="remove"
            label="Remove"
            onClick={onRemoveBtnClick}
            style={{
              display: "inline-block",
              margin: "0.5rem"
            }} />
      </div>
    </div>
);

const HelpText = ({theme}) => (
    <div style={{textAlign: "center", paddingTop: "1rem", color: theme.themeId === "light" ? "#747474" : "#bbbbbb"}}>
      <img src={dropzoneArrow} />
      <p>Drop your new company logo here or click to choose a file</p>
    </div>
);

const isValidFile = file => {
  return isValidImageFile(file.name) && isValidFileSize(file.size);
};
const isValidImageFile = fileName => {
  const regex = /\.(jpe?g|png)$/i;
  return regex.test(fileName.toLowerCase());
};
const isValidFileSize = fileSizeInBytes => fileSizeInBytes <= maxFileSizeInBytes;

const updateCompanyLogoUrl = newLogoUrl => currentClient.save({logoURL: newLogoUrl}, {wait: true});

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <CompanyLogoUpload theme={theme} {...props} />;
};