import Immutable from "immutable";

import {
  getKpiIdsForFormulaId,
  evaluateFormulaId,
  evaluateFormulasInTemplateString,
  getLabelForFormulaId,
  addErrorLocation
} from "js/common/formulas/formulas";

import {formatResult, applyArgsToFormula} from "js/common/formulas/utils";

const getWorkingType = type => {
  if (type === "label-working" || type === "label-all") {
    return "label";
  } else if (type === "component-on-hover") {
    return "hover";
  } else {
    return "none";
  }
};

export default Immutable.fromJS([
  {
    id: "show-working",
    getFormulaUsed: args => {
      return Immutable.Map({
        formulaId: args.get(1),
        formulaArgs: [args.shift().shift()]
      });
    },
    getKpiIds: (
        context,
        masterKpiTypeToKpiId,
        idToNode,
        idToFormula,
        formulaIdAndArgsToCachedKpiIds,
        args) => {
      // NOTE destructuring not available in testcafe hence below, causes error - sent to hammerhead.js team
      // https://github.com/DevExpress/testcafe-hammerhead/issues/2669
      const formulaId = args.get(1);
      const rest = args.shift().shift();
      const formulaArgs = new Immutable.List(rest);

      if (!formulaId) {
        // TODO can we do something better around argument parsing and validation for formulas?
        throw new Error("wrong args for $(show-working <label-type> <formula-id>), received " + formulaArgs);
      }

      return getKpiIdsForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          idToFormula,
          formulaIdAndArgsToCachedKpiIds,
          formulaId,
          new Immutable.List(formulaArgs));
    },
    fn: (context, masterKpiTypeToKpiId, idToNode, kpiIdToValue, idToFormula, args) => {
      // NOTE destructuring not available in testcafe hence below, causes error - sent to hammerhead.js team
      const type = args.get(0);
      const formulaId = args.get(1);
      const rest = args.shift().shift();
      const formulaArgs = new Immutable.List(rest);

      if (!formulaId) {
        throw new Error("wrong args for $(show-working <label-type> <formula-id>)");
      }

      if (!idToFormula.has(formulaId)) {
        throw new Error(`${formulaId} is not a custom formula`);
      }

      const formulaStr = idToFormula.getIn([formulaId, "str"]);
      const formulaStrWithArgs = applyArgsToFormula(formulaStr, formulaArgs);
      const {result: working, errors: workingErrors} = evaluateFormulasInTemplateString(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          formulaStrWithArgs,
          getWorkingType(type));

      const locationedWorkingErrors = addErrorLocation(workingErrors, "custom-formula", {formulaId});

      const {result, errors: resultErrors} = evaluateFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          formulaId,
          new Immutable.List(formulaArgs));

      let errors = locationedWorkingErrors.concat(resultErrors);

      const {result: formattedResult, errors: formattingErrors} = formatResult(result, formulaId);
      errors = errors.concat(formattingErrors);

      const getResultLabel = () => getLabelForFormulaId(
          context,
          masterKpiTypeToKpiId,
          idToNode,
          kpiIdToValue,
          idToFormula,
          formulaId,
          new Immutable.List(formulaArgs));
      const getLabelledResult = () => {
        const {result, errors: labelErrors} = getResultLabel();
        errors = errors.concat(labelErrors);
        return formattedResult + " " + result;
      };
      const workingWithEquals = working.push(" = ");

      switch (type) {
        case "label-working":
          const workingAndResult = workingWithEquals.push(formattedResult);
          return {result: Immutable.Map({formatAs: "REACT_ELEMENTS", value: workingAndResult}), errors: errors};
        case "label-result":
          const workingAndLabelledResult = workingWithEquals.push(getLabelledResult());
          return {result: Immutable.Map({formatAs: "REACT_ELEMENTS", value: workingAndLabelledResult}), errors: errors};
        case "label-all":
          const labelledWorkingAndResult = workingWithEquals.push(getLabelledResult());
          return {result: Immutable.Map({formatAs: "REACT_ELEMENTS", value: labelledWorkingAndResult}), errors: errors};
          /* case "component-on-hover":
            const resultWithHover = <>
              <i data-tip='' data-for={formulaId}>
                {formattedResult}
                <Tooltip id={formulaId} place="top" type="light" effect="solid">
                  {getResultLabel().result}
                </Tooltip>
              </i>
            </>;
            const componentsWithHover = workingWithEquals.push(resultWithHover);
            return {result: Immutable.Map({formatAs: "REACT_ELEMENTS", value: componentsWithHover}), errors: errors};
          case "formula-on-hover":
            const formulaLabelList = evaluateFormulaLabelsInTemplateString(
                context,
                masterKpiTypeToKpiId,
                idToNode,
                kpiIdToValue,
                idToFormula,
                formulaStrWithArgs
            );

            const formulaLabel = formulaLabelList.push(" = ", getResultLabel().result);
            const workingAndResultFormula = workingWithEquals.push(formattedResult);

            const fullComponent = <>
              <i data-tip='' data-for={formulaId}>
                {workingAndResultFormula}
              </i>
              <Tooltip id={formulaId} place="top" type="light" effect="solid">
                {formulaLabel}
              </Tooltip>
            </>;

            return {result: Immutable.Map({formatAs: "REACT_ELEMENTS", value: fullComponent}), errors: errors}; */
        case "no-labels":
          const formulaNoLabels = workingWithEquals.push(formattedResult);
          return {result: Immutable.Map({formatAs: "REACT_ELEMENTS", value: formulaNoLabels}), errors: errors};
        default:
          throw new Error("Unsupported show working type: " + type);
      }
    }
  }]);
