import * as Immutable from "immutable";

import {formatDate, parseDate, today} from "js/job-pipelines/date-utils";
import * as Users from "js/common/users";
import * as ajax from "js/common/ajax";
import * as auditor from "js/common/auditer";

const path = window.path;

const loadPipelines = () => ajax
    .get({url: "job-pipeline/pipelines"})
    .then(x => Immutable.fromJS(x));

const loadStages = () => ajax
    .get({url: "job-pipeline/pipelines/stages"})
    .then(x => Immutable.fromJS(x));

const dataForReq = (
    jobKpiId,
    jobKpiQualifier,
    activityQualifier,
    byActivityOwner,
    timeframe,
    pipelineIdToStages,
    shouldIncludeActivityForOtherUsers) => {
  const data = {
    params: {
      startDate: timeframe.start,
      endDate: timeframe.end,
      eventDate: formatDate(today()),
      dateFromUI: formatDate(today())
    },
    jobKpiId,
    activityQualifierId: activityQualifier.get("id"),
    activityQualifierType: activityQualifier.get("type"),
    byActivityOwner,
    shouldIncludeActivityForOtherUsers,
  };
  switch (jobKpiQualifier.get("type")) {
    case "USER":
      data.params.userId = jobKpiQualifier.get("id");
      data.params.groupId = getImmutableUser(jobKpiQualifier.get("id")).get("groupId");
      break;
    case "GROUP":
      data.params.groupId = jobKpiQualifier.get("id");
      break;
  }
  return data;
};

const loadPipelineIdToStats = (
    jobKpiId,
    jobKpiQualifier,
    activityQualifier,
    byActivityOwner,
    timeframe,
    pipelineIdToStages
) => {
  const opts = {
    url: "job-pipeline/predict",
    json: dataForReq(
        jobKpiId,
        jobKpiQualifier,
        activityQualifier,
        byActivityOwner,
        timeframe,
        pipelineIdToStages)
  };
  return ajax
      .post(opts)
      .then(x => Immutable.fromJS(x))
      .then(x => parsePredictData(x));
};

const parsePredictData = pipelineIdToStats => pipelineIdToStats
    .mapKeys(parseInt)
    .map(stats => stats
        .update("stageIdToCountForPlacedJobs", stageIdToCount => stageIdToCount
            .mapKeys(stageId => parseInt(stageId))));

const loadData = (
    jobKpiId,
    jobKpiQualifier,
    activityQualifier,
    byActivityOwner,
    timeframe,
    pipelineIdToStages,
    idToPipeline,
    shouldIncludeActivityForOtherUsers = true
) => {
  auditor.audit("job-pipeline:run-report");
  const opts = {
    url: "job-pipeline/calculate",
    json: dataForReq(
        jobKpiId,
        jobKpiQualifier,
        activityQualifier,
        byActivityOwner,
        timeframe,
        pipelineIdToStages,
        shouldIncludeActivityForOtherUsers)
  };
  return ajax
      .post(opts)
      .then(x => Immutable.fromJS(x))
      .then(d => parseData(d, pipelineIdToStages))
      .then(d => resolveIdsInRows(d))
      .then(d => calculateCandidateData(d, pipelineIdToStages, idToPipeline))
      .then(d => calculateJobData(d, pipelineIdToStages, idToPipeline));
};

const updateJob = job => ajax
    .put({
      url: path("job-pipeline/jobs", job.get("id")),
      json: job
          .update("createdDate", formatDate)
          .update("closeDate", d => d ? formatDate(d) : null)
          .toJS()
    })
    .then(x => parseJob(Immutable.fromJS(x)));

const parseJob = job => job
    .update("createdDate", d => d ? parseDate(d) : null)
    .update("closeDate", d => d ? parseDate(d) : null);

const dateEnumToDateFieldName = Immutable.Map({
  CREATED: "createdDate",
  EVENT: "eventDate"
});

const parseData = (data, pipelineIdToStages) => {
  const idToStage = pipelineIdToStages
      .valueSeq()
      .flatMap(stages => stages)
      .groupBy(stage => stage.get("id"))
      .map(stages => stages.first());
  return data
      .update("idToJob", idToJob => idToJob
          .mapKeys(parseInt)
          .map(parseJob))
      .update("idToClient", idToClient => idToClient.mapKeys(parseInt))
      .update("idToClientContact", idToClientContact => idToClientContact.mapKeys(parseInt))
      .update("idToCandidate", idToCandidate => idToCandidate.mapKeys(parseInt))
      .update("jobRows", jobRows => jobRows
          .map(jobRow => jobRow
              .update("candidateRows", candidateRows => candidateRows
                  .map(candidateRow => candidateRow
                      .update("stageIdToEntities", stageIdToEntities => stageIdToEntities
                          .mapKeys(parseInt)
                          .map((entities, stageId) => entities
                              .map(entity => entity
                                  .update("createdDate", parseDate)
                                  .update("eventDate", parseDate))
                              .map(entity => {
                                const stage = idToStage.get(stageId);
                                const dateFieldName = dateEnumToDateFieldName.get(stage.get("dateToUse"));
                                return entity.set("date", entity.get(dateFieldName));
                              })))))));
};

const resolveIdsInRows = data => data
    .update("jobRows", jobRows => jobRows
        .map(jobRow => {
          const job = data.get("idToJob").get(jobRow.get("jobId"));
          const client = data.get("idToClient").get(job.get("clientId"));
          const clientContact = data.get("idToClientContact").get(job.get("clientContactId"));
          return jobRow
              .set("client", client)
              .set("clientContact", clientContact)
              .update("candidateRows", candidateRows => candidateRows
                  .map(candidateRow => {
                    const candidate = data.get("idToCandidate").get(candidateRow.get("candidateId"));
                    return candidateRow.set("candidate", candidate);
                  }));
        }));

const calculateCandidateData = (data, pipelineIdToStages, idToPipeline) => data
    .update("jobRows", jobRows => jobRows
        .map(jobRow => jobRow
            .update("candidateRows", candidateRows => candidateRows
                .map(candidateRow => {
                  const pipeline = idToPipeline.get(jobRow.get("pipelineId"));
                  const stages = pipelineIdToStages.get(jobRow.get("pipelineId"), Immutable.List());
                  const job = data.get("idToJob").get(jobRow.get("jobId"));
                  const stageIdToEntities = candidateRow.get("stageIdToEntities");

                  const stageIdToLatestEntityDate = calculateStageIdToLatestEntityDate(
                      stages,
                      job.get("createdDate"),
                      stageIdToEntities);
                  const stageIdToDaysBetween = calculateStageIdToDaysBetween(
                      stages,
                      job.get("createdDate"),
                      stageIdToLatestEntityDate);

                  const completedStages = getCompletedStages(stages, stageIdToEntities);
                  const furthestStageReached = completedStages.last();
                  const allEntities = stageIdToEntities.valueSeq().flatMap(es => es);
                  const lastActivityDate = allEntities.map(e => e.get("date")).max();

                  const completedStageTypes = completedStages.map(s => s.get("type")).toSet();
                  let candidateType;
                  if (completedStageTypes.includes("DEAD")) {
                    candidateType = "DEAD";
                  } else if (completedStageTypes.includes("PLACED")) {
                    candidateType = "PLACED";
                  } else {
                    candidateType = "LIVE";
                  }

                  return candidateRow
                      .set("candidate", data.get("idToCandidate").get(candidateRow.get("candidateId")))
                      .set("weighting", furthestStageReached ?
                          furthestStageReached.get("weighting") :
                          pipeline.get("defaultWeighting"))
                      .set("type", candidateType)
                      .set("lastActivityDate", lastActivityDate)
                      .set("daysSinceLastActivity", today().diff(lastActivityDate, "days"))
                      .set("stageIdToLatestEntityDate", stageIdToLatestEntityDate)
                      .set("stageIdToDaysBetween", stageIdToDaysBetween)
                      .set("furthestStageReachedOrder", furthestStageReached ?
                          furthestStageReached.get("ordering") :
                          -1)
                      .set("furthestStageReachedId", furthestStageReached && furthestStageReached.get("id"));
                }))));

const calculateJobData = (data, pipelineIdToStages, idToPipeline) => data
    .update("jobRows", jobRows => jobRows
        .map(jobRow => {
          const pipeline = idToPipeline.get(jobRow.get("pipelineId"));
          const stages = pipelineIdToStages.get(jobRow.get("pipelineId"), Immutable.List());
          const stageIdToEntities = jobRow
              .get("candidateRows")
              .map(candidateRow => candidateRow.get("stageIdToEntities"))
              .reduce(
                  (m, stageIdToEntities) => m.mergeWith(
                      (es1, es2) => es1.concat(es2),
                      stageIdToEntities),
                  Immutable.Map());

          const job = data.get("idToJob").get(jobRow.get("jobId"));

          const completedStages = getCompletedStages(stages, stageIdToEntities);
          const furthestStageReached = completedStages.last();

          const stageIdToLatestEntityDate = calculateStageIdToLatestEntityDate(
              stages,
              job.get("createdDate"),
              stageIdToEntities);
          const stageIdToDaysBetween = calculateStageIdToDaysBetween(
              stages,
              job.get("createdDate"),
              stageIdToLatestEntityDate);

          const allEntities = stageIdToEntities.valueSeq().flatMap(es => es);
          const lastActivityDate = allEntities.map(e => e.get("date")).max();

          const hasLiveCandidates = jobRow.get("candidateRows").some(cr => cr.get("type") === "LIVE");
          const hasDeadCandidates = jobRow.get("candidateRows").some(cr => cr.get("type") === "DEAD");

          const placedCandidates = jobRow.get("candidateRows").filter(cr => cr.get("type") === "PLACED");
          const hasPlacedCandidates = !placedCandidates.isEmpty();

          let weighting;
          if (hasPlacedCandidates) {
            weighting = jobRow
                .get("candidateRows")
                .filter(cr => cr.get("type") === "PLACED")
                .maxBy(cr => cr.get("furthestStageReachedOrder"))
                .get("weighting");
          } else if (hasLiveCandidates) {
            weighting = jobRow
                .get("candidateRows")
                .filter(cr => cr.get("type") === "LIVE")
                .maxBy(cr => cr.get("furthestStageReachedOrder"))
                .get("weighting");
          }
          weighting = weighting || pipeline.get("defaultWeighting");

          return jobRow
              .set("weighting", weighting)
              .set("hasLiveCandidates", hasLiveCandidates)
              .set("hasPlacedCandidates", hasPlacedCandidates)
              .set("hasDeadCandidates", hasDeadCandidates)
              .set("hasActivity", !allEntities.isEmpty())
              .set("lastActivityDate", lastActivityDate)
              .set("daysSinceLastActivity", lastActivityDate ? today().diff(lastActivityDate, "days") : -1)
              .set("stageIdToEntities", stageIdToEntities)
              .set("furthestStageReachedOrder", furthestStageReached ? furthestStageReached.get("ordering") : -1)
              .set("furthestStageReachedId", furthestStageReached && furthestStageReached.get("id"))
              .set("stageIdToLatestEntityDate", stageIdToLatestEntityDate)
              .set("stageIdToDaysBetween", stageIdToDaysBetween);
        }));

const calculateStageIdToLatestEntityDate = (stages, startDate, stageIdToEntities) => {
  const [_1, stageIdToLatestEntityDate] = stages.reduce(
      ([previousDate, m], stage) => {
        const latestEntity = stageIdToEntities
            .get(stage.get("id"), Immutable.List())
            .maxBy(entity => entity.get("date"));
        const latestDate = latestEntity ? latestEntity.get("date") : previousDate;
        return [latestDate, m.set(stage.get("id"), latestDate)];
      },
      [startDate, Immutable.Map()]);
  return stageIdToLatestEntityDate;
};

const calculateStageIdToDaysBetween = (stages, startDate, stageIdToLatestEntityDate) => {
  const [_2, stageIdToDaysBetween] = stages.reduce(
      ([previousDate, m], stage) => {
        const latestEntityDate = stageIdToLatestEntityDate.get(stage.get("id"));
        const daysBetween = latestEntityDate.diff(previousDate, "days");
        return [latestEntityDate, m.set(stage.get("id"), daysBetween)];
      },
      [startDate, Immutable.Map()]);
  return stageIdToDaysBetween;
};

const getCompletedStages = (stages, stageIdToEntities) => {
  return stages.filter(stage => {
    const entities = stageIdToEntities.get(stage.get("id"), Immutable.List());
    return !entities.isEmpty();
  });
};

const getImmutableUser = userId => {
  const userModel = Users.getUser(userId);
  const userJson = userModel ? userModel.toJSON() : {};
  return Immutable.fromJS(userJson);
};

export {
  loadPipelines,
  loadStages,
  updateJob,
  loadData
};
