import eventBus from "js/cube19.event-bus";

const Backbone = window.Backbone;

export const MAX_NAME_LENGTH = 128;

let CategoryModel = Backbone.Model.extend();

let CategoryCollection = Backbone.Collection.extend({
  url: "tags/categories",
  model: CategoryModel,
  comparator: "name"

});

let TagModel = Backbone.Model.extend({
  initialize: function() {
    this.updateCategory();
    this.listenTo(this, "change:categoryId", this.updateCategory);
  },

  updateCategory: function() {
    this.category = getCategory(this.get("categoryId"));
  }
});

let TagCollection = Backbone.Collection.extend({
  url: "tags",
  model: TagModel,
  comparator: function(tag) {
    return (tag?.category?.get("name") ?? "") + tag?.get("name");
  }
});

let categories = new CategoryCollection();
let tags = new TagCollection();

function loadCategories() {
  return categories
      .fetch()
      .then(() => {
        eventBus.trigger("data-loaded", "tag-categories");
      });
}

function loadTags() {
  return tags
      .fetch()
      .then(() => {
        eventBus.trigger("data-loaded", "tags");
      });
}

function load() {
  return loadCategories()
      .then(loadTags)
      .then(() => {
        // Update categories for all tags after both are loaded
        tags.each(tag => tag.updateCategory());
      })
      .catch(error => {
        eventBus.trigger("error:fatal", error);
      });
}

function getCategory(id) {
  return categories.get(id);
}

function getAllCategories() {
  return categories;
}

function get(id) {
  return tags.get(id);
}

function getAll() {
  return tags;
}

function getTagsInCategory(categoryId) {
  return tags.filter(tag => tag.category.id === categoryId);
}

export {
  load,
  get,
  getAll,
  getCategory,
  getAllCategories,
  getTagsInCategory
};
