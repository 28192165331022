import React from "react";
import Pluralize from "pluralize";

import DropdownToggle from "js/common/views/react-dropdown-toggle";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import GroupAndUserPicker from "js/common/views/inputs/group-and-user-picker/filterable-group-user-tree";
import {betterMemo} from "js/common/utils/more-memo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const MultipleUserGroupPicker = betterMemo({displayName: "MultipleUserGroupPicker"}, ({
  onChange,
  type,
  excludeNewAndInactive = false
}) => {
  const [selectedUserIds, setSelectedUserIds] = React.useState([]);
  const [selectedGroupIds, setSelectedGroupIds] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);

  const onRequestClose = () => {
    setIsOpen(false);
    onChange(type === "USER" ? selectedUserIds : selectedGroupIds);
  };

  const onToggle = () => {
    if (isOpen) {
      onRequestClose();
    } else {
      setIsOpen(true);
    }
  };

  const onUserClick = id => {
    const users = selectedUserIds;
    if (users.includes(id)) {
      users.splice(users.indexOf(id), 1);
    } else {
      users.push(id);
    }
    setSelectedUserIds(users);
  };

  const onGroupClick = id => {
    const groups = selectedGroupIds;
    if (groups.includes(id)) {
      groups.splice(groups.indexOf(id), 1);
    } else {
      groups.push(id);
    }
    setSelectedGroupIds(groups);
  };

  const label = (type === "USER" ? selectedUserIds.length === 0 : selectedGroupIds.length === 0)
      ? `Filter by ${type[0].toUpperCase() + type.slice(1).toLowerCase()}s`
      : `Filtering by ${type === "USER" ? selectedUserIds.length : selectedGroupIds.length} ${Pluralize(
          type[0].toUpperCase() + type.slice(1).toLowerCase(),
          type === "USER" ? selectedUserIds.length : selectedGroupIds.length,
          false)}`;

  const currentUserId = Users.getCurrentUser().get("id");
  const hierarchy = Groups.getHierarchyWithUsers(true);
  const {theme} = React.useContext(CustomThemeContext);

  return <div>
    <DropdownToggle
        primaryText={label}
        isOpen={isOpen}
        tooltipDisabled={true}
        onToggleButtonClick={onToggle}
        onRequestClose={onRequestClose}>
      <GroupAndUserPicker
          theme={theme}
          selectedGroupIds={selectedGroupIds}
          selectedUserIds={selectedUserIds}
          hierarchy={hierarchy}
          showInvisibleUsers={true}
          excludeNewAndInactive={excludeNewAndInactive}
          includeCube19Users={false}
          expandAll={type === "GROUP"}
          showDeletedGroups={false}
          showLoggedInUserOptions={false}
          disableChildrenOfSelectedGroups={false}
          qualifierId={currentUserId}
          qualifierType={type}
          onGroupClick={(id) => onGroupClick(id)}
          onUserClick={(id) => onUserClick(id)}
          multiSelect={true}
          groupsClickable={false}
          excludeUsers={type === "GROUP"}
          customContainerStyle={{
            maxHeight: "40rem",
            overflow: "auto"
          }}
      />
    </DropdownToggle>
  </div>;
});

export default MultipleUserGroupPicker;