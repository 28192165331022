import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";

import Ratio from "js/admin/oneview-pages/ratio"

export default createReactClass({

  propTypes: {
    ratioAssignments: ImmutablePropTypes.list.isRequired,
    onDeleteRatioClick: ReactPropTypes.func.isRequired,
    onMoveRatioDownClick: ReactPropTypes.func.isRequired,
    onMoveRatioUpClick: ReactPropTypes.func.isRequired
  },

  render() {
    return <ul>{this.getRatioElements()}</ul>;
  },

  getRatioElements() {
    const ratios = this.props.ratioAssignments
        .sortBy(assignment => assignment.get("order"))
        .map(assignment => assignment.get("ratio"));
    return ratios
        .map((ratio) => {
      const id = ratio.get("id") || ratio.get("cid");
      return <Ratio
          key={id}
          ratio={ratio}
          onMoveRatioDownClick={() => this.props.onMoveRatioDownClick(id)}
          onMoveRatioUpClick={() => this.props.onMoveRatioUpClick(id)}
          onDeleteRatioClick={() => this.props.onDeleteRatioClick(id)} />;
    });
  }

});