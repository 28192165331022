import React from "react";
import createReactClass from "create-react-class";

import GetContainerDimensions from "react-dimensions";
import PureRenderMixin from "react-addons-pure-render-mixin";

import AdminHeader from "js/admin/common/admin-header";
import Icon from "js/admin/common/icon";
import { Layout } from "js/common/views/foundation-column-layout";
import { TextButton } from "js/common/views/inputs/buttons";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import TagCategoryPicker from "js/admin/tags/tag-category-picker";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import * as tagRepo from "js/common/repo/backbone/tag-repo";

export default createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            tags,
            categories,
            newTagName,
            onNewTagNameChange,
            newTagCategoryId,
            onNewTagCategoryIdChange,
            onCreateNewTagRequest,
            isLoading,
            isCreatingNewTag,
            onEditTagClick,
            onDeleteTagClick,
            tagMgmtError,
            tagMgmtSuccess,
            onTagMgmtSuccessMsgTimeout
        } = this.props;
        const isCreateTagBtnDisabled = !newTagName || !newTagCategoryId || !!tagMgmtError || isLoading || isCreatingNewTag;
        const createTagBtnLabel = isCreatingNewTag ?
            <span><i className="fa fa-spinner fa-pulse fa-1x" />&nbsp;Creating Tag</span> :
            <span>Create Tag</span>;
        const spacing = {
            marginLeft: "0.5rem",
            marginRight: "0.5rem"
        };
        return (
            <div>
                <AdminHeader>
                    <Icon icon="category-tags" />
                    Create Tags
                </AdminHeader>
                <Layout
                    allSmall={12}
                    smallCentered={true}
                    collapseRow={true}
                    rowStyle={{ margin: "1rem 0.5rem" }}>
                    <div>
                        <div style={{ width: "25vw", display: "inline-block", verticalAlign: "top", ...spacing }}>
                            <SimpleTextInput
                                type="text"
                                placeholder="Enter New Tag Name Here"
                                value={newTagName}
                                onChange={onNewTagNameChange}
                                onKeyPress={keyPressed => {
                                    if (keyPressed === "Enter") {
                                        onCreateNewTagRequest();
                                    }
                                }} />
                        </div>
                        <div style={{ width: "25vw", display: "inline-block", verticalAlign: "top", ...spacing }}>
                            <div style={{
                                display: "inline-block",
                                verticalAlign: "bottom",
                                textAlign: "left",
                                width: "100%"
                            }}>
                                <TagCategoryPicker
                                    categories={categories}
                                    selectedCategoryId={newTagCategoryId}
                                    onChange={onNewTagCategoryIdChange} />
                            </div>
                        </div>
                        <div style={{ width: "180px", display: "inline-block", verticalAlign: "top", ...spacing }}>
                            <div style={{ display: "inline-block", float: "left" }}>
                                <TextButton
                                    type="primary"
                                    icon={isCreatingNewTag ? "" : "plus" }
                                    label={createTagBtnLabel}
                                    onClick={onCreateNewTagRequest}
                                    disabled={isCreateTagBtnDisabled} />
                            </div>
                        </div>
                    </div>
                </Layout>
                <Layout
                    allSmall={12}
                    allMedium={10}
                    smallCentered={true}
                    collapseRow={true}
                    rowStyle={{ margin: "1rem" }}>
                    {tagMgmtError && <ErrorMsg text={tagMgmtError} />}
                    {tagMgmtSuccess &&
                        <SuccessMsg text={tagMgmtSuccess} onMessageTimeout={onTagMgmtSuccessMsgTimeout} />}
                </Layout>
                <Layout
                    allSmall={12}
                    allMedium={10}
                    smallCentered={true}
                    collapseRow={true}
                    rowStyle={{ margin: "1rem" }}>
                    <TagsTable
                        tags={tags}
                        isActionsDisabled={isLoading}
                        onEditTagClick={onEditTagClick}
                        onDeleteTagClick={onDeleteTagClick} />
                </Layout>
            </div>
        );
    }

});

const TagsTable = GetContainerDimensions()(createReactClass({

    render() {
        const { tags, containerWidth } = this.props;
        const actionsColumnWidth = 300;
        const dataColumnWidth = (containerWidth - actionsColumnWidth) / 2;
        const columns = [{
            label: "Category",
            width: dataColumnWidth
        },{
            label: "Name",
            width: dataColumnWidth
        },{
            label: "Actions",
            sortMapper: () => {},
            width: actionsColumnWidth
        }];
        const rows = tags
            .map(this.tagToRow)
            .toJS();
        return (
            <div style={{ textAlign: "left" }}>
                <SimpleDataTable columns={columns} rows={rows} maxTableHeight={700} />
            </div>
        );
    },

    tagToRow(tag) {
        const { isActionsDisabled, onEditTagClick, onDeleteTagClick } = this.props;
        const id = tag.get("id");
        const buttonStyle = {
            // marginTop : "-5px", // to compensate for the 8px padding that fixed-data-table sets on cells
            paddingTop: 0,
            paddingBottom: 0,
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
            marginRight: "0.5rem",
            marginLeft: "0.5rem",
            height: 25,
            lineHeight: 1
        };
        const category = tagRepo.getCategory(tag.get("categoryId"));
        return [
            `${category.get("name")} :`,
            tag.get("name"),
            <div key="actions" style={{ textAlign: "center" }}>
                <TextButton
                    icon="edit"
                    label="Edit"
                    type="defaultInner"
                    disabled={isActionsDisabled}
                    onClick={() => onEditTagClick(tag)}
                    style={buttonStyle} />
                <TextButton
                    icon="trash-o"
                    label="Delete"
                    type="alertInner"
                    disabled={isActionsDisabled}
                    onClick={() => onDeleteTagClick(id)}
                    style={{ ...buttonStyle}} />
            </div>
        ];
    }

}));
