import React from "react";

import ReactSelect from "js/common/views/inputs/immutable-react-select";
import {betterMemo} from "js/common/utils/more-memo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import TooltipLabel from "js/admin/kpis/edit-kpis/tabs/common/tooltip-label";

const MultiSelect = betterMemo(
    {displayName: "Config"},
    ({placeholder, label, labelTooltip, disabled, options, onChange, selectedValues}) => {
      const {theme} = React.useContext(CustomThemeContext);

      return (
          <div className={`table-${theme.themeId}`}>
            {label && <TooltipLabel label={label} tooltip={labelTooltip}/>}
            <ReactSelect
                theme={theme}
                isMulti={true}
                selectedValues={selectedValues}
                isClearable={true}
                isDisabled={disabled}
                placeholder={placeholder}
                options={options}
                onChange={onChange} />
          </div>
      );
    });

export default MultiSelect;