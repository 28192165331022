/** @jsxImportSource @emotion/react */

import React from "react";
import * as Formatter from "js/common/utils/formatter";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import ReactTooltip from "react-tooltip";

const NoTargetPriorityMetric = React.memo(({dimensions, config, data, onClick, clientIds}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const kpiId = config.get("kpiId");
  const kpi = kpiRepo.get(kpiId);
  const hasClientFilter = clientIds && clientIds.count() > 0;
  const notFilterableByClient = hasClientFilter && !kpi.get("filterableByClient");

  const {width, height} = dimensions;
  const showWideVariant = width > 240 || (width > (height * 1.3) && width > 240);

  let clickable = true;
  if (hasClientFilter && !kpi.get("filterableByClient")) {
    clickable = false;
  }

    return (
        <div
            style={{...containerStyle(notFilterableByClient), flexDirection: "column"}}
            onClick={clickable ? onClick : null}>
          {config.get("iconWhenNoTarget") &&
          <div style={iconStyle}>
            <i className={`bhi-${config.get("iconWhenNoTarget")}`} style={{
              color: theme.palette.primary.main,
              fontSize: 32
            }} />
          </div>
          }
          {renderValue(hasClientFilter, kpi, data, theme)}
        </div>);
});

const renderValue = (hasClientFilter, kpi, data, theme) => {
  const tooltipId = `tooltip-${kpi.get("id")}`;
  const notFilterable = hasClientFilter && !kpi.get("filterableByClient");
  if (notFilterable) {
    return (
        <div>
          <h2 data-for={tooltipId} data-tip="" style={{width: "fit-content", opacity: 0.6}}>N/A</h2>
          <ReactTooltip
              textColor={theme.palette.text.inverted}
              backgroundColor={theme.palette.background.inverted}
              place="top"
              effect="solid"
              id={tooltipId}>
            This metric cannot be filtered by client.
          </ReactTooltip>
        </div>
    )
  } else {
    return <h2 data-test-id="metric-value" style={{fontSize: 28, fontWeight: 600, lineHeight: 1}}>{Formatter.format(data.get("value").get("total"), {maxDisplayLength: 6})}</h2>;
  }
};

const containerStyle = (notFilterableByClient) => ({
  padding: 10,
  height: "100%",
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "row",
  cursor: notFilterableByClient ? "default" : "pointer"
});

const iconStyle = {
  display: "flex",
  justifyContent: "center",
  background: "rgba(0,0,0,0.1)",
  borderRadius: "50%",
  minHeight: 64,
  height: 64,
  width: 64,
  marginBottom: 15,
  flexShrink: 0,
  alignItems: "center"
};

const wideIconStyle = {
  ...iconStyle,
  minHeight: "8em",
  height: "8em",
  width: "8em",
  marginBottom: 0
};

export default NoTargetPriorityMetric;
