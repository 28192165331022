/** @jsxImportSource @emotion/react */
import React from "react";
import {Draggable} from "react-beautiful-dnd";
import {betterMemo} from "js/common/utils/more-memo";

const OrderKpiDraggable = betterMemo({debug: false, displayName: "Row"}, (
    {
      theme,
      index,
      hasChanged,
      kpi
    }) => {

  const statusCss = {display: "none", [`@media(min-width: 1280px)`]: {display: "inline"}};

  let statusEl;

  if (hasChanged) {
    statusEl = (
        <span style={{fontSize: "0.8rem", color: "rgb(64, 234, 64)"}}>
          <i className="fa fa-check" style={{marginLeft: "0.3rem"}} />
          <span css={statusCss}>&nbsp;Ready to Save</span>
        </span>);
  }

  return (
      <Draggable
          isDragDisabled={!kpi.get("visible")}
          draggableId={kpi.get("id").toString()}
          index={index}
          type="ROW">
        {(draggableProvided) =>
            // NOTE inner and outer div so that we don't accidentally drag the placeholder too
            (<div>
              <div
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}>
                <div
                    id={kpi.get("id").toString()}
                    style={{
                      borderBottom: `2px solid ${theme.palette.background.paper}`,
                      margin: "0.25rem",
                      borderRadius: 4,
                      backgroundColor: (kpi.get("visible")
                          ? theme.palette.background.card
                          : theme.palette.background.paper)
                    }}>
                  <div
                      style={{display: "flex", height: 40, lineHeight: "40px", cursor: "pointer"}}>
                    <div style={{flex: "0.3 1 20px"}}>
                      <span
                          css={{
                            padding: "0.25rem",
                            [`@media(min-width: 640px)`]: {padding: "0.5rem"},
                            [`@media(min-width: 1280px)`]: {padding: "1rem"}
                          }}>
                        <i
                            className="fa fa-arrows"
                            onClick={e => e.stopPropagation()}
                            style={{cursor: "move", visibility: kpi.get("visible") ? "visible" : "hidden"}}
                            data-test-id={kpi.get("id").toString() + "-drag"}
                            id={index}
                            {...draggableProvided.dragHandleProps} />
                      </span>
                      #{index + 1}
                    </div>

                    <div style={{flex: 2, fontSize: 14}}>
                      {kpi.get("name")}
                    </div>

                    <div css={{flex: 0.5, [`@media(min-width: 1280px)`]: {flex: 1}}}>
                      {statusEl}
                    </div>
                  </div>
                </div>
              </div>
              {draggableProvided.placeholder}
            </div>)}
      </Draggable>);
});
export default OrderKpiDraggable;
