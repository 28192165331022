import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";

import Drawer from "js/common/views/drawer";
import SavedConfigsMenu from "js/common/views/sharing/saved-configs-menu";
import Dialog from "js/common/views/tabs-dialog";
import ShareReport from "js/common/views/sharing/share-config";
import ClientSetManager from "js/common/views/client-filter/client-set-editor";
import {CLIENT_SET_SIZE_LIMIT} from "js/common/views/client-filter/client-set-editor";
import * as SavedConfigs from "js/common/saved-configs";
import * as Users from "js/common/users";
import * as Popups from "js/common/popups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const ClientSetsList = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    ownershipTypeToSavedClientSets: ImmutablePropTypes.map.isRequired,
    onRequestOpen: ReactPropTypes.func.isRequired,
    onRequestClose: ReactPropTypes.func.isRequired,
    onRefreshClientSets: ReactPropTypes.func.isRequired,
    onAddClientSet: ReactPropTypes.func.isRequired,
    onUpdateClientSet: ReactPropTypes.func.isRequired,
    onRemoveClientSet: ReactPropTypes.func.isRequired,
    isOpen: ReactPropTypes.bool,
    loading: ReactPropTypes.bool,
    clientSet: ImmutablePropTypes.map
  },

  getInitialState() {
    return ({
      currentClientSet: this.props.clientSet,
      clientSetToShare: null,
      shareClientSetError: null,
      shareClientSetSuccess: null,
      editableClientSetSharingSettings: null,
      loadingClientSetSharing: null,
      errorMessage: ""
    });
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.clientSet !== nextProps.clientSet) {
      this.setState({currentClientSet: nextProps.clientSet});
    }
  },

  render() {
    const {
      currentClientSet,
      clientSetToShare,
      shareClientSetError,
      shareClientSetSuccess,
      clientSetSharingSettings,
      editableClientSetSharingSettings,
      loadingClientSetSharing
    } = this.state;
    const {
      ownershipTypeToSavedClientSets,
      onRefreshClientSets,
      loading,
      isOpen
    } = this.props;
    return (
        <div>
          <Drawer
              open={isOpen && !clientSetToShare}
              onRequestOpen={this.handleRequestOpen}
              onRequestClose={this.handleRequestClose}
              width={currentClientSet ? "85%" : 500}>
            <SavedConfigsMenu
                headerLabel="Saved Client Sets"
                ownershipTypeToSavedConfigs={ownershipTypeToSavedClientSets}
                ownershipTypeToCustomDisplayName={Immutable.fromJS({ownedConfigs: "My client sets"})}
                onRefreshConfigsRequest={onRefreshClientSets}
                onAddConfigRequest={this.handleAddClientSetClick}
                isLoading={loading}
                onSavedConfigSelect={this.handleClientSetClick}
                isSavedConfigSharingEnabled={Users.currentHasPermission("SHARE_CLIENT_SET")}
                onShareSavedConfigRequest={this.handleShareClientSetClick}
                onRemoveSavedConfigRequest={(clientSet, ownershipType) => {
                  handleRemoveClientSetClick(clientSet, ownershipType, this.removeClientSet);
                }}
                mainContent={currentClientSet ? this.createClientSetManager() : null} />
          </Drawer>

          {clientSetToShare &&
          <Dialog
              label={
                <span
                    style={{
                      color: this.props.theme.themeId === "light"
                          ? this.props.theme.palette.text.main
                          : this.props.theme.palette.primary.main,
                      textTransform: "uppercase"
                    }}>{`Share ${clientSetToShare.get("name")}`}</span>
              }
              onRequestClose={this.handleCloseShareDialogClick}
              content={
                <ShareReport
                    config={clientSetToShare}
                    isLoading={loadingClientSetSharing}
                    reportSharingSettings={editableClientSetSharingSettings}
                    onReportSharingSettingsChange={this.handleClientSetSharingChange}
                    hasReportSharingSettingsChanged={
                      !Immutable.is(clientSetSharingSettings, editableClientSetSharingSettings)
                    }
                    onCancelChangesRequest={this.handleCancelClientSetSharingClick}
                    onShareReportRequest={this.handleSaveClientSetSharingClick}
                    shareReportError={shareClientSetError}
                    shareReportSuccess={shareClientSetSuccess}
                    onSuccessTimeout={this.handleCloseShareDialogClick} />
              }
              flex={1}
              contentOverflowAutoScroll={false} />}
        </div>
    );
  },

  createClientSetManager() {
    const {ownershipTypeToSavedClientSets} = this.props;
    const {currentClientSet, errorMessage} = this.state;
    const ownClientSets = ownershipTypeToSavedClientSets.get("ownedConfigs");

    return (<ClientSetManager
        key={currentClientSet.get("id")}
        ownClientSets={ownClientSets}
        clientSet={currentClientSet}
        errorMessage={errorMessage}
        onCancelClick={this.handleCancelClick}
        onSaveClick={this.saveClientSet}
        onSaveAsClick={this.handleSaveAsClick}
        onSetError={this.handleSetError} />);
  },

  handleSetError(errorMessage) {
    this.setState({errorMessage: errorMessage});
  },

  handleCancelClick() {
    this.setState({errorMessage: ""});
    if (!this.state.currentClientSet.has("id")) {
      this.setState({currentClientSet: null});
    }
  },

  handleSaveAsClick() {
    this.setState({isSaveAsDialogOpen: true});
  },

  async saveClientSet(changedClientSet) {
    const {onAddClientSet, onUpdateClientSet} = this.props;
    const clientCount = changedClientSet.getIn(["json", "clientIds"]).count();
    if (clientCount > CLIENT_SET_SIZE_LIMIT) {
      this.setState({
        errorMessage: `Too many Clients to save Client Set. Current:${clientCount} Limit:${CLIENT_SET_SIZE_LIMIT}`
      });
      return changedClientSet;
    }
    const otherOwnedNames = this.props.ownershipTypeToSavedClientSets.get("ownedConfigs")
        .filter(clientSet => clientSet.get("id") !== changedClientSet.get("id"))
        .map(clientSet => clientSet.get("name"))
        .toSet();
    if (otherOwnedNames.has(changedClientSet.get("name"))) {
      this.setState({
        nameErrorMessage: "Name already in use"
      });
      return changedClientSet;
    } else {
      this.setState({nameErrorMessage: ""});
    }
    if (changedClientSet.has("id") && changedClientSet.get("ownerId") === Users.getCurrentUser().get("id")) {
      return await onUpdateClientSet(changedClientSet)
          .then(clientSet => {
            this.setState({
              currentClientSet: clientSet,
              errorMessage: ""
            });
            return clientSet;
          });
    } else {
      return await onAddClientSet(changedClientSet)
          .then(clientSet => {
            this.setState({
              currentClientSet: clientSet,
              errorMessage: ""
            });
            return clientSet;
          });
    }
  },


  handleRequestOpen() {
    this.setState({
      currentClientSet: null
    });
    this.props.onRequestOpen();
  },
  
  handleRequestClose() {
    this.setState({
      currentClientSet: null
    });
    this.props.onRequestClose();
  },

  handleCloseShareDialogClick() {
    const initialState = this.getInitialState();
    this.setState({
      clientSetToShare: initialState.clientSetToShare,
      clientSetSharingSettings: initialState.clientSetSharingSettings,
      editableClientSetSharingSettings: initialState.editableClientSetSharingSettings,
      shareClientSetError: initialState.shareClientSetError,
      shareClientSetSuccess: initialState.shareClientSetSuccess
    });
  },

  handleClientSetSharingChange(newClientSetSharingSettings) {
    this.setState({
      editableClientSetSharingSettings: newClientSetSharingSettings
    });
  },

  handleCancelClientSetSharingClick() {
    this.setState((state) => {
      return ({editableClientSetSharingSettings: state.clientSetSharingSettings});
    });
  },

  handleSaveClientSetSharingClick() {
    const {clientSetToShare, editableClientSetSharingSettings} = this.state;
    this.setState({
      loadingClientSetSharing: true
    });
    SavedConfigs
        .updateShareList(clientSetToShare.get("id"), editableClientSetSharingSettings)
        .then(() => {
          this.setState({
            loadingClientSetSharing: false,
            shareClientSetSuccess: "Your changes to the Client Set sharing settings have been saved"
          });
        }, reason => {
          let errorMsg = "Unable to save changes to the Client Set sharing settings";
          const responseJSON = reason.responseJSON;
          if (responseJSON && responseJSON.errors) {
            errorMsg = responseJSON.errors;
          }
          this.setState({
            loadingClientSetSharing: false,
            shareClientSetError: errorMsg
          });
        });
  },

  removeClientSet(clientSet, ownershipType) {
    const {currentClientSet} = this.state;
    if (currentClientSet && currentClientSet.get("id") === clientSet.get("id")) {
      this.setState({currentClientSet: null});
    }
    this.props.onRemoveClientSet(clientSet, ownershipType);
  },

  handleClientSetClick(savedClientSet) {
    this.setState({
      currentClientSet: savedClientSet,
      errorMessage: ""
    });
  },

  handleShareClientSetClick(savedClientSet) {
    this.setState({
      clientSetToShare: savedClientSet,
      loadingClientSetSharing: true
    });
    SavedConfigs
        .getShareList(savedClientSet.get("id"))
        .then(clientSetSharingSettings => {
          this.setState({
            loadingClientSetSharing: false,
            clientSetSharingSettings,
            editableClientSetSharingSettings: clientSetSharingSettings
          });
        }, () => {
          this.setState({
            loadingClientSetSharing: false,
            shareClientSetError: "Unable to load Client Set sharing settings"
          });
        });
  },

  handleAddClientSetClick() {
    const emptyConfig = Immutable.fromJS({
      configType: "CLIENT_SET",
      name: "",
      json: {clientIds: Immutable.Set()}
    });
    this.setState({
      currentClientSet: emptyConfig
    });
  }

});

const handleRemoveClientSetClick = (clientSet, ownershipType, onRemoveClientSet) => {
  Popups.confirm(`Remove '${clientSet.get("name")}' permanently?`, {
    title: "Delete this Client Set?",
    labels: {
      cancel: "No",
      ok: "Yes"
    },
    onok: () => onRemoveClientSet(clientSet, ownershipType)
  });
};

export {
  CLIENT_SET_SIZE_LIMIT
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <ClientSetsList theme={theme} {...props} />;
};