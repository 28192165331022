import React from "react";

import pure from "js/common/views/pure";

export default pure(({ customStyle = {}, customIconStyle = {} }) => (
    <div style={{ ...style, ...customStyle }}>
        <i className="fa fa-spinner fa-pulse fa-3x" style={{ color: "#555", ...customIconStyle }} />
    </div>));

const style = {
    display: "block",
    width: "100%",
    margin: "10px 0px",
    textAlign: "center"
};
