import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';

import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Formatter from "js/common/utils/formatter";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";

import GroupUserPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import Leaderboard from "js/charting/leaderboard/leaderboard";

export default createReactClass({

    propTypes: {
        config: ReactPropTypes.object.isRequired,
        onConfigChange: ReactPropTypes.func.isRequired,
        data: ReactPropTypes.object.isRequired
    },

    childContextTypes: {
        muiTheme: ReactPropTypes.object
    },

    shouldComponentUpdate(nextProps) {
        return nextProps.data !== this.props.data
            || nextProps.config !== this.props.config;
    },

    render() {
        return (
            <div>
                {this.props.config.isConfigVisible && this.getChartConfigElements()}
                <br />
                {this.props.data.isLoading ? <LoadingSpinner /> : this.getLeaderboard()}
            </div>);
    },

    getChartConfigElements() {
        const { kpiId } = this.props.config;
        return (
            <div className="chart-config">
                <KpiPicker
                    kpis={getLeaderboardableKpis()}
                    selectedKpiIds={kpiId ? [kpiId] : []}
                    onChange={kpiIds => this.props.onConfigChange({ kpiId: kpiIds[0] })}
                    disabled={this.props.data.isLoading}
                    multiSelect={false} />
                <div className="margin-top">
                    <GroupUserPicker
                        excludeUsers={true}
                        hierarchy={Groups.getHierarchyWithUsers()}
                        qualifierId={this.props.config.groupId}
                        qualifierType={"GROUP"}
                        onGroupClick={groupId => this.props.onConfigChange({ groupId })}
                        onUserClick={() => {}}
                        disabled={this.props.data.isLoading} />
                </div>
                <div className="margin-top">
                    <TimeframePicker
                        timeframe={this.getTimeframe()}
                        onChange={timeframe => this.props.onConfigChange({ timeframe: timeframe.getRawJson() })}
                        disabled={this.props.data.isLoading} />
                </div>
            </div>);
    },

    getLeaderboard() {
        const currentKpi = kpiRepo.get(this.props.config.kpiId);
        const columns = ["Rank", "Name", currentKpi.get("name")];
        const rows = (this.props.data.users || []).map(({ userId, rank, kpi}) => {
            return {
                id: userId,
                fields: [
                    rank,
                    getUserName(userId),
                    Formatter.format(kpi.total)
                ]
            };
        });
        return <Leaderboard columns={columns} rows={rows} />;
    },

    getTimeframe() {
        return timeframeRepo.parse(this.props.config.timeframe);
    }

});

const getLeaderboardableKpis = () => {
    return kpiRepo.getAll();
};

const getUserName = userId => Users.getUser(userId).get("fullName");

const LoadingSpinner = () => (
    <div className="small-12 medium-6 small-centered column text-center" style={{marginTop: "6em"}}>
        <i className="fa fa-spinner fa-pulse fa-5x" />
    </div>);
