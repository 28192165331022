import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";
import promiseLimit from "promise-limit";
import Cube19 from "js/cube19.app";

import MenuBar from "js/common/views/menu-bar";
import Header from "js/oneview/header/header-container";
import PriorityKpis from "js/oneview/priority-kpi/app";
import KpiSections from "js/oneview/kpi-sections/section-list";
import Ratios from "js/oneview/ratios";
import KpiDetailsDialog from "js/oneview/kpi-details/dialog";
import RatioDetailsDialog from "js/oneview/ratio-details/dialog";
import JobPipelinesApp from "js/job-pipelines/app";
import eventBus from "js/cube19.event-bus";
import currentClient from "js/common/repo/backbone/current-client";
import {reactComponentToMarionetteComponent} from "js/common/views/react-to-marionette";
import {getGlobalVariable, saveAsGlobalVariable} from "js/common/utils/global-variables";
import * as Ajax from "js/common/ajax";
import * as Auditor from "js/common/auditer";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as KpiCalculator from "js/common/kpi-calculator";
import * as Pages from "js/common/pages";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import * as Time from "js/common/utils/time";
import LatestVersionDialog from "js/common/views/latest-version-dialog";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {Paper} from "@mui/material";
import moment from "moment";

const $ = window.$;

const concurrency = 5;
let limit = promiseLimit(concurrency);

let currentLoadId = 0;
let loadIdToData = Immutable.Map();

const staleAfterMillis = 10 * 60 * 1000;

const OneViewPage = createReactClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    const storedState = getStoredState();
    return {
      config: this.props.initialConfig || (storedState ? storedState.get("config") : getDefaultOneViewConfig()),
      pages: Immutable.List(),
      currentPage: null,
      expandedSectionConfigIds: Immutable.Set(),
      priorityKpiIds: null,
      kpiDataById: Immutable.Map(),
      allDataLoaded: false,
      kpiDetailsOptions: null,
      ratioDetailsOptions: null,
      clientFilterConfig: storedState && storedState.get("clientFilterConfig"),
      defaultPageId: null,
      refreshCounter: 0
    };
  },

  componentDidMount() {
    const theme = localStorage.getItem("appTheme");
    $("body")
        .removeClass()
        .addClass("main-app")
        .addClass(`theme-${theme}`);
    this.loadData();
    this.loadPages();
    eventBus.on("kpi:target-added", this.reloadKpiData, this);
    eventBus.on("kpi:targets-changed", this.reloadKpiData, this);
  },

  componentWillUnmount() {
    $("body").removeClass("main-app");
    saveStateAsGlobalVariable(this.state);
    this.cancelPendingPromises();
  },

  render() {
    const client = currentClient;
    const {
      config,
      currentPage,
      expandedSectionConfigIds,
      priorityKpiIds,
      kpiDataById,
      kpiDetailsOptions,
      ratioDetailsOptions,
      pages,
      clientFilterConfig,
      refreshCounter
    } = this.state;

    const isUserOneView = !!config.has("userId");
    const kpiDetailsDialogIsOpen = !!kpiDetailsOptions;
    const ratioDetailsDialogIsOpen = !!ratioDetailsOptions;
    const containerStyle = {
      minHeight: "100%",
      padding: "0 0 80px",
      boxSizing: "border-box",
      position: "relative",
      borderRadius: "0"
    };
    const isQuickViewVisible = client.hasPermission("QUICKVIEW_CT")
        && !isUserOneView
        && Users.currentHasPermission("QUICKVIEW_CT");
    return (
        <>
          <LatestVersionDialog refreshCounter={refreshCounter} />
          <MenuBar
              appView={isUserOneView ? "user-oneview" : "group-oneview"}
              onClick={this.handleMenuBarClick}
              options={{
                initialTimeframe: config.get("timeframe").getRawJson(),
                initialGroupId: config.get("groupId"),
                initialUserId: config.get("userId")
              }} />
          <Paper style={containerStyle}>
            <Header
                appView={isUserOneView ? "user-oneview" : "group-oneview"}
                isDataLoading={!this.state.allDataLoaded}
                onUserNavClick={this.handleMenuBarClick}
                config={config}
                pages={pages}
                currentPageId={currentPage && currentPage.get("id")}
                clientFilterConfig={clientFilterConfig}
                onConfigChange={this.updateConfig}
                onRefreshClick={this.handleRefreshClick}
                onUserClick={this.handleUserClick}
                onGroupClick={this.handleGroupClick}
                onFilterChanges={this.handleFilterChanges}
                onJobPipelinesClick={this.goToJobPipelinesApp}
                defaultPageId={this.state.defaultPageId} />
            <section style={{marginBottom: 10}}>
              <PriorityKpis
                  priorityKpiIds={priorityKpiIds}
                  kpiDataById={kpiDataById}
                  clientIds={config.get("clientIds")}
                  hasAppliedTagFilters={this.hasAppliedTagFilters()}
                  onQuickViewClick={this.handlePriorityKpiQuickViewClick}
                  onPriorityKpiCubeClick={this.handlePriorityKpiClick} />
            </section>
            {currentPage && currentPage.get("sections").count() > 0 &&
            <section className="page-section">
              <KpiSections
                  currentTimeframe={config.get("timeframe")}
                  clientIds={config.get("clientIds")}
                  sectionConfigs={currentPage.get("sections")}
                  expandedSectionConfigIds={expandedSectionConfigIds}
                  kpiDataById={kpiDataById}
                  hasQuickView={isQuickViewVisible}
                  onKpiSummaryClick={this.handleKpiSummaryClick}
                  onQuickViewClick={this.handleKpiSummaryQuickViewClick}
                  onExpandToggleClick={this.handleExpandSectionToggleClick} />
            </section>}
            {currentPage && currentPage.get("ratioAssignments").count() > 0 &&
            <section className="page-section">
              <Ratios
                  ratioAssignments={currentPage.get("ratioAssignments")}
                  kpiDataById={kpiDataById}
                  clientIds={config.get("clientIds")}
                  onRatioClick={this.handleRatioClick}
                  onKpiClick={this.handleRatioKpiClick}
                  currentTimeframe={config.get("timeframe")} />
            </section>}
          </Paper>
          {ratioDetailsDialogIsOpen &&
          <RatioDetailsDialog
              {...ratioDetailsOptions}
              onRequestClose={this.closeRatioDialog}
              onKpiClick={this.handleRatioKpiClick} />}

          {kpiDetailsDialogIsOpen &&
          <KpiDetailsDialog
              {...kpiDetailsOptions}
              matchAnyTagIds={config.get("matchAnyTagIds", Immutable.List())}
              matchAllTagIds={config.get("matchAllTagIds", Immutable.List())}
              excludedTagIds={config.get("excludedTagIds", Immutable.List())}
              clientIds={config.get("clientIds") || Immutable.List()}
              onRequestClose={this.closeKpiDetailsDialog}
              onGroupClick={this.handleGroupClick}
              onUserClick={this.handleUserClick} />}
        </>);
  },

  reloadKpiData(kpiId) {
    this.setState({
      allDataLoaded: false
    });
    currentLoadId++;
    const loadTimestampMillis = Date.now();
    loadIdToData = loadIdToData.set(currentLoadId, Immutable.Map({
      isCancelled: false,
      loadTimestampMillis
    }));

    makeCancellable(loadPriorityKpiIds(this.state.config), currentLoadId)
        .then(
            priorityKpiIds => {
              this.setState({priorityKpiIds});
              const kpisToLoad = Immutable.Set([kpiId]);
              const promisesWithKpiIds = loadKpis(currentLoadId, kpisToLoad, this.state.config);
              makeCancellable(
                  Promise.all(this.updateDataAsEachKpiLoads(currentLoadId, promisesWithKpiIds)),
                  currentLoadId)
                  .then(
                      () => this.onAllDataLoaded(loadTimestampMillis, kpisToLoad, "oneview:reload-kpi"),
                      reason => !reason.isCancelled && this.onAllDataLoaded(loadTimestampMillis, kpisToLoad, "oneview:reload-kpi"));
            },
            reason => !reason.isCancelled && Promise.reject(reason)
        );
  },

  loadData() {
    currentLoadId++;
    const loadTimestampMillis = Date.now();
    loadIdToData = loadIdToData.set(currentLoadId, Immutable.Map({
      isCancelled: false,
      loadTimestampMillis
    }));
    this.setState({
      currentPage: null,
      priorityKpiIds: null,
      kpiDataById: Immutable.Map(),
      allDataLoaded: false
    });

    const promises = [
      loadPriorityKpiIds(this.state.config),
      loadCurrentPage(this.state.config),
      getDefaultOneviewPage(this.state.config)];
    makeCancellable(Promise.all(promises), currentLoadId)
        .then(
            ([priorityKpiIds, currentPage, defaultPage]) => {
              const sectionConfigs = currentPage.get("sections")
                  .sortBy(section => section.get("order"))
                  .filter(section => section.get("visible"))
                  .map(section => section.update(
                      "assignedKpis",
                      assignedKpis => assignedKpis.sortBy(ak => ak.get("order"))));
              this.setState({
                priorityKpiIds,
                currentPage: currentPage.set("sections", sectionConfigs),
                defaultPageId: defaultPage.get("id")
              });
              const sectionKpiIds = getKpiIdsToLoad(sectionConfigs, this.state.expandedSectionConfigIds);
              const ratioKpiIds = currentPage.get("ratioAssignments")
                  .flatMap(c => [c.get("ratio").get("firstKpiId"), c.get("ratio").get("secondKpiId")]);
              const allKpiIdsToLoad = sectionKpiIds
                  .union(priorityKpiIds)
                  .union(ratioKpiIds);
              const promisesWithKpiIds = loadKpis(currentLoadId, allKpiIdsToLoad, this.state.config);
              makeCancellable(
                  Promise.all(this.updateDataAsEachKpiLoads(currentLoadId, promisesWithKpiIds)),
                  currentLoadId)
                  .then(
                      () => this.onAllDataLoaded(loadTimestampMillis, allKpiIdsToLoad, "oneview:loaded"),
                      reason => !reason.isCancelled && this.onAllDataLoaded(loadTimestampMillis, allKpiIdsToLoad, "oneview:loaded"));
            },
            reason => !reason.isCancelled && Promise.reject(reason));
  },

  onAllDataLoaded(startTimestampMillis, loadedKpiIds, auditCategory) {
    if (auditCategory) {
      const endTimeMillis = Date.now();
      Promise.all([
        getAuditConfigDetails(this.state.config, this.state.clientFilterConfig, this.state.currentPage),
        loadServerTimeMillis().then(serverTimeMillis => Date.now() - serverTimeMillis)])
          .then(([details, serverTimeOffset]) => {
            const startServerTimeMillis = startTimestampMillis - serverTimeOffset;
            const endServerTimeMillis = endTimeMillis - serverTimeOffset;
            details.startServerTime = Time.formatDateTime(moment.utc(startServerTimeMillis));
            details.endServerTime = Time.formatDateTime(moment.utc(endServerTimeMillis));
            details.durationMillis = endServerTimeMillis - startServerTimeMillis;
            details.kpiIds = loadedKpiIds.toJS();
            Auditor.audit(auditCategory, details);
          });
    }
    this.setState({allDataLoaded: true});
  },

  updateDataAsEachKpiLoads(loadId, promisesWithKpiIds) {
    return promisesWithKpiIds.map(({promise, kpiId}) => promise
        .then(result => {
          if (!loadIdToData.get(loadId).get("isCancelled")) {
            this.setState({
              kpiDataById: this.state.kpiDataById.set(kpiId, Immutable.Map(result))
            });
          }
        }, reason => {
          if (reason.isCanceled) {
            this.setState({
              kpiDataById: this.state.kpiDataById.set(kpiId, null)
            });
          } else {
            if (!loadIdToData.get(loadId).get("isCancelled")) {
              this.setState({
                kpiDataById: this.state.kpiDataById.set(kpiId, Immutable.Map({error: true}))
              });
            }
          }
        })
    );
  },

  loadPages() {
    if (!Users.currentHasPermission("ONEVIEW_PAGE_SUPER_USER")) {
      return;
    }
    Pages.getAll()
        .then(pages => {
          this.setState({pages: pages});
        });
  },

  handleMenuBarClick(e) {
    const handlers = {
      "prev-user": this.handlePrevUserClick,
      "next-user": this.handleNextUserClick
    };
    const handler = handlers[e];
    if (handler) {
      handler();
    } else {
      throw new Error("Unsupported type: " + e);
    }
  },

  handleRefreshClick() {
    getAuditConfigDetails(this.state.config, this.state.clientFilterConfig, this.state.currentPage)
        .then(details => Auditor.audit("oneview:refresh", details));
    this.cancelPendingPromises();
    this.loadData();
    this.setState((prevState) => ({
      refreshCounter: prevState.refreshCounter + 1
    }));
  },

  handleGroupClick(groupId) {
    this.closeKpiDetailsDialog();

    let newConfig = this.state.config
        .set("groupId", groupId)
        .delete("userId");

    this.updateConfig(newConfig);
  },

  handleUserClick(userId) {
    this.closeKpiDetailsDialog();

    const user = Users.getUser(userId);
    let newConfig = this.state.config
        .set("userId", userId)
        .set("groupId", user.get("groupId"));

    this.updateConfig(newConfig);
  },

  handleFilterChanges(combinedClientIds, clientIds, clientSetIds, matchAnyTagIds, matchAllTagIds, excludedTagIds) {
    const clientFilterConfig = Immutable.Map({clientIds, clientSetIds});
    this.setState({clientFilterConfig});
    this.updateConfig(this.state.config
        .set("clientIds", combinedClientIds)
        .set("matchAnyTagIds", matchAnyTagIds)
        .set("matchAllTagIds", matchAllTagIds)
        .set("excludedTagIds", excludedTagIds));
  },

  hasAppliedTagFilters() {
    const {config} = this.state;
    if (config.get("matchAnyTagIds", Immutable.List()).isEmpty() &&
        config.get("matchAllTagIds", Immutable.List()).isEmpty() &&
        config.get("excludedTagIds", Immutable.List()).isEmpty()) {
      return false;
    } else {
      return true;
    }
  },

  handlePrevUserClick() {
    Auditor.audit("menu_bar:click_previous_user");
    const currentUserInView = Users.getUser(this.state.config.get("userId"));
    const previousUserInView = Users.getPreviousUser(currentUserInView);
    let newConfig = this.state.config
        .set("userId", previousUserInView.get("id"))
        .set("groupId", previousUserInView.get("groupId"));

    this.updateConfig(newConfig);
  },

  handleNextUserClick() {
    Auditor.audit("menu_bar:click_next_user");
    const currentUserInView = Users.getUser(this.state.config.get("userId"));
    const nextUserInView = Users.getNextUser(currentUserInView);
    let newConfig = this.state.config
        .set("userId", nextUserInView.get("id"))
        .set("groupId", nextUserInView.get("groupId"));

    this.updateConfig(newConfig);
  },

  handlePriorityKpiClick(kpiId) {
    this.showKpiDetailsTable("priority", kpiId);
  },

  handlePriorityKpiQuickViewClick(kpiId) {
    this.showKpiQuickView("priority", kpiId);
  },

  handleKpiSummaryClick(kpiId) {
    this.showKpiDetailsTable("summary", kpiId);
  },

  handleKpiSummaryQuickViewClick(kpiId) {
    this.showKpiQuickView("summary", kpiId);
  },

  handleExpandSectionToggleClick(section) {
    const {kpiDataById, config, expandedSectionConfigIds} = this.state;
    const newToggleState = !expandedSectionConfigIds.has(section.get("id"));
    this.setState({
      expandedSectionConfigIds: newToggleState ?
          expandedSectionConfigIds.add(section.get("id")) :
          expandedSectionConfigIds.delete(section.get("id"))
    });
    Auditor.audit(
        newToggleState ? "oneview:expand-section" : "oneview:collapse-section",
        {
          noOfMetrics: section.get("assignedKpis").count(),
          sectionId: section.get("id"),
          timeframe: config.get("timeframe").getRawJson(),
          userId: config.get("userId"),
          groupId: config.get("groupId")
        });

    const kpisToLoad = section.get("assignedKpis")
        .map(kpi => kpi.get("kpiId"))
        .filter(kpiId => !kpiDataById.has(kpiId));
    if (!kpisToLoad.isEmpty()) {
      const millisSinceLastLoad = Date.now() - loadIdToData.get(currentLoadId).get("loadTimestampMillis");
      if (millisSinceLastLoad < staleAfterMillis) {
        const loadTimestampMillis = Date.now();
        this.setState({allDataLoaded: false});
        const promisesWithKpiIds = loadKpis(currentLoadId, kpisToLoad, config);
        makeCancellable(Promise.all(this.updateDataAsEachKpiLoads(currentLoadId, promisesWithKpiIds)), currentLoadId)
            .then(
                () => this.onAllDataLoaded(loadTimestampMillis, kpisToLoad),
                reason => !reason.isCancelled && this.onAllDataLoaded(loadTimestampMillis, kpisToLoad));
      } else {
        Auditor.audit("oneview:stale-section", {
          millisSinceLastLoad,
          sectionId: section.get("id"),
          timeframe: config.get("timeframe").getRawJson(),
          userId: config.get("userId"),
          groupId: config.get("groupId")
        });
        this.cancelPendingPromises();
        this.loadData();
      }
    }
  },

  handleRatioClick(ratio, ratioData) {
    const {config} = this.state;
    const isUserOneView = !!config.has("userId");
    const groupId = isUserOneView ? Users.getUser(config.get("userId")).get("groupId") : config.get("groupId");
    const timeframe = config.get("timeframe");

    Auditor.audit("ratio_ct:open", {
      ratioId: ratio.get("id"),
      ratioName: ratio.get("name"),
      timeframe: timeframe.getRawJson(),
      userId: config.get("userId"),
      groupId
    });


    const ratioDetailsOptions = {
      initialTab: "DETAILS",
      ratio,
      ratioData,
      groupId,
      timeframe,
      clientIds: config.get("clientIds"),
      matchAnyTagIds: config.get("matchAnyTagIds", Immutable.List()),
      matchAllTagIds: config.get("matchAllTagIds", Immutable.List()),
      excludedTagIds: config.get("excludedTagIds", Immutable.List())
    };
    this.setState({
      kpiDetailsOptions: null,
      ratioDetailsOptions
    });
  },

  handleRatioKpiClick(kpiId) {
    this.setState({
      ratioDetailsOptions: null
    }, () => {
      this.showKpiDetailsTable("ratio", kpiId);
    });
  },

  showKpiQuickView(type, kpiId) {
    const {config} = this.state;
    const kpi = KpiRepo.get(kpiId);
    const isUserOneView = !!config.has("userId");
    const groupId = isUserOneView ? Users.getUser(config.get("userId")).get("groupId") : config.get("groupId");
    const timeframe = config.get("timeframe");
    const clientIds = config.get("clientIds");

    Auditor.audit("quick-view:click", {
      type,
      timeframe: timeframe.getRawJson(),
      kpi: kpi.get("name"),
      kpiId,
      groupId
    });

    const kpiDetailsOptions = {
      initialTab: "QUICKVIEW",
      kpiId,
      groupId,
      timeframe,
      clientIds: clientIds && clientIds.toJS()
    };
    this.setState({
      kpiDetailsOptions,
      ratioDetailsOptions: null
    });
  },

  closeKpiDetailsDialog() {
    this.setState({
      kpiDetailsOptions: null
    });
  },

  closeRatioDialog() {
    this.setState({
      ratioDetailsOptions: null
    });
  },

  updateConfig(newConfig) {
    this.cancelPendingPromises();
    this.setState({
      config: newConfig
    }, () => {
      this.loadData();
    });
  },

  goToJobPipelinesApp() {
    Auditor.audit("oneview:job_pipeline_btn_click", {}, {userAgent: true});
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(<JobPipelinesApp />));
  },

  showKpiDetailsTable(type, kpiId) {
    const {config} = this.state;
    const timeframe = config.get("timeframe");
    const clientIds = config.get("clientIds");
    const isUserOneView = !!config.has("userId");
    const groupId = isUserOneView ? Users.getUser(config.get("userId")).get("groupId") : config.get("groupId");

    Auditor.audit("kpi_ct:open", {
      type,
      timeframe: timeframe.getRawJson(),
      kpiId,
      userId: config.get("userId"),
      groupId
    });

    const kpiDetailsOptions = {
      kpiId,
      timeframe,
      clientIds: clientIds && clientIds.toJS(),
      userId: isUserOneView ? config.get("userId") : null,
      groupId: isUserOneView ? null : config.get("groupId")
    };
    this.setState({kpiDetailsOptions});
  },

  cancelPendingPromises() {
    loadIdToData = loadIdToData.setIn([currentLoadId, "isCancelled"], true);
  }
});

const getAuditConfigDetails = async (config, clientFilterConfig, currentPage) => {
  const auditOptions = {
    timeframe: config.get("timeframe").getRawJson(),
    isDefaultConfig: getDefaultOneViewConfig().equals(config)
  };

  const loggedInUser = Users.getCurrentUser();
  if (config.has("userId")) {
    auditOptions.type = "user";
    auditOptions.userId = config.get("userId");
    auditOptions.lookingAtSelf = loggedInUser.get("id") === config.get("userId");
  } else {
    auditOptions.type = "group";
    auditOptions.groupId = config.get("groupId");
    auditOptions.lookingAtSelf = loggedInUser.get("groupId") === config.get("groupId");
  }

  if (clientFilterConfig) {
    const clientIds = clientFilterConfig.get("clientIds");
    const clientSetIds = clientFilterConfig.get("clientSetIds");
    if (clientIds.count() > 0) {
      auditOptions.clientIds = clientIds.toJS();
    }
    if (clientSetIds.count() > 0) {
      auditOptions.clientSetIds = clientSetIds.toJS();
    }
  }

  if (config.get("pageId")) {
    const qualifierDetails = getQualifierDetails(config);
    const defaultPage = qualifierDetails.type === "USER"
        ? await Pages.getPageForUser(qualifierDetails.id)
        : await Pages.getPageForGroup(qualifierDetails.id);

    auditOptions.pageId = config.get("pageId");
    auditOptions.isDefaultOneViewPage = config.get("pageId") === defaultPage.get("id");
    auditOptions.hasSpecifiedOneViewPage = true;
  } else {
    auditOptions.pageId = currentPage.get("id");
    auditOptions.isDefaultOneViewPage = true;
    auditOptions.hasSpecifiedOneViewPage = false;
  }

  return auditOptions;
};

const getDefaultOneViewConfig = () => {
  const loggedInUser = Users.getCurrentUser();
  const loggedInUserState = loggedInUser.get("state");
  let config = {
    timeframe: TimeframeRepo.getDefaultForClient(),
    groupId: loggedInUser.get("groupId")
  };
  switch (loggedInUserState) {
    case "VISIBLE":
      config.userId = loggedInUser.get("id");
      break;
    case "INVISIBLE":
      if (loggedInUser.get("dataVisibility") === "SELF") {
        return;
      }
      break;
    default:
      throw new Error("Unsupported user state:", loggedInUserState);
  }
  return Immutable.fromJS(config);
};

const getDefaultOneviewPage = (config) => {
  const qualifierDetails = getQualifierDetails(config);
  if (qualifierDetails.type === "USER") {
    return Pages.getPageForUser(qualifierDetails.id);
  } else {
    return Pages.getPageForGroup(qualifierDetails.id);
  }
};

const loadCurrentPage = config => {
  if (config.get("pageId")) {
    return Pages.get(config.get("pageId"));
  } else {
    return getDefaultOneviewPage(config);
  }
};

const loadPriorityKpiIds = config => {
  const timeframe = config.get("timeframe");
  const startDate = timeframe.getStart();
  const endDate = timeframe.getEnd();
  const qualifier = getQualifierDetails(config);

  return Ajax
      .get({
        url: "kpi/priorities",
        data: {
          startDate,
          endDate,
          qualifierId: qualifier.id,
          qualifierType: qualifier.type
        }
      })
      .then(priorityKpiIds => Immutable
          .fromJS(priorityKpiIds)
          .filter(kpiId => !!KpiRepo.get(kpiId))
          .sortBy(kpiId => KpiRepo.get(kpiId).get("order")));
};

const getQualifierDetails = config => {
  const isUserOneView = !!config.has("userId");
  return {
    type: isUserOneView ? "USER" : "GROUP",
    id: isUserOneView ? config.get("userId") : config.get("groupId")
  };
};

const getKpiIdsToLoad = (sectionConfigs, expandedSectionConfigIds) => {
  return sectionConfigs
      .filter(s => s.get("visible"))
      .flatMap(s => {
        const assignedKpis = s.get("assignedKpis");
        if (expandedSectionConfigIds.has(s.get("id"))) {
          return assignedKpis;
        } else {
          return assignedKpis.count() > 6 ? assignedKpis.filter(k => k.get("minimisedOrder") !== null) :
              assignedKpis;
        }
      })
      .map(k => k.get("kpiId"))
      .toSet();
};

const makeCancellable = (promise, loadId) => {
  return new Promise((resolve, reject) => {
    promise.then(
        val => loadIdToData.get(loadId).get("isCancelled") ? reject({isCancelled: true}) : resolve(val),
        error => loadIdToData.get(loadId).get("isCancelled") ? reject({isCancelled: true}) : reject(error)
    );
  });
};

const loadKpis = (loadId, kpiIds, config) => {
  const kpiOptions = {
    ...config.toJS(),
    timeframe: config.get("timeframe")
  };
  return kpiIds.map(kpiId => ({
    kpiId: kpiId,
    promise: limit(() => {
      if (loadIdToData.get(loadId).get("isCancelled")) {
        return Promise.reject({isCanceled: true});
      } else {
        return KpiCalculator.summary(kpiId, kpiOptions);
      }
    })
  }));
};

const loadServerTimeMillis = () => Ajax.get({url: "audit/server-time"});

const saveStateAsGlobalVariable = state => {
  const loggedInUser = Users.getCurrentUser();
  const object = {
    loggedInUser: loggedInUser.get("id"),
    config: state.config,
    clientFilterConfig: state.clientFilterConfig
  };
  saveAsGlobalVariable("mainApp", Immutable.Map(object));
};

const getStoredState = () => {
  const mainAppState = getGlobalVariable("mainApp");
  if (!mainAppState) {
    return;
  }

  const storedConfigLoggedInUserId = mainAppState.get("loggedInUser");
  const currentLoggedInUser = Users.getCurrentUser();
  if (currentLoggedInUser.get("dataVisibility") === "SELF") {
    return;
  }
  if (storedConfigLoggedInUserId !== currentLoggedInUser.get("id")) {
    return;
  }

  if (currentLoggedInUser.get("dataVisibility") === "GROUP_AND_BELOW") {
    const configGroupBreadcrumbs = Groups.getGroupBreadcrumbs(mainAppState.get("config").get("groupId"));
    let isInLoggedInUserGroupOrBelow = false;
    configGroupBreadcrumbs.forEach(crumb => {
      if (crumb.id === currentLoggedInUser.get("groupId")) {
        console.log("configGroup is in loggedInUser Group or below", crumb.id);
        isInLoggedInUserGroupOrBelow = true;
      }
    });
    if (!isInLoggedInUserGroupOrBelow) {
      console.log("configGroup is NOT in loggedInUser Group or below");
    }
    return isInLoggedInUserGroupOrBelow ? mainAppState : null;
  }
  return mainAppState;
};

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <OneViewPage theme={theme} {...props} />;
};
