import React from "react";
import createReactClass from "create-react-class";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import getContainerDimensions from "react-dimensions";

import Dialog from "js/common/views/tabs-dialog";
import Tabs from "js/common/views/tabs";
import RatioDetails from "js/oneview/ratio-details/ratio";
import RatioTrend from "js/oneview/ratio-details/trend";

import * as Users from "js/common/users";
import * as Permissions from "js/common/permissions";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Auditor from "js/common/auditer";
import currentClient from "js/common/repo/backbone/current-client";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const indexByTabName = Immutable.Map({
  DETAILS: 0,
  TREND: 1
});
const tabNameByIndex = indexByTabName.mapEntries(([k, v]) => [v, k]);

const RatioDetailsDialog = createReactClass({

  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      initialTab: "DETAILS"
    };
  },

  getInitialState() {
    return {
      showTabIndex: indexByTabName.get(this.props.initialTab)
    };
  },

  render() {
    const {ratio, timeframe, onRequestClose} = this.props;
    const { theme } = this.context;
    const {showTabIndex} = this.state;
    const currentTab = tabNameByIndex.get(showTabIndex);
    const isRatioDetailsTab = currentTab === "DETAILS";
    const dialogMinWidth = 500;
    const isSmallScreen = window.outerWidth < dialogMinWidth;

    if(this.props.renderContentOnly) {
      return <Tabs
          selectedIndex={showTabIndex}
          onChangeTab={this.changeTab}
          tabs={this.getTabs()} />
    } else {
    return (
      <Dialog
            label={
              <div>
                <span style={{textTransform: "uppercase", color: theme.palette.primary.main}}>
                  {ratio.get("name")}
                </span>
                <span style={{textTransform: "uppercase", color: theme.palette.text.disabled}}>
                  {` (${timeframe.get("name")})`}
                </span>
              </div>
            }
            content={
              <Tabs
                  selectedIndex={showTabIndex}
                  onChangeTab={this.changeTab}
                  tabs={this.getTabs()} />
            }
            minWidth={isRatioDetailsTab && !isSmallScreen ? dialogMinWidth : "95vw"}
            height={isRatioDetailsTab ? "auto" : "95vh"}
            onRequestClose={onRequestClose} />
    );
  }},

  getTabs() {
    const tabs = [this.getRatioDetailsTab()];
    if (Users.canAccessApp(Users.getCurrentUser(), Permissions.apps.trends)
        && currentClient.canAccessApp(Permissions.apps.trends)
        && currentClient.hasPermission(Permissions.clientPermissions.canUseAdvancedTrendCharting)) {
      tabs.push(this.getTrendTab());
    }
    return tabs;
  },

  getRatioDetailsTab() {
    const {ratio, ratioData} = this.props;
    return {
      title: <TabTitle icon="balance-scale" text="Ratio Details" />,
      content: <RatioDetails ratio={ratio} ratioData={ratioData} onKpiClick={this.handleKpiClick} />
    };
  },

  handleKpiClick(kpiId) {
    const {groupId, userId, onKpiClick} = this.props;
    onKpiClick(kpiId, groupId, userId);
  },

  getTrendTab() {
    const {groupId, userId, timeframe, ratio, clientIds, matchAnyTagIds, matchAllTagIds, excludedTagIds} = this.props;
    const { theme } = this.context;
    const firstRatioKpi = KpiRepo.get(ratio.get("firstKpiId"));
    const secondRatioKpi = KpiRepo.get(ratio.get("secondKpiId"));
    const isRatioTrendable = firstRatioKpi.get("trendable") && secondRatioKpi.get("trendable");
    const TrendChart =
        <RatioTrend
            ratio={ratio.toJS()}
            qualifierType={userId ? "USER" : "GROUP"}
            qualifierId={userId ? userId : groupId}
            clientIds={clientIds}
            matchAnyTagIds={matchAnyTagIds}
            matchAllTagIds={matchAllTagIds}
            excludedTagIds={excludedTagIds}
            timeframe={timeframe} />;
    return {
      title: <TabTitle icon="line-chart" text="Trend Chart" />,
      content: isRatioTrendable ? <div style={{width: "95vw"}}>{TrendChart}</div> : <NonTrendableRatioMessage palette={theme.palette} />
    };
  },

  changeTab(index) {
    const tab = tabNameByIndex.get(index);
    Auditor.uniqueAudit("ratio_ct:change_tab", {...this.getAuditOptions(), tab});
    this.setState({showTabIndex: index});
  },

  getAuditOptions() {
    return {
      ratioId: this.props.ratio.get("id"),
      userId: this.props.userId,
      groupId: this.props.groupId,
      timeframe: this.props.timeframe.get("id")
    };
  }

});

const TabTitle = ({icon, text}) => <span><i className={`fa fa-${icon}`} /> {text}</span>;

const NonTrendableRatioMessage = ({palette}) => (
    <p style={{margin: "1em", textAlign: "center", color: palette.text.disabled}}>
      <i className="fa fa-ban" />
      <br />
      This Ratio can not be viewed as a Trend Chart
    </p>
);

RatioDetailsDialog.contextType = CustomThemeContext;
export default getContainerDimensions()(RatioDetailsDialog);
