import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import Immutable from "immutable";
import Tooltip from "react-tooltip";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import SlideTimeframePicker from "js/admin/cubetv/slide-timeframe-picker";
import NumberField from "js/common/views/inputs/number-field";
import { Layout, Row, Column } from "js/common/views/foundation-column-layout";
import GroupPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import ImmutableTagFilter from "js/common/views/inputs/tag-picker/immutable-tag-filter";
import LabelledComponent from "js/common/views/labelled-component";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Groups from "js/common/groups";
import currentClient from "js/common/repo/backbone/current-client";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

import { TextField } from '@mui/material';
import Checkbox from "js/common/views/inputs/checkbox";

const EditLeaderboard = createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes:{
        timeframes: ImmutablePropTypes.list.isRequired,
        leaderboard: ReactPropTypes.object.isRequired,
        onChange: ReactPropTypes.func.isRequired
    },

    render() {
        const { leaderboard, timeframes, theme } = this.props;
        const currentTimeframeId = leaderboard.get("timeframeId").toLowerCase();
        const visibleTimeframes = timeframes
            .filter(t => (t.get("visible") && !t.get("isDeleted")) || t.get("id") === currentTimeframeId);

        return (
            <div>
                <Layout allSmall={12} allMedium={6} columnStyle={{ marginTop: "1rem" }}>
                    <LabelledTimeframePicker
                        selectedTimeframeId={currentTimeframeId}
                        timeframes={visibleTimeframes}
                        onChange={timeframeId => this.handleChange({ timeframeId })} />
                    <LabelledGroupPicker
                        hierarchy={Groups.getHierarchyWithUsers()}
                        excludeUsers={true}
                        qualifierType="GROUP"
                        qualifierId={leaderboard.get("groupId")}
                        onGroupClick={groupId => this.handleChange({ groupId })} />
                </Layout>
                {currentClient.canAccessApp("TAGS_ADMIN") && this.renderTagFilters()}
                <Layout allSmall={12} allMedium={6}>
                    <LabelledKpiPicker
                        label="Ranking Metric"
                        multiSelect={false}
                        clearable={false}
                        kpis={getKpis()}
                        onChange={kpiId => this.handleChange({ rankedKpiId: kpiId })}
                        selectedKpiId={leaderboard.get("rankedKpiId")} />
                    <TextField variant="standard"
                        style={{width: 250, marginTop: 15}}
                        label="Heading for Ranking Metric Column"
                        onChange={e => this.handleChange({ rankedKpiColumnHeading: e.target.value.substring(0, 30) })}
                        value={leaderboard.get("rankedKpiColumnHeading")} />
                </Layout>
                <Layout allSmall={12} allMedium={6}>
                    <LabelledKpiPicker
                        label={<span>Other Metric <em>(optional)</em></span>}
                        multiSelect={false}
                        clearable={true}
                        kpis={getKpis()}
                        onChange={kpiId => this.handleChange({ otherKpiId: kpiId })}
                        selectedKpiId={leaderboard.get("otherKpiId")} />
                    <TextField variant="standard"
                        style={{width: 250, marginTop: 15}}
                        label="Heading for Other Metric Column"
                        onChange={e => this.handleChange({ otherKpiColumnHeading: e.target.value.substring(0, 30) })}
                        value={leaderboard.get("otherKpiColumnHeading") || ""} />
                </Layout>
                <Row style={{ marginTop:"1rem" }}>
                    <Column small={12} medium={6}>
                        <Checkbox
                            label="Rank by % of target achieved"
                            style={{ display:"inline-block", width:"250px" }}
                            checked={leaderboard.get("rankByPercentOfTarget")}
                            onCheck={(e, rankByPercentOfTarget) => this.handleChange({ rankByPercentOfTarget })} />
                        <i className="fa fa-info-circle"
                            data-tip data-for="rank-by-percent-of-target"
                            style={{ display:"inline-block", lineHeight:1.5, verticalAlign:"top", paddingLeft:8 }} />
                        <Tooltip id="rank-by-percent-of-target" place="top" type={theme.themeId === "light" ? "dark" : "light"} effect="solid">
                            Ranking by percentage of target achieved will exclude Users without a target for the
                            ranking Metric from the leaderboard.
                        </Tooltip>
                    </Column>
                    <Column small={12} medium={6}>
                        <Checkbox
                            label="Exclude Users with Zero Activity / Billings"
                            checked={leaderboard.get("excludeZeroValues")}
                            onCheck={(e, excludeZeroValues) => this.handleChange({ excludeZeroValues })} />
                    </Column>
                </Row>
                <Layout allSmall={12} allMedium={6} allLarge={3} columnStyle={{ marginTop: "1rem" }}>
                    <NumberField
                        style={{width: 260}}
                        label="Minimum Seconds to Display Each Page (1 - 120)"
                        range={[ 1, 120 ]}
                        value={leaderboard.get("paginationTime") / 1000}
                        onChange={val => this.handleChange({ paginationTime: (val * 1000) })}
                        fullWidth={true} />
                    <NumberField
                        style={{width: 260}}
                        label="No. of Pages (1 - 10)"
                        range={[ 1, 10 ]}
                        value={leaderboard.has("totalUsers") ? leaderboard.get("totalUsers") / 10 : undefined}
                        onChange={val => this.handleChange({ totalUsers: (val * 10) })} />
                    <NumberField
                        style={{width: 260}}
                        label="No. of Users to Highlight (0 - 10)"
                        range={[ 0, 10 ]}
                        value={leaderboard.get("limit")}
                        onChange={limit => this.handleChange({ limit })} />
                    <NumberField
                        style={{width: 260}}
                        label="Seconds to Highlight Each User (3 - 60)"
                        range={[ 3, 60 ]}
                        value={leaderboard.get("cycleTime") / 1000}
                        onChange={val => this.handleChange({ cycleTime: (val * 1000) })} />
                </Layout>
            </div>);
    },

    renderTagFilters() {
        const { leaderboard } = this.props;
        return (
            <Layout allSmall={12} allMedium={4} columnStyle={{ marginTop: "1rem" }}>
                <ImmutableTagFilter
                    label="Match Any Tags"
                    onChange={tagIds => this.handleChange({ matchAnyTagIds: tagIds })}
                    multiple={true}
                    tagIds={leaderboard.get("matchAnyTagIds")} />
                <ImmutableTagFilter
                    label="Match All Tags"
                    onChange={tagIds => this.handleChange({ matchAllTagIds: tagIds })}
                    multiple={true}
                    tagIds={leaderboard.get("matchAllTagIds")} />
                <ImmutableTagFilter
                    label="Exclude Tags"
                    onChange={tagIds => this.handleChange({ excludedTagIds: tagIds })}
                    multiple={true}
                    tagIds={leaderboard.get("excludedTagIds")} />
            </Layout>);
    },

    handleChange(delta) {
        this.props.onChange(this.props.leaderboard.merge(delta));
    }

});

const LabelledGroupPicker = pure(({ ...props }) => (
    <LabelledComponent label="Group" icon="group">
        <GroupPicker {...props} />
    </LabelledComponent>));

const LabelledTimeframePicker = pure(({ ...props }) => (
    <LabelledComponent label="Timeframe" icon="calendar-o">
        <SlideTimeframePicker {...props} />
    </LabelledComponent>));

const LabelledKpiPicker = pure(({ label, ...props }) => (
    <div style={{ marginTop:"15px" }}>
        <label>{label}</label>
        <KpiPicker {...props} />
    </div>));

const getKpis = () => Immutable
    .fromJS(kpiRepo.getAll().toJSON())
    .filter(kpi => kpi.get("visible"));


export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <EditLeaderboard theme={theme} {...props} />;
};