import React from "react";
import PropTypes from "prop-types";
import {FormControlLabel, Radio} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles({
  root: props => ({...props.style}),
  label: {
    fontSize: 14,
  }
});

const radioStyles = makeStyles({
  root: {
    "&&:hover": {
      backgroundColor: "transparent"
    }
  },
});

const RadioButton = React.memo(props => {
  const {value, label} = props;
  const classes = styles(props);
  const radioClasses = radioStyles();
  return (
      <FormControlLabel
          value={value}
          control={<Radio color={"primary"} classes={radioClasses} />}
          label={label}
          classes={classes} />);
});

RadioButton.propTypes = {
  value: PropTypes.any,
  label: PropTypes.string,
  styles: PropTypes.func
};

export default RadioButton;
