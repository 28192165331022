import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';

import Layout from "js/common/views/foundation-column-layout";
import TrendChart from "js/charting/trend/trend-chart";
import ErrorMsg from "js/common/views/error";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Colors from "js/common/cube19-colors";
import * as charting from "js/charting/charting-defaults";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const _ = window._;

const ConfigurableTrendChart = createReactClass({

    propTypes: {
        config: ReactPropTypes.object.isRequired,
        data: ReactPropTypes.object.isRequired
    },

    render() {
        const { config, data, height, width } = this.props;
        return (
            <div>
                <div id={`chart-${config.cid}`} style={{ minHeight: height ? height : "510px", width: width ? width : "100%" }}>
                    {data.isLoading ? <LoadingSpinner /> : this.renderTrendChart()}
                </div>
            </div>);
    },

    renderTrendChart() {
        const { config, data, theme, rangeEnabled, zoomEnabled, exportEnabled, yAxisEnabled } = this.props;
        if (config.isExampleChart) {
            return this.renderExampleHelpChart();
        } else {
            const timeframe = timeframeRepo.parse(config.timeframe);
            return (
                <div>
                    {data.error && <ErrorMsg text={data.error} style={{ marginTop: 0, marginBottom: 0 }} />}
                    <TrendChart
                        height={this.props.height}
                        width={this.props.width}
                        title={config.title}
                        startDate={timeframe.get("start")}
                        endDate={timeframe.get("end")}
                        trends={data.trends}
                        rangeEnabled={rangeEnabled}
                        zoomEnabled={zoomEnabled}
                        exportEnabled={exportEnabled}
                        yAxisEnabled={yAxisEnabled}
                        dataAggregation={config.dataAggregation}
                        isAbsoluteDates={config.dateDisplay === "ABSOLUTE"}
                        chartTheme={theme.themeId === "light" ? "WHITE" : "DEFAULT"}
                        trendType={config.trendType || this.props.trendType}
                        showDataPoints={config.showDataPoints} />
                </div>
            );
        }
    },

    renderExampleHelpChart() {
        const { config, onEditChartConfigClick, onSavedPagesMenuClick, theme } = this.props;
        const timeframe = timeframeRepo.parse(config.timeframe);
        const {
            trendType,
            title,
            dataAggregation,
            dateDisplay,
            chartTheme
        } = config;

        return (
            <div style={{ position: "relative" }}>
                <div style={{ position: "absolute", width: "100%", textAlign: "center", top: "2em" }}>
                    <ExampleChartInfoMessages
                        onEditChartConfigClick={ onEditChartConfigClick }
                        onSavedPagesMenuClick={ onSavedPagesMenuClick } />
                </div>
                <div style={{ opacity: 0.4, pointerEvents: "none" }}>
                    <TrendChart
                        title={title}
                        startDate={timeframe.get("start")}
                        endDate={timeframe.get("end")}
                        trends={charting.getExampleTrendData()}
                        dataAggregation={dataAggregation}
                        isAbsoluteDates={dateDisplay === "ABSOLUTE"}
                        chartTheme={chartTheme}
                        trendType={trendType} />
                </div>
            </div>);
    },

    getMaxDataGroupTimeframe() {
        const dataGroups = this.props.config.dataGroups;
        const timeframeDifferences = dataGroups.map(dataGroup => {
            const timeframe = timeframeRepo.parse(dataGroup.timeframe);
            return timeframe.get("end").diff(timeframe.get("start"));
        });
        const maxDifference = _(timeframeDifferences).max();
        const maxDataGroupTimeframe = dataGroups[_(timeframeDifferences).indexOf(maxDifference)].timeframe;
        return timeframeRepo.parse(maxDataGroupTimeframe);
    }

});

const LoadingSpinner = () => (
    <div className="small-12 medium-6 small-centered column text-center"
        style={{marginTop:"6rem", marginBottom:"3rem"}}>
        <i className="fa fa-spinner fa-pulse fa-5x" style={{color:"#C5C5C5"}} />
    </div>);

const ExampleChartInfoMessages = ({
    onEditChartConfigClick,
    onSavedPagesMenuClick
}) => (
    <div style={{ lineHeight: 2, marginTop: "1rem" }}>
        <Layout allSmall={12} allMedium={5} mediumCentered={true} rowStyle={{ marginBottom: "2rem" }}>
            <div>
                <i className="fa fa-cogs" style={{ marginRight:"10px", fontSize:"2rem" }}
                    onClick={ onEditChartConfigClick } />
                <br/>
                <span>{`To get started, click on 'Edit Trend Chart' at the top right, above the chart.`}</span>
            </div>
        </Layout>
        <Layout allSmall={12} allMedium={5} mediumCentered={true} >
            <div>
                <div onClick={ onSavedPagesMenuClick }>
                    <i className="fa fa-list" style={{ paddingRight: 8 }} />
                    <span style={{color: Colors.chartingColor, textTransform: "uppercase"}}> Saved Trends</span>
                </div>
                <span>{`To access previous Trends, click on 'Saved Trends' in the bottom left of the screen.`}</span>
            </div>
        </Layout>
    </div>);


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <ConfigurableTrendChart theme={theme} {...props} />;
};

ConfigurableTrendChart.displayName = "ConfigurableTrendChart";
export default Wrapper;
