import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import ReactPropTypes from "prop-types";
import * as Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";

import LoadingSpinner from "js/common/views/loading-spinner";
import Select from "js/common/views/inputs/immutable-react-select";
import AdminHeader from "js/admin/common/admin-header";
import Error from "js/common/views/error";
import {IconButton, TextButton} from "js/common/views/inputs/buttons";
import {Column, Row} from "js/common/views/foundation-column-layout";
import Tooltip from "js/common/views/tooltips";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as KeyKpis from "js/admin/kpis/key-kpis/key-kpis";
import * as Auditor from "js/common/auditer";
import * as Popups from "js/common/popups";
import * as Users from "js/common/users";
import * as Branding from "js/common/branding-constants";

import {LinearProgress} from "@mui/material";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const noSelectionReasons = Immutable.fromJS([
  {
    label: "We don't track this, and don't want to",
    value: "DONT_WANT"
  },
  {
    label: "We don't track this, but would like to",
    value: "WOULD_LIKE"
  },
  {
    label: "It's complicated (it depends on team, situation, etc)",
    value: "COMPLICATED"
  },
  {
    label: "The Metric I use isn't in the list",
    value: "KPI_NOT_IN_LIST"
  },
  {
    label: "I need help - please contact me",
    value: "NEED_HELP"
  }
]);

const KpiApp = createReactClass({

  displayName: "KeyKpis",

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      loadingKeyKpis: true,
      displayWizard: true,
      keyKpiList: Immutable.List()
    };
  },

  componentDidMount() {
    KeyKpis.getAll()
        .then(filterClientVisibleKeyKpis)
        .then(sortKeyKpis)
        .then(keyKpiList => {
          this.setState({
            keyKpiList,
            loadingKeyKpis: false
          });
          Auditor.audit("master-metrics-admin:loaded");
        }, () => {
          this.setState({
            loadingKeyKpis: false,
            loadError: "Unable to load Master Metrics"
          });
        });
  },

  render() {
    const {loadingKeyKpis, loadError, displayWizard, keyKpiList} = this.state;
    if (loadingKeyKpis) {
      return (
          <div><br /><LoadingSpinner /><br /></div>
      );
    }
    const incompleteKeyKpis = keyKpiList
        .filter(keyKpi => !keyKpi.get("selectedKpiId") && !keyKpi.get("noSelectionReason"));
    if (incompleteKeyKpis.count() > 0) {
      return (
          <div>
            <div
                style={{
                  textAlign: "right",
                  alignItems: "center",
                  paddingLeft: "0.25rem",
                  paddingRight: "0.25rem"
                }}>
              <Tooltip text={`${displayWizard ? "Close" : "Show"} Wizard`} position="left">
                <IconButton
                    icon={displayWizard ? "times" : "chevron-down"}
                    style={{
                      fontSize: "1.4rem",
                      padding: "0.5rem"
                    }}
                    onClick={this.toggleDisplayWizard}
                    size="large" />
              </Tooltip>
            </div>
            <VelocityTransitionGroup component="div" enter="slideDown" leave="slideUp">
              {displayWizard &&
                  <KeyKpiWizard
                      theme={this.props.theme}
                      incompleteKeyKpis={incompleteKeyKpis}
                      totalKeyKpiCount={keyKpiList.count()}
                      onKpiSelect={this.handleKpiSelect}
                      onReasonSelect={this.handleReasonSelect} />}
            </VelocityTransitionGroup>
            {!displayWizard &&
                <div>
                  <AdminHeader>Master Metric List</AdminHeader>
                  <KeyKpiTable
                      theme={this.props.theme}
                      keyKpiList={keyKpiList}
                      onKpiSelect={this.handleKpiSelect}
                      onReasonSelect={this.handleReasonSelect}
                  />
                </div>}
          </div>
      );
    }
    return (
        <div>
          {loadError && <Error text={loadError} />}
          <AdminHeader>Master Metric List</AdminHeader>
          <div style={{margin: "1.5rem", marginTop: "1rem"}}>{keyKpisDescription}</div>
          <KeyKpiTable
              theme={this.props.theme}
              keyKpiList={keyKpiList}
              onKpiSelect={this.handleKpiSelect}
              onReasonSelect={this.handleReasonSelect}
          />
        </div>);
  },

  toggleDisplayWizard() {
    const newState = !this.state.displayWizard;
    Auditor.audit(newState ? "key-metric:wizard-show" : "key-metric:wizard-hide");
    this.setState(state => ({
      displayWizard: !state.displayWizard
    }));
  },

  handleKpiSelect(keyKpiId, selectedKpiId) {
    if (selectedKpiId === -1) {
      this.setState(state => {
        const keyKpiList = state.keyKpiList;
        const index = keyKpiList.findIndex(keyKpi => keyKpi.get("id") === keyKpiId);
        return {
          keyKpiList: keyKpiList.update(
              index,
              keyKpi => keyKpi.set("noneSelected", true)
          )
        };
      });
    } else {
      this.setState(state => {
        const keyKpiList = state.keyKpiList;
        const index = keyKpiList.findIndex(keyKpi => keyKpi.get("id") === keyKpiId);
        return {
          keyKpiList: keyKpiList.update(
              index,
              keyKpi => keyKpi.set("isLoading", true)
          )
        };
      });

      const selection = {selectedKpiId};
      KeyKpis.update(keyKpiId, selection)
          .then(() => {
            this.setState(state => {
              const keyKpiList = state.keyKpiList;
              const index = keyKpiList.findIndex(keyKpi => keyKpi.get("id") === keyKpiId);
              return {
                keyKpiList: keyKpiList.update(
                    index,
                    keyKpi => keyKpi.set("selectedKpiId", selectedKpiId)
                        .delete("noneSelected")
                        .delete("noSelectionReason")
                        .delete("isLoading")
                )
              };
            });
          }, () => {
            Popups.contactSupport();
            this.setState(state => {
              const keyKpiList = state.keyKpiList;
              const index = keyKpiList.findIndex(keyKpi => keyKpi.get("id") === keyKpiId);
              return {
                keyKpiList: keyKpiList.update(
                    index,
                    keyKpi => keyKpi.delete("isLoading")
                )
              };
            });
          });
    }
  },

  handleReasonSelect(keyKpiId, noSelectionReason) {
    this.setState(state => {
      const keyKpiList = state.keyKpiList;
      const index = keyKpiList.findIndex(keyKpi => keyKpi.get("id") === keyKpiId);
      return {
        keyKpiList: keyKpiList.update(
            index,
            keyKpi => keyKpi.set("isLoading", true)
        )
      };
    });

    const selection = {noSelectionReason};
    KeyKpis.update(keyKpiId, selection)
        .then(() => {
          this.setState(state => {
            const keyKpiList = state.keyKpiList;
            const index = keyKpiList.findIndex(keyKpi => keyKpi.get("id") === keyKpiId);
            return {
              keyKpiList: keyKpiList.update(
                  index,
                  keyKpi => keyKpi.set("noSelectionReason", noSelectionReason)
                      .delete("selectedKpiId")
                      .delete("isLoading")
              )
            };
          });
        }, () => {
          Popups.contactSupport();
          this.setState(state => {
            const keyKpiList = state.keyKpiList;
            const index = keyKpiList.findIndex(keyKpi => keyKpi.get("id") === keyKpiId);
            return {
              keyKpiList: keyKpiList.update(
                  index,
                  keyKpi => keyKpi.delete("isLoading")
              )
            };
          });
        });

  }

});

const KeyKpiWizard = createReactClass({

  displayName: "KeyKpiWizard",

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      selectedValue: null
    };
  },

  propTypes: {
    incompleteKeyKpis: ImmutablePropTypes.list.isRequired,
    totalKeyKpiCount: ReactPropTypes.number.isRequired,
    onKpiSelect: ReactPropTypes.func.isRequired,
    onReasonSelect: ReactPropTypes.func.isRequired
  },

  componentDidUpdate(prevProps) {
    if (this.props.incompleteKeyKpis.first().get("id") !== prevProps.incompleteKeyKpis.first().get("id")) {
      this.setState({
        selectedValue: null
      });
    }
  },

  render() {
    const {incompleteKeyKpis, totalKeyKpiCount, theme} = this.props;
    const incompleteKeyKpiCount = incompleteKeyKpis.count();
    const keyKpi = incompleteKeyKpis.first();
    const availableKpiOptions = getAvailableKpiOptions(keyKpi);
    const selectedValue = this.state.selectedValue || applyRegex(availableKpiOptions, keyKpi.get("regex"));
    const availableOptions = availableKpiOptions
        .push(Immutable.Map({
          label: "",
          value: "",
          disabled: true
        }))
        .concat(noSelectionReasons);
    const nextEnabled = !!selectedValue;
    const wizardStyle = theme => ({
      listStyle: "none",
      borderRadius: 5,
      backgroundColor: theme.palette.background[theme.themeId === "light" ? "paper" : "card"],
      border: "1px solid rgba(255, 255, 255, 0.21)",
      marginLeft: "0.625rem",
      marginRight: "0.625rem",
      marginBottom: "1.25rem",
      padding: "0.625rem"
    });
    return (
        <div style={wizardStyle(theme)}>
          <div className="text-box" style={{padding: "1rem", marginBottom: "1.25rem"}}>
            <h3
                style={{
                  fontWeight: 300,
                  color: theme.palette.primary.main,
                  textTransform: "uppercase",
                  marginBottom: 10
                }}>We need to know a little more about how you use the Metrics in {Branding.brandingName}.</h3>
            {keyKpisDescription}
          </div>

          <div>
            <Row style={rowStyle}>
              <Column small={2} style={columnStyle}>
                <span style={columnSpanStyle}>{"Master Metric Name:"}</span>
              </Column>
              <Column small={4} style={columnStyle}>
                <span style={columnSpanStyle}>{keyKpi.get("name")}</span>
              </Column>
              <Column small={2} style={columnStyle}>
                <span style={columnSpanStyle}>{"Your Metric:"}</span>
              </Column>
              <Column small={4} style={{...columnStyle, paddingTop: 3, lineHeight: 1.35}}>
                <Select
                    key={keyKpi.get("id")}
                    isMulti={false}
                    isClearable={false}
                    isSearchable={true}
                    placeholder={"Select a Metric"}
                    selectedValue={selectedValue}
                    options={availableOptions}
                    onChange={value => this.setState({selectedValue: value})} />
              </Column>
            </Row>
            <Row style={rowStyle}>
              <Column small={2} style={columnStyle}>
                <span style={columnSpanStyle}>{"Description:"}</span>
              </Column>
              <Column small={4} style={columnStyle}>
                {keyKpi.get("description")
                    .split("\n")
                    .map((line, index) => <p key={index} style={columnParStyle}>{line}</p>)}
              </Column>
              <Column small={6} style={columnStyle}>
              </Column>
            </Row>
          </div>
          <div style={buttonDivStyle}>
            <span style={{color: "#999", marginTop: "1rem"}}>{`${incompleteKeyKpiCount} remaining`}</span>
            <TextButton
                type="primary"
                label="Next"
                onClick={this.onNextClicked}
                disabled={!nextEnabled}
            />
          </div>
          <LinearProgress
              variant={"determinate"}
              value={100 * (1 - incompleteKeyKpiCount / totalKeyKpiCount)} />
        </div>
    );
  },

  onNextClicked() {
    const {incompleteKeyKpis, onKpiSelect, onReasonSelect} = this.props;
    const {selectedValue} = this.state;
    const keyKpi = incompleteKeyKpis.first();
    const keyKpiId = keyKpi.get("id");
    if (!selectedValue) {
      const matchedKpi = applyRegex(getAvailableKpiOptions(keyKpi), keyKpi.get("regex"));
      if (matchedKpi) {
        const payload = {
          master_metric_id: keyKpiId,
          kpi_id: matchedKpi,
          fromRegex: true
        };
        Auditor.audit("key-metric:wizard-next", payload);
        onKpiSelect(keyKpiId, matchedKpi);
      }
    } else {
      if (typeof selectedValue === "string") {
        const payload = {
          master_metric_id: keyKpiId,
          no_selection_reason: selectedValue
        };
        Auditor.audit("key-metric:wizard-next", payload);
        onReasonSelect(keyKpiId, selectedValue);
      } else {
        const payload = {
          master_metric_id: keyKpiId,
          kpi_id: selectedValue,
          fromRegex: false
        };
        Auditor.audit("key-metric:wizard-next", payload);
        onKpiSelect(keyKpiId, selectedValue);
      }
    }
  }
});

const KeyKpiTable = createReactClass({

  displayName: "KeyKpiTable",

  mixins: [PureRenderMixin],

  propTypes: {
    keyKpiList: ImmutablePropTypes.list.isRequired,
    onKpiSelect: ReactPropTypes.func.isRequired,
    onReasonSelect: ReactPropTypes.func.isRequired
  },

  render() {
    const isCube19User = Users.isCube19User(Users.getCurrentUser());
    const {theme} = this.props;
    return (
        <div
            style={{
              marginTop: "0.5rem"
            }}>
          <Row style={headerRowStyle(theme)}>
            <Column small={2}>
              Name
            </Column>
            <Column small={isCube19User ? 3 : 4}>
              Description
            </Column>
            <Column small={isCube19User ? 2 : 3}>
              Metric
            </Column>
            <Column small={3}>
              Reason
            </Column>
            {isCube19User &&
                <Column small={2}>
                  Category
                </Column>}
          </Row>
          <div>
            {this.props.keyKpiList.map(this.keyKpiToRow)}
          </div>
        </div>);
  },

  keyKpiToRow(keyKpi) {
    const id = keyKpi.get("id");

    const isCube19User = Users.isCube19User(Users.getCurrentUser());
    const reasonEnabled = keyKpi.get("noSelectionReason") || keyKpi.get("noneSelected");
    const selectedKpiId = reasonEnabled ? -1 : keyKpi.get("selectedKpiId");
    const availableKpiOptions = getAvailableKpiOptions(keyKpi, selectedKpiId)
        .unshift(Immutable.Map({
          label: "None",
          value: -1
        }));
    return (
        <Row key={id} style={rowStyle}>
          <Column small={2} style={columnStyle}>
            <span style={columnSpanStyle}>{keyKpi.get("name")}</span>
          </Column>
          <Column small={isCube19User ? 3 : 4} style={columnStyle}>
            <div style={{display: "table-cell", paddingTop: 3}}>
              {keyKpi.get("description")
                  .split("\n")
                  .map((line, index) => <p key={index} style={columnParStyle}>{line}</p>)}
            </div>
          </Column>
          <Column small={isCube19User ? 2 : 3} style={{...columnStyle, paddingTop: 3, lineHeight: 1.35}}>
            <Select
                isMulti={false}
                isClearable={false}
                isSearchable={true}
                placeholder={"Select a Metric"}
                selectedValue={selectedKpiId}
                options={availableKpiOptions}
                disabled={keyKpi.get("isLoading")}
                onChange={kpiId => this.props.onKpiSelect(id, kpiId)} />
          </Column>
          <Column small={3} style={{...columnStyle, paddingTop: 3, lineHeight: 1.35}}>
            {reasonEnabled && <Select
                isMulti={false}
                isClearable={false}
                isSearchable={true}
                placeholder={"Select a reason"}
                selectedValue={keyKpi.get("noSelectionReason")}
                options={noSelectionReasons}
                disabled={keyKpi.get("isLoading")}
                onChange={reason => this.props.onReasonSelect(id, reason)} />}
          </Column>
          {isCube19User &&
              <Column small={2} style={{...columnStyle, paddingTop: 3}}>
                {keyKpi.get("category")}
              </Column>}
        </Row>);
  }
});

const getAvailableKpiOptions = (keyKpi, selectedKpiId) => Immutable.fromJS(KpiRepo.getAll().toJSON())
    .filter(kpi => !kpi.get("deleted") || kpi.get("id") === selectedKpiId)
    .filter(kpi => {
      const kpiTemplate = kpi.get("type");
      return kpi.get("id") === selectedKpiId ||
          (keyKpi.get("validQueryTypes").contains(kpiTemplate.get("query"))
              && keyKpi.get("valueFormat") === kpiTemplate.get("valueFormat")
              && !keyKpi.get("invalidSummaryActions").contains(kpi.getIn(["params", "summary", "action"])));
    })
    .map(kpi => (Immutable.Map({
      label: kpi.get("name"),
      value: kpi.get("id")
    })));

const applyRegex = (kpiOptions, regexString) => {
  const regex = new RegExp(regexString);
  const matches = kpiOptions.filter(option => regex.test(option.get("label").toLowerCase()));
  if (matches.count() === 1) {
    return matches.first().get("value");
  }
};

const filterClientVisibleKeyKpis = keyKpiList => Users.isCube19User(Users.getCurrentUser()) ?
    keyKpiList :
    keyKpiList.filter(keyKpi => keyKpi.get("isCustomerVisible"));

const sortKeyKpis = keyKpiList => {
  const sorted = keyKpiList.sortBy(kpi => kpi.get("ordering")).sortBy(kpi => kpi.get("category"));
  const coreKpis = sorted.filter(kpi => kpi.get("category") === "CORE");
  const otherKpis = sorted.filter(kpi => kpi.get("category") !== "CORE");
  const finalSortedKpiList = coreKpis.concat(otherKpis);
  return (finalSortedKpiList);
};

const keyKpisDescription =
    <p style={{fontSize: "0.875rem"}}>
      We need you to select what you consider to be the most relevant Metric based on the descriptions provided.
      As there are many different ways of measuring and filtering your data, when {Branding.brandingName} delivers
      standardised
      reports and new features into your account, 'Master Metrics' will underpin these releases and allow us to
      deliver them in the most efficient way possible.
      Your Master Metrics can be amended but will not affect reports that have already been provided to you
      by {Branding.brandingName}.
      However, you can manually amend these reports if required. {Branding.brandingName}' Customer Success team can help
      to guide you through defining your Master Metrics.
    </p>;

const headerRowStyle = theme => ({
  fontSize: ".7em",
  fontWeight: "bold",
  paddingTop: 0,
  paddingLeft: "8px",
  paddingRight: "8px",
  height: "20px",
  paddingBottom: "0.5rem",
  borderBottom: `2px solid ${theme.palette.primary.main}`
});

const rowStyle = {
  fontSize: "0.875rem",
  lineHeight: "40px",
  margin: 2
};

const columnStyle = {
  paddingLeft: "0.9375em",
  paddingRight: "0.9375em",
  height: 40,
  display: "table"
};

const columnSpanStyle = {
  display: "table-cell",
  verticalAlign: "middle",
  lineHeight: 1.35,
  paddingTop: 3
};

const columnParStyle = {
  verticalAlign: "middle",
  lineHeight: 1.35,
  fontSize: "0.875rem"
};

const buttonDivStyle = {
  display: "flex",
  justifyContent: "space-between",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  marginLeft: "0.25rem",
  marginRight: "0.25rem"
};


export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <KpiApp theme={theme} {...props} />;
};
