import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";

import LoadingSpinner from "js/common/views/loading-spinner";
import Targets from "js/oneview/targets/targets-container";
import ErrorMsg from "js/common/views/error";
import {parseDate} from "js/common/utils/time";
import * as Ajax from "js/common/ajax";

const path = window.path;

export default createReactClass({
  displayName: "TargetsCT",
  mixins: [PureRenderMixin],

  propTypes: {
    kpiId: ReactPropTypes.number.isRequired,
    timeframe: ReactPropTypes.object.isRequired,
    qualifierType: ReactPropTypes.string.isRequired,
    qualifierId: ReactPropTypes.number.isRequired
  },

  getInitialState() {
    return {
      isLoading: false,
      loadTargetsError: null,
      targets: Immutable.List()
    };
  },

  componentDidMount() {
    this.loadTargets();
  },

  render() {
    const {isLoading, loadTargetsError} = this.state;
    if (isLoading) {
      return this.renderLoadingSpinner();
    } else if (loadTargetsError) {
      return <ErrorMsg text={loadTargetsError} />;
    } else {
      return this.renderTargets();
    }
  },

  renderLoadingSpinner() {
    return (
        <div style={{margin: "3rem"}}>
          <LoadingSpinner iconSize={3} label="Loading Targets" />
        </div>);
  },

  renderTargets() {
    const {
      kpiId,
      timeframe,
      qualifierType,
      qualifierId
    } = this.props;
    return (
        <Targets
            kpiId={kpiId}
            timeframe={timeframe}
            qualifierType={qualifierType}
            qualifierId={qualifierId}
            initialTargets={this.state.targets}
            reloadTargets={this.loadTargets}
            onNewTargetNoteAdded={this.onNewTargetNoteAdded} />
    );
  },

  loadTargets() {
    const {kpiId, qualifierType, qualifierId} = this.props;
    this.setState({
      isLoading: true
    }, () => {
      loadTargets(kpiId, {qualifierType, qualifierId})
          .then(targets => {
            this.setState({
              isLoading: false,
              targets
            });
          })
          .catch(() => {
            this.setState({
              isLoading: false,
              loadTargetsError: "Unable to load targets"
            });
          });
    });
  },

  onNewTargetNoteAdded(target) {
    this.setState(state => {
      const index = state.targets.findIndex(t => t.get("id") === target.get("id"));
      return {
        targets: state.targets.set(index, target)
      };
    });
  }

});

const loadTargets = (kpiId, params) => Ajax
    .get({
      url: path("kpi", kpiId, "qualifierTargets"),
      data: params
    })
    .then(results => Immutable
        .fromJS(results)
        .map(parseTargetDates)
        .filterNot(t => t.get("deleted"))
        .sort(sortByDescStartDate));

const sortByDescStartDate = (targetA, targetB) => {
  const dateA = targetA.get("targetStart");
  const dateB = targetB.get("targetStart");
  if (dateA > dateB) {
    return -1;
  }
  if (dateA < dateB) {
    return 1;
  }
  return 0;
};

const parseTargetDates = target => target
    .update("targetStart", str => parseDate(str))
    .update("targetEnd", str => parseDate(str));
