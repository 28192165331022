import React, {useEffect, useState} from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const CustomColumnHeader = (props) => {
  const [ascSort, setAscSort] = useState("inactive");
  const [descSort, setDescSort] = useState("inactive");
  const [noSort, setNoSort] = useState("inactive");

  const {theme} = React.useContext(CustomThemeContext)

  const onSortChanged = () => {
    setAscSort(props.column.isSortAscending() ? "active" : "inactive");
    setDescSort(props.column.isSortDescending() ? "active" : "inactive");
    setNoSort(!props.column.isSortAscending() && !props.column.isSortDescending()
        ? "active"
        : "inactive");
  };

  const onSortRequested = () => {
    props.progressSort();
  };

  useEffect(() => {
    props.column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
  }, []);

  let sort = null;
  const overlayHeight = theme.themeId === "dark" ? 9 : 8;
  if (props.enableSorting) {
    sort = (
        <div key={`header-${props.displayName}`} style={{display: "flex", alignSelf: "flex-end", cursor: "pointer"}}>
          <div onClick={(event) => onSortRequested()}>
            <div style={{position: "relative"}}>
              <i className="bhi-sortable" />
              <div
                  style={{
                    background: theme.palette.background.card,
                    opacity: 0.8,
                    position: "absolute",
                    top: ascSort === "active" ? "auto" : 0,
                    bottom: ascSort === "active" ? 0 : "auto",
                    height: noSort === "active" ? 20 : overlayHeight,
                    width: 11,
                    pointerEvents: "none",
                    cursor: "pointer"
                  }}></div>
            </div>
          </div>
        </div>
    );
  }

  return <div style={{display: "flex", width: "100%", alignItems: "center"}}>
    <i className="bhi-move" style={{cursor: "move", marginRight: 5}} />
    <div style={{
      paddingTop: 2,
      flexGrow: 1,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontWeight: 500,
      fontSize: 12,
    }}>
      {props.displayName}
    </div>
    {sort}
  </div>;
};

export default CustomColumnHeader;