import * as Immutable from "immutable";

import Cube19 from "js/cube19.app";
import * as Ajax from "js/common/ajax";
import * as Users from "js/common/users";
import eventBus from "js/cube19.event-bus";
import {maybeBackboneToImmutable} from "js/common/utils/backbone-migration";

const path = window.path;

const getGroup = groupId => Cube19.request("group", groupId);
const getGroupOrCurrent = groupId => {
  if (groupId === 0) {
    groupId = Users.getCurrentUser().get("groupId");
  }
  return getGroup(groupId);
};
const getRootGroup = () => Cube19.request("group:root");
const getGroups = () => Cube19.request("groups");
const getChildGroups = groupId => Cube19.request("group:child-groups", groupId);

const getGroupBreadcrumbs = groupId => Cube19.request("hierarchy:breadcrumbs", groupId);
const getGroupBreadcrumbsStr = (groupId, delimiter) => Cube19.request("hierarchy:breadcrumbs-str", groupId, delimiter);
const getHierarchy = () => Cube19.request("hierarchy");
const getHierarchyWithUsers = (showInvisibleUsers = false) => Cube19.request(
    "hierarchy:groupUserPicker",
    showInvisibleUsers);

const createGroup = (group, options) => Cube19.request("groups").create(group, options);

const deleteGroup = (deletingGroupId, newGroupId, groupEndDate) => {
  const requestParams = `?newGroupId=${newGroupId}&groupEndDate=${groupEndDate.format("YYYY-MM-DD")}`;
  return Ajax.del({url: path("group", deletingGroupId, requestParams)})
      .then(group => {
        getGroup(deletingGroupId).set(group);
        eventBus.trigger("hierarchy:group-updated", Immutable.fromJS(group));
        Cube19.request("users:hierarchy-changed");
      });
};

const loadUsersInGroup = groupId => Ajax
    .get({url: path("group", groupId, "user-ids")})
    .then(userIds => Immutable
        .fromJS(userIds)
        .map(userId => Users.getUserOrCurrent(userId))
        .filter(u => u != null)
        .map(user => maybeBackboneToImmutable(user))
    );

const loadActiveUsersInGroup = groupId => loadUsersInGroup(groupId)
    .then(users => users.filter(user => Users.isActive(user)));

export {
  getGroup,
  getGroupOrCurrent,
  getRootGroup,
  getGroups,
  getChildGroups,
  getGroupBreadcrumbs,
  getGroupBreadcrumbsStr,
  getHierarchy,
  getHierarchyWithUsers,
  createGroup,
  deleteGroup,
  loadActiveUsersInGroup,
  loadUsersInGroup
};