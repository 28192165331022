import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import Immutable from "immutable";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import pure from "js/common/views/pure";

import Layout from "js/common/views/foundation-column-layout";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import GroupPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import ImmutableTagFilter from "js/common/views/inputs/tag-picker/immutable-tag-filter";
import SlideTimeframePicker from "js/admin/cubetv/slide-timeframe-picker";
import LabelledComponent from "js/common/views/labelled-component";
import ConfigContainer from "js/admin/cubetv/leaderboard/config-container";
import { TextButton } from "js/common/views/inputs/buttons";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Groups from "js/common/groups";
import currentClient from "js/common/repo/backbone/current-client";
import getDefaultLeaderboardSlide from "js/admin/cubetv/leaderboard/default-leaderboard-slide";


import { TextField } from '@mui/material';
import Checkbox from "js/common/views/inputs/checkbox";

const pathToCompanyOverviewKpis = ["layout", "right", 1, "kpis"];

export default createReactClass({

    mixins: [PureRenderMixin],

    propTypes: {
        timeframes: ImmutablePropTypes.list.isRequired,
        companyOverview: ReactPropTypes.object.isRequired,
        onChange: ReactPropTypes.func.isRequired
    },

    render() {
        const isEnabled = !this.props.companyOverview.get("kpis").isEmpty();
        return (
            <div>
                <Checkbox
                    style={{ paddingTop: "1rem", paddingLeft: "1rem", paddingRight: "1rem" }}
                    label="Show Metrics for a Group after Top Performers"
                    checked={isEnabled}
                    onCheck={(e, isChecked) => this.handleShowGroupOverviewMetricsToggle(isChecked)} />
                {isEnabled && this.renderEditArea()}
            </div>);
    },

    renderEditArea() {
        const { companyOverview, onChange, timeframes } = this.props;
        const configKpis = companyOverview.get("kpis");
        return (
            <div>
                <Layout allSmall={6}>
                    <TextField variant="standard" style={{marginTop: 15}}
                        label="Title"
                        onChange={e => onChange(companyOverview.set("title", e.target.value.substring(0, 22)))}
                        value={companyOverview.get("title")} />
                </Layout>
                <Layout allSmall={6} columnStyle={{ marginTop: "1rem" }}>
                    <LabelledGroupPicker
                        label="Group"
                        icon="group"
                        excludeUsers={true}
                        hierarchy={Groups.getHierarchyWithUsers()}
                        qualifierId={companyOverview.get("groupId")}
                        qualifierType={"GROUP"}
                        onGroupClick={groupId => onChange(companyOverview.set("groupId", groupId))} />
                </Layout>
                {currentClient.canAccessApp("TAGS_ADMIN") && this.renderTagFilters()}
                <Layout allSmall={12} rowStyle={{ marginTop: "1rem" }}>
                    {configKpis.map((config, index) => (
                        <EditKpi
                            key={index}
                            kpis={getKpis()}
                            timeframes={timeframes}
                            label={`Metric ${index + 1}`}
                            config={config}
                            onChange={config => this.handleKpiChange(index, config)}
                            onRemoveKpiRequest={() => this.handleRemoveKpiRequest(index)} />
                    ))}
                </Layout>
                <Layout allSmall={12} smallCentered={true} columnStyle={{ marginTop: "1rem", marginBottom: "1rem" }}>
                    <TextButton
                        icon="plus"
                        label="Add Another Metric (max 2)"
                        disabled={configKpis.count() === 2}
                        onClick={this.handleAddKpiRequest} />
                </Layout>
            </div>);
    },

    renderTagFilters() {
        const { companyOverview, onChange } = this.props;
        return (
            <Layout allSmall={12} allMedium={4} columnStyle={{ marginTop: "1rem" }}>
                <ImmutableTagFilter
                    label="Match Any Tags"
                    onChange={tagIds => onChange(companyOverview.set("matchAnyTagIds", Immutable.List(tagIds)))}
                    multiple={true}
                    tagIds={companyOverview.get("matchAnyTagIds")} />
                <ImmutableTagFilter
                    label="Match All Tags"
                    onChange={tagIds => onChange(companyOverview.set("matchAllTagIds", Immutable.List(tagIds)))}
                    multiple={true}
                    tagIds={companyOverview.get("matchAllTagIds")} />
                <ImmutableTagFilter
                    label="Exclude Tags"
                    onChange={tagIds => onChange(companyOverview.set("excludedTagIds", Immutable.List(tagIds)))}
                    multiple={true}
                    tagIds={companyOverview.get("excludedTagIds")} />
            </Layout>);
    },

    handleKpiChange(index, config) {
        this.props.onChange(this.props.companyOverview.setIn([ "kpis", index ], config));
    },

    handleAddKpiRequest() {
        const { companyOverview, onChange } = this.props;
        const defaultCompanyOverviewKpi = getDefaultLeaderboardSlide().getIn(pathToCompanyOverviewKpis).first();
        onChange(companyOverview.set("kpis", companyOverview.get("kpis").push(defaultCompanyOverviewKpi)));
    },

    handleRemoveKpiRequest(index) {
        const kpis = this.props.companyOverview.get("kpis");
        this.props.onChange(this.props.companyOverview.set("kpis", kpis.delete(index)));
    },

    handleShowGroupOverviewMetricsToggle(isEnabled) {
        if (isEnabled) {
            const defaultCompanyOverviewKpis = getDefaultLeaderboardSlide().getIn(pathToCompanyOverviewKpis);
            this.props.onChange(this.props.companyOverview.set("kpis", defaultCompanyOverviewKpis));
        } else {
            this.props.onChange(this.props.companyOverview.set("kpis", Immutable.List()));
        }
    }

});

const EditKpi = pure(({
    timeframes,
    kpis,
    label,
    config,
    onChange,
    onRemoveKpiRequest
}) => {
    const currentTimeframeId = config.get("timeframeId").toLowerCase();
    const visibleTimeframes = timeframes
        .filter(t => (t.get("visible") && !t.get("isDeleted")) || t.get("id") === currentTimeframeId);
    const visibleKpis = kpis.filter(k => k.get("visible") || k.get("id") === config.get("kpiId"));
    return (
        <ConfigContainer label={label} onRemoveClick={onRemoveKpiRequest}>
            <Layout allSmall={12}>
                <TextField variant="standard" style={{marginTop: 15}}
                    label="Display Name"
                    value={config.get("displayName")}
                    onChange={e => onChange(config.set("displayName", e.target.value.substring(0, 39)))} />
            </Layout>
            <Layout allSmall={6} columnStyle={{ marginTop: "1rem" }}>
                <LabelledKpiPicker
                    label="Metric"
                    multiSelect={false}
                    clearable={false}
                    kpis={visibleKpis}
                    selectedKpiId={config.get("kpiId")}
                    onChange={kpiId => {
                        const newConfig = config
                            .update(c => updateName(c, kpiId, c.get("timeframeId")))
                            .set("kpiId", kpiId);
                        onChange(newConfig);
                    }} />
                <LabelledTimeframePicker
                    selectedTimeframeId={config.get("timeframeId").toLowerCase()}
                    timeframes={visibleTimeframes}
                    onChange={timeframeId => {
                        const newConfig = config
                            .update(c => updateName(c, c.get("kpiId"), timeframeId))
                            .set("timeframeId", timeframeId);
                        onChange(newConfig);
                    }} />
            </Layout>
        </ConfigContainer>
    );
});

const updateName = (config, newKpiId, newTimeframeId) => {
    return config.update("displayName", name => {
        const oldKpi = kpiRepo.get(config.get("kpiId"));
        const oldTimeframe = timeframeRepo.get(config.get("timeframeId"));
        const isDefaultName = name === createName(oldKpi, oldTimeframe);
        const newKpi = kpiRepo.get(newKpiId);
        const newTimeframe = timeframeRepo.get(newTimeframeId);
        return isDefaultName ? createName(newKpi, newTimeframe) : name;
    });
};
const createName = (kpi, timeframe) => `${kpi.get("name")} ${timeframe.get("shortName") || timeframe.get("name")}`;

const LabelledKpiPicker = pure(({ label, ...props }) => (
    <div>
        <label>{label}</label>
        <KpiPicker {...props} />
    </div>));

const LabelledGroupPicker = pure(({ ...props }) => (
    <LabelledComponent label="Group" icon="group">
        <GroupPicker {...props} />
    </LabelledComponent>));

const LabelledTimeframePicker = pure(({ ...props }) => (
    <LabelledComponent label="Timeframe" icon="calendar-o">
        <SlideTimeframePicker {...props} />
    </LabelledComponent>));

const getKpis = () => Immutable.fromJS(kpiRepo.getAll().toJSON());
