import React from "react";

import { reactComponentToMarionetteComponent } from "js/common/views/react-to-marionette";
import LeaderboardSlide from "js/cubetv/cubes/leaderboard-slide";

import Cube19 from "js/cube19.app";

const JST = window.JST;


Cube19.module("Views.CubeTv.Layouts", function(Layouts, App, Backbone, Marionette, $, _) {
    
    Layouts.LeftBigRightSmall = Marionette.LayoutView.extend({

        template: JST["cubetv/layouts/fullscreen"],
        className: "fullscreen",

        regions: {
            slideRegion: ".slide-region"
        },

        initialize() {
            this.listenTo(this.model.leaderboardModel, "change", this.update);
        },

        onShow() {
            this.hasBeenShown = true;
            this.renderLeaderboard();
        },

        update() {
            if (!this.hasBeenShown) {
                return;
            }

            this.renderLeaderboard();
        },

        renderLeaderboard() {
            const leaderboardSlideProps = this.model.leaderboardModel.toJSON();
            const leaderboardSlide = reactComponentToMarionetteComponent(
                <LeaderboardSlide { ...leaderboardSlideProps } />
            );
            this.slideRegion.show(leaderboardSlide);
        }

    });

});
