import Immutable from "immutable";

import * as ajax from "js/common/ajax";

export const MAX_NAME_LENGTH = 64;
const path = window.path;
const baseUrl = "sections";

const getAll = () =>
    ajax.get({url: baseUrl})
        .then(x => Immutable.fromJS(x));

const create = section =>
    ajax.post({
        url: baseUrl,
        json: section.toJS()
    });

const update = section =>
    ajax.put({
        url: path(baseUrl, section.get("id")),
        json: section.toJS()
    });

const del = sectionId =>
    ajax.del({url: path(baseUrl, sectionId)});

export {
    getAll,
    create,
    update,
    del
};