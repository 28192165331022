import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ReactDOM from "react-dom";
import ImmutablePropTypes from "react-immutable-proptypes";
import PureRenderMixin from "react-addons-pure-render-mixin";
import GetContainerDimensions from "react-dimensions";
import getCube19Theme from "js/common/themes/dark-theme";

import Leaderboard, {columnHeadersByDataValue} from "js/oneview/leaderboards/leaderboard";
import GroupPicker from "js/common/views/inputs/group-and-user-picker/dropdown-user-group-picker";
import Checkbox from "js/common/views/inputs/checkbox";
import {Layout} from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import {isMobile} from "js/common/ua-parser";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import * as Numbers from "js/common/utils/numbers";

const $ = window.$;

const LeaderboardContainer = GetContainerDimensions()(createReactClass({

  mixins: [PureRenderMixin],

  childContextTypes: {
    muiTheme: ReactPropTypes.object
  },

  getChildContext() {
    return {
      muiTheme: getCube19Theme()
    };
  },

  propTypes: {
    leaderboard: ImmutablePropTypes.list.isRequired,
    onSortChange: ReactPropTypes.func.isRequired,
    sortField: ReactPropTypes.string.isRequired,
    sortDescending: ReactPropTypes.bool.isRequired,
    filterZero: ReactPropTypes.bool.isRequired,
    onFilterToggle: ReactPropTypes.func.isRequired,
    onGroupChange: ReactPropTypes.func.isRequired,
    onUserClick: ReactPropTypes.func.isRequired,
    onDownloadClick: ReactPropTypes.func.isRequired,
    groupId: ReactPropTypes.number,
    userId: ReactPropTypes.number,
    hideGroupPicker: ReactPropTypes.bool
  },

  getInitialState() {
    return {
      filterZero: false
    };
  },

  componentDidMount() {
    const $this = $(ReactDOM.findDOMNode(this));
    const $leaderboard = $this.find(".leaderboard-container");
    const $leaderboardRows = $this.find("#leaderboard-rows");
    if ($leaderboardRows.length > 0 && $leaderboardRows[0].scrollHeight > $leaderboard.innerHeight()) {
      $this.find(".leaderboard-header").css("paddingRight", 20);
    }

    if (this.props.userId === null) {
      return;
    }
    this.scrollToUser();
  },

  render() {
    const {
      leaderboard,
      onSortChange,
      sortField,
      sortDescending,
      groupId,
      userId,
      filterZero,
      onFilterToggle,
      onGroupChange,
      onUserClick,
      containerWidth,
      containerHeight,
      hideGroupPicker
    } = this.props;

    const screenBreakpoint = 800;
    const isSmallScreen = containerWidth < screenBreakpoint;
    const isLandscapeMode = containerWidth > containerHeight;

    const isRootGroup = groupId === Groups.getRootGroup().get("id");
    const warning = isRootGroup ? "" : `
            This leaderboard shows ONLY the activity completed for '${Groups.getGroup(groupId).get("name")}' and all Groups below it.
            If a User has completed activity for other Groups, it will not be counted.`;

    return (
        <div style={{overflow: isSmallScreen ? "auto" : null}}>
          {isSmallScreen &&
              <Layout allSmall={12} columnStyle={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
                <TextButton
                    type="default"
                    id="download-leaderboard"
                    label="Download"
                    onClick={this.handleDownloadClick} />
                <div>
                  <Checkbox
                      style={{
                        display: "inline-block",
                        width: "auto",
                        verticalAlign: isLandscapeMode ? "top" : "bottom",
                        marginTop: isLandscapeMode ? -4 : 0,
                        marginRight: isLandscapeMode ? "2rem" : 0
                      }}
                      iconStyle={{marginRight: 8}}
                      labelStyle={{width: "auto"}}
                      label="Filter Zero Value Users"
                      checked={filterZero}
                      onCheck={onFilterToggle} />
                  {!hideGroupPicker && <div
                      style={{
                        display: isLandscapeMode ? "inline-block" : "block",
                        marginTop: isLandscapeMode ? 0 : "0.5rem",
                        width: containerWidth < 365 ? containerWidth : 365
                      }}>
                    <GroupPicker
                        onGroupClick={onGroupChange}
                        excludeUsers={true}
                        qualifierType="GROUP"
                        qualifierId={groupId} />
                  </div>}
                </div>
              </Layout>}

          <div style={{width: screenBreakpoint, margin: "0 auto"}}>
            {!isSmallScreen &&
                <Layout small={[3, 4, 5]} rowStyle={{paddingTop: "0.5rem", paddingBottom: "0.5rem"}}>
                  <TextButton
                      type="default"
                      id="download-leaderboard"
                      label="Download"
                      onClick={this.handleDownloadClick} />
                  <Checkbox
                      style={{
                        display: "inline-block",
                        height: 36,
                        verticalAlign: "top",
                        marginTop: -4,
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                        float: "right"
                      }}
                      iconStyle={{marginRight: 8}}
                      labelStyle={{width: "auto"}}
                      label="Filter Zero Value Users"
                      checked={filterZero}
                      onCheck={onFilterToggle} />
                  {!hideGroupPicker &&
                      <GroupPicker
                          onGroupClick={onGroupChange}
                          excludeUsers={true}
                          qualifierType="GROUP"
                          qualifierId={groupId} />
                  }
                </Layout>}
            <Leaderboard
                leaderboard={leaderboard}
                groupWarning={warning}
                onSortChange={onSortChange}
                sortField={sortField}
                sortDescending={sortDescending}
                containerHeight={containerHeight}
                containerWidth={containerWidth}
                userId={userId}
                onUserClick={onUserClick} />
          </div>
        </div>
    );
  },

  scrollToUser() {
    const $this = $(ReactDOM.findDOMNode(this));
    const $leaderboard = $this.find("#leaderboard-rows");
    const $user = $this.find("#selectedUser");
    if ($user.length) {
      const offset = $user.offset().top;
      const leaderboardOffset = $leaderboard.offset().top;
      const isUserOutsideOfView = offset >= ($leaderboard.innerHeight());
      const screenPosition = isMobile() ? 25 : ($leaderboard.innerHeight() / 2);
      if (isUserOutsideOfView) {
        $leaderboard.scrollTop(offset - leaderboardOffset - screenPosition);
      }
    }
  },

  handleDownloadClick() {
    const {leaderboard, onDownloadClick} = this.props;
    const columns = [
      columnHeadersByDataValue.get("rank"),
      columnHeadersByDataValue.get("userId"),
      columnHeadersByDataValue.get("totalValueDisplay"),
      columnHeadersByDataValue.get("targetValueDisplay"),
      columnHeadersByDataValue.get("percentOfTarget")
    ];
    const rows = leaderboard
        .map(row => {
          const hasTarget = !!row.get("targetValueDisplay");
          return [
            row.get("rank"),
            Users.getUser(row.get("userId")).get("fullName"),
            row.get("totalValueDisplay"),
            row.get("targetValueDisplay"),
            hasTarget ? Numbers.toPercentageStr(row.get("percentOfTarget")) : ""
          ];
        });
    onDownloadClick(columns, rows);
  }

}));


export default LeaderboardContainer;
