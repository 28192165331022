import React from "react";
import createReactClass from "create-react-class";

export default createReactClass({

    render() {
        return (
            <div className="row" style={this.props.style}>
                <div className="small-12 columns text-center">
                    {this.renderChildren()}
                </div>
            </div>);
    },

    renderChildren() {
        return React.Children.map(this.props.children, this.cloneWithMargin);
    },

    cloneWithMargin(child, index) {
        return React.cloneElement(child, {
            style: {
                marginLeft: "0.5rem",
                marginRight: "0.5rem",
                ...child.props.style
            }
        });
    }

});