import React from "react";

import {reactComponentToMarionetteComponent} from "js/common/views/react-to-marionette";
import FreeText from "js/cubetv/cubes/free-text";
import DealFlash from "js/cubetv/cubes/deal-flash-slide";
import EmbeddedVideo from "js/cubetv/cubes/embedded-video-slide";
import Iframe from "js/cubetv/cubes/iframe";

import Cube19 from "js/cube19.app";

const JST = window.JST;

Cube19.module("Views.CubeTv.Layouts", function(Layouts, App, Backbone, Marionette, $, _) {

  Layouts.Iframe = Layouts.EmbeddedVideo = Layouts.Fullscreen = Marionette.LayoutView.extend({
    template: JST["cubetv/layouts/fullscreen"],
    className: "cubetv",

    regions: {
      slideRegion: ".slide-region"
    },

    onShow() {
      this.showSlide();
    },

    showSlide() {
      const visIdToView = {
        FreeText,
        DealFlash,
        EmbeddedVideo,
        Iframe
      };
      const cubeModel = this.model.getCubeModelForRegion("fullscreen");
      const visId = cubeModel.get("visualizationId");
      const SlideView = visIdToView[visId];
      const props = cubeModel.toJSON();
      this.slideRegion.show(reactComponentToMarionetteComponent(
          <SlideView {...props} isFullHeight={this.model.get("isFullHeight")} channelId={this.model.get("channelId")} />
      ));
    }

  });

});
