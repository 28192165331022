import React from "react";

import {betterMemo} from "js/common/utils/more-memo";
import Select from "react-select";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import Immutable from "immutable";
import TooltipLabel from "js/admin/kpis/edit-kpis/tabs/common/tooltip-label";
const Group = ({...props}) => {
  const {children, label, data} = props;
  const [expanded, setExpanded] = React.useState(data.defaultExpanded);
  const {theme} = React.useContext(CustomThemeContext);

  return (
      <div key={`group-${label.replace(" ", "-")}`}>
        <div>
          <div
              style={{
                display: "flex",
                padding: "5px 10px",
                fontSize: 13,
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                fontWeight: 500,
                fontFamily: theme.typography.fontFamilyBold,
                cursor: "pointer"
              }}
              onClick={() => !data.isSearching && setExpanded(!expanded)}>
            {label}
            {!data.isSearching && <i className={`bhi-${expanded ? "sort-asc" : "sort-desc"}`} />}
          </div>
        </div>

        <div style={{display: expanded || data.isSearching ? "block" : "none"}}>{children}</div>
      </div>
  );
};

const EntityColumnPicker = betterMemo(
    {displayName: "EntityColumnPicker"},
    ({
      columns,
      onColumnSelect,
      entityColumnId,
      joinPath,
      label,
      labelTooltip,
      disabled,
      placeholder
    }) => {
      const {theme} = React.useContext(CustomThemeContext);
      const [isSearching, setIsSearching] = React.useState(false);

      const styles = {
        placeholder: (provided, state) => ({
          ...provided,
          fontSize: 14,
          color: state.isDisabled ? theme.palette.text.disabled : theme.palette.text.primary,
        }),
        input: (provided, state) => ({
          ...provided,
          color: theme.palette.text.main,
          fontFamily: `${theme.typography.fontFamily} !important`,
          input: {
            fontFamily: `${theme.typography.fontFamily} !important`,
            color: theme.palette.text.main,
          }
        }),
        control: (provided, state) => ({
          ...provided,
          backgroundColor: state.isDisabled ? theme.palette.action.hover : theme.palette.background.card,
          border: `2px solid ${theme.palette.background.paper}`,
          boxShadow: "none",
          minHeight: 0,
          height: 42,
          cursor: "pointer",
          fontSize: 14,
          ":hover": {
            backgroundColor: theme.palette.action.hover,
            border: `2px solid ${theme.palette.background.paper}`,
          }
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? theme.palette.text.disabled : theme.palette.text.primary,
          ":hover": state.isDisabled ? theme.palette.text.disabled : theme.palette.text.primary
        }),
        indicatorSeparator: () => ({display: "none"}),
        menu: (provided) => ({
          ...provided,
          backgroundColor: `${theme.palette.background.card}`,
          zIndex: 9999,
          marginTop: 0,
          overflow: "auto"
        }),
        option: (provided, state) => ({
          ...provided,
          color: theme.palette.text.main,
          fontSize: 13,
          backgroundColor: state.isFocused
              ? theme.palette.background.paper
              : theme.palette.background.card,
        }),
        singleValue: (provided, state) => ({
          ...provided,
          fontSize: 13,
          color: `${state.isDisabled
              ? theme.palette.text.disabled
              : theme.palette.text.primary} !important`,
          padding: "3px 0"
        }),
      }

      const groupedOptions = React.useMemo(() => {
        const groupOptions = [];
        const generateRecursiveGroup = (columns, currentDepth, isSearching) => {
          const childGroups = columns
              .filter(c => c.get("readableJoinPath").count() > currentDepth)
              .groupBy(c => c.get("readableJoinPath").get(currentDepth))
          childGroups
              .entrySeq()
              .sortBy(([entity]) => entity)
              .forEach(([entity, columns], i) => {
                const columnsAtCurrentDepth = columns.filter(g => g.get("readableJoinPath").count() === currentDepth + 1)
                if (columnsAtCurrentDepth.count() > 0) {
                  const firstGroup = currentDepth === 0;
                  const label = firstGroup ? entity : columnsAtCurrentDepth.first().get("readableJoinPath").slice(1).join(' > ');
                  const child = {
                    label,
                    defaultExpanded: firstGroup,
                    isSearching: isSearching,
                    options: columnsAtCurrentDepth
                        .sortBy(c => c.get("label"))
                        .map(column => (
                              {
                                colId: column.get("entityColumnId"),
                                uniqueId: `${column.get("joinPathStr")}:${column.get("entityColumnId")}`,
                                joinPath: column.get("joinPathStr"),
                                label: column.get("label"),
                              }
                          )).toJS()
                  };
                  groupOptions.push(child);
                }
                generateRecursiveGroup(columns, currentDepth + 1, isSearching)
              })
        };
        columns && generateRecursiveGroup(columns, 0, isSearching)
        return groupOptions;
      }, [columns, isSearching])

      const destructJoinPath = (path) => path?.map(p => {
          if(p.get("contextFromParent") && p.get("contextFromParent") !== "NONE") {
            return `${p.get("tableEntity")}+${p.get("contextFromParent")}`
          } else {
            return p.get("tableEntity")
          }
        }).join()

      const constructJoinPath = (joinPath) => {
        const newPath = joinPath.split(",").map(tableEntity => {
          const context = tableEntity.split("+")[1];
          if(context) {
            return({
              "tableEntity": tableEntity,
              "contextFromParent": context
            })
          } else {
            return({
              "tableEntity": tableEntity
            })
          }
        });
        return Immutable.fromJS(newPath)
      }

      const selectedOption = React.useMemo(() => {
        if (joinPath && entityColumnId) {
          const path = destructJoinPath(joinPath);
          return groupedOptions.map(g => g.options).flat().find(o => o.uniqueId === `${path}:${entityColumnId}`)
        } else {
          return null;
        }
      }, [groupedOptions, joinPath, entityColumnId])

      // NOTE: The below function replaces the default react-select search behaviour
      // we need this because react-select does not have built in behaviour to search by group name/label
      const filterOption = ({label, data: { joinPath }}, string) => {
        // default search behaviour from react-select
        label = label.toLocaleLowerCase();
        string = string.toLocaleLowerCase();
        if (label.includes(string) || joinPath.includes(string)) return true;

        // check if a group as the filter string as label
        const groupOptions = groupedOptions.filter(group =>
            group.label.toLocaleLowerCase().includes(string)
        );

        if (groupOptions) {
          for (const groupOption of groupOptions) {
            const option = groupOption.options.find(opt => opt.joinPath === joinPath);
            if (option) {
              return true;
            }
          }
        }
        return false;
      };


      return <>
        {label && <TooltipLabel label={label} tooltip={labelTooltip}/>}
        <Select
          options={groupedOptions}
          searchable
          onInputChange={x => setIsSearching( x.length > 0)}
          blurInputOnSelect={false}
          closeMenuOnSelect={true}
          filterOption={filterOption}
          components={{Group}}
          styles={styles}
          value={selectedOption || ""}
          onChange={({colId, joinPath}) => onColumnSelect(colId, constructJoinPath(joinPath))}
          isDisabled={disabled}
          placeholder={placeholder}
      />
        </>;
    });

export default EntityColumnPicker;
