import React from "react";
import * as Immutable from "immutable";

import pure from "js/common/views/pure";
import currentClient from "js/common/repo/backbone/current-client";
import {reactComponentToMarionetteComponent} from "js/common/views/react-to-marionette";
import * as Colors from "js/common/cube19-colors";

import Cube19 from "js/cube19.app";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const JST = window.JST;

Cube19.module("CubeTv", function(CubeTv, App, Backbone, Marionette, $, _) {

  CubeTv.PageView = Marionette.LayoutView.extend({
    template: JST["cubetv/page"],
    className: "cubetv-page",

    regions: {
      headerRegion: "#cubetv-page-header",
      contentRegion: "#cubetv-page-content"
    },

    onShow() {
      const layoutModel = this.model
          .get("layout")
          .set("isFullHeight", !this.model.get("showNewsticker"))
          .set("channelId", this.model.get("channelId"));
      const layoutModelId = layoutModel.get("id");
      const layoutsWithPageTitleDisplayable = [
        "LeftBigRightSmall",
        "LeftBigRight4",
        "Iframe"
      ];
      const layoutsWithHiddenEmptyTitle = [
        "Iframe"
      ];
      const pageTitle = Immutable.fromJS(this.model.get("pageTitle"));
      if (pageTitle) {
        const isEmptyTitleAndHidden = pageTitle.get("title") === "" &&
            layoutsWithHiddenEmptyTitle.includes(layoutModelId);
        const isPageTitleDisplayed = layoutsWithPageTitleDisplayable.includes(layoutModelId) && !isEmptyTitleAndHidden;
        if (isPageTitleDisplayed && !pageTitle.isEmpty()) {
          this.headerRegion.show(
              reactComponentToMarionetteComponent(<PageTitle pageTitle={pageTitle} />)
          );
        }
      }
      const LayoutViewClass = Cube19.Views.CubeTv.Layouts[layoutModelId];
      const layoutView = new LayoutViewClass({model: layoutModel});
      this.contentRegion.show(layoutView);
    }

  });

});

const PageTitle = React.memo(({pageTitle}) => {
  const { theme } = React.useContext(CustomThemeContext);

  let titlesList = Immutable
      .List()
      .push(pageTitle.get("title"));
  if (pageTitle.get("subTitle")) {
    titlesList = titlesList.push(pageTitle.get("subTitle"));
  }
  if (pageTitle.get("timeframeId")) {
    titlesList = titlesList.push(pageTitle.get("timeframeId"));
  }
  return (
      <ul style={{
        fontSize: "2.35vw",
        lineHeight: 1,
        paddingTop: 10,
        marginBottom: 5,
        display: "inline-block"
      }}>
        {titlesList.map((title, index) => (
            <li key={index} style={listItemStyle}>
                    <span style={{fontFamily: theme.typography.fontFamilyBold, paddingLeft: "1vw", paddingRight: "1vw"}}>
                        {title}
                    </span>
              {(index < titlesList.count() - 1) && <Divider />}
            </li>
        ))}
      </ul>
  );
});

const Divider = pure(() => {
  const client = currentClient;
  if (client.hasPermission("HAS_NEW_LOOK_CUBETV")) {
    return <i className="fa fa-circle" style={{fontSize: "1rem", verticalAlign: "middle", color: Colors.c19Yellow}} />;
  } else {
    return <i className="fa fa-caret-right" />;
  }
});

const listItemStyle = {
  display: "inline-block",
  verticalAlign: "middle",
  color: "#fff",
  whiteSpace: "nowrap"
};

