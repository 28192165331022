import Immutable from "immutable";

export default () => Immutable.fromJS([{
    id: "bbc-news",
    title: "BBC News",
    url: "http://feeds.bbci.co.uk/news/rss.xml",
    isCube19Default: true
},{
    id: "bbc-sports",
    title: "BBC Sports",
    url: "http://feeds.bbci.co.uk/sport/0/rss.xml?edition=uk#",
    isCube19Default: true
},{
    id: "financial-times",
    title: "Financial Times",
    url: "http://www.ft.com/rss/home/uk",
    isCube19Default: true
},{
    id: "cnn",
    title: "CNN",
    url: "http://rss.cnn.com/rss/cnn_latest.rss",
    isCube19Default: true
},{
    id: "espn-uk",
    title: "ESPN UK",
    url: "http://www.espn.co.uk/rss/sport/story/feeds/0.x",
    isCube19Default: true
},{
    id: "huffington-post",
    title: "Huffington Post",
    url: "https://www.huffingtonpost.com/section/front-page/feed",
    isCube19Default: true
},{
    id: "new-york-times",
    title: "New York Times",
    url: "http://rss.nytimes.com/services/xml/rss/nyt/HomePage.xml",
    isCube19Default: true
},{
    id: "sky-news",
    title: "Sky News",
    url: "http://feeds.skynews.com/feeds/rss/home.xml",
    isCube19Default: true
},{
    id: "sky-sports",
    title: "Sky Sports",
    url: "http://www.skysports.com/rss/12040",
    isCube19Default: true
},{
    id: "wall-street-journal",
    title: "Wall Street Journal",
    url: "http://online.wsj.com/xml/rss/3_7085.xml",
    isCube19Default: true
},{
    id: "sydney-morning-herald",
    title: "Sydney Morning Herald",
    url: "http://www.smh.com.au/rssheadlines/top.xml",
    isCube19Default: true
},{
    id: "abc-net-au",
    title: "ABC.net.au",
    url: "http://www.abc.net.au/news/feed/45910/rss.xml",
    isCube19Default: true
},{
    id: "news-com-au",
    title: "News.com.au",
    url: "http://www.news.com.au/content-feeds/latest-news-national/",
    isCube19Default: true
},{
    id: "nz-herald",
    title: "NZ Herald",
    url: "http://rss.nzherald.co.nz/rss/xml/nzhtsrsscid_000000698.xml",
    isCube19Default: true
},{
    id: "radio-nz",
    title: "Radio NZ",
    url: "http://www.radionz.co.nz/rss/national.xml",
    isCube19Default: true
}]);
