/** @jsxImportSource @emotion/react */
import React from "react";
import { TextField, InputAdornment } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { jsx, css } from "@emotion/react";
import * as Colors from "js/common/cube19-colors";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const cube2021Theme = makeStyles({
  root: props => (
      {
        color: props.disabled ? "rgba(255,255,255,0.3) !important" : props.fullTheme.palette.text.main,
        backgroundColor: props.disabled ? "rgba(0,0,0,0.2)" : props.fullTheme.palette.background.card,
        border: `${props.disabled ? "1px solid  rgba(255,255,255,0.1)" : props.error ? `1px solid ${Colors.redLight}` : `1px solid ${props.fullTheme.palette.background.paper}`}`,
        cursor: props.disabled ? "not-allowed" : "default",
        borderRadius: 3,
        height: props.multiline ? "auto" : 42,
        lineHeight: props.multiline ? 1.4 : 1,
        padding: 8,
        fontSize: 13,
        margin: "5px 0 5px 0!important",
        width: "100%",
        ...props.style
      }
  ),
  label: props => (
      {
        color: props.error ? `${Colors.redLight} !important` : props.fullTheme.palette.text.main,
        padding: "5px 0",
        margin: "5px 0 0 0",
        fontSize: 14,
        display: "block",
        transform: "none",
        position: "relative",
        top: 0,
        left: 0,
        ...props.labelStyle,
      }
  ),
  error: props => ({
    background: Colors.redLight,
    color: `${Colors.white} !important`,
    position: "absolute",
    overflow: "hidden",
    whiteSpace: "nowrap",
    top: props.label ? 20 : props.multiline ? 0 : -10,
    right: 5,
    padding: "2px 8px",
    borderRadius: 4
  })
});


const Error = (message) => {
return <span>{message}
  <i className={`fa fa-times`}
     style={{
       width: "15px",
       height: "15px",
       marginLeft: "0.5rem",
       borderRadius: "50%",
       textAlign: "center",
       lineHeight: "15px",
       backgroundColor: "rgba(255,255,255,0.2)",
       color: Colors.white
     }} />
  </span>;
};

const CubeTextField = (props) => {
  const textFieldClasses = cube2021Theme(props);

  const {theme, fullTheme, ...restProps} = props;
  if (theme === 'cube2021') {
    return (
        <TextField
            variant="standard"
            {...restProps}
            helperText={props.helperText && Error(props.helperText)}
            InputLabelProps={{classes: {root: textFieldClasses.label}}}
            FormHelperTextProps={{className: textFieldClasses.error}}
            InputProps={
              {
                classes: {root: textFieldClasses.root},
                endAdornment: props.disabled &&
                      <InputAdornment position="end">
                        <i className="fa fa-lock" />
                      </InputAdornment>,
                ...props.InputProps
              }
            }
        />
    );
  } else {
    return <TextField
        {...restProps}
        variant="standard"
        InputLabelProps={{style: {color: fullTheme.palette.text.primary, ...props.labelStyle}}}
        InputProps={{style: {borderColor: "#fff", ...props.style}}}
    />
  }
};

CubeTextField.displayName = "TextField";

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <CubeTextField fullTheme={theme} {...props} />;
};
