import React from "react";
import Immutable from "immutable";
import {betterMemo} from "js/common/utils/more-memo";

import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";

import LoadingSpinner from "js/common/views/loading-spinner";
import * as Strings from "js/common/utils/strings";

export default betterMemo(
    {displayName: "AutocompleteTextArea"},
    ({delayInMillis = 100, lookupEntries, onChange, label, value}) => {

      const [str, setStr] = React.useState(value);
      React.useEffect(() => setStr(value), [value]);

      const timeoutIdRef = React.useRef(null);

      const flushChanges = () => {
        clearTimeout(timeoutIdRef.current);
        onChange(str);
      };

      const handleChange = e => {
        const newStr = e.target.value;
        setStr(newStr);

        clearTimeout(timeoutIdRef.current);
        timeoutIdRef.current = setTimeout(() => {
          onChange(newStr);
        }, delayInMillis);
      };

      return <ReactTextareaAutocomplete
          placeholder={label}
          dropdownStyle={{zIndex: 999}}
          minChar={0}
          value={str}
          onChange={handleChange}
          onBlur={flushChanges}
          loadingComponent={LoadingSpinner}
          trigger={{
            ":": {
              dataProvider: token => {
                const matchingColumns = getMatchingEntries(
                    lookupEntries.filter(c => c.get("autoCompleteChar") === ":"),
                    token);
                return matchingColumns.take(12).toArray();
              },
              allowWhitespace: true,
              output: c => c.get("value"),
              component: ({entity}) => <div style={{color: "#111"}}>{entity.get("displayLabel")}</div>
            },
            ";": {
              dataProvider: token => {
                const matchingColumns = getMatchingEntries(
                    lookupEntries.filter(c => c.get("autoCompleteChar") === ";"),
                    token);
                return matchingColumns.take(12).toArray();
              },
              allowWhitespace: true,
              output: c => c.get("value"),
              component: ({entity}) => <div style={{color: "#111"}}>{entity.get("displayLabel")}</div>
            }
          }} />;
    });

const getMatchingEntries = (lookupEntries, token) => {
  if (token === "") {
    return lookupEntries.sortBy(c => c.get("sortScore"));
  } else {
    return lookupEntries
        .map(c => c.set("matchScore", Strings.similarityScore(c.get("displayLabel"), token)))
        .filter(c => c.get("matchScore") > 0)
        .sortBy(c => c.get("matchScore"))
        .reverse();
  }
};