import React from "react";
import pure from "js/common/views/pure";

export default pure(({label, branch, timestamp, id, url}) =>
    <span style={BuildInfoItemStyle}>
        <strong className="build-info-label" style={BuildInfoLabel}>{label}:</strong> {branch} - {timestamp} - <a href={url} target="_blank" rel="noopener noreferrer">Build {id}</a>
    </span>
);

const BuildInfoItemStyle = {
    padding: "5px 0",
    fontFamily: "Courier, monospace",
    fontSize: "11px",
    letterSpacing: "1px",
    textTransform: "uppercase"
}

const BuildInfoLabel = {
    fontWeight: "bold"
}