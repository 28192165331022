/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {jsx, css} from "@emotion/react";
import {faChevronDown, faChevronUp, faTimes} from "@fortawesome/pro-solid-svg-icons";

import * as Colors from "js/common/cube19-colors";

export default createReactClass({

  displayName: "SearchBar",
  mixins: [PureRenderMixin],
  propTypes: {
    results: ReactPropTypes.array,
    currentResult: ReactPropTypes.any,
    onChange: ReactPropTypes.func,
    onClickPrevious: ReactPropTypes.func,
    onClickNext: ReactPropTypes.func,
    onClickClear: ReactPropTypes.func
  },

  getInitialState() {
    return {inputText: ""};
  },

  render() {
    const {theme, onChange, onClickPrevious, onClickNext, onClickClear, results, currentResult} = this.props;
    const {inputText} = this.state;

    const resultIndex = results.indexOf(currentResult);
    const resultsStr = `${resultIndex === -1 ? 0 : resultIndex + 1} / ${results.length}`;

    return <div css={searchResultsContainer(this.props.theme)}>
      <input
          className="TESTCAFE-search-input"
          placeholder="Search..."
          style={{
            padding: "0.8rem",
            border: "none",
            color: theme.palette.text.main,
            width: "15rem",
            background: "none"
          }}
          value={inputText}
          onChange={event => {
            const inputText = event.target.value;
            this.setState({inputText});
            onChange(inputText);
          }} />
      <span className={"TESTCAFE-search-count-results"} css={countResults(this.props.theme)}>{resultsStr}</span>
      <div css={searchResultButtons}>
        <a onClick={onClickPrevious} id="TESTCAFE-search-prev-result">
          <FontAwesomeIcon
              icon={faChevronUp}
              color={results.length > 1 ? "inline" : Colors.greyLight} />
        </a>
        <a onClick={onClickNext} id="TESTCAFE-search-next-result">
          <FontAwesomeIcon
              color={results.length > 1 ? "inline" : Colors.greyLight}
              icon={faChevronDown} />
        </a>
        <a onClick={() => {
          this.setState({inputText: ""});
          onClickClear();
        }}>
          <FontAwesomeIcon
              className="TESTCAFE-search-clear"
              color={inputText ? Colors.c19Red : Colors.greyLight}
              icon={faTimes} />
        </a>
      </div>
    </div>;
  }

});

const searchResultsContainer = theme => css`
      display: flex;
      pointer-events: auto;
      background: ${theme.palette.background.card};
      border-radius: 3px;
      align-items: center;
      width: 25.8rem;
    `;


const searchResultButtons = css`
      border-left: 1px solid ${Colors.greyLight};
      margin: 0 0 0 1rem;
      padding: 0 0.5rem 0 0.5rem;
      a {
        padding: 0.5rem;
      }
    `;

const countResults = theme => css`
      color: ${theme.palette.text.main};
      font-size: 12px;
      width: 3rem;
      text-align: right;
    `;
