import React from "react";

import RatioPicker from "js/common/views/inputs/ratio-picker";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import Hint from "js/admin/common/hint";
import Icon from "js/admin/common/icon";
import * as RatioRepo from "js/common/repo/ratio-repo";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const RatioForm = React.memo(({dataConfig, onDataConfigChange}) => {
  const {theme} = React.useContext(CustomThemeContext);
  const [addNewRatio, setAddNewRatio] = React.useState(dataConfig.get("ratiosList").count() < 2);
  const getRatios = () => RatioRepo.getAll();
  const maxRatios = 10;
  const ratiosList = dataConfig.get("ratiosList");
  return(
      <div style={{width: 500, marginBottom: "0.3rem"}}>
        {ratiosList.map((ratio, index) =>
            <div style={{display: "flex", marginBottom: "0.5rem", alignItems: "center"}}>
              <div style={{width: 30, fontSize: "1.2em", opacity: 0.3, fontWeight: 600}}>{index + 1}.</div>
              <div style={{width: 400}}>
                <RatioPicker
                    label={ratio.get("name") || "Select Ratio"}
                    ratios={getRatios()
                        .deleteAll(ratiosList.map(r => r.get("id")))
                        .toList()
                        .filter(r => !r.get("isDeleted"))
                        .filter(x => {
                          const firstRatioKpi = KpiRepo.get(x.get("firstKpiId"));
                          const secondRatioKpi = KpiRepo.get(x.get("secondKpiId"));
                          return firstRatioKpi.get("trendable") && secondRatioKpi.get("trendable");
                        })}
                    onRatioSelect={(r) => {
                      const updatedRatiosList = ratiosList.set(index, r);
                      onDataConfigChange(dataConfig.set("ratiosList", updatedRatiosList));
                      setAddNewRatio(false);
                    }}
                    closeOnSelect={true} />
              </div>

              {index > 0 && <i className="bhi-times"
                               style={{marginLeft: "0.5rem", fontSize: "0.8rem", color: theme.palette.error.main}}
                               onClick={() => {
                                 onDataConfigChange(dataConfig.set("ratiosList", ratiosList.delete(index)));
                               }} />}
              {index + 1 === ratiosList.count() && !addNewRatio && ratiosList.count() !== maxRatios &&
                  <div
                      style={{
                        position: "relative",
                        right: -50,
                        lineHeight: "25px",
                        textAlign: "center",
                        width: 30,
                        height: 30,
                        borderRadius: "50%",
                        background: theme.palette.primary.main,
                        color: theme.palette.text.inverted
                      }}
                      onClick={() => setAddNewRatio(true)}>
                    <i className="bhi-plus" style={{fontSize: "12px"}} />
                  </div>
              }
            </div>
        )}
        {addNewRatio && <div>
          {ratiosList.count() < maxRatios
              ? <div style={{display: "flex", alignItems: "center"}}>
                <div style={{width: 30, fontSize: "1.2em", opacity: 0.3, fontWeight: 600}}>{ratiosList.count() + 1}</div>
                <div style={{width: 400}}>
                  <RatioPicker
                      label={"Select Ratio"}
                      ratios={getRatios()
                          .deleteAll(ratiosList.map(r => r.get("id")))
                          .toList()
                          .filter(r => !r.get("isDeleted"))
                          .filter(x => {
                            const firstRatioKpi = KpiRepo.get(x.get("firstKpiId"));
                            const secondRatioKpi = KpiRepo.get(x.get("secondKpiId"));
                            return firstRatioKpi.get("trendable") && secondRatioKpi.get("trendable");
                          })}
                      onRatioSelect={(r) => {
                        onDataConfigChange(
                            dataConfig
                                .set("ratiosList", ratiosList.push(r))
                        );
                        setAddNewRatio(false);
                      }}
                      closeOnSelect={true} />
                </div>
                {ratiosList.count() > 1 && <i className="bhi-times"
                                              style={{
                                                marginLeft: "0.5rem",
                                                fontSize: "0.8rem",
                                                color: theme.palette.error.main
                                              }}
                                              onClick={() => setAddNewRatio(false)} />}
              </div>
              : <div>You can only trend {maxRatios} ratios at a time.</div>
          }
        </div>}
        {ratiosList.count() >= maxRatios &&
            <div style={{maxWidth: 500, margin: "20px 0 10px 0"}}>
              <Hint>
                <Icon icon="info" style={{color: theme.palette.hints.text}} />
                You can only trend {maxRatios} ratios at a time.
              </Hint>
            </div>
        }
      </div>
  );
});

export default RatioForm;