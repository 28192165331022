import Immutable from "immutable";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Groups from "js/common/groups";

export default () => {
    const cid = Math.random();
    const defaultKpi = getKpis().filter(kpi => kpi.get("visible")).first();
    const rootGroup = Groups.getRootGroup();
    const groupId = rootGroup.get("id");
    const timeframe = timeframeRepo.getDefaultForClient();
    const timeframeId = timeframe.get("id");

    const defaultKpiConfig = Immutable.fromJS({
        kpiId: defaultKpi.get("id"),
        displayName: `${defaultKpi.get("name")} ${timeframe.get("shortName") || timeframe.get("name")}`,
        timeframeId,
        matchAnyTagIds: [],
        matchAllTagIds: [],
        excludedTagIds: []
    });

    return Immutable.fromJS({
        cid: cid,
        id: `slide-${cid}`,
        pageTitle: {
            title: "Leaderboard",
            subTitle: "",
            timeframeId: ""
        },
        layout: {
            id: "LeftBigRightSmall",
            left: [{
                visualizationId: "Leaderboard",
                timeframeId,
                rankedKpiId: defaultKpi.get("id"),
                rankedKpiColumnHeading: "Total",
                limit: 5,
                groupId,
                excludeZeroValues: false,
                rankByPercentOfTarget: false,
                totalUsers: 20,
                cycle: true,
                cycleTime: 10000,
                paginationTime: 15000
            }],
            right: [{
                visualizationId: "TopPerformers",
                title: "Top Performers",
                kpis: Immutable.List.of(defaultKpiConfig)
            },{
                visualizationId: "CompanyOverview",
                title: "Overview",
                kpis: Immutable.List.of(defaultKpiConfig),
                groupId
            }]
        },
        showNewsticker: true
    });
};

const getKpis = () => Immutable.fromJS(kpiRepo.getAll().toJSON());
