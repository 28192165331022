import store from "store";
import React from "react";
import moment from "moment";
import Immutable from "immutable";
import {v4 as uuid} from "uuid";
import {reactComponentToMarionetteComponent, reactAppToMarionetteComponent} from "js/common/views/react-to-marionette";

import Cube19 from "js/cube19.app";
import eventBus from "js/cube19.event-bus";

import OrphanUserPage from "js/invalid-state/orphan-user-page";
import MaintenancePage from "js/invalid-state/maintenance-page";
import ErrorPage from "js/invalid-state/error-page";
import JobPipelinesApp from "js/job-pipelines/app";
import UsageApp from "js/usage-app/app";
import CubeTvChannelsSelectionApp from "js/cubetv/channel-selection/app";
import AdminApp from "js/admin/app";
import ReportingApp from "js/reporting/app";
import ChartingApp from "js/charting/app";
import OneViewApp from "js/oneview/main";
import OnPointApp from "js/onpoint/app";
import LoginApp from "js/login/app";
import SquidApp from "js/squids/app";
import DashboardApp from "js/dashboards/app";

import LoadingScreen from "js/common/views/loading/loading-screen";
import BuildInfo from "js/common/views/build-info/build-info";

import * as CurrentClientRepo from "js/common/repo/backbone/current-client";
import * as CurrencyRepo from "js/common/repo/backbone/currency-repo";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as RatioRepo from "js/common/repo/ratio-repo";
import * as ReportRepo from "js/common/repo/report-repo";
import * as TagRepo from "js/common/repo/backbone/tag-repo";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as RssFeedRepo from "js/common/repo/rss-feed-repo";
import * as DealMusicRepo from "js/common/repo/deal-music-repo";

import * as Locales from "js/common/locales";
import * as Users from "js/common/users";

import * as Auth from "js/common/auth";
import * as Auditor from "js/common/auditer";
import * as ErrorTracking from "js/common/error-tracking";
import * as Permissions from "js/common/permissions";
import attachGlobalsToWindow from "js/attach-globals-to-window";
import setupHighchartsTheme from "js/common/setup-highcharts-theme";
import {currentQueryString, addParam, deleteParam, replaceParams} from "js/common/utils/query-strings";

import "moment/locale/en-gb";
import {hasValue} from "js/common/utils/value-checking";
import NewUserPage from "js/invalid-state/new-user-page";
import {isIframed, loginViaSso, SsoLoginFailedPage} from "js/login/bullhorn-sso";
import {currentUserCanAccessApp} from "js/common/permissions";

const _ = window._;

moment.locale("en-gb");

window.appJsLoaded = true;

setupHighchartsTheme();

// NOTE remove old remember me items from localStorage
store.remove("username");
store.remove("rememberMeToken");

if (!store.get("deviceId")) {
  store.set("deviceId", uuid());
}

ErrorTracking.startTracking();

Cube19.on("start", () => {
  if (window.location.hash === "#invalidateExistingSession") {
    window.location.hash = "";
    Auth.logoutWithoutRefresh()
        .then(() => {
          Cube19.contentRegion.show(reactComponentToMarionetteComponent(<LoginApp initialView="LOGIN" />));
        });
    return;
  }

  Cube19.buildInfoRegion.show(reactComponentToMarionetteComponent(<BuildInfo />));

  Cube19.Users.controller = new Cube19.Users.Controller();
  Cube19.Group.controller = new Cube19.Group.Controller();

  if (!store.get("ignoreLoginUrl") && currentQueryString.username && currentQueryString.password) {
    Auth.login(currentQueryString.username, currentQueryString.password)
        .then(
            () => {eventBus.trigger("auth:logged-in-new");},
            error => {
              if (error.type === "CHANGE_PASSWORD_REQUIRED") {
                console.log("change password required");
                Cube19.contentRegion.show(reactComponentToMarionetteComponent(<LoginApp
                    initialView="CHANGE_PASSWORD" />));
              } else {
                console.log("login required");
                Cube19.contentRegion.show(reactComponentToMarionetteComponent(<LoginApp initialView="LOGIN" />));
              }
            });
    return;
  }
  store.remove("ignoreLoginUrl");

  console.log("app started, checking for valid session");
  if (currentQueryString.code) {
    const expectedState = store.get("ssoState");
    store.remove("ssoState");
    if (currentQueryString?.state !== btoa(JSON.stringify(expectedState))) {
      console.log(currentQueryString?.state, btoa(JSON.stringify(expectedState)));
      eventBus.trigger("error:fatal", new Error("Failed Bullhorn SSO security check. State parameter did not match."));
      deleteParam("code");
      deleteParam("state");
      return;
    }

    Auth.sendAuthCode(currentQueryString.code)
        .then(corpId => {
          // Add CorporationID back in post-redirect in case we need to log in again later
          addParam("CorporationID", corpId);
          eventBus.trigger("auth:logged-in-new");
        })
        .catch(error => Cube19.contentRegion.show(reactComponentToMarionetteComponent(<SsoLoginFailedPage
            error={error} />)))
        .finally(() => {
          // Restore query string to what it was before redirecting to Bullhorn
          // Strip the auth code from the URL to avoid errors from re-use.
          replaceParams(expectedState.queryString);
        });
  } else if (currentQueryString.error) {
    deleteParam("error");
    deleteParam("state");
    const paramError = new Error(currentQueryString.error);
    paramError.type = "ERROR_IN_BULLHORN_AUTH";
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(<SsoLoginFailedPage error={paramError} />));
  } else {
    checkForValidSession();
  }
});

const checkForValidSession = () => {
  Auth.checkForValidSession()
      .then(() => {
        eventBus.trigger("auth:logged-in-new");
      }, error => {
        if (error.type === "CHANGE_PASSWORD_REQUIRED") {
          console.log("change password required");
          Cube19.contentRegion.show(reactComponentToMarionetteComponent(<LoginApp
              initialView="CHANGE_PASSWORD" />));
        } else if (currentQueryString.CorporationID) { // Bullhorn iframe SSO login flow
          Auth
              .checkForSSORequired(currentQueryString.CorporationID)
              .then(ssoRequired => ssoRequired ? loginViaSso() : showLoginApp())
              .catch(error => {
                console.error("Error while checking if SSO is required", error);
                showLoginApp();
              });
        } else {
          showLoginApp();
        }
      });
};

const showLoginApp = () => {
  console.log("login required");
  Cube19.contentRegion.show(reactComponentToMarionetteComponent(<LoginApp initialView="LOGIN" />));
};

eventBus.on("auth:logged-in-new", () => {
  store.remove("ipRestricted");

  const loadingScreen = reactComponentToMarionetteComponent(<LoadingScreen text="Loading App" />);
  Cube19.contentRegion.show(loadingScreen);

  console.log("begin load");
  Promise
      .all([
        Cube19.request("user:load-source"),
        Cube19.Group.controller.load(),
        Cube19.request("user:load-current"),
        Locales.loadAll()
      ])
      .then(([_sourceUser, _, currentUser, locales]) => {
        const localeId = Users.getInherited(currentUser, x => x?.get("localeId"));
        const locale = locales.find(l => l.get("id") === localeId);
        if (locale) {
          moment.locale(locale.get("code").toLowerCase());
        }

        // NOTE legacy event triggered to loading users and groups
        eventBus.trigger("auth:logged-in");

        CurrentClientRepo.load();
        CurrencyRepo.load();
        KpiRepo.load();
        RatioRepo.load();
        ReportRepo.load();
        TagRepo.load();
        TimeframeRepo.load();
        RssFeedRepo.load();
        DealMusicRepo.load();
      })
      .catch(error => eventBus.trigger("error:fatal", error));
});

eventBus.on("all-data-loaded", async () => {
  attachGlobalsToWindow();

  ErrorTracking.startSendingToServer();

  if (await logoutIfIdentityIsMismatched()) {
    return;
  }

  showLandingPage();
});

function newHeadScript(src) {
  return new Promise(function(resolve, reject){
    let script = document.createElement('script');
    script.src = src;
    script.addEventListener('load', function () {
      resolve();
    });
    script.addEventListener('error', function (e) {
      reject(e);
    });
    document.head.appendChild(script);
  })
};

const showLandingPage = () => {
  const currentUser = Users.getCurrentUser();
  const landingPage = currentUser.get("landingPage").toUpperCase();
  const canAccessCubeTv = Permissions.currentUserCanAccessApp(Permissions.apps.gamification);
  const isInIframe = isIframed();
  const bullhornCorpId = currentQueryString.CorporationID;
  const bullhornCorpUserId = currentQueryString.userId;
  Auditor.audit("app:loaded", {
    landingPage: landingPage,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    isInIframe,
    bullhornCorpId,
    bullhornCorpUserId
  }, {
    userAgent: true
  });

  const currentClient = CurrentClientRepo.get();

  if (currentClient.isGainsightEnabled()) {
    const loadGainsight = newHeadScript('enable-gainsight.js');

    const isFullDashboardUser = Users.hasPermission(currentUser, "DASHBOARD_FULL_EDITOR");
    const isBasicDashboardUser = Users.hasPermission(Users.getCurrentUser(), "DASHBOARD_BASIC_EDITOR");

    const getDashboardPermission = () => {
      if(isFullDashboardUser) {
        return "DASHBOARD_FULL_EDITOR"
      } else if(isBasicDashboardUser) {
        return "DASHBOARD_BASIC_EDITOR";
      } else {
        return "NONE";
      }
    };

    loadGainsight.then(() => {
      const aptrinsic = window.aptrinsic;
      aptrinsic("identify",
          {
            //User Fields
            "id": currentUser.attributes.id,
            "email": currentUser.attributes.email,
            "firstName": currentUser.attributes.firstName,
            "lastName": currentUser.attributes.lastName,
            "canAccessDashboards": currentUserCanAccessApp(Permissions.apps.dashboards),
            "dashboardsPermissionLevel": getDashboardPermission()
          },
          {
            //Account Fields
            "id": currentClient.attributes.id,
            "name": currentClient.attributes.name,
            "Program": currentClient.attributes.type,
          });
    }).catch(() => {
      console.error("Failed to load Gainsight");
    })
  }

  if (Users.isNew(currentUser)) {
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(<NewUserPage />));
  } else if (Users.isOrphan(currentUser)) {
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(<OrphanUserPage />));
  } else {
    Cube19.contentRegion.show(getAppForLandingPage(landingPage, canAccessCubeTv));
  }
};

/**
 * Check if the corpId or userId in the query params don't match the current user if either corp require SSO.
 * @returns {Promise<boolean>} True if the identity was mismatched
 */
const logoutIfIdentityIsMismatched = async () => {
  // Check that the user and corp ID provided by the Bullhorn iframe redirect matches the current session
  const corpId = currentQueryString.CorporationID;
  const userId = currentQueryString.userId;
  const sourceUserCrmId = Users.getSourceUser().get("originalCrmUserId");
  const currentClient = CurrentClientRepo.get();
  const corpIdIsSpecified = hasValue(corpId);
  const userIdIsSpecified = hasValue(userId);
  const corpIdDoesNotMatch = corpIdIsSpecified && corpId !== currentClient.get("bullhornCorpId");
  const userIdDoesNotMatch = userIdIsSpecified && userId !== sourceUserCrmId;
  if (corpIdDoesNotMatch || userIdDoesNotMatch) {
    const currentClientRequiresSso = currentClient.get("requireSsoLogin");
    return (corpIdDoesNotMatch ? Auth.checkForSSORequired(corpId) : Promise.resolve(currentClientRequiresSso))
        .then(newClientRequiresSso => {
          if (currentClientRequiresSso || newClientRequiresSso) {
            console.log(
                "User given by Bullhorn does not match the user for the current session.",
                {
                  corpIdFromUrl: corpId,
                  expectedCorpId: currentClient.get("bullhornCorpId"),
                  userIdFromUrl: userId,
                  expectedUserId: sourceUserCrmId
                });
            // ErrorTracking may throw an error here because we aren't cancelling its requests
            Auth
                .logoutWithoutRefresh()
                .then(() => {
                  const error = new Error(
                      "You were logged out because your user did not match the one provided by Bullhorn");
                  error.type = "USER_MISMATCH";
                  Cube19
                      .contentRegion
                      .show(reactComponentToMarionetteComponent(<SsoLoginFailedPage error={error} />));
                });
            return true;
          } else {
            return false;
          }
        });
  } else {
    Promise.resolve(false);
  }
};

const getAppForLandingPage = (landingPage, canAccessCubeTv) => {
  if (currentQueryString.channel && canAccessCubeTv) {
    store.set("kioskMode.carouselId", Number(currentQueryString.channel));
    return reactComponentToMarionetteComponent(<CubeTvChannelsSelectionApp />);
  }

  switch (landingPage) {
    case "JOB_PIPELINE":
      return reactAppToMarionetteComponent(<JobPipelinesApp />);
    case "DASHBOARDS":
      return reactAppToMarionetteComponent(<DashboardApp />);
    case "ADMIN":
      return reactAppToMarionetteComponent(<AdminApp />);
    case "REPORTING":
      return reactAppToMarionetteComponent(<ReportingApp />);
    case "BIGSCREENS":
      return reactAppToMarionetteComponent(<CubeTvChannelsSelectionApp />);
    case "USAGE":
      return reactAppToMarionetteComponent(<UsageApp />);
    case "CHARTING":
      return reactAppToMarionetteComponent(<ChartingApp />);
    case "ONPOINT":
      return reactAppToMarionetteComponent(<OnPointApp />);
    case "SQUIDS":
      return reactAppToMarionetteComponent(<SquidApp />);
    case "ONEVIEW":
    default:
      return reactAppToMarionetteComponent(<OneViewApp />);
  }
};

eventBus.on("error:fatal", error => {
  eventBus.off();
  const type = error?.responseJSON?.type;
  if (error) {
    console.error(error);
  }
  switch (type) {
    case "USER_CANNOT_BE_ORPHAN":
      const orphanPage = reactComponentToMarionetteComponent(<OrphanUserPage />);
      Cube19.contentRegion.show(orphanPage);
      break;
    case "FAILED_SSO_CHECK":
      const ssoLoginFailedPage = reactComponentToMarionetteComponent(<SsoLoginFailedPage error={error} />);
      Cube19.contentRegion.show(ssoLoginFailedPage);
      break;
    default:
      const errorPage = reactComponentToMarionetteComponent(<ErrorPage />);
      Cube19.contentRegion.show(errorPage);
      break;
  }
});

eventBus.on("app-under-maintenance", () => {
  eventBus.off();
  const maintenancePage = reactComponentToMarionetteComponent(<MaintenancePage />);
  Cube19.contentRegion.show(maintenancePage);
});

let requiredData = Immutable.Set([
  "users",
  "current-client",
  "groups",
  "hierarchy",
  "kpis",
  "timeframes",
  "ratio-configs",
  "currencies",
  "tags"
]);

eventBus.on("data-loaded", identifier => {
  if (requiredData.isEmpty()) {
    return;
  }

  if (requiredData.has(identifier)) {
    console.log("loaded required data: " + identifier);
    requiredData = requiredData.delete(identifier);
    if (requiredData.size === 0) {
      console.log("loaded all required data");
      eventBus.trigger("all-data-loaded");
    }
  } else {
    console.log("loaded optional data: " + identifier);
  }
});

const triggerResize = _.debounce(event => {
  eventBus.trigger("window:resize", event);
}, 100);
window.addEventListener("resize", e => {
  triggerResize();
});
