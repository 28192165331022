import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";

export default createReactClass({

    mixins: [ PureRenderMixin ],

    propTypes: {
        text: ReactPropTypes.string,
        messageTimeout: ReactPropTypes.number,
        onMessageTimeout: ReactPropTypes.func,
        style: ReactPropTypes.object
    },

    getDefaultProps() {
        return {
            text: "",
            messageTimeout: 1500,
            onMessageTimeout: () => {},
            style: {}
        };
    },

    componentDidMount() {
        const { messageTimeout, onMessageTimeout } = this.props;
        this.msgTimeoutId = window.setTimeout(() => onMessageTimeout(), messageTimeout);
    },

    componentWillUnmount() {
        window.clearTimeout(this.msgTimeoutId);
    },

    render() {
        const { text, style } = this.props;
        return (
            <div style={{ ...containerStyle, ...style }}>
                <i className="fa fa-check-circle" style={iconStyle} />
                <span style={{ display: "table-cell" }}>{text}</span>
            </div>
        );
    }

});

const containerStyle = {
    fontSize: "0.75rem",
    fontWeight: "bold",
    letterSpacing: "2px",
    lineHeight: 1.25,
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    padding: "5px 10px",
    backgroundColor: "#2BAE66FF",
    border: "1px solid #2BAE66FF",
    color: "#fff"
};

const iconStyle = {
    display: "table-cell",
    fontSize: "1rem",
    paddingRight: "8px"
};
