/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as Colors from "js/common/cube19-colors";

export default React.memo(({
    customStyle = {},
    onClick
}) => {
    const { theme } = React.useContext(CustomThemeContext);
    return (<i className="bhi-projects" title="See Quick View" style={{ ...style, ...customStyle }} css={hover(theme)} onClick={e => { e.stopPropagation(); onClick(); }} />)
});

const style = {};

const hover = (theme) => css`
    color: ${theme.palette.text.main};
    &:hover {
        color: ${theme.palette.primary.main};
        font-size: 12px;
        text-shadow: #afafaf 0 0 15px;
    }
`;
