import React from "react";
import Immutable from "immutable";
import pure from "js/common/views/pure";
import DropdownToggle from "js/common/views/react-dropdown-toggle";
import {Layout, Row, Column} from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import {TextField} from "@mui/material";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const RatioPicker = React.memo(({
  ratios,
  onRatioSelect,
  kpis,
  searchMinLimit = 10,
  closeOnSelect = true,
  label = "Select ratio",
  theme
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");

  const filteredRatios = React.useMemo(() => {
    if (searchText.length > 0) {
      return ratios.filter(ratio => {
        const stringIndex = ratio.get("name").toUpperCase().indexOf(searchText.toUpperCase());
        return stringIndex > -1;
      });
    } else {
      return ratios;
    }
  }, [ratios, searchText]);

  const isSearchable = ratios.count() >= searchMinLimit;

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSearchText("");
  };

  const closeDropdown = () => {
    setIsOpen(false);
    setSearchText("");
  };

  const handleRatioSelect = (selectedRatio) => {
    onRatioSelect(selectedRatio);
    if (closeOnSelect) {
      closeDropdown();
    }
  };

  return <DropdownToggle
      primaryText={label}
      isOpen={isOpen}
      onToggleButtonClick={toggleDropdown}
      onRequestClose={closeDropdown}>
    <RatioList
        theme={theme}
        ratios={filteredRatios}
        kpis={kpis}
        onSelect={handleRatioSelect}
        isSearchable={isSearchable}
        onSearchTextChange={(text) => setSearchText(text)} />
  </DropdownToggle>;
});

const RatioList = React.memo(({
  theme,
  ratios,
  onSelect,
  isSearchable,
  onSearchTextChange,
  kpis
}) => {

  const [customRatioFirstKpiId, setCustomRatioFirstKpiId] = React.useState(null);
  const [customRatioSecondKpiId, setCustomRatioSecondKpiId] = React.useState(null);
  let searchTextInput = React.useRef(null);

  React.useEffect(() => {
    if (isSearchable) {
      setTimeout(() => searchTextInput && searchTextInput.focus(), 250);
    }
  }, [isSearchable]);

  const renderRatioOptions = () => {
    return ratios
        .sortBy(r => r.get("name").toLowerCase())
        .map(ratio => <Ratio theme={theme} key={ratio.get("id")} ratio={ratio} onClick={onSelect} />);
  };

  const renderCustomRatioMaker = () => {
    const isBothKpisSelected = customRatioFirstKpiId && customRatioSecondKpiId;
    const padding = {padding: "0.5rem 0.875rem"};
    const kpiPickerContainerStyle = {
      display: "block",
      width: "100%"
    };
    return <div>
      <Layout allSmall={12} collapseRow={true} columnStyle={padding}>
        <div style={{fontSize: "0.7rem", textTransform: "uppercase"}}>Create a custom ratio</div>
      </Layout>
      <Row collapse={true}>
        <Column small={12} style={padding}>
          <div style={{...kpiPickerContainerStyle, marginBottom: 10}}>
            <KpiPicker
                placeholder="Set 1st Metric"
                kpis={kpis}
                selectedKpiId={customRatioFirstKpiId}
                onChange={(kpiId) => setCustomRatioFirstKpiId(kpiId)}
                multiSelect={false} />
          </div>
          <div style={kpiPickerContainerStyle}>
            <KpiPicker
                placeholder="Set 2nd Metric"
                kpis={kpis}
                selectedKpiId={customRatioSecondKpiId}
                onChange={(kpiId) => setCustomRatioSecondKpiId(kpiId)}
                multiSelect={false} />
          </div>
        </Column>
      </Row>
      <Layout
          allSmall={12}
          smallCentered={true}
          collapseRow={true}
          rowStyle={{marginBottom: "1rem"}}
          columnStyle={padding}>
        <TextButton
            label="Add custom ratio"
            onClick={onAddCustomRatio}
            disabled={!isBothKpisSelected} />
      </Layout>
    </div>;
  };

  const onAddCustomRatio = () => {
    const firstKpi = kpiRepo.get(customRatioFirstKpiId);
    const secondKpi = kpiRepo.get(customRatioSecondKpiId);
    const customRatio = Immutable.fromJS({
      id: `custom-ratio-${Math.random()}`,
      name: `${firstKpi.get("name")} : ${secondKpi.get("name")}`,
      firstKpiId: firstKpi.get("id"),
      secondKpiId: secondKpi.get("id"),
      displayType: "NORMALISED",
      order: ratios.count()
    });
    onSelect(customRatio);
  };

  return <div style={{marginTop: isSearchable ? 0 : "1rem"}}>
    {isSearchable &&
        <div style={{paddingLeft: "1rem", paddingRight: "1rem"}}>
          {/* this is a stopgap to fix a known issue with React and/or Material-UI for IE11
                            https://github.com/facebook/react/issues/6822 */}
          <style type="text/css" dangerouslySetInnerHTML={{__html: "::-ms-clear {display: none;}"}} />
          <TextField
              variant="standard"
              label={
                <span>
              <i className="fa fa-search" style={{paddingRight: "5px"}} />
              Type to search for ratios
            </span>}
              inputstyle={{boxShadow: "none"}}
              onChange={e => onSearchTextChange(e.target.value)}
              autoFocus={true}
              inputRef={ref => searchTextInput = ref}
          />
        </div>}
    {ratios.count() > 0 && renderRatioOptions()}
    {ratios.isEmpty() && <div style={{opacity: 0.5, padding: "0.875rem"}}>No results found</div>}
    {kpis && <hr style={{margin: "1rem 0.5rem", border: "1px solid #555"}} />}
    {kpis && renderCustomRatioMaker()}
  </div>;
});

const Ratio = pure(({
  theme,
  ratio,
  onClick
}) => (
    <div
        data-test-id="ratio-option"
        key={ratio.get("id")}
        style={optionStyle(theme)}
        onClick={() => onClick(ratio)}>
      {ratio.get("name")}
    </div>
));

const optionStyle = theme => ({
  fontSize: "0.875rem",
  cursor: "pointer",
  padding: "0.5rem 0.875rem",
  overflow: "hidden",
  display: "block",
  textWrap: "nowrap",
  textOverflow: "ellipsis",

  ":hover": {
    background: theme.palette.primary.main,
    color: theme.palette.text.main,
    fontWeight: "bold"
  }
});

const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <RatioPicker theme={theme} {...props} />;
};

export default Wrapper;
