import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import ReactHighcharts from "react-highcharts";
import getContainerDimensions from "react-dimensions";
import {white, black, pomegranate, boulder} from "js/common/cube19-colors";

const PriorityCurveChart = createReactClass({

  propTypes: {
    totalValue: ReactPropTypes.number.isRequired,
    targetValue: ReactPropTypes.number.isRequired,
    expectedValue: ReactPropTypes.number.isRequired,
    percentageOfTargetComplete: ReactPropTypes.string.isRequired,
    theme: ReactPropTypes.object.isRequired,
  },

  shouldComponentUpdate(nextProps) {
    return this.props.containerWidth !== nextProps.containerWidth || this.props.theme.themeId !== nextProps.theme.themeId;
  },

  render() {
    const {containerWidth} = this.props;
    const fullChartConfig = this.getGaugeChartOptions();
    const chartOptions = fullChartConfig.chart;
    const chartOptionsWithWidth = {...chartOptions, width: containerWidth};
    const allOptions = {...fullChartConfig, chart: chartOptionsWithWidth};
    return <ReactHighcharts config={allOptions} />;
  },

  getGaugeChartOptions() {
    const {theme} = this.props;
    const {palette} = theme;
    const currentTheme = theme.themeId;
    let {totalValue, targetValue, expectedValue, percentageOfTargetComplete} = this.props;

    let yAxisColor = "";

    let redMaxValue = 0.5 * expectedValue;

    if (totalValue < redMaxValue) {
      yAxisColor = palette.error.main;
    }
    if (totalValue >= redMaxValue && totalValue < expectedValue) {
      yAxisColor = palette.warning.main;
    }
    if (totalValue >= expectedValue) {
      yAxisColor = palette.success.main;
    }

    if (targetValue === 0) {
      yAxisColor = palette.background.card;
    }

    if (totalValue > targetValue) {
      totalValue = targetValue;
    }

    const yAxisOptions = {
      ...gaugeOptions(theme, palette).yAxis,
      min: 0,
      max: targetValue,
      plotBands: [{
        from: 0,
        to: expectedValue,
        color: currentTheme === "light" ? "#E0E0E3" : "#616161",
        outerRadius: "88%",
        innerRadius: "70%",
        thickness: "24%"
      }],
      minColor: yAxisColor,
      maxColor: yAxisColor,
      lineColor: currentTheme === "light" ? "#ccd6eb" : "#707070"
    };

    const seriesOptions = [{
      name: "Percentage of Total",
      data: [totalValue],
      dataLabels: {
        format: `<span style='font-family: ${theme.typography.fontFamily}; font-size:2em;color:${palette.text.main}'>` + percentageOfTargetComplete + "</span>",
        align: "center",
        y: -18,
        x: 0,
        borderWidth: 0
      }
    }];

    const chartOptions = {
      ...gaugeOptions(theme, palette),
      chart: {
        type: "solidgauge",
        className: "kpi-gauge",
        backgroundColor: "transparent",
        height: 145
      },
      yAxis: yAxisOptions,
      series: seriesOptions
    };

    return chartOptions;
  }
});


const gaugeOptions = (theme, palette) => ({
  exporting: {
    enabled: false
  },

  loading: {
    labelStyle: {
      color: white,
      fontFamily: theme.typography.fontFamily
    },
    style: {
      backgroundColor: boulder
    },
    showDuration: 500
  },

  title: {
    text: null,
    align: "left",
    style: {
      color: pomegranate,
      fontWeight: "normal",
      fontStyle: "normal"
    },
    y: 10
  },

  subtitle: {
    text: null,
    align: "left",
    style: {
      color: white,
      fontSize: "15px",
      fontFamily: theme.typography.fontFamily,
      fontStyle: "normal"
    }
  },

  pane: {
    center: ["50%", "50%"],
    size: "90%",
    startAngle: 0,
    endAngle: 360,
    background: [{
      backgroundColor: palette.background.card,
      innerRadius: "70%",
      outerRadius: "100%",
      borderWidth: 0,
      shape: "arc"
    }]
  },

  path: {
    borderColor: black
  },

  yAxis: {
    lineWidth: 2,
    offset: 7,
    minorTickInterval: null,
    tickWidth: 0,
    labels: {
      style: {
        "display": "none"   // hides the gauge's tick values
      }
    }
  },

  plotOptions: {
    solidgauge: {
      innerRadius: "70%", // sets thickness/width of Total Value ring/bar
      dataLabels: {
        useHTML: true
      }
    }
  },

  tooltip: {
    enabled: false
  },

  credits: {
    enabled: false
  }
});

export default getContainerDimensions()(PriorityCurveChart);
