/** @jsxImportSource @emotion/react */

import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseButton from "js/common/views/inputs/close-button";
import {css} from "@emotion/react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const styles = makeStyles({
  root: props => ({
    width: getWidth(props),
    zIndex: 999999
  }),
  paper: props => ({
    background: props.theme.themeId === "light" ? "#f9f9f8" : props.theme.palette.background.card,
    width: getWidth(props),
    maxWidth: "100%"
  })
});

const Drawer = props => {
  const {title, open, onRequestOpen, onRequestClose, docked, showCloseButton, openRight, children, dataProductId} = props;
  const {theme} = React.useContext(CustomThemeContext);

  const classes = styles({...props, theme});
  return (
      <SwipeableDrawer
          open={open}
          onOpen={onRequestOpen}
          onClose={onRequestClose}
          variant={docked ? "persistent" : "temporary"}
          className={classes.root}
          PaperProps={{className: classes.paper}}
          anchor={openRight ? "right" : "left"}
          data-product-id={dataProductId}>
        {(title || showCloseButton) &&
        <div css={drawerHeaderStyle(theme)}>
          {title && <h3 css={drawerHeadingStyle(theme)}>{title}</h3>}
          {showCloseButton &&
          <CloseButton
              onClick={onRequestClose}
              style={{display: "flex", alignItems: "center", fontSize: "16px", margin: 0, padding: 0}} />}
        </div>}

        {open && children}
      </SwipeableDrawer>
  );
};

const getWidth = (props) => {
  const {width, wideWidth} = props;
  const screenWidth = window.innerWidth;
  const BREAKPOINT = 1024;
  if (screenWidth >= BREAKPOINT && wideWidth) {
    return screenWidth * 0.8;
  } else if (screenWidth < BREAKPOINT && wideWidth) {
    return screenWidth;
  } else {
    return width || 350;
  }
};

Drawer.defaultProps = {
  docked: false,
  onRequestOpen: () => { /* do nothing by default */ },
  showCloseButton: false
};

const drawerHeaderStyle = theme => css`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 2px solid ${theme.themeId === "light" ? "#e4e4e4" : "#A0A0A0"};
  margin-top: 0;
  max-height: 52px;
`;

const drawerHeadingStyle = theme => css`
  //flex: 1;
  font-family: ${theme.typography.fontFamilyBold};
  font-size: 18px;
  color: ${theme.palette.text.main};
  text-transform: none;
  white-space: nowrap;
  width: calc(100%);
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default Drawer;
