import {css} from "@emotion/react";

export const appBarStyle = (palette, currentTheme) => css`
  background-color: transparent;
  margin-top: ${currentTheme === "light" ? "-10px" : "0px"};
  margin-bottom: ${currentTheme === "light" ? "0px" : "30px"};
  box-shadow: none;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &:after {
    content: '';
    width: 100%;
    background: ${palette.background.card};
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .MuiTabs-root {
    overflow: visible;
    margin-bottom: ${currentTheme === "light" ? "-7px" : "0px"};
  }

  .MuiTabs-indicator {
    z-index: 1;
  }

  .MuiTabs-scroller {
    max-width: calc(100% - 75px);
    height: 80px;
    margin-top: ${currentTheme === "light" ? "-35px" : "-30px"};
    display: flex;
    align-items: flex-end;
    overflow-x: hidden !important;
  }

  .MuiTabs-scrollButtons {
    position: absolute;
    right: 0px;
    bottom: 7px;
    z-index: 1199;

    &:first-of-type {
      position: absolute;
      right: 14px;
      bottom: 7px;
    }
  }
`;

export const tabStyles = (palette, currentTheme) => css({
  color: `${palette.text.main} !important`,
  backgroundColor: palette.background.card,
  marginRight: "10px",
  paddingTop: "6px",
  paddingBottom: "6px",
  paddingLeft: "10px",
  paddingRight: "10px",
  lineHeight: "1.75",
  fontSize: "12px",
  minWidth: "auto",
  border: `solid 2px ${palette.background.card}`,
  minHeight: "10px",
  marginTop: "15px",
  overflow: "visible",
  textTransform: "uppercase",
  zIndex: 0,

  "&.Mui-selected": {
    color: `${currentTheme === "light" ? palette.primary.main  : palette.text.main} !important`,
  },

  "&:hover, &:focus": {
    color: `${currentTheme === "light" ? palette.primary.main  : palette.text.main} !important`,
    backgroundColor: palette.background.card,
    zIndex: 1
  }
});
