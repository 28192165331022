import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import * as Colors from "js/common/cube19-colors";
import Layout from "js/common/views/foundation-column-layout";

import {TextButton} from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";
import {checkNewPasswordComplexity} from "js/login/app";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import { TextField } from '@mui/material';
import Checkbox from "js/common/views/inputs/checkbox";

const PASSWORD_LENGTH = 10;
const MAX_LENGTH = 128;

const SetPasswordDialog = createReactClass({

  propTypes: {
    onUpdateClick: ReactPropTypes.func.isRequired,
    onCancelClick: ReactPropTypes.func.isRequired,
    isOpen: ReactPropTypes.bool.isRequired,
    theme: ReactPropTypes.object
  },

  getInitialState() {
    return {
      firstPassword: "",
      secondPassword: "",
      showPassword: false,
      changePasswordFlag: true,
      emailCurrentUser: true,
      emailModifiedUser: false
    };
  },

  render() {
    const {
      firstPassword,
      secondPassword,
      showPassword,
      changePasswordFlag,
      emailCurrentUser,
      emailModifiedUser
    } = this.state;
    const { theme } = this.props;
    const dontMatch = firstPassword && secondPassword && firstPassword !== secondPassword;
    const disabled = !firstPassword || !secondPassword || dontMatch;

    const actions = [
      <TextButton
          type="default"
          label="Cancel"
          style={{marginRight: 12}}
          onClick={this.handleCancelClick} />,
      <TextButton
          type="primary"
          key="update"
          label="Update Password"
          disabled={disabled}
          onClick={this.handleUpdateClick} />];

    const complexityErrors = !disabled && checkNewPasswordComplexity(firstPassword);
    return <Dialog
        paperStyle={{backgroundColor: theme.themeId === "light" ? theme.palette.background.card : theme.palette.background.paper}}
        title="Set User Password"
        actions={actions}
        open={this.props.isOpen}>
      <div>
        {complexityErrors && complexityErrors.count() > 0 &&
        <div style={{fontSize: "0.875rem", lineHeight: 1.5, textAlign: "left", paddingBottom: "0.5rem"}}>
          {"Password does not meet all of our data security requirements:"}
          {complexityErrors
              .map((criteria, index) =>
                  <div key={index} style={complexityMsgStyle(theme)}>
                    <i className="fa fa-exclamation-triangle" style={{paddingRight: 8}} />
                    {criteria}
                  </div>)}
        </div>
        }
      <Layout allSmall={12} allMedium={6}>
        <div style={{ display: "flex", flexDirection: "column"}}>
          <TextField variant="standard"
              label="New Password"
              style={{margin: 5}}
              type={showPassword ? "text" : "password"}
              value={firstPassword}
              onChange={e => this.setState({firstPassword: e.target.value.substring(0, MAX_LENGTH),})} />
          <TextField variant="standard"
              label="Confirm New Password"
              style={{margin: 5}}
              error={dontMatch}
              helperText={dontMatch && "Doesn't match"}
              type={showPassword ? "text" : "password"}
              value={secondPassword}
              onChange={e => this.setState({secondPassword: e.target.value.substring(0, MAX_LENGTH)})} />
        <TextButton
            type="default"
            label={"Generate Password"}
            style={{marginTop: 10}}
            onClick={this.onGeneratePasswordClick} />
      </div>
        <div style={{...boxStyle(theme)}}>
          <Checkbox
              label="Show password"
              checked={showPassword}
              style={{height: 30, width: 200}}
              onCheck={(e, checked) => this.setState({showPassword: checked})} />
          <Checkbox
              label="Reset on next login"
              checked={changePasswordFlag}
              style={{height: 30, width: 200}}
              onCheck={(e, checked) => this.setState({changePasswordFlag: checked})} />
          <Checkbox
              label="Email me"
              checked={emailCurrentUser}
              style={{height: 30, width: 200}}
              onCheck={(e, checked) => this.setState({emailCurrentUser: checked})} />
          <Checkbox
              label="Email user"
              checked={emailModifiedUser}
              style={{height: 30, width: 200}}
              onCheck={(e, checked) => this.setState({emailModifiedUser: checked})} />
        </div>
      </Layout>
      </div>
    </Dialog>;
  },

  onGeneratePasswordClick() {
    const password = randomPassword();
    this.setState({
      firstPassword: password,
      secondPassword: password,
      showPassword: true
    });
  },

  handleCancelClick() {
    this.setState(this.getInitialState());
    this.props.onCancelClick();
  },

  handleUpdateClick() {
    const {
      firstPassword,
      changePasswordFlag,
      emailCurrentUser,
      emailModifiedUser
    } = this.state;
    this.props.onUpdateClick({
      password: firstPassword,
      changePasswordFlag,
      emailCurrentUser,
      emailModifiedUser
    });
    this.setState(this.getInitialState());
  }
});

const complexityMsgStyle = theme => ({
  fontSize: "0.875rem",
  color: theme.palette.text.paper,
  lineHeight: 1.5,
  paddingBottom: "0.5rem"
});

const boxStyle = theme => ({
  backgroundColor: theme.palette.background.card,
  padding: "10px 10px 8px 10px",
  marginRight: "-0.9375em",
  border: `2px solid ${theme.palette.background.paper}`,
  borderRadius: 8
});

const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()+_-=}{[]|:;?.><,`~";
const lower = /[a-z]/;
const upper = /[A-Z]/;
const number = /[0-9]/;
const symbol = /[!@#$%^&*()+_\-=}{[\]|:;?.><,`~]/;

const randomPassword = () => {
  const crypto = window.crypto || window.msCrypto; // for IE 11
  const randomValues = crypto.getRandomValues(new Uint8Array(PASSWORD_LENGTH));
  const result = randomValues.reduce((result, value) => result + chars[value % chars.length], "");
  if (result.match(lower) && result.match(upper) && result.match(number) && result.match(symbol)) {
    return result;
  }
  return randomPassword();
};



const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <SetPasswordDialog theme={theme} {...props} />;
};

export default Wrapper;