
const DEFAULT_RETRY_OPTIONS = {
    maxRetries: 3,
    intervalInMillis: 250,
    intervalMultiplier: 2
};

const retry = (action, overrideRetryOptions) => {
    const retryOptions = { ...DEFAULT_RETRY_OPTIONS, ...overrideRetryOptions };
    return attemptToRun(action, retryOptions.maxRetries, retryOptions.intervalInMillis, retryOptions);
};

const attemptToRun = (action, retriesRemaining, intervalInMillis, retryOptions) => {
    const promise = Promise.resolve(action());

    if (retriesRemaining === 0) {
        return promise;
    }

    return promise.catch(error => {
        const nextAttempt = () => attemptToRun(
            action,
            retriesRemaining - 1,
            intervalInMillis * retryOptions.intervalMultiplier,
            retryOptions);
        return delay(intervalInMillis).then(nextAttempt);
    });
};

const delay = delayInMillis => new Promise((resolve, reject) => setTimeout(resolve, delayInMillis));

export default retry;
