import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as Formatter from "js/common/utils/formatter";

import currentClient from "js/common/repo/backbone/current-client";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const maxCharDisplayLength = 10;
const nonBreakingSpace = "\u00A0";

const GroupOverview = createReactClass({

  mixins: [PureRenderMixin],

  render() {
    const {config, data} = this.props;
    const title = config.get("title") || nonBreakingSpace;
    const client = currentClient;
    const clientName = client.getClientName();
    const imgUrl = client.getLogoUrl();
    const hasKpis = !config.get("kpis").isEmpty();
    const imgHeight = hasKpis ? "18vh" : "23vh";
    const logoContainerStyle = {
      height: imgHeight,
      marginTop: hasKpis ? 0 : "2vh",
      marginBottom: "2vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    };
    const headerTextStyle = {
      ...textStyle,
      fontSize: "3vh",
      color: "#f9ec33",
      textTransform: "uppercase",
      lineHeight: 1,
      height: "6vh",
      display: "flex"
    };
    return (
        <div style={{height: "100%"}}>
          <div style={headerTextStyle}>
            <div style={{alignSelf: "flex-end"}}>{title}</div>
          </div>
          <div style={{...leftSideContainerStyle(this.props.theme), height: "calc(100% - 6vh)"}}>
            <div style={logoContainerStyle}>
              <img style={{...imgStyle, maxHeight: imgHeight}} src={`${imgUrl}`} alt={clientName} />
            </div>
            {hasKpis && data && this.renderKpisDataSummary()}
          </div>
        </div>
    );
  },

  renderKpisDataSummary() {
    return this.props.data
        .sortBy(data => data.get("order"))
        .map((kpiData, index) => {
          const kpiId = kpiData.get("kpiId");
          const kpi = kpiRepo.get(kpiId);
          const kpiValueFormat = kpi.get("type").valueFormat;
          const summaryContainerStyle = {
            borderTop: "2px solid #534e6e",
            paddingTop: "2.75vh"
          };
          const kpiNameDisplayStyle = {
            fontFamily: this.props.theme.typography.fontFamily,
            fontWeight: "bold",
            lineHeight: 1.35,
            color: "#fff"
          };
          const kpiValueDisplayStyle = {
            fontFamily: this.props.theme.typography.fontFamily,
            color: "#f9ec33",
            fontSize: "3.8vw",
            fontWeight: "bold",
            lineHeight: 1
          };
          const kpiTotal = kpiData.get("total");
          return (
              <div key={`${kpiId}-${index}`} style={summaryContainerStyle}>
                <div style={kpiNameDisplayStyle}>
                  {kpiData.get("displayName")}
                </div>
                <div style={kpiValueDisplayStyle}>
                  {kpiTotal.get("type") === "error" ?
                      kpiTotal.get("value") :
                      Formatter.format(
                          kpiTotal.toJS(),
                          {valueFormat: kpiValueFormat, maxDisplayLength: maxCharDisplayLength})}
                </div>
              </div>
          );
        });
  }

});

const textStyle = theme => ({
  fontFamily: theme.typography.fontFamilyBold,
  fontSize: "3.5vmin",
  color: "#fff",
  textShadow: "0 2px 2px #000"
});

const imgStyle = {
  objectFit: "contain",
  width: "auto",
  flexGrow: 0,
  filter: "drop-shadow(0 0 10px rgba(191,188,214,0.6))"
};

const leftSideContainerStyle = theme => ({
  overflow: "hidden",
  padding: "1rem",
  borderTop: "3px solid #f9ec33",
  borderBottom: "3px solid #f9ec33",
  background: "linear-gradient(to bottom, #0f0763 0%, #0f0763 23%, #030111 100%)",
  textAlign: "center",
  ...textStyle(theme)
});


const Wrapper = (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <GroupOverview theme={theme} {...props} />;
};

export default Wrapper;
