/** @jsxImportSource @emotion/react */

import React from "react";
import {indexBy} from "js/common/utils/collections";
import Immutable from "immutable";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as KpiCalculator from "js/common/kpi-calculator";

const loadKpis = (kpiIds, controlConfig) => {
  // NOTE limiting these requests like oneview / onpoint not really required, squids don't have that many metrics
  //  much more important to implement caching of kpi result sets to avoid repeated runs of core metrics
  const options = {
    timeframe: TimeframeRepo.parse(controlConfig.get("timeframe")),
    qualifierId: controlConfig.get("qualifierId"),
    qualifierType: controlConfig.get("qualifierType"),
    clientIds: controlConfig.get("allClientIds").toArray(),
    alwaysAllowClientFilter: true,
    matchAnyTagIds: controlConfig.get("matchAnyTagIds"),
    matchAllTagIds: controlConfig.get("matchAllTagIds"),
    excludedTagIds: controlConfig.get("excludedTagIds")
  };
  return kpiIds.map(kpiId => {
    try {return KpiCalculator.summary(kpiId, options).then(result => {
      result.kpiId = kpiId;
      return result;
    }, error => {
      return {isError: true, kpiId, error};
    });
  } catch (e) {
      return Promise.resolve({isError: true, message: "synchronous error when calling calculator: " + e.message});
    }});
};

const useKpiLoader = (requiredKpiIds, controlConfig) => {
  const countOfKpisLoaded = React.useRef(0);
  const [loadingKpiData, setLoadingKpiData] = React.useState(false);
  const [kpiIdToValue, setKpiIdToValue] = React.useState(Immutable.Map());
  React.useEffect(() => {
    setLoadingKpiData(true);
    const kpiPromises = loadKpis(requiredKpiIds, controlConfig).toArray();
    kpiPromises.map(promise => promise.then(() => {
      countOfKpisLoaded.current++;
    }));
    Promise.all(kpiPromises).then(results => {
      results.length > 0 && setKpiIdToValue(indexBy(r => r.get("kpiId"), Immutable.fromJS(results)));
      setLoadingKpiData(false);
    });
  }, [requiredKpiIds, controlConfig]);
  return {kpiIdToValue, loadingKpiData, countOfKpisLoaded};
};

export default useKpiLoader;