import React from "react";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import {Card, TextField} from "@mui/material";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Kpis from "js/common/kpis";
import * as Users from "js/common/users";
import currentClient from "js/common/repo/backbone/current-client";
import * as popups from "js/common/popups";
import Error from "js/common/views/error";

const Explanation = ({kpiId}) => {
  const [explanation, setExplanation] = React.useState(null);
  const [editMode, setEditMode] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);

  const currentUser = Users.getCurrentUser();
  const canEdit = currentClient.canAccessApp("kpi_admin") && Users.canAccessApp(currentUser, "kpi_admin");
  const areExplanationsEnabled = currentClient.areExplanationsEnabled();

  React.useEffect(() => {
    const savedExplanation = KpiRepo.get(kpiId).get("explanation");
    setExplanation(savedExplanation);
  }, [kpiId]);

  const handleSave = editedExplanation => {
    setIsUpdating(true);
    const kpiName = KpiRepo.get(kpiId).get("name");
    const reason = "Updating explanation for kpi " + kpiId + " " + kpiName;
    Kpis.updateExplanation(kpiId, editedExplanation || null, reason).then(result => {
      setExplanation(result.get("explanation"));
      setIsUpdating(false);
      setEditMode(false);
      //need to reload KpiRepo or old explanation will be loaded if CT is closed and reopened
      KpiRepo.reload();
    }).catch(e => {
      if (e.responseJSON.type) {
        popups.error(e.responseJSON.message);
      } else {
        popups.contactSupport();
      }
      setIsUpdating(false);
    });
  };

  if (editMode) {
    return <EditExplanation
        explanation={explanation}
        setEditMode={setEditMode}
        onSave={handleSave}
        isUpdating={isUpdating}
    />;
  } else {
    let content;
    if (explanation) {
      content = explanation;
    } else if (canEdit) {
      content = "Include a description of what this metric does...";
    } else {
      content = <div>
        <i className="fa fa-info-circle" style={{marginRight: 10}} />
        This metric does not currently have an explanation. Please ask an admin if you would like one included.
      </div>;
    }

    return <div style={{maxHeight: "calc(100% - 5px)", display: "flex", flexDirection: "column"}}>
      {!areExplanationsEnabled && <Error
          text="Metric Explanations are currently disabled and only visible to Admin Users.  Enable them in Company Settings to make them visible to all Users."
          type={"warn"}
          style={{margin: "5px 10px"}} />}
      <Card style={{display: "flex", margin: "10px 15px", position: "relative"}}>
        <div style={getContentStyle(canEdit, explanation)}>{content}</div>
        {canEdit && <i
            style={{margin: "10px 10px 10px 7px", cursor: "pointer", position: "absolute", right: 5}}
            className="fa fa-pencil"
            onClick={() => setEditMode(true)} />}
      </Card>
    </div>;
  }
};

Explanation.displayName = "ExplanationCT";

const EditExplanation = ({explanation, setEditMode, onSave, isUpdating}) => {

  const [editedExplanation, setEditedExplanation] = React.useState(explanation);
  const characterCount = editedExplanation ? editedExplanation.length : 0;
  const maxCharacterCount = 1024;
  const characterCountExceeded = characterCount > maxCharacterCount;

  return <div style={{margin: 10}}>
    <TextField
        maxRows={15}
        multiline={true}
        minRows={3}
        style={textFieldStyle}
        error={characterCountExceeded}
        helperText={characterCountExceeded && "Maximum number of characters reached"}
        onChange={e => setEditedExplanation(e.target.value.substring(0, maxCharacterCount + 1))}
        value={editedExplanation} />
    <div style={{display: "flex", justifyContent: "flex-end", marginRight: 7}}>
      <TextButton
          style={{margin: 5}}
          icon="history"
          label="Cancel Changes"
          onClick={() => setEditMode(false)} />
      <TextButton
          style={{margin: 5}}
          type="primary"
          icon={isUpdating ? "spinner fa-spin" : "floppy-o"}
          label="Save Changes"
          onClick={() => onSave(editedExplanation)}
          disabled={explanation === editedExplanation || characterCountExceeded} />
    </div>
  </div>;
};

const getContentStyle = (canEdit, explanation) => {
  return {
    whiteSpace: "pre-wrap",
    padding: canEdit ? 30 : 25,
    fontSize: "0.95rem",
    lineHeight: 1.8,
    width: "100%",
    opacity: !explanation && "50%",
    overflow: "auto"
  };
};

const textFieldStyle = {
  width: "100%",
  marginBottom: 5,
  fontSize: "0.95rem",
  lineHeight: 0.5,
  padding: 10
};

export default Explanation;