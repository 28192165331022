import * as Immutable from "immutable";

const maybeBackboneToImmutable = maybeBackbone => {
  if (maybeBackbone === null || maybeBackbone === undefined) {
    return Immutable.Map();
  } else if (Immutable.isImmutable(maybeBackbone)) {
    return maybeBackbone;
  } else {
    return Immutable.fromJS(maybeBackbone.toJSON());
  }
};

export {
  maybeBackboneToImmutable
};