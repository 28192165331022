import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

export default React.memo(({ label }) => {
    const { theme } = React.useContext(CustomThemeContext);

    return <h3 style={{...style(theme), color : theme.id === "light" ? theme.palette.text.main : theme.palette.primary.main}}>{label}</h3>
});

const style = theme => ({
    display: "block",
    fontSize: "16px",
    textTransform: theme.themeId === "light" ? "none" : "uppercase",
    fontFamily: theme.typography.fontFamilyBold,
    marginBottom: "18px",
    fontWeight: theme.themeId === "light" ? "600" : "300",
});
