import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from 'prop-types';
import Color from "color";
import ColorPicker from "react-simple-colorpicker";
import onClickOutside from "react-onclickoutside";
import { isMobile } from "js/common/ua-parser";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";

const DEFAULT_INITIAL_COLOR = "#c0ff33";

// TODO: Replace "react-simple-colorpicker" with native `<input type="color">`
const SimpleColorPicker = createReactClass({

    propTypes: {
        theme: ReactPropTypes.object,
        onChange: ReactPropTypes.func.isRequired,
        initialColor: ReactPropTypes.string   // color = rgb(a) string
    },

    getInitialState() {
        const { initialColor } = this.props;
        return {
            showColorPicker: false,
            selectedColor: initialColor && Color(initialColor).rgb().toString()
        };
    },

    handleClick() {
        this.setState({
            showColorPicker: !this.state.showColorPicker
        });
    },

    handleDoneClick() {
        this.setState({
            showColorPicker: false
        });
        this.props.onChange(this.state.selectedColor || DEFAULT_INITIAL_COLOR);
    },

    onCancelClick() {
        this.resetColor();
    },

    handleChange(color) {
        this.setState({
            selectedColor: color
        });
    },

    removeColor() {
        this.setState({
            selectedColor: null
        }, () => {
            this.props.onChange(this.state.selectedColor);
        });
    },

    resetColor() {
        this.setState(this.getInitialState());
    },

    render() {
        const { initialColor, theme } = this.props;
        const { selectedColor, showColorPicker } = this.state;
        const triggerContainerStyle = {
            display:"inline-block",
            verticalAlign:"middle",
            lineHeight:"24px",
            position:"relative",
            marginRight: selectedColor ? 0 : 16
        };
        const triggerStyle = {
            width: 24,
            height: 24,
            borderRadius: 2,
            border: "1px solid #969696",
            backgroundColor: selectedColor || initialColor
        };
        const buttonStyle = {
            paddingLeft: 5,
            fontSize: "0.85rem",
            cursor: "pointer",
            display: "inline-block",
            verticalAlign: "middle",
            lineHeight: "24px"
        };

        return (
            <div style={{ display:"inline-block" }} className={isMobile() ? "right" : ""}>
                <div style={triggerContainerStyle}>
                    <div style={triggerStyle} onClick={this.handleClick}></div>
                    {showColorPicker &&
                        <ColorPickerWrapper
                            theme={theme}
                            onChange={this.handleChange}
                            onCancelClick={this.onCancelClick}
                            onDoneClick={this.handleDoneClick}
                            color={selectedColor} />}
                </div>
                {selectedColor &&
                    <i className="fa fa-times-circle" style={buttonStyle} onClick={this.removeColor} />}
            </div>);
    }

});

const ColorPickerWrapper = onClickOutside(createReactClass({

    propTypes: {
        theme: ReactPropTypes.object,
        onChange: ReactPropTypes.func.isRequired,
        onCancelClick: ReactPropTypes.func.isRequired,
        onDoneClick: ReactPropTypes.func.isRequired,
        color: ReactPropTypes.string   // color = rgb(a) string
    },

    render() {
        const { theme } = this.props;
        const buttonStyle = theme => ({
            marginRight:"10px",
            padding:"0 8px",
            background: theme.palette.background.card,
            border: "1px solid #555",
            color: theme.palette.textColor
        });
        return (
            <div className={`colorpicker-container colorpicker-${theme.themeId}`}>
                <ColorPicker
                    color={this.props.color || DEFAULT_INITIAL_COLOR}
                    opacitySlider={false}
                    onChange={this.props.onChange} />
                <div className="text-right">
                    <button style={buttonStyle(theme)} onClick={this.props.onCancelClick}>
                        Cancel
                    </button>
                    <button style={buttonStyle(theme)} onClick={this.props.onDoneClick}>
                        Done
                    </button>
                </div>
            </div>);
    },

    handleClickOutside() {
        this.props.onCancelClick();
    }

}));


const Wrapper = (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <SimpleColorPicker theme={theme} {...props} />;
};
  
export default Wrapper;