import React from "react";
import createReactClass from "create-react-class";
import moment from "moment";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";

import TagsHistory from "js/admin/tags/react-tags-history";
import TagRecalculationJobs from "js/admin/tags/react-tag-recalculation-jobs";

import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as Branding from "js/common/branding-constants"

const path = window.path;

const mysqlDatFormat = "YYYY-MM-DD";

const unexpectedErrorMsg = `An unexpected error has occurred. ${Branding.submitTicketString}`;

export default createReactClass({

    mixins: [ PureRenderMixin ],

    getInitialState() {
        return {
            hierarchy: Groups.getHierarchyWithUsers(),
            currentQualifierType: null,
            currentQualifierId: null,
            isLoadingTagsHistory: false,
            tagsHistory: Immutable.List(),
            isSavingTagsHistoryChanges: false,
            tagsHistoryError: null,
            tagsHistorySuccess: null,

            isLoadingInheritedTags: false,
            inheritedTags: Immutable.List(),

            currentTagRecalculationJobId: null,
            isLoadingTagRecalculationJobs: false,
            tagRecalculationJobs: Immutable.List()
        };
    },

    componentDidMount() {
        this.setState({
            isLoadingTagRecalculationJobs: true
        });
        this.loadJobs();
        this.intervalId = window.setInterval(() => this.loadJobs(), 30*1000);
    },

    componentWillUnmount() {
        window.clearInterval(this.intervalId);
    },

    loadJobs() {
        loadTagRecalculationJobs()
            .then(tagRecalculationJobs => {
                this.setState({
                    isLoadingTagRecalculationJobs: false,
                    tagRecalculationJobs
                });
            });
    },

    render() {
        const {
            hierarchy,
            currentQualifierType,
            currentQualifierId,
            isLoadingTagsHistory,
            isSavingTagsHistoryChanges,
            tagsHistory,
            tagsHistorySuccess,
            tagsHistoryError,
            isLoadingInheritedTags,
            inheritedTags,
            tagRecalculationJobs,
            isLoadingTagRecalculationJobs,
            currentTagRecalculationJobId
        } = this.state;
        const hasUnsavedChanges = tagsHistory.some(th => th.get("cid") || th.get("isChanged") || th.get("deleted"));
        return (
            <div>
                <TagsHistory
                    hierarchy={hierarchy}
                    onGroupSelect={this.handleGroupSelect}
                    onUserSelect={this.handleUserSelect}
                    currentQualifierType={currentQualifierType}
                    currentQualifierId={currentQualifierId}
                    isLoadingTagsHistory={isLoadingTagsHistory}
                    hasUnsavedChanges={hasUnsavedChanges}
                    tagsHistory={tagsHistory}
                    onChange={this.handleTagsHistoryChange}
                    onCancelChangesRequest={this.cancelTagsHistoryChanges}
                    onSaveChangesRequest={this.handleSaveTagsHistoryChangesRequest}
                    isSavingChanges={isSavingTagsHistoryChanges}
                    error={tagsHistoryError}
                    success={tagsHistorySuccess}
                    onSuccessMsgTimeout={() => this.setState({ tagsHistorySuccess: null })}
                    isLoadingInheritedTags={isLoadingInheritedTags}
                    inheritedTags={inheritedTags} />
                <TagRecalculationJobs
                    isLoading={isLoadingTagRecalculationJobs}
                    jobs={tagRecalculationJobs}
                    selectedJobId={currentTagRecalculationJobId}
                    onJobSelect={jobId => this.setState({ currentTagRecalculationJobId: jobId })} />
            </div>
        );
    },

    handleGroupSelect(groupId) {
        const currentUser = Users.getCurrentUser();
        const currentUserCanOnlySeeSelf = currentUser.get("dataVisibility") === "SELF";
        if (currentUserCanOnlySeeSelf) {
            this.setState({
                currentQualifierType: "GROUP",
                currentQualifierId: groupId,
                tagsHistorySuccess: null,
                tagsHistoryError: "You do not have permission to view this group's Tags History"
            });
            return;
        }

        this.setState({
            currentQualifierType: "GROUP",
            currentQualifierId: groupId,
            isLoadingTagsHistory: true,
            isLoadingInheritedTags: true,
            tagsHistorySuccess: null,
            tagsHistoryError: null
        });

        loadTagsHistoryForGroup(groupId)
            .then(tagsHistory => {
                this.setState({
                    isLoadingTagsHistory: false,
                    tagsHistory
                });
            }, () => {
                this.setState({
                    tagsHistoryError: "Unable to load the group's Tags History. Please wait a few minutes, then try again."
                });
            });

        const includeInitialGroup = false;
        loadInheritedTags(groupId, includeInitialGroup)
            .then(inheritedTags => {
                this.setState({
                    isLoadingInheritedTags: false,
                    inheritedTags
                });
            }, () => {
                this.setState({
                    tagsHistoryError: "Unable to load the group's Inherited Tags."
                });
            });
    },

    handleUserSelect(userId) {
        const currentUser = Users.getCurrentUser();
        const currentUserCanOnlySeeSelf = currentUser.get("dataVisibility") === "SELF";
        if (currentUserCanOnlySeeSelf && currentUser.get("id") !== userId) {
            this.setState({
                currentQualifierType: "USER",
                currentQualifierId: userId,
                tagsHistorySuccess: null,
                tagsHistoryError: "You do not have permission to view this user's Tags History"
            });
            return;
        }

        this.setState({
            currentQualifierType: "USER",
            currentQualifierId: userId,
            isLoadingTagsHistory: true,
            isLoadingInheritedTags: true,
            tagsHistorySuccess: null,
            tagsHistoryError: null
        });

        loadTagsHistoryForUser(userId)
            .then(tagsHistory => {
                this.setState({
                    isLoadingTagsHistory: false,
                    tagsHistory
                });
            }, () => {
                this.setState({
                    tagsHistoryError: "Unable to load the user's Tags History. Please wait a few minutes, then try again."
                });
            });

        const user = Users.getUser(userId);
        const includeInitialGroup = true;
        loadInheritedTags(user.get("groupId"), includeInitialGroup)
            .then(inheritedTags => {
                this.setState({
                    isLoadingInheritedTags: false,
                    inheritedTags
                });
            }, () => {
                this.setState({
                    tagsHistoryError: "Unable to load the user's Inherited Tags."
                });
            });
    },

    handleTagsHistoryChange(tagsHistory) {
        this.setState({
            tagsHistory,
            tagsHistorySuccess: null,
            tagsHistoryError: null
        });
    },

    cancelTagsHistoryChanges() {
        const { currentQualifierType, currentQualifierId } = this.state;
        this.setState({
            tagsHistoryError: null
        });
        if (currentQualifierType === "GROUP") {
            const groupId = currentQualifierId;
            this.setState({
                isLoadingTagsHistory: true
            });
            loadTagsHistoryForGroup(groupId)
                .then(tagsHistory => {
                    this.setState({
                        isLoadingTagsHistory: false,
                        tagsHistory
                    });
                });
        } else if (currentQualifierType === "USER") {
            const userId = currentQualifierId;
            this.setState({
                isLoadingTagsHistory: true
            });
            loadTagsHistoryForUser(userId)
                .then(tagsHistory => {
                    this.setState({
                        isLoadingTagsHistory: false,
                        tagsHistory
                    });
                });
        }
    },

    handleSaveTagsHistoryChangesRequest() {
        this.setState({
            isSavingTagsHistoryChanges: true,
            tagsHistorySuccess: null,
            tagsHistoryError: null
        });

        const { tagsHistory } = this.state;
        let errorsFound = Immutable.List();
        const hasInvalidTagDates = !tagsHistory.every(th => {
            const tagStartDate = th.get("startDate");
            const tagEndDate = th.get("endDate");
            return tagStartDate.isValid() && tagEndDate.isValid();
        });
        if (hasInvalidTagDates) {
            errorsFound = errorsFound.push("You have invalid start/end dates in 1 or more rows");
        }
        const hasUnassignedTagRows = !tagsHistory.every(th => th.get("tagId"));
        if (hasUnassignedTagRows) {
            errorsFound = errorsFound.push("You have not assigned a Tag to 1 or more rows");
        }

        if (errorsFound.isEmpty()) {
            this.saveTagsHistoryChanges();
        } else {
            this.setState({
                tagsHistoryError: errorsFound,
                isSavingTagsHistoryChanges: false
            });
        }
    },

    saveTagsHistoryChanges() {
        const { tagsHistory, currentQualifierType, currentQualifierId } = this.state;
        if (currentQualifierType === "GROUP") {
            const groupId = currentQualifierId;
            ajax
                .put({
                    url: path("group", groupId, "tags"),
                    json: getTagsHistoryJson(tagsHistory)
                })
                .then(results => {
                    const newTagsHistory = Immutable
                        .fromJS(results)
                        .map(parseTagHistoryDates);
                    const group = Groups.getGroup(groupId);
                    this.setState({
                        tagsHistory: newTagsHistory,
                        tagsHistorySuccess: `Tag history for ${group.get("name")} updated`,
                        isSavingTagsHistoryChanges: false
                    });
                }, error => {
                    const responseJson = error.responseJSON;
                    const errors = responseJson.errors;
                    if (responseJson && errors) {
                        this.setState({
                            tagsHistoryError: errors,
                            isSavingTagsHistoryChanges: false
                        });
                    } else {
                        this.setState({
                            tagsHistoryError: unexpectedErrorMsg,
                            isSavingTagsHistoryChanges: false
                        });
                    }
                });
        } else if (currentQualifierType === "USER") {
            const userId = currentQualifierId;
            ajax
                .put({
                    url: path("users", userId, "tags"),
                    json: getTagsHistoryJson(tagsHistory)
                })
                .then(results => {
                    const newTagsHistory = Immutable
                        .fromJS(results)
                        .map(parseTagHistoryDates);
                    const user = Users.getUser(userId);
                    this.setState({
                        tagsHistory: newTagsHistory,
                        tagsHistorySuccess: `Tag history for ${user.get("fullName")} updated`,
                        isSavingTagsHistoryChanges: false
                    });
                }, error => {
                    const responseJson = error.responseJSON;
                    const errors = responseJson.errors;
                    if (responseJson && errors) {
                        const rowNumbers = Object.keys(errors);
                        let errorList = Immutable.List();
                        rowNumbers.forEach(n => {
                            errorList = errorList.push(errors[n]);
                        });
                        this.setState({
                            tagsHistoryError: errorList,
                            isSavingTagsHistoryChanges: false
                        });
                    } else {
                        this.setState({
                            tagsHistoryError: unexpectedErrorMsg,
                            isSavingTagsHistoryChanges: false
                        });
                    }
                });
        }
    }

});

const getTagsHistoryJson = tagsHistory => tagsHistory
    .filter(th => th.get("id") || (th.get("cid") && !th.get("deleted")))
    .map(tagHistory => tagHistory
        .set("startDate", tagHistory.get("startDate").format(mysqlDatFormat))
        .set("endDate", tagHistory.get("endDate").format(mysqlDatFormat))
        .delete("isChanged")
        .delete("cid"))
    .toJSON();

const loadTagsHistoryForUser = userId => ajax
    .get({ url: `users/${userId}/tags` })
    .then(results => {
        const tagsHistoryList = Immutable.fromJS(results);
        return tagsHistoryList.map(parseTagHistoryDates);
    });

const loadTagsHistoryForGroup = groupId => ajax
    .get({ url: `group/${groupId}/tags` })
    .then(results => {
        const tagsHistoryList = Immutable.fromJS(results);
        return tagsHistoryList.map(parseTagHistoryDates);
    });

const loadInheritedTags = (initialGroupId, includeInitialGroup) => {
    const initialGroup = Groups.getGroup(initialGroupId);
    let groupIds = includeInitialGroup ? [ initialGroupId ] : [];
    let group = Groups.getGroup(initialGroup.get("parentId"));
    while (group) {
        groupIds.push(group.get("id"));
        group = Groups.getGroup(group.get("parentId"));
    }
    const promises = groupIds
        .reverse()
        .map(loadTagsHistoryForGroup);
    return Promise
        .all(promises)
        .then(results => {
            const inheritedTagsList = Immutable.fromJS(results).flatten(1);
            return inheritedTagsList
                .map(parseTagHistoryDates);
        });
};

const parseTagHistoryDates = tagHistory => tagHistory
    .set("startDate", parseDateStr(tagHistory.get("startDate")))
    .set("endDate", parseDateStr(tagHistory.get("endDate")));

const parseDateStr = mysqlDateStr => moment(mysqlDateStr, mysqlDatFormat);

const loadTagRecalculationJobs = () => ajax
    .get({ url: "async-calculation-jobs" })
    .then(results => Immutable
        .fromJS(results)
        .map(parseJobPayload));

const parseJobPayload = job => {
    const payloadJson = JSON.parse(job.get("payload"));
    return job.set("payload", Immutable.fromJS(payloadJson));
};
