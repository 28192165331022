import React from "react";
import GetContainerDimensions from "react-dimensions";
import pure from "js/common/views/pure";
import SimpleDataTable from "js/common/views/tables/simple-data-table";

export default GetContainerDimensions()(pure(({ id = `errors-tbl-${Math.random()}`, errors, containerWidth, downloadable = true, searchable = true, maxTableHeight, hideLineNumber }) => {
    const longestErrorMsg = errors
        .map(e => e.get("message").toLowerCase())
        .sortBy(str => str.length)
        .last();
    const pixelsPerChar = 7;
    const errorMsgWidth = longestErrorMsg.length * pixelsPerChar;
    const lineNumColWidth = 120;
    const columns = [ !hideLineNumber && {
        label: "Line Number",
        width: lineNumColWidth
    },{
        label: "Error",
        width: Math.max(containerWidth - lineNumColWidth, errorMsgWidth)
    }];
    const rows = errors.map(e => [ !hideLineNumber && e.get("lineNumber"), e.get("message") ]).toJS();
    return <SimpleDataTable id={id} columns={columns} rows={rows} downloadable={downloadable} searchable={searchable} maxTableHeight={maxTableHeight} />;
}));
