import React from "react";

const nextDirection = direction => direction === "ASC" ? "DESC" : "ASC";

const makeComparator = asc => {
  return (a, b) => {
    if (typeof a === "string") {
      const direction = asc ? 1 : -1;
      return direction * a.localeCompare(b, undefined, {numeric: true});
    } else {
      if (a < b) {
        return asc ? -1 : 1;
      } else if (a > b) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    }
  };
};

const directionToSortIcon = (direction, style) => {
  switch (direction) {
    case "ASC":
      return <i style={{paddingLeft: "0.5rem", ...style}} className="fa fa-caret-up" />;
    case "DESC":
      return <i style={{paddingLeft: "0.5rem", ...style}} className="fa fa-caret-down" />;
    default:
      return "";
  }
};

export {
  nextDirection,
  makeComparator,
  directionToSortIcon
};