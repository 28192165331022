import React from "react";
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import moment from "moment";
import Immutable from "immutable";
import PureRenderMixin from "react-addons-pure-render-mixin";
import {TextButton} from "js/common/views/inputs/buttons";
import CurrencyPicker from "js/oneview/targets/currency-picker";
import PeriodTypePicker from "js/oneview/targets/period-type-picker";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import ArrowIcon from "js/oneview/targets/arrow-icon";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import NoteTextArea from "js/oneview/targets/note-text-area";
import ErrorMsg from "js/common/views/error";
import eventBus from "js/cube19.event-bus";
import currentClient from "js/common/repo/backbone/current-client";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as ajax from "js/common/ajax";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const AddNewTarget = createReactClass({

  mixins: [PureRenderMixin],

  propTypes: {
    kpiId: ReactPropTypes.number.isRequired,
    qualifierType: ReactPropTypes.string.isRequired,
    qualifierId: ReactPropTypes.number.isRequired,
    onNewTargetCreated: ReactPropTypes.func.isRequired,
    onCloseRequest: ReactPropTypes.func.isRequired
  },

  getInitialState() {
    const {kpiId, qualifierType, qualifierId} = this.props;
    return {
      target: getNewTarget(kpiId, qualifierType, qualifierId),
      errors: Immutable.List(),
      isUpdating: false
    };
  },

  render() {
    const {target, errors, isUpdating} = this.state;
    const targetValueWrapper = target.get("value");
    const isMonetaryTarget = !!targetValueWrapper.get("currency");
    const targetCurrency = targetValueWrapper.get("currency");
    const targetValue = targetValueWrapper.get("value");
    const startDate = target.get("targetStart");
    const endDate = target.get("targetEnd");
    const isPriority = target.get("priority");
    const theme = this.context.theme;
    const priorityToggleStyle = {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      cursor: "pointer",
      fontSize: "1rem",
      lineHeight: "35px",
      color: isPriority ? theme.palette.primary.main : theme.palette.action.disabled
    };
    const minValue = 0;
    const maxValue = 999999999999999;
    return (
        <div style={containerStyle}>
          <h1 style={headerStyle(theme)}>Add New Target</h1>
          <div style={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection: "column", marginRight: "2rem"}}>
              <span style={labelStyle}>Target Value</span>
              <div style={{display: "flex", flexWrap: "wrap"}}>
                {isMonetaryTarget &&
                    <CurrencyPicker
                        theme={theme}
                        containerStyle={{marginRight: "0.2rem"}}
                        style={boxStyle(theme)}
                        value={targetCurrency}
                        onChange={currency => {
                          this.setState(state => ({target: state.target.setIn(["value", "currency"], currency)}));
                        }} />}
                <SimpleTextInput
                    type="number"
                    value={targetValue}
                    onChange={this.handleValueChange}
                    customStyle={{width: 180, height: 38, ...boxStyle(theme)}} />
                {currentClient.hasPermission("USES_445_CALENDAR") ?
                    <div style={{width: 180, lineHeight: "36px", paddingLeft: "0.5rem"}}>
                      per week
                    </div> :
                    <PeriodTypePicker
                        style={boxStyle(theme)}
                        containerStyle={{width: 180, marginLeft: "0.2rem"}}
                        value={target.get("period")}
                        onChange={period => this.setState(state => ({target: state.target.set("period", period)}))} />}
              </div>

              {(isNaN(targetValue) || (!targetValue && targetValue !== 0)) &&
                  <ErrorMsg text="Numerical value required" style={errorSpacing} />}
              {targetValue < minValue &&
                  <ErrorMsg text="Target should not be a negative value" style={errorSpacing} />}
              {targetValue > maxValue &&
                  <ErrorMsg text={`Target should not be over ${maxValue}`} style={errorSpacing} />}
            </div>

            <div style={{display: "flex", flexDirection: "column", marginRight: "2rem"}}>
              <span style={labelStyle}>
                  Start <ArrowIcon style={{paddingLeft: "0.5rem", paddingRight: "0.5rem"}} /> End
              </span>
              <div className={`picker-${theme.themeId}`} style={{display: "flex"}}>
                <DatePicker
                    style={{marginRight: "1rem", background: theme.palette.background.card, ...boxStyle(theme)}}
                    hideError={true}
                    value={startDate}
                    onDateChange={date => {
                      this.setState(state => ({target: state.target.set("targetStart", date)}));
                    }} />
                <ArrowIcon style={{marginRight: "1rem", lineHeight: "35px"}} />
                <DatePicker
                    style={{marginRight: "1rem", background: theme.palette.background.card, ...boxStyle(theme)}}
                    hideError={true}
                    value={isFarFutureDate(endDate) ? null : endDate}
                    onDateChange={this.handleEndDateChange} />
              </div>

              {(!startDate.isValid() || (endDate && !endDate.isValid())) &&
                  this.renderDatesValidationError()}
            </div>

            <div style={{minWidth: 450}}>
              <span style={labelStyle}>Note</span>
              <NoteTextArea
                  value={target.getIn(["noteAssignmentDto", "note"])}
                  onChange={this.handleNoteChange}
                  customStyle={{
                    marginTop: 0,
                    backgroundColor: theme.palette.background.card,
                    color: theme.palette.textColor
                  }} />
            </div>
          </div>

          <div>
            <span style={labelStyle}>Make this a priority</span>
            <i
                className={`fa fa-${isPriority ? "star" : "star-o"}`}
                style={priorityToggleStyle}
                onClick={() => {
                  this.setState(state => ({target: state.target.set("priority", !target.get("priority"))}));
                }} />
          </div>

          <div>
            <TextButton type="secondary" label="Cancel" onClick={() => this.props.onCloseRequest()} style={spacing} />
            <TextButton type="primary" label="Add" onClick={() => this.addNewTarget()} style={spacing} />
            {isUpdating && <LoadingIndicator />}
          </div>

          {!errors.isEmpty() &&
              errors.map((error, index) => <ErrorMsg key={index} text={error} style={errorSpacing} />)}
        </div>);
  },

  renderDatesValidationError() {
    const startDate = this.state.target.get("targetStart");
    const endDate = this.state.target.get("targetEnd");
    let invalidDatesText = "Dates";
    if (!startDate.isValid() && !endDate.isValid()) {
      invalidDatesText = "Start and End Dates";
    }
    if (!startDate.isValid() && endDate.isValid()) {
      invalidDatesText = "Start Date";
    }
    if (startDate.isValid() && !endDate.isValid()) {
      invalidDatesText = "End Date";
    }
    return <ErrorMsg
        text={`${invalidDatesText} should be in the format ${moment.localeData().longDateFormat.L}`}
        style={errorSpacing} />;
  },

  handleValueChange(value) {
    const valueWrapper = this.state.target.get("value");
    const num = isNaN(value) ? "0" : value;
    this.setState({
      target: this.state.target.set("value", valueWrapper.set("value", num))
    });
  },

  handleEndDateChange(endDate, endDateInputValue) {
    const targetEndDate = (!endDate.isValid() && !endDateInputValue) ? getFarFutureDate() : endDate;
    this.setState({
      target: this.state.target.set("targetEnd", targetEndDate)
    });
  },

  handleNoteChange(note) {
    this.setState({
      target: this.state.target.setIn(["noteAssignmentDto", "note"], note)
    });
  },

  addNewTarget() {
    const {target, errors} = this.state;
    this.setState({
      isUpdating: true
    });
    createNewTarget(target).then(
        result => {
          this.setState({
            isUpdating: false
          });
          this.props.onNewTargetCreated();
          eventBus.trigger("kpi:target-added", this.props.kpiId);
        },
        error => {
          let validationErrors;
          if (error.responseJSON && error.responseJSON.type === "INVALID_TARGET") {
            const message = error.responseJSON.message;
            validationErrors = Immutable.List.of(message);
          } else {
            validationErrors = errors.push("Unable to save new target");
          }
          this.setState({
            isUpdating: false,
            errors: validationErrors
          });
        });
  }

});

const LoadingIndicator = () => {
  const alignmentStyle = {
    lineHeight: "41px",
    verticalAlign: "top"
  };
  return (
      <span>
            <i className="fa fa-spinner fa-spin" style={{marginRight: 8, color: "#999", ...alignmentStyle}} />
            <span style={{fontSize: "0.875rem", ...alignmentStyle}}>Saving New Target</span>
        </span>
  );
};

const spacing = {
  marginRight: "1rem"
};

const errorSpacing = {
  marginTop: "0.5rem",
  marginBottom: "0.5rem"
};

const headerStyle = theme => ({
  color: theme.palette.primary.main,
  fontSize: "0.875rem",
  fontWeight: "normal",
  textTransform: "uppercase"
});

const boxStyle = theme => ({
  border: theme.themeId === "light" ? "1px solid #cccccc" : "1px solid #666666"
});

const containerStyle = {
  ...boxStyle,
  borderRadius: 3,
  padding: "1rem",
  margin: "1rem",
  display: "flex",
  flexDirection: "column"
};

const labelStyle = {
  fontSize: "0.875rem",
  lineHeight: "35px"
};

const getFarFutureDate = () => parseDate("2099-01-01");
const isFarFutureDate = dateStr => parseDate(dateStr).isSameOrAfter(getFarFutureDate(), "day");

const parseDate = (dateStr, format = "YYYY-MM-DD") => moment(dateStr, format);

const getGroupForUser = userId => Groups.getGroup(Users.getUser(userId).get("groupId"));
const getNewTarget = (kpiId, qualifierType, qualifierId) => {
  const today = moment().startOf("day");
  const farFutureDate = moment("2099-01-01", "YYYY-MM-DD");
  const kpiValueFormat = kpiRepo.get(kpiId).get("type").valueFormat;
  const isMonetaryFormat = kpiValueFormat === "CURRENCY";
  let valueWrapper = {
    value: "0"
  };
  if (isMonetaryFormat) {
    const group = qualifierType === "GROUP" ? Groups.getGroup(qualifierId) : getGroupForUser(qualifierId);
    valueWrapper.currency = group.get("currencyCode");
  }
  return Immutable.fromJS({
    kpiId,
    value: valueWrapper,
    targetStart: today,
    targetEnd: farFutureDate,
    period: currentClient.hasPermission("USES_445_CALENDAR") ? "WEEKLY" : "MONTHLY",
    priority: false,
    qualifierType,
    qualifierId,
    noteAssignmentDto: {
      note: ""
    }
  });
};

const formatDate = (date, format = "YYYY-MM-DD") => date.format(format);
const createNewTarget = target => {
  const pathToTargetNote = ["noteAssignmentDto", "note"];
  const maxNoteStrLength = 8192;
  const json = target
      .set("targetStart", formatDate(target.get("targetStart")))
      .set("targetEnd", formatDate(target.get("targetEnd")))
      .set("targetQualifierIds", [target.get("qualifierId")])
      .setIn(pathToTargetNote, target.getIn(pathToTargetNote).substring(0, maxNoteStrLength))
      .delete("qualifierId")
      .toJS();
  return ajax.post({
    url: "kpi/target?source=ClickThrough",
    json
  });
};


AddNewTarget.contextType = CustomThemeContext;

export default AddNewTarget;
