import React from "react";
import * as Immutable from "immutable";

import pure from "js/common/views/pure";
import {getTimeAgo, formatDateForDisplay} from "js/job-pipelines/date-utils";

const candidateStageWidth = 190;
const candidateNameWidth = 150;

const candidateRowHeight = 100;
const circleRadius = 12;
const lineThickness = 2;
const missingStageColor = "slategrey";
const doneStageColor = "limegreen";
const nextStageColor = "lightgrey";
const futureStageColor = "dimgrey";

const countUniqueIds = xs => xs.map(x => x.get("id")).toSet().count();

const getTranslationStr = ([x = 0, y = 0] = []) => `translate(${x}, ${y})`;
const getRotationStr = (angle = 0) => `rotate(${angle})`;
const getScaleStr = (scale = 1) => `scale(${scale})`;

const Transform = pure(({style = {}, translate, rotate, scale, children}) => (
    <g style={style} transform={getTranslationStr(translate) + "," + getRotationStr(rotate) + "," + getScaleStr(scale)}>
        {children}
    </g>));

const CandidateRowSvg = pure(({
    stages,
    job,
    candidateRow,
    onActivityClick,
    theme
}) => {
    const candidate = candidateRow.get("candidate");
    return (
        <div style={{whiteSpace: "nowrap"}}>
            <div
                style={{
                    display: "table",
                    width: candidateNameWidth,
                    height: candidateRowHeight,
                    float: "left",
                    paddingRight: "0.5rem"
                }}>
                <div style={{display: "table-cell", textAlign: "right", verticalAlign: "middle", whiteSpace: "normal"}}>
                    {candidate.get("name")}
                </div>
            </div>
            <div
                style={{
                    display: "inline-block",
                    width: (candidateStageWidth * stages.count()) + candidateStageWidth,
                    height: candidateRowHeight
                }}>
                <svg
                    style={{width: "100%", height: "100%"}}
                    textRendering="optimizeLegibility">
                    <Transform
                        translate={[circleRadius + (candidateStageWidth / 2), candidateRowHeight / 2]}
                        style={{stroke: "none", fill: theme.palette.text.main}}>
                        <text
                            textAnchor="middle"
                            x={0}
                            y={circleRadius * -1.1}
                            style={{dominantBaseline: "text-after-edge"}}>
                            {"Job Added"}
                        </text>
                        <text
                            x={0}
                            y={circleRadius * 1.1}
                            fill={theme.palette.success.main}
                            fontSize={15}
                            textAnchor="middle"
                            style={{dominantBaseline: "text-before-edge"}}>
                            {getTimeAgo(job.get("createdDate"))}
                        </text>
                        <text
                            x={0}
                            y={(circleRadius * 1.1) + 16}
                            fill={doneStageColor}
                            fontSize={15}
                            textAnchor="middle"
                            style={{dominantBaseline: "text-before-edge"}}>
                            {"(" + formatDateForDisplay(job.get("createdDate")) + ")"}
                        </text>
                        {stages
                            .reverse()
                            .map((stage, index) => <PipelineStage
                                key={index}
                                stages={stages}
                                stage={stage}
                                index={stages.count() - index - 1}
                                width={candidateStageWidth}
                                entities={candidateRow.get("stageIdToEntities").get(stage.get("id"), Immutable.List())}
                                stageIdToLatestEntityDate={candidateRow.get("stageIdToLatestEntityDate")}
                                stageIdToDaysBetween={candidateRow.get("stageIdToDaysBetween")}
                                furthestStageReachedOrder={candidateRow.get("furthestStageReachedOrder")}
                                onStageCircleClick={onActivityClick}
                                theme={theme}
                                />
                            )}
                        <circle
                            fill={theme.palette.success.main}
                            stroke={theme.palette.success.main}
                            strokeWidth={lineThickness}
                            r={circleRadius / 2} />
                    </Transform>
                </svg>
            </div>
        </div>
    );
});

const PipelineStage = pure(({
    stages,
    stage,
    index,
    width,
    entities,
    stageIdToLatestEntityDate,
    stageIdToDaysBetween,
    furthestStageReachedOrder,
    onStageCircleClick,
    theme
}) => {
    const circleX = (index + 1) * width;

    const stageHasActivity = entities.count() > 0;
    const stageIsDone = stage.get("ordering") <= furthestStageReachedOrder;

    if (stageIsDone && !stageHasActivity) {
        return (
            <g>
                <line
                    x1={circleX - width}
                    x2={circleX}
                    stroke={missingStageColor}
                    strokeWidth={lineThickness} />
                <text
                    x={circleX}
                    y={circleRadius * -1.1}
                    textAnchor="middle"
                    style={{dominantBaseline: "text-after-edge"}}>
                    {stage.get("name")}
                </text>
                <text
                    x={circleX}
                    fontSize="22"
                    fill={missingStageColor}
                    textAnchor="middle"
                    style={{
                        dominantBaseline: "central",
                        fontSize: "1.3rem",
                        fontWeight: "bold"
                    }}>
                    {"X"}
                </text>
            </g>
        );
    } else {
        const stageIsAfterCurrent = stage.get("ordering") >= furthestStageReachedOrder + 1;
        const stageIsNextToBeDone = stage.get("ordering") === furthestStageReachedOrder + 1;

        let stageColor;
        if (stageIsDone && stageHasActivity) {
            stageColor = doneStageColor;
        } else if (stageIsNextToBeDone) {
            stageColor = nextStageColor;
        } else if (stageIsAfterCurrent) {
            stageColor = futureStageColor;
        } else {
            stageColor = "purple"; // NOTE vibrant color to make errors stand out, should never be shown
        }
        return (
            <g>
                {(stageIsDone || stageIsNextToBeDone) &&
                <line
                    x1={circleX - width}
                    x2={circleX - circleRadius}
                    stroke={stageColor}
                    strokeWidth={lineThickness}
                    strokeDasharray={stageIsNextToBeDone ? "5,5" : null} />
                }
                <circle
                    onClick={stageHasActivity ? () => onStageCircleClick(stage, entities) : () => {}}
                    style={stageHasActivity ? {cursor: "pointer"} : {}}
                    fill={theme.palette.background.card}
                    stroke={stageColor}
                    strokeWidth={lineThickness}
                    strokeDasharray={stageIsAfterCurrent ? "3,3" : null}
                    cx={circleX}
                    r={circleRadius} />
                <text
                    textAnchor="middle"
                    x={circleX}
                    y={circleRadius * -1.1}
                    style={{dominantBaseline: "text-after-edge"}}>
                    {stage.get("name")}
                </text>
                {stageIsDone &&
                <text
                    x={circleX}
                    fill={stageColor}
                    textAnchor="middle"
                    style={{
                        pointerEvents: "none",
                        dominantBaseline: "central",
                        fontSize: "0.9rem",
                        fontWeight: "bold"
                    }}>
                    {countUniqueIds(entities)}
                </text>
                }
                {(stageIsDone && stageHasActivity) &&
                <g>
                    <text
                        x={circleX}
                        y={circleRadius * 1.1}
                        fill={stageColor}
                        fontSize={15}
                        textAnchor="middle"
                        style={{dominantBaseline: "text-before-edge"}}>
                        {getTimeAgo(entities.map(e => e.get("date")).max())}
                    </text>
                    <text
                        x={circleX}
                        y={(circleRadius * 1.1) + 16}
                        fill={stageColor}
                        fontSize={15}
                        textAnchor="middle"
                        style={{dominantBaseline: "text-before-edge"}}>
                        {"(" + formatDateForDisplay(entities.map(e => e.get("date")).max()) + ")"}
                    </text>
                </g>
                }
            </g>
        );
    }
});

export {
    CandidateRowSvg,
    candidateStageWidth,
    candidateNameWidth
};
